import { Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormErrorHelper } from '@helpers';
import { ManufacturerResource } from '@resources';
import { SnackbarService } from '../../../snackbar/snackbar.service';
import { AuthService } from '@services';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';

@Component({
  selector: 'app-manufacturer-new',
  template: require('./manufacturer-new.component.html')
})
export class ManufacturerNewComponent extends AbstractPageComponent {
  public manufacturer: any = {};

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: ManufacturerResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private snackbar: SnackbarService,
  ) {
    super($translate, authService, resource, state);
  }

  /**
   * Prepares query to send data to API.
   */
  private prepareQuery(query: any): object {
    return {
      ...query
    };
  }

  /**
   * Handles the save form event.
   */
  public save({ body, form, redirect }: { body: object, form: FormGroup, redirect: boolean }): void {
    this.resource.create(this.prepareQuery(body))
      .takeUntil(this.destroyed$)
      .subscribe(() => {
        this.snackbar.validate(this.translate('PAGE.MANUFACTURER.NEW.SAVED'));
        if (redirect) {
          this.actions.list.go();
        }
      }, (error: any) => {
        if (400 === error.code) {
          FormErrorHelper.fillFormControlWithErrors(form, error.errors);
        }
      })
    ;
  }
}
