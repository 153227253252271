import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'groupby', pure: false})
export class GroupByPipe implements PipeTransform {
  transform<T>(value: T[], property: string): any {
    if (!value || !property) {
      return value;
    }

    const groups: any = {};

    value.forEach((item: any) => {
      const key = item[property];
      groups[key] = [...(groups[key] || []), item];
    });

    return Object.keys(groups).map(key => ({ key, value: groups[key] }));
  }
}
