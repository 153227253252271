export * from './abstract.resource';
export * from './attribute-family.resource';
export * from './attribute.resource';
export * from './attribute-marketplace.resource';
export * from './attribute-value.resource';
export * from './carrier-configs.resource';
export * from './carrier-group.resource';
export * from './carrier-icons.resource';
export * from './category.resource';
export * from './country.resource';
export * from './customer-type.resource';
export * from './commercial-entity.resource';
export * from './cross-selling-product.resource';
export * from './city.resource';
export * from './cms.resource';
export * from './country.resource';
export * from './marketplace.resource';
export * from './master-product.resource';
export * from '../components/customer/customer.resource';
// <<<
export * from './logistic.resource';
export * from './inscpxee.resource';
export * from './manufacturer.resource';
export * from './package.resource';
export * from './order-item.resource';
export * from './order.resource';
export * from './order-supported-by.resource';
export * from './payment-icons.resource';
export * from './block.resource';
export * from './picto.resource';
export * from './product-marketplace-status.resource';
export * from './reviewer.resource';
export * from './role-hierarchy.resource';
export * from './order-manager-status.resource';
export * from './status.resource';
export * from './tab-image.resource';
export * from './up-selling-product.resource';
export * from './user.resource';
export * from './website-configuration.resource';
export * from './wysiwyg.resource';
export * from './master-products-warehouses.resource';
export * from './indicator.resource';
export * from './room.resource';
export * from './carrier-blacklist-marketplaces.resource';
export * from './logistic-wave.resource';
export * from './litigation-package.resource';
export * from './stock-movement.resource';
export * from './stock-movement-line.resource';
export * from './oney-opc.resource';
