import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormTabValidationDirective } from '@directives/tabs/form-tab-validation.directive';
import { CopyButtonDirective } from '@directives/copy-button.directive';

@NgModule({
  declarations: [
    FormTabValidationDirective,
    CopyButtonDirective,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    FormTabValidationDirective,
    CopyButtonDirective,
  ]
})
export class DirectivesModule {}
