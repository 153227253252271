export default eavAttributeRoutes;

/** @ngInject */
function eavAttributeRoutes($stateProvider: any): void {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'walipush',
    sidebarSubNav: 'eavAttributeList',
    title: '',
  };

  $stateProvider
    .state('eav-attribute', {
      url: '/eav-attributes',
      parent: 'walisoft',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.EAV_ATTRIBUTE',
          stateName: 'eav-attribute.list'
      },
    })
    .state('eav-attribute.list', {
      url: '',
      views: {
        'page@layout': {
          template: '<app-eav-attribute-list></app-eav-attribute-list>'
        },
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.EAV_ATTRIBUTE.LIST.TITLE'
      })
    })
    .state('eav-attribute.edit', {
      url: '/:id/edit',
      breadcrumb: { label: 'PAGE.EAV_ATTRIBUTE.EDIT.TITLE' },
      views: {
        'page@layout': {
          template: '<app-eav-attribute-form></app-eav-attribute-form>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.EAV_ATTRIBUTE.EDIT.TITLE',
      })
    })
    .state('eav-attribute.new', {
      url: '/new',
      breadcrumb: { label: 'PAGE.EAV_ATTRIBUTE.NEW.TITLE' },
      views: {
        'page@layout': {
          template: '<app-eav-attribute-form></app-eav-attribute-form>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.EAV_ATTRIBUTE.NEW.TITLE',
      })
    })
  ;
}
