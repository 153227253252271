'use strict'

###
  @name SwitchCmp
  @doc https://github.com/frapontillo/angular-bootstrap-switch

  @example:
    <example>
      <switch model="item.active" change="single.onChange()" readonly />
    </example>
###
angular
  .module('ammsFrontendApp')
  .directive('switch', [ ->
    replace: true,
    restrict: 'E',
    templateUrl: require('views/default/switch.html'),
    controller: 'SwitchCtrl',
    controllerAs: 'switch',
    bindToController:
      model: '=',
      change: '&',
      onText: '@',
      offText: '@',
      readonly: '='
    scope: true,
    compile: (elm, attr) ->
      return {
        pre: (pScope, pElement, pAttrs, controller) ->
          if (undefined == pAttrs.change)
            controller.change = -> undefined

          if (undefined == pAttrs.readonly)
            controller.isReadonly = false
          else
            controller.isReadonly = '' == pAttrs.readonly || controller.readonly

          if (undefined == controller.onText)
            controller.onText = 'YES'

          if (undefined == controller.offText)
            controller.offText = 'NO'
      }
  ])
  .controller('SwitchCtrl', [
    '$translate',
    ($translate) ->
      ###
        Translates key for a specific domain

        @param {string} key
        @param {string} [domain]

        @returns {string}
      ###
      this.translate = (key, domain = 'BUTTON') ->
        $translate.instant(domain + '.' + key)

      return this
  ])
