export class NumberHelper {

  /**
   * Parse to float any type of var
   *
   * @static
   * @param {any} valueToParse
   * @returns number
   * @memberof NumberHelper
   */
  public static parseFloat(valueToParse: any): number {
    if (typeof valueToParse === 'string') {
      valueToParse = valueToParse.replace(/,/, '.');
    }

    return parseFloat(valueToParse);
  }
}
