'use strict'

angular
  .module('ammsFrontendApp')
  .directive('ignoreMouseWheel', [
    ->
      restrict: 'A'
      link: (scope, element) ->
        element.on('mousewheel', (event) ->
          event.preventDefault()
        )
        scope.$on('$destroy', ->
          element.off('mousewheel')
        )
  ])
