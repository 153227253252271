import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractComponent } from '@components/generic/abstract.component';
import { SessionHelper } from '@helpers/session.helper';
import { CMS, HOMEPAGE, PRODUCT, SHELF, TYPES, UNIVERSE } from '@components/breadcrumb/breadcrumb.constant';
import { ICrumb } from '@components/breadcrumb/breadcrumb.interface';

@Component({
  selector: 'app-breadcrumb-item',
  template: require('./breadcrumb-item.component.html'),
})
export class BreadcrumbItemComponent extends AbstractComponent implements OnInit {

  @Input() public crumb: ICrumb;
  @Input() public universes: any;
  @Input() public shelves: any;
  @Input() public contents: any;

  public types: any = [];
  public currentCountryCode: string = SessionHelper.getCountry().code;

  @Output() public onChange: EventEmitter<any> = new EventEmitter();
  @Output() public onDelete: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, null, state);
  }

  ngOnInit(): void {
    TYPES.forEach((type: string) => this.types.push({ code: type, label: this.translate(`PAGE.CMS.FORM.CRUMB.${type.toUpperCase()}`) }));
  }

  public dropdownChange(event: any): void {
    this.crumb.value = event.value;
    this.onChange.emit(this.crumb);
  }

  public skuChange(event: any): void {
    this.crumb.value = event[0].sku;
    this.onChange.emit(this.crumb);
  }

  public reset(event: any): void {
    this.crumb.type = event.code;
    this.crumb.value = null;
    this.onChange.emit(this.crumb);
  }

  public delete(): void {
    this.crumb = null;
    this.onDelete.emit();
  }

  public hasValue(): boolean {
    return this.crumb.type !== null && !this.isHomepage();
  }

  public isHomepage(): boolean {
    return this.crumb.type === HOMEPAGE;
  }

  public isUniverse(): boolean {
    return this.crumb.type === UNIVERSE;
  }

  public isShelf(): boolean {
    return this.crumb.type === SHELF;
  }

  public isProduct(): boolean {
    return this.crumb.type === PRODUCT;
  }

  public isCms(): boolean {
    return this.crumb.type === CMS;
  }
}
