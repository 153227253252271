'use strict'

import { SessionHelper } from '@helpers'

angular
  .module 'ammsFrontendApp'
  .filter 'stockByWarehouse', [ ->
    (product, stockType, warehouseSelected) ->

      if !product.stocks
        return

      warehouses = SessionHelper.getWarehouses()
      abbr = ''

      if warehouses && 0 != warehouses.length
        stocks = product.stocks
          .filter (stock) ->
            warehouse = warehouses.find((warehouse) -> return warehouse.code == stock.warehouse)
            warehouse.stockOrigin == stock.origin && 0 != stock.quantity && stock.type == stockType
          .map (stock, index) ->
            warehouse = warehouses.find((warehouse) -> return warehouse.code == stock.warehouse)

            if warehouse
              if 0 < index
                abbr += "<br>"

              abbr += if warehouseSelected == warehouse.code then '<strong>' else ''
              abbr += "#{ warehouse.name } : #{ stock.quantity }<br>"
              abbr += if warehouseSelected == warehouse.code then '</strong>' else ''

        if (0 == stocks.length)
          abbr = '0'

      abbr
  ]
