import { IPackage } from '../../../models/IPackage';

export class ManualPackageModel implements IPackage {
  public width: number;
  public height: number;
  public length: number;
  public number: number;
  public weight: number;

  constructor(model?: IPackage) {
    this.width = model ? model.width : 0;
    this.height = model ? model.height : 0;
    this.length = model ? model.length : 0;
    this.weight = model ? model.weight : 0;
  }
}
