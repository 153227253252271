import { Component, Inject, Input } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-commercial-operations-block-result-list',
  template: require('./commercial-operations-block-result-list.component.html'),
})
export class CommercialOperationsBlockResultListComponent extends AbstractComponent {
  @Input() public commercialOperation: any;
  @Input() public locale: string;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, null, state);
  }
}
