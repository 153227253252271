import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import {
  IFormGeneralBody,
  IFormGeneralValue,
  IProductForm,
} from '@components/product/interfaces/product-form.interface';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { FormNotifierService } from '@services/form-notifier.service';
import { IButtonLinks } from '@components/generic/buttons/button-links.interface';
import { AbstractComponent } from '@components/generic/abstract.component';
import { MasterProductResource } from '@resources/master-product.resource';
import { HydraHelper } from '@helpers/HydraHelper';
import { buildVintageDates } from '@helpers/VintageHelper';
import { ICountry } from '@interfaces';
import { IWarehouses } from '@components/warehouses/models';
import { SuperProductCategoriesResource } from '@components/super-product-categories/super-product-categories.resource';
import {AbstractResource, PackageResource} from '@resources';
import {ProductResource} from '@components/product/product.resource';
import {AuthService} from '@services';
import { WarehousesResource } from '../../../../../components/warehouses/warehouses.resource';
import { SuperProductResource } from '../../../../../components/super-product/super-product.resource';
import { WarrantyResource } from '../../../../../components/warranty/resources/warranty.resource';
import { SessionHelper } from '../../../../../helpers/session.helper';
import { ManufacturerResource } from '../../../../../resources/manufacturer.resource';
import { SnackbarService } from '../../../../../components/snackbar/snackbar.service';
import { ProductProductFormComponent } from '../../tab-product/product-product-form.component';
/**
 * Handle ProductGeneralForm.
 */
@Component({
  selector: 'app-spare-part-product-general-form',
  template: require('./spare-part-product-general-form.component.html'),
  providers: [
    { provide: AbstractResource, useClass: ProductResource },
    SuperProductCategoriesResource,
  ],
  styles: [require('./spare-part-product-general-form.component.scss')],
})
export class SparePartProductGeneralFormComponent extends AbstractComponent implements OnInit {

  public objectKeys: (o: {}) => string[] = Object.keys;

  public form: FormGroup;

  public superProductsSkus$: Observable<object>;
  public warranties$: Observable<object>;
  public manufacturers$: Observable<object>;
  public displayWarranty: boolean;
  public pathSuperProductList: string;
  public buttonLinks: IButtonLinks[];
  public warehouses: any[] = [];
  public vintageSkus: any[] = [];
  public vintages: any[] = [];
  public vintagePossibilitiesDistinctSkus: any = [];
  public vintagePossibilitiesFilterBySku: any[];
  public newVintage: any;
  public newVintageList: any;
  public categories: any[] = [];
  public availableVintageDates: any[];
  public showSkus: boolean = false;
  public showVintage: boolean = false;
  public openedSkus: any = [];

  @Input() model: IProductForm;

  @ViewChild(ProductProductFormComponent) productProductForm: ProductProductFormComponent;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    private superProductResource: SuperProductResource,
    private warrantyResource: WarrantyResource,
    private manufacturerResource: ManufacturerResource,
    private warehouseResource: WarehousesResource,
    private snackbar: SnackbarService,
    private formNotifier: FormNotifierService,
    @Inject('DialogService') private dialog: any,
  ) {
    super($translate, authService, resource, state);
  }


  /**
   * @inheritDoc
   */
  ngOnInit(): void {
    this.displayWarranty = true;
    this.pathSuperProductList = this.state.href('super-product.list', {}, { absolute: true });
    this.fetch();
    this.buildForm();
    this.fillForm();
    this.fetchVintages();
    this.buildVintageDates();
    this.showSkus = true;
    this.showVintage = false;
  }

  private openSku(sku: any) {
    const index = this.openedSkus.indexOf(sku);
    if (index !== -1) {
      this.openedSkus.splice(index, 1);
    } else {
      this.openedSkus.push(sku);
    }
  }

  private isOpened(sku: any) {
    return this.openedSkus.indexOf(sku) !== -1;
  }
  private fetch() {
    const options: any = {
      isHydra: true,
      returnHydraMembers: true,
      dontUseModel: true,
      blocking: false
    };

    this.warehouseResource.cGet(
      {
        'acceptSparePart': true,
        'carriers.country.code[]': SessionHelper.getCountries().map((country: ICountry) => country.code),
        'active': true,
        'pagination': false,
      },
      options
    )
      .takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        this.warehouses = response.map((warehouse: IWarehouses): any => {
          return {
            iri: warehouse['@id'],
            code: warehouse.code,
            name: warehouse.name,
          };
        });
      })
      ;
  }

  private buildVintageDates() {
    this.availableVintageDates = buildVintageDates();
  }

  private buildForm(): void {
    const currentMarketplace = SessionHelper.getCurrentWebsiteMarketplace()['code'];
    const currentLocale = SessionHelper.getLocale();
    delete this.model.productMarketplaces[''];
    let basePrice = null;
    if (this.model && this.model.productMarketplaces[currentMarketplace]) {
      basePrice = this.model.productMarketplaces[currentMarketplace].basePrice;
    }
    this.form = this.formBuilder.group({
      sku: [''],
      ean: [''],
      superProduct: [{ label: '', id: undefined }],
      manufacturerOverrideContent: [false],
      manufacturers: [this.model ? this.model.manufacturers : [{ label: '', id: undefined }]],
      afterSalesService: [''],
      notifyReviewerOverrideContent: [false],
      notifyReviewers: [false],
      hidden: [false],
      reference_id: [this.model ? this.getSkuReference(this.model.sku) : null],
      basePrice: [basePrice ? basePrice : null],
      shortTitle: [this.model && this.model.translations[currentLocale] ? this.model.translations[currentLocale]['shortTitle'] : null],
      superProductAgTitle: [''],
      warehouse: null,
    });

    this.superProductsSkus$ = this.superProductResource.getSkus({ locale: this.currentLocale }, { blocking: false });
    this.manufacturers$ = this.manufacturerResource.getMany(undefined, { blocking: false });

    if (this.displayWarranty) {
      this.form.addControl('warrantyOverrideContent', this.formBuilder.control(['']));
      this.form.addControl('warranty', this.formBuilder.control(['']));

      this.warranties$ = this.warrantyResource.getMany({ locale: SessionHelper.getUILanguage() }, { blocking: false });
    }
  }

  public getSkuReference(sku: string): string {
    return sku.replace(/^SPAREPART_/, '');
  }

  private fillForm(): void {
    if (this.model) {
      this.form.patchValue(this.model);
    }
  }

  public submitEdit(event?: any): void {

    this.dialog.confirm(this.translate('PAGE.PRODUCT.CONFIRM.UPDATE'))
    .then(() => {
        this.productProductForm.submit( {
          doNotNotify: true,
        });

        const body: IFormGeneralBody = this.prepareBody();
        const id: string = this.state.params.id;

        this.resource.update(id, body, { entryPoint: `/v2/products/${id}` })
          .takeUntil(this.destroyed$)
          .subscribe(() => {

            this.resource.putVintagesFromMasterProductId(this.model.id, this.vintages)
                .takeUntil(this.destroyed$)
                .subscribe();

            this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));

            if (event && event.redirect) {
               if (this.state.current.name === 'spare-part.edit') {
                 this.state.go('spare-part.list');
               }
              return;
            }

            this.formNotifier.notifyFormSubmitted();

            if (this.state.current.name === 'spare-part.edit') {
              this.state.go('spare-part.edit', { id });
            } else {
              this.state.go(`${this.resource.routeName}.edit`, { id });
            }
          })
          ;
      })
      ;
  }

  public submitNew(event?: any): void {
    this.dialog.confirm(this.translate('PAGE.PRODUCT.CONFIRM.UPDATE')).then(() => {

      const detached = this.state.current.name === 'spare-part.new';

      if (!detached) {
        this.superProductsSkus$.subscribe((x: any[]) => {
          const obj = x.find(o => o.id === String(this.form.value.superProduct.id));
          if (obj) {
            this.snackbar.warn('SAME SKU');
          }
        });
      }

      const body = {
        sku: this.form.value.sku,
        superProductAgTitle: this.form.value.superProductAgTitle,
        country: HydraHelper.buildIri(SessionHelper.getCountry().id, 'countries'),
        detached: detached,
        defaultWarehouse: this.form.value.warehouse,
      };

      this.resource.create(body, { entryPoint: '/v2/products' }).takeUntil(this.destroyed$).subscribe((response: any) => {
        this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
        this.formNotifier.notifyFormSubmitted();

        if (detached) {
          this.state.go('spare-part.edit', { id: response.id });
        } else {
          this.state.go(`${this.resource.routeName}.edit`, { id: response.id });
        }
      });
    });
  }

  private prepareBody(): IFormGeneralBody {
    const formValue: IFormGeneralValue = this.form.value;
    const locale = SessionHelper.getLocale();
    const marketplace = SessionHelper.getCurrentWebsiteMarketplace()['code'];
    const translations: any = this.model.translations ? this.model.translations : {};
    const productMarketplaces: any = this.model.productMarketplaces ? this.model.productMarketplaces : {};

    translations[locale] = translations[locale] ? translations[locale] : {};
    productMarketplaces[marketplace] = productMarketplaces[marketplace] ? productMarketplaces[marketplace] : {};

    translations[locale] = {
      ...translations[locale],
      shortTitle: formValue.shortTitle,
      locale: locale
    };
    productMarketplaces[marketplace] = {
      ...productMarketplaces[marketplace],
      basePrice: +formValue.basePrice,
      marketplace: marketplace
    };
    return {
      hidden: formValue.hidden,
      notifyReviewerOverrideContent: formValue.notifyReviewerOverrideContent,
      notifyReviewers: formValue.notifyReviewers,
      manufacturerOverrideContent: true,
      manufacturers: formValue.manufacturers ? formValue.manufacturers.map((e: any) => HydraHelper.buildIri(e.id, 'manufacturers')) : null,
      afterSalesService: formValue.afterSalesService || null,
      warrantyOverrideContent: formValue.warrantyOverrideContent,
      warranty: formValue.warranty ? `/api/v2/warranties/${formValue.warranty.id}` : null,
      superProduct: formValue.superProduct ? `/api/v2/super_products/${formValue.superProduct.id}` : null,
      translations: translations,
      productMarketplaces: productMarketplaces,
    };
  }

  public cancelForm(): void {
    this.dialog.confirm(this.translate('DIALOG.TEXT.DONT_SAVE'))
      .then(() => {
        if (this.state.current.name === 'spare-part.edit.cross-up-sells') {
          this.state.go('spare-part.list');
        } else {
          this.state.go('product.list');
        }
      })
      ;
  }

  public fetchVintages() {
    this.resource.getVintagesFromMasterProductId(this.model.masterProduct.id).takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        for (const vintage of response.vintages) {
          this.vintages.push(vintage);
        }

        this.vintages.sort(function (a, b) {
          return a.vintage - b.vintage;
        });

        this.vintages.sort(function (a, b) {
          if (a.sku < b.sku) {
            return -1;
          }
          if (a.sku > b.sku) {
            return 1;
          }
          return 0;
        });

        this.vintageSkus = this.vintages.map(item => item.sku)
          .filter((value, index, self) => self.indexOf(value) === index);

      });
  }

  public onFilterSku(event: string) {
      this.resource.getVintagePossibilites(event).takeUntil(this.destroyed$)
        .subscribe((response: any) => {
          this.vintagePossibilitiesDistinctSkus = [];

          for (const iterator of Object.keys(response)) {
            this.vintagePossibilitiesDistinctSkus.push({
              'sku': iterator
            });
          }
        }
        );
  }

  public onSelectSku(event: any) {
    this.vintagePossibilitiesFilterBySku = [];
    this.newVintageList = event;
    this.showVintage = true;
  }

  public onSelectVintage(event: any) {
    this.newVintage = [];
    this.newVintageList.forEach((item: any) => {
      this.newVintage.push({
        sku: item.sku,
        vintage: event,
        type: 'vintage_master_product'
      });
    });

  }


  public addVintage() {
    this.newVintage.forEach((newVintageItem: any) => {
      newVintageItem.vintage.forEach((vintage: any) => {
        if (this.vintages.find(e => e.sku === newVintageItem.sku && e.vintage === vintage)) {
          return;
        }
        this.vintages.push({
          sku: newVintageItem.sku,
          vintage: vintage,
          type: 'vintage_master_product'
        });
      }
      );
    });

    this.vintages.sort(function (a, b) {
      return a.vintage - b.vintage;
    });

    this.vintages.sort(function (a, b) {
      if (a.sku < b.sku) {
        return -1;
      }
      if (a.sku > b.sku) {
        return 1;
      }
      return 0;
    });

  }

  public onDeleteVintage(vintage: any) {
    const index = this.vintages.indexOf(vintage);
    if (index > -1) {
      this.vintages.splice(index, 1);
    }
  }
  private mapVintage(array: any) {
    return array.map((item: any) => item.vintage).join(', ');
  }

  public getFirstImage() {
    // tslint:disable-next-line:max-line-length
    return this.model.masterProduct.marketplaceImages && this.model.masterProduct.marketplaceImages[0] && this.model.masterProduct.marketplaceImages[0].webPath  ? this.model.masterProduct.marketplaceImages[0].webPath : null;
  }
}
