import { Component, Inject, OnInit } from '@angular/core';
import { CountryHelper, SessionHelper } from '@helpers';
import { AuthService } from '@services';
import { RedirectsModel } from '@components/redirects/models';
import { RedirectsResource } from '@components/redirects/redirects.resource';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AbstractFormComponent } from '@components/generic/Form/abstract-form.component';
import { IFormViolation } from '@interfaces';
import { DATE_FULL_FORMAT } from '@constants';
import * as moment from 'moment';
import { Subscription } from 'rxjs/Subscription';
import { SnackbarService } from '@components';

@Component({
  selector: 'app-redirects',
  template: require('./redirects.component.html'),
})

export class RedirectsComponent extends AbstractFormComponent implements OnInit {

  public inCreation: boolean;
  public model: RedirectsModel;
  public form: FormGroup;
  public locales: string[];
  public violations: IFormViolation[]|any = [];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: RedirectsResource,
    @Inject('StateService') state: ng.ui.IStateService,
    @Inject('DialogService') private dialog: any,
    private snackbar: SnackbarService,
    ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    this.inCreation = !this.state.params.id;
    this.locales = CountryHelper.getLocales([SessionHelper.getCountry()]);
    if (!this.inCreation) {
      this.fetch();
      return;
    }
    this.model = new RedirectsModel();
    this.createForm();
  }

  public fetch() {
    const subscriber: Subscription = this.resource.get(this.state.params.id)
      .subscribe(
        (response: RedirectsModel) => {
          this.model = response;
          this.createForm();
        },
        undefined,
        () => subscriber.unsubscribe()
      );
  }

  public createForm() {
    this.form = new FormGroup({
      locale: new FormControl(this.model.locale ? this.model.locale : SessionHelper.getLocale(), Validators.required),
      fromRoute: new FormControl(this.model.fromRoute, Validators.required),
      toRoute: new FormControl(this.model.toRoute, Validators.required),
    });
  }

  public submit(returnToList: boolean) {
    while (this.violations.length) { this.violations.pop(); }

    if (!this.form.dirty || !this.form.valid) {
      let errorMessage = 'ALERTS.NO_CHANGE.FORM';

      if (!this.form.valid) {
        errorMessage = 'ALERTS.ERROR.FORM';
      }

      this.snackbar.warn(this.translate(errorMessage));
      return;
    }

    const body = this.prepareQuery(this.form);
    !this.inCreation
      ? this.update(body, returnToList)
      : this.save(body, returnToList);
  }

  public save(body: any, returnToList: boolean) {
    const subscriber: Subscription = this.resource.create(body)
      .subscribe(
        (response: any) => {
          this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
          returnToList
            ? this.state.go(`${this.resource.routeName}.list`)
            : this.state.go(`${this.resource.routeName}.edit`, { id: response.id });
        },
        (reject: any) => {
          this.violations = this.formatViolations(reject);
        },
        () => subscriber.unsubscribe()
      );
  }

  public update(body: any, returnToList: boolean) {
    const subscriber: Subscription = this.resource.update(this.state.params.id, body)
      .subscribe(
        (response: any) => {
          this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
          returnToList
            ? this.actions.list.go()
            : this.actions.update.go(
              { id: response.id },
              { reload: true }
            )
          ;
        },
        (reject: any) => {
          this.violations = this.formatViolations(reject);
        },
        () => subscriber.unsubscribe()
      );
  }

  private prepareQuery(redirectionForm: FormGroup): any {
    return {
      fromRoute: redirectionForm.value.fromRoute,
      toRoute: redirectionForm.value.toRoute,
      createdBy: {
        username: SessionHelper.get('CURRENT_USERNAME'),
      },
      automatic: false,
      updatedAt: moment().format(DATE_FULL_FORMAT),
      locale: redirectionForm.value.locale,
    };
  }

  public cancel() {
    this.dialog.confirm(this.translate('PAGE.REDIRECTS.CONFIRM.BACK_TO_LIST'))
      .then(() => this.actions.list.go());
  }

  private formatViolations(reject: any): IFormViolation[] {
    if (!reject.hasOwnProperty('violations')) {
      return;
    }

    return reject.violations.map((violation: IFormViolation): IFormViolation => {
      return {
        propertyPath: violation.propertyPath,
        message: violation.message
      };
    });
  }

  public delete(): void {
    this.dialog.confirm(this.translate('PAGE.REDIRECTS.CONFIRM.DELETE'))
      .then(() => {
        this.resource.remove(this.state.params.id)
          .takeUntil(this.destroyed$)
          .subscribe(() => this.actions.list.go())
        ;
      })
    ;
  }
}
