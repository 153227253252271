export default discountRoutes;

/** @ngInject */
function discountRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'website',
    sidebarSubNav: 'discount',
    title: '',
    reloadChangeCountry: 'discount.list',
  };

  $stateProvider
    .state('discount', {
      url: '/discount',
      parent: 'walipush',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.DISCOUNT.GENERAL',
        stateName: 'discount.list'
      }
    })
    .state('discount.list', {
      url: `?
        name
        &discountCode
        &dateBegin
        &dateEnd
        &customers.id
        &customers.username
        &type.type
        &discountStatus
        &page
        &byPage
        &archived
        &country.code[]
      `,
      views: {
        'page@layout': {
          template: '<app-discount-list></app-discount-list>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.DISCOUNT.LIST.TITLE',
      })
    })
    .state('discount.new', {
      url: '/discount/new',
      params: {
        type: null
      },
      breadcrumb: { label: 'BREADCRUMB.NEW', stateName: 'discount.new' },
      views: {
        'page@layout': { template: '<app-discount-form></app-discount-form>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.DISCOUNT.NEW.TITLE',
        reloadChangeCountry: 'discount.new',
        formTab: 'general',
        tabs: [
          {
            label: 'PAGE.DISCOUNT.FORM.TAB.GENERAL',
            state: 'discount.new',
          },
          {
            label: 'PAGE.DISCOUNT.FORM.TAB.RESTRICTION',
          },
          {
            label: 'PAGE.DISCOUNT.FORM.TAB.LIMIT',
          },
        ],
      })
    })
    .state('discount.edit', {
      url: '/:id',
      breadcrumb: { label: 'BREADCRUMB.EDIT', stateName: 'discount.edit' },
      views: {
        'page@layout': { template: '<app-discount-form></app-discount-form>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.DISCOUNT.EDIT.TITLE',
        reloadChangeCountry: 'discount.edit',
        reloadChangeCountrySearchParam: 'id',
        formTab: 'general',
        tabs: [
          {
            label: 'PAGE.DISCOUNT.FORM.TAB.GENERAL',
            state: 'discount.edit',
          },
          {
            label: 'PAGE.DISCOUNT.FORM.TAB.RESTRICTION',
            state: 'discount.edit.restrictions',
          },
          {
            label: 'PAGE.DISCOUNT.FORM.TAB.LIMIT',
            state: 'discount.edit.limits',
          },
          {
            label: 'PAGE.DISCOUNT.FORM.TAB.PRODUCTS',
            state: 'discount.edit.products',
          },
        ],
      })
    })
    .state('discount.edit.restrictions', {
      url: '/restrictions',
      breadcrumb: {label: 'BREADCRUMB.DISCOUNT.RESTRICTIONS', stateName: 'discount.edit.restrictions'},
      views: {
        'page@layout': {template: '<app-discount-form></app-discount-form>'}
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.DISCOUNT.EDIT.TITLE',
        reloadChangeCountry: 'discount.list',
        formTab: 'restrictions',
        tabs: [
          {
            label: 'PAGE.DISCOUNT.FORM.TAB.GENERAL',
            state: 'discount.edit',
          },
          {
            label: 'PAGE.DISCOUNT.FORM.TAB.RESTRICTION',
            state: 'discount.edit.restrictions',
          },
          {
            label: 'PAGE.DISCOUNT.FORM.TAB.LIMIT',
            state: 'discount.edit.limits',
          },
          {
            label: 'PAGE.DISCOUNT.FORM.TAB.PRODUCTS',
            state: 'discount.edit.products',
          },
        ],
      })
    })
    .state('discount.edit.limits', {
      url: '/limits',
      breadcrumb: {label: 'BREADCRUMB.DISCOUNT.LIMITS', stateName: 'discount.edit.limits'},
      views: {
        'page@layout': {template: '<app-discount-form></app-discount-form>'}
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.DISCOUNT.EDIT.TITLE',
        reloadChangeCountry: 'discount.list',
        formTab: 'limits',
        tabs: [
          {
            label: 'PAGE.DISCOUNT.FORM.TAB.GENERAL',
            state: 'discount.edit',
          },
          {
            label: 'PAGE.DISCOUNT.FORM.TAB.RESTRICTION',
            state: 'discount.edit.restrictions',
          },
          {
            label: 'PAGE.DISCOUNT.FORM.TAB.LIMIT',
            state: 'discount.edit.limits',
          },
          {
            label: 'PAGE.DISCOUNT.FORM.TAB.PRODUCTS',
            state: 'discount.edit.products',
          },
        ],
      })
    })
    .state('discount.edit.products', {
      url: '/products',
      breadcrumb: {label: 'BREADCRUMB.DISCOUNT.PRODUCTS', stateName: 'discount.edit.products'},
      views: {
        'page@layout': {template: '<app-discount-form></app-discount-form>'}
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.DISCOUNT.EDIT.TITLE',
        reloadChangeCountry: 'discount.list',
        formTab: 'products',
        tabs: [
          {
            label: 'PAGE.DISCOUNT.FORM.TAB.GENERAL',
            state: 'discount.edit',
          },
          {
            label: 'PAGE.DISCOUNT.FORM.TAB.RESTRICTION',
            state: 'discount.edit.restrictions',
          },
          {
            label: 'PAGE.DISCOUNT.FORM.TAB.LIMIT',
            state: 'discount.edit.limits',
          },
          {
            label: 'PAGE.DISCOUNT.FORM.TAB.PRODUCTS',
            state: 'discount.edit.products',
          },
        ],
      })
    })
  ;
}
