'use strict'

import { SessionHelper, MarketplaceHelper, ProductHelper } from '@helpers'
import { BASE_URL } from '../../../constants'

###
  @name ProductListCmp
###
angular
  .module('ammsFrontendApp')
  .controller('ProductListCtrl', [
    '$stateParams',
    'ProductChecker',
    'ListFilter',
    'ProductResource',
    (
      $stateParams,
      ProductChecker,
      ListFilter,
      ProductResource,
    ) ->
      this.displayList = false
      currentCountry = SessionHelper.getCountry()
      this.marketplaces = MarketplaceHelper.getDisplayedOnProductPageMarketplaces()
      currentLocale = SessionHelper.getLocale()
      this.defaultFilters =
        byPage: 30
        currentTab: 0
        direction: ''
        orderBy: ''
        page: 1
        catalog: undefined
        superProduct: undefined
        sku: undefined
        reference: undefined
        'marketplaces[]': []
        hidden: '0'
        hasLogistic: undefined
        packageType: 'all'
        status: undefined
        stockMin: undefined
        stockMax: undefined
      this.listFilter = new ListFilter(this.defaultFilters)
      this.filters = this.listFilter.extractUrl()
      this.bulkEditorSelectItems = {}
      this.bulkEditorActive = false
      this.showPagination = false

      # Filter's management
      this.applyFilters = =>
        this.filters.page = this.defaultFilters['page']
        this.listFilter.updateUrl(this.filters, true)

      ###
        @param {object} filters
      ###
      getProducts = (filters = {}) =>
        if (!ProductHelper.hasNonEmptyFilters($stateParams))
          this.displayList = false
        else
          filters['countryCode'] = currentCountry.code

          subscriber = ProductResource.getMany(filters)
            .subscribe(
              (data) =>
                this.displayList = true
                this.totalProducts = data.total
                this.numberOfPages = data.pages
                this.showPagination = this.numberOfPages > 1
                this.products = data.products.map((product) ->
                  productChecker = new ProductChecker(product, MarketplaceHelper.getDisplayedOnProductPageMarketplaces(), currentCountry.vat)
                  productChecker.populate()

                  if ('undefined' != typeof product.superProduct)
                    if (
                      'undefined' != typeof product.superProduct.translations &&
                      'undefined' != typeof product.superProduct.translations[currentLocale] &&
                      'undefined' != typeof product.superProduct.translations[currentLocale].reference
                    )
                      product.superProduct.reference = product.superProduct.translations[currentLocale].reference
                    else
                      product.superProduct.reference = ''
                    product.superProduct = { skuParent: product.superProduct.skuParent , reference: product.superProduct.reference, catalog: product.superProduct.catalog }

                  return product
                )
              , null
              , -> subscriber.unsubscribe()
            )
      getProducts(this.filters)

      ###
        @param {string} tab id

        @returns boolean
      ###
      this.isTabActive = (id) =>
        return this.filters.currentTab == id

      ###
        Checks if the product marketplace is part of an offer

        @param {object} productMarketplace

        @returns boolean
      ###
      this.isOffer = (productMarketplace) ->
        return _.size(productMarketplace.currentOffer) != 0

      return this
  ])
