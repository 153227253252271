import { Pipe, PipeTransform } from '@angular/core';
import { StringHelper } from '@helpers/StringHelper';

@Pipe({
  name: 'countChar',
})
export class CountCharPipe implements PipeTransform {

    transform(value: string|string[], encoded: boolean = false): number {
      return StringHelper.countChar(value, encoded);
    }
}
