import { Component, Inject, Input } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services/auth.service';

/**
 * Wrap template in a `porlet` css style.
 */
@Component({
  selector: 'app-card',
  template: require('./card.component.html'),
})
export class CardComponent extends AbstractComponent {

  @Input() public greyHeader: boolean;
  @Input() public redHeader: boolean;
  @Input() public blueHeader: boolean;
  @Input() public greenHeader: boolean;
  @Input() public yellowHeader: boolean;
  @Input() public collapse: boolean = false;
  @Input() public header: string;
  @Input() public formType: string;
  @Input() public param: any;
  @Input() public expanded: boolean = true;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, null, state);
  }

  public expand() {
    this.expanded = !this.expanded;
  }

}
