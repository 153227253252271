'use strict'

###
  @name ProductTabECommerceCmp

  @description
  Component tab for product module.
  Displays list of products with e-commerce purpose.

  @example
    <example>
      <div product-tab-e-commerce></div>
    </example>

###
angular
  .module('ammsFrontendApp')
  .directive('productTabECommerce', [
    'ProductBulkEditorService',
    'ProductRowECommerceService',
    (ProductBulkEditorService, ProductRowECommerceService) ->
      restrict: 'A'
      templateUrl: require('views/products/list/tab-e-commerce.html')
      controller: 'ProductTabECommerceCtrl'
      controllerAs: 'tab'
      bindToController:
        products: '='
      scope: true
      link: (scope, elem, attrs, tab) ->
        scope.$on(ProductBulkEditorService.REFRESHED_BULK_EDITOR, tab.checkAllStatus)
        scope.$on(ProductRowECommerceService.INLINE_PRODUCTION_EDITION_START, -> tab.changeBulkEditorStatus(false))
        scope.$on(ProductRowECommerceService.INLINE_PRODUCTION_EDITION_STOP, -> tab.changeBulkEditorStatus(true))
  ])
  .controller('ProductTabECommerceCtrl', [
    'ProductBulkEditorService',
    (ProductBulkEditorService) ->
      this.bulkEditorSelectAll = false
      this.bulkEditorSelectable = true

      this.refreshBulkEditor = ProductBulkEditorService.refreshBulkEditor

      this.floatTheadOptions = ProductBulkEditorService.floatTheadOptions

      this.changeBulkEditorStatus = (status) =>
        this.bulkEditorSelectable = status

      ###
        Selects/deselects all products for bulk editor
      ###
      this.toggleAllForBulk = =>
        this.products.forEach((product) =>
          if (this.bulkEditorSelectAll)
            product.selected = true
          else
            delete product.selected
        )

        this.refreshBulkEditor()

      ###
        Checks if all products are selected or not

        @param {object} event
        @param {array}  selection - selected sku
      ###
      this.checkAllStatus = (event, selection) =>
        this.bulkEditorSelectAll = selection.selection.length == this.products.length

      return this
  ])
