import { AbstractResource } from '../../../resources/abstract.resource';
import { Injectable } from '@angular/core';
import { Resource } from '../../../decorators/Resource';
import { HttpClient } from '@angular/common/http';
import { AddressingListModel } from '../models';
import { Observable } from 'rxjs/Observable';

@Injectable()
@Resource({
  routeName: 'addressings',
  entryPoint: '/v2/addressings/list',
  translationKey: 'ADDRESSING',
  listModel: AddressingListModel,
  listColumns: [
    { field: 'addressingBay', translationKey: 'ADDRESSING_BAY', sortable: true },
    { field: 'addressingLane', translationKey: 'ADDRESSING_LANE', sortable: true },
    { field: 'addressingLocation', translationKey: 'ADDRESSING_LOCATION', sortable: true },
    { field: 'addressingLevel', translationKey: 'ADDRESSING_LEVEL', sortable: true },
    { field: 'addressingType', translationKey: 'ADDRESSING_TYPE', sortable: true },
    { field: 'quantity', translationKey: 'QUANTITY', sortable: true },
    { field: 'warehouseName', translationKey: 'WAREHOUSE', sortable: true },
    { field: 'barcodeImage', translationKey: 'BARCODE', type: 'image', sortable: true },
  ],
  createAvailable: false,
  updateAvailable: false,
  deleteAvailable: false,
  isHydra: true,
  hasMassiveActions: true,
})
export class AddressingListResource extends AbstractResource {
  constructor(http: HttpClient) {
    super(http);
  }

  public cGet(params: any = {}, options: any = {}): Observable<object> {
    params['order[addressingBay]'] = params.sort_addressingBay;
    params['order[addressingLane]'] = params.sort_addressingLane;
    params['order[addressingLocation]'] = params.sort_addressingLocation;
    params['order[addressingLevel]'] = params.sort_addressingLevel;
    params['order[quantity]'] = params.sort_quantity;
    params['order[addressingType]'] = params.sort_addressingType;
    params['order[warehouseName]'] = params.sort_warehouseName;
    params['order[barcode.value]'] = params.sort_barcodeImage;
    params['exists[barcode]'] = params.exists_barcode;
    params['order[filter_type]'] = params.filter_type;

    const cleanParams = Object.keys(params)
      .filter((key) => !key.startsWith('sort_') && !key.startsWith('exists_'))
      .filter((key) => typeof params[key] !== 'undefined')
      .reduce((obj, key) => {
        return Object.assign(obj, { [key]: params[key] });
      }, {});

    return super.cGet(cleanParams, options);
  }
}
