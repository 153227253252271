import { Inject, Injectable } from '@angular/core';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { AbstractFormFieldBase } from '@components/generic/Form/dynamic/fields/abstract-form-field-base.class';
import { DateField, MultiSearchField, SkuSearchField, TextField, ComboSearchField } from '@components/generic/Form/dynamic/fields';
import { FormNotifierService } from '@services';
import { SessionHelper } from '@helpers';
import { IDropdownData } from '@interfaces/IDropdownData';
import moment = require('moment');
import { DATE_FULL_FORMAT } from '@constants';

@Injectable()
export class OrderManagerLightFiltersFormService extends AbstractFiltersFieldsService {


  constructor(
    @Inject('StateService') protected state: ng.ui.IStateService,
    @Inject('TranslationService') public translate: ng.translate.ITranslateService,
    formNotifier: FormNotifierService
  ) {
    super(formNotifier, state);
  }
  private dateStartField: TextField = new TextField({
    fieldName: 'dateStart',
    value: null,
    hidden: true
  });

  private dateEndField: TextField = new TextField({
    fieldName: 'dateEnd',
    value: null,
    hidden: true
  });

  private static setDates(newValue: string, startField: TextField, endField: TextField): void {
    if (!newValue || null === newValue[0]) {
      return;
    }

    startField.value = moment(newValue[0]).startOf('day').format(DATE_FULL_FORMAT);
    endField.value = moment(newValue[1]).endOf('day').format(DATE_FULL_FORMAT);
  }

  public getFields(): AbstractFormFieldBase<any>[] {
    this.dateStartField.value = this.filters.get('dateStart') ? this.filters.get('dateStart') : undefined;
    this.dateEndField.value = this.filters.get('dateEnd') ? this.filters.get('dateEnd') : undefined;

    return [
      new TextField({
        fieldName: 'search',
        label: 'PAGE.ORDER_MANAGER.LIST.FILTER.SEARCH',
        value: this.filters.get('search') ? this.filters.get('search') : undefined,
      }),
      new MultiSearchField({
        fieldName: 'orderId[]',
        data: [],
        label: 'PAGE.ORDER_MANAGER.LIST.FILTER.ID',
        value: 0 < this.filters.getAll('orderId[]').length ? this.filters.getAll('orderId[]') : undefined,
        allowCustom: true
      }),
      new MultiSearchField({
        fieldName: 'countries[]',
        label: 'PAGE.ORDER_MANAGER.LIST.FILTER.COUNTRIES',
        data: this.getCountriesList(),
        textField: 'label',
        valueField: 'value',
        value: 0 < this.filters.getAll('countries[]').length ? this.filters.getAll('countries[]') : undefined,
        defaultItem: { label: this.translate.instant('PAGE.ORDER.LIST.FILTER.COUNTRY.DATA.0'), value: null },
        valuePrimitive: true,
        readonly: false,
      }),
      new SkuSearchField({
        fieldName: 'skus[]',
        label: 'PAGE.ORDER_MANAGER.LIST.FILTER.SKUS',
        productType: 'product',
        value: 0 < this.filters.getAll('skus[]').length ? this.filters.getAll('skus[]') : undefined,
        valueField: 'sku',
        valuePrimitive: true
      }),
      new DateField({
        label: 'PAGE.ORDER_MANAGER.LIST.FILTER.DATES',
        fieldName: 'date',
        dateRange: true,
        value: this.dateStartField.value && this.dateEndField.value
          ? [new Date(this.dateStartField.value), new Date(this.dateEndField.value)]
          : undefined,
        valueChangedAction: OrderManagerLightFiltersFormService.setDates.bind(this),
      }),
      this.dateStartField,
      this.dateEndField,
      new ComboSearchField({
        fieldName: 'hasReverse',
        label: 'PAGE.ORDER_MANAGER.LIST.FILTER.REVERSE',
        data: [
          { value: 1, label: this.translate.instant('PAGE.CATEGORIES_MARKETPLACE.BOOLEANS.YES') },
          { value: 0, label: this.translate.instant('PAGE.CATEGORIES_MARKETPLACE.BOOLEANS.NO') },
          { value: undefined, label: this.translate.instant('PAGE.CATEGORIES_MARKETPLACE.BOOLEANS.ALL') },
        ],
        textField: 'label',
        valueField: 'value',
        value: this.filters.get('hasReverse') ? +this.filters.get('hasReverse') : undefined,
        valuePrimitive: true,
      }),
    ];
  }


  private getCountriesList(): IDropdownData[] {
    const countries = SessionHelper.getCountries();
    const currentLocale = SessionHelper.getUILanguage();

    return countries.map((country) => {
      return {
        label: country.translations[currentLocale].name,
        value: country.code,
      };
    });
  }
}
