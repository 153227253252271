import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { Component, Inject } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { BannerResource } from '@components/banner/banner.resource';
import { SnackbarService } from '@components/snackbar';

@Component({
  selector: 'app-banner-list',
  template: require('./banner-list.component.html'),
  providers: [
    { provide: AbstractResource, useClass: BannerResource },
  ],
})
export class BannerListComponent extends AbstractPageComponent {

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private snackbar: SnackbarService,
  ) {
    super($translate, authService, resource, state);
  }

  public update({active, id}: { active: boolean; id: string; }) {
    this.resource.update(id, { active })
      .takeUntil(this.destroyed$)
      .subscribe(() => this.snackbar.validate(this.translate('ALERTS.DATA.UPDATE')))
    ;
  }
}
