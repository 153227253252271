'use strict'

import { DATE_FULL_FORMAT } from '@constants'
import { DateHelper, MarketplaceHelper, ProductMarketplaceHelper } from '@helpers'
import { SessionHelper } from '../../../helpers/session.helper'

###
  @name ProductRowECommerceCmp

  @description
  Component row for product module.
  Displays each table cell.

  @param {Object} product

  @example
    <example>
      <tr product-row-e-commerce
          ng-repeat="item in collection"
          product="item"></tr>
    </example>

###
angular
  .module('ammsFrontendApp')
  .service('ProductRowECommerceService', [
    '$rootScope',
    ($rootScope) ->
      this.INLINE_PRODUCTION_EDITION_START = 'inline-edition-start'
      this.INLINE_PRODUCTION_EDITION_STOP = 'inline-edition-stop'

      ###
        Fires an event bout inline edition start

        @param {object} product
      ###
      this.startEdition = (product) ->
        $rootScope.$broadcast(this.INLINE_PRODUCTION_EDITION_START, product)

      ###
        Fires an event bout inline edition stop

        @param {object} product
      ###
      this.stopEdition = (product) ->
        $rootScope.$broadcast(this.INLINE_PRODUCTION_EDITION_STOP, product)

      return this
  ])
  .directive('productRowECommerce', [
    ->
      replace: true
      restrict: 'A'
      templateUrl: require('views/products/list/row-e-commerce.html')
      controller: 'ProductRowECommerceCtrl'
      controllerAs: 'row'
      bindToController:
        product: '='
        selectable: '='
      scope: true
      link: (scope, elem, attrs, row) ->
        scope.$watch('row.product', (newValue) ->
          row.prepareModel(newValue)
        )
  ])
  .controller('ProductRowECommerceCtrl', [
    '$document',
    '$scope',
    '$timeout',
    '$dialog',
    'ProductResource',
    'ProductChecker',
    'ProductMarketplaceResource',
    'ProductRowECommerceService',
    'Snackbar',
    '$translate'
    (
      $document,
      $scope,
      $timeout,
      $dialog,
      ProductResource,
      ProductChecker,
      ProductMarketplaceResource,
      ProductRowECommerceService,
      Snackbar,
      $translate
    ) ->
      this.isEditable = true
      this.isInEditMode = false
      this.model = {}
      $scope.currentCountry = SessionHelper.getCountry()

      ###
        Prepares model to fit form requirements

        @param {object} source
      ###
      this.prepareModel = (source) =>
        this.model = JSON.parse(JSON.stringify(source))
        if(this?.model?.productMarketplaces)
          Object.values(this.model.productMarketplaces).forEach((productMarketplace) ->
            if (productMarketplace.shippingDate)
              productMarketplace.shippingDate = new Date(productMarketplace.shippingDate)
              if (productMarketplace.shippingDate < Date.now())
                productMarketplace.shippingDate = null
          )

      ###
        Updates the margin volume field.

        @param {float}  data
        @param {object} productMarketplace
        @param {bool}   isBasePrice
      ###
      this.updateMarginVolumeField = (data, productMarketplace, isBasePrice = false) ->
        marketplaces = MarketplaceHelper.getDisplayedOnProductPageMarketplaces()
        currentMarketplace = marketplaces.filter(
          (marketplace) -> return marketplace.code == productMarketplace.marketplace
        )[0]
        if (isBasePrice)
         productMarketplace.basePrice = data
        else
          productMarketplace.currentOffer.specialPrice = data
        productChecker = new ProductChecker(this.model, marketplaces, $scope.currentCountry.vat)
        this.productMarketplace = productChecker.populateProductMarketplaceData(currentMarketplace, productMarketplace)

      this.basePriceValidity = (data) ->
        return data > ((this.model.cmup || 0) + (this.model.familyShippingCost || 0))

      ###
        Starts edition for the current row and asks for other row to become uneditable

        @param {jQueryEvent}  event
        @param {DOMNode}      form
        @param {object}       productMarketplace
      ###
      this.startEdition = (event, form, productMarketplace) =>
        if (this.isEditable && productMarketplace)
          form.$show()
          startKeyboardListening(form)
          ProductRowECommerceService.startEdition({ productId: this.model.id, cell: angular.element(event.target) })

      ###
        Stops edition for the current row and asks for other row to become editable

        @param {DOMNode}      form
      ###
      this.stopEdition = (form) =>
        form.$hide()
        stopKeyboardListening()
        ProductRowECommerceService.stopEdition({ productId: this.model.id })

      ###
        Starts listening for key pressed
          - enter should save the form
          - escape should cancel the form

        @param {DOMNode}      form
      ###
      startKeyboardListening = (form) =>
        $document.on('keyup', (event) =>
          switch (event.keyCode)
            when 13 then this.save(form)
            when 27 then $timeout(( => this.cancel(form)), 0) # forces template update with async
        )

      ###
        Stops listening for key pressed
      ###
      stopKeyboardListening = ->
        $document.off('keyup')

      ###
        Listen for inline edition start on the page
      ###
      $scope.$on(ProductRowECommerceService.INLINE_PRODUCTION_EDITION_START, (e, args) =>
        if (this.model.id != args.productId)
          this.isEditable = false
        else
          this.isInEditMode = true
          # adds focus on the right cell
          $timeout(
            ->
              args.cell.siblings('.editable-wrap')
                .find('.editable-input')
                .trigger('focus')
            ,
            0,
            false
          )
      )

      ###
        Listen for inline edition stop on the page
      ###
      $scope.$on(ProductRowECommerceService.INLINE_PRODUCTION_EDITION_STOP, =>
        this.isEditable = true
        this.isInEditMode = false
      )

      ###
        Encodes request

        @param {object} request

        @returns {object}
      ###
      encodeRequest = (request) ->
        productMarketplaces = {}

        Object.keys(request.productMarketplaces).forEach((marketplaceName) ->
          marketplace = angular.copy(request.productMarketplaces[marketplaceName])
          productMarketplace = {
            stock: marketplace.stock,
            basePrice: marketplace.basePrice,
            shippingPrice: marketplace.shippingPrice,
          }

          if (DateHelper.isValid(marketplace.shippingDate))
            productMarketplace.shippingDate = DateHelper.format(marketplace.shippingDate, DATE_FULL_FORMAT)
          else
            productMarketplace.shippingDate = null

          if ('undefined' != typeof marketplace.currentOffer)
            productMarketplace.currentOffer = marketplace.currentOffer
            delete marketplace.currentOffer.id

          productMarketplaces[marketplaceName] = productMarketplace

          productChecker = new ProductChecker(request, MarketplaceHelper.getDisplayedOnProductPageMarketplaces(), $scope.currentCountry.vat)
          productChecker.populate()
        )

        newQuery = {}
        newQuery[request.id] = { productMarketplaces }

        return newQuery

      ###
        Saves current row updates

        @param {DOMNode}  form
      ###
      this.save = (form) =>
        isValid = true

        for marketplaceName, productMarketplace of this.model.productMarketplaces
          if (isValid && 'active' == productMarketplace.status && !this.basePriceValidity(if productMarketplace.currentOffer && productMarketplace.currentOffer.specialPrice then productMarketplace.currentOffer.specialPrice else productMarketplace.basePrice))
            isValid = false
            break

        if(!isValid)
          $dialog.confirm($translate.instant('PAGE.PRODUCT.EDIT.TAB.MARKETPLACES.VALIDITY.CONFIRM.DELETE'))
            .then(=>
              ProductResource.batchUpdate(encodeRequest(this.model)).then(=>
                this.stopEdition(form)
              )
          )
        else
          ProductResource.batchUpdate(encodeRequest(this.model))
            .then(=>
              this.stopEdition(form)
          )
        this.prepareModel(this.model)

      ###
        Cancels current row updates

        @param {DOMNode}  form
      ###
      this.cancel = (form) =>
        this.stopEdition(form)
        this.prepareModel(this.product)

      ###
        @inheritdoc
      ###
      this.hasStockAndShippingDate = ProductMarketplaceHelper.hasStockAndShippingDate.bind(ProductMarketplaceHelper)

      ###
        Checks that product marketplace and forecasted stock (SP) product have stock,
        that product marketplace is active and does not have shipping date.

        @param {number}  stock
        @param {boolean} isInPreorder
        @returns {boolean}
      ###
      this.hasStocksWithoutShippingDate = (stock, isInPreorder) ->
          return !!this.product.sold && ProductMarketplaceHelper.hasStockWithoutShippingDate(stock, isInPreorder)

      ###
        Checks that forecasted stock (SP) product has no stock,
        that product marketplace has stock, is active and does not have shipping date.

        @param {number}  stock
        @param {boolean} isInPreorder
        @returns {boolean}
      ###
      this.isProductOutOfStockAndProductMarketplaceHasNoShippingDate = (stock, isInPreorder) ->
        return !this.product.sold && ProductMarketplaceHelper.hasStockWithoutShippingDate(stock, isInPreorder)

      ###
        Checks that pm is active but does not have stock.

        @param {boolean} active
        @param {number}  stock
        @returns {boolean}
      ###
      this.hasNoPMStock = (active, stock) ->
        return active && !stock

      ###
        Removes clicked offer

        @param {{*}}  productMarketplace
        @param {string} offerId
      ###
      this.removeCurrentOffer = (productMarketplace, offerId) ->
        $dialog.confirm($translate.instant('PAGE.PRODUCT.EDIT.TAB.MARKETPLACES.BOX_OFFER.CONFIRM.DELETE'))
          .then( ->
            subscriber = ProductMarketplaceResource.removeOffer(productMarketplace.id, offerId)
              .subscribe( ->
                subscriber.unsubscribe()
                Snackbar.validate($translate.instant('PAGE.PRODUCT.EDIT.TAB.MARKETPLACES.BOX_OFFER.ALERTS.DELETE'))
                delete productMarketplace.currentOffer
              )
          )

      return this
  ])
