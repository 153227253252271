export default websiteModuleRoutes;

/** @ngInject */
function websiteModuleRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'walipush',
    sidebarSubNav: 'website_module',
    title: '',
    reloadChangeCountry: 'website.modules.edit',
  };

  $stateProvider
    .state('websiteModules', {
      url: '/website-modules',
      parent: 'walipush',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.ROOMS',
        stateName: 'room.list'
      }
    })
    .state('websiteModules.edit', {
      url: '',
      views: {
        'page@layout': {
          template: '<app-website-module></app-website-module>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.CONTENT_WEBSITE.MODULE',
      })
    })
  ;
}
