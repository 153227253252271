import {
  IProductDeclination, ISuperProductCategory,
  ISuperProductForm,
  ITranslationReference,
  ITranslations,
} from '@components/super-product/interfaces/super-product-form.interface';
import {IManufacturer} from '@components/super-product/interfaces/form-general.interfaces';

export class SuperProductFormModel implements ISuperProductForm {
  id?: string;
  catalog: string;
  skuParent: string;
  translations: {
    [keys: string]: ITranslationReference;
  };
  productDeclinations?: IProductDeclination[];
  superProductCategories?: ISuperProductCategory[];
  warranty?: string;
  manufacturers?: string[];
  notifyReviewers: boolean;
  internalNotes: string;
  attributeValues: any[];

  constructor(model: ISuperProductForm = {
    id: undefined,
    catalog: undefined,
    skuParent: undefined,
    productDeclinations: undefined,
    superProductCategories: undefined,
    translations: undefined,
    warranty: undefined,
    manufacturers: undefined,
    notifyReviewers: undefined,
    internalNotes: undefined,
    attributeValues: undefined,
  }) {
    this.id = model.id;
    this.catalog = model.catalog;
    this.skuParent = model.skuParent;
    this.productDeclinations = model.products;
    this.superProductCategories = model.superProductCategories;
    this.translations = model.translations;
    this.warranty = model.warranty;
    this.manufacturers = model.manufacturers;
    this.notifyReviewers = model.notifyReviewers;
    this.internalNotes = model.internalNotes;
    this.attributeValues = model.attributeValues;
  }
}
