import { AbstractResource } from '../resources/abstract.resource';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Resource } from '@decorators/Resource';
import { Observable } from 'rxjs/Observable';
import { StringHelper } from '@helpers';

@Injectable()
@Resource({})
export class BlockResource extends AbstractResource {
  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  public writeBlock(formData: FormData, businessObject: string): Observable<any> {
    return this.uploadFile(formData, { entryPoint: '/v2/' + StringHelper.pluralize(businessObject) });
  }

  public deleteBlock(businessObject: string, businessObjectId: number) {
    return this.remove(null, { entryPoint: '/v2/' + StringHelper.pluralize(businessObject) + '/' + businessObjectId });
  }

  public duplicateBlock(businessObject: string, businessObjectId: number) {
    return this.remove(null, { entryPoint: '/v2/' + StringHelper.pluralize(businessObject) + '/' + businessObjectId });
  }
}
