import { Component, Inject } from '@angular/core';
import { AbstractResource, MasterProductResource } from '@resources';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { AuthService } from '@services';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { StockViewService } from '@components/stock/stock-view/stock-view.service';
import { StockViewResource } from '@components/stock/stock-view/stock-view.resource';
import { SnackbarService } from '@components/snackbar';
import {HydraHelper} from '@helpers/HydraHelper';

@Component({
  selector: 'app-stock-view',
  template: require('./stock-view.component.html'),
  providers: [
    { provide: AbstractResource, useClass: StockViewResource },
    { provide: AbstractFiltersFieldsService, useClass: StockViewService }
  ],
})
export class StockViewComponent extends AbstractPageComponent {
  public data: any[] = [];
  public skuData: any = null;
  public stockType: string;
  public objectKeys: (o: {}) => string[] = Object.keys;
  public body: any = {};
  public selectedArrival: any;
  public showQuantityInput: number;
  private modalOpen: boolean = false;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private snackbar: SnackbarService,
    private masterProductResource: MasterProductResource,
    @Inject('DialogService') protected dialog: any,
  ) {
    super($translate, authService, resource, state);
  }

  public onFilter(event: any): void {
    this.stockType = event.stockType;

    this.body = {
      'warehouse': event.warehouse,
      'sku': event.stockSku[0],
      'stockType': this.stockType,
    };

    this.doFilter();
  }

  public doFilter(): void {
    this.resource.cGet(this.body, { isHydra: true, returnHydraMembers: true })
      .takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        this.data = response;
        this.skuData = this.data[0];
      })
    ;
  }

  public recomputeStock(id: any, warehouse: string, stockOrigin: string): void {
    this.masterProductResource.computeStock(id, warehouse, 'sage' === stockOrigin ? 'product' : 'package', {}).subscribe(() => {
        this.snackbar.validate(this.translate('PAGE.PRODUCT.EDIT.SAVE_COMPUTE'));
        this.doFilter();
    });
  }

  public recomputeArrivalStockStatus(sku: string, warehouse: string): void {
    this.masterProductResource.create({ sku: sku, warehouse: warehouse }, { entryPoint: '/v2/order-manager/arrival-stock-status/execute' }).subscribe(() => {
      this.snackbar.validate(this.translate('PAGE.PRODUCT.EDIT.SAVE_COMPUTE'));
      this.doFilter();
    });
  }

  public transformArrivals(arrivalId?: number): void {
    this.dialog.confirm(this.translate('PAGE.STOCK_VIEW.LIST.TABLE.HEAD.CONFIRM_TRANSFORM_ARRIVAL'))
      .then(() => {
        this.masterProductResource.create(
          { arrivalId: arrivalId },
          { entryPoint: '/v2/order-manager/arrivals/transform' }
        ).subscribe(() => {
          this.snackbar.validate(this.translate('PAGE.PRODUCT.EDIT.SAVE_ARRIVAL'));
          this.doFilter();
        });
      })
    ;
  }

  public detransformArrivals(arrivalId?: number): void {
    this.dialog.confirm(this.translate('PAGE.STOCK_VIEW.LIST.TABLE.HEAD.CONFIRM_DETRANSFORM_ARRIVAL'))
      .then(() => {
        this.masterProductResource.create(
          { arrivalId: arrivalId },
          { entryPoint: '/v2/order-manager/arrivals/detransform' }
        ).subscribe(() => {
          this.snackbar.validate(this.translate('PAGE.PRODUCT.EDIT.SAVE_ARRIVAL'));
          this.doFilter();
        });
      })
    ;
  }

  public forceReferenceStockUpdatedAt(stockId: any, id: any, warehouse: string, stockOrigin: string): void {
    this.dialog.confirm(this.translate('PAGE.STOCK_VIEW.LIST.TABLE.HEAD.CONFIRM_FORCE_STOCK_UPDATED_AT'))
      .then(() => {
        this.masterProductResource.create({ id: stockId }, { entryPoint: '/v2/stocks/force-updated-at' }).subscribe(() => {
          this.snackbar.validate(this.translate('PAGE.PRODUCT.EDIT.SAVE_UPDATED_AT'));
          this.recomputeStock(id, warehouse, stockOrigin);
        });
      })
    ;
  }

  public triggerCut(masterProductId: any, warehouseId: any): void {
      this.masterProductResource.create(
        { masterProduct: HydraHelper.buildIri(masterProductId, 'master_products'), warehouse: HydraHelper.buildIri(warehouseId, 'warehouses') },
        { entryPoint: '/v2/cut/trigger' }
      ).subscribe();
  }

  public displayQuantityInput(arrivalId: number): void {
    this.showQuantityInput = arrivalId;

    setTimeout((): void => {
      document.getElementById('arrival-quantity-' + arrivalId).focus();
    }, 0);
  }

  public hideQuantityInput(): void {
    if (!this.modalOpen) {
      this.showQuantityInput = null;
    }
  }

  public saveQuantity(arrivalId: number, event: any): void {
    if (event.keyCode === 27) {
      this.hideQuantityInput();
      return;
    }

    if (event.keyCode !== 13) {
      return;
    }

    this.modalOpen = true;

    this.dialog.confirm(this.translate('PAGE.STOCK_VIEW.LIST.TABLE.HEAD.CONFIRM_UPDATE_QUANTITY', { quantity: +event.target.value }))
      .then(() => {
        this.masterProductResource.update(null, { quantity: +event.target.value }, { entryPoint: '/v2/arrivals/' + arrivalId }).subscribe(() => {
          this.snackbar.validate(this.translate('PAGE.PRODUCT.EDIT.SAVE_QUANTITY'));
          this.doFilter();
        });
      })
      .finally(() => {
        this.showQuantityInput = null;
        this.modalOpen = false;
      })
    ;
  }
}
