import { IHydraMember } from '@interfaces/hydra-resource.interface';
import { ProformaInvoiceModel } from '@components/proforma-invoice/models/proforma-invoice.model';
import * as moment from 'moment';
import { DATE_SHORT_FORMAT } from '@constants';

export class ProformaInvoiceListModel {
  id: number;
  identifier: string;
  supplierLabel: string;
  date: string;

  constructor(object: IHydraMember) {
    const proformaInvoice = new ProformaInvoiceModel(object);

    this.id = proformaInvoice.id;
    this.identifier = proformaInvoice.identifier;
    this.supplierLabel = proformaInvoice.supplier.label;
    this.date = moment(proformaInvoice.createdAt).format(DATE_SHORT_FORMAT);
  }
}
