import { Component, Inject, Input } from '@angular/core';
import { AbstractResource } from '@resources/abstract.resource';
import { ProductResource } from '@components/product/product.resource';
import { AbstractFormComponent } from '@components/generic/Form/abstract-form.component';
import { AuthService } from '@services/auth.service';
import { FormArray } from '@angular/forms';

@Component({
  selector: 'app-product-marketplace-general-form-translations',
  template: require('./product-marketplace-general-form-translations.component.html'),
  providers: [{provide: AbstractResource, useClass: ProductResource}]
})
export class ProductMarketplaceGeneralFormTranslationsComponent extends AbstractFormComponent {

  @Input() public localeTab: string;
  @Input() public translationsFA: FormArray;
  @Input() public tabIndex: number = 0;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }
}
