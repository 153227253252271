import {Component, Inject, Input} from '@angular/core';
import {FormArray} from '@angular/forms';
import { OnInit } from '@angular/core';
import {AuthService} from '@services';
import {AbstractComponent} from '@components/generic/abstract.component';

@Component({
  selector: 'app-room-form-translation',
  template: require('./room-form-translation.component.html'),
})
export class RoomFormTranslationComponent extends AbstractComponent implements OnInit {
  @Input() translationsFA: FormArray;
  @Input() tabIndex: number = 0;
  public messageSuccess = false;

  ngOnInit() {
    setTimeout(() => {
      this.messageSuccess = true;
    }, 150);
  }

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
  ) {
    super($translate, authService, null, null);
  }
}
