export enum CountryTypeEnum {
  DE = 'Allemagne',
  BD = 'Bangladesh',
  BE = 'Belgique',
  CN = 'Chine',
  CO = 'Colombie',
  US = 'États-Unis',
  FR = 'France',
  IN = 'Inde',
  ID = 'Indonésie',
  IL = 'Israël',
  LT = 'Lituanie',
  NL = 'Pays-Bas',
  PK = 'Pakistan',
  PL = 'Pologne',
  PT = 'Portugal',
  TR = 'Turquie',
  VN = 'Viêt Nam'
}
