import { Resource } from '@decorators';
import { Injectable } from '@angular/core';
import { AbstractResource } from '@resources';

@Injectable()
@Resource({
  entryPoint: '/v2/orders?isEstimate=1',
  isHydra: true,
  deleteAvailable: false,
  updateAvailable: true,
  createAvailable: false,
})
export class EstimateOrdersResource extends AbstractResource {
}
