import {
  IExportLogisticsHistory, IExportLogisticsHistoryFiles,
  IExportLogisticsHistoryStatistics
} from '@components/export-logistics/export-logistics-history/models/export-logistics-history.interface';
import { IShowDetailsTable } from '@components/generic/List/show-details/table/table.interface';
import * as moment from 'moment';
import { FULL_EUROPEEN_DATE_FORMAT } from '@constants/date';
import { IButtonLinks } from '@components/generic/buttons/button-links.interface';

export class ExportLogisticsHistoryModel implements IExportLogisticsHistory {
  public automatic: string;
  public countries: string[];
  public warehouses: string;
  public createdAt: string;
  public endedAt: string;
  public numberOfPackages: number;
  public statistics: IExportLogisticsHistoryStatistics[];
  public showDetailsData: IShowDetailsTable;
  public buttonLinks: IButtonLinks[];
  public goLinkLabel: string;
  public volume: string;
  public id: string;

  constructor(exportLogisticsHistory: IExportLogisticsHistory) {
    this.automatic = exportLogisticsHistory.automatic
      ? 'PAGE.EXPORT_LOGISTICS.HISTORY.LIST.TABLE.AUTOMATIC_EXPORT_YES'
      : 'PAGE.EXPORT_LOGISTICS.HISTORY.LIST.TABLE.AUTOMATIC_EXPORT_NO'
    ;

    this.formatVolume(exportLogisticsHistory.statistics);

    this.showDetailsData = this.formatShowDetailsData(exportLogisticsHistory.statistics);
    this.countries = exportLogisticsHistory.countries;
    this.warehouses = this.formatString(<string[]>exportLogisticsHistory.warehouses);
    this.createdAt = exportLogisticsHistory.createdAt ? moment(exportLogisticsHistory.createdAt).format(FULL_EUROPEEN_DATE_FORMAT) : null;
    this.endedAt = exportLogisticsHistory.endedAt ? moment(exportLogisticsHistory.endedAt).format(FULL_EUROPEEN_DATE_FORMAT) : null;
    this.numberOfPackages = exportLogisticsHistory.numberOfPackages;
    this.buttonLinks = this.formatFiles(exportLogisticsHistory.files);
    this.goLinkLabel = 'PAGE.EXPORT_LOGISTICS.HISTORY.LIST.TABLE.ACTIONS.DOWNLOAD';
    this.volume = this.computeVolume(exportLogisticsHistory.statistics);
    this.id = exportLogisticsHistory.id;
  }

  private formatShowDetailsData(data: IExportLogisticsHistoryStatistics[]): IShowDetailsTable {
    return {
      table: {
        columns: [
          {translationKey: 'PAGE.EXPORT_LOGISTICS.HISTORY.LIST.SHOW_DETAILS.TABLE.HEAD.AGENCY', fieldName: 'agency'},
          {translationKey: 'PAGE.EXPORT_LOGISTICS.HISTORY.LIST.SHOW_DETAILS.TABLE.HEAD.WAREHOUSE', fieldName: 'warehouse'},
          {translationKey: 'PAGE.EXPORT_LOGISTICS.HISTORY.LIST.SHOW_DETAILS.TABLE.HEAD.DELIVERABLE', fieldName: 'deliverable'},
          {translationKey: 'PAGE.EXPORT_LOGISTICS.HISTORY.LIST.SHOW_DETAILS.TABLE.HEAD.ORDER', fieldName: 'orders'},
          {translationKey: 'PAGE.EXPORT_LOGISTICS.HISTORY.LIST.SHOW_DETAILS.TABLE.HEAD.ITEM', fieldName: 'items'},
          {translationKey: 'PAGE.EXPORT_LOGISTICS.HISTORY.LIST.SHOW_DETAILS.TABLE.HEAD.PACKAGE', fieldName: 'packages'},
          {translationKey: 'PAGE.EXPORT_LOGISTICS.HISTORY.LIST.SHOW_DETAILS.TABLE.HEAD.VOLUME', fieldName: 'volume'}
        ],
        rows: data
      }
    };
  }

  private computeVolume(statistics: any[]): string {
    let total = 0;
    statistics.forEach((statistic: any) => {
        total += +statistic.volume;
    });
    return total.toFixed(2) + 'm3';
  }

  private formatVolume(statistics: any[]) {
    statistics.forEach((statistic: any) => {
      statistic.volume = statistic.volume ? statistic.volume.toFixed(2) : '0';
    });
  }

  private formatFiles(files: IExportLogisticsHistoryFiles): IButtonLinks[] {
    const links: any[] = [];
    Object.keys(files).forEach((warehouse: string) => {
      Object.keys(files[warehouse]).forEach((carrier: string) => {
        links.push( {
          label: carrier === 'file' ? warehouse : `${warehouse} / ${carrier}`,
          url: files[warehouse][carrier],
        });
      });
    });
    return links;
  }

  private formatString(array: string[]): string {
    let formattedString: string = '';
    array.forEach((string: string) => {
      formattedString = formattedString.concat(', ', string);
    });

    return formattedString.slice(2);
  }
}
