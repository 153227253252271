import { Injectable } from '@angular/core';
import { Resource } from '@decorators/Resource';
import { AbstractResource } from '../../../../resources/abstract.resource';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

@Injectable()
@Resource({
  entryPoint: '/v2/task_attachments',
  isHydra: true,
})
export class TaskAttachmentsResource extends AbstractResource {

  constructor(http: HttpClient) {
    super(http);
  }

  public postAttachments(taskId: string, file: File): Observable<Object> {
    const formData: FormData = new FormData();

    formData.append('file', file, file.name);
    formData.append('task', taskId);

    return this.uploadFile(formData);
  }

  public destroyFile(fileId: string, options?: object): Observable<Object> {
    return this.remove(undefined, {
      ...options,
      entryPoint: `${this.entryPoint}/${fileId}`,
    });
  }
}
