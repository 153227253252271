import { AbstractComponent } from '@components/generic/abstract.component';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProductResource } from '@components/product/product.resource';
import { SnackbarService } from '@components';
import { FormNotifierService } from '@services';
import { OrderResource } from '@resources';
import { takeUntil } from 'rxjs/operators';
import { IHydraMember } from '@interfaces/hydra-resource.interface';

@Component({
  selector: 'app-order-manager-comment-form',
  template: require('./order-manager-comment-form.component.html'),
})
export class OrderManagerCommentFormComponent extends AbstractComponent implements OnInit {

  @Input() public order: any;

  public form: FormGroup;
  public comments: any[] = [];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private productResource: ProductResource,
    @Inject('CountryService') private countryService: any,
    private snackbar: SnackbarService,
    private formBuilder: FormBuilder,
    private formNotifierService: FormNotifierService,
  ) {
    super($translate, authService, resource, state);
  }

  public ngOnInit(): void {
    this.buildForm();
    this.fetchComments();
  }

  public buildForm(): void {
    this.form = this.formBuilder.group({
      comment: [null],
    });
  }

  public fetchComments(): void {
    (<OrderResource>this.resource).getComments(this.state.params.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: IHydraMember[]) => {
        this.comments = response;
      })
    ;
  }

  public addComment(): void {
    (<OrderResource>this.resource).createComment(this.state.params.id, {message: this.form.get('comment').value})
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        this.snackbar.validate(this.translate('ALERTS.COMMENT.SAVE'));
        this.form.reset();
        this.comments.unshift(response);
        this.formNotifierService.notifyFormSubmitted();
      })
    ;
  }
}
