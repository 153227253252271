import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { AbstractResource } from '../../resources/abstract.resource';
import { HttpClient } from '@angular/common/http';
import { ServiceListModel } from '@components/service/model/service-list.model';
import { MarketplaceHelper, SessionHelper } from '@helpers';
import { IMarketplace } from '@interfaces';
import { Observable } from 'rxjs/Observable';

@Injectable()
@Resource({
  routeName: 'service',
  entryPoint: '/v2/services',
  isHydra: true,
  deleteAvailable: true,
  buttonActionMultipleAvailable: true,
  listModel: ServiceListModel,
  translationKey: 'SERVICE',
  listColumns: [
    { field: 'id', translationKey: 'ID', type: 'id' },
    { field: 'type', translationKey: 'TYPE', type: 'string', translateValueFrom: 'PAGE.SERVICE.TYPES' },
    { field: 'sku', translationKey: 'SKU', type: 'string' },
    { field: 'title', translationKey: 'TITLE', type: 'string' },
    { field: 'amount', translationKey: 'AMOUNT', type: 'number' },
    { field: 'cost', translationKey: 'COST', type: 'number' },
    { field: 'products', translationKey: 'PRODUCTS', type: 'labelled' },
  ],
  cGetDefaultFilters: () => ({
    'marketplace.code': SessionHelper.getMarketplaces().filter((item: IMarketplace) => {
      return MarketplaceHelper.isWebsiteMarketplace(item);
    })[0].code
  })
})
export class ServiceResource extends AbstractResource {
  protected nullableProperties: string[] = ['productMinPrice', 'productMaxPrice', 'contentBlock'];

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  public getServiceTypes(): Observable<object> {
    return this.cGet({}, { entryPoint: '/v2/service_types', returnHydraMembers: true, isHydra: true, dontUseModel: true });
  }

  public getAllServices(): Observable<object> {
    return this.cGet({}, { entryPoint: '/v2/services', returnHydraMembers: true, isHydra: true, dontUseModel: true });
  }
}
