export default carrierRoutes;

/** @ngInject */
function carrierRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'carrier',
    sidebarSubNav: 'carrier',
    title: '',
    reloadChangeCountry: 'carrier.list'
  };

  const tabs = [
    {
      label: 'PAGE.CARRIER.EDIT.TAB.GENERAL.LABEL',
      state: 'carrier.edit',
    },
    {
      label: 'PAGE.CARRIER.EDIT.TAB.SHIPPING_COSTS.LABEL',
      state: 'carrier.edit.cost',
    },
    {
      label: 'PAGE.CARRIER.EDIT.TAB.MESH_AGENCY.LABEL',
      state: 'carrier.edit.mesh',
    },
    {
      label: 'PAGE.CARRIER.EDIT.TAB.BLACKLIST.LABEL',
      state: 'carrier.edit.blacklist',
    },
    {
      label: 'PAGE.CARRIER.EDIT.TAB.BLACKLIST.POSTAL_CODES_LABEL',
      state: 'carrier.edit.blacklistPostalCode',
    }
  ];

  $stateProvider
    .state('carrier', {
      url: '/carriers',
      parent: 'walisoft',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.CARRIER.LIST',
        stateName: 'carrier.list',
      }
    })
    .state('carrier.calculator', {
      url: '/calculator',
      views: {
        'page@layout': { template: '<app-carrier-calculator></app-carrier-calculator>' }
      },
      breadcrumb: {
        label: 'BREADCRUMB.CARRIER.CALCULATOR'
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.CARRIER.CALCULATOR.TITLE',
        sidebarSubNav: 'calculator'
      })
    })
    .state('carrier.capacity', {
      url: '/capacity',
      views: {
        'page@layout': { template: '<app-carrier-capacity></app-carrier-capacity>' }
      },
      breadcrumb: {
        label: 'BREADCRUMB.CARRIER.CAPACITY',
        stateName: 'carrier.capacity',
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.CARRIER.CAPACITY.TITLE',
        sidebarSubNav: 'capacity'
      })
    })
    .state('carrier.docked_volume', {
      url: '/docked-volume',
      views: {
        'page@layout': { template: '<app-docked-volume></app-docked-volume>' }
      },
      breadcrumb: {
        label: 'BREADCRUMB.CARRIER.DOCKED_VOLUME',
        stateName: 'carrier.docked_volume',
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.CARRIER.DOCKED_VOLUME.TITLE',
        sidebarSubNav: 'capacity'
      })
    })
    .state('carrier.capacity.new', {
      url: '/new',
      views: {
        'page@layout': { template: '<app-carrier-capacity-form></app-carrier-capacity-form>' }
      },
      breadcrumb: {
        label: 'BREADCRUMB.CARRIER.CAPACITY'
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.CARRIER.CAPACITY.TITLE',
        sidebarSubNav: 'capacity'
      })
    })
    .state('carrier.capacity.edit', {
      url: '/:id/edit',
      views: {
        'page@layout': { template: '<app-carrier-capacity-form></app-carrier-capacity-form>' }
      },
      breadcrumb: {
        label: 'BREADCRUMB.CARRIER.CAPACITY'
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.CARRIER.CAPACITY.TITLE',
        sidebarSubNav: 'capacity'
      })
    })
    .state('carrier.list', {
      url: '',
      views: {
        'page@layout': { template: '<app-carrier-group-list></app-carrier-group-list>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.CARRIER.CARRIER.LIST.TITLE',
      })
    })
    .state('carrier.edit', {
      url: '/:id',
      views: {
        'page@layout': { template: '<app-carrier-edit></app-carrier-edit>' }
      },
      breadcrumb: {
        label: 'BREADCRUMB.EDIT'
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.CARRIER.EDIT.TITLE',
        tabs: tabs,
      })
    })
    .state('carrier.edit.cost', {
      url: '/cost',
      views: {
        'page@layout': { template: '<app-carrier-cost></app-carrier-cost>' }
      },
      breadcrumb: {
        label: 'BREADCRUMB.SHIPPING_COSTS'
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.CARRIER.EDIT.TAB.SHIPPING_COSTS.LABEL',
        tabs: tabs,
      })
    })
    .state('carrier.edit.mesh', {
      url: '/mesh-agency',
      breadcrumb: { label: 'BREADCRUMB.PRODUCT' },
      views: {
        'page@layout': { template: '<app-carrier-mesh-agency></app-carrier-mesh-agency>' }
      },
      params: {
        carrierCode: '',
        dynamicAgency: false,
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.CARRIER.EDIT.TAB.MESH_AGENCY.TITLE',
        tabs: tabs,
      })
    })
    .state('carrier.edit.blacklist', {
      url: '/blacklist',
      breadcrumb: { label: 'BREADCRUMB.PRODUCT' },
      views: {
        'page@layout': { template: '<app-carrier-blacklist></app-carrier-blacklist>' }
      },
      params: {
        carrierCode: '',
        dynamicAgency: false,
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.CARRIER.BLACKLIST.TITLE',
        tabs: tabs,
      })
    })
    .state('carrier.edit.blacklistPostalCode', {
      url: '/blacklist-postal-codes',
      breadcrumb: { label: 'BREADCRUMB.PRODUCT' },
      views: {
        'page@layout': { template: '<app-carrier-blacklist-postal-codes></app-carrier-blacklist-postal-codes>' }
      },
      params: {
        carrierCode: '',
        dynamicAgency: false,
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.CARRIER.EDIT.TAB.BLACKLIST.POSTAL_CODES_LABEL',
        tabs: tabs,
      })
    })
    ;
}
