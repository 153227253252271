import { Resource } from '@decorators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractResource } from '@resources/abstract.resource';
import { FooterLinksListModel } from '@components/footer/footer-links/models';

@Injectable()
@Resource({
  entryPoint: '/v2/footers',
  listModel: FooterLinksListModel,
  routeName: 'footerLinks',
  buttonActionMultipleAvailable: true,
})

export class FooterLinksResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }
}
