'use strict'

import { FormHelper } from '@helpers';

###
  @name FormDisplayErrorsCmp

  @description Component formDisplayErrors for form.
  Displays the errors returned by the API.

  @param {object} errors
  @param {string} field

  @example
    // Basic use in forms
    <div class="row">
      <div class="col-md-2 col-sm-5">
        <label for="field-name" class="control-label">Label</label>
      </div>
      <div class="col-md-10 col-sm-7">
        <input type="text"
               name="field-name"
               id="field-name"
               class="form-control input-sm"
               ng-model="model.fieldName">

        <div form-display-errors
             errors="form.formErrors"
             field="field-name"></div>
      </div>
    </div>

  @example
    // If the field belongs to a translation object
    <div class="row">
      <div class="col-md-2 col-sm-5">
        <label for="field-name" class="control-label">Label</label>
      </div>
      <div class="col-md-10 col-sm-7">
        <input type="text"
               name="field-name"
               id="field-name"
               class="form-control input-sm"
               ng-model="translateModel.fieldName">

        <div form-display-errors
             errors="form.formErrors"
             field="translations[{{ form.currentLocale }}].fieldName"></div>
      </div>
    </div>
###
angular
  .module('ammsFrontendApp')
  .directive('formDisplayErrors', [
    ->
      replace: true
      restrict: 'A'
      templateUrl: require('views/default/form/display-errors.html')
      controller: 'FormDisplayErrorsCtrl'
      controllerAs: 'formError'
      bindToController:
        errors: '='
        field: '@'
      scope: true
      link: ((scope, elem, attrs, formError) ->
        scope.$watchCollection('formError.errors', (newValue) ->
          if ('undefined' != typeof newValue)
            formError.listErrors = formError.getErrors(newValue, formError.field)
        )
      )
  ])
  .controller('FormDisplayErrorsCtrl', [ ->
      ###
        Get errors for each field

        @param {object} errors
        @param {string} field

        @returns {array<string>}
      ###
      getErrors: (errors, field) ->
        if ('undefined' != typeof errors)
          return FormHelper.getErrors(errors, field)

        return []
  ])
