import { Resource } from '@decorators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractResource } from '@resources/abstract.resource';
import { ReinsurancePointsListModel } from '@components/footer/reinsurance-points/models';

@Injectable()
@Resource({
  entryPoint: '/v2/reinsurance_items',
  listModel: ReinsurancePointsListModel,
  routeName: 'reinsurancePoints',
  translationKey: 'REINSURANCE_POINTS',
  buttonActionMultipleAvailable: true,
})

export class ReinsurancePointsResource extends AbstractResource {
  constructor(
    http: HttpClient,
  ) {
    super(http);
  }
}
