import { Component, Inject } from '@angular/core';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { AuthService } from '@services/auth.service';
import { AbstractResource, RoomResource } from '@resources';

@Component({
  selector: 'app-room-list',
  template: require('./room-list.component.html'),
  providers: [
    { provide: AbstractResource, useClass: RoomResource },
  ]
})

export class RoomListComponent extends AbstractPageComponent {
  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    public resource: AbstractResource,
  ) {
    super($translate, authService, resource, state);
  }
}
