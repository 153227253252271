import { Injectable } from '@angular/core';
import { Resource } from '../decorators/Resource';
import { AbstractResource } from '../resources/abstract.resource';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  entryPoint: '/marketplaces',
})
export class MarketplaceResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  /**
   * Exports product on marketplace (launch API marketplace exporter)
   *
   * @param {string} marketplace  - name of the marketplace
   * @param {string} exporterType - exporter type (offer - product ...)
   */
  public updateProductOnMarketplace(marketplace: string, exporterType: string) {
    return this.create(undefined, {entryPoint: `${this.entryPoint}/${marketplace}/export/${exporterType}`});
  }

  public filterByCountryCode(countryCode: string): Observable<object> {
    return this.getMany({countryCode}, {entryPoint: `${this.entryPoint}/${countryCode}`});
  }
}
