import { AbstractResource } from '../resources/abstract.resource';
import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  entryPoint: '/orders/{orderId}/items'
})
export class OrderItemResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  public createOrderItem(orderId: string, body: object): Observable<Object> {
    return this.create(
      body,
      {entryPoint: this.entryPoint.replace('{orderId}', orderId)}
    );
  }

  public updateOrderItem(orderId: string, itemId: string, body: object): Observable<Object> {
    return this.update(
      null,
      body,
      {entryPoint: `${this.entryPoint.replace('{orderId}', orderId)}/${itemId}`}
    );
  }

  /**
   * @deprecated - use removeOrderItemv2() instead
   */
  public removeOrderItem(orderId: string, itemId: string): Observable<Object> {
    return this.remove(
      null,
      {entryPoint: `${this.entryPoint.replace('{orderId}', orderId)}/${itemId}`}
    );
  }

  public removeOrderItemv2(itemId: string): Observable<Object> {
    return this.remove(
      null,
      {entryPoint: `/v2/order_items/${itemId}`}
    );
  }

  public resendEmail(orderId: string, itemId: string, status: string): Observable<Object> {
    return this.create(
      {},
      {entryPoint: `/orders/${orderId}/items/${itemId}/resend-email/${status}`}
    );
  }

  public forceMarketplaceSynced(orderItemId: string): Observable<Object> {
    return this.update(
      orderItemId,
      {'marketplaceSynced': true},
      {entryPoint: `/v2/order_items/${orderItemId}/export/synchronize_marketplace`}
    );
  }

  public getOrderItem(orderId: string) {
    return this.get(null, {entryPoint: `/v2/order-manager/orders/${orderId}/items`});
  }
}
