import { NgModule } from '@angular/core';
import { CMSListComponent } from '@components/cms/list';
import { GenericFormModule, GenericModule } from '@components/generic';
import { CMSResource } from '@components/cms/cms.resource';
import { CMSFormComponent } from '@components/cms/form/cms-form.component';
import { CMSTranslationsFormComponent } from '@components/cms/form';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TabsModule } from 'ngx-bootstrap';
import { DirectivesModule } from '@directives/directives.module';
import { PipesModule } from '../../pipes/pipes.module';
import { BreadcrumbModule } from '@components/breadcrumb';
import { WebsiteCategoriesResource } from '@components/cms/website_categorie.resource';

@NgModule({
  declarations: [
    CMSListComponent,
    CMSFormComponent,
    CMSTranslationsFormComponent,
  ],
  entryComponents: [
    CMSListComponent,
    CMSFormComponent,
    CMSTranslationsFormComponent,
  ],
  imports: [
    CommonModule,
    DirectivesModule,
    GenericModule,
    GenericFormModule,
    ReactiveFormsModule,
    TabsModule,
    PipesModule,
    BreadcrumbModule,
  ],
  providers: [
    CMSResource,
    WebsiteCategoriesResource
  ]
})
export class CMSModule {}
