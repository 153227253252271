'use strict'

import { CategoryTreeHelper, MarketplaceHelper, SessionHelper } from '@helpers'

###
  @name CategorySelectorCmp
###
angular
  .module('ammsFrontendApp')
  .controller('CategorySelector', [
    '$scope',
    'CategoryResource',
    '$translate',
    ($scope, CategoryResource, $translate) ->
      websiteMarketplace = MarketplaceHelper.getWebsiteMarketplace()
      currentLocale = SessionHelper.getLocale()
      this.categories = []

      CategoryResource.getMany({ level: 1, marketplace: websiteMarketplace.code })
        .subscribe((categories) => this.categories = CategoryTreeHelper.flatten(decodeCategories(categories)))

      ###
        Decodes recursively categories

        @param {Array} categories

        @returns {Array}
      ###
      decodeCategories = (categories) ->
        if (!categories)
          return null

        return categories.map((category) -> {
          children: decodeCategories(category.children),
          id: category.id,
          label: category.translations[currentLocale].name
        })

      ###
        Translates key for a specific domain

        @param {string} key
        @param {string} [domain]

        @returns {string}
      ###
      this.translate = (key, domain = 'DIALOG') ->
        $translate.instant(domain + '.' + key)

      ###
        Closes the modal and dispatch the selected category

        @param {object} category
      ###
      this.close = (category) ->
        $scope.$close({ $value: category })

      return this
  ])
