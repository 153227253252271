import { Component, Inject } from '@angular/core';
import { AbstractResource, MasterProductResource } from '@resources';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { AuthService } from '@services';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { SnackbarService } from '@components/snackbar';
import { ArrivalViewResource } from '@components/stock/arrival-view/arrival-view.resource';
import { ArrivalViewService } from '@components/stock/arrival-view/arrival-view.service';
import { IExportOptions } from '@interfaces';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FormService} from '@services/form.service';
import * as moment from 'moment';
import {DATE_SHORT_INTERNATIONAL_FORMAT} from '@constants';
import {Observable} from 'rxjs/Observable';
import {forkJoin} from 'rxjs/observable/forkJoin';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-arrival-view',
  template: require('./arrival-view.component.html'),
  providers: [
    { provide: AbstractResource, useClass: ArrivalViewResource },
    { provide: AbstractFiltersFieldsService, useClass: ArrivalViewService },
    FormService
  ],
})
export class ArrivalViewComponent extends AbstractPageComponent {
  public crossDockModalData: {arrivalId: number, doIt: boolean, maxVersion: number}|null = null;
  public crossDockFormGroup: FormGroup = new FormGroup({
    version: new FormControl(null, Validators.required)
  });
  public selectAll: boolean = false;
  public data: any[] = [];
  public objectKeys: (o: {}) => string[] = Object.keys;
  public body: any = {};
  public exportOptions: IExportOptions[] = [
    {
      translationKey: 'EXPORT',
      entryPoint: '/v2/arrival_view',
      responseType: 'text/csv',
      type: 'text/csv',
      filename: 'arrivals.csv',
      filters: false,
    },
    {
      translationKey: 'PAGE.STOCK_VIEW.EXPORT.SUMMARY',
      entryPoint: '/v2/export',
      responseType: 'text/csv',
      type: 'text/csv',
      filename: `export-cross-dock-summary-${moment().format(DATE_SHORT_INTERNATIONAL_FORMAT)}.csv`,
      filters: false,
      roles: ['ROLE_WALISOFT_AGENT'],
      name: 'cross_dock_summary',
      postOptions: {
        exportCode: 'cross_dock_summary',
        formatCode: 'csv',
        dryRun: false,
        deferred: false,
        split: false,
        serializationGroups: [],
        limit: '',
        async: false
      }
    }
  ];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private snackbar: SnackbarService,
    private masterProductResource: MasterProductResource,
    @Inject('DialogService') protected dialog: any,
  ) {
    super($translate, authService, resource, state);
  }

  toggleSelect(): void {
    this.selectAll = true;
    this.data.forEach((item) => {
      if (!item.selected) {
        this.selectAll = false;
      }
    });
  }

  public toggleSelectAll(): void {
    this.data.forEach((item) => {
      item.selected = this.selectAll;
      return item;
    });
  }

  public getSelectedItems(): any[] {
    return this.data.filter(item => item.selected);
  }

  public onFilter(event: any): void {
    this.body = {
      'warehouse': event.warehouse,
      'containers': event.containers,
    };

    this.exportOptions[0].filters = this.body;
    this.exportOptions[1].postOptions.limit = event.containers.join('|');
    this.doFilter();
  }

  public doFilter(): void {
    this.resource.cGet(this.body, { isHydra: true, returnHydraMembers: true })
      .takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        this.selectAll = false;
        this.data = response;
        this.data.forEach((item) => {
          item.selected = false;
        });
      })
    ;
  }

  public transformArrivals(arrivalId?: number): void {
    this.dialog.confirm(this.translate('PAGE.STOCK_VIEW.LIST.TABLE.HEAD.CONFIRM_TRANSFORM_ARRIVAL'))
      .then(() => {
        this.masterProductResource.create(
          { arrivalId: arrivalId },
          { entryPoint: '/v2/order-manager/arrivals/transform' }
        ).subscribe(() => {
          this.snackbar.validate(this.translate('PAGE.PRODUCT.EDIT.SAVE_ARRIVAL'));
          this.doFilter();
        });
      })
    ;
  }

  public detransformArrivals(arrivalId?: number): void {
    this.dialog.confirm(this.translate('PAGE.STOCK_VIEW.LIST.TABLE.HEAD.CONFIRM_DETRANSFORM_ARRIVAL'))
      .then(() => {
        this.masterProductResource.create(
          { arrivalId: arrivalId },
          { entryPoint: '/v2/order-manager/arrivals/detransform' }
        ).subscribe(() => {
          this.snackbar.validate(this.translate('PAGE.PRODUCT.EDIT.SAVE_ARRIVAL'));
          this.doFilter();
        });
      })
    ;
  }

  openCrossDockModal(arrivalId: number, doIt: boolean, maxVersion: number): void {
    this.crossDockModalData = {
      arrivalId,
      doIt,
      maxVersion
    };
    this.crossDockFormGroup.get('version').setValue(maxVersion);
  }

  closeCrossDockModal(): void {
    this.crossDockModalData = null;
    this.crossDockFormGroup.get('version').setValue(null);
  }

  public crossDockItSelectedItems(doIt: boolean): void {
    const promises: Promise<any>[] = [];

    if (0 === this.getSelectedItems().length) {
      this.snackbar.warn(this.translate('PAGE.STOCK_VIEW.LIST.TABLE.HEAD.NO_ITEM_SELECTED'));
    }

    this.getSelectedItems().forEach((item: {id: number, maxVersion: boolean, crossDocking: boolean}) => {
      if (doIt === item.crossDocking) {
        return;
      }

      const body: any = {
        crossDocking: doIt,
        version: item.maxVersion
      };

      promises.push(new Promise((resolve, reject) => {
        this.resource.update(null, body, { entryPoint: `/v2/arrivals/${ item.id }` })
          .pipe(takeUntil(this.destroyed$))
          .subscribe(resolve, reject);
      }));
    });

    if (!promises.length) {
      return;
    }

    Promise
      .all(promises)
      .then(() => {
        this.snackbar.validate(this.translate('SAVED'));
        this.doFilter();
      })
      .catch(() => {
        this.doFilter();
      });
  }

  public crossDockIt(arrivalId: number, doIt: boolean): void {
    const body: any = {
      crossDocking: doIt,
      version: this.crossDockFormGroup.get('version').value.toString()
    };

    this.resource.update(null, body, { entryPoint: `/v2/arrivals/${ arrivalId }` })
      .subscribe(() => {
        this.closeCrossDockModal();
        this.snackbar.validate(this.translate('SAVED'));
        this.doFilter();
      });
  }
}
