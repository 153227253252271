import { Inject, Injectable } from '@angular/core';
import { FormNotifierService } from '@services/form-notifier.service';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import {
    DateField,
    MultiSearchField,
    TextField
} from '@components/generic/Form/dynamic/fields';
import { SessionHelper } from '@helpers';
import { Subscription } from 'rxjs/Subscription';
import { ICommercialEntity } from '@interfaces';
import { CommercialEntityResource } from '@resources';
import { DropDownListField } from '@components/generic/Form/dynamic/fields/select-field.class';
import { DATE_FULL_FORMAT } from '@constants';
import moment = require('moment');

@Injectable()
export class OrderCollectionFiltersFormService extends AbstractFiltersFieldsService {

    private commercialEntityField: DropDownListField = new DropDownListField({
        fieldName: 'transactionCommercialEntity',
        label: 'PAGE.ORDER.LIST.FILTER.COMMERCIAL_ENTITY.LABEL',
        data: [],
        textField: 'label',
        valueField: 'id',
        value: this.filters.get('transactionCommercialEntity') ? this.filters.get('transactionCommercialEntity') : null,
        valuePrimitive: true,
    });

    private dateStartField: TextField = new TextField({
        fieldName: 'dateStart',
        value: null,
        hidden: true
    });

    private dateEndField: TextField = new TextField({
        fieldName: 'dateEnd',
        value: null,
        hidden: true
    });

    constructor(
        private commercialEntityResource: CommercialEntityResource,
        @Inject('StateService') protected state: ng.ui.IStateService,
        @Inject('TranslationService') public $translate: ng.translate.ITranslateService,
        formNotifier: FormNotifierService,
    ) {
        super(formNotifier, state);

        this.fetchCommercialEntities();
    }

    public fetchCommercialEntities(): void {
        const subscriber: Subscription = this.commercialEntityResource.cGet(null, { returnHydraMembers: true })
            .subscribe((commercialEntities: ICommercialEntity[]) => {
                subscriber.unsubscribe();

                this.commercialEntityField.data.push(...commercialEntities);
            });
    }

    public getFields(): any[] {
        const dateStart = this.filters.get('dateStart');
        const dateEnd = this.filters.get('dateEnd');

        if (dateStart && dateEnd) {
            this.dateStartField.value = dateStart;
            this.dateEndField.value = dateEnd;
        }

        const ordersTypeOptions = [
            { label: 'SAV', value: 'sav' },
            { label: 'No SAV', value: 'nosav' },
            { label: 'Unpaired', value: 'unpaired' },
        ];

        return [
            new TextField({
                fieldName: 'search',
                label: 'PAGE.ORDER_MANAGER.LIST.FILTER.SEARCH',
                value: this.filters.get('search') ? this.filters.get('search') : undefined,
            }),

            new MultiSearchField({
                fieldName: 'warehouses[]',
                label: 'PAGE.ORDER.LIST.FILTER.WAREHOUSES.LABEL',
                data: SessionHelper.getAllWarehouses(),
                textField: 'name',
                valueField: 'code',
                value: 0 < this.filters.getAll('warehouses[]').length ? this.filters.getAll('warehouses[]') : undefined,
                valuePrimitive: true,
            }),

            this.commercialEntityField,

            new DateField({
                label: 'PAGE.ORDER.LIST.FILTER.ORDER_DATE.LABEL',
                fieldName: 'date',
                dateRange: true,
                value: dateStart && dateEnd ? [new Date(dateStart), new Date(dateEnd)] : undefined,
                valueChangedAction: this.setDateValue.bind(this),
            }),

            this.dateStartField,
            this.dateEndField,

            new DropDownListField({
                fieldName: 'ordersType',
                label: 'PAGE.ORDER.LIST.FILTER.ORDERS_TYPE.LABEL',
                data: ordersTypeOptions,
                textField: 'label',
                valueField: 'value',
                value: this.filters.get('ordersType') ? this.filters.get('ordersType') : null,
                defaultItem: { label: '', value: null },
                valuePrimitive: true,
            }),
        ];
    }

    private setDateValue(newValue: string): void {
        this.setDates(newValue, this.dateStartField, this.dateEndField);
    }

    private setDates(newValue: string, startField: TextField, endField: TextField): void {
        if (!newValue || null === newValue[0]) {
            return;
        }

        startField.value = moment(newValue[0]).startOf('day').format(DATE_FULL_FORMAT);
        endField.value = moment(newValue[1]).endOf('day').format(DATE_FULL_FORMAT);
    }
}
