import { IService } from '@components/service/model/service.interface';
import { Component, Inject } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { AbstractComponent } from '@components/generic/abstract.component';
import { IListColumn, IMarketplace } from '@interfaces';
import { debounce } from 'lodash';
import { SnackbarService } from '@components';

@Component({
    selector: 'app-marketplaces-configure-result-list',
    template: require('./marketplaces-configure-result-list.component.html'),
})
export class MarketplacesConfigureResultListComponent extends AbstractComponent {

    private _items: IService[];
    public columnList: IListColumn[] = [];
    public translationKey: string = '';
    public listColumnsEditable: string[] = [];

    set items(items: any[]) {
      const excludedMarketplaces = [
        'googlefr',
        'gosport',
        'intermarche',
        'moncornerdeco',
        'moncornerjardin',
        'moneden',
        'pixplace',
        'magento',
        'willemse',
        'ubaldi'
      ];
      this._items = items.filter(item => !excludedMarketplaces.includes(item.code));
    }

    get items(): any[] {
      return this._items;
    }

    constructor(
        @Inject('TranslationService') $translate: ng.translate.ITranslateService,
        authService: AuthService,
        resource: AbstractResource,
        @Inject('StateService') state: ng.ui.IStateService,
        private snackbar: SnackbarService,
    ) {
        super($translate, authService, resource, state);

        this.columnList = this.resource.listColumns;
        this.translationKey = this.resource.translationKey;
    }

    public valueChange = debounce(function (index: number, field: string) {
        const item = this._items[index];
        const id = item['id'];
        const value = item[field];
        const body: any = { [field]: value };

        this.resource.partialUpdate(id, body)
            .takeUntil(this.destroyed$)
            .subscribe(
                (response: IMarketplace) =>
                    this.snackbar.validate(this.translate('PAGE.MARKETPLACES_CONFIGURE.SNACKBAR.VALID', { name: response.commercialName })),
                (reject: any) =>
                    this.violations = this.formatViolations(reject),
            );
    }, 1000);
}
