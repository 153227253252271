import { Component, Inject } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { AbstractComponent } from '@components/generic/abstract.component';
import { IDiscountList } from '@components/discount/interfaces/discount-list.interface';
import { DATE_SHORT_FORMAT_FILTER } from '@constants';
import { SnackbarService } from '@components/snackbar/snackbar.service';
import { takeUntil } from 'rxjs/operators/takeUntil';

/**
 * Display the discount result list.
 */
@Component({
  selector: 'app-discount-result-list',
  template: require('./discount-result-list.component.html'),
  styles: [require('./discount-result-list.component.scss')],
})
export class DiscountResultListComponent extends AbstractComponent {

  public readonly dateFormat = DATE_SHORT_FORMAT_FILTER;

  private _items: { discounts: IDiscountList[] };
  public numberOfUsage: any = {};

  set items(items: { discounts: IDiscountList[] }) {
    this._items = items;
  }

  get items(): { discounts: IDiscountList[] } {
    // @ts-ignore
    return this._items ? this._items['hydra:member'] : [];
  }

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    @Inject('DialogService') private dialog: any,
    private snackbar: SnackbarService,
  ) {
    super($translate, authService, resource, state);
  }

  public updateDiscountStatus(discount: IDiscountList): void {
    this.resource.updateDiscount(discount.id, { active: discount.active })
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(() => this.snackbar.validate(this.translate('ALERTS.DATA.UPDATE')))
    ;
  }

  public goToEdit(event: MouseEvent, id: string): void {
    const editionState = `${this.resource.routeName}.edit`;

    if (event.ctrlKey) {
      window.open(this.state.href(editionState, { id }, { absolute: true }), '_blank');

      return;
    }

    this.state.go(editionState, { id });
  }

  public archive(discount: IDiscountList): void {
    this.dialog.confirm(
      this.$translate.instant(
        discount.archived ? 'PAGE.DISCOUNT.MESSAGES.UNARCHIVE_CONFIRM' : 'PAGE.DISCOUNT.MESSAGES.ARCHIVE_CONFIRM')
      )
      .then(() => {
        this.resource.updateDiscount(discount.id, { archived: !discount.archived })
          .pipe(
            takeUntil(this.destroyed$)
          )
          .subscribe(() => {
            discount.archived = !discount.archived;
            discount.active = false;

            this.snackbar.validate(this.translate('ALERTS.DATA.UPDATE'));
          })
        ;
      })
    ;
  }

  public getTranslation(translatable: any): any {
    if (!translatable.translations) {
      return {};
    }

    const keys: string[] = Object.keys(translatable.translations);

    if (!keys.length) {
      return {};
    }

    return translatable.translations[keys[0]];
  }

  public getNumberOfUsage(id: number): void {
    this.resource.getNumberOfUsage(id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any): void => {
        this.numberOfUsage[id] = response.numberOfUsage;
      })
    ;
  }
}
