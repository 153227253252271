import { Injectable } from '@angular/core';
import { AbstractResource } from '../../../../resources/abstract.resource';
import { TaskTypeModel } from '@components/generic/task-manager/model';
import { Resource } from '@decorators/Resource';
import { SessionHelper } from '../../../../helpers/session.helper';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  entryPoint: '/v2/task_types',
  model: TaskTypeModel,
  isHydra: true,
  translationKey: 'TASK_MANAGER.TASK_TYPE',
  listColumns: [
    { field: 'target', translationKey: 'TARGET' },
    { field: 'categoryName', translationKey: 'CATEGORY' },
    { field: 'name', translationKey: 'TYPE' },
  ],
  deleteAvailable: false,
  cGetDefaultFilters: () => ({ locale: SessionHelper.getLocale() }),
  routeName: 'task-manager'
})
export class TaskTypeResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }
}
