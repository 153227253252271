import { IHydraMember } from '@interfaces/hydra-resource.interface';
import { EavAttributeModel } from '@components/eav-attribute/models/eav-attribute.model';
import { EavAttributeOptionModel } from '@components/eav-attribute/models/eav-attribute-option.model';
import * as moment from 'moment';

export class EavAttributeValueModel {
  '@id': string;
  id: number;
  attribute: EavAttributeModel;
  value: string|number|boolean|moment.Moment|EavAttributeOptionModel;

  constructor(attributeValue: IHydraMember) {
    this['@id'] = attributeValue['@id'];
    this.id = attributeValue.id;
    this.attribute = new EavAttributeModel(attributeValue.attribute);

    switch (attributeValue['@type']) {
      case 'EavAttributeValueDatetime':
        this.value = moment(attributeValue.value);
        break;
      case 'EavAttributeValueOption':
        this.value = new EavAttributeOptionModel(attributeValue.value);
        break;
      default:
        this.value = attributeValue.value;
        break;
    }
  }
}
