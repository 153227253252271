import {NgModule} from '@angular/core';
import {UnpairedListComponent} from '@components/unpaired/list/unpaired-list.component';
import {GenericModule} from '@components';
import {AbstractFiltersFieldsService} from '@components/generic/Form/filters/abstract-filters-fields.service';
import {UnpairedFiltersFormService} from '@components/unpaired/unpaired-filters-form.service';

@NgModule({
  declarations: [
    UnpairedListComponent,
  ],
  entryComponents: [
    UnpairedListComponent,
  ],
  imports: [
    GenericModule,
  ],
  providers: [
    { provide: AbstractFiltersFieldsService, useClass: UnpairedFiltersFormService },
  ],
})
export class UnpairedModule {}
