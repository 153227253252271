import { Resource } from '@decorators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractResource } from '@resources';
import { MarketplaceHelper } from '@helpers';
import { Observable } from 'rxjs/Observable';

@Injectable()
@Resource({
  entryPoint: '/v2/product_marketplaces/grc',
  isHydra: true,
  deleteAvailable: false,
  updateAvailable: true,
  createAvailable: false,
  cGetDefaultFilters: () => ({
    'marketplace': MarketplaceHelper.getWebsiteMarketplace().code,
    'product.masterProduct.detached': false,
  }),
})
export class GrcResource extends AbstractResource {

  constructor(http: HttpClient) {
    super(http);
  }

  public cGet(params: any = {}, options: any = {}): Observable<object> {
    if (params.sku) {
      params['product.sku'] = params.sku;
      delete params.sku;
    }

    if (params.reference) {
      params['product.superProduct.translations.reference'] = params.reference;
      delete params.reference;
    }

    return super.cGet(params, options);
  }

  public getProduct(productMarketplaceId: string): Observable<object> {
    return super.get(productMarketplaceId, {entryPoint: `/v2/product_marketplaces/${productMarketplaceId}/grc`});
  }

  public createFrequentlyAskedQuestion(body: any): Observable<object> {
    return super.create(body, {entryPoint: '/v2/frequently_asked_questions'});
  }

  public updateFrequentlyAskedQuestionTranslation(id: string, body: any): Observable<object> {
    return super.partialUpdate(null, body, {entryPoint: `/v2/frequently_asked_question_translations/${id}`});
  }

  public deleteFrequentlyAskedQuestion(id: string): Observable<object> {
    return super.remove(null, {entryPoint: `/v2/frequently_asked_questions/${id}`});
  }

  public getFrequentlyAskedQuestions(body: any): Observable<object> {
    return super.cGet(body, {entryPoint: '/v2/frequently_asked_questions', returnHydraMembers: true});
  }

  public updateFrequentlyAskedQuestionLikes(id: string, body: any): Observable<object> {
    return super.partialUpdate(null, body, {entryPoint: `/v2/frequently_asked_questions/${id}`});
  }
}
