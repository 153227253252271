import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { Component, Inject } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { RedirectsResource } from '@components/redirects/redirects.resource';
import { RedirectsFiltersFieldsService } from '@components/redirects/services/redirects-filters-fields.service';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { AbstractResource } from '@resources/abstract.resource';

/**
 * Entry component for list of items.
 *
 * For each entry list component like, we must provide an `AbstractResource` in the providers and an `AbstractFiltersFieldsService`
 * if the list can be filtered.
 * These services will be used in a generic way in the generic components like generic list.
 */
@Component({
  selector: 'app-redirect-list',
  template: require('./redirect-list.component.html'),
  styles: [require('./redirect-list.component.scss')],
  providers: [
    { provide: AbstractResource, useClass: RedirectsResource },
    { provide: AbstractFiltersFieldsService, useClass: RedirectsFiltersFieldsService }
  ]
})
export class RedirectListComponent extends AbstractPageComponent {

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }
}
