import { AbstractComponent } from '@components/generic/abstract.component';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AbstractResource } from '@resources/abstract.resource';
import { FormNotifierService } from '@services/form-notifier.service';
import { SnackbarService } from '@components/snackbar';
import { TaskAttachmentsResource } from '@components/generic/task-manager/resources';
import { IFileInfo } from '@components/generic/Form/file-uploader/interfaces/file-info.interface';
import { IImage } from '@components/generic/List';
import { takeUntil } from 'rxjs/operators';
import { ITask } from '@components/generic/task-manager/model';
import { ITaskAttachment } from '@components/generic/task-manager/interfaces';

@Component({
  selector: 'app-task-attachments',
  template: require('./task-attachments.component.html'),
  styles: [require('./task-attachments.component.scss')],
  providers: [
    { provide: AbstractResource, useClass: TaskAttachmentsResource },
  ],
})
export class TaskAttachmentsComponent extends AbstractComponent implements OnInit {

  public form: FormGroup;
  public filesList: ITaskAttachment[] = [];

  @Input() public task: ITask;

  @Output() private onCancelAttachments: EventEmitter<any> = new EventEmitter();
  @Output() private onSubmit: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    @Inject('DialogService') private dialog: any,
    private formNotifier: FormNotifierService,
    private snackbar: SnackbarService,
  ) {
    super($translate, authService, resource, state);
  }

  /**
   * @inheritDoc
   */
  ngOnInit(): void {
    this.buildForm();
    this.filesList = this.task.attachments;
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      files: [null]
    });
  }

  public submit(): void {
    this.dialog.confirm(this.translate('TASKS.FORM.SLIDING_SIDEBAR.ATTACHMENTS.SUBMIT'))
      .then(() => {
        this.form.value.files.subscriber
          .pipe(takeUntil(this.destroyed$))
          .subscribe((fileList: IFileInfo[]) => {
            const files = fileList;

            const fileLength = files ? files.length : 0;

            if (0 === fileLength) {
              this.snackbar.warn(this.translate('UPLOADER.NO_FILES_TO_UPLOAD'));

              return;
            }

            this.create(files, 0, fileLength);
          });
      })
    ;
  }

  private create(files: IFileInfo[], index: number, fileLength: number): void {
    (<TaskAttachmentsResource>this.resource).postAttachments(this.task.id, <File>files[index].rawFile)
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe((response: IImage) => {
        this.snackbar.validate(this.translate('SNACKBAR.VALIDATE.FILE_UPLOAD'));

        this.filesList = [...this.filesList, {
          id: response.id,
          originalPath: response.originalPath,
          thumbnailPath: response.thumbnailPath,
          fileName: response.fileName,
        }];

        fileLength--;
        if (0 === fileLength) {
          this.formNotifier.notifyFormSubmitted();

          this.onSubmit.emit(this.filesList);

          return;
        }

        index++;
        this.create(files, index, fileLength);
      })
    ;
  }

  public cancel(): void {
    this.onCancelAttachments.emit();
  }

  public delete(fileList: IImage[]): void {
    this.deleteFile(fileList, 0, fileList.length);
  }

  private deleteFile(filesList: IImage[], index: number, filesLength: number): void {
    (<TaskAttachmentsResource>this.resource).destroyFile(filesList[index].id, { dontUseModel: true })
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(() => {
        this.snackbar.validate(this.translate('SNACKBAR.VALIDATE.FILE_DELETED'));

        filesLength--;
        if (0 === filesLength) {
          return;
        }

        index++;
        this.deleteFile(filesList, index, filesLength);
      })
    ;
  }
}
