import { Component, Inject, OnInit } from '@angular/core';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { IDndColumn } from '@components/generic/dnd-list/dnd-list.component';
import { BASE_URL } from '@constants';
import { IHighlights } from './models';
import { WebsiteHomepageHighlightsItemsResource } from './website-homepage-highlights-items.resource';

@Component({
  selector: 'website-homepage-highlights-items-result-list',
  template: require('./website-homepage-highlights-items-result-list.component.html'),
  styles: [require('./website-homepage-highlights-items-result-list.component.scss')],
  providers: [
    { provide: AbstractResource, useClass: WebsiteHomepageHighlightsItemsResource },
  ],
})
export class WebsiteHomepageHighlightsItemsResultListComponent extends AbstractPageComponent implements OnInit {

  private _items: IHighlights[];

  set items(items: IHighlights[]) {
    this._items = items;
  }

  get items(): IHighlights[] {
    return this._items;
  }

  public columns: IDndColumn[];
  public BASE_URL = BASE_URL;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }

  public ngOnInit(): void {
    this.columns = [{
      size: 4,
      title: this.translate('PAGE.HIGHLIGHTS.LIST.TABLE.HEAD.IMAGE'),
      property: '',
      type: 'customColumnContent',
    },
      {
        size: 3,
        title: this.translate('PAGE.HIGHLIGHTS.LIST.TABLE.HEAD.LINK'),
        property: 'link',
        type: 'link'
      },
      {
        size: 1,
        title: this.translate('PAGE.HIGHLIGHTS.LIST.TABLE.HEAD.DATE.START'),
        property: 'startTime',
        type: 'date'
      },
      {
        size: 1,
        title: this.translate('PAGE.HIGHLIGHTS.LIST.TABLE.HEAD.DATE.END'),
        property: 'endTime',
        type: 'date'
      }];
  }
}
