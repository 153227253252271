import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { AbstractResource } from '@resources/abstract.resource';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  routeName: 'discount_cart',
  entryPoint: '/v2/discount_carts'
})
export class DiscountCartResource extends AbstractResource {
  constructor(http: HttpClient) {
    super(http);
  }

  public createDiscountCart(body: object): Observable<Object> {
    return this.create(body, {entryPoint: `${this.entryPoint}`});
  }

  public updateDiscountCart(id: string, body: object): Observable<Object> {
    return this.partialUpdate(null, body, {entryPoint: `${this.entryPoint}/${id}`});
  }
}
