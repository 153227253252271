import { Resource } from '@decorators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractResource } from '@resources';

export class DashboardIndicatorModel {
    public id: string;
    public value: number;

    constructor(dashboardIndicator: any) {
        this.id = dashboardIndicator.id;
        this.value = dashboardIndicator.value;
    }
}

@Injectable()
@Resource({
  routeName: 'indicator_dashboards',
  entryPoint: '/v2/indicator_dashboards',
  model: DashboardIndicatorModel,
  isHydra: true,
  cGetDefaultFilters: () => ({}),
})
export class DashboardIndicatorResource extends AbstractResource {

  constructor(http: HttpClient) {
    super(http);
  }

}
