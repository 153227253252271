import { AbstractResource } from '../../../../resources/abstract.resource';
import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  routeName: 'user_guide',
  entryPoint: '/v2/user_guides',
  translationKey: 'USER_GUIDE',
  isHydra: true,
})
export class UserGuideResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }
}
