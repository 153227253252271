import { Inject, Injectable } from '@angular/core';
import {
  ComboSearchField,
  DateField,
  MultiSearchField,
  SkuSearchField,
  TextField
} from '@components/generic/Form/dynamic/fields';
import { AbstractFormFieldBase } from '@components/generic/Form/dynamic/fields/abstract-form-field-base.class';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { DATE_FULL_FORMAT, LOCALE_FR } from '@constants';
import { FormNotifierService } from '@services';
import * as moment from 'moment';
import { ArrivalReasonResource } from '@resources/arrival-reason.resource';
import { SessionHelper } from '@helpers';
import { DropDownListField } from '@components/generic/Form/dynamic/fields/select-field.class';
import { SuperProductResource } from '@components/super-product/super-product.resource';

@Injectable()
export class SavArrivalFiltersFormService extends AbstractFiltersFieldsService {

    private superProductsField: ComboSearchField;
    private superProductResponse: any[];

    private startDateField: TextField = new TextField({
        fieldName: 'startDate',
        value: null,
        hidden: true
    });

    private endDateField: TextField = new TextField({
        fieldName: 'endDate',
        value: null,
        hidden: true
    });

    private reasonsField: MultiSearchField = new MultiSearchField({
        fieldName: 'reasons',
        label: 'PAGE.SAV.ARRIVAL.REASON',
        data: [],
        textField: 'label',
        valueField: 'value',
        value: this.filters.get('reasons') ? this.filters.get('reasons') : undefined,
        valuePrimitive: true,
    });

    constructor(
        @Inject('StateService') protected state: ng.ui.IStateService,
        @Inject('TranslationService') public $translate: ng.translate.ITranslateService,
        formNotifier: FormNotifierService,
        private arrivalReasonResource: ArrivalReasonResource,
        private superProductResource: SuperProductResource,
    ) {
        super(formNotifier, state);

        this.superProductResource.getSkus({ locale: SessionHelper.getLocale() })
            .takeUntil(this.destroyed$)
            .subscribe((response: any) => {
              this.superProductResponse = response;
            })
        ;

        this.superProductsField = new ComboSearchField({
            fieldName: 'parentSku',
            label: 'PAGE.PRODUCT.LIST.FILTER.SUPER_PRODUCT.LABEL',
            data: null,
            textField: 'label',
            valueField: 'skuParent',
            value: this.filters.get('skuParent') ? this.filters.get('skuParent') : undefined,
            valuePrimitive: true,
            order: 1,
            filterChange: this.filterChange.bind(this)
        });
    }

    private filterChange(sku: any) {
        this.superProductsField.data = this.superProductResponse.filter((object: any) => {
          return object.skuParent.startsWith(sku);
        });
    }

    public getFields(): AbstractFormFieldBase<any>[] {
        const typeOptions = [
          { label: this.$translate.instant('PAGE.SAV.ARRIVAL.MOVEMENT_TITLE'), value: 'stock_movement' },
          { label: this.$translate.instant('PAGE.SAV.ARRIVAL.ARRIVAL_TITLE'), value: 'arrival' },
        ];

        this.getReasons();
        const startDate = this.filters.get('date[before]');
        const endDate = this.filters.get('date[after]');

        const fields: AbstractFormFieldBase<any>[] = [
            new DateField({
                fieldName: 'date',
                label: 'PAGE.COMMERCIAL_OPERATIONS.LIST.FILTERS.DATE',
                dateRange: true,
                value: startDate && endDate ? [new Date(startDate), new Date(endDate)] : undefined,
                valueChangedAction: this.setDates.bind(this),
                order: 1
            }),
            this.reasonsField,
            new DropDownListField({
              fieldName: 'type',
              label: 'Type',
              data: typeOptions,
              textField: 'label',
              valueField: 'value',
              value: this.filters.get('type') ? this.filters.get('type') : null,
              valuePrimitive: true,
            }),
            new TextField({
              fieldName: 'sparePartId',
              label: 'PAGE.SPARE_PART.ID',
              value: this.filters.get('sparePartId') ? this.filters.get('sparePartId') : undefined,
            }),
            this.superProductsField,
            new SkuSearchField({
                fieldName: 'childSku[]',
                label: 'PAGE.ORDER.LIST.FILTER.SKU.LABEL',
                productType: 'product',
                value: 0 < this.filters.getAll('childSku[]').length ? this.filters.getAll('childSku[]') : [],
                valuePrimitive: true,
                limit: 2
            })
        ];

        return fields;
    }

    private setDates(newValue: string): void {
        if (newValue && null !== newValue[0]) {
            this.startDateField.value = moment(newValue[0]).startOf('day').format(DATE_FULL_FORMAT);
            this.endDateField.value = moment(newValue[1]).endOf('day').format(DATE_FULL_FORMAT);
        }
    }

    public getReasons(): void {
        this.arrivalReasonResource
            .cGet(
                { pagination: false },
                {
                    isHydra: true,
                    returnHydraMembers: true,
                    dontUseModel: true,
                    blocking: false,
                }
            )
            .takeUntil(this.destroyed$)
            .subscribe((response: any) => {
                this.reasonsField.data = response.map((reason: any): any => {
                    return {
                        value: +reason['id'],
                        label: (reason.translations[SessionHelper.getLocale()] || Object.values(reason.translations)[0]).reason,
                    };
                });
            });
    }
}
