import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MultiSelectComponent } from '@progress/kendo-angular-dropdowns';
import { MarketplaceHelper, SessionHelper } from '@helpers';
import { ISku } from '@interfaces';
import { ProductResource } from '@components/product/product.resource';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services/auth.service';
import { ProductMarketplaceResource } from '@components/product/product-marketplace.resource';
import { SkuSearchService } from '@services/sku-search.service';
import { IProductMarketplace, ISuperProduct } from '@components/product/interfaces/product-form.interface';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { SuperProductResource } from '@components/super-product/super-product.resource';

const PRODUCT_MARKETPLACE = 'productMarketplace';
const PRODUCT = 'product';
const SUPER_PRODUCT = 'superProduct';

@Component({
  selector: 'app-sku-search',
  template: require('./sku-search.component.html'),
})
export class SkuSearchComponent extends AbstractComponent implements OnInit, OnChanges {

  /**
   * Initial value
   */
  @Input() public value: string | string[];
  @Input() public limit: number;
  @Input() public valueField: string = 'sku';
  @Input() public isSparePart: boolean = false;
  @Input() public withSpareParts?: boolean = false;
  @Input() public formResetting: boolean;
  @Input() private productType: string;
  @Input() private countryCode: string;
  @Input() public valuePrimitive: boolean;
  @Input() public marketplaceCode: string;
  @Input() public minimalLengthToFilter: number = 3;
  @Input() public elementsToDisplay: number = 50;
  @Input() public allowCustom: boolean = false;

  @Output() public change: EventEmitter<any> = new EventEmitter();

  @ViewChild(MultiSelectComponent) private search: MultiSelectComponent;

  public skus: ISku[];
  public filteredResults: ISku[] = [];
  public currentValues: ISku[] = [];
  public currentCountryCode: string;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: ProductResource,
    private productMarketplaceResource: ProductMarketplaceResource,
    private superProductResource: SuperProductResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private skuSearchService: SkuSearchService,
  ) {
    super($translate, authService, resource, state);
  }

  /**
   * @inheritDoc
   */
  ngOnInit() {
    if (undefined !== this.limit && (isNaN(this.limit) || this.limit < 1)) {
      throw new Error(`Limit attribute should be a positive number, ${this.limit} given.`);
    }

    switch (this.productType) {
      case PRODUCT:
        this.getSkuProduct();
        break;
      case SUPER_PRODUCT:
        this.getSkuSuperProduct();
        break;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.formResetting) {
      this.currentValues = [];

      this.formResetting = false;
    }

    if (PRODUCT === this.productType && this.currentCountryCode !== this.countryCode) {
      this.getSkuProduct();
      this.currentCountryCode = this.countryCode;
    }

    if (SUPER_PRODUCT === this.productType && this.currentCountryCode !== this.countryCode) {
      this.getSkuSuperProduct();
      this.currentCountryCode = this.countryCode;
    }

    if (PRODUCT_MARKETPLACE === this.productType
      && changes.hasOwnProperty('value')
      && !changes.value.previousValue
      && changes.value.currentValue !== null) {
      this.getSpecificSkuProductMarketplace(changes.value.currentValue);
    }
  }

  private initValues(): void {
    if (this.value && (typeof this.value !== 'object' || Array.isArray(this.value))) {
      const values: any[] = Array.isArray(this.value) ? this.value : [this.value];
      this.valueChange(values.map((value: { [key: string]: string }) => {
        const existingSku = this.skus.find((item: any) => {
          return ('' + item[this.valueField] === '' + value[this.valueField] || '' + item[this.valueField] === '' + value);
        });

        return undefined !== existingSku ? existingSku : { id: undefined, sku: value };
      }));

      return;
    }

    this.resetFilters();
  }

  private getSkuProduct(): void {
    this.resource.getByCountryCodeAndSku(
      this.countryCode ? this.countryCode : SessionHelper.getCountry().code,
      this.marketplaceCode || MarketplaceHelper.getWebsiteMarketplace().code,
      { withSpareParts: null === this.withSpareParts ? null : +this.withSpareParts },
      { dontUseModel: true, blocking: false },
      this.isSparePart,
    )
      .takeUntil(this.destroyed$)
      .subscribe((results: ISku[]) => {
        this.skus = results;
        this.initValues();
      })
      ;
  }

  private getSpecificSkuProductMarketplace(values: { [key: string]: string|number }[]): void {
    const valuesIds: number[] = values.map((value: { id: number }) => value.id).filter((id: any) => id);
    const marketplace = this.marketplaceCode || MarketplaceHelper.getWebsiteMarketplace().code;
    this.productMarketplaceResource.cGetV2({
      marketplace,
      'pagination': false,
      'id[]': valuesIds.length > 0 ? valuesIds : values,
    }, { isHydra: true, returnHydraMembers: true, dontUseModel: true, blocking: true })
      .takeUntil(this.destroyed$)
      .subscribe((results: IProductMarketplace[]) => {
        this.skus = this.skuSearchService.sortSkus(results);
        this.initValues();
      })
      ;
  }

  private getSkuSuperProduct(): void {
    this.superProductResource.getSkus({ locale: SessionHelper.getLocale() })
      .takeUntil(this.destroyed$)
      .subscribe((superProducts: ISuperProduct[]) => {
        this.skus = superProducts.map((superProduct: ISuperProduct) => ({
          'id': superProduct.id,
          'sku': superProduct.skuParent,
        }));
        this.initValues();
      })
      ;
  }

  /**
   * Checks if the limit is set to 1
   *
   * @returns {boolean}
   */
  private hasUniqueResult(): boolean {
    return 1 === this.limit;
  }

  /**
   * Filters results list depending on value
   *
   * @param {ISku[]} results
   * @param {string} value
   * @returns {ISku[]}
   */
  private filter(results: ISku[] = [], value: string = ''): ISku[] {
    if (!results.length || !value || value.length < this.minimalLengthToFilter) {
      return [];
    }

    value = value.toLowerCase();
    return results.filter((product: any) => {
      if (undefined !== product.sku) {
        return product.sku.toLowerCase().includes(value);
      }
    });
  }

  /**
   * Resets filters
   */
  private resetFilters(): void {
    this.filteredResults = this.filter(this.skus).slice(0, this.elementsToDisplay);
  }

  /**
   * Changes filtered results list depending on current search
   *
   * @param {string} value
   */
  public filterChange(value: string): void {
    if (value.length < this.minimalLengthToFilter) {
      this.filteredResults = [];
    } else {
      if (PRODUCT_MARKETPLACE === this.productType) {
        const marketplace = this.marketplaceCode || MarketplaceHelper.getWebsiteMarketplace().code;
        this.productMarketplaceResource.cGetV2({
          marketplace,
          countryCode: this.countryCode,
          searchByPartialSku: value
        }, { isHydra: true, returnHydraMembers: true, dontUseModel: true, blocking: false })
          .takeUntil(this.destroyed$)
          .subscribe((results: IProductMarketplace[]) => {
            this.filteredResults = this.skuSearchService.sortSkus(results);
          })
        ;
      } else {
        this.filteredResults = this.filter(this.skus, value).slice(0, this.elementsToDisplay);
      }
      // is search change we show autocomplete results (even after hitting escape key)
      if (!this.search.isOpen) {
        this.search.toggle(true);
      }
    }
  }

  /**
   * Register and emit new values after a SKU selection
   *
   * @param values
   */
  public valueChange(values: any): void {
    this.resetFilters();

    // limits SKU to n latest selected
    if (undefined !== this.limit) {
      this.currentValues = values.slice(-this.limit);
    } else {
      this.currentValues = values;
    }

    // let's warn the world that value has changed
    this.change.emit(this.hasUniqueResult() ? this.currentValues[0] : this.currentValues);

    // auto close popover when max results have been reached
    if (this.limit === this.currentValues.length) {
      this.search.toggle(false);
    }
  }

  public valueNormalizer = (text: Observable<string>) => text.pipe(map((data: string) => ({ id: undefined, sku: data })));
}
