export default unpairedRoutes;

/** @ngInject **/
function unpairedRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'walipush',
    sidebarSubNav: 'unpaired',
    title: '',
    reloadChangeCountry: 'unpaired.list'
  };

  $stateProvider
    .state('unpaired', {
      url: '/unpaired',
      parent: 'walisoft',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.UNPAIRED',
        stateName: 'unpaired.list'
      },
    })
    .state('unpaired.list', {
      url: '',
      views: {
        'page@layout': {
          template: '<app-unpaired-list></app-unpaired-list>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.UNPAIRED.LIST.TITLE',
      })
    });
}
