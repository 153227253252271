'use strict'

angular
  .module 'ammsFrontendApp'
  .run [
    'editableOptions'
    'editableThemes'
    (editableOptions, editableThemes) ->
      editableThemes.bs3.inputClass = 'input-sm'
      editableThemes.bs3.buttonsClass = 'btn-sm'
      editableOptions.theme = 'bs3'
  ]
