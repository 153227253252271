export default commitmentRoutes;

/** @ngInject */
function commitmentRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'walipush',
    sidebarSubNav: 'orders',
    title: '',
    reloadChangeCountry: 'commitment.list',
  };

  $stateProvider
    .state('commitment', {
      url: '/commitment',
      parent: 'walipush',
      abstract: true,
      breadcrumb: {
        label: 'SIDEBAR.LABEL.ORDERS.COMMITMENT',
        stateName: 'commitment.list'
      },
    })
    .state('commitment.list', {
      url: `?
      page
      &byPage
      &sku
      country[]`,
      views: {
        'page@layout': {
          template: '<app-commitment-list></app-commitment-list>'
        }
      },
      breadcrumb: {
        label: 'SIDEBAR.LABEL.ORDERS.COMMITMENT',
      },
      data: Object.assign(commonStateData, {
        title: 'SIDEBAR.LABEL.ORDERS.COMMITMENT',
      })
    })
  ;
}
