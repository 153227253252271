import { AfterContentInit, Component, ContentChild, ElementRef, Inject, Input, ViewChild } from '@angular/core';
import { FormControl, FormControlName } from '@angular/forms';
import { FormErrorHelper } from '@helpers';
import { IFormViolation } from '@interfaces';

@Component({
  selector: 'app-form-row',
  template: require('./form-row.component.html')
})
export class FormRowComponent implements AfterContentInit {
  @Input() public label: string;
  @Input() public violations: IFormViolation[] = [];
  @Input() public property: string;
  @Input() public tooltipLabel: string;

  @ContentChild(FormControlName)
  private formControlName: FormControlName;

  @ViewChild('content')
  private content: ElementRef;

  private field: FormControl;

  constructor(@Inject('TranslationService') private $translate: ng.translate.ITranslateService) {}

  /**
   * @inheritDoc
   */
  ngAfterContentInit() {
    if (undefined !== this.formControlName) {
      this.field = this.formControlName.control;
    }
  }

  /**
   * Translates a string.
   */
  public translate(translation: string): string {
    return this.$translate.instant(`VALIDATORS.${translation}`);
  }

  /**
   * Checks if the field has validator.
   */
  public hasValidator(): boolean {
    return this.field && (null !== this.field.validator || null !== this.field.asyncValidator);
  }

  /**
   * Check if the field is valid.
   */
  public isValid(): boolean {
    return undefined === this.field || this.field.root.pristine || this.field.disabled || this.field.valid;
  }

  /**
   * Checks if the field failed a validator.
   */
  public hasError(type: string): boolean {
    return !this.isValid() && this.field.hasError(type);
  }

  /**
   * Gets API errors.
   */
  public getAPIErros(): string[] {
    return this.hasError(FormErrorHelper.ERROR_KEY) ? this.field.getError(FormErrorHelper.ERROR_KEY) : [];
  }

  /**
   * Checks if a field is a group of several elements or not.
   */
  public isGroup(): boolean {
    if (undefined === this.content) {
      return false;
    }

    const children = this.content.nativeElement.children;

    return (this.isValid() && children.length > 1) || (!this.isValid() && children.length > 2);
  }
}
