import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericFormModule } from '@components/generic/generic-form.module';
import { GenericModule } from '@components/generic/generic.module';
import { ProductModule } from '@components/product';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { FormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap';
import { GrcProductListComponent } from '@components/grc/grc-product-list.component';
import { GrcProductResultListComponent } from '@components/grc/grc-product-result-list.component';
import { GrcResource } from '@components/grc/grc.resource';
import { GrcProductDetailComponent } from '@components/grc/grc-product-detail.component';
import { ChartModule } from 'angular-highcharts';
import { GrcDashboardComponent } from '@components/grc/grc-dashboard.component';
import { TooltipModule } from 'ngx-tooltip';
import {GrcTicketsResultListComponent} from '@components/grc/grc-tickets-result-list.component';
import {GrcTicketResource} from '@components/grc/grc-ticket.resource';
import {GrcTicketsListComponent} from '@components/grc/grc-tickets-list.component';

@NgModule({
  declarations: [
    GrcProductListComponent,
    GrcProductResultListComponent,
    GrcProductDetailComponent,
    GrcDashboardComponent,
    GrcTicketsResultListComponent,
    GrcTicketsListComponent,
  ],
  entryComponents: [
    GrcProductListComponent,
    GrcProductResultListComponent,
    GrcProductDetailComponent,
    GrcDashboardComponent,
    GrcTicketsResultListComponent,
    GrcTicketsListComponent,
  ],
  imports: [
    CommonModule,
    GenericFormModule,
    GenericModule,
    ProductModule,
    DropDownListModule,
    FormsModule,
    BsDropdownModule,
    ChartModule,
    TooltipModule
  ],
  exports: [
    GrcProductListComponent,
    GrcProductResultListComponent,
    GrcProductDetailComponent,
    GrcDashboardComponent,
    GrcTicketsResultListComponent,
    GrcTicketsListComponent
  ],
  providers: [
    GrcResource,
    GrcTicketResource
  ],
})

export class GrcModule { }
