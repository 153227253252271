import { CategoriesResource } from '@components/categories';

export default categoriesRoutes;

/** @ngInject */
function categoriesRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'walipush',
    sidebarSubNav: 'categories',
    title: '',
    reloadChangeCountry: 'categories.new',
  };

  $stateProvider
    .state('categories', {
      url: '/categories',
      parent: 'walisoft',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.TAB',
        stateName: 'categories.list'
      }
    })
    .state('categories.list', {
      url: '',
      views: {
        'page@layout': {
          template: '<app-categories-list></app-categories-list>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.CATEGORIES.LIST.TITLE',
      })
    })
    .state('categories.new', {
      url: '/new',
      breadcrumb: { label: 'BREADCRUMB.NEW' },
      views: {
        'page@layout': { template: '<app-categories></app-categories>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.CATEGORIES.NEW.TITLE',
        tabs: [
          {
            label: 'PAGE.CATEGORIES.TABS.GENERAL',
            state: 'categories.new',
          },
          {
            label: 'PAGE.CATEGORIES.TABS.E_MERCH',
          },
          {
            label: 'PAGE.CATEGORIES.TABS.SEO',
          },
        ],
      })
    })
    .state('categories.edit', {
      url: '/:id',
      breadcrumb: { label: 'BREADCRUMB.EDIT' },
      views: {
        'page@layout': { template: '<app-categories></app-categories>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.CATEGORIES.EDIT.TITLE',
        tabs: [
          {
            label: 'PAGE.CATEGORIES.TABS.GENERAL',
            state: 'categories.edit',
          },
          {
            label: 'PAGE.CATEGORIES.TABS.E_MERCH',
            state: 'categories.edit.superProducts',
          },
          {
            label: 'PAGE.CATEGORIES.TABS.SEO',
            state: 'categories.edit.seo',
          },
          {
            label: 'PAGE.CATEGORIES.CONTENT_WEBSITE',
            state: 'categories.edit.blocks',
          },
        ],
      })
    })
    .state('categories.edit.superProducts', {
      url: '/super-products',
      breadcrumb: { label: 'BREADCRUMB.E_MERCH' },
      views: {
        'page@layout': { template: '<app-super-products-form></app-super-products-form>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.CATEGORIES.EDIT.TITLE',
        tabs: [
          {
            label: 'PAGE.CATEGORIES.TABS.GENERAL',
            state: 'categories.edit',
          },
          {
            label: 'PAGE.CATEGORIES.TABS.E_MERCH',
            state: 'categories.edit.superProducts',
          },
          {
            label: 'PAGE.CATEGORIES.TABS.SEO',
            state: 'categories.edit.seo',
          },
          {
            label: 'PAGE.CATEGORIES.CONTENT_WEBSITE',
            state: 'categories.edit.blocks',
          },
        ],
      })
    })
    .state('categories.edit.seo', {
      url: '/seo',
      breadcrumb: { label: 'BREADCRUMB.SEO' },
      views: {
        'page@layout': { template: '<app-seo-form></app-seo-form>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.CATEGORIES.EDIT.TITLE',
        tabs: [
          {
            label: 'PAGE.CATEGORIES.TABS.GENERAL',
            state: 'categories.edit',
          },
          {
            label: 'PAGE.CATEGORIES.TABS.E_MERCH',
            state: 'categories.edit.superProducts',
          },
          {
            label: 'PAGE.CATEGORIES.TABS.SEO',
            state: 'categories.edit.seo',
          },
          {
            label: 'PAGE.CATEGORIES.CONTENT_WEBSITE',
            state: 'categories.edit.blocks',
          },
        ],
      })
    })
    .state('categories.edit.blocks', {
      url: '/blocks',
      breadcrumb: { label: 'PAGE.CATEGORIES.CONTENT_WEBSITE' },
      views: {
        'page@layout': { template: '<app-category-blocks-form></app-category-blocks-form>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.CATEGORIES.EDIT.TITLE',
        tabs: [
          {
            label: 'PAGE.CATEGORIES.TABS.GENERAL',
            state: 'categories.edit',
          },
          {
            label: 'PAGE.CATEGORIES.TABS.E_MERCH',
            state: 'categories.edit.superProducts',
          },
          {
            label: 'PAGE.CATEGORIES.TABS.SEO',
            state: 'categories.edit.seo',
          },
          {
            label: 'PAGE.CATEGORIES.CONTENT_WEBSITE',
            state: 'categories.edit.blocks',
          },
        ],
      })
    })
  ;
}
