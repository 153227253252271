import { Component, Inject, OnInit } from '@angular/core';
import { SessionHelper } from '@helpers/session.helper';
import { ICountry } from '@interfaces/ICountry';

@Component({
  selector: 'app-select-country',
  template: require('./select-country.component.html'),
  styles: [require('./select-country.component.scss')],
})

export class SelectCountryComponent implements OnInit {
  public currentCountries: ICountry[];
  public currentCountry: ICountry;

  constructor(
    @Inject('CountryService') private countryService: any,
  ) {

  }

  ngOnInit(): void {
    this.currentCountries = SessionHelper.getCountries();
    this.currentCountry = SessionHelper.getCountry();
  }

  public changeCountry(countryCode: string): void {
    this.countryService.changeCountry(countryCode);
  }
}
