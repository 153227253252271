import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractFormComponent } from '@components/generic/Form/abstract-form.component';
import { CategorySatelliteResource } from '@components/categories/categories-satellite.resource';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { AbstractResource } from '@resources/abstract.resource';
import { CategorySatelliteModel, CategorySatelliteTranslationModel, ICategorySatellite } from '@components/categories/models';
import { INDEX_FOLLOW, NO_INDEX_FOLLOW, NO_INDEX_NO_FOLLOW } from '../seo-form';
import { HydraHelper } from '@helpers/HydraHelper';
import { Subscription } from 'rxjs/Subscription';
import { WebsiteCategoriesResource } from '@components/cms/website_categorie.resource';
import { CategoryResource } from '@resources/category.resource';
import { INPUT_NUMBER_PATTERN_DEC } from '@constants';

@Component({
  selector: 'app-categories-satellite-form',
  template: require('./categories-satellite-form.component.html'),
  providers: [
    { provide: AbstractResource, useClass: CategorySatelliteResource },
  ],
})
export class CategoriesSatelliteFormComponent extends AbstractFormComponent implements OnInit {
  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    @Inject('DialogService') private dialog: any,
    private formBuilder: FormBuilder,
    private categoryResource: CategoryResource,
    private websiteCategoriesResource: WebsiteCategoriesResource
  ) {
    super($translate, authService, resource, state);
  }

  public form: FormGroup;
  public groupedAttributes: any[] = [];
  public seoMetaRobotOptions: any[] = [
    INDEX_FOLLOW,
    NO_INDEX_FOLLOW,
    NO_INDEX_NO_FOLLOW,
  ];
  private isEdit = this.state.current.name === `${this.resource.routeName}.edit`;
  public haveOldAttribute = false;
  private model: ICategorySatellite;


  ngOnInit(): void {
    if (this.isEdit) {
      this.resource.get(+this.state.params[this.resource.goToEditKey])
        .takeUntil(this.destroyed$)
        .subscribe((response: any) => {
          this.model = new CategorySatelliteModel(response);
          if (this.model.id !== +this.state.params[this.resource.goToEditKey]) {
            this.actions.list.go({}, { reload: true, notify: true });
          }
          this.fetchAttributes();
        });
    } else {
      this.actions.delete.available = false;
      this.fetchAttributes();
    }
  }

  private buildForm(): void {
    let attributeFamilies: any[] = [];
    if (this.model && this.model.attributeFamilies) {
      attributeFamilies = this.groupedAttributes.filter((attribute: any) => {
        return this.model.attributeFamilies.indexOf(attribute.value) !== -1;
      });
      this.haveOldAttribute = attributeFamilies.length !== this.model.attributeFamilies.length;
    }

    const hasLocale = this.model && this.model.translations.hasOwnProperty(this.currentLocale);
    this.form = this.formBuilder.group({
      referenceCategory: [this.model ? this.model.referenceCategory : HydraHelper.buildIri(this.state.params.id, 'categories')],
      active: [hasLocale ? this.model.translations[this.currentLocale].active : false],
      seoMetaRobot: [hasLocale ? this.model.translations[this.currentLocale].seoMetaRobot : null],
      name: [hasLocale ? this.model.translations[this.currentLocale].name : null],
      shortDescription: [hasLocale ? this.model.translations[this.currentLocale].shortDescription : null],
      longDescription: [hasLocale ? this.model.translations[this.currentLocale].longDescription : null],
      metaDescriptionSeo: [hasLocale ? this.model.translations[this.currentLocale].metaDescriptionSeo : null],
      titleSeo: [hasLocale ? this.model.translations[this.currentLocale].titleSeo : null],
      locale: [this.currentLocale],
      attributeFamilies: [attributeFamilies],
      minPrice: [this.model ? this.model.minPrice : null, Validators.pattern(INPUT_NUMBER_PATTERN_DEC)],
      maxPrice: [this.model ? this.model.maxPrice : null, Validators.pattern(INPUT_NUMBER_PATTERN_DEC)],
    });
  }

  public update(updateAndReturn: boolean) {
    const data = Object.assign({}, this.form.value);
    data.attributeFamilies = data.attributeFamilies.map((attributeFamily: any) => {
      return attributeFamily.value;
    });

    const translation = new CategorySatelliteTranslationModel(data);
    data.translations = {
      [translation.locale]: translation
    };
    if (
      (typeof data.minPrice === 'number' || typeof data.maxPrice === 'number')
      && (isNaN(data.minPrice) || null === data.minPrice || isNaN(data.maxPrice) || null === data.maxPrice)
    ) {
      data.translations[translation.locale].active = false;
    }
    const model = new CategorySatelliteModel(data);
    if (this.model) {
      if (this.model.translations.hasOwnProperty(this.currentLocale)) {
        model.translations[this.currentLocale].id = this.model ? this.model.translations[this.currentLocale]['@id'] : null;
      }
      for (const locale in this.model.translations) {
        if (Object.prototype.hasOwnProperty.call(this.model.translations, locale)) {
          const modelTranslation = this.model.translations[locale];
          if (locale !== this.currentLocale) {
            model.translations[locale] = modelTranslation;
            model.translations[locale].id = modelTranslation['@id'] || null;
          }
        }
      }
    }
    const resourceSubscriber: Subscription = (
      this.isEdit ?
        this.resource.partialUpdate(+this.state.params[this.resource.goToEditKey], model) :
        this.resource.create(model)
    ).subscribe((response: any) => {
      this.actions[updateAndReturn ? 'list' : 'update'].go(response.id, { reload: true, notify: true });
    }, (error: any) => {
      console.error(error);
    }, () => resourceSubscriber.unsubscribe());
  }

  public cancel() {
    this.actions.list.go({}, { reload: true, notify: true });
  }

  public delete() {
    this.dialog.confirm(this.translate('PAGE.CATEGORIES_SATELLITE.CONFIRM.DELETE'))
      .then(() => {
        const resourceSubscriber: Subscription = this.resource.remove(+this.state.params[this.resource.goToEditKey])
          .subscribe(() => {
            this.actions.list.go({}, { reload: true, notify: true });
          }, (error: any) => {
            console.error(error);
          }, () => resourceSubscriber.unsubscribe());
      });
  }

  private fetchAttributes() {
    const categoryId = this.state.params.id;
    this.categoryResource.getAltSlugsFromId(categoryId)
      .takeUntil(this.destroyed$)
      .subscribe((categoryResponse: any) => {
        let slug: string = null;
        const locale: string = this.currentLocale,
          isUniverse: number = 0;
        if (categoryResponse.hasOwnProperty(locale) && categoryResponse[locale].hasOwnProperty('slug')) {
          slug = categoryResponse[locale].slug;
        }
        if (slug) {
          this.websiteCategoriesResource.cGet(
            { slug, isUniverse, locale },
            { dontUseModel: true }
          ).takeUntil(this.destroyed$)
            .subscribe((websiteCategoriesResponse: any) => {
              const attributes = websiteCategoriesResponse.filters.attributes;
              this.groupedAttributes = this.groupAttributes(attributes);
              this.buildForm();
            });
        } else {
          this.actions.list.go({}, { reload: true, notify: true });
        }
      });
  }

  private groupAttributes(attributes: any[]) {
    attributes = [].concat(...Object.values(attributes).map(e => Object.values(e)));
    const transformedAttributes: any[] = [];
    for (let i = 0; i < attributes.length; i++) {
      const attribute = attributes[i];
      transformedAttributes.push({
        name: `${attribute.name}: ${attribute.value}`,
        value: HydraHelper.buildIri(attribute.id, 'attribute_families')
      });
    }

    return transformedAttributes;
  }
}
