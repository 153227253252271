import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { AbstractResource } from '@resources';
import { HttpClient } from '@angular/common/http';
import { SessionHelper } from '@helpers';

@Injectable()
@Resource({
  entryPoint: '/v2/catalog_marketplaces',
  isHydra: true,
  returnHydraMembers: true,
  deleteAvailable: true,
  updateAvailable: true,
  cGetDefaultFilters: () => ({ 'order[updatedAt]': 'desc', 'exists[catalog]': true, countryCode: SessionHelper.getCountry().code })
})
export class MarketplacesCommissionResource extends AbstractResource {
  constructor(
    http: HttpClient,
  ) {
    super(http);
  }
}
