import { SessionHelper, UrlHelper } from '@helpers';
import { LOCALE_FR } from './country';

export const WEBSITE_URLS: any = {
  fr_FR: 'https://www.alicesgarden.fr',
  es_ES: 'https://www.alicesgarden.es',
  en_GB: 'https://www.alicesgarden.co.uk',
  fr_BE: 'https://fr.alicesgarden.be',
  nl_BE: 'https://nl.alicesgarden.be',
  it_IT: 'https://www.alicesgarden.it',
  nl_NL: 'https://www.alicesgarden.nl',
  pt_PT: 'https://www.alicesgarden.pt',
};


export function getWebsiteHostFromEnv() {
  return getWebsiteHostFromEnvForLocale(SessionHelper.getCountry().defaultLocale);
}

export function getWebsiteHostFromEnvForLocale(locale: string) {
  locale = Object.keys(WEBSITE_URLS).indexOf(locale) !== - 1 ? locale : LOCALE_FR;
  const currentHostname = (new URL((new UrlHelper()).getBaseUrl())).hostname;
  let websiteBaseUrl = WEBSITE_URLS[locale];
  switch (currentHostname) {
    case 'localhost':
      websiteBaseUrl = websiteBaseUrl.replace(/https:\/\/((www\.)|(\w+\.))alicesgarden(\.(\w+))+/, `https://$3site$5.lan`);
      break;
    case 'preprod-bo.alicesgarden.solutions':
      websiteBaseUrl = websiteBaseUrl.replace(/https:\/\/((www)|(\w+))(.*)/, `https://preprod-$3$4`).replace(/preprod-\./, `preprod.`);
      break;
  }

  return websiteBaseUrl;
}
