import { Component, Inject, OnInit } from '@angular/core';
import { SessionHelper } from '@helpers/session.helper';
import { LoaderService } from '@components/loader';

@Component({
  selector: 'app-select-locale',
  template: require('./select-locale.component.html'),
  styles: [require('./select-locale.component.scss')],
  providers: [LoaderService]
})

export class SelectLocaleComponent implements OnInit {
  public currentLocales: any[];
  public currentLocale: any;

  constructor(
    @Inject('CountryService') private countryService: any,
    @Inject('RootscopeService') private $rootScope: any,
    private loaderService: LoaderService,
  ) {}

  ngOnInit(): void {
    this.currentLocales = SessionHelper.getCountry().locales.map(this.formatLocale);
    this.currentLocale = this.formatLocale(SessionHelper.getLocale());
  }

  public changeLocale(locale: string): void {
    this.loaderService.add('switch-locale');
    SessionHelper.setCurrentUserLocale(locale);
    this.loaderService.remove('switch-locale');
    this.$rootScope.$emit('$stateCountryChangeSuccess');
  }

  public formatLocale(locale: string): {name: string, shortName: string} {
    return {
      name: locale,
      shortName: locale.toUpperCase().split('_').shift()
    };
  }
}
