import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import {AbstractControl, FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import { SnackbarService } from '../../../snackbar/snackbar.service';
import {AbstractResource, CarrierGroupResource} from '@resources';
import { AuthService, FormNotifierService } from '@services';
import { AbstractComponent } from '@components/generic/abstract.component';
import {INPUT_NUMBER_PATTERN_DEC, INPUT_NUMBER_PATTERN_NODEC} from '@constants';

@Component({
  selector: 'app-carrier-edit-constraint-form',
  template: require('./carrier-edit-constraint-form.component.html'),
  providers: [
    { provide: AbstractResource, useClass: CarrierGroupResource },
  ]
})
export class CarrierEditConstraintFormComponent extends AbstractComponent {
  @Input() public form: AbstractControl;
  @Input() public isCombo: boolean;
  @Input() public indexCombo: number = 0;

  @Input() public configurationTypes: any[] = [];
  @Input() public authorizedConfigurationTypesId: string[] = [];
  @Input() public ignoredConfigurations: any[] = [];

  public configurationLevel = [
    {code: 'error', translations: 'ERROR'},
    {code: 'warning', translations: 'WARNING'},
    {code: '', translations: ''},
  ];

  public configurationOperator = [
    {code: '<='},
    {code: '<'},
    {code: '>='},
    {code: '>'},
    {code: ''},
  ];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: CarrierGroupResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private snackbar: SnackbarService,
    @Inject('DialogService') private dialog: any,
    public formNotifier: FormNotifierService,
  ) {
    super($translate, authService, resource, state);
  }

  get constraintsFormArray(): FormArray {
    if (!this.form.get('constraints')) {
      return this.form as FormArray;
    }
    return this.form.get('constraints') as FormArray;
  }

  private createConstraintFormGroup(id: number = null, type: string = null, value: number = null, level: string = 'error', operator: string = '>'): FormGroup {
    return new FormGroup({
      id: new FormControl(id),
      type: new FormControl(type, Validators.required),
      value: new FormControl(value, [Validators.required, Validators.pattern(INPUT_NUMBER_PATTERN_DEC)]),
      operator: new FormControl(operator),
      level: new FormControl(level)
    });
}

private createConstraintComboFormGroup(id: number = null, type: string = null, level: string = 'error'): FormGroup {
  return new FormGroup({
    id: new FormControl(id),
    type: new FormControl(type, Validators.required),
    level: new FormControl(level),
    combos: new FormArray([])
  });
}

  public addConstraint(index: number, indexCombo: number): void {
    (<FormArray> this.constraintsFormArray.at(index).get('combos')).push(this.createConstraintFormGroup());
  }

  public getMargin(index: number): string {
    return (-(index * 10)) + 'px';
  }

  public removeConstraint(index: number, indexCombo: number): void {
    this.constraintsFormArray.removeAt(index);
    this.constraintsFormArray.markAsDirty();
  }

  public changeType(event: any, constraint: any, index: number, indexCombo: number): void {
    (<FormArray> this.constraintsFormArray).removeAt(index);

    let formGroup = null;
    if (event.code.includes('combo')) {
      formGroup = this.createConstraintComboFormGroup(null, event['@id']);
    } else {
      formGroup = this.createConstraintFormGroup(null, event['@id']);
    }

    (<FormArray> this.constraintsFormArray).insert(index, formGroup);
    this.constraintsFormArray.markAsDirty();
  }
}
