import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogisticWaveListComponent } from './list/logistic-wave-list.component';
import {GenericModule} from '@components/generic';
import { LogisticWaveResultListComponent } from './list/logistic-wave-result-list.component';

@NgModule({
  declarations: [LogisticWaveListComponent, LogisticWaveResultListComponent],
  entryComponents: [LogisticWaveListComponent, LogisticWaveResultListComponent],
  providers: [],
  imports: [
    CommonModule,
    GenericModule
  ]
})
export class LogisticWaveModule { }
