import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * The DumpPipe is a pipe that logs the value of the input to the console. It is useful for debugging purposes.
 * Use it like this: {{ someValue | dump }}
 * It will now console.log whenever the value of someValue changes.
 */

@Pipe({
  name: 'dump',
  pure: false
})

export class DumpPipe implements PipeTransform {
  private previousValue: any;
  constructor(private sanitizer: DomSanitizer) {}
  transform(value: any): void {
    if (value !== this.previousValue) {
      console.log(value);
      this.previousValue = value;
    }
  }
}
