import { IHydraMember } from '@interfaces/hydra-resource.interface';

export class SupplierAddressModel {
  '@id': string;
  id: number;
  firstName: string;
  lastName: string;
  countryCode: string;
  address: string;
  additionalAddress?: string;
  city: string;
  postcode: string;
  province?: string;
  email: string;
  phoneNumber: string;

  constructor(object: IHydraMember) {
    this['@id'] = object['@id'];
    this.id = object.id;
    this.firstName = object.firstName;
    this.lastName = object.lastName;
    this.countryCode = object.countryCode;
    this.address = object.addressLine1;
    this.additionalAddress = object.addressLine2;
    this.city = object.city;
    this.postcode = object.postcode;
    this.province = object.province;
    this.email = object.email;
    this.phoneNumber = object.phone1;
  }
}
