import { OrderCollectionResource } from '@components/order-collection/order-collection.resource';

export default OrderCollectionRoutes;

/** @ngInject */
function OrderCollectionRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'walisoft',
    sidebarSubNav: 'orders',
    title: '',
    reloadChangeCountry: 'order-collection.list',
  };

  $stateProvider
    .state('order-collection', {
      url: '/order-collection',
      parent: 'walisoft',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.ORDER.ORDER_COLLECTION',
        stateName: 'order-collection.list'
      },
      data: Object.assign(commonStateData, {
        showCountries: false
      })
    })
    .state('order-collection.list', {
      url: `?
        page
        &byPage
      `,
      views: {
        'page@layout': {
          template: '<app-order-collection-list></app-order-collection-list>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.ORDER_COLLECTION.LIST.TITLE',
        resource: OrderCollectionResource
      })
    })
  ;
}
