import { Resource } from '@decorators/Resource';
import { Injectable } from '@angular/core';
import { AbstractResource } from '@resources/abstract.resource';
import { HttpClient } from '@angular/common/http';
import { MasterProductsWarehousesModel } from '@models';

@Injectable()
@Resource({
  entryPoint: '/v2/master_products_warehouses',
  translationKey: 'MASTER_PRODUCTS_WAREHOUSES',
  model: MasterProductsWarehousesModel,
  isHydra: true,
})
export class MasterProductsWarehousesResource extends AbstractResource {
  constructor(
    http: HttpClient,
  ) {
    super(http);
  }
}
