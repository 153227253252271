import { ICustomer } from '@components/customer/interfaces/customer.interface';
import { IHydraMember } from '@interfaces/hydra-resource.interface';

export class CustomerModel implements ICustomer {
  public id: number;
  public fullName: string;
  public username: string;
  public locale: string;
  public phones: string[];
  public postcodes: string[];
  public cities: string[];
  public countries: string[];
  public marketplaces: string[];
  public numberOfOrder: number;
  public totalPrice: number;
  public createdAt: string;
  public perfectMatch: boolean;

  constructor(customer: IHydraMember) {
    this.id = customer.id;
    this.fullName = customer.fullName;
    this.username = customer.username;
    this.locale = customer.locale;
    this.phones = customer.phones.join(', ');
    this.postcodes = customer.postcodes.join(', ');
    this.cities = customer.cities.join(', ');
    this.countries = customer.countries.join(', ');
    this.marketplaces = customer.marketplaces.join(', ');
    this.numberOfOrder = customer.numberOfOrder;
    this.totalPrice = customer.totalPrice.toFixed(2);
    this.createdAt = customer.createdAt;
    this.perfectMatch = customer.perfectMatch;
  }
}
