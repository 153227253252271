import { Inject, Injectable } from '@angular/core';
import { FormNotifierService } from '@services/form-notifier.service';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { AbstractFormFieldBase } from '@components/generic/Form/dynamic/fields/abstract-form-field-base.class';
import {
  DateField,
  MultiSearchField,
  TextField
} from '@components/generic/Form/dynamic/fields';
import * as moment from 'moment';
import { CODE_LU, DATE_FULL_FORMAT } from '@constants';
import { SessionHelper } from '@helpers';
import { CODE_BE, CODE_ES, CODE_FR, CODE_GB, CODE_IT, CODE_MC, CODE_NL, CODE_PT, CODE_DE, CODE_PL } from '@constants';

interface IDropdownData {
  label: string;
  value: string;
}

@Injectable()
export class SavOrdersFiltersFormService extends AbstractFiltersFieldsService {

  COUNTRIES = [
    { label: 'France', value: CODE_FR },
    { label: 'Belgique', value: CODE_BE },
    { label: 'Espagne', value: CODE_ES },
    { label: 'Belgique', value: CODE_BE },
    { label: 'Angleterre', value: CODE_GB },
    { label: 'Pays-Bas', value: CODE_NL },
    { label: 'Portugal', value: CODE_PT },
    { label: 'Italie', value: CODE_IT },
    { label: 'Allemagne', value: CODE_DE },
    { label: 'Luxembourg', value: CODE_LU },
    { label: 'Pologne', value: CODE_PL }
  ];

  SAV_STATUTS = [ 'new', 'accepted' , 'preparing', 'shipped'];

  private dateStartField: TextField = new TextField({
    fieldName: 'dateStart',
    value: null,
    hidden: true
  });

  private dateEndField: TextField = new TextField({
    fieldName: 'dateEnd',
    value: null,
    hidden: true
  });

  private countryCodeField: MultiSearchField = new MultiSearchField({
    fieldName: 'countries[]',
    label: 'PAGE.ORDER.LIST.FILTER.COUNTRY.LABEL',
    data: null,
    textField: 'label',
    valueField: 'value',
    value: null,
    defaultItem: { label: this.translate.instant('PAGE.ORDER.LIST.FILTER.COUNTRY.DATA.0'), value: null },
    valuePrimitive: true,
    readonly: false,
  });

  private static setDates(newValue: string, startField: TextField, endField: TextField): void {
    if (!newValue || null === newValue[0]) {
      return;
    }

    startField.value = moment(newValue[0]).startOf('day').format(DATE_FULL_FORMAT);
    endField.value = moment(newValue[1]).endOf('day').format(DATE_FULL_FORMAT);
  }

  constructor(
    @Inject('StateService') protected state: ng.ui.IStateService,
    @Inject('TranslationService') public translate: ng.translate.ITranslateService,
    formNotifier: FormNotifierService,
  ) {
    super(formNotifier, state);
  }

  public getFields(): AbstractFormFieldBase<any>[] {
    const countriesList: IDropdownData[] = [...this.COUNTRIES];
    this.countryCodeField.value = 0 < this.filters.getAll('countries[]').length ? this.filters.getAll('countries[]') : undefined,
      this.countryCodeField.data = countriesList;

    return [
      new TextField({
        fieldName: 'search',
        label: 'PAGE.ORDER_MANAGER.LIST.FILTER.SEARCH',
        value: this.filters.get('search') ? this.filters.get('search') : undefined,
      }),
      new MultiSearchField({
        fieldName: 'orderId[]',
        data: [],
        label: 'PAGE.ORDER_MANAGER.LIST.FILTER.SEARCHES',
        value: 0 < this.filters.getAll('orderId[]').length ? this.filters.getAll('orderId[]') : undefined,
        order: 3,
        allowCustom: true
      }),

      this.countryCodeField
    ];
  }

  getComplementaryFields(): AbstractFormFieldBase<any>[] {
    const dateStart = this.filters.get('dateStart');
    const dateEnd = this.filters.get('dateEnd');

    if (dateStart && dateEnd) {
      this.dateStartField.value = dateStart;
      this.dateEndField.value = dateEnd;
    }

    return [
      new DateField({
        label: 'PAGE.ORDER.LIST.FILTER.ORDER_DATE.LABEL',
        fieldName: 'date',
        dateRange: true,
        value: dateStart && dateEnd ? [new Date(dateStart), new Date(dateEnd)] : undefined,
        valueChangedAction: this.setDateValue.bind(this),
      }),

      this.dateStartField,
      this.dateEndField,

      new MultiSearchField({
        fieldName: 'carriers[]',
        label: 'PAGE.ORDER.LIST.FILTER.CARRIERS.LABEL',
        data: SessionHelper.getAllCarrierGroups(),
        textField: 'name',
        valueField: 'code',
        value: 0 < this.filters.getAll('carriers[]').length ? this.filters.getAll('carriers[]') : undefined,
        valuePrimitive: true,
      }),

      new MultiSearchField({
        fieldName: 'orderManagerStatuses[]',
        label: 'PAGE.ORDER.LIST.FILTER.STATUSES.LABEL',
        data: this.SAV_STATUTS.map((status) => {
          return {
            label: this.translate.instant(`PAGE.SAV.ORDERS_LIST.FILTERS.STATUS.${status.toUpperCase()}`),
            value: status,
          };
        }),
        textField: 'label',
        valueField: 'value',
        value: 0 < this.filters.getAll('orderManagerStatuses[]').length ? this.filters.getAll('orderManagerStatuses[]') : [],
        valuePrimitive: true,
      }),

      new TextField({
        fieldName: 'sparePartId',
        label: 'PAGE.SPARE_PART.ID',
        value: this.filters.get('sparePartId') ? this.filters.get('sparePartId') : undefined,
      }),
    ];
  }

  private setDateValue(newValue: string): void {
    if (!newValue || null === newValue[0]) {
      return;
    }
    this.dateStartField.value = moment(newValue[0]).startOf('day').format(DATE_FULL_FORMAT);
    this.dateEndField.value = moment(newValue[1]).endOf('day').format(DATE_FULL_FORMAT);
  }
}
