import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'internationalPrefix'})

export class NumberToInternationalPrefix implements PipeTransform {
    private kilo = Math.pow(10, 3);
    private mega = Math.pow(10, 6);
    private giga = Math.pow(10, 9);
    private tera = Math.pow(10, 12);

    transform(value: number): string {
        if (value < this.kilo) {
            return value.toString();
        }

        if (value < this.mega) {
            return `+${Math.floor(value / this.kilo)}K`;
        }

        if (value < this.giga) {
            return `+${Math.floor(value / this.mega)}M`;
        }

        if (value < this.tera) {
            return `+${Math.floor(value / this.giga)}G`;
        }

        return '∞';
    }
}
