import { Injectable } from '@angular/core';
import { IAddress } from '@models/address.interface';
import { AbstractFormFieldBase } from '@components/generic/Form/dynamic/fields/abstract-form-field-base.class';
import { TextField } from '@components/generic/Form/dynamic/fields';
import { IAddressesFields, ICustomerDetailAddresses, ICustomerDetailOrderAddress } from '@components/customer/interfaces';
import { ADDRESS_TYPES } from '../../../enums/address-types.enum';

@Injectable()
export class CustomerFieldsFormService {

  /**
   * Gets addresses fields list with address id for editable address form.
   */
  public getAddressesFields(customerAddresses: ICustomerDetailAddresses): IAddressesFields[][] {

    return Object.keys(customerAddresses).map((addressType: string) => {
      return this.getFields(customerAddresses[addressType], addressType);
    });
  }

  private getFields(addresses: any, addressType: string): IAddressesFields[] {
    const translationDomain: string = 'PAGE.CUSTOMER_CARD.DETAILS.ADDRESSES.LIST.FIELD';

    return addresses.map((item: IAddress|ICustomerDetailOrderAddress) => {
      let address: IAddress|ICustomerDetailOrderAddress;
      let response: IAddressesFields = {id: '', fields: [], type: ''};

      if (ADDRESS_TYPES.BILLING === addressType || ADDRESS_TYPES.SHIPPING === addressType) {
        address = item.address;

        response = Object.assign(response, {
          orderId: item.orderId,
          date: item.date,
          marketplace: item.marketplace,
        });

      } else {
        address = item;
      }

      const fields: AbstractFormFieldBase<any>[] = [
        new TextField({
          label: `${translationDomain}.NAME`,
          fieldName: 'name',
          value: address.name,
        }),

        new TextField({
          label: `${translationDomain}.LASTNAME`,
          fieldName: 'lastName',
          value: address.lastName,
        }),

        new TextField({
          label: `${translationDomain}.FIRSTNAME`,
          fieldName: 'firstName',
          value: address.firstName,
        }),

        new TextField({
          label: `${translationDomain}.COMPANY`,
          fieldName: 'company',
          value: address.company,
        }),

        new TextField({
          label: `${translationDomain}.PHONE1`,
          fieldName: 'phone1',
          value: address.phone1,
        }),

        new TextField({
          label: `${translationDomain}.PHONE2`,
          fieldName: 'phone2',
          value: address.phone2,
        }),

        new TextField({
          label: `${translationDomain}.ADDRESSE_LINE_1`,
          fieldName: 'addressLine1',
          value: address.addressLine1,
        }),

        new TextField({
          label: `${translationDomain}.ADDRESSE_LINE_2`,
          fieldName: 'addressLine2',
          value: address.addressLine2,
        }),

        new TextField({
          label: `${translationDomain}.ADDRESSE_LINE_3`,
          fieldName: 'addressLine3',
          value: address.addressLine3,
        }),

        new TextField({
          label: `${translationDomain}.POSTCODE`,
          fieldName: 'postcode',
          value: address.postcode,
        }),

        new TextField({
          label: `${translationDomain}.CITY`,
          fieldName: 'city',
          value: address.city,
        }),

        new TextField({
          label: `${translationDomain}.PROVINCE`,
          fieldName: 'province',
          value: address.province,
        }),

        new TextField({
          label: address.identificationNumber
            ? `${translationDomain}.IDENTIFICATION_NUMBER_TYPE.${address.identificationNumberType.toUpperCase()}`
            : `${translationDomain}.IDENTIFICATION_NUMBER`,
          fieldName: 'identificationNumber',
          value: address.identificationNumber,
        })
      ];

      response = Object.assign(response, {
        id: address.id,
        type: addressType,
        fields
      });

      return response;
    });
  }
}
