import { NgModule } from '@angular/core';
import { TableComponent } from '@components/generic/List/show-details/table';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [TableComponent],
  entryComponents: [TableComponent],
  imports: [CommonModule]
})

export class ShowDetailsModule {}
