import { Component, Inject } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { UsersResource } from '@components/users/users.resource';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { UsersFiltersFormService } from '@components/users/services/users-filters-form.service';
import { SessionHelper } from '@helpers/session.helper';
import { CountryHelper } from '@helpers/CountryHelper';
import { DASHBOARD_REVENUES } from '@constants/route.constants';
import { AbstractResource } from '@resources/abstract.resource';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';


@Component({
  selector: 'app-users-list',
  template: require('./users-list.component.html'),
  providers: [
    { provide: AbstractResource, useClass: UsersResource },
    { provide: AbstractFiltersFieldsService, useClass: UsersFiltersFormService }
  ]
})

export class UsersListComponent extends AbstractPageComponent {

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    @Inject('StateService') state: ng.ui.IStateService,
    @Inject('DialogService') private dialog: any,
    authService: AuthService,
    public resource: UsersResource,
  ) {
    super($translate, authService, resource, state);
  }

  public delete(id: string): void {
    this.dialog.confirm(this.translate(`PAGE.USERS.CONFIRM.DELETE`))
      .then(() => {
        this.resource.remove(id)
          .takeUntil(this.destroyed$)
          .subscribe(() => this.state.go(`${this.resource.routeName}.list`, null, {reload: true, notify: true}));
      });
  }

  public goToEdit(id: string): void {
    this.actions.update.go({id});
  }

  public usurp(user: any): void {
    user.countries = user.countries.map((country: any) => CountryHelper.getCountryByCountryCode(country.code));
    SessionHelper.setUsurpedUser(user);
    SessionHelper.setUsurpedUserDataInStorage(user);
    this.state.go(DASHBOARD_REVENUES, {}, {reload: true});
  }
}
