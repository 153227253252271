'use strict'

import { BASE_URL } from '../constants';

###
  @name Ckeditor

  @description Display the WYSIWYG CKeditor.
  The directive ngModel and attribute id are required.

  @param {object} ng-model
  @param {string} id

  @example
    // Basic use
    <textarea class="form-control input-sm"
              id="my-field"
              ckeditor
              category-selector
              image-upload
              ng-model="model.myField"></textarea>
###
angular
  .module('ammsFrontendApp')
  .directive('ckeditor', [ ->
      restrict: 'A'
      require: '?ngModel'
      controller: 'CkeditorCtrl'
      controllerAs: 'ckeditor'
      bindToController:
        id: '@'
      scope: true
      link: (scope, elem, attrs, ngModel) ->
        id = attrs.id
        ckeditorCtrl = scope.ckeditor

        if ('undefined' != typeof attrs.categorySelector)
          scope.categorySelector = true

        if ('undefined' != typeof attrs.formSelector)
          scope.formSelector = true

        if ('undefined' != typeof attrs.imageUpload)
          scope.imageUpload = true

        if (id)
          ckeditorCtrl.initInstanceCkeditor(ngModel, id, elem)

        scope.$watch('ckeditorCtrl.id', ->
          if (!id && null != ckeditorCtrl.id && 'undefined' != typeof ckeditorCtrl.id)
            ckeditorCtrl.initInstanceCkeditor(ngModel, ckeditorCtrl.id, elem)
        )
  ])
  .controller('CkeditorCtrl', [
    '$scope',
    '$interfaceLanguage',
    '$timeout',
    '$uibModal',
    '$translate',
    'WysiwygResource',
    (
      $scope,
      $interfaceLanguage,
      $timeout,
      $uibModal,
      $translate,
      WysiwygResource,
    ) ->
      instanceCkeditor = null
      modelCtrl = null
      dataModel = []

      this.initInstanceCkeditor = (ngModel, id, elements) ->
        if (!ngModel)
          return

        if (!id)
          throw new ReferenceError('The HTML ID attribute is required.')

        options = {
          defaultLanguage: 'fr'
          format_tags: 'p;h3'
          language: $interfaceLanguage.getLocale().split('_').shift()
          toolbar: [
            { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline' ] },
            { name: 'styles', items: ['Format'] },
            { name: 'insert', items: ['ImageUpload', 'CategoryContent', 'FormContent'] },
            { name: 'document', items: ['Source'] }
          ]
          height: '400px'
          width: '100%'
          allowedContent: true
        }
        modelCtrl = ngModel

        CKEDITOR.addCss('
          .universe, .shelf {
            padding: 5px 15px 5px 35px;
            background: #F1C18D url(/images/embed-content.png) 0 50% no-repeat; border: 1px solid #CC762D;
          }
          .universe:before, .shelf:before {
            content: attr(category); font-size: 1.5em; font-weight: bold; line-height: 1; user-select: none;
          }
          .form {
            padding: 5px 15px 5px 35px;
            background: #00BCD4 url(/images/embed-content.png) 0 50% no-repeat; border: 1px solid #CC762D;
          }
          .form:before {
            content: attr(data-form)"-"attr(data-crm); font-size: 1.5em; font-weight: bold; line-height: 1; user-select: none;
          }
        ')

        CKEDITOR.on('instanceCreated', (event) -> event.editor.name = id)

        instanceCkeditor = CKEDITOR.replace(elements[0], options)

        ngModel.$render = ->
          if ('undefined' != typeof ngModel.$viewValue)
            dataModel.push(ngModel.$viewValue)

        instanceCkeditor.on('instanceReady', ->
          $scope.$broadcast('ckeditor.ready')
          $scope.$apply(setData)
        )

        instanceCkeditor.on('change', _.debounce(setModel, 200))
        instanceCkeditor.on('key', _.debounce(setModel, 200))

        # Embed category content management
        if ($scope.categorySelector)
          instanceCkeditor.addCommand('addCategoryContent', {
            exec: (edt) ->
              modal = $uibModal.open({
                controller: 'CategorySelector',
                controllerAs: 'selector',
                templateUrl: require('views/default/category-selector.html')
              })

              modal.result.then((result) ->
                category = result.$value
                container = edt.document.createElement( 'div' )
                container.addClass(if 0 == category.level then 'universe' else 'shelf')
                container.setAttribute('category', category.id)
                edt.insertElement(container)
              )
          })

          instanceCkeditor.ui.addButton('CategoryContent', {
            label: $translate.instant('BUTTON.ADD.CATEGORY_CONTENT'),
            command: 'addCategoryContent',
            toolbar: 'insert',
            icon: '/images/embed-content.png'
          })

        # Insert image
        if ($scope.imageUpload)
          instanceCkeditor.addCommand('addImageUpload', {
            exec: (edt) ->
              uploader = angular.element('<input type="file" />')
              uploader.trigger('click')
              uploader.on('change', (e) ->
                file = e.target.files[0]

                WysiwygResource.upload(file)
                  .subscribe((response) ->
                    if ('undefined' != typeof response.path)
                      image = edt.document.createElement( 'img' )
                      image.setAttribute('src', BASE_URL + '/' + response.path)
                      image.setAttribute('alt', '')
                      edt.insertElement(image)
                  )
              )
          })

          instanceCkeditor.ui.addButton('ImageUpload', {
            label: $translate.instant('BUTTON.ADD.IMAGE_UPLOAD'),
            command: 'addImageUpload',
            toolbar: 'insert',
            icon: '/images/upload-image.png'
          })

        if ($scope.formSelector)
          instanceCkeditor.addCommand('addFormContent', {
            exec: (edt) ->
              modal = $uibModal.open({
                controller: 'FormSelector',
                controllerAs: 'selector',
                templateUrl: require('views/default/form-selector.html')
              })

              modal.result.then((result) ->
                id = result.$value
                container = edt.document.createElement( 'div' )
                container.addClass( 'form')
                container.setAttribute('data-crm', id)
                container.setAttribute('data-form', 'cmsRegister')
                edt.insertElement(container)
              )
          })

          instanceCkeditor.ui.addButton('FormContent', {
            label: $translate.instant('BUTTON.ADD.FORM_CONTENT'),
            command: 'addFormContent',
            toolbar: 'insert',
            icon: '/images/form_icon.png'
          })

      setData = ->
        $timeout( ->
          return instanceCkeditor.setData(dataModel.pop() || '<p></p>')
        , 200)

      setModel = ->
        data = instanceCkeditor.getData()

        if (modelCtrl.$viewValue != data)
          modelCtrl.$setViewValue(data)
          $scope.$apply( -> return modelCtrl.$setViewValue(data))

      return this
  ])
