'use strict'

angular
  .module('ammsFrontendApp')
  .directive('filterPagination', [
    'ListFilter'
    (ListFilter) ->
      controller = ($scope) ->
        listFilter = new ListFilter($scope.defaultFilters)

        $scope.$watch(
          ->
            $scope.itemsPerPage
          , (itemsPerPage) ->
            if (typeof $scope.itemsPerPage is 'string')
              $scope.itemsPerPage = +itemsPerPage
          , true
        )

        $scope.changeItemPerPage = ( ->
          filters = $scope.filters

          # Hack for product filter in Product List
          if (filters['products[]']?)
            products = []

            angular.forEach(filters['products[]'], (product) ->
              if (angular.isObject(product))
                products.push(product.id)
              else
                products.push(product)
            )

            filters['products[]'] = products

          filters.byPage = $scope.itemsPerPage
          listFilter.updateUrl(filters, true)
        )

      controller: controller
      replace: true
      restrict: 'E'
      scope:
        defaultFilters: '='
        filters: '='
        itemsPerPage: '='
        listItemsPerPage: '='
      templateUrl: require('views/directives/filter-pagination.html')
  ])
