import * as tinycolor from 'tinycolor2';
import { ColorPickerService } from 'ngx-color-picker';

const ALPHA_CHANNEL: string = 'always';

export class ColorHelper {

  static convertRGBAToHex8(rgba: string): string {
    return tinycolor(rgba).toHex8String();
  }

  static convertHex8ToRgba(hex8: string): string {
    const cpService = new ColorPickerService();

    return cpService.outputFormat(cpService.stringToHsva(hex8, true), 'rgba', ALPHA_CHANNEL);
  }
}
