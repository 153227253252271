import { Injectable } from '@angular/core';
import { Resource } from '@decorators/Resource';
import { AbstractResource } from '@resources/abstract.resource';
import { HttpClient } from '@angular/common/http';
import { SessionHelper } from '@helpers/session.helper';
import { BannerModel } from '@components/banner/banner.model';

@Injectable()
@Resource({
  routeName: 'banners',
  entryPoint : '/v2/banners',
  isHydra: true,
  cGetDefaultFilters: () => ({ 'translations.locale': SessionHelper.getLocale() }),
  translationKey: 'BANNER',
  listModel: BannerModel,
  deleteAvailable: false,
})
export class BannerResource extends AbstractResource {

  protected nullableProperties: string[] = ['link', 'startDate', 'endDate'];

  constructor(http: HttpClient) {
    super(http);
  }
}
