import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { CategoriesResource } from '@components/categories/categories.resource';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { SessionHelper } from '@helpers/session.helper';
import { IFormViolation } from '@interfaces/IFormViolation';
import { ICategories, ICategoriesTranslation, IHreflang } from '@components/categories/models';
import { AbstractFormComponent } from '@components/generic/Form/abstract-form.component';
import { SnackbarService } from '@components/snackbar';
import { TabResource } from '@components/tab';
import { CategoryRelationsResource } from '@components/categories/category-relations.resource';

export const INDEX_FOLLOW: string = 'index, follow';
export const NO_INDEX_FOLLOW: string = 'noindex, follow';
export const NO_INDEX_NO_FOLLOW: string = 'noindex, nofollow';

@Component({
  selector: 'app-seo-form',
  template: require('./seo-form.component.html'),
  styles: [require('./seo-form.component.scss')],
})

export class SeoFormComponent extends AbstractFormComponent implements OnInit {

  public model: ICategories;
  public violations: IFormViolation[] | any = [];
  public form: FormGroup;
  public currentLocales: string[] = SessionHelper.getCountry().locales;
  public seoMetaRobotOptions: any[] = [
    INDEX_FOLLOW,
    NO_INDEX_FOLLOW,
    NO_INDEX_NO_FOLLOW,
  ];

  public relations: any[] = [];
  public copyOfRelations: any[] = [];
  public countryCodeIso2Fr = 'FR';

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    @Inject('DialogService') private dialog: any,
    private snackbar: SnackbarService,
    private tabResource: TabResource,
    private categoriesResource: CategoriesResource,
    private categoryRelationsResource: CategoryRelationsResource
  ) {
    super($translate, authService, null, state);
  }

  ngOnInit(): void {
    this.fetch();
  }

  public fetch(): void {
    this.categoriesResource.get(this.state.params.id)
      .takeUntil(this.destroyed$)
      .subscribe((response: ICategories) => {
        this.model = response;
        this.relations = response.relations;
        this.copyOfRelations = response.relations;

        this.form = new FormGroup({
          translations: new FormArray([]),
          relations: new FormArray([]),
          seoMetaRobot: new FormControl(this.model.seoMetaRobot),
        });

        const arrayControls = this.form.get('translations') as FormArray;
        Object.keys(this.model.translations).forEach((locale: any) => {
          arrayControls.push(this.createTranslationForm(this.model.translations[locale]));
        });

        const relationControls = this.form.get('relations') as FormArray;
        Object.keys(this.model.relations).forEach((index: any) => {
          relationControls.push(this.createRelationForm(this.model.relations[index]));
        });
      });
  }

  public addRelationForm(): void {
    const relationControls = this.form.get('relations') as FormArray;
    relationControls.push(this.createRelationForm({}));
  }

  public createRelationForm(relation: any): FormGroup {
    if (Object.entries(relation).length === 0) {
      return new FormGroup({
        id: new FormControl(),
        categoryId: new FormControl(),
        locale: new FormControl(),
      });
    } else {
      return new FormGroup({
        id: new FormControl(relation.id),
        categoryId: new FormControl(relation.children.id),
        locale: new FormControl(relation.locale),
      });
    }

  }

  public hasSeveralTranslation(): boolean {
    return this.currentLocales.length > 1;
  }

  public createTranslationForm(translation: any): FormGroup {
    return new FormGroup({
      id: new FormControl(translation.id),
      locale: new FormControl((translation.locale)),
      titleSeo: new FormControl(translation.titleSeo),
      metaDescriptionSeo: new FormControl(translation.metaDescriptionSeo),
    });
  }

  public getTranslation(key?: string): FormGroup {
    const translations: FormArray = this.form.get('translations') as FormArray;

    if (this.hasSeveralTranslation() && undefined !== key) {
      return translations.controls.find((group: FormGroup) => key === group.get('locale').value) as FormGroup;
    }

    return translations.controls[0] as FormGroup;
  }

  public getRelationForms(): FormGroup[] {
    const relations: FormArray = this.form.get('relations') as FormArray;
    return relations.controls as FormGroup[];
  }

  public deleteRelation(index: any): void {
    const relations: FormArray = this.form.get('relations') as FormArray;
    relations.removeAt(index);
    this.form.markAsDirty();
  }

  public cancel(): void {
    this.dialog.confirm(this.translate('PAGE.CATEGORIES.CONFIRM.BACK_TO_LIST'))
      .then(() => this.state.go(`${this.tabResource.routeName}.list`));
  }

  public update(returnToList: boolean): void {
    while (this.violations.length) { this.violations.pop(); }

    for (const relation of this.copyOfRelations) {
      if (! this.form.value.relations.some((e: any) => e.id === relation.id)) {
        this.categoryRelationsResource.remove(relation.id).subscribe();
      }
    }

    for (const relation of this.form.value.relations) {
      if (! relation.id) {
        this.categoryRelationsResource.create({
            parent: `api/v2/categories/${this.state.params.id}`,
            children: `api/v2/categories/${relation.categoryId}`,
            locale: relation.locale
          }
        ).subscribe();
      }
    }

    if (!this.form.dirty || !this.form.valid) {
      let errorMessage = 'ALERTS.NO_CHANGE.FORM';

      if (!this.form.valid) {
        errorMessage = 'ALERTS.ERROR.FORM';
      }

      this.snackbar.warn(this.translate(errorMessage));
      return;
    }

    this.categoriesResource.update(this.state.params.id, this.prepareCategoriesBody(this.form))
      .takeUntil(this.destroyed$)
      .subscribe(
        () => {
          this.snackbar.validate('SAVED');
          returnToList
             ? this.state.go(`${this.tabResource.routeName}.list`)
            : this.state.go(`${this.categoriesResource.routeName}.edit.seo`, { id: this.model.id }, { reload: true })
            ;
        },
        (reject: any) => {
          this.violations = this.formatViolations(reject);
        }
      )
      ;
  }

  private formatViolations(reject: any): IFormViolation[] {
    if (!reject.hasOwnProperty('violations')) {
      return;
    }

    return reject.violations.map((violation: IFormViolation): IFormViolation => {
      return {
        propertyPath: violation.propertyPath,
        message: violation.message
      };
    });
  }

  public prepareCategoriesBody(categoriesForm: any): {
    obfuscated: boolean;
    hiddenMenu: boolean;
    translations: ICategoriesTranslation[];
    seoMetaRobot: string;
  } {
    return {
      obfuscated: categoriesForm.value.obfuscated,
      hiddenMenu: categoriesForm.value.hiddenMenu,
      translations: this.getPreparedTranslationsCategories(categoriesForm.value.translations),
      seoMetaRobot: categoriesForm.value.seoMetaRobot,
    };
  }

  private getPreparedTranslationsCategories(translations: ICategoriesTranslation): ICategoriesTranslation[] {
    return translations.reduce(
      (
        current: ICategoriesTranslation,
        { name, locale, id, metaDescriptionSeo, titleSeo, slug }: ICategoriesTranslation
      ) => {
        current[locale] = {
          locale,
          id,
          metaDescriptionSeo,
          titleSeo
        };
        return current;
      },
      {}
    );
  }

  public delete(id: string): void {
    this.dialog.confirm(this.translate('PAGE.CATEGORIES.CONFIRM.DELETE'))
      .then(() => {
        this.categoriesResource.remove(id)
          .takeUntil(this.destroyed$)
          .subscribe(() => this.state.go(`${this.tabResource.routeName}.list`, { reload: true }));
      });
  }

  public getCurrentCountryCode() {
    return SessionHelper.get('CURRENT_USER_COUNTRY').code;
  }
}
