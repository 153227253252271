import { AbstractResource } from '../../../../resources/abstract.resource';
import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  routeName: 'ce_standard',
  entryPoint: '/v2/ce_standards',
  translationKey: 'CE_STANDARD',
  isHydra: true,
})
export class CeStandardResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }
}
