import { Component, Inject, Input, OnInit } from '@angular/core';
import { SessionHelper } from '@helpers';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services';
import { ITask } from './model/task.interface';
import { ITaskCategory, ITaskStatus } from './interfaces';
import { ITaskCategoryComponent } from './task-category.component.interface';
import { IFlatTaskType, ITaskType } from './interfaces/task-type.interface';
import { TaskCategoryResource, TaskResource } from './resources';
import { Subscription } from 'rxjs/Subscription';
import { IFormViolation, IResource } from '@interfaces';
import { SnackbarService } from '@components/snackbar/snackbar.service';

@Component({
  selector: 'app-task-category',
  template: require('./task-category.component.html'),
  styles: [require('./task-category.component.scss')]
})
export class TaskCategoryComponent extends AbstractComponent implements OnInit, ITaskCategoryComponent {

  @Input() public taskCategory: ITaskCategory;
  @Input() public taskStatuses: ITaskStatus;
  @Input() public businessObjectId: string;
  @Input() public businessObjectType: string;
  @Input() public taskTypes: ITaskType[];

  public flatTaskTypes: IFlatTaskType[] = [];
  public tasks: ITask[] = [];
  public tasksListDisplayed: boolean = false;
  public tasksOpen: number = 0;
  public tasksClosed: number = 0;
  public creationTaskViolations: IFormViolation[] = [];
  public taskCategoryTitle: string = '';

  private isTasksAlreadyLoading: boolean = false;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private taskResource: TaskResource,
    private taskCategoryResource: TaskCategoryResource,
    private snackbar: SnackbarService,
  ) {
    super($translate, authService, null, state);
  }

  ngOnInit(): void {
    this.taskCategoryTitle = this.taskCategory.translations[SessionHelper.getUILanguage()].name;

    this.flatTaskTypes = this.taskTypes.map((taskType: ITaskType) => {
      return {
        'name': taskType.translations[SessionHelper.getUILanguage()].name,
        'value': taskType['@id']
      };
    });

    this.tasksOpen = this.taskCategory.totalTaskByStatus.open;
    this.tasksClosed = this.taskCategory.totalTaskByStatus.close;
  }

  public createTask(event: Event, taskTypeId: string): void {
    this.creationTaskViolations = [];

    const subscriber: Subscription = this.taskResource.create({
        assignFrom: `/api/v2/users/${SessionHelper.getUser().id}`,
        status: this.taskStatuses.open,
        businessObjectId: '' + this.businessObjectId,
        taskType: taskTypeId,
      }).subscribe((response: ITask) => {
        this.tasksOpen++;
        this.taskCategory.totalTask++;

        // push only if the list of tasks have already be retrieve from API
        if (this.isTasksAlreadyLoading) {
          this.tasks.push(response);
        }

        this.snackbar.validate(this.translate('TASKS.SAVED'));
      }, (reject) => {
        if (400 === reject.status) {
          this.creationTaskViolations = reject.violations;
        }
      }, () => subscriber.unsubscribe()
    );
  }

  public showTasks(): void {
    if (0 !== this.tasks.length) {
      this.tasksListDisplayed = !this.tasksListDisplayed;

      return;
    }

    const subscriber: Subscription = this.taskResource.getMany({
      businessObject: this.businessObjectType,
      businessObjectId: this.businessObjectId,
      'taskType.taskTypeCategory.id': this.taskCategory.id,
    }).subscribe((response: ITask[]) => {
      this.tasks = response;
      this.isTasksAlreadyLoading = true;
      this.tasksListDisplayed = !this.tasksListDisplayed;
    }, undefined, () => subscriber.unsubscribe);
  }

  public updateStatusesText(task: ITask) {
    if (this.taskStatuses.open === task.status) {
      this.tasksOpen++;

      if (0 < this.tasksClosed) {
        this.tasksClosed--;
      }
    }

    if (this.taskStatuses.close === task.status) {
      this.tasksClosed++;

      if (0 < this.tasksOpen) {
        this.tasksOpen--;
      }
    }
  }
}
