import { IRedirects, IRedirectsCreatedBy } from '@components/redirects/models/redirects.interface';
import { DATE_SHORT_FORMAT, FULL_EUROPEEN_DATE_FORMAT } from '@constants';
import * as moment from 'moment';

export class RedirectsModel implements IRedirects {
  public '@id': string;
  public id: string;
  public fromRoute: string;
  public toRoute: string;
  public createdBy: IRedirectsCreatedBy;
  public automatic: string;
  public fromStatusCode: string;
  public toStatusCode: string;
  public finalStatusCode: string;
  public crawledAt?: string;
  public updatedAt: string;
  public locale: string;
  public username: string;

  constructor(redirection: IRedirects = {
    '@id': '',
    id: '',
    fromRoute: '',
    toRoute: '',
    createdBy: null,
    automatic: false,
    fromStatusCode: '',
    toStatusCode: '',
    finalStatusCode: '',
    crawledAt: null,
    updatedAt: '',
    locale: ''
  }) {
    this['@id'] = redirection['@id'];
    this.id = redirection.id;
    this.fromRoute = redirection.fromRoute;
    this.toRoute = redirection.toRoute;
    this.createdBy = redirection.createdBy;
    this.automatic = redirection.automatic
      ? 'PAGE.REDIRECTS.LIST.FILTERS.AUTOMATIC.AUTOMATIC'
      : 'PAGE.REDIRECTS.LIST.FILTERS.AUTOMATIC.MANUAL'
    ;
    this.fromStatusCode = this.getStatusCode(redirection.fromStatusCode);
    this.toStatusCode = this.getStatusCode(redirection.toStatusCode);
    this.finalStatusCode = this.getStatusCode(redirection.finalStatusCode);
    this.crawledAt = redirection.crawledAt ?  moment(redirection.crawledAt).format(FULL_EUROPEEN_DATE_FORMAT) : null;
    this.updatedAt = redirection.updatedAt ? moment(redirection.updatedAt).format(DATE_SHORT_FORMAT) : null;
    this.locale = redirection.locale;
    this.username = redirection.createdBy
      ? redirection.createdBy.username
      : 'PAGE.REDIRECTS.LIST.TABLE.SYSTEM'
    ;
  }

  private getStatusCode(statusCode?: string): string {
    return statusCode ? '<span class="http-st http-st-' + statusCode + '">' + statusCode + '</span>' : '';
  }
}
