import { Component, Inject, OnInit } from '@angular/core';
import { IPendingTask, LoaderService } from '@components/loader/loader.service';
import { ISnackbarEventMessage, SnackbarService, TYPE_ALERT, TYPE_INFO, TYPE_VALID, TYPE_WARN } from './snackbar.service';
import 'rxjs/add/operator/map';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services/auth.service';
import { IResource } from '@interfaces/IResource';

const TYPE_LOADER: string = 'loader';

interface ISnackbarMessage {
  active?: boolean;
  closed?: boolean;
  className: string;
  id: string;
  label: string;
  type: string;
}

const MAX_NUMBER_OF_MESSAGE: number = 5;
const DURATION_OK: number = 3000;
const DURATION_WARN: number = 5000;
const DURATION_ALERT: number = 10000;

@Component({
  selector: 'app-snackbar-group',
  template: require('./snackbar.component.html'),
  styles: [require('./snackbar.component.scss')],
})
export class SnackbarComponent extends AbstractComponent implements OnInit {
  public messages: Array<ISnackbarMessage> = [];
  private currentLoaderMessage: ISnackbarMessage;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private snackbarService: SnackbarService,
    private loaderService: LoaderService
  ) {
    super($translate, authService, null, state);
  }

  public ngOnInit() {
    this.snackbarService.messages.subscribe((event: ISnackbarEventMessage) => {
      if (event) {
        this.addMessage(event.type, event.message);
      }
    });
    this.loaderService.pendingTasks
      .map((tasks: IPendingTask[]) => tasks.filter((task: IPendingTask) => task.blocking))
      .subscribe((tasks: IPendingTask[]) => {
        if (tasks.length) {
          if (undefined === this.currentLoaderMessage) {
            this.currentLoaderMessage = this.addMessage(
              TYPE_LOADER,
              this.translate('ALERTS.PENDING.SEARCH')
            );
          }
        } else if (undefined !== this.currentLoaderMessage) {
          this.prepareMessageDisappearance(this.currentLoaderMessage);
          this.currentLoaderMessage = undefined;
        }
      });
  }

  /**
   * Adds message to snackbar list.
   */
  public addMessage(type: string, label: string): ISnackbarMessage {
    const message: ISnackbarMessage = {
      className: this.getClassName(type),
      id: this.getId(type, label),
      label,
      type,
    };

    this.messages.push(message);
    this.limitMessages();
    this.showMessage(message);
    if (TYPE_LOADER !== type) {
      this.prepareMessageDisappearance(message, this.getDurationByType(type));
    }

    return message;
  }

  /**
   * Generates id for a message.
   */
  private getId(type: string, message: string): string {
    return btoa([(new Date()).getTime(), type, message].join());
  }

  /**
   * Gets class name depending on the type of message.
   */
  private getClassName(type: string): string {
    return 'snackbar-group-item-' + type;
  }

  /**
   * Launch opening animation for a message.
   */
  private showMessage(message: ISnackbarMessage): void {
    setTimeout((() => message.active = true), 0);
  }

  /**
   * Handle message closing animation then removal.
   */
  private prepareMessageDisappearance(message: ISnackbarMessage, duration = 0): void {
    // add closed class
    setTimeout((() => message.closed = true), duration);
    // removes message from list after animation duration
    setTimeout(() => {
      this.messages = this.messages.filter((item) => message.id !== item.id);
    }, duration + 200);
  }

  /**
   * Gets lifespan of the message depending on its type.
   */
  private getDurationByType(type: string): number {
    switch (type) {
      case TYPE_ALERT:
        return DURATION_ALERT;
      case TYPE_WARN:
      case TYPE_INFO:
        return DURATION_WARN;
      case TYPE_VALID:
        return DURATION_OK;
    }
  }

  /**
   * Limits messages number
   * If the limit is reached, we shift the list and execute closing animation on each deleted item
   */
  private limitMessages(): void {
    if (this.messages.length > MAX_NUMBER_OF_MESSAGE) {
      this.messages.slice(0, this.messages.length - MAX_NUMBER_OF_MESSAGE)
        .forEach(this.prepareMessageDisappearance);
    }
  }
}
