import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@services';
import { AbstractComponent } from '@components/generic/abstract.component';
import { MailResource } from '@resources/mail.resource';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-customer-detail-mails',
  template: require('./customer-detail-mails.component.html'),
})
export class CustomerDetailMailsComponent extends AbstractComponent implements OnInit {

  public mails: any[] = [];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    @Inject('DialogService') private dialogService: any,
    protected resource: MailResource,
  ) {
    super($translate, authService, null, state);
  }

  ngOnInit(): void {
    this.fetch();
  }

  private fetch(): void {
    (<MailResource>this.resource)
      .cGet({'customer.id': this.state.params.id, 'sended': 1}, {returnHydraMembers: true})
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        this.mails = response;
      })
    ;
  }

  public downloadMail(mail: any): void {
    (<MailResource>this.resource)
      .get(mail.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        const blob = new Blob([response.content], { type: 'text/html' });
        const a = document.createElement('a');
        const url = URL.createObjectURL(blob);

        a.href = url;
        a.download = 'mail.html';
        document.body.appendChild(a);
        a.click();
        setTimeout(function () {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
      })
    ;
  }

  public sendMail(mail: any): void {
    this.dialogService.confirm(this.translate('PAGE.CUSTOMER_CARD.DETAILS.MAILS.RESEND'))
      .then(() => {
        (<MailResource>this.resource).get(mail.id, { entryPoint: '/v2/mails/' + mail.id + '/resend' })
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response: any) => {});
        })
      .catch(() => {
      });
  }
}
