import { Component, Inject, Input, OnInit } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@services';
import { AbstractResource, InscpxeeResource } from '@resources';
import { AbstractComponent } from '@components/generic/abstract.component';
import { OrderManagerAddressResource } from '@components/order-manager/order-manager-address.resource';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'address-dialog',
    template: require('./address-dialog.component.html'),
    styles: [require('./address-dialog.component.scss')],
})
export class AddressDialogComponent extends AbstractComponent implements OnInit {

    @Input() address: any;
    @Input() addressType: string;
    @Input() marketplace: string;
    @Input() addressIdToRecopy: string;
    @Input() activeCityAutocomplete: boolean = false;
    public form: FormGroup;
    public recopyForm: FormGroup;
    public cityList: any[] = [];

    constructor(
        @Inject('TranslationService') $translate: ng.translate.ITranslateService,
        authService: AuthService,
        @Inject('StateService') state: ng.ui.IStateService,
        resource: AbstractResource,
        private orderManagerAddressResource: OrderManagerAddressResource,
        private dialog: DialogRef,
        private formBuilder: FormBuilder,
        private inscpxeeResource: InscpxeeResource) {
        super($translate, authService, resource, state);
    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            firstName: new FormControl(this.address.firstName, Validators.required),
            lastName: new FormControl(this.address.lastName, Validators.required),
            company: new FormControl(this.address.company || null),
            addressLine1: new FormControl(this.address.addressLine1, Validators.required),
            addressLine2: new FormControl(this.address.addressLine2 || null),
            addressLine3: new FormControl(this.address.addressLine3 || null),
            postcode: new FormControl(this.address.postcode, Validators.required),
            city: new FormControl(this.address.city, Validators.required),
            province: new FormControl(this.address.province || null),
            countryCode: new FormControl(this.address.countryCode, Validators.required),
            phone1: new FormControl(this.address.phone1, Validators.required),
            phone2: new FormControl(this.address.phone2 || null),
            email: new FormControl(this.address.email, [Validators.required, Validators.email]),
            vatNumber: new FormControl(this.address.vatNumber || null),
            identificationNumber: new FormControl(this.address.identificationNumber || null),
        });

        this.recopyForm = this.formBuilder.group({
            addressRecopy: new FormControl(false)
        });

        if (this.address.postcode) {
            this.onChangePostCode(this.address.postcode);
        }
    }

    public onCancelAction(): void {
        this.dialog.close({ type: 'cancel' });
    }

    public onConfirmAction(): void {
        if (this.recopyForm.get('addressRecopy').value) {
            this.orderManagerAddressResource.update(this.addressIdToRecopy, this.form.value).subscribe(
                () => {
                    this.orderManagerAddressResource.update(this.getAddressId(this.address), this.form.value).subscribe(
                        () => {
                            this.dialog.close({ type: 'submit' });
                            this.state.go(this.state.current, this.state.params, { reload: true });
                        }
                    );
                }
            );
        } else {
            this.orderManagerAddressResource.update(this.getAddressId(this.address), this.form.value).subscribe(
                () => {
                    this.dialog.close({ type: 'submit' });
                    this.state.go(this.state.current, this.state.params, { reload: true });
                }
            );
        }
    }

    private getAddressId(address: any) {
        try {
            return address['@id'].split('/').pop();
        } catch (error) {
            console.error(error);
        }
    }

    public onChangePostCode(postCode: string) {
        if (this.activeCityAutocomplete && postCode && postCode.length > 3) {
            this.inscpxeeResource.getMany({ countryCode: 'FR', postcode: postCode })
                .pipe(takeUntil(this.destroyed$))
                .subscribe(
                    (inscpxeeResponse: any) => {
                        this.cityList = [];
                        inscpxeeResponse.forEach((response: any) => {
                            this.cityList.push(response.city.name);
                        });
                    }
                );
        }
    }

}
