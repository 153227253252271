import { AbstractResource } from '../../../resources/abstract.resource';
import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { Observable } from 'rxjs/Observable';
import {IReverse, IReverseProduct, IReverseProductPackage, Reverse} from '@components/reverse';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  routeName: 'reverses',
  entryPoint: '/v2/reverses',
  translationKey: 'REVERSE',
  updateAvailable: true,
})
export class ReverseResource extends AbstractResource {

  protected nullableProperties: string[] = ['reconditionedStatus'];

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  /**
   * Updates a reverseProduct
   *
   * @param {string} reverseProductId
   * @param {object} body
   *
   * @returns {Observable<IReverseProduct>}
   * @memberof ReverseResource
   */
  public updateReverseProduct(reverseProductId: string, body: object): Observable<IReverseProduct> {
    return this.partialUpdate(
      reverseProductId,
      body,
      { entryPoint: `/reverses/products/${reverseProductId}` }) as Observable<IReverseProduct>;
  }

  /**
   * Creates a reverseProduct
   *
   * @param {object} body
   * @returns {Observable<IReverseProduct>}
   * @memberof ReverseResource
   */
  public createReverseProduct(body: object): Observable<IReverseProduct> {
    return this.create(body, { entryPoint: `/reverses/products` }) as Observable<IReverseProduct>;
  }

  /**
   * Updates a reverseProductPackage
   *
   * @param {string} reverseProductPackageId
   * @param {object} body
   * @param {*} [options={}]
   * @returns {Observable<IReverseProductPackage>}
   * @memberof ReverseResource
   */
  public updateReverseProductPackage(reverseProductPackageId: string, body: object, options: any = {}): Observable<IReverseProductPackage> {
    return this.partialUpdate(
      reverseProductPackageId,
      body,
      { entryPoint: `/reverses/packages/${reverseProductPackageId}` }) as Observable<IReverseProductPackage>;
  }

  public updateReverseZendeskStatus(reverseId: any): Observable<IReverse> {
    return this.update(
      reverseId,
      {},
      { entryPoint: `${this.entryPoint}/${reverseId}/zendesk` }
    ) as Observable<IReverse>;
  }

  /**
   * Creates a ReverseProductPackage
   *
   * @param {object} body
   * @param {*} [options={}]
   * @returns {Observable<IReverseProductPackage>}
   * @memberof ReverseResource
   */
  public createReverseProductPackage(body: object, options: any = {}): Observable<IReverseProductPackage> {
    return this.create(body, { entryPoint: `/reverses/packages` }) as Observable<IReverseProductPackage>;
  }

  /**
   * Gets reverse from order.
   *
   * @param {string} orderId
   *
   * @returns {Observable<object>}
   */
  public getReverseFromOrder(orderId: string): Observable<object> {
    return this.get(
      undefined,
      {
        entryPoint: `/reverses/template-from-order/${orderId}`
      }
    );
  }

  public closeReverse(reverseId: string, close: boolean): Observable<object> {
    const body = close ? {status: 'close', archived: true} : {status: 'open', archived: false};
    return this.partialUpdate(
      reverseId,
      body,
      { entryPoint: `${this.entryPoint}/${reverseId}` });
  }
}
