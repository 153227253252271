import { Component, Inject } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';

@Component({
  selector: 'app-tab-result-list',
  template: require('./tab-result-list.component.html')
})
export class TabResultListComponent extends AbstractComponent {

  private _items: any;

  set items(items: any) {
    this._items = items;
  }

  get items(): any {
    return this._items;
  }

  public readonly roles: string[] = ['ROLE_WALISOFT_AGENT'];
  public rolesForActions = ['ROLE_WALISOFT_AGENT'];

  // TODO: see to fusion generic list with dnd list - see SU-3911
  public columns = [
    { size: 3, title: 'PAGE.TAB.LIST.TABLE.HEAD.LABEL', property: 'name', handleColor: true },
    { size: 3, title: 'PAGE.TAB.LIST.TABLE.HEAD.TYPE', property: 'type' },
    { size: 3, title: 'PAGE.TAB.LIST.TABLE.HEAD.LINK', property: 'url' },
    { size: 1, title: 'PAGE.TAB.FORM.ACTIVE', property: 'active', type: 'boolean' },
  ];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService
  ) {
    super($translate, authService, resource, state);
  }
}
