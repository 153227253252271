import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericFormModule, GenericModule } from '@components';
import { TabsModule } from 'ngx-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SwitchModule } from '@progress/kendo-angular-inputs';
import { TooltipModule } from 'ngx-tooltip';
import { AddressingListComponent } from './list/addressing-list.component';
import { AddressingListResource } from './ressources';
import { AddressingResultListComponent } from './list/addressing-result-list.component';

@NgModule({
  entryComponents: [AddressingListComponent, AddressingResultListComponent],
  declarations: [AddressingListComponent, AddressingResultListComponent],
  providers: [AddressingListResource],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    GenericModule,
    GenericFormModule,
    TabsModule,
    SwitchModule,
    TooltipModule,
  ],
})
export class AddressingModule { }
