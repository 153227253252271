export default oneyOpcRoutes;

/** @ngInject */
function oneyOpcRoutes($stateProvider: any): void {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'walipush',
    sidebarSubNav: 'oneyOpc',
    title: '',
    reloadChangeCountry: 'oney-opc.list',
  };

  $stateProvider
    .state('oney-opc', {
      'url': '/oney-opc',
      parent: 'walisoft',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.ONEY_OPC',
        stateName: 'oney-opc.list',
      },
    })
    .state('oney-opc.list', {
      url: '',
      views: {
        'page@layout': {
          template: '<app-oney-opc-list></app-oney-opc-list>'
        },
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.ONEY_OPC.LIST.TITLE'
      })
    })
    .state('oney-opc.new', {
      url: '/new',
      breadcrumb: {
        label: 'BREADCRUMB.NEW'
      },
      views: {
        'page@layout': { template: '<app-oney-opc-form></app-oney-opc-form>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.ONEY_OPC.NEW.TITLE'
      })
    })
    .state('oney-opc.edit', {
      url: '/:id',
      breadcrumb: {
        label: 'BREADCRUMB.EDIT'
      },
      views: {
        'page@layout': { template: '<app-oney-opc-form></app-oney-opc-form>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.ONEY_OPC.EDIT.TITLE',
        reloadChangeCountry: 'oney-opc.list',
      })
    })
  ;
}
