import { Component, Inject, OnInit } from '@angular/core';
import { AbstractResource } from '@resources';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { AuthService } from '@services';
import { SnackbarService } from '@components/snackbar';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {GlossaryResource} from '@components/glossary/glossary.resource';
import {CREATION_PAGE, EDITION_PAGE} from '@interfaces';
import {GlossaryModel} from '@components/glossary';
import {CODE_FR, COUNTRIES} from '@constants';
import {SessionHelper} from '@helpers';

@Component({
  selector: 'app-glossary-form',
  template: require('./glossary-form.component.html'),
  providers: [
    { provide: AbstractResource, useClass: GlossaryResource },
  ],
})
export class GlossaryFormComponent extends AbstractPageComponent implements OnInit {
  protected readonly SessionHelper = SessionHelper;
  protected readonly CODE_FR = CODE_FR;
  public form: FormGroup;
  public lineForms: FormGroup[] = [];
  public country: any[] = COUNTRIES;
  public attribute: GlossaryModel;
  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    public formBuilder: FormBuilder,
    private snackbar: SnackbarService,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    if (EDITION_PAGE === this.pageType) {
      this.fetch();
    } else if (CREATION_PAGE === this.pageType) {
      this.addLineGlossary();
    }
  }

  private fetch(): void {
    this.resource.get(this.state.params.id)
      .takeUntil(this.destroyed$)
      .subscribe((response: GlossaryModel): void => {
        this.addLineGlossary(response);
      })
    ;
  }

  public addLineGlossary(attribute?: GlossaryModel): void {
    this.lineForms.push(this.formBuilder.group({
      fromLang: [CODE_FR, [Validators.required]],
      fromText: [attribute ? attribute.fromText : null, [Validators.required]],
      toLang: [SessionHelper.getCountry().code, [Validators.required]],
      toText: [attribute ? attribute.toText : null, [Validators.required]],
    }));
  }

  private prepareBody(formLine?: any): any {
    return {
      fromLang: formLine.value.fromLang,
      fromText: formLine.value.fromText,
      toLang: formLine.value.toLang,
      toText: formLine.value.toText,
    };
  }

  public submit(): void {
    if (this.state.params.id) {
      this.resource.update(this.state.params.id, this.prepareBody())
        .takeUntil(this.destroyed$)
        .subscribe((response: GlossaryModel): void => {
          this.state.go('glossary.list');
          this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
        });
    } else {
      this.lineForms.forEach((formLine: FormGroup): void => {
        this.resource.create(this.prepareBody(formLine))
          .takeUntil(this.destroyed$)
          .subscribe((response: GlossaryModel): void => {
            this.state.go('glossary.list');
          })
        ;
      });
    }
  }
}
