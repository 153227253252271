'use strict'

import { CODE_FR } from '@constants'

angular
  .module 'ammsFrontendApp'
  .factory 'OrderChecker', [
    '$filter'
    ($filter) ->
      class OrderChecker
        constructor: (@order, @vat) ->
          @order.marketplaceParent = $filter('marketplaceParentCode') @order.marketplace
          return this

        populateAwaitingData: ->
          if @order.marketplaceParent not in ['laredoute', 'cdiscount', 'auchan', 'gosport', 'darty']
            @order.awaitingData = false
            return

          addressAlert = typeof @order?.shippingAddress?.addressLine1 is 'undefined'
          for item in @order.items
            if item.status in ['new', 'processing'] && addressAlert
              @order.awaitingData = true
              return

          @order.awaitingData = false

        populateStats: ->
          totalPriceWithoutTaxForMargin =  @order.totalServiceAmount + (@order.totalPrice - @order.totalServiceAmount) / (1 + @vat)
          @order.totalPrice = Math.round(@order.totalPrice * 100) / 100
          @order.totalPriceTaxExcluded = @order.totalPrice / (1 + @vat)
          @order.costPrice = null
          @order.marginValue = null
          @order.marginVolume = null

          if (@order.carryingCost? && 0 != @order.carryingCost || 'enlevement' is @order.carrier)
            @order.costPrice = @order.carryingCost + @order.totalServiceCost

            for item in @order.items
              if item.status isnt 'cancelled'
                @order.costPrice += item.cmup * item.quantity

            @order.marginValue = totalPriceWithoutTaxForMargin - @order.costPrice

            if @order.totalPrice <= 0
              @order.marginVolume = 0
            else
              @order.marginVolume = ((Math.round(@order.marginValue / totalPriceWithoutTaxForMargin * 100 * 100) / 100) || 0)

            @order.marginValue = Math.round(@order.marginValue * 100) / 100
            @order.costPrice = Math.round(@order.costPrice * 100) / 100
            @order.totalPriceTaxExcluded = Math.round(@order.totalPriceTaxExcluded * 100) / 100

        populateCanChange: ->
          if @order?.items
            for item in @order.items
              item.canChange = ![
                'cancelled',
                'handling',
                'exporting',
                'in_transit',
                'delivered',
                'export_error'
              ].includes(item.status)

        populateOldValues: ->
          @order.old = {}
          @order.old.discount = @order.discount
          @order.old.carryingCost = @order.carryingCost

          if @order?.items
            for item in @order.items
              item.previous = {}
              item.previous.lastStatus = angular.copy item.status
              item.previous.currentStatus = angular.copy item.status
              item.old = {}
              item.old.status = angular.copy item.status
              item.old.quantity = angular.copy item.quantity

              if item.product?
                item.product.old = {}
                item.product.old.prepared = angular.copy item.product.prepared
                item.product.old.sku = angular.copy item.product.sku
                item.product.old.sold = angular.copy item.product.sold

        populateFlagAlert: ->
          postCode = @order.shippingAddress?.postcode
          countryCode = @order.shippingAddress?.countryCode

          @order.isCorsica = postCode?.substr(0, 2) is '20' && countryCode? && countryCode is CODE_FR
          @order.isDomTom = (postCode?.substr(0, 2) is '97' || (postCode?.substr(0, 2) is '98' && postCode?.substr(0, 3) isnt '980')) && countryCode? && countryCode is CODE_FR
          @order.isMonaco = postCode?.substr(0, 3) is '980' && countryCode? && countryCode in [CODE_FR, 'MC']
          @order.isMrCubageExceeded = false
          @order.isMrWeightExceeded = false
          @order.isRcCubageExceeded = false
          @order.isRcWeightExceeded = false
          @order.isRcEWeightExceeded = false
          @order.isRcNbPackagesExceeded = false
          @order.isSrWeightExceeded = false
          @order.isGeodisWeightExceeded = false

          cubageLd1 = 0
          cubageLds = 0
          cubageRce = 0
          weightMr = 0
          weightRc = 0
          weightSr = 0
          weightGeodis = 0
          nbPackages = 0

          for item in @order.items
            if ('undefined' != typeof item.carrier &&
              [
                'mondialrelay-ld1',
                'mondialrelay-lds',
                'relaiscolis-eco',
                'relaiscolis-confort',
                'schenker',
                'geodis'
              ].includes(item.carrier))
              if item.carrier in ['relaiscolis-eco'] && item.product.packages?
                nbPackages += item.product.packages.length * item.quantity

              if item.carrier is 'relaiscolis-eco' && nbPackages > 3
                @order.isRcNbPackagesExceeded = true

              if item.carrier is 'relaiscolis-confort' && item.product.packages?
                for pack in item.product.packages
                  if pack.weight > 80
                    @order.isRcWeightExceeded = true

              if item.carrier in ['mondialrelay-ld1', 'mondialrelay-lds']
                weightMr += item.product.weight * item.quantity

              if item.carrier in ['relaiscolis-eco']
                weightRc += item.product.weight * item.quantity

              if (['schenker'].includes(item.carrier))
                weightSr += item.product.weight * item.quantity

              if (['geodis'].includes(item.carrier))
                weightGeodis += item.product.weight * item.quantity

              switch item.carrier
                when 'mondialrelay-ld1' then cubageLd1 += item.product.m3 * item.quantity
                when 'mondialrelay-lds' then cubageLds += item.product.m3 * item.quantity
                when 'relaiscolis-eco' then cubageRce += item.product.m3 * item.quantity

              if (item.carrier is 'mondialrelay-ld1' || item.carrier is 'mondialrelay-lds') && weightMr >= 199
                @order.isMrWeightExceeded = true

              if item.carrier is 'relaiscolis-eco' && weightRc > 70
                @order.isRcEWeightExceeded = true

              if ('schenker' == item.carrier && 35 < weightSr)
                @order.isSrWeightExceeded = true

              if ('geodis' == item.carrier && 35 < weightGeodis)
                @order.isGeodisWeightExceeded = true

          if cubageLd1 >= 2 || cubageLds >= 4
            @order.isMrCubageExceeded = true

          if cubageRce > 1
            @order.isRcCubageExceeded = true

        populateCarriers: ->
          @order.isMondialRelay = typeof @order.carrier is 'string' && /^mondialrelay/.test(@order.carrier)
          @order.isGls = @order.carrier is 'gls'

          if !@order.isGls && !@order.isMondialRelay && @order.items.length > 1
            for item in @order.items
              if /^mondialrelay/.test(item.carrier) && !@order.isMondialRelay
                @order.isMondialRelay = true
              else if item.carrier is 'gls' && !@order.isGls
                @order.isGls = true

              if @order.isGls && @order.isMondialRelay
                break

        formatErrors: ->
          @order.hasErrors = Object.keys(@order.errors).length
          inline = for key, value of @order.errors
            "#{ key }: #{ value }"
          @order.formattedErrors = inline.join('\n')

        formatWarnings: ->
          @order.hasWarnings = Object.keys(@order.warnings).length
          inline = for key, value of @order.warnings
            "#{ key }: #{ value }"
          @order.formatWarnings = inline.join('\n')

        populate: ->
          @formatErrors()
          @formatWarnings()
          @populateAwaitingData()
          @populateCanChange()
          @populateStats()
          @populateOldValues()
          @populateFlagAlert()
          @populateCarriers()
  ]
