import { FULL_EUROPEEN_DATE_FORMAT } from '@constants';
import { IItem, IOrder, IOrderManagerListLight, IReverse, IShippingAddress } from './order-manager-list-light.interface';
import * as moment from 'moment';

export class OrderManagerListLightModel implements IOrderManagerListLight {
  id: number;
  locale: string;
  orderId: string;
  items: IItem[];
  date: string;
  shippingAddress: IShippingAddress;
  reverse: IReverse | null;
  country: string;

  constructor(order: IOrder) {
    this.id = order.id;
    this.locale = order.locale;
    this.orderId = order.orderId;
    this.items = order.items;
    this.date = moment(order.date).format(FULL_EUROPEEN_DATE_FORMAT);
    this.shippingAddress = order.shippingAddress;
    this.reverse = order.reverse;
    this.country = this.setHtmlImageCountry(order.locale);
  }

  private setHtmlImageCountry(locale: string): string {
    let html = locale;

    try {
      const countryCode = locale.split('_')[1];
      const src = `../images/flags/${countryCode.toLowerCase()}.png`;
      html = `<img src="${src}" alt="${countryCode}"/>`;
    } catch (error) {
      console.error(error);
    }

    return html;
  }
}
