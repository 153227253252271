import {OrderResource} from '@resources';
import {takeUntil} from 'rxjs/operators';
import {SessionHelper} from '@helpers';
import {IMarketplace} from '@interfaces';
import {Input} from '@angular/core';

export default orderManagerRoutes;
/** @ngInject */
function orderManagerRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'order',
    reloadChangeCountrySearchParam: 'id',
    btnAreGrey: false,
    tabs: [
      {
        label: 'PAGE.ORDER_MANAGER.TAB.GENERAL',
        state: 'order_manager.edit'
      },
      {
        label: 'PAGE.ORDER_MANAGER.TAB.PAYMENT',
        state: 'order_manager.payment'
      },
      {
        label: 'PAGE.ORDER_MANAGER.TAB.SHIPPING',
        state: 'order_manager.shipping'
      },
      {
        label: 'PAGE.ORDER_MANAGER.TAB.REVERSE',
        state: 'order_manager.reverse'
      },
      {
        label: 'PAGE.ORDER_MANAGER.TAB.SYNCHRONIZATION',
        state: 'order_manager.synchronization'
      },
      {
        label: 'PAGE.ORDER_MANAGER.TAB.HISTORY',
        state: 'order_manager.history'
      },
      {
        label: 'PAGE.ORDER_MANAGER.TAB.LITIGATION',
        state: 'order_manager.litigation'
      },
      {
        label: 'PAGE.ORDER_MANAGER.TAB.COMMENT',
        state: 'order_manager.comment'
      },
      {
        label: 'PAGE.ORDER_MANAGER.TAB.ASSOCIATED',
        state: 'order_manager.associated'
      },
      {
        label: 'BREADCRUMB.ORDER_MANAGER.LIST-LIGHT',
        state: 'order_manager_light'
      },
      {
        label: 'PAGE.ORDER_MANAGER.TAB.CUSTOMER_HISTORY',
        state: 'customer_history'
      },
      {
        label: 'PAGE.ORDER_MANAGER.TAB.RELATION',
        state: 'order_manager.relation'
      },
    ]
  };
  $stateProvider
    .state('customer_history', {
      url: `/customers/:customerId/walitickets?
        customer`,
    })
    .state('order_manager.relation', {
      url: `/orders/:id/relation?
        order`,
      views: {
        'page@layout': { template: '<app-order-manager-form></app-order-manager-form>' }
      },
      breadcrumb: { label: 'BREADCRUMB.EDIT' },
      data: Object.assign(commonStateData, {
        title: 'PAGE.ORDER.EDIT.TITLE',
        reloadChangeCountry: 'order_manager.relation',
      })
    })
    .state('order_manager.relation_edit', {
      url: '/orders/:id/relation/edit/:ticketId',
      views: {
        'page@layout': { template: '<app-order-manager-form></app-order-manager-form>' }
      },
      breadcrumb: { label: 'BREADCRUMB.EDIT' },
      data: Object.assign(commonStateData, {
        title: 'PAGE.ORDER.EDIT.TITLE',
        reloadChangeCountry: 'order_manager.relation_edit',
      })
    })
    .state('order_manager.relation_new', {
      url: '/orders/:id/relation/new',
      views: {
        'page@layout': { template: '<app-order-manager-form></app-order-manager-form>' }
      },
      breadcrumb: { label: 'BREADCRUMB.EDIT' },
      data: Object.assign(commonStateData, {
        title: 'PAGE.ORDER.EDIT.TITLE',
        reloadChangeCountry: 'order_manager.relation_new',
      })
    })
    .state('order_manager_light', {
      url: '/order-manager-light',
      parent: 'walisoft',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.ORDER_MANAGER.LIST-LIGHT',
        stateName: 'order_manager_light.list'
      }
    })
    .state('order_manager_light.list', {
      url: `?
      page
      &search
      &orderId[]
      &countries[]
      &dateStart
      &dateEnd
      &skus[]
      &hasReverse
    `,
      views: {
        'page@layout': {
          template: '<app-order-manager-list-light></app-order-manager-list-light>'
        }
      },
      data: {
        title: 'BREADCRUMB.ORDER_MANAGER.LIST-LIGHT',
        showCountries: false
      }
    })
    .state('order_manager', {
      url: '/order-manager',
      parent: 'walisoft',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.ORDER_MANAGER.LIST',
        stateName: 'order_manager.list'
      }
    })
    .state('order_manager.list', {
      url: `?
        page
        &search
        &indicatorFilters[]
        &countries[]
        &dateStart
        &dateEnd
        &marketplaces[]
        &warehouses[]
        &carriers[]
        &skus[]
        &skuParent
        &postcode
        &paymentType
        &hasComment
        &hasRefund
        &isSageExported
        &orderManagerStatuses[]
        &dateExportLogisticStart
        &dateExportLogisticEnd
        &dateExportSageStart
        &dateExportSageEnd
        &indicatorStatuses[]
        &collaborators
      `,
      views: {
        'page@layout': {
          template: '<app-order-manager-list></app-order-manager-list>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.ORDER_MANAGER.LIST.TITLE',
        showCountries: false
      })
    })
    .state('order_manager.list-light', {
      url: `-light?
        page
        &countries[]
        &dateEnd
        &dateStart
        &hasReverse
        &orderId[]
        &search
        &skus[]
      `,
      views: {
        'page@layout': {
          template: '<app-order-manager-list-light></app-order-manager-list-light>'
        }
      },
      abstract: true,
      breadcrumb: { label: 'BREADCRUMB.ORDER_MANAGER.LIST-LIGHT' },
      data: {
        title: 'BREADCRUMB.ORDER_MANAGER.LIST-LIGHT',
        showCountries: false
      }
    })
    .state('order_manager.edit', {
      url: '/orders/:id',
      views: {
        'page@layout': { template: '<app-order-manager-form></app-order-manager-form>' }
      },
      breadcrumb: { label: 'BREADCRUMB.EDIT' },
      data: Object.assign(commonStateData, {
        title: 'PAGE.ORDER.EDIT.TITLE',
        reloadChangeCountry: 'order_manager.edit',
      })
    })
    .state('order_manager.shipping', {
      url: '/orders/:id/shipping',
      views: {
        'page@layout': { template: '<app-order-manager-form></app-order-manager-form>' }
      },
      breadcrumb: { label: 'BREADCRUMB.EDIT' },
      data: Object.assign(commonStateData, {
        title: 'PAGE.ORDER.EDIT.TITLE',
        reloadChangeCountry: 'order_manager.shipping',
      })
    })
    .state('order_manager.comment', {
      url: '/orders/:id/comment',
      views: {
        'page@layout': { template: '<app-order-manager-form></app-order-manager-form>' }
      },
      breadcrumb: { label: 'BREADCRUMB.EDIT' },
      data: Object.assign(commonStateData, {
        title: 'PAGE.ORDER.EDIT.TITLE',
        reloadChangeCountry: 'order_manager.comment',
      })
    })
    .state('order_manager.payment', {
      url: '/orders/:id/payment?refund&reason',
      views: {
        'page@layout': { template: '<app-order-manager-form></app-order-manager-form>' }
      },
      breadcrumb: { label: 'BREADCRUMB.EDIT' },
      data: Object.assign(commonStateData, {
        title: 'PAGE.ORDER.EDIT.TITLE',
        reloadChangeCountry: 'order_manager.payment.edit',
      })
    })
    .state('order_manager.synchronization', {
      url: '/orders/:id/synchronization',
      views: {
        'page@layout': { template: '<app-order-manager-form></app-order-manager-form>' }
      },
      breadcrumb: { label: 'BREADCRUMB.EDIT' },
      data: Object.assign(commonStateData, {
        title: 'PAGE.ORDER.EDIT.TITLE',
        reloadChangeCountry: 'order_manager.synchronization',
      })
    })
    .state('order_manager.history', {
      url: '/orders/:id/history',
      views: {
        'page@layout': { template: '<app-order-manager-form></app-order-manager-form>' }
      },
      breadcrumb: { label: 'BREADCRUMB.EDIT' },
      data: Object.assign(commonStateData, {
        title: 'PAGE.ORDER.EDIT.TITLE',
        reloadChangeCountry: 'order_manager.history',
      })
    })
    .state('order_manager.litigation', {
      url: '/orders/:id/litigation',
      views: {
        'page@layout': { template: '<app-order-manager-form></app-order-manager-form>' }
      },
      breadcrumb: { label: 'BREADCRUMB.EDIT' },
      data: Object.assign(commonStateData, {
        title: 'PAGE.ORDER.EDIT.TITLE',
        reloadChangeCountry: 'order_manager.litigation',
      })
    })
    .state('order_manager.reverse', {
      url: '/orders/:id/reverse',
      views: {
        'page@layout': { template: '<app-order-manager-form></app-order-manager-form>' }
      },
      breadcrumb: { label: 'BREADCRUMB.EDIT' },
      data: Object.assign(commonStateData, {
        title: 'PAGE.ORDER.EDIT.TITLE',
        reloadChangeCountry: 'order_manager.reverse',
      })
    })
    .state('order_manager.associated', {
      url: '/orders/:id/associated',
      views: {
        'page@layout': { template: '<app-order-manager-form></app-order-manager-form>' }
      },
      breadcrumb: { label: 'BREADCRUMB.EDIT' },
      data: Object.assign(commonStateData, {
        title: 'PAGE.ORDER.EDIT.TITLE',
        reloadChangeCountry: 'order_manager.associated',
      })
    })
    .state('order_manager.scaling_form', {
      url: '/scaling',
      views: {
        'page@layout': { template: '<app-order-manager-scaling-form></app-order-manager-scaling-form>' }
      },
      breadcrumb: { label: 'BREADCRUMB.EDIT' },
      data: Object.assign(commonStateData, {
        title: 'PAGE.CARRIER_SCALE.FORM.TITLE',
        reloadChangeCountry: 'order_manager.scaling_form',
      })
    })
    .state('order_manager.scaling_list', {
      url: '/scaling-list',
      views: {
        'page@layout': { template: '<app-order-manager-scaling-list></app-order-manager-scaling-list>' }
      },
      breadcrumb: { label: 'BREADCRUMB.EDIT' },
      data: Object.assign(commonStateData, {
        title: 'PAGE.CARRIER_SCALE.LIST.TITLE',
        reloadChangeCountry: 'order_manager.scaling_list',
      })
    })
    .state('order_manager.sage_exports', {
      url: '/sage-exports',
      views: {
        'page@layout': { template: '<app-order-manager-sage-export-list></app-order-manager-sage-export-list>' }
      },
      breadcrumb: { label: 'BREADCRUMB.EDIT' },
      data: Object.assign(commonStateData, {
        showCountries: true,
        title: 'PAGE.SAGE_EXPORT.LIST.TITLE',
        reloadChangeCountry: 'order_manager.sage_exports',
      })
    })
    .state('order_manager.delay_reason_order_list', {
      url: `/delayed-orders?
        page
        &countries[]
        &dateStart
        &dateEnd
        &etdStart
        &etdEnd
        &skus[]
        &marketplaceOrigin
        &orderManagerStatuses[]
        &delayReason
        &indicatorStatuses[]
      `,
      views: {
        'page@layout': { template: '<app-delay-reason-order-list></app-delay-reason-order-list>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.DELAY_REASON_ORDER_LIST.LIST.TITLE',
        reloadChangeCountry: 'order_manager.delay_reason_order_list',
      })
    })
    ;
}
