import {Component, Inject, Input} from '@angular/core';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { AuthService } from '@services/auth.service';
import { AbstractResource, BlockResource } from '@resources';
import { SessionHelper } from '@helpers';

@Component({
  selector: 'app-website-module',
  template: require('./website-module.component.html'),
  providers: [
    { provide: AbstractResource, useClass: BlockResource },
  ]
})

export class WebsiteModuleComponent extends AbstractPageComponent {
  public currentLocales: string[] = SessionHelper.getCountry().locales;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, null, state);
  }

  public hasSeveralTranslations(): boolean {
    return this.currentLocales.length > 1;
  }
}
