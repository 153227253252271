import { Component, Inject, OnInit } from '@angular/core';
import { AbstractPageComponent } from '../generic/abstract-page.component';
import { AuthService, FormNotifierService } from '@services';
import { ReverseFormService } from '@components/reverse/services';
import { takeUntil } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { ReverseProductResource } from '@components/reverse/resources/reverse.product.resource';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { SnackbarService } from '@components/snackbar';
import { DATE_FULL_FORMAT, DATE_SHORT_FORMAT } from '@constants';
import * as moment from 'moment';
import { AbstractResource } from '@resources';

@Component({
  selector: 'app-reverse-case-workshop',
  template: require('./reverse-case-workshop.component.html'),
  providers: [
    { provide: AbstractResource, useClass: ReverseProductResource },
  ]
})
export class ReverseCaseWorkshopComponent extends AbstractPageComponent implements OnInit {

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    public reverseFormService: ReverseFormService,
    public reverseProductResource: ReverseProductResource,
    public snackbar: SnackbarService,
    public formNotifier: FormNotifierService,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    this.reverseFormService.fetchReverse(this.state.params.id);
  }

  public submit(backToList: boolean): void {
    const observables: any[] = [];

    this.reverseFormService.reverseWorkshops.controls.forEach((formGroup: FormGroup) => {
      const workshop = formGroup.value;

      workshop.repairedAt = workshop.repairedAt ? moment(workshop.repairedAt, DATE_SHORT_FORMAT).startOf('day').format(DATE_FULL_FORMAT) : null;
      observables.push(this.reverseProductResource.updateReverseProductWorkshop(workshop.id, workshop));
    });

    forkJoin(observables)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        () => {
          this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));

          if (backToList) {
            this.state.go('reverses.list');

            return;
          }

          this.formNotifier.notifyFormSubmitted();
        },
      )
    ;
  }
}
