import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-date-check',
  template: require('./date-between-switch.html'),
})
export class IsDateBetweenSwitchComponent implements OnInit {
  @Input() dateStart: Date;
  @Input() dateEnd: Date;
  isValid = false;
  isDateInRange(): boolean {
    return new Date() >= new Date(this.dateStart) && new Date() <= new Date(this.dateEnd);
  }

  ngOnInit(): void {
    this.isValid = this.isDateInRange();
  }

}
