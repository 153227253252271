import {IRelation} from '@components/order-manager/relation/order-manager-relation.interface';

function translate(key: string = '', translate_instance: any, params: object = {}): string {
  if (null === key || undefined === key) {
    return;
  }

  return translate_instance.instant('' + key.toString(), params);
}
export function generateSummaryForTicket(ticket: IRelation, truncated: boolean, translate_instance: any): string {
  let summary: any = [];
  let step1Value = ticket.steps[0] ? ticket.steps[0].stepValue : null;
  let step2Value = ticket.steps[1] ? ticket.steps[1].stepValue : null;
  if (step1Value) {
    if (String(step1Value).includes(',')) {
      step1Value = step1Value.split(',').map((value: any) => translate('PAGE.RELATION.DATA.' + value, translate_instance)).join(',');
    } else {
      if (!String(translate('PAGE.RELATION.DATA.' + step1Value, translate_instance)).includes('PAGE.')) {
        step1Value = translate('PAGE.RELATION.DATA.' + step1Value, translate_instance);
      }
    }
    step1Value = step1Value.replace(',', ' / ');
  }
  if (step2Value) {
    if (String(step2Value).includes(',')) {
      step2Value = step2Value.split(',').map((value: any) => translate('PAGE.RELATION.DATA.' + value, translate_instance)).join(',');
    } else {
      if (!String(translate('PAGE.RELATION.DATA.' + step2Value, translate_instance)).includes('PAGE.')) {
        step2Value = translate('PAGE.RELATION.DATA.' + step2Value, translate_instance);
      }
    }
    step2Value = step2Value.replace(',', ' / ');
  }
  let imagesAttachments: any[];
  let videoAttachments: any[];
  let imagesCount: string;
  let videoCount: string;
  let comment: string;
  let reason: string;
  imagesAttachments = ticket.attachments.filter((attachment: any) => attachment.typeOfFile === 'image');
  videoAttachments = ticket.attachments.filter((attachment: any) => attachment.typeOfFile === 'video');
  if (imagesAttachments) {
    imagesCount = imagesAttachments.length + ' image(s)';
  }
  if (videoAttachments) {
    videoCount = videoAttachments.length + ' video(s)';
  }
  if (truncated) {
    comment = ticket.comment ? ticket.comment.substring(0 , 20) + ' ...' : null;
  } else {
    comment = ticket.comment ? 'Commentaire: ' + ticket.comment.toLowerCase() : null;
  }

  const type = ticket.type ? translate('PAGE.RELATION.FIELDS.THEME.' + ticket.type.toUpperCase(), translate_instance) : null;
  if (ticket.type === 'sav') {
    reason = ticket.reason ? translate('PAGE.RELATION.FIELDS.REASONS.' + ticket.reason.toUpperCase(), translate_instance) : null;
  } else if (ticket.type === 'business') {
    reason = ticket.reason ? translate('PAGE.RELATION.FIELDS.REASONS_COMMERCE.' + ticket.reason.toUpperCase(), translate_instance) : null;
  } else {
    reason = ticket.reason ? translate('PAGE.RELATION.FIELDS.REASONS_TRACKING.' + ticket.reason.toUpperCase(), translate_instance) : null;
  }

  summary.push(type, reason, step1Value, step2Value, imagesCount, videoCount);
  summary = summary.filter((elt: any) => elt);
  summary = summary.map((element: string) => element.toUpperCase());
  summary.push(comment);
  return summary.join(' / ');
}
