import { AfterViewInit, Directive, ElementRef, HostListener, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: 'kendo-slider[sliderTooltip]',
  host: {'class': 'slider-tooltiped'}
})
export class SliderTooltipDirective implements AfterViewInit {
  private tooltipNow: any;

  constructor(private element: ElementRef, @Inject(DOCUMENT) private document: any) {}

  /**
   * @inheritDoc
   */
  ngAfterViewInit() {
    const element = this.element.nativeElement;
    const min = element.attributes.getNamedItem('aria-valuemin');
    const max = element.attributes.getNamedItem('aria-valuemax');
    const now = element.attributes.getNamedItem('aria-valuenow');

    // min tooltip
    const tooltipMin = this.document.createElement('div');
    tooltipMin.classList.add('slider-tooltip-item', 'slider-tooltip-item-min');
    tooltipMin.innerHTML = min.nodeValue;
    element.appendChild(tooltipMin);

    // max tooltip
    const tooltipMax = this.document.createElement('div');
    tooltipMax.classList.add('slider-tooltip-item', 'slider-tooltip-item-max');
    tooltipMax.innerHTML = max.nodeValue;
    element.appendChild(tooltipMax);

    // max tooltip
    this.tooltipNow = this.document.createElement('div');
    this.tooltipNow.classList.add('slider-tooltip-item', 'slider-tooltip-item-now');
    this.tooltipNow.innerHTML = now.nodeValue;
    element.appendChild(this.tooltipNow);
    this.moveTooltip();

  }

  /**
   * Handles click on slider
   */
  @HostListener('click')
  private click(): void {
    this.moveTooltip();
  }

  /**
   * Handles swipe on slider
   *
   * @param {number} value
   */
  @HostListener('valueChange', ['$event'])
  private valueChanged(value: number): void {
    this.tooltipNow.innerHTML = value;
    this.moveTooltip();
  }

  /**
   * Moves value tooltip to follow the bubble part of the slider
   */
  private moveTooltip(): void {
    const bubble = this.element.nativeElement.querySelector('.k-draghandle');
    this.tooltipNow.style.left = bubble.style.left;
  }
}
