export default attributeRoutes;

/** @ngInject */
function attributeRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'walipush',
    sidebarSubNav: 'attribute',
    title: '',
    reloadChangeCountry: 'attribute.list',
  };

  $stateProvider
    .state('attribute', {
      parent: 'walisoft',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.ATTRIBUTE.LIST',
        stateName: 'attribute.list'
      }
    })
    .state('attribute.table', {
      url: '/attributes-table',
      views: {
        'page@layout': {
          template: '<app-attribute-table></app-attribute-table>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.ATTRIBUTE.TABLE.TITLE'
      })
    })
    .state('attribute.list', {
      url: '/attributes',
      views: {
        'page@layout': {
          template: '<app-attribute-list></app-attribute-list>'
        }
      },
      data: {
        sidebarNav: 'walipush',
        sidebarSubNav: 'attributeList',
        reloadChangeCountry: 'attribute.list',
        title: 'PAGE.ATTRIBUTE.LIST.TITLE',
      }
    })
    .state('attribute.new', {
      url: '/attributes/new',
      breadcrumb: {
        label: 'BREADCRUMB.NEW'
      },
      views: {
        'page@layout': {
          template: '<app-attribute-new></app-attribute-new>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.ATTRIBUTE.NEW.TITLE'
      })
    })
    .state('attribute.edit', {
      url: '/attributes/:id/edit',
      breadcrumb: {
        label: 'BREADCRUMB.EDIT'
      },
      views: {
        'page@layout': {
          template: '<app-attribute-edit></app-attribute-edit>'
        }
      },
      data: Object.assign(commonStateData, {
        reloadChangeCountry: 'attribute.edit',
        reloadChangeCountrySearchParam: 'id',
        title: 'PAGE.ATTRIBUTE.EDIT.TITLE',
      })
    })
    ;
}
