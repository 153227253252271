interface IObjectId {
    id: number|string;
}

interface IObjectPosition {
    position: number;
}

export class CollectionHelper {

    /**
     * Shrinks an array as a string of ids to help comparison between collections
     *
     * @static
     * @param {Array<Object>} [collection=[]]
     * @param {string} [property='id']
     * @param {string} [separator='-']
     * @returns {string}
     * @memberof CollectionHelper
     */
    static convertToFieldList(collection: Array<Object> = [], property: string = 'id', separator: string = '-'): string {
        return collection.map((item: any) => {
            if (!item.hasOwnProperty(property)) {
                throw new Error('Unable to find property "' + property + '" in collection');
            }
            return item[property];
        }).join(separator);
    }

    /**
     * Refreshs position properties depending on indexes in the array
     *
     * @static
     * @template T
     * @param {Array<Object>} [collection=[]]
     * @returns {T[]}
     * @memberof CollectionHelper
     */
    static refreshPosition<T extends IObjectPosition>(collection: Array<Object> = []): T[] {
        return collection.map((item, index) => {
            return <T> Object.assign({}, item, { position: index });
        });
    }

    /**
     * Extract an object identified by its id
     *
     * @static
     * @template T
     * @param {T[]} collection
     * @param {(number|string|null)} [id=null]
     * @returns {T[]}
     * @memberof CollectionHelper
     */
    static omit<T extends IObjectId>(collection: T[], id: number|string|null = null ): T[] {
        if (!Array.isArray(collection)) {
            throw new TypeError('You have to specify a collection');
        }

        return collection.filter((item) => item.id !== id);
    }

    /**
     * Removes deeply undefined properties from object.
     *
     * @param {{[keys: string]: any}} obj
     * @returns {{[keys: string]: any}}
     * @memberof AttributeHelper
     */
    public static removeUndefined (obj: {[keys: string]: any}): {[keys: string]: any} {
      Object.keys(obj).forEach(key => {
        if (obj[key] && typeof obj[key] === 'object') {
          this.removeUndefined(obj[key]);
        } else if (obj[key] === undefined) {
          delete obj[key];
        }
      });
      return obj;
    }
}
