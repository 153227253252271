export const JPEG: string = '.jpeg';
export const JPG: string = '.jpg';
export const PNG: string = '.png';
export const SVG: string = '.svg';

export const FILE_EXTENSIONS = [
  JPEG,
  JPG,
  PNG,
  SVG
];
