import { ExportLogisticsHistoryResource } from '@components/export-logistics/export-logistics-history/export-logistics-history.resource';

export default exportLogisticsRoutes;

/** @ngInject */
function exportLogisticsRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'carrier',
    sidebarSubNav: 'exportLogistics',
    title: '',
    reloadChangeCountry: 'export-logistics.export',
  };

  $stateProvider
    .state('export-logistics', {
      url: '/export-logistics',
      parent: 'walisoft',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.ORDER.EXPORT_LOGISTICS',
        stateName: 'export-logistics'
      },
      data: Object.assign(commonStateData, {
        showCountries: false
      })
    })
    .state('export-logistics.export', {
      url: '',
      views: {
        'page@layout': {
          template: '<app-export-logistics></app-export-logistics>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.EXPORT_LOGISTICS.TITLE',
        tabs: [
          {
            label: 'PAGE.EXPORT_LOGISTICS.TAB.LAUNCH_EXPORT',
            state: 'export-logistics.export',
          },
          {
            label: 'PAGE.EXPORT_LOGISTICS.TAB.HISTORY',
            state: 'export-logistics.history',
          },
          {
            label: 'PAGE.EXPORT_LOGISTICS.TAB.SCHEDULED',
            state: 'export-logistics.scheduled',
          },
          {
            label: 'PAGE.EXPORT_LOGISTICS.TAB.CROSS_DOCK',
            state: 'export-logistics.cross-dock',
          },
        ],
      })
    })
    .state('export-logistics.history', {
      url: '/history?country_codes[]&carrier_names[]&warehouse_names[]',
      views: {
        'page@layout': {
          template: '<app-export-logistics-history></app-export-logistics-history>'
        }
      },
      breadcrumb: { label: 'BREADCRUMB.ORDER.EXPORT_LOGISTICS.HISTORY' },
      data: Object.assign(commonStateData, {
        title: 'PAGE.EXPORT_LOGISTICS.HISTORY.TITLE',
        tabs: [
          {
            label: 'PAGE.EXPORT_LOGISTICS.TAB.LAUNCH_EXPORT',
            state: 'export-logistics.export',
          },
          {
            label: 'PAGE.EXPORT_LOGISTICS.TAB.HISTORY',
            state: 'export-logistics.history',
          },
          {
            label: 'PAGE.EXPORT_LOGISTICS.TAB.SCHEDULED',
            state: 'export-logistics.scheduled',
          },
          {
            label: 'PAGE.EXPORT_LOGISTICS.TAB.CROSS_DOCK',
            state: 'export-logistics.cross-dock',
          },
        ],
      })
    })
    .state('export-logistics.scheduled', {
      url: '/scheduled',
      views: {
        'page@layout': {
          template: '<app-export-logistics-scheduled></app-export-logistics-scheduled>'
        }
      },
      breadcrumb: { label: 'PAGE.EXPORT_LOGISTICS.TAB.SCHEDULED' },
      data: Object.assign(commonStateData, {
        title: 'PAGE.EXPORT_LOGISTICS.SCHEDULE.TITLE',
        tabs: [
          {
            label: 'PAGE.EXPORT_LOGISTICS.TAB.LAUNCH_EXPORT',
            state: 'export-logistics.export',
          },
          {
            label: 'PAGE.EXPORT_LOGISTICS.TAB.HISTORY',
            state: 'export-logistics.history',
          },
          {
            label: 'PAGE.EXPORT_LOGISTICS.TAB.SCHEDULED',
            state: 'export-logistics.scheduled',
          },
          {
            label: 'PAGE.EXPORT_LOGISTICS.TAB.CROSS_DOCK',
            state: 'export-logistics.cross-dock',
          },
        ],
      })
    })
    .state('export-logistics.cross-dock', {
      url: '/cross-dock',
      views: {
        'page@layout': {
          template: '<app-export-logistics-cross-dock></app-export-logistics-cross-dock>'
        }
      },
      breadcrumb: { label: 'PAGE.EXPORT_LOGISTICS.TAB.CROSS_DOCK' },
      data: Object.assign(commonStateData, {
        title: 'PAGE.EXPORT_LOGISTICS.CROSS_DOCK.TITLE',
        tabs: [
          {
            label: 'PAGE.EXPORT_LOGISTICS.TAB.LAUNCH_EXPORT',
            state: 'export-logistics.export',
          },
          {
            label: 'PAGE.EXPORT_LOGISTICS.TAB.HISTORY',
            state: 'export-logistics.history',
          },
          {
            label: 'PAGE.EXPORT_LOGISTICS.TAB.SCHEDULED',
            state: 'export-logistics.scheduled',
          },
          {
            label: 'PAGE.EXPORT_LOGISTICS.TAB.CROSS_DOCK',
            state: 'export-logistics.cross-dock',
          },
        ],
      })
    })
  ;
}
