import { Component, Inject, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { SnackbarService } from '@components/snackbar/snackbar.service';
import { FormErrorHelper, SessionHelper } from '@helpers';
import { FormSubmitArgument } from '@interfaces';
import { CarrierGroupResource } from '@resources';
import { ICarrierGroup } from '@components/carrier';
import { AuthService } from '@services';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { HydraHelper } from '@helpers/HydraHelper';

@Component({
  selector: 'app-carrier-cost',
  template: require('./carrier-cost.component.html')
})
export class CarrierCostComponent extends AbstractPageComponent implements OnInit {

  public carrierGroup: ICarrierGroup;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: CarrierGroupResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private snackbar: SnackbarService,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit() {
    this.fetch();
  }

  private fetch(): void {
    const subscriber: Subscription = this.resource.get(this.state.params.id)
      .subscribe((carrierGroup: ICarrierGroup) => {
        subscriber.unsubscribe();
        this.carrierGroup = carrierGroup;
      });
  }

  public save({body, form, redirect}: FormSubmitArgument): void {
    const data: any = body;
    Object.keys(data.carriers).forEach((key: any) => {
      if (data.carriers[key].id) {
        data.carriers[key].id = `/api/v2/carriers/${data.carriers[key].id}`;
      }
    });
    SessionHelper.get('APPLICATION_LOCALES').forEach((locale: string) => {
      if (data.translations[locale] && data.translations[locale].id) {
        data.translations[locale].id = HydraHelper.buildIri(data.translations[locale], 'carrier_group_translations');
      }
    });
    const subscriber: Subscription = this.resource.update(this.state.params.id, data)
      .subscribe((response: ICarrierGroup) => {
        subscriber.unsubscribe();

        this.snackbar.validate(this.translate('SAVED'));
        this.carrierGroup.translations[this.currentLocale].id = response.translations[this.currentLocale].id;

        if (redirect) {
          this.actions.list.go();
        }
      }, (error: any) => {
        if (400 === error.code) {
          FormErrorHelper.fillFormControlWithErrors(form, error.errors);
        }
      }
    );
  }
}
