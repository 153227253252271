import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { AbstractComponent } from '@components/generic/abstract.component';
import { IUsers } from '@components/users/models';
import { SessionHelper } from '@helpers/session.helper';
import { ICountry } from '@interfaces/ICountry';
import * as _ from 'lodash';

@Component({
  selector: 'app-users-result-list',
  template: require('./users-result-list.component.html'),
})
export class UsersResultListComponent extends AbstractComponent implements OnInit {

  private _items: any;

  set items(items: any) {
    items.forEach((item: IUsers) => {
      item.roles = Object.values(item.roles);
    });

    this._items = items;
  }

  get items(): any {
    return this._items;
  }

  @Output() public onDelete: EventEmitter<any> = new EventEmitter();
  @Output() public onUpdate: EventEmitter<any> = new EventEmitter();
  @Output() public onUsurp: EventEmitter<any> = new EventEmitter();

  public loggedUser: IUsers;
  public usurpedUser: IUsers;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    @Inject('StateService') state: ng.ui.IStateService,
    authService: AuthService,
    resource: AbstractResource,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    this.loggedUser = SessionHelper.getUser();
    this.usurpedUser = SessionHelper.getUsurpedUser();
  }

  public handleDelete(id: string): void {
    if (!this.isAdmin()) {
      return;
    }
    this.onDelete.emit(id);
  }

  public handleUpdate(id: string): void {
    this.onUpdate.emit(id);
  }

  public handleUsurp(user: IUsers): void {
    if (!this.isAdmin()) {
      return;
    }
    this.onUsurp.emit(user);
  }

  public canBeUsurp(user: IUsers): boolean {
    if (!this.isAdmin()) {
      return false;
    }
    const currentCountryCodes: string[] = SessionHelper.getCountries().map((country: ICountry) => country.code);
    const userCountryCodes: string[] = user.countries.map((country: ICountry) => country.code);
    const countryCodes: string[] = _.intersection(currentCountryCodes, userCountryCodes);

    return _.size(countryCodes) > 0  && user.id !== this.loggedUser.id && !this.usurpedUser;
  }

  public canEditUser(user: IUsers): boolean {
    return this.isAdmin() || this.loggedUser.id === user.id;
  }
}
