export default reverseRoutes;

import { ReverseResource } from '@components/reverse';

/** @ngInject */
function reverseRoutes($stateProvider: any) {
  const commonStateData: {[key: string]: any} = {
    sidebarNav: 'carrier',
    sidebarSubNav: 'reverses',
    title: '',
    reloadChangeCountry: 'reverses.list',
    resource: ReverseResource,
  };

  $stateProvider
    .state('reverses', {
      url: '/reverses',
      parent: 'walisoft',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.REVERSE',
        stateName: 'reverses.list'
      },
      data: Object.assign(commonStateData, {
        showCountries: false
      })
    })
    .state('reverses.list', {
      url: `?
        reverseProduct.reverse.id
        &startDate
        &endDate
        &packageSku[]
        &receiveStatus[]
        &reconditionedStatus[]
        &reverseProduct.reverse.order.id
        &carrierGroup.code[]
        &reverseProduct.reverse.status
        &taskType[]
        &page
        &byPage
        &taskStatus
        &assignedToMe
        &withoutLabel
        &sort_receptionDate
        &reverseProduct.reverse.order.shippingAddress.countryCode[]
        &reverseProduct.reverse.warehouse.code[]
        &reverseProduct.reverse.archived
      `,
      views: {
        'page@layout': { template: '<app-reverse-list></app-reverse-list>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.REVERSE.LIST.TITLE',
      })
    })
    .state('reverses.new', {
        url: '/new?order',
        views: {
            'page@layout': { template: '<app-reverse-case></app-reverse-case>' }
        },
        breadcrumb: { label: 'BREADCRUMB.NEW' },
        data: Object.assign(commonStateData, {
          title: 'PAGE.REVERSE.NEW.TITLE',
          tabs: [
            {
              label: 'PAGE.REVERSE.TABS.GENERAL',
              state: 'reverses.new',
            },
            {
              label: 'PAGE.REVERSE.TABS.TASKS',
            },
          ],
        })
    })
    .state('reverses.edit', {
      url: '/:id',
      views: {
        'page@layout': { template: '<app-reverse-case></app-reverse-case>' }
      },
      breadcrumb: { label: 'BREADCRUMB.EDIT' },
      data: Object.assign(commonStateData, {
        title: 'PAGE.REVERSE.EDIT.TITLE',
        tabs: [
          {
            label: 'PAGE.REVERSE.TABS.GENERAL',
            state: 'reverses.edit',
          },
          // {
          //   label: 'PAGE.REVERSE.TABS.WORKSHOP',
          //   state: 'reverses.edit.workshop',
          // },
          {
            label: 'PAGE.REVERSE.TABS.TASKS',
            state: 'reverses.edit.tasks',
          },
        ],
      })
    })
    .state('reverses.edit.tasks', {
      url: '/tasks',
      views: {
        'page@layout': { template: '<app-reverse-case-tasks></app-reverse-case-tasks>' }
      },
      breadcrumb: { label: 'BREADCRUMB.TASKS' },
      data: Object.assign(commonStateData, {
        title: 'PAGE.REVERSE.TASKS.TITLE',
        tabs: [
          {
            label: 'PAGE.REVERSE.TABS.GENERAL',
            state: 'reverses.edit',
          },
          // {
          //   label: 'PAGE.REVERSE.TABS.WORKSHOP',
          //   state: 'reverses.edit.workshop',
          // },
          {
            label: 'PAGE.REVERSE.TABS.TASKS',
            state: 'reverses.edit.tasks',
          },
        ],
      }),
    })
    .state('reverses.edit.workshop', {
      url: '/workshop',
      views: {
        'page@layout': { template: '<app-reverse-case-workshop></app-reverse-case-workshop>' }
      },
      breadcrumb: { label: 'BREADCRUMB.WORKSHOP' },
      data: Object.assign(commonStateData, {
        title: 'PAGE.REVERSE.WORKSHOP.TITLE',
        tabs: [
          {
            label: 'PAGE.REVERSE.TABS.GENERAL',
            state: 'reverses.edit',
          },
          // {
          //   label: 'PAGE.REVERSE.TABS.WORKSHOP',
          //   state: 'reverses.edit.workshop',
          // },
          {
            label: 'PAGE.REVERSE.TABS.TASKS',
            state: 'reverses.edit.tasks',
          },
        ],
      }),
    })
  ;
}
