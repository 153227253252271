import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { AbstractComponent } from '@components/generic/abstract.component';
import { CategoriesMarketplaceModel } from '@components/categories-marketplace/models';

/**
 * Display the result of the categories marketplaces.
 */
@Component({
  selector: 'app-categories-marketplace-result-list',
  template: require('./categories-marketplace-result-list.component.html')
})
export class CategoriesMarketplaceResultListComponent extends AbstractComponent {

  private _items: any;

  set items(items: any) {
    this._items = items.categories.map((category: any) => new CategoriesMarketplaceModel(category));
  }

  get items(): any {
    return this._items;
  }

  @Output() onUpdate: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }

  public updateCategory(event: any, id: string) {
    this.onUpdate.emit({active: event, id});
  }
}
