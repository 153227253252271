import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import {SessionHelper, StringHelper} from '@helpers';
import { AuthService, FormNotifierService } from '@services';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import { CommercialOperationsResource } from '../resources/commercial-operations.resource';
import { FormService } from './form.service';
import { ICountry, IFormViolation, ISku } from '@interfaces';
import { ICommercialOperations, ICommercialOperationsTranslation } from '../models/commercial-operations.interface';
import { Subscription } from 'rxjs/Subscription';
import { AbstractResource, MarketplaceResource } from '@resources';
import { SnackbarService } from '../../snackbar/snackbar.service';
import {
  COMMERCIAL_OPERATION_BUNDLE,
  COMMERCIAL_OPERATION_CLASSICAL,
  COMMERCIAL_OPERATION_CREDIT,
  COMMERCIAL_OPERATION_FLASH_SALE,
  COMMERCIAL_OPERATION_SALE,
  COMMERCIAL_OPERATION_NEW,
  COMMERCIAL_OPERATION_GOOD_DEALS,
} from '../constants/commercial-operations.constants';
import { DATE_FULL_FORMAT, MARKETPLACES_AG } from '@constants';
import { AbstractFormComponent } from '@components/generic/Form/abstract-form.component';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { ICommercialOperationFormData } from '@components/commercial-operations/interfaces/commercial-operation-form.interfaces';
import { CMSResource } from '@components/cms';
import moment = require('moment');
import { NO_INDEX_NO_FOLLOW } from '@components/categories';

interface IOption {
  label: string;
  value: string;
}

@Component({
  selector: 'app-commercial-operation-form',
  template: require('./commercial-operation-form.component.html'),
  styles: [require('./commercial-operation-form.component.scss')],
  providers: [
    { provide: AbstractResource, useClass: CommercialOperationsResource },
  ],
})
export class CommercialOperationFormComponent extends AbstractFormComponent implements OnInit, OnDestroy {

  public form: FormGroup;
  public countries: ICountry[];
  public marketplaces: any[] = [];
  public operationTypes: any[] = [];
  public sortingOptions: IOption[] = [];
  public hideField: boolean;
  public violations: IFormViolation[] | any = [];

  @Input() public model: ICommercialOperations;
  @Input() public inCreation: boolean;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    @Inject('DialogService') private dialog: any,
    public formNotifier: FormNotifierService,
    public resource: AbstractResource,
    private marketplaceResource: MarketplaceResource,
    private formService: FormService,
    private snackbar: SnackbarService,
    private cmsResource: CMSResource,
  ) {
    super($translate, authService, null, state);
  }

  ngOnInit(): void {
    this.countries = SessionHelper.getCountries();
    this.form = this.formService.getForm(this.model);

    if (!this.inCreation) {
      const arrayControls = this.form.get('translations') as FormArray;
      this.model.translations.forEach((translation: ICommercialOperationsTranslation) => {
        arrayControls.push(this.formService.createTranslationForm(translation));
      });

      this.fetchMarketplaces(this.model.country.code);
    } else {
      this.form.get('country').setValue(SessionHelper.getCountry());
      this.updateMarketplaceFieldData(SessionHelper.getCountry());
    }

    this.form.statusChanges.subscribe(() => {
      if (this.form.dirty) {
        this.formNotifier.notifyFormInEdition();
      }
    });

    if (this.form.controls.archived.value) {
      this.form.disable();
    }
  }

  ngOnDestroy(): void {
    this.formNotifier.notifyFormIsDestroyed();
  }

  /**
   * Fired at component init then when switching country.
   */
  public updateMarketplaceFieldData(country: ICountry): void {
    this.prepareTranslationsForForm(country);
    this.fetchMarketplaces(country.code);
  }

  /**
   * When the user select another country, we have to clean then prepare translations form array.
   */
  private prepareTranslationsForForm(country: ICountry): void {
    const arrayControls = this.form.get('translations') as FormArray;

    while (arrayControls.length !== 0) {
      arrayControls.removeAt(0);
    }

    country.locales.forEach((locale: string) => {

      const translation: ICommercialOperationsTranslation = {
        title: '',
        description: '',
        locale: locale,
      };

      arrayControls.push(this.formService.createTranslationForm(translation));
    });
  }

  private fetchMarketplaces(countryCode: string): void {
    const subscriber: Subscription = this.marketplaceResource.filterByCountryCode(countryCode)
      .subscribe((response: any) => {
        this.marketplaces = response.sort((a: any, b: any) => a.code.localeCompare(b.code));

        let marketplace = response[0];

        if (!this.inCreation) {
          marketplace = response.find((item: any) => item.code === this.model.marketplace);
        }

        this.form.get('marketplace').setValue(marketplace);
        this.updateOperationTypeFieldData(marketplace);
      }, undefined, () => subscriber.unsubscribe());
  }

  /**
   * Fired at component init then when switching marketplace.
   */
  private updateOperationTypeFieldData(marketplace: any): void {
    if (MARKETPLACES_AG.includes(marketplace.code)) {
      this.operationTypes = [COMMERCIAL_OPERATION_CLASSICAL, COMMERCIAL_OPERATION_FLASH_SALE, COMMERCIAL_OPERATION_SALE,
        COMMERCIAL_OPERATION_BUNDLE, COMMERCIAL_OPERATION_CREDIT, COMMERCIAL_OPERATION_NEW, COMMERCIAL_OPERATION_GOOD_DEALS];
      this.form.get('defaultSorting').setValidators(Validators.required);
      this.form.get('defaultSorting').updateValueAndValidity();
      this.hideField = false;
    } else {
      this.operationTypes = [COMMERCIAL_OPERATION_CLASSICAL, COMMERCIAL_OPERATION_SALE];
      this.form.get('defaultSorting').clearValidators();
      this.form.get('defaultSorting').updateValueAndValidity();
      this.hideField = true;
    }

    let type = this.operationTypes[0];

    if (!this.inCreation) {
      type = this.operationTypes.find((item) => item.value === this.model.type);
    }

    this.form.get('type').setValue(type);
    this.initDefaultSortingChoice(type.value);
  }

  /**
   * Checks if country chosen has several locales.
   */
  public hasSeveralTranslation(): boolean {
    return this.form.get('country').value.locales.length > 1;
  }

  /**
   * Gets translation form necessary for FormTabTranslation.
   */
  public getTranslation(key?: string): FormGroup {
    const translations: FormArray = this.form.get('translations') as FormArray;

    if (this.hasSeveralTranslation() && undefined !== key) {
      return translations.controls.find((group: FormGroup) => key === group.get('locale').value) as FormGroup;
    }

    return translations.controls[0] as FormGroup;
  }

  /**
   * Cancels form and redirect to list.
   */
  public cancel(): void {
    this.dialog.confirm(this.translate('PAGE.CATEGORY.CONFIRM.BACK_TO_LIST'))
      .then(() => this.state.go(`${this.resource.routeName}.list`));
  }

  public setColorValue(color: Event, name: string): void {
    this.form.get(name).setValue(color);
    this.form.markAsDirty();
  }

  public isFlashSale(): boolean {
    return COMMERCIAL_OPERATION_FLASH_SALE.value === this.form.controls.type.value.value;
  }

  public isCredit(): boolean {
    return COMMERCIAL_OPERATION_CREDIT.value === this.form.controls.type.value.value;
  }

  public isNew(): boolean {
    return COMMERCIAL_OPERATION_NEW.value === this.form.controls.type.value.value;
  }

  public isBundle(): boolean {
    return COMMERCIAL_OPERATION_BUNDLE.value === this.form.controls.type.value.value;
  }

  public isGoodDeals(): boolean {
    return COMMERCIAL_OPERATION_GOOD_DEALS.value === this.form.controls.type.value.value;
  }

  public isClassical(): boolean {
    return COMMERCIAL_OPERATION_CLASSICAL.value === this.form.controls.type.value.value;
  }

  public isBestSAle(): boolean {
    return COMMERCIAL_OPERATION_SALE === this.form.controls.type.value.value;
  }

  public getTranslatableString(): string {
      const type = this.form.get('defaultSorting').value;
      if (undefined === type) {
        return '';
      }

      return this.translate(`PAGE.COMMERCIAL_OPERATIONS.EMERCH.DESCRIPTION.${type.replace(' ', '_').toUpperCase()}`);
    }

  public submit(): void {
    this.formatErrors();

    if (!this.form.dirty || !this.form.valid) {
      let errorMessage = 'ALERTS.NO_CHANGE.FORM';

      if (!this.form.valid) {
        errorMessage = 'ALERTS.ERROR.FORM';
      }

      this.snackbar.warn(this.translate(errorMessage));

      return;
    }

    const body: ICommercialOperations = this.formService.prepareBody(this.form.value);

    const localesForImages: string[] = [];

    this.save(body)
      .takeUntil(this.destroyed$)
      .subscribe(
        (response: any) => {
          this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
          if (!this.inCreation) {
            this.formNotifier.notifyFormSubmitted();
          }
          this.state.go(`${this.resource.routeName}.edit`, { id: response.id }, { reload: true });
        },
        (reject: any) => {
          if (reject.hasOwnProperty('violations')) {
            this.violations = reject.violations.map((violation: IFormViolation): IFormViolation => {
              return {
                propertyPath: violation.propertyPath,
                message: violation.message
              };
            });
          }
        }
      )
      ;
  }

  public save(body: ICommercialOperations): Observable<any> {
    body.startDate = moment(body.startDate, DATE_FULL_FORMAT).isValid()
      ? moment(body.startDate, DATE_FULL_FORMAT).seconds(0).format(DATE_FULL_FORMAT)
      : null;
    body.endDate = moment(body.endDate, DATE_FULL_FORMAT).isValid()
      ? moment(body.endDate, DATE_FULL_FORMAT).seconds(59).format(DATE_FULL_FORMAT)
      : null;

    if (this.inCreation) {
      for (const locale in body.translations) {
        if (body.translations.hasOwnProperty(locale)) {
          body.translations[locale].seoMetaRobot = NO_INDEX_NO_FOLLOW;
        }
      }
    }

    return this.inCreation ?
      this.resource.create(body) :
      this.resource.update(this.state.params.id, body)
      ;
  }

  public formatErrors(): void {
    while (this.violations.length) { this.violations.pop(); }
    const errorLists = document.querySelectorAll('.errors-block');

    for (let i: number = 0; i < errorLists.length; i++) {
      if (errorLists[i].classList.contains('hidden')) {
        errorLists[i].classList.remove('hidden');
      }
    }
  }

  public isArchived(): boolean {
    return this.form.controls.archived.value;
  }

  public getTypeTooltip(): string {
    const type = this.form.get('type').value.value;

    if (undefined === type) {
      return '';
    }

    return this.translate(`PAGE.COMMERCIAL_OPERATIONS.FORM.HELP.${type.replace(' ', '_').toUpperCase()}`);
  }

  public initDefaultSortingChoice(type: any) {
    this.sortingOptions = [
      { label: this.translate('PAGE.COMMERCIAL_OPERATIONS.FORM.LABEL.DEFAULT_SORTING.VALUES.MANUAL'), value: 'manual' },
      { label: this.translate('PAGE.COMMERCIAL_OPERATIONS.FORM.LABEL.DEFAULT_SORTING.VALUES.PRICE_ASC'), value: 'price asc' },
      { label: this.translate('PAGE.COMMERCIAL_OPERATIONS.FORM.LABEL.DEFAULT_SORTING.VALUES.PRICE_DESC'), value: 'price desc' },
    ];

    switch (type) {
      case COMMERCIAL_OPERATION_CLASSICAL.value:
      case COMMERCIAL_OPERATION_FLASH_SALE.value:
      case COMMERCIAL_OPERATION_SALE.value:
        this.sortingOptions.push(
          {label: this.translate('PAGE.COMMERCIAL_OPERATIONS.FORM.LABEL.DEFAULT_SORTING.VALUES.DEFAULT_MERCHANDISING'), value: 'default_merchandising' }
        );
        break;
      case COMMERCIAL_OPERATION_NEW.value:
        this.sortingOptions.push(
          { label: this.translate('PAGE.COMMERCIAL_OPERATIONS.FORM.LABEL.DEFAULT_SORTING.VALUES.NOVELTY_MERCHANDISING'), value: 'novelty_merchandising' },
        );
        break;
      case COMMERCIAL_OPERATION_GOOD_DEALS.value:
        this.sortingOptions.push(
          { label: this.translate('PAGE.COMMERCIAL_OPERATIONS.FORM.LABEL.DEFAULT_SORTING.VALUES.GOOD_DEAL_MERCHANDISING'), value: 'good_deal_merchandising' },
        );
        break;
    }
  }
}
