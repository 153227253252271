import { RedirectsResource } from '@components';

export default redirectsRoutes;

/** @ngInject */
function redirectsRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'website',
    sidebarSubNav: 'redirects',
    title: '',
    reloadChangeCountry: 'redirects.list',
  };

  $stateProvider
    .state('redirects', {
      url: '/redirects',
      parent: 'website',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.REDIRECTS',
        stateName: 'redirects.list'
      }
    })
    .state('redirects.list', {
      url: `?
        fromRoute&
        toRoute&
        automatic&
        fromStatusCode&
        toStatusCode&
        finalStatusCode&
        startDate&
        endDate&
        sort_updatedAt&
        sort_fromRoute&
        sort_toRoute&
        sort_automatic&
        sort_createdBy.username&
        page&
        byPage
      `,
      views: {
        'page@layout': {
          template: '<app-redirect-list></app-redirect-list>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.REDIRECTS.LIST.TITLE',
      })
    })
    .state('redirects.new', {
      url: '/new',
      breadcrumb: { label: 'BREADCRUMB.NEW' },
      views: {
        'page@layout': { template: '<app-redirects></app-redirects>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.REDIRECTS.NEW.TITLE',
      })
    })
    .state('redirects.edit', {
      url: '/:id',
      breadcrumb: { label: 'BREADCRUMB.EDIT' },
      views: {
        'page@layout': { template: '<app-redirects></app-redirects>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.REDIRECTS.EDIT.TITLE',
      })
    })
  ;
}
