import { CarrierScaleScheduleResource } from './resources/carrier-scale-schedule.resource';
import { CarrierScaleScheduledModel } from './models/carrier-scale-scheduled.model';
import { Component, Inject, OnInit } from '@angular/core';
import { SnackbarService } from '@components/snackbar/snackbar.service';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { WarehouseCarrierGroupAgenciesModel } from '@components/warehouses/models/warehouse-carrier-group-agencies.model';
import { WarehouseCarrierGroupAgenciesResource } from '@components/warehouses/warehouse-carrier-group-agencies.resource';
import { AuthService } from '@services';
import { LogisticsScheduledModel } from './models/logistics-scheduled.model';
import { LogisticsScheduleResource } from './resources/logistics-schedule.resource';
import { SessionHelper } from '@helpers';
import moment = require('moment');

@Component({
  selector: 'app-export-logistics-scheduled',
  template: require('./export-logistics-scheduled.component.html'),
})
export class ExportLogisticsScheduledComponent extends AbstractPageComponent implements OnInit {
  public items: LogisticsScheduledModel[] = [];
  public collapses: boolean[] = [];
  public readonly SCHEDULE_TABLE = {
    header: {
      title: 'PAGE.EXPORT_LOGISTICS.SCHEDULE.MODAL.TABLE.TITLE',
      days: [
        'PAGE.EXPORT_LOGISTICS.SCHEDULE.MODAL.TABLE.DAYS.MONDAY',
        'PAGE.EXPORT_LOGISTICS.SCHEDULE.MODAL.TABLE.DAYS.TUESDAY',
        'PAGE.EXPORT_LOGISTICS.SCHEDULE.MODAL.TABLE.DAYS.WEDNESDAY',
        'PAGE.EXPORT_LOGISTICS.SCHEDULE.MODAL.TABLE.DAYS.THURSDAY',
        'PAGE.EXPORT_LOGISTICS.SCHEDULE.MODAL.TABLE.DAYS.FRIDAY',
        'PAGE.EXPORT_LOGISTICS.SCHEDULE.MODAL.TABLE.DAYS.SATURDAY',
        'PAGE.EXPORT_LOGISTICS.SCHEDULE.MODAL.TABLE.DAYS.SUNDAY',
      ],
      hours: Array(24)
        .fill(null)
        .map((x: number, i: number) => ('0' + i).slice(-2)),
    },
  };

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private logisticsScheduleResource: LogisticsScheduleResource,
    private carrierScalingScheduleResource: CarrierScaleScheduleResource,
    private warehouseCarrierGroupAgenciesResource: WarehouseCarrierGroupAgenciesResource,
    @Inject('DialogService') private dialog: any,
    private snackbar: SnackbarService
  ) {
    super($translate, authService, null, state);
  }

  ngOnInit(): void {
    this.fetch();
  }

  private fetch() {
    this.logisticsScheduleResource
      .cGet()
      .takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        this.warehouseCarrierGroupAgenciesResource
          .cGet()
          .takeUntil(this.destroyed$)
          .subscribe((response2: any) => {
            const warehouseCarrierGroupAgencies = response2['hydra:member'].map((member: any) => {
              return new WarehouseCarrierGroupAgenciesModel(member);
            });
            this.items = response['hydra:member'].map((member: any) => {
              return new LogisticsScheduledModel(member, warehouseCarrierGroupAgencies);
            });
          });
      });
  }

  public toggleSchedule(value: boolean, item: LogisticsScheduledModel): void {
    this.dialog
      .confirm(
        this.translate(
          value
            ? 'PAGE.EXPORT_LOGISTICS.SCHEDULE.CONFIRM.UPDATE_YES'
            : 'PAGE.EXPORT_LOGISTICS.SCHEDULE.CONFIRM.UPDATE_NO'
        )
      )
      .then(
        () => {
          this.logisticsScheduleResource
            .partialUpdate(item.id, {
              active: value,
            })
            .takeUntil(this.destroyed$)
            .subscribe((response: any) => {
              this.snackbar.validate(this.translate('PAGE.EXPORT_LOGISTICS.SCHEDULE.UPDATED'));
            });
        },
        () => {
          item.active = !value;
        }
      );
  }

  public toggleScaleSchedule(value: boolean, item: CarrierScaleScheduledModel): void {
    this.dialog
      .confirm(
        this.translate(
          value
            ? 'PAGE.EXPORT_LOGISTICS.SCHEDULE.CONFIRM.UPDATE_SCALE_YES'
            : 'PAGE.EXPORT_LOGISTICS.SCHEDULE.CONFIRM.UPDATE_SCALE_NO'
        )
      )
      .then(
        () => {
          this.carrierScalingScheduleResource
            .partialUpdate(item.id, {
              active: value,
            })
            .takeUntil(this.destroyed$)
            .subscribe((response: any) => {
              this.snackbar.validate(this.translate('PAGE.EXPORT_LOGISTICS.SCHEDULE.UPDATED'));
            });
        },
        () => {
          item.active = !value;
        }
      );
  }

  public hasNextSchedule(item: LogisticsScheduledModel): boolean {
    return item.active && item.schedule.length > 0;
  }

  public getNextSchedule(item: LogisticsScheduledModel): string | null {
    if (!this.hasNextSchedule(item)) {
      return null;
    }

    const nextSchedule = item.schedule[0];
    nextSchedule.locale(SessionHelper.getUILanguage().split('_').slice(0, 1));

    return nextSchedule
      .calendar(null, {
        sameDay: function (now) {
          if (this.diff(now, 'minute', true) < 45) {
            return '[' + this.fromNow() + ']';
          }

          return '[' + this.calendar() + ']';
        },
        sameElse: function (now) {
          return 'dddd [' + (this.locale() === 'fr' ? 'à' : 'at') + '] LT';
        },
      })
      .toLocaleLowerCase();
  }

  public getCountryTranslation(translations: any): string {
    return translations[SessionHelper.getUILanguage()].name;
  }

  public getScheduleTable(schedules: moment.Moment[]): boolean[][] {
    const scheduleTable = Array(7)
      .fill(null)
      .map((v) => Array(24).fill(false));
    for (let i = 0; i < schedules.length; i++) {
      let x: number, y: number;
      [x, y] = [schedules[i].isoWeekday() - 1, schedules[i].hour()];
      scheduleTable[x][y] = true;
    }

    return scheduleTable;
  }

  public delete(index: number): void {
    this.dialog.confirm(this.translate('PAGE.EXPORT_LOGISTICS.SCHEDULE.CONFIRM.DELETE')).then(() => {
      this.logisticsScheduleResource
        .remove(this.items[index].id)
        .takeUntil(this.destroyed$)
        .subscribe((response: any) => {
          this.items = [].concat(this.items.slice(0, index), this.items.slice(index + 1));
          this.snackbar.validate(this.translate('PAGE.EXPORT_LOGISTICS.SCHEDULE.DELETED'));
        });
    });
  }
}
