import { IHydraMember } from '@interfaces/hydra-resource.interface';

export class EavAttributeOptionModel {
  '@id': string;
  id: number;
  option: string;

  constructor(attributeOption: IHydraMember) {
    this['@id'] = attributeOption['@id'];
    this.id = attributeOption.id;
    this.option = attributeOption.option;
  }
}
