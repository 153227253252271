import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlagDirective, InputCounterDirective, SliderTooltipDirective, WysiwygDirective } from './';

@NgModule({
  declarations: [
    InputCounterDirective,
    SliderTooltipDirective,
    WysiwygDirective,
    FlagDirective,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    InputCounterDirective,
    SliderTooltipDirective,
    WysiwygDirective,
    FlagDirective,
  ],
})
export class EnhancedFieldsModule {}
