
import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { CollectionHelper } from './CollectionHelper';
import { IAttribute, IAttributeValue } from '@components/attribute/models/attribute.interface';
import {IAttributeFamily} from '@components/attribute-family/models/attribute-family.interface';

/**
 * Attribute Helper
 */
@Injectable()
export class AttributeHelper {
  /**
   * Clean object attribute before send to api
   *
   * @param {IAttribute} attribute
   *
   * @returns {IAttribute}
   */
  public cleanObject(attribute: IAttribute): IAttribute {
    delete attribute.id;
    delete attribute.attributeFilterables;
    delete attribute.attributeActives;
    delete attribute.attributeValues;

    return attribute;
  }

  /**
   * Updates toTranslate bool on updated translation.
   *
   * @param {IAttribute} attribute
   * @param {IAttribute} originalAttribute
   *
   * @returns {IAttribute}
   */
  public updateToTranslate(attribute: IAttribute, originalAttribute: IAttribute): IAttribute {
    for (const locale in attribute.translations) {
      if (attribute.translations.hasOwnProperty(locale)) {
        const attributeTranslation = attribute.translations[locale];
        const originalTranslation = originalAttribute.translations[locale];

        if (attributeTranslation.name !== (originalTranslation && originalTranslation.name)) {
          attributeTranslation.toTranslate = false;
        }
      }
    }

    return attribute;
  }

  /**
   * Gets new attribute values.
   *
   * @param {object} attribute
   *
   * @returns {IAttributeValue[]}
   */
  public getNewAttributeValues(attribute: IAttribute): IAttributeValue[] {
    return attribute.attributeValues.filter(
      attributeValue => 'undefined' === typeof attributeValue.id
    );
  }

  /**
   * Gets new attribute families.
   *
   * @param {object} attribute
   *
   * @returns {IAttributeFamily[]}
   */
  public getNewAttributeFamilies(attribute: IAttribute): IAttributeFamily[] {
    return attribute.attributeFamilies.filter(
      attributeFamily => 'undefined' === typeof attributeFamily.id
    );
  }

  /**
   * Gets edited attribute values.
   *
   * @param {IAttribute} attribute
   * @param {IAttribute} originalAttribute
   *
   * @returns {IAttributeValue[]}
   */
  public getEditedAttributeValues(attribute: IAttribute, originalAttribute: IAttribute): IAttributeValue[] {
    CollectionHelper.removeUndefined(attribute);
    return _.differenceWith(
      attribute.attributeValues.filter(
        attributeValue => 'undefined' !== typeof attributeValue.id
      ), originalAttribute.attributeValues, _.isEqual
    );
  }

  /**
   * Gets edited attribute families.
   *
   * @param {IAttribute} attribute
   * @param {IAttribute} originalAttribute
   *
   * @returns {IAttributeFamily[]}
   */
  public getEditedAttributeFamilies(attribute: IAttribute, originalAttribute: IAttribute): IAttributeFamily[] {
    CollectionHelper.removeUndefined(attribute);
    return _.differenceWith(
      attribute.attributeFamilies.filter(
        attributeFamily => 'undefined' !== typeof attributeFamily.id
      ), originalAttribute.attributeFamilies, _.isEqual
    );
  }

  /**
   * Fills missing translations
   *
   * @param {IAttribute} attribute
   * @param {string}     fromLocale
   *
   * @returns {IAttribute}
   */
  public copyTranslationsFromLocale(attribute: IAttribute, fromLocale: string): IAttribute {
    const fromTranslation = attribute.translations[fromLocale];
    const fromName = fromTranslation.name;

    for (const locale in attribute.translations) {
      if (attribute.translations.hasOwnProperty(locale)) {
        if ('' === attribute.translations[locale].name) {
          attribute.translations[locale].name = fromName;
          attribute.translations[locale].toTranslate = true;
        }
      }
    }

    return attribute;
  }
}
