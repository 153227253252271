import { AbstractResource } from '@resources/abstract.resource';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { Observable } from 'rxjs/Observable';

@Injectable()
@Resource({
  entryPoint: '/v2/carrier_blacklist_marketplaces',
  routeName: 'carrier',
  isHydra: true,
})
export class CarrierBlacklistMarketplacesResource extends AbstractResource {

  constructor(
    http: HttpClient
  ) {
    super(http);
  }

  public blacklist(carrierGroup: string, marketplaces: string[], country: string): Observable<any> {
    return super.create({carrierGroup: carrierGroup, marketplaces: marketplaces, country: country});
  }
}
