import { Component, Inject, Input, OnInit } from '@angular/core';
import { AbstractProductStatus } from '../../AbstractProductStatus';
import { AuthService } from '@services/auth.service';

/**
 * Displays the status of the product.
 */
@Component({
  selector: 'app-product-status',
  template: require('./product-status.component.html'),
  styles: [require('./product-status.component.scss')]
})
export class ProductStatusComponent extends AbstractProductStatus implements OnInit {

  public label: string;
  public statusBackground: string;

  @Input() private status: string;

  constructor (
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, state);
  }

  /**
   * @inheritDoc
   */
  ngOnInit() {
    if (!this.hasOwnProperty('status')) {
      throw new Error(this.translate('PAGE.PRODUCT.STATUSES.COMPONENTS.MISSING_STATUS'));
    }

    this.label = this.getProductStatusLabel(this.status);
    this.statusBackground = this.getProductStatusBackground(this.status);
  }
}
