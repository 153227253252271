import { AbstractResource } from '../resources/abstract.resource';
import { Injectable } from '@angular/core';
import { Resource } from '@decorators/Resource';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  routeName: 'reviewer',
  entryPoint: '/reviewers',
  translationKey: 'NOTATIONS',
  createAvailable: false,
  updateAvailable: false,
  deleteAvailable: false,
})
export class ReviewerResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }
}
