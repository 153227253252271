import { Component, Inject, OnInit } from '@angular/core';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { MarketplaceHelper, SessionHelper } from '@helpers';
import { IWebsiteConfiguration } from '@models';
import { ReviewerResource, WebsiteConfigurationResource } from '@resources';
import 'rxjs/add/observable/merge';
import { AuthService } from '@services';
import { forkJoin } from 'rxjs/observable/forkJoin';

@Component({
  selector: 'app-notation-list',
  template: require('./notation-list.component.html')
})
export class NotationListComponent extends AbstractPageComponent implements OnInit {
  public configuration: IWebsiteConfiguration;
  public notations: any;

  private activeHeaderNotations: Array<string>;
  private activeFooterNotations: Array<string>;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: ReviewerResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private configurationResource: WebsiteConfigurationResource,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit() {
    this.fetch();
  }

  /**
   * Checks if the current country can use a feature.
   *
   * @returns {boolean}
   */
  public isWebsiteFeature(): boolean {
    return MarketplaceHelper.hasWebsiteMarketplace();
  }

  /**
   * Is row drag n' drop enable?
   *
   * @param {any} item
   * @returns {boolean}
   */
  public isDragDropEnable(item: any): boolean {
    return item.activeHeader || item.activeFooter;
  }

  public dropSuccess(fromItem: any, toItem: any): void {
    const indexFrom = this.notations.indexOf(fromItem);
    const indexTo = this.notations.indexOf(toItem);
    this.notations[indexFrom] = toItem;
    this.notations[indexTo] = fromItem;

    this.updateNotations();
  }

  /**
   * Updates API with notation's changes!
   */
  public updateNotations(): void {
    this.configurationResource.update(
      this.configuration.locale,
      {
        headerReviewers: this.notations
          .filter((notation: any) => notation.activeHeader)
          .map((notation: any) => notation.label),
        footerReviewers: this.notations
          .filter((notation: any) => notation.activeFooter)
          .map((notation: any) => notation.label)
      }
    ).subscribe((response: IWebsiteConfiguration) => {
      this.storeConfiguration(response);
      this.storeNotations();
    });
  }

  /**
   * Fetches API to get configuration.
   */
  private fetch(): void {
    const observableArray = [];
    observableArray.push(this.configurationResource.get(SessionHelper.getLocale()));

    observableArray.push(this.resource.getMany());
    forkJoin(observableArray)
      .takeUntil(this.destroyed$)
      .subscribe(
        (response) => {
          this.storeConfiguration(response[0]);
          this.notations = response[1];
          this.storeNotations();
        }
      );
  }

  /**
   * Stores current website configuration and active footer reviewers
   *
   * @param configuration
   */
  private storeConfiguration(configuration: any): void {
    this.configuration = configuration;
    this.activeHeaderNotations = configuration.headerReviewers;
    this.activeFooterNotations = configuration.footerReviewers;
  }

  /**
   * Stores all notations, mark them as active or not and sort them
   */
  private storeNotations(): void {
    this.notations = this.notations.map((service: any) => {
      let label = '';
      if ('string' === typeof service) {
        label = service;
      } else {
        label = service.label;
      }

      return {
        label: label,
        activeHeader: this.activeHeaderNotations.includes(label),
        activeFooter: this.activeFooterNotations.includes(label)
      };
    })
    .sort(this.sortByActive.bind(this));
  }

  /**
   * Sorts an array of active notations.
   *
   * @param {object} a
   * @param {object} b
   * @returns {number}
   */
  private sortByActive(a: any, b: any): number {
    const aActive = this.isDragDropEnable(a);
    const bActive = this.isDragDropEnable(b);

    // sort active first
    if (aActive && !bActive) {
      return -1;
    } else if (!aActive && bActive) {
      return 1;
    } else {
      const aHeaderIndex = this.activeHeaderNotations.indexOf(a.label);
      const aFooterIndex = this.activeFooterNotations.indexOf(a.label);
      const bHeaderIndex = this.activeHeaderNotations.indexOf(b.label);
      const bFooterIndex = this.activeFooterNotations.indexOf(b.label);

      if (-1 !== aHeaderIndex && -1 !== bHeaderIndex) {
        return aHeaderIndex > bHeaderIndex ? 1 : -1;
      }

      if (-1 !== aFooterIndex && -1 !== bFooterIndex) {
        return aFooterIndex > bFooterIndex ? 1 : -1;
      }

      return 0;
    }
  }
}
