import {Component, Inject, OnInit} from '@angular/core';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { SessionHelper } from '@helpers';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-website-homepage-highlights',
  template: require('./website-homepage-highlights.component.html'),
})

export class WebsiteHomepageHighlightsComponent extends AbstractPageComponent implements OnInit {
  public currentLocales: string[] = SessionHelper.getCountry().locales;
  public descriptionRteOptions: any;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, null, state);
  }

  ngOnInit(): void {
    this.descriptionRteOptions = {
      toolbar: 'bold',
      cleanup: true,
      cleanup_on_startup: true,
      force_p_newlines: true,
      force_br_newlines: true,
      verify_css_classes: true,
      verify_html: true,
      keep_styles: false,
      forced_root_block: '',
      valid_elements: 'b,strong,p,br',
    };
  }

  public hasSeveralTranslations(): boolean {
    return this.currentLocales.length > 1;
  }
}
