export const FORM_VALUES = [
  {
    'REASON': {
      'TRANSPORT': {
        'STEP_1': {
          'PARCEL_OK': {
            'STEP_2': {
              'DEFORMATION': {
                'sparepart': 'SPAREPART',
                'avoir': 'AVOIR',
                'semirefund': 'SEMIREFUND',
                'totalrefund': 'TOTALREFUND',
                'resent_total': 'RESENT_TOTAL',
                'resent_semi': 'RESENT_SEMI',
                'empty_sav_order': 'EMPTY_SAV_ORDER'
              },
              'BROKEN': {
                'sparepart': 'SPAREPART',
                'avoir': 'AVOIR',
                'semirefund': 'SEMIREFUND',
                'totalrefund': 'TOTALREFUND',
                'resent_total': 'RESENT_TOTAL',
                'resent_semi': 'RESENT_SEMI',
                'empty_sav_order': 'EMPTY_SAV_ORDER'
              },
              'SCRATCHES': {
                'sparepart': 'SPAREPART',
                'avoir': 'AVOIR',
                'semirefund': 'SEMIREFUND',
                'totalrefund': 'TOTALREFUND',
                'resent_total': 'RESENT_TOTAL',
                'resent_semi': 'RESENT_SEMI',
                'empty_sav_order': 'EMPTY_SAV_ORDER'
              }
            }
          },
          'PARCEL_OUT': {
            'STEP_2': {
              'DEFORMATION': {
                'sparepart': 'SPAREPART',
                'avoir': 'AVOIR',
                'semirefund': 'SEMIREFUND',
                'totalrefund': 'TOTALREFUND',
                'resent_total': 'RESENT_TOTAL',
                'resent_semi': 'RESENT_SEMI',
                'empty_sav_order': 'EMPTY_SAV_ORDER'
              },
              'BROKEN': {
                'sparepart': 'SPAREPART',
                'avoir': 'AVOIR',
                'semirefund': 'SEMIREFUND',
                'totalrefund': 'TOTALREFUND',
                'resent_total': 'RESENT_TOTAL',
                'resent_semi': 'RESENT_SEMI',
                'empty_sav_order': 'EMPTY_SAV_ORDER'
              },
              'SCRATCHES': {
                'sparepart': 'SPAREPART',
                'avoir': 'AVOIR',
                'semirefund': 'SEMIREFUND',
                'totalrefund': 'TOTALREFUND',
                'resent_total': 'RESENT_TOTAL',
                'resent_semi': 'RESENT_SEMI',
                'empty_sav_order': 'EMPTY_SAV_ORDER'
              },
              'MISSING': {
                'sparepart': 'SPAREPART',
                'avoir': 'AVOIR',
                'semirefund': 'SEMIREFUND',
                'totalrefund': 'TOTALREFUND',
                'resent_total': 'RESENT_TOTAL',
                'resent_semi': 'RESENT_SEMI',
                'empty_sav_order': 'EMPTY_SAV_ORDER'
              }
            }
          }
        }
      },
      'SUPPLIER': {
        'STEP_1': {
          'PACKING_ERROR': {
            'STEP_2': {
              'MISSING_PIECE': 'MISSING_PIECE',
              'CHINA_ERROR': 'CHINA_ERROR'
            },
          },
          'MANUFACTURING_DEFECT': {
            'STEP_2': {
              'LESS_3_MONTHS': 'LESS_3_MONTHS',
              'BETWEEN_3_6_MONTHS': 'BETWEEN_3_6_MONTHS',
              'MORE_6_MONTHS': 'MORE_6_MONTHS'
            }
          },
          'PREMATURE_WEAR': 'PREMATURE_WEAR',
          'PRODUCT_NOT_WORKING': {
            'STEP_2': {
              'ASSEMBLY': 'ASSEMBLY',
              'USAGE': 'USAGE'
            }
          }
        },
        'SOLUTION': {
          'LITIGATION': {
            'sparepart': 'SPAREPART',
            'avoir': 'AVOIR',
            'semirefund': 'SEMIREFUND',
            'totalrefund': 'TOTALREFUND',
            'resent_total': 'RESENT_TOTAL',
            'resent_semi': 'RESENT_SEMI',
            'empty_sav_order': 'EMPTY_SAV_ORDER'
          },
          'NOTLITIGATION': {
            'sparepart': 'SPAREPART',
            'avoir': 'AVOIR',
            'semirefund': 'SEMIREFUND',
            'totalrefund': 'TOTALREFUND',
            'resent_total': 'RESENT_TOTAL',
            'resent_semi': 'RESENT_SEMI',
            'empty_sav_order': 'EMPTY_SAV_ORDER'
          }
        }
      },
      'CUSTOMER': {
        'STEP_1': {
          'DIFFICULTY_ASSEMBLY': {
            'STEP_2': {
              'UNCLEAR_INSTRUCTIONS': {
                'advice': 'ADVICE',
                'sparepart': 'SPAREPART',
                'avoir': 'AVOIR',
                'semirefund': 'SEMIREFUND',
                'resent_total': 'RESENT_TOTAL',
                'resent_semi': 'RESENT_SEMI',
                'totalrefund': 'TOTALREFUND',
                'empty_sav_order': 'EMPTY_SAV_ORDER'
              },
              'OTHERS': {
                'advice': 'ADVICE',
                'sparepart': 'SPAREPART',
                'avoir': 'AVOIR',
                'semirefund': 'SEMIREFUND',
                'resent_total': 'RESENT_TOTAL',
                'resent_semi': 'RESENT_SEMI',
                'totalrefund': 'TOTALREFUND',
                'empty_sav_order': 'EMPTY_SAV_ORDER'
              }
            }
          },
          'FAKE_PROBLEM': {
            'STEP_2': {
              'OTHERS': {
                'advice': 'ADVICE',
                'sparepart': 'SPAREPART',
                'avoir': 'AVOIR',
                'semirefund': 'SEMIREFUND',
                'resent_total': 'RESENT_TOTAL',
                'resent_semi': 'RESENT_SEMI',
                'totalrefund': 'TOTALREFUND',
                'empty_sav_order': 'EMPTY_SAV_ORDER'
              },
              'FAKE_PROBLEM': {
                'advice': 'ADVICE',
                'sparepart': 'SPAREPART',
                'avoir': 'AVOIR',
                'semirefund': 'SEMIREFUND',
                'resent_total': 'RESENT_TOTAL',
                'resent_semi': 'RESENT_SEMI',
                'totalrefund': 'TOTALREFUND',
                'empty_sav_order': 'EMPTY_SAV_ORDER'
              }
            }
          },
          'USAGE_PROBLEM': {
            'STEP_2': {
              'MAINTENANCE': {
                'advice': 'ADVICE',
                'sparepart': 'SPAREPART',
                'avoir': 'AVOIR',
                'semirefund': 'SEMIREFUND',
                'resent_total': 'RESENT_TOTAL',
                'resent_semi': 'RESENT_SEMI',
                'totalrefund': 'TOTALREFUND',
                'empty_sav_order': 'EMPTY_SAV_ORDER'
              },
              'STORAGE': {
                'advice': 'ADVICE',
                'sparepart': 'SPAREPART',
                'avoir': 'AVOIR',
                'semirefund': 'SEMIREFUND',
                'resent_total': 'RESENT_TOTAL',
                'resent_semi': 'RESENT_SEMI',
                'totalrefund': 'TOTALREFUND',
                'empty_sav_order': 'EMPTY_SAV_ORDER'
              },
              'USAGE': {
                'advice': 'ADVICE',
                'sparepart': 'SPAREPART',
                'avoir': 'AVOIR',
                'semirefund': 'SEMIREFUND',
                'resent_total': 'RESENT_TOTAL',
                'resent_semi': 'RESENT_SEMI',
                'totalrefund': 'TOTALREFUND',
                'empty_sav_order': 'EMPTY_SAV_ORDER'
              }
            }
          }
        }
      },
      'SWEEEK': {
        'STEP_1': {
          'PRODUCT_MISTAKE': {
            'STEP_2': {
              'DEFAULT': {
                'SOLUTION': {
                  'sparepart': 'SPAREPART',
                  'avoir': 'AVOIR',
                  'semirefund': 'SEMIREFUND',
                  'totalrefund': 'TOTALREFUND',
                  'resent_total': 'RESENT_TOTAL',
                  'resent_semi': 'RESENT_SEMI',
                  'empty_sav_order': 'EMPTY_SAV_ORDER'
                }
              }
            }
          },
          'INSTRUCTIONS_MISTAKE': {
            'STEP_2': {
              'DEFAULT': {
                'SOLUTION': {
                  'sparepart': 'SPAREPART',
                  'avoir': 'AVOIR',
                  'semirefund': 'SEMIREFUND',
                  'totalrefund': 'TOTALREFUND',
                  'resent_total': 'RESENT_TOTAL',
                  'resent_semi': 'RESENT_SEMI',
                  'empty_sav_order': 'EMPTY_SAV_ORDER'
                }
              }
            }
          },
          'ERROR_PREP_SPAREPART': {
            'STEP_2': {
              'DEFAULT': {
                'SOLUTION': {
                  'sparepart': 'SPAREPART',
                  'avoir': 'AVOIR',
                  'semirefund': 'SEMIREFUND',
                  'totalrefund': 'TOTALREFUND',
                  'resent_total': 'RESENT_TOTAL',
                  'resent_semi': 'RESENT_SEMI',
                  'empty_sav_order': 'EMPTY_SAV_ORDER'
                }
              }
            }
          },
        }
      },
      'DEFECT': {
        'STEP_1': {
          'OVERSOLD': {
            'STEP_2': {
              'DEFAULT': {
                'SOLUTION': {
                  'cancel_refund': 'CANCEL_REFUND',
                  'update_my_order': 'UPDATE_MY_ORDER'
                }
              }
            }
          },
          'CUSTOMER_FAULT': {
            'STEP_2': {
              'DEFAULT': {
                'SOLUTION': {
                  'update': 'UPDATE',
                  'cancel_refund': 'CANCEL_REFUND',
                }
              }
            }
          },
          'NEGATIVE_MARGIN': {
            'STEP_2': {
              'DEFAULT': {
                'SOLUTION': {
                  'cancel_refund': 'CANCEL_REFUND',
                  'force_order': 'FORCE_ORDER'
                }
              }
            }
          },
          'OVERSOLD_ANOMALY': {
            'STEP_2': {
              'DEFAULT': {
                'SOLUTION': {
                  'cancel_refund': 'CANCEL_REFUND',
                  'change_sku': 'CHANGE_SKU'
                }
              }
            }
          },
          'DELAYED_ARRIVAL': {
            'STEP_2': {
              'DEFAULT': {
                'SOLUTION': {
                  'change_sku': 'CHANGE_SKU',
                  'cancel_refund': 'CANCEL_REFUND',
                  'waiting_customer': 'WAITING_CUSTOMER'
                }
              }
            }
          },
          'DUPLICATE': {
            'STEP_2': {
              'DEFAULT': {
                'SOLUTION': {
                  'cancel_refund': 'CANCEL_REFUND',
                  'keep_order': 'KEEP_ORDER'
                }
              }
            }
          }
        },
      },
      'CANCEL_OR_UPDATE': {
        'STEP_1': {
          'PARTIAL_CANCELLATION': {
            'STEP_2': {
              'DEFAULT': {
                'SOLUTION': {
                  'semirefund': 'SEMIREFUND'
                }
              }
            }
          },
          'TOTAL_CANCELLATION': {
            'STEP_2': {
              'DEFAULT': {
                'SOLUTION': {
                  'totalrefund': 'TOTALREFUND'
                }
              }
            }
          },
          'PRICE_ADJUSTMENT': {
            'STEP_2': {
              'ALIGNMENT_DISCOUNT': {
                'avoir': 'AVOIR',
                'semirefund': 'SEMIREFUND'
              },
              'ALIGNMENT_PDM': {
                'avoir': 'AVOIR',
                'semirefund': 'SEMIREFUND'
              }
            }
          },
          'AGEC_LAW': {
            'STEP_2': {
              'DEFAULT': {
                'SOLUTION': {
                  'update_carrier': 'UPDATE_CARRIER'
                }
              }
            }
          },
          'UPDATE_ORDER': {
            'STEP_2': {
              'DEFAULT': {
                'SOLUTION': {
                  'update_order': 'UPDATE_ORDER',
                  'move_delivery': 'MOVE_DELIVERY',
                  'update_carrier': 'UPDATE_CARRIER'
                }
              }
            }
          }
        }
      },
      'ESTIMATE': {
        'STEP_1': {
          'RESEND_ESTIMATE': {
            'STEP_2': {
              'DEFAULT': {
                'SOLUTION': {
                  'send_mail': 'SEND_MAIL'
                }
              }
            }
          },
          'UPDATE_ESTIMATE': {
            'STEP_2': {
              'DEFAULT': {
                'SOLUTION': {
                  'update_estimate': 'UPDATE_ESTIMATE'
                }
              }
            }
          },
          'UNAVAILABLE': {
            'STEP_2': {
              'DEFAULT': {
                'SOLUTION': {
                  'arrival': 'ARRIVAL',
                  'no_arrival': 'NO_ARRIVAL'
                }
              }
            }
          }
        }
      },
      'MANAGEMENT': {
        'STEP_1': {
          'DELETE_CUSTOMER': {
            'STEP_2': {
              'DEFAULT': {
                'SOLUTION': {
                  'create_tma': 'CREATE_TMA'
                }
              }
            }
          },
          'DISCOUNT_UNAVAILABLE': {
            'STEP_2': {
              'DEFAULT': {
                'SOLUTION': {
                  'create_tma': 'CREATE_TMA'
                }
              }
            }
          },
          'UPDATE_CUSTOMER': {
            'STEP_2': {
              'DEFAULT': {
                'SOLUTION': {
                  'create_tma': 'CREATE_TMA'
                }
              }
            }
          },
          'ACCESS_ACCOUNT': {
            'STEP_2': {
              'DEFAULT': {
                'SOLUTION': {
                  'create_tma': 'CREATE_TMA'
                }
              }
            }
          },
          'DISCOUNT_NOT_RECEIVED': {
            'STEP_2': {
              'DEFAULT': {
                'SOLUTION': {
                  'create_tma': 'CREATE_TMA'
                }
              }
            }
          }
        }
      },
      'FAQ_PRODUCT': {
        'STEP_1': {
          'FAQ_ARRIVAL': {
            'STEP_2': {
              'DEFAULT': {
                'SOLUTION': {
                  'no_visibility': 'NO_VISIBILITY',
                  'visibility': 'VISIBILITY'
                }
              }
            }
          },
          'FAQ_PRODUCT': {
            'STEP_2': {
              'DEFAULT': {
                'SOLUTION': {
                  'product_nok': 'PRODUCT_NOK',
                  'product_ok': 'PRODUCT_OK'
                }
              }
            }
          }
        }
      },
      'OTHER': {
        'STEP_1': {
          'RESEND_INVOICE': {
            'STEP_2': {
              'DEFAULT': {
                'SOLUTION': {
                  'send_invoice': 'SEND_INVOICE',
                  'no_invoice': 'NO_INVOICE'
                }
              }
            }
          },
          'MISSING_USER_GUIDE': {
            'STEP_2': {
              'DEFAULT': {
                'SOLUTION': {
                  'send_user_guide': 'SEND_USER_GUIDE'
                }
              }
            }
          },
          'FAQ_DISCOUNT_CO': {
            'STEP_2': {
              'FAQ_DISCOUNT': {
                'avoir': 'AVOIR',
                'bug': 'BUG',
                'cgu_problem': 'CGU_PROBLEM'
              },
              'FAQ_CO': {
                'avoir': 'AVOIR',
                'bug': 'BUG',
                'cgu_problem': 'CGU_PROBLEM',
                'refund_order': 'REFUND_ORDER'
              },
            }
          },
          'PAIEMENT_PROBLEM': {
            'STEP_2': {
              'DEFAULT': {
                'SOLUTION': {
                  'send_payment_link': 'SEND_PAYMENT_LINK'
                }
              }
            }
          },
          'WAITING_REFUND': {
            'STEP_2': {
              'DEFAULT': {
                'SOLUTION': {
                  'no_action': 'NO_ACTION'
                }
              }
            }
          }
        }
      },
      'RETRACTATION': {
        'STEP_1': {
          'BY_CUSTOMER': {
            'STEP_2': {
              'DEFAULT': {
                'SOLUTION': {
                  'create_reverse': 'CREATE_REVERSE'
                }
              }
            }
          },
          'BY_OURSELF': {
            'STEP_2': {
              'DEFAULT': {
                'SOLUTION': {
                  'create_reverse': 'CREATE_REVERSE',
                  'deduct_fee_refund': 'DEDUCT_FEE_REFUND'
                }
              }
            }
          }
        },
      },
      'ADDRESS_CHANGE': {
        'STEP_1': {
          'SOLVE_BY_CUSTOMER': {
            'STEP_2': {
              'DEFAULT': {
                'SOLUTION': {
                  'resent_address': 'RESENT_ADDRESS',
                  'refund_order': 'REFUND_ORDER',
                  'close_ticket': 'CLOSE_TICKET'
                }
              }
            }
          },
          'BY_OUR_SERVICE': {
            'STEP_2': {
              'DEFAULT': {
                'SOLUTION': {
                  'update_contact_info': 'UPDATE_CONTACT_INFO'
                }
              }
            }
          },
          'CONTACT_CARRIER_SAME_DEPARTMENT': {
            'STEP_2': {
              'UPDATE_IMPOSSIBLE': {
                'create_reverse': 'CREATE_REVERSE'
              },
              'UPDATE_POSSIBLE': {
                'resent_address': 'RESENT_ADDRESS',
                'refund_order': 'REFUND_ORDER'
              },
            }
          },
          'CONTACT_CARRIER_DIFFERENT_DEPARTMENT': {
            'STEP_2': {
              'STOP_DELIVERY': 'STOP_DELIVERY',
              'DEFAULT': {
                'SOLUTION': {
                  'resent_address': 'RESENT_ADDRESS',
                  'refund_order': 'REFUND_ORDER'
                }
              }
            }
          }
        }
      },
      'CLASSIC_TRACKING': {
        'STEP_1': {
          'IMPATIENT_CUSTOMER': {
            'STEP_2': {
              'DEFAULT': {
                'SOLUTION': {
                  'resend_tracking': 'RESEND_TRACKING'
                }
              }
            }
          },
          'DELIVERY_SEVERAL_DATES': {
            'STEP_2': {
              'DEFAULT': {
                'SOLUTION': {
                  'resend_tracking': 'RESEND_TRACKING'
                }
              }
            }
          },
          'PREORDER': {
            'STEP_2': {
              'WEBSITE_MISTAKE': {
                'send_delivery_date': 'SEND_DELIVERY_DATE'
              },
              'PDM_MISTAKE': {
                'send_delivery_date': 'SEND_DELIVERY_DATE'
              },
              'INATTENTIVE_CUSTOMER': {
                'send_delivery_date': 'SEND_DELIVERY_DATE'
              },
            }
          },
          'TRACKING_ERROR': {
            'STEP_2': {
              'DISPLAY_ERROR': {
                'resend_tracking': 'RESEND_TRACKING'
              },
              'TRACKING': {
                'resend_tracking': 'RESEND_TRACKING'
              }
            }
          },
          'DELAYED_SHIPPING': {
            'STEP_2': {
              'DEFAULT': {
                'SOLUTION': {
                  'refund_shipping_fee': 'REFUND_SHIPPING_FEE',
                  'commercial_gesture': 'COMMERCIAL_GESTURE'
                }
              }
            }
          },
        }
      },
      'CARRIER_LITIGATION': {
        'STEP_1': {
          'LOST': {
            'STEP_2': {
              'PACKAGE_ACCEPTED': {
                'create_litigation': 'CREATE_LITIGATION',
              },
              'PACKAGE_REFUSED': {
                'create_litigation': 'CREATE_LITIGATION',
                'create_reverse': 'CREATE_REVERSE'
              },
              'PACKAGE_DAMAGED_BEFORE_DELIVERY': {
                'create_litigation': 'CREATE_LITIGATION',
                'create_reverse': 'CREATE_REVERSE'
              },
              'PACKAGE_DAMAGED_DURING_RETURN': {
                'create_litigation': 'CREATE_LITIGATION',
              },
              'SERVICE_NOT_PROVIDED': {
                'create_litigation': 'CREATE_LITIGATION',
              }
            }
          },
          'MISSED_APPOINTMENT_2': {
            'STEP_2': {
              'DEFAULT': {
                'SOLUTION': {
                  'ask_commercial_gesture': 'ASK_COMMERCIAL_GESTURE'
                }
              }
            }
          },
          'LOST_PACKAGE': {
            'STEP_2': {
              'DEFAULT': {
                'SOLUTION': {
                  'create_litigation': 'CREATE_LITIGATION',
                }
              }
            }
          },
          'DELIVERY_DISPUTE': {
            'STEP_2': {
              'DEFAULT': {
                'SOLUTION': {
                  'create_litigation': 'CREATE_LITIGATION',
                }
              }
            }
          },
        }
      },
      'CARRIER_SERVICE': {
        'STEP_1': {
          'MISSED_APPOINTMENT_1': {
            'STEP_2': {
              'DEFAULT': {
                'SOLUTION': {
                  'refund_shipping_fee': 'REFUND_SHIPPING_FEE',
                  'commercial_gesture': 'COMMERCIAL_GESTURE',
                  'create_reverse': 'CREATE_REVERSE'
                }
              }
            }
          },
          'DELAYED_CARRIER': {
            'STEP_2': {
              'PACKAGE_RETURNED_ERROR': {
                'STEP_3': {
                  'ADDRESS_PROBLEM': {
                    'create_reverse': 'CREATE_REVERSE',
                  },
                  'CUSTOMER_UNAVAILABLE': {
                    'create_reverse': 'CREATE_REVERSE',
                  },
                  'DOUBLE_LABELLING': {
                    'create_reverse': 'CREATE_REVERSE',
                  }
                }
              },
              'DELIVERYMAN_PROBLEM': {
                'refund_shipping_fee': 'REFUND_SHIPPING_FEE',
                'commercial_gesture': 'COMMERCIAL_GESTURE',
                'refund_order': 'REFUND_ORDER'
              }
            }
          }
        }
      },
      'WAREHOUSE': {
        'STEP_1': {
          'PREPARATION_MISTAKE': {
            'STEP_2': {
              'DEFAULT': {
                'SOLUTION': {
                  'create_reverse': 'CREATE_REVERSE',
                  'resend_order': 'RESEND_ORDER'
                }
              }
            }
          },
          'PACKAGE_NOT_BEEPED': {
            'STEP_2': {
              'DEFAULT': {
                'SOLUTION': {
                  'resend_order': 'RESEND_ORDER',
                  'refund_order': 'REFUND_ORDER'
                }
              }
            }
          }
        },
      }
    }
  }
];

export const STEP1 = 'STEP_1';
export const STEP2 = 'STEP_2';
export const STEP3 = 'STEP_3';
export const SOLUTION = 'SOLUTION';
export const DEFAULT = 'DEFAULT';
