/** @ngInject */
export default function addressingRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'walisoft',
    sidebarSubNav: 'addressing',
    title: '',
    reloadChangeCountry: 'addressing.list',
  };

  $stateProvider
    .state('addressing', {
      url: '/addressing',
      parent: 'walisoft',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.ADDRESSING',
        stateName: 'addressing.list',
      },
      data: Object.assign(commonStateData, {
        showCountries: false,
      }),
    })
    .state('addressing.list', {
      url: `?
        page
        &byPage
        &addressingBay
        &addressingLane
        &addressingLocation
        &addressingLevel
        &addressingType
        &masterProductsWarehouse.warehouse.code
        &exists_barcode
        &sort_addressingBay
        &sort_addressingLane
        &sort_addressingLocation
        &sort_addressingLevel
        &sort_addressingType
        &filter_type
      `,
      views: {
        'page@layout': {
          template: '<app-addressing-list></app-addressing-list>',
        },
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.ADDRESSING.LIST.TITLE',
      }),
    });
}
