import { Component, Inject, Input, OnChanges, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MarketplaceHelper } from '@helpers';
import { AuthService, FormNotifierService } from '@services';
import { AbstractFormComponent } from '@components/generic/Form/abstract-form.component';
import { IFormViolation } from '@interfaces';
import { TabModel } from '@components/tab/models';
import { CMSResource } from '@components/cms/cms.resource';
import { ICMS } from '@components/cms/models/cms.interface';
import { IButtonLinks } from '@components/generic/buttons/button-links.interface';
import { WEBSITE_URLS } from '@constants/websiteUrls.constants';
import { ICommercialOperations } from '@components/commercial-operations/models/commercial-operations.interface';
import { CommercialOperationsResource } from '@components/commercial-operations/resources';
import { RoomResource } from '@resources/room.resource';
import { CategoryResource } from '@resources';
import { HydraHelper } from '@helpers/HydraHelper';

@Component({
  selector: 'app-tab-additional-form',
  template: require('./tab-additional-form.component.html'),
  styles: [require('./tab-additional-form.component.scss')]
})
export class TabAdditionalFormComponent extends AbstractFormComponent implements OnInit, OnChanges {

  public form: FormGroup;
  public specificUrl: string = this.translate('PAGE.TAB.FORM.SPECIFIC_URL');
  public cms: string = this.translate('PAGE.TAB.FORM.CMS');
  public commercialOperations: string = this.translate('PAGE.TAB.FORM.COMMERCIAL_OPERATIONS');
  public room: string = this.translate('PAGE.TAB.FORM.ROOM');
  public collapse: string = this.translate('PAGE.TAB.FORM.COLLAPSE');
  public category: string = this.translate('PAGE.TAB.FORM.CATEGORY');
  public property: string = '';
  public title: string = '';
  public cmsList: any = null;
  public buttonLinks: IButtonLinks[];
  public commercialOperationsList: any = null;
  public rooms: any = {};
  public categories: any = {};

  @Input() public formType: string;
  @Input() public violations: IFormViolation[]|any = [];
  @Input() public model: TabModel;
  @Input() public inCreation: boolean;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    public formNotifier: FormNotifierService,
    private cmsResource: CMSResource,
    private commercialOperationsResource: CommercialOperationsResource,
    private roomResource: RoomResource,
    private categoryResource: CategoryResource,
  ) {
    super($translate, authService, null, state);
  }

  ngOnInit(): void {
    this.form.statusChanges.subscribe(() => {
      if (this.form.dirty) {
        this.formNotifier.notifyFormInEdition();
      }
    });
  }

  ngOnChanges(): void {
    this.setAdditionalForm();
  }

  public getCMSList(): void {
    this.cmsResource.cGet({pagination: false}, {dontUseModel: true, isHydra: true, returnHydraMembers: true}).takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        this.cmsList = response.map((item: any) => {
          if (!item.translations[this.currentLocale]) {
            return;
          }

          return {
            name: item.translations[this.currentLocale].name,
            id: item['@id']
          };
        }).filter((item: any) => {
          return undefined !== item;
        });
        if (!this.inCreation) {
          this.setButtonCMSLinks(response);
        }
      });
  }

  public getCommercialOperationsList() {
    this.commercialOperationsResource.getMany({'marketplace[]': MarketplaceHelper.getWebsiteMarketplace().code, 'pagination': false})
      .takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        this.commercialOperationsList = response.map((item: any) => {
          return {
            name: item.translations[this.currentLocale]
              ? item.translations[this.currentLocale].title
              : this.translate('PAGE.TAB.COMMERCIAL_OPERATIONS.NO_TITLE'),
            id: item['@id']
          };
        });
        if (!this.inCreation) {
          this.setButtonCommercialOperationsLinks(response);
        }
      });
  }

  public getRoomList() {
    this.roomResource.cGet({ 'marketplace.code': MarketplaceHelper.getWebsiteMarketplace().code }, { returnHydraMembers: true })
      .takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        this.rooms = response.map((item: any) => {
          return { id: item['@id'], name: item.translations[this.currentLocale].name };
        });
      })
    ;
  }

  public getCategoryList() {
    this.categoryResource.cGet({ 'marketplace': MarketplaceHelper.getWebsiteMarketplace().code }, { returnHydraMembers: true })
      .takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        this.categories = response.map((item: any) => {
          return {
            id: HydraHelper.buildIri(item, 'categories'),
            name: item.translations[this.currentLocale] ? item.translations[this.currentLocale].name : 'Missing translation'
          };
        });
      })
    ;
  }

  public setAdditionalForm() {
    this.buttonLinks = [];
    this.form = new FormGroup({});

    switch (this.formType) {
      case this.specificUrl:
        this.form.removeControl('contentBlock');
        this.form.removeControl('commercialOperations');
        this.form.removeControl('room');
        this.form.removeControl('category');
        break;
      case this.cms:
        this.form.addControl('contentBlock', new FormControl(this.model.contentBlock, Validators.required));
        this.form.removeControl('commercialOperations');
        this.form.removeControl('room');
        this.form.removeControl('category');
        this.property = 'contentBlock';
        this.title = this.translate('PAGE.TAB.CMS.TITLE');
        this.getCMSList();
        break;
      case this.commercialOperations:
        this.form.addControl('commercialOperation', new FormControl(this.model.commercialOperation, Validators.required));
        this.form.removeControl('cms');
        this.form.removeControl('room');
        this.form.removeControl('category');
        this.property = 'commercialOperation';
        this.title = this.translate('PAGE.TAB.COMMERCIAL_OPERATIONS.TITLE');
        this.getCommercialOperationsList();
        break;
      case this.room:
        this.form.addControl('room', new FormControl(this.model.room, Validators.required));
        this.form.removeControl('cms');
        this.form.removeControl('commercialOperations');
        this.form.removeControl('category');
        this.property = 'room';
        this.title = this.translate('PAGE.TAB.ROOM.TITLE');
        this.getRoomList();
        break;
      case this.category:
        this.form.addControl('category', new FormControl(this.model.category, Validators.required));
        this.form.removeControl('cms');
        this.form.removeControl('commercialOperations');
        this.property = 'category';
        this.title = this.translate('PAGE.TAB.CATEGORY.TITLE');
        this.getCategoryList();
        break;
    }
  }

  private setButtonCMSLinks(cmsList: any): void {
    const cmsPage = cmsList.find((item: ICMS) => item['@id'] === this.model.contentBlock);
    this.buttonLinks.push({
      url: `#!/cms/${cmsPage.id}/edit`,
      label: this.translate('PAGE.TAB.BUTTON_LINK.INTERNAL_PAGE')
    });
    this.buttonLinks.push({
      url: cmsPage.translations[this.currentLocale]
        ? `${WEBSITE_URLS[this.currentLocale]}/cms/${cmsPage.translations[this.currentLocale].slug}`
        : '',
      label: this.translate('PAGE.TAB.BUTTON_LINK.WEBSITE_URL')
    });
  }

  private setButtonCommercialOperationsLinks(commercialOperationsList: ICommercialOperations[]): void {
    const commercialOperationsPage = commercialOperationsList
      .find((item: ICommercialOperations) => item['@id'] === this.model.commercialOperation);
    this.buttonLinks.push({
      url: `#!/commercial-operations/${commercialOperationsPage.id}`,
      label: this.translate('PAGE.TAB.BUTTON_LINK.INTERNAL_PAGE')
    });

    commercialOperationsPage.buttonLinks.map(link => {
      this.buttonLinks.push({
        url: link.url,
        label: this.translate('BUTTON.GO_LINK', {params: link.translationParams})
      });
    });
  }
}
