import { Injectable } from '@angular/core';
import { AbstractResource } from '../../../resources/abstract.resource';
import { Observable } from 'rxjs/Observable';
import { CarrierGroupAgencyModel } from '@components/carrier/models/carrier-group-agency.model';
import { Resource } from '@decorators/Resource';
import { HttpClient } from '@angular/common/http';
import { BASE_URL_API } from '@constants/config.constants';

@Injectable()
@Resource({
  entryPoint: '/v2/carrier_group_agencies',
  model: CarrierGroupAgencyModel,
  translationKey: 'CARRIER_AGENCY',
  isHydra: true,
  listColumns: [
    { field: 'warehouseCode', translationKey: 'WAREHOUSE_CODE' },
    { field: 'agencyCode', translationKey: 'AGENCY_CODE' },
    { field: 'countryCode', translationKey: 'COUNTRY_CODE' },
    { field: 'deliverable', translationKey: 'DELIVERABLE', translateValueFrom: 'BOOLEANS' },
  ],
  createAvailable: false,
  updateAvailable: false,
  deleteAvailable: false,
})
export class CarrierGroupAgencyResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  public export(carrierCode: string): Observable<void> {
    return this.exportFile({}, {
      entryPoint: `${this.entryPoint}/export?carrier_code=${carrierCode}`,
      responseType: 'blob',
    });
  }

  public getImportEntryPoint(): string {
    return `${BASE_URL_API}${this.entryPoint}/import`;
  }
}
