'use strict'

angular
  .module 'ammsFrontendApp'
  .factory '$menu', [
    '$rootScope'
    'sessionStorage'
    ($rootScope, sessionStorage) ->
      new class Menu
        constructor: ->
          $rootScope.$menu = this

        changeStatus: ->
          if not sessionStorage.get('MENU_IS_OPEN')?
            sessionStorage.set 'MENU_IS_OPEN', true
          else
            sessionStorage.set 'MENU_IS_OPEN', (sessionStorage.get('MENU_IS_OPEN') is 'false')

        isOpen: ->
          if not sessionStorage.get('MENU_IS_OPEN')?
            return false

          (sessionStorage.get('MENU_IS_OPEN') is 'true')
]
