import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';
import { FormNotifierService } from '@services/form-notifier.service';
import { FORM_STREAMS } from '../../../../enums/form-notifier-streams.enum';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { AbstractComponent } from '@components/generic/abstract.component';
import { FormService } from '@services/form.service';
import { IBtnBarOptions } from '@components/generic/Form';
import { SnackbarService } from '@components/snackbar';

interface ICancelActionOptions {
  useDialog?: boolean;
}

@Component({
  selector: 'app-form',
  template: require('./form.component.html'),
  providers: [FormService],
})
export class FormComponent extends AbstractComponent implements OnInit, OnDestroy {

  @Input() public form: FormGroup;
  @Input() public useAnchoredBar: boolean = true;
  @Input() public dontUseButtonBar: boolean = false;
  @Input() public readonly: boolean = false;
  @Input() public btnBarOptions: IBtnBarOptions = {
    enableCancelButton: true,
    enableSaveAndListButton: true,
    enableDeleteButton: false,
  };
  @Input() private cancelActionOptions?: ICancelActionOptions = {
    useDialog: true,
  };

  @Output() public onSubmit: EventEmitter<any> = new EventEmitter();
  @Output() public onDelete: EventEmitter<any> = new EventEmitter();
  @Output() public onCancel: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    @Inject('DialogService') private dialog: any,
    private formNotifier: FormNotifierService,
    private formService: FormService,
    private snackBar: SnackbarService,
  ) {
    super($translate, authService, resource, state);
  }

  /**
   * @inheritDoc
   */
  ngOnInit(): void {
    this.listenFormEvents();
  }

  public submit(event?: any): void {
    this.formService.formIsSubmitted = true;

    if (this.form.invalid) {
      this.formNotifier.notifyFormHasErrors({
        event: FORM_STREAMS.formUiHasErrors,
        form: this.form,
      });

      this.snackBar.warn('ALERTS.ERROR.FORM');

      return;
    }

    this.onSubmit.emit(event);
  }

  public delete(): void {
    this.onDelete.emit();
  }

  public cancel(): void {
    if (this.cancelActionOptions.useDialog) {
      this.dialog.confirm(this.translate('DIALOG.TEXT.BACK_TO_LIST'))
        .then(() => this.state.go(`${ this.resource.routeName }.list`))
      ;
    }

    this.onCancel.emit();
  }

  ngOnDestroy(): void {
    this.formNotifier.notifyFormIsDestroyed();
  }

  private listenFormEvents(): void {
    this.form.statusChanges.subscribe(() => {
      if (this.form.dirty) {
        this.formNotifier.notifyFormInEdition();
      }
    });
  }
}
