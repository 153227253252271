import { Component, Inject, Input, OnInit } from '@angular/core';
import { AuthService } from '../../../../services/auth.service';
import { AbstractResource } from '../../../../resources/abstract.resource';
import { ProductResource } from '../../../../components/product/product.resource';
import {
  IFormGeneralBody,
  IFormGeneralValue,
  IProductForm,
} from '@components/product/interfaces/product-form.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { SuperProductResource } from '../../../../components/super-product/super-product.resource';
import { WarrantyResource } from '../../../../components/warranty/resources/warranty.resource';
import { SessionHelper } from '../../../../helpers/session.helper';
import { ManufacturerResource } from '../../../../resources/manufacturer.resource';
import { CountryHelper } from '../../../../helpers/CountryHelper';
import { CODE_FR, CODE_GB } from '@constants/country';
import { SnackbarService } from '../../../../components/snackbar/snackbar.service';
import { FormNotifierService } from '@services/form-notifier.service';
import { IButtonLinks } from '@components/generic/buttons/button-links.interface';
import { AbstractComponent } from '@components/generic/abstract.component';
import { MasterProductResource } from '@resources/master-product.resource';
import { HydraHelper } from '@helpers/HydraHelper';
import { buildVintageDates } from '@helpers/VintageHelper';
import { WarehousesResource } from '../../../../components/warehouses/warehouses.resource';
import { ICountry } from '@interfaces';
import { IWarehouses } from '@components/warehouses/models';
import { AddressingResource } from '@resources/addressing.resource';
import { IAddressing } from '@models/addressing.interface';
import { INPUT_NUMBER_PATTERN_NODEC } from '@constants';
import { NumberHelper } from '@helpers/NumberHelper';
import { SuperProductCategoriesResource } from '@components/super-product-categories/super-product-categories.resource';
/**
 * Handle ProductGeneralForm.
 */
@Component({
  selector: 'app-product-general-form',
  template: require('./product-general-form.component.html'),
  providers: [
    { provide: AbstractResource, useClass: ProductResource },
    SuperProductCategoriesResource,
  ],
  styles: [require('./product-general-form.component.scss')],
})
export class ProductGeneralFormComponent extends AbstractComponent implements OnInit {

  public objectKeys: (o: {}) => string[] = Object.keys;

  public form: FormGroup;
  public addressingForm: FormGroup;
  public initialStorageForm: FormGroup;
  public addressingStorageForm: FormGroup[] = [];

  public superProductsSkus$: Observable<object>;
  public warranties$: Observable<object>;
  public manufacturers$: Observable<object>;
  public displayWarranty: boolean;
  public pathSuperProductList: string;
  public buttonLinks: IButtonLinks[];
  public warehouses: any[] = [];
  public isSparePartMode: boolean = false;
  public vintageSkus: any[] = [];
  public vintages: any[] = [];
  public vintagePossibilitiesDistinctSkus: any = [];
  public vintagePossibilitiesFilterBySku: any[];
  public newVintage: any;
  public newVintageList: any;
  public addressingPickingId: string;
  public masterProductWarehouseId: string;
  public categories: any[] = [];
  public vintageType: {
    value: string,
    label: string
  };
  public availableVintageDates: any[];
  public showSkus: boolean = false;
  public showVintage: boolean = false;

  @Input() model: IProductForm;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    private superProductResource: SuperProductResource,
    private warrantyResource: WarrantyResource,
    private manufacturerResource: ManufacturerResource,
    private masterProductResource: MasterProductResource,
    private superProductCategoriesResource: SuperProductCategoriesResource,
    private addressingResource: AddressingResource,
    private warehouseResource: WarehousesResource,
    private snackbar: SnackbarService,
    private formNotifier: FormNotifierService,
    @Inject('DialogService') private dialog: any,
  ) {
    super($translate, authService, resource, state);

    if (this.state.current.name === 'spare-part.edit') {
      this.isSparePartMode = true;
    }
  }


  /**
   * @inheritDoc
   */
  ngOnInit(): void {
    this.displayWarranty = true;
    this.pathSuperProductList = this.state.href('super-product.list', {}, { absolute: true });
    this.fetch();
    this.buildForm();
    this.fillForm();

    if (this.isSparePartMode) {
      this.buildAddressingForm();
      this.getAddressing();
      this.fetchVintages();
      this.buildVintageDates();
    }

    this.fetchCategories();

  }

  private fetchCategories(): void {
    if (typeof this.model.superProduct.id === 'undefined') {
      return;
    }
    this.categories = [];
    this.superProductCategoriesResource.getCategories(this.model.superProduct.id)
      .subscribe((res: any) => {
        res.forEach((e: any) => {
          if (e.category.translations[SessionHelper.getLocale()] != null) {
            this.categories.push(e.category.translations[SessionHelper.getLocale()]['name']);
          }
        });
      });
  }
  private fetch() {
    const options: any = {
      isHydra: true,
      returnHydraMembers: true,
      dontUseModel: true,
      blocking: false
    };

    this.warehouseResource.cGet(
      {
        'acceptSparePart': true,
        'carriers.country.code[]': SessionHelper.getCountries().map((country: ICountry) => country.code),
        'active': true,
        'pagination': false,
      },
      options
    )
      .takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        this.warehouses = response.map((warehouse: IWarehouses): any => {
          return {
            iri: warehouse['@id'],
            code: warehouse.code,
            name: warehouse.name,
          };
        });
      })
      ;
  }

  private buildVintageDates() {
    this.availableVintageDates = buildVintageDates();
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      sku: [''],
      ean: [''],
      superProduct: [{ label: '', id: undefined }],
      manufacturerOverrideContent: [false],
      manufacturers: [{ label: '', id: undefined }],
      afterSalesService: [''],
      notifyReviewerOverrideContent: [false],
      notifyReviewers: [false],
      hidden: [false],
      superProductAgTitle: [''],
      warehouse: null,
    });

    this.masterProductResource.getAddressing(this.model.masterProduct.id).subscribe((response: any) => {
      const sav = response.masterProductsWarehouses
        .filter((masterProductWarehouse: { warehouse: { code: string; }; }) => masterProductWarehouse.warehouse.code === 'sav');

      if (sav[0]) {
        this.masterProductWarehouseId = sav[0]['@id'].split('/').pop();
        sav[0].addressings.forEach((addressing: IAddressing, index: number) => {
          if (addressing.addressingType === 'storage') {
            this.addressingStorageForm.push(this.getForm());
            this.fillFormStorage(addressing, index - 1);
          }
        });
      }
    });

    this.initialStorageForm = this.getForm();
    this.superProductsSkus$ = this.superProductResource.getSkus({ locale: this.currentLocale }, { blocking: false });
    this.manufacturers$ = this.manufacturerResource.getMany(undefined, { blocking: false });

    if (this.displayWarranty) {
      this.form.addControl('warrantyOverrideContent', this.formBuilder.control(['']));
      this.form.addControl('warranty', this.formBuilder.control(['']));

      this.warranties$ = this.warrantyResource.getMany({ locale: SessionHelper.getUILanguage() }, { blocking: false });
    }
  }

  private getForm(): FormGroup {
    return this.formBuilder.group({
      id: [''],
      addressingBay: [''],
      addressingLane: [''],
      addressingLocation: [''],
      addressingLevel: [''],
      quantity: ['', [Validators.pattern(INPUT_NUMBER_PATTERN_NODEC)]],
      barcodeImage: [null],
    });
  }
  private fillFormStorage(addressing: IAddressing, index?: number): void {
    // @ts-ignore
    addressing.id = addressing['@id'] ? addressing['@id'].split('/').pop() : null;
    addressing.addressingBay = addressing.addressingBay ? addressing.addressingBay : null;
    addressing.addressingLane = addressing.addressingLane ? addressing.addressingLane : null;
    addressing.addressingLocation = addressing.addressingLocation ? addressing.addressingLocation : null;
    addressing.addressingLevel = addressing.addressingLevel ? addressing.addressingLevel : null;
    addressing.quantity = addressing.quantity ? NumberHelper.parseFloat(addressing.quantity) : 0;
    addressing.barcodeImage = addressing.barcode ? (addressing.barcode.imageWebpath || null) : null;
    this.addressingStorageForm[index].patchValue(addressing);
  }
  private buildAddressingForm() {
    this.addressingForm = this.formBuilder.group({
      id: [''],
      addressingBay: [''],
      addressingLane: [''],
      addressingLocation: [''],
      addressingLevel: [''],
      quantity: ['', [Validators.pattern(INPUT_NUMBER_PATTERN_NODEC)]],
      barcodeImage: [null],
    });
  }

  private fillForm(): void {
    if (this.model) {
      this.form.patchValue(this.model);
    }
  }

  public submitEdit(event?: any): void {
    this.dialog.confirm(this.translate('PAGE.PRODUCT.CONFIRM.UPDATE'))
      .then(() => {
        if (this.isSparePartMode) {
          if (this.addressingPickingId) {
            this.putAddressing(this.addressingForm);
          } else if (!this.isAddressingEmpty(this.addressingForm.value)) {
            this.createAddressing(this.addressingForm, 'picking');
          }

          this.addressingStorageForm.forEach((form: any) => {
            this.putAddressing(form);
          });

          if (!this.isAddressingEmpty(this.initialStorageForm.value)) {
            this.createAddressing(this.initialStorageForm, 'storage');
          }
        }
        if (!this.isSparePartMode) {
            if (this.form.value.sku === this.form.value.superProduct.skuParent) {
              this.snackbar.warn(this.translate('PAGE.SUPER_PRODUCT.ALERTS.SAME_SKU'));
            }
        }

        const body: IFormGeneralBody = this.prepareBody();
        const id: string = this.state.params.id;

        this.resource.update(id, body, { entryPoint: `/v2/products/${id}` })
          .takeUntil(this.destroyed$)
          .subscribe(() => {

            if (this.isSparePartMode) {
              this.resource.putVintagesFromMasterProductId(this.model.id, this.vintages)
                .takeUntil(this.destroyed$)
                .subscribe();
            }

            this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));

            if (event && event.redirect) {
              if (this.state.current.name === 'spare-part.edit.content') {
                this.state.go('spare-part.list');
              } else {
                this.state.go(`${this.resource.routeName}.list`);
              }

              return;
            }

            this.formNotifier.notifyFormSubmitted();

            if (this.state.current.name === 'spare-part.edit') {
              this.state.go('spare-part.edit', { id });
            } else {
              this.state.go(`${this.resource.routeName}.edit`, { id });
            }
          })
          ;
      })
      ;
  }

  public submitNew(event?: any): void {
    this.dialog.confirm(this.translate('PAGE.PRODUCT.CONFIRM.UPDATE')).then(() => {
      const detached = this.state.current.name === 'spare-part.new';

      if (!detached) {
        this.superProductsSkus$.subscribe((x: any[]) => {
          const obj = x.find(o => o.id === String(this.form.value.superProduct.id));
          if (obj) {
            this.snackbar.warn('SAME SKU');
          }
        });
      }

      const body = {
        sku: this.form.value.sku,
        superProductAgTitle: this.form.value.superProductAgTitle,
        country: HydraHelper.buildIri(SessionHelper.getCountry().id, 'countries'),
        detached: detached,
        defaultWarehouse: this.form.value.warehouse,
      };

      this.resource.create(body, { entryPoint: '/v2/products' }).takeUntil(this.destroyed$).subscribe((response: any) => {
        this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
        this.formNotifier.notifyFormSubmitted();

        if (detached) {
          this.state.go('spare-part.edit', { id: response.id });
        } else {
          this.state.go(`${this.resource.routeName}.edit`, { id: response.id });
        }
      });
    });
  }

  private prepareBody(): IFormGeneralBody {
    const formValue: IFormGeneralValue = this.form.value;

    return {
      hidden: formValue.hidden,
      notifyReviewerOverrideContent: formValue.notifyReviewerOverrideContent,
      notifyReviewers: formValue.notifyReviewers,
      manufacturerOverrideContent: formValue.manufacturerOverrideContent,
      manufacturers: formValue.manufacturers ? formValue.manufacturers.map((e: any) => HydraHelper.buildIri(e.id, 'manufacturers')) : null,
      afterSalesService: formValue.afterSalesService || null,
      warrantyOverrideContent: formValue.warrantyOverrideContent,
      warranty: formValue.warranty ? `/api/v2/warranties/${formValue.warranty.id}` : null,
      superProduct: formValue.superProduct ? `/api/v2/super_products/${formValue.superProduct.id}` : null,
    };
  }

  public cancelForm(): void {
    this.dialog.confirm(this.translate('DIALOG.TEXT.DONT_SAVE'))
      .then(() => {
        if (this.state.current.name === 'spare-part.edit.cross-up-sells') {
          this.state.go('spare-part.list');
        } else {
          this.state.go('product_new.list');
        }
      })
      ;
  }

  public fetchVintages() {
    this.resource.getVintagesFromMasterProductId(this.model.masterProduct.id).takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        for (const vintage of response.vintages) {
          this.vintages.push(vintage);
        }

        this.vintages.sort(function (a, b) {
          return a.vintage - b.vintage;
        });

        this.vintages.sort(function (a, b) {
          if (a.sku < b.sku) {
            return -1;
          }
          if (a.sku > b.sku) {
            return 1;
          }
          return 0;
        });

        this.vintageSkus = this.vintages.map(item => item.sku)
          .filter((value, index, self) => self.indexOf(value) === index);

      });
  }

  public onFilterSku(event: string) {
    if (this.vintageType.value === 'sku') {
      this.resource.getVintagePossibilites(event).takeUntil(this.destroyed$)
        .subscribe((response: any) => {
          this.vintagePossibilitiesDistinctSkus = [];

          for (const iterator of Object.keys(response)) {
            this.vintagePossibilitiesDistinctSkus.push({
              'sku': iterator
            });
          }
        }
        );
    } else if (this.vintageType.value === 'parent') {
      this.superProductResource.getSkus({ locale: 'fr_FR' }).subscribe((response: any) => {
        this.vintagePossibilitiesDistinctSkus = [];
        this.vintagePossibilitiesDistinctSkus = response.filter((obj: any) => {
          return obj.label.includes(event);
        }).map((obj: any) => {
          return {
            sku: obj.skuParent
          };
        });
      });
    }
  }

  public onSelectSku(event: any) {
    this.vintagePossibilitiesFilterBySku = [];
    this.newVintageList = event;
    this.showVintage = true;
  }

  public onSelectVintage(event: any) {
    this.newVintage = {
      sku: this.newVintageList.sku,
      vintage: event,
      type: this.vintageType.value === 'sku' ? 'vintage_master_product' : 'vintage_super_product'
    };
  }

  public changeVintageType() {
    this.showSkus = true;
    this.showVintage = false;
  }

  public addVintage() {
    if (!this.vintages.find(e => e.sku === this.newVintage.sku && e.vintage === this.newVintage.vintage)) {
      this.vintages.push(this.newVintage);

      this.vintages.sort(function (a, b) {
        return a.vintage - b.vintage;
      });

      this.vintages.sort(function (a, b) {
        if (a.sku < b.sku) {
          return -1;
        }
        if (a.sku > b.sku) {
          return 1;
        }
        return 0;
      });
    }

    this.showVintage = false;
  }

  public onDeleteVintage(index: number) {
    this.vintages.splice(index, 1);
  }

  public getAddressing() {
    this.masterProductResource.getAddressing(this.model.masterProduct.id).subscribe((response: any) => {
      const sav = response.masterProductsWarehouses
        .filter((masterProductWarehouse: { warehouse: { code: string; }; }) => masterProductWarehouse.warehouse.code === 'sav');

      if (sav[0]) {
        this.masterProductWarehouseId = sav[0]['@id'].split('/').pop();
        sav[0].addressings.forEach((addressing: IAddressing) => {
          if (addressing.addressingType === 'picking') {
            // @ts-ignore
            this.addressingPickingId = addressing['@id'].split('/').pop();
            this.addressingForm.controls['id'].setValue(this.addressingPickingId);
            this.addressingForm.controls['addressingBay'].setValue(addressing.addressingBay);
            this.addressingForm.controls['addressingLevel'].setValue(addressing.addressingLevel);
            this.addressingForm.controls['addressingLane'].setValue(addressing.addressingLane);
            this.addressingForm.controls['addressingLocation'].setValue(addressing.addressingLocation);
            this.addressingForm.controls['quantity'].setValue(addressing.quantity);
            this.addressingForm.controls['barcodeImage'].setValue(addressing.barcode ?  (addressing.barcode.imageWebpath || null) : null);
          }
        });
      }
    });
  }

  private isAddressingEmpty(addressing: any): boolean {
    return !(addressing.quantity || addressing.addressingType || addressing.addressingLevel || addressing.addressingLane || addressing.addressingBay);
  }

  private isAddressingValid(addressing: any): boolean {
    return addressing.quantity && (addressing.addressingType || addressing.addressingLevel || addressing.addressingLane || addressing.addressingBay);
  }

  public deleteAddressing(form: FormGroup) {
    this.dialog.confirm(this.translate('PAGE.PRODUCT.CONFIRM.DELETE')).then(() => {
      this.addressingResource.deleteAddressing(form.value.id, {})
        .takeUntil(this.destroyed$)
        .subscribe(() => {
          this.snackbar.validate(this.translate('ALERTS.FORM.DELETED'));
          this.addressingStorageForm = this.addressingStorageForm.filter((formStorage: FormGroup) => form.value.id !== formStorage.value.id);
        });
    });
  }
  public putAddressing(form: FormGroup) {
    form.value.quantity = +form.value.quantity;
    this.addressingResource.putAddressing(form.value.id, form.value, {}).subscribe(() => {
      this.snackbar.validate(this.translate('ALERTS.FORM.UPDATED'));
      this.formNotifier.notifyFormSubmitted();
    });
  }
  public createAddressing(form: FormGroup, type: string) {
    form.value.quantity = +form.value.quantity;
    const body = {
      ...form.value,
      masterProductsWarehouse: HydraHelper.buildIri(this.masterProductWarehouseId, 'master_products_warehouses'),
      addressingType: type,
    };

    delete body.id;

    this.addressingResource.postAddressing(body, {}).subscribe((result: any) => {
      this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
      if (type === 'storage') {
        this.initialStorageForm.reset();
        this.addressingStorageForm.push(this.getForm());
        this.masterProductResource.getAddressing(this.model.masterProduct.id).subscribe((response: any) => {
          const sav = response.masterProductsWarehouses
            .filter((masterProductWarehouse: { warehouse: { code: string; }; }) => masterProductWarehouse.warehouse.code === 'sav');

          if (sav[0]) {
            this.masterProductWarehouseId = sav[0]['@id'].split('/').pop();
            sav[0].addressings.forEach((addressing: IAddressing, index: number) => {
              if (addressing.addressingType === 'storage') {
                this.fillFormStorage(addressing, index - 1);
              }
            });
          }
          this.formNotifier.notifyFormSubmitted();
        });
      } else {
        this.addressingPickingId = result['@id'].split('/').pop();
        this.addressingForm.controls['id'].setValue(this.addressingPickingId);
      }
    });
  }
}
