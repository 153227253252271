import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { Component, Inject } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { SalesLogExportFiltersFormService } from '@components/sales-log-export/sales-log-export-filters-form.service';

@Component({
  selector: 'app-sales-log-export',
  template: require('./sales-log-export.component.html'),
  providers: [
    { provide: AbstractFiltersFieldsService, useClass: SalesLogExportFiltersFormService },
  ]
})
export class SalesLogExportComponent extends AbstractPageComponent {

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, null, state);
  }
}
