import { ICatalogs, ICatalogsTranslations } from '@components/catalogs/models/catalogs.interface';
import { SessionHelper } from '@helpers/session.helper';

export class CatalogsModel implements ICatalogs {
  id?: string;
  label?: string;
  active: boolean;
  activeLabel?: string;
  translations?: ICatalogsTranslations;

  constructor(catalog: ICatalogs = {
    id: '',
    label: '',
    active: false,
    activeLabel: '',
    translations: null,
  }) {
    this.id = catalog.id;
    this.label = catalog.label
      ? catalog.label
      : (catalog.translations && catalog.translations[SessionHelper.getLocale()]
        ? catalog.translations[SessionHelper.getLocale()].label
        : null);
    this.active = catalog.active;
    this.translations = catalog.translations;

    // TODO: correct this
    this.activeLabel = catalog.active
      ? 'PAGE.CATALOGS.LIST.YES'
      : 'PAGE.CATALOGS.LIST.NO'
    ;
  }
}
