import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { AbstractComponent } from '@components/generic/abstract.component';
import { ISuperProductList } from '@components/super-product/interfaces/super-product-list.interface';
import { IColumnSortedEvent } from '@components/generic/List/services/sort.service';

/**
 * Display the result of the super products.
 *
 * TODO:
 * - delete this component and use only generic list when endpoint from api will use API Platform.
 */
@Component({
  selector: 'app-super-product-result-list',
  template: require('./super-product-result-list.component.html'),
})
export class SuperProductResultListComponent extends AbstractComponent {

  private _items: ISuperProductList[];

  /**
   * Fired when user sort column
   */
  @Output() public onSortList: EventEmitter<any> = new EventEmitter();

  set items(items: any) {
    this._items = items.superProducts;
  }

  get items(): any {
    return this._items;
  }

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }

  /**
   * Emit event to avert parent that we want sorted data.
   */
  public sortResult(event: IColumnSortedEvent) {
    this.onSortList.emit(event);
  }

  public delete(deletedItem: any) {
    this.resource.partialUpdate(deletedItem.id, { deleted: true })
      .takeUntil(this.destroyed$)
      .subscribe(() => {
        this.items = {
          superProducts: this.items.filter((item: ISuperProductList) => item.id !== deletedItem.id),
        };
      })
    ;
  }
}
