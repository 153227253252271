import { BannerResource } from '@components/banner/banner.resource';

export default bannersRoutes;

/** @ngInject */
function bannersRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {};

  $stateProvider
    .state('banners', {
      url: '/banners',
      parent: 'walisoft',
      breadcrumb: {
        label: 'BREADCRUMB.BANNER',
        stateName: 'banners.list'
      },
      abstract: true
    })
    .state('banners.list', {
      url: '?translations.locale',
      views: {
        'page@layout': {
          template: '<app-banner-list></app-banner-list>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.BANNER.LIST.TITLE',
        reloadChangeCountry: 'banners.list',
      })
    })
    .state('banners.new', {
      url: '/new',
      breadcrumb: { label: 'BREADCRUMB.NEW' },
      views: {
        'page@layout': { template: '<app-banner-form></app-banner-form>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.BANNER.NEW.TITLE',
        reloadChangeCountry: 'banners.new',
      })
    })
    .state('banners.edit', {
      url: '/:id',
      breadcrumb: { label: 'BREADCRUMB.EDIT' },
      views: {
        'page@layout': { template: '<app-banner-form></app-banner-form>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.BANNER.EDIT.TITLE',
        reloadChangeCountry: 'banners.list',
      })
    })
  ;
}
