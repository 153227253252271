import { Component, Inject, Input, OnDestroy } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractComponent } from '@components/generic/abstract.component';
import { DialogCloseResult, DialogRef } from '@progress/kendo-angular-dialog';
import { DialogBoxService } from '@services/dialog-box.service';
import { FormNotifierService } from '@services/form-notifier.service';
import { FORM_STREAMS } from '../../../../enums/form-notifier-streams.enum';
import { IMarketplace } from '@interfaces';

/**
 * Handle tabs marketplaces.
 */
@Component({
  selector: 'app-product-marketplace-tabs',
  template: require('./product-marketplace-tabs.component.html'),
  styles: [require('./product-marketplace-tabs.component.scss')]
})
export class ProductMarketplaceTabsComponent extends AbstractComponent implements OnDestroy {

  private formInEdition: boolean = false;
  public expanded: boolean = false;

  @Input() marketplaces: IMarketplace[];
  @Input() sparePartContext: boolean = false;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private dialogBoxService: DialogBoxService,
    private formNotifier: FormNotifierService,
  ) {
    super($translate, authService, null, state);

    this.expanded = (this.sparePartContext ? 'spare-part.edit.marketplace' : 'product.edit.marketplace') === this.state.current.name;

    this.formNotifier.observable.takeUntil(this.destroyed$).subscribe((stream) => {
      if (FORM_STREAMS.formInEdition === stream) {
        this.formInEdition = true;
      }

      if (FORM_STREAMS.formIsDestroyed === stream || FORM_STREAMS.dataSubmitted === stream) {
        this.formInEdition = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.formInEdition = false;
  }

  public isActiveState(marketplaceCode: string): boolean {
    return this.state.params.marketplaceCode === marketplaceCode;
  }

  public switchState(event: Event, marketplaceCode: string): void {
    if (this.isActiveState(marketplaceCode)) {
      event.preventDefault();

      return;
    }

    if (!this.formInEdition) {
      this.handleState(marketplaceCode);

      return;
    }

    const dialog: DialogRef = this.dialogBoxService.alert({content: this.translate('DIALOG.ALERT.FORM.IN_EDITION')});
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult || this.translate('DIALOG.BUTTON.CANCEL') === result.text) {
        return;
      }

      this.handleState(marketplaceCode);
    });
  }

  private handleState(marketplaceCode: string): void {
    this.state.go(this.sparePartContext ? 'spare-part.edit.marketplace' : 'product.edit.marketplace', { marketplaceCode });
  }

  public toggleExpandables() {
    this.expanded = !this.expanded;
  }
}
