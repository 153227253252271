import { NgModule } from '@angular/core';
import { GenericFormModule } from '@components/generic';
import { GenericModule } from '@components/generic';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { EnhancedFieldsModule } from '@directives/enhanced-fields.module';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import {TabsModule, TimepickerModule} from 'ngx-bootstrap';
import {
  WebsiteHomepageHighlightsComponent,
  WebsiteHomepageBargainsComponent,
  WebsiteHomepageSliderComponent,
  WebsiteHomepageSliderItemsComponent,
  WebsiteHomepageSliderItemsResultListComponent,
  WebsiteHomepageSliderItemsListComponent,
  WebsiteHomepageAdvicesComponent,
  WebsiteHomepageSeoComponent,
  WebsiteHomepageHighlightsItemsListComponent,
  WebsiteHomepageHighlightsItemsComponent,
  WebsiteHomepageHighlightsItemsResultListComponent,
} from '@components/website-homepage';

@NgModule({
  entryComponents: [
    WebsiteHomepageHighlightsComponent,
    WebsiteHomepageBargainsComponent,
    WebsiteHomepageSliderComponent,
    WebsiteHomepageSliderItemsComponent,
    WebsiteHomepageSliderItemsResultListComponent,
    WebsiteHomepageSliderItemsListComponent,
    WebsiteHomepageHighlightsItemsComponent,
    WebsiteHomepageHighlightsItemsListComponent,
    WebsiteHomepageHighlightsItemsResultListComponent,
    WebsiteHomepageAdvicesComponent,
    WebsiteHomepageSeoComponent
  ],
  imports: [
    GenericModule,
    GenericFormModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    EnhancedFieldsModule,
    DropDownsModule,
    TabsModule,
    TimepickerModule,
  ],
  providers: [],
  declarations: [
    WebsiteHomepageHighlightsComponent,
    WebsiteHomepageBargainsComponent,
    WebsiteHomepageSliderComponent,
    WebsiteHomepageSliderItemsComponent,
    WebsiteHomepageSliderItemsResultListComponent,
    WebsiteHomepageSliderItemsListComponent,
    WebsiteHomepageHighlightsItemsComponent,
    WebsiteHomepageHighlightsItemsListComponent,
    WebsiteHomepageHighlightsItemsResultListComponent,
    WebsiteHomepageAdvicesComponent,
    WebsiteHomepageSeoComponent
  ],
})
export class WebsiteHomepageModule { }
