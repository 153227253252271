import { IndicatorInterface, IndicatorStateInterface } from '@interfaces';

export class IndicatorModel implements IndicatorInterface {
  public businessObject: string;
  public businessObjectId: number;
  public type: string;
  public id: number;
  public isAutomatic: boolean;
  public isLock: boolean;
  public status: 'green' | 'orange' | 'red';
  public historic: IndicatorStateInterface[];
  public extraData?: any;
  public updatedBy?: string;

  constructor(indicator: IndicatorInterface) {
    this.businessObject = indicator.businessObject;
    this.businessObjectId = indicator.businessObjectId;
    this.type = indicator.type;
    this.id = indicator.id;
    this.isAutomatic = indicator.isAutomatic;
    this.isLock = indicator.isLock;
    this.status = indicator.status;
    this.historic = indicator.historic;
    this.extraData = indicator.extraData;
    this.updatedBy = indicator.updatedBy;
  }
}
