import { Resource } from '@decorators/Resource';
import { Injectable } from '@angular/core';
import { AbstractResource } from '@resources/abstract.resource';
import { HttpClient } from '@angular/common/http';
import { StockMovementModel } from '@components/stock-movement/stock-movement.model';

@Injectable()
@Resource({
  entryPoint: '/v2/stock_movements',
  isHydra: true,
  listModel: StockMovementModel,
  cGetDefaultFilters: () => ({
    page: 1,
    byPage: 30,
    'order.orderType': 'shooting',
    'order[id]': 'DESC'
  }),
})
export class StockMovementResource extends AbstractResource {
  constructor(
    http: HttpClient,
  ) {
    super(http);
  }
}
