import { Component, Inject, Input } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource, BlockResource } from '@resources';
import { takeUntil } from 'rxjs/operators';
import { StringHelper } from '@helpers';
import { SnackbarService } from '@components/snackbar/snackbar.service';
import { BlockHelper } from '@components/generic/blocks/block-helper';
import { AbstractBlockOverrideFormComponent } from '@components/generic/blocks/abstract-block-override-form.component';

@Component({
  selector: 'app-block-list-form',
  template: require('./block-list-form.component.html'),
  styles: [require('./block.scss')],
  providers: [{ provide: AbstractResource, useClass: BlockResource }]
})
export class BlockListFormComponent extends AbstractBlockOverrideFormComponent {
  @Input() public businessObject: string;
  @Input() public blockListTitle: string;
  @Input() public blockTitleField: string;
  @Input() public blockTitleFields: string[] = [];
  @Input() public blockTitleKey: string;
  @Input() public properties: string[] = [];
  @Input() public locale: string;
  @Input() public maxItems?: number;
  @Input() public allowOverride: boolean = false;
  @Input() public isModule: boolean = false;
  @Input() public additionnalOptions: object = {};
  @Input() public position: number;
  @Input() public show: boolean = false;

  public blocks: any[] = [];
  public add: boolean = false;
  public shows: any = {};
  public edits: any = {};
  public lastDragObject: any;
  public lastDragObjectItemIndex: any;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    protected snackbar: SnackbarService,
    @Inject('DialogService') protected dialog: any,
  ) {
    super($translate, authService, resource, state, snackbar, dialog);
  }

  setOverrideField() {
    if (this.product) {
      this.overrideField = 'override' + StringHelper.snakeToPascal(this.businessObject.replace('product_', ''));
    } else if (this.category) {
      this.overrideField = 'override' + StringHelper.snakeToPascal(this.businessObject.replace('category_', ''));
    }
  }

  fetch(event?: any) {
    if (undefined !== this.product) {
      if (this.allowOverride) {
        this.fetchBlockConfig('/v2/products/' + this.product.id + '/product_block');
      } else {
        this.fetchBlocks({ 'product.id': this.product.id });
      }
    } else if (undefined !== this.category) {
      if (this.allowOverride) {
        this.fetchBlockConfig('/v2/categories/' + this.category.id + '/category_block');
      } else {
        this.fetchBlocks({'category.id': this.category.id});
      }
    } else if (undefined !== this.superProduct) {
      this.fetchBlocks({ 'superProduct.id': this.superProduct.id }, event);
    } else if (undefined !== this.commercialOperation) {
      this.fetchBlocks({ 'commercialOperation.id': this.commercialOperation.id }, event);
    } else if (undefined !== this.room) {
      this.fetchBlocks({ 'room.id': this.room.id }, event);
    } else if (this.isModule) {
      this.fetchBlocks({ 'exists[category]': false });
    }
  }

  fetchBlocks(filters: any, event?: any) {
    filters.locale = this.locale;

    this.resource.cGet(filters, { entryPoint: '/v2/' + StringHelper.pluralize(this.businessObject), isHydra: true, returnHydraMembers: true })
      .pipe(takeUntil(this.destroyed$))
      .subscribe((blocks: any) => {
        this.add = false;
        this.blocks = blocks;
        this.handleBlocks();

        if (event) {
          this.shows[event.id] = true;
        }
      })
    ;
  }

  handleBlocks(): void {
  }

  drop(event: any) {
    if (!this.config[this.overrideField] && !this.superProduct && !this.category) {
      return;
    }

    const items: any = event.target.closest('.block-list-container').querySelectorAll('.block-list-item');

    for (const item of items) {
      item.classList.remove('allow-drop');
    }

    const newPosition = +event.target.closest('.block-list-item').getAttribute('data-position');
    const formData = BlockHelper.getFormData(
      null,
      [],
      this.locale,
      this.businessObject,
      { id: this.lastDragObject.id },
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      { position: newPosition },
      this.isModule
    );

    (<BlockResource>this.resource).writeBlock(formData, this.businessObject)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.snackbar.validate('SAVED');
        this.fetch();
      })
    ;

    event.preventDefault();
  }

  drag(event: any, item: any, index: number) {
    if ('INPUT' === document.activeElement.tagName) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    if (!this.config[this.overrideField] && !this.superProduct && !this.category) {
      return;
    }

    this.lastDragObject = item;
    this.lastDragObjectItemIndex = index;
  }

  allowDrop(event: any) {
    event.preventDefault();
  }

  dragEnter(event: any) {
    if (!this.config[this.overrideField] && !this.superProduct) {
      return;
    }

    const items: any = event.target.closest('.block-list-container').querySelectorAll('.block-list-item');

    for (const item of items) {
      item.classList.remove('allow-drop');
    }

    event.target.closest('.block-list-item').classList.add('allow-drop');
  }
}
