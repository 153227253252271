import { Component, Inject, OnInit } from '@angular/core';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { AuthService } from '@services';
import { CommercialOperationsResource } from '@components/commercial-operations/resources';
import { SessionHelper } from '@helpers';

@Component({
  selector: 'app-commercial-operations-block-list',
  template: require('./commercial-operations-block-list.component.html'),
})

export class CommercialOperationsBlockListComponent extends AbstractPageComponent implements OnInit {
  public currentLocales: string[] = SessionHelper.getCountry().locales;
  public commercialOperation: any;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: CommercialOperationsResource,
    @Inject('StateService') state: ng.ui.IStateService
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit() {
    this.commercialOperation = { id: this.state.params.id };
  }

  public hasSeveralTranslations(): boolean {
    return this.currentLocales.length > 1;
  }
}
