import { AbstractResource } from '@resources';
import { Injectable } from '@angular/core';
import { AttributeModel } from '../components/attribute/models/attribute.model';
import { Resource } from '../decorators/Resource';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { Observable } from 'rxjs/Observable';

@Injectable()
@Resource({
  routeName: 'attribute',
  entryPoint: '/attributes',
  model: AttributeModel,
  listColumns: [
    {field: 'sortCode', translationKey: 'SORTCODE'},
  ],
  translationKey: 'ATTRIBUTE',
  importOptions: [
    {
      entryPoint: '/api/v2/import',
      importButton: 'PAGE.ATTRIBUTE.LIST.FILTER.IMPORT',
      businessObject: 'master-products-attribute-values'
    }
  ],
  exportOptions: [
    {
      entryPoint: `/v2/export`,
      responseType: 'text',
      type: 'text/csv',
      filename: `master-products-attribute-values_${moment().format()}.csv`,
      translationKey: 'PAGE.ATTRIBUTE.LIST.FILTER.EXPORT',
      filters: true,
      postOptions : {
        exportCode: 'attribute_set',
        formatCode: 'attribute_set',
        dryRun: false,
        deferred: true,
        split: true,
        serializationGroups: [],
        async : true
      }
    }
  ]
})
export class AttributeResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  public getAttributesValues(body: object): Observable<Object> {
    return this.http.post(this.getPath('/v2/attribute_values/retriever'), JSON.stringify(body), {
      headers: { 'Content-Type': 'application/json' },
    });
  }
}
