import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services/auth.service';
import { AbstractResource, BlockResource } from '@resources';
import { FormBuilder, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { SnackbarService } from '@components/snackbar/snackbar.service';
import { BlockHelper } from '@components/generic/blocks/block-helper';

@Component({
  selector: 'app-block-form',
  template: require('./block-form.component.html'),
  styles: [require('./block.scss')],
  providers: [{ provide: AbstractResource, useClass: BlockResource }]
})
export class BlockFormComponent extends AbstractComponent implements OnInit {
  @Input() public businessObject: string;
  @Input() public businessObjectData?: any = {};
  @Input() public blockTitleField: string;
  @Input() public product?: any;
  @Input() public superProduct?: any;
  @Input() public category?: any;
  @Input() public commercialOperation?: any;
  @Input() public room?: any;
  @Input() public isModule: boolean = false;
  @Input() public properties: string[] = [];
  @Input() public readonly: boolean = false;
  @Input() public locale: string;
  @Input() public override: boolean = false;
  @Input() public title: string;
  @Input() public columns: string[] = [];
  @Input() public show: boolean = false;
  @Input() public allowDelete: boolean = true;
  @Input() public allowUpdate: boolean = true;
  @Input() public ownerBusinessObject?: string;
  @Input() public ownerData?: any;
  @Input() public additionalData?: any;
  @Input() public rteOptions?: object;
  @Input() public additionnalOptions?: object;
  @Input() public position: number;
  @Input() public filters: any = {};

  @Output() onEdit: EventEmitter<any> = new EventEmitter();
  @Output() onShow: EventEmitter<any> = new EventEmitter();
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() onSave: EventEmitter<any> = new EventEmitter();
  @Output() onDelete: EventEmitter<any> = new EventEmitter();

  public form: FormGroup;
  public controls: any[] = [];
  public edit: boolean = false;
  public titles: any[] = [];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    protected formBuilder: FormBuilder,
    protected snackbar: SnackbarService,
    @Inject('DialogService') protected dialog: any,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    if ('category_block_inset' === this.businessObject && 0 === Object.keys(this.businessObjectData).length) {
      this.businessObjectData.obfuscated = true;
    }
    this.form = BlockHelper.getForm(this.formBuilder, this.properties, this.businessObjectData);
    this.controls = BlockHelper.getControls(this.properties);
    this.setTable();
  }

  save(): void {
    const formData = BlockHelper.getFormData(
      this.form,
      this.properties,
      this.locale,
      this.businessObject,
      this.businessObjectData,
      this.product,
      this.superProduct,
      this.category,
      this.commercialOperation,
      this.room,
      this.ownerBusinessObject,
      this.ownerData,
      this.additionalData,
      this.isModule,
      this.filters
    );

    (<BlockResource>this.resource).writeBlock(formData, this.businessObject)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        this.snackbar.validate('SAVED');
        this.onSave.emit(response);
      })
    ;
  }

  delete() {
    this.dialog.confirm(this.translate('PAGE.CONTENT_WEBSITE.CONFIRM_DELETE'))
      .then(() => {
        (<BlockResource>this.resource).deleteBlock(this.businessObject, this.businessObjectData.id)
          .pipe(takeUntil(this.destroyed$))
          .subscribe(() => {
            this.snackbar.validate('SAVED');
            this.onDelete.emit();
          })
          ;
      })
    ;
  }

  setTable(): void {
    if (!this.columns.length) {
      return;
    }

    this.titles = this.columns.filter((field: string) => !!this.businessObjectData[BlockHelper.fields[field][0].name]).map((field: string) => {
      const value: string = this.businessObjectData[BlockHelper.fields[field][0].name] || null;
      const type: string = BlockHelper.fields[field][0].type || null;

      if (!type) {
        return { type: 'text', value: value || field };
      }

      return { type: type, value: value };
    });
  }
}
