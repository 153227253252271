import { AbstractComponent } from '@components/generic/abstract.component';
import { Inject, Input, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '@services';
import { AbstractResource } from '@resources';
import { SnackbarService } from '@components';
import { SessionHelper } from '@helpers';
import { BlockHelper } from '@components/generic/blocks/block-helper';

export abstract class AbstractBlockOverrideFormComponent extends AbstractComponent implements OnInit {
  @Input() public product?: any;
  @Input() public superProduct?: any;
  @Input() public category?: any;
  @Input() public commercialOperation?: any;
  @Input() public room?: any;

  public config: any = {};
  public overrideField: string;
  public currentLocales: string[] = SessionHelper.getCountry().locales;

  protected constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    protected snackbar: SnackbarService,
    @Inject('DialogService') protected dialog: any,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit() {
    this.setOverrideField();
    this.fetch();
  }

  setOverrideField() {
  }

  fetch(event?: any) {
  }

  fetchBlocks(filters: any, event?: any) {
  }

  fetchBlockConfig(entryPoint: string, event?: any) {
    this.resource.cGet({}, { entryPoint: entryPoint, throwViolations: false })
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (response: any) => {
          this.config = response;
          const filters = BlockHelper.getFilters(this.config, this.overrideField, this.product, this.category);
          this.fetchBlocks(filters, event);
        },
        () => {
          this.config[this.overrideField] = false;
          const filters = BlockHelper.getFilters(this.config, this.overrideField, this.product, this.category);
          this.fetchBlocks(filters, event);
        }
      )
    ;
  }

  override(event: any) {
    const translationKey = event.target.checked ? 'CONFIRM_ENABLE_OVERRIDE' : 'CONFIRM_DISABLE_OVERRIDE';

    this.dialog.confirm(this.translate('PAGE.CONTENT_WEBSITE.' + translationKey))
      .then(() => {
        const body: any = {};

        if (this.product) {
          body.product = this.product.id;
        }

        if (this.category) {
          body.category = this.category.id;
        }

        if (this.commercialOperation) {
          body.commercialOperation = this.commercialOperation.id;
        }

        if (this.room) {
          body.room = this.room.id;
        }

        body[this.overrideField] = +!this.config[this.overrideField];

        let entryPoint;

        if (this.product) {
          entryPoint = '/v2/product_blocks';
        } else if (this.category) {
          entryPoint = '/v2/category_blocks';
        } else {
          return;
        }

        this.resource.create(body, { entryPoint: entryPoint })
          .pipe(takeUntil(this.destroyed$))
          .subscribe(() => {
            this.snackbar.validate('SAVED');
            this.fetch({ reload: true });

            if (this.hasSeveralTranslations()) {
              this.state.go(this.state.current, this.state.params, { reload: true });
            }
          })
        ;
      }, () => {
        event.target.checked = !event.target.checked;
      })
    ;
  }

  hasSeveralTranslations(): boolean {
    return this.currentLocales.length > 1;
  }
}
