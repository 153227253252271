import { AbstractResource } from '../../resources/abstract.resource';
import { Injectable } from '@angular/core';
import { IExportOptions } from '@interfaces';
import { Observable } from 'rxjs/Observable';
import * as moment from 'moment';
import { DATE_SHORT_INTERNATIONAL_FORMAT } from '@constants';
import { Resource } from '@decorators/Resource';
import { HttpClient } from '@angular/common/http';
import { SessionHelper } from '../../helpers/session.helper';
import { RedirectsModel } from '@components/redirects/models';

const exportOptions: IExportOptions[] = [
  {
    entryPoint: '/v2/redirections.csv?pagination=false',
    responseType: 'text',
    type: 'text/plain',
    filename: `redirections_${moment().format(DATE_SHORT_INTERNATIONAL_FORMAT)}.csv`,
    translationKey: 'BUTTON.EXPORT.CSV',
    filters: true
  }
];

@Injectable()
@Resource({
  isHydra: true,
  entryPoint: '/v2/redirections',
  cGetDefaultFilters: () => ({ 'locale[]': SessionHelper.getCountry().locales }),
  model: RedirectsModel,
  routeName: 'redirects',
  translationKey: 'REDIRECTS',
  listColumns: [
    {field: 'id', translationKey: 'ID', type: 'id'},
    {field: 'updatedAt', translationKey: 'UPDATED_AT', type: 'date', sortable: true},
    {field: 'fromRoute', translationKey: 'FROM_ROUTE', type: 'string', sortable: true},
    {field: 'toRoute', translationKey: 'TO_ROUTE', type: 'string', sortable: true},
    {field: 'automatic', translationKey: 'AUTOMATIC', type: 'boolean', sortable: true},
    {field: 'fromStatusCode', translationKey: 'FROM_STATUS_CODE', type: 'text', sortable: false, displayRawHtml: true},
    {field: 'toStatusCode', translationKey: 'TO_STATUS_CODE', type: 'text', sortable: false, displayRawHtml: true},
    {field: 'finalStatusCode', translationKey: 'FINAL_STATUS_CODE', type: 'text', sortable: false, displayRawHtml: true},
    {field: 'crawledAt', translationKey: 'CRAWLED_AT', type: 'date', sortable: false},
    {field: 'username', translationKey: 'AUTHOR', type: 'string', sortable: true, sortableName: 'createdBy.username'},
  ],
  exportOptions,
  updateAvailable: 'ROLE_WALISOFT_AGENT',
  buttonActionMultipleAvailable: true,
  hasMassiveActions: true,
})
export class RedirectsResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  public cGet(params: any = {}, options: any = {}): Observable<object> {
    params['updatedAt[after]'] = params.startDate;
    params['updatedAt[before]'] = params.endDate;
    params['order[fromRoute]'] = params.sort_fromRoute;
    params['order[toRoute]'] = params.sort_toRoute;
    params['order[updatedAt]'] = params.sort_updatedAt;
    params['order[automatic]'] = params.sort_automatic;
    params['order[createdBy.username]'] = params['sort_createdBy.username'];

    delete params.date;

    return super.cGet(params, options);
  }
}
