import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { HttpClient } from '@angular/common/http';
import { AbstractResource } from '@resources';
import { EavAttributeOptionModel } from '@components/eav-attribute/models/eav-attribute-option.model';

@Injectable()
@Resource({
  entryPoint: '/v2/eav_attribute_options',
  listModel: EavAttributeOptionModel,
  formModel: EavAttributeOptionModel,
  createAvailable: false,
  deleteAvailable: false,
  isHydra: true,
})
export class EavAttributeOptionResource extends AbstractResource {
  constructor(
    http: HttpClient,
  ) {
    super(http);
  }
}
