import { Component, Inject, Input } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services/auth.service';
import { SessionHelper } from '@helpers';

@Component({
  selector: 'app-product-block-list',
  template: require('./product-block-list.component.html'),
  styles: [require('../blocks/block.scss')],
})
export class ProductBlockListComponent extends AbstractComponent {
  @Input() public product?: any;
  @Input() public superProduct?: any;

  public currentLocales: string[] = SessionHelper.getCountry().locales;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, null, state);
  }

  public hasSeveralTranslations(): boolean {
    return this.currentLocales.length > 1;
  }
}
