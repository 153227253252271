import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { HttpClient } from '@angular/common/http';
import { AbstractResource } from '@resources';

@Injectable()
@Resource({
  entryPoint: '/v2/countries',
  routeName: 'shipping-prices',
  isHydra: true,
  createAvailable: false,
  deleteAvailable: false,
  translationKey: 'SHIPPING_PRICE',
})
export class ShippingPriceResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }
}
