import { Resource } from '@decorators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AbstractResource, IRequestOptions } from '@resources';
import { Observable } from 'rxjs/Observable';

@Injectable()
@Resource({
  entryPoint: '/v2/website-category',
  isHydra: true,
})
export class WebsiteCategoriesResource extends AbstractResource {

  constructor(http: HttpClient) {
    super(http);
  }

  cGet(params: any | null = null, options: IRequestOptions = {}, headers?: HttpHeaders): Observable<object> {
    if (params.slug) {
      options.entryPoint = this.entryPoint + '/' + params.slug;
      delete params.slug;
    }
    return super.cGet(params, options, headers);
  }
}
