import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SessionHelper } from '@helpers/session.helper';
import { PageChangedEvent } from 'ngx-bootstrap/pagination/pagination.component';

/**
 * Handle hydra pagination.
 *
 * Emits `onPaginate` event.
 */
@Component({
  selector: 'app-hydra-pagination',
  template: require('./hydra-pagination.component.html'),
  styles: [require('./hydra-pagination.component.scss')],
})
export class HydraPaginationComponent implements OnInit, OnChanges {

  public currentPage: number = 1;
  public itemsByPage: number;
  public maxPages: number = 0;
  public readonly limitChoices: number[] = [15, 30, 50, 100, 200, 250];

  @Input() public totalItems: number;
  @Input() public hydraPagination: any = null;

  @Output() public onPaginate: EventEmitter<any> = new EventEmitter();

  constructor(@Inject('StateService') public state: ng.ui.IStateService) { }

  ngOnInit(): void {
    const currentState = (<any>this.state.$current).parent.self.name;
    let defaultPageIndex = this.currentPage;

    if (SessionHelper.getFiltersForPage(currentState)) {
      defaultPageIndex = SessionHelper.getFiltersForPage(currentState).page ?
        SessionHelper.getFiltersForPage(currentState).page :
        this.currentPage
        ;
    }

    this.currentPage = this.state.params.page ? +this.state.params.page : +defaultPageIndex;
    this.itemsByPage = this.state.params.byPage || 30;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hydraPagination && changes.hydraPagination.currentValue) {
      this.currentPage = +changes.hydraPagination.currentValue.page;
      this.itemsByPage = +changes.hydraPagination.currentValue.byPage;
    }
  }

  /**
   * Triggers by selecting a page or a limit.
   */
  public paginate(event: PageChangedEvent | number, paginateBy: string): void {
    if ((<PageChangedEvent>event).page !== this.currentPage) {
      const byPage = this.itemsByPage;

      if ('page' === paginateBy) {
        this.onPaginate.emit({ page: (<PageChangedEvent>event).page, byPage, limit: byPage });

        return;
      }

      this.maxPages = <number>event;
      this.onPaginate.emit({ page: this.currentPage, byPage, limit: byPage });
    }
  }
}
