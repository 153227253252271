import { IFiltersFieldsService } from '@components/generic/Form/filters/filters-fields.service.interface';
import { AbstractFormFieldBase } from '@components/generic/Form/dynamic/fields/abstract-form-field-base.class';
import { FormNotifierService } from '@services/form-notifier.service';
import { FORM_STREAMS } from '../../../../enums/form-notifier-streams.enum';
import { SessionHelper } from '@helpers/session.helper';
import { HttpParams } from '@angular/common/http';
import { HttpEncoderService } from '@services/http-encoder.service';
import { Inject } from '@angular/core';
import { AbstractService } from '@services/abstract.service';

/**
 * Listen to a resetting filters event to reset the filters.
 * Sets the filters values necessary for the form fields in the `filters` property.
 *
 * Services that inherit from this class must implements the getFields methods,
 * these services are used in a generic way in the FilterListComponent.
 */
export abstract class AbstractFiltersFieldsService extends AbstractService implements IFiltersFieldsService {

  protected filters: URLSearchParams;

  protected constructor(
    protected formNotifier: FormNotifierService,
    @Inject('StateService') protected state: ng.ui.IStateService,
  ) {
    super();

    this.formNotifier.observable.subscribe((stream) => {
      if (FORM_STREAMS.formResetting === stream) {
        this.filters = new URLSearchParams('');
      }
    });

    this.setFilters();
  }

  /**
   * Gets array of fields to construct the form.
   */
  public abstract getFields(): AbstractFormFieldBase<any>[];

  public getComplementaryFields(): AbstractFormFieldBase<any>[] {
    return [];
  }

  /**
   * Sets filters property with URLSearchParams stored in url or in the localstorage.
   * `filters` property will be used for complete the filters fields.
   *
   * Functionality:
   *     1 - If the user arrives on the page, there are no filters in the url nor in the localstorage.
   *         So `filters` will be just an initialized URLSearchParams with no data.
   *         In this case the fields of the form will be empty and the request to the api don't use params
   *         except if there are default params.
   *     2 - If the user copy/paste an url that contains query params. In this case we have to take the filters from the url and
   *         inject them in the fields of the form, then save them in the localstorage if options `keepFiltersInStorage` from
   *         `FilterListComponent` is set to true.
   *         That way, if the user navigate to another page and returns on this page, we have to checks in the localstorage
   *         for finding existing filters related to the page, call this method to set the data in the form then update the url.
   *         -> this step take predominance on others.
   *     3 - If the user arrives on the page, there are no filters in the url as step 1, but the localstorage contains
   *         some filters related from to the page. In this case `filters` property contains params from the storage.
   */
  public setFilters(): void {
    const filters = !!this.encodeParams(this.state.params).toString() ? this.state.params : false;
    const filtersInStorage = SessionHelper.getFiltersForPage((<any>this.state.$current).parent.self.name);
    const params = filters ?
      filters :
      filtersInStorage ?
        filtersInStorage :
        {}
    ;

    this.filters = new URLSearchParams(params ? this.encodeParams(params).toString() : null);
  }

  /**
   * Encode the filter object into query params.
   */
  private encodeParams(params: object): HttpParams {
    if (params) {
      const encodedParams = new URLSearchParams();

      Object.entries(params)
        .filter((item: any[]) => undefined !== item[1])
        .forEach((item: any[]) => {
          if (Array.isArray(item[1])) {
            for (const param of item[1]) {
              encodedParams.append(item[0], param);
            }
          } else {
            encodedParams.append(item[0], item[1]);
          }
        })
      ;

      return new HttpParams({ fromString: encodedParams.toString() });
    }

    return null;
  }
}
