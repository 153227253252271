import { Component, Inject, Input, OnInit } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services/auth.service';
import { AbstractResource, AttributeResource } from '@resources';
import { takeUntil } from 'rxjs/operators';
import { SessionHelper } from '@helpers';
import { ICountry } from '@interfaces';

interface IAttributesValues {
  name: string;
  unit?: string;
  type?: string;
  value: string;
}

@Component({
  selector: 'app-product-block-result-list',
  template: require('./product-block-result-list.component.html'),
  providers: [{ provide: AbstractResource, useClass: AttributeResource }]
})
export class ProductBlockResultListComponent extends AbstractComponent implements OnInit {
  @Input() public product?: any;
  @Input() public superProduct?: any;
  @Input() public locale: string;

  public additionalCustomAttributes: any[] = [];
  public additionalTechnicalAttributes: any[] = [];
  public isFetched: boolean = false;
  public currentCountry: ICountry;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit() {
    this.currentCountry = SessionHelper.getCountry();
    this.fetch();
  }

  fetch() {
    (<AttributeResource>this.resource).getAttributesValues(this.getBody())
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: IAttributesValues[]) => {
        this.isFetched = true;

        response.forEach((attributeValue: IAttributesValues) => {
          if ('product' === attributeValue.type) {
            this.additionalCustomAttributes.push({key: attributeValue.name, value: attributeValue.value + (attributeValue.unit ? attributeValue.unit : '')});
          } else if ('technical' === attributeValue.type) {
            this.additionalTechnicalAttributes.push({key: attributeValue.name, value: attributeValue.value + (attributeValue.unit ? attributeValue.unit : '')});
          }
        });
      });
  }

  getBody() {
    const superProduct = this.product ? this.product.superProduct : (this.superProduct || null);
    const masterProduct = this.product ? this.product.masterProduct : null;

    return {
      'locale': this.locale,
      'country': this.currentCountry.id,
      'superProduct': superProduct ? superProduct.id : null,
      'masterProduct': masterProduct ? masterProduct.id : null,
    };
  }
}
