export const HOMEPAGE = 'homepage';
export const UNIVERSE = 'universe';
export const SHELF = 'shelf';
export const PRODUCT = 'product';
export const CMS = 'cms';

export const TYPES: string[] = [
  HOMEPAGE,
  UNIVERSE,
  SHELF,
  PRODUCT,
  CMS,
];
