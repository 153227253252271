import { IWarehouses } from './warehouses.interface';
import { ICountry } from '@interfaces';

export class WarehousesModel implements IWarehouses {
  public '@id': string;
  public id: string;
  public name: string;
  public code: string;
  public countries: ICountry[] = [];
  public assignable: boolean;
  public active: boolean;
  public countriesCodes?: string[] = [];
  public acceptSparePart?: boolean;
  public capacityExportEnabled?: boolean;
  public fulfillment?: boolean;
  public clustering?: boolean;
  public boxes: any[];
  public stockOrigin: string;
  public address: any[];

  constructor(warehouses: IWarehouses) {
    this['@id'] = warehouses['@id'];
    this.id = warehouses.id;
    this.name = warehouses.name;
    this.code = warehouses.code;
    this.countries = warehouses.countries;
    this.assignable = warehouses.assignable;
    this.active = warehouses.active;
    this.countriesCodes = warehouses.countries ? warehouses.countries.map((country: ICountry) => country.code) : [];
    this.acceptSparePart = warehouses.acceptSparePart;
    this.capacityExportEnabled = warehouses.capacityExportEnabled;
    this.fulfillment = warehouses.fulfillment;
    this.clustering = warehouses.clustering;
    this.boxes = warehouses.boxes;
    this.stockOrigin = warehouses.stockOrigin;
    this.address = warehouses.address;
  }
}
