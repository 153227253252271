'use strict'

angular
  .module 'ammsFrontendApp'
  .factory 'ListFilter', [
    '$state'
    '$stateParams'
    ($state, $stateParams) ->
      class ListFilter
        constructor: (@defaultFilters) ->
          return this

        extractUrl: ->
          filters = {}

          for key, value of @defaultFilters
            if ($stateParams[key]?)
              filters[key] = if 'null' == $stateParams[key] then undefined else $stateParams[key]
            else
              filters[key] = angular.copy value

            if key is 'currentTab'
              filters[key] = parseInt filters[key]

          filters

        updateUrl: (filters, reload = false) ->
          params = {}
          options = {}

          for key, value of filters
            if !angular.equals(value, @defaultFilters[key]) || !angular.equals(value, $stateParams[key])
              params[key] = angular.copy(value)

          if reload
            options['reload'] = true

          $state.go('.', params, options)

        reset: (filters) ->
          for key, value of @defaultFilters
            if key not in ['page', 'byPage', 'orderBy', 'direction', 'currentTab']
              filters[key] = angular.copy value

          filters
]
