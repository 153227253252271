import { ICustomerDetailOrderAddress } from '@components/customer/interfaces/customer-detail-order-address.interface';
import { ICustomerDetailAddresses } from '@components/customer/interfaces/customer-detail-addresses.interface';
import { IAddress } from '@models/address.interface';

export class CustomerDetailAddressesModel implements ICustomerDetailAddresses {
  public addresses: IAddress[];
  public billingAddresses: ICustomerDetailOrderAddress[];
  public shippingAddresses: ICustomerDetailOrderAddress[];

  constructor(customerDetailAddresses: ICustomerDetailAddresses) {
    this.addresses = customerDetailAddresses.addresses;
    this.billingAddresses = customerDetailAddresses.billingAddresses;
    this.shippingAddresses = customerDetailAddresses.shippingAddresses;
  }
}
