import { Inject, Injectable } from '@angular/core';
import { SessionHelper } from '@helpers/session.helper';
import { FormNotifierService } from '@services/form-notifier.service';
import { ComboSearchField, TextField } from '@components/generic/Form/dynamic/fields';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';

@Injectable()
export class CategoriesMarketplaceFiltersFormService extends AbstractFiltersFieldsService {

  private booleanChoices: {value: number; label: string}[] = [];

  constructor(
    @Inject('StateService') protected state: ng.ui.IStateService,
    @Inject('TranslationService') public $translate: ng.translate.ITranslateService,
    formNotifier: FormNotifierService,
  ) {
    super(formNotifier, state);
  }

  public getFields(): any[]  {
    this.booleanChoices = [
      {value: 1, label: this.$translate.instant('PAGE.CATEGORIES_MARKETPLACE.BOOLEANS.YES')},
      {value: 0, label: this.$translate.instant('PAGE.CATEGORIES_MARKETPLACE.BOOLEANS.NO')},
      {value: null, label: this.$translate.instant('PAGE.CATEGORIES_MARKETPLACE.BOOLEANS.ALL')},
    ];

    return [
      new TextField({
        fieldName: 'label',
        label: 'PAGE.CATEGORIES_MARKETPLACE.FILTERS.LABEL.LABEL',
        value: this.filters.get('label') ? this.filters.get('label') : undefined,
        order: 1,
      }),

      new TextField({
        fieldName: 'code',
        label: 'PAGE.CATEGORIES_MARKETPLACE.FILTERS.CODE.LABEL',
        value: this.filters.get('code') ? +this.filters.get('code') : undefined,
        order: 2,
      }),

      new ComboSearchField({
        fieldName: 'marketplace',
        label: 'PAGE.CATEGORIES_MARKETPLACE.FILTERS.MARKETPLACE.LABEL',
        data: SessionHelper.getMarketplaces(),
        textField: 'name',
        valueField: 'code',
        value: this.filters.get('marketplace') ? this.filters.get('marketplace') : undefined,
        valuePrimitive: true,
        order: 3,
      }),

      new ComboSearchField({
        fieldName: 'active',
        label: 'PAGE.CATEGORIES_MARKETPLACE.FILTERS.ACTIVE.LABEL',
        data: this.booleanChoices,
        textField: 'label',
        valueField: 'value',
        value: this.filters.get('active') ? +this.filters.get('active') : undefined,
        valuePrimitive: true,
        order: 4,
      }),

      new ComboSearchField({
        fieldName: 'deprecated',
        label: 'PAGE.CATEGORIES_MARKETPLACE.FILTERS.DEPRECATED.LABEL',
        data: this.booleanChoices,
        textField: 'label',
        valueField: 'value',
        value: this.filters.get('deprecated') ? +this.filters.get('deprecated') : undefined,
        valuePrimitive: true,
        order: 5,
      }),

      new ComboSearchField({
        fieldName: 'leaf',
        label: 'PAGE.CATEGORIES_MARKETPLACE.FILTERS.SELECTABLE.LABEL',
        data: this.booleanChoices,
        textField: 'label',
        valueField: 'value',
        value: this.filters.get('leaf') ? +this.filters.get('leaf') : undefined,
        valuePrimitive: true,
        order: 6,
      }),
    ];
  }
}
