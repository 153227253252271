import { Injectable } from '@angular/core';
import { Resource } from '../decorators/Resource';
import { AbstractResource } from '../resources/abstract.resource';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  entryPoint: '/statutes',
})
export class StatusResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }
}
