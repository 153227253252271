import { Component, Inject, OnInit } from '@angular/core';
import { AuthService, FormNotifierService } from '@services';
import { SnackbarService, WarehousesResource } from '@components';
import { ICarrierGroup as ICarrierGroups } from '@components/carrier/interfaces';
import { IWarehouses } from '@components/warehouses/models';
import { IWarehouseCarrierGroupAgencies } from '@components/warehouses/models/warehouse-carrier-group-agencies.interface';
import { WarehouseCarrierGroupAgenciesResource } from '@components/warehouses/warehouse-carrier-group-agencies.resource';
import { IDirectInjection } from '@components/export-logistics/models/direct-injection.interface';
import { DirectInjectionModel } from '@components/export-logistics/models/direct-injection.model';
import { ExportLogisticsResource } from '@components/export-logistics/export-logistics.resource';
import { IMarketplace, IUserCountry } from '@components/export-logistics/forms';
import {
  CountryService,
  MarketplacesService,
  WarehousesService,
  WcaService
} from '@components/export-logistics/services';
import { ICarrierGroup } from '@components/export-logistics/forms/choice-carrier-groups-form.component';
import { IWarehouse } from '@components/export-logistics/forms/choice-warehouses-form.component';
import { IFormViolation } from '@interfaces/IFormViolation';
import { FORM_STREAMS } from '../../enums/form-notifier-streams.enum';
import * as moment from 'moment';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { CarrierGroupResource } from '@resources/carrier-group.resource';
import { CarrierService } from '@components/export-logistics/services/carrier.service';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { MarketplaceResource } from '@resources';
import { ExportLogisticsScheduleResource } from './export-logistics-scheduled/resources/export-logistics-schedule.resource';

interface IExportQuery {
  countries: string[];
  warehouses: string[];
  carriers: string[];
  warehouseCarrierGroupAgencies: string[];
  marketplaces: string[];
  dryRun: boolean; // true for simulation (click on warehouseCarrierGroupAgencies box) false for real export (click on submit btn)
}

interface IExportCarrierScaleScheduledQuery {
  warehouse: string;
  fromCarrierGroup: string;
  fromVolume: number;
  toCarrierGroup: string;
  toVolume: number;
  days: string[];
  maxCarriersDiffCost: number;
  active: boolean;
}

interface IExportScheduledQuery {
  countries: string[];
  warehouses: string[];
  carriers: string[];
  warehouseCarrierGroupAgencies: string[];
  marketplaces: string[];
  schedule: string[];
  active: boolean;
  carrierScaleScheduled?: IExportCarrierScaleScheduledQuery;
}

interface IExportSummary {
  agency: string;
  warehouse: string;
  orders: number;
  items?: number;
  packages: number;
  volume: number;
  deliverable: boolean;
}

@Component({
  selector: 'app-export-logistics',
  template: require('./export-logistics.component.html'),
  styles: [require('./export-logistics.component.scss')],
  providers: [
    ExportLogisticsResource,
    WarehousesResource,
    WarehouseCarrierGroupAgenciesResource,
    ExportLogisticsScheduleResource,
  ],
})
export class ExportLogisticsComponent extends AbstractPageComponent implements OnInit {
  public readonly roles: string[] = ['ROLE_WALISOFT_AGENT', 'ROLE_WALISOFT_AGENT'];

  public isInReadonlyWarehouseForm: boolean;
  public isInReadonlyCarrierForm: boolean;
  public isInReadonlyMarketplaceForm: boolean;
  public carrierGroups: ICarrierGroup[] = [];
  public warehouses: IWarehouse[] = [];
  public warehousesChecked: IWarehouse[] = [];
  public marketplaces: IMarketplace[] = [];

  public isInReadonlyWarehouseCarrierGroupAgenciesForm: boolean;
  public warehouseCarrierGroupAgencies: IDirectInjection[] = [];
  public isExportIsLaunching: boolean;
  public dryRunExportIsValid: boolean = false;
  public exportSummary: IExportSummary[] = [];
  public violations: any;
  public objectKeys: (o: {}) => string[] = Object.keys;
  public date: string = moment().locale(this.shortLocale).format('LTS');
  public scheduleTable: any;
  public openedScheduleModal: boolean = false;
  public carrierGroupsChecked: ICarrierGroup[] = [];
  public scheduleStep: number = 0;
  private carrierScaleForm: any = {
    valid: false,
    add: false,
    values: {},
  };

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private carrierGroupResource: CarrierGroupResource,
    private exportLogisticsResource: ExportLogisticsResource,
    private exportLogisticScheduleResource: ExportLogisticsScheduleResource,
    private warehouseResource: WarehousesResource,
    private warehouseCarrierGroupAgenciesResource: WarehouseCarrierGroupAgenciesResource,
    private marketplaceResource: MarketplaceResource,
    private carrierService: CarrierService,
    private countryService: CountryService,
    private warehousesService: WarehousesService,
    private marketplacesService: MarketplacesService,
    private wcaService: WcaService,
    private formNotifier: FormNotifierService,
    private snackbar: SnackbarService
  ) {
    super($translate, authService, null, state);
  }

  ngOnInit() {
    if (!this.isGranted(this.roles)) {
      this.snackbar.alert(this.translate('ALERTS.AUTHORIZATION_FAIL'));
      return;
    }

    this.createScheduleTable();

    this.formNotifier.observable.takeUntil(this.destroyed$).subscribe((stream: any) => {
      const violations = stream.reject ? stream.reject.violations || stream.reject.errors : null;

      if (violations && !Array.isArray(violations)) {
        this.violations = violations;
      }

      if (stream === FORM_STREAMS.dataSubmitted) {
        this.violations = null;
      }
    });
  }

  /**
   * Sets the warehouses according to a list of countriesCodes,
   * The `checked` property is set to true for each warehouses by default.
   * The child component ChoiceWarehousesFormComponent will receive the warehouses list.
   */
  public fetchData(countriesCodes: string[]): void {
    this.resetDryRun();
    this.isInReadonlyWarehouseForm = true;
    this.isInReadonlyCarrierForm = true;
    this.isInReadonlyWarehouseCarrierGroupAgenciesForm = true;
    this.isInReadonlyMarketplaceForm = true;
    this.formNotifier.notifyFormSubmitted();

    const observables: any[] = [
      this.warehouseResource.getMany(
        {
          'carriers.country.code[]': countriesCodes,
          canExport: true,
          active: true,
          pagination: false,
        },
        {
          blocking: false,
        }
      ),
      this.carrierGroupResource.getMany(
        {
          'carriers.country.code[]': countriesCodes,
          active: true,
          pagination: false,
        },
        {
          blocking: false,
        }
      ),
    ];

    forkJoin(observables)
      .switchMap((responses: any[]) => {
        const warehouseResponse = responses[0];
        const carrierResponse = responses[1];

        this.warehouses = warehouseResponse.map((warehouse: IWarehouses): IWarehouse => {
          return {
            iri: warehouse['@id'],
            code: warehouse.code,
            name: warehouse.name,
            checked: true,
          };
        });

        this.isInReadonlyWarehouseForm = false;
        this.warehousesService.setWarehousesChecked(
          this.warehouses.filter((warehouse: IWarehouse) => warehouse.checked)
        );
        this.warehousesChecked = this.warehousesService.getWarehousesChecked();
        const warehousesCodes: string[] = this.warehouses
          .filter((warehouse: IWarehouse) => warehouse.checked)
          .map((warehouse: IWarehouse) => warehouse.code);

        this.carrierGroups = carrierResponse.map((carrierGroup: ICarrierGroups): ICarrierGroup => {
          return {
            iri: carrierGroup['@id'],
            code: carrierGroup.code,
            name: carrierGroup.name,
            checked: true,
            volume: 0,
          };
        });

        this.isInReadonlyCarrierForm = false;
        this.carrierService.setCarriersChecked(
          this.carrierGroups.filter((carrierGroup: ICarrierGroup) => carrierGroup.checked)
        );

        const carriersCodes: string[] = this.carrierGroups
          .filter((carrierGroup: ICarrierGroup) => carrierGroup.checked)
          .map((carrierGroup: ICarrierGroup) => carrierGroup.code);
        return forkJoin([
          this.warehouseCarrierGroupAgenciesResource.getMany(
            {
              'warehouse.code[]': warehousesCodes,
              'carrierGroupAgency.carrierGroup.code[]': carriersCodes,
            },
            {
              blocking: false,
            }
          ),
          this.marketplaceResource.cGet(
            {
              'country[]': countriesCodes,
              'warehouseMarketplaces.warehouse.code[]': warehousesCodes,
              active: true,
              pagination: false,
            },
            {
              entryPoint: '/v2/marketplaces',
              isHydra: true,
              returnHydraMembers: true,
              blocking: false,
            }
          ),
        ]);
      })
      .switchMap((responses: any[]) => {
        const warehouseCarrierGroupAgencyResponse: IWarehouseCarrierGroupAgencies[] = responses[0];
        const marketplaceResponse = responses[1];

        this.warehouseCarrierGroupAgencies = warehouseCarrierGroupAgencyResponse.map(
          (warehouseCarrierGroupAgency: IWarehouseCarrierGroupAgencies) => {
            return new DirectInjectionModel(warehouseCarrierGroupAgency);
          }
        );

        this.wcaService.setWcaChecked(
          this.warehouseCarrierGroupAgencies.filter((wca: IDirectInjection) => wca.activated)
        );
        this.isInReadonlyWarehouseCarrierGroupAgenciesForm = false;

        this.marketplaces = marketplaceResponse.map((marketplace: any): IMarketplace => {
          return {
            iri: marketplace['@id'],
            code: marketplace.code,
            name: marketplace.commercialName,
            checked: true,
          };
        });

        this.isInReadonlyMarketplaceForm = false;
        this.marketplacesService.setMarketplacesChecked(
          this.marketplaces.filter((marketplace: IMarketplace) => marketplace.checked)
        );

        return [];
      })
      .takeUntil(this.destroyed$)
      .subscribe();
  }

  /**
   * Sets the warehouse carrier agencies according to a list of warehouses codes,
   * The child component ChoiceWarehouseCarrierGroupAgenciesFormComponent will receive the wca list.
   */
  public fetchWarehouseCarrierGroupAgencies(): void {
    this.dryRunExportIsValid = false;

    this.warehousesChecked = this.warehousesService.getWarehousesChecked();
    const warehousesCodes: string[] = this.warehousesService
      .getWarehousesChecked()
      .map((warehouse: IWarehouse) => warehouse.code);
    const carriersCodes: string[] = this.carrierService
      .getCarrierGroupsChecked()
      .map((carrierGroup: ICarrierGroup) => carrierGroup.code);
    const countriesCodes: string[] = this.countryService
      .getCountryChecked()
      .map((country: IUserCountry) => country.code);
    this.isInReadonlyWarehouseCarrierGroupAgenciesForm = true;
    this.isInReadonlyMarketplaceForm = true;
    this.formNotifier.notifyFormSubmitted();

    forkJoin([
      this.warehouseCarrierGroupAgenciesResource.getMany(
        {
          'warehouse.code[]': warehousesCodes,
          'carrierGroupAgency.carrierGroup.code[]': carriersCodes,
        },
        {
          blocking: false,
        }
      ),
      this.marketplaceResource.cGet(
        {
          'country[]': countriesCodes,
          'warehouseMarketplaces.warehouse.code[]': warehousesCodes,
          active: true,
          pagination: false,
        },
        {
          entryPoint: '/v2/marketplaces',
          isHydra: true,
          returnHydraMembers: true,
          blocking: false,
        }
      ),
    ])
      .switchMap((responses: any[]) => {
        const warehouseCarrierGroupAgencyResponse: IWarehouseCarrierGroupAgencies[] = responses[0];
        const marketplaceResponse = responses[1];

        this.warehouseCarrierGroupAgencies = warehouseCarrierGroupAgencyResponse.map(
          (warehouseCarrierGroupAgency: IWarehouseCarrierGroupAgencies) => {
            return new DirectInjectionModel(warehouseCarrierGroupAgency);
          }
        );

        this.wcaService.setWcaChecked(
          this.warehouseCarrierGroupAgencies.filter((wca: IDirectInjection) => wca.activated)
        );
        this.isInReadonlyWarehouseCarrierGroupAgenciesForm = false;

        this.marketplaces = marketplaceResponse.map((marketplace: any): IMarketplace => {
          return {
            iri: marketplace['@id'],
            code: marketplace.code,
            name: marketplace.commercialName,
            checked: true,
          };
        });

        this.isInReadonlyMarketplaceForm = false;
        this.marketplacesService.setMarketplacesChecked(
          this.marketplaces.filter((marketplace: IMarketplace) => marketplace.checked)
        );

        return [];
      })
      .takeUntil(this.destroyed$)
      .subscribe();
  }

  /**
   * Launch the export with dryRun to true when user select country||warehouse||warehouseCarrierGroupAgencies.
   * When user click on `Launch export` btn the dryRun is false.
   */
  public launchExport(dryRun: boolean): void {
    this.isExportIsLaunching = true;
    this.formNotifier.notifyFormSubmitted();

    this.exportLogisticsResource
      .create(this.prepareQuery(dryRun), { blocking: false })
      .takeUntil(this.destroyed$)
      .subscribe(
        (response: IExportSummary[]) => {
          this.updateExportSummary(response);
          this.snackbar.validate(
            this.translate(
              dryRun
                ? 'PAGE.EXPORT_LOGISTICS.LAUNCH_EXPORT.EXPORT_WITH_DRY_RUN_SUCCESS'
                : 'PAGE.EXPORT_LOGISTICS.LAUNCH_EXPORT.EXPORT_SUCCESS'
            )
          );

          if (dryRun) {
            this.dryRunExportIsValid = true;
          }
        },
        (reject: { violations: IFormViolation[]; errors: any }) => {
          this.isExportIsLaunching = false;
          if (this.isSimpleError(reject.errors)) {
            this.displaySnackbarErrors(reject.errors);
          } else {
            this.formNotifier.notifyFormHasErrors({ reject });
          }
        },
        () => (this.isExportIsLaunching = false)
      );
  }

  private prepareQuery(dryRun: boolean): IExportQuery {
    const countries: string[] = this.countryService.getCountryChecked().map((country: IUserCountry) => country.iri);
    const warehouses = this.warehousesService.getWarehousesChecked().map((warehouse: IWarehouse) => warehouse.iri);
    const marketplaces = this.marketplacesService
      .getMarketplacesChecked()
      .map((marketplace: IMarketplace) => marketplace.iri);
    const carriers: any[] = [];
    this.carrierService.getCarrierGroupsChecked().forEach((carrier: any) => {
      carriers.push({ carrierGroup: carrier.iri, volume: carrier.volume });
    });

    const warehouseCarrierGroupAgencies = this.wcaService
      .getWcaChecked()
      .map((directInjection: IDirectInjection) => directInjection['@id']);
    return { countries, warehouses, carriers, warehouseCarrierGroupAgencies, marketplaces, dryRun };
  }

  /**
   * Updates exportSummary to display new data in the table.
   */
  private updateExportSummary(exportSummary: IExportSummary[]): void {
    this.exportSummary = exportSummary.map((line: IExportSummary): IExportSummary => {
      return {
        agency: line.agency,
        warehouse: line.warehouse,
        orders: line.orders,
        packages: line.packages,
        volume: line.volume,
        deliverable: line.deliverable,
      };
    });
  }

  private isSimpleError(errors: any): boolean {
    return !!Object.keys(errors).find((key) => typeof errors[key] === 'string');
  }

  private displaySnackbarErrors(errors: any): void {
    Object.keys(errors).forEach((key) => {
      if (typeof errors[key] === 'string') {
        this.snackbar.alert(errors[key]);
      }
    });
  }

  public simulateExportLog() {
    this.launchExport(true);
  }

  public resetDryRun() {
    this.warehousesChecked = this.warehousesService.getWarehousesChecked();
    this.dryRunExportIsValid = false;
    this.updateExportSummary([]);
  }

  public createScheduleTable() {
    this.scheduleTable = {
      header: {
        title: 'PAGE.EXPORT_LOGISTICS.SCHEDULE.MODAL.TABLE.TITLE',
        days: [
          'PAGE.EXPORT_LOGISTICS.SCHEDULE.MODAL.TABLE.DAYS.MONDAY',
          'PAGE.EXPORT_LOGISTICS.SCHEDULE.MODAL.TABLE.DAYS.TUESDAY',
          'PAGE.EXPORT_LOGISTICS.SCHEDULE.MODAL.TABLE.DAYS.WEDNESDAY',
          'PAGE.EXPORT_LOGISTICS.SCHEDULE.MODAL.TABLE.DAYS.THURSDAY',
          'PAGE.EXPORT_LOGISTICS.SCHEDULE.MODAL.TABLE.DAYS.FRIDAY',
          'PAGE.EXPORT_LOGISTICS.SCHEDULE.MODAL.TABLE.DAYS.SATURDAY',
          'PAGE.EXPORT_LOGISTICS.SCHEDULE.MODAL.TABLE.DAYS.SUNDAY',
        ],
        hours: Array(24)
          .fill(null)
          .map((x: number, i: number) => ('0' + i).slice(-2)),
      },
      checkedValue: Array(7)
        .fill(null)
        .map((v) => Array(24).fill(false)),
    };
  }

  public toggleSchedule(x: number, y: number) {
    this.scheduleTable.checkedValue[x][y] = !this.scheduleTable.checkedValue[x][y];
  }

  public openSchedule() {
    this.openedScheduleModal = true;
  }

  public nextScheduleStep() {
    if (this.openedScheduleModal) {
      this.scheduleStep += 1;
    }
  }

  public prevScheduleStep() {
    if (this.openedScheduleModal) {
      this.scheduleStep -= 1;
    }
  }

  public updateCarrierScalingForm(carrierScalingForm: any): void {
    const valid = carrierScalingForm.valid;
    const add = carrierScalingForm.values.add || false;
    const values = carrierScalingForm.values;
    values.fromCarrierGroup = values.fromCarrierGroup ? values.fromCarrierGroup.iri : null;
    values.toCarrierGroup = values.toCarrierGroup ? values.toCarrierGroup.iri : null;
    const days = [];
    for (const day in values.days) {
      if (Object.prototype.hasOwnProperty.call(values.days, day) && values.days[day]) {
        days.push(day.toLocaleUpperCase());
      }
    }
    values.days = days;
    this.carrierScaleForm = {
      valid,
      add,
      values,
    };
  }

  public carrierScalingFormIsValid(): boolean {
    return this.carrierScaleForm.add === false || this.carrierScaleForm.valid === this.carrierScaleForm.add;
  }

  public validateSchedule() {
    if (!this.carrierScalingFormIsValid()) {
      return;
    }
    let carrierScaleScheduled: IExportCarrierScaleScheduledQuery = null;
    if (this.carrierScaleForm.add) {
      carrierScaleScheduled = this.carrierScaleForm.values;
    }
    const schedule = this.prepareScheduleQuery(this.scheduleTable.checkedValue);
    const exportQuery = this.prepareQuery(true);
    const exportScheduledQuery = <IExportScheduledQuery>{
      ...exportQuery,
      ...{ schedule, active: true },
      carrierScaleScheduled,
    };
    this.exportLogisticScheduleResource
      .create(exportScheduledQuery, { blocking: false })
      .takeUntil(this.destroyed$)
      .subscribe((response: IExportSummary[]) => {
        this.snackbar.validate(this.translate('PAGE.EXPORT_LOGISTICS.SCHEDULE.CREATED'));
      });
    this.closeSchedule();
  }

  public prepareScheduleQuery(checkedValue: any): string[] {
    const schedule: string[] = [];
    const days = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];
    for (let i = 0; i < days.length; i++) {
      const day = days[i];
      for (let j = 0; j < 24; j++) {
        if (checkedValue[i][j]) {
          schedule.push(day + '_' + j);
        }
      }
    }

    return schedule;
  }

  public closeSchedule() {
    this.scheduleTable.checkedValue = Array(7)
        .fill(null)
        .map((v) => Array(24).fill(false));
    this.openedScheduleModal = false;
    this.scheduleStep = 0;
  }
}
