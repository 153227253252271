import { Component, Inject, Output, EventEmitter, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import { FormErrorHelper } from '@helpers';
import { FileInfo, FileRestrictions, UploadEvent, SelectEvent, ClearEvent, RemoveEvent } from '@progress/kendo-angular-upload';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SnackbarService } from '@components';
import { HttpHeaders } from '@angular/common/http';
import { CustomerProductPhotosResource} from '@components/super-product/form/customer-photos/customer-photos.resource';
import { IColumn } from '@components/generic/grid/interfaces/column.interface';
import { AuthService } from '@services';
import { SuperProductResource } from '@components/super-product/super-product.resource';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { BASE_URL_API } from '@constants/config.constants';

const UPLOADER_CUSTOMER_PHOTOS_ENDPOINT = `${BASE_URL_API}/v2/customer_product_photos`;
const FILE_FIELD_NAME = 'file';

@Component({
  selector: 'app-customer-photos',
  template: require('./customer-photos.component.html'),
})
export class CustomerPhotosComponent extends AbstractPageComponent implements AfterViewInit {

  public userPictures: Array<any> = [];
  public selectedFiles: Array<FileInfo> = [];
  public form: FormGroup;
  public restrictions: FileRestrictions = {
    allowedExtensions: ['jpg', 'jpeg', 'png']
  };
  public uploaderEndpoint: string = UPLOADER_CUSTOMER_PHOTOS_ENDPOINT;
  public saveField: string = FILE_FIELD_NAME;
  public fetchEntryPoint: string = `/v2/super_products/${this.state.params.id}/customer_product_photos`;
  public selectedFilesHeaders: IColumn[];

  @Output() public onDelete: EventEmitter<object> = new EventEmitter();

  @ViewChild('stateTemplate') public stateTemplate: TemplateRef<any>;
  @ViewChild('sizeTemplate') public sizeTemplate: TemplateRef<any>;

  constructor(
    private fb: FormBuilder,
    private snackbar: SnackbarService,
    public superProductResource: SuperProductResource,
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: CustomerProductPhotosResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);

    this.form = this.fb.group({
      superproductId: [this.state.params.id, [Validators.required, Validators.minLength(4)]],
      file: [this.userPictures, [Validators.required]]
    });
  }

  ngAfterViewInit(): void {
    this.selectedFilesHeaders = [
      {
        name: this.translate('UPLOADER.NAME'),
        field: 'name',
      },
      {
        name: this.translate('UPLOADER.FILE_SIZE') + ' (Mo)',
        field: 'size',
        template: this.sizeTemplate
      },
      {
        name: this.translate('UPLOADER.STATUS'),
        field: 'state',
        template: this.stateTemplate
      }
    ];
  }

  /**
   * Fires by kendo-upload when one or more files are about to be uploaded.
   */
  public onUpload(event: UploadEvent): void {
    event.data = {
      'superproductId': this.form.value.superproductId
    };
    event.headers = new HttpHeaders({
      'accept': 'application/json'
    });
  }

  /**
   * Fires by kendo-upload when an `upload` operation has failed.
   * Fills form controls with errors.
   */
  public errorEventHandler(event: { [keys: string]: any }): void {
    FormErrorHelper.fillFormControlWithErrors(this.form, event.response.errors);
  }

  /**
   * Fires by kendo-upload when an `upload` operation is successfully completed.
   * Averts operator when file upload success.
   * Adds the new file to the form.
   */
  public successEventHandler(event: { [keys: string]: any }): void {
    this.snackbar.validate(this.translate('PAGE.CUSTOMER_PRODUCT_PHOTOS.FORM.UPLOAD_SUCCESS'));

    this.userPictures.push(event.response.body);
  }

  /**
   * Fires by kendo-upload when one or more files are selected.
   */
  public selectEventHandler(e: SelectEvent) {
    let receivedFiles: FileInfo[] = [];

    if (e.files) {
      receivedFiles = e.files;
    }

    this.selectedFiles.push(...receivedFiles);
  }


  /**
   * Fires by kendo-upload when the clear button is clicked.
   */
  public clearEventHandler(e: ClearEvent) {
    this.selectedFiles = [];
  }

  /**
   * Fires by kendo-upload when one or more files are removed.
   */
  public removeEventHandler(e: RemoveEvent) {
    this.selectedFiles = this.selectedFiles.filter((file: FileInfo) => file.uid !== e.files[0].uid);
  }

  /**
   * Calculates the number of items that are to be uploaded
   * https://www.telerik.com/kendo-angular-ui/components/upload/api/FileState/#toc-selected
   * All items that have a state different of selected and uploading are not considered.
   */
  public getTotalFilesRemaining (): number {
    return this.selectedFiles.filter((item: FileInfo) => item.state === 2 || item.state === 4 ).length;
  }
}
