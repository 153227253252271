export class AddressHelper {

    /**
     * Removes international fields
     */
    static purgeInternationalFields(target: any): void {
        if (target) {
            delete target.internationalPhone1;
            delete target.internationalPhone2;
        }
    }
}
