export class MarketplacesConfigureModel {
  id: string;
  code: string;
  commercialName: string;
  preorder: boolean;
  provisionalShippingDelay: number;
  maxEtdAccepted: number;

  constructor(data: any) {
    this.id = data['id'];
    this.code = data.code;
    this.commercialName = data.commercialName;
    this.preorder = data.preorder;
    this.provisionalShippingDelay = data.provisionalShippingDelay;
    this.maxEtdAccepted = data.maxEtdAccepted;
  }
}
