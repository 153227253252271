import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SliderModule, SwitchModule } from '@progress/kendo-angular-inputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { BsDatepickerModule, TabsModule } from 'ngx-bootstrap';
import { DndModule } from 'ng2-dnd';
import {
  ManufacturerFormComponent,
  ManufacturerEditComponent,
  ManufacturerNewComponent,
} from './Single';
import { GenericFormModule } from '@components/generic/generic-form.module';
import { GenericModule } from '@components/generic/generic.module';
import { EnhancedFieldsModule } from '@directives/enhanced-fields.module';
import { ManufacturerListComponent } from '@components/manufacturer/list/manufacturer-list.component';

@NgModule({
  declarations: [
    ManufacturerListComponent,
    ManufacturerFormComponent,
    ManufacturerEditComponent,
    ManufacturerNewComponent,
  ],
  entryComponents: [
    ManufacturerListComponent,
    ManufacturerFormComponent,
    ManufacturerEditComponent,
    ManufacturerNewComponent,
  ],
  imports: [
    CommonModule,
    BsDatepickerModule.forRoot(),
    DndModule.forRoot(),
    DropDownsModule,
    EnhancedFieldsModule,
    GenericFormModule,
    ReactiveFormsModule,
    SliderModule,
    SwitchModule,
    TabsModule.forRoot(),
    GenericModule,
  ],
  exports: [
    ManufacturerNewComponent,
    ManufacturerEditComponent,
  ]
})
export class ManufacturerModule { }
