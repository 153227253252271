import { ICountry } from '@interfaces';
import { IUser } from '@models';
import {IWarehouses} from '@components/warehouses/models';

export class UserModel implements IUser {
  '@id': string;
  id: string;
  username: string;
  email: string;
  roles: string[];
  allRoles: string[];
  selectableRoles: string[];
  countries: ICountry[];
  locale: string;
  warehouses: IWarehouses[];

  constructor(user: IUser) {
    this['@id'] = user['@id'];
    this.id = user.id;
    this.username = user.username;
    this.email = user.email;
    this.roles = user.roles;
    this.allRoles = [... new Set(user.allRoles)];
    this.selectableRoles = [... new Set(user.selectableRoles)];
    this.countries = user.countries;
    this.locale = user.locale;
  }
}
