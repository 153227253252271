'use strict'

angular
  .module 'ammsFrontendApp'
  .directive 'cmupTooltip', [
    '$compile'
    '$translate'
    ($compile, $translate) ->
      restrict: 'A'
      scope:
        product: '=cmupTooltip'
      priority: 1001
      terminal: true
      link: (scope, iElement) ->
        product = scope.product
        content = ''
        contentEnd = ''

        if product?
          if product.children?.length > 0
            contentEnd += '<br/>'
            contentEnd += $translate.instant 'TOOLTIP.CMUP.CONTENT.12'

          if product.sageQuantityInStock? && product.sageQuantityInStock <= 0 || product.warehouse is 'bureauneuf' && product.sageQuantityInStock? && product.sageQuantityInStock > 0 && product.sageStockAmount?
            content = "#{ $translate.instant 'TOOLTIP.CMUP.CONTENT.1' } :"

            if product.sageBuyPrice? && (product.sageTransportFee? || product.sageCustomsFees?)
              content += '<br/>'
              content += $translate.instant 'TOOLTIP.CMUP.CONTENT.2',
                sageBuyPrice: product.sageBuyPrice
                sageTransportFee: product.sageTransportFee
              content += '<br/>'
              content += $translate.instant 'TOOLTIP.CMUP.CONTENT.3',
                sageCustomsFees: product.sageCustomsFees

            else if product.sageBuyPrice?
              content += '<br/>'
              content += $translate.instant 'TOOLTIP.CMUP.CONTENT.4'
            else if product.warehouse is 'bureauneuf'
              content += '<br/>'
              content += $translate.instant 'TOOLTIP.CMUP.CONTENT.5',
                sageStockAmount: product.sageStockAmount
                sageQuantityInStock: product.sageQuantityInStock
            else
              content += '<br/>'
              content += $translate.instant 'TOOLTIP.CMUP.CONTENT.6'

            content += contentEnd
          else if product.sageStockAmount? && product.sageQuantityInStock?
            content += "#{ $translate.instant 'TOOLTIP.CMUP.CONTENT.1' } :"
            content += '<br/>'
            content += $translate.instant 'TOOLTIP.CMUP.CONTENT.5',
              sageStockAmount: product.sageStockAmount
              sageQuantityInStock: product.sageQuantityInStock
            content += contentEnd
          else if product.sageQuantityInStock? && product.sageQuantityInStock <= 0
            content += "#{ $translate.instant 'TOOLTIP.CMUP.CONTENT.1' } :"
            content += '<br/>'
            content += $translate.instant 'TOOLTIP.CMUP.CONTENT.7'
            content += '<br/>'
            content += $translate.instant 'TOOLTIP.CMUP.CONTENT.8'
            content += '<br/>'
            content += $translate.instant 'TOOLTIP.CMUP.CONTENT.9'
            content += contentEnd
          else if product.sageQuantityInStock? && product.sageQuantityInStock > 0
            content += "#{ $translate.instant 'TOOLTIP.CMUP.CONTENT.1' } :"
            content += '<br/>'
            content += $translate.instant 'TOOLTIP.CMUP.CONTENT.10'
            content += '<br/>'
            content += $translate.instant 'TOOLTIP.CMUP.CONTENT.11'
            content += contentEnd
          else
            content += $translate.instant 'TOOLTIP.CMUP.CONTENT.13'
            content += contentEnd
        else
          content += $translate.instant 'TOOLTIP.CMUP.CONTENT.14'
          content += contentEnd

        scope.cmupHelp = content

        iElement.attr 'uib-tooltip-html', 'cmupHelp'
        iElement.attr 'tooltip-trigger', 'mouseenter'

        $compile(iElement, null, 1001) scope
  ]
