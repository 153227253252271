import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services/auth.service';
import { IndicatorResource } from '@resources/indicator.resource';
import { IndicatorInterface } from '@interfaces/indicator.interface';
import { BASE_URL, INDICATOR_ORDER_ITEM_STOCK } from '@constants';
import { FormControl, Validators } from '@angular/forms';
import { ORDER_ITEM_STATUS_NEW, ORDER_ITEM_STATUS_PROCESSING, ORDER_ITEM_STATUS_WAITING_PAYMENT } from '@constants/item-status.constants';

/**
 * Wrap template in a `porlet` css style.
 */
@Component({
  selector: 'app-indicator',
  template: require('./indicator.component.html'),
  styles: [require('./indicator.component.scss')],
})
export class IndicatorComponent extends AbstractComponent implements OnInit {

  @Output() public update = new EventEmitter();
  @Output() public load = new EventEmitter();

  @Input() public businessObject: string;
  @Input() public businessObjectId: string;
  @Input() public type: string;
  @Input() public label: string;
  @Input() public readonly: boolean = false;
  @Input() public reloadAfterUpdate: boolean = false;
  @Input() public displayOrderList: boolean = true;
  @Input() public displayIcons: boolean = true;
  @Input() public displayInfos: boolean = false;
  @Input() public orderItemStatus: string;
  @Input() public displayStateDate: boolean = false;
  @Input() public displayStateCondition: string[] = ['green', 'red'];

  public indicator: IndicatorInterface;
  readonly BASE_URL = BASE_URL;
  public opened = false;

  public date = new FormControl('', Validators.required);

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    private indicatorResource: IndicatorResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, null, state);
  }

  ngOnInit() {
    this.fetchData();
  }

  fetchData() {
    this.indicatorResource.get(this.businessObjectId, {blocking: false, params: { businessObject: this.businessObject, type: this.type } })
      .subscribe(
        data => {
          this.indicator = <IndicatorInterface>data;
          this.load.emit(this.indicator);
        }
      );
  }

  setStatus(status: 'green' | 'orange' | 'red') {
    if (this.type === 'orderItemDeliverableIndicator' && status === 'orange') {
      this.openDateDialog();
    } else {
      const indicator = {
        businessObjectId: this.businessObjectId,
        businessObject: this.businessObject,
        type: this.type,
        status: status
      };
      this.createIndicator(indicator);
    }
  }

  public closeDateDialog() {
    this.opened = false;
  }

  public openDateDialog() {
    this.opened = true;
  }

  public onSubmitDateDialog() {
    const indicator = {
      businessObjectId: this.businessObjectId,
      businessObject: this.businessObject,
      type: this.type,
      status: 'orange',
      extraData: {
        lockDate: this.date.value
      }
    };

    this.createIndicator(indicator);
  }

  private createIndicator(indicator: { businessObjectId: string, businessObject: string, type: string, status: string, extraData?: any }) {
    this.indicatorResource.create(indicator).subscribe(
      response => {
        if (this.reloadAfterUpdate) {
          this.state.go(this.state.current, this.state.params, { reload: true });
        }

        this.update.emit();
        this.indicator = <IndicatorInterface>response;
        this.closeDateDialog();
        this.update.emit(this.indicator);
      }
    );
  }

  public canBeDisplay(status: 'green' | 'orange' | 'red', orderItemStatus?: string) {
    if (
      this.type === INDICATOR_ORDER_ITEM_STOCK
      &&
      orderItemStatus
      &&
      orderItemStatus !== ORDER_ITEM_STATUS_PROCESSING && orderItemStatus !== ORDER_ITEM_STATUS_NEW
      &&
      orderItemStatus !== ORDER_ITEM_STATUS_WAITING_PAYMENT
      &&
      status !== 'red') {
      return false;
    } else {
      return true;
    }
  }
}
