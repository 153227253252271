import {Injectable, Inject} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormArray,
  FormControl,
  Validators,
} from '@angular/forms';
import {
  IReverseFormService,
  IReverse,
  IReverseProduct,
  IReverseProductPackage,
  IPackage,
  ReverseResource,
  Reverse,
  ReverseProduct,
  ReverseProductPackage,
} from '@components/reverse';
import { Subscription } from 'rxjs/Subscription';
import { ICarrierGroup } from '@interfaces';
import { SessionHelper, FormErrorHelper } from '../../../helpers';
import {
  RECEIVE_WAITING,
  RECONDITIONED_SAGE,
  DATE_SHORT_INTERNATIONAL_FORMAT,
  DATE_SHORT_FORMAT,
  LOCALE_FR
} from '@constants';
import { ISku } from '@interfaces';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable } from 'rxjs/Observable';
import { OPEN_STATUS } from '@constants/reverseStatuses';
import { FormNotifierService } from '../../../services/form-notifier.service';
import { SnackbarService } from '../../../components/snackbar/snackbar.service';
import { ProductResource } from '../../../components/product/product.resource';
import { WarehousesResource } from '@components/warehouses/warehouses.resource';
import {IGrcReason} from '@components/order/interfaces/order-refund.interface';
import {GrcReasonResource} from '@components/order/resources/grc-reason-resource.service';
import {HydraHelper} from '@helpers/HydraHelper';

/**
 * This is the service for managing the forms of the reverses.
 * It will serves the form to the different child states of reverse
 * and prepares then submits the form.
 *
 * Also holds methods for selecting and deselecting multiple data.
 * Manages the fetch of all data needed by reverses.
 */
@Injectable()
export class ReverseFormService implements IReverseFormService {

  public reverseForm: FormGroup;
  public workshopForm: FormGroup;
  public reasons: any[];
  public grcReasons: any[];
  public warehouses: any[];
  public carrierGroups: ICarrierGroup[];
  private reverse: IReverse;
  private reverseModel: Reverse;
  private backToList: boolean = false;
  private backToOrder: boolean = false;

  constructor(
    private fb: FormBuilder,
    private formNotifier: FormNotifierService,
    private resource: ReverseResource,
    private productResource: ProductResource,
    private warehouseResource: WarehousesResource,
    @Inject('StateService') public state: ng.ui.IStateService,
    private snackbar: SnackbarService,
    @Inject('TranslationService') private $translate: ng.translate.ITranslateService,
    private grcReasonResource: GrcReasonResource,
  ) {
    this.fetchGrcReasons();
    this.fetchWarehouses();

  }

  get reverseProducts(): FormArray {
    return this.reverseForm.get('reverseProducts') as FormArray;
  }

  public getReverseModel(): IReverse {
    return this.reverseModel;
  }

  get reverseWorkshops(): FormArray {
    return this.workshopForm.get('reverseWorkshops') as FormArray;
  }

  public fetchReverse(reverseId: string): void {
    const subscriber: Subscription = this.resource.get(reverseId)
      .subscribe((reverse: IReverse) => {
        this.reverse = reverse;
        this.initModelsAndForm();
      }, undefined, () => subscriber.unsubscribe());
  }
  /**
   * Fetches reverse from order,
   * then inits models and forms.
   *
   * @param {string} order
   *
   * @memberof ReverseService
   */
  public fetchReverseFromOrder(order: string): void {
    const subscriber: Subscription = this.resource.getReverseFromOrder(order)
      .subscribe((reverse: IReverse) => {
        this.reverse = reverse;
        this.reverse.order = order;

        this.initModelsAndForm();
      }, undefined, () => subscriber.unsubscribe());
  }

  public initModelsAndForm(): void {
    this.populateModels(this.reverse);

    if (this.reverse && this.reverse.grcReason) {
      this.getChildren(this.reverse.grcReason);
    }

    this.createForm();

    if (this.reverseModel.reverseProducts) {
      this.createWorkshopsForm(this.reverseModel.reverseProducts);
    }

    this.isAllChecked(true, 'expected', 'isAllExpected');
    this.isAllChecked(true, 'withoutLabel', 'isAllWithoutLabel');
  }

  /**
   * Fetches reverse reasons.
   *
   * @private
   *
   * @memberof ReverseFormService
   */
  private fetchGrcReasons(): void {
    const subscriber: Subscription = this.grcReasonResource.cGet({'exists[parent]': false, 'active': 1}, { returnHydraMembers: true, blocking: false })
      .subscribe((response: IGrcReason[]) => {

        this.reasons = response.map((reason: IGrcReason) => {
          return {
            label: (reason.translations[SessionHelper.getUILanguage()] || reason.translations[LOCALE_FR]).reason,
            id: reason.id,
          };
        }).sort((a, b) => {
          if (a.label < b.label) { return -1; }
          if (a.label > b.label) { return 1; }
          return 0;
        });

      },  undefined, () => subscriber.unsubscribe());

    if (undefined !== this.reverseForm) {
      this.reverseForm.patchValue({ 'reverseReason': this.reasons });
    }

  }

  private getCountryCodesFromUser() {
    const countryCodes = [];
    for (const country of SessionHelper.get('CURRENT_USER_COUNTRIES')) {
      countryCodes.push(country.code);
    }
    return countryCodes;
  }

  private fetchWarehouses() {
    if (SessionHelper.useLinkedWarehousesOnly()) {
      const warehouseCodes = [];
      for (const warehouse of SessionHelper.getLinkedWarehouses()) {
        warehouseCodes.push({ label: warehouse.code, value: warehouse.code, id: warehouse.id });
      }
      this.warehouses = warehouseCodes;
    } else {
      const params: any = { 'carriers.country.code[]': [] };
      for (const countryCode of this.getCountryCodesFromUser()) {
        params['carriers.country.code[]'].push(countryCode);
      }

      this.warehouseResource.cGet(params).subscribe(
        (response: any) => {
          const warehouses = response['hydra:member'];
          const warehouseCodes = [];
          for (const warehouse of warehouses) {
            warehouseCodes.push({ label: warehouse.code, value: warehouse.code, id: warehouse.id });
          }
          this.warehouses = warehouseCodes;
          if (undefined !== this.reverseForm) {
            this.reverseForm.patchValue({ 'warehouse': this.getWarehouseFieldDefaultValue() });
          }
        });
    }
  }

  /**
   * Adds products corresponding to the @param skus with their packages form the @param skus to the reverse form
   *
   * @param {ISku[]} skus - the skus of the products we want to add to the forms
   *
   * @memberof ReverseFormService
   */
  public getReverseProductPackages(skus: ISku[]): void {
    skus.forEach((sku: ISku) => {
      this.addReverseProductFormToReverseForm(sku);

      const subscriber: Subscription = this.productResource
        .getProductPackages(sku.id || `${sku.sku}|${SessionHelper.getCountry().code}`, { dontUseModel: true })
        .subscribe((reverseProductPackages: IPackage[]) => {
          this.addReverseProductPackageToProductForm(sku, reverseProductPackages);
        }, undefined, () => {
          this.isAllChecked(true, 'expected', 'isAllExpected');
          this.isAllChecked(true, 'withoutLabel', 'isAllWithoutLabel');
          subscriber.unsubscribe();
        });
    });
  }

  /**
   * Checks/unchecks the ckeckbox that inform if all packages' @param property are checked or not
   *
   * @public
   *
   * @param {boolean} event - represents the value sent by ckeckbox.
   * @param {string} property - represents the property to be changed in the form group.
   * @param {string} allSwitch - represents the switch that checks all other switchs
   *
   * @memberof ReverseFormService
   */
  public isAllChecked(event: boolean, property: string, allSwitch: string): void {
    let flag = event;
    if (flag) {
      const reverseProducts: FormArray = <FormArray>this.reverseForm.get('reverseProducts');

      reverseProducts.controls.forEach((product: FormGroup) => {
        const reverseProductsPackages: FormArray = <FormArray>product.get('reverseProductPackages');

        reverseProductsPackages.controls.forEach((productPackage: FormGroup) => {
          if (!productPackage.get(property).value) {
            flag = false;
          }
        });
      });
    }
    this.reverseForm.patchValue({ [allSwitch]: flag });
  }

  /**
   * Checks/unchecks all ckeckboxes of a property passed in @param property of a package
   * Checks only if reconditionned status is =/= sage
   *
   * @public
   *
   * @param {boolean} isChecked - represents the value sent by ckeckbox.
   * @param {string} property - represents the property to be changed in the form group.
   *
   * @memberof ReverseFormService
   */
  public checkAllPackagesAs(isChecked: boolean, property: string): void {
    const reverseProducts: FormArray = <FormArray>this.reverseForm.get('reverseProducts');

    reverseProducts.controls.forEach((product: FormGroup) => {
      const reverseProductsPackages: FormArray = <FormArray>product.get('reverseProductPackages');

      reverseProductsPackages.controls.forEach((productPackage: FormGroup) => {
        if (!productPackage.value.isReconditionnedStatusReadOnly) {
          productPackage.patchValue({ [property]: isChecked });
        }
      });
    });
  }

  public hasPackagesChecked(reverseProduct: FormArray): boolean {
    return (<FormArray>reverseProduct.get('reverseProductPackages')).controls.some(rpp => rpp.value.expected === true);
  }

  /**
   * Populates reverse, reverseProduct, reverseProductPackage models.
   */
  private populateModels(reverse: IReverse): void {
    const reverseProductModels: IReverseProduct[] = [];

    if (undefined !== reverse && reverse.reverseProducts) {
      reverse.reverseProducts.forEach((reverseProduct: IReverseProduct) => {
        const reverseProductPackageModels: IReverseProductPackage[] = [];

        reverseProduct.reverseProductPackages.forEach((reverseProductPackage: IReverseProductPackage) => {
          reverseProductPackageModels.push(
            new ReverseProductPackage(reverseProductPackage, reverseProduct.id)
          );
        });

        reverseProductModels.push(new ReverseProduct(reverseProduct, reverseProductPackageModels, reverse.id));
      });
    }

    this.reverseModel = new Reverse(reverse, reverseProductModels);
  }

  /**
   * Creates the reverse form.
   */
  private createForm(): void {
    this.reverseForm = this.fb.group({
      date: moment(this.reverseModel.date, DATE_SHORT_INTERNATIONAL_FORMAT).isValid() ?
        moment(this.reverseModel.date, DATE_SHORT_INTERNATIONAL_FORMAT).toDate() :
        moment().toDate(),
      status: this.reverseModel.status || OPEN_STATUS,
      lastname: this.reverseModel.lastname || '',
      firstname: this.reverseModel.firstname || '',
      // @ts-ignore
      grcReason: [this.reverseModel.grcReason ? this.reverseModel.grcReason.id : '', Validators.required], // reverse reason case id || null
      order: this.state.params.order || this.reverseModel.order,
      reverseReason: [this.reverseModel.reverseReason ? this.reverseModel.reverseReason.id : '', Validators.required], // reverse reason case id || null
      zendDeskId: this.reverseModel.zendDeskId || '',
      comment: this.reverseModel.comment || '',
      isAllExpected: true, // checkbox reverse? all
      isAllWithoutLabel: true, // checkbox withoutLabel? all
      reverseProducts: this.fb.array([]),
      orderId: this.reverseModel.orderId,
      warehouse: this.getWarehouseFieldDefaultValue()
    });

    if (this.reverseModel.reverseProducts) {
      this.setReverseProductsForm(this.reverseModel.reverseProducts);
    }

    this.reverseForm.statusChanges.subscribe(() => {
      if (this.reverseForm.dirty) {
        this.formNotifier.notifyFormInEdition();
      }
    });
  }

  /**
   * Sets reverse products form array. It will be bind with the reverseForm.
   *
   * @param {IReverseProduct[]} reverseProducts
   */
  private setReverseProductsForm(reverseProducts: IReverseProduct[]): void {
    const reverseProductsFGs = reverseProducts.map((reverseProduct: IReverseProduct) => {
      let reverseProductForm: FormGroup;

      reverseProductForm = this.fb.group({
        id: reverseProduct.id,
        sku: reverseProduct.sku,
        orderNumber: reverseProduct.masterProductVersion ? reverseProduct.masterProductVersion.orderNumber : null,
        reason: reverseProduct.reason, // reverse product reason,
        comment: reverseProduct.comment, // reverse product comment,
        reverse: this.reverseModel.id,
        reverseProductPackages: this.fb.array([])
      });

      this.setReverseProductPackagesForm(reverseProduct.reverseProductPackages, reverseProduct.id, reverseProductForm);

      return reverseProductForm;
    });

    const reverseProductsFormArray = this.fb.array(reverseProductsFGs);

    this.reverseForm.setControl('reverseProducts', reverseProductsFormArray);
  }

  private createWorkshopsForm(reverseProducts: IReverseProduct[]): void {
    this.workshopForm = this.fb.group({});

    const formGroups = reverseProducts.map((reverseProduct: IReverseProduct) => {
      return this.fb.group({
        id: reverseProduct.id,
        sku: reverseProduct.sku,
        condition: reverseProduct.condition,
        analysis: reverseProduct.analysis,
        diagnosis: reverseProduct.diagnosis,
        action: reverseProduct.action,
        repairable: reverseProduct.repairable,
        repairedAt: reverseProduct.repairedAt,
        reparationTime: reverseProduct.reparationTime,
      });
    });

    this.workshopForm.setControl('reverseWorkshops', this.fb.array(formGroups));

    this.workshopForm.statusChanges.subscribe(() => {
      if (this.workshopForm.dirty) {
        this.formNotifier.notifyFormInEdition();
      }
    });
  }

  /**
   * Adds a reverseProductForm to the reverseForm
   *
   * @private
   *
   * @param {ISku} sku - the sku of the product to be added to the form
   *
   * @memberof ReverseFormService
   */
  private addReverseProductFormToReverseForm(sku: ISku): void {
    let reverseProductForm: FormGroup;

    reverseProductForm = this.fb.group({
      sku: sku.sku,
      orderNumber: '',
      reason: '', // reverse product reason
      reverse: this.reverseModel.id,
      reverseProductPackages: this.fb.array([]),
      comment: ''
    });

    this.reverseProducts.push(reverseProductForm);
  }

  /**
   * Sets reverse product packages form array. It will be bind with the reverseProductForm.
   *
   * @param {IReverseProductPackage[]} reverseProductPackages
   * @param {number} reverseProductId
   * @param {FormGroup} reverseProductForm
   */
  private setReverseProductPackagesForm(
    reverseProductPackages: IReverseProductPackage[],
    reverseProductId: number,
    reverseProductForm: FormGroup
  ) {
    const reverseProductPackagesFGs = reverseProductPackages.map((reverseProductPackage: IReverseProductPackage) => {
      let reverseProductPackageForm: FormGroup;

      reverseProductPackageForm = this.fb.group({
        id: reverseProductPackage.id,
        packageSku: reverseProductPackage.packageSku,
        packageNumber: reverseProductPackage.packageNumber,
        expected: reverseProductPackage.expected,
        deal: reverseProductPackage.deal,
        withoutLabel: reverseProductPackage.withoutLabel,
        receiveStatus: reverseProductPackage.receiveStatus,
        reconditionedStatus: reverseProductPackage.reconditionedStatus,
        isReconditionnedStatusReadOnly: reverseProductPackage.reconditionedStatus === RECONDITIONED_SAGE,
        damageReason: reverseProductPackage.damageReason,
        pictures: [reverseProductPackage.pictures],
        carrierGroup: reverseProductPackage.carrierGroup ? reverseProductPackage.carrierGroup.id : reverseProductPackage.carrierGroup,
        goneTracking: reverseProductPackage.goneTracking,
        returnTracking: reverseProductPackage.returnTracking,
        reverseProduct: reverseProductId,
        dateExportSage: reverseProductPackage.dateExportSage,
      });

      if (reverseProductPackageForm.value.isReconditionnedStatusReadOnly) {
        reverseProductPackageForm.get('withoutLabel').disable();
        reverseProductPackageForm.get('expected').disable();
        reverseProductPackageForm.get('deal').disable();
        reverseProductPackageForm.get('damageReason').disable();
        reverseProductPackageForm.get('carrierGroup').disable();
        reverseProductPackageForm.get('goneTracking').disable();
        reverseProductPackageForm.get('returnTracking').disable();
      }

      return reverseProductPackageForm;
    });

    const reverseProductPackagesFormArray = this.fb.array(reverseProductPackagesFGs);

    reverseProductForm.setControl('reverseProductPackages', reverseProductPackagesFormArray);
  }

  /**
   * Adds reverseProductPackageForm to the product with the same @param sku
   * Adds only if not already present
   *
   * @private
   * @param {ISku} sku - the sku of the product on which we want to add packages
   * @param {IPackage[]} reverseProductPackagesRaw - the information about the packages, fetched from api (raw state)
   * @memberof ReverseFormService
   */
  private addReverseProductPackageToProductForm(sku: ISku, reverseProductPackagesRaw: IPackage[]): void {
    reverseProductPackagesRaw.map((reverseProductPackageRaw: IPackage) => {
      let reverseProductPackageForm: FormGroup;

      reverseProductPackageForm = this.fb.group({
        packageSku: reverseProductPackageRaw.sku,
        packageNumber: reverseProductPackageRaw.number,
        expected: false,
        deal: false,
        withoutLabel: false,
        isReconditionnedStatusReadOnly: false,
        receiveStatus: RECEIVE_WAITING,
        reconditionedStatus: '',
        damageReason: '',
        pictures: [[]],
        carrierGroup: '',
        goneTracking: '',
        returnTracking: '',
        reverseProduct: sku.sku
      });

      this.reverseProducts.controls.forEach((reverseProduct: FormGroup) => {
        if (
          reverseProduct.value.sku === reverseProductPackageForm.value.reverseProduct &&
          reverseProduct.value.reverseProductPackages.length < reverseProductPackagesRaw.length
        ) {
          const reverseProductPackageFormArray: FormArray = <FormArray>reverseProduct.get('reverseProductPackages');
          reverseProductPackageFormArray.push(reverseProductPackageForm);
        }
      });
    });
  }

  /**
   * Creates an observable (via httpClient) of a reverse from a reverseId.
   * Submits all forms synchronously by looping over objects in order to keep the index of the forms for ids linking (package holds
   * product's id which holds reverse's id) and error handling.
   *
   * todo: Another solution would use Observable.forkJoin([observables], projectCallback) but needs to rework errorHandlerInterceptor.
   */
  public submitReverse(reverseId?: string, backToList?: boolean, backToOrder?: boolean): void {
    const productObservables: Observable<IReverseProduct>[] = [];
    const reverse$: Observable<IReverse> = reverseId ?
      this.resource.partialUpdate(reverseId, this.prepareQuery()) as Observable<IReverse> :
      this.resource.create(this.prepareQuery()) as Observable<IReverse>
      ;

    this.backToList = !!backToList;
    this.backToOrder = !this.backToList && !!backToOrder;

    const subscriber: Subscription = reverse$.subscribe((reverse: IReverse) => {
      this.submitReverseProducts(reverse, productObservables);
    }, (err: any) => {
      if (400 === err.code) {
        FormErrorHelper.fillFormControlWithErrors(this.reverseForm, err.errors);
      }
    }, () => subscriber.unsubscribe());
  }

  /**
   * Creates the array of reverseProducts (referencing their reverse) to create/update
   * Then
   * Submits the reverseProducts
   *
   * @private
   *
   * @param {IReverse} reverse
   * @param {Observable<IReverseProduct>[]} productObservables
   *
   * @memberof ReverseFormService
   */
  private submitReverseProducts(reverse: IReverse, productObservables: Observable<IReverseProduct>[]): void {
    let successMarker: number = 0;

    this.reverseProducts.controls.forEach((product: FormGroup) => {
      product.patchValue({ reverse: reverse.id });
      const product$ = this.createProduct$(product);
      productObservables.push(product$);
    });

    productObservables.forEach((product$, i: number) => {
      const productPackageObservables: Observable<IReverseProductPackage>[] = [];
      const reverseProductFG = this.reverseProducts.at(i) as FormGroup;

      const productSubscriber: Subscription = product$.subscribe((product: IReverseProduct) => {
        successMarker++;

        const areAllReverseProductsRequestsSuccessful = successMarker === productObservables.length;

        this.submitReverseProductPackages(
          reverseProductFG,
          product,
          productPackageObservables,
          reverse.id,
          areAllReverseProductsRequestsSuccessful
        );
      }, (err) => {
        FormErrorHelper.fillFormControlWithErrors(reverseProductFG, err.errors);
      }, () => productSubscriber.unsubscribe());
    });
  }

  /**
   * Adds the id control to the product received from API after subscription in @ReverseFormService.submitReverseProducts() (in order to
   * don't re-create same package but update).
   * Then
   * Creates the array of packages(referencing their product) to create/update
   * Then
   * Submits the reverse products packages
   * Then
   * Adds the id control to the package received from API after subscription (in order to don't re-create same product but update).
   */
  private submitReverseProductPackages(
    reverseProductFG: FormGroup,
    product: IReverseProduct,
    productPackageObservables: Observable<IReverseProductPackage>[],
    reverseId: number,
    areAllReverseProductsRequestsSuccessful: boolean
  ) {
    const packages: FormArray = reverseProductFG.get('reverseProductPackages') as FormArray;
    let successMarker: number = 0;

    reverseProductFG.addControl('id', new FormControl(product.id));

    packages.controls.forEach((productPackage: FormGroup) => {
      productPackage.patchValue({ reverseProduct: product.id });
      const productPackage$ = this.createProductPackage$(productPackage);
      if (productPackage$) {
        productPackageObservables.push(productPackage$);
      }
    });

    productPackageObservables.forEach((package$, j: number) => {
      const productPackagesFA: FormArray = reverseProductFG.get('reverseProductPackages') as FormArray;
      const productPackageFG: FormGroup = productPackagesFA.at(j) as FormGroup;

      const productPackageSubscriber: Subscription = package$.subscribe((productPackage: IReverseProductPackage) => {
        successMarker++;
        productPackageFG.addControl('id', new FormControl(productPackage.id));

        const areAllReverseProductPackagesRequestsSuccessful = successMarker === productPackageObservables.length;

        if (
          true === areAllReverseProductsRequestsSuccessful &&
          true === areAllReverseProductPackagesRequestsSuccessful
        ) {
          this.resource.updateReverseZendeskStatus(reverseId)
            .subscribe((reverse: IReverse) => {
              this.reverse = reverse;
              this.initModelsAndForm();

              this.formNotifier.notifyFormSubmitted();

              this.snackbar.validate(
                this.$translate.instant(this.state.params.id ? 'PAGE.REVERSE.EDIT.SUCCESS' : 'PAGE.REVERSE.NEW.SUCCESS')
              );

              this.backToList ?
                this.state.go('reverses.list') :
                this.backToOrder ?
                  this.state.go('order_manager.edit', { id: this.reverseModel.order }) :
                  this.state.go('reverses.edit', { id: reverseId })
              ;

              productPackageSubscriber.unsubscribe();
            });
        }
      }, (err) => {
        FormErrorHelper.fillFormControlWithErrors(productPackageFG, err.errors);
      });
    });
  }

  /**
   * Creates an observable (via httpClient) of a product from a reverseProduct FormGroup
   *
   * @private
   *
   * @param {FormGroup} reverseProduct - the reverse product form.
   *
   * @returns {(Observable<IReverseProduct>)} an observable that can resolve an IReverseProduct or an Error if something went wrong
   *
   * @memberof ReverseFormService
   */
  private createProduct$(reverseProduct: FormGroup): Observable<IReverseProduct> {
    let action$: Observable<IReverseProduct>;

    if (reverseProduct.value.id) {
      action$ = this.resource.updateReverseProduct(reverseProduct.value.id, this.prepareUpdateReverseProduct(reverseProduct, true));
    } else {
      action$ = this.resource.createReverseProduct(this.prepareUpdateReverseProduct(reverseProduct, false));
    }

    return action$;
  }

  /**
   * Creates an observable (via httpClient) of a package from a reverseProductPackage FormGroup
   *
   * @private
   *
   * @param {FormGroup} reverseProductPackage - the product package form
   *
   * @returns {(Observable<IReverseProductPackage>)} - an observable that can resolve an IReverseProductPackage
   *                                                   or an Error if something went wrong
   *
   * @memberof ReverseFormService
   */
  private createProductPackage$(reverseProductPackage: FormGroup): Observable<IReverseProductPackage> {
    let action$: Observable<IReverseProductPackage>;

    if (reverseProductPackage.value.id) {
      action$ = this.resource.updateReverseProductPackage(
        reverseProductPackage.value.id,
        this.prepareUpdateReverseProductPackage(reverseProductPackage, true)
      );
    } else {
      action$ = this.resource.createReverseProductPackage(
        this.prepareUpdateReverseProductPackage(reverseProductPackage, false)
      );
    }

    return action$;
  }

  /**
   * Removes unnecessary fields before sending the reverse.
   */
  private prepareQuery(): IReverse {
    const reverse: IReverse = { ...this.reverseForm.value };

    reverse.date = moment(reverse.date, DATE_SHORT_FORMAT).format(DATE_SHORT_INTERNATIONAL_FORMAT);

    delete reverse.reverseProducts;
    delete reverse.isAllWithoutLabel;
    delete reverse.isAllExpected;
    delete reverse.orderId;
    delete reverse.reverseReason;

    if (reverse.grcReason) {
      reverse.grcReason = HydraHelper.buildIri(reverse.grcReason, 'grc_reasons');
    }
    if (reverse.order) {
      reverse.order = HydraHelper.buildIri(reverse.order, 'orders');
    }
    if (reverse.warehouse) {
      reverse.warehouse = HydraHelper.buildIri(reverse.warehouse, 'warehouses');
    }

    return reverse;
  }

  /**
   * Removes unnecessary fields before sending the product.
   *
   * @private
   *
   * @param {FormGroup} reverseProduct
   * @param {boolean} isCreate
   *
   * @returns {{ [key: string]: any }}
   * @memberof ReverseFormService
   */
  private prepareUpdateReverseProduct(reverseProduct: FormGroup, isCreate: boolean): { [key: string]: any } {
    const product: { [key: string]: any } = _.cloneDeep(reverseProduct.value);

    delete product.reverseProductPackages;

    if (isCreate) {
      delete product.id;
    }
    delete product.orderNumber;

    return product;
  }

  /**
   * Removes unnecessary fields before sending the package.
   *
   * @private
   *
   * @param {FormGroup} reverseProductPackage
   * @param {boolean} isCreate
   *
   * @returns {{ [key: string]: any }}
   * @memberof ReverseFormService
   */
  private prepareUpdateReverseProductPackage(reverseProductPackage: FormGroup, isCreate: boolean): { [key: string]: any } {
    const productPackage: { [key: string]: any } = _.cloneDeep(reverseProductPackage.value);

    delete productPackage.isReconditionnedStatusReadOnly;

    if (productPackage.receiveStatus === undefined) {
      productPackage.receiveStatus = RECEIVE_WAITING;
    }

    if (productPackage.reconditionedStatus === undefined) {
      productPackage.reconditionedStatus = null;
    }

    const isValidCarrier = Object.values(this.carrierGroups).find((carrierGroup: any) => carrierGroup.id === productPackage.carrier);

    if (!isValidCarrier) {
      productPackage.carrier = null;
    }

    if (isCreate) {
      delete productPackage.id;
    }

    delete productPackage.dateExportSage;

    productPackage.pictures.forEach((pictureUrl: string, i: number) => {
      const lastSlashIndex = pictureUrl.lastIndexOf('/');

      productPackage.pictures[i] = pictureUrl.substring(lastSlashIndex + 1);
    });

    delete productPackage.withoutLabel;

    return productPackage;
  }

  private getWarehouseFieldDefaultValue(): string {
    if (this.reverseModel.warehouse) {
      return this.reverseModel.warehouse.id;
    }
    if (SessionHelper.useLinkedWarehousesOnly() && this.warehouses.length === 1) {
      return this.warehouses[0].id;
    }
    if (this.warehouses && this.warehouses.length > 0) {
      return (this.warehouses.find(warehouse => 'bureauneuf' === warehouse.value) || { id: null }).id;
    }

    return null;
  }

  public getChildren(grcReason: any) {
    this.grcReasonResource.cGet({'parent': grcReason.parent.id}, { returnHydraMembers: true, blocking: true })
      .subscribe((response: any) => {
        this.grcReasons = response.map((reason: IGrcReason) => {
          return {
            label: (reason.translations[SessionHelper.getUILanguage()] || reason.translations[LOCALE_FR]).reason,
            id: reason.id,
          };
        });
      });
  }

}
