import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { ServiceResource } from '@components/service/service.resource';
import { MarketplaceHelper, SessionHelper } from '@helpers';
import { ICountry, IMarketplace, ISku } from '@interfaces';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { INPUT_NUMBER_PATTERN_DEC } from '@constants';
import { IProductDeclination } from '@components/super-product/interfaces/super-product-form.interface';
import { MarketplaceResource } from '@resources';
import { IService } from '@components/service/model/service.interface';
import { HydraHelper } from '@helpers/HydraHelper';
import { SnackbarService } from '@components/snackbar';
import { IServiceTranslation } from '@components/service/model/service-translation.interface';
import { FormNotifierService } from '@services';
import { ServiceModel } from '@components/service/model/service.model';
import { CMSResource } from '@components/cms';
import { ICMS } from '@components/cms/models/cms.interface';

@Component({
  selector: 'app-service-form',
  template: require('./service-form.component.html'),
  providers: [
    { provide: AbstractResource, useClass: ServiceResource },
  ],
})
export class ServiceFormComponent extends AbstractPageComponent implements OnInit {

  public model: IService;
  public form: FormGroup;
  public marketplace: IMarketplace;
  public contentBlocks: ICMS[] = [];
  public currentCountry: ICountry = SessionHelper.getCountry();
  public currentLocales: string[] = SessionHelper.getCountry().locales;
  public productDeclinations: IProductDeclination[];
  public serviceTypes: any[] = [];

  private static parseFloat(value: any): number {
    return parseFloat(value.toString().replace(',', '.'));
  }

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    private marketplaceResource: MarketplaceResource,
    private cmsResource: CMSResource,
    private snackbar: SnackbarService,
    private formNotifier: FormNotifierService,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    this.model = new ServiceModel();
    this.buildForm();
    this.buildTranslationsForm();
    this.fetch();
  }

  private fetch(): void {
    this.fetchServiceTypes();
    this.fetchMarketplace();
    this.fetchContentBlocks();

    if (this.state.params.id) {
      this.fetchService();
    }
  }

  private fetchServiceTypes(): void {
    this.resource.getServiceTypes()
      .takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        for (const type of response) {
            this.serviceTypes.push({
              code: type,
              label: this.translate('PAGE.SERVICE.TYPES.' + type.toString().toUpperCase())
            });
        }
      })
    ;
  }

  private fetchMarketplace(): void {
    const websiteMarketplaces = SessionHelper.getMarketplaces().filter((item: IMarketplace) => {
      return MarketplaceHelper.isWebsiteMarketplace(item);
    });

    this.marketplaceResource
      .cGet({ code: websiteMarketplaces[0].code }, { entryPoint: '/v2/marketplaces', isHydra: true, returnHydraMembers: true })
      .takeUntil(this.destroyed$)
      .subscribe((results: IMarketplace[]) => {
        this.marketplace = results[0];
      })
    ;
  }

  private fetchContentBlocks(): void {
    this.cmsResource
      .cGet({ 'translations.active': 1 }, { returnHydraMembers: true })
      .takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        this.contentBlocks = response.map((item: any) => {
          if (!item.translations[this.currentLocale]) {
            return;
          }

          return {
            id: item.id,
            name: item.translations[this.currentLocale].name,
          };
        }).filter((item: any) => {
          return undefined !== item;
        });

        this.fillForm();
      })
    ;
  }

  private fetchService(): void {
    this.resource.get(this.state.params.id)
      .takeUntil(this.destroyed$)
      .subscribe((response: IService) => {
        this.model = response;
        this.buildTranslationsForm(this.model.translations);
        this.fillForm();
      })
    ;
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      type: [null, [Validators.required]],
      sku: [null, [Validators.required]],
      amount: [null, [Validators.required, Validators.pattern(INPUT_NUMBER_PATTERN_DEC)]],
      cost: [null, [Validators.required, Validators.pattern(INPUT_NUMBER_PATTERN_DEC)]],
      excludedProducts: null,
      includedProducts: null,
      productMinPrice: [null, [Validators.pattern(INPUT_NUMBER_PATTERN_DEC)]],
      productMaxPrice: [null, [Validators.pattern(INPUT_NUMBER_PATTERN_DEC)]],
      translations: this.formBuilder.array([]),
      contentBlock: null,
    });
  }

  private buildTranslationsForm(translations?: {[locale: string]: IServiceTranslation}): void {
    const formGroups = this.currentCountry.locales.map((locale) => {
      return this.formBuilder.group({
        title: [translations && translations[locale] ? translations[locale].title : '', [Validators.required]],
        description: [translations && translations[locale] ? translations[locale].description : '', [Validators.required]],
        contractTitle: [translations && translations[locale] ? translations[locale].contractTitle : ''],
        contractHref: [translations && translations[locale] ? translations[locale].contractHref : ''],
        informationSheetTitle: [translations && translations[locale] ? translations[locale].informationSheetTitle : ''],
        informationSheetHref: [translations && translations[locale] ? translations[locale].informationSheetHref : ''],
        ipidTitle: [translations && translations[locale] ? translations[locale].ipidTitle : ''],
        ipidHref: [translations && translations[locale] ? translations[locale].ipidHref : ''],
        locale
      });
    });

    this.form.setControl('translations', this.formBuilder.array(formGroups));
  }

  private fillForm(): void {
    const translations = this.model.translations;
    delete this.model.translations;
    this.form.patchValue(this.model);
    this.model.translations = translations;

    if (this.model.contentBlock) {
      this.form.get('contentBlock').setValue(this.contentBlocks.find(item => item.id === this.model.contentBlock.id));
    }

    if (this.model.productMarketplacesExcluded) {
      const excludedProducts = this.model.productMarketplacesExcluded.map((productMarketplace: any) => {
        return productMarketplace.id;
      });

      this.form.get('excludedProducts').setValue(excludedProducts);
    }

    if (this.model.productMarketplacesIncluded) {
      const includedProducts = this.model.productMarketplacesIncluded.map((productMarketplace: any) => {
        return productMarketplace.id;
      });

      this.form.get('includedProducts').setValue(includedProducts);
    }
  }

  public submit(event?: any): void {
    const data = this.form.value;
    data.excludedProducts = data.excludedProducts || [];
    data.includedProducts = data.includedProducts || [];

    const translations: {[locale: string]: IServiceTranslation} = {};

    data.translations.forEach((translation: IServiceTranslation) => {
      translations[translation.locale] = {
        title: translation.title,
        description: translation.description,
        contractTitle: translation.contractTitle,
        contractHref: translation.contractHref,
        informationSheetTitle: translation.informationSheetTitle,
        informationSheetHref: translation.informationSheetHref,
        ipidTitle: translation.ipidTitle,
        ipidHref: translation.ipidHref,
        locale: translation.locale,
      };

      if (this.model.translations && this.model.translations[translation.locale]) {
        const serviceTranslation: IServiceTranslation = this.model.translations[translation.locale];

        if (serviceTranslation.id) {
          translations[translation.locale].id = HydraHelper.buildIri(serviceTranslation, 'service_translations');
        }
      }
    });

    const body = {
      type: data.type,
      sku: data.sku,
      amount: ServiceFormComponent.parseFloat(data.amount),
      cost: ServiceFormComponent.parseFloat(data.cost),
      marketplace: this.marketplace['@id'],
      productMarketplacesExcluded: data.excludedProducts.map((product: ISku) => HydraHelper.buildIri(product.id, 'product_marketplaces')),
      productMarketplacesIncluded: data.includedProducts.map((product: ISku) => HydraHelper.buildIri(product.id, 'product_marketplaces')),
      productMinPrice: data.productMinPrice ? ServiceFormComponent.parseFloat(data.productMinPrice) : null,
      productMaxPrice: data.productMaxPrice ? ServiceFormComponent.parseFloat(data.productMaxPrice) : null,
      translations: translations,
      contentBlock: data.contentBlock ? HydraHelper.buildIri(data.contentBlock, 'content_blocks') : null
    };

    const observable = this.state.params.id
      ? this.resource.update(this.state.params.id, body)
      : this.resource.create(body)
    ;

    observable
      .takeUntil(this.destroyed$)
      .subscribe((response: IService) => {
        this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));

        if (event && event.redirect) {
          this.state.go(`${this.resource.routeName}.list`);
          return;
        }

        this.formNotifier.notifyFormSubmitted();

        if (!this.state.params.id) {
          this.state.go(`${this.resource.routeName}.edit`, { id: response.id });
        }
      })
    ;
  }

  public hasSeveralTranslations(): boolean {
    return this.currentCountry.locales.length > 1;
  }

  public getTranslation(key?: string): FormGroup {
    const translations: FormArray = this.form.get('translations') as FormArray;

    if (this.hasSeveralTranslations() && undefined !== key) {
      return translations.controls.find((group: FormGroup) => key === group.get('locale').value) as FormGroup;
    }

    return translations.controls[0] as FormGroup;
  }
}
