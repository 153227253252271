'use strict'

###
  @name FrozenTableHeader

  @description
  Freeze table header firsts row and column

  @example
    <example>
      <div frozen-table-header="myCmp.isFrozenTableActive">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Some header</th>
              <th>Some other header</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Some header</th>
              <td>Some value</td>
              <td>Some other value</td>
            </tr>
          </tbody>
        </table>
      </div>
    </example>
###
angular
  .module('ammsFrontendApp')
  .directive('frozenTableHeader', [
    '$timeout',
    ($timeout) ->
      replace: true
      restrict: 'A'
      link: (scope, container, attributes) ->
        scope.$watch(attributes.frozenTableHeader, (value) ->
          if (value && !container.hasClass('frozen-table-header'))
            $timeout(->
              container.addClass('frozen-table-header')
              table = container.children('table')

              # header column clone
              columnCloned = $('<table/>')
                .attr('class', table.attr('class'))
                .addClass('column-cloned')
              columnHead = $('<thead/>')
                .appendTo(columnCloned)
              columnBody = $('<tbody/>')
                .appendTo(columnCloned)
              $('thead tr th:first-child', table).each((index, cell) ->
                $('<tr/>')
                  .attr('class', $(cell).parent().attr('class'))
                  .append($(cell).clone())
                  .appendTo(columnHead)
              )
              $('tbody tr th:first-child', table).each((index, cell) ->
                $('<tr/>')
                  .attr('class', $(cell).parent().attr('class'))
                  .append($(cell).clone())
                  .appendTo(columnBody)
              )
              container.append(columnCloned)

              # header row clone
              rowCloned = $('<table/>')
                .attr('style', 'width: ' + table.innerWidth() + 'px !important;')
                .attr('class', table.attr('class'))
                .addClass('row-cloned')
                .append(
                  $('<thead />')
                    .append($('thead tr', table).clone())
                )
              container.append(rowCloned)
              oldHeaderList = $('thead th', table)

              $('thead th', rowCloned).each((index, item) ->
                $(item).attr('style', 'width: ' + $(oldHeaderList[index]).innerWidth() + 'px !important;')
              )

              # corner clone
              cornerCloned = $('<table/>')
                .attr('class', table.attr('class'))
                .addClass('corner-cloned')
                .attr('style', 'width: ' + $('thead tr th:first-child', table).innerWidth() + 'px !important;')
              cornerHead = $('<thead/>')
                .appendTo(cornerCloned)
              $('thead tr:first-child th:first-child', table).each((index, cell) ->
                $('<tr/>')
                  .attr('class', $(cell).parent().attr('class'))
                  .append($(cell).clone())
                  .appendTo(cornerHead)
              )
              container.append(cornerCloned)

              # scale table to fit available size
              screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
              unshrinkableUI = 300
              container.css({ maxHeight: (screenHeight - unshrinkableUI) + 'px' })

              # apply listener on scroll to repercute change to cloned headers
              container.on('scroll', ->
                rowCloned.css({ transform: 'translate3d(0, ' + container.scrollTop() + 'px, 0)' })
                columnCloned.css({ transform: 'translate3d(' + container.scrollLeft() + 'px, 0, 0)' })
                cornerCloned.css({ transform: 'translate3d(' + container.scrollLeft() + 'px, ' + container.scrollTop() + 'px, 0)' })
              )

              # unbind scroll event when component is destroyed
              scope.$on('$destroy', ->
                container.off('scroll')
              )
            )
        )
  ])
