import { Injectable } from '@angular/core';
import { Resource } from '@decorators/Resource';
import { AbstractResource } from '@resources/abstract.resource';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {$t} from 'codelyzer/angular/styles/chars';

@Injectable()
@Resource({
  entryPoint: '/v2/litigations',
  isHydra: true,
  cGetDefaultFilters: () => ({
    archived: '0',
  })
})
export class LitigationResource extends AbstractResource {

  constructor(http: HttpClient) {
    super(http);
  }

  public downloadInvoice(id: number, type: string): Observable<Object> {
    const url = this.getPath(`${this.entryPoint}/${id}/${type}`);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.get(url, {
      headers: headers,
      responseType: 'blob'
    });
  }

  public downloadAllInvoices(id: number): Observable<Object> {
    const url = this.getPath(`${this.entryPoint}/${id}/invoices`);
    const headers = new HttpHeaders({
      'Content-Type': 'application/zip'
    });

    return this.http.get(url, {
      headers: headers,
      responseType: 'blob'
    });
  }

  public createPayment(amount: number, date: any, litigations: string[]): Observable<Object> {
    const body: any = {
      amount: amount,
      date: date,
      litigations: litigations
    };

    return this.create(body, {entryPoint: '/v2/litigation_payments'});
  }
}
