import { ICMS, ICMSTranslations } from '@components/cms/models/cms.interface';
import { SessionHelper } from '@helpers/session.helper';

export class CMSListModel implements ICMS {
  '@id': string;
  id: string;
  translations: {
    [long_locale: string]: ICMSTranslations;
  };
  createdAt: string;
  updatedAt: string;
  name?: string;
  slug?: string;
  status?: string;

  constructor(cms: ICMS) {
    this['@id'] = cms['@id'];
    this.id = cms.id;
    this.translations = cms.translations;
    this.createdAt = cms.createdAt;
    this.updatedAt = cms.updatedAt;
    this.name = cms.translations[SessionHelper.getLocale()]
      ? cms.translations[SessionHelper.getLocale()].name
      : 'PAGE.CMS.LIST.TABLE.CONTENT.NO_TITLE';
    this.slug = cms.translations[SessionHelper.getLocale()]
      ? cms.translations[SessionHelper.getLocale()].slug
      : 'PAGE.CMS.LIST.TABLE.CONTENT.NO_SLUG';
    this.status = cms.translations[SessionHelper.getLocale()]
      ? (cms.translations[SessionHelper.getLocale()].active ? 'PAGE.CMS.LIST.TABLE.CONTENT.ACTIVE' : 'PAGE.CMS.LIST.TABLE.CONTENT.INACTIVE')
      : 'PAGE.CMS.LIST.TABLE.CONTENT.INACTIVE';
  }
}
