import { AbstractResource } from './abstract.resource';
import { Injectable } from '@angular/core';
import { Resource } from '../decorators/Resource';
import { HttpClient } from '@angular/common/http';
import { MarketplaceHelper } from '@helpers';

@Injectable()
@Resource({
  entryPoint: '/v2/rooms',
  isHydra: true,
  routeName: 'room',
  cGetDefaultFilters: () => ({ 'marketplace.code': MarketplaceHelper.getWebsiteMarketplace().code }),
})

export class RoomResource extends AbstractResource {
  constructor(http: HttpClient) {
    super(http);
  }
}
