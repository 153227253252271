import { AbstractResource } from '@resources/abstract.resource';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { SessionHelper } from '@helpers';
import { OneyOpcModel } from '@components/oney-opc/models/oney-opc.model';


@Injectable()
@Resource({
  routeName: 'oney-opc',
  entryPoint: '/v2/oney_opcs',
  isHydra: true,
  translationKey: 'ONEY_OPC',
  listModel: OneyOpcModel,
  listColumns: [
    { field: 'locale', translationKey: 'LOCALE' },
    { field: 'code', translationKey: 'CODE' },
    { field: 'label', translationKey: 'TYPE', translateValueFrom: 'PAGE.ONEY_OPC.LIST.TABLE.ROW' },
    { field: 'active', translationKey: 'ACTIVE', type: 'boolean' },
    { field: 'startDate', translationKey: 'START_DATE' },
    { field: 'endDate', translationKey: 'END_DATE' },
  ],
  createAvailable: true,
  cGetDefaultFilters: () => ({
    'locale[]': SessionHelper.getCountry().locales,
  }),
})
export class OneyOpcResource extends AbstractResource {

  protected nullableProperties: string[] = ['startDate', 'endDate'];

  constructor(http: HttpClient) {
    super(http);
  }
}
