import { Inject, Injectable } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { AbstractComponent } from '@components/generic/abstract.component';
import { IDialogBoxService, IDialogOptions } from '@services/dialog-box.service.interface';
import { AuthService } from '@services';
import { IResource } from '@interfaces/IResource';

const DIALOG_WIDTH: number = 450;
const DIALOG_MIN_WIDTH: number = 250;

@Injectable()
export class DialogBoxService extends AbstractComponent implements IDialogBoxService {
  public constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private dialogService: DialogService,
  ) {
    super($translate, authService, null, state);
  }

  public alert(
    options: IDialogOptions = {
      title: '',
      content: '',
    }): DialogRef {
    let cancelBtn = this.getCancelBtn();
    let confirmBtn = this.getConfirmBtn();

    if (options.actions) {
      cancelBtn = this.getCancelBtn(options.actions.cancel ? options.actions.cancel : undefined);
      confirmBtn = this.getConfirmBtn(options.actions.confirm ? options.actions.confirm : undefined);
    }

    const actions: {text: string, primary?: boolean}[] = [
      ...(cancelBtn ? cancelBtn : []),
      ...(confirmBtn ? confirmBtn : [])
    ];

    const dialogOptions = {
      title: options.title || this.translate('DIALOG.TITLE.WARNING'),
      content: options.content,
      actions,
      width: DIALOG_WIDTH,
      minWidth: DIALOG_MIN_WIDTH,
    };

    return this.dialogService.open(dialogOptions);
  }

  private getCancelBtn(
    {enabled = true, text = this.translate('DIALOG.BUTTON.CANCEL')}: {enabled?: boolean, text?: string} = {enabled, text}
  ): {text: string}|false {
    if (!enabled) {
      return false;
    }

    return {text};
  }

  private getConfirmBtn(
    {enabled = true, text = this.translate('DIALOG.BUTTON.CONFIRM')}: {enabled?: boolean, text?: string} = {enabled, text}
  ): {text: string, primary: boolean}|false {
    if (!enabled) {
      return false;
    }

    return {
      text,
      primary: true
    };
  }
}
