'use strict'

angular
  .module 'ammsFrontendApp'
  .directive 'flag', [ ->
    replace: true
    restrict: 'E'
    scope:
      country: '='
    templateUrl: require('views/directives/flag.html')
  ]
