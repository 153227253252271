import { Component, Inject, OnInit } from '@angular/core';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { AuthService} from '@services/auth.service';
import { BannerResource } from '@components/banner/banner.resource';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import {
  BannerModel,
  defaultBgColor,
  defaultFontColor,
  IBanner,
  IContentFormTranslationsValue,
  IContentFormValue
} from '@components/banner';
import { AbstractResource } from '@resources/abstract.resource';
import { SnackbarService } from '@components/snackbar';
import { SessionHelper } from '@helpers/session.helper';
import { EDITION_PAGE } from '@interfaces/IPageComponent';
import { FormNotifierService } from '@services/form-notifier.service';
import { ColorHelper } from '@helpers/ColorHelper';
import { DATE_FULL_FORMAT, DATE_SHORT_FORMAT } from '@constants/date';
import * as moment from 'moment';
import {BannerTypeRessource} from '@resources/banner-type.ressource';
import {BannerTypeModel} from '@components/banner/banner-type.model';
import {HydraHelper} from '@helpers/HydraHelper';

@Component({
  selector: 'app-banner-form',
  template: require('./banner-form.component.html'),
  styles: [require('./banner-form.component.scss')],
  providers: [
    { provide: AbstractResource, useClass: BannerResource},
    BannerTypeRessource
  ],
})
export class BannerFormComponent extends AbstractPageComponent implements OnInit {

  public form: FormGroup;
  public currentLocales: string[] = SessionHelper.getCountry().locales;
  public model: IBanner;
  public startTime: Date;
  public endTime: Date;
  public bannerStatuses: BannerTypeModel[];

  get translationsFA(): FormArray {
    return this.form.get('translations') as FormArray;
  }

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: BannerResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    private snackbar: SnackbarService,
    @Inject('DialogService') private dialog: any,
    private formNotifier: FormNotifierService,
    private bannerTypeRessource: BannerTypeRessource

  ) {
    super($translate, authService, resource, state);
  }

  /**
   * @inheritDoc
   */
  ngOnInit(): void {
    if (EDITION_PAGE === this.pageType) {
      this.resource.get(this.state.params.id, { model: BannerModel })
        .takeUntil(this.destroyed$)
        .subscribe((response: IBanner) => {
          this.model = response;
          this.startTime = this.model.startDate ? new Date(this.model.startDate) : null;
          this.endTime = this.model.endDate ? new Date(this.model.endDate) : null;

          this.buildForm();
        })
      ;

      return;
    }

    this.buildForm();
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      active: [this.model ? this.model.active : false],
      timerDisplayed: [this.model ? this.model.timerDisplayed : false],
      // tslint:disable-next-line:max-line-length
      bannerTypes: [this.model ? this.model.bannerTypes.map((item: any) =>  new BannerTypeModel(item)) : false],
      startDate: [this.model && this.model.startDate ? moment(this.model.startDate).format(DATE_SHORT_FORMAT) : null],
      endDate: [this.model && this.model.endDate ? moment(this.model.endDate).format(DATE_SHORT_FORMAT) : null],
      fontColor: [this.model ? this.model.fontColor : defaultFontColor],
      bgColor: [this.model ? this.model.bgColor : defaultBgColor],
      translations: this.formBuilder.array([])
    });
    this.bannerTypeRessource.getMany(undefined, { blocking: false, dontUseModel: true, isHydra: true }).subscribe((data: any) => {
      data.forEach((e: any) => e.code = this.translate('PAGE.BANNER.FORM.LABEL.' + e.code.toUpperCase()));
      this.bannerStatuses = data;
    });
    this.buildTranslationsForm();
  }

  private buildTranslationsForm(): void {
    // build several form group for each country locales
    const formGroups = this.currentLocales.map((locale) => {
      return this.formBuilder.group({
        link: [this.model && this.model.translations && this.model.translations[locale] ? this.model.translations[locale].link : ''],
        content: [this.model && this.model.translations && this.model.translations[locale] ? this.model.translations[locale].content : ''],
        locale,
      });
    });

    // pass the form groups to a form array
    const formArray = this.formBuilder.array(formGroups);

    // replace existing control with a new one, we pass it the form array that is a form group collection
    this.form.setControl('translations', formArray);
  }

  /**
   * Checks if the current country has several translations.
   */
  public hasSeveralTranslations(): boolean {
    return this.currentLocales.length > 1;
  }

  public submit(): void {
    this.dialog.confirm(
      this.translate(EDITION_PAGE === this.pageType ? 'PAGE.BANNER.FORM.CONFIRM.UPDATE' : 'PAGE.BANNER.FORM.CONFIRM.SAVE')
    )
      .then(() => {
        const body: IBanner = this.prepareBody();
        const observable$ = EDITION_PAGE === this.pageType ?
          this.resource.update(this.state.params.id, body) :
          this.resource.create(body)
        ;

        observable$
          .takeUntil(this.destroyed$)
          .subscribe((response: IBanner) => {
            this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));

            this.formNotifier.notifyFormSubmitted();
            this.state.go(`${this.resource.routeName}.edit`, { id: response.id });
          })
        ;
      })
    ;
  }

  private prepareBody(): IBanner {
    const formValue: IContentFormValue = this.form.value;
    const translations: { [locale: string]: IContentFormTranslationsValue } = {};
    let startDate = null;
    let endDate = null;

    if (formValue.startDate) {
      if (this.startTime) {
        startDate = moment(formValue.startDate, DATE_SHORT_FORMAT)
          .add(this.startTime.getHours(), 'hours')
          .add(this.startTime.getMinutes(), 'minutes')
          .add(this.startTime.getSeconds(), 'seconds')
          .format(DATE_FULL_FORMAT)
        ;
      } else {
        startDate = moment(formValue.startDate, DATE_SHORT_FORMAT).startOf('day').format(DATE_FULL_FORMAT);
      }
    }

    if (formValue.endDate) {
      if (this.endTime) {
        endDate = moment(formValue.endDate, DATE_SHORT_FORMAT)
          .add(this.endTime.getHours(), 'hours')
          .add(this.endTime.getMinutes(), 'minutes')
          .add(this.endTime.getSeconds(), 'seconds')
          .format(DATE_FULL_FORMAT)
        ;
      } else {
        endDate = moment(formValue.endDate, DATE_SHORT_FORMAT).endOf('day').format(DATE_FULL_FORMAT);
      }
    }
    const bannerTypes = formValue.bannerTypes.map((item: any) => {
       return item['@id'];
    });

    this.translationsFA.controls.forEach((formGroup: FormGroup) => {
      const translationsFormValue: IContentFormTranslationsValue = formGroup.value;
      const locale: string = translationsFormValue.locale;

      translations[locale] = translationsFormValue;

      if (EDITION_PAGE === this.pageType) {
        translations[locale].id = `/api/v2/banner_translations/${this.model.translations[locale].id}`;
      }
    });

    return {
      active: formValue.active,
      timerDisplayed: formValue.timerDisplayed,
      startDate,
      endDate,
      bannerTypes,
      fontColor: ColorHelper.convertHex8ToRgba(formValue.fontColor),
      bgColor: ColorHelper.convertHex8ToRgba(formValue.bgColor),
      translations,
    };
  }
}
