import { IWarehouses } from '@components/warehouses/models';
import { IMasterProductsWarehouses } from '@models/master-products-warehouses.interface';
import {IAddressing} from '@models/addressing.interface';

export class MasterProductsWarehousesModel implements IMasterProductsWarehouses {
  id: number;
  masterProduct: string;
  warehouse: IWarehouses;
  cmup: Number;
  defaultWarehouse: boolean;
  addressings: IAddressing;

  constructor(masterProductsWarehouses: IMasterProductsWarehouses) {
    this.id = masterProductsWarehouses.id;
    this.masterProduct = masterProductsWarehouses.masterProduct;
    this.warehouse = masterProductsWarehouses.warehouse;
    this.cmup = masterProductsWarehouses.cmup;
    this.defaultWarehouse = masterProductsWarehouses.defaultWarehouse;
    this.addressings = masterProductsWarehouses.addressings;
  }
}
