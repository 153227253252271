import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { AbstractResource } from '@resources';
import { PaymentCarrierIconsModel } from '@components/payment-carrier-icons/models';

@Injectable()
@Resource({
  entryPoint: '/configuration/carrieritems',
  updateAvailable: false,
  routeName: 'paymentCarrierIcons',
  model: PaymentCarrierIconsModel,

})
export class CarrierIconsResource extends AbstractResource {}
