import { Component, Inject, OnInit } from '@angular/core';
import { ReverseResource } from './resources';
import { ReverseFormService } from './services';
import { AuthService } from '@services';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { SkuSearchService } from '@services/sku-search.service';
import {SnackbarService} from '@components/snackbar';

@Component({
  selector: 'app-reverse-case',
  template: require('./reverse-case.component.html'),
  styles: [require('./reverse-case.component.scss')],
  providers: [
    ReverseResource,
    ReverseFormService,
    SkuSearchService,
  ]
})
export class ReverseCaseComponent extends AbstractPageComponent implements OnInit {

  protected reverseId: string = '';

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: ReverseResource,
    @Inject('StateService') state: ng.ui.IStateService,
    @Inject('DialogService') private dialogService: any,
    public reverseFormService: ReverseFormService,
    private reverseResource: ReverseResource,
    private snackbar: SnackbarService,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    this.reverseId = this.state.params.id;

    // edition
    if (this.reverseId) {
      this.reverseFormService.fetchReverse(this.reverseId);

      return;
    }

    // creation with autofill from order
    if (this.state.params.order) {
      this.reverseFormService.fetchReverseFromOrder(this.state.params.order);

      return;
    }

    // case creation
    this.reverseFormService.initModelsAndForm();
  }

  protected submit(): void {
    if (this.reverseId) {
      this.reverseFormService.submitReverse(this.reverseId);

      return;
    }

    this.reverseFormService.submitReverse();
  }

  protected submitAndList(): void {
    this.reverseFormService.submitReverse(this.reverseId, true);
  }

  /**
   * Gets the original reverse case and re-initialises the form.
   */
  protected cancel(): void {
    this.reverseFormService.fetchReverse(this.reverseId);
  }

  protected submitAndOrder(): void {
    this.reverseFormService.submitReverse(this.reverseId, false, true);
  }
  protected backToOrder(orderId: string): void {
    this.state.go('order_manager.edit', {id: orderId});
  }
  protected onCloseReverse() {
    this.dialogService.confirm(
      this.translate('ALERTS.REVERSE.CANCEL')
    )
      .then(() => {
        this.reverseResource.closeReverse(this.reverseId, true)
          .takeUntil(this.destroyed$)
          .subscribe(() => {
              this.snackbar.validate(this.translate('ALERTS.REVERSE.ARCHIVED'));
              setTimeout(() => {
                this.state.go('reverses.edit', {id: this.reverseId}, { reload: true });
              }, 1000);
          }
          );
      });
  }

  protected onReopenReverse() {
    this.dialogService.confirm(
      this.translate('ALERTS.REVERSE.REOPEN')
    )
      .then(() => {
        this.reverseResource.closeReverse(this.reverseId, false)
          .takeUntil(this.destroyed$)
          .subscribe(() => {
            this.snackbar.validate(this.translate('ALERTS.REVERSE.DESARCHIVED'));
            setTimeout(() => {
              this.state.go('reverses.edit', {id: this.reverseId}, { reload: true });
            }, 1000);
            }
          );
      });
  }
}
