import { Injectable } from '@angular/core';
import { AbstractResource } from '../../../resources/abstract.resource';
import { TableComponent } from '@components/generic/List/show-details/table';
import { ExportLogisticsHistoryModel } from '@components/export-logistics/export-logistics-history/models/export-logistics-history.model';
import { SessionHelper } from '../../../helpers/session.helper';
import { Resource } from '@decorators/Resource';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  entryPoint: '/v2/logistics',
  translationKey: 'EXPORT_LOGISTICS.HISTORY',
  model: ExportLogisticsHistoryModel,
  cGetDefaultFilters: () => ({'country_codes[]': SessionHelper.getCountries().map(country => country.code)}),
  listColumns: [
    { field: 'countries', translationKey: 'COUNTRIES', type: 'string' },
    { field: 'id', translationKey: 'ID', type: 'string' },
    { field: 'warehouses', translationKey: 'WAREHOUSES', type: 'string' },
    { field: 'numberOfPackages', translationKey: 'NUMBER_OF_PACKAGES', type: 'string' },
    { field: 'createdAt', translationKey: 'CREATED_AT', type: 'string' },
    { field: 'endedAt', translationKey: 'ENDED_AT', type: 'string' },
    { field: 'automatic', translationKey: 'AUTOMATIC_EXPORT', type: 'string' },
    { field: 'volume', translationKey: 'VOLUME', type: 'string' },
  ],
  createAvailable: false,
  deleteAvailable: false,
  updateAvailable: false,
  buttonActionMultipleAvailable: true,
  isHydra: true,
  showDetailsComponent: TableComponent,
})
export class ExportLogisticsHistoryResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }
}
