import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskComponent, TaskCategoryComponent, TaskManagerComponent } from './task-manager';
import {
  TaskAdditionalPropertyValueResource,
  TaskCategoryResource,
  TaskCommentResource,
  TaskResource,
  TaskStatusResource,
  TaskTypeResource
} from './task-manager/resources';
import { ReactiveFormsModule } from '@angular/forms';
import { GenericFormModule, GenericModule } from './';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { TaskAttachmentsComponent } from '@components/generic/task-manager/attachments';

@NgModule({
  declarations: [
    TaskManagerComponent,
    TaskCategoryComponent,
    TaskComponent,
    TaskAttachmentsComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    GenericModule,
    DropDownsModule,
    GenericFormModule
  ],
  exports: [
    TaskManagerComponent,
  ],
  providers: [
    TaskCategoryResource,
    TaskResource,
    TaskStatusResource,
    TaskCommentResource,
    TaskAdditionalPropertyValueResource,
    TaskTypeResource,
  ],
})
export class TaskManagerModule {}
