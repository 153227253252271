import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopoverModule } from 'ngx-bootstrap';
import { LanguageInterfaceComponent } from '@components/application/components/language-interface.component';

@NgModule({
  declarations: [LanguageInterfaceComponent],
  entryComponents: [LanguageInterfaceComponent],
  imports: [
    CommonModule,
    PopoverModule,
  ],
  exports: [
    LanguageInterfaceComponent,
  ]
})
export class ApplicationModule {}
