import { SessionHelper } from '@helpers';
import { Session } from 'inspector';
import { CategorySatelliteTranslationModel, ICategories } from '.';
import { ICategorySatellite, ICategorySatelliteTranslation } from './category-satellite.interface';

export class CategorySatelliteListModel implements ICategorySatellite, ICategorySatelliteTranslation {
  public '@id': string;
  public id: number;
  public referenceCategory: ICategories | string;
  public active: boolean;
  public translations: { [key: string]: ICategorySatelliteTranslation };
  public seoMetaRobot: string;
  public attributeFamilies: string[];
  public name?: string;
  public shortDescription: string;
  public longDescription: string;
  public metaDescriptionSeo?: string;
  public titleSeo?: string;
  public locale?: string;
  public minPrice?: number;
  public maxPrice?: number;

  constructor(categorySatellite: ICategorySatellite = {
    '@id': '',
    id: 0,
    referenceCategory: null,
    translations: null,
    attributeFamilies: [],
    minPrice: null,
    maxPrice: null
  }) {
    this.translations = this.transformTranslations(categorySatellite.translations);
    const currentLocaleTranslation = this.getCurrentLocaleTranslation(this.translations);
    for (const key in currentLocaleTranslation) {
      if (Object.prototype.hasOwnProperty.call(currentLocaleTranslation, key)) {
        Object.defineProperty(this, key, {
          enumerable: true,
          configurable: true,
          writable: true,
          value: currentLocaleTranslation[key]
        });
      }
    }
    this['@id'] = categorySatellite['@id'];
    this.id = categorySatellite.id;
    this.referenceCategory = categorySatellite.referenceCategory;
    this.attributeFamilies = categorySatellite.attributeFamilies;
    this.minPrice = categorySatellite.minPrice;
    this.maxPrice = categorySatellite.maxPrice;
  }

  private transformTranslations(translations: { [key: string]: ICategorySatelliteTranslation }) {
    for (const locale in translations) {
      if (Object.prototype.hasOwnProperty.call(translations, locale)) {
        translations[locale] = new CategorySatelliteTranslationModel(translations[locale]);
      }
    }

    return translations;
  }

  private getCurrentLocaleTranslation(translations: { [key: string]: ICategorySatelliteTranslation }) {
    return translations[SessionHelper.getLocale()];
  }
}
