import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { HttpClient } from '@angular/common/http';
import { AbstractResource } from '@resources/abstract.resource';

@Injectable()
@Resource({
  routeName: 'websiteHomepageBargains',
  entryPoint: '/v2/homepage_block_bargains',
  isHydra: true,
})
export class WebsiteHomepageBargainsResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }
}
