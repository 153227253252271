import {Component, Inject, OnInit} from '@angular/core';
import {AbstractPageComponent} from '@components/generic/abstract-page.component';
import {AbstractResource} from '@resources';
import {AuthService} from '@services';
import {UnpairedResource} from '@components/unpaired/unpaired.resource';
import {AbstractFiltersFieldsService} from '@components/generic/Form/filters/abstract-filters-fields.service';
import {UnpairedFiltersFormService} from '@components/unpaired/unpaired-filters-form.service';

@Component({
  selector: 'app-unpaired-list',
  template: require('./unpaired-list.component.html'),
  providers: [
    { provide: AbstractResource, useClass: UnpairedResource },
    { provide: AbstractFiltersFieldsService, useClass: UnpairedFiltersFormService },
  ]
})
export class UnpairedListComponent extends AbstractPageComponent {

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService
  ) {
    super($translate, authService, resource, state);
  }
}
