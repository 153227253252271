import { Component, Inject, OnInit } from '@angular/core';
import { SessionHelper } from '@helpers';
import { AuthService } from '@services';
import { IRevenues } from './models/revenues.interface';
import { DashboardResource } from '@components/dashboard/dashboard.resource';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { RevenuesModel } from '@components/dashboard/models/revenues.model';
@Component({
  selector: 'app-dashboard',
  template: require('./dashboard.component.html')
})
export class DashboardComponent extends AbstractPageComponent implements OnInit {

  public revenues: IRevenues;

  public constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: DashboardResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }

  public ngOnInit(): void {

    if (!SessionHelper.getCurrentUserRoles().some(role => role === 'ROLE_WALISOFT_AGENT')) {
      if (SessionHelper.isCurrentUserReverseExternal() && !SessionHelper.getCurrentUserRoles().some(role => role === 'ROLE_WALISOFT_WAREHOUSE_AGENT')) {
        this.state.go('order_manager_light.list');
        return;
      }
      this.state.go('spare-part.list');
      return;
    }

    this.resource.getRevenuesByCountry(SessionHelper.getCountry().code)
      .takeUntil(this.destroyed$)
      .subscribe((response: IRevenues) => this.revenues = new RevenuesModel(response))
      ;
  }
}
