import { AbstractComponent } from '@components/generic/abstract.component';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { AuthService } from '@services';
import { AbstractResource } from '@resources';
import { IReverse } from '@components';
import { ReverseFormService } from '@components/reverse/services';

@Component({
  selector: 'app-order-manager-reverse',
  template: require('./order-manager-reverse.component.html'),
  styles: [require('./order-manager-reverse.component.scss')],
})
export class OrderManagerReverseComponent extends AbstractComponent implements OnInit {

  @Input() public reverse?: IReverse;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    public reverseFormService: ReverseFormService,
  ) {
    super($translate, authService, resource, state);
  }

  public ngOnInit() {
    if (this.reverse) {
      this.state.go('reverses.edit', {id: this.reverse.id});
    }
  }
}
