import { AbstractComponent } from '@components/generic/abstract.component';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { ShipmentResource } from '@resources/shipment.resource';
import { ShipmentInterface } from '@components/order-manager/interfaces/shipment.interface';
import { OrderManagerResource } from '../../order-manager.resource';
import { takeUntil } from 'rxjs/operators';
import { IndicatorInterface } from '@interfaces';

@Component({
  selector: 'app-order-manager-shipping-form',
  template: require('./order-manager-shipping-form.component.html'),
  styles: [require('./order-manager-shipping-form.component.scss')],
  providers: [
    { provide: AbstractResource, useClass: ShipmentResource },
  ],
})
export class OrderManagerShippingFormComponent extends AbstractComponent implements OnInit {

  @Input() order: any;

  public shipments: ShipmentInterface[];
  public showPackages: boolean[] = [];
  public manualMode: boolean;
  public editMode: boolean = false;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    private orderManagerResource: OrderManagerResource,
    @Inject('StateService') state: ng.ui.IStateService,
    @Inject('DialogService') private dialog: any
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit() {
    this.fetchShipments();
    this.setManualMode();
  }

  private setManualMode() {
    this.manualMode = this.order.manual;
  }

  public goToEditMode() {
    this.orderManagerResource.updateManual(this.order.id, { manual: true }).subscribe(
      response => {
        this.order.manual = true;
        this.editMode = true;
      }
    );
  }

  private fetchShipments(): void {
    this.resource.cGet({ 'items.order.id': this.order.id, 'pagination': false }, { returnHydraMembers: true }).subscribe((response: ShipmentInterface[]) => {
      this.shipments = response;
    });
  }

  getLabel(shipment: ShipmentInterface) {
    let label = ' ';

    if (shipment.warehouse) {
      label += shipment.warehouse.code;
    }

    if (shipment.carrier && shipment.carrier.carrierGroup) {
      label += '/';
      label += shipment.carrier.carrierGroup.name;
    }

    return label;
  }

  initResetShipment(indicator: IndicatorInterface, indicatorType: string, shipmentIndex: number): void {
    if (indicatorType === 'logisticIndicator') {
      this.shipments[shipmentIndex].logisticIndicatorStatus = indicator.status;
    }
  }

  resetShipment(shipmentId: string): void {
    this.dialog.confirm(this.translate('PAGE.ORDER_MANAGER.SHIPMENT.DIALOG_RESET_SHIPMENT')).then(() => {
      this.resource.resetShipment(shipmentId)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response: any) => {
          this.state.go(this.state.current, this.state.params, { reload: true });
        })
        ;
    });
  }

  onUpdateRepatriationIndicator() {
    this.fetchShipments();
  }

  onShippingEditCancel() {
    this.editMode = false;
    this.fetchShipments();
  }

  displayItems(item: any) {
    let nbPackagesWithoutParent = 0;
    for (const _package of item.packages) {
      if (_package.parent === null) {
        nbPackagesWithoutParent = nbPackagesWithoutParent + 1;
      }
    }

    if ((nbPackagesWithoutParent > 0 && this.order.detached) || (!this.order.detached)) {
      return true;
    } else {
      return false;
    }
  }

  displayPackage(_package: any) {
    if ((_package.parent === null && this.order.detached) || (!this.order.detached)) {
      return true;
    } else {
      return false;
    }
  }

  public goToProductEdit(event: MouseEvent, productUri: string) {
    const id = productUri.split('/').pop();
    let state = 'product.edit';

    if (this.order.detached) {
        state = 'spare-part.edit';
    }

    if (event.ctrlKey) {
        window.open(this.state.href(state, { id }, { absolute: true }), '_blank');
        return;
    }
    this.state.go(state, { id });
  }
}
