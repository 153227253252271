import { Component, Inject } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { ICarrierFamily } from '@components';
import { SnackbarService } from '@components/snackbar';
import { Observable } from 'rxjs/Observable';
import { catchError, takeUntil } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { CountryHelper, SessionHelper } from '@helpers';
import { ICountry } from '@interfaces';

@Component({
  selector: 'app-carrier-family-result-list',
  template: require('./carrier-family-result-list.component.html'),
})
export class CarrierFamilyResultListComponent extends AbstractComponent {

  private _items: ICarrierFamily[];
  public currentCountriesCode: string[] = [SessionHelper.getCountry() && SessionHelper.getCountry().code];

  set items(items: ICarrierFamily[]) {
    this._items = items;
  }

  get items(): ICarrierFamily[] {
    return this._items;
  }

  public columns = [
    { 'size': 1, property: 'id', title: 'PAGE.CARRIER.CARRIER_FAMILIES.LIST.TABLE.HEAD.ID', type: 'id' },
    { 'size': 3, property: 'reference', title: 'PAGE.CARRIER.CARRIER_FAMILIES.LIST.TABLE.HEAD.REFERENCE_CODE' },
    { 'size': 6, property: 'formattedCarriers', type: 'customColumnContent', title: 'PAGE.CARRIER.CARRIER_FAMILIES.LIST.TABLE.HEAD.FORMATTED_CARRIERS' },
  ];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private snackbar: SnackbarService,
  ) {
    super($translate, authService, resource, state);
    this.currentCountriesCode.push(...CountryHelper.getCountryChilrenByCountryCode(
      SessionHelper.getCountry() && SessionHelper.getCountry().code
    ).map((country: ICountry) => country.code));
  }

  public updatePosition(event: { id: string, position: number }): void {
    const carrierFamilies = this.items.filter((item: ICarrierFamily) => '' + item.id !== event.id);
    const movedCarrierFamily = this.items.find((item: ICarrierFamily) => '' + item.id === event.id);
    const observables$: Observable<any>[] = [];

    let sortedCarriersFamilies: ICarrierFamily[] = [
      ...carrierFamilies.slice(0, event.position),
      movedCarrierFamily,
      ...carrierFamilies.slice(event.position),
    ];

    sortedCarriersFamilies = Object.keys(sortedCarriersFamilies).map((key: string) => {
      return {
        ...sortedCarriersFamilies[+key],
        position: +key,
      };
    });

    sortedCarriersFamilies.forEach((item: ICarrierFamily) => {
      observables$.push(this.resource.update(item.id, { position: item.position }).pipe(catchError((error) => of(error))));
    });

    forkJoin(observables$)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.items = [...sortedCarriersFamilies];
        this.snackbar.validate('ALERTS.DATA.UPDATE');
      })
      ;
  }
}
