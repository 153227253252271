import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { WcaService } from '@components/export-logistics/services';
import { IDirectInjection } from '@components/export-logistics/models/direct-injection.interface';
import { DirectInjectionModel } from '@components/export-logistics/models/direct-injection.model';

@Component({
  selector: 'app-choice-warehouse-carrier-group-agencies-form',
  template: require('./choice-warehouse-carrier-group-agencies-form.component.html'),
})
export class ChoiceWarehouseCarrierGroupAgenciesFormComponent extends AbstractComponent {

  public form: FormGroup;
  public wcaActivated = '';

  private _wca: IDirectInjection[];

  @Input() set warehouseCarrierGroupAgencies(wca: IDirectInjection[]) {
    this._wca = wca;

    this.updateWcaActivatedListText();
    this.setControls();
    this.subscribeToValueChange();
    this.wcaService.setWcaChecked(wca.filter((item: IDirectInjection) => item.activated));
  }

  @Output() public onFieldSelected: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private fb: FormBuilder,
    public wcaService: WcaService,
  ) {
    super($translate, authService, null, state);

    this.form = this.fb.group({
      warehouseCarrierGroupAgencies: this.fb.array([]),
    });
  }

  get wca(): IDirectInjection[] { return this._wca; }
  get wcaFA(): FormArray { return this.form.get('warehouseCarrierGroupAgencies') as FormArray; }

  private updateWcaActivatedListText(): void {
    this.wcaActivated = this.wca.filter((wca: DirectInjectionModel) => wca.activated)
      .map((wca) => wca.carrierGroupAgency.agencyCode)
      .join(', ')
      ;
  }

  /**
   * Sets the form controls.
   */
  private setControls(): void {
    const fgs: FormGroup[] = this.wca.map(
      (directInjection: IDirectInjection): FormGroup => this.fb.group({ checked: directInjection.activated })
    );
    const fa: FormArray = this.fb.array(fgs);

    this.form.setControl('warehouseCarrierGroupAgencies', fa);
  }

  /**
   * When user checks a direct injection from warehouseCarrierGroupAgencies list, export with dry run to true is launched.
   */
  private subscribeToValueChange(): void {
    const controls = (<FormArray>this.form.get('warehouseCarrierGroupAgencies')).controls;

    controls.forEach((control, index) => {
      control.get('checked').valueChanges.forEach((value: boolean) => {
        this.wca[index].activated = value;
        this.updateWcaActivatedListText();
        this.wcaService.setWcaChecked(this.wca.filter((wca: IDirectInjection) => wca.activated));
        this.emitFieldSelected();
      });
    });
  }

  public hasSomeChecked(): boolean {
    return (<FormArray>this.form.get('warehouseCarrierGroupAgencies')).controls.some(control => control.get('checked').value === true);
  }

  public toggleCheck(): void {
    const controls = (<FormArray>this.form.get('warehouseCarrierGroupAgencies')).controls;
    const checkedValue = !this.hasSomeChecked();

    controls.forEach((control, index) => {
      this.wca[index].activated = checkedValue;
      control.get('checked').setValue(checkedValue);
    });

    this.updateWcaActivatedListText();
    this.wcaService.setWcaChecked(this.wca.filter((wca: IDirectInjection) => wca.activated));
    this.emitFieldSelected();
  }

  /**
   * Emit event to parent to avert it that it must launch the export with dryRun true.
   */
  private emitFieldSelected(): void {
    this.onFieldSelected.emit(true);
  }
}
