import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { WarehousesService } from '@components/export-logistics/services';
import { ICarrierGroup } from '@components/export-logistics/forms/choice-carrier-groups-form.component';

export interface IWarehouse {
  iri: string;
  code: string;
  name: string;
  checked: boolean;
}

@Component({
  selector: 'app-choice-warehouses-form',
  template: require('./choice-warehouses-form.component.html'),
})
export class ChoiceWarehousesFormComponent extends AbstractComponent {

  public form: FormGroup;
  private _warehouses: IWarehouse[];

  @Input() set warehouses(warehouses: IWarehouse[]) {
    this._warehouses = warehouses;

    this.setControls();
    this.warehousesService.setWarehousesChecked(warehouses.filter((warehouse: IWarehouse) => warehouse.checked));
  }

  @Output() public onFieldSelected: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private fb: FormBuilder,
    public warehousesService: WarehousesService,
  ) {
    super($translate, authService, null, state);

    this.form = this.fb.group({
      warehouses: this.fb.array([]),
    });
  }

  get warehouses(): IWarehouse[] { return this._warehouses; }
  get warehousesFA(): FormArray { return this.form.get('warehouses') as FormArray; }

  /**
   * Sets the form controls.
   */
  private setControls(): void {
    const fgs: FormGroup[] = this.warehouses.map(
      (warehouse: IWarehouse): FormGroup => this.fb.group({ checked: warehouse.checked })
    );
    const fa: FormArray = this.fb.array(fgs);

    this.form.setControl('warehouses', fa);
  }

  /**
   * When user checks a warehouses, warehouseCarrierGroupAgencies are updated.
   */
  private subscribeToValueChange(): void {
    const controls = (<FormArray>this.form.get('warehouses')).controls;

    controls.forEach((control, index) => {
      control.get('checked').valueChanges.forEach((value: boolean) => {
        this.warehouses[index].checked = value;
        this.warehousesService.setWarehousesChecked(this.warehouses.filter((warehouse: IWarehouse) => warehouse.checked));
        this.emitFieldSelected();
      });
    });
  }

  /**
   * Emit event to parent to avert it that it must update the warehouse carrier agencies.
   */
  private emitFieldSelected(): void {
    this.onFieldSelected.emit();
  }

  public hasSomeChecked(): boolean {
    return (<FormArray>this.form.get('warehouses')).controls.some(control => control.get('checked').value === true);
  }

  public toggleCheck(): void {
    const controls = (<FormArray>this.form.get('warehouses')).controls;
    const checkedValue = !this.hasSomeChecked();

    controls.forEach((control, index) => {
      this.warehouses[index].checked = checkedValue;
      control.get('checked').setValue(checkedValue);
    });

    this.warehousesService.setWarehousesChecked(this.warehouses.filter((warehouse: IWarehouse) => warehouse.checked));
    this.emitFieldSelected();
  }

  public dryRun(): void {
    this.subscribeToValueChange();
  }
}
