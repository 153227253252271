import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { SnackbarService } from '@components/snackbar';
import { SessionHelper } from '@helpers';
import { HydraHelper } from '@helpers/HydraHelper';
import { ISku } from '@interfaces';
import { AbstractResource } from '@resources';
import { AuthService } from '@services/auth.service';
import { SkuSearchService } from '@services/sku-search.service';
import { Observable } from 'rxjs/Observable';
import { WebsiteHomepageBargainsResource } from './website-homepage-bargains.resource';

@Component({
  selector: 'app-website-homepage-bargains',
  template: require('./website-homepage-bargains.component.html'),
  providers: [
    { provide: AbstractResource, useClass: WebsiteHomepageBargainsResource },
    SkuSearchService
  ],
})

export class WebsiteHomepageBargainsComponent extends AbstractPageComponent implements OnInit {
  public form: FormGroup;
  private productMarketplaces: number[] = [];
  private ids: number[] = [];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    private snackbar: SnackbarService
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    this.buildForm();
    this.resource.cGet({ 'locale': SessionHelper.getLocale() }).subscribe((response: any) => {
      const ids = response['hydra:member']
        .map((bargains: any) => +bargains.id);
      this.ids.push(...ids);
      this.productMarketplaces = response['hydra:member']
        .sort((a: any, b: any) => a.position - b.position)
        .map((bargains: any) => +bargains.productMarketplace.replace(/.*\/(\d+)$/, `$1`));

      this.form.patchValue({
        'productMarketplaces': this.productMarketplaces
      });

      const otherLocales = SessionHelper.getCountry().locales.filter((locale: string) => {
        return locale !== SessionHelper.getLocale();
      });
      for (let i = 0; i < otherLocales.length; i++) {
        const locale = otherLocales[i];
        this.resource.cGet({ locale }).subscribe((otherResponse: any) => {
          const otherIds = otherResponse['hydra:member']
            .map((bargains: any) => +bargains.id);
          this.ids.push(...otherIds);
        });
      }
    });
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      productMarketplaces: []
    });
  }

  public submit(): void {
    const formValues = this.form.value;
    const productMarketplaces = this.getUniqueProductMarketplaces(formValues);
    if (!productMarketplaces) {
      return;
    }
    if (JSON.stringify(productMarketplaces) === JSON.stringify(this.productMarketplaces)) {
      this.snackbar.validate(this.translate('ALERTS.DATA.UPDATE'));
      return;
    }
    const locales = SessionHelper.getCountry().locales;
    const createBargains: Observable<object>[] = [];
    for (let i = 0; i < locales.length; i++) {
      const locale = locales[i];
      for (let position = 0; position < productMarketplaces.length; position++) {
        const productMarketplace = HydraHelper.buildIri(productMarketplaces[position], 'product_marketplaces');
        createBargains.push(this.resource.create({ productMarketplace, position, locale }));
      }
    }
    if (this.ids.length) {
      const deleteBargains: Observable<object>[] = [];
      for (let i = 0; i < this.ids.length; i++) {
        const id = this.ids[i];
        deleteBargains.push(this.resource.remove(id));
      }
      Observable.forkJoin(...deleteBargains).subscribe(() => {
        Observable.forkJoin(...createBargains).subscribe((responses: any) => {
          this.snackbar.validate(this.translate('ALERTS.DATA.UPDATE'));
          this.state.go(this.state.current.name, {}, { reload: true, notify: true });
        });
      });
    } else {
      Observable.forkJoin(...createBargains).subscribe((responses: any) => {
        this.snackbar.validate(this.translate('ALERTS.DATA.UPDATE'));
        this.state.go(this.state.current.name, {}, { reload: true, notify: true });
      });
    }
  }

  private getUniqueProductMarketplaces(formValues: any): number[] | void {
    const productMarketplaces = formValues.productMarketplaces
      .filter((productMarketplace: ISku) => productMarketplace.hasOwnProperty('id'))
      .map((productMarketplace: ISku) => productMarketplace.id);
    if (productMarketplaces.length !== 4) {
      this.snackbar.warn(this.translate('PAGE.WEBSITE_HOMEPAGE.FORM.ERROR.PRODUCTS_MISSING'));
      return;
    }
    const uniqueProductMarketplaces = productMarketplaces.filter((id: string, index: number, array: string[]) => {
      return array.indexOf(id) === index;
    });
    if (uniqueProductMarketplaces.length !== productMarketplaces.length) {
      this.snackbar.warn(this.translate('PAGE.WEBSITE_HOMEPAGE.FORM.ERROR.PRODUCTS_UNIQUE'));
      return;
    }

    return uniqueProductMarketplaces.map((id: string) => +id);
  }
}
