import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@services';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AbstractResource } from '@resources';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import {GrcTicketsFiltersService} from '@components/grc/services/grc-tickets-filters.service';
import {GrcResource} from '@components/grc/grc.resource';
import {GrcTicketResource} from '@components/grc/grc-ticket.resource';

@Component({
  selector: 'app-grc-tickets-list',
  template: require('./grc-tickets-list.component.html'),
  providers: [
    { provide: AbstractResource, useClass: GrcTicketResource },
    { provide: AbstractFiltersFieldsService, useClass: GrcTicketsFiltersService }
  ]
})
export class GrcTicketsListComponent extends AbstractComponent {
  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, null, state);
  }
}
