import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { OrderManagerResource } from '@components/order-manager/order-manager.resource';
import { DelayReasonOrderListService } from '@components/order-manager/delay-reason/delay-reason-order-list.service';
import { IExportOptions } from '@interfaces';
import moment = require('moment');

@Component({
  selector: 'app-delay-reason-order-list',
  template: require('./delay-reason-order-list.component.html'),
  providers: [
    { provide: AbstractResource, useClass: OrderManagerResource },
    { provide: AbstractFiltersFieldsService, useClass: DelayReasonOrderListService },
  ]
})

export class DelayReasonOrderListComponent extends AbstractPageComponent {

  public exportOption: IExportOptions[] = [{
    entryPoint: '/v2/export',
    responseType: 'text',
    type: 'text/csv',
    filename: `delayed-orders-${moment().format('YYYYMMDDHHmmss')}.csv`,
    translationKey: 'BUTTON.EXPORT.CSV',
    filters: false,
    roles: ['ROLE_WALISOFT_AGENT'],
    postOptions: {
      exportCode: 'delayed_order',
      formatCode: 'csv_excel',
      dryRun: false,
      deferred: true,
      split: false,
      serializationGroups: ['walisoft_order_list'],
      async: true,
      limit: null
    }
  }];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    public resource: AbstractResource,
  ) {
    super($translate, authService, resource, state);
  }

  public updateExportOptions(filters?: any) {
    this.exportOption[0].postOptions.limit = this.resource.encodeParams(filters).toString();
  }
}
