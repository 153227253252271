import { Component, Inject, Input, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { SessionHelper } from '@helpers/session.helper';
import { ICountry } from '@interfaces/ICountry';
import { IFormViolation } from '@interfaces/IFormViolation';
import { ICategories } from '@components/categories/models';
import { AbstractFormComponent } from '@components/generic/Form/abstract-form.component';
import { CMSResource } from '@components/cms';
import { HydraHelper } from '@helpers/HydraHelper';
import * as moment from 'moment';
import { DATE_FULL_FORMAT, DATE_SHORT_FORMAT } from '@constants';

@Component({
  selector: 'app-categories-form',
  template: require('./categories-form.component.html'),
})

export class CategoriesFormComponent extends AbstractFormComponent implements OnInit {

  @Input() public model: ICategories;
  @Input() public inCreation: boolean;
  @Input() public violations: IFormViolation[]|any = [];
  @Input() public parentCategories: any;

  public form: FormGroup;
  public currentLocales: string[] = SessionHelper.getCountry().locales;
  public defaultSortingList: any[];
  public cmsLinks: { id: string; name: string; }[] = null;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private cmsResource: CMSResource,
  ) {
    super($translate, authService, null, state);
  }

  ngOnInit(): void {
    this.defaultSortingList = [
      {label: this.translate('PAGE.CATEGORIES.FORM.DEFAULT_SORTING.SELLS'), value: 'sells'},
      {label: this.translate('PAGE.CATEGORIES.FORM.DEFAULT_SORTING.MANUAL'), value: 'manual'},
      {label: this.translate('PAGE.CATEGORIES.FORM.DEFAULT_SORTING.PRICE'), value: 'price'},
      {label: this.translate('PAGE.CATEGORIES.FORM.DEFAULT_SORTING.DEFAULT_MERCHANDISING'), value: 'default_merchandising'},
    ];
    this.inCreation  = !this.state.params.id;

    this.getCMSList();
    this.form = new FormGroup({
      translations: new FormArray([]),
      active: new FormControl(this.model.active, Validators.required),
      parent: new FormControl(this.model.parent),
      defaultSorting: new FormControl(this.model.defaultSorting, Validators.required),
      startDate: new FormControl(this.model.startDate ? moment(this.model.startDate).startOf('day').format(DATE_SHORT_FORMAT) : null),
      endDate: new FormControl(this.model.endDate ? moment(this.model.endDate).endOf('day').format(DATE_SHORT_FORMAT) : null),
      position: new FormControl(this.model.position, Validators.required),
    });

    if (!this.inCreation) {
      const arrayControls = this.form.get('translations') as FormArray;
      Object.keys(this.model.translations).forEach((locale: any) => {
        arrayControls.push(this.createTranslationForm(this.model.translations[locale]));
      });
      return;
    }

    this.prepareTranslationsForForm(SessionHelper.getCountry());
  }

  public hasSeveralTranslation(): boolean {
    return this.currentLocales.length > 1;
  }

  private prepareTranslationsForForm(country: ICountry): void {
    const arrayControls = this.form.get('translations') as FormArray;

    while (arrayControls.length !== 0) {
      arrayControls.removeAt(0);
    }

    country.locales.forEach((locale: string) => {

      const translation = {
        locale: locale,
        name: '',
        lengowName: '',
        slug: '',
        shortDescription: '',
        longDescription: '',
        contentBlock: '',
        contentBlockTitle: ''
      };

      arrayControls.push(this.createTranslationForm(translation));
    });
  }

  public createTranslationForm(translation: any): FormGroup {
    if (translation.contentBlock === '') {
      translation.contentBlock = null;
    }

    return new FormGroup({
      id: new FormControl(translation.id),
      locale: new FormControl((translation.locale)),
      name: new FormControl(translation.name, Validators.required),
      lengowName: new FormControl(translation.lengowName, Validators.required),
      slug: new FormControl(translation.slug),
      shortDescription: new FormControl(translation.shortDescription),
      longDescription: new FormControl(translation.longDescription),
      contentBlock: new FormControl(translation.contentBlock),
      contentBlockTitle: new FormControl(translation.contentBlockTitle),
    });
  }

  public getTranslation(key?: string): FormGroup {
    const translations: FormArray = this.form.get('translations') as FormArray;

    if (this.hasSeveralTranslation() && undefined !== key) {
      return translations.controls.find((group: FormGroup) => key === group.get('locale').value) as FormGroup;
    }

    return translations.controls[0] as FormGroup;
  }

  public getCMSList(): void {
    this.cmsResource.getMany({'translations.active': 1}, {dontUseModel: true})
      .takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        this.cmsLinks = response.map((item: any) => {
          if (!item.translations[this.currentLocale]) {
            return;
          }

          return {
            name: item.translations[this.currentLocale].name,
            id: HydraHelper.buildIri(item['id'], 'content_blocks')
          };
        }).filter((item: any) => {
          return undefined !== item;
        });
      });
  }

  public getTranslatableString(): string {
    const type = this.form.get('defaultSorting').value;
    if (undefined === type || type === 'price' || type === 'sells' ) {
      return '';
    }

    return this.translate(`EMERCH.DESCRIPTION.${type.replace(' ', '_').toUpperCase()}`);
  }
}
