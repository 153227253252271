import { Injectable } from '@angular/core';
import { AbstractResource } from '../../resources/abstract.resource';
import { CustomerDetailOrderModel } from '@components/customer/models/customer-detail-order.model';
import { StringHelper } from '../../helpers/StringHelper';
import { Resource } from '@decorators/Resource';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  entryPoint: '/v2/customers/{id}/orders',
  editionState: 'order_manager.edit',
  model: CustomerDetailOrderModel,
  translationKey: 'CUSTOMER_CARD.DETAILS.ORDERS',
  listColumns: [
    { field: 'id', translationKey: 'ID', type: 'id' },
    { field: 'orderId', translationKey: 'ORDERID', type: 'string' },
    { field: 'date', translationKey: 'DATE', type: 'string' },
    { field: 'status', translationKey: 'STATUS', type: 'string', translateValueFrom: ('DATA.STATUSES') },
    { field: 'amount', translationKey: 'AMOUNT', type: 'number' },
    { field: 'marketplace', translationKey: 'MARKETPLACE', type: 'string' },
  ],
  createAvailable: false,
  deleteAvailable: false,
  updateAvailable: true,
  isHydra: true

})
export class CustomerDetailOrdersResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  public getManyEntrypoint(id: string): string {
    return StringHelper.replacePatterns(this.entryPoint, {'{id}': id});
  }
}
