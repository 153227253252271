import { AbstractComponent } from '@components/generic/abstract.component';
import { ChangeDetectorRef, Component, ElementRef, Inject, Input, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { FormArray } from '@angular/forms';
import { MP_NAMES } from '@constants/marketplace';
import { SessionHelper } from '@helpers/session.helper';
import { IAttributeValue, IProductForm } from '@components/product/interfaces/product-form.interface';

@Component({
  selector: 'app-product-content-form-translations',
  template: require('./product-content-form-translations.component.html'),
  styles: [require('./product-content-form-translations.component.scss')],
})
export class ProductContentFormTranslationsComponent extends AbstractComponent implements OnInit {
  public readonly mpNames = MP_NAMES;
  public mpsForDescriptionMarketplace: string[] = [];
  public messageSuccess = false;
  @Input() translationsFA: FormArray;
  @Input() tabIndex: number = 0;
  @Input() attributeValues: IAttributeValue[] = [];
  @Input() packagesInfo: string = '';
  @Input() localeTab: string;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    public resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private el: ElementRef,
    private cdRef: ChangeDetectorRef
  ) {
    super($translate, authService, resource, state);

    const currentMarketplacesNames: string[] = SessionHelper.getMarketplaces().map((marketplace) => marketplace.name);

    this.mpsForDescriptionMarketplace = currentMarketplacesNames.filter((mpName: string) => MP_NAMES.ALICES_GARDEN !== mpName);
  }

  ngOnInit() {
    setTimeout(() => {
      this.messageSuccess = true;
    }, 150);
  }

  public getDescriptions(): string[] {
    const htmlAttributes = this.el.nativeElement.querySelector(`.attributes-list-${this.tabIndex}`) ?
      this.el.nativeElement.querySelector(`.attributes-list-${this.tabIndex}`)
        .innerHTML
        .replace(/(\<!--[\s\S]*?-->)/g, '') // remove angular comments
        .replace(/<\s*([a-z][a-z0-9]*)\s.*?>/gi, '<$1>') // attributes
        .replace(/[\n\r]+/g, '') // line ending
        .replace(/\s{2,}/g, '') : // more than one space
      ''
      ;

    return [
      this.translationsFA.at(this.tabIndex).get('descriptionMarketplace').value,
      htmlAttributes,
      this.translationsFA.at(this.tabIndex).get('complementaryDescriptionForMarketplace').value,
      this.packagesInfo,
    ];
  }
  public isAutoGeneratedShortTitle(): boolean {
    if (this.translationsFA.at(this.tabIndex) && typeof this.translationsFA.at(this.tabIndex).get('autoGeneratedShortTitle') !== 'undefined') {
      return this.translationsFA.at(this.tabIndex).get('autoGeneratedShortTitle').value;
    }
    return false;
  }

  public isAutoGeneratedLongTitle() {
    if (this.translationsFA.at(this.tabIndex) && typeof this.translationsFA.at(this.tabIndex).get('autoGeneratedLongTitle') !== 'undefined') {
      return this.translationsFA.at(this.tabIndex).get('autoGeneratedLongTitle').value;
    }
    return false;
  }

  public isAutoGeneratedMetaDescription() {
    if (this.translationsFA.at(this.tabIndex) && typeof this.translationsFA.at(this.tabIndex).get('autoGeneratedMetaDescription') !== 'undefined') {
      return this.translationsFA.at(this.tabIndex).get('autoGeneratedMetaDescription').value;
    }
    return false;
  }
}
