export default marketplacesConfigureRoutes;

/** @ngInject */
function marketplacesConfigureRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'walipush',
    sidebarSubNav: 'marketplaces',
    title: '',
    reloadChangeCountry: 'marketplaces.commission',
    showCountries: true,
    tabs: [
      {
        label: 'PAGE.MARKETPLACES_CONFIGURE.BREADCRUMB.COMMISSIONS',
        state: 'marketplaces.commission',
      },
      {
        label: 'PAGE.MARKETPLACES_CONFIGURE.BREADCRUMB.PREORDER',
        state: 'marketplaces.configure',
      }
    ],
  };

  $stateProvider
    .state('marketplaces', {
      url: '/marketplaces/configure',
      parent: 'walipush',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.MARKETPLACES_CONFIGURE',
        stateName: 'marketplaces.commission'
      }
    })
    .state('marketplaces.commission', {
      url: `/commission?
        page&
        byPage&
        marketplaceCode&
        catalog`,
      views: {
        'page@layout': {
          template: '<app-marketplaces-commission></app-marketplaces-commission>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.MARKETPLACES_CONFIGURE.COMMISSIONS.TITLE',
      })
    })
    .state('marketplaces.commission_edit', {
      url: `/commission/:id/edit`,
      views: {
        'page@layout': {
          template: '<app-marketplaces-commission-form></app-marketplaces-commission-form>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.MARKETPLACES_CONFIGURE.COMMISSIONS.EDIT',
      })
    })
    .state('marketplaces.commission_new', {
      url: `/commission/new`,
      views: {
        'page@layout': {
          template: '<app-marketplaces-commission-form></app-marketplaces-commission-form>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.MARKETPLACES_CONFIGURE.COMMISSIONS.ADD',
      })
    })
    .state('marketplaces.configure', {
      url: `/preorder?
        &startDate
        &endDate
      `,
      views: {
        'page@layout': {
          template: '<app-marketplaces-configure></app-marketplaces-configure>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.MARKETPLACES_CONFIGURE.BREADCRUMB.PREORDER',
      })
    })
  ;
}
