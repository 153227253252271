import { Component, Inject, OnInit } from '@angular/core';
import { AttributeSetResource } from '@components/attribute-set/attribute-set.resource';
import { SessionHelper } from '@helpers';
import { AuthService } from '@services';
import { AbstractFormComponent } from '@components/generic/Form/abstract-form.component';
import { SnackbarService } from '@components/snackbar/snackbar.service';
import { ICountry, IFormViolation } from '@interfaces';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { AttributeResource } from '@resources';
import { SuperProductResource } from '@components/super-product/super-product.resource';
import { Observable } from 'rxjs/Observable';
import { AttributeAttributeSetResource } from '@components/attribute-set/attribute-attribute-set.resource';
import {
  IAttributeAttributeSet,
  IAttributeFilterable,
  IAttributeActive,
  IAttributeSet,
  IAttributeType,
} from '@components/attribute-set/models/attributes-set.interface';
import { ISuperProduct } from '@models/ISuperProduct';
import { IAttribute } from '@components/attribute/models/attribute.interface';
import { from } from 'rxjs/observable/from';
import { mergeMap } from 'rxjs/operators';
import { LOCALE_FR } from '@constants';
import { AttributeTypeWebsiteResource } from '@components/attribute-set/attribute-type-website.resource';

@Component({
  selector: 'app-attribute-set',
  template: require('./attribute-set.component.html'),
  styles: [require('./attribute-set.component.scss')]
})

export class AttributeSetComponent extends AbstractFormComponent implements OnInit {

  public inCreation: boolean;
  public violations: IFormViolation[] | any = [];
  public form: FormGroup;
  public model: IAttributeSet;
  public country: ICountry = SessionHelper.getCountry();
  public superProducts: ISuperProduct[] = [];
  public superProductsId: string[] = [];
  public attributes: IAttribute[] = [];
  public deletedAttributeAttributeSetIds: string[] = [];
  private attributesActiveForAll = new Map();
  private attributesFilterableForAll = new Map();
  private attributesTypeForAll = new Map();
  public attributeTypes: any[] = [];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AttributeSetResource,
    @Inject('StateService') state: ng.ui.IStateService,
    @Inject('DialogService') private dialogService: any,
    private snackbar: SnackbarService,
    private superProductResource: SuperProductResource,
    private attributeResource: AttributeResource,
    private attributeAttributeSetsResource: AttributeAttributeSetResource,
    private attributeTypeWebsiteResource: AttributeTypeWebsiteResource
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    this.inCreation = !this.state.params.id;

    this.form = new FormGroup({
      name: new FormControl(null, Validators.required),
      attributes: new FormArray([]),
      superProducts: new FormControl(),
    });
    this.getAttributes();
    this.getAttributeTypes();

    if (this.inCreation) {
      this.addAttribute();
      this.superProductResource.getSkus({ locale: this.currentLocale })
        .takeUntil(this.destroyed$)
        .subscribe((response: any) => {
          this.superProducts = response;
        });
      return;
    }
    this.fetch();
  }

  private fetch(): void {
    this.resource.get(this.state.params.id)
      .catch((error) => {
        throw new Error(error);
      })
      .switchMap((response: IAttributeSet) => {
        this.model = response;
        this.form.get('name').setValue(this.model.name);
        this.formatAttributesForm();
        return this.superProductResource.getSkus({ locale: this.currentLocale })
          .catch((error) => {
            throw new Error(error);
          });
      }
      )
      .takeUntil(this.destroyed$)
      .subscribe(
        (response: ISuperProduct[]) => {
          this.superProducts = response;
          this.formatSuperProductsForm();
        },
        () => {
          this.snackbar.alert(this.translate('ALERTS.ERROR.API'));
        })
      ;
  }

  private deleteItems(ids: string[]): Observable<any> {
    return from(ids).pipe(
      mergeMap(id => this.attributeAttributeSetsResource.remove(id).catch(() => Observable.of(id)))
    );
  }

  private formatAttributesForm(): void {
    const attributeAttributeSet: IAttributeAttributeSet[] = this.model.attributeAttributeSets;
    const attributeFilterables: IAttributeFilterable[] = this.model.attributeFilterables;
    const attributeActives: IAttributeActive[] = this.model.attributeActives;
    const attributeTypes: IAttributeType[] = this.model.attributeTypes;

    attributeAttributeSet.forEach((attribute: IAttributeAttributeSet) => {
      const attributeFilterable = attributeFilterables.find((attrf: any) => {
        return attribute.attribute.id === attrf.attribute.id && attrf.country.id === this.country.id;
      });
      const attributeActive = attributeActives.find((attra: any) => {
        return attribute.attribute.id === attra.attribute.id && attra.country.id === this.country.id;
      });
      const attributeType = attributeTypes.find((attrt: IAttributeType) => {
        return attribute.attribute.id === attrt.attribute.id && attrt.country.id === this.country.id;
      });
      this.addAttribute(
        attribute,
        attributeFilterable ? attributeFilterable.filterable : false,
        attributeActive ? attributeActive.active : false,
        attributeType ? attributeType : this.attributeTypes[0]
      );
    });
  }

  private formatSuperProductsForm(): void {
    this.model.superProducts.forEach((superProduct: ISuperProduct) => {
      if (superProduct.hasOwnProperty('id')) {
          this.superProductsId.push(superProduct.id.toString());
      }
    });

    this.form.get('superProducts')
      .setValue(this.superProducts.filter((superProduct: ISuperProduct) =>
        superProduct.hasOwnProperty('id') && superProduct.id !== undefined && this.superProductsId.includes(superProduct.id.toString())
      ));
  }

  private submit(redirectToList: boolean): void {
    if (this.inCreation) {
      this.resource.create(this.prepareQuery())
        .takeUntil(this.destroyed$)
        .subscribe((response: IAttributeSet) => {
          redirectToList
            ? this.actions.list.go({}, { reload: true, notify: true })
            : this.actions.update.go({ id: response.id }, { reload: true, notify: true })
            ;
        });
      return;
    }

    const requestsQueue = this.deletedAttributeAttributeSetIds.length > 0
      ? this.deleteItems(this.deletedAttributeAttributeSetIds)
        .switchMap((response: any) => {
          if (response !== null) {
            const arrayControls = this.form.get('attributes') as FormArray;
            const formAttribute = arrayControls.controls.find(control => control.get('id').value === response);
            const attribute = this.model.attributeAttributeSets.find(attr => attr.id === response);
            const attributeFilterable = this.model.attributeFilterables.find((attrf: any) => {
              return attribute.attribute.id === attrf.attribute.id && attrf.country.id === this.country.id;
            });
            const attributeActive = this.model.attributeActives.find((attrf: any) => {
              return attribute.attribute.id === attrf.attribute.id && attrf.country.id === this.country.id;
            });
            const attributeType = this.model.attributeTypes.find((attrt: IAttributeType) => {
              return attribute.attribute.id === attrt.attribute.id && attrt.country.id === this.country.id;
            });

            if (!formAttribute) {
              this.addAttribute(
                attribute,
                attributeFilterable ? attributeFilterable.filterable : false,
                attributeActive ? attributeActive.active : false,
                attributeType ? attributeType : null
              );
              this.snackbar.alert(
                this.translate(
                  'PAGE.ATTRIBUTE_SETS.FORM.ALERTS.DELETE.ERROR.GLOBAL',
                  { name: attribute.attribute.translations[this.currentLocale].name }
                )
              );
            }
          }
          return this.resource.partialUpdate(this.state.params.id, this.prepareQuery());
        })
      : this.resource.partialUpdate(this.state.params.id, this.prepareQuery());

    requestsQueue
      .takeUntil(this.destroyed$)
      .subscribe(
        (response: IAttributeSet) => {
          redirectToList
            ? this.actions.list.go({}, { reload: true, notify: true })
            : this.actions.update.go({ id: response.id }, { reload: true, notify: true })
            ;
        },
        (reject) => {
          reject.violations.forEach((violation: IFormViolation) => {
            this.snackbar.alert(violation.message);
          });
        }
      );
  }

  private prepareQuery(): IAttributeSet {
    const arrayControls = this.form.get('attributes') as FormArray;

    const body: any = {
      attributeAttributeSets: [],
      attributeFilterables: [],
      attributeActives: [],
      name: '',
      superProducts: [],
    };

    arrayControls.controls.forEach((control, index) => {
      if (control.get('attribute').value && control.get('attribute').value.id) {
        body.attributeAttributeSets.push({
          attribute: control.get('attribute').value.id,
          position: index,
          required: control.get('required').value
        });
      }
    });

    body.attributeFilterables = this.prepareQueryAttributesFilterable();
    body.attributeActives = this.prepareQueryAttributesActive();
    body.attributeTypes = this.prepareQueryAttributesType();

    if (this.form.get('superProducts').value && this.form.get('superProducts').value.length > 0) {
      this.form.get('superProducts').value.forEach((superProduct: any) => {
        if (superProduct.hasOwnProperty('id') && this.form.get('superProducts').value) {
          body.superProducts.push(superProduct.id);
        }
      });
    }
    body.name = this.form.get('name').value;

    return body;
  }

  private prepareQueryAttributesActive(): any[] {
    const countries = SessionHelper.getAllApplicationActiveCountries(),
      arrayControls = this.form.get('attributes') as FormArray,
      attributeActives: any[] = [];
    arrayControls.controls.forEach((control, index) => {
      if (control.get('attribute').value && control.get('attribute').value.id) {
        const attributeId: number = control.get('attribute').value.id;
        countries.forEach((country) => {
          const attributeActiveValue = this.getAttributeActiveValue(attributeId, control, country);
          attributeActives.push({
            attribute: attributeId,
            active: attributeActiveValue,
            country: country.id,
          });
        });
        if (this.attributesActiveForAll.has(attributeId) && this.attributesActiveForAll.get(attributeId) !== null) {
          this.attributesActiveForAll.delete(attributeId);
        }
      }
    });

    return attributeActives;
  }

  private prepareQueryAttributesFilterable(): any[] {
    const countries = SessionHelper.getAllApplicationActiveCountries(),
      arrayControls = this.form.get('attributes') as FormArray,
      attributeFilterables: any[] = [];
    arrayControls.controls.forEach((control, index) => {
      if (control.get('attribute').value && control.get('attribute').value.id) {
        const attributeId: number = control.get('attribute').value.id;
        countries.forEach((country) => {
          const attributeFilterableValue = this.getAttributeFilterableValue(attributeId, control, country);
          attributeFilterables.push({
            attribute: attributeId,
            filterable: attributeFilterableValue,
            country: country.id,
          });
        });
        if (this.attributesFilterableForAll.has(attributeId) && this.attributesFilterableForAll.get(attributeId) !== null) {
          this.attributesFilterableForAll.delete(attributeId);
        }
      }
    });

    return attributeFilterables;
  }

  private prepareQueryAttributesType(): any[] {
    const countries = SessionHelper.getAllApplicationActiveCountries(),
      arrayControls = this.form.get('attributes') as FormArray,
      attributeTypes: any[] = [];

    arrayControls.controls.forEach((control: AbstractControl) => {
      if (control.get('attribute').value && control.get('attribute').value.id) {
        const attributeId: number = control.get('attribute').value.id;
        countries.forEach((country: ICountry) => {
          const attributeTypeValue = this.getAttributeTypeValue(attributeId, control, country);
          attributeTypes.push({
            attribute: attributeId,
            type: attributeTypeValue,
            country: country.id,
          });
        });
        if (this.attributesTypeForAll.has(attributeId) && this.attributesTypeForAll.get(attributeId) !== null) {
          this.attributesTypeForAll.delete(attributeId);
        }
      }
    });

    return attributeTypes;
  }

  private getAttributeActiveValue(attributeId: number, control: AbstractControl, country: ICountry) {
    if (this.attributesActiveForAll.has(attributeId) && this.attributesActiveForAll.get(attributeId) !== null) {
      return this.attributesActiveForAll.get(attributeId);
    }

    if (country.id === this.country.id) {
      return !!control.get('active').value;
    }

    const attributeActive = this.model && this.model.attributeActives.find((attra: any) => {
      return attributeId === attra.attribute.id && country.id === attra.country.id;
    });

    return attributeActive ? !!attributeActive.active : false;
  }

  private getAttributeFilterableValue(attributeId: number, control: AbstractControl, country: ICountry) {
    if (this.attributesFilterableForAll.has(attributeId) && this.attributesFilterableForAll.get(attributeId) !== null) {
      return this.attributesFilterableForAll.get(attributeId);
    }

    if (country.id === this.country.id) {
      return !!control.get('filterable').value;
    }

    const attributeFilterable = this.model && this.model.attributeFilterables.find((attrf: any) => {
      return attributeId === attrf.attribute.id && country.id === attrf.country.id;
    });

    return attributeFilterable ? !!attributeFilterable.filterable : false;
  }

  private getAttributeTypeValue(attributeId: number, control: AbstractControl, country: ICountry) {
    if (this.attributesTypeForAll.has(attributeId)) {
      return this.attributesTypeForAll.get(attributeId);
    }

    if (country.id === this.country.id) {
      return null != control.get('type').value ? control.get('type').value.value : this.attributeTypes[0].value;
    }

    const attributeType = this.model.attributeTypes.find((attrt: any) => {
      return attributeId === attrt.attribute.id && country.id === attrt.country.id;
    });

    return attributeType ? attributeType.type : null;
  }

  private addAttribute(model?: IAttributeAttributeSet, filterable?: boolean, active?: boolean, type?: IAttributeType, error: boolean = false): void {
    const arrayControls = this.form.get('attributes') as FormArray;

    arrayControls.push(new FormGroup({
      id: new FormControl(model ? model.id : null),
      attribute: new FormControl(model ? model.attribute : null),
      required: new FormControl(model ? model.required : null),
      filterable: new FormControl(!!filterable),
      active: new FormControl(!!active),
      type: new FormControl(undefined !== type ? this.attributeTypes.find((attributeType: any) => attributeType.value === type.type) : this.attributeTypes[0]),
      position: new FormControl(model ? model.position : arrayControls.length + 1),
      error: new FormControl(error),
    }));
  }

  private getAttributes(): void {
    this.attributeResource.getMany()
      .takeUntil(this.destroyed$)
      .subscribe((response: any[]) => {
        response.forEach((attribute) => {
          attribute.name = attribute.translations[this.currentLocale].name;
        });
        this.attributes = response;
      }
      );
  }

  private getAttributeTypes(): void {
    this.attributeTypes.push({name: '', value: null});

    this.attributeTypeWebsiteResource.cGet({}, { returnHydraMembers: true })
      .takeUntil(this.destroyed$)
      .subscribe((response: any[]) => {
        response.forEach((type) => {
          this.attributeTypes.push({name: type, value: type});
        });
      });
  }

  private switchAttributeActive(switchValue: boolean, index?: any): void {
    if (this.getAttributeForm(index).get('attribute').value !== null && this.currentLocale === LOCALE_FR) {
      this.dialogService.confirm(switchValue ?
        this.translate('PAGE.ATTRIBUTE_SETS.ACTIVE_CONFIRM.ACTIVE_FOR_ALL') :
        this.translate('PAGE.ATTRIBUTE_SETS.ACTIVE_CONFIRM.DEACTIVATE_FOR_ALL'))
        .then(() => {
          this.setAttributeActiveForAll(this.getAttributeForm(index).get('attribute').value.id, switchValue);
        })
        .catch(() => {
          this.setAttributeActiveForAll(this.getAttributeForm(index).get('attribute').value.id, null);
        });
    }
  }

  private setAttributeActiveForAll(id: number, active?: boolean) {
    this.attributesActiveForAll.set(id, active);
  }

  private switchAttributeFilterable(switchValue: boolean, index?: any): void {
    if (this.getAttributeForm(index).get('attribute').value !== null && this.currentLocale === LOCALE_FR) {
      this.dialogService.confirm(switchValue ?
        this.translate('PAGE.ATTRIBUTE_SETS.ACTIVE_CONFIRM.ACTIVE_FOR_ALL') :
        this.translate('PAGE.ATTRIBUTE_SETS.ACTIVE_CONFIRM.DEACTIVATE_FOR_ALL'))
        .then(() => {
          this.setAttributeFilterableForAll(this.getAttributeForm(index).get('attribute').value.id, switchValue);
        })
        .catch(() => {
          this.setAttributeFilterableForAll(this.getAttributeForm(index).get('attribute').value.id, null);
        });
    }
  }

  private setAttributeFilterableForAll(id: number, filterable?: boolean) {
    this.attributesFilterableForAll.set(id, filterable);
  }

  private switchAttributeType(value: any, index?: any): void {
    if (this.getAttributeForm(index).get('attribute').value !== null && this.currentLocale === LOCALE_FR) {
      this.dialogService.confirm(value ?
        this.translate('PAGE.ATTRIBUTE_SETS.ACTIVE_CONFIRM.ACTIVE_FOR_ALL') :
        this.translate('PAGE.ATTRIBUTE_SETS.ACTIVE_CONFIRM.DEACTIVATE_FOR_ALL'))
        .then(() => {
          this.setAttributeTypeForAll(this.getAttributeForm(index).get('attribute').value.id, value.value);
        }).catch(() => {
          this.setAttributeTypeForAll(this.getAttributeForm(index).get('attribute').value.id, null);
      });
    }
  }

  private setAttributeTypeForAll(id: number, type?: string) {
    this.attributesTypeForAll.set(id, type);
  }

  private getAttributeForm(index: number): FormGroup {
    return (this.form.get('attributes') as FormArray).controls[index] as FormGroup;
  }

  private deleteAttribute(index: number): void {
    const attributesControl = (this.form.get('attributes') as FormArray).controls;
    const id = attributesControl[index].get('id').value;
    this.deletedAttributeAttributeSetIds.push(id);

    for (let i = 0; i < attributesControl.length; i++) {
      if (i === index) {
        (this.form.get('attributes') as FormArray).controls.splice(i, 1);
      }
    }
  }

  private cancel(): void {
    this.dialogService.confirm(this.translate('PAGE.ATTRIBUTE_SETS.CONFIRM.BACK_TO_LIST'))
      .then(() => this.actions.list.go());
  }

  private delete(): void {
    this.dialogService.confirm(this.translate('PAGE.ATTRIBUTE_SETS.CONFIRM.DELETE'))
      .then(() => {
        this.resource.remove(this.state.params.id)
          .takeUntil(this.destroyed$)
          .subscribe(() => this.actions.list.go({ reload: true, notify: true }));
      });
  }

  private drop(droppedData: any, dropZone: AbstractControl): void {
    const arrayControls: FormArray = this.form.get('attributes') as FormArray;
    const dropped: AbstractControl = arrayControls.controls
      .find(control => droppedData.id === control.get('attribute').value.id);

    if (dropZone.get('attribute').value !== dropped.value) {
      const newIndex: number = arrayControls.controls.indexOf(dropZone);
      const oldIndex: number = arrayControls.controls.indexOf(dropped);

      if (newIndex >= arrayControls.controls.length) {
        let k: number = newIndex - arrayControls.controls.length + 1;
        while (k--) {
          arrayControls.controls.push(undefined);
        }
      }
      arrayControls.controls.splice(newIndex, 0, arrayControls.controls.splice(oldIndex, 1)[0]);
    }
  }
}
