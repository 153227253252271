import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractFormComponent } from '@components/generic/Form/abstract-form.component';
import { takeUntil } from 'rxjs/operators';
import { CategoriesResource } from '@components/categories/categories.resource';

@Component({
  selector: 'app-category-blocks-form',
  template: require('./category-blocks-form.component.html'),
})
export class CategoryBlocksFormComponent extends AbstractFormComponent implements OnInit {
  public category: any;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private categoryResource: CategoriesResource,
  ) {
    super($translate, authService, null, state);
  }

  ngOnInit(): void {
    this.categoryResource.get(this.state.params.id).pipe(takeUntil(this.destroyed$)).subscribe((response: any) => {
        this.category = response;
    });
  }
}
