export class LogisticHelper {
  /**
   * Returns a list of assignable warehouses.
   *
   * @param list - warehouses list
   * @returns assignable warehouses list
   */
  public static getAssignableWarehouses(list: any[]): any[] {
    if (null == list) {
      list = [];
    }

    return list.map(item => ({
      'code': item.code,
      'name': item.name,
      'assignable': item.assignable,
      'active': item.active,
      'countries': item.countries
    })).filter(item => item.assignable);
  }

  /**
   * Returns a list of assignable carriers.
   *
   * @static
   * @param {any[]} list - Carriers list.
   * @returns {any[]} - Assignable Carriers list.
   * @memberof LogisticHelper
   */
  public static getAssignableCarriers(list: any[]): any[] {
    if (null == list) {
      list = [];
    }

    return list.filter(item => item.assignable);
  }
}
