import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AbstractResource, IRequestOptions } from '@resources';
import { StockRegularizationPackageModel } from './stock-regularization-package.model';
import { Observable } from 'rxjs/Observable';
@Injectable()
@Resource({
  routeName: 'stocks.stock_regularization_package',
  entryPoint: '/v2/stock_regularization_packages',
  translationKey: 'STOCK_REGULARIZATION_PACKAGE_VIEW',
  cGetDefaultFilters: () => ({
    page: 1,
    byPage: 100,
  }),
  listColumns: [
    { field: 'sku', translationKey: 'SKU', type: 'string' },
    { field: 'origin', translationKey: 'ORIGIN', type: 'string' },
    { field: 'quantity', translationKey: 'QUANTITY', type: 'number' },
    { field: 'date', translationKey: 'DATE', type: 'string' },
    { field: 'url', translationKey: 'URL', type: 'string', displayRawHtml: true },
  ],
  listModel: StockRegularizationPackageModel,
  formModel: StockRegularizationPackageModel,
  createAvailable: false,
  updateAvailable: false,
  deleteAvailable: false,
  isHydra: true,
})
export class StockRegularizationPackageListResource extends AbstractResource {
  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  cGet(params: object | null = null, options: IRequestOptions = {}, headers?: HttpHeaders): Observable<object> {
    const parameters: {[key: string]: any} = params;

    if (parameters && parameters.hasOwnProperty('startDate')) {
      Object.assign(parameters, { 'date[after]': parameters['startDate'] });
      delete parameters['startDate'];
      delete parameters['date'];
    }

    if (parameters && parameters.hasOwnProperty('endDate')) {
      Object.assign(parameters, { 'date[before]': parameters['endDate'] });
      delete parameters['endDate'];
      delete parameters['date'];
    }

    return super.cGet(parameters, options, headers);
  }
}
