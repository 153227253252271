import { Component, OnInit, Input, HostListener, Inject } from '@angular/core';
import { SortService } from '../services/sort.service';
import { AbstractService } from '@services/abstract.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: '[sortable-column], app-sortable-column',
  template: require('./sortable-column.component.html'),
  styles: [require('./sortable-column.component.scss')]
})
export class SortableColumnComponent extends AbstractService implements OnInit {

  @Input('sortable-column') columnName: string;
  @Input('sort-direction') sortDirection: string = 'none';

  constructor(
    @Inject('StateService') private state: ng.ui.IStateService,
    private sortService: SortService
  ) {
    super();
  }

  @HostListener('click')
  sort() {
    for (const param in this.state.params) {
      if (param.startsWith('sort_') && param !== this.columnName) {
        this.state.params[param] = undefined;
      }
    }

    switch (this.sortDirection) {
      case 'asc':
        this.sortDirection = 'desc';
        break;
      case 'desc':
        this.sortDirection = 'none';
        break;
      case 'none':
        this.sortDirection = 'asc';
        break;
    }

    this.sortService.columnSorted({ sortColumn: this.columnName, sortDirection: this.sortDirection });
  }

  ngOnInit() {
    // Inits the sort direction depending on state params
    if (this.state.params[`sort_${this.columnName}`]) {
      this.sortDirection = this.state.params[`sort_${this.columnName}`] || 'none';
    }

    this.sortService.columnSorted$.pipe(takeUntil(this.destroyed$)).subscribe((stream) => {
      if (stream && this.columnName !== stream.sortColumn) {
        this.sortDirection = 'none';
      }
    });
  }
}
