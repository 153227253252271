import { Component, Inject, OnInit } from '@angular/core';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { SessionHelper } from '@helpers';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-website-homepage-seo',
  template: require('./website-homepage-seo.component.html'),
})

export class WebsiteHomepageSeoComponent extends AbstractPageComponent implements OnInit {
  public currentLocales: string[] = SessionHelper.getCountry().locales;
  public descriptionRteOptions: any;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, null, state);
  }

  ngOnInit(): void {
    this.descriptionRteOptions = {
      plugins: 'link',
      toolbar: 'removeformat | styleselect bold link',
      formats: {
        h3: { classes: 'h4-like' },
        a : { classes: 'text-decoration-underline' },
        p: { classes: 'p-like' },
      },
      style_formats: [
        { title: 'Heading', block: 'h3', classes: 'h4-like' },
        { title: 'Paragraph', block: 'p', classes: 'p-like' },
      ],
      cleanup: true,
      cleanup_on_startup: true,
      force_p_newlines: true,
      force_br_newlines: false,
      verify_css_classes: true,
      verify_html: true,
      keep_styles: false,
      forced_root_block: '',
      valid_elements: 'a[!href|class:text-decoration-underline|target],b,p,h3[class:h4-like],br,strong',
    };
  }

  public hasSeveralTranslations(): boolean {
    return this.currentLocales.length > 1;
  }
}
