export default sparePartRoutes;

/** @ngInject */
function sparePartRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'walipush',
    sidebarSubNav: 'spare-part',
    title: '',
    reloadChangeCountry: 'spare-part.list',
    tabs: [
      {
        label: 'PAGE.PRODUCT.EDIT.TAB.GENERAL.TAB_LABEL',
        state: 'spare-part.edit',
      },
      {
        label: 'PAGE.PRODUCT.EDIT.TAB.PICTURES.TAB_LABEL',
        state: 'spare-part.edit.pictures-mp'
      },
      {
        label: 'PAGE.PRODUCT.EDIT.TAB.LOGISTIC.TAB_LABEL',
        state: 'spare-part.edit.logistic'
      },
      {
        label: 'PAGE.PRODUCT.EDIT.TAB.CARRIERS.TAB_LABEL',
        state: 'spare-part.edit.carriers'
      },
    ],
  };

  $stateProvider
    .state('spare-part', {
      url: '/spare-parts',
      parent: 'walipush',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.SPARE_PARTS',
        stateName: 'spare-part.list'
      }
    })
    .state('spare-part.list', {
      url: `?
        page
        &byPage
      `,
      views: {
        'page@layout': {
          template: '<app-spare-part-list></app-spare-part-list>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.SPARE_PARTS.LIST.TITLE',
      })
    })
    .state('spare-part.search', {
      url: `/search?
        page
        &byPage
        &sparePartId
        &date
      `,
      views: {
        'page@layout': {
          template: '<app-spare-part-search></app-spare-part-search>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.SPARE_PARTS.SEARCH.TITLE',
      })
    })
    .state('spare-part.new', {
      url: '/new',
      breadcrumb: { label: 'BREADCRUMB.NEW', stateName: 'spare-part.new' },
      views: {
        'page@layout': {
          template: '<app-spare-part-page></app-spare-part-page>'
        }
      },
      data: {
        sidebarNav: 'walipush',
        sidebarSubNav: 'spare-part',
        title: '',
        reloadChangeCountry: 'spare-part.list',
        tabs: [
          {
            label: 'PAGE.PRODUCT.EDIT.TAB.GENERAL.TAB_LABEL',
          },
        ],
      },
    })
    .state('spare-part.edit', {
      url: '/:id',
      breadcrumb: { label: 'BREADCRUMB.EDIT', stateName: 'spare-part.edit' },
      views: {
        'page@layout': {
          template: '<app-spare-part-page></app-spare-part-page>'
        }
      },
      data: commonStateData,
    })
    .state('spare-part.edit.product', {
      url: '/product',
      breadcrumb: { label: 'BREADCRUMB.EDIT', stateName: 'spare-part.edit.product' },
      views: {
        'page@layout': {
          template: '<app-spare-part-page></app-spare-part-page>'
        }
      },
      data: commonStateData,

    })
    .state('spare-part.edit.content', {
      url: '/content',
      breadcrumb: { label: 'BREADCRUMB.EDIT', stateName: 'spare-part.edit.content' },

      views: {
        'page@layout': {
          template: '<app-spare-part-page></app-spare-part-page>'
        }
      },
      data: commonStateData,

    })
    .state('spare-part.edit.purchase-service', {
      url: '/purchase-service',
      breadcrumb: { label: 'BREADCRUMB.EDIT', stateName: 'spare-part.edit.purchase-service' },
      views: {
        'page@layout': {
          template: '<app-spare-part-page></app-spare-part-page>'
        }
      },
      data: commonStateData,

    })
    .state('spare-part.edit.carriers', {
      url: '/carriers',
      breadcrumb: { label: 'BREADCRUMB.EDIT', stateName: 'spare-part.carriers' },

      views: {
        'page@layout': {
          template: '<app-spare-part-page></app-spare-part-page>'
        }
      },
      data: commonStateData,
    })
    .state('spare-part.edit.pictures-mp', {
      url: '/pictures-mp',
      breadcrumb: { label: 'BREADCRUMB.EDIT', stateName: 'spare-part.pictures-mp' },
      views: {
        'page@layout': {
          template: '<app-spare-part-page></app-spare-part-page>'
        }
      },
      data: commonStateData,
    })
    .state('spare-part.edit.logistic', {
      url: '/logistic',
      breadcrumb: { label: 'BREADCRUMB.EDIT', stateName: 'spare-part.logistic' },
      views: {
        'page@layout': {
          template: '<app-spare-part-page></app-spare-part-page>'
        }
      },
      data: commonStateData,
    })
    .state('spare-part.edit.cross-up-sells', {
      url: '/cross-up-sells',
      breadcrumb: { label: 'BREADCRUMB.EDIT', stateName: 'spare-part.cross-up-sells' },
      views: {
        'page@layout': {
          template: '<app-spare-part-page></app-spare-part-page>'
        }
      },
      data: commonStateData,
    })
    .state('spare-part.warehouse-agent', {
      url: '/:id/warehouse-agent',
      views: {
        'page@layout': {
          template: '<app-spare-part-page-warehouse-agent></app-spare-part-page-warehouse-agent>'
        }
      },
      data: commonStateData,
    })
    .state('spare-part.edit.marketplace', {
      url: '/:marketplaceCode',
      breadcrumb: { label: 'BREADCRUMB.SUPER_PRODUCT.PRODUCT_MARKETPLACE_GROUPING' },
      views: {
        'page@layout': { template: '<app-spare-part-page></app-spare-part-page>' }
      },
      data: commonStateData,
    })
  ;
}
