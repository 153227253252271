import { Component, Input } from '@angular/core';
import { FormArray } from '@angular/forms';

@Component({
  selector: 'app-banner-form-translation',
  template: require('./banner-form-translation.component.html'),
})
export class BannerFormTranslationComponent {

  @Input() translationsFA: FormArray;
  @Input() tabIndex: number = 0;
}
