import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { SuperProductResource } from '../../super-product.resource';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { CatalogsResource } from '../../../../components/catalogs/catalogs.resource';
import {
  IProductDeclination,
  ISuperProductForm,
  ITranslationReference,
} from '@components/super-product/interfaces/super-product-form.interface';
import { SnackbarService } from '../../../snackbar/snackbar.service';
import { AbstractResource } from '../../../../resources/abstract.resource';
import { CREATION_PAGE, EDITION_PAGE } from '@interfaces/IPageComponent';
import { SessionHelper } from '@helpers/session.helper';
import { IBulkBody, ICatalog, IFormValue } from '@components/super-product/interfaces/form-general.interfaces';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { RemoveTagEvent } from '@progress/kendo-angular-dropdowns/dist/es/common/remove-tag-event';
import { FormNotifierService } from '@services/form-notifier.service';
import { IButtonLinks, IButtonPost } from '@components/generic/buttons/button-links.interface';
import { ICountry } from '@interfaces/ICountry';
import { ProductResource } from '../../../../components/product/product.resource';
import { WarrantyResource } from '@components/warranty/resources';
import {CategoryResource, ManufacturerResource} from '@resources';
import { Subject } from 'rxjs/Subject';
import {HydraHelper} from '@helpers/HydraHelper';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-super-product',
  template: require('./super-product.component.html'),
  providers: [
    { provide: AbstractResource, useClass: SuperProductResource },
    CategoryResource
  ]
})
export class SuperProductComponent extends AbstractPageComponent implements OnInit, OnDestroy {

  public form: FormGroup;
  public catalogs$: Observable<Object>;
  public productDeclinations$: Observable<Object>;
  public buttonLinks: IButtonLinks[];
  public buttonPosts: IButtonPost[] = [{
    params: 'event.duplicate',
    label: 'PAGE.SUPER_PRODUCT.MODAL_EDIT.DUPLICATE.TITLE',
  }];
  public currentCountry: ICountry;
  public warranties$: Observable<object>;
  public manufacturers$: Observable<object>;
  public attributeValues: any;
  private openModalSubject: Subject<void> = new Subject<void>();
  public Categories$: Observable<object>;
  public otherCategories: any[];
  public currentCategories: any[];
  public AllCategories: any[] = [];
  public AllTranslations: any = [];
  public categoryWithTypes: any = [];

  get translationsFA(): FormArray {
    return this.form.get('translations') as FormArray;
  }

  /**
   * Keeps product declinations in memory for the multi select purpose.
   */
  private productDeclinationsMemory: IProductDeclination[];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    private catalogResource: CatalogsResource,
    private productResource: ProductResource,
    private snackbar: SnackbarService,
    private formNotifier: FormNotifierService,
    private warrantyResource: WarrantyResource,
    private manufacturerResource: ManufacturerResource,
    private categoryResource: CategoryResource
  ) {
    super($translate, authService, resource, state);

    this.currentCountry = SessionHelper.getCountry();
  }

  /**
   * @inheritDoc
   */
  ngOnInit(): void {
    this.buildForm();
    if (CREATION_PAGE === this.pageType) {
      this.currentCategories = [];
      this.otherCategories = [];
    }

    if (EDITION_PAGE === this.pageType) {
      this.fillForm();
    }
  }
  private buildForm(): void {
    this.form = this.formBuilder.group({
      skuParent: ['', Validators.required], // sku name of super product
      translations: this.formBuilder.array([]),
      catalog: [{ label: '', id: undefined }],
      repairabilityIndex: [],
      superProductCategories: [null],
      warranty: [{ label: '', id: undefined }],
      manufacturers: [null],
      notifyReviewers: [true],
      internalNotes: [''],
    });

    this.buildTranslationsForm();

    this.catalogs$ = this.catalogResource.getMany({ locale: this.currentLocale, active: true }, { dontUseModel: true, blocking: false });
    this.warranties$ = this.warrantyResource.getMany({ locale: SessionHelper.getUILanguage() }, { blocking: false });
    this.manufacturers$ = this.manufacturerResource.getMany(undefined, { blocking: false });
    this.Categories$ = this.categoryResource.getAllNames({ locale: this.currentLocale }, { blocking: false }).pipe(
      // @ts-ignore
      map(categories => categories.map(category => ({ ...category, nameWithType: this.concatWithCategoryType(category) })))
    );
  }

  /**
   * Builds translations form.
   * When we are on edition page, we pass the translations to it for setting values.
   */
  private buildTranslationsForm(translations?: { [locale: string]: ITranslationReference }): void {
    // build several form group for each country locales
    const formGroups = this.currentCountry.locales.map((locale) => {
      const translation = {
        reference: [translations && translations[locale] ? translations[locale].reference : '', Validators.required],
        locale,
        id: [translations && translations[locale] ? translations[locale].id : ''],
      };
      if (translations && translations[locale] && translations[locale].id) {
        translation.id = [translations[locale].id];
      }
      return this.formBuilder.group(translation);
    });

    // pass the form groups to a form array
    const formArray = this.formBuilder.array(formGroups);

    // replace existing control with a new one, we pass it the form array that is a form group collection
    this.form.setControl('translations', formArray);
  }

  public updateURI(dictionnary: any) {
    for (const key in dictionnary) {
      if (dictionnary[key].id) {
        dictionnary[key].id = HydraHelper.buildIri(dictionnary[key].id, 'super_product_translations');
      }
    }
  }
  public getSuperProductCategory(categoryID: any): void {

    const matchingCategory = this.currentCategories.find(c => c.category.id === categoryID);
    if (typeof matchingCategory === 'undefined') {
      this.AllCategories.push({
        'category': HydraHelper.buildIri(categoryID, 'categories'),
        'position': -1
      });
    } else {
      const test: any = {...matchingCategory};
        test.id = HydraHelper.buildIri(test.id, 'super_product_categories');
        test.category = HydraHelper.buildIri(test.category, 'categories');
      this.AllCategories.push(test);
    }
  }

  public getAllSuperProductCategory(ArraycategoryID: any[]): void {
    if (null === ArraycategoryID) {
      return;
    }

    this.AllCategories = [];
    ArraycategoryID.forEach(c => this.getSuperProductCategory(c));
    const other = this.otherCategories;
    other.forEach((e: any) => {
      if (String(e.category)[0] !== '/') {
        e.id = HydraHelper.buildIri(e.id, 'super_product_categories');
        e.category = HydraHelper.buildIri(e.category.id, 'categories');
      }
    });
    this.AllCategories.concat(other);
  }
  /**
   * Fills form control values with data receive from api.
   */
  private fillForm(): void {
    this.form.addControl('productDeclinations', this.formBuilder.control([]));

    this.productDeclinations$ = this.productResource.getByCountryCodeAndSku(
      this.currentCountry.code, undefined, undefined, { dontUseModel: true, blocking: false }
    );

    (<SuperProductResource>this.resource).getByLocale(this.state.params.id, this.currentLocale)
      .takeUntil(this.destroyed$)
      .subscribe((response: ISuperProductForm) => {
        this.attributeValues = response.attributeValues;
        this.productDeclinationsMemory = response.productDeclinations;

        // tslint:disable-next-line:max-line-length
        this.otherCategories = response.superProductCategories.filter((category: any) => category.category.marketplace !== SessionHelper.getCurrentWebsiteMarketplace().code);
        // tslint:disable-next-line:max-line-length
        this.currentCategories = response.superProductCategories.filter((category: any) => category.category.marketplace === SessionHelper.getCurrentWebsiteMarketplace().code);
        // tslint:disable-next-line:max-line-length
        this.AllTranslations = Object.assign({}, ...Object.keys(response.translations).filter(key => !SessionHelper.getCountry().locales.includes(key)).map(key => ({ [key]: response.translations[key] })));
        // tslint:disable-next-line:max-line-length
        response.superProductCategories = response.superProductCategories.filter((category: any) => category.category.marketplace === SessionHelper.getCurrentWebsiteMarketplace().code).map((category: any) => category.category.id);

        this.buildTranslationsForm(response.translations);
        this.updateURI(this.AllTranslations);
        delete response.translations;

        this.form.removeControl('internalNotes');
        this.form.addControl('internalNotes', this.formBuilder.control([]));
        this.form.patchValue(response);

        this.formatButtonLinks(this.productDeclinationsMemory);
      });
  }

  public submit(event?: any): void {
    const observable: Observable<object> | Observable<object[]> = this.getObservable();

    if (undefined !== this.productDeclinationsMemory) {
      this.productDeclinationsMemory.every((declination: any) => {
        if (declination.sku === this.form.value.skuParent) {
          this.snackbar.warn(this.translate('PAGE.SUPER_PRODUCT.ALERTS.SAME_SKU'));

          return false;
        }

        return true;
      });
    }

    observable
      .takeUntil(this.destroyed$)
      .subscribe((response: ISuperProductForm | Array<object | ISuperProductForm>) => {
        this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));

        if (event && event.redirect) {
          this.state.go(`${this.resource.routeName}.list`);

          return;
        }

        this.formNotifier.notifyFormSubmitted();
        this.state.go(
          `${this.resource.routeName}.edit`,
          { id: !Array.isArray(response) ? (<ISuperProductForm>response).id : this.state.params.id, reload: true }
        )
          ;
      })
      ;
  }

  private getObservable(): Observable<object> | Observable<object[]> {
    const body: ISuperProductForm = this.prepareBody();

    if (CREATION_PAGE === this.pageType) {
      this.resource.create(body, { entryPoint: '/v2/super-products' }).subscribe(
        (response: ISuperProductForm) => this.state.transitionTo('super-product.edit', {id: response.id}, {reload: true})
      );
      return;
    }

    // >>> EDITION_PAGE must handle product declinations
    const productDeclinations: IProductDeclination[] = this.form.controls.productDeclinations.value;
    const observables: Observable<object>[] = [];

    // don't make bulk request if there no new declinations added
    if (
      0 < productDeclinations.length &&
      JSON.stringify(this.productDeclinationsMemory) !== JSON.stringify(productDeclinations)
    ) {
      const superProduct = {
        superProduct: this.state.params.id,
      };
      const bulkBody: IBulkBody = {};

      productDeclinations.forEach((productDeclination: IProductDeclination) => bulkBody[productDeclination.id] = superProduct);

      observables.push(this.productResource.bindProductDeclinationsToSuperProduct(bulkBody));
    }

    observables.push(this.resource.partialUpdate(this.state.params.id, body, {
      entryPoint: `/v2/super_products/${this.state.params.id}`
    }));

    return forkJoin(observables);
  }

  private prepareBody(): ISuperProductForm {
    const formValue: IFormValue = this.form.value;
    let translations: { [locale: string]: ITranslationReference } = {};

    this.translationsFA.controls.forEach((formGroup: FormGroup) => {
      const translationsFormValue: ITranslationReference = formGroup.value;

      translations[translationsFormValue.locale] = {
        reference: translationsFormValue.reference,
        locale: translationsFormValue.locale,
      };
      if (translationsFormValue.id) {
        translations[translationsFormValue.locale].id = HydraHelper.buildIri(translationsFormValue.id, 'super_product_translations');
      }
    });

    const otherIris: any = {};

    Object.keys(this.AllTranslations).forEach((key: any) => {
      otherIris[key] = { id: this.AllTranslations[key].id };
    });

    translations = Object.assign({}, translations, otherIris);
    // @ts-ignore
    const notifyReviewersValue = formValue.notifyReviewers !== '';

    const body: ISuperProductForm = {
      skuParent: formValue.skuParent,
      translations,
      notifyReviewers: notifyReviewersValue,
      internalNotes: formValue.internalNotes,
      attributeValues: undefined
    };
    if (formValue.catalog) {
      body['catalog'] = formValue.catalog.id ? HydraHelper.buildIri(formValue.catalog.id, 'catalogs') : null;
    }

    if (formValue.warranty) {
      body['warranty'] = formValue.warranty.id ? HydraHelper.buildIri(formValue.warranty.id, 'warranties') : null;
    }

    if (formValue.manufacturers) {
      body['manufacturers'] = formValue.manufacturers ? formValue.manufacturers.map((e: any) => HydraHelper.buildIri(e.id, 'manufacturers')) : null;
    }

    this.getAllSuperProductCategory(formValue.superProductCategories);

    if (formValue.superProductCategories) {
      body['superProductCategories'] = this.AllCategories.concat(this.otherCategories);
      this.AllCategories = [];
    }

    return body;
  }

  /**
   * Prevent the deletion of a product declination tag from multi search field because declination is already bind
   * to the super product.
   */
  public onRemoveDeclination(event: RemoveTagEvent): void {
    const checkAlreadyExisting = this.productDeclinationsMemory.find(
      (declination: IProductDeclination) => declination.id === event.dataItem.id
    );

    if (checkAlreadyExisting) {
      event.preventDefault();
      this.snackbar.alert(this.translate('PAGE.SUPER_PRODUCT.ALERTS.DELETE_FORBIDDEN'));
    }
  }

  public formatButtonLinks(products: IProductDeclination[] = []) {
    const children = products.map((product: IProductDeclination) => {
      return {
        url: `#!/products-new/products/${product.id}`,
        label: product.sku,
      };
    });

    this.buttonLinks = [{
      label: 'PAGE.SUPER_PRODUCT.EDIT.BUTTON_LINKS.LINKED_SKU',
      url: '#',
      children,
    }];
  }

  public onPost(params: any) {
    if (params === 'event.duplicate') {
      this.openModalSubject.next();
    }
  }

  /**
   * Checks if the current country has several translations.
   */
  public hasSeveralTranslations(): boolean {
    return this.currentCountry.locales.length > 1;
  }

  private concatWithCategoryType(category: any) {
    if (category.level === 1) {
      category.name = category.name + ' (' + this.translate('PAGE.CMS.FORM.CRUMB.UNIVERSE').toUpperCase() + ')';
    } else {
      category.name = category.name + ' (' + this.translate('PAGE.CMS.FORM.CRUMB.SHELF').toUpperCase() + ')';
    }
    return category;
  }
}
