'use strict'

angular
  .module 'ammsFrontendApp'
  .factory 'httpInterceptor', [
    '$q',
    'LoaderService'
    ($q, LoaderService) ->

      ###
        Transform pending request to a string

        @var query {Object}
        @returns {string}
      ###
      getRequestId = (query) ->
        keys = [query.method, query.url]
        if query.paramSerializer? && query.params
          keys.push query.paramSerializer(query.params)
        keys.join('-')

      {
        request: (config) ->
          LoaderService.add(getRequestId(config), (undefined == config.blocking || config.blocking))
          config || $q.when config

        response: (response) ->
          LoaderService.remove(getRequestId(response.config))
          response || $q.when response

        responseError: (response) ->
          LoaderService.remove(getRequestId(response.config))
          $q.reject response
      }
  ]
