import { IFormViolation } from '@interfaces';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';

/**
 * TODO: this class will be deleted in SU-3487 and all the errors that are handle in the operation commercial template form
 * Offers common features for form component.
 */
export abstract class AbstractFormComponent extends AbstractPageComponent {

  public hasErrors(field: string, violations: IFormViolation[]) {
    return violations.filter((violation: IFormViolation) => {
      return violation.propertyPath === field;
    }).length > 0;
  }

  public hasErrorsInTab(locale: string, violations: IFormViolation[]) {
    return violations.filter((violation: IFormViolation) => {
      if (!violation.propertyPath) {
        return;
      }

      return violation.propertyPath.split('.').shift() === `translations[${locale}]`;
    }).length > 0;
  }

  public clearViolations(event: Event): void {
    const elem = (<HTMLElement>event.target);

    const errorList: NodeListOf<HTMLElement> = elem.parentElement.querySelectorAll('.errors-block');

    for (let i = 0; i < errorList.length; i++) {
      errorList[i].classList.add('hidden');
    }

    elem.parentElement.classList.remove('has-error');
    elem.classList.remove('has-error');
  }
}
