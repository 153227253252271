import { ICustomerDetail, ICustomerDetailOrder } from '../interfaces';
import { DATE_SHORT_FORMAT, DATE_SHORT_INTERNATIONAL_FORMAT } from '@constants';
import * as moment from 'moment';
import { IAddress } from '@models/address.interface';

export class CustomerDetailModel implements ICustomerDetail {
  [key: string]: any;
  public id: number;
  public username: string|null;
  public gender: string|null;
  public birthdate: string|null;
  public addresses: IAddress[];
  public orders: ICustomerDetailOrder[];
  public firstName: string;
  public lastName: string;
  public locale: string|null;
  public addressesPhones: string[];
  public ordersPhones: object;
  public firstOrder: string|null;
  public lastOrder: string|null;
  public callbackUrl: string|null;
  public authenticationFailureAttempts: number|null;
  public email: string|null;
  public contactMethod: string|null;
  public collaborator: object|null;

  constructor(customerCard: ICustomerDetail) {
    this.id = customerCard.id;
    this.username = customerCard.username || null;
    this.gender = customerCard.gender || null;
    this.birthdate = customerCard.birthdate ?
      moment(customerCard.birthdate, DATE_SHORT_INTERNATIONAL_FORMAT).format(DATE_SHORT_INTERNATIONAL_FORMAT) :
      null
    ;
    this.addresses = customerCard.addresses || [];
    this.orders = customerCard.orders || [];
    this.firstName = customerCard.firstName;
    this.lastName = customerCard.lastName;
    this.locale = customerCard.locale || null;
    this.addressesPhones = this.getAddressesPhones({
        addresses: this.addresses, orders: this.orders
      });
    this.ordersPhones = this.getOrdersPhones(this.orders);
    this.firstOrder = 0 < this.orders.length ? moment(this.orders[0].date).format(DATE_SHORT_FORMAT) : null;
    this.lastOrder = 0 < this.orders.length ? moment(this.orders[this.orders.length - 1].date).format(DATE_SHORT_FORMAT) : null;
    this.callbackUrl = customerCard.callbackUrl;
    this.authenticationFailureAttempts = customerCard.authenticationFailureAttempts;
    this.email = this.username || (0 < this.addresses.length ? this.addresses[0].email : null);
    this.contactMethod = customerCard.contactMethod || null;
    this.collaborator = customerCard.collaborator || null;
  }

  /**
   * Gets phones from:
   * - customer addresses, if customer has no order:
   *     -> return an array of unique phone numbers without null value
   *     @example: ['0612345678','0328373737']
   */
  public getAddressesPhones({addresses = [], orders = []}): string[] {
    if (0 === addresses.length && 0 === orders.length || 0 !== orders.length) {
      return [];
    }

    if (0 === orders.length) {
      const phones1 = addresses.map((address) => {
        return address.phone1;
      });

      const phones2 = addresses.map((address) => {
        return address.phone2;
      });

      return [...new Set([...phones1, ...phones2])].filter((phone: string|null) => !!phone);
    }
  }

  /**
   * Gets phones from:
   * - customer orders, if customer has orders
   *     -> return an object of unique phone numbers as key with their values equal to the number of time they are found
   *        (null values for phone numbers are skipped)
   *     @example: {
   *       '0612345678': 2,
   *       '0687654321': 1,
   *       '0327722772': 1,
   *     }
   */
  public getOrdersPhones(orders: ICustomerDetailOrder[] = []): object {
    if (0 === orders.length) {
      return {};
    }

    const phonesPresence: any = {};

    orders.forEach((order: ICustomerDetailOrder) => {
      const phonesOrderItem = [
        ...new Set([
          order.shippingAddress.phone1,
          order.shippingAddress.phone2,
          order.billingAddress.phone1,
          order.billingAddress.phone2
        ])
      ].filter((phone: string|null) => !!phone);

      phonesOrderItem.forEach((phone) => {
        if (phonesPresence.hasOwnProperty(phone)) {
          phonesPresence[phone]++;
        } else {
          phonesPresence[phone] = 1;
        }
      });
    });

    return phonesPresence;
  }
}
