import { AbstractResource } from '../../../../resources/abstract.resource';
import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  entryPoint: '/v2/task_additional_property_values',
  isHydra: true,
})
export class TaskAdditionalPropertyValueResource extends AbstractResource {

  protected nullableProperties: string[] = ['value'];

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }
}
