export default refundsRoutes;

/** @ngInject */
function refundsRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'walipush',
    sidebarSubNav: 'refunds',
    title: '',
    reloadChangeCountry: 'refunds.list',
  };

  $stateProvider
    .state('refunds', {
      url: '/refunds',
      parent: 'walipush',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.REFUNDS',
        stateName: 'refunds.list'
      }
    })
    .state('refunds.list', {
      url: `?
        page
        &byPage
        &operation[]
        &archived[]
        &dateStartField
        &dateEndField
        &order.orderId
        &order.id
        &order.marketplace[]
        &status[]
        &providerStatus[]
        &order.billingAddress.countryCode[]
        &transactionCommercialEntity
      `,
      views: {
        'page@layout': {
          template: '<app-refunds-list></app-refunds-list>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.REFUNDS.LIST.TITLE',
      })
    })
  ;
}
