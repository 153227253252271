import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { AbstractFormComponent } from '@components/generic/Form/abstract-form.component';
import { EDITION_PAGE } from '@interfaces/IPageComponent';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ISliderItems, ISliderItemsFormValue } from '@components/website-homepage/website-homepage-slider-items/models';
import { SnackbarService } from '@components/snackbar';
import { FormNotifierService } from '@services/form-notifier.service';
import { BASE_URL, DATE_FULL_FORMAT, DATE_SHORT_FORMAT } from '@constants';
import * as moment from 'moment';
import { SkuSearchService } from '@services/sku-search.service';
import {WebsiteHomepageSliderItemsResource} from '@components/website-homepage/website-homepage-slider-items/website-homepage-slider-items.resource';

@Component({
  selector: 'app-website-homepage-slider-items-list',
  template: require('./website-homepage-slider-items-list.component.html'),
  providers: [
    { provide: AbstractResource, useClass: WebsiteHomepageSliderItemsResource },
    SkuSearchService
  ],
})
export class WebsiteHomepageSliderItemsListComponent extends AbstractFormComponent {
  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }

  public add(): void {
    this.state.go('websiteHomepage.slider-items.new');
  }
}
