import { IHydraMember } from '@interfaces/hydra-resource.interface';
import { EavType } from '@components/eav-attribute/models/eav-type.enum';
import { EavAttributeOptionModel } from '@components/eav-attribute/models/eav-attribute-option.model';

export class EavAttributeModel {
  '@id': string;
  id: number;
  type: EavType;
  code: string;
  label: string;
  regex?: string;
  attributeOptions?: EavAttributeOptionModel[];

  constructor(attribute: IHydraMember) {
    this['@id'] = attribute['@id'];
    this.id = attribute.id;
    this.type = attribute.type;
    this.code = attribute.code;
    this.label = attribute.label;
    this.regex = attribute.regex;
    this.attributeOptions = attribute.attributeOptions;
  }
}
