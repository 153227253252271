export class HydraHelper {
    public static getIris(items: any[], type?: string) {
        const result: string[] = [];

        items.map((item: any) => result.push(item['@id'] || HydraHelper.buildIri(item, type)));

        return result;
    }

    public static buildIri(item: any, type: string): string {
        const id = 'object' === typeof item ? item.id : item;

        if ('string' === typeof id && id.startsWith('/api/v2/')) {
            return id;
        }

        return `/api/v2/${type}/${id}`;
    }
}
