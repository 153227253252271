import {Injectable} from '@angular/core';
import {Resource} from '@decorators';
import {AbstractResource} from '@resources';
import {HttpClient} from '@angular/common/http';
import {UnpairedModel} from '@components/unpaired/models/unpaired.model';
import {Observable} from 'rxjs/Observable';
import {IExportOptions} from '@interfaces';
import * as moment from 'moment';
import {DATE_SHORT_INTERNATIONAL_FORMAT} from '@constants';
import {SessionHelper} from '@helpers';

const exportOptions: IExportOptions[] = [
  {
    entryPoint: '/v2/export',
    responseType: 'text',
    type: 'text/plain',
    filename: `export-unpaired-count${moment().format(DATE_SHORT_INTERNATIONAL_FORMAT)}.csv`,
    translationKey: 'PAGE.UNPAIRED.LIST.FILTERS.EXPORT.UNPAIRED',
    filters: false,
    roles: ['ROLE_WALISOFT_AGENT'],
    name: 'exportUnpaired',
    postOptions: {
      exportCode: 'unpaired',
      formatCode: 'csv_excel',
      dryRun: false,
      deferred: false,
      split: false,
      serializationGroups: [],
      async: false
    }
  }
];

@Injectable()
@Resource({
  routeName: 'unpaired',
  entryPoint: '/v2/order_items/unpaired',
  exportOptions,
  translationKey: 'UNPAIRED',
  model: UnpairedModel,
  listColumns: [
    {field: 'sku', translationKey: 'SKU'},
    {field: 'package_number', translationKey: 'PACKAGE_NUMBER'},
    {field: 'total', translationKey: 'TOTAL'},
    {field: 'warehouse', translationKey: 'WAREHOUSE'},
  ],
  isHydra: true,
  deleteAvailable: false,
  updateAvailable: false,
  createAvailable: false,
})
export class UnpairedResource extends AbstractResource {
  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  public cGet(params: any = {}, options: any = {}): Observable<object> {
    delete params.date;
    return super.cGet(params, options);
  }
}
