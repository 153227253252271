export default grcRoutes;

/** @ngInject */
function grcRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'walipush',
    sidebarSubNav: 'grc',
    title: '',
    reloadChangeCountry: 'grc.dashboard',
  };

  $stateProvider
    .state('grc', {
      url: '/grc',
      parent: 'walipush',
      abstract: true,
      breadcrumb: {
        label: 'SIDEBAR.LABEL.GRC',
        stateName: 'grc.list'
      },
    })
    .state('grc.list', {
      url: `/products?
      page
      &reference
      &sku`,
      views: {
        'page@layout': {
          template: '<app-grc-product-list></app-grc-product-list>'
        }
      },
      breadcrumb: {
        label: 'SIDEBAR.LABEL.PRODUCT_INFOS',
      },
      data: Object.assign(commonStateData, {
        title: 'SIDEBAR.LABEL.PRODUCT_INFOS',
        showCountries: true
      })
    })
    .state('grc.detail', {
      url: '/products/{id}',
      views: {
        'page@layout': {
          template: '<app-grc-product-detail></app-grc-product-detail>'
        }
      },
      breadcrumb: {
        label: 'PAGE.GRC.DETAIL',
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.GRC.DETAIL',
        showCountries: true
      })
    })
    .state('grc.dashboard', {
      url: '/dashboard',
      views: {
        'page@layout': {
          template: '<app-grc-dashboard></app-grc-dashboard>'
        }
      },
      breadcrumb: {
        label: 'PAGE.GRC.DASHBOARD.TITLE'
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.GRC.DASHBOARD.TITLE',
        showCountries: true
      })
    })
    .state('grc.tickets', {
      url: `/tickets?
        page
        &byPage
        &status
        &agent
        &createdAtStart
        &createdAtEnd
        &type[]`,
      views: {
        'page@layout': {
          template: '<app-grc-tickets-list></app-grc-tickets-list>'
        }
      },
      breadcrumb: {
        label: 'PAGE.GRC.DASHBOARD.TITLE'
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.GRC.DASHBOARD.TICKETS_REPORTING',
        showCountries: true
      })
    })
  ;
}
