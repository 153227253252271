import { Component, Inject } from '@angular/core';
import { AbstractResource } from '@resources';
import { AuthService } from '@services';
import { SnackbarService } from '@components/snackbar';
import { StockRegularizationPackageListResource } from '@components/stock/stock-regularization-package/stock-regularization-package-list.resource';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { StockRegularizationPackageListFiltersService } from '@components/stock/stock-regularization-package/stock-regularization-package-list-filters.service';
import { AbstractFormComponent } from '@components/generic/Form/abstract-form.component';

@Component({
  selector: 'app-stock-regularization-package-list',
  template: require('./stock-regularization-package-list.component.html'),
  providers: [
    { provide: AbstractResource, useClass: StockRegularizationPackageListResource },
    { provide: AbstractFiltersFieldsService, useClass: StockRegularizationPackageListFiltersService }
  ],
})
export class StockRegularizationPackageListComponent extends AbstractFormComponent {
  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private snackbar: SnackbarService
  ) {
    super($translate, authService, resource, state);
  }
}
