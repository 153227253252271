import { Resource } from '@decorators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractResource } from '@resources';

@Injectable()
@Resource({
  entryPoint: '/v2/order-manager/addresses',
  isHydra: true,
  deleteAvailable: false,
  updateAvailable: true,
  createAvailable: false,
})
export class OrderManagerAddressResource extends AbstractResource {

  protected nullableProperties: string[] = ['province', 'phone2', 'company', 'addressLine2', 'addressLine3'];

  constructor(http: HttpClient) {
    super(http);
  }
}
