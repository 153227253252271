import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { Component, Inject } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AttributeResource } from '@resources/attribute.resource';
import { AbstractResource } from '@resources/abstract.resource';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { AttributeListFiltersService } from '@components/attribute/list/services/attribute-list-filters.service';

@Component({
  selector: 'app-attribute-list',
  template: require('./attribute-list.component.html'),
  styles: [require('./attribute-list.component.scss')],
  providers: [
    { provide: AbstractResource, useClass: AttributeResource },
    { provide: AbstractFiltersFieldsService, useClass: AttributeListFiltersService },
  ]
})
export class AttributeListComponent extends AbstractPageComponent {

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }
}
