export default shippingPriceRoutes;

/** @ngInject */
function shippingPriceRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'carrier',
    sidebarSubNav: 'shippingPrices',
    title: '',
    reloadChangeCountry: 'shipping-prices.list',
  };

  $stateProvider
    .state('shipping-prices', {
      url: '/shipping-prices',
      parent: 'walipush',
      abstract: true,
      breadcrumb: {
        label: 'SIDEBAR.LABEL.SHIPPING_PRICE',
        stateName: 'shipping-prices.list'
      }
    })
    .state('shipping-prices.list', {
      url: `?
        page&
        byPage&
      `,
      views: {
        'page@layout': {
          template: '<app-shipping-price-list></app-shipping-price-list>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'SIDEBAR.LABEL.SHIPPING_PRICE',
      })
    })
    .state('shipping-prices.edit', {
      url: '/:id',
      breadcrumb: { label: 'BREADCRUMB.EDIT', stateName: 'shipping-prices.edit' },
      views: {
        'page@layout': { template: '<app-shipping-price-form></app-shipping-price-form>' }
      },
      data: Object.assign(commonStateData, {
        title: 'SIDEBAR.LABEL.SHIPPING_PRICE',
      })
    })
  ;
}
