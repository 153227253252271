import { Component, Inject, OnInit } from '@angular/core';
import { ReverseResource } from './resources';
import { Subscription } from 'rxjs/Subscription';
import { AbstractPageComponent } from '../generic/abstract-page.component';
import { AuthService } from '@services';
import { IReverse, IReverseProduct } from '@components';
import { IBusinessObject } from '@components/generic/task-manager/interfaces';
import { BUSINESS_OBJECT_REVERSE_PRODUCT } from '@constants/business-object.constants';

@Component({
  selector: 'app-reverse-case-tasks',
  template: require('./reverse-case-tasks.component.html'),
})
export class ReverseCaseTasksComponent extends AbstractPageComponent implements OnInit {

  public readonly businessObjectType: string = BUSINESS_OBJECT_REVERSE_PRODUCT;
  public reverseProducts: IBusinessObject[];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: ReverseResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    const subscriber: Subscription = this.resource.get(this.state.params.id).subscribe((response: IReverse) => {
      this.reverseProducts = response.reverseProducts.map((reverseProduct: IReverseProduct) => {
        return Object.assign({businessObjectTitle: this.getTitle(reverseProduct)}, reverseProduct);
      });
    }, undefined, () => subscriber.unsubscribe());
  }

  private getTitle(reverseProduct: IReverseProduct): string {
    const title = reverseProduct.sku;
    const expectedPackages = reverseProduct.reverseProductPackages.filter((reverseProductPackage) => {
      return reverseProductPackage.expected && 'waiting' === reverseProductPackage.receiveStatus;
    });

    return `${title} - ${expectedPackages.length} / ${reverseProduct.reverseProductPackages.length}`;
  }
}
