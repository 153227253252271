import { Component, EventEmitter, Inject, Input, OnChanges, Output } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { CarrierService, WarehousesService } from '@components/export-logistics/services';

export interface ICarrierGroup {
  iri: string;
  code: string;
  name: string;
  checked: boolean;
  volume?: number;
}

@Component({
  selector: 'app-choice-carrier-groups-form',
  template: require('./choice-carrier-groups-form.component.html'),
})
export class ChoiceCarrierGroupsFormComponent extends AbstractComponent implements OnChanges {

  public form: FormGroup;
  private _carrierGroups: ICarrierGroup[];

  @Input() warehousesChecked: any[];

  @Input() set carrierGroups(carrierGroups: ICarrierGroup[]) {
    this._carrierGroups = carrierGroups;
    this.setControls();
    this.carrierService.setCarriersChecked(carrierGroups.filter((carrierGroup: ICarrierGroup) => carrierGroup.checked));
  }

  @Output() public onFieldSelected: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private fb: FormBuilder,
    public carrierService: CarrierService,
    private warehousesService: WarehousesService
  ) {
    super($translate, authService, null, state);

    this.form = this.fb.group({
      carrierGroups: this.fb.array([]),
    });
  }

  get carrierGroups(): ICarrierGroup[] {
    return this._carrierGroups;
  }

  get carrierGroupsFA(): FormArray { return this.form.get('carrierGroups') as FormArray; }


  ngOnChanges() {
    if (this.warehousesChecked.length === 1) {
      const controls = (<FormArray>this.form.get('carrierGroups')).controls;
      controls.forEach((control, index) => {
        this.carrierGroups[index].checked = false;
        control.get('checked').setValue(false);

        this.carrierGroups[index].volume = 0;
        control.get('volume').setValue(0);
      });

      this.carrierService.setCarriersChecked(this.carrierGroups.filter((carrierGroup: ICarrierGroup) => carrierGroup.checked));
      this.emitFieldSelected();
    } else {
      const controls = (<FormArray>this.form.get('carrierGroups')).controls;
      controls.forEach((control, index) => {
        this.carrierGroups[index].volume = 0;
        control.get('volume').setValue(0);
      });

      this.carrierService.setCarriersChecked(this.carrierGroups.filter((carrierGroup: ICarrierGroup) => carrierGroup.checked));
      this.emitFieldSelected();
    }

    this.dryRun();
  }

  /**
   * Sets the form controls.
   */
  private setControls(): void {
    const fgs: FormGroup[] = this.carrierGroups.map(
      (carrierGroup: ICarrierGroup): FormGroup => this.fb.group({ checked: carrierGroup.checked, volume: carrierGroup.volume })
    );
    const fa: FormArray = this.fb.array(fgs);
    this.form.setControl('carrierGroups', fa);
  }

  /**
   * When user checks a carrier, warehouseCarrierGroupAgencies are updated.
   */
  private subscribeToValueChange(): void {
    const controls = (<FormArray>this.form.get('carrierGroups')).controls;

    controls.forEach((control, index) => {
      control.get('volume').valueChanges.forEach((value: number) => {
        this.carrierGroups[index].volume = value;
        if (this.carrierGroups[index].checked) {
          this.carrierService.setCarriersChecked(this.carrierGroups.filter((carrierGroup: ICarrierGroup) => carrierGroup.checked));
          this.emitFieldSelected();
        }
      });

      control.get('checked').valueChanges.forEach((value: boolean) => {
        this.carrierGroups[index].checked = value;
        this.carrierService.setCarriersChecked(this.carrierGroups.filter((carrierGroup: ICarrierGroup) => carrierGroup.checked));
        this.emitFieldSelected();
      });
    });
  }

  /**
   * Emit event to parent to avert it that it must update the warehouse carrier agencies.
   */
  private emitFieldSelected(): void {
    this.onFieldSelected.emit();
  }

  public hasSomeChecked(): boolean {
    return (<FormArray>this.form.get('carrierGroups')).controls.some(control => control.get('checked').value === true);
  }

  public toggleCheck(): void {
    const controls = (<FormArray>this.form.get('carrierGroups')).controls;
    const checkedValue = !this.hasSomeChecked();

    controls.forEach((control, index) => {
      this.carrierGroups[index].checked = checkedValue;
      control.get('checked').setValue(checkedValue);
    });

    this.carrierService.setCarriersChecked(this.carrierGroups.filter((carrierGroup: ICarrierGroup) => carrierGroup.checked));
    this.emitFieldSelected();
  }

  public dryRun(): void {
    this.subscribeToValueChange();
  }
}
