import { IAttributeFamily, IAttributeFamilyTranslation } from '@components/attribute-family/models/attribute-family.interface';
import { IAttribute, IAttributeValue } from '@components/attribute/models/attribute.interface';

export class AttributeFamilyModel implements IAttributeFamily {
  public id: number;
  public attribute: IAttribute;
  public attributeValues: IAttributeValue[];
  public translations: { [key: string]: IAttributeFamilyTranslation };
  public value: any;

  constructor(attributeFamily: IAttributeFamily) {
    this.id = attributeFamily.id;
    this.attribute = attributeFamily.attribute;
    this.translations = attributeFamily.translations;
    this.attributeValues = attributeFamily.attributeValues;
    this.value = attributeFamily.value;
  }
}
