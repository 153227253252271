import {
  ICommercialOperations,
  ICommercialOperationsTranslation,
  IWebsiteUrls
} from './commercial-operations.interface';
import { ICountry } from '@interfaces';
import { SessionHelper } from '@helpers';
import { IButtonLinks } from '@components/generic/buttons/button-links.interface';
import { NO_INDEX_NO_FOLLOW } from '@components/categories';

const defaultBackgroundColor = '#FFFF00';
const defaultFontColor = '#000';

export class CommercialOperationsModel implements ICommercialOperations {
  '@id': string;
  id: string;
  marketplace: string | any;
  type: string;
  startDate: any;
  startTime: any;
  endDate: any;
  endTime: any;
  translations: ICommercialOperationsTranslation[];
  priority: number;
  defaultSorting: string;
  defaultDirection: string | null;
  country: ICountry;
  websiteUrls: IWebsiteUrls;
  productMarketplaceCount: number;
  buttonLinks: IButtonLinks[];
  bgFlashSaleColor: string;
  fontFlashSaleColor: string;
  archived: boolean;
  goodDeals: boolean;
  percentage: number;
  minimumAmount?: number;
  maximumAmount?: number;
  validityPeriod?: number;

  constructor(commercialOperation: ICommercialOperations = {
    '@id': '',
    id: '',
    marketplace: '',
    type: '',
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null,
    translations: [],
    priority: 1,
    defaultSorting: '',
    defaultDirection: null,
    country: null,
    websiteUrls: null,
    productMarketplaceCount: 0,
    bgFlashSaleColor: '',
    fontFlashSaleColor: '',
    archived: false,
    goodDeals: false,
    percentage: null,
    minimumAmount: null,
    maximumAmount: null,
    validityPeriod: null
  }) {
    const countries = SessionHelper.getCountries();
    this['@id'] = commercialOperation['@id'];
    this.id = commercialOperation.id;
    this.marketplace = commercialOperation.marketplace;
    this.type = commercialOperation.type;
    this.startDate = commercialOperation.startDate ? commercialOperation.startDate : null;
    this.startTime = commercialOperation.startTime ? new Date(commercialOperation.startDate) : null;
    this.endDate = commercialOperation.endDate ? commercialOperation.endDate : null;
    this.endTime = commercialOperation.endTime ? new Date(commercialOperation.endDate) : null;
    this.translations = commercialOperation.translations ? this.formatTranslations(commercialOperation.translations) : [];
    this.priority = commercialOperation.priority;
    this.defaultSorting = commercialOperation.defaultSorting;
    this.defaultDirection = commercialOperation.defaultDirection;
    this.country = commercialOperation.country
      ? countries.find(c => commercialOperation.country.code === c.code)
      : SessionHelper.getCountries()[0]
    ;
    this.websiteUrls = commercialOperation.websiteUrls;
    this.productMarketplaceCount = commercialOperation.productMarketplaceCount;
    this.buttonLinks = commercialOperation.websiteUrls ? this.formatLinks(commercialOperation.websiteUrls) : [];
    this.fontFlashSaleColor = commercialOperation.fontFlashSaleColor || defaultFontColor;
    this.bgFlashSaleColor = commercialOperation.bgFlashSaleColor || defaultBackgroundColor;
    this.archived = commercialOperation.archived;
    this.goodDeals = commercialOperation.goodDeals;
    this.percentage = commercialOperation.percentage;
    this.minimumAmount = commercialOperation.minimumAmount;
    this.maximumAmount = commercialOperation.maximumAmount;
    this.validityPeriod = commercialOperation.validityPeriod;
  }

  private formatTranslations(translations: any): ICommercialOperationsTranslation[] {
    return Object.keys(translations).map((locale: any) => {
      return {
        title: translations[locale].title,
        description: translations[locale].description,
        locale: translations[locale].locale,
        id: translations[locale]['@id'],
        titleSeo: translations[locale].titleSeo,
        metaDescriptionSeo: translations[locale].metaDescriptionSeo,
        longDescription: translations[locale].longDescription,
        shortDescription: translations[locale].shortDescription,
        seoMetaRobot: translations[locale].seoMetaRobot
      };
    });
  }

  private formatLinks(websiteUrls: IWebsiteUrls) {
    return Object.keys(websiteUrls).map(key => {
      return {
        translationParams: key,
        url: websiteUrls[key]
      };
    });
  }
}
