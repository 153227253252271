import { ISuperProduct, ISuperProductsCategories } from '@components/super-product-categories/models/super-product-categories.interface';
import { TranslationHelper } from '@helpers';

export class SuperProductCategoriesModel {
  '@id'?: string;
  id?: number;
  category?: string;
  superProduct: ISuperProduct;
  featured?: boolean;
  position?: number;
  scheduleRemove?: boolean;
  lengowShelf?: string;
  productsUnlisted?: boolean;

  constructor(superProductCategories: ISuperProductsCategories) {
    this['@id'] = superProductCategories['@id'];
    this.id = superProductCategories.id;
    this.category = superProductCategories.category;
    this.superProduct = superProductCategories.superProduct;
    this.featured = superProductCategories.featured;
    this.position = superProductCategories.position;
    this.scheduleRemove = superProductCategories.scheduleRemove;

    const lengowShelfTranslation = TranslationHelper.getTranslation(superProductCategories.lengowShelf);
    this.lengowShelf = lengowShelfTranslation
      ? lengowShelfTranslation.lengowName
      : (superProductCategories.lengowShelf ? superProductCategories.lengowShelf['@id'] : null)
    ;
    this.productsUnlisted = superProductCategories.productsUnlisted;
  }
}
