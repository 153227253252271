import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { CategoriesResource } from '@components/categories/categories.resource';
import { CategoryResource } from '@resources/category.resource';
import { ITab, ITabTranslation, TabModel } from '@components/tab/models';
import { TabResource } from '@components/tab';
import { TabManagementComponent } from '@components/generic/tab-management';
import { CategoriesFormComponent } from '@components/categories/forms/categories-form/categories-form.component';
import { IFormViolation } from '@interfaces/IFormViolation';
import { SnackbarService } from '@components/snackbar';
import { SessionHelper } from '@helpers/session.helper';
import { DATE_SHORT_FORMAT, DATE_FULL_FORMAT } from '@constants/date';
import { ColorHelper } from '@helpers/ColorHelper';
import * as moment from 'moment';
import { CategoriesModel, ICategories, ICategoriesTranslation } from '@components/categories/models';
import { MarketplaceHelper } from '@helpers/MarketplaceHelper';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { ICategory } from '@interfaces';
import { Observable } from 'rxjs/Observable';
import { TabFileUploadService } from '@components/tab/services/tab-file-upload.service';

@Component({
  selector: 'app-categories',
  template: require('./categories.component.html'),
  styles: [require('./categories.component.scss')],
  providers: [TabFileUploadService],
})

export class CategoriesComponent extends AbstractPageComponent implements OnInit {
  @ViewChild(TabManagementComponent) tabManagementComponent: TabManagementComponent;
  @ViewChild(CategoriesFormComponent) categoriesFormComponent: CategoriesFormComponent;

  public inCreation: boolean;
  public model: ICategories;
  public tabModel: ITab;
  public violations: IFormViolation[]|any = [];
  public parentCategories: any = [];
  public isUniverse: boolean = false;
  public customPageTitle: string;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: CategoriesResource,
    @Inject('StateService') state: ng.ui.IStateService,
    public tabResource: TabResource,
    private categoryResource: CategoryResource,
    @Inject('DialogService') private dialog: any,
    private snackbar: SnackbarService,
    private tabFileUploadService: TabFileUploadService,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    this.inCreation  = !this.state.params.id;

    this.categoryResource.getAllNames({level: 1, locale: this.currentLocale}, { dontUseModel: true })
      .subscribe((response: ICategory[]) => {
        this.parentCategories = response;
      })
    ;

    if (!this.inCreation) {
      this.resource.get(this.state.params.id)
        .takeUntil(this.destroyed$)
        .concatMap((response: ICategories) => {
          this.model = response;
          return this.tabResource.getMany({category: this.model.id}, {model: TabModel});
        })
        .concatMap((response: ITab[]) => {
          return this.tabResource.get(response[0].id, {model: TabModel});
        })
        .subscribe((response: ITab) => {
          this.tabModel = response;
          this.customPageTitle = this.translate('PAGE.CATEGORIES.EDIT.TITLE');
          if (this.tabModel.category) {
            this.isUniverse = !(this.tabModel.parent && this.tabModel.parent.category);
            const translationKey = this.tabModel.parent && this.tabModel.parent.category ? 'PAGE.CMS.FORM.CRUMB.SHELF' : 'PAGE.CMS.FORM.CRUMB.UNIVERSE';
            this.customPageTitle += ' (' + this.translate(translationKey).toLowerCase() + ')';
          }

          const productsTab: any = this.state.current.data.tabs.find((item: any) => {
            return item.state === 'categories.edit.superProducts';
          });

          if (productsTab) {
            productsTab.hidden = this.isUniverse;
          }
        })
      ;

      return;
    }

    this.model = new CategoriesModel();
    this.tabModel = new TabModel();
  }

  private isDirty(): boolean {
    return this.tabManagementComponent.form.dirty || this.categoriesFormComponent.form.dirty;
  }

  private isValid(): boolean {
    return this.tabManagementComponent.form.valid && this.categoriesFormComponent.form.valid;
  }

  public delete(id: string) {
    this.dialog.confirm(this.translate('PAGE.CATEGORIES.CONFIRM.DELETE'))
      .then(() => {
        this.resource.remove(id)
          .takeUntil(this.destroyed$)
          .subscribe(() => this.actions.list.go({}, {reload: true}));
      });
  }

  public cancel() {
    this.dialog.confirm(this.translate('PAGE.CATEGORIES.CONFIRM.BACK_TO_LIST'))
      .then(() => this.state.go(`${this.tabResource.routeName}.list`));
  }

  public submit(returnToList: boolean) {
    const dirty = this.isDirty();
    const valid = this.isValid();

    while (this.violations.length) { this.violations.pop(); }

    if (!dirty || !valid) {
      let errorMessage = 'ALERTS.NO_CHANGE.FORM';

      if (!valid) {
        errorMessage = 'ALERTS.ERROR.FORM';
      }

      this.snackbar.warn(this.translate(errorMessage));
      return;
    }

    this.save(this.prepareCategoriesBody(this.categoriesFormComponent.form), returnToList);
  }

  public save(categoriesBody: ICategories, returnToList: boolean) {
    let categoryId: string;

    const observable$ = this.inCreation ?
      this.resource.create(categoriesBody, {cleanParams: 'false'}) :
      this.resource.update(this.state.params.id, categoriesBody)
    ;

    observable$
      .concatMap((response: ICategories) => {
        return this.inCreation ?
          this.tabResource.create(this.prepareTabBody(this.tabManagementComponent.form, response['@id'])) :
          this.tabResource.update(
            this.tabModel.id,
            this.prepareTabBody(this.tabManagementComponent.form, response['@id']),
            { dontUseModel: true }
          )
        ;
      })
      .concatMap(
        (tabResponse: ITab) => {
          categoryId = tabResponse.category.split('/').pop();

          const formTranslations: ITabTranslation[] = this.tabManagementComponent.form.value.translations;
          const formData$: Observable<object>[] = this.tabFileUploadService.getFormData$(formTranslations, tabResponse);

          if (0 < formData$.length) {
            return Observable.onErrorResumeNext(formData$);
          }

          return Observable.empty();
        }
      )
      .takeUntil(this.destroyed$)
      .subscribe(
        () => this.snackbar.validate('SAVED'),
        (reject: any) => {
          this.violations = this.formatViolations(reject);
        },
        () => {
          returnToList
            ? this.state.go(`${this.tabResource.routeName}.list`)
            : this.state.go(`${this.resource.routeName}.edit`, { id: categoryId }, { reload: true })
          ;
        }
      )
    ;
  }

  private formatViolations(reject: any): IFormViolation[] {
    if (!reject.hasOwnProperty('violations')) {
      return;
    }

    return reject.violations.map((violation: IFormViolation): IFormViolation => {
      return {
        propertyPath: violation.propertyPath,
        message: violation.message
      };
    });
  }

  public prepareTabBody(managementForm: any, categoriesId: any) {
    return {
      parent: managementForm.value.parent && managementForm.value.parent.id ? managementForm.value.parent.id : null,
      position: managementForm.value.position,
      marketplace: SessionHelper.getMarketplaces().find((item) => item.code.includes('site')).code,
      startDate: managementForm.value.startDate
        ? moment(managementForm.value.startDate, DATE_SHORT_FORMAT).startOf('day').format(DATE_FULL_FORMAT)
        : null,
      endDate: managementForm.value.endDate
        ? moment(managementForm.value.endDate, DATE_SHORT_FORMAT).endOf('day').format(DATE_FULL_FORMAT)
        : null,
      active: managementForm.value.active,
      obfuscated: managementForm.value.obfuscated,
      hiddenMenu: managementForm.value.hiddenMenu,
      category: categoriesId,
      fontColor: ColorHelper.convertHex8ToRgba(managementForm.value.fontColor),
      backgroundColor: ColorHelper.convertHex8ToRgba(managementForm.value.backgroundColor),
      translations: this.getPreparedTranslationsTabs(managementForm.value.translations),
      type: 'universe'
    };
  }

  public prepareCategoriesBody(categoriesForm: any) {
    let categoryParentId = null;

    if (categoriesForm.value.parent) {
      categoryParentId = categoriesForm.value.parent.id
        ? categoriesForm.value.parent.id
        : categoriesForm.value.parent
      ;

      categoryParentId = `/api/v2/categories/${categoryParentId}`;
    }

    return {
      name: categoriesForm.value.name,
      active: categoriesForm.value.active,
      parent: categoryParentId,
      translations: this.getPreparedTranslationsCategories(categoriesForm.value.translations),
      hreflangs: categoriesForm.value.hreflangs,
      marketplace: MarketplaceHelper.getAlicesGardenMarketplace().code,
      defaultSorting: categoriesForm.value.defaultSorting,
      seoMetaRobot: this.model.seoMetaRobot ? this.model.seoMetaRobot : 'index, follow',
      startDate: categoriesForm.value.startDate ? moment(categoriesForm.value.startDate, DATE_SHORT_FORMAT).startOf('day').format(DATE_FULL_FORMAT) : null,
      endDate: categoriesForm.value.endDate ? moment(categoriesForm.value.endDate, DATE_SHORT_FORMAT).endOf('day').format(DATE_FULL_FORMAT) : null,
      position: categoriesForm.value.position,
    };
  }

  private getPreparedTranslationsTabs(translations: ITabTranslation): ITabTranslation[] {
    return translations.reduce(
      (current: ITabTranslation, {name, locale, id}: ITabTranslation) => {
        current[locale] = {
          name,
          locale
        };

        if (id) {
          current[locale].id = `/api/v2/tab_translations/${id}`;
        }
        return current;
      },
      {}
    );
  }

  private getPreparedTranslationsCategories(translations: ICategoriesTranslation): ICategoriesTranslation[] {
    return translations.reduce(
      (
        current: ICategoriesTranslation,
        {name, lengowName, slug, locale, id, shortDescription, longDescription, contentBlock, contentBlockTitle}: ICategoriesTranslation
      ) => {
        if (contentBlock === '') {
          contentBlock = null;
        }
        current[locale] = {
          name,
          lengowName,
          slug,
          locale,
          id,
          shortDescription,
          longDescription,
          contentBlock,
          contentBlockTitle,
        };
        return current;
      },
      {}
    );
  }
}
