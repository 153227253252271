'use strict'

import { SessionHelper, MarketplaceHelper } from '@helpers'
import { BASE_URL_API } from '@constants/config.constants';


###
  @name ProductFiltersCmp
###
angular
  .module('ammsFrontendApp')
  .directive('productFilters', [
    ->
      replace: true
      restrict: 'A'
      templateUrl: require('views/products/list/filters.html')
      controller: 'ProductFiltersCtrl'
      controllerAs: 'filters'
      bindToController:
        filters: '=',
        submitAction: '&'
      scope: true
      link: (scope, elem, attrs) ->
        if ('undefined' == typeof attrs.filters)
          throw new TypeError('filters attribute is required for productFilters directive')

        if ('undefined' == typeof attrs.submitAction)
          throw new TypeError('save-action attribute is required for productFilters directive')
  ])
  .controller('ProductFiltersCtrl', [
    '$scope',
    '$translate',
    'CatalogsResource',
    'ProductResource',
    'SuperProductResource',
    'AuthService',
    ($scope, $translate, CatalogsResource, ProductResource, SuperProductResource, AuthService) ->
      currentLocale = SessionHelper.getLocale()
      currentCountry = SessionHelper.getCountry()
      this.catalogs = []
      this.superProducts = []
      this.marketplaces = MarketplaceHelper.getDisplayedOnProductPageMarketplaces()
      this.entryPoint = BASE_URL_API + '/v2/products/import'
      this.importButton = 'PAGE.PRODUCT.LIST.FILTER.IMPORT.PRODUCTS'
      this.businessObject = 'product'
      this.entryPointProductMarketplace = BASE_URL_API + '/v2/import'
      this.importButtonProductMarketplace = 'PAGE.PRODUCT.LIST.FILTER.IMPORT.PRODUCT_MARKETPLACES'
      this.businessObjectProductMarketplace = 'product-marketplace'
      this.hidden = [
        {
          key: 'null'
          label: $translate.instant('PAGE.PRODUCT.LIST.FILTER.HIDDEN.DATA.0')
        },
        {
          key: 0
          label: $translate.instant('PAGE.PRODUCT.LIST.FILTER.HIDDEN.DATA.1')
        },
        {
          key: 1
          label: $translate.instant('PAGE.PRODUCT.LIST.FILTER.HIDDEN.DATA.2')
        }
      ]
      this.hasLogistic = [
        {
          key: ''
          label: $translate.instant('PAGE.PRODUCT.LIST.FILTER.LOGISTIC.DATA.0')
        },
        {
          key: 0
          label: $translate.instant('PAGE.PRODUCT.LIST.FILTER.LOGISTIC.DATA.1')
        },
        {
          key: 1
          label: $translate.instant('PAGE.PRODUCT.LIST.FILTER.LOGISTIC.DATA.2')
        }
      ]
      this.productStatuses = [
        { label: $translate.instant('PAGE.PRODUCT.LIST.FILTER.ALL_FILTERS'), status: 'null' },
        { label: $translate.instant('PAGE.PRODUCT.STATUSES.STATUS_NEW'), status: 'new' },
        { label: $translate.instant('PAGE.PRODUCT.STATUSES.STATUS_PENDING_CONTENT'), status: 'pending_content' },
        { label: $translate.instant('PAGE.PRODUCT.STATUSES.STATUS_PENDING_TRANSLATION_CHECK'), status: 'pending_translation_check' },
        { label: $translate.instant('PAGE.PRODUCT.STATUSES.STATUS_CONTENT_VALID'), status: 'content_valid' },
        { label: $translate.instant('PAGE.PRODUCT.STATUSES.STATUS_READY_FOR_SALE'), status: 'ready_for_sale' },
        { label: $translate.instant('PAGE.PRODUCT.STATUSES.STATUS_END_OF_LIFE'), status: 'end_of_life' },
      ]
      this.packageType = [
        {
          key: 'all'
          label: $translate.instant('PAGE.PRODUCT.LIST.FILTER.PACKAGE_TYPE.DATA.ALL')
        },
        {
          key: 'multi'
          label: $translate.instant('PAGE.PRODUCT.LIST.FILTER.PACKAGE_TYPE.DATA.YES')
        },
        {
          key: 'mono'
          label: $translate.instant('PAGE.PRODUCT.LIST.FILTER.PACKAGE_TYPE.DATA.NO')
        }
      ]

      this.isGranted = (roles) ->
        return AuthService.isGranted(roles)

      this.$onInit = =>
        # init catalog
        catalogSubscriber = CatalogsResource.getMany({ locale: currentLocale })
          .subscribe((catalogsResponse) =>
            this.catalogs = catalogsResponse.filter((catalog) -> return !!catalog.label)

            catalogSubscriber.unsubscribe()
        )

        # init super product
        superProductSubscriber = SuperProductResource.getSkus({ locale: currentLocale })
          .subscribe((superProducts) =>
            this.superProducts = superProducts

            superProductSubscriber.unsubscribe()
        )

      ###
        Search product by SKU for autocompletion

        @param {string} sku

        @return Promise<object>
      ###
      this.searchSku = (sku) ->
        if (sku.length >= 2)
          return ProductResource.filterByCountryCodeAndSku(currentCountry.code,
            { sku },
            { blocking: false, dontUseModel: true }
          )
            .then((productsResponse) -> return productsResponse.map((product) -> return product.sku))

      $scope.$watch('filters.sku', (newValue) =>
        if (angular.isObject newValue)
          this.filters.sku = newValue.sku
      )

      this.export = (exportType) ->
        if (exportType == 'products')
          return ProductResource.ExportProductsStockByWarehouse(currentCountry.code).subscribe()
        else
          return ProductResource.ExportProductsStockByPackagesAndWarehouse(currentCountry.code).subscribe()

      ###
        Resets filters
      ###
      this.reset = =>
        [
          'page',
          'catalog',
          'superProduct',
          'sku',
          'reference',
          'hasLogistic',
          'stockMin',
          'stockMax'
        ].forEach((key) => this.filters[key] = undefined)

        this.filters['marketplaces[]'] = []
        this.filters.hidden = 0
        this.filters.status = 'null'
        this.filters.packageType = 'all'

      ###
        Submits form
      ###
      this.submit = =>
        if ('undefined' != typeof this.filters.page)
          this.filters.page = 1
        if ('undefined' != typeof this.filters.orderBy)
          this.filters.orderBy = ''
        if ('undefined' != typeof this.filters.direction)
          this.filters.direction = ''

        this.submitAction()

      return this
  ])
