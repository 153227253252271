import { Injectable, Inject } from '@angular/core';
import { AbstractFormFieldBase } from '@components/generic/Form/dynamic/fields/abstract-form-field-base.class';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { FormNotifierService } from '@services/form-notifier.service';
import { TextField } from '@components/generic/Form/dynamic/fields';
import { DropDownListField } from '@components/generic/Form/dynamic/fields/select-field.class';
import { CountryTypeEnum } from '@components/supplier/models/country-type.enum';
import { Subscription } from 'rxjs/Subscription';
import { ICommercialEntity } from '@interfaces';
import { CommercialEntityResource } from '@resources';

@Injectable()
export class SupplierListFiltersService extends AbstractFiltersFieldsService {
  private commercialEntityField: DropDownListField = new DropDownListField({
    fieldName: 'supplierCommercialEntities.commercialEntity',
    label: 'PAGE.SUPPLIER.LIST.FILTER.COMMERCIAL_ENTITY',
    data: [],
    textField: 'label',
    valueField: 'id',
    value: this.filters.get('supplierCommercialEntities.commercialEntity') ? this.filters.get('supplierCommercialEntities.commercialEntity') : null,
    valuePrimitive: true,
  });

  constructor(
    @Inject('StateService') protected state: ng.ui.IStateService,
    @Inject('TranslationService') public $translate: ng.translate.ITranslateService,
    formNotifier: FormNotifierService,
    private commercialEntityResource: CommercialEntityResource
  ) {
    super(formNotifier, state);

    const subscriber: Subscription = this.commercialEntityResource.cGet({ 'pagination': false }, { returnHydraMembers: true })
      .takeUntil(this.destroyed$)
      .subscribe((commercialEntities: ICommercialEntity[]) => {
        subscriber.unsubscribe();

        this.commercialEntityField.data = commercialEntities;
      })
    ;
  }

  public getFields(): AbstractFormFieldBase<any>[] {
    const countries: any[] = [];

    for (const [key, value] of Object.entries(CountryTypeEnum)) {
      countries.push({
        label: value,
        value: key
      });
    }

    return [
      new DropDownListField({
        fieldName: 'address.countryCode',
        label: 'PAGE.SUPPLIER.LIST.FILTER.ADDRESS_COUNTRY_CODE',
        data: countries,
        textField: 'label',
        valueField: 'value',
        value: this.filters.getAll('address.countryCode').length !== 0 ? this.filters.getAll('address.countryCode') : undefined,
        valuePrimitive: true,
      }),
      new TextField({
        fieldName: 'label',
        label: 'PAGE.SUPPLIER.LIST.FILTER.LABEL',
        value: this.filters.getAll('label').length !== 0 ? this.filters.getAll('label') : undefined,
        valuePrimitive: true,
      }),
      new TextField({
        fieldName: 'supplierCommercialEntities.sageIdentifier',
        label: 'PAGE.SUPPLIER.LIST.FILTER.SAGE_IDENTIFIER',
        value: this.filters.getAll('supplierCommercialEntities.sageIdentifier').length !== 0
          ? this.filters.getAll('supplierCommercialEntities.sageIdentifier')
          : undefined
        ,
        valuePrimitive: true,
      }),
      this.commercialEntityField
    ];
  }
}
