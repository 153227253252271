
# With angularjs we have to aliases services to use statical functions and non statical
# in the same file... otherwise it throw `method not exists`
import { SessionHelper as SessionHelperStatics } from '@helpers'

###
  @description Service, allow to change country easily
###
angular
  .module('ammsFrontendApp')
  .service('CountryService', [
    '$listOrderSession',
    '$rootScope',
    'LoaderService',
    'SessionHelper',
    (
      $listOrderSession,
      $rootScope,
      LoaderService,
      SessionHelper
    ) ->
      ###
        Changes country according to the countryCode.

        @params {string} countryCode
      ###
      this.changeCountry = (countryCode) ->
        LoaderService.add('switch-country')

        country = SessionHelperStatics.getCountries()
          .filter((country) -> return countryCode == country.code)
          .shift()

        # Update current country
        SessionHelperStatics.setCurrentUserCountry(country)
        # Update locale for translation by current country
        SessionHelperStatics.setCurrentUserLocale(country.defaultLocale)
        SessionHelperStatics.setCurrentUserCurrency(country.currencyCode)
        $listOrderSession.reset()

        SessionHelper.callRequiredDataForApplication().subscribe((requiredData) ->
          SessionHelper.setRequiredDataInStorage(requiredData)

          LoaderService.remove('switch-country')
          $rootScope.$emit('$stateCountryChangeSuccess')
        )

      return this
  ])
