import { IService } from '@components/service/model/service.interface';
import { Component, Inject, Input } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { AbstractComponent } from '@components/generic/abstract.component';
import {
  MarketplacesCommissionResource
} from '@components/marketplaces-configure/commission/marketplaces-commission.resource';
import {ICommission} from '@components/marketplaces-configure';

@Component({
  selector: 'app-marketplaces-commission-result-list',
  template: require('./marketplaces-commission-result-list.component.html'),
  providers: [
    { provide: AbstractResource, useClass: MarketplacesCommissionResource },
  ],
})
export class MarketplacesCommissionResultListComponent extends AbstractComponent {
  @Input() public locale: string;
  private _items: IService[];
  public translationKey: string = '';

  set items(items: any[]) {
    this._items = items;
  }

  get items(): any[] {
    return this._items;
  }

  constructor(
      @Inject('TranslationService') $translate: ng.translate.ITranslateService,
      authService: AuthService,
      resource: AbstractResource,
      @Inject('StateService') state: ng.ui.IStateService,
  ) {
      super($translate, authService, resource, state);
  }

  public goToEdit(id: any) {
    this.state.go('marketplaces.commission_edit', { id: id}, { reload: true });
  }

  public add() {
    this.state.go('marketplaces.commission_new', null, { reload: true });
  }

  public delete(id: any) {
    this.resource.remove(id).takeUntil(this.destroyed$)
      .subscribe(() => {
          this.state.go('marketplaces.commission', { id: id }, { reload : true });
        }
      );
  }
}
