import { AbstractResource } from '../resources/abstract.resource';
import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  routeName: 'cms',
  entryPoint: '/contentblocks',
})
export class CMSResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  public findByName(name: string, locale: string, options: any): Observable<any> {
    return this.getMany({name, locale}, Object.assign({}, options, {entryPoint: `${this.entryPoint}/names`}));
  }
}
