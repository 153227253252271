import { FactoryProvider } from '@angular/core';

export class HybridHelper {
    static buildProviderForUpgrade(ng1Name: string, ng2Name?: string): FactoryProvider {
      ng2Name = ng2Name || ng1Name;

      return {
        provide: ng2Name,
        useFactory: HybridHelper.buildFactoryForUpgradeProvider(ng1Name),
        deps: ['$injector']
      };
    }

    static buildFactoryForUpgradeProvider(ng1Name: string): Function {
      return (injector: any) => injector.get(ng1Name);
    }
}
