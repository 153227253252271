export default stockRoutes;

/** @ngInject */
function stockRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'walipush',
    sidebarSubNav: 'stocks',
    title: '',
  };

  $stateProvider
    .state('stocks', {
      url: '/stocks',
      parent: 'walipush',
      abstract: true,
      breadcrumb: { label: 'PAGE.STOCK_VIEW.TITLE' }
    })
    .state('stocks.view', {
      url: `?
        page&
        byPage&
        stockType&
        stockSku&
        warehouse
      `,
      views: {
        'page@layout': {
          template: '<app-stock-view></app-stock-view>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.STOCK_VIEW.TITLE',
      })
    })
    .state('stocks.arrivals', {
      url: `/arrivals?
        page&
        byPage&
        container&
        warehouse
      `,
      breadcrumb: { label: 'PAGE.ARRIVAL_VIEW.TITLE' },
      views: {
        'page@layout': {
          template: '<app-arrival-view></app-arrival-view>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.ARRIVAL_VIEW.TITLE',
      })
    })
    .state('stocks.import', {
      url: `/imports?
        page&
        byPage&
      `,
      views: {
        'page@layout': {
          template: '<app-stock-import-list></app-stock-import-list>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.STOCK_IMPORT.TITLE',
      })
    })
    .state('stocks.container', {
      url: '/containers',
      parent: 'walipush',
      abstract: true,
      breadcrumb: { label: 'PAGE.STOCK_VIEW.TITLE' }
    })
    .state('stocks.container.list', {
      url: `?page
        &byPage
        &containerNumber[]
        &sageIdentifier[]
      `,
      breadcrumb: {
        label: 'PAGE.CONTAINER_VIEW.TITLE',
        stateName: 'stocks.container.list'
      },
      views: {
        'page@layout': {
          template: '<app-container-list></app-container-list>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.CONTAINER_VIEW.TITLE',
      })
    })
    .state('stocks.container.new', {
      url: '/new',
      parent: 'stocks.container.list',
      breadcrumb: { label: 'BREADCRUMB.NEW' },
      views: {
        'page@layout': { template: '<app-container-new></app-container-new>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.CONTAINER_VIEW.TITLE'
      })
    })
    .state('stocks.container.edit', {
      url: '/:id',
      parent: 'stocks.container.list',
      breadcrumb: { label: 'PAGE.CONTAINER_VIEW.EDIT.TITLE_WITHOUT_ID' },
      views: {
        'page@layout': {
          template: '<app-container-edit></app-container-edit>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.CONTAINER_VIEW.EDIT.TITLE',
      })
    })
    .state('stocks.stock_regularization_package', {
      url: `/stock-regularization-packages?
        page&
        byPage&
        sku&
        origin&
        startDate&
        endDate
      `,
      breadcrumb: {
        label: 'PAGE.STOCK_REGULARIZATION_PACKAGE_VIEW.TITLE',
        stateName: 'stocks.stock-regularization-package'
      },
      views: {
        'page@layout': {
          template: '<app-stock-regularization-package-list></app-stock-regularization-package-list>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.STOCK_REGULARIZATION_PACKAGE_VIEW.TITLE',
      })
    })
  ;
}
