import { Inject, Injectable } from '@angular/core';
import { IRootScopeService } from 'angular';
import { Title } from '@angular/platform-browser';

@Injectable()
export class TranslatedTitleService {
  constructor(
    @Inject('RootscopeService') rootScope: IRootScopeService,
    @Inject('TranslationService') private $translate: ng.translate.ITranslateService,
    pageTitle: Title,
  ) {
    rootScope.$on('$stateChangeSuccess', (event, toState, toParams) => {
      const params = toParams && toParams.id ? {id: toParams.id} : {};
      const titleFromRoute = toState.data && toState.data.title ? `${this.$translate.instant(toState.data.title, params)} - ` : '';
      const title: string = `${titleFromRoute}sweeek Marketplace Management System`;

      pageTitle.setTitle(title);
    });
  }
}
