import {Component, Inject, Input, OnInit, ViewEncapsulation} from '@angular/core';
import { OrderManagerRelationRessource } from '@components/order-manager/relation/order-manager-relation.ressource';
import { SnackbarService } from '@components/snackbar/snackbar.service';
import { AuthService } from '@services';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AbstractResource } from '@resources/abstract.resource';
import { IRelation } from '@components/order-manager/relation/order-manager-relation.interface';
import moment = require('moment');
import {generateSummaryForTicket} from '@components/order-manager/relation/SummaryHelper';

/**
 * Displays a list of customer tickets.
 */
@Component({
  selector: 'app-customer-detail-walitickets',
  template: require('./customer-detail-walitickets.component.html'),
  styles: [require('./customer-detail-walitickets.component.scss')],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: AbstractResource, useClass: OrderManagerRelationRessource },
  ]
})
export class CustomerDetailWaliticketsComponent extends AbstractComponent implements OnInit {
  public readonly roles: string[] = ['ROLE_WALISOFT_AGENT', 'ROLE_WALISOFT_AGENT'];

  public customerId: string;
  @Input() public allTickets: any = [];
  @Input() public showTabs: boolean = true;
  @Input() public ticketStatus: string = null;
  @Input() public order: any;
  @Input() columns: any = [];
  public modalInfo: any = {};
  public displayModal: boolean = false;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    public resource: OrderManagerRelationRessource,
    private snackbar: SnackbarService,
  ) {
    super($translate, authService, null, state);

    this.customerId = this.state.params.id;
  }
  mapAllTickets(array: IRelation[]) {
    if (this.ticketStatus) {
      array = array.filter((i) => {
        return i.status === this.ticketStatus;
      });
    }
    return array.map((ticket: IRelation): any => {
      const lastAgent = ticket.agents.pop();
      let otherAgents = ticket.agents.map(function(obj) { return obj.agent; });
      otherAgents = otherAgents.filter(function(v, i) { return otherAgents.indexOf(v) === i; });
      if (otherAgents.length === 1 && otherAgents[0] === lastAgent.agent) {
        otherAgents = [];
      }
      return {
        id: ticket.id,
        date: moment(ticket.claimDate).format('DD/MM/Y'),
        order_id: ticket.order.id ? ticket.order.id : ticket.order,
        litigation: ticket.litigation ? this.translate('BOOLEANS.YES') : this.translate('BOOLEANS.NO'),
        origin: ticket.origins.map((origin) => origin.type).join(' / '),
        summary: generateSummaryForTicket(ticket, true, this.$translate),
        attachments: ticket.attachments ? ticket.attachments : [],
        status: ticket.status,
        satisfactionType: ticket.satisfactionType,
        lastAgent: lastAgent,
        agents: otherAgents,
        updatedAt: moment(ticket.updatedAt).format('DD/MM/Y'),
        tooltip: generateSummaryForTicket(ticket, false, this.$translate),
      };
    });
  }

  ngOnInit(): void {
    if (!this.isGranted(this.roles)) {
      this.snackbar.alert(this.translate('ALERTS.AUTHORIZATION_FAIL'));

      return;
    }

    if (this.order) {
      this.resource.getMany({order: this.order}, {})
        .takeUntil(this.destroyed$)
        .subscribe((response: IRelation[]) => {
          this.allTickets = this.mapAllTickets(response);
        });
      return;
    }

    this.resource.getAllTicketForCustomer(this.customerId)
      .takeUntil(this.destroyed$)
      .subscribe((response: IRelation[]) => {
        // @ts-ignore
        this.allTickets = this.mapAllTickets(response['hydra:member']);
      });
  }
  public goToEdit(id: any, ticketId: any) {
    this.state.go('order_manager.relation_edit', { id: id, ticketId: ticketId}, { reload: true });
  }

  public closeTicket(id: any, ticketId: any) {
    this.displayModal = true;
    this.modalInfo = {
      id: id,
      ticketId: ticketId,
    };
  }

  public validateSatisfaction(satisfaction: any): void {
    this.resource.update(this.modalInfo.ticketId,  { status: 'closed', satisfactionType: satisfaction }).takeUntil(this.destroyed$)
      .subscribe(
        (response: any) => {
          this.state.go('order_manager.relation', { id: this.modalInfo.id }, { reload : true });
        }
      );
      this.displayModal = false;
  }
}
