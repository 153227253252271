import { Inject, Injectable } from '@angular/core';
import { FormNotifierService } from '@services/form-notifier.service';
import { ComboSearchField, DateField, MultiSearchField, TextField } from '@components/generic/Form/dynamic/fields';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { AbstractFormFieldBase } from '@components/generic/Form/dynamic/fields/abstract-form-field-base.class';
import * as moment from 'moment';
import { DATE_FULL_FORMAT } from '@constants/date';
import { ICustomer } from '@components/customer/interfaces';
import { CustomerResource } from '../../../../components/customer/customer.resource';
import { DropDownListField } from '@components/generic/Form/dynamic/fields/select-field.class';
import { SessionHelper } from '@helpers';

interface IDropdownData {
  label: string;
  value: string;
}

export const DISCOUNT_TYPES = {
  product: 'product',
  cart: 'cart',
  gift: 'gift',
  credit: 'credit'
};

const DISCOUNT_STATUSES = {
  published: '1',
  pause: '0',
  expired: '-1',
};

const ARCHIVING_STATUSES = {
  archived: '1',
  nonArchived: '0',
};

@Injectable()
export class DiscountFiltersFormService extends AbstractFiltersFieldsService {

  private startDateField: TextField = new TextField({
    fieldName: 'dateBegin',
    value: null,
    hidden: true
  });

  private endDateField: TextField = new TextField({
    fieldName: 'dateEnd',
    value: null,
    hidden: true
  });

  /**
   * Keeps field reference to update dynamically data.
   */
  private customerEmailField: ComboSearchField = new ComboSearchField({
    fieldName: 'customers.username',
    label: 'PAGE.DISCOUNT.FILTER.CUSTOMER_EMAIL.LABEL',
    data: [],
    textField: 'label',
    valueField: 'label',
    value: null,
    filterChange: this.searchCustomer.bind(this),
    valuePrimitive: true,
    allowCustom: true,
  });

  private typeList: IDropdownData[] = [
    { label: '', value: null },
    { label: this.translate.instant('PAGE.DISCOUNT.FILTER.TYPE.DATA.PRODUCT'), value: DISCOUNT_TYPES.product },
    { label: this.translate.instant('PAGE.DISCOUNT.FILTER.TYPE.DATA.CART'), value: DISCOUNT_TYPES.cart },
    { label: this.translate.instant('PAGE.DISCOUNT.FILTER.TYPE.DATA.GIFT'), value: DISCOUNT_TYPES.gift },
    { label: this.translate.instant('PAGE.DISCOUNT.FILTER.TYPE.DATA.CREDIT'), value: DISCOUNT_TYPES.credit },
  ];

  private statusList: IDropdownData[] = [
    { label: this.translate.instant('PAGE.DISCOUNT.FILTER.STATUS.DATA.PUBLISHED'), value: DISCOUNT_STATUSES.published },
    { label: this.translate.instant('PAGE.DISCOUNT.FILTER.STATUS.DATA.PAUSE'), value: DISCOUNT_STATUSES.pause },
    { label: this.translate.instant('PAGE.DISCOUNT.FILTER.STATUS.DATA.EXPIRED'), value: DISCOUNT_STATUSES.expired },
  ];

  private archivingList: IDropdownData[] = [
    { label: this.translate.instant('PAGE.DISCOUNT.FILTER.ARCHIVING_STATUS.DATA.ARCHIVED'), value: ARCHIVING_STATUSES.archived },
    { label: this.translate.instant('PAGE.DISCOUNT.FILTER.ARCHIVING_STATUS.DATA.NON_ARCHIVED'), value: ARCHIVING_STATUSES.nonArchived },
  ];

  constructor(
    @Inject('StateService') protected state: ng.ui.IStateService,
    @Inject('TranslationService') public translate: ng.translate.ITranslateService,
    formNotifier: FormNotifierService,
    private customerResource: CustomerResource,
  ) {
    super(formNotifier, state);
  }

  public getFields(): AbstractFormFieldBase<any>[]  {
    const dateBegin = this.filters.get('dateBegin');
    const dateEnd = this.filters.get('dateEnd');
    const customerEmailFilter = this.filters.get('customers.username');

    if (dateBegin && dateEnd) {
      this.startDateField.value = dateBegin;
      this.endDateField.value = dateEnd;
    }

    if (customerEmailFilter) {
      this.customerEmailField.value = customerEmailFilter;
    }

    return [
      new TextField({
        fieldName: 'name',
        label: 'PAGE.DISCOUNT.FILTER.NAME.LABEL',
        value: this.filters.get('name') ? this.filters.get('name') : undefined,
      }),

      new TextField({
        fieldName: 'discountCode',
        label: 'PAGE.DISCOUNT.FILTER.CODE.LABEL',
        value: this.filters.get('discountCode') ? this.filters.get('discountCode') : undefined,
      }),

      new DateField({
        label: 'PAGE.DISCOUNT.FILTER.DATE.LABEL',
        fieldName: 'date',
        dateRange: true,
        value: dateBegin && dateEnd ? [new Date(dateBegin), new Date(dateEnd)] : undefined,
        valueChangedAction: this.setDates.bind(this),
      }),

      this.startDateField,
      this.endDateField,

      new TextField({
        fieldName: 'customers.id',
        label: 'PAGE.DISCOUNT.FILTER.CUSTOMER.LABEL',
        value: this.filters.get('customers.id') ? this.filters.get('customers.id') : undefined,
      }),

      this.customerEmailField,

      new DropDownListField({
        fieldName: 'type.type',
        label: 'PAGE.DISCOUNT.FILTER.TYPE.LABEL',
        data: this.typeList,
        textField: 'label',
        valueField: 'value',
        value: this.filters.get('type.type') ? this.filters.get('type.type') : undefined,
        valuePrimitive: true,
      }),

      new DropDownListField({
        fieldName: 'discountStatus',
        label: 'PAGE.DISCOUNT.FILTER.STATUS.LABEL',
        data: this.statusList,
        textField: 'label',
        valueField: 'value',
        value: this.filters.get('discountStatus') ? this.filters.get('discountStatus') : undefined,
        defaultItem: { label: this.translate.instant('PAGE.DISCOUNT.FILTER.STATUS.DATA.ALL'), value: null },
        valuePrimitive: true,
      }),

      new DropDownListField({
        fieldName: 'archived',
        label: 'PAGE.DISCOUNT.FILTER.ARCHIVING_STATUS.LABEL',
        data: this.archivingList,
        textField: 'label',
        valueField: 'value',
        value: this.filters.get('archived') ? this.filters.get('archived') : this.archivingList[1].value,
        defaultItem: { label: this.translate.instant('PAGE.DISCOUNT.FILTER.ARCHIVING_STATUS.DATA.ALL'), value: null },
        valuePrimitive: true,
      }),

      new MultiSearchField({
        fieldName: 'country.code[]',
        label: 'PAGE.ORDER.LIST.FILTER.COUNTRY.LABEL',
        data: SessionHelper.getCountries(),
        textField: 'name',
        valueField: 'code',
        value: this.filters.getAll('country.code[]'),
        order: 7
      }),
    ];
  }

  private setDates(newValue: string): void {
    if (newValue && null !== newValue[0]) {
      this.startDateField.value = moment(newValue[0]).startOf('day').format(DATE_FULL_FORMAT);
      this.endDateField.value = moment(newValue[1]).endOf('day').format(DATE_FULL_FORMAT);
    }
  }

  public searchCustomer(query: string): void {
    // Regex based on HTM5 email symfony validator
    const validateEmail = RegExp(
      /^[a-zA-Z0-9.!#$%&\'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/
    ).test(query);
    if (2 < query.length && 100 > query.length && validateEmail) {
      this.customerResource.cGet({ username: query }, { returnHydraMembers: true, blocking: false })
        .takeUntil(this.destroyed$)
        .subscribe((response: ICustomer[]) => {
          this.customerEmailField.data = response.map((customer: ICustomer) => ({ label: customer.username }));
        })
      ;
    }
  }
}
