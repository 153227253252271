import { IReverse, IReverseProductPackage } from '@components/reverse';
import { ICarrierGroup } from '@interfaces';
import * as moment from 'moment';
import {
  DATE_SHORT_INTERNATIONAL_FORMAT,
  getReceiveStatuses,
  getReconditionedStatuses
} from '@constants';

export class ReverseProductPackage implements IReverseProductPackage {

  public id: number;
  public skuId?: number;
  public packageSku: string;
  public packageNumber: string;
  public expected: boolean;
  public deal: boolean;
  public withoutLabel: boolean;
  public receiveStatus: string;
  public receiveStatusLabel: string;
  public reconditionedStatus: string;
  public reconditionedStatusLabel: string;
  public receptionDate: string;
  public damageReason: string;
  public pictures: string[];
  public carrierGroup: ICarrierGroup;
  public goneTracking: string;
  public returnTracking: string;
  // Because reverseProduct is an IReverseProduct when fetches api/reverses/packages
  // public reverseProduct?: number|IReverseProduct; // don't work // reverseProductId || IReverseProduct
  public reverseProduct?: any;
  public reverseId?: number;
  public reverseDate?: string;
  public customer?: string;
  public country?: string;
  public packageIdentification?: string;
  public carrierCode?: string;
  public dateExportSage?: string;
  public archived: boolean;
  public orderId?: number;
  public orderLinkTitle?: string;

  constructor(
    reverseProductPackage: IReverseProductPackage,
    reverseProductId?: number
  ) {
    this.id = reverseProductPackage.id;
    this.packageSku = reverseProductPackage.packageSku;
    this.packageNumber = reverseProductPackage.packageNumber;
    this.expected = reverseProductPackage.expected;
    this.deal = reverseProductPackage.deal;
    this.withoutLabel = reverseProductPackage.withoutLabel;

    this.receiveStatus = reverseProductId ?
      reverseProductPackage.receiveStatus :
      this.getStatus(getReceiveStatuses(), reverseProductPackage.receiveStatus)
      ;

    this.reconditionedStatus = reverseProductId ?
      reverseProductPackage.reconditionedStatus :
      this.getStatus(getReconditionedStatuses(), reverseProductPackage.reconditionedStatus)
      ;
    this.receiveStatusLabel = this.getStatus(getReceiveStatuses(), this.receiveStatus, true);
    this.reconditionedStatusLabel = this.getStatus(getReconditionedStatuses(), this.reconditionedStatus, true);

    this.receptionDate = reverseProductPackage.receptionDate
      ? moment(reverseProductPackage.receptionDate).format(DATE_SHORT_INTERNATIONAL_FORMAT)
      : null
      ;
    this.damageReason = reverseProductPackage.damageReason;
    this.pictures = reverseProductPackage.pictures || [];
    this.carrierGroup = reverseProductPackage.carrierGroup;
    this.goneTracking = reverseProductPackage.goneTracking;
    this.returnTracking = reverseProductPackage.returnTracking;
    this.reverseProduct = reverseProductId;
    this.carrierCode = reverseProductPackage.carrierGroup ? reverseProductPackage.carrierGroup.code : '';
    this.dateExportSage = reverseProductPackage.dateExportSage;

    if (
      undefined !== reverseProductPackage.reverseProduct &&
      undefined !== reverseProductPackage.reverseProduct.reverse
    ) {
      const reverse: IReverse = reverseProductPackage.reverseProduct.reverse;

      this.reverseId = reverse.id;

      if (reverseProductPackage.reverseProduct.reverse.order) {
        this.country = this.setHtmlImageCountry(reverseProductPackage.reverseProduct.reverse.order.shippingAddress.countryCode);
      }

      this.reverseDate = moment(reverse.date).format(DATE_SHORT_INTERNATIONAL_FORMAT);
      this.customer = `${reverse.lastname || ''} ${reverse.firstname || ''}`;
      this.archived = reverse.archived || false;
      this.packageIdentification = `${reverseProductPackage.reverseProduct.sku}/${reverseProductPackage.packageNumber}`;
      this.skuId = reverseProductPackage.reverseProduct['id'];
      this.orderId = reverse.order ? (<{ [keys: string]: any }>reverse.order).id : null;
      this.orderLinkTitle = this.orderId ? `# ${this.orderId}` : null;
    }
  }

  /**
   * Gets reverse product package status.
   *
   * @param {{}[]} statuses - Receive|Reconditioned statuses
   * @param {string} status - Receive|Reconditioned status
   *
   * @returns {string}
   */
  private getStatus(statuses: {}[], status?: string, withoutLabel?: boolean): string {
    let outputStatus = '';

    if (status) {
      statuses.forEach((item: { [keys: string]: string }) => {
        if (item.id === status) {
          outputStatus = withoutLabel ? item.label : item.id;
        }
      });
    }

    return outputStatus;
  }

  private setHtmlImageCountry(locale: any): string {
    let html = locale;
    try {
      const countryCode = locale;
      const src = `images/flags/${countryCode.toLowerCase()}.png`;

      html = `<img src="${src}" alt="${countryCode}"/>`;
    } catch (error) {
      console.error(error);
    }
    return html;
  }
}
