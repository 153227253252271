import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { GenericFormModule } from '../../components/generic/generic-form.module';
import { GenericModule } from '../../components/generic/generic.module';
import { CustomerDetailResource } from './customer-detail.resource';
import { CustomerDetailAddressesResource } from './customer-detail-addresses.resource';
import { CustomerDetailOrdersResource } from './customer-detail-orders.resource';
import { CustomerResource } from './customer.resource';
import { CustomerFiltersFormService } from './services/customer-filters-form.service';
import { CustomerFieldsFormService } from './services/customer-fields-form.service';
import { AddressesResource } from '../../resources/addresses.resource';
import { DialogBoxService } from '../../services/dialog-box.service';
import { OrderManagerRelationComponent } from '@components/order-manager/relation';

import {
  CustomerDetailComponent,
  CustomerDetailAddressesComponent,
  CustomerDetailOrdersComponent,
  CustomerDetailMailsComponent,
  CustomerDetailTabsComponent,
  CustomerDetailWaliticketsComponent, CustomerDetailDiscountComponent
} from './single';
import { CustomerListComponent } from '@components/customer/list/customer-list.component';
import { MailResource } from '@resources/mail.resource';
import {OrderManagerRelationRessource} from '@components/order-manager/relation/order-manager-relation.ressource';
import {PipesModule} from '../../pipes/pipes.module';
import {TooltipModule} from 'ngx-tooltip';

@NgModule({
  entryComponents: [
    CustomerListComponent,
    CustomerDetailComponent,
    CustomerDetailAddressesComponent,
    CustomerDetailOrdersComponent,
    CustomerDetailMailsComponent,
    CustomerDetailDiscountComponent,
    CustomerDetailTabsComponent,
    CustomerDetailWaliticketsComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        GenericFormModule,
        GenericModule,
        TooltipModule,
        PipesModule,
        TooltipModule
    ],
  providers: [
    AddressesResource,
    CustomerDetailResource,
    CustomerDetailAddressesResource,
    CustomerDetailOrdersResource,
    CustomerResource,
    CustomerFiltersFormService,
    CustomerFieldsFormService,
    DialogBoxService,
    MailResource,
    OrderManagerRelationRessource,
  ],
  declarations: [
    CustomerListComponent,
    CustomerDetailComponent,
    CustomerDetailAddressesComponent,
    CustomerDetailOrdersComponent,
    CustomerDetailMailsComponent,
    CustomerDetailDiscountComponent,
    CustomerDetailTabsComponent,
    CustomerDetailWaliticketsComponent,
  ],
  exports: [
    CustomerDetailComponent,
    CustomerDetailAddressesComponent,
    CustomerDetailOrdersComponent,
    CustomerDetailMailsComponent,
    CustomerDetailDiscountComponent,
    CustomerDetailTabsComponent,
    CustomerDetailWaliticketsComponent,
  ]
})
export class CustomerModule {}
