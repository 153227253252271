import { AbstractComponent } from '@components/generic/abstract.component';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { FormBuilder } from '@angular/forms';
import { LOCALE_FR } from '@constants';
import { LitigationStatusResource } from '@components/order-manager/resources/litigation-status.resource';
import { LitigationTypeResource } from '@components/order-manager/resources/litigation-type.resource';
import { SessionHelper } from '@helpers';
import { ILitigationType } from '@components/order-manager/interfaces/litigation-type.interface';
import { FormNotifierService } from '@services';
import { OrderManagerResource } from '@components/order-manager/order-manager.resource';
import { LitigationResource } from '@components/order-manager/resources/litigation.resource';
import { OrderItemResource } from '@resources';

@Component({
  selector: 'app-order-manager-litigation',
  template: require('./order-manager-litigation.component.html'),
  providers: [
    OrderManagerResource, OrderItemResource, LitigationResource, LitigationStatusResource, LitigationTypeResource
  ],
})
export class OrderManagerLitigationComponent extends AbstractComponent implements OnInit {

  @Input() public order: any;

  public openCreation: boolean = false;
  public openEditions: any = {};
  public litigationStatuses: { label: string; id: string }[] = [];
  public litigationTypes: { label: string; id: string }[] = [];
  public litigations: any[] = [];
  public allowLitigationCreation: boolean = false;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    private formBuilder: FormBuilder,
    private litigationStatusResource: LitigationStatusResource,
    private litigationTypeResource: LitigationTypeResource,
    private litigationResource: LitigationResource,
    private formNotifier: FormNotifierService,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }

  public ngOnInit(): void {
    let countLitigationPackages: number = 0;
    let countPackages: number = 0;

    this.order.items.forEach((item: any) => {
      countPackages += item.packages.length;

      item.packages.forEach((pck: any) => {
        if (pck.litigation) {
          countLitigationPackages++;
        }
      });
    });

    this.allowLitigationCreation = countLitigationPackages < countPackages;

    this.fetchLitigations();
    this.fetchLitigationStatuses();
    this.fetchLitigationTypes();
  }

  private fetchLitigations(): void {
    this.litigationResource.cGet({ 'orderId': this.order.id }, { returnHydraMembers: true, blocking: false })
      .takeUntil(this.destroyed$)
      .subscribe((response: any[]) => {
        this.litigations = response;
      })
    ;
  }

  private fetchLitigationStatuses(): void {
    this.litigationStatusResource.cGet({}, { returnHydraMembers: true, blocking: false })
      .takeUntil(this.destroyed$)
      .subscribe((response: any[]) => {
        this.litigationStatuses = response.map((status: any) => {
          return {
            label: (status.translations[SessionHelper.getUILanguage()] || status.translations[LOCALE_FR]).label,
            code: status.code,
            position: status.position,
            id: status['@id'],
          };
        }).sort((a, b) => a.position - b.position);
      })
    ;
  }

  private fetchLitigationTypes(): void {
    this.litigationTypeResource.cGet({}, { returnHydraMembers: true, blocking: false })
      .takeUntil(this.destroyed$)
      .subscribe((response: ILitigationType[]) => {
        this.litigationTypes = response.map((status: ILitigationType) => {
          return {
            label: (status.translations[SessionHelper.getUILanguage()] || status.translations[LOCALE_FR]).label,
            id: status['@id'],
            code: status.code,
          };
        }).sort((a, b) => {
          if (a.label < b.label) { return -1; }
          if (a.label > b.label) { return 1; }
          return 0;
        });
      })
    ;
  }

  public getLitigationStatus(id: any): string {
    return this.litigationStatuses.find((item) => {
      return item.id === id;
    }).label;
  }

  public getLitigationType(id: any): string {
    return this.litigationTypes.find((item) => {
      return item.id === id;
    }).label;
  }

  public downloadInvoices(id: number): void {
    this.litigationResource.downloadAllInvoices(id)
      .takeUntil(this.destroyed$)
      .subscribe(
        (response: Blob | null) => {
          const fileURL = URL.createObjectURL(response);
          window.open(fileURL);
        },
      );
  }

  public getLitigationNextStatuses(litigation: any): any[] {
    if (0 === litigation.nextStatuses.length) {
      return this.litigationStatuses;
    }

    const nextStatusesIds = litigation.nextStatuses.map((status: any) => {
      return status['@id'];
    });

    return this.litigationStatuses.filter((status) => {
      return nextStatusesIds.includes(status.id);
    });
  }

}
