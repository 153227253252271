import { Injectable } from '@angular/core';
import {
  AdditionalPropertyFieldTypes,
  IRestrictedValue,
  ITaskAdditionalProperty,
  TaskAdditionalPropertyModel
} from '../model';
import { ComboSearchField, TextField } from '@components/generic/Form/dynamic/fields';
import { AbstractFormFieldBase } from '@components/generic/Form/dynamic/fields/abstract-form-field-base.class';
import { SessionHelper } from '@helpers';
import { DropDownListField } from '@components/generic/Form/dynamic/fields/select-field.class';

@Injectable()
export class TaskHelper {

  public getAdditionalPropertiesFields(
    additionalProperties: ITaskAdditionalProperty[]
  ): AbstractFormFieldBase<any>[] {
    return additionalProperties.map((item: ITaskAdditionalProperty) => {
      const additionalProperty = new TaskAdditionalPropertyModel(item);

      switch (additionalProperty.type) {
        case AdditionalPropertyFieldTypes.SELECT:
          return new DropDownListField({
            fieldName: additionalProperty.id,
            label: additionalProperty.translations[SessionHelper.getUILanguage()].label,
            data: additionalProperty.restrictedValues.map((value: IRestrictedValue) => {
              return {
                label: value.translations[SessionHelper.getUILanguage()].value,
                value: value.valueCode,
              };
            }),
            textField: 'label',
            valueField: 'value',
            fieldType: 'choice-single',
            value: (() => {
              if (additionalProperty.value) {
                const property = additionalProperty.restrictedValues.find((value: IRestrictedValue) => {
                  return additionalProperty.value === value.valueCode;
                });

                return {
                  label: property.translations[SessionHelper.getUILanguage()].value,
                  value: property.valueCode,
                };
              }
            })()
          });
        case AdditionalPropertyFieldTypes.TEXT:
          return new TextField({
            label: additionalProperty.translations[SessionHelper.getUILanguage()].label,
            fieldName: additionalProperty.id,
            value: additionalProperty.value,
          });
      }
    });
  }
}
