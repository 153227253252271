import { Component, Inject } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { FormErrorHelper } from '@helpers';
import { FormSubmitArgument } from '@interfaces';
import { SnackbarService } from '../../../snackbar/snackbar.service';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { CarrierFamilyResource, ICarrierFamily } from '@components/carrierFamily';
import { AuthService } from '@services';

@Component({
  selector: 'app-carrier-family-new',
  template: require('./carrier-family-new.component.html')
})
export class CarrierFamilyNewComponent extends AbstractPageComponent {

  public carrierFamily: ICarrierFamily;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: CarrierFamilyResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private snackbar: SnackbarService,
  ) {
    super($translate, authService, resource, state);
  }

  /**
   * Prepares query to send data to API.
   */
  private prepareQuery(query: any): object {
    return {
      ...query
    };
  }

  /**
   * Handles the save form event.
   */
  public save({ body, form, redirect }: FormSubmitArgument): void {
    const subscriber: Subscription = this.resource.create(this.prepareQuery(body))
      .subscribe((res: any) => {
        this.snackbar.validate(this.translate('PAGE.CARRIER.CARRIER_FAMILIES.NEW.SAVED'));
        if (redirect) {
          this.actions.list.go();
        } else {
          this.actions.update.go({id: res.id});
        }
      }, (error: any) => {
        if (400 === error.code) {
          FormErrorHelper.fillFormControlWithErrors(form, error.errors);
        }
      }, () => subscriber.unsubscribe());
  }
}
