import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({selector: 'textarea[wysiwyg]'})
export class WysiwygDirective implements OnInit, OnDestroy {

  public elementId: string;
  private editor: any;

  constructor(private element: ElementRef, private field: NgControl) {}

  /**
   * @inheritDoc
   */
  ngOnInit(): void {
    this.generateId();
    this.initEditor();
  }

  /**
   * @inheritDoc
   */
  ngOnDestroy() {
    tinymce.remove(this.editor);
  }

  /**
   * Generates id for target if it doesn't exist
   */
  private generateId(): void {
    const element = this.element.nativeElement;

    if (null === (this.elementId = element.getAttribute('id'))) {
      this.elementId = (new Date().getTime() + Math.floor((Math.random() * 10000) + 1)).toString(16);
      element.setAttribute('id', this.elementId);
    }
  }

  /**
   * Inits wysiwyg editor on field
   */
  private initEditor(): void {
    tinymce.baseURL = '/vendor/tinymce';

    this.editor = tinymce.init({
      convert_urls: false,
      selector: '#' + this.elementId,
      menubar: false,
      plugins: ['code', 'lists', 'link'],
      toolbar: 'bold italic underline | formatselect | h2 h3 h4 | numlist bullist | link | code',
      block_formats: 'Paragraphe=p;Titre 2=h2;Titre 3=h3;Titre 4=h4',
      init_instance_callback: (editor: any) => {
        editor.on('change', this.change.bind(this));
      }
    });
  }

  /**
   * Handles value change
   */
  public change(): void {
    this.field.control.setValue(tinymce.get(this.elementId).getContent());
    this.field.control.markAsDirty();
  }
}
