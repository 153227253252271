export default orderRoutes;

/** @ngInject */
function orderRoutes($stateProvider: any) {

  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'order',
    reloadChangeCountrySearchParam: 'id',
  };

  $stateProvider
    .state('order', {
      url: '/orders',
      parent: 'walisoft',
      abstract: true,
    })
    .state('order.new', {
      url: '/new',
      params: {
        marketplace: null,
        orderType: null,
        type: null
      },
      breadcrumb: { label: 'BREADCRUMB.NEW_ORDER' },
      views: {
        'page@layout': { template: '<app-order-creation></app-order-creation>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.ORDER.NEW.TITLE',
        reloadChangeCountry: 'order.new',
      })
    });
}
