import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import {AbstractResource, OrderResource} from '@resources';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SessionHelper } from '@helpers';
import { SnackbarService } from '@components/snackbar';
import { IWarehouses } from '@components/warehouses/models';
import { StockMovementLineResource } from '@resources/stock-movement-line.resource';
import {takeUntil} from 'rxjs/operators';
import {WarehousesResource} from '@components/warehouses';
import {HydraHelper} from '@helpers/HydraHelper';

@Component({
  selector: 'app-stock-movement-form',
  template: require('./stock-movement-form.component.html'),
  providers: [
    { provide: AbstractResource, useClass: StockMovementLineResource },
  ],
})
export class StockMovementFormComponent extends AbstractPageComponent implements OnInit {
  public form: FormGroup;
  public lineForms: FormGroup[] = [];
  public warehouses: any[] = SessionHelper.getAllWarehouses();
  public countryCode: string = SessionHelper.getCountry().code;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    private snackbar: SnackbarService,
    private warehouseResource: WarehousesResource
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    this.buildForm();
    this.addLineForm();
    this.initDefaultValues();
  }

  public buildForm(): void {
    this.form = this.formBuilder.group({
      fromWarehouse: [null],
      toWarehouse: [null],
    });
  }

  public addLineForm(): void {
    this.lineForms.push(this.formBuilder.group({
      masterProduct: [null],
      quantity: [null],
      maxQuantity: [null],
    }));
  }

  public initDefaultValues(): void {
    this.form.get('fromWarehouse').setValue(this.warehouses.find((warehouse: IWarehouses): boolean => {
      return warehouse.code === 'lsl';
    })['@id']);

    this.form.get('toWarehouse').setValue(this.warehouses.find((warehouse: IWarehouses): boolean => {
      return warehouse.code === 'ecospace';
    })['@id']);
  }

  public fetchStock(event: any, index: number): void {
    if (!event.sku) {
      return;
    }

    const body: any = {
      'warehouse': this.form.value.fromWarehouse,
      'masterProduct.products.sku': event.sku,
      'origin': 'sage',
      'type': 'SP',
    };

    this.resource.cGet(body, { entryPoint: '/v2/stock_master_products', dontUseModel: true })
      .takeUntil(this.destroyed$)
      .subscribe((response: any): void => {
        this.lineForms[index].get('maxQuantity').setValue(+response['hydra:member'][0].quantity);
      })
    ;
  }

  private async prepareBody(): Promise<any> {
    const toWarehouseId = this.form.value.toWarehouse.split('/').pop();

    const response: any = await this.warehouseResource.get(toWarehouseId)
      .pipe(takeUntil(this.destroyed$))
      .toPromise();

    const address = response.address;

    address.lastName = 'sweeek';
    address.firstName = 'sweeek';

    return {
      shippingAddress: address,
      billingAddress: address,
      locale: this.currentLocale,
      marketplace: 'showroom',
      orderType: 'repatriation',
      referralOrderSav: null,
      paymentType: 'free',
      sendPaymentLink: false,
      manuallyAdded: true,
      grcReason: null,
      estimate: false,
      zendeskTicketId: null,
      privateData: false,
    };
  }

  public async submit(): Promise<void> {
    const preparedBody = await this.prepareBody();

    (<OrderResource>this.resource)
      .create(preparedBody, { entryPoint: '/v2/order-manager/manual-order-creation' })
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (response: any) => {
          this.createStockMovement(response.id);
          this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
        }
      );
  }

  public createStockMovement(orderId: number): void {
    const lines: any = [];
    this.lineForms.forEach((formLine): void => {
      const sku: string = formLine.value.masterProduct.sku;
      const quantity: number = +formLine.value.quantity;

      if (sku && quantity) {
        lines.push({
          sku: sku,
          quantity: quantity,
        });
      }
    });

    if (!lines.length) {
      return;
    }

    const body: any = {
      fromWarehouse: this.form.value.fromWarehouse,
      toWarehouse: this.form.value.toWarehouse,
      order: HydraHelper.buildIri(orderId, 'orders'),
      lines: lines,
    };

    this.resource.create(body, { entryPoint: '/v2/stock_movements' })
      .takeUntil(this.destroyed$)
      .subscribe((): void => {
        this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
        setTimeout((): void => {
          this.state.go('stock-movement.list');
        }, 100);
      })
    ;
  }
}
