import { ITaskComment } from './task-comment.interface';
import { ICreatedBy } from './task.interface';
import * as moment from 'moment';
import { FULL_EUROPEEN_DATE_FORMAT } from '@constants';

export class TaskCommentModel implements ITaskComment {
  public '@id': string;
  public message: string;
  public createdAt: string;
  public createdBy: string;
  public updatedAt?: string;

  constructor(taskComment: ITaskComment) {
    this['@id'] = taskComment['@id'];
    this.message = taskComment.message.replace(/\r?\n/g, '<br>');
    this.createdAt = moment(taskComment.createdAt).format(FULL_EUROPEEN_DATE_FORMAT);
    this.createdBy = (<ICreatedBy>taskComment.createdBy).username;
    this.updatedAt = taskComment.updatedAt ? moment(taskComment.updatedAt).format(FULL_EUROPEEN_DATE_FORMAT) : null;
  }
}
