export default categoriesMarketplaceRoutes;

/** @ngInject */
function categoriesMarketplaceRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'walipush',
    sidebarSubNav: 'categories-marketplace',
    title: '',
    reloadChangeCountry: 'categories-marketplace.list',
  };

  $stateProvider
    .state('categories-marketplace', {
      url: '/categories-marketplace',
      parent: 'walisoft',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.CATEGORIES_MARKETPLACE',
        stateName: 'categories-marketplace.list'
      }
    })
    .state('categories-marketplace.list', {
      url: `?
        page
        &byPage
        &active
        &code
        &deprecated
        &label
        &marketplace
        &leaf
      `,
      views: {
        'page@layout': {
          template: '<app-categories-marketplace-list></app-categories-marketplace-list>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.CATEGORIES_MARKETPLACE.LIST.TITLE',
      })
    })
  ;
}
