export function buildVintageDates() {
  let possibilities = [];

  const todayYear = new Date().getFullYear();

  const oldDates = [];
  for (let i = 0; i < 10; i++) {
    oldDates.push(todayYear - (i + 1));
  }

  possibilities = oldDates.reverse();
  possibilities.push(todayYear);

  for (let i = 0; i < 10; i++) {
    possibilities.push(todayYear + (i + 1));
  }

  return possibilities;
}
