import { CMSResource } from '@components/cms/cms.resource';

export default cmsRoutes;

/** @ngInject */
function cmsRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'website',
    sidebarSubNav: 'cms',
    title: '',
    reloadChangeCountry: 'cms.list',
  };

  $stateProvider
    .state('cms', {
      url: '/cms',
      parent: 'website',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.CMS',
        stateName: 'cms.list'
      }
    })
    .state('cms.list', {
      url: `?
        page'
        &byPage
      `,
      views: {
        'page@layout': {
          template: '<app-cms-list></app-cms-list>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.CMS.LIST.TITLE',
        resource: CMSResource
      })
    })
    .state('cms.new', {
      url: '/new',
      breadcrumb: { label: 'BREADCRUMB.NEW' },
      views: {
        'page@layout': { template: '<app-cms-form></app-cms-form>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.CMS.NEW.TITLE',
      })
    })
    .state('cms.edit', {
      url: '/:id',
      breadcrumb: { label: 'BREADCRUMB.EDIT' },
      views: {
        'page@layout': { template: '<app-cms-form></app-cms-form>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.CMS.EDIT.TITLE',
      })
    })
  ;
}
