import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import { SessionHelper, FormErrorHelper } from '@helpers';
import { SnackbarService } from '../../../snackbar/snackbar.service';
import {AbstractResource, CarrierGroupResource} from '@resources';
import { ICarrierGroup } from '@components/carrier/interfaces/carrier-group.interface';
import { AuthService, FormNotifierService } from '@services';
import { AbstractComponent } from '@components/generic/abstract.component';
import {CarrierConfigurationTypeResource} from '@resources/carrier-configuration-type.resource';
import {CarrierConfigurationTypeModel} from '@components/carrier/models/carrier-configuration-type.model';
import {INPUT_NUMBER_PATTERN_DEC, INPUT_NUMBER_PATTERN_NODEC} from '@constants';

@Component({
  selector: 'app-carrier-edit-form',
  template: require('./carrier-edit-form.component.html'),
  providers: [
    { provide: AbstractResource, useClass: CarrierGroupResource },
  ]
})
export class CarrierEditFormComponent extends AbstractComponent implements OnInit {

  @Input() public carrierGroup: ICarrierGroup;
  @Input() public locale: string = SessionHelper.getLocale();

  @Output() public onSave: EventEmitter<object> = new EventEmitter();

  public form: FormGroup;
  private configurationTypeResource: CarrierConfigurationTypeResource;

  public configurationTypes: any[] = [];
  public authorizedConfigurationTypesId: string[] = [];
  public ignoredConfigurations: any[] = [];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: CarrierGroupResource,
    configurationTypeResource: CarrierConfigurationTypeResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private snackbar: SnackbarService,
    @Inject('DialogService') private dialog: any,
    public formNotifier: FormNotifierService,
  ) {
    super($translate, authService, resource, state);
    this.configurationTypeResource = configurationTypeResource;
  }

  get constraintsFormArray(): FormArray {
    return this.form.get('constraints') as FormArray;
  }

  get configurations(): any[] {
    return this.carrierGroup.config.configurations;
  }

  ngOnInit() {
    this.configurationTypeResource
      .cGet(
        {},
        {
          returnHydraMembers: true
        }
      )
      .takeUntil(this.destroyed$)
      .subscribe((types: CarrierConfigurationTypeModel[]) => {
        for (const type of types) {
          if (!['valid_postal_code', 'invalid_postal_code'].includes(type.code)) {
            this.configurationTypes.push({
              '@id': type['@id'],
              'translations': this.translate('PAGE.CARRIER.EDIT.CONSTRAINTS.TYPES.' + type['code']),
              'code': type['code'],
              'id': type['id']
            });
            this.authorizedConfigurationTypesId.push(type['@id']);
          }
        }

        const constraints2 = this.configurations
          .filter(
            (configuration: any) => {
              if (this.authorizedConfigurationTypesId.includes(configuration.type['@id'])) {
                return true;
              } else {
                const conf = configuration;
                conf.type = conf.type['@id'];
                this.ignoredConfigurations.push(conf);
                return false;
              }
            }
          );


        const constraintsArray = this.handleConstraints(constraints2);

        this.handleForm(constraintsArray);
      });

    this.form = new FormGroup({
      constraints: new FormArray([])
    });

    this.form.statusChanges.subscribe(() => {
      if (this.form.dirty) {
        this.formNotifier.notifyFormInEdition();
      }
    });
  }

  private handleConstraints(values: any[]): any[] {
    const result: any[] = [];

    for (const [key, value] of Object.entries(values)) {
      if (
        value['type'] &&
        typeof value['type']['code'] === 'string' &&
        value['type']['code'].includes('combo') &&
        typeof key === 'string' &&
        /^\d+$/.test(key)
      ) {
        value['value'] = typeof value['value'] === 'string' ? JSON.parse(value['value']) : value['value'];
        result.push({
          type: value['type'],
          combos: this.handleConstraints(value['value']),
          level: value['level'],
          operator: value['operator'],
          id: value['id']
        });
      } else if (typeof key === 'string' && key.includes('combo') && typeof value === 'object') {
        value['value'] = typeof value['value'] === 'string' ? JSON.parse(value['value']) : value['value'];
        let type = key;
        if (typeof key === 'string') {
          type = this.configurationTypes.find(({ code }) => code === key);
        }
        result.push({
          type: type,
          level: value['level'],
          combos: this.handleConstraints(value['value']),
        });
      } else {
        const type = this.configurationTypes.find(({ code }) => code === key);
        result.push({
          type: value['type'] && value['type']['code'] ? value['type'] : type,
          value: value['value'],
          level: value['level'],
          operator: value['operator'],
          id: value['id']
        });
      }
    }

    return result;
  }

  private handleForm(total: any[], form: any | null = null): void {
    total.forEach((value: any) => {
        if (!value['combos'] && form === null) {
            this.constraintsFormArray.push(
              this.createConstraintFormGroup(value['id'], value['type']['@id'], value['value'], value['level'], value['operator'])
            );
        } else if (value['combos'] && form === null) {
            const f = this.createConstraintComboFormGroup(value['id'], value['type']['@id'], value['level']);

            this.handleForm(value['combos'], (<FormArray>f.get('combos')));

            this.constraintsFormArray.push(f);
        } else if (!value['combos'] && form !== null) {
            form.push(this.createConstraintFormGroup(value['id'], value['type']['@id'], value['value'], value['level'], value['operator']));
        } else {
          const f = this.createConstraintComboFormGroup(value['id'], value['type']['@id'], value['level']);
          (<FormArray> form).push(f);
          this.handleForm(value['combos'], (<FormArray>f.get('combos')));
        }
    });
}

  private createConstraintFormGroup(id: number = null, type: string = null, value: number = null, level: string = 'error', operator: string = '>'): FormGroup {
      return new FormGroup({
        id: new FormControl(id),
        type: new FormControl(type, Validators.required),
        value: new FormControl(value, [Validators.required, Validators.pattern(INPUT_NUMBER_PATTERN_DEC)]),
        operator: new FormControl(operator),
        level: new FormControl(level)
      });
  }

  private createConstraintComboFormGroup(id: number = null, type: string = null, level: string = 'error'): FormGroup {
    return new FormGroup({
      id: new FormControl(id),
      type: new FormControl(type, Validators.required),
      level: new FormControl(level),
      combos: new FormArray([])
    });
}

  public addConstraint(): void {
    (<FormArray>this.form.controls['constraints']).push(this.createConstraintFormGroup());
  }

  private prepareBody(formValue: any): object {
    let configurations = [...this.ignoredConfigurations];

    configurations = configurations.concat(this.handleBody(formValue.constraints));

    return {
      configurations
    };
  }

  private handleBody(forms: any[], isCombo: boolean = false): any {
    let body: any;
    if (isCombo) {
      body = {};
    } else {
      body = [];
    }
    forms.forEach((form) => {
      if (form.combos && form.id) {
        body.push({
          id: form.id,
          type: form.type,
          value: JSON.stringify(this.handleBody(form.combos, true)),
          level: form.level
        });
      } else if (form.combos && !form.id) {
        if (isCombo) {
          const type = this.configurationTypes.find(function(t) {
            return t['@id'] === form.type;
          });
            body[type.code] = {
              value: this.handleBody(form.combos, true),
              level: form.level,
            };
          } else {
            body.push({
              type: form.type,
              value: JSON.stringify(this.handleBody(form.combos, true)),
              level: form.level,
            });
          }

      } else if (!form.id && isCombo) {
        const type = this.configurationTypes.find(function(t) {
          return t['@id'] === form.type;
        });

          body[type.code] = {
            value: form.value.toString(),
            operator: form.operator,
            level: form.level,
          };
      } else {

        body.push({
          id: form.id,
          type: form.type,
          value: form.value.toString(),
          operator: form.operator,
          level: form.level,
        });
      }
    });

    return body;
  }

  public submit(isRedirect: boolean = false): void {
    if (this.form.dirty && this.form.valid) {
      this.onSave.emit({
        body: this.prepareBody(this.form.value),
        form: this.form,
        redirect: isRedirect
      });

      return;
    }

    if (this.form.dirty && !this.form.valid) {
      this.snackbar.warn(this.translate('ALERTS.ERROR.FORM'));

      return;
    }

    this.snackbar.warn(this.translate('ALERTS.NO_CHANGE.FORM'));
  }

  /**
   * Cancels form update and redirect to list.
   */
  public cancel(): void {
    this.dialog.confirm(this.translate('DIALOG.TEXT.DONT_SAVE'))
      .then(() => this.actions.list.go())
      .catch(() => {})
    ;
  }
}
