import { IService } from '@components/service/model/service.interface';
import { CountryHelper, SessionHelper } from '@helpers';
import { IProductMarketplace } from '@components';

export class ServiceListModel {
  id: string;
  type: string;
  sku: string;
  amount: number;
  cost: number;
  title?: string;
  products: any;

  constructor(service: IService) {
    let translation = service.translations[SessionHelper.getLocale()] || null;

    if (null === translation) {
      const locales = CountryHelper.getLocales(SessionHelper.getCountries());

      for (const key in locales) {
        if (service.translations[locales[key]]) {
          translation = service.translations[locales[key]];
          break;
        }
      }
    }

    this.id = service.id;
    this.type = service.type;
    this.sku = service.sku;
    this.amount = service.amount;
    this.cost = service.cost;
    this.title = translation ? translation.title : '-';
    this.products = service.productMarketplacesExcluded.map((item: IProductMarketplace): string => item.product.sku);
  }
}
