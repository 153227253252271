import { StockMovementListComponent } from '@components/stock-movement/stock-movement-list.component';
import { StockMovementResultListComponent } from '@components/stock-movement/stock-movement-result-list.component';
import { StockMovementFormComponent } from '@components/stock-movement/stock-movement-form.component';
import { NgModule } from '@angular/core';
import { GenericFormModule, GenericModule } from '@components';
import { CommonModule } from '@angular/common';
import {DialogModule} from '@progress/kendo-angular-dialog';
import { StockMovementLineResource } from '@resources/stock-movement-line.resource';
import { StockMovementResource } from '@resources/stock-movement.resource';

@NgModule({
  declarations: [
    StockMovementListComponent,
    StockMovementResultListComponent,
    StockMovementFormComponent,
  ],
  entryComponents: [
    StockMovementListComponent,
    StockMovementResultListComponent,
    StockMovementFormComponent,
  ],
    imports: [
        CommonModule,
        GenericModule,
        GenericFormModule,
        DialogModule
    ],
  exports: [
    StockMovementListComponent,
    StockMovementResultListComponent,
    StockMovementFormComponent,
  ],
  providers: [
    StockMovementResource,
    StockMovementLineResource,
  ]
})
export class StockMovementModule {}
