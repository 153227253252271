export default footerRoutes;

/** @ngInject */
function footerRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'walipush',
    sidebarSubNav: 'footer',
    title: 'PAGE.WEBSITE_HOMEPAGE.TITLE',
    reloadChangeCountry: 'website_homepage',
    showCountries: true,
    tabs: [
      {
        label: 'PAGE.FOOTER.TAB.REINSURANCE_POINTS',
        state: 'reinsurancePoints.list',
      },
      {
        label: 'PAGE.FOOTER.TAB.FOOTER_LINKS',
        state: 'footerLinks.list',
      },
    ],
  };

  $stateProvider
    .state('reinsurancePoints', {
      url: '/reinsurance-points',
      parent: 'walipush',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.REINSURANCE_POINTS',
        stateName: 'reinsurancePoints.list'
      }
    })
    .state('reinsurancePoints.list', {
      url: '',
      views: {
        'page@layout': {
          template: '<app-reinsurance-points-list></app-reinsurance-points-list>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.REINSURANCE_POINTS.LIST.TITLE',
      })
    })
    .state('reinsurancePoints.new', {
      url: '/new',
      breadcrumb: {label: 'BREADCRUMB.NEW'},
      views: {
        'page@layout': {template: '<app-reinsurance-points></app-reinsurance-points>'}
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.REINSURANCE_POINTS.NEW.TITLE',
      })
    })
    .state('reinsurancePoints.edit', {
      url: '/:id',
      breadcrumb: {label: 'BREADCRUMB.EDIT'},
      views: {
        'page@layout': {template: '<app-reinsurance-points></app-reinsurance-points>'}
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.REINSURANCE_POINTS.EDIT.TITLE',
      })
    })
    .state('footerLinks', {
      url: '/footer-links',
      parent: 'walipush',
      abstract: true,
      breadcrumb: {
        label: 'SIDEBAR.LABEL.WEBSITE_FOOTER',
        stateName: 'footerLinks.list'
      }
    })
    .state('footerLinks.list', {
      url: '',
      views: {
        'page@layout': {
          template: '<app-footer-links-list></app-footer-links-list>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.FOOTER.TAB.FOOTER_LINKS',
      })
    }
  );
}
