import { IHydraMember } from '@interfaces/hydra-resource.interface';
import * as moment from 'moment';
import { DATE_SHORT_FORMAT_UK } from '@constants';

export class StockRegularizationPackageModel {
  '@id': string;
   id: string;
   sku: string;
   origin: string;
   quantity: number;
   date: string;
   url: string;

  constructor(stockRegularizationPackage: IHydraMember) {
    this['@id'] = stockRegularizationPackage['@id'];
    this.id = stockRegularizationPackage.id;
    this.sku = stockRegularizationPackage.package.packageSku;
    this.origin = stockRegularizationPackage.origin;
    this.quantity = stockRegularizationPackage.quantity;
    this.date = stockRegularizationPackage.date ? moment(stockRegularizationPackage.date).format(DATE_SHORT_FORMAT_UK) : null;
    this.url = `<a href="${stockRegularizationPackage.stockRegularization.url}" target="_blank">${stockRegularizationPackage.stockRegularization.filePath}</a>`;
  }
}
