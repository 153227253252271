import { Resource } from '@decorators/Resource';
import { Injectable } from '@angular/core';
import { AbstractResource } from '@resources/abstract.resource';
import { HttpClient } from '@angular/common/http';
import { HighlightsModel } from '@components/website-homepage/website-homepage-highlights-items/models';
import { SessionHelper } from '@helpers/session.helper';

@Injectable()
@Resource({
  routeName: 'websiteHomepage.highlights-items',
  entryPoint: '/highlights',
  translationKey: 'HIGHLIGHTS',
  model: HighlightsModel,
  cGetDefaultFilters: () => ({ locale: SessionHelper.getLocale() }),
  buttonActionMultipleAvailable: true,
})
export class WebsiteHomepageHighlightsItemsResource extends AbstractResource {

  protected nullableProperties: string[] = ['dateStart', 'dateEnd'];

  constructor(http: HttpClient) {
    super(http);
  }
}
