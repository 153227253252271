import { Component, Inject, OnInit } from '@angular/core';
import { SnackbarService } from '@components/snackbar/snackbar.service';
import { SessionHelper } from '@helpers';
import { Subscription } from 'rxjs/Subscription';
import { AttributeFamilyResource, AttributeValueResource } from '@resources';
import { Observable } from 'rxjs/Observable';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { AuthService } from '@services';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';

@Component({
  selector: 'app-attribute-family-edit',
  template: require('./attribute-family-edit.component.html')
})
export class AttributeFamilyEditComponent extends AbstractPageComponent implements OnInit {

  public attributeFamilyID: string;
  public actionName: string = 'edit';
  public attributeFamily: any;
  public applicationLocales: string[] = SessionHelper.get('APPLICATION_LOCALES');
  public customTitleIdentifier: string = '';

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AttributeFamilyResource,
    @Inject('StateService') state: ng.ui.IStateService,
    @Inject('DialogService') private dialog: any,
    private snackbar: SnackbarService,
    private attributeValueResource: AttributeValueResource,
  ) {
    super($translate, authService, resource, state);

    this.attributeFamilyID = this.state.params.id;
  }

  ngOnInit() {
    // # Get attribute family by id
    const subscriber: Subscription = this.resource.get(this.attributeFamilyID)
      .subscribe(
        (attributeFamilyResponse) => {
          this.attributeFamily = attributeFamilyResponse;
          this.customTitleIdentifier = this.attributeFamily.attribute.sortCode;
          this.actions.list.go = () => this.state.go(`attribute.edit`, {id: this.attributeFamily.attribute.id}, {});
        },
        null,
        () => subscriber.unsubscribe()
      );
  }

  /**
   * Assign attribute values to an attribute family
   */
  public saveAttributeAction({ redirect, attribute, newAttributeValues = [], editedAttributeValues = [] }: any): void {
    this.dialog.confirm(this.translate('PAGE.ATTRIBUTE.CONFIRM.UPDATE'))
      .then(() => {
        let formErrors = {};
        const pendingRequest: Observable<object>[] = [];

        // # Update attribute values and families
        this.updateAttributeValuesAndFamilies(editedAttributeValues, pendingRequest);

        // # Creates attribute values and families
        this.createAttributeValuesAndFamilies(newAttributeValues, formErrors);

        const subscriber: Subscription = Observable.forkJoin(...pendingRequest).pipe(catchError(error => of(error))).subscribe((res) => {
          this.snackbar.validate(this.translate('ALERTS.DATA.UPDATE'));

          if ('single' === redirect) {
            return this.actions.update.go({ id: this.attributeFamilyID }, { 'reload': true, 'notify': true });
          }

          return this.actions.list.go();

        }, (errorData) => {
          if (400 === errorData.status) {
            formErrors = errorData.data.errors;
          }
        }, () => subscriber.unsubscribe());
      });
  }

  private createAttributeValuesAndFamilies(newAttributeValues: any, formErrors: any): void {
    newAttributeValues.forEach((newAttributeValue: any) => {
      for (const k in newAttributeValue.translations) {
        if (newAttributeValue.translations.hasOwnProperty(k)) {
          delete newAttributeValue.translations[k].id;
        }
      }

      newAttributeValue.value = [].concat(newAttributeValue.value);
      const attributeValueSubscriber: Subscription = this.attributeValueResource.create(newAttributeValue)
        .subscribe((res: any) => {}, (errorData: any) => {
          if (400 === errorData.status) {
            formErrors = errorData.data.errors;
          }
        }, () => attributeValueSubscriber.unsubscribe());
    });
  }

  private updateAttributeValuesAndFamilies(
    editedAttributeValues: any[],
    pendingRequest: Observable<object>[]
  ): void {
    editedAttributeValues.forEach((editedAttributeValue: any, i: number) => {
      const attributeValueId = editedAttributeValue.id;
      delete editedAttributeValue.id;
      editedAttributeValue.value = [].concat(editedAttributeValue.value);

      this.applicationLocales.forEach((locale) => {
        editedAttributeValue.translations[locale] = {
          value: editedAttributeValue.translations[locale].value,
          locale: locale,
          toTranslate: editedAttributeValue.translations[locale].toTranslate,
          unit: editedAttributeValue.translations[locale].unit
        };
      });
      pendingRequest.push(this.attributeValueResource.partialUpdate(attributeValueId, editedAttributeValue));
    });
  }

  public deleteAction() {
    this.dialog.confirm(this.translate('PAGE.ATTRIBUTE_FAMILY.CONFIRM.DELETE'))
      .then(() => {
        const subscriber: Subscription = this.resource.remove(this.attributeFamily.id)
          .subscribe(
            () => this.actions.list.go(),
            null,
            () => subscriber.unsubscribe()
          );
      });
  }
}
