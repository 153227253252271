'use strict'

angular
  .module 'ammsFrontendApp'
  .directive 'dgsProcessing', [
    ->
      priority: 1010
      restrict: 'E'
      templateUrl: require('views/directives/dgs-processing.html')
  ]
