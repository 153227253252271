import { Resource } from '@decorators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OrderManagerResource } from '../order-manager.resource';
import {OrderManagerRelationModel} from '@components/order-manager/relation/order-manager-relation-model';
import {Observable} from 'rxjs/Observable';
import {BASE_URL_API} from '@constants';
@Injectable()
@Resource({
  routeName: 'wali_ticket',
  entryPoint: '/v2/wali_tickets',
  isHydra: true,
  cGetDefaultFilters: () => ({
    }),
  translationKey: 'ORDER_MANAGER',
  deleteAvailable: false,
  updateAvailable: false,
  createAvailable: false,
  model: OrderManagerRelationModel,
})


export class OrderManagerRelationRessource extends OrderManagerResource {
  protected nullableProperties: string[] = ['zendeskID'];
  constructor(http: HttpClient) {
    super(http);
  }

  public getAllTicketForCustomer(customerId: string, params: any = {}, options: any = {}): Observable<Object> {
    return this.http.get(`${BASE_URL_API}/v2/wali_tickets?customer=${customerId}`);
  }
}
