import {Component, Inject, OnInit} from '@angular/core';
import { AuthService} from '@services/auth.service';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { AbstractResource } from '@resources/abstract.resource';
import {
  ExportLogisticsCrossDockResource
} from '@components/export-logistics/export-logistics-cross-dock/resources/export-logistics-cross-dock.resource';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-export-logistics-cross-dock',
  template: require('./export-logistics-cross-dock.component.html'),
  providers: [
    { provide: AbstractResource, useClass: ExportLogisticsCrossDockResource },
  ]
})
export class ExportLogisticsCrossDockComponent extends AbstractPageComponent implements OnInit {
  public readonly roles: string[] = ['ROLE_WALISOFT_AGENT'];

  public isLoading: boolean = false;

  public theoreticalCrossDockCapacities: any[] = [];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private exportLogisticsCrossDockResource: ExportLogisticsCrossDockResource,
  ) {
    super($translate, authService, null, state);
  }

  ngOnInit(): void {
    this.getCapacities(this.defaultDate());
  }

  public defaultDate(): string {
    const date = new Date();
    date.setDate(date.getDate() + 2);

    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }

  public onDateChanged(event: any): void {
    const date = event.target.value;

    if (date) {
      this.getCapacities(date);
    }
  }

  protected getCapacities(date: string): void {
    this.isLoading = true;

    this.exportLogisticsCrossDockResource.getLslCapacities(
      date,
      { dontUseModel: true, blocking: false, isHydra: true, returnHydraMembers: true }
    )
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        this.theoreticalCrossDockCapacities = response.map(function (theoreticalCrossDockCapacity: any) {
          return {
            carrierCode: theoreticalCrossDockCapacity.carrierCode,
            volume: theoreticalCrossDockCapacity.volume,
            packageCount: theoreticalCrossDockCapacity.packageCount
          };
        });

        this.isLoading = false;
      })
    ;
  }
}
