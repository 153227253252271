import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { Component, Inject } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { AddressingListResource } from '../ressources';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { AddressingListFiltersService } from './services/addressing-list-filters.service';

@Component({
  selector: 'app-addressing-list',
  template: require('./addressing-list.component.html'),
  styles: [require('./addressing-list.component.scss')],
  providers: [
    { provide: AbstractResource, useClass: AddressingListResource },
    { provide: AbstractFiltersFieldsService, useClass: AddressingListFiltersService }
  ],
})
export class AddressingListComponent extends AbstractPageComponent {
  public barcodeImagePages: string[][];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService
  ) {
    super($translate, authService, resource, state);
  }

  public onPrintBarcode(barcodeImages: string[]): void {
    this.barcodeImagePages = this.chunk(barcodeImages, 16);
    setTimeout(() => {
      window.print();
    }, 1000);
  }

  private chunk(arr: any[], size: number = 1) {
    const res = [];
    for (let i = 0; i < arr.length; i += size) {
      const chunk = arr.slice(i, i + size);
      res.push(chunk);
    }
    return res;
  }
}
