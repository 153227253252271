import { CarrierScaleScheduleResource } from './export-logistics-scheduled/resources/carrier-scale-schedule.resource';
import { GenericFormModule } from './../generic/generic-form.module';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExportLogisticsComponent } from '@components/export-logistics/export-logistics.component';
import { GenericModule } from '@components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TooltipModule } from 'ngx-tooltip';
import {
  ChoiceCarrierGroupsFormComponent,
  ChoiceCarrierScalingFormComponent,
  ChoiceCountriesFormComponent,
  ChoiceWarehouseCarrierGroupAgenciesFormComponent,
  ChoiceWarehousesFormComponent,
  ChoiceMarketplacesFormComponent,
} from '@components/export-logistics/forms';
import { CarrierService, CountryService, WarehousesService, WcaService, MarketplacesService } from '@components/export-logistics/services';
import { FormNotifierService } from '@services/form-notifier.service';
import { ExportLogisticsHistoryResource } from '@components/export-logistics/export-logistics-history/export-logistics-history.resource';
import { ExportLogisticsHistoryComponent } from '@components/export-logistics/export-logistics-history/export-logistics-history.component';
import { BooleanPipe } from '../../pipes/boolean.pipe';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { ExportLogisticsScheduledComponent } from '@components/export-logistics/export-logistics-scheduled/export-logistics-scheduled.component';
import { LogisticsScheduleResource } from './export-logistics-scheduled/resources/logistics-schedule.resource';
import { SwitchModule } from '@progress/kendo-angular-inputs';
import {
  ExportLogisticsHistoryFiltersFormService
} from '@components/export-logistics/services/export-logistics-history-filters-form.service';
import { ExportLogisticsCrossDockComponent } from '@components/export-logistics/export-logistics-cross-dock/export-logistics-cross-dock.component';
import { ExportLogisticsCrossDockResource } from '@components/export-logistics/export-logistics-cross-dock/resources/export-logistics-cross-dock.resource';

@NgModule({
  declarations: [
    ExportLogisticsComponent,
    ChoiceCarrierGroupsFormComponent,
    ChoiceCarrierScalingFormComponent,
    ChoiceCountriesFormComponent,
    ChoiceWarehousesFormComponent,
    ChoiceWarehouseCarrierGroupAgenciesFormComponent,
    ChoiceMarketplacesFormComponent,
    ExportLogisticsHistoryComponent,
    ExportLogisticsScheduledComponent,
    ExportLogisticsCrossDockComponent,
    BooleanPipe,
  ],
  entryComponents: [ExportLogisticsComponent, ExportLogisticsHistoryComponent, ExportLogisticsScheduledComponent, ExportLogisticsCrossDockComponent],
  imports: [
    CommonModule,
    GenericModule,
    ReactiveFormsModule,
    TooltipModule,
    DialogsModule,
    SwitchModule,
    FormsModule,
    DropDownsModule,
    GenericFormModule,
  ],
  providers: [
    CarrierService,
    CountryService,
    WarehousesService,
    WcaService,
    MarketplacesService,
    FormNotifierService,
    ExportLogisticsHistoryResource,
    LogisticsScheduleResource,
    CarrierScaleScheduleResource,
    ExportLogisticsHistoryFiltersFormService,
    ExportLogisticsCrossDockResource
  ],
})
export class ExportLogisticsModule {}
