import { Injectable, Inject } from '@angular/core';
import { TextField } from '@components/generic/Form/dynamic/fields';
import { AbstractFormFieldBase } from '@components/generic/Form/dynamic/fields/abstract-form-field-base.class';
import { DropDownListField } from '@components/generic/Form/dynamic/fields/select-field.class';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { WarehousesResource } from '@components/warehouses';
import { IWarehouses } from '@components/warehouses/models';
import { SessionHelper } from '@helpers';
import { ICurrentCarrier } from '@interfaces';
import { FormNotifierService } from '@services/form-notifier.service';

@Injectable()
export class AddressingListFiltersService extends AbstractFiltersFieldsService {
  private warehouses: any[] = [];
  public warehouseField: DropDownListField = new DropDownListField({
    fieldName: 'masterProductsWarehouse.warehouse.code',
    label: 'PAGE.ADDRESSING.LIST.TABLE.HEAD.WAREHOUSE',
    data: SessionHelper.getWarehouses().map((warehouse: ICurrentCarrier): any => {
      return {
        code: warehouse.code,
        name: warehouse.name,
      };
    }),
    textField: 'name',
    valueField: 'code',
    value: this.filters.get('masterProductsWarehouse.warehouse.code') ? this.filters.get('masterProductsWarehouse.warehouse.code') : null,
    defaultItem: { label: '', value: null },
    valuePrimitive: true,
  });

  constructor(
    @Inject('StateService') protected state: ng.ui.IStateService,
    @Inject('TranslationService') public $translate: ng.translate.ITranslateService,
    formNotifier: FormNotifierService,
    private warehouseResource: WarehousesResource,
  ) {
    super(formNotifier, state);
    this.updateWarehouses();
  }

  /**
   * @inheritDoc.
   */
  public getFields(): AbstractFormFieldBase<any>[] {
    return [
      new TextField({
        fieldName: 'addressingBay',
        label: 'PAGE.ADDRESSING.LIST.TABLE.HEAD.ADDRESSING_BAY',
        value: this.filters.get('addressingBay') ? this.filters.get('addressingBay') : undefined,
      }),
      new TextField({
        fieldName: 'addressingLane',
        label: 'PAGE.ADDRESSING.LIST.TABLE.HEAD.ADDRESSING_LANE',
        value: this.filters.get('addressingLane') ? this.filters.get('addressingLane') : undefined,
      }),
      new TextField({
        fieldName: 'addressingLocation',
        label: 'PAGE.ADDRESSING.LIST.TABLE.HEAD.ADDRESSING_LOCATION',
        value: this.filters.get('addressingLocation') ? this.filters.get('addressingLocation') : undefined,
      }),
      new TextField({
        fieldName: 'addressingLevel',
        label: 'PAGE.ADDRESSING.LIST.TABLE.HEAD.ADDRESSING_LEVEL',
        value: this.filters.get('addressingLevel') ? this.filters.get('addressingLevel') : undefined,
      }),
      new DropDownListField({
        fieldName: 'addressingType',
        label: 'PAGE.ADDRESSING.LIST.TABLE.HEAD.ADDRESSING_TYPE',
        data: [
          { label: this.$translate.instant('PAGE.ADDRESSING.CHOICE.ADDRESSING_TYPE.PICKING'), value: 'picking' },
          { label: this.$translate.instant('PAGE.ADDRESSING.CHOICE.ADDRESSING_TYPE.STORAGE'), value: 'storage' },
        ],
        textField: 'label',
        valueField: 'value',
        value: this.filters.get('addressingType') ? this.filters.get('addressingType') : null,
        defaultItem: { label: '', value: null },
        valuePrimitive: true,
      }),
      this.warehouseField,
      new DropDownListField({
        fieldName: 'exists_barcode',
        label: 'PAGE.ADDRESSING.LIST.TABLE.HEAD.BARCODE',
        data: [
          { label: this.$translate.instant('BOOLEANS.YES'), value: 1 },
          { label: this.$translate.instant('BOOLEANS.NO'), value: 0 },
        ],
        textField: 'label',
        valueField: 'value',
        value: this.filters.get('exists_barcode') ? this.filters.get('exists_barcode') : null,
        defaultItem: { label: '', value: null },
        valuePrimitive: true,
      }),
      new DropDownListField({
        fieldName: 'filter_type',
        label: 'PAGE.ADDRESSING.LIST.TABLE.HEAD.FILTER',
        data: [
          { label: this.$translate.instant('PAGE.ADDRESSING.CHOICE.ORDER_TYPE.ASC'), value: 'asc' },
          { label: this.$translate.instant('PAGE.ADDRESSING.CHOICE.ORDER_TYPE.DESC'), value: 'desc' },
        ],
        textField: 'label',
        valueField: 'value',
        value: this.filters.get('filter_type') ? this.filters.get('filter_type') : null,
        defaultItem: { label: '', value: null },
        valuePrimitive: true,
      }),
    ];
  }

  private updateWarehouses() {
    this.warehouseResource.cGet(
      { 'acceptSparePart': true, 'active': true, 'pagination': false }
    )
      .takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        this.warehouses = response['hydra:member'].map((warehouse: IWarehouses): any => {
          return {
            code: warehouse.code,
            name: warehouse.name,
          };
        });
        this.warehouseField.data = [];
        this.warehouseField.data.push(...this.warehouses);
      });
  }
}
