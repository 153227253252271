import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { AbstractResource } from '../../resources/abstract.resource';
import { StringHelper } from '../../helpers/StringHelper';
import { Observable } from 'rxjs/Observable';
import { CustomerDetailAddressesModel } from '@components/customer/models/customer-detail-addresses.model';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  entryPoint: '/v2/customers/{id}/addresses_list',
  model: CustomerDetailAddressesModel,
  translationKey: 'CUSTOMER_CARD.DETAILS.ADDRESSES',
})
export class CustomerDetailAddressesResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  public getCustomerAddresses(id: string): Observable<object> {
    return this.get('', {entryPoint: StringHelper.replacePatterns(this.entryPoint, {'{id}': id})});
  }
}
