import { NgModule } from '@angular/core';
import { CatalogsResource } from '@components/catalogs/catalogs.resource';
import { CatalogsComponent } from '@components/catalogs/catalogs.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { GenericFormModule, GenericModule } from '@components/generic';
import { SwitchModule } from '@progress/kendo-angular-inputs';
import { CatalogsListComponent } from '@components/catalogs/list/catalogs-list.component';

@NgModule({
  entryComponents: [
    CatalogsListComponent,
    CatalogsComponent,
  ],
  declarations: [
    CatalogsListComponent,
    CatalogsComponent,
  ],
  providers: [
    CatalogsResource,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    GenericModule,
    GenericFormModule,
    SwitchModule,
  ]
})

export class CatalogsModule {}
