import { AbstractResource } from './abstract.resource';
import { Injectable } from '@angular/core';
import { Resource } from '../decorators/Resource';
import { HttpClient } from '@angular/common/http';
import { SessionHelper } from '@helpers';

@Injectable()
@Resource({
  routeName: 'sage_export',
  entryPoint: '/v2/sage_exports',
  isHydra: true,
  cGetDefaultFilters: () => ({
    page: 1,
    byPage: 30,
    'countryCode': SessionHelper.getCountry().code,
  }),
})
export class SageExportResource extends AbstractResource {
  constructor(http: HttpClient) {
    super(http);
  }
}
