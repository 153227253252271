import { Component, Inject } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { WebsiteCategoryResource } from '@components/website-category';
import { AuthService } from '@services';
import { AbstractResource } from '@resources';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { WebsiteCategoryFiltersFormService } from '@components/website-category/service/website-category-filters-form.service';

@Component({
  selector: 'app-website-category-list',
  template: require('./website-category-list.component.html'),
  providers: [
    { provide: AbstractResource, useClass: WebsiteCategoryResource },
    { provide: AbstractFiltersFieldsService, useClass: WebsiteCategoryFiltersFormService }
  ],
})
export class WebsiteCategoryListComponent extends AbstractComponent {

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }

  public goToCreate(isCategory: boolean = false): void {
      this.actions.create.go();
  }
}
