import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AbstractFormFieldBase } from '@components/generic/Form/dynamic/fields/abstract-form-field-base.class';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService, FormNotifierService } from '@services';
import { Subscription } from 'rxjs/Subscription';
import { DialogRef, DialogCloseResult } from '@progress/kendo-angular-dialog';
import { DialogBoxService } from '@services/dialog-box.service';
import { FORM_STREAMS } from '../../../../enums/form-notifier-streams.enum';

/**
 * Handle editable fields, firstly readonly fields are shown, clicking on the switch button display the form.
 *
 * If user write data into fields and click on the resetting button,
 * the component returns to the readonly state with the initial data.
 *
 * When the form is submitted, if there is no error from the API,
 * the component returns to the readonly state with the new value (the model is updated).
 *
 * If a component is in edit mode, when a user clicks on another editable component from the same page,
 * this component is destroyed.
 * However, it becomes dirty if the form is used and a popup warns the user that he can lose its new data
 * he filled in the form.
 */
@Component({
  selector: 'app-editable',
  template: require('./editable.component.html'),
  styles: [require('./editable.component.scss')],
})
export class EditableComponent extends AbstractComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public fields: AbstractFormFieldBase<any>[];

  @Input()
  public roles: string[];

  @Input()
  public id: string;

  @Output()
  public onUpdate: EventEmitter<any> = new EventEmitter();

  public readonly: boolean = true;
  private initialFields: any;
  private subscription: Subscription;
  private formInEdition: boolean = false;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private formNotifier: FormNotifierService,
    private dialogBoxService: DialogBoxService,
  ) {
    super($translate, authService, null, state);
  }

  ngOnInit(): void {
    this.initialFields = JSON.parse(JSON.stringify(this.fields));

    this.subscription = this.formNotifier.observable.subscribe((stream) => {
      if (FORM_STREAMS.formInEdition === stream) {
        this.formInEdition = true;
      }

      if (FORM_STREAMS.formIsDestroyed === stream) {
        this.formInEdition = false;
      }

      /**
       * Precedent editable component instance must be destroy when another instance is invoked or when api have updated data.
       */
      if (FORM_STREAMS.editableFormEnabled === stream.event && stream.id !== this.id || FORM_STREAMS.dataSubmitted === stream) {
        this.cancelForm();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initialFields = JSON.parse(JSON.stringify(this.fields));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Switches to editable mode.
   * Notify all editable components that one component is in edition mode.
   * Averts user if a form is in edition before switching to another editable block.
   */
  public switchToEditableMode(): void {
    if (this.formInEdition) {
      const dialog: DialogRef = this.dialogBoxService.alert({content: this.translate('DIALOG.ALERT.FORM.IN_EDITION')});

      dialog.result.subscribe((result) => {
        if (result instanceof DialogCloseResult) {
          return;
        } else {
          if (this.translate('DIALOG.BUTTON.CANCEL') === result.text) {
            return;
          }

          this.continueSwitchToEditableMode();
        }
      });
    } else {
      this.continueSwitchToEditableMode();
    }
  }

  /**
   * @emits onUpdate
   */
  public update(e: Event): void {
    this.onUpdate.emit(e);
  }

  /**
   * Switches to readonly mode. We must keep initial form fields values.
   */
  public cancelForm(): void {
    this.readonly = true;
    this.fields = JSON.parse(JSON.stringify(this.initialFields));
  }

  /**
   * Continue to editable mode.
   */
  private continueSwitchToEditableMode(): void {
    this.readonly = false;
    this.formNotifier.notifyEditableFormEnabled(this.id);
  }
}
