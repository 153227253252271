import {
  AdditionalPropertyFieldType,
  IAdditionalPropertyTranslation,
  IRestrictedValue,
  ITaskAdditionalProperty
} from './task-additional-property.interface';

export class TaskAdditionalPropertyModel implements ITaskAdditionalProperty {
  public '@id': string;
  public id: string;
  public type: AdditionalPropertyFieldType;
  public restrictedValues: IRestrictedValue[];
  public translations: { [key: string]: IAdditionalPropertyTranslation };
  public value: any;

  constructor(taskAdditionalProperty: ITaskAdditionalProperty) {
    this['@id'] = taskAdditionalProperty['@id'];
    this.id = taskAdditionalProperty.id;
    this.type = taskAdditionalProperty.type;
    this.restrictedValues = taskAdditionalProperty.restrictedValues;
    this.translations = taskAdditionalProperty.translations;
    this.value = taskAdditionalProperty.value ? taskAdditionalProperty.value : taskAdditionalProperty.default;
  }
}
