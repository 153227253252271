import { AbstractResource } from './abstract.resource';
import { Injectable } from '@angular/core';
import { Resource } from '../decorators/Resource';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

@Injectable()
@Resource({
  entryPoint: '/v2/order-manager/shipments',
  isHydra: true,
})
export class ShipmentResource extends AbstractResource {
  constructor(http: HttpClient) {
    super(http);
  }

  async createShipment(body: any) {
    if (undefined !== body.id) {
      delete body.id;
    }
    return super.create(body, {entryPoint: `${this.entryPoint}`}).toPromise();
  }

  async updateShipment(id: string, body: any) {
    return super.update(id, body, {entryPoint: `${this.entryPoint}/${id}`}).toPromise();
  }

  public resetShipment(id: string, body: any): Observable<any> {
    return super.create({shipment: `/api/v2/shipments/${id}`}, {entryPoint: `${this.entryPoint}/reset`});
  }
}
