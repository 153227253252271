import { Directive, OnInit, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[copyButton]'
})
export class CopyButtonDirective implements OnInit {
  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  public ngOnInit(): void {
    const copyButton = this.renderer.createElement('button');
    const icon = this.renderer.createElement('i');
    this.renderer.setAttribute(copyButton, 'class', 'btn btn-default btn-xs btn-right btn-copy');
    this.renderer.setAttribute(icon, 'class', 'fa fa-clipboard');
    if (this.elementRef.nativeElement.localName === 'input') {
      this.renderer.setStyle(this.elementRef.nativeElement, 'width', '90%');
    }
    this.renderer.appendChild(copyButton, icon);
    this.renderer.appendChild(this.elementRef.nativeElement.parentNode, copyButton);

    this.renderer.listen(copyButton, 'click', () => this.onClick());
  }

  public onClick(): void {
    const listener = (e: ClipboardEvent) => {
      const clipboard = e.clipboardData;
      const copyValue: string = this.elementRef.nativeElement.localName === 'input'
        ? this.elementRef.nativeElement.value
        : this.elementRef.nativeElement.innerText;

      clipboard.setData('text', copyValue);
      e.preventDefault();
    };

    document.addEventListener('copy', listener, false);
    document.execCommand('copy');
    document.removeEventListener('copy', listener, false);
  }
}
