import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services/auth.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-block-table-form',
  template: require('./block-table-form.component.html'),
  styles: [require('./block.scss')],
})
export class BlockTableFormComponent extends AbstractComponent {
  @Input() public form: FormGroup;
  @Input() public controls: any[];
  @Input() public readonly: boolean;
  @Input() public allowDelete: boolean;

  @Output() onDelete: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, null, state);
  }
}
