import { NgModule } from '@angular/core';
import { SalesLogExportComponent } from '@components/sales-log-export/sales-log-export.component';
import { ExportModule, GenericModule } from '@components';

@NgModule({
  declarations: [
    SalesLogExportComponent,
  ],
  entryComponents: [
    SalesLogExportComponent,
  ],
  exports: [
    SalesLogExportComponent
  ],
  imports: [
    ExportModule,
    GenericModule,
  ]
})

export class SalesLogExportModule {}
