import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SessionHelper, FormErrorHelper } from '@helpers';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { ManufacturerResource } from '@resources';
import { ILabelledContent } from '@interfaces';
import { SnackbarService } from '../../../snackbar/snackbar.service';
import { AuthService } from '@services';

@Component({
  selector: 'app-manufacturer-form',
  template: require('./manufacturer-form.component.html')
})
export class ManufacturerFormComponent extends AbstractPageComponent implements OnInit {

  public form: FormGroup;

  @Input() public manufacturer: ILabelledContent = {};
  @Input() public locale: string = SessionHelper.getLocale();

  @Output() public onSave: EventEmitter<object> = new EventEmitter();

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    resource: ManufacturerResource,
    private snackbar: SnackbarService,
    @Inject('DialogService') private dialog: any,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit() {
    this.form = new FormGroup({
      label: new FormControl(this.manufacturer.label, Validators.required)
    });
  }

  /**
   * Prepares query to send data to API.
   */
  private prepareQuery(query: any): object {
    return {
      ...query
    };
  }

  public submit(isRedirect: boolean = false): void {
    const save = () => {
      this.onSave.emit({
        body: this.prepareQuery(this.form.value),
        form: this.form,
        redirect: isRedirect
      });
    };
    // clean form from API errors
    FormErrorHelper.removeFormControlsAPIErrors(this.form);
    if (this.form.dirty && this.form.valid) {
      save();
    } else if (this.form.dirty && !this.form.valid) {
      this.snackbar.warn(this.translate('ALERTS.ERROR.FORM'));
    } else {
      this.snackbar.warn(this.translate('ALERTS.NO_CHANGE.FORM'));
    }
  }

  /**
   * Cancels form update and redirect to list.
   */
  public cancel(): void {
    this.dialog.confirm(this.translate('DIALOG.TEXT.DONT_SAVE'))
      .then(() => this.actions.list.go())
    ;
  }
}
