import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { IGenericListNotifier } from '@services/generic-list-notifier.interface';
import { REFUNDS_LIST_STREAMS } from '../enums/generic-list-notifier-streams.enum';

export class GenericListNotifierService implements IGenericListNotifier {
  private subject: Subject<any> = new Subject<any>();

  /**
   * Observable streams.
   */
  public observable: Observable<any> = this.subject.asObservable();

  public notifySwitchValueChange(item: any, status: boolean): void {
    this.subject.next({event: REFUNDS_LIST_STREAMS.refundsListSwitchValueChange, item, status});
  }
}
