import { Injectable } from '@angular/core';
import { ICarrierGroup } from '@components/export-logistics/forms/choice-carrier-groups-form.component';

/**
 * Keep a reference of carrier checked needed by the ExportLogisticsComponent.
 */
@Injectable()
export class CarrierService {

    private carriersChecked: ICarrierGroup[] = [];

    public setCarriersChecked(carriersChecked: ICarrierGroup[]): void {
        this.carriersChecked = carriersChecked;
    }

    public getCarrierGroupsChecked(): ICarrierGroup[] {
        return this.carriersChecked;
    }
}
