import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { AbstractFormFieldBase } from '@components/generic/Form/dynamic/fields/abstract-form-field-base.class';

/**
 * A simple service for transforming the fields to a FormGroup.
 *
 * In a nutshell, the form group consumes the metadata from the field model,
 *     and allows you to specify default values and validation rules.
 */
@Injectable()
export class FieldFactoryService {
  /**
   * Constructs the form group.
   *
   * @param {AbstractFormFieldBase<any>[]} fields
   * @returns {FormGroup}
   */
  toFormGroup(fields: AbstractFormFieldBase<any>[]): FormGroup {
    const group: {[key: string]: FormControl} = {};

    fields.forEach(field => {
      group[field.fieldName] = field.required ?
        new FormControl(field.value || null, Validators.required) :
        new FormControl(field.value || null)
      ;
    });

    return new FormGroup(group);
  }
}
