import { Component, Inject, OnInit } from '@angular/core';
import { CustomerDetailAddressesResource } from '../../customer-detail-addresses.resource';
import { SnackbarService } from '../../../snackbar';
import { Subscription } from 'rxjs/Subscription';
import { CustomerFieldsFormService } from '@components/customer/services/customer-fields-form.service';
import { AuthService, FormNotifierService } from '@services';
import { IAddress } from '@models/address.interface';
import { AddressesResource } from '@resources/addresses.resource';
import { IAddressesFields, ICustomerDetailAddresses, ICustomerDetailOrderAddress } from '@components/customer/interfaces';
import { ADDRESS_TYPES } from '../../../../enums/address-types.enum';
import { AbstractComponent } from '@components/generic/abstract.component';

/**
 * Displays a list of customer orders.
 */
@Component({
  selector: 'app-customer-detail-addresses',
  template: require('./customer-detail-addresses.component.html'),
  styles: [require('./customer-detail-addresses.component.scss')]
})
export class CustomerDetailAddressesComponent extends AbstractComponent implements OnInit {
  public readonly roles: string[] = ['ROLE_WALISOFT_AGENT', 'ROLE_WALISOFT_AGENT'];

  public customerAddresses: ICustomerDetailAddresses;
  public customerAddressesEditableFields: IAddressesFields[][];
  public addressTypes: typeof ADDRESS_TYPES = ADDRESS_TYPES;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    protected resource: CustomerDetailAddressesResource,
    public addressResource: AddressesResource,
    private formNotifier: FormNotifierService,
    private snackbar: SnackbarService,
    private customerFieldsFormService: CustomerFieldsFormService,
  ) {
    super($translate, authService, null, state);
  }

  ngOnInit(): void {
    if (!this.isGranted(this.roles)) {
      this.snackbar.alert(this.translate('ALERTS.AUTHORIZATION_FAIL'));

      return;
    }

    this.fetch();
  }

  private fetch(): void {
    const subscriber: Subscription = this.resource.getCustomerAddresses(this.state.params.id)
      .subscribe((response: ICustomerDetailAddresses) => {
        this.customerAddresses = response;
        this.customerAddressesEditableFields = this.customerFieldsFormService.getAddressesFields(response);
      }, undefined, () => subscriber.unsubscribe())
    ;
  }

  public update(address: IAddress, addressId: string, addressType: string): void {
    const subscriber: Subscription = this.addressResource.partialUpdate(addressId, address)
      .subscribe((response: IAddress) => {
        this.snackbar.validate(this.translate('PAGE.CUSTOMER_CARD.DETAILS.ADDRESSES.LIST.SAVED'));

        switch (addressType) {
          case this.addressTypes.SHIPPING:
          case this.addressTypes.BILLING:
            this.customerAddresses[addressType].forEach((addressItem: ICustomerDetailOrderAddress, index: number) => {
              if (addressId === addressItem.address.id) {
                this.customerAddresses[addressType][index].address = response;
              }
            });
            break;
          default:
            this.customerAddresses.addresses.forEach((addressItem: IAddress, index: number) => {
              if (addressId === addressItem.id) {
                this.customerAddresses.addresses[index] = response;
              }
            });
        }

        this.customerAddressesEditableFields = this.customerFieldsFormService.getAddressesFields(this.customerAddresses);
        this.formNotifier.notifyFormSubmitted();
      }, (reject: any) => {
        if (400 === reject.status) {
          this.formNotifier.notifyFormHasErrors({reject});
        }
      }, () => subscriber.unsubscribe())
    ;
  }
}
