import {cloneDeep} from 'lodash';

export class ModelHelper {

    /**
     * Creates an object indexed with listed properties in @param properties
     * and containing values at same indexes in @param defaultModel if it contains the property
     * or in @param source if it contains the property. if value in @param source contains an id,
     * then we fill result only with the id at this index. Used to prepare sent data to api.
     *
     * @static
     * @param {string[]} [properties=[]]
     * @param {{[key: string]: any}} [source={}]
     * @param {{[key: string]: any}} [defaultModel={}]
     * @returns {{[keys: string]: any}}
     * @memberof ModelHelper
     */
    static populateModel(
        properties: string[] = [],
        source: {[key: string]: any} = {},
        defaultModel: {[key: string]: any} = {}
    ): {[keys: string]: any} {
        const model: {[key: string]: any} = {};

        if (properties) {
            properties.forEach((property: any) => {
                if (!Object.keys(defaultModel).includes(property)) {
                    if (Object.keys(source).includes(property)) {
                        if (
                            null != source[property] &&
                            undefined !== source[property].id
                        ) {
                            model[property] = source[property].id;
                        } else {
                            model[property] = cloneDeep(source[property]);
                        }
                    }
                } else {
                    model[property] = cloneDeep(defaultModel[property]);
                }
            });
        }

        return model;
    }
}
