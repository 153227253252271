import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { AuthService } from '@services/auth.service';
import { TaskTypeResource } from '@components/generic/task-manager/resources';
import { IUser } from '@models/user.interface';

@Component({
  selector: 'app-task-type-user-list',
  template: require('./task-type-user-list.component.html')
})

export class TaskTypeUserListComponent extends AbstractPageComponent {

  @Input() public users: IUser[];
  @Input() public type: string;

  @Output() public onDelete: EventEmitter<any> = new EventEmitter();
  @Output() public onAdd: EventEmitter<any> = new EventEmitter();

  public searchedUsers: IUser[] = [];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    public resource: TaskTypeResource,
  ) {
    super($translate, authService, resource, state);
  }

  public delete(users: IUser[]) {
    this.onDelete.emit(users);
  }

  public add(users: IUser[]) {
    this.onAdd.emit(users);
  }

  public change(users: IUser[]): void {
    this.searchedUsers = users;
  }
}
