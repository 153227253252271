import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { LoaderService } from '@components/loader';
import 'rxjs/add/operator/map';

/**
 * Append Bearer to header if user is authenticated
 */
@Injectable()
export class PendingInterceptor implements HttpInterceptor {
  constructor(private loaderService: LoaderService) {}

  /**
   * Generate a key based on method and full url
   *
   * @param {HttpRequest<any>} request
   * @returns {string}
   */
  public static getRequestId(request: HttpRequest<any>) {
    return [request.method, request.urlWithParams].join('-');
  }

  /**
   * @inheritDoc
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestKey: string = PendingInterceptor.getRequestId(req);

    // adds loader on request
    this.loaderService.add(requestKey, req.reportProgress);

    return next.handle(req)
      .map((response: any) => {
        if (response.hasOwnProperty('status')) {
          // removes loader on response
          this.loaderService.remove(requestKey);
        }

        return response;
      });
  }
}
