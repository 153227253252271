import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { HttpClient } from '@angular/common/http';
import { CategorySatelliteModel } from './models/category-satellite.model';
import { AbstractResource } from '@resources';
import { CategorySatelliteListModel } from './models/category-satellite.list.model';

@Injectable()
@Resource({
  routeName: 'websiteCategories.edit.categoriesSatellite',
  entryPoint: '/v2/category_satellites',
  isHydra: true,
  model: CategorySatelliteModel,
  translationKey: 'CATEGORIES_SATELLITE',
  listColumns: [
    { field: 'id', translationKey: 'ID', type: 'id' },
    { field: 'name', translationKey: 'NAME', type: 'string' },
  ],
  listModel: CategorySatelliteListModel,
  createAvailable: true,
  updateAvailable: true,
  deleteAvailable: true,
  buttonActionMultipleAvailable: false,
  goToEditKey: 'idSatellite'
})
export class CategorySatelliteResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }
}
