import { cloneDeep, forEach, isString } from 'lodash';

export class ProductMarketplaceHelper {

  /**
   * Removes properties that are not desired.
   *
   * @static
   * @param {{[keys: string]: any}} target
   * @returns
   * @memberof ProductMarketplaceHelper
   */
  public static purgeInvalidProperties(target: {[keys: string]: any}): {[keys: string]: any} {
    if (target == null) {
      target = {};
    }

    const product = cloneDeep(target);

    [
      'attributeLabels',
      'errors',
      'eta_min',
      'eta_max',
      'eta_min_computed',
      'eta_max_computed',
      'offers',
      'superProductCategories',
      'attributeValues',
    ]
      .forEach(field => delete product[field]);

    // removes id from translations
    forEach(product.translations, (translation: {[keys: string]: any}) => delete translation.id);

    return product;
  }

  /**
   * Formats attribute marketplace values to be compatible with requirements.
   *
   * @param {[]} values - attribute marketplace values
   * @returns {{ attributeMarketplace: string; value: any; }[]}
   * @memberof ProductMarketplaceHelper
   */
  public static formatValuesForRequest(values: any[]): { attributeMarketplace: string; value: any; }[] {
    if (null == values || Array.isArray(values)) {
      return [];
    }

    const newValues = Object.entries(values).map(value => {
      return {
        'attributeMarketplace': value[0],
        'value': this.removeEmptyValue(value[1])
      };
    }).filter(value => !!value.value);

    return newValues;
  }

  /**
   * Formats value of a field depending of the type field
   *
   * @private
   * @static
   * @param {*} value
   * @returns
   * @memberof ProductMarketplaceHelper
   */
  public static removeEmptyValue(value: any) {
    if (null == value || isString(value) && '' === value.trim()) {
      return null;
    }

    return value;
  }

  /**
   * Formats array of values send from API to a parsable object
   *
   * @static
   * @param {*} values
   * @returns
   * @memberof ProductMarketplaceHelper
   */
  public static formatValues(values: any) {
    if (Array.isArray(values)) {
      const model: any = {};
      values.forEach(attribute => model[attribute.attributeMarketplace.id] = attribute.value);

      return model;
    }

    return values;
  }

  /**
   * Is product marketplace has stock and shipping date?
   *
   * @static
   * @param {number} stock
   * @param {boolean} isInPreorder
   * @returns {boolean}
   * @memberof ProductMarketplaceHelper
   */
  public static hasStockAndShippingDate(stock: number, isInPreorder: boolean): boolean {
    return !!stock && isInPreorder;
  }

  /**
   * Is product marketplace has stock and shipping date?
   *
   * @static
   * @param {number} stock
   * @param {boolean} isInPreorder
   * @returns
   * @memberof ProductMarketplaceHelper
   */
  public static hasStockWithoutShippingDate(stock: number, isInPreorder: boolean) {
    return !!stock && !isInPreorder;
  }
}
