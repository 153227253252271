import { Inject, Injectable } from '@angular/core';
import { SessionHelper } from '@helpers/session.helper';
import { FormNotifierService } from '@services/form-notifier.service';
import { MultiSearchField } from '@components/generic/Form/dynamic/fields';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';

@Injectable()
export class ExportLogisticsHistoryFiltersFormService extends AbstractFiltersFieldsService {
  constructor(
    @Inject('StateService') protected state: ng.ui.IStateService,
    @Inject('TranslationService') public $translate: ng.translate.ITranslateService,
    formNotifier: FormNotifierService,
  ) {
    super(formNotifier, state);
  }

  public getFields(): any[]  {
    return [
      new MultiSearchField({
        fieldName: 'country_codes[]',
        label: 'PAGE.EXPORT_LOGISTICS.HISTORY.FILTERS.COUNTRIES',
        data: SessionHelper.getCountries(),
        textField: 'name',
        valueField: 'code',
        value: this.filters.getAll('country_codes[]').length !== 0 ? this.filters.getAll('country_codes[]') : undefined,
        order: 1
      }),

      new MultiSearchField({
        fieldName: 'warehouse_names[]',
        label: 'PAGE.EXPORT_LOGISTICS.HISTORY.FILTERS.WAREHOUSES',
        data: SessionHelper.getWarehouses(),
        textField: 'name',
        valueField: 'code',
        value: this.filters.getAll('warehouse_names[]').length !== 0 ? this.filters.getAll('warehouse_names[]') : undefined,
        order: 2
      }),

      new MultiSearchField({
        fieldName: 'carrier_names[]',
        label: 'PAGE.EXPORT_LOGISTICS.HISTORY.FILTERS.CARRIERS',
        data: SessionHelper.getCarrierGroups(),
        textField: 'name',
        valueField: 'code',
        value: this.filters.getAll('carrier_names[]').length !== 0 ? this.filters.getAll('carrier_names[]') : undefined,
        order: 3
      }),
    ];
  }
}
