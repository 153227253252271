export class StockMovementModel {
  public id: number;
  public order: number;
  public status: string;
  public from: string;
  public received: string;
  public transferred: string;

  constructor(stockMovement: any) {
    this.id = stockMovement.id;
    this.order = stockMovement.order;
    this.status = stockMovement.status;
    this.from = stockMovement.fromWarehouse.name;
    this.received = stockMovement.received;
    this.transferred = stockMovement.transferred;
  }
}
