import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { Component, Inject } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { ServiceResource } from '@components/service/service.resource';

@Component({
  selector: 'app-service-list',
  template: require('./service-list.component.html'),
  providers: [
    { provide: AbstractResource, useClass: ServiceResource },
  ],
})
export class ServiceListComponent extends AbstractPageComponent {

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }
}
