import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { AbstractResource } from '@resources';
import { HttpClient } from '@angular/common/http';
import { MarketplacesConfigureModel } from './marketplaces-configure.model';
import { SessionHelper } from '@helpers';

@Injectable()
@Resource({
  entryPoint: '/v2/marketplaces',
  isHydra: true,
  deleteAvailable: false,
  updateAvailable: false,
  listModel: MarketplacesConfigureModel,
  translationKey: 'MARKETPLACES_CONFIGURE',
  listColumns: [
    { field: 'commercialName', translationKey: 'MARKETPLACE', type: 'string' },
    { field: 'preorder', translationKey: 'ACCEPT_PREORDER', type: 'boolean', switchable: true },
    { field: 'provisionalShippingDelay', translationKey: 'DELAY', type: 'number' },
    { field: 'maxEtdAccepted', translationKey: 'MAX_ESTIMATE_DATE', type: 'number' },
  ],
  cGetDefaultFilters: () => ({
    'order[commercialName]': 'asc',
    country: SessionHelper.getCountry() && SessionHelper.getCountry().code,
  }),
})
export class MarketplacesConfigureResource extends AbstractResource {
  constructor(
    http: HttpClient,
  ) {
    super(http);
  }
}
