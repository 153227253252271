import { AbstractComponent } from '@components/generic/abstract.component';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DATE_FULL_FORMAT, DATE_SHORT_FORMAT, INPUT_NUMBER_PATTERN_DEC } from '@constants';
import { LitigationStatusResource } from '@components/order-manager/resources/litigation-status.resource';
import { LitigationTypeResource } from '@components/order-manager/resources/litigation-type.resource';
import { SnackbarService } from '@components';
import { FormNotifierService } from '@services';
import { OrderManagerResource } from '@components/order-manager/order-manager.resource';
import { LitigationResource } from '@components/order-manager/resources/litigation.resource';
import { HydraHelper } from '@helpers/HydraHelper';
import * as moment from 'moment';
import { OrderItemResource } from '@resources';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { Observable } from 'rxjs/Observable';
import { takeUntil } from 'rxjs/operators';
import { PackageResource } from '@resources/package.resource';
import { CarrierGroupResource } from '@resources';
import { ICarrierGroup } from '@interfaces';

@Component({
  selector: 'app-order-manager-litigation-form',
  template: require('./order-manager-litigation-form.component.html'),
  providers: [
    OrderManagerResource, OrderItemResource, LitigationResource, LitigationStatusResource, LitigationTypeResource
  ],
})
export class OrderManagerLitigationFormComponent extends AbstractComponent implements OnInit {

  @Input() public order?: any;
  @Input() public litigation: any;
  @Input() public litigationStatuses: any[] = [];
  @Input() public litigationTypes: any[] = [];
  @Output() onClose: EventEmitter<any> = new EventEmitter();

  public form: FormGroup;
  public showProductList: boolean = false;
  public items: any[] = [];
  public selectedItem: any;
  public selectedProduct: any;
  public selectedLitigationTypes: any = {};
  public packages: any[] = [];
  public carrierGroups: any[] = [];
  public defaultLitigationType: { id?: any; label?: any } = {
    id: null,
    label: '',
  };
  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    private formBuilder: FormBuilder,
    private litigationResource: LitigationResource,
    private formNotifier: FormNotifierService,
    private snackbar: SnackbarService,
    @Inject('StateService') state: ng.ui.IStateService,
    @Inject('DialogService') private dialog: any,
    private packageResource: PackageResource,
    private carrierGroupResource: CarrierGroupResource,
  ) {
    super($translate, authService, resource, state);
  }

  public ngOnInit(): void {
    this.items = this.order.items.map((item: any) => {
      return { value: item, text: item.product.sku, productId: item.product['@id'] };
    });

    if (this.litigation) {
      this.selectedItem = this.items.find((item) => {
        return this.litigation.orderItem['@id'] === item.value['@id'];
      });

      this.litigation.litigationPackages.map((litigationPackage: any) => {
        this.selectedLitigationTypes[litigationPackage.package['@id']] = litigationPackage.litigationType;
      });

      this.showProductList = this.litigation.orderItem.product.id !== this.litigation.product.id;
      this.fetchPackages(this.litigation.product.sku, this.litigation.orderItem.version);
    }

    this.fetchCarriers();
  }

  private buildForm(): void {
    const payment: any = this.litigation ? this.litigation.litigationPayment : null;

    this.form = this.formBuilder.group({
      carrierGroup: [this.getCarrierGroup(), Validators.required],
      sku: [this.selectedItem, Validators.required],
      product: [this.showProductList && this.litigation ? this.litigation.product.id : null],
      litigationStatus: [this.getLitigationStatus(), Validators.required],
      trackingNumber: [this.litigation ? this.litigation.trackingNumber : null],
      cmup: [''],
      litigationTotal: [this.litigation ? this.litigation.litigationTotal : null, [Validators.pattern(INPUT_NUMBER_PATTERN_DEC), Validators.required]],
      credit: [this.litigation ? this.litigation.credit : 0, [Validators.pattern(INPUT_NUMBER_PATTERN_DEC), Validators.required]],
      carrierTotal: [this.litigation ? this.litigation.carrierTotal : 0, [Validators.pattern(INPUT_NUMBER_PATTERN_DEC)]],
      paymentDate: [payment ? moment(payment.date, DATE_FULL_FORMAT).format(DATE_SHORT_FORMAT) : null],
      zendeskTicketId: [this.litigation ? this.litigation.zendeskTicketId : ''],
      comment: [this.litigation ? this.litigation.comment : ''],
      files: [''],
    });
  }

  private getLitigationStatus(): any {
    if (!this.litigation) {
      return null;
    }

    return this.litigationStatuses.find((item) => {
      return this.litigation.litigationStatus === item.id;
    });
  }

  private getCarrierGroup(): any {
    if (!this.litigation) {
      return null;
    }

    return this.carrierGroups.find((item) => {
      return this.litigation.carrierGroup['@id'] === item['@id'];
    });
  }

  public getLitigationType(currentPackage: any): any {
    let litigationPackage: any = null;

    if (this.litigation) {
      litigationPackage = this.litigation.litigationPackages.find((item: any) => {
        return item.package['@id'] === currentPackage['@id'];
      });
    }

    if (!litigationPackage) {
      return null;
    }

    return this.litigationTypes.find((item) => {
      return litigationPackage.litigationType === item.id;
    });
  }

  private getLitigationTypes(): any[] {
    const litigationTypeIds: string[] = [];

    Object.keys(this.selectedLitigationTypes).map((packageId: any) => {
      litigationTypeIds.push(this.selectedLitigationTypes[packageId]);
    });

    return this.litigationTypes.filter((item: any) => {
      return litigationTypeIds.includes(item.id);
    });
  }

  private prepareBody(): any {
    const form = this.form.value;
    const litigationPackages: any[] = [];

    Object.keys(this.selectedLitigationTypes).map((packageId: any) => {
      let litigationPackage;

      if (this.litigation) {
        litigationPackage = this.litigation.litigationPackages.find((item: any) => {
          return item.package['@id'] === packageId;
        });
      }

      const litigationPackageData: any = {
        package: packageId,
        litigationType: this.selectedLitigationTypes[packageId]
      };

      if (litigationPackage) {
        litigationPackageData.id = litigationPackage['@id'];
      }

      litigationPackages.push(litigationPackageData);
    });

    const payment: any = {};

    if (this.litigation && this.litigation.litigationPayment) {
      payment['id'] = this.litigation.litigationPayment['@id'];
    }

    if (form.paymentDate) {
      payment['date'] = moment(form.paymentDate, DATE_SHORT_FORMAT).format(DATE_FULL_FORMAT);
    }

    const body: any = {
      carrierGroup: form.carrierGroup['@id'],
      orderItem: this.selectedItem.value['@id'],
      product: this.showProductList && this.selectedProduct ? HydraHelper.buildIri(this.selectedProduct, 'products') : this.selectedItem.productId,
      litigationStatus: form.litigationStatus.id,
      litigationTotal: form.litigationTotal,
      credit: form.credit,
      carrierTotal: form.carrierTotal,
      zendeskTicketId: form.zendeskTicketId,
      comment: form.comment,
      litigationPackages: litigationPackages,
      trackingNumber: this.hasLitigationType('reverse_damage') ? form.trackingNumber : null,
    };

    if (payment.date) {
      body.litigationPayment = payment;
    }

    return body;
  }

  private getUploadFileObservables(litigationId: any): Observable<any>[] {
    const observables: Observable<object>[] = [];

    if (this.form && this.form.value.files && this.form.value.files.subscriber) {
      this.form.value.files.subscriber.subscribe((fileList: any[]) => {
        fileList.map((file: any) => {
          const formData = new FormData();
          formData.append('litigation', litigationId);
          formData.append('file', file.rawFile, file.name);

          observables.push(this.litigationResource.uploadFile(formData, { entryPoint: '/v2/litigation_images', blocking: false}));
        });
      });
    }

    return observables;
  }

  public submit(): void {
    if (!+this.form.value.litigationTotal) {
      this.snackbar.warn(this.translate('PAGE.ORDER.EDIT.EDIT_LITIGATION.FILL_LITIGATION_TOTAL'));
      return;
    }

    if (this.form.value.litigationStatus.code === 'billed' && !+this.form.value.carrierTotal) {
      this.snackbar.warn(this.translate('PAGE.ORDER.EDIT.EDIT_LITIGATION.FILL_CARRIER_TOTAL'));
      return;
    }

    if (this.form.value.litigationStatus.code === 'billed' && !this.form.value.paymentDate) {
      this.snackbar.warn(this.translate('PAGE.ORDER.EDIT.EDIT_LITIGATION.FILL_PAYMENT_DATE'));
      return;
    }

    if (this.form.value.paymentDate && moment(this.form.value.paymentDate, DATE_SHORT_FORMAT).isAfter(moment(), 'day')) {
      this.snackbar.warn(this.translate('PAGE.LITIGATION.LIST.BULK_PAYMENT_ERROR_DATE'));
      return;
    }

    const body = this.prepareBody();

    if (!body.litigationPackages.length) {
      this.snackbar.warn(this.translate('PAGE.ORDER.EDIT.EDIT_LITIGATION.FILL_PACKAGES'));
      return;
    }

    if (this.hasLitigationType('reverse_damage') && !body.trackingNumber) {
      this.snackbar.warn(this.translate('PAGE.ORDER.EDIT.EDIT_LITIGATION.FILL_TRACKING'));
      return;
    }

    if (this.litigation) {
      this.dialog.confirm(this.translate('PAGE.ORDER.EDIT.EDIT_LITIGATION.CONFIRM'))
        .then(() => {
          this.litigationResource.update(this.litigation.id, body, { cleanParams: false, reload : true })
            .takeUntil(this.destroyed$)
            .subscribe(() => {
              this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
              const uploads = this.getUploadFileObservables(this.litigation.id);

              if (uploads.length) {
                forkJoin(uploads).pipe(takeUntil(this.destroyed$)).subscribe(() => {
                  this.state.go(this.state.current, this.state.params, { reload: true });
                });
              } else {
                this.state.go(this.state.current, this.state.params, { reload: true });
              }
            })
          ;
        })
      ;

      return;
    }

    this.dialog.confirm(this.translate('PAGE.ORDER.EDIT.CREATE_LITIGATION.CONFIRM'))
      .then(() => {
        this.litigationResource.create(body)
          .takeUntil(this.destroyed$)
          .subscribe((response: any) => {
            this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
            const uploads = this.getUploadFileObservables(response.id);

            if (uploads.length) {
              forkJoin(uploads).pipe(takeUntil(this.destroyed$)).subscribe(() => {
                this.state.go(this.state.current, this.state.params, { reload: true });
              });
            } else {
              this.state.go(this.state.current, this.state.params, { reload: true });
            }
          })
        ;
      })
    ;
  }

  public hasLitigationStatus(statuses: string[]): boolean {
    const litigationStatus = this.getLitigationStatus();

    return litigationStatus && statuses.includes(litigationStatus.code);
  }

  public hasFormLitigationStatus(statuses: string[]): boolean {
    const form = this.form.value;
    const currentFormStatus = form.litigationStatus ? form.litigationStatus.code : null;

    return currentFormStatus && statuses.includes(currentFormStatus);
  }

  public hasLitigationType(type: string): boolean {
    const litigationTypes: any[] = this.getLitigationTypes();

    return litigationTypes.filter((item: any): boolean => {
      return item.code === type;
    }).length > 0;
  }

  public fetchPackages(sku: string, version: string): void {
    this.packageResource.cGet({ sku: sku, version: version, countryCode: this.order.shippingAddress.countryCode }, { entryPoint: `/v2/packages/skus` })
      .takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        this.packages = response['hydra:member'].map((item: any) => {
          return { '@id': HydraHelper.buildIri(item.id, 'packages'), id: item.id, label: item.sku + '/' + item.number };
        });
      })
    ;
  }

  public refreshPackages(): void {
    if (!this.showProductList && this.selectedItem) {
      this.fetchPackages(this.selectedItem.value.product.sku, this.selectedItem.value.version);
    }
  }

  public fetchCarriers(): void {
    this.carrierGroupResource.getMany(
      { pagination: false, 'carriers.country.code': this.order.locale.split('_')[1] },
      { dontUseModel: true }
    )
      .takeUntil(this.destroyed$)
      .subscribe((carrierGroups: ICarrierGroup[]) => {
        this.carrierGroups = carrierGroups.sort((a: ICarrierGroup, b: ICarrierGroup) => a.code.localeCompare(b.code));
        this.buildForm();
      })
    ;
  }

  public setCarrier(): void {
    const carrierGroup = this.carrierGroups.find((item) => {
      return this.selectedItem.value.carrier === item.code;
    });

    if (carrierGroup) {
      this.form.get('carrierGroup').setValue(carrierGroup);
    }
  }

  public litigationTypeChange(event: any, packageLitigation: any): void {
    if (event.id) {
      this.selectedLitigationTypes[packageLitigation['@id']] = event.id;

      return;
    }

    delete this.selectedLitigationTypes[packageLitigation['@id']];
  }
}
