import { Component, Inject } from '@angular/core';
import { AbstractResource } from '@resources';
import { FormGroup} from '@angular/forms';
import { AuthService } from '@services';
import { CarrierScaleResource } from '@resources/carrier-scale.resource';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';

@Component({
  selector: 'app-order-manager-scaling-list',
  template: require('./order-manager-scaling-list.component.html'),
  providers: [
    { provide: AbstractResource, useClass: CarrierScaleResource },
  ],
})
export class OrderManagerScalingListComponent extends AbstractPageComponent {

  public form: FormGroup;
  public warehouses: any[] = [];
  public carriers: any[] = [];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }
}
