import { Component, Inject } from '@angular/core';
import { ReinsurancePointsResource } from '@components/footer/reinsurance-points';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { AbstractResource } from '@resources/abstract.resource';
import { AuthService } from '@services/auth.service';
import { SessionHelper } from '@helpers/session.helper';

@Component({
  selector: 'app-reinsurance-points-list',
  template: require('./reinsurance-points-list.component.html'),
  providers: [{provide: AbstractResource, useClass: ReinsurancePointsResource}],
})
export class ReinsurancePointsListComponent extends AbstractPageComponent {
  public currentLocales: string[] = SessionHelper.getCountry().locales;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }

  public hasSeveralTranslations(): boolean {
    return this.currentLocales.length > 1;
  }
}
