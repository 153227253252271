import { ITaskAttachment, ITaskType } from '../interfaces';
import {
  AssignGroups,
  IAssignFrom,
  IAssignTo,
  ICreatedBy,
  ITask,
  TaskStatuses,
} from './task.interface';
import { ITaskComment } from './task-comment.interface';

export class TaskModel implements ITask {
  public '@id': string;
  public id: string;
  public assignFrom: string|null;
  public assignTo: string|null;
  public assignGroup: AssignGroups;
  public assignedToMe: boolean;
  public attachments: ITaskAttachment[];
  public businessObjectId: string;
  public concernedMe: boolean;
  public createdBy: string;
  public status: TaskStatuses;
  public taskComments: ITaskComment[];
  public taskType: ITaskType;

  constructor(task: ITask) {
    this['@id'] = task['@id'];
    this.id = task.id;
    this.assignFrom = task.assignFrom ? (<IAssignFrom>task.assignFrom).username : null;
    this.assignTo = task.assignTo ? (<IAssignTo>task.assignTo).username : null;
    this.assignGroup = task.assignGroup;
    this.assignedToMe = task.assignedToMe;
    this.attachments = task.attachments;
    this.businessObjectId = task.businessObjectId;
    this.concernedMe = task.concernedMe;
    this.createdBy = (<ICreatedBy>task.createdBy).username;
    this.status = task.status;
    this.taskComments = task.taskComments;
    this.taskType = <ITaskType>task.taskType;
  }
}
