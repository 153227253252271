export default glossaryRoutes;

/** @ngInject */
function glossaryRoutes($stateProvider: any): void {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'walipush',
    sidebarSubNav: 'glossaryList',
    title: '',
    reloadChangeCountry: 'glossary.list',
  };

  $stateProvider
    .state('glossary', {
      url: '/glossary',
      parent: 'walisoft',
      abstract: true,
      breadcrumb: {
        label: 'SIDEBAR.LABEL.GLOSSARY',
          stateName: 'glossary.list'
      },
    })
    .state('glossary.list', {
      url: '',
      views: {
        'page@layout': {
          template: '<app-glossary-list></app-glossary-list>'
        },
      },
      data: Object.assign(commonStateData, {
        title: 'SIDEBAR.LABEL.GLOSSARY'
      })
    })
    .state('glossary.edit', {
      url: '/:id/edit',
      breadcrumb: { label: 'PAGE.EAV_ATTRIBUTE.EDIT.TITLE' },
      views: {
        'page@layout': {
          template: '<app-glossary-form></app-glossary-form>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'SIDEBAR.LABEL.GLOSSARY',
      })
    })
    .state('glossary.new', {
      url: '/new',
      breadcrumb: { label: 'SIDEBAR.LABEL.GLOSSARY' },
      views: {
        'page@layout': {
          template: '<app-glossary-form></app-glossary-form>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'SIDEBAR.LABEL.GLOSSARY',
      })
    })
  ;
}
