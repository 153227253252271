import { SessionHelper } from './session.helper';
import { IPackage } from '@models/IPackage';

export class OrderNewHelper {

  public static setDateExpired(key: string, timestamp: number): void {
    key = key + '_date_expired';
    SessionHelper.set(key, timestamp);
  }

  public static isDateExpired(key: string): boolean {
    key += '_date_expired';
    const data: number = SessionHelper.get(key);

    if (!data) {
      return true;
    }

    return Date.now() >= ((1000 * 60 * 60) + data);
  }

  /**
   * The orderItem must be locked for the edition.
   * Its status is higher than "handling".
   */
  public static isOrderItemLockedForEdition(status: string) {
    return ['exporting', 'in_transit', 'delivered', 'export_error'].includes(status);
  }

  public static areOrderItemPackagesInDifferentStatus(orderItem: any) {
    const packagesStatus: string[] = orderItem.packages.map((pkg: IPackage) => pkg.status);

    return (new Set(packagesStatus)).size > 1;
  }

  /**
   * Check if order items contains the targeted status.
   */
  public static checkStatusInOrderItems(orderItems: any[] = [], status: string): boolean {
    return 0 !== orderItems.filter((orderItem) => status === orderItem.status).length;
  }

  /**
   * Check if order items contains the targeted carriers.
   */
  public static checkCarriersInOrderItems(orderItems: any[] = [], carriers: any[] = []): boolean {
    return 0 !== orderItems.filter((orderItem) => carriers.includes(orderItem.carrier)).length;
  }

  public static getTotalTax(order: any, taxName: string): number {
    let totalTax = 0;

    order.items.forEach((item: any) => {
      if (item.status !== 'cancelled' && item.status !== 'refused') {
        const quantity: number = (item.isNew || item.manuallyAdded) ? item.quantity : item.initialQuantity;
        totalTax += item[taxName] * quantity;
      }
    });

    return +totalTax.toFixed(2);
  }
}
