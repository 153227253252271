'use strict'

import { FormHelper } from '@helpers';

###
  @name FormHasErrors

  @description Add CSS class at current element if the API return errors.

  @param {Object} errors
  @param {String} field

  @example
    <example>
      <div form-has-errors
           errors="single.formErrors"
           field="field-name"></div>
    </example>
###
angular
  .module('ammsFrontendApp')
  .directive('formHasErrors', [ ->
      replace: true
      restrict: 'A'
      scope:
        errors: '='
        field: '@'
      link: (scope, elem) ->
        scope.$watchCollection('errors', (newValue) ->
          if (elem.hasClass('has-error'))
            elem.removeClass('has-error')

          if (!_.isEmpty(newValue) && !!FormHelper.getErrors(newValue, scope.field).length)
            elem.addClass('has-error')
        )
  ])
