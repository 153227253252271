import { Resource } from '@decorators';
import { Injectable } from '@angular/core';
import { AbstractResource } from '../resources/abstract.resource';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  entryPoint: '/v2/packages',
  isHydra: true
})

export class PackageResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }
}
