import { IOneyOpc } from '@components/oney-opc/interfaces/oney-opc.interface';
import moment = require('moment');
import { DATE_SHORT_FORMAT } from '@constants';

export class OneyOpcModel implements IOneyOpc {
  id: string;
  locale: string;
  code: string;
  description?: string;
  active: boolean;
  waitingPayment: boolean;
  startDate?: string;
  startTime?: any;
  endDate?: string;
  endTime?: any;
  type: string;
  freeFees: boolean;
  label: string;

  constructor(oneyOpc: IOneyOpc) {
    this.id = oneyOpc.id;
    this.locale = oneyOpc.locale;
    this.code = oneyOpc.code;
    this.description = oneyOpc.description;
    this.active = oneyOpc.active;
    this.waitingPayment = oneyOpc.waitingPayment;
    this.startDate = oneyOpc.startDate ? moment(oneyOpc.startDate).startOf('day').format(DATE_SHORT_FORMAT) : null;
    this.startTime = oneyOpc.startDate ? new Date(oneyOpc.startDate) : null;
    this.endDate = oneyOpc.endDate ? moment(oneyOpc.endDate).startOf('day').format(DATE_SHORT_FORMAT) : null;
    this.endTime = oneyOpc.endDate ? new Date(oneyOpc.endDate) : null;
    this.type = oneyOpc.type;
    this.freeFees = oneyOpc.freeFees;
    this.label = `${this.type.toUpperCase()}_${this.freeFees ? 'FREE' : 'PAYABLE'}`;
  }
}
