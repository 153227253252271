'use strict'

import { SessionHelper } from '@helpers'

angular
  .module 'ammsFrontendApp'
  .factory '$listOrderSession', [
    '$rootScope'
    ($rootScope) ->
      new class ListOrderSession
        constructor: ->
          $rootScope.$listOrderSession = this

        setOrders: (orders) ->
          SessionHelper.set('LIST_ORDER', orders)

        getOrders: ->
          orders = SessionHelper.get('LIST_ORDER')

          if orders? && angular.isObject orders
            return orders

          {}

        countOrders: ->
          _.size @getOrders()

        hasOrder: (id) ->
          orders = @getOrders()

          if orders[id]?
            return true

          false

        addSelected: (id, key, value) ->
          orders = @getOrders()

          if not @hasOrder(id)
            orders[id] = {}

          orders[id][key] = value
          SessionHelper.set('LIST_ORDER', orders)

        removeSelected: (id, key) ->
          orders = @getOrders()

          if @hasOrder(id)
            delete orders[id][key]

            if 0 == _.size(orders[id])
              delete orders[id]

            SessionHelper.set('LIST_ORDER', orders)

        findSelected: (id) ->
          data = false
          orders = @getOrders()

          angular.forEach orders, (order, orderId) ->
            if  parseInt(orderId) == id && order['selected']
              data = Boolean(order['selected'])

          data

        getItems: (id) ->
          orders = @getOrders()

          if @hasOrder(id) && orders[id]['items']? && angular.isObject orders[id]['items']
            return orders[id]['items']

          {}

        hasItem: (id, itemId) ->
          items = @getItems(id)

          if items[itemId]?
            return true

          false

        countAllItems: ->
          countAllItems = 0
          orders = @getOrders()

          angular.forEach orders, (order) ->
            countAllItems += _.size order.items

          countAllItems

        addValueForItem: (id, itemId, key, value) ->
          orders = @getOrders()

          if not @hasOrder(id)
            orders[id] = {}

          if not @hasItem(id, itemId)
            if not orders[id]['items']?
              orders[id]['items'] = {}

            orders[id]['items'][itemId] = {}

          orders[id]['items'][itemId][key] = value
          SessionHelper.set('LIST_ORDER', orders)

        removeValueForItem: (id, itemId, key) ->
          orders = @getOrders()

          if @hasItem(id, itemId)
            delete orders[id]['items'][itemId][key]

            if 0 == _.size(orders[id]['items'][itemId])
              delete orders[id]['items'][itemId]

            if 0 == _.size(orders[id]['items'])
              delete orders[id]['items']

            if 0 == _.size(orders[id])
              delete orders[id]

            SessionHelper.set('LIST_ORDER', orders)

        findValueForItems: (id, itemId, key) ->
          data = null
          orders = @getOrders()

          if @hasOrder(id) && @hasItem(id, itemId) && orders[id]['items'][itemId][key]?
            return orders[id]['items'][itemId][key]

          data

        hasValueForItems: (id, itemId, key, value) ->
          searchValue = @findValueForItems id, itemId, key

          if searchValue? && searchValue is value
            return true

          false

        reset: ->
          SessionHelper.remove('LIST_ORDER')
]
