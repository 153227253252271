import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { ITask } from './model/task.interface';
import { SessionHelper } from '@helpers';
import { AuthService } from '@services';
import {
  HeaderColorClasses,
  IStatusBtn,
  ITaskComponent
} from './task.component.interface';
import { Subscription } from 'rxjs/Subscription';
import { TaskAdditionalPropertyValueResource, TaskCommentResource, TaskResource } from './resources';
import { ITaskAttachment, ITaskStatus, ITaskType } from './interfaces';
import {
  TaskCommentModel,
  ITaskComment,
  AdditionalPropertyFieldTypes,
  ITaskAdditionalProperty,
  IAdditionalPropertyValue,
} from './model';
import { FormControl, FormGroup } from '@angular/forms';
import { FieldUtilityService } from '@components/generic/Form/services/field-utility.service';
import { AbstractFormFieldBase } from '@components/generic/Form/dynamic/fields/abstract-form-field-base.class';
import { IFormViolation } from '@interfaces';
import { FieldFactoryService } from '@components/generic/Form/dynamic/services/field-factory.service';
import { TaskHelper } from '@components/generic/task-manager/helpers/TaskHelper';
import { SnackbarService } from '@components/snackbar/snackbar.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-task',
  template: require('./task.component.html'),
  styles: [require('./task.component.scss')],
  providers: [ FieldFactoryService, FieldUtilityService, TaskHelper ],
})
export class TaskComponent extends AbstractComponent implements OnInit, ITaskComponent {

  public taskHeaderColor: HeaderColorClasses;
  public statusBtn: IStatusBtn;
  public taskComments: ITaskComment[];
  public chatEnabled: boolean = false;
  public message: FormControl;
  public additionalPropertiesForm: FormGroup;
  public additionalPropertiesFields: AbstractFormFieldBase<any>[] = [];
  public additionalPropertiesFieldTypes: any;
  public additionalViolations: IFormViolation[] = [];
  public taskTypeTitle: string = '';
  public openAttachments: boolean = false;
  public attachments: any = [];

  @Input() public task?: ITask;
  @Input() public taskIRI?: string;
  @Input() public taskStatuses: ITaskStatus;

  @Output() public onChangeStatus?: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private taskResource: TaskResource,
    private taskCommentResource: TaskCommentResource,
    public fieldUtility: FieldUtilityService,
    private fieldFactory: FieldFactoryService,
    private taskHelper: TaskHelper,
    private taskAdditionalPropertyValueResource: TaskAdditionalPropertyValueResource,
    private snackbar: SnackbarService,
  ) {
    super($translate, authService, null, state);
  }

  ngOnInit(): void {
    if (undefined === this.task && undefined === this.taskIRI) {
      throw new Error('You must provide a TaskModel or a taskIRI to the component');
    }

    this.additionalPropertiesFieldTypes = AdditionalPropertyFieldTypes;

    if (undefined !== this.task) {
      this.initTask();
      return;
    }

    this.taskResource.get(this.taskIRI)
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe((response: ITask) => {
        this.task = response;
        this.taskTypeTitle = (<ITaskType>this.task.taskType).translations[SessionHelper.getUILanguage()].name;
        this.initTask();
      })
    ;
  }

  private initTask() {
    this.taskTypeTitle = (<ITaskType>this.task.taskType).translations[SessionHelper.getUILanguage()].name;
    this.attachments = this.task.attachments;
    this.updateComponentsStatus();
    this.createAdditionalPropertiesForm();
    this.setTaskComments();
    this.handleChat();
  }

  public changeStatus(): void {
    const subscriber: Subscription = this.taskResource.update(this.task.id, {
      status: 'open' === this.task.status ? this.taskStatuses.close : this.taskStatuses.open,
    }).subscribe((response: ITask) => {
      this.task = response;
      this.initTask();
      this.onChangeStatus.emit(this.task);
    }, undefined, () => subscriber.unsubscribe());
  }

  public saveComment() {
    const subscriber: Subscription = this.taskCommentResource.create({
      task: this.task['@id'],
      message: this.message.value.trim(),
    }).subscribe((response: ITaskComment) => {
      this.taskComments.push(new TaskCommentModel(response));
      this.resetComment();
    }, undefined, () => subscriber.unsubscribe());
  }

  public triggerCommentActions(event: KeyboardEvent) {
    this.fieldUtility.triggerTextAreaActions(event, this.message.value, this.saveComment.bind(this), this.resetComment.bind(this));
  }

  private resetComment() {
    this.message.reset();
  }

  private setHeaderColor(): void {
    this.taskHeaderColor = this.taskStatuses.close === this.task.status ? 'task-closed' :
      !!this.task.concernedMe && this.taskStatuses.open === this.task.status || !!this.task.assignedToMe ? 'task-concerned-me' :
        'task-default';
  }

  private setStatusBtn(): void {
    const textBtn: string =
      this.translate(this.taskStatuses.open === this.task.status && !!this.task.concernedMe ?
        'TASKS.ACTIONS.CLOSE_TASK' : 'TASKS.ACTIONS.REOPEN_TASK'
      );

    this.statusBtn = {
      color: this.taskStatuses.open === this.task.status && !!this.task.concernedMe ? 'task-to-closed' : 'task-to-reopen',
      text: textBtn,
    };
  }

  /**
   * Updates header color, btns texts + colors of the task .
   */
  private updateComponentsStatus(): void {
    this.setHeaderColor();

    if (this.task.concernedMe) {
      this.setStatusBtn();
    }
  }

  private setTaskComments(): void {
    this.taskComments = this.task.taskComments.map((taskComment: ITaskComment) => {
      return new TaskCommentModel(taskComment);
    });
  }

  private handleChat(): void {
    if (this.isChatCanBeActivated()) {
      this.chatEnabled = true;
      this.message = new FormControl();

      return;
    }

    this.chatEnabled = false;
  }

  public isChatCanBeActivated(): boolean {
    return (<ITaskType>this.task.taskType).commentActivated && this.taskStatuses.open === this.task.status;
  }

  /**
   * Creates the form and fields dynamically.
   */
  public createAdditionalPropertiesForm(): void {
    this.additionalPropertiesFields = this.taskHelper.getAdditionalPropertiesFields(
      (<ITaskType>this.task.taskType).additionalProperties
    );

    this.additionalPropertiesForm = this.fieldFactory.toFormGroup(this.additionalPropertiesFields);
  }

  public saveAdditionalPropertyValue(event: any, fieldName: string): void {
    if (event.key && 'Enter' !== event.key) {
      return;
    }

    const value = event.target ? event.target.value : event.value;
    const originalAdditionalProperty: ITaskAdditionalProperty = (<ITaskType>this.task.taskType).additionalProperties
      .find((property: ITaskAdditionalProperty) => {
        return fieldName === property.id;
      })
    ;

    if (originalAdditionalProperty.value === value || value === null || value.trim().length === 0) {
      return;
    }

    // POST
    if (null === originalAdditionalProperty.value && value !== originalAdditionalProperty.value) {
      const subscriber: Subscription = this.taskAdditionalPropertyValueResource.create({
          task: this.task['@id'],
          taskAdditionalProperty: originalAdditionalProperty['@id'],
          value
        }).subscribe((response: IAdditionalPropertyValue) => {
          this.updateAdditionalPropertyValue(response, fieldName);
        }, undefined, () => subscriber.unsubscribe())
      ;
    }

    // PUT
    if (null !== originalAdditionalProperty.value && value !== originalAdditionalProperty.value) {
      const subscriber: Subscription = this.taskAdditionalPropertyValueResource.update(
          `task=${this.task.id};taskAdditionalProperty=${fieldName}`,
          {value}
        ).subscribe((response: IAdditionalPropertyValue) => {
          this.updateAdditionalPropertyValue(response, fieldName);
        }, undefined, () => subscriber.unsubscribe())
      ;
    }
  }

  /**
   * The fields values must be updated according to the api response.
   */
  private updateAdditionalPropertyValue(additionalPropertyValue: IAdditionalPropertyValue, fieldName: string): void {
    (<ITaskType>this.task.taskType).additionalProperties
      .forEach((property: ITaskAdditionalProperty, index: number) => {
        if (fieldName === property.id) {
          (<ITaskType>this.task.taskType).additionalProperties[index].value = additionalPropertyValue.value;
        }
      })
    ;

    this.snackbar.validate(this.translate('TASKS.FORM.ADDITIONAL_PROPERTIES.SAVED'));
  }

  public toggleAttachments(event?: any): void {
    if (event) {
      this.attachments.splice(0, this.attachments.length);

      event.forEach((attachments: ITaskAttachment) => {
        this.attachments.push(attachments);
      });
    }

    this.openAttachments = !this.openAttachments;
  }
}
