import { Component, Inject } from '@angular/core';
import { AbstractResource } from '@resources';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { AuthService } from '@services';
import { GlossaryResource } from '@components/glossary/glossary.resource';
import {AbstractFiltersFieldsService} from '@components/generic/Form/filters/abstract-filters-fields.service';
import {GlossaryListFiltersService} from '@components/glossary/list/glossary-list-filters.service';
@Component({
  selector: 'app-glossary-list',
  template: require('./glossary-list.component.html'),
  providers: [
    { provide: AbstractResource, useClass: GlossaryResource },
    { provide: AbstractFiltersFieldsService, useClass: GlossaryListFiltersService },
  ],
})
export class GlossaryListComponent extends AbstractPageComponent {
  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService
  ) {
    super($translate, authService, resource, state);
  }
}
