import { IWarehouseCarrierGroupAgencies } from '@components/warehouses/models/warehouse-carrier-group-agencies.interface';
import { IDirectInjection } from '@components/export-logistics/models/direct-injection.interface';

export class DirectInjectionModel implements IDirectInjection {
  '@id': string;
  activated: boolean;
  warehouse = { code: '' };
  carrierGroupAgency = {
    carrierName: '',
    agencyCode: '',
    countryCode: '',
    deliverable: true,
  };

  constructor(model: IWarehouseCarrierGroupAgencies) {
    this['@id'] = model['@id'];
    this.activated = model.directInjection;
    this.warehouse.code = model.warehouse.code;
    this.carrierGroupAgency.carrierName = model.carrierGroupAgency.carrierGroup.name;
    this.carrierGroupAgency.agencyCode = model.carrierGroupAgency.agencyCode;
    this.carrierGroupAgency.countryCode = model.carrierGroupAgency.countryCode;
    this.carrierGroupAgency.deliverable = model.carrierGroupAgency.deliverable;
  }
}
