import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '@services';
import { CommercialOperationsResource } from '@components/commercial-operations/resources/commercial-operations.resource';
import { ICommercialOperations } from '@components/commercial-operations/models/commercial-operations.interface';
import { Subscription } from 'rxjs/Subscription';
import { MarketplaceResource } from '@resources';
import { CommercialOperationsModel } from './models/commercial-operations.model';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { AbstractResource } from '@resources/abstract.resource';
import { MarketplaceHelper, SessionHelper } from '@helpers';
import {
  BUSINESS_OBJECT_BUNDLE,
  BUSINESS_OBJECT_CREDIT,
  BUSINESS_OBJECT_NORMAL
} from '@components/commercial-operations/constants/commercial-operations.constants';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CommercialOperationProductMarketplaceOffersResource} from '@components/commercial-operations/resources';
import {DialogCloseResult, DialogRef} from '@progress/kendo-angular-dialog';
import {DialogBoxService} from '@services/dialog-box.service';
import {DATE_SHORT_FORMAT, LOCALE_FR} from '@constants';
import {IGrid} from '@components/generic/List';
import {SnackbarService} from '@components/snackbar';
import * as moment from 'moment';

@Component({
  selector: 'app-commercial-operations-products',
  template: require('./commercial-operations-products.component.html'),
  styles: [require('./commercial-operations-products.component.scss')],
  providers: [
    { provide: AbstractResource, useClass: CommercialOperationsResource },
    CommercialOperationProductMarketplaceOffersResource,
  ],
})

export class CommercialOperationsProductsComponent extends AbstractPageComponent implements OnInit {

  public uploaderEndpoint: string;
  public marketplace: string;
  public importOptions: any[];
  public exportOptions: any[];
  public commercialOperation: ICommercialOperations;
  public products: any[] = [];
  public goodDealsCommercialOperationForm: FormGroup;
  public availableGoodDealsCommercialOperations: any[] = [];
  public associatedGoodDealsCommercialOperations: any[] = [];
  public showAssociatedGoodDealCommercialOperations: boolean = false;
  public openCreation: boolean = false;
  public canAddProduct: boolean = false;
  public canRemoveProduct: boolean = false;

  @ViewChild('descriptionDiv')
  public descriptionDiv: ElementRef;

  public columns = [
    { size: 2, title: 'PAGE.COMMERCIAL_OPERATIONS.PRODUCTS.COLUMNS.THUMBNAIL', property: 'thumbnailPath', type: 'thumbnail'},
    { size: 4, title: 'PAGE.COMMERCIAL_OPERATIONS.PRODUCTS.COLUMNS.SKU', property: 'sku' },
    { size: 4, title: 'PAGE.COMMERCIAL_OPERATIONS.PRODUCTS.COLUMNS.REFERENCE', property: 'reference' },
  ];

  public associatedGoodDealsCommercialOperationsColumns = [
    {
      size: 2,
      title: 'PAGE.COMMERCIAL_OPERATIONS.PRODUCTS.ASSOCIATED_GOOD_DEALS_COMMERCIAL_OPERATIONS.LIST.TABLE.HEAD.ID',
      property: 'id'
    },
    {
      size: 4,
      title: 'PAGE.COMMERCIAL_OPERATIONS.PRODUCTS.ASSOCIATED_GOOD_DEALS_COMMERCIAL_OPERATIONS.LIST.TABLE.HEAD.TITLE',
      property: 'title'
    },
    {
      size: 4,
      title: 'PAGE.COMMERCIAL_OPERATIONS.PRODUCTS.ASSOCIATED_GOOD_DEALS_COMMERCIAL_OPERATIONS.LIST.TABLE.HEAD.NUMBER_OF_PRODUCTS',
      property: 'productMarketplaceCount'
    },
  ];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: CommercialOperationsResource,
    @Inject('StateService') state: ng.ui.IStateService,
    public marketplaceResource: MarketplaceResource,
    private formBuilder: FormBuilder,
    private commercialOperationProductMarketplaceOffersResource: CommercialOperationProductMarketplaceOffersResource,
    private dialogBoxService: DialogBoxService,
    private snackbar: SnackbarService,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    this.uploaderEndpoint = this.resource.getFileEndpoint(this.state.params.id);
    this.exportOptions = [{translationKey: 'PAGE.COMMERCIAL_OPERATIONS.FORM.LABEL.EXPORT'}];

    const subscriber: Subscription = this.resource.get(this.state.params.id, {model: CommercialOperationsModel})
      .subscribe(
        (response: ICommercialOperations) => {
          this.commercialOperation = response;

          this.canAddProduct = this.commercialOperation !== undefined && 'good_deals' !== this.commercialOperation.type;
          this.canRemoveProduct = this.commercialOperation !== undefined && 'good_deals' !== this.commercialOperation.type;

          if (this.commercialOperation !== undefined && 'new' === this.commercialOperation.type) {
            this.columns.push({ size: 4, title: 'PAGE.COMMERCIAL_OPERATIONS.PRODUCTS.COLUMNS.NOVELTY_DATE', property: 'noveltyDate' });
          }
          if (this.commercialOperation !== undefined && 'classical' === this.commercialOperation.type) {
            this.columns.forEach(function (value, index) {
              if ('reference' !== value.property) {
                this[index].size = value.size - 1;
              }
            }, this.columns);
          }

          this.setImportOptions();
          this.setCommercialOperationDescription();

          this.fetchMarketplaces();
          this.fetchProducts();
          this.fetchAvailableGoodDealCommercialOperations();
          this.fetchAssociatedGoodDealCommercialOperations();
        },
        undefined,
        () => subscriber.unsubscribe()
      );
  }
  public getTranslatableString(key: string, translation: string): string {
    if (key === 'price') {
      key += '_' + this.commercialOperation.defaultDirection;
    }
    return this.$translate.instant(translation + key.toUpperCase());
  }
  public onUpdate(img: IGrid): void {
    const body = {position: img.position + 1};
    this.resource.updateProductPosition(img.id, body).subscribe(() => {
      this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
    });
  }

  public onDelete(imgs: IGrid[]): void {
    this.deleteProducts(imgs, 0, imgs.length);
  }

  private deleteProducts(imageList: IGrid[], index: number, imagesLength: number): void {
    this.resource.deleteProducts(imageList[index].id)
      .takeUntil(this.destroyed$)
      .subscribe(() => {
        this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));

        imagesLength--;
        if (0 === imagesLength) {
          return;
        }

        index++;
        this.deleteProducts(imageList, index, imagesLength);
      })
    ;
  }

  public fetchMarketplaces(): void {
    if (this.commercialOperation === undefined) {
      return;
    }

    const marketplaceSubscriber: Subscription = this.marketplaceResource.filterByCountryCode(this.commercialOperation.country.code)
      .subscribe(
        (marketplaceResponse: any) => {
          this.marketplace = marketplaceResponse.find((item: any) => item.code === this.commercialOperation.marketplace);
        },
        undefined,
        () => marketplaceSubscriber.unsubscribe()
      );
  }

  public fetchProducts(): void {
    if (this.commercialOperation === undefined) {
      return;
    }

    let filters = {};
    const isMerchandisingEnabled = this.commercialOperation.defaultSorting.endsWith('_merchandising');
    if (this.commercialOperation.marketplace.startsWith('site')) {
      filters = { commercialOperation: this.commercialOperation.id, locale: this.commercialOperation.translations[0].locale };
    } else {
      filters = {};
    }
    if (isMerchandisingEnabled) {
      filters = { ...filters, ...{merchandising: this.commercialOperation.defaultSorting.split('_merchandising')[0] } };
    }

    if (this.commercialOperation.marketplace.startsWith('site')) {
      this.resource.getProductsElastic(filters).subscribe((response: any) => {
        this.products = response.map((item: any) => {
          if (null !== item) {
            const translations = null !== item.productMarketplace.product.superProduct ? item.productMarketplace.product.superProduct.translations : null;
            const translation = null !== translations ? translations[SessionHelper.getLocale()] || translations[Object.keys(translations)[0]] : null;
            return {
              id: item.id,
              sku: item.productMarketplace.product.sku,
              reference: translation ? translation.reference : null,
              type: item.offer && item.offer.type ? item.offer.type.type : 'PAGE.COMMERCIAL_OPERATIONS.FORM.LABEL.NO_TYPE',
              position: isMerchandisingEnabled ? null : item.position,
              thumbnailPath: item.productMarketplace.product.firstThumbnailPathProductImage,
              noveltyDate: item.productMarketplace.noveltyDate ? moment(item.productMarketplace.noveltyDate).format(DATE_SHORT_FORMAT) : null,
            };
          }
      });
    });
    } else {
      this.resource.getProducts(this.state.params.id, filters).subscribe((response: any) => {
        this.products = response.map((item: any) => {
          if (null !== item) {
            const translations = null !== item.productMarketplace.product.superProduct ? item.productMarketplace.product.superProduct.translations : null;
            const translation = null !== translations ? translations[SessionHelper.getLocale()] || translations[Object.keys(translations)[0]] : null;
            return {
              id: item.id,
              sku: item.productMarketplace.product.sku,
              reference: translation ? translation.reference : null,
              type: item.offer && item.offer.type ? item.offer.type.type : 'PAGE.COMMERCIAL_OPERATIONS.FORM.LABEL.NO_TYPE',
              position: isMerchandisingEnabled ? null : item.position,
              thumbnailPath: item.productMarketplace.product.firstThumbnailPathProductImage,
              noveltyDate: item.productMarketplace.noveltyDate ? moment(item.productMarketplace.noveltyDate).format(DATE_SHORT_FORMAT) : null,
            };
          }
        });
      });
  }
  }

  public fetchAvailableGoodDealCommercialOperations(): void {
    if (this.commercialOperation === undefined || 'good_deals' !== this.commercialOperation.type) {
      return;
    }

    this.goodDealsCommercialOperationForm = this.formBuilder.group({
      commercialOperation: [null, Validators.required],
    });

    const subscriber: Subscription = this.resource.getAvailableGoodDealsCommercialOperations(this.state.params.id)
      .subscribe(
        (response: CommercialOperationsModel[]) => {
          this.availableGoodDealsCommercialOperations = response.map((item: CommercialOperationsModel) => {
            return {
              id: item.id,
              title: item.translations[0].title,
            };
          });
        },
        undefined,
        () => subscriber.unsubscribe()
      )
    ;
  }

  public fetchAssociatedGoodDealCommercialOperations(): void {
    if (this.commercialOperation === undefined || 'good_deals' !== this.commercialOperation.type) {
      return;
    }

    this.showAssociatedGoodDealCommercialOperations = true;

    const subscriber: Subscription = this.resource.getAssociatedGoodDealsCommercialOperations(this.state.params.id)
      .subscribe(
        (response: CommercialOperationsModel[]) => {
          this.associatedGoodDealsCommercialOperations = response.map((item: CommercialOperationsModel) => {
            return {
              id: item.id,
              title: item.translations[0].title,
              productMarketplaceCount: item.productMarketplaceCount,
            };
          });
        },
        undefined,
        () => subscriber.unsubscribe()
      )
    ;
  }

  public removeAssociatedGoodDealCommercialOperation(event: any): void {
    const subscriber: Subscription = this.resource.update(event.id, { goodDeals: false })
      .subscribe(
        () => {
          this.state.go(this.state.current, this.state.params, { reload: true });
        },
        undefined,
        () => subscriber.unsubscribe()
      )
    ;
  }

  public addAssociatedGoodDealCommercialOperation(event: any): void {
    const commercialOperation = this.goodDealsCommercialOperationForm.value.commercialOperation;

    if (null === commercialOperation) {
      return;
    }

    const subscriber: Subscription = this.resource.update(commercialOperation.id, { goodDeals: true })
      .subscribe(
        () => {
          this.state.go(this.state.current, this.state.params, { reload: true });
        },
        undefined,
        () => subscriber.unsubscribe()
      )
    ;
  }

  public exportCsv() {
    const subscriber: Subscription = this.resource.exportFile({}, {
      entryPoint: `/v2/commercial_operations/${this.state.params.id}/export`,
      responseType: 'text/csv',
      type: 'text/csv'
    }).subscribe(() => {}, undefined, () => subscriber.unsubscribe());
  }

  private setImportOptions() {
    if (!this.commercialOperation || this.commercialOperation.type === 'good_deals') {
      return;
    }

    this.importOptions = [
      {
        entryPoint: '/api/v2/import',
        importButton: 'PAGE.COMMERCIAL_OPERATIONS.FORM.LABEL.IMPORT',
        extraData: 'PAGE.COMMERCIAL_OPERATIONS.FORM.LABEL.EXTRA_DATA',
        businessObject: this.getBusinessObject(),
        businessObjectId: this.state.params.id
      },
    ];
  }

  public setCommercialOperationDescription() {
    if (this.commercialOperation !== undefined && 'bundle' === this.commercialOperation.type) {
      this.descriptionDiv.nativeElement.innerHTML = this.translate('PAGE.COMMERCIAL_OPERATIONS.PRODUCTS.ADD_PRODUCTS.DESCRIPTIONS.BUNDLE');
    }
  }

  private getBusinessObject() {
    if (this.commercialOperation === undefined) {
      return BUSINESS_OBJECT_NORMAL;
    }

    switch (this.commercialOperation.type) {
      case 'bundle':
        return BUSINESS_OBJECT_BUNDLE;
      case 'credit':
        return BUSINESS_OBJECT_CREDIT;
      default:
        return BUSINESS_OBJECT_NORMAL;
    }
  }

  public removeCommercialOperationProduct(event: any): void {
    const dialog: DialogRef = this.dialogBoxService.alert({content: this.translate('PAGE.COMMERCIAL_OPERATIONS.PRODUCTS.DELETE_PRODUCTS.POPIN.CONTENT')});

    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        return;
      } else {
        if (this.translate('DIALOG.BUTTON.CANCEL') === result.text) {
          return;
        }

        const subscriber: Subscription = this.commercialOperationProductMarketplaceOffersResource.remove(event.id)
          .subscribe(
            () => {
              this.state.go(this.state.current, this.state.params, { reload: true });
            },
            undefined,
            () => subscriber.unsubscribe()
          )
        ;
      }
    });
  }
}
