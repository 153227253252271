import { Inject, Injectable } from '@angular/core';
import { FormNotifierService } from '@services/form-notifier.service';
import { SessionHelper } from '@helpers/session.helper';
import { MultiSearchField } from '@components/generic/Form/dynamic/fields';
import { AbstractFormFieldBase } from '@components/generic/Form/dynamic/fields/abstract-form-field-base.class';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';

@Injectable()
export class UsersFiltersFormService extends AbstractFiltersFieldsService {

  constructor(
    @Inject('StateService') protected state: ng.ui.IStateService,
    @Inject('TranslationService') public $translate: ng.translate.ITranslateService,
    formNotifier: FormNotifierService,
  ) {
    super(formNotifier, state);
  }

  public getFields(): AbstractFormFieldBase<any>[]  {
    return [
      new MultiSearchField({
        fieldName: 'country[]',
        label: 'PAGE.USERS.LIST.FILTERS.COUNTRIES',
        data: SessionHelper.getCountries(),
        textField: 'name',
        valueField: 'id',
        value: this.filters.getAll('country[]').length !== 0 ? this.filters.getAll('country[]') : undefined,
        order: 1,
      }),
    ];
  }
}
