import { NgModule } from '@angular/core';
import { MarketplacesConfigureComponent } from '@components/marketplaces-configure/marketplaces-configure.component';
import { MarketplacesCommissionComponent } from '@components/marketplaces-configure/commission/marketplaces-commission.component';
import { MarketplacesConfigureResultListComponent } from '@components/marketplaces-configure/marketplaces-configure-result-list.component';
import { ExportModule, GenericFormModule, GenericModule } from '@components';
import { CommonModule } from '@angular/common';
import { SwitchModule } from '@progress/kendo-angular-inputs';
import { FormsModule } from '@angular/forms';
import { TabsModule } from 'ngx-bootstrap';
import { MarketplacesCommissionResultListComponent } from '@components/marketplaces-configure/commission/marketplaces-commission-result-list.component';
import {DirectivesModule} from '@directives';
import {
  MarketplacesCommissionFormComponent
} from '@components/marketplaces-configure/commission/marketplaces-commission-form.component';
@NgModule({
  declarations: [
    MarketplacesConfigureComponent,
    MarketplacesConfigureResultListComponent,
    MarketplacesCommissionComponent,
    MarketplacesCommissionResultListComponent,
    MarketplacesCommissionFormComponent
  ],
  entryComponents: [
    MarketplacesConfigureComponent,
    MarketplacesConfigureResultListComponent,
    MarketplacesCommissionComponent,
    MarketplacesCommissionResultListComponent,
    MarketplacesCommissionFormComponent,
  ],
  exports: [
    MarketplacesConfigureComponent,
    MarketplacesConfigureResultListComponent,
    MarketplacesCommissionComponent,
    MarketplacesCommissionResultListComponent,
    MarketplacesCommissionFormComponent,
  ],
  imports: [
    ExportModule,
    GenericModule,
    CommonModule,
    SwitchModule,
    FormsModule,
    TabsModule,
    DirectivesModule,
    GenericFormModule
  ]
})

export class MarketplacesConfigureModule { }
