'use strict'

import { StringHelper } from '@helpers'

angular
  .module 'ammsFrontendApp'
  .filter 'countChar', [ ->
    (sourceText, encoded = false) ->
      return StringHelper.countChar(sourceText, encoded)
  ]
