import { NgModule } from '@angular/core';
import { TabComponent } from '@components/tab/tab.component';
import { TabResource } from '@components/tab';
import { GenericFormModule } from '@components/generic';
import { GenericModule } from '@components/generic';
import { TabManagementModule } from '@components/generic/tab-management.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TabAdditionalFormComponent } from '@components/tab/tab-additional-form/tab-additional-form.component';
import { CommonModule } from '@angular/common';
import { TabListComponent } from './List';
import { EnhancedFieldsModule } from '@directives/enhanced-fields.module';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { TabResultListComponent } from './List';

@NgModule({
  entryComponents: [
    TabComponent,
    TabListComponent,
  ],
  imports: [
    GenericModule,
    GenericFormModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    TabManagementModule,
    EnhancedFieldsModule,
    DropDownsModule,
  ],
  providers: [
    TabResource,
  ],
  declarations: [
    TabComponent,
    TabAdditionalFormComponent,
    TabListComponent,
    TabResultListComponent,
  ],
  exports: [
    TabListComponent,
  ]
})
export class TabModule {}
