import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@services';
import { CarrierGroupAgencyResource } from '@components/carrier/mesh-agency/carrier-group-agency.resource';
import { Observable } from 'rxjs/Observable';
import { ICarrierGroup } from '@components';
import { AbstractResource, CarrierGroupResource } from '@resources';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';

@Component({
  selector: 'app-carrier-mesh-agency',
  template: require('./carrier-mesh-agency.component.html'),
  styles: [require('./carrier-mesh-agency.component.scss')],
  providers: [
    { provide: AbstractResource, useClass: CarrierGroupAgencyResource },
  ]
})
export class CarrierMeshAgencyComponent extends AbstractPageComponent implements OnInit {

  /**
   * Needed by kendo-upload to set the FormData property name.
   */
  public readonly uploadFieldName = 'carrierGroupAgency';
  public export$: Observable<void>;
  public importEntryPoint: string;
  public importAllowedExtensions: string[] = ['.csv'];

  public carrierCode: string = '';
  public dynamicAgency: boolean;
  public carrierGroupAgencyEntrypoint: string;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    public carrierGroupAgencyResource: CarrierGroupAgencyResource,
    public carrierGroupResource: CarrierGroupResource,
  ) {
    super($translate, authService, null, state);
  }

  ngOnInit(): void {
    let carrierCode;

    this.importEntryPoint = this.carrierGroupAgencyResource.getImportEntryPoint();

    if (this.state.params.carrierCode) {
      carrierCode = this.state.params.carrierCode;
      this.prepareImportExport(carrierCode, this.state.params.dynamicAgency);
      this.carrierGroupAgencyEntrypoint = `${this.carrierGroupAgencyResource.entryPoint}?carrierGroup.code=${carrierCode}`;

      return;
    }

    if (this.state.params.id) {
      this.setCarrierCodeToExport(this.state.params.id);

      return;
    }

    throw new Error('A carrier code or a carrier id are required to set the export observable');
  }

  /**
   * Prepare query before sending import.
   */
  public prepareQuery(): {carrierCode: string} {
    return {
      carrierCode: this.carrierCode,
    };
  }

  /**
   * If the user is not coming from the `shipping cost` tabs, we need to retrieve the carrier code by fetching the api.
   */
  private setCarrierCodeToExport(id: string): void {
    this.carrierGroupResource.get(id)
      .takeUntil(this.destroyed$)
      .subscribe((carrierGroup: ICarrierGroup) => {
        this.carrierGroupAgencyEntrypoint = `${this.carrierGroupAgencyResource.entryPoint}?carrierGroup.code=${carrierGroup.code}`;
        this.prepareImportExport(carrierGroup.code, carrierGroup.dynamicAgency);
      })
    ;
  }

  /**
   * Prepare common data necessary for the import/export.
   * - The export observable
   * - The carrierCode necessary for importing operation.
   * - The dynamicAgency to enable/disable import operation.
   */
  private prepareImportExport(carrierCode: string, dynamicAgency: boolean): void {
    this.export$ = this.carrierGroupAgencyResource.export(carrierCode);
    this.carrierCode = carrierCode;
    this.dynamicAgency = dynamicAgency;
  }
}
