import { AbstractResource } from '../../../resources/abstract.resource';
import { Injectable } from '@angular/core';
import { SessionHelper } from '../../../helpers/session.helper';
import { WarrantyListModel } from '@components/warranty/models/warranty-list.model';
import { Resource } from '@decorators/Resource';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  routeName: 'warranty',
  entryPoint: '/v2/warranties',
  model: WarrantyListModel,
  isHydra: true,
  translationKey: 'WARRANTY',
  cGetDefaultFilters: () => ({ locale: SessionHelper.getLocale() }),
  listColumns: [
    {field: 'id', translationKey: 'ID', type: 'id'},
    {field: 'label', translationKey: 'LABEL'},
    {field: 'months', translationKey: 'MONTHS'},
  ],
  deleteAvailable: false,
})
export class WarrantyResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }
}
