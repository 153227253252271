export default stockShootingRoutes;

/** @ngInject */
function stockShootingRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'walipush',
    sidebarSubNav: 'orders',
    title: '',
  };

  $stateProvider
    .state('stock-shooting', {
      url: '/stock-shootings',
      parent: 'walipush',
      abstract: true,
      breadcrumb: {
        label: 'PAGE.STOCK_SHOOTING.TITLE',
        stateName: 'stock-shootings'
      }
    })
    .state('stock-shooting.list', {
      url: '',
      views: {
        'page@layout': {
          template: '<app-stock-shooting-list></app-stock-shooting-list>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.STOCK_SHOOTING.TITLE',
      })
    })
    .state('stock-shooting.new', {
      url: '/new',
      views: {
        'page@layout': {
          template: '<app-stock-shooting-form></app-stock-shooting-form>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.STOCK_SHOOTING.TITLE',
      })
    })
    .state('stock-shooting.detail', {
      url: '/:id/edit/movement',
      views: {
        'page@layout': {
          template: '<app-stock-shooting-result-detail></app-stock-shooting-result-detail>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.STOCK_SHOOTING.TITLE',
      })
    })
    .state('stock-shooting.edit', {
      url: '/:id/edit',
      views: {
        'page@layout': {
          template: '<app-stock-shooting-form></app-stock-shooting-form>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.STOCK_SHOOTING.TITLE',
      })
    })
  ;
}
