import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractResource } from '@resources';
import { Resource } from '../../../decorators/Resource';
import { Observable } from 'rxjs/Observable';

@Injectable()
@Resource({
  entryPoint: '/v2/delay_reasons',
  isHydra: true,
})
export class DelayReasonResource extends AbstractResource {

  constructor(http: HttpClient) {
    super(http);
  }

  public bulk(orders: string[], reason: string, comment: string): Observable<any> {
    return super.create({ orders: orders, delayReason: reason, delayComment: comment }, { entryPoint: '/v2/delay_reasons/bulk' });
  }
}
