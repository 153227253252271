import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  CarrierEditFormComponent,
  CarrierEditComponent
} from './single';

import { GenericFormModule } from '@components/generic/generic-form.module';
import { GenericModule } from '@components/generic/generic.module';
import { CarrierGroupResource, CarrierResource } from '@resources';
import { CarrierCalculatorComponent } from '@components/carrier/calculator/carrier-calculator.component';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { ByExistingSkuFormComponent } from '@components/carrier/calculator/forms/by-existing-sku-form.component';
import { ByImportFormComponent } from '@components/carrier/calculator/forms/by-import-form.component';
import { ByManualEntryFormComponent } from '@components/carrier/calculator/forms/by-manual-entry-form.component';
import { CarrierMeshAgencyComponent } from '@components/carrier/mesh-agency/carrier-mesh-agency.component';
import { TooltipModule } from 'ngx-tooltip';
import { CarrierGroupAgencyResource } from '@components/carrier/mesh-agency/carrier-group-agency.resource';
import { CarrierGroupListComponent } from '@components/carrier/list/carrier-group-list.component';
import { CarrierBlacklistComponent } from '@components/carrier/blacklist/carrier-blacklist.component';
import { CarrierBlacklistPostalCodesComponent } from '@components/carrier/blacklist-postal-codes/carrier-blacklist-postal-codes.component';
import { CarrierCapacityComponent } from '@components/carrier/capacity/carrier-capacity.component';
import { CarrierCapacityFormComponent } from '@components/carrier/capacity/carrier-capacity-form.component';
import { CarrierCapacityWeekComponent } from '@components/carrier/capacity/carrier-capacity-week.component';
import { CarrierCapacityDockedVolumeComponent } from '@components/carrier/capacity/carrier-capacity-docked-volume.component';
import { ScaleWeekComponent } from '@components/carrier/capacity/scale-week.component';
import { SwitchModule } from '@progress/kendo-angular-inputs';
import { WarehousesResource } from '@components/warehouses/warehouses.resource';
import { CarrierBlacklistMarketplacesComponent } from '@components/carrier/blacklist/marketplaces/carrier-blacklist-marketplaces.component';
import { CarrierBlacklistMarketplacesResource } from '@resources/carrier-blacklist-marketplaces.resource';
import { PipesModule } from '../../pipes/pipes.module';
import { CarrierCostComponent } from '@components/carrier/cost/carrier-cost.component';
import { CarrierCostFormComponent } from '@components/carrier/cost/form/carrier-cost-form.component';
import { CarrierConfigurationTypeResource } from '@resources/carrier-configuration-type.resource';
import { CarrierEditConstraintFormComponent } from './single/form/carrier-edit-constraint-form.component';
import { UploadModule } from '@progress/kendo-angular-upload';
import { CarrierCapacityResource } from '@resources/carrier-capacity.resource';
import { WarehouseCarrierResource } from '@resources/warehouse-carrier.resource';
import { DockedVolumeComponent } from '@components/carrier/docked-volume/docked-volume.component';

@NgModule({
  declarations: [
    CarrierGroupListComponent,
    CarrierEditFormComponent,
    CarrierEditComponent,
    CarrierCalculatorComponent,
    CarrierCapacityComponent,
    CarrierCapacityFormComponent,
    CarrierCapacityWeekComponent,
    ScaleWeekComponent,
    CarrierCapacityDockedVolumeComponent,
    CarrierMeshAgencyComponent,
    ByExistingSkuFormComponent,
    ByImportFormComponent,
    ByManualEntryFormComponent,
    CarrierCostComponent,
    CarrierCostFormComponent,
    CarrierBlacklistComponent,
    CarrierBlacklistPostalCodesComponent,
    CarrierBlacklistMarketplacesComponent,
    CarrierEditConstraintFormComponent,
    DockedVolumeComponent
  ],
  entryComponents: [
    CarrierGroupListComponent,
    CarrierEditFormComponent,
    CarrierEditComponent,
    CarrierCalculatorComponent,
    CarrierCapacityComponent,
    CarrierCapacityFormComponent,
    ScaleWeekComponent,
    CarrierCapacityDockedVolumeComponent,
    CarrierMeshAgencyComponent,
    CarrierCostComponent,
    CarrierCostFormComponent,
    CarrierBlacklistComponent,
    CarrierBlacklistPostalCodesComponent,
    CarrierBlacklistMarketplacesComponent,
    CarrierEditConstraintFormComponent,
    DockedVolumeComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    DropDownsModule,
    GenericFormModule,
    ReactiveFormsModule,
    GenericModule,
    LayoutModule,
    DialogsModule,
    TooltipModule,
    SwitchModule,
    PipesModule,
    UploadModule
  ],
  providers: [
    CarrierGroupResource,
    CarrierConfigurationTypeResource,
    CarrierGroupAgencyResource,
    CarrierResource,
    WarehousesResource,
    CarrierBlacklistMarketplacesResource,
    CarrierCapacityResource,
    WarehouseCarrierResource,
  ],
  exports: [
    CarrierEditComponent,
  ]
})
export class CarrierModule { }
