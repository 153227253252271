import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { GenericModule } from '@components/generic/generic.module';
import { GenericFormModule } from '@components/generic/generic-form.module';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { UploadModule } from '@progress/kendo-angular-upload';
import { BsDatepickerModule, BsDropdownModule, PopoverModule, TabsModule } from 'ngx-bootstrap';
import { OrderRefundComponent } from '@components/order/components/order-refund.component';
import { OrderCreationComponent } from '@components/order/form/new/order-creation.component';
import { OrderDetailsComponent } from '@components/order/form/new/order-details/order-details.component';
import { OrderResource } from '@resources';
import { OrderAddressesComponent } from '@components/order/form/new/addresses/order-addresses.component';
import { OrderProductListComponent } from './form/new/product-list/order-product-list.component';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { PipesModule } from '../../pipes/pipes.module';
import { DirectivesModule } from '@directives/directives.module';
import { TooltipModule } from 'ngx-tooltip';
import { OrderEmailComponent } from './form/new/email/order-email.component';
import { CustomerModule } from '../customer/customer.module';
import { OrderItemStatusService } from '@components/order/services/order-item-status.service';
import { OrderCommentComponent } from './form/new/comment/order-comment.component';
import { OrderResumeComponent } from './form/new/resume/order-resume.component';
import { PatternsResource } from './resources/patterns.resource';


@NgModule({
  imports: [
    CommonModule,
    DropDownsModule,
    GenericFormModule,
    PopoverModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    FormsModule,
    GenericModule,
    UploadModule,
    DialogModule,
    BsDatepickerModule,
    TooltipModule,
    PipesModule,
    BsDropdownModule,
    TabsModule,
    DirectivesModule,
    CustomerModule
  ],
  entryComponents: [
    OrderRefundComponent,
    OrderCreationComponent,
  ],
  declarations: [
    OrderRefundComponent,
    OrderCreationComponent,
    OrderDetailsComponent,
    OrderAddressesComponent,
    OrderProductListComponent,
    OrderEmailComponent,
    OrderCommentComponent,
    OrderResumeComponent
  ],
  exports: [
    OrderRefundComponent,
  ],
  providers: [
    OrderItemStatusService,
    OrderResource,
    CurrencyPipe,
    PatternsResource
  ]
})
export class OrderModule {}
