import { Component, Inject } from '@angular/core';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { TabResource } from '../';
import { AuthService } from '@services';
import { AbstractResource } from '@resources/abstract.resource';

@Component({
  selector: 'app-tab-list',
  template: require('./tab-list.component.html'),
  styles: [require('./tab-list.component.scss')],
  providers: [{ provide: AbstractResource, useClass: TabResource}]
})
export class TabListComponent extends AbstractPageComponent {

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: TabResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }

  /**
   * Navigate to creation view.
   */
  public goToCreate(isCategory: boolean = false): void {
    isCategory
    ? this.state.go(`categories.new`)
    : this.actions.create.go();
  }
}
