import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormErrorHelper } from '@helpers';
import { SessionHelper } from '@helpers';
import { SnackbarService } from '../../../snackbar/snackbar.service';
import { WarrantyResource } from '@components/warranty/resources/warranty.resource';
import { AuthService } from '@services';
import { IWarranty } from '@components/warranty/models/warranty.interface';
import { AbstractComponent } from '@components/generic/abstract.component';

@Component({
  selector: 'app-warranty-form',
  template: require('./warranty-form.component.html')
})
export class WarrantyFormComponent extends AbstractComponent implements OnInit {

  public form: FormGroup;

  @Input() public warranty: IWarranty;
  @Input() public isCreation: boolean;
  @Input() public locale: string = SessionHelper.getLocale();

  @Output() public onSave: EventEmitter<object> = new EventEmitter();

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: WarrantyResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private snackbar: SnackbarService,
    @Inject('DialogService') private dialog: any,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit() {
    this.form = new FormGroup({
      label: new FormControl(
        this.warranty.translations[this.currentLocale] ? this.warranty.translations[this.currentLocale].label : '',
        Validators.required
      ),
      months: new FormControl(this.warranty.months ? this.warranty.months : null, Validators.required)
    });
  }

  /**
   * Prepares query to send data to API.
   */
  private prepareQuery(query: any): object {
    this.warranty.translations[this.currentLocale].label = query.label;
    this.warranty.months = query.months;

    return {
      months: this.warranty.months,
      translations: this.warranty.translations
    };
  }

  public submit(isRedirect: boolean = false): void {
    // clean form from API errors
    FormErrorHelper.removeFormControlsAPIErrors(this.form);
    if (this.form.dirty && this.form.valid) {
      this.onSave.emit({
        body: this.prepareQuery(this.form.value),
        form: this.form,
        redirect: isRedirect
      });
    } else if (this.form.dirty && !this.form.valid) {
      this.snackbar.warn(this.translate('ALERTS.ERROR.FORM'));
    } else {
      this.snackbar.warn(this.translate('ALERTS.NO_CHANGE.FORM'));
    }
  }

  /**
   * Cancels form update and redirect to list.
   */
  public cancel(): void {
    this.dialog.confirm(this.translate('DIALOG.TEXT.DONT_SAVE'))
      .then(() => this.actions.list.go())
    ;
  }
}
