import { Component, OnInit } from '@angular/core';
import { IPendingTask, LoaderService } from './loader.service';
import 'rxjs/add/operator/map';

@Component({
  selector: 'app-loader',
  template: `<div *ngIf="display" class="loader"><img src="../../images/loader.gif"></div>`
})
export class LoaderComponent implements OnInit {
  public display: boolean = false;

  constructor(private loaderService: LoaderService) { }

  ngOnInit() {
    this.loaderService.pendingTasks
      .map((tasks: IPendingTask[]) => tasks.filter((task: IPendingTask) => task.blocking))
      .subscribe((tasks: IPendingTask[]) => this.display = !!tasks.length);
  }
}
