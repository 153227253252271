import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractResource } from '@resources/abstract.resource';
import { ProductResource } from '@components/product/product.resource';
import { AuthService } from '@services/auth.service';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { SessionHelper } from '@helpers/session.helper';
import { IHydraResource } from '@interfaces/hydra-resource.interface';
import { IMarketplace, IMarketplacesByCountry } from '@interfaces/marketplace.interface';
import { MarketplaceHelper } from '@helpers/MarketplaceHelper';
import { UrlHelper } from '@helpers/UrlHelper';
import { IColumnSortedEvent } from '@components/generic/List/services/sort.service';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { ProductFiltersFormService } from '@components/product/list/services/product-filters-form.service';
import { IProduct } from '@components/product/interfaces';
import { ExportResource } from '@resources/export.resource';
import { SnackbarService } from '@components/snackbar';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-product-list',
  template: require('./product-list.component.html'),
  styles: [require('./product-list.component.scss')],
  providers: [
    { provide: AbstractResource, useClass: ProductResource },
    { provide: AbstractFiltersFieldsService, useClass: ProductFiltersFormService }
  ],
})
export class ProductListComponent extends AbstractPageComponent implements OnInit {

  private items: any;
  private totalItems: number;
  public marketplaces: IMarketplace[];
  public filteredMarketplaces: IMarketplace[];
  public marketplacesByCountry: IMarketplacesByCountry;
  private productList: IProduct[] = [];
  private currentState: string;
  public showBulkEdition: boolean = false;
  public selectedProductListCount: number = 0;
  public bulkEditorHeight: number = 0;
  public filterParams: string;
  public modalOpened: boolean = false;
  public form: FormGroup;
  public popinElement: any;
  public marketplacesNotToSelect = [
    'bricoprive',
    'gosport',
    'sitefr',
    'showroom',
    'youkado',
    'decathlon',
    'fabriquedestyle',
    'b2b',
    'monechellefulfillmentde',
    'monechellefulfillment',
    'alicedeals',
    'privatesportshop',
    'googlefr',
  ];

  @ViewChild('productResultList') public productResultList: any;
  @ViewChild('productListBulkEdition') public productListBulkEdition: any;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private urlHelper: UrlHelper,
    private exportResource: ExportResource,
    private snackbar: SnackbarService,
    private formBuilder: FormBuilder
  ) {
    super($translate, authService, resource, state);
  }

  public ngOnInit(): void {
    this.currentState = (<any>this.state.$current).parent.self.name;
    this.marketplaces = MarketplaceHelper.getDisplayedOnProductPageMarketplaces();
    this.filteredMarketplaces = MarketplaceHelper.getDisplayedOnProductListPageFilteredMarketplaces();
    this.marketplacesByCountry = MarketplaceHelper.getMarketplacesByCountry();
    this.fetch(null, true);
    this.buildForm();
  }

  public loadItems(params: object): void {
    this.resource.cGet(this.getParams(params))
      .takeUntil(this.destroyed$)
      .subscribe((response: IHydraResource|any) => {
          if (+response.total) {
            this.totalItems = +response.total;
          }
          this.items = response.products;
        },
        undefined,
        () => {
          SessionHelper.setFiltersForPage(params, this.currentState);

          this.state.go(this.state.current, params, { location: true, notify: false });
        }
      )
    ;
  }

  /**
   * Fetches data with potentially filters.
   * Save filters in the storage in the complete subscription, then update url.
   */
  private fetch(filters?: object, checkFilterOnInit: boolean = false): void {
    const currentFilters: any = this.getParams(filters);
    if (checkFilterOnInit) {
      const keyToCheck = ['sku[]', 'reference', 'superProduct', 'catalog', 'ean'];
      const hasFilters = Object.keys(currentFilters).some(function(key) {
        return keyToCheck.includes(key) && currentFilters[key] && currentFilters[key].length > 0;
      });

      if (false === hasFilters) {
        return;
      }
    }
    const params = {
      hidden: 0,
      ...this.getParams(filters),
      sparePartOnly: false,
      'brotherMarketplaces[]': MarketplaceHelper.getDisplayedOnProductListPageFilteredMarketplaceCodes()
    };

    this.filterParams = JSON.stringify(params);

    this.loadItems(params);
  }

  private getParams(filters?: object): object {
    const params = { ...this.resource.cGetDefaultFilters() };

    /**
     * Rules:
     *  1 - If user make research, we take its filters, the potentially state params (pagination) and the default params
     *  2 - If user arrives on the page without query params we check in the storage for filters
     *      and add potentially default filters
     *  3 - If the user come with filters in url, we take them by the state params and add default filters
     *  4 - If conditions above are not true, default filters are used.
     */
    if (filters) {
      return { ...params, ...this.state.params, ...filters };
    } else if (undefined === this.urlHelper.getParameters()) {
      return { ...params, ...SessionHelper.getFiltersForPage(this.currentState) };
    } else if (undefined !== this.urlHelper.getParameters()) {
      return { ...params, ...this.state.params };
    }

    return params;
  }

  public paginate(event: {page: number, byPage: number}): void {
    this.fetch(event);
  }

  public sortResult(event: IColumnSortedEvent) {
    switch (event.sortDirection) {
      case 'asc':
        this.fetch({ direction: 'asc', orderBy: event.sortColumn });
        break;
      case 'desc':
        this.fetch({ direction: 'desc', orderBy: event.sortColumn });
        break;
      case 'none':
        this.fetch({ direction: null, orderBy: null });
        break;
    }
  }

  public updateSelectedProductList(products: IProduct[]): void {
    this.selectedProductListCount = products.filter((product: IProduct) => product.selected).length;
    this.productList = products;
    this.showBulkEdition = !!this.productList.find((product: IProduct) => product.selected);
    this.productResultList.bulkEditorHeight = 0;

    setTimeout(() => {
      if (this.productListBulkEdition) {
        this.productResultList.bulkEditorHeight = this.productListBulkEdition.height;
      }
    }, 10);
  }

  public removeSkuFromBulkEdition(): void {
    this.selectedProductListCount--;
    const isProductSelected: IProduct = this.items.find((product: IProduct) => product.selected);

    if (!isProductSelected) {
      this.showBulkEdition = false;
      this.productResultList.bulkEditorHeight = 0;
    }

    this.productResultList.checkAll = false;
  }

  public mustBeDisplayedFirst(tabNumber: number): boolean {
    if (this.state.params.currentTab) {
      return +this.state.params.currentTab === tabNumber;
    }

    return 0 === tabNumber;
  }

  public onTabSelect(e: any): void {
    const params = { ...this.getParams(), currentTab: e.index };

    this.state.go('.', params , { notify: false });
  }

  public export(): void {
    const body: any = {
        exportCode: 'master_products_warehouses',
        formatCode: 'csv_excel',
        serializationGroups: [],
        dryRun: false,
        batchSize: null,
        deferred: true,
        split: false,
        limitFields: this.filterParams,
        async: true
    };

    this.exportResource.create(body).subscribe(
      () => {
        this.snackbar.inform(this.translate('PAGE.PRODUCT.LIST.FILTER.EXPORT.MASTER_PRODUCTS_WAREHOUSES.SUCCESS'));
      }
    );
  }

  public exportContent(): void {
    const body: any = {
      exportCode: 'product_content',
      formatCode: 'csv_excel',
      serializationGroups: ['product_content_export'],
      dryRun: false,
      deferred: true,
      split: false,
      limitFields: JSON.stringify({countryCode: this.currentCountryCode}),
      async : true
    };

    this.exportResource.create(body).subscribe(
      () => {
        this.snackbar.inform(this.translate('PAGE.PRODUCT.LIST.FILTER.EXPORT.MASTER_PRODUCTS_WAREHOUSES.SUCCESS'));
      }
    );
  }

  public exportInfoLogCarrier(): void {
    let filterParams = {};
    if (undefined !== this.filterParams) {
      filterParams = JSON.parse(this.filterParams);
    }

    const body: any = {
        exportCode: 'info_log_carrier',
        formatCode: 'csv_excel',
        serializationGroups: [],
        dryRun: false,
        batchSize: null,
        deferred: true,
        split: false,
        limitFields: JSON.stringify({ ...filterParams, ...{'countryCode': SessionHelper.getCountry().code}}),
        async: true
    };

    this.exportResource.create(body).subscribe(
      () => {
        this.snackbar.inform(this.translate('PAGE.PRODUCT.LIST.FILTER.EXPORT.INFO_LOG_CARRIER.SUCCESS'));
      }
    );
  }

  public buildForm(): void {
    const group: any = [];

    group[this.currentCountryCode] = this.formBuilder.array(this.marketplacesByCountry[this.currentCountryCode].map((marketplace: IMarketplace): FormGroup => {
      return this.formBuilder.group({
        checked: MarketplaceHelper.isFilteredOnProductListPage(marketplace.code, this.currentCountryCode),
        value: marketplace.code,
        commercialName: marketplace.commercialName
      });
    }));

    Object.keys(this.marketplacesByCountry)
      .filter((countryCode: string) => countryCode !== this.currentCountryCode)
      .map((countryCode) => {
        group[countryCode] = this.formBuilder.array(this.marketplacesByCountry[countryCode].map((marketplace: IMarketplace): FormGroup => {
          return this.formBuilder.group({
            checked: MarketplaceHelper.isFilteredOnProductListPage(marketplace.code, countryCode),
            value: marketplace.code,
            commercialName: marketplace.commercialName
          });
        }));
      });

    this.form = this.formBuilder.group(group);
  }

  public getCountryFA(countryCode: string): FormArray {
    return this.form.get(countryCode) as FormArray;
  }

  public getFormKeys(): string[] {
    return Object.keys(this.form.controls);
  }

  public saveForm(): void {
    const newFormValue = this.form.value;
    const selectedCodes: any = {};

    Object.keys(newFormValue).map((countryCode: string) => {
      const inputs = newFormValue[countryCode];

      selectedCodes[countryCode] = inputs.filter((input: any) => input.checked).map((input: any) => {
        return MarketplaceHelper.getMarketplaceByCode(input.value);
      });
    });

    SessionHelper.setFilteredMarketplaces(selectedCodes);

    this.state.go(this.state.current, this.state.params, { reload: true });
  }

  public hasSomeChecked(countryCode: string): boolean {
    return (<FormArray>this.form.get(countryCode)).controls.some(control => control.get('checked').value === true);
  }

  public toggleCheck(countryCode: string): void {
    const controls = (<FormArray>this.form.get(countryCode)).controls;
    const checkedValue = !this.hasSomeChecked(countryCode);

    controls.forEach((control) => {
      control.get('checked').setValue(checkedValue && !this.marketplacesNotToSelect.includes(control.value.value));
    });
  }

  public getFilterButtonLabel(): string {
    const number: number = SessionHelper.getCountFilteredMarketplaces();
    let label: string = this.$translate.instant('PAGE.PRODUCT.LIST.FILTER.SPECIFIC_MARKETPLACES.LABEL');

    if (number > 0) {
      label += ' (' + number + ')';
    }

    return label;
  }

  public showModal(event: MouseEvent): void {
    event.stopPropagation();
    this.modalOpened = true;
    document.body.addEventListener('click', this.handleOutsideClick);
  }

  private handleOutsideClick = (event: MouseEvent) => {
    this.popinElement = document.getElementById('modal');

    if (null !== this.popinElement && !this.popinElement.contains(event.target)) {
      this.modalOpened = false;

      document.body.removeEventListener('click', this.handleOutsideClick);
    }
  }

  private onImportSuccess(event: any): void {
    if (['product', 'product_pack'].includes(event.businessObject)) {
      this.onImportProduct(event);
    }
  }

  private onImportProduct(event: any): void {
    if (!event.response || !event.response.body || !event.response.body.processId) {
      return;
    }

    let state: string|null = null;

    switch (event.businessObject) {
      case 'product':
        state = 'product.import';
        break;
      case 'product_pack':
        state = 'product.pack_import';
        break;
    }

    if (null !== state) {
      this.state.go(state, {id: event.response.body.processId}, { reload: true });
    }
  }
}
