import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { Component, Inject } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { SuperProductResource } from '@components/super-product/super-product.resource';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { SuperProductListFiltersService } from '@components/super-product/list/services/super-product-list-filters.service';
import { IImportOptions } from '@interfaces/ImportOptions.interface';
import { IExportOptions } from '@interfaces';
import { DATE_SHORT_INTERNATIONAL_FORMAT } from '@constants';
import { SessionHelper } from '@helpers';
import moment = require('moment');

@Component({
  selector: 'app-super-product-list',
  template: require('./super-product-list.component.html'),
  styles: [require('./super-product-list.component.scss')],
  providers: [
    { provide: AbstractResource, useClass: SuperProductResource },
    { provide: AbstractFiltersFieldsService, useClass: SuperProductListFiltersService },
  ],
})
export class SuperProductListComponent extends AbstractPageComponent {
  public importOptions: IImportOptions[] = [];
  public exportOptions: IExportOptions[] = [];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
    this.importOptions = [...this.resource.importOptions, {
      entryPoint: '/api/v2/import',
      importButton: 'BUTTON.SUPER_PRODUCT.IMPORT.NEW_CONTENT',
      businessObject: 'super_product_content'
    }];
    this.exportOptions = [...this.resource.exportOptions, {
      entryPoint: '/v2/export',
      responseType: 'text',
      type: 'text/plain',
      filename: `export-super-product-new-content-${moment().format(DATE_SHORT_INTERNATIONAL_FORMAT)}.csv`,
      translationKey: 'BUTTON.SUPER_PRODUCT.EXPORT.NEW_CONTENT',
      filters: false,
      roles: ['ROLE_WALISOFT_AGENT'],
      name: 'exportSuperProductNewContent',
      postOptions: {
        exportCode: 'super_product_content',
        formatCode: 'csv_excel',
        dryRun: false,
        deferred: true,
        split: false,
        serializationGroups: [
          'super_product_content_export'
        ],
        limit: SessionHelper.getLocale() ? SessionHelper.getLocale() : null,
        async: true
      }
    }, {
      entryPoint: '/v2/export',
      responseType: 'text',
      type: 'text/plain',
      filename: `export-super-product-old-content-${moment().format(DATE_SHORT_INTERNATIONAL_FORMAT)}.csv`,
      translationKey: 'BUTTON.SUPER_PRODUCT.EXPORT.OLD_CONTENT',
      filters: false,
      roles: ['ROLE_WALISOFT_AGENT'],
      name: 'exportSuperProductOldContent',
      postOptions: {
        exportCode: 'super_product_content_old',
        formatCode: 'csv_excel',
        dryRun: false,
        deferred: true,
        split: false,
        serializationGroups: [
          'super_product_content_old_export'
        ],
        limit: SessionHelper.getLocale() ? SessionHelper.getLocale() : null,
        async: true
      }
    }];
  }
}
