'use strict'

angular
  .module 'ammsFrontendApp'
  .directive 'tableSort', [
    'ListFilter'
    (ListFilter) ->
      link = (scope, element, attrs) ->
        scope.onClick = ->
          filters = scope.filters
          listFilter = new ListFilter(scope.defaultFilters)

          if (!scope.direction)
            scope.direction = filters.direction

          if ('asc' == scope.direction)
            scope.direction = 'desc'
          else
            scope.direction = 'asc'

          if (attrs.by != filters.orderBy)
            scope.direction = 'asc'

          filters.orderBy = attrs.by
          filters.direction = scope.direction
          listFilter.updateUrl(filters)

      restrict: 'E'
      transclude: true
      scope:
        'by': '@'
        'defaultFilters': '='
        'filters': '='
      templateUrl: require('views/directives/table-sort.html')
      link: link
]
