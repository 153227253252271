import { AbstractFormFieldBase } from './abstract-form-field-base.class';

/**
 * Generic slider field, used to render slider widget.
 */
export class SliderField extends AbstractFormFieldBase<number> {
  /**
   * Used in the template to render the kendo-text-area component.
   *
   * @type {string}
   */
  fieldType = 'slider';

  showButtons: boolean;

  min: number;

  max: number;

  smallStep: number;

  constructor(options: {[key: string]: string|number|boolean}) {
    super(options);
    this.showButtons = undefined !== options.showButtons ? <boolean>options.showButtons : false;
    this.min = <number>options.min || 0;
    this.max = <number>options.max || 10;
    this.smallStep = <number>options.smallStep || 1;
  }
}
