import { Component, Inject, Input } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { AuthService } from '@services';
import { AbstractResource } from '@resources';
import { AbstractComponent } from '@components/generic/abstract.component';

@Component({
    selector: 'comment-dialog',
    template: require('./comment-dialog.component.html'),
    styles: [require('./comment-dialog.component.scss')],
})
export class CommentDialogComponent extends AbstractComponent {

    @Input() comments: any;

    constructor(
        @Inject('TranslationService') $translate: ng.translate.ITranslateService,
        authService: AuthService,
        @Inject('StateService') state: ng.ui.IStateService,
        resource: AbstractResource,
        private dialog: DialogRef) {
        super($translate, authService, resource, state);
    }

    onClose() {
        this.dialog.close();
    }
}
