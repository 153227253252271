import {
  Directive,
  Renderer2,
  HostListener,
  Input,
  ElementRef,
  AfterViewInit
} from '@angular/core';

@Directive({
  selector: '[appStickyHeader]'
})
export class StickyHeaderDirective implements AfterViewInit {
  @Input() private bulkEditorHeight: number;
  private tHeadPosition: number;
  private headerHeight: number;

  @HostListener('window:scroll')
  public windowScrolled() {
    this.windowScrollEvent();
  }

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  public ngAfterViewInit(): void {
    this.headerHeight = document.querySelector('.page-header.navbar').getBoundingClientRect().height;
    this.tHeadPosition = this.getElementTop();
  }

  windowScrollEvent() {
    const isReachingTop = this.getTop() >= this.tHeadPosition;
    if (isReachingTop) {
      this.renderer.addClass(this.elementRef.nativeElement, 'stickyTabHeader');
      this.renderer.setStyle(this.elementRef.nativeElement, 'top', `${ this.bulkEditorHeight + this.headerHeight }px`);
    } else {
      this.renderer.removeClass(this.elementRef.nativeElement, 'stickyTabHeader');
    }
  }

  private getTop() {
    return (
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0
    );
  }

  private getElementTop() {
    return this.elementRef.nativeElement.getBoundingClientRect().top // Element top
      - this.elementRef.nativeElement.getBoundingClientRect().height // With element height;
      + this.headerHeight; // With navbar;
  }
}
