import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { GrcResource } from '@components/grc/grc.resource';
import { LOCALES, WEBSITE_URLS } from '@constants';
import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SnackbarService } from '@components/snackbar';
import { IWarehouses } from '@components/warehouses/models';
import { SessionHelper } from '@helpers';
import { IArrival } from '@components/product/interfaces';

@Component({
  selector: 'app-grc-product-detail',
  template: require('./grc-product-detail.component.html'),
  styles: [require('./grc-product-detail.component.scss')],
  providers: [
    { provide: AbstractResource, useClass: GrcResource },
  ],
})
export class GrcProductDetailComponent extends AbstractPageComponent implements OnInit {

  public productMarketplace: any;
  public chart: Chart;
  public objectKeys: (o: {}) => string[] = Object.keys;
  public faqForm: FormGroup;
  public faqEditionForms: any = {};
  public frequentlyAskedQuestions: any = {super: [], master: []};
  public showFaqEditionForms: any = {};
  public showFaqCreationForm: boolean = false;
  public warehouses: IWarehouses[] = SessionHelper.getAllWarehouses();

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private snackbar: SnackbarService,
    private formBuilder: FormBuilder,
    @Inject('DialogService') private dialog: any,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit() {
    if (SessionHelper.useLinkedWarehousesOnly()) {
      this.warehouses = SessionHelper.getLinkedWarehouses();
    }

    if (this.isGranted('ROLE_WALISOFT_AGENT')) {
      this.buildFaqCreationForm();
    }

    this.resource.getProduct(this.state.params.id).takeUntil(this.destroyed$).subscribe((response: any) => {
      this.productMarketplace = response;
      this.createChart();
      if (this.isGranted('ROLE_WALISOFT_AGENT')) {
        this.fetchSuperProductFaq();
        this.fetchMasterProductFaq();
      }
    });
  }

  public createChart(): void {
    const series: any = [];

    for (const price of this.productMarketplace.prices) {
      series.push([new Date(price.date).getTime(), price.price]);
    }

    this.chart = new Chart({
      chart: {
        type: 'line'
      },
      title: {
        text: this.translate('PAGE.GRC.GRAPH')
      },
      credits: {
        enabled: false
      },
      xAxis: {
        type: 'datetime',
        labels: {
          formatter: function() {
            return Highcharts.dateFormat('%e %b %y', Number(this.value));
          }
        }
      },
      series: [{
        type: 'line',
        data: series
      }]
    });
  }

  public getWebsiteHost(): string {
    return WEBSITE_URLS[this.currentLocale];
  }

  public fetchSuperProductFaq(): void {
    const body: any = {};

    if (this.productMarketplace.product.superProduct) {
      body['superProduct.id'] = this.productMarketplace.product.superProduct.id;
    }

    this.resource
      .getFrequentlyAskedQuestions(body)
      .takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        this.addEditionFaqForms(response);
        this.frequentlyAskedQuestions.super = response;
      })
    ;
  }

  public getStockOriginByWarehouse(warehouseCode: any) {
    const warehouse = this.warehouses.find((e) => {
      return e.code === warehouseCode && !e.fulfillment;
    });

    return warehouse !== undefined && warehouse.stockOrigin !== undefined ? warehouse.stockOrigin : null;
  }

  public fetchMasterProductFaq(): void {
    const body: any = {};

    if (this.productMarketplace.product.masterProduct) {
      body['masterProduct.id'] = this.productMarketplace.product.masterProduct.id;
    }

    this.resource
      .getFrequentlyAskedQuestions(body)
      .takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        this.addEditionFaqForms(response);
        this.frequentlyAskedQuestions.master = response;
      })
    ;
  }

  public buildFaqCreationForm(): void {
    this.faqForm = this.formBuilder.group({
      isSuperProductQuestion: [true],
      question: [null, Validators.required],
      response: [null],
    });
  }

  public addEditionFaqForms(questions: any[]): void {
    for (const question of questions) {
      this.faqEditionForms[question.translations[this.currentLocale].id] = this.formBuilder.group({
        question: [question.translations[this.currentLocale].question],
        response: [question.translations[this.currentLocale].response],
      });
    }
  }

  public showFaqEdition(id: string): void {
    this.showFaqEditionForms[id] = true;
  }

  public hideFaqEdition(id: string): void {
    this.showFaqEditionForms[id] = false;
  }

  public showFaqCreation(type: string): void {
    this.faqForm.get('isSuperProductQuestion').setValue('super' === type);
    this.showFaqCreationForm = true;
  }

  public hideFaqCreation(): void {
    this.showFaqCreationForm = false;
  }

  public createFaq(): void {
    this.resource
      .createFrequentlyAskedQuestion(this.getFaqCreationBody())
      .takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
        this.state.go(this.state.current, this.state.params, { reload: true });
      })
    ;
  }

  public editFaq(translationId: any): void {
    this.resource
      .updateFrequentlyAskedQuestionTranslation(translationId, this.getFaqEditionBody(translationId))
      .takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
        this.state.go(this.state.current, this.state.params, { reload: true });
      })
    ;
  }

  public removeFaq(id: string): void {
    this.dialog.confirm(this.translate('PAGE.GRC.DELETE_FAQ'))
      .then(() => {
        this.resource
          .deleteFrequentlyAskedQuestion(id)
          .takeUntil(this.destroyed$)
          .subscribe((response: any) => {
            this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
            this.state.go(this.state.current, this.state.params, {reload: true});
          })
        ;
      })
    ;
  }

  public like(question: any, quantity: number): void {
    question.likes += quantity;

    this.resource
      .updateFrequentlyAskedQuestionLikes(question.id, this.getFaqLikesBody(question))
      .takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
        this.state.go(this.state.current, this.state.params, { reload: true });
      })
    ;
  }

  public getStock(fulfillment: boolean): number {
    let stock = 0;
    const stocks = this.productMarketplace.product.provisionalStockByWarehouse;

    for (const code of Object.keys(stocks)) {
      for (const warehouse of this.warehouses) {
        if (code === warehouse.code && ((fulfillment && warehouse.fulfillment) || (!fulfillment && !warehouse.fulfillment))) {
          stock += stocks[code];
          break;
        }
      }
    }

    return stock;
  }

  public getArrivalStock(): number {
    if (!SessionHelper.useLinkedWarehousesOnly()) {
      return this.productMarketplace.product.arrivalStock + this.getStock(false);
    }

    let stock = 0;
    const stocks = this.productMarketplace.product.arrivalStockByWarehouse;

    for (const code of Object.keys(stocks)) {
      for (const warehouse of this.warehouses) {
        if (code === warehouse.code) {
          stock += stocks[code];
          break;
        }
      }
    }

    return stock + this.getStock(false);
  }

  public getArrivals(): any {
    return this.productMarketplace.product.arrivals
      .filter((arrival: IArrival) => new Date(arrival.date) > new Date())
      .sort((a: IArrival, b: IArrival) => new Date(a.date) > new Date(b.date))
      .map((arrival: IArrival) => ({date: arrival.date, quantity: arrival.quantity}));
  }

  private getFaqCreationBody(): any {
    const formValues = this.faqForm.value;

    const superProductIri = formValues.isSuperProductQuestion ? this.productMarketplace.product.superProduct['@id'] : null;
    const masterProductIri = !formValues.isSuperProductQuestion ? this.productMarketplace.product.masterProduct['@id'] : null;

    const body: any = {
      translations: {},
    };

    if (superProductIri) {
      body.superProduct = superProductIri;
    }

    if (masterProductIri) {
      body.masterProduct = masterProductIri;
    }

    for (const locale of LOCALES) {
      body.translations[locale] = {
        locale: locale,
      };

      if (formValues.question) {
        body.translations[locale].question = formValues.question;

        if (locale !== this.currentLocale) {
          body.translations[locale].question += ' (to translate)';
        }
      }

      if (formValues.response) {
        body.translations[locale].response = formValues.response;

        if (locale !== this.currentLocale) {
          body.translations[locale].response += ' (to translate)';
        }
      }
    }

    return body;
  }

  private getFaqEditionBody(id: string): any {
    return this.faqEditionForms[id].value;
  }

  private getFaqLikesBody(question: any): any {
    return {
      likes: question.likes,
    };
  }
}
