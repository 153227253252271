import { Component, Inject, OnInit } from '@angular/core';
import { CustomerDetailOrdersResource } from '@components/customer/customer-detail-orders.resource';
import { SnackbarService } from '../../../snackbar';
import { AuthService } from '@services';
import { AbstractComponent } from '@components/generic/abstract.component';
import { ICustomerDetailOrder } from '@components/customer/interfaces';
import { AbstractResource } from '@resources/abstract.resource';

/**
 * Displays a list of customer orders.
 */
@Component({
  selector: 'app-customer-detail-orders',
  template: require('./customer-detail-orders.component.html'),
  styles: [require('./customer-detail-orders.component.scss')],
  providers: [
    { provide: AbstractResource, useClass: CustomerDetailOrdersResource },
  ]
})
export class CustomerDetailOrdersComponent extends AbstractComponent implements OnInit {
  public readonly roles: string[] = ['ROLE_WALISOFT_AGENT', 'ROLE_WALISOFT_AGENT'];

  public getManyEntryPoint: string;
  public orders: ICustomerDetailOrder[] = [];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    public resource: CustomerDetailOrdersResource,
    private snackbar: SnackbarService,
  ) {
    super($translate, authService, null, state);

    this.getManyEntryPoint = this.resource.getManyEntrypoint(this.state.params.id);
  }

  ngOnInit(): void {
    if (!this.isGranted(this.roles)) {
      this.snackbar.alert(this.translate('ALERTS.AUTHORIZATION_FAIL'));

      return;
    }

    this.resource.getMany({}, {entryPoint: this.getManyEntryPoint})
      .takeUntil(this.destroyed$)
      .subscribe((response: ICustomerDetailOrder[]) => {
        this.orders = response;
      })
    ;
  }

  public getTotalAmountOrders(orders: ICustomerDetailOrder[]): number {
    const totalAmount = orders.map((order) => {
      return +order.amount;
    });

    return totalAmount.reduce((a, b) => a + b, 0);
  }

  public switchState(state: string): void {
    this.state.go(state, {id: this.state.params.id});
  }
}
