import { Component, Inject, Input, OnInit } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import {
  IHighestOffer,
  IOffer,
  IProductMarketplaceForm,
} from '@components/product/interfaces/product-marketplace-form.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { DATE_FULL_FORMAT, DATE_SHORT_FORMAT } from '@constants/date';
import { ProductMarketplaceResource } from '@components/product/product-marketplace.resource';
import { SnackbarService } from '@components/snackbar';
import { INPUT_NUMBER_PATTERN_DEC } from '@constants/form';
import { NumberHelper } from '@helpers/NumberHelper';
import { FormNotifierService } from '@services';

@Component({
  selector: 'app-product-marketplace-offers',
  template: require('./product-marketplace-offers-form.component.html'),
})
export class ProductMarketplaceOffersFormComponent extends AbstractComponent implements OnInit {

  public highestOffer: IHighestOffer;
  public formsFromOffers: FormGroup[] = [];
  public initialForm: FormGroup;
  public readonly currency: string = this.currency.toLowerCase();

  @Input() public productMarketplace: IProductMarketplaceForm;
  @Input() sparePartContext: boolean = false;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    resource: AbstractResource,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    @Inject('DialogService') private dialog: any,
    private snackbar: SnackbarService,
    private formNotifier: FormNotifierService,
  ) {
    super($translate, authService, resource, state);
  }

  /**
   * @inheritDoc
   */
  ngOnInit(): void {
    if (this.productMarketplace.id) {
      this.resource.getHighestOffer(this.productMarketplace.id)
        .takeUntil(this.destroyed$)
        .subscribe((response: IHighestOffer) => this.highestOffer = response)
      ;
    }

    this.buildForm();
  }

  private buildForm(): void {
    this.productMarketplace.offers.forEach((offer: IOffer, index: number) => {
      this.formsFromOffers.push(this.getForm());
      this.fillForm(offer, index);
    });

    this.initialForm = this.getForm();
  }

  private getForm(): FormGroup {
    return this.formBuilder.group({
      specialPrice: ['', [Validators.pattern(INPUT_NUMBER_PATTERN_DEC), Validators.required]],
      dateBegin: [null],
      dateEnd: [null],
      percentage: ['', [Validators.pattern(INPUT_NUMBER_PATTERN_DEC)]],
      id: [''],
    });
  }

  private fillForm(offer: IOffer, index: number): void {
    offer.dateBegin = offer.dateBegin ? moment(offer.dateBegin).startOf('day').format(DATE_SHORT_FORMAT) : null;
    offer.dateEnd = offer.dateEnd ? moment(offer.dateEnd).endOf('day').format(DATE_SHORT_FORMAT) : null;
    offer.percentage = offer.commercialOperation && offer.commercialOperation.percentage ? offer.commercialOperation.percentage : null;
    this.formsFromOffers[index].patchValue(offer);
  }

  public submit(event: any, form: FormGroup, offerId?: string): void {
    this.dialog.confirm(this.translate(
      offerId ? 'PAGE.PRODUCT.EDIT.TAB.MARKETPLACES.BOX_OFFER.CONFIRM.UPDATE' : 'PAGE.PRODUCT.EDIT.TAB.MARKETPLACES.BOX_OFFER.CONFIRM.ADD')
    )
      .then(() => {
        const body = {
          specialPrice: NumberHelper.parseFloat(form.value.specialPrice),
          dateBegin: form.value.dateBegin ? moment(form.value.dateBegin, DATE_SHORT_FORMAT).startOf('day').format(DATE_FULL_FORMAT) : null,
          dateEnd: form.value.dateEnd ? moment(form.value.dateEnd, DATE_SHORT_FORMAT).endOf('day').format(DATE_FULL_FORMAT) : null,
        };

        if (offerId) {
          (<ProductMarketplaceResource>this.resource).partialUpdateOffer(this.productMarketplace.id, offerId, body)
            .takeUntil(this.destroyed$)
            .subscribe(() => {
              this.formNotifier.notifyFormSubmitted();
              this.snackbar.validate(this.translate('PAGE.PRODUCT.EDIT.TAB.MARKETPLACES.BOX_OFFER.ALERTS.UPDATE'));
            })
          ;

          return;
        }

        (<ProductMarketplaceResource>this.resource).createOffer(this.productMarketplace.id, body)
          .takeUntil(this.destroyed$)
          .subscribe((response: IOffer) => {
            this.snackbar.validate(this.translate('PAGE.PRODUCT.EDIT.TAB.MARKETPLACES.BOX_OFFER.ALERTS.ADD'));
            this.initialForm.reset();
            this.formsFromOffers.push(this.getForm());
            this.productMarketplace.offers.push(response);
            this.fillForm(response, this.formsFromOffers.length - 1);
            this.formNotifier.notifyFormSubmitted();
          });
      });
  }

  public delete(offerId: string): void {
    this.dialog.confirm(this.translate('PAGE.PRODUCT.EDIT.TAB.MARKETPLACES.BOX_OFFER.CONFIRM.DELETE'))
      .then(() => {
        (<ProductMarketplaceResource>this.resource).destroyOffer(this.productMarketplace.id, offerId)
          .takeUntil(this.destroyed$)
          .subscribe(() => {
            this.snackbar.validate(this.translate('PAGE.PRODUCT.EDIT.TAB.MARKETPLACES.BOX_OFFER.ALERTS.DELETE'));
            this.productMarketplace.offers = this.productMarketplace.offers.filter((offer: IOffer) => offerId !== offer.id);
            this.formsFromOffers = this.formsFromOffers.filter((form: FormGroup) => offerId !== form.value.id);
          })
        ;
      })
    ;
  }
}
