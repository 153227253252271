import { AbstractFormFieldBase } from './abstract-form-field-base.class';
import { FormGroup } from '@angular/forms';

/**
 * Sku search field, used to render app-sku-search component.
 */
export class SkuSearchField extends AbstractFormFieldBase<string[]> {
  /**
   * Used in the template to render the app-sku-search component.
   *
   * @type {string}
   */
  fieldType = 'skuSearch';
  /**
   * Used to determine the type of data send to SkuSearchComponent
   *
   * @type {string}
   */
  productType: string;

  valueField?: string;

  limit?: number;

  isSparePart?: boolean;

  withSpareParts?: boolean;

  allowCustom?: boolean;

  constructor(options: { [key: string]: any } = {}) {
    super(options);
    if (options) {
      this.productType = options.productType || '';
      this.valueField = options.valueField || 'sku';
      this.limit = options.limit || undefined;
      this.isSparePart = options.isSparePart;
      this.withSpareParts = options.withSpareParts;
      this.allowCustom = undefined !== options.allowCustom ? options.allowCustom : false;
    }
  }

  /**
   *  Binds skus to form control value.
   */
  private bindSkusToField(skus: any, form: FormGroup): void {
    if (this.valueField === 'sku') {
      Array.isArray(skus)
        ? form.get(this.fieldName).patchValue(skus.map(item => item.sku))
        : form.get(this.fieldName).patchValue(skus.sku);
      return;
    }
    Array.isArray(skus)
      ? form.get(this.fieldName).patchValue(skus.map(item => item.id))
      : form.get(this.fieldName).patchValue(skus.id);
  }
}
