import { Component, Inject, OnInit } from '@angular/core';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { WebsiteHomepageSliderItemsResource } from '@components/website-homepage/website-homepage-slider-items/website-homepage-slider-items.resource';
import { IDndColumn } from '@components/generic/dnd-list/dnd-list.component';
import { BASE_URL } from '@constants';
import { ISliderItems } from '@components/website-homepage/website-homepage-slider-items/index';

@Component({
  selector: 'app-website-homepage-slider-items-result-list',
  template: require('./website-homepage-slider-items-result-list.component.html'),
  styles: [require('./website-homepage-slider-items-result-list.component.scss')],
  providers: [
    { provide: AbstractResource, useClass: WebsiteHomepageSliderItemsResource },
  ],
})
export class WebsiteHomepageSliderItemsResultListComponent extends AbstractPageComponent implements OnInit {

  private _items: ISliderItems[];

  set items(items: ISliderItems[]) {
    this._items = items;
  }

  get items(): ISliderItems[] {
    // @ts-ignore
    return this._items['hydra:member'];
  }

  public columns: IDndColumn[];
  public BASE_URL = BASE_URL;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }

  public ngOnInit(): void {
    this.columns = [
      {
        size: 3,
        title: this.translate('PAGE.SLIDER_ITEMS.LIST.TABLE.HEAD.IMAGE'),
        property: '',
        type: 'customColumnContent',
      },
      {
        size: 2,
        title: this.translate('PAGE.SLIDER_ITEMS.LIST.TABLE.HEAD.LINK'),
        property: 'link',
        type: 'link'
      },
      {
        size: 1,
        title: this.translate('PAGE.SLIDER_ITEMS.LIST.TABLE.HEAD.DATE.START'),
        property: 'startTime',
        type: 'date'
      },
      {
        size: 1,
        title: this.translate('PAGE.SLIDER_ITEMS.LIST.TABLE.HEAD.DATE.END'),
        property: 'endTime',
        type: 'date'
      },
      {
        size: 2,
        title: this.translate('PAGE.SLIDER_ITEMS.LIST.TABLE.HEAD.MEDIA_QUERY'),
        property: 'mediaQuery',
      },
      {
        size: 1,
        title: this.translate('PAGE.SLIDER_ITEMS.LIST.TABLE.HEAD.ACTIVE'),
        property: '',
        type: 'active'
      }
    ];
  }
}
