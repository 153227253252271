import { Injectable } from '@angular/core';
import { IDirectInjection } from '@components/export-logistics/models/direct-injection.interface';

/**
 * Keep a reference of warehouses checked needed by the ExportLogisticsComponent.
 */
@Injectable()
export class WcaService {

  public wcaChecked: IDirectInjection[] = [];

  public setWcaChecked(wcaChecked: IDirectInjection[]): void {
    this.wcaChecked = wcaChecked;
  }

  public getWcaChecked(): IDirectInjection[] {
    return this.wcaChecked;
  }
}
