import {Component, Inject, Input, OnInit} from '@angular/core';
import {AbstractResource} from '@resources';
import {ProductResource} from '@components/product/product.resource';
import {AbstractFormComponent} from '@components/generic/Form/abstract-form.component';
import {AuthService} from '@services';
import {IMasterProductVersion, IProductForm} from '@components/product/interfaces/product-form.interface';
import {FormBuilder, FormGroup} from '@angular/forms';
import {SnackbarService} from '@components/snackbar';
import {DATE_SHORT_FORMAT, DATE_SHORT_FORMAT_UK, FULL_EUROPEEN_DATE_FORMAT} from '@constants';
import * as moment from 'moment';

@Component({
  selector: 'app-product-versions-form',
  template: require('./product-versions-form.component.html'),
  providers: [
    {provide: AbstractResource, useClass: ProductResource},
  ]
})
export class ProductVersionsFormComponent extends AbstractFormComponent implements OnInit {

  @Input() model: IProductForm;

  public form: FormGroup;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    private snackbar: SnackbarService,
    @Inject('DialogService') private dialog: any,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    this.createForm();
    this.fillForm();
  }

  public createForm(): void {
    this.form = this.formBuilder.group({
      actual: this.formBuilder.group({
        id: null,
        version: this.model.version,
        orderNumber: null,
        shipmentNumber: null,
        assemblyChange: null,
        noticeVersion: null,
        comment: null,
      }),
      versions: this.formBuilder.array([]),
    });

    const versions = this.model.masterProduct.versions.filter((version: string) => version !== this.model.version);

    const versionGroups = versions.map((version: string) => {
      return this.formBuilder.group({
        id: null,
        version: version,
        orderNumber: null,
        shipmentNumber: null,
        assemblyChange: null,
        noticeVersion: null,
        comment: null,
      });
    });

    this.form.setControl('versions', this.formBuilder.array(versionGroups));
  }

  public fillForm(): void {
    const formModel: any = {
      actual: null,
      versions: [],
    };

    const actual: IMasterProductVersion = this.getVersions(true).shift() || {
      id: null,
      version: this.model.version,
      orderNumber: null,
      shipmentNumber: null,
      assemblyChange: null,
      noticeVersion: null,
      comment: null,
    };

    const others: IMasterProductVersion[] = this.getVersions();

    formModel.actual = actual;
    formModel.versions = others;

    this.form.patchValue(formModel);
  }

  public submit(): void {
    if (!this.form.dirty || !this.form.valid) {
      let errorMessage = 'ALERTS.NO_CHANGE.FORM';

      if (!this.form.valid) {
        errorMessage = 'ALERTS.ERROR.FORM';
      }

      this.snackbar.warn(this.translate(errorMessage));

      return;
    }

    const actual: IMasterProductVersion = this.form.value.actual;
    const others: IMasterProductVersion[] = this.form.value.versions;

    this.sendVersionData(actual);

    for (const version of others) {
      this.sendVersionData(version);
    }
  }

  public getVersions(actual: boolean = false): IMasterProductVersion[] {
    if (actual) {
      const masterProductVersions: IMasterProductVersion[] = this.model.masterProduct.masterProductVersions
        .filter((masterProductVersion: IMasterProductVersion) => this.model.version === masterProductVersion.version);

      if (masterProductVersions[0] && masterProductVersions[0].noticeVersion) {
        masterProductVersions[0].noticeVersion = moment(masterProductVersions[0].noticeVersion).format(DATE_SHORT_FORMAT);
      }

      return masterProductVersions;
    }

    const versions = this.model.masterProduct.versions.filter((version: string) => version !== this.model.version);

    return versions.map((version: string) => {
      const find = this.model.masterProduct.masterProductVersions
        .find((masterProductVersion: IMasterProductVersion) => masterProductVersion.version === version);

      if (find && find.noticeVersion) {
        find.noticeVersion = moment(find.noticeVersion).format(DATE_SHORT_FORMAT);
      }

      return find || {
        id: null,
        version: version,
        orderNumber: null,
        shipmentNumber: null,
        assemblyChange: null,
        noticeVersion: null,
        comment: null,
      };
    });
  }

  public sendVersionData(masterProductVersion: IMasterProductVersion): void {
    if (masterProductVersion.id === null && (masterProductVersion.comment !== null || masterProductVersion.orderNumber !== null)) {
      this.resource
        .create({
          masterProduct: `/api/v2/master_products/${this.model.masterProduct.id}`,
          version: masterProductVersion.version,
          orderNumber: masterProductVersion.orderNumber,
          shipmentNumber: masterProductVersion.shipmentNumber,
          assemblyChange: !!masterProductVersion.assemblyChange,
          noticeVersion: masterProductVersion.noticeVersion ? moment(masterProductVersion.noticeVersion, DATE_SHORT_FORMAT).format(DATE_SHORT_FORMAT_UK) : null,
          comment: masterProductVersion.comment
        }, {entryPoint: '/v2/master_product_versions'})
        .subscribe(() => {
          this.snackbar.validate(this.translate('ALERTS.DATA.UPDATE'));
          this.state.go(this.state.current.name, {}, {reload: true});
        });
    } else if (masterProductVersion.comment !== null || masterProductVersion.orderNumber !== null) {
      this.resource
        .update(null, {
          comment: masterProductVersion.comment,
          orderNumber: masterProductVersion.orderNumber,
          shipmentNumber: masterProductVersion.shipmentNumber,
          assemblyChange: !!masterProductVersion.assemblyChange,
          noticeVersion: masterProductVersion.noticeVersion ? moment(masterProductVersion.noticeVersion, DATE_SHORT_FORMAT).format(DATE_SHORT_FORMAT_UK) : null,
        }, {entryPoint: `/v2/master_product_versions/${masterProductVersion.id}`})
        .subscribe(() => {
          this.snackbar.validate(this.translate('ALERTS.DATA.UPDATE'));
          this.state.go(this.state.current.name, {}, {reload: true});
        });
    }
  }

  public updateVersion(version: any): void {
    this.dialog.confirm(this.translate('PAGE.VERSION.CONFIRM', { oldVersion: this.model.version, newVersion: version }))
      .then(() => {
        (<ProductResource>this.resource)
          .update(null, { version: version }, { entryPoint: `/v2/products/${this.state.params.id}` })
          .takeUntil(this.destroyed$)
          .subscribe(() => {
            this.snackbar.validate(this.translate('SAVED'));
            this.state.go(this.state.current, this.state.params, { reload: true });
          });
      });
  }

  public createVersion(): void {
    this.dialog.confirm(this.translate('PAGE.PRODUCT.EDIT.SAVE_VERSION')).then(() => {
      (<ProductResource>this.resource).createVersion(this.state.params.id).takeUntil(this.destroyed$).subscribe(() => {
        this.snackbar.validate(this.translate('SAVED'));
        this.state.go(this.state.current, this.state.params, { reload: true });
      });
    });
  }
}
