import { IHydraMember } from '@interfaces/hydra-resource.interface';
import {ICarrierConfigurationType} from '@components/carrier/interfaces/carrier-configuration-type.interface';

export class CarrierConfigurationTypeModel implements ICarrierConfigurationType {
  public '@id': string;
  public id: number;
  public code: string;

  constructor(carrierConfigurationType: IHydraMember) {
    this['@id'] = carrierConfigurationType['@id'];
    this.id = carrierConfigurationType.id;
    this.code = carrierConfigurationType.code;
  }
}
