import { Component, Inject, Input, OnChanges, OnInit } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services/auth.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CODE_GB, INPUT_NUMBER_PATTERN_DEC} from '@constants';
import {SessionHelper} from '@helpers';


@Component({
    selector: 'app-order-resume',
    template: require('./order-resume.component.html'),
    providers: [],
})
export class OrderResumeComponent extends AbstractComponent implements OnInit, OnChanges {

  @Input() protected order: any;

  public form: FormGroup;
  public subTotal: number = 0;
  public discountTotal: number = 0;
  public fidelityTotal: number = 0;
  public exemptionTotal: number = 0;
  public shippingPriceTotal: number = 0;
  public total: number = 0;
  public discountTypes = [{ value: 'currency', label: SessionHelper.getCountry().code === CODE_GB ? '£' : '€' }, { value: 'percentage', label: '%' }];

  constructor(
      @Inject('TranslationService') $translate: ng.translate.ITranslateService,
      authService: AuthService,
      @Inject('StateService') state: ng.ui.IStateService,
      private formBuilder: FormBuilder
  ) {
      super($translate, authService, null, state);
  }

  ngOnInit(): void {
    this.buildForm();
  }

  ngOnChanges() {
      this.computeOrderResume();
  }

  public buildForm(): void {
    const orderModel = {
      discount: 0,
      discountPercent: 0,
      discountType: 'currency'
    };

    this.form = this.formBuilder.group({
      discountType: [orderModel.discountType, Validators.required],
      discount: [orderModel.discount, [Validators.pattern(INPUT_NUMBER_PATTERN_DEC), Validators.min(0)]],
      discountPercent: [orderModel.discountPercent, [Validators.pattern(INPUT_NUMBER_PATTERN_DEC), Validators.min(0), Validators.max(100)]],
    });
  }

  public hasItemDiscount(): boolean {
    return this.order.items.some((item: any) => {
      return item.discount;
    });
  }
  public onChangeDiscount() {
    let newDiscount: number = 0;
    let newDiscountPercent: number = 0;
    let totalPrice: number = 0;

    this.order.items.forEach((item: any) => {
      totalPrice += item.totalPrice;
    });

    const discountType = this.form.get('discountType').value;
    const discountValue = +this.form.get('discount').value;
    const discountPercentValue = +this.form.get('discountPercent').value;
    if (discountType === 'percentage') {
      newDiscount = totalPrice ? Math.round(((discountPercentValue / 100) * totalPrice) * 100) / 100 : 0;
      newDiscountPercent = Number.isFinite(discountPercentValue) ? discountPercentValue : 0;
    } else {
      newDiscount = Number.isFinite(discountValue) ? discountValue : 0;
      newDiscountPercent = totalPrice ? Math.round((discountValue * 100) / totalPrice) : 0;
    }

    this.order.totalPrice = totalPrice;
    this.order.discount = newDiscount;
    this.order.discountPercent = newDiscountPercent;
    this.order.discountType = discountType;
    this.computeOrderResume();
  }

  private computeOrderResume() {
      if (this.order && this.order.items) {
        this.subTotal = 0;
        this.discountTotal = 0;
        this.shippingPriceTotal = 0;
        this.fidelityTotal = 0;
        this.exemptionTotal = 0;

        this.order.items.forEach((item: any) => {
          this.subTotal += +item.totalPrice;
          this.discountTotal += +item.discount;
          this.shippingPriceTotal += +item.shippingPrice;
          this.fidelityTotal += +item.fidelity;

          if (this.order.marketplace === 'b2b' && this.order.locale !== 'fr_FR') {
            const excludeExemptTotal = ((item.totalPrice + item.shippingPrice - item.discount) - item.fidelity);
            this.exemptionTotal += (excludeExemptTotal - ((excludeExemptTotal / (1 + item.product.country.vat))));
          }
        });
        this.total = (this.subTotal + this.shippingPriceTotal - this.discountTotal) - this.fidelityTotal - this.exemptionTotal - this.order.discount;
      }
  }
}
