import { ITrackingSaleArrival, ITrackingSales, ITrackingFlags } from '@components/tracking-sales';
import * as moment from 'moment';
import {DATE_SHORT_FORMAT} from '@constants';

export class TrackingSalesModel implements ITrackingSales {
  id: string;
  sku: string;
  sp: string;
  ss: string;
  arrivals: ITrackingSaleArrival[];
  formattedArrivals: string[];
  rotation: string;
  priceMin: string;
  priceMax: string;
  ets: string;
  flags: ITrackingFlags;
  formattedFlags: string[];

  constructor(trackingSale: ITrackingSales) {
    this.id = trackingSale.id;
    this.sku = trackingSale.sku;
    this.sp = trackingSale.sp;
    this.ss = trackingSale.ss;
    this.arrivals = trackingSale.arrivals;
    this.formattedArrivals = this.formatArrivals(trackingSale.arrivals);
    this.rotation = trackingSale.rotation;
    this.priceMin = trackingSale.priceMin;
    this.priceMax = trackingSale.priceMax;
    this.ets = trackingSale.ets;
    this.flags = trackingSale.flags;
    this.formattedFlags = this.formatFlags(trackingSale.flags);
  }

  private formatFlags(flags: ITrackingFlags): string[] {
    let pricingAlert = '';

    if (true === flags.pricingAlert) {
      pricingAlert = '<i class="glyphicon glyphicon-euro"></i>';
    }

    return [pricingAlert];
  }

  private formatArrivals(arrivals: ITrackingSaleArrival[]): string[] {
    return arrivals
      ? arrivals.map(arrival => {
        return `<p>${moment(arrival.date).format(DATE_SHORT_FORMAT)}: ${arrival.quantity}</p>`;
      })
      : null;
  }
}
