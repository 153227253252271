import { Component, Inject, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services/auth.service';
import { IServiceTranslation } from '@components/service/model/service-translation.interface';

@Component({
  selector: 'app-service-form-translations',
  template: require('./service-form-translations.component.html'),
})
export class ServiceFormTranslationsComponent extends AbstractComponent {

  @Input() public form: FormGroup;
  @Input() public translation: IServiceTranslation;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, null, state);
  }
}
