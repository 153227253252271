import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { AbstractResource } from '@resources';

@Injectable()
@Resource({
  entryPoint: '/v2/patterns',
  isHydra: true,
})
export class PatternsResource extends AbstractResource {

  constructor(http: HttpClient) {
    super(http);
  }

  public getAllPatterns(): Observable<object> {
    return this.cGet({}, { entryPoint: '/v2/patterns', returnHydraMembers: true, isHydra: true, dontUseModel: true });
  }
}
