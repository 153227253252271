import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: '[app-notation-row]',
  template: require('./notation-row.component.html')
})
export class NotationRowComponent {
  @Input()
  public item: any;

  @Output()
  public onToggle: EventEmitter<any> = new EventEmitter();

  /**
   * Toggles activation in header of footer
   *
   * @param {string} type
   */
  public toggle(type: string) {
    if ('header' === type) {
      this.item.activeHeader = !this.item.activeHeader;
    } else {
      this.item.activeFooter = !this.item.activeFooter;
    }

    this.onToggle.emit();
  }
}
