'use strict'

angular
  .module 'ammsFrontendApp'
  .directive 'labelMarketplace', [
    ->
      link = ($scope) ->
        $scope.isMarketplaceParentCdiscount = ->
          if $scope.marketplaceParentCode is 'cdiscount'
            return true

          false

      link: link
      replace: true
      restrict: 'E'
      scope:
        marketplaceName: '<'
        marketplaceParentCode: '<'
      templateUrl: require('views/directives/label-marketplace.html')
  ]
