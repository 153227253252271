import { NgModule } from '@angular/core';
import { SelectLocaleComponent } from '@components/partials/select-locale/select-locale.component';
import { SelectCountryComponent } from '@components/partials/select-country/select-country.component';
import { UsurpedComponent } from '@components/partials/usurped/usurped.component';
import { EnhancedFieldsModule } from '@directives/enhanced-fields.module';
import { CommonModule } from '@angular/common';
import { PopoverModule } from 'ngx-bootstrap';
import { HeaderComponent } from '@components/partials/header/header.component';
import { RabbitMqModule } from '@components/rabbit';
import { HeaderTasksStatsModule } from '@components/header-tasks-stats/header-tasks-stats.module';
import { ApplicationModule } from '@components/application';
import { BreadcrumbsComponent } from '@components/partials/breadcrumbs';
import { SidebarComponent } from '@components/partials/sidebar';

@NgModule({
  entryComponents: [
    SelectCountryComponent,
    SelectLocaleComponent,
    UsurpedComponent,
    HeaderComponent,
    BreadcrumbsComponent,
    SidebarComponent,
  ],
  declarations: [
    SelectCountryComponent,
    SelectLocaleComponent,
    UsurpedComponent,
    HeaderComponent,
    BreadcrumbsComponent,
    SidebarComponent,
  ],
  imports: [
    EnhancedFieldsModule,
    CommonModule,
    PopoverModule,
    RabbitMqModule,
    HeaderTasksStatsModule,
    ApplicationModule,
  ],
})
export class PartialsModule {}
