import { Component, Inject, OnInit } from '@angular/core';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { AuthService } from '@services/auth.service';
import { AbstractResource, RoomResource } from '@resources';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormHelper, MarketplaceHelper, SessionHelper, } from '@helpers';
import { HydraHelper } from '@helpers/HydraHelper';
import { takeUntil } from 'rxjs/operators';
import { IFileInfo } from '@components/generic/Form/file-uploader/interfaces/file-info.interface';
import { IContentFormTranslationsValue } from '@components/banner';
import { INDEX_FOLLOW, NO_INDEX_FOLLOW, NO_INDEX_NO_FOLLOW } from '@components/categories';

@Component({
  selector: 'app-room-form',
  template: require('./room-form.component.html'),
  providers: [
    { provide: AbstractResource, useClass: RoomResource },
  ]
})

export class RoomFormComponent extends AbstractPageComponent implements OnInit {
  public form: FormGroup;
  public categoryForm: FormGroup;
  public room: any;
  public items: any[];
  private marketplace: any;
  public categories: any[];
  public roomCategories: any[];
  public currentLocales: string[] = SessionHelper.getCountry().locales;

  public seoMetaRobotOptions: any[] = [
    INDEX_FOLLOW,
    NO_INDEX_FOLLOW,
    NO_INDEX_NO_FOLLOW,
  ];

  public columns = [
    { 'size': 10, property: 'label', title: 'PAGE.PRODUCT.EDIT.TAB.MARKETPLACES.FORM.CATEGORY' },
  ];

  get translationsFA(): FormArray {
    return this.form.get('translations') as FormArray;
  }

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    public resource: AbstractResource,
    private formBuilder: FormBuilder,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    this.fetchMarketplace();

    if (this.state.params.id) {
      this.fetch();
    } else {
      this.buildForm();
    }
  }

  private fetchMarketplace(): void {
    this.resource.cGet({ code: MarketplaceHelper.getWebsiteMarketplace().code }, { entryPoint: '/v2/marketplaces', returnHydraMembers: true })
      .takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        this.marketplace = response[0];
      })
      ;
  }

  private buildForm(room?: any) {
    this.form = this.formBuilder.group({
      image: [room ? room.imageWebPath : null],
      file: null,
      seoMetaRobot: [room ? room.seoMetaRobot : NO_INDEX_NO_FOLLOW],
      translations: this.formBuilder.array([]),
      category: [room && room.category ? room.category['@id'] : null],
    });

    this.buildTranslationsForm(room);
  }

  private buildCategoryForm() {
    this.categoryForm = this.formBuilder.group({
      category: [null, Validators.required],
    });
  }

  private buildTranslationsForm(room?: any): void {
    const formGroups = this.currentLocales.map((locale) => {
      const roomTranslation = room && room.translations && room.translations[locale] ? room.translations[locale] : null;
      return this.formBuilder.group({
        name: [roomTranslation ? roomTranslation.name : '', Validators.required],
        titleSeo: [roomTranslation ? roomTranslation.titleSeo : '', Validators.required],
        metaDescriptionSeo: [roomTranslation ? roomTranslation.metaDescriptionSeo : '', Validators.required],
        slug: [roomTranslation ? roomTranslation.slug : '', Validators.required],
        longDescription: [roomTranslation ? roomTranslation.longDescription : '', Validators.required],
        shortDescription: [roomTranslation ? roomTranslation.shortDescription : '', Validators.required],
        alt: [roomTranslation ? roomTranslation.alt : '', Validators.required],
        locale,
      });
    });
    const formArray = this.formBuilder.array(formGroups);
    this.form.setControl('translations', formArray);
  }

  public hasSeveralTranslations(): boolean {
    return this.currentLocales.length > 1;
  }

  private fetch(): void {
    this.resource.cGet(
      { marketplace: MarketplaceHelper.getWebsiteMarketplace().code, pagination: false },
      { entryPoint: '/v2/categories', returnHydraMembers: true }
    )
      .takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        this.categories = response.map((item: any) => {
          return {
            id: item['@id'],
            label: item.translations[this.currentLocale] ? item.translations[this.currentLocale].name : 'Category ' + item.id + ' (missing translation)',
          };
        });
        this.roomCategories = [...[{id: '', label: ''}], ...this.categories];
      });

    this.resource.get(this.state.params.id)
      .takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        this.room = response;
        this.items = response.roomCategories.map((item: any) => {
          return {
            id: item.id,
            position: item.position,
            label: item.category ? item.category.translations[this.currentLocale].name : '',
          };
        });
        this.buildForm(response);
        this.buildCategoryForm();
      })
      ;
  }

  public submit(event: any): void {
    const formData: FormData = new FormData();
    const translations: { [locale: string]: IContentFormTranslationsValue } = {};

    if (this.state.params.id) {
      formData.append('room', this.state.params.id);
    }

    formData.append('seoMetaRobot', this.form.value.seoMetaRobot);
    formData.append('category', this.form.value.category ? this.form.value.category.substring(this.form.value.category.lastIndexOf('/') + 1) : null);
    formData.append('marketplace', this.marketplace['id']);

    this.translationsFA.controls.forEach((formGroup: FormGroup) => {
      const translationsFormValue: IContentFormTranslationsValue = formGroup.value;
      const locale: string = translationsFormValue.locale;

      translations[locale] = translationsFormValue;

      if (this.room && this.room.translations[locale] && this.room.translations[locale].id) {
        translations[locale].id = this.room.translations[locale].id;
      }
    });

    FormHelper.convertModelToFormData(translations, formData, 'translations');

    if (this.form && this.form.value.file && this.form.value.file.subscriber) {
      this.form.value.file.subscriber.subscribe((fileList: IFileInfo[]) => {
        formData.append('file', <File>fileList[0].rawFile, fileList[0].name);
      });
    }

    this.resource.uploadFile(formData, { entryPoint: '/v2/rooms' })
      .takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        this.state.go('room.edit', { id: response.id }, { reload: true });
      })
      ;
  }

  public submitCategory(event: any): void {
    const body = {
      room: HydraHelper.buildIri(this.state.params.id, 'rooms'),
      category: this.categoryForm.value.category.id,
    };

    this.resource.create(body, { entryPoint: '/v2/room_categories' })
      .takeUntil(this.destroyed$)
      .subscribe(() => {
        this.state.go(this.state.current, this.state.params, { reload: true });
      })
      ;
  }

  public delete() {
    this.resource.remove(this.state.params.id)
      .takeUntil(this.destroyed$)
      .subscribe(() => {
        this.state.go('room.list');
      })
      ;
  }

  public updatePosition(event: { id: string, position: number }): void {
    this.resource.partialUpdate(null, { position: event.position }, { entryPoint: '/v2/room_categories/' + event.id })
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.state.go(this.state.current, this.state.params, { reload: true });
      })
      ;
  }

  public deleteRoomCategory(event: any) {
    this.resource.remove(null, { entryPoint: '/v2/room_categories/' + event.id })
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.state.go(this.state.current, this.state.params, { reload: true });
      })
      ;
  }
}
