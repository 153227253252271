import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AbstractProductStatus } from '../../AbstractProductStatus';
import { AuthService } from '@services/auth.service';
import { takeUntil } from 'rxjs/operators';
import { AbstractResource } from '@resources';
import { IProductForm } from '@components/product/interfaces/product-form.interface';

/**
 * Displays the status of the product and allows its edition.
 */
@Component({
  selector: 'app-product-status-editable',
  template: require('./product-status-editable.component.html'),
  styles: [require('./product-status-editable.component.scss')],
  encapsulation: ViewEncapsulation.None
})
export class ProductStatusEditableComponent extends AbstractProductStatus implements OnInit {

  public statusBackground: string;
  public form: FormGroup;

  @Input() private status: string;

  constructor (
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    public resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    @Inject('DialogService') private dialog: any,
  ) {
    super($translate, authService, state);
  }

  /**
   * @inheritDoc
   */
  ngOnInit() {
    if (!this.hasOwnProperty('status')) {
      throw new Error(this.translate('PAGE.PRODUCT.STATUSES.COMPONENTS.MISSING_STATUS'));
    }

    this.statusBackground = this.getProductStatusBackground(this.status);

    this.form = new FormGroup({
      status: new FormControl(this.status),
    });

    this.form.valueChanges.subscribe((form) => {
      if (form.status !== this.status) {
        this.updateProductStatus(form.status);
      }
    });
  }

  private updateProductStatus(status: string): void {
    if (this.isReadOnlyStatus(status)) {
      this.form.setValue({ status: this.status });

      return;
    }

    this.dialog.confirm(this.translate('PAGE.PRODUCT.CONFIRM.UPDATE'))
      .then(() => {
        this.resource.update(null, { status }, { entryPoint: `/v2/products/${ this.state.params.id }/status` })
          .pipe(takeUntil(this.destroyed$))
          .subscribe((response: IProductForm) => {
            this.status = response.status;
            this.statusBackground = this.getProductStatusBackground(response.status);
            this.state.go(this.state.current, this.state.params, { reload: true });
          }, () => this.form.setValue({status: this.status}
          ))
        ;
      }, () => this.form.setValue({status: this.status}))
    ;
  }
}
