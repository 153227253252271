import { Component, Inject, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { FormErrorHelper, SessionHelper } from '@helpers';
import { FormSubmitArgument } from '@interfaces';
import { SnackbarService } from '../../../snackbar/snackbar.service';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { CarrierFamilyResource, ICarrierFamily } from '@components/carrierFamily';
import { AuthService } from '@services';

@Component({
  selector: 'app-carrier-family-edit',
  template: require('./carrier-family-edit.component.html')
})
export class CarrierFamilyEditComponent extends AbstractPageComponent implements OnInit {

  public carrierFamily: ICarrierFamily;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: CarrierFamilyResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private snackbar: SnackbarService,
    @Inject('DialogService') private dialog: any,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    this.fetch();
  }

  /**
   * Fetches API to get carrier family info.
   */
  private fetch(): void {
    const subscriber: Subscription = this.resource.get(this.state.params.id)
      .subscribe((carrierFamily: ICarrierFamily) => {
        this.carrierFamily = carrierFamily;
      }, undefined, () => subscriber.unsubscribe());
  }

  public save({ body, form, redirect }: FormSubmitArgument): void {
    const data: any = body;
    SessionHelper.get('APPLICATION_LOCALES').forEach((locale: string) => {
      if (data.translations[locale] && data.translations[locale].id) {
        data.translations[locale].id = `/api/v2/carrier_family_translations/${data.translations[locale].id}`;
      }
    });
    const subscriber: Subscription = this.resource.update(this.state.params.id, data)
      .subscribe(() => {
        this.snackbar.validate(this.translate('PAGE.CARRIER.CARRIER_FAMILIES.EDIT.SAVED'));
        if (redirect) {
          this.actions.list.go();
        }
      }, (error: any) => {
        if (400 === error.code) {
          FormErrorHelper.fillFormControlWithErrors(form, error.errors);
        }
      }, () => subscriber.unsubscribe());
  }
}
