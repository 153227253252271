import { NgModule } from '@angular/core';
import { GenericFormModule } from '@components/generic';
import { GenericModule } from '@components/generic';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { EnhancedFieldsModule } from '@directives/enhanced-fields.module';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { WebsiteModuleComponent } from '@components/website-module/website-module.component';
import { BlockResource } from '@resources';
import { TabsModule } from 'ngx-bootstrap';
import {DialogModule} from '@progress/kendo-angular-dialog';

@NgModule({
  entryComponents: [
    WebsiteModuleComponent,
  ],
    imports: [
        GenericModule,
        GenericFormModule,
        ReactiveFormsModule,
        FormsModule,
        CommonModule,
        EnhancedFieldsModule,
        DropDownsModule,
        TabsModule,
        DialogModule,
    ],
  providers: [
    BlockResource
  ],
  declarations: [
    WebsiteModuleComponent,
  ],
})
export class WebsiteModuleModule {}
