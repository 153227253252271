import { AbstractResource } from '@resources';
import { Injectable } from '@angular/core';
import { Resource } from '../decorators/Resource';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

@Injectable()
@Resource({
  entryPoint: '/v2/export',
})
export class ExportResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  public exportSelection(businessObject: string, businessObjectIds: any[], serializationGroup?: string): Observable<any> {
    return super.create({
      businessObject: businessObject,
      businessObjectIds: businessObjectIds,
      serializationGroup: serializationGroup,
    }, {
      entryPoint: '/v2/export_selections',
      responseType: 'blob',
    });
  }
}
