import { Component, Inject, Input } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-category-block-universe-result-list',
  template: require('./category-block-universe-result-list.component.html'),
})
export class CategoryBlockUniverseResultListComponent extends AbstractComponent {
  @Input() public category: any;
  @Input() public locale: string;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, null, state);
  }
}
