import { CarrierFamilyResource } from '@components/carrierFamily';

export default carrierFamilyRoutes;

/** @ngInject */
function carrierFamilyRoutes($stateProvider: any) {
  const commonStateData: {[key: string]: any} = {
    sidebarNav: 'carrier',
    sidebarSubNav: 'carrierFamilies',
    title: '',
    reloadChangeCountry: 'carrierFamilies.list'
  };

  $stateProvider
    .state('carrierFamilies', {
      url: '/carrier-families',
      parent: 'walisoft',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.CARRIER_FAMILIES',
        stateName: 'carrierFamilies.list',
      },
    })

    .state('carrierFamilies.list', {
      url: '/list',
      views: {
        'page@layout': {
          template: '<app-carrier-family-list></app-carrier-family-list>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.CARRIER.CARRIER_FAMILIES.LIST.TITLE',
      })
    })

    .state('carrierFamilies.new', {
      url: '/new',
      breadcrumb: {
        label: 'BREADCRUMB.NEW'
      },
      views: {
        'page@layout': {
          template: '<app-carrier-family-new></app-carrier-family-new>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.CARRIER.CARRIER_FAMILIES.NEW.TITLE',
        resource: CarrierFamilyResource,
        tabs: [
          {
            label: 'PAGE.CARRIER.CARRIER_FAMILIES.TAB.GENERAL',
            state: 'carrierFamilies.new',
          },
          {
            label: 'PAGE.CARRIER.CARRIER_FAMILIES.TAB.SHIPPING_PRICE',
          },
        ],
      })
    })

    .state('carrierFamilies.edit', {
      url: '/:id/edit',
      breadcrumb: {
        label: 'BREADCRUMB.EDIT'
      },
      views: {
        'page@layout': {
          template: '<app-carrier-family-edit></app-carrier-family-edit>'
        }
      },
      data:  Object.assign(commonStateData, {
        title: 'PAGE.CARRIER.CARRIER_FAMILIES.EDIT.TITLE',
        resource: CarrierFamilyResource,
        tabs: [
          {
            label: 'PAGE.CARRIER.CARRIER_FAMILIES.TAB.GENERAL',
            state: 'carrierFamilies.edit',
          },
          {
            label: 'PAGE.CARRIER.CARRIER_FAMILIES.TAB.SHIPPING_PRICE',
            state: 'carrierFamilies.edit.shippingPrice',
          },
        ],
      })
    })

    .state('carrierFamilies.edit.shippingPrice', {
      url: '/shipping-price',
      breadcrumb: {
        label: 'BREADCRUMB.SHIPPING_PRICE'
      },
      views: {
        'page@layout': {
          template: '<app-carrier-family-shipping-price></app-carrier-family-shipping-price>'
        }
      },
      data:  Object.assign(commonStateData, {
        title: 'PAGE.CARRIER.CARRIER_FAMILIES.SHIPPING_PRICE.TITLE',
        resource: CarrierFamilyResource,
        tabs: [
          {
            label: 'PAGE.CARRIER.CARRIER_FAMILIES.TAB.GENERAL',
            state: 'carrierFamilies.edit',
          },
          {
            label: 'PAGE.CARRIER.CARRIER_FAMILIES.TAB.SHIPPING_PRICE',
            state: 'carrierFamilies.edit.shippingPrice',
          },
        ],
      })
    })
  ;
}
