export class HttpHelper {

    /**
     * Recursively cleans parameters from angular properties
     * Removes object entries starting with $$, _ or @
     *
     * Empty strings are converted into null value, cause if we have an entry in db that we want to delete its value,
     * we have to send a null value to the api and not an empty string, if you are in this case, don't forget to add in
     * your resource the field name in the nullableProperties.
     *
     * @static
     * @param {*} params
     * @param {string[]} nullableProperties properties to handle null params
     * @param {string[]} hiddenProperties properties to handle hidden params
     * @returns {*}
     * @memberof HttpHelper
     */
    static cleanParams(params: any, nullableProperties: string[] = [], hiddenProperties: string[] = []): any {
        const results: any = {};

        if (!params || ['string', 'number', 'boolean'].includes(typeof params)) {
            return params;
        }

        Object.keys(params)
            .filter((key: string) => '$$' !== key.substr(0, 2) && '_' !== key.substr(0, 1) && '@' !== key.substr(0, 1))
            .forEach((key: string) => {
                if ('' === params[key] && nullableProperties.includes(key)) {
                  params[key] = null;
                }

                if (hiddenProperties.includes(key)) {
                  delete params[key];
                }

                if (Array.isArray(params[key])) {
                    results[key] = params[key].map((item: any) => this.cleanParams(item, nullableProperties, hiddenProperties));
                } else if (null !== params[key] || nullableProperties.includes(key) || nullableProperties[0] === 'all') {
                    results[key] = this.cleanParams(params[key], nullableProperties, hiddenProperties);
                }
            });

        return results;
    }

}
