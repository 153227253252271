import { LOCALE_FR } from '@constants';
import { SessionHelper } from '@helpers/session.helper';

export class TranslationHelper {
  public static getTranslation(translatable?: any, locale?: string, defaultLocale: string = LOCALE_FR): any {
    if (!translatable) {
      return null;
    }

    if (!locale) {
      locale = SessionHelper.getLocale();
    }

    if (!translatable.translations) {
      return null;
    }

    if (translatable.translations[locale]) {
      return translatable.translations[locale];
    }

    if (translatable.translations[defaultLocale]) {
      return translatable.translations[defaultLocale];
    }

    const keys = Object.keys(translatable.translations);

    return translatable.translations[keys[0]];
  }
}
