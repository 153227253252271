import { IRevenues } from './revenues.interface';

export class RevenuesModel implements IRevenues {
  public day: number;
  public month: number;
  public qte: string;
  public week: number;

  constructor(revenues: IRevenues) {
    // Because API return a string like "1 337 718", we need to remove spaces and convert into number for currency pipe
    this.day = +(<string>revenues.day).replace(/\s/g, '');
    this.month = +(<string>revenues.month).replace(/\s/g, '');
    this.qte = revenues.qte;
    this.week = +(<string>revenues.week).replace(/\s/g, '');
  }
}
