import { AbstractComponent } from '@components/generic/abstract.component';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import {
  IFormGeneralBody,
  IProductMarketplaceForm
} from '@components/product/interfaces/product-marketplace-form.interface';
import { IMarketplace } from '@interfaces/marketplace.interface';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { ProductMarketplaceStatusResource } from '@resources/product-marketplace-status.resource';
import * as moment from 'moment';
import { DATE_FULL_FORMAT, DATE_SHORT_FORMAT } from '@constants/date';
import { SnackbarService } from '@components/snackbar';
import { FormNotifierService } from '@services/form-notifier.service';
import { INPUT_NUMBER_PATTERN_DEC, INPUT_NUMBER_PATTERN_NODEC } from '@constants/form';
import { NumberHelper } from '@helpers/NumberHelper';
import { ICountry } from '@interfaces/ICountry';
import { SessionHelper } from '@helpers/session.helper';
import { IContentFormValue } from '@components/product/interfaces/product-form.interface';

@Component({
  selector: 'app-product-marketplace-general-form',
  template: require('./product-marketplace-general-form.component.html'),
})
export class ProductMarketplaceGeneralFormComponent extends AbstractComponent implements OnInit {

  public form: FormGroup;
  public productMarketplaceStatuses$: Observable<object>;
  public readonly currency: string = this.currency.toLowerCase();
  private currentCountry: ICountry;
  private isWebsiteMarketplace: boolean;

  @Input() productMarketplace: IProductMarketplaceForm;
  @Input() marketplace: IMarketplace;
  @Input() sparePartContext: boolean = false;

  get translationsFA(): FormArray {
    return this.form.get('translations') as FormArray;
  }

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    resource: AbstractResource,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    private productMarketplaceStatusResource: ProductMarketplaceStatusResource,
    @Inject('DialogService') private dialog: any,
    private snackbar: SnackbarService,
    private formNotifier: FormNotifierService,
  ) {
    super($translate, authService, resource, state);

    this.currentCountry = SessionHelper.getCountry();
  }

  /**
   * @inheritDoc
   */
  ngOnInit(): void {
    this.isWebsiteMarketplace = 'website' === this.marketplace.parent.code;

    this.buildForm();
    this.fillForm();

    if (this.isWebsiteMarketplace) {
      this.addWebsiteMarketplaceFields();
      this.buildTranslationsForm();
    }
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      stock: ['', Validators.pattern(INPUT_NUMBER_PATTERN_NODEC)],
      shippingDate: [''],
      basePrice: ['', Validators.pattern(INPUT_NUMBER_PATTERN_DEC)],
      quantityForCut: [0, Validators.pattern(INPUT_NUMBER_PATTERN_NODEC)],
      status: [{ text: this.translate('PAGE.PRODUCT.EDIT.TAB.MARKETPLACES.STATUS.INACTIVE'), value: 'inactive'}],
      marketplace: [''],
    });

    if (this.hasShippingPrice()) {
      this.form.addControl('shippingPrice', this.formBuilder.control([0], [Validators.pattern(INPUT_NUMBER_PATTERN_DEC)]));
    }

    this.productMarketplaceStatuses$ = this.productMarketplaceStatusResource.getManyWithLabel(
      { marketplace: this.marketplace.code }, { blocking: false }
    );
  }

  private fillForm(): void {
    this.productMarketplace.status = {
      value: this.productMarketplace.status,
      text: this.translate(`PAGE.PRODUCT.EDIT.TAB.MARKETPLACES.STATUS.${(<string>this.productMarketplace.status).toUpperCase()}`),
    };

    this.form.patchValue(this.productMarketplace);
  }

  public hasShippingPrice(): boolean {
    return !['ebay', 'magento', 'website'].includes(this.marketplace.parent.code);
  }

  public submit(event?: any): void {
    this.dialog.confirm(this.translate('PAGE.PRODUCT.CONFIRM.UPDATE'))
      .then(() => {
        const body: IFormGeneralBody = this.prepareBody();
        const productMarketplaceId: string = this.productMarketplace.id;

        this.resource.update(null, body, { entryPoint: `/v2/product_marketplaces/${productMarketplaceId}` })
          .takeUntil(this.destroyed$)
          .subscribe(() => {
            this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));

            if (event && event.redirect) {
              this.state.go(this.sparePartContext ? 'spare-part.list' : 'product_new.list');

              return;
            }

            this.formNotifier.notifyFormSubmitted();
            this.state.go(this.sparePartContext ? 'spare-part.edit.marketplace' : 'product.edit.marketplace', {
              id: this.state.params.id,
              marketplaceCode: this.marketplace.code
            }, {
              reload: true
            });
          })
        ;
      })
    ;
  }

  private prepareBody(): IFormGeneralBody {
    const formValue: IFormGeneralBody = this.form.value;

    formValue.shippingDate = formValue.shippingDate ? moment(formValue.shippingDate, DATE_SHORT_FORMAT).format(DATE_FULL_FORMAT) : null;
    formValue.status = (<{ text: string, value: string }>formValue.status).value;
    formValue.basePrice = NumberHelper.parseFloat(formValue.basePrice);
    formValue.quantityForCut = NumberHelper.parseFloat(formValue.quantityForCut);
    formValue.stock = NumberHelper.parseFloat(formValue.stock);
    if (this.hasShippingPrice()) {
      formValue.shippingPrice = NumberHelper.parseFloat(formValue.shippingPrice);
    }

    if (this.translationsFA) {
      const translations: { [locale: string]: IContentFormValue } = {};

      this.translationsFA.controls.forEach((formGroup: FormGroup) => {
        const translationsFormValue: IContentFormValue = formGroup.value;

        if (this.productMarketplace.translations[translationsFormValue.locale]) {
          translations[translationsFormValue.locale] = {
            id: `api/v2/product_marketplace_translations/${this.productMarketplace.translations[translationsFormValue.locale].id}`,
            ...translationsFormValue
          };
        } else {
          translations[translationsFormValue.locale] = translationsFormValue;
        }
      });

      formValue.translations = translations;
    }

    return formValue;
  }

  private addWebsiteMarketplaceFields(): void {
    this.form.addControl('featuredForced', new FormControl(false));
    this.form.get('featuredForced').setValue(this.productMarketplace.featuredForced);

    this.form.addControl('enabledMailInput', new FormControl(false));
    this.form.get('enabledMailInput').setValue(this.productMarketplace.enabledMailInput);
  }

  private buildTranslationsForm(): void {
    // build several form group for each country locales
    const formGroups = this.currentCountry.locales.map((locale: string) => {
      return this.formBuilder.group({
        longTitle: [this.hasExistingTranslations(locale) ? this.productMarketplace.translations[locale].longTitle : ''],
        shortTitle: [this.hasExistingTranslations(locale) ? this.productMarketplace.translations[locale].shortTitle : ''],
        locale,
      });
    });

    // pass the form groups to a form array
    const formArray = this.formBuilder.array(formGroups);

    // replace existing control with a new one, we pass it the form array that is a form group collection
    this.form.setControl('translations', formArray);
  }

  /**
   * Checks if the model translations exists and are sets for a given locale.
   */
  private hasExistingTranslations(locale: string): boolean {
    return this.productMarketplace.translations && !!this.productMarketplace.translations[locale];
  }

  /**
   * Checks if the current country has several translations.
   */
  public hasSeveralTranslations(): boolean {
    return this.currentCountry.locales.length > 1;
  }
}
