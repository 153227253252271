import { Injectable } from '@angular/core';
import { Resource } from '@decorators/Resource';
import { AbstractResource } from '@resources/abstract.resource';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  entryPoint: '/v2/litigation_types',
  isHydra: true,
})
export class LitigationTypeResource extends AbstractResource {

  constructor(http: HttpClient) {
    super(http);
  }
}
