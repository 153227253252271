import {
  IAddressing,
  IAddressingBarcode,
  IAddressingMasterProductsWarehouse,
} from './addressing-list.interface';
import { IAddressingList } from './addressing.interface';

export class AddressingListModel implements IAddressingList {
  public '@id': string;
  public addressingLane?: string;
  public addressingBay?: string;
  public addressingLocation?: string;
  public addressingLevel?: string;
  public addressingType: string;
  public masterProductsWarehouse?: IAddressingMasterProductsWarehouse;
  public quantity: number;
  public barcode?: IAddressingBarcode;
  public warehouseName?: string;
  public barcodeImage?: string;

  constructor(addressing: IAddressing) {
    this['@id'] = addressing['@id'];
    this.addressingLane = addressing.addressingLane || null;
    this.addressingBay = addressing.addressingBay || null;
    this.addressingLocation = addressing.addressingLocation || null;
    this.addressingLevel = addressing.addressingLevel || null;
    this.addressingType = addressing.addressingType;
    this.masterProductsWarehouse = addressing.masterProductsWarehouse || null;
    this.quantity = addressing.quantity || 0;
    this.barcode = addressing.barcode || null;
    this.warehouseName = this.masterProductsWarehouse ? this.masterProductsWarehouse.warehouse.name : null;
    this.barcodeImage = this.barcode !== null ? this.barcode.imageWebpath : null;
  }
}
