import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { Component, Inject } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { StockMovementResource } from '@resources/stock-movement.resource';

@Component({
  selector: 'app-stock-shooting-list',
  template: require('./stock-shooting-list.component.html'),
  providers: [
    { provide: AbstractResource, useClass: StockMovementResource },
  ],
})
export class StockShootingListComponent extends AbstractPageComponent {
  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }

  public add(): void {
    this.state.go('stock-shooting.new');
  }
}
