import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { HttpClient } from '@angular/common/http';
import { AbstractResource } from '@resources';

@Injectable()
@Resource({
  entryPoint: '/v2/stock_view',
  isHydra: true,
})
export class StockViewResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }
}
