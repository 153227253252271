import { Resource } from '@decorators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractResource } from '@resources';

@Injectable()
@Resource({
    entryPoint: '/v2/order-manager/items',
    isHydra: true,
    deleteAvailable: false,
    updateAvailable: false,
    createAvailable: false,
})
export class OrderManagerItemResource extends AbstractResource {

    constructor(http: HttpClient) {
        super(http);
    }
}
