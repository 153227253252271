'use strict'

###
  @name ProductPagination

  @description
  Component pagination for product module.

  @example
    <example>
      <div product-pagination></div>
    </example>

###
angular
  .module('ammsFrontendApp')
  .directive('productPagination', [
    ->
      restrict: 'A'
      templateUrl: require('views/products/list/pagination.html')
  ])
