import { Component, Inject, Input, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource, BlockResource } from '@resources';
import { SnackbarService } from '@components/snackbar/snackbar.service';
import { BlockListFormComponent } from '@components/generic/blocks/block-list-form.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BlockHelper } from '@components/generic/blocks/block-helper';
import { takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { forkJoin } from 'rxjs/observable/forkJoin';

@Component({
  selector: 'app-block-list-table',
  template: require('./block-list-table.component.html'),
  styles: [require('./block.scss')],
  providers: [{ provide: AbstractResource, useClass: BlockResource }]
})
export class BlockListTableComponent extends BlockListFormComponent implements OnInit {
  @Input() public additionalData: any[];

  public forms: FormGroup[] = [];
  public additionalForms: FormGroup[] = [];
  public newForms: FormGroup[] = [];
  public controls: any[] = [];
  public objectKeys: (o: {}) => string[] = Object.keys;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    protected snackbar: SnackbarService,
    @Inject('DialogService') protected dialog: any,
    private formBuilder: FormBuilder,
  ) {
    super($translate, authService, resource, state, snackbar, dialog);
  }

  ngOnInit() {
    super.ngOnInit();

    for (const data of this.additionalData) {
      this.additionalForms.push(BlockHelper.getForm(this.formBuilder, this.properties, data));
    }
  }

  handleBlocks(): void {
    this.controls = BlockHelper.getControls(this.properties);
    this.forms = [];

    for (const block of this.blocks) {
      this.forms.push(BlockHelper.getForm(this.formBuilder, this.properties, block));
    }
  }

  addForm(): void {
    this.newForms.push(BlockHelper.getForm(this.formBuilder, this.properties, null));
  }

  save(): void {
    const forms: FormGroup[] = [...this.forms];
    const newForms: FormGroup[] = [...this.newForms];
    const observables$ = this.getObservables(forms);
    const newObservables$ = this.getObservables(newForms, this.forms.length);

    if (!observables$.length && newObservables$.length) {
      this.bulkOneByOne(newObservables$);
      return;
    }

    forkJoin(observables$).subscribe(() => {
      if (newObservables$.length) {
        this.bulkOneByOne(newObservables$);
      } else {
        this.validate();
      }
    });
  }

  bulkOneByOne(observables$: Observable<any>[]): void {
    if (!observables$.length) {
      return;
    }

    const observable = observables$.shift();

    observable.subscribe(() => {
      if (observables$.length) {
        this.bulkOneByOne(observables$);
      } else {
        this.validate();
      }
    });
  }

  validate() {
    this.snackbar.validate('SAVED');
    this.newForms = [];
    this.fetch();
  }

  getObservables(forms: FormGroup[], startIndex: number = 0): Observable<any>[] {
    forms = forms.filter((form: any) => {
      return !BlockHelper.isFormEmpty(form, this.properties);
    });

    return forms.map((form: any, index: number) => {
      const formData = BlockHelper.getFormData(
        form,
        this.properties,
        this.locale,
        this.businessObject,
        this.blocks[startIndex + index],
        this.product,
        this.superProduct,
        this.commercialOperation,
        this.room
      );

      return (<BlockResource>this.resource).writeBlock(formData, this.businessObject);
    });
  }

  delete(block: any) {
    this.dialog.confirm(this.translate('PAGE.CONTENT_WEBSITE.CONFIRM_DELETE'))
      .then(() => {
        (<BlockResource>this.resource).deleteBlock(this.businessObject, block.id)
          .pipe(takeUntil(this.destroyed$))
          .subscribe(() => {
            this.snackbar.validate('SAVED');
            this.fetch();
          })
        ;
      })
    ;
  }
}
