import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericFormModule, GenericModule } from '@components/generic';
import { ReactiveFormsModule } from '@angular/forms';
import { UploadModule } from '@progress/kendo-angular-upload';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { TabsModule } from 'ngx-bootstrap';
import { DirectivesModule } from '@directives/directives.module';
import { PipesModule } from '../../pipes/pipes.module';
import { ShippingPriceListComponent, ShippingPriceResultListComponent } from '@components/shipping-price/list';
import { ShippingPriceResource } from '@components/shipping-price/shipping-price.resource';
import { SwitchModule } from '@progress/kendo-angular-inputs';
import { ShippingPriceFormComponent } from '@components/shipping-price/form';

@NgModule({
  declarations: [
    ShippingPriceListComponent,
    ShippingPriceResultListComponent,
    ShippingPriceFormComponent,
  ],
  entryComponents: [
    ShippingPriceListComponent,
    ShippingPriceResultListComponent,
    ShippingPriceFormComponent,
  ],
  imports: [
    CommonModule,
    GenericModule,
    ReactiveFormsModule,
    GenericFormModule,
    UploadModule,
    DialogsModule,
    TabsModule,
    DirectivesModule,
    PipesModule,
    SwitchModule,
  ],
  providers: [
    ShippingPriceResource,
  ]
})
export class ShippingPriceModule {}
