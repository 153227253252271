import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractComponent } from '@components/generic/abstract.component';
import { ICrumb } from '@components/breadcrumb/breadcrumb.interface';
import { CategoryResource } from '@resources/category.resource';
import { CMSResource } from '@resources/cms.resource';

@Component({
  selector: 'app-breadcrumb',
  template: require('./breadcrumb.component.html'),
})
export class BreadcrumbComponent extends AbstractComponent implements OnInit {

  @Output() public onChange: EventEmitter<any> = new EventEmitter();

  @Input() public breadcrumb: ICrumb[] = [];

  public universes: any[];
  public shelves: any[];
  public contents: any[];

  private static getDropdownElements(elements: any) {
    return elements
      .map((element: any) => {
        return {
          label: element.name,
          value: element.id.toString(),
        };
      })
      .sort((a: any, b: any) => {
        return a.label.localeCompare(b.label);
      })
    ;
  }

  constructor(
    authService: AuthService,
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    @Inject('StateService') state: ng.ui.IStateService,
    private categoryResource: CategoryResource,
    private cmsResource: CMSResource,
  ) {
    super($translate, authService, null, state);
  }

  ngOnInit(): void {
    this.categoryResource.getAllNames({ level: 1, locale: this.currentLocale })
      .subscribe((categories) => this.universes = BreadcrumbComponent.getDropdownElements(categories));

    this.categoryResource.getAllNames({ level: 2, locale: this.currentLocale })
      .subscribe((categories) => this.shelves = BreadcrumbComponent.getDropdownElements(categories));

    this.cmsResource.findByName(null, this.currentLocale, { blocking: false })
      .subscribe((contents) => this.contents = BreadcrumbComponent.getDropdownElements(contents));
  }

  public addItem(): void {
    this.breadcrumb.push({ type: null, value: null });
    this.change();
  }

  public updateItem(event: ICrumb, position: any): void {
    this.breadcrumb[position] = event;
    this.change();
  }

  public deleteItem(position: any): void {
    this.breadcrumb.splice(position, 1);
    this.change();
  }

  private change(): void {
    this.onChange.emit(this.breadcrumb);
  }
}
