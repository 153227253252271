import { Component, Inject, Input } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource, BlockResource } from '@resources';
import { SnackbarService } from '@components/snackbar/snackbar.service';
import { BlockListFormComponent } from '@components/generic/blocks/block-list-form.component';
import { BlockHelper } from '@components/generic/blocks/block-helper';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-block-list-form-table',
  template: require('./block-list-form-table.component.html'),
  styles: [require('./block.scss')],
  providers: [{ provide: AbstractResource, useClass: BlockResource }]
})
export class BlockListFormTableComponent extends BlockListFormComponent {
  @Input() public filters: any = {};
  @Input() public orders: any = {};

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    protected snackbar: SnackbarService,
    @Inject('DialogService') protected dialog: any,
  ) {
    super($translate, authService, resource, state, snackbar, dialog);
  }

  fetchBlocks(filters: any, event?: any) {
    super.fetchBlocks({ ...this.filters, ...filters, ...this.orders }, event);
  }

  handleBlocks(): void {
    this.setTable();
  }

  setTable(): void {
    this.blocks.forEach((block: any): void => {
      block.tableData = this.blockTitleFields.map((field: string): any => {
        const blockField = BlockHelper.fields[field];
        const value: string = blockField ? block[blockField[0].name] : block[field];
        const type: string = blockField ? blockField[0].type : null;

        if (!type) {
          return { type: 'text', value: value || field };
        }

        return { type: type, value: value };
      });
    });
  }

  delete(block: any): void {
    this.dialog.confirm(this.translate('PAGE.CONTENT_WEBSITE.CONFIRM_DELETE'))
      .then((): void => {
        (<BlockResource>this.resource).deleteBlock(this.businessObject, block.id)
          .pipe(takeUntil(this.destroyed$))
          .subscribe(() => {
            this.snackbar.validate('SAVED');
            this.fetch();
          })
        ;
      })
    ;
  }
}
