import { IHydraMember } from '@interfaces/hydra-resource.interface';
import { EavType } from '@components/eav-attribute/models/eav-type.enum';
import { EavAttributeOptionModel } from '@components/eav-attribute/models/eav-attribute-option.model';

export class GlossaryModel {
  '@id': string;
  id: number;
  fromLang: string;
  fromText: string;
  toLang: string;
  toText: string;

  constructor(glossary: IHydraMember) {
    this['@id'] = glossary['@id'];
    this.id = glossary.id;
    this.fromLang = glossary.fromLang;
    this.fromText = glossary.fromText;
    this.toLang = glossary.toLang;
    this.toText = glossary.toText;
  }
}
