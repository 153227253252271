import { AbstractResource } from '../../resources/abstract.resource';
import { Injectable } from '@angular/core';
import { WarehousesModel } from './models/warehouses.model';
import { SessionHelper } from '../../helpers/session.helper';
import { Resource } from '../../decorators/Resource';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

@Injectable()
@Resource({
  routeName: 'warehouses',
  entryPoint: '/v2/warehouses',
  translationKey: 'WAREHOUSES',
  model: WarehousesModel,
  cGetDefaultFilters: () => ({ 'carriers.country.code[]': SessionHelper.getCountries().map(country => country.code) }),
  listColumns: [
    { field: 'name', translationKey: 'WAREHOUSE', type: 'string' },
    { field: 'code', translationKey: 'CODE', type: 'string' },
    { field: 'countriesCodes', translationKey: 'COUNTRIES', type: 'string' },
  ],
  createAvailable: false,
  deleteAvailable: false,
  isHydra: true,
  goToEditField: 'id'
})
export class WarehousesResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  public createBox(body: any): Observable<object> {
    return super.create(body, { entryPoint: '/v2/boxes', isHydra: true });
  }

  public updateBox(id: string, body: any): Observable<object> {
    return super.update(null, body, { entryPoint: '/v2/boxes/' + id, isHydra: true });
  }

  public deleteBox(id: string): Observable<object> {
    return super.remove(null, { entryPoint: '/v2/boxes/' + id, isHydra: true });
  }

  public getCarrierCapacitiesStats(id: string): Observable<object> {
    return super.cGet({
      pagination: false
    }, {
      entryPoint: `${this.entryPoint}/${id}/carrier_capacities/stats`,
      isHydra: true,
      dontUseModel: true,
      blocking: false
    });
  }
}
