import { SIZE_MEDIUM, FORM_GROUP_SIZES } from '@constants';
import { IFormFieldBase } from '../interfaces/form-field-base.interface';

/**
 * AbstractFormFieldBase is the shape of a form field, its a fundamental field class.
 *
 * @info: The <T> generic type is used to type the value of the field, if this class is instancied like new FiltersBase<string>
 * so the type of the value will be string
 *
 * @example:
 *
 * <div [ngSwitch]="field.fieldType">
 *     <label [attr.for]="field.fieldName">{{field.label}}</label>
 *
 *     <input *ngSwitchCase="'text'"
 *            [formControlName]="field.fieldName"
 *            [id]="field.fieldName"
 *            [type]="field.type" //text|email...>
 *
 *     ...
 * </div>
 */
export abstract class AbstractFormFieldBase<T> implements IFormFieldBase<T> {
  /**
   * Label value for the field.
   */
  public label: string;

  /**
   * Used for the formControlName value, id value for the form field tag + the [attr.for] value for the label tag
   */
  public fieldName: string;

  /**
   * Value of the field, use as value for the formControls, take the type of the class.
   */
  public value: T;

  /**
   * Used to render the field in the template according to this type (input, select ...)
   */
  public fieldType: string;

  /**
   * Used for the field validator.
   */
  public required: boolean;

  /**
   * Position of the field in the template.
   */
  public order: number;

  /**
   * Size of the form group in the template.
   */
  public formGroupSize: string;

  /**
   * Action to get done when the value changes
   */
  public valueChangedAction: (newValue: any) => any;

  /**
   * Determine if the field must be displayed or not.
   */
  public hidden: boolean;

  /**
   * Add a placeholder
   */
  public placeholder?: string;

  /**
   * Is field is in readonly?
   */
  public readonly?: boolean;

  /**
   * Should the field must be displayed?
   */
  public shouldBeDisplayed?: boolean;

  constructor(options: {
    label?: string,
    fieldName?: string,
    value?: T,
    fieldType?: string,
    required?: boolean,
    order?: number,
    formGroupSize?: string,
    valueChangedAction?:  (newValue: any) => void,
    hidden?: boolean,
    placeholder?: string,
    readonly?: boolean,
    shouldBeDisplayed?: boolean;
  } = {}) {
    this.label = options.label;
    this.fieldName = options.fieldName;
    this.value = options.value;
    this.fieldType = options.fieldType;
    this.required = !!options.required;
    this.order = undefined === options.order ? 1 : options.order;
    this.formGroupSize = undefined === options.formGroupSize ? SIZE_MEDIUM : this.getFormGroupSize(options.formGroupSize);
    this.valueChangedAction = options.valueChangedAction;
    this.hidden = undefined !== options.hidden ? options.hidden : false;
    this.readonly = undefined !== options.readonly ? options.readonly : false;
    this.placeholder = options.placeholder ? options.placeholder : '';
    this.shouldBeDisplayed = undefined !== options.shouldBeDisplayed ? options.shouldBeDisplayed : true;
  }

  /**
   * Gets form group size.
   *
   * @param {string} size
   *
   * @returns {string}
   */
  private getFormGroupSize(size: string): string {
    return FORM_GROUP_SIZES.includes(size) ? size : SIZE_MEDIUM;
  }
}
