import { Component, Inject, OnInit } from '@angular/core';
import { IRootScopeService } from 'angular';
import { AuthService } from '@services/auth.service';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';

@Component({
  selector: 'app-breadcrumbs',
  template: require('./breadcrumbs.component.html')
})
export class BreadcrumbsComponent extends AbstractPageComponent implements OnInit {
  breadcrumbs: any[];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    @Inject('RootscopeService') rootScope: IRootScopeService,
  ) {
    super($translate, authService, null, state);

    rootScope.$on('$stateChangeSuccess', () => this.updateBreadcrumbs());
  }

  ngOnInit(): void {
    this.updateBreadcrumbs();
  }

  public updateBreadcrumbs() {
    this.breadcrumbs = [];

    let currentState: any = this.state.$current;
    let itemState: any;
    while (currentState.parent) {
      itemState = currentState.self;

      if (itemState && itemState.breadcrumb) {
        this.breadcrumbs.unshift(itemState.breadcrumb);
      }

      currentState = currentState.parent;
    }
  }
}
