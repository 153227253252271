import { Component, Input } from '@angular/core';
import { PaymentIconsResource } from '@components/payment-carrier-icons/payment-icons';
import { AbstractResource } from '@resources/abstract.resource';

@Component({
  selector: 'app-payment-icons',
  template: require('./payment-icons.component.html'),
  providers: [
    { provide: AbstractResource, useClass: PaymentIconsResource }
  ],
})
export class PaymentIconsComponent {
  @Input() websiteConfiguration: any;
}
