import { SessionHelper } from '@helpers/session.helper';
import { IWebsiteCategoryList } from '@components/website-category/models/website-category-list.interface';
import { CountryHelper } from '@helpers';

export class WebsiteCategoryListModel implements IWebsiteCategoryList {
  id: string;
  name: string;
  activeTab?: boolean;
  active?: boolean;

  constructor(category: IWebsiteCategoryList = {
    id: '',
    name: '',
    activeTab: false,
    active: false,
  }) {
    this.id = category.id;
    this.activeTab = category.activeTab;
    this.active = category.active;

    let translation = category.translations[SessionHelper.getLocale()] || null;

    if (null === translation) {
      const locales = CountryHelper.getLocales(SessionHelper.getCountries());

      for (const key in locales) {
        if (category.translations[locales[key]]) {
          translation = category.translations[locales[key]];
          break;
        }
      }
    }

    this.name = translation ? translation.name : '';
  }
}
