import { Component, Inject, OnInit } from '@angular/core';
import { AbstractFormComponent } from '@components/generic/Form/abstract-form.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IFormViolation } from '@interfaces/IFormViolation';
import { AuthService } from '@services/auth.service';
import { SnackbarService } from '@components/snackbar';
import { IUsers, UsersModel } from '@components/users/models';
import { UsersResource } from '@components/users/users.resource';
import { SessionHelper } from '@helpers/session.helper';
import { ICountry } from '@interfaces/ICountry';
import { RoleHierarchyResource } from '@resources/role-hierarchy.resource';
import { CountryHelper } from '@helpers/CountryHelper';
import { DASHBOARD_REVENUES } from '@constants/route.constants';
import * as _ from 'lodash';
import { ILogisticRow } from '@components/product/interfaces';
import {IWarehouses} from '@components/warehouses/models';

@Component({
  selector: 'app-users',
  template: require('./users.component.html'),
})

export class UsersComponent extends AbstractFormComponent implements OnInit {

  public inCreation: boolean;
  public model: IUsers;
  public form: FormGroup;
  public violations: IFormViolation[] | any = [];
  public locales: any;
  public countries: ICountry[];
  public roles: any;
  public warehouses: any;
  public passwordInEdition: boolean = false;
  public emailInEdition: boolean = false;
  public loggedUser: IUsers;
  public usurpedUser: IUsers;

  readonly FAKE_PASSWORD = '********';

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    @Inject('DialogService') private dialog: any,
    public resource: UsersResource,
    private snackbar: SnackbarService,
    private rolesHierarchyResource: RoleHierarchyResource,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    this.loggedUser = SessionHelper.getUser();
    this.usurpedUser = SessionHelper.getUsurpedUser();
    this.inCreation = !this.state.params.id;
    this.locales = ['fr_FR', 'en_GB'];
    this.countries = SessionHelper.getCountries();
    this.warehouses = SessionHelper.getAllWarehouses();

    if (!this.inCreation) {
      this.fetch();
      return;
    }
    this.model = new UsersModel();
    this.roles = SessionHelper.getCurrentUserRoles()
      .filter((value, index, self) => self.indexOf(value) === index)
      .map(role => ({ label: this.translate(`DATA.ROLES.${role}`), value: role}))
    ;
    this.roles = this.sortByLabel(this.roles);
    this.createForm();
  }

  public sortByLabel(details: string[]): string[] {
    return details.sort(function (a: any, b: any) {
      const textA = a.label.toUpperCase();
      const textB = b.label.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
  }

  public createForm(): void {
    const countries = this.model.countries.map(country => country.id);
    const warehouses = this.model.warehouses.map(warehouse => warehouse.id);

    this.form = new FormGroup({
      roles: new FormControl(Object.values(this.model.roles), Validators.required),
      countries: new FormControl(countries, Validators.required),
      warehouses: new FormControl(warehouses, Validators.required),
      locale: new FormControl(this.model.locale, Validators.required),
    });

    if (this.inCreation) {
      this.form.addControl('email', new FormControl(this.model.email, Validators.required));
      this.form.addControl('plainPassword', new FormControl(this.model.plainPassword, Validators.required));
    }
  }

  public fetch(): void {
    this.resource.get(this.state.params.id)
      .takeUntil(this.destroyed$)
      .subscribe((response: IUsers) => {
        this.model = response;
        this.roles = SessionHelper.getCurrentUserRoles().concat(this.model.roles)
          .filter((value, index, self) => self.indexOf(value) === index)
          .map(role => ({ label: this.translate(`DATA.ROLES.${role}`), value: role}))
        ;
        this.roles = this.sortByLabel(this.roles);
        this.createForm();
      });
  }

  public editInput(inputName: string, inEdition: boolean): void {
    inEdition
    ? this.form.addControl(inputName, new FormControl(this.model[inputName], Validators.required))
    : this.form.removeControl(inputName);

    switch (inputName) {
      case 'plainPassword':
        this.passwordInEdition = inEdition;
        break;
      case 'email':
        this.emailInEdition = inEdition;
        break;
    }
  }

  public submit(returnToList: boolean): void {
    while (this.violations.length) { this.violations.pop(); }
    if (!this.form.dirty || !this.form.valid) {
      let errorMessage = 'ALERTS.NO_CHANGE.FORM';

      if (!this.form.valid) {
        errorMessage = 'ALERTS.ERROR.FORM';
      }

      this.snackbar.warn(this.translate(errorMessage));
      return;
    }

    const body = this.prepareQuery(this.form);
    !this.inCreation
      ? this.update(body, returnToList)
      : this.save(body, returnToList);
  }

  public save(body: IUsers, returnToList: boolean): void {
    this.resource.create(body)
      .takeUntil(this.destroyed$)
      .subscribe(
        (response: IUsers) => {
          returnToList
            ? this.state.go(`${this.resource.routeName}.list`)
            : this.state.go(
            `${this.resource.routeName}.edit`,
            { id: response.id },
            { reload: true }
            );
        },
        (reject: any) => this.violations = this.formatViolations(reject),
      );
  }

  public update(body: IUsers, returnToList: boolean): void {
    this.resource.partialUpdate(this.state.params.id, body)
      .takeUntil(this.destroyed$)
      .subscribe(
        (response: IUsers) => {
          returnToList
            ? this.state.go(`${this.resource.routeName}.list`)
            : this.state.go(
            `${this.resource.routeName}.edit`,
            { id: response.id },
            { reload: true }
            );
        },
        (reject: any) => this.violations = this.formatViolations(reject),
      );
  }

  private prepareQuery(usersForm: FormGroup): IUsers {
    const body: IUsers = {
      email: usersForm.get('email') ? usersForm.get('email').value : this.model.email,
      roles: usersForm.get('roles').value,
      warehouses: usersForm.get('warehouses').value,
      locale: usersForm.get('locale').value,
      countries: usersForm.get('countries').value,
      username: usersForm.get('email') ? usersForm.get('email').value : this.model.username,
    };
    if (usersForm.get('plainPassword')) {
      body.plainPassword = usersForm.get('plainPassword').value;
    }

    return body;
  }

  public cancel(): void {
    this.dialog.confirm(this.translate('PAGE.USERS.CONFIRM.BACK_TO_LIST'))
      .then(() => this.state.go(`${this.resource.routeName}.list`));
  }

  private formatViolations(reject: any): IFormViolation[] {
    if (!reject.hasOwnProperty('violations')) {
      return;
    }

    return reject.violations.map((violation: IFormViolation): IFormViolation => {
      return {
        propertyPath: violation.propertyPath,
        message: violation.message
      };
    });
  }

  public delete(id: string): void {
    if (!this.isAdmin()) {
      return;
    }
    this.dialog.confirm(this.translate(`PAGE.USERS.CONFIRM.DELETE`))
      .then(() => {
        this.resource.remove(id)
          .takeUntil(this.destroyed$)
          .subscribe(() => this.actions.list.go(null, {reload: true, notify: true}));
      });
  }

  public usurp(user: any): void {
    user.countries = user.countries.map((country: any) => CountryHelper.getCountryByCountryCode(country.code));
    SessionHelper.setUsurpedUser(user);
    SessionHelper.setUsurpedUserDataInStorage(user);
    this.state.go(DASHBOARD_REVENUES, {}, {reload: true});
  }

  public canBeUsurp(): boolean {
    const currentCountryCodes: string[] = SessionHelper.getCountries().map((country: ICountry) => country.code);
    const userCountryCodes: string[] = this.model.countries.map((country: ICountry) => country.code);
    const countryCodes: string[] = _.intersection(currentCountryCodes, userCountryCodes);

    return _.size(countryCodes) > 0  && this.model.id !== this.loggedUser.id && !this.usurpedUser;
  }
}
