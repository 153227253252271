import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services';
import { AbstractResource } from '@resources/abstract.resource';
import { ShowDetailsInterface } from '@components/generic/List/show-details';
import { IListColumn } from '@interfaces/IListColumn';
import { GenericListNotifierService } from '@services/generic-list-notifier.service';
import { takeUntil } from 'rxjs/operators';
import { SnackbarService } from '@components/snackbar';
import { Observable } from 'rxjs/Observable';

@Component({
  selector: '[app-generic-row]',
  template: require('./generic-row.component.html'),
  styles: [require('./generic-row.component.scss')],
})
export class GenericRowComponent extends AbstractComponent {

  public hasMassiveActions: boolean;
  public showDetailsComponent: ShowDetailsInterface;
  public columnList: IListColumn[] = [];

  @Input() public row: any;

  @Output() public onShowDetails: EventEmitter<any> = new EventEmitter();
  @Output() public onCustomDelete: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    @Inject('DialogService') private dialog: any,
    private snackbar: SnackbarService,
    private genericListNotifierService: GenericListNotifierService,
  ) {
    super($translate, authService, resource, state);

    this.hasMassiveActions = this.resource.hasMassiveActions;
    this.showDetailsComponent = this.resource.showDetailsComponent;
    this.columnList = this.resource.getDefaultListColumns() || this.resource.listColumns;
  }

  /**
   * Change view to edition view.
   */
  goToEdit(e: KeyboardEvent): void {
    const editionState = this.resource.editionState || this.resource.updateRouteName || `${this.resource.routeName}.edit`;
    const goToEditField = this.resource.goToEditField ? this.row[this.resource.goToEditField] : this.row.id;
    const goToEditKey = this.resource.goToEditKey ? this.resource.goToEditKey : 'id';

    if (true === e.ctrlKey) {
      window.open(
        this.state.href(
          editionState,
          { [goToEditKey]: goToEditField },
          { absolute: true }
        ),
        '_blank'
      );
    } else {
      this.state.go(
        editionState,
        { [goToEditKey]: goToEditField }
      );
    }
  }

  /**
   * Sends to another page by clicking a cell in the row
   *
   * @param {*} row
   * @param {*} column
   * @memberof GenericRowComponent
   */
  public link(row: any, column: any, e: KeyboardEvent) {
    if (true === e.ctrlKey || column.linkable.newTab) {
      window.open(
        this.state.href(column.linkable.state, { [column.linkable.toParams]: row[column.linkable.fromParams] }, { absolute: true }),
        '_blank'
      );
    } else {
      this.state.go(column.linkable.state, { [column.linkable.toParams]: row[column.linkable.fromParams] });
    }
  }

  /**
   * Delete item from list. If we have a custom delete operation specified with the `customDeleteAvailable` from resource,
   * we have to emit an `onCustomDelete` event.
   */
  public delete(item: any): void {
    this.dialog.confirm(this.$translate.instant(`PAGE.${this.resource.translationKey}.CONFIRM.DELETE`))
      .then(() => {
        if (this.resource.customDeleteAvailable) {
          this.onCustomDelete.emit(item);

          return;
        }

        this.resource.remove(item.id ? item.id : item)
          .subscribe(() => this.state.go(`${this.resource.routeName}.list`, null, { reload: true, notify: true }));
      });
  }

  /**
   * Download a file.
   */
  downloadFile(row: any): void {
    this.resource.downloadFile(row.directory, row.filename).subscribe((response: any) => {
      const reader = new FileReader();

      reader.readAsDataURL(response);

      reader.addEventListener('loadend', () => {
        opener = window.document.createElement('a');
        opener.href = reader.result;
        opener.download = row.filename;

        document.body.appendChild(opener);
        opener.click();

        document.body.removeChild(opener);
      });
    });
  }

  /**
   * Archive a row.
   */
  public archive(item: any): void {
    this.dialog.confirm(
      this.$translate.instant(item.archived ? 'ALERTS.CONFIRM.UNARCHIVE' : 'ALERTS.CONFIRM.ARCHIVE')
    )
      .then(() => {
        let callback: Observable<object>;
        if (typeof this.resource.customArchive === 'function') {
          callback = this.resource.customArchive(item);
        } else {
          callback = this.resource.update(item.id, { archived: !item.archived });
        }
        callback
          .pipe(
            takeUntil(this.destroyed$)
          )
          .subscribe(() => {
            this.snackbar.validate(this.translate('ALERTS.DATA.UPDATE'));
            this.state.go(`${this.resource.routeName}.list`, null, { reload: true });
          })
          ;
      })
      ;
  }

  private showDetail() {
    this.onShowDetails.emit();
  }

  private switchValueChange(item: any, status: boolean): void {
    this.genericListNotifierService.notifySwitchValueChange(item, status);
  }

  public getToolTipLabel(column: any, argument?: any) {
    if (column && column.rowToolTipLabelField) {
      if (argument) {
        return this.row[column.rowToolTipLabelField](argument);
      } else {
        return this.row[column.rowToolTipLabelField];
      }
    }
  }
}
