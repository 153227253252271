import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { AbstractComponent } from '@components/generic/abstract.component';
import { IService } from '@components/service/model/service.interface';

@Component({
  selector: 'app-service-result-list',
  template: require('./service-result-list.component.html'),
})
export class ServiceResultListComponent extends AbstractComponent {

  private _items: IService[];

  set items(items: any) {
    this._items = items;
  }

  get items(): any {
    return this._items;
  }

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }
}
