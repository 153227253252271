import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ReverseResource } from '@components/reverse/resources';
import { ReverseProductPackage } from '@components/reverse/models/ReverseProductPackage';
import { SessionHelper } from '@helpers';
import { IExportOptions } from '@interfaces';
import { Resource } from '@decorators/Resource';
import { IWarehouses } from '@components/warehouses/models';
import { IReverse } from '../interfaces';

const entryPoint = '/v2/reverse_product_packages';
const exportOptions: IExportOptions[] = [
  {
    entryPoint: `${entryPoint}.csv?pagination=false`,
    responseType: 'text',
    type: 'text/plain',
    filename: 'export.csv',
    translationKey: 'BUTTON.EXPORT.CSV',
    filters: true
  },
  {
    entryPoint: `/exports/${SessionHelper.getCountry() && SessionHelper.getCountry().code}/reverse/product/complete`,
    responseType: 'text',
    type: 'text/plain',
    filename: 'export-sage.csv',
    translationKey: 'EXPORT.SAGE',
    filters: false
  },
];

@Injectable()
@Resource({
  entryPoint,
  model: ReverseProductPackage,
  isHydra: true,
  exportOptions: [],
  listColumns: [
    { field: 'reverseId', translationKey: 'CASE_ID', type: 'id', sortable: true },
    { field: 'skuId', translationKey: 'SKU_ID', type: 'id' },
    { field: 'id', translationKey: 'PRODUCT_PACKAGE_ID', type: 'id' },
    {
      field: 'orderLinkTitle', translationKey: 'ORDER_ID', type: 'string', linkable: {
        state: SessionHelper.isCurrentUserReverseExternal() ? 'order_manager_light.list' : 'order_manager.edit',
        fromParams: 'orderId',
        toParams: SessionHelper.isCurrentUserReverseExternal() ? 'orderId[]' : 'id',
        newTab: true
      }
    },
    { field: 'receptionDate', translationKey: 'DATE', sortable: true },
    { field: 'customer', translationKey: 'CUSTOMER' },
    { field: 'carrierCode', translationKey: 'CARRIER' },
    { field: 'packageIdentification', translationKey: 'SKU_PACKAGE_NUMBER' },
    { field: 'receiveStatusLabel', translationKey: 'STATUS_RECEIVE' },
    { field: 'archived', translationKey: 'ARCHIVED', type: 'boolean' },
    { field: 'reconditionedStatusLabel', translationKey: 'RECONDITIONED_STATUS' },
    { field: 'country', translationKey: 'COUNTRY', displayRawHtml: true },
  ],
  buttonActionMultipleAvailable: true,
  createAvailable: true,
  updateAvailable: true,
  deleteAvailable: false,
  goToEditField: 'reverseId',
  keepFiltersInStorage: true,
  archiveAvailable: true,
})
export class ReversePackageResource extends ReverseResource {

  public cGet(params: any = {}, options: any = {}): Observable<object> {
    params['receptionDate[after]'] = params.startDate;
    params['receptionDate[before]'] = params.endDate;
    params['order[receptionDate]'] = params.sort_receptionDate;

    if (SessionHelper.useLinkedWarehousesOnly()) {
      if (SessionHelper.getLinkedWarehouses().length) {
        params['reverseProduct.reverse.warehouse.code[]'] = SessionHelper.getLinkedWarehouses().map((warehouse: IWarehouses) => warehouse.code);
      } else {
        params['reverseProduct.reverse.warehouse.code'] = false;
      }
    }

    delete params.date;
    delete params.taskFilter;

    return super.cGet(params, options);
  }

  /**
   * Exports files
   *
   * @param {object} [params]
   * @param {*} [options={}]
   * @returns {Observable<void>}
   * @memberof AbstractResource
   */
  public exportFile(params: any = {}, options: any = {}): Observable<void> {
    if (undefined !== params.startDate && undefined !== params.endDate) {
      params['receptionDate[after]'] = params.startDate;
      params['receptionDate[before]'] = params.endDate;
    }

    return super.exportFile(params, options);
  }

  public setExportOptions() {
    for (const exportOption of exportOptions) {
      if (-1 === this.exportOptions.indexOf(exportOption)) {
        this.exportOptions.push(exportOption);
      }
    }
  }

  public customArchive(item: any) {
    return this.partialUpdate(
      item.reverseId,
      { archived: !item.archived },
      { entryPoint: `/reverses/${item.reverseId}` }) as Observable<IReverse>;
  }
}
