import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { SessionHelper } from '@helpers';
import { AuthService } from '@services';
import { CarrierGroupResource } from '@resources/carrier-group.resource';
import { ICarrierCalculator } from '../../../components/carrier/interfaces/carrier-calculator.interface';
import { IPackage } from '../../../models';
import { IByManualEntryData } from './forms/by-manual-entry-form.component';
import { IByExistingSkuData } from './forms/by-existing-sku-form.component';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';

@Component({
  selector: 'app-carrier-calculator',
  template: require('./carrier-calculator.component.html'),
  styles: [require('./carrier-calculator.component.scss')],
  encapsulation: ViewEncapsulation.None,
})
export class CarrierCalculatorComponent extends AbstractPageComponent {

  public isAverage: boolean = false;
  public isLocated: boolean = false;
  public previewCosts: {[keys: string]: any};
  public objectKeys: (o: {}) => string[] = Object.keys;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private fb: FormBuilder,
    private carrierGroupResource: CarrierGroupResource,
  ) {
    super($translate, authService, null, state);
  }

  public resetForm(): void {
    this.isAverage = false;
    this.isLocated = false;
  }

  private getEstimations(data: IByManualEntryData|IByExistingSkuData) {
    this.resetForm();

    this.carrierGroupResource.getCostEstimation(this.prepareQuery(data))
      .then((response: {results: {[keys: string]: any}}) => {
        this.previewCosts = response.results;

        if (0 === data.postcodes.length) {
          this.isAverage = true;

          return;
        }

        const previewCostsKeys = Object.keys(this.previewCosts);

        this.isLocated = true;

        // @todo: response will be refactored in another task from api so this will be update.
        if (1 < data.postcodes.length) {
          this.previewCosts = previewCostsKeys.map((postalCode: string) => {
            const carriers = this.previewCosts[postalCode].carriers;

            return {
              [postalCode]:
                Object.keys(carriers).map((carrier: string) => {
                  return {
                    carrierName: carrier,
                    cost: carriers[carrier].cost,
                    errors: this.getCarrierErrors(carriers[carrier].errors),
                    warnings: this.getCarrierErrors(carriers[carrier].warnings)
                  };
                })
            };
          });

          return;
        }

        this.previewCosts = [{
          [data.postcodes[0]]: previewCostsKeys.map((carrier: string) => {
            return {
              carrierName: carrier,
              cost: this.previewCosts[carrier].cost,
              errors: this.getCarrierErrors(this.previewCosts[carrier].errors),
              warnings: this.getCarrierErrors(this.previewCosts[carrier].warnings)
            };
          })
        }];
      })
    ;
  }

  private getCarrierErrors(errors: any): string[] {
    if (!errors) {
      return [];
    }

    return 'string' === typeof errors ?
        [errors] :
          Object.keys(errors)
            .map((error) => {
              return errors[error];
            })
        ;
  }

  private prepareQuery(data: IByManualEntryData|IByExistingSkuData): ICarrierCalculator {
    const query: any = {};

    if (data.hasOwnProperty('products') && data.hasOwnProperty('packages')) {
      query['products'] = (<IByExistingSkuData>data).products;
      query['packages'] = (<IByExistingSkuData>data).packages;
    } else {
      query['manualPackages'] = (<IByManualEntryData>data).manualPackages.map((packageItem: IPackage, index: number) => {
        return {
          ...packageItem,
          number: index + 1
        };
      });
    }

    return {
      ...query,
      postcodes: data.postcodes,
      country: `/api/v2/countries/${SessionHelper.getCountry().id}`
    };
  }
}
