import { IReverseProductPackage, IReverseProduct } from '@components/reverse';
import * as moment from 'moment';
import {DATE_SHORT_FORMAT} from '@constants';
import { IMasterProductVersion } from '@components/reverse/interfaces/IMasterProductVersion';

// @todo: see to init properties with empty values
// -> the parameters can be optional
export class ReverseProduct implements IReverseProduct {
  public id: number;
  public sku: string;
  public reason: string;
  public comment: string;
  public reverse: number;
  public reverseProductPackages: IReverseProductPackage[];
  public condition: string;
  public analysis: string;
  public diagnosis: string;
  public action: string;
  public repairable: boolean;
  public repairedAt: string;
  public reparationTime: Number;
  public masterProductVersion: IMasterProductVersion;

  constructor(
    reverseProduct: IReverseProduct,
    reverseProductPackages: IReverseProductPackage[],
    reverseId: number,
  ) {
    this.id = reverseProduct.id;
    this.sku = reverseProduct.sku;
    this.reason = reverseProduct.reason;
    this.comment = reverseProduct.comment;
    this.reverse = reverseId;
    this.reverseProductPackages = reverseProductPackages;
    this.condition = reverseProduct.condition;
    this.analysis = reverseProduct.analysis;
    this.diagnosis = reverseProduct.diagnosis;
    this.action = reverseProduct.action;
    this.repairable = reverseProduct.repairable;
    this.repairedAt = reverseProduct.repairedAt ? moment(reverseProduct.repairedAt).startOf('day').format(DATE_SHORT_FORMAT) : null;
    this.reparationTime = reverseProduct.reparationTime;
    this.masterProductVersion = reverseProduct.masterProductVersion;
  }
}
