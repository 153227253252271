import {Component, Inject, OnInit} from '@angular/core';
import {AbstractPageComponent} from '@components/generic/abstract-page.component';
import {AuthService} from '@services/auth.service';
import {AbstractResource} from '@resources';
import {AbstractFiltersFieldsService} from '@components/generic/Form/filters/abstract-filters-fields.service';
import {ProductResource} from '@components/product/product.resource';
import {IHydraResource} from '@interfaces/hydra-resource.interface';
import {BASE_URL} from '@constants';
import {SessionHelper} from '@helpers';
import {SparePartFiltersFormService} from './spare-part-filters-form.service';
import { IWarehouse } from '@components/export-logistics/forms';

@Component({
  selector: 'app-spare-part-list',
  template: require('./spare-part-list.component.html'),
  styles: [require('./spare-part-list.component.scss')],
  providers: [
    {provide: AbstractResource, useClass: ProductResource},
    {provide: AbstractFiltersFieldsService, useClass: SparePartFiltersFormService}
  ]
})

export class SparePartListComponent extends AbstractPageComponent implements OnInit {
  public items: any[];
  public totalItems: number;
  public isAllowToEditOrCreate: boolean = false;
  private skuFilters: any = {};
  public sparePartsByVintage: any[];
  public missingSpares: string[] = [];

  public imports: object[] = [
    {
      entryPoint: BASE_URL + '/api/v2/import',
      importButton: 'PAGE.SPARE_PART.LIST.IMPORT_PRODUCTS',
      businessObject: 'spare-part',
    },
    {
      entryPoint: BASE_URL + '/api/v2/import',
      importButton: 'PAGE.SPARE_PART.LIST.IMPORT_STOCK',
      businessObject: 'spare-part-stock',
    }
  ];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    public resource: AbstractResource,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    this.isAllowToEditOrCreate = this.isWalisoftAgent();
  }

  public fetch(filters?: any) {
    this.resource.getProductAndSpareParts(filters)
      .takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        this.missingSpares = [];
        this.sparePartsByVintage = response.items;

        const givenSkus: string[] = [];
        response.items.forEach((object: any) => {
          givenSkus.push(object.sku);
        });

        this.skuFilters.sku.forEach((sku: string) => {
          if (false === givenSkus.includes(sku)) {
            this.missingSpares.push(sku);
          }
        });
      });
  }

  public goToProductEdit(event: MouseEvent, id: string) {
    const state = 'spare-part.edit';

    if (event.ctrlKey) {
      window.open(this.state.href(state, { id }, { absolute: true }), '_blank');
      return;
    }
    this.state.go(state, { id });
  }

  public paginate(event: { page: number, byPage: number }): void {
    let filters = event;
    if (this.skuFilters) {
      filters = {...this.skuFilters, ...filters};
    }
    if (SessionHelper.useLinkedWarehousesOnly()) {
      const warehouses = SessionHelper.getLinkedWarehouses().map((warehouse: IWarehouse) => warehouse.code);
      filters = {...this.skuFilters, ...filters, ...{warehouses}};
    }
    this.fetch(filters);
  }

  public goToEditPage(sparePartId: any) {
    window.open(this.state.href('spare-part.edit', { id: sparePartId } , { absolute: true }), '_blank');
  }

  public isWalisoftAgent(): boolean {
    const currentRoles = SessionHelper.getCurrentUserRoles();
    return currentRoles.some((element) => element === 'ROLE_WALISOFT_AGENT');
  }

  public onFilterChange(event: { sku: string, idParent: string }) {
    let warehouses = null;
    if ((event.sku && event.sku.length > 0 ) || (event.idParent && event.idParent.length > 0)) {
      this.skuFilters = event;
      if (SessionHelper.useLinkedWarehousesOnly()) {
        warehouses = SessionHelper.getLinkedWarehouses().map((warehouse: IWarehouse) => warehouse.code);
      }
      this.fetch({masterProduct: event.sku, superProduct: event.idParent, warehouses: warehouses});
    }
  }
}
