import { IHydraMember } from '@interfaces/hydra-resource.interface';
import { ArrivalModel } from '@components/stock/container/arrival.model';

export class ContainerModel {
  '@id': string;
   id: string;
   reference?: string;
   containerNumber: string;
   sageIdentifier?: string;
   date?: Date;
   comment?: string;
   freightPrice?: number;
   arrivalPrice?: number;
   commercialEntity?: string;
   additionalPrice?: number;
   sum: number;
   arrivalsFreightPrice: number;
   arrivals: ArrivalModel[];

  constructor(container: IHydraMember) {
    this['@id'] = container['@id'];
    this.id = container.id;
    this.reference = container.reference;
    this.containerNumber = container.containerNumber;
    this.sageIdentifier = container.sageIdentifier;
    this.date = container.date;
    this.comment = container.comment;
    this.freightPrice = container.freightPrice;
    this.arrivalPrice = container.arrivalPrice;
    this.additionalPrice = container.additionalPrice;
    this.sum = container.sum;
    this.arrivalsFreightPrice = container.arrivalsFreightPrice;
    this.arrivals = container.arrivals.map((arrival: IHydraMember) => new ArrivalModel(arrival));
    this.commercialEntity = null;
  }
}
