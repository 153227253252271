import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SessionHelper } from '@helpers';
import { AuthService, IJwt } from '@services';
import { ICredentials, AuthResource } from '@components/login/resources/auth.resource';
import { AbstractComponent } from '@components/generic/abstract.component';
import { DASHBOARD_REVENUES } from '@constants/route.constants';
import { UserResource } from '@resources';
import { IUser } from '@models';
import { ICarrierGroup, ICountry, IMarketplace, IMarketplacesByCountry } from '@interfaces';
import { IWarehouses } from '@components/warehouses/models';
import { ITaskStatus } from '@components/generic/task-manager/interfaces';

@Component({
  selector: 'app-login',
  template: require('./login.component.html'),
})
export class LoginComponent extends AbstractComponent {
  public form: FormGroup;
  public loginRejectionMessage: string;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private fb: FormBuilder,
    private loginCheckResource: AuthResource,
    private userResource: UserResource,
    private sessionHelper: SessionHelper,
  ) {
    super($translate, authService, null, state);

    SessionHelper.clearStorage();

    this.form = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      rememberMe: true
    });
  }

  public login() {
    const {username, password}: ICredentials = this.form.value;
    const rememberUser: boolean = !!this.form.value.rememberMe;

    if (username && password) {
      this.loginCheckResource.create({username, password})
        .switchMap((jwt: IJwt) => {
          AuthService.setTokenInStorage(jwt, rememberUser);

          const decodedToken: any = this.authService.getDecodedToken();

          return this.userResource.get(decodedToken.user_id);
        })
        .switchMap((user: IUser) => {
          if (0 === user.countries.length) {
            throw new Error('A user must be linked to at least one country');
          }

          SessionHelper.setCurrentUser(user);
          SessionHelper.setCurrentUserDataInStorage(user);

          return this.sessionHelper.callRequiredDataForApplication();
        })
        .takeUntil(this.destroyed$)
        .subscribe((requiredData: [
          IMarketplace[],
          ICarrierGroup[],
          IWarehouses[],
          string[],
          string[],
          ITaskStatus[],
          ICountry[],
          ICountry[],
          IMarketplace[],
          ICarrierGroup[],
          IWarehouses[],
          string[],
          IWarehouses[]
        ]) => {
          this.sessionHelper.setRequiredDataInStorage(requiredData);
          SessionHelper.setMarketplacesByCountry(this.getMarketplacesByCountry());
          this.state.go(DASHBOARD_REVENUES);
        }, (reject) => {
          SessionHelper.clearStorage();
          this.loginRejectionMessage = reject.message;
        })
      ;
    }
  }

  private getMarketplacesByCountry(): IMarketplacesByCountry {
    const marketplacesByCountry: IMarketplacesByCountry = {};
    const allMarketplaces = SessionHelper.getAllMarketplaces();

    SessionHelper.getCountries().map((country: ICountry) => {
      marketplacesByCountry[country.code] = [];

      country.marketplaces.map((code: string) => {
        const marketplaceObject = allMarketplaces.find((marketplace: IMarketplace) => {
          return marketplace.code === code;
        });

        if (undefined !== marketplaceObject) {
          marketplacesByCountry[country.code].push(marketplaceObject);
        }
      });
    });

    return marketplacesByCountry;
  }
}
