import { Injectable } from '@angular/core';
import { BUSINESS_OBJECT_REVERSE_PRODUCT } from '@constants/business-object.constants';
import { REVERSES_LIST } from '@constants/route.constants';

@Injectable()
export class BusinessObjectPageListService {

  /**
   * Gets the state name according to business object.
   */
  public static getStateName(businessObject: string): string {
    switch (businessObject) {
      case BUSINESS_OBJECT_REVERSE_PRODUCT:
        return REVERSES_LIST;
      default:
        throw new Error('The business object is not valid');
    }
  }
}
