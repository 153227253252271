import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { Component, Inject } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { ManufacturerResource } from '@resources/manufacturer.resource';
import { AbstractResource } from '@resources/abstract.resource';

@Component({
  selector: 'app-manufacturer-list',
  template: require('./manufacturer-list.component.html'),
  styles: [require('./manufacturer-list.component.scss')],
  providers: [
    { provide: AbstractResource, useClass: ManufacturerResource },
  ],
})
export class ManufacturerListComponent extends AbstractPageComponent {

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }
}
