import { Component, Inject, OnInit } from '@angular/core';
import { CountryHelper } from '@helpers';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { CustomerDetailResource } from '../../customer-detail.resource';
import { SnackbarService } from '../../../snackbar';
import { Subscription } from 'rxjs/Subscription';
import { ICustomerDetail } from '@components/customer/interfaces';
import { AbstractFormFieldBase } from '@components/generic/Form/dynamic/fields/abstract-form-field-base.class';
import { ComboSearchField, DateField, TextField} from '@components/generic/Form/dynamic/fields';
import { CustomerDetailModel } from '@components/customer/models/customer-detail.model';
import { DATE_FULL_FORMAT, DATE_SHORT_FORMAT, SIZE_MAX } from '@constants';
import * as moment from 'moment';
import { AuthService, FormNotifierService } from '@services';

/**
 * Customer card layout.
 * Handle:
 *  - fetch a customer from api and serve it
 *  - serve editable form fields
 *  - update a customer then update the model
 */
@Component({
  selector: 'app-customer-detail',
  template: require('./customer-detail.component.html'),
  styles: [require('./customer-detail.component.scss')]
})
export class CustomerDetailComponent extends AbstractPageComponent implements OnInit {
  public readonly roles: string[] = ['ROLE_WALISOFT_AGENT', 'ROLE_WALISOFT_AGENT'];

  public customer: ICustomerDetail;
  public editableFields: AbstractFormFieldBase<any>[];
  public objectKeys: (o: {}) => string[] = Object.keys;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: CustomerDetailResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private formNotifier: FormNotifierService,
    private snackbar: SnackbarService,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit() {
    if (this.isGranted(this.roles)) {
      this.fetch();

      return;
    }

    this.snackbar.alert(this.translate('ALERTS.AUTHORIZATION_FAIL'));
  }

  private fetch() {
    const subscriber: Subscription = this.resource.get(this.state.params.id)
      .subscribe((response: ICustomerDetail) => {
        this.customer = response;
        this.editableFields = this.getFields();
      }, undefined, () => subscriber.unsubscribe())
    ;
  }

  public update(customer: ICustomerDetail): void {
    if (customer.birthdate) {
      customer.birthdate = moment(customer.birthdate).format(DATE_FULL_FORMAT);
    }

    const subscriber: Subscription = this.resource.update(this.state.params.id, customer)
      .subscribe((response: ICustomerDetail) => {
        this.snackbar.validate(this.translate('PAGE.CUSTOMER_CARD.DETAILS.SAVED'));

        this.customer = new CustomerDetailModel(response);
        this.editableFields = this.getFields();
        this.formNotifier.notifyFormSubmitted();
      }, (reject: any) => {
        if (400 === reject.status) {
          this.formNotifier.notifyFormHasErrors({reject});
        }
      }, () => subscriber.unsubscribe())
    ;
  }

  /**
   * Gets form fields list.
   */
  private getFields(): AbstractFormFieldBase<any>[] {
    const fields: AbstractFormFieldBase<any>[] = [

      new TextField({
        label: 'PAGE.CUSTOMER_CARD.DETAILS.FIELD.GENDER',
        fieldName: 'gender',
        value: this.customer.gender,
        formGroupSize: SIZE_MAX,
        order: 1
      }),

      new TextField({
        label: 'PAGE.CUSTOMER_CARD.DETAILS.FIELD.FIRSTNAME',
        fieldName: 'firstName',
        value: this.customer.firstName,
        formGroupSize: SIZE_MAX,
        order: 2
      }),

      new TextField({
        label: 'PAGE.CUSTOMER_CARD.DETAILS.FIELD.LASTNAME',
        fieldName: 'lastName',
        value: this.customer.lastName,
        formGroupSize: SIZE_MAX,
        order: 3
      }),

      new DateField({
        label: 'PAGE.CUSTOMER_CARD.DETAILS.FIELD.BIRTHDATE',
        dateRange: false,
        dateFormat: DATE_SHORT_FORMAT,
        fieldName: 'birthdate',
        value: this.customer.birthdate,
        formGroupSize: SIZE_MAX,
        order: 4
      }),

      new ComboSearchField({
        label: 'PAGE.CUSTOMER_CARD.DETAILS.FIELD.LOCALE',
        fieldName: 'locale',
        value: this.customer.locale,
        valuePrimitive: true,
        data: CountryHelper.getAllLocalesForApplication(),
        formGroupSize: SIZE_MAX,
        order: 5
      }),

      new TextField({
        label: 'PAGE.CUSTOMER_CARD.DETAILS.FIELD.EMAIL',
        fieldName: 'username',
        value: this.customer.username,
        formGroupSize: SIZE_MAX,
        order: 6
      }),
    ];

    return fields.sort((a, b) => a.order - b.order);
  }

  public resetAuthenticationFailures(): void {
    this.resource.reset(this.state.params.id)
      .takeUntil(this.destroyed$)
      .subscribe(() => {
        this.state.go('customers.detail.orders', { id: this.state.params.id }, { reload: true });
      })
    ;
  }

  updateContactMethod(contactMethod: string) {
    this.customer.contactMethod = contactMethod;
    this.update(this.customer);
  }
}
