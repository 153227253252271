import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ReverseCaseComponent,
  ReverseCaseGeneralComponent,
  ReverseCaseDetailComponent,
  ReverseCaseTasksComponent,
  ReverseCaseWorkshopComponent
} from './';

import { ReverseResource } from '@components/reverse/resources';
import { ReversePackageResource } from '@components/reverse/resources/reverse.package.resource';
import { GenericFormModule } from '@components/generic/generic-form.module';
import { GenericModule } from '@components/generic/generic.module';
import { TabsModule } from 'ngx-bootstrap';
import { UploadModule } from '@progress/kendo-angular-upload';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { SwitchModule } from '@progress/kendo-angular-inputs';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CarrierGroupResource } from '@resources';
import { ReverseFiltersFormService } from '@components/reverse/services/reverse-filters-form.service';
import { TaskManagerModule } from '@components';
import { ReverseListComponent } from '@components/reverse/list/reverse-list.component';
import { SkuSearchService } from '@services/sku-search.service';
import { ReverseFormService } from '@components/reverse/services';
import { ReverseProductResource } from '@components/reverse/resources/reverse.product.resource';
import {GrcReasonResource} from '@components/order/resources/grc-reason-resource.service';
import {PipesModule} from '../../pipes/pipes.module';

@NgModule({
  declarations: [
    ReverseListComponent,
    ReverseCaseComponent,
    ReverseCaseGeneralComponent,
    ReverseCaseDetailComponent,
    ReverseCaseTasksComponent,
    ReverseCaseWorkshopComponent,
  ],
  entryComponents: [
    ReverseListComponent,
    ReverseCaseComponent,
    ReverseCaseTasksComponent,
    ReverseCaseWorkshopComponent,
  ],
  imports: [
    CommonModule,
    GenericModule,
    BsDatepickerModule.forRoot(),
    DropDownsModule,
    FormsModule,
    GenericFormModule,
    ReactiveFormsModule,
    SwitchModule,
    TabsModule.forRoot(),
    UploadModule,
    TaskManagerModule,
  ],
  providers: [
    CarrierGroupResource,
    ReverseResource,
    ReverseProductResource,
    ReversePackageResource,
    ReverseFiltersFormService,
    SkuSearchService,
    ReverseFormService,
    GrcReasonResource
  ],
  exports: [
    ReverseCaseGeneralComponent,
    ReverseCaseDetailComponent
  ]
})
export class ReverseModule {}
