import { Component, Inject } from '@angular/core';
import { SnackbarService } from '@components/snackbar/snackbar.service';
import { CountryHelper, AttributeValueHelper, AttributeHelper, SessionHelper } from '@helpers';
import { AttributeResource, AttributeFamilyResource, AttributeValueResource } from '@resources';
import { AuthService } from '@services';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { forkJoin } from 'rxjs/observable/forkJoin';

@Component({
  selector: 'app-attribute-new',
  template: require('./attribute-new.component.html'),
  providers: [AttributeHelper, AttributeValueHelper]
})
export class AttributeNewComponent extends AbstractPageComponent {
  public formErrors: any;
  public actionName: string = 'new';
  public attribute: any = {
    translations: {},
    attributeValues: [],
    attributeFamilies: []
  };
  public attributeId: string;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AttributeResource,
    @Inject('StateService') state: ng.ui.IStateService,
    @Inject('DialogService') private dialog: any,
    private snackbar: SnackbarService,
    private attributeHelper: AttributeHelper,
    private attributeValueHelper: AttributeValueHelper,
    private attributeValueResource: AttributeValueResource,
    private attributeFamilyResource: AttributeFamilyResource,
  ) {
    super($translate, authService, resource, state);
  }

  /**
   * Saves attribute, new attribute values and create asociated attribute families.
   */
  public saveAttributeAction({ redirect, attribute, newAttributeValues = [] }: any) {
    this.dialog.confirm(this.translate('PAGE.ATTRIBUTE.CONFIRM.CREATE'))
      .then(() => {
        this.formErrors = {};
        delete attribute.attributeFamilies;
        this.attributeHelper.copyTranslationsFromLocale(attribute, SessionHelper.getLocale());

        this.resource.create(attribute)
          .switchMap((attributeResponse: any) => {
            this.attributeId = attributeResponse.id;
            const attributeFamilyObservable: any[] = [];

            newAttributeValues.forEach((newAttributeValue: any) => {
              const newAttributeFamily: any = {
                attribute: `/api/v2/attributes/${attributeResponse.id}`,
                translations: {}
              };

              CountryHelper.getAllLocalesForApplication().forEach((locale) => {
                newAttributeFamily.translations[locale] = {
                  value: newAttributeValue.translations[locale].value,
                  locale: locale
                };
              });

              newAttributeFamily.value = [].concat(newAttributeFamily.value);

              attributeFamilyObservable.push(this.attributeFamilyResource.create(newAttributeFamily));
            });

            return forkJoin(attributeFamilyObservable);
          })
          .switchMap((response:  any[]) => {
            const attributeValuesObservable: any[] = [];

            newAttributeValues.forEach((newAttributeValue: any, index: number) => {
                newAttributeValue.attributeFamily = response[index]['@id']
                                                      .substring(response[index]['@id']
                                                      .lastIndexOf('/') + 1, response[index]['@id'].length);

                this.attributeValueHelper.cleanObject(newAttributeValue);

                CountryHelper.getAllLocalesForApplication().forEach((locale) => {
                  delete newAttributeValue.translations[locale].id;
                });
                newAttributeValue.value = [].concat(newAttributeValue.value);
                attributeValuesObservable.push(this.attributeValueResource.create(newAttributeValue));
            });
            return forkJoin(attributeValuesObservable);
          })
          .subscribe(
            () => {
              this.snackbar.validate(this.translate('ALERTS.DATA.UPDATE'));

              if ('single' === redirect) {
                return this.actions.update.go({ id: this.attributeId }, { reload: true, notify: true });
              }
              return this.actions.list.go();
            },
            (errorData) => {
              if (errorData.status === 400) {
                this.formErrors = errorData.data.errors;
              }
            });
      });
  }
}
