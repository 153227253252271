import { Component, Input, forwardRef, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InlineEditComponent),
  multi: true
};

@Component({
  selector: 'app-inline-edit',
  template: require('./inline-edit.component.html'),
  providers: [VALUE_ACCESSOR],
})

export class InlineEditComponent implements ControlValueAccessor {

  @Output() onSave: EventEmitter<any> = new EventEmitter();
  private _value: any = '';

  @Input() public editing: boolean = false;
  @Input() public canBeEdit: boolean = true;
  @Input() public type: string = 'text';

  public onChange: any = Function.prototype;
  public onTouched: any = Function.prototype;

  get value(): any {
    return this._value;
  }

  set value(v: any) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
    }
  }

  writeValue(value: any) {
    this._value = value;
  }

  public registerOnChange(fn: (_: any) => {}): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  onBlur($event: Event) {
    this.editing = false;
    this.onSave.emit({value: this.value, type: this.type});
  }

  beginEdit(value: any) {
      this.editing = true;
  }
}
