import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup , Validators } from '@angular/forms';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services';
import { ManualPackageModel } from '@components/carrier/models/manual-package.model';
import { IPackage } from '@models';

export interface IByManualEntryData {
  manualPackages: IPackage[];
  postcodes: string[];
}

/**
 * Complete the form fields with the packages dimensions.
 * User can attach postal codes to form.
 *
 * Emits an event when submitting the form to CarrierCalculatorComponent.
 */
@Component({
  selector: 'app-by-manual-entry-form',
  template: require('./by-manual-entry-form.component.html')
})
export class ByManualEntryFormComponent extends AbstractComponent {

  public manualEntryForm: FormGroup;

  @Output() public onSubmit: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private fb: FormBuilder,
  ) {
    super($translate, authService, null, state);

    this.createManualEntryForm();
  }

  /**
   * Gets the manual packages contained in the form.
   */
  get manualPackages(): FormArray {
    return this.manualEntryForm.get('manualPackages') as FormArray;
  }

  /**
   * Adds a manual package in the form.
   */
  public addPackage(): void {
    (<FormArray>this.manualEntryForm.controls['manualPackages']).push(this.getManualPackageFormGroup());
  }

  /**
   * Removes a manual package in the form.
   */
  public removePackage(index: number): void {
    (<FormArray>this.manualEntryForm.controls['manualPackages']).removeAt(index);
  }

  public compute(): void {
    this.getEstimations();
  }

  private createManualEntryForm(): void {
    this.manualEntryForm = this.fb.group({
      manualPackages: [],
      postcodes: [[]],
    });

    this.addManualPackagesControl();
  }

  /**
   * Adds manual packages formArray control to form.
   */
  private addManualPackagesControl(): void {
    this.manualEntryForm.setControl('manualPackages', this.fb.array([this.getManualPackageFormGroup()]));
  }

  /**
   * Creates a formGroup then return it.
   */
  private getManualPackageFormGroup(): FormGroup {
    const packageModel = new ManualPackageModel();

    return this.fb.group({
      width: [packageModel.width, Validators.required],
      height: [packageModel.height, Validators.required],
      length: [packageModel.length, Validators.required],
      weight: [packageModel.weight, Validators.required],
    });
  }

  private getEstimations(): void {
    this.onSubmit.emit({
      manualPackages: this.manualEntryForm.get('manualPackages').value,
      postcodes: this.manualEntryForm.get('postcodes').value
    });
  }
}
