import { Component, Inject, OnInit } from '@angular/core';
import { AbstractResource, CarrierGroupResource, OrderItemResource } from '@resources';
import { AbstractComponent } from '@components/generic/abstract.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@services';
import { CarrierScaleResource } from '@resources/carrier-scale.resource';
import { SessionHelper } from '@helpers';
import { ICountry } from '@interfaces';
import { IWarehouses } from '@components/warehouses/models';
import { WarehousesResource } from '@components/warehouses';
import { SnackbarService } from '@components/snackbar';
import { NumberHelper } from '@helpers/NumberHelper';
import * as moment from 'moment';
import { DATE_SHORT_FORMAT, DATE_SHORT_FORMAT_UK } from '@constants';

@Component({
  selector: 'app-order-manager-scaling-form',
  template: require('./order-manager-scaling-form.component.html'),
  providers: [
    { provide: AbstractResource, useClass: CarrierScaleResource },
  ],
})
export class OrderManagerScalingFormComponent extends AbstractComponent implements OnInit {

  public form: FormGroup;
  public warehouses: any[] = [];
  public carrierGroups: any[] = [];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    private orderItemResource: OrderItemResource,
    private formBuilder: FormBuilder,
    private warehouseResource: WarehousesResource,
    private carrierGroupResource: CarrierGroupResource,
    private snackbar: SnackbarService,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit() {
    this.fetch();
    this.buildForm();
  }

  public buildForm(): void {
    this.form = this.formBuilder.group({
      warehouse: [null, Validators.required],
      fromCarrierGroup: [null, Validators.required],
      toCarrierGroup: [null, Validators.required],
      volume: [null, Validators.required],
      scheduledAt: [null],
      locale: [null],
    });
  }

  public fetch(): void {
    this.warehouseResource.cGet(
      {
        'carriers.country.code[]': SessionHelper.getCountries().map((country: ICountry) => country.code),
        'active': true,
        'pagination': false,
      },
      {
        isHydra: true,
        returnHydraMembers: true,
        dontUseModel: true,
        blocking: false
      }
    )
    .takeUntil(this.destroyed$)
    .subscribe((response: any) => {
      this.warehouses = response.map((warehouse: IWarehouses): any => {
        return {
          iri: warehouse['@id'],
          code: warehouse.code,
          name: warehouse.name,
        };
      });
    });

    this.carrierGroupResource.cGet(
      {
        'carriers.country.code[]': SessionHelper.getCountries().map((country: ICountry) => country.code),
        'active': true,
        'pagination': false,
      },
      {
        isHydra: true,
        returnHydraMembers: true,
        dontUseModel: true,
        blocking: false
      }
    )
    .takeUntil(this.destroyed$)
    .subscribe((response: any) => {
      this.carrierGroups = response.map((carrierGroup: any): any => {
        return {
          iri: carrierGroup['@id'],
          code: carrierGroup.code,
          name: carrierGroup.name,
        };
      });
    });
  }

  public submit(event?: any): void {
    const body = this.form.value;
    body.volume = NumberHelper.parseFloat(body.volume);
    body.locale = SessionHelper.getLocale();
    body.scheduledAt = body.scheduledAt ? moment(body.scheduledAt, DATE_SHORT_FORMAT).format(DATE_SHORT_FORMAT_UK) : null;

    this.resource.create(body)
      .takeUntil(this.destroyed$)
      .subscribe(() => {
        this.snackbar.validate('SAVED');
        this.state.go('order_manager.scaling_list');
      })
    ;
  }
}
