export default manufacturerRoutes;

/** @ngInject */
function manufacturerRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'parameters',
    sidebarSubNav: 'manufacturer',
    reloadChangeCountry: 'manufacturer.list',
  };

  $stateProvider
    .state('manufacturer', {
      parent: 'walisoft',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.MANUFACTURER',
        stateName: 'manufacturer.list'
      },
      data: Object.assign(commonStateData, {
        showCountries: false
      })
    })
    .state('manufacturer.list', {
      url: '/manufacturers',
      views: {
        'page@layout': {
          template: '<app-manufacturer-list></app-manufacturer-list>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.MANUFACTURER.LIST.TITLE',
      })
    })
    .state('manufacturer.new', {
      url: '/manufacturers/new',
      breadcrumb: {
        label: 'BREADCRUMB.NEW'
      },
      views: {
        'page@layout': {
          template: '<app-manufacturer-new></app-manufacturer-new>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.MANUFACTURER.NEW.TITLE',
      })
    })
    .state('manufacturer.edit', {
      url: '/manufacturers/:id/edit',
      breadcrumb: {
        label: 'BREADCRUMB.EDIT'
      },
      views: {
        'page@layout': {
          template: '<app-manufacturer-edit></app-manufacturer-edit>'
        }
      },
      data: Object.assign(commonStateData, {
        reloadChangeCountry: 'manufacturer.edit',
        reloadChangeCountrySearchParam: 'id',
        title: 'PAGE.MANUFACTURER.EDIT.TITLE'
      })
    })
  ;
}
