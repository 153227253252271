import { IHydraMember } from '@interfaces/hydra-resource.interface';

export class SupplierCommercialEntityModel {
  commercialEntity: string;
  commercialEntityId: number;
  sageIdentifier: string;

  constructor(object: IHydraMember) {
    this.commercialEntity = object.commercialEntity;
    this.sageIdentifier = object.sageIdentifier;

    this.commercialEntityId = Number(this.commercialEntity.split('/').pop());
  }
}
