import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {IResource} from '@interfaces/IResource';
import {HttpHelper} from '../helpers/HttpHelper';
import {HttpEncoderService} from '../services/http-encoder.service';
import 'rxjs/add/operator/map';
import {BASE_URL_API} from '../constants';

export interface IRequestOptions {
  entryPoint?: string;
  blocking?: boolean;
  isHydra?: boolean;
  model?: any;
  listModel?: any;
  formModel?: any;
  dontUseModel?: boolean;
  returnHydraMembers?: boolean;
  throwViolations?: boolean;
  params?: any;
}

@Injectable()
export class AbstractResource implements IResource {

  [keys: string]: any;

  public isHydra?: boolean;
  public entryPoint: string;
  public model?: any;
  public listModel?: any;
  public formModel?: any;
  public routeName: string;
  public translationKey: string;
  public throwViolations?: boolean;
  public encoder?: any;

  protected defaultHeaders: any = new HttpHeaders({'Content-type': 'application/json'});
  protected nullableProperties: string[] = [];
  protected hiddenProperties: string[] = [];

  constructor(protected http: HttpClient) {
  }

  public get(id?: string, options: IRequestOptions = {}): Observable<object> {
    return this.http.get(
      this.getPath(options.entryPoint || `${this.entryPoint}/${id}`),
      {
        headers: this.getHeaders(options),
        params: this.encodeParams(options.params),
        reportProgress: undefined === options.blocking ? true : options.blocking,
      }
    )
      .map((response: any) => {
        if ((
          undefined === this.model &&
          undefined === options.model &&
          undefined === this.formModel &&
          undefined === options.formModel
        ) || options.dontUseModel) {
          return response;
        }

        if (options.model || options.formModel) {
          const model = options.model ? options.model : options.formModel;

          return new model(response);
        }

        if (this.model || this.formModel) {
          const model = this.model ? this.model : this.formModel;

          return new model(response);
        }

        return response;
      });
  }

  /**
   * @deprecated - use cGet() instead
   */
  public getMany(params?: object, options: any = {}): Observable<object> {
    return this.http.get(
      this.getPath(options.entryPoint || this.entryPoint),
      {
        headers: this.getHeaders(options),
        params: this.encodeParams(params),
        reportProgress: undefined === options.blocking ? true : options.blocking,
        ...options,
      }
    )
      .map((response: any) => {
        if (options.dontUseModel) {
          if (this.isHydra) {
            response = options.isHydra === false ? response : response['hydra:member'];
          } else if (options.isHydra) {
            return response['hydra:member'];
          }

          return response;
        } else if (options.model) {
          if (this.isHydra) {
            if (options.isHydra === false) {
              if (response['hydra:member'].map) {
                response['hydra:member'] = response['hydra:member'].map((item: any) => new this.model(item));
              }
            } else {
              response = response['hydra:member'];
            }
          } else if (options.isHydra) {
            response = response['hydra:member'];
          }

          return (response.map && response.map((item: any) => new options.model(item)) || response);
        } else if (this.model) {
          if (this.isHydra) {
            if (options.isHydra === false) {
              if (response['hydra:member'].map) {
                response['hydra:member'] = response['hydra:member'].map((item: any) => new this.model(item));
              }
            } else {
              response = response['hydra:member'];
            }
          }
          return (response && response.map && response.map((item: any) => new this.model(item)) || response);
        }

        return response;
      });
  }

  /**
   * It's a complete refactor for the getMany method.
   *
   * Gets a list of data.
   *
   * @param {object|null} params - parameters to send with request for filtering.
   * @param {IRequestOptions} options - options needed for the request.
   * Options can store:
   *  - a different entry point which is provided by the provider
   *  - a blocking option to display a pending request loader on the entire screen or in the bottom of the screen.
   *  - an isHydra boolean to avert that response will be in hydra format.
   *  - a model or a listModel that is a reference to a class in case of we want to use a different model that the one in the provider.
   *
   *  If there are no model given, so we return directly the response,
   *  If the response is in hydra format we return an object for each hydra member.
   *  If the response is not in hydra format we return an object for each item in the array.
   *
   *  TODO: create a task for calling only this method in every place instead of `getMany`, see to adapt it if necessary,
   *        for each list component, a model defined in a resource or from options must be replaced by a listModel.
   *        Generally the model is different from data list and for one getting item.
   *        When the task will be done, don't forget to remove the `getMany` method and remove notions of `model` inside
   *        `cGet` method.
   */
  public cGet(params: object | null = null, options: IRequestOptions = {}, headers?: HttpHeaders): Observable<object> {
    return this.http.get(
      this.getPath(options.entryPoint || this.entryPoint),
      {
        headers: headers || this.getHeaders(options),
        params: this.encodeParams(params),
        reportProgress: undefined === options.blocking ? true : options.blocking,
      }
    ).map((response: any) => {
      if (
        (undefined === this.model &&
          undefined === options.model &&
          undefined === this.listModel &&
          undefined === options.listModel) ||
        options.dontUseModel
      ) {
        if ((this.isHydra || options.isHydra) && options.returnHydraMembers) {
          return response['hydra:member'];
        }

        return response;
      }

      const model = undefined !== options.listModel ?
        options.listModel :
        undefined !== this.listModel ?
          this.listModel :
          undefined !== options.model ?
            options.model :
            this.model
      ;

      if (this.isHydra || options.isHydra) {
        response['hydra:member'] = response['hydra:member'].map((item: object) => new model(item));

        if (options.returnHydraMembers) {
          return response['hydra:member'];
        }

        return response;
      }

      if (Array.isArray(response)) {
        return response.map((item: any) => new model(item));
      }

      for (const property in response) {
        if (response.hasOwnProperty(property) && Array.isArray(response[property])) {
          response[property] = response[property].map((item: object) => new model(item));
        }
      }

      return response;
    });
  }

  public exportFile(params?: object, options: any = {}, headers?: any): Observable<any> {

    if (options.postOptions) {
      return this.http.post(
        this.getPath(options.entryPoint || this.entryPoint),
        {
          ...options.postOptions,
          observe: 'response'
        }
      ).map((fullRes: any) => {
        const res: any = fullRes.csv;
        if (res) {
          const file = new Blob([res], {type: options.type});
          const filename = options.filename
            || `export_${fullRes.headers.get('Content-Disposition') && fullRes.headers
              .get('Content-Disposition')
              .substring(
                fullRes.headers.get('Content-Disposition').indexOf('="') + 2,
                fullRes.headers.get('Content-Disposition').length - 1
              )}`
            || 'export.csv';

          if (window.navigator.msSaveOrOpenBlob) { // IE10+
            window.navigator.msSaveOrOpenBlob(file, filename);
          } else { // Others
            const a = document.createElement('a');
            const url = URL.createObjectURL(file);

            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            setTimeout(function () {
              document.body.removeChild(a);
              window.URL.revokeObjectURL(url);
            }, 0);
          }
        }
      });
    }

    return this.http.get(
      this.getPath(options.entryPoint || this.entryPoint),
      {
        headers: headers ? headers : this.getHeaders(options),
        params: this.encodeParams(params),
        reportProgress: undefined === options.blocking ? true : options.blocking,
        ...options,
        observe: 'response'
      }
    ).map((fullRes: any) => {
      const res: any = fullRes.body;
      const file = new Blob([res], {type: options.type});
      const filename = options.filename
        || `export_${fullRes.headers.get('Content-Disposition') && fullRes.headers
          .get('Content-Disposition')
          .substring(
            fullRes.headers.get('Content-Disposition').indexOf('="') + 2,
            fullRes.headers.get('Content-Disposition').length - 1
          )}`
        || 'export.csv';

      if (window.navigator.msSaveOrOpenBlob) { // IE10+
        window.navigator.msSaveOrOpenBlob(file, filename);
      } else { // Others
        const a = document.createElement('a');
        const url = URL.createObjectURL(file);

        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setTimeout(function () {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
      }
    });
  }

  public create(body: object, options: any = {}): Observable<object> {
    if (options.cleanParams === undefined) {
      body = HttpHelper.cleanParams(body, this.nullableProperties, this.hiddenProperties);
    }

    return this.http.post(
      this.getPath(options.entryPoint || this.entryPoint),
      JSON.stringify(body),
      {
        headers: options.headers || this.getHeaders(options),
        reportProgress: undefined === options.blocking ? true : options.blocking,
        ...options,
      }
    );
  }

  public update(id: string, body: object, options: any = {}): Observable<object> {
    if (options.cleanParams === undefined) {
      body = HttpHelper.cleanParams(body, this.nullableProperties, this.hiddenProperties);
    }
    return this.http.put(
      this.getPath(options.entryPoint || `${this.entryPoint}/${id}`),
      JSON.stringify(body),
      {
        headers: this.getHeaders(options),
        reportProgress: undefined === options.blocking ? true : options.blocking,
        ...options,
      }
    )
      .map((response: any) => {
        if (
          (undefined === this.model &&
            undefined === options.model &&
            undefined === this.formModel &&
            undefined === options.formModel) ||
          options.dontUseModel
        ) {
          return response;
        }

        if (options.model || options.formModel) {
          const model = options.model ? options.model : options.formModel;

          return new model(response);
        }

        if (this.model || this.formModel) {
          const model = this.model ? this.model : this.formModel;

          return new model(response);
        }

        return response;
      })
      ;
  }

  public partialUpdate(id: string, body: object, options: any = {}): Observable<object> {
    if (this.getPath(options.entryPoint).includes('v2') && options.usePatch !== true) {
      return this.http.put(
        this.getPath(options.entryPoint || `${this.entryPoint}/${id}`),
        JSON.stringify(HttpHelper.cleanParams(body, this.nullableProperties, this.hiddenProperties)),
        {
          headers: this.getHeaders(options),
          reportProgress: undefined === options.blocking ? true : options.blocking,
          ...options,
        }
      );
    }

    return this.http.patch(
      this.getPath(options.entryPoint || `${this.entryPoint}/${id}`),
      JSON.stringify(HttpHelper.cleanParams(body, this.nullableProperties, this.hiddenProperties)),
      {
        headers: this.getHeaders(options),
        reportProgress: undefined === options.blocking ? true : options.blocking,
        ...options,
      }
    );
  }

  public remove(id: string, options: any = {}): Observable<object> {
    return this.http.delete(
      this.getPath(options.entryPoint || `${this.entryPoint}/${id}`),
      {
        headers: this.getHeaders(options),
        reportProgress: undefined === options.blocking ? true : options.blocking,
        ...options,
      }
    );
  }

  /**
   * Combines API base url and target entryPoint
   */
  public getPath(entryPoint: string = this.entryPoint): string {
    return `${BASE_URL_API}${entryPoint}`;
  }

  /**
   * Converts an object into query params.
   */
  public encodeParams(params: object): HttpParams | null {
    if (params) {
      const encodedParams: any[] = [];
      Object.entries(HttpHelper.cleanParams(params, this.nullableProperties, this.hiddenProperties))
        .filter((item: any[]) => undefined !== item[1])
        .forEach((item: any[]) => {
          if (Array.isArray(item[1])) {
            for (const param of item[1]) {
              encodedParams.push(item[0] + (item[0].includes('[]') ? '=' : '[]=') + param);
            }
          } else {
            encodedParams.push(item[0] + '=' + encodeURIComponent(item[1]));
          }
        })
      ;

      return new HttpParams({
        fromString: encodedParams.join('&'),
        encoder: this.encoder ? new this.encoder() : new HttpEncoderService()
      });
    }

    return null;
  }

  public uploadFile(body: FormData, options: any = {}): Observable<object> {
    return this.http.post(
      this.getPath(options.entryPoint || this.entryPoint),
      body,
      {
        reportProgress: undefined === options.blocking ? true : options.blocking,
        ...options,
      }
    );
  }

  private getHeaders(options: IRequestOptions): HttpHeaders {
    let headers: HttpHeaders = this.defaultHeaders;

    if (false === this.throwViolations || false === options.throwViolations) {
      headers = headers.append('X-Throw-Violations', '0');
    }

    return headers;
  }
}
