import { AbstractResource } from '../../../resources/abstract.resource';
import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { Observable } from 'rxjs/Observable';
import { IReverseProduct } from '@components/reverse';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  entryPoint: '/v2/sparepart_replenishments',
})
export class SparepartReplenishmentResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  public saveNeededSparepartQuantity( body: any): Observable<any> {
    return this.create(
      body,
      { entryPoint: `${this.entryPoint}` });
  }
}
