import { Injectable, Inject } from '@angular/core';
import { AbstractFormFieldBase } from '../../../components/generic/Form/dynamic/fields/abstract-form-field-base.class';
import { TextField, MultiSearchField } from '../../../components/generic/Form/dynamic/fields';
import { MarketplaceResource } from '../../../resources/marketplace.resource';
import { FormNotifierService } from '../../../services/form-notifier.service';
import { SessionHelper } from '../../../helpers/session.helper';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import {CustomerResource} from '../../customer/customer.resource';
import {DropDownListField} from '@components/generic/Form/dynamic/fields/select-field.class';
import {inject} from '@angular/core/testing';

/**
 * Constructs all filters field for the customer list.
 */
@Injectable()
export class CustomerFiltersFormService extends AbstractFiltersFieldsService {

  /** Defined here to keep a reference to data in order to change it dynamically*/
  public marketplacesField: MultiSearchField = new MultiSearchField({
    fieldName: 'marketplaces[]',
    label: 'PAGE.CUSTOMER.LIST.FILTERS.MARKETPLACES',
    data: null,
    textField: 'commercialName',
    valueField: 'code',
    value: null,
    order: 8
  });

  public collaboratorsField: DropDownListField = new DropDownListField({
    fieldName: 'collaborators',
    label: 'PAGE.ORDER.NEW.BLOCK_ADDRESS.COLLABORATOR',
    data: null,
    textField: 'label',
    valueField: 'value',
    value: this.filters.get('collaborators') ? this.filters.get('collaborators') : null,
    valuePrimitive: true,
    defaultItem: {
      label: '',
      value: '' },
  });

  constructor(
    private customerResource: CustomerResource,
    @Inject('StateService') protected state: ng.ui.IStateService,
    @Inject('TranslationService') public $translate: ng.translate.ITranslateService,
    formNotifier: FormNotifierService,
    private marketplaceResource: MarketplaceResource,
  ) {
    super(formNotifier, state);

    if (state.params.countries) {
      this.fetchMarketplaces((typeof state.params.countries === 'string') ? [state.params.countries] : state.params.countries);
    } else {
      this.fetchMarketplaces(SessionHelper.getCountries().map((country) => country.code));
    }
    this.customerResource.getCollaborators()
      .takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        this.collaboratorsField.data = response;
        this.collaboratorsField.data.unshift(
          {label: this.$translate.instant('PAGE.ORDER.LIST.FILTER.COLLABORATORS.ALL'),
            value: response.map((collaborator: any) => collaborator.value).join(',')}
        );
      });
  }

  public fetchMarketplaces(countryCodes: string[]): void {
    countryCodes.forEach((countryCode: string) => {
      this.marketplaceResource.filterByCountryCode(countryCode)
        .subscribe((marketplaces: any) => {
          this.marketplacesField.data.push(...marketplaces);

          const itemsAvailable: any = [];
          const stateParams = typeof this.state.params.marketplaces === 'string' ?
            [this.state.params.marketplaces]
            :
            this.state.params.marketplaces;

          marketplaces.forEach((marketplace: any) => {
            if (stateParams && stateParams.includes(marketplace.code)) {
              itemsAvailable.push(marketplace.code);
            }
          });

          this.marketplacesField.value = itemsAvailable;
          this.state.params.marketplaces = this.marketplacesField.value;

          this.marketplacesField.value = this.filters.getAll('marketplaces[]').length !== 0 ?
            this.filters.getAll('marketplaces[]') :
            undefined
          ;
        });
    });
  }

  /**
   * Updates marketplaces field choices when country field value changes
   *
   * @param {any[]} newValues
   * @memberof CustomerFiltersFormService
   */
  public updateMarketplaces(newValues: any[]) {
    this.marketplacesField.data = [];
    this.marketplacesField.value = [];
    if (newValues.length) {
      this.fetchMarketplaces(newValues);
    } else {
      this.fetchMarketplaces(SessionHelper.getCountries().map((country) => country.code));
    }
  }

  /**
   * Gets fields list.
   */
  public getFields(): AbstractFormFieldBase<any>[] {

    const fields: AbstractFormFieldBase<any>[] = [

      new TextField({
        label: 'PAGE.CUSTOMER.LIST.FILTERS.NAME',
        fieldName: 'fullName',
        value: this.filters.get('fullName'),
        order: 1
      }),

      new TextField({
        label: 'PAGE.CUSTOMER.LIST.FILTERS.EMAIL',
        fieldName: 'username',
        value: this.filters.get('username'),
        order: 2
      }),

      new TextField({
        label: 'PAGE.CUSTOMER.LIST.FILTERS.PHONES',
        fieldName: 'phones',
        value: this.filters.get('phones'),
        order: 3
      }),

      new TextField({
        label: 'PAGE.CUSTOMER.LIST.FILTERS.ID',
        fieldName: 'id',
        value: this.filters.get('id'),
        order: 4
      }),

      new TextField({
        label: 'PAGE.CUSTOMER.LIST.FILTERS.ORDER_NUMBER',
        fieldName: 'orderNumber',
        value: this.filters.get('orderNumber'),
        order: 5
      }),

      new TextField({
        label: 'PAGE.CUSTOMER.LIST.FILTERS.POST_CODE',
        fieldName: 'postcodes',
        value: this.filters.get('postcodes'),
        order: 6
      }),

      new MultiSearchField({
        fieldName: 'countries[]',
        label: 'PAGE.CUSTOMER.LIST.FILTERS.COUNTRIES',
        data: SessionHelper.getCountries(),
        textField: 'name',
        valueField: 'code',
        value: this.filters.getAll('countries[]'),
        valueChangedAction: this.updateMarketplaces.bind(this),
        order: 7
      }),

      this.marketplacesField,
      this.collaboratorsField,
    ];
    return fields.sort((a, b) => a.order - b.order);
  }
}
