import {Component, ComponentFactoryResolver, Inject} from '@angular/core';
import {AuthService} from '@services';
import {AbstractResource } from '@resources';
import {AbstractFiltersFieldsService} from '@components/generic/Form/filters/abstract-filters-fields.service';
import {GrcTicketsFiltersService} from '@components/grc/services/grc-tickets-filters.service';
import { GenericResultListComponent } from '@components/generic/List';
import {GrcTicketResource} from '@components/grc/grc-ticket.resource';
import {UrlHelper} from '@helpers';
import {IRelation} from '@components/order-manager/relation/order-manager-relation.interface';
import {generateSummaryForTicket} from '@components/order-manager/relation/SummaryHelper';
import moment = require('moment');

@Component({
  selector: 'app-grc-tickets-result-list',
  template: require('./grc-tickets-result-list.component.html'),
  providers: [
    { provide: AbstractResource, useClass: GrcTicketResource },
    { provide: AbstractFiltersFieldsService, useClass: GrcTicketsFiltersService }
  ],
})
export class GrcTicketsResultListComponent extends GenericResultListComponent {
  private _items: any[];
  protected allTickets: any = [];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    @Inject('StateService') state: ng.ui.IStateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('DialogService') dialog: any,
    urlHelper: UrlHelper,
    componentFactoryResolver: ComponentFactoryResolver,
  ) {
    super($translate, state, authService, resource, dialog, urlHelper, componentFactoryResolver);
  }

  set items(items: any[]) {
    this._items = items;
  }

  get items(): any[] {
    return this.mapAllTickets(this._items);
  }

  public mapAllTickets(array: IRelation[]): any[] {
    if (!array) {
      return [];
    }

    return array.map((ticket: IRelation): any => {
      const agent = ticket.agents.slice(-1);
      return {
        id: ticket.id,
        date: moment(ticket.claimDate).format('DD/MM/Y'),
        order_id: ticket.order.id ? ticket.order.id : ticket.order,
        litigation: ticket.litigation ? this.translate('BOOLEANS.YES') : this.translate('BOOLEANS.NO'),
        origin: ticket.origins.map((origin) => origin.type).join(' / '),
        summary: generateSummaryForTicket(ticket, false, this.$translate),
        status: ticket.status,
        lastAgent: agent[0].agent,
        satisfactionType: ticket.satisfactionType,
      };
    });
  }
}
