import { Component, Input } from '@angular/core';
import { FormArray } from '@angular/forms';

@Component({
  selector: 'app-discount-form-rejection-translation',
  template: require('./discount-form-rejection-translation.component.html'),
})
export class DiscountFormRejectionTranslationComponent {

  @Input() translationsFA: FormArray;
  @Input() tabIndex: number = 0;
  @Input() isReadOnly: boolean = false;
}
