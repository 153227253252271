import { UsersResource } from '@components/users/users.resource';

export default usersRoutes;

/** @ngInject */
function usersRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'walisoft',
    sidebarSubNav: 'users',
    title: '',
    reloadChangeCountry: 'users.list',
  };

  $stateProvider
    .state('users', {
      url: '/users',
      parent: 'walisoft',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.USERS',
        stateName: 'users.list'
      },
      data: Object.assign(commonStateData, {
        showCountries: false
      })
    })
    .state('users.list', {
      url: `?country[]`,
      views: {
        'page@layout': {
          template: '<app-users-list [hydra-pagination]="true"></app-users-list>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.USERS.LIST.TITLE',
        resource: UsersResource
      })
    })
    .state('users.new', {
      url: '/new',
      breadcrumb: { label: 'BREADCRUMB.NEW' },
      views: {
        'page@layout': { template: '<app-users></app-users>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.USERS.NEW.TITLE',
      })
    })
    .state('users.edit', {
      url: '/:id',
      breadcrumb: { label: 'BREADCRUMB.EDIT' },
      views: {
        'page@layout': { template: '<app-users></app-users>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.USERS.EDIT.TITLE',
      })
    })
  ;
}
