import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SessionHelper, FormErrorHelper } from '@helpers';
import { SnackbarService } from '../../../snackbar/snackbar.service';
import { CarrierGroupResource } from '@resources';
import { ICarrierGroup } from '@components/carrier/interfaces/carrier-group.interface';
import { AuthService, FormNotifierService } from '@services';
import { AbstractComponent } from '@components/generic/abstract.component';

@Component({
  selector: 'app-carrier-cost-form',
  template: require('./carrier-cost-form.component.html')
})
export class CarrierCostFormComponent extends AbstractComponent implements OnInit {

  @Input() public carrierGroup: ICarrierGroup;
  @Input() public locale: string = SessionHelper.getLocale();

  @Output() public onSave: EventEmitter<object> = new EventEmitter();

  public form: FormGroup;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: CarrierGroupResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private snackbar: SnackbarService,
    @Inject('DialogService') private dialog: any,
    public formNotifier: FormNotifierService,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit() {
    const description = this.carrierGroup.translations[this.locale] && this.carrierGroup.translations[this.locale].description;

    this.form = new FormGroup({
      overhead: new FormControl(this.carrierGroup.config.overhead),
      overheadPercentage: new FormControl(this.carrierGroup.config.overheadPercentage),
      description: new FormControl(description),
      active: new FormControl(this.carrierGroup.active),
      limitedScope: new FormControl(this.carrierGroup.config.limitedScope),
    });

    this.form.statusChanges.subscribe(() => {
      if (this.form.dirty) {
        this.formNotifier.notifyFormInEdition();
      }
    });
  }

  private prepareQuery(query: any): object {
    if (undefined === this.carrierGroup.translations[this.locale]) {
      this.carrierGroup.translations = Object.assign({
        [this.locale]: {
          description: query.description,
        }
      }, this.carrierGroup.translations);
    } else {
      this.carrierGroup.translations[this.locale].description = query.description;
    }

    this.carrierGroup.translations[this.locale].locale = this.locale;

    return {
      active: query.active,
      carriers: [
        {
          id: this.carrierGroup.config.id,
          overhead: query.overhead,
          overheadPercentage: query.overheadPercentage,
          limitedScope: query.limitedScope
        }
      ],
      translations: this.carrierGroup.translations
    };
  }

  public submit(isRedirect: boolean = false): void {
    FormErrorHelper.removeFormControlsAPIErrors(this.form);

    if (this.form.dirty && this.form.valid) {
      this.onSave.emit({
        body: this.prepareQuery(this.form.value),
        form: this.form,
        redirect: isRedirect
      });

      return;
    }

    if (this.form.dirty && !this.form.valid) {
      this.snackbar.warn(this.translate('ALERTS.ERROR.FORM'));

      return;
    }

    this.snackbar.warn(this.translate('ALERTS.NO_CHANGE.FORM'));
  }

  /**
   * Cancels form update and redirect to list.
   */
  public cancel(): void {
    this.dialog.confirm(this.translate('DIALOG.TEXT.DONT_SAVE'))
      .then(() => this.actions.list.go())
    ;
  }
}
