import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { AbstractResource } from '@resources/abstract.resource';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { SessionHelper } from '@helpers/session.helper';
import { DiscountListModel } from '@components/discount/models/discount-list.model';

@Injectable()
@Resource({
  routeName: 'discount',
  entryPoint: '/v2/discounts',
  cGetDefaultFilters: () => ({
    page: 1,
    byPage: 30,
  }),
  listModel: DiscountListModel,
})
export class DiscountResource extends AbstractResource {

  protected nullableProperties: string[] = ['minimumAmount', 'maximumAmount', 'errorMessage'];

  constructor(http: HttpClient) {
    super(http);
  }

  public cGet(params: any = {}, options: any = {}): Observable<object> {
    delete params.date;

    return super.cGet(params, options);
  }

  public createDiscount(body: object): Observable<Object> {
    return this.create(body, {entryPoint: `${this.entryPoint}`});
  }

  public updateDiscount(id: string, body: object): Observable<Object> {
    return this.partialUpdate(null, body, {entryPoint: `${this.entryPoint}/${id}`});
  }

  public getNumberOfUsage(id: string): Observable<Object> {
    return this.get(null, {entryPoint: `${this.entryPoint}/${id}/number_of_usage`});
  }

  public getCreditDiscount(customerId: string): Observable<object> {
    return this.getMany({}, {
      entryPoint: `/v2/discounts/customers/${customerId}/credit`,
      isHydra: false,
    });
  }

  public getProductCartDiscount(customerId: string): Observable<object> {
    return this.getMany({}, {
      entryPoint: `/v2/discounts/customers/${customerId}/product_cart`,
      isHydra: false,
    });
  }
}
