export class CarrierScaleListModel {
  id: number;
  warehouse: string;
  fromCarrierGroup: string;
  toCarrierGroup: string;
  volume: number;
  scheduledAt: any;
  scaledVolume: number;
  startDate: any;
  endDate: any;
  fileUrl: string;
  locale: any;
  automatic: string;

  constructor(data: any) {
    this.id = data.id;
    this.warehouse = data.warehouse.name;
    this.fromCarrierGroup = data.fromCarrierGroup.name;
    this.toCarrierGroup = data.toCarrierGroup ? data.toCarrierGroup.name : null;
    this.volume = data.volume;
    this.scheduledAt = data.scheduledAt;
    this.scaledVolume = data.scaledVolume;
    this.startDate = data.startDate;
    this.endDate = data.endDate;
    this.fileUrl = data.fileUrl;
    this.locale = data.locale;
    this.automatic = data.automatic
      ? 'PAGE.CARRIER_SCALE.LIST.TABLE.AUTOMATIC_SCALE_YES'
      : 'PAGE.CARRIER_SCALE.LIST.TABLE.AUTOMATIC_SCALE_NO';
  }
}
