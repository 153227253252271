import { Component, Inject, Input, OnInit } from '@angular/core';
import { AbstractResource, CarrierBlacklistMarketplacesResource } from '@resources';
import { AuthService } from '@services';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { SnackbarService } from '@components/snackbar';
import { AbstractComponent } from '@components/generic/abstract.component';
import { SessionHelper } from '@helpers';

@Component({
  selector: 'app-carrier-blacklist-marketplaces',
  template: require('./carrier-blacklist-marketplaces.component.html'),
  styles: [require('./carrier-blacklist-marketplaces.component.scss')],
  providers: [
    { provide: AbstractResource, useClass: CarrierBlacklistMarketplacesResource },
  ]
})
export class CarrierBlacklistMarketplacesComponent extends AbstractComponent implements OnInit {

  @Input() public carrierGroupId: string;

  public marketplaces: any[] = [];
  public form: FormGroup;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    @Inject('DialogService') private dialogService: any,
    private formBuilder: FormBuilder,
    private snackbar: SnackbarService,
  ) {
    super($translate, authService, resource, state);
  }

  get marketplacesFA(): FormArray {
    return this.form.get('marketplaces') as FormArray;
  }

  ngOnInit(): void {
    this.fetch();
    this.buildForm();
    this.setControls();
  }

  fetch(): void {
    const currentCountryCode = SessionHelper.getCountry().code;

    (<CarrierBlacklistMarketplacesResource>this.resource).cGet(
      {country: currentCountryCode, pagination: false},
      {entryPoint: '/v2/marketplaces', returnHydraMembers: true}
    )
      .pipe(takeUntil(this.destroyed$))
      .subscribe((marketplaces: any) => {
        marketplaces.forEach((marketplace: any) => {
          let checked: boolean = false;

          marketplace.blacklistedCarriers.forEach((carrier: any) => {

            if (
              carrier.carrierGroup && carrier.carrierGroup.id && carrier.carrierGroup.id === +this.carrierGroupId
              && carrier.country && carrier.country.code && carrier.country.code === SessionHelper.getCountry().code
            ) {
              checked = true;
            }
          });

          this.marketplaces.push({
            name: marketplace.commercialName,
            value: marketplace['@id'],
            checked: checked,
          });
        });

        this.setControls();
      });
  }

  buildForm(): void {
    this.form = this.formBuilder.group({
      marketplaces: this.formBuilder.array([]),
    });
  }

  setControls(): void {
    const formGroups: FormGroup[] = this.marketplaces.map(
      (marketplace: any): FormGroup => this.formBuilder.group({checked: marketplace.checked, value: marketplace.value})
    );
    const formArray: FormArray = this.formBuilder.array(formGroups);

    this.form.setControl('marketplaces', formArray);
  }

  submit(isRedirect: boolean = false): void {
    const carrierGroup: string = `/api/v2/carrier_groups/${this.carrierGroupId}`;
    const marketplaces: string[] = this.form.value.marketplaces.filter((marketplace: any) => marketplace.checked).map((item: any) => item.value);
    const country = SessionHelper.getCountry().code;

    (<CarrierBlacklistMarketplacesResource>this.resource).blacklist(carrierGroup, marketplaces, country)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.snackbar.validate(this.translate('ALERTS.DATA.UPDATE'));

        if (isRedirect) {
          this.actions.list.go();
        }
      })
    ;
  }

  cancel(): void {
    this.dialogService.confirm(this.translate('PAGE.CARRIER.BLACKLIST.BACK_TO_LIST'))
      .then(() => this.actions.list.go());
  }

  public hasSomeChecked(): boolean {
    return (<FormArray>this.form.get('marketplaces')).controls.some(control => control.get('checked').value === true);
  }

  public toggleCheck(): void {
    const controls = (<FormArray>this.form.get('marketplaces')).controls;
    const checkedValue = !this.hasSomeChecked();

    controls.forEach((control) => {
      control.get('checked').setValue(checkedValue);
    });
  }
}
