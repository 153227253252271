import { IWarranty, IWarrantyTranslation } from '@components/warranty/models/warranty.interface';
import { SessionHelper } from '@helpers';

export class WarrantyModel implements IWarranty {
    id: number;
    months: number;
    translations: { [key: string]: IWarrantyTranslation; };

    constructor(warranty: IWarranty) {
        const locale: string = SessionHelper.getLocale();

        this.id = warranty.id;
        this.months = warranty.months;

        if (warranty.translations) {
            if (warranty.translations[locale] === undefined) {
                warranty.translations[locale] = {
                    label: '',
                    locale
                };
            }

            this.translations = warranty.translations;
        } else {
            this.translations = {
                [locale]: {
                    label: '',
                    locale
                }
            };
        }
    }
}
