import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ITab, ITabTranslation } from '@components/tab/models';
import { ITabFile } from '@components/tab/interfaces/tab-file.interface';
import { FileInfo } from '@progress/kendo-angular-upload';
import { TabImagesResource } from '@components/tab/tab-images.resource';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { AbstractService } from '@services/abstract.service';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import {DATE_FULL_FORMAT, DATE_SHORT_FORMAT} from '@constants';

/**
 * Handle tab and category file upload.
 */
@Injectable()
export class TabFileUploadService extends AbstractService {

  constructor(private tabImageResource: TabImagesResource) {
    super();
  }

  getFormData$(formTranslations: ITabTranslation[], tab: ITab): Observable<object>[] {
    const formData$: Observable<object>[] = [];

    Object.keys(tab.translations).forEach((locale: any) => {
      const tabTranslation: ITabTranslation = tab.translations[locale];
      const tabTranslationForm: ITabTranslation = formTranslations.find((translation: ITabTranslation) => translation.locale === locale);

      const tabTranslationFiles: ITabFile = {
        image: tabTranslation.image,
        leftBanner: tabTranslation.leftBanner,
        middleBanner: tabTranslation.middleBanner,
        rightBanner: tabTranslation.rightBanner,
        icon: tabTranslation.icon,
      };

      Object.keys(tabTranslationFiles).forEach((property: string) => {

        const file$: BehaviorSubject<FileInfo[]> | null = tabTranslationForm[property].file &&
        tabTranslationForm[property].file.subscriber
          ? tabTranslationForm[property].file.subscriber
          : null;

        const formData = new FormData();
        let file: FileInfo | null = null;

        if (file$) {
          file$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((response: any) => {
              file =
                response && Array.isArray(response) && response.length > 0
                  ? response[0]
                  : null;

              if (file && file.hasOwnProperty('rawFile')) {
                formData.append('file', file.rawFile);
              }
          });
        }

        formData.append('tabTranslationProperty', property);
        // conditions are necessary to set null value when we want to remove a value field and for the 'image' property,
        // because it does'nt contain additional fields
        // if we post the formData with empty string, so an empty string will be set in the DB,
        // a formData can only post string or File (null value is convert into string 'null')
        if (
          tabTranslationForm[property].file &&
          'object' === typeof tabTranslationForm[property].file &&
          null === tabTranslationForm[property].file.file
        ) {
          formData.append('file', null);
        }


        if (tabTranslationForm[property].startDate && null !== tabTranslationForm[property].startDate) {
          if (tabTranslationForm[property].startTime) {
            formData.append('startDate', moment(tabTranslationForm[property].startDate, DATE_SHORT_FORMAT)
              .add(tabTranslationForm[property].startTime.getHours(), 'hours')
              .add(tabTranslationForm[property].startTime.getMinutes(), 'minutes')
              .add(tabTranslationForm[property].startTime.getSeconds(), 'seconds')
              .format(DATE_FULL_FORMAT));
          } else {
            formData.append('startDate', moment(tabTranslationForm[property].startDate, DATE_SHORT_FORMAT).startOf('day').format(DATE_FULL_FORMAT));
          }
        }


        if (tabTranslationForm[property].endDate && null !== tabTranslationForm[property].endDate) {
          if (tabTranslationForm[property].endTime) {
            formData.append('endDate', moment(tabTranslationForm[property].endDate, DATE_SHORT_FORMAT)
              .add(tabTranslationForm[property].endTime.getHours(), 'hours')
              .add(tabTranslationForm[property].endTime.getMinutes(), 'minutes')
              .add(tabTranslationForm[property].endTime.getSeconds(), 'seconds')
              .format(DATE_FULL_FORMAT));
          } else {
            formData.append('endDate', moment(tabTranslationForm[property].endDate, DATE_SHORT_FORMAT).endOf('day').format(DATE_FULL_FORMAT));
          }
        }

        if (tabTranslationForm[property].associatedLink) {
          formData.append('associatedLink', tabTranslationForm[property].associatedLink);
        }

        if (tabTranslationForm[property].title) {
          formData.append('title', tabTranslationForm[property].title);
        }

        if (tabTranslationForm[property].description) {
          formData.append('description', tabTranslationForm[property].description);
        }

        if (tabTranslationForm[property].alt) {
          formData.append('alt', tabTranslationForm[property].alt);
        }

        if (tabTranslationForm[property].productMarketplace) {
          formData.append('productMarketplace', tabTranslationForm[property].productMarketplace.id);
        }

        // if a translation tab with file property (image, leftBanner ...) data has already data we have to PUT the formData
        if (tabTranslationFiles[property]) {
          formData$.push(this.tabImageResource.uploadFile(formData, { entryPoint: `/v2/tab/files/upload/${tabTranslation.id}` }));

          return;
        }

        // else if it's a POST, but we have firstly to check that a file is binded to the form because it's required
        if (file) {
          // we have to bind the tab translations with the tabThumbnail
          formData.append('tabTranslationId', tabTranslation.id);
          formData$.push(this.tabImageResource.uploadFile(formData, { entryPoint: '/v2/tab/files/upload' }));
        }
      });
    });

    return formData$;
  }
}
