import { Component, Inject, Input, OnInit } from '@angular/core';
import { AbstractResource } from '@resources/abstract.resource';
import { CMSResource } from '@components/cms/cms.resource';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { AuthService } from '@services/auth.service';
import { FormArray } from '@angular/forms';
import { MarketplaceHelper } from '@helpers/MarketplaceHelper';
import { takeUntil } from 'rxjs/operators';
import { ICategories } from '@components/categories/models';
import { CategoryTreeHelper } from '@helpers/CategoryTreeHelper';
import { RteEmbedPagesService } from '@services/rte-embed-pages.service';
import { CategoryResource } from '@resources/category.resource';

const INDEX_FOLLOW: string = 'index, follow';
const NO_INDEX_FOLLOW: string = 'noindex, follow';
const NO_INDEX_NO_FOLLOW: string = 'noindex, nofollow';

@Component({
  selector: 'app-cms-translations-form',
  template: require('./cms-translations-form.component.html'),
  styles: [require('./cms-translations-form.component.scss')],
  providers: [{provide: AbstractResource, useClass: CMSResource}],
})
export class CMSTranslationsFormComponent extends AbstractPageComponent implements OnInit {

  @Input() public translationsFA: FormArray;
  @Input() public tabIndex: number = 0;
  @Input() public localeTab: string;

  public categories: any[];

  public seoMetaRobotOptions: any[] = [
    INDEX_FOLLOW,
    NO_INDEX_FOLLOW,
    NO_INDEX_NO_FOLLOW,
  ];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private categoryResource: CategoryResource,
    private rteEmbedPagesService: RteEmbedPagesService,
  ) {
    super($translate, authService, resource, state);
  }

  public ngOnInit(): void {
    this.getCategoryList();
  }

  public getRteConfig(): any {
    return this.rteEmbedPagesService.getCmsRteEmbedPages(this.categories);
  }

  public getCategoryList(): void {
    this.categoryResource.cGet(
      { level: 1, marketplace: MarketplaceHelper.getWebsiteMarketplace().code },
      { dontUseModel: true }
      ).pipe(takeUntil(this.destroyed$))
      .subscribe(
        (categories: ICategories[]) => {
          this.categories = CategoryTreeHelper.flatten(this.decodeCategories(categories));
          this.categories = this.categories.map((category: any) => {
            return {
              text: `${category.label}`,
              value: JSON.stringify({
                id: category.id,
                type: category.level === 0 ? 'universe' : 'shelf',
                slug: category.slug
              }),
            };
          });
        },
      );
  }

  public decodeCategories(categories: ICategories[]): any {
   if (!categories) {
     return null;
   }

   return categories.map((category: any) => ({
     children: this.decodeCategories(category.children),
       id: category.id,
       label: category.translations[this.currentLocale] ? category.translations[this.currentLocale].name : null,
       slug: category.translations[this.currentLocale] ? category.translations[this.currentLocale].slug : null
   }));
  }
}
