export const INDICATOR_ADDRESS_TYPE = 'addressIndicator';
export const INDICATOR_CRM360_TYPE = 'crm360Indicator';
export const INDICATOR_SAGE_TYPE = 'sageIndicator';
export const INDICATOR_MARGIN_TYPE = 'orderMarginIndicator';
export const INDICATOR_TRANSACTION_TYPE = 'transactionIndicator';
export const INDICATOR_DUPLICATE_TYPE = 'orderDuplicateIndicator';
export const INDICATOR_ORDER_ITEM_STATUS_SYNC_TYPE = 'orderItemStatusSyncIndicator';
export const INDICATOR_ORDER_ITEM_STOCK = 'orderItemStockIndicator';
export const INDICATOR_FRAUD_TYPE = 'fraudIndicator';
export const INDICATOR_SUPPLY_OPTIMIZATION_TYPE = 'supplyOptimizationIndicator';
export const INDICATOR_ESTIMATE_TYPE = 'orderEstimateIndicator';
