import { Component, Inject, OnInit } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { CategoriesResource } from '../';
import 'rxjs/add/operator/map';
import { AuthService } from '@services';

@Component({
  selector: 'app-categories-list',
  template: require('./categories-list.component.html')
})
export class CategoriesListComponent extends AbstractComponent implements OnInit {

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: CategoriesResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit() {
    this.state.go(`tabs.list`);
  }
}
