import { NgModule } from '@angular/core';
import { QualityExportComponent } from '@components/quality-export/quality-export.component';
import { ExportModule, GenericModule } from '@components';

@NgModule({
  declarations: [
    QualityExportComponent,
  ],
  entryComponents: [
    QualityExportComponent,
  ],
  exports: [
    QualityExportComponent
  ],
  imports: [
    ExportModule,
    GenericModule,
  ]
})

export class QualityExportModule {}
