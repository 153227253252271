import { Injectable } from '@angular/core';
import { ISku } from '@interfaces/ISku';
import { MarketplaceHelper } from '@helpers/MarketplaceHelper';
import { IProductMarketplace } from '@components/product/interfaces/product-form.interface';
import { ProductMarketplaceResource } from '@components/product/product-marketplace.resource';
import { AbstractService } from '@services/abstract.service';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class SkuSearchService extends AbstractService {

  private subject: Subject<{ skus: ISku[] }> = new Subject<{ skus: ISku[] }>();

  public skus: ISku[];
  public isLoading: boolean = false;
  public observable: Observable<any> = this.subject.asObservable();

  constructor(private productMarketplaceResource: ProductMarketplaceResource) {
    super();
  }

  /**
   * Gets productMarketplace ids with product skus.
   */
  public getSkus(marketplaceCode?: string): void {
    if (!this.skus) {
      this.isLoading = true;

      this.productMarketplaceResource.cGetV2({
          marketplace: marketplaceCode || MarketplaceHelper.getWebsiteMarketplace().code,
          pagination: false,
        },
        { isHydra: true, returnHydraMembers: true, dontUseModel: true, blocking: false }
      )
        .takeUntil(this.destroyed$)
        .subscribe((results: IProductMarketplace[]) => {
          this.skus = this.sortSkus(results);

          this.subject.next({ skus: this.skus });
          this.isLoading = false;
        })
      ;
    }
  }

  public sortSkus(results: IProductMarketplace[]): ISku[] {
    return results.map((result: IProductMarketplace): ISku => {
      return {
        id: result.id,
        sku: result.product.sku
      };
    }).sort((a, b) => {
      if (a.sku < b.sku) { return -1; }
      if (a.sku > b.sku) { return 1; }

      return 0;
    });
  }
}
