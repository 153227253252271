import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services/auth.service';
import { IndicatorResource } from '@resources/indicator.resource';
import { IndicatorInterface } from '@interfaces/indicator.interface';
import { BASE_URL } from '@constants';

@Component({
  selector: 'app-indicator-history',
  template: require('./indicator-history.component.html'),
  styles: [require('./indicator-history.component.scss')],
})
export class IndicatorHistoryComponent extends AbstractComponent implements OnInit {

  @Output() update = new EventEmitter();

  @Input() public businessObject: string;
  @Input() public businessObjectId: string;
  @Input() public type: string;
  @Input() public label: string;
  @Input() public editable: boolean = false;
  @Input() public roleSyncro: string;

  @Input() public indicator: IndicatorInterface;
  readonly BASE_URL = BASE_URL;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    private indicatorResource: IndicatorResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, null, state);
  }

  ngOnInit() {
    if (!this.indicator) {
      this.fetchData();
    }
    this.roleSyncro = 'ROLE_WALISOFT_ORDER_EDIT';
  }

  fetchData() {
    this.indicatorResource.getHistory( this.businessObjectId, this.businessObject, this.type)
    .subscribe(
      data => {
        this.indicator =  <IndicatorInterface> data;
      }
    );
  }

  setStatus(businessObject: string, businessObjectId: number, type: string, status: 'green' | 'orange' | 'red') {
    const indicator = {
      businessObjectId: '' + businessObjectId,
      businessObject: businessObject,
      type: type,
      status: status,

    };
    this.createIndicator(indicator);
  }

  private createIndicator(indicator: { businessObjectId: string, businessObject: string, type: string, status: string, extraData?: any }) {
    this.indicatorResource.create(indicator).subscribe(
      response => {
        this.state.go(this.state.current, this.state.params, { reload: true });
      }
    );
  }
}
