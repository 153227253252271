export default trackingSalesRoutes;

/** @ngInject */
function trackingSalesRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'walipush',
    sidebarSubNav: 'tracking-sales',
    title: '',
    reloadChangeCountry: 'tracking-sales.list',
  };

  $stateProvider
    .state('tracking-sales', {
      url: '/tracking-sales',
      parent: 'walipush',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.TRACKING_SALES',
        stateName: 'tracking-sales.list'
      }
    })
    .state('tracking-sales.list', {
      url: `?
        page
        &byPage
        &sku
        &sp
        &ss
        &priceMin
        &priceMax
        &ets
        &from
        &to
        &countries[]
        &warehouse
        &pricingAlert
        &sort_sku
        &sort_sp
        &sort_ss
        &sort_priceMin
        &sort_priceMax
        &sort_ets
      `,
      views: {
        'page@layout': {
          template: '<app-tracking-sales-list></app-tracking-sales-list>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.TRACKING_SALES.LIST.TITLE',
        showCountries: false
      })
    })
  ;
}
