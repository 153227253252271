import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { AbstractResource } from '../resources/abstract.resource';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';

@Injectable()
@Resource({
  entryPoint: '/v2/carriers',
})
export class CarrierResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  updateConfigurations(id: string, body: object, options: any = {}): Observable<object> {
    return super.update(id, body, {entryPoint: `${this.entryPoint}/${id}/configurations`, ...options});
  }

}
