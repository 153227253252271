import { ITaskCategory, ITaskType, ITaskTypeTranslation } from '../interfaces';
import { ITaskAdditionalProperty } from './task-additional-property.interface';
import { IUser } from '@models/user.interface';
import { SessionHelper } from '@helpers';

export class TaskTypeModel implements ITaskType {
    public '@id': string;
    public id: string;
    public translations: {
        [key: string]: ITaskTypeTranslation;
    };
    public commentActivated: boolean;
    public additionalProperties: ITaskAdditionalProperty[];
    public name: string;
    public businessObject: string;
    public from: IUser[];
    public to: IUser[];
    public target: string;
    public taskTypeCategory: ITaskCategory;
    public categoryName: string;

  constructor(taskType: ITaskType = {
      '@id': '',
      id: '',
      commentActivated: false,
      additionalProperties: null,
      translations: null,
      name: '',
      businessObject: '',
      from: null,
      to: null,
      taskTypeCategory: null
  }) {
    this['@id'] = taskType['@id'];
    this.id = taskType.id;
    this.translations = taskType.translations;
    this.commentActivated = taskType.commentActivated;
    this.additionalProperties = taskType.additionalProperties;
    this.name = this.retrieveTranslationsForLocales(taskType.translations);
    this.businessObject = taskType.businessObject ? taskType.businessObject : '';
    this.from = taskType.from;
    this.to = taskType.to;
    this.taskTypeCategory = taskType.taskTypeCategory;

    if (taskType.businessObject) {
      this.target = `PAGE.TASK_MANAGER.TASK_TYPE.LIST.TABLE.BODY.TARGET.${taskType.businessObject.toUpperCase().replace('-', '_')}`;
    }

    if (taskType.taskTypeCategory && taskType.taskTypeCategory.translations) {
        this.categoryName = this.retrieveTranslationsForLocales(taskType.taskTypeCategory.translations);
    }
  }

  private retrieveTranslationsForLocales(
      translations: any
  ) {
      const countryTranslationKey = SessionHelper.getUILanguage();
      let name = '';
      Object.keys(translations).map((translation: any) => {
          if (countryTranslationKey === translation) {
            name = translations[translation].name;
          }
      });

      return name;
  }
}
