import { Component, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { IRefundsList } from '@components/transactions/refunds/models/refunds.interface';
import { TransactionsResource } from '@components/transactions/transactions.resource';
import { takeUntil } from 'rxjs/operators';
import { SnackbarService } from '@components/snackbar';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { HydraHelper } from '@helpers/HydraHelper';
import { CountryHelper, SessionHelper } from '@helpers';

@Component({
  selector: 'app-refunds-result-list',
  template: require('./refunds-result-list.component.html'),
  styles: [require('./refunds-result-list.component.scss')]
})
export class RefundsResultListComponent extends AbstractComponent implements OnInit {

  public items: IRefundsList[];
  public selectAll: any = {};
  public selectedItems: any = {};

  public statusTransactionList: {label: string, value: string}[] = [];

  @ViewChildren('dropdown') dropdowns: QueryList<DropDownListComponent>;
  public userCanEdit: boolean = false;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    @Inject('DialogService') private dialog: any,
    private snackbar: SnackbarService,
  ) {
    super($translate, authService, resource, state);
  }

  public ngOnInit(): void {
    this.statusTransactionList = [
      {label: this.translate('PAGE.REFUNDS.LIST.STATUSES.WAITING'), value: 'waiting'},
      {label: this.translate('PAGE.REFUNDS.LIST.STATUSES.WAITING_ELEMENTS'), value: 'elements_waiting'},
      {label: this.translate('PAGE.REFUNDS.LIST.STATUSES.WAITING_SAGE'), value: 'sage_waiting'},
      {label: this.translate('PAGE.REFUNDS.LIST.STATUSES.CLOSE'), value: 'close'},
      {label: this.translate('PAGE.REFUNDS.LIST.STATUSES.ERROR'), value: 'error'},
      {label: this.translate('PAGE.REFUNDS.LIST.STATUSES.TO_CHECK'), value: 'to_check'},
      {label: this.translate('PAGE.REFUNDS.LIST.STATUSES.REQUESTED'), value: 'requested'},
    ];
    this.userCanEdit = SessionHelper.getCurrentUserRoles().some(role => role === 'ROLE_WALISOFT_REFUND_EDIT');
  }

  private update(id: string, currentStatus: string, index: number, newStatus: string) {
    if (currentStatus === newStatus) {
      return;
    }

    (<TransactionsResource>this.resource).update(id, { status: newStatus })
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.items.find((item: IRefundsList) => item.id === id).status = newStatus;
        this.snackbar.validate(this.translate('ALERTS.DATA.UPDATE'));
      }, () => this.dropdowns.toArray()[index].writeValue(currentStatus))
    ;
  }

  public archive(id: string): void {
    this.dialog.confirm(this.translate('PAGE.REFUNDS.CONFIRM.ARCHIVE'))
      .then(() => {
        (<TransactionsResource>this.resource).update(id, {archived: true})
          .takeUntil(this.destroyed$)
          .subscribe(() => {
            const filterItems = this.items.filter(item => item.id !== id);
            this.items = filterItems;
          } );
      });
  }

  public toggleSelectAll() {
    this.selectAll[this.getPage()] = !this.selectAll[this.getPage()];

    this.items.forEach(item => {
      if (!this.selectAll[this.getPage()]) {
        delete this.selectedItems[item.id];
      } else {
        this.selectedItems[item.id] = item;
      }
    });
  }

  public getPage(): number {
    return this.state.params.page || 1;
  }

  public handleSelection(item: any): void {
    if (this.selectedItems[item.id]) {
      delete this.selectedItems[item.id];
    } else {
      this.selectedItems[item.id] = item;
    }
  }

  public bulk(status?: string): void {
    this.dialog.confirm(this.translate('ALERTS.CONFIRM.BULK')).then(() => {
      (<TransactionsResource>this.resource).bulk(this.getSelectedIris(), status)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response: any) => {
          this.snackbar.validate(this.translate('ALERTS.DATA.UPDATE'));
          this.state.go(this.state.current, this.state.params, { reload: true });
          this.resetSelection();
        })
      ;
    });
  }

  public getSelectedIris(): any {
    const iris: string[] = [];

    for (const id of Object.keys(this.selectedItems)) {
      iris.push(HydraHelper.buildIri(id, 'transactions'));
    }

    return iris;
  }

  public resetSelection(): void {
    this.selectedItems = {};
  }

  public getComment(comment?: string): string {
    if (!comment) {
      return null;
    }

    if (comment.length < 50) {
      return comment;
    }

    return comment.substring(0, 50) + '...';
  }

  public getTransationStatus(status?: string): string {
    if (status && this.statusTransactionList.some((statusTransaction: any) => statusTransaction.value === status)) {
      return this.statusTransactionList.filter((statusTransaction: any) => statusTransaction.value === status)[0].label;
    }

    return null;
  }

  public goToOrderEdit(event: MouseEvent, id: string): void {
    const editionState = 'order_manager.payment';

    if (event.ctrlKey) {
      window.open(this.state.href(editionState, { id }, { absolute: true }), '_blank');
      return;
    }

    this.state.go(editionState, { id });
  }

  public getCountryCodeFromLocale(locale: string): string {
    return CountryHelper.getCountryCodeFromLocale(locale);
  }
}
