import { Component, Inject, Input } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AbstractResource } from '@resources/abstract.resource';
import { AuthService } from '@services/auth.service';
import { BASE_URL_INVOICE_ORDER } from '@constants/config.constants';
import { OrderResource } from '@resources';
import { IHydraMember } from '@interfaces/hydra-resource.interface';
import { takeUntil } from 'rxjs/operators';
import { DialogService } from '@progress/kendo-angular-dialog';
import { OrderManagerListModel } from '@components/order-manager/models';
import { SessionHelper } from '@helpers';
import { ICountry } from '@interfaces';
import { CommentDialogComponent } from '../components/comment-dialog.component';
import { IOrderManagerListLight, IReverse } from '../models/order-manager-list-light.interface';

@Component({
    selector: 'app-order-manager-result-list-light',
    template: require('./order-manager-result-list-light.component.html'),
    styles: [require('./order-manager-result-list-light.component.scss')],
})
export class OrderManagerResultListLightComponent extends AbstractComponent {

    public BASE_URL_INVOICE_ORDER = BASE_URL_INVOICE_ORDER;

    @Input() public forcedItems?: any[] = null;
    comments: any;

    private _items: any[];

    set items(items: any[]) {
        this._items = items;
    }

    get items(): any[] {
        return null !== this.forcedItems ? this.forcedItems : this._items;
    }

    constructor(
        @Inject('TranslationService') $translate: ng.translate.ITranslateService,
        authService: AuthService,
        resource: AbstractResource,
        private orderResource: OrderResource,
        private dialogService: DialogService,
        @Inject('StateService') state: ng.ui.IStateService) {
        super($translate, authService, resource, state);
    }

    public getTranslation(product: any, locale: string): string {
        const countriesParent = SessionHelper.getApplicationActiveCountriesWithParent().filter((country: ICountry) =>
            country.locales.includes(locale) && country.parent
        );

        if (countriesParent[0] && countriesParent[0].parent && countriesParent[0].parent.locales[0]) {
            locale = countriesParent[0].parent.locales[0];
        }

        if (product && product.translations && product.translations[locale] && product.translations[locale].shortTitle) {
            return product.translations[locale].shortTitle;
        }

        return '';
    }

    public goToProduct(event: MouseEvent, productUri: string) {
        const id = +(productUri.split('/').slice(-1).find(() => true));
        const state = 'product_light.view';

        if (event.ctrlKey) {
            window.open(this.state.href(state, { id }, { absolute: true }), '_blank');
            return;
        }
        this.state.go(state, { id });
    }

    public goToReverse(event: MouseEvent, order: IOrderManagerListLight) {
        const params = order.reverse ? {
            id: order.reverse.id
        } : {
            order: order.id
        };
        const state = order.reverse ? 'reverses.edit' : 'reverses.new';

        if (event.ctrlKey) {
            window.open(this.state.href(state, params, { absolute: true }), '_blank');
            return;
        }
        this.state.go(state, params);
    }

    isOrderTypeUnpaired(order: any): boolean {
        return order.unpaired;
    }

}
