import {Inject, Injectable} from '@angular/core';
import {FormNotifierService} from '@services/form-notifier.service';
import {ComboSearchField} from '@components/generic/Form/dynamic/fields';
import {AbstractFiltersFieldsService} from '@components/generic/Form/filters/abstract-filters-fields.service';
import {AbstractFormFieldBase} from '@components/generic/Form/dynamic/fields/abstract-form-field-base.class';
import {SessionHelper} from '@helpers';
import {CatalogsResource} from '@components/catalogs';
import {ICatalogs} from '@components/catalogs/models';

@Injectable()
export class MarketplacesCommissionFiltersService extends AbstractFiltersFieldsService {
  public catalogsField: ComboSearchField = new ComboSearchField({
    fieldName: 'catalog',
    label: 'PAGE.PRODUCT.LIST.FILTER.CATALOG.LABEL',
    data: null,
    textField: 'label',
    valueField: 'id',
    // tslint:disable-next-line:radix
    value: this.filters.get('catalog') ? parseInt(this.filters.get('catalog')) : undefined,
    valuePrimitive: true,
    order: 1
  });

  constructor(
    @Inject('StateService') protected state: ng.ui.IStateService,
    @Inject('TranslationService') public translate: ng.translate.ITranslateService,
    formNotifier: FormNotifierService,
    private catalogsResource: CatalogsResource,
  ) {
    super(formNotifier, state);

    this.catalogsResource.cGet({ locale: SessionHelper.getLocale() })
      .takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        this.catalogsField.data = response['hydra:member'].filter((catalog: ICatalogs) => !!catalog.label);
      });
  }

  public getFields(): AbstractFormFieldBase<any>[] {
    return [
      this.catalogsField,
      new ComboSearchField({
        fieldName: 'marketplaceCode',
        label: 'PAGE.CATEGORIES_MARKETPLACE.FILTERS.MARKETPLACE.LABEL',
        data: SessionHelper.getMarketplaces(),
        textField: 'commercialName',
        valueField: 'code',
        value: this.filters.get('marketplaceCode') ? this.filters.get('marketplaceCode') : undefined,
        valuePrimitive: true,
        order: 1,
      }),
    ];
  }

  getComplementaryFields(): AbstractFormFieldBase<any>[] {
    return [];
  }
}
