import * as moment from 'moment';
import { FULL_EUROPEEN_DATE_FORMAT } from '@constants';

export class StockImportListModel {
  id: string;
  warehouse: string;
  startDate: string;
  endDate: string;

  constructor(data: any) {
    this.id = data.id;
    this.warehouse = data.warehouse ? data.warehouse.code : null;
    this.startDate = data.startDate ? moment(data.startDate).format(FULL_EUROPEEN_DATE_FORMAT) : null;
    this.endDate = data.endDate ? moment(data.endDate).format(FULL_EUROPEEN_DATE_FORMAT) : null;
  }
}
