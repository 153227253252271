
import { TabResource } from './';

export default tabRoutes;

/** @ngInject */
function tabRoutes($stateProvider: any) {
  const commonStateData: {[key: string]: any} = {
    sidebarNav: 'tabs',
    title: '',
    reloadChangeCountry: 'tabs.list'
  };

  $stateProvider
    .state('tabs', {
      url: '/tabs',
      parent: 'walisoft',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.TAB',
        stateName: 'tabs.list',
      }
    })
    .state('tabs.list', {
      url: '',
      views: {
        'page@layout': {
            template: '<app-tab-list></app-tab-list>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.TAB.LIST.TITLE',
        resource: TabResource
      })
    })
    .state('tabs.new', {
      url: '/new',
      breadcrumb: {
        label: 'BREADCRUMB.NEW'
      },
      views: {
        'page@layout': {
          template: '<app-tab></app-tab>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.TAB.NEW.TITLE',
      })
    })
    .state('tabs.edit', {
      url: '/:id',
      breadcrumb: {
        label: 'BREADCRUMB.EDIT'
      },
      views: {
        'page@layout': {
          template: '<app-tab></app-tab>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.TAB.EDIT.TITLE',
      })
    })
  ;
}
