import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { ExportResource } from '@resources/export.resource';
import { takeUntil } from 'rxjs/operators';
import { SnackbarService } from '@components/snackbar';
import { CsvDataService } from '@services';

@Component({
  selector: 'app-export-selection',
  template: require('./export-selection.component.html')
})
export class ExportSelectionComponent extends AbstractPageComponent {

  @Input() public businessObject: string;
  @Input() public businessObjectIds: any[];
  @Input() public serializationGroups: string[];
  @Input() public label: string;
  @Input() public class: string;

  @Output() public onExport: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    @Inject('StateService') state: ng.ui.IStateService,
    authService: AuthService,
    resource: ExportResource,
    private snackbar: SnackbarService,
    private csvDataService: CsvDataService,
  ) {
    super($translate, authService, resource, state);
  }

  public export(): void {
    this.resource.exportSelection(this.businessObject, this.businessObjectIds, this.serializationGroups)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        this.csvDataService.exportToCsv('export.csv', response);
        this.snackbar.validate(this.translate('ALERTS.DATA.UPDATE'));
        this.onExport.emit(response);
      })
    ;
  }
}
