import { Component, ContentChild, EventEmitter, Inject, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services/auth.service';
import { SnackbarService } from '@components/snackbar';
import { AbstractResource } from '@resources/abstract.resource';

export interface IDndColumn {
  size: number;
  title: string;
  property: string;
  type?: string;
  handleColor?: boolean;
}

@Component({
  selector: 'app-dnd-list',
  template: require('./dnd-list.component.html'),
  styles: [require('./dnd-list.component.scss')],
})
export class DndListComponent extends AbstractComponent implements OnInit {
  /**
   * Columns of the list.
   */
  @Input() public columns: IDndColumn[];

  /**
   * Items for the list.
   */
  @Input() public items: object[];

  /**
   * Roles for displaying actions.
   */
  @Input() public rolesForActions: string[];

  /**
   * Method used to update position
   */
  @Input() public methodForUpdate: string = 'update';

  /**
   * Custom and fixed row height
   */
  @Input() public rowHeight: string;

  @Input() public allowUpdate: boolean;
  @Input() public allowDelete: boolean;

  @Output() public dragEnd: EventEmitter<any> = new EventEmitter();
  @Output() public customDelete: EventEmitter<any> = new EventEmitter();

  @ContentChild(TemplateRef) customColumnContent: TemplateRef<any>;

  public deleteAvailable: boolean = false;
  public customDeleteAvailable: boolean = false;
  public updateAvailable: boolean = true;

  private movedElement: HTMLElement;
  private headerBottomCoordinates: number;
  private footerTopCoordinates: number;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private snackbar: SnackbarService,
    @Inject('DialogService') private dialog: any,
    resource: AbstractResource,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {

    if (undefined === this.resource) {
      throw Error('A resource must be provided by the parent list component');
    }

    this.customDeleteAvailable = this.customDelete.observers.length > 0;
    this.deleteAvailable = this.resource.deleteAvailable;
    this.headerBottomCoordinates = document.querySelector('.page-header.navbar.navbar-fixed-top').getBoundingClientRect().bottom;
    this.footerTopCoordinates = document.querySelector('.page-footer').getBoundingClientRect().top;
    this.updateAvailable = undefined !== this.allowUpdate ? this.allowUpdate : <boolean>this.resource.updateAvailable;
  }

  /**
   * Begin at drag start.
   * Keep the moved element in memory.
   */
  public dragstart(ev: DragEvent): void {
    ev.dataTransfer.setData('text/html', null);
    ev.dataTransfer.effectAllowed = 'move';

    this.movedElement = <HTMLElement>ev.target;
    this.movedElement.classList.add('moved-element');
  }

  /**
   * We must exchange positions between the moved element and the overflew element when the moved element enter on an overflew element.
   *
   * Rules:
   *     - a parent can be reorder with another parent, TODO: it can become a child element if it has no child.
   *     - a child can be reorder with another child even if the another child is contained by another parent.
   *     - a child can be put in a parent that haven't children.
   */
  public dragenter(ev: DragEvent): void {
    ev.stopImmediatePropagation();

    const overflewElem: HTMLElement = (<HTMLElement>(<HTMLElement>ev.target).closest('.draggable'));
    const childrenContainer: HTMLElement = overflewElem.querySelector('.dnd-list__body--children');

    this.handleScroll(ev, overflewElem);

    if (this.canAppendElementToParentWithoutChild(childrenContainer)) {
      childrenContainer.appendChild(this.movedElement);

      return;
    }

    if (this.canElementsCanBeReordered(overflewElem)) {
      this.reorderElements(overflewElem);
    }
  }

  /**
   * If a list has a lot of item:
   *  - if the top coordinate of overflew element is smaller than bottom coordinate of the header we must scroll up
   *  - if the bottom coordinate of overflew element is taller than top coordinate of the footer we must scroll down
   */
  private handleScroll(ev: DragEvent, overflewElem: HTMLElement): void {
    if (this.headerBottomCoordinates >= overflewElem.getBoundingClientRect().top) {
      window.scrollBy(0, -(<HTMLElement>ev.target).scrollHeight);
    }

    if (this.footerTopCoordinates <= overflewElem.getBoundingClientRect().bottom) {
      window.scrollBy(0, (<HTMLElement>ev.target).scrollHeight);
    }
  }

  /**
   * Is moved element is a child and overflew element has an empty children container?
   */
  private canAppendElementToParentWithoutChild(childrenContainer: HTMLElement): boolean {
    return !this.movedElement.dataset.root && childrenContainer && 0 === childrenContainer.children.length;
  }

  /**
   * Can elements can be reorder?
   * Rules:
   *     - moved elem and overflew elem are both root
   *     - moved elem and overflew elem are both child
   *     - TODO: moved elem is a root that has no children
   *
   *  Legend:
   *      - !!this.movedElement.dataset.root and !!overflewElem.dataset.root are both parent (data-root attr is present)
   *      - !this.movedElement.dataset.root and !overflewElem.dataset.root are both child (data-root attr is not present)
   */
  private canElementsCanBeReordered(overflewElem: HTMLElement): boolean {
    return !!this.movedElement.dataset.root && !!overflewElem.dataset.root ||
           !this.movedElement.dataset.root && !overflewElem.dataset.root
    ;
  }

  private reorderElements(overflewElem: HTMLElement): void {
    const tempPosition = this.movedElement.dataset.position;

    this.movedElement.dataset.position = overflewElem.dataset.position;
    overflewElem.dataset.position = tempPosition;

    if (+this.movedElement.dataset.position < +overflewElem.dataset.position) {
      const insertAfter = true;
      this.exchangeElements(overflewElem, insertAfter);
    }

    if (+this.movedElement.dataset.position > +overflewElem.dataset.position) {
      this.exchangeElements(overflewElem);
    }
  }

  private exchangeElements(overflewElem: HTMLElement, insertAfter: boolean = false): void {
    const parentNode = overflewElem.closest('.dnd-list__body');
    parentNode.replaceChild(this.movedElement, overflewElem);

    const referenceNode = insertAfter ? this.movedElement.nextElementSibling : this.movedElement;
    parentNode.insertBefore(overflewElem, referenceNode);

    // add child class to a parent that become a child then remove it when it become parent
    if (this.movedElement.closest('.dnd-list__body--children')) {
      this.movedElement.classList.add('dnd-list__body__item--child');

      return;
    }

    this.movedElement.classList.remove('dnd-list__body__item--child');
  }

  /**
   * Remove style at drag end after a certain time.
   * (Don't use the reference, because if another elem start dragging, the class could not be remove on the precedent element dragged.)
   * Make update request with the move element id and its position.
   * If element is a child, we must add the parent to the request, because it can be place in another parent.
   */
  public dragend(ev: DragEvent): void {
    ev.preventDefault();
    ev.stopImmediatePropagation();
    const movedElement = (<HTMLElement>ev.target);
    const childrenContainer = movedElement.closest('.dnd-list__body--children');
    const parent = childrenContainer ?
      `api${this.resource.entryPoint}/${(<HTMLElement>childrenContainer.closest('[data-root]')).id}` :
      null
    ;

    setTimeout(() => movedElement.classList.remove('moved-element'), 1000);

    if (this.dragEnd.observers.length > 0) {
      this.dragEnd.emit({ id: movedElement.id, position: +movedElement.dataset.position, parent: parent });

      return;
    }

    this.resource[this.methodForUpdate](movedElement.id, { position: +movedElement.dataset.position, parent })
      .takeUntil(this.destroyed$)
      .subscribe(() => this.snackbar.validate(this.translate('SNACKBAR.VALIDATE.LIST.UPDATE.POSITION')))
    ;
  }

  /**
   * Navigates to edition view.
   */
  public goToEdit(item: any) {
    item.editUrl
    ? this.state.go(`${item.editUrl}.edit`, {id: item.editUrlId})
    : this.state.go(`${this.resource.routeName}.edit`, {id: item.id});
  }

  /**
   * Removes tab from list.
   */
  public delete(id: string) {
    this.dialog.confirm(this.translate(`PAGE.${this.resource.translationKey}.CONFIRM.DELETE`))
      .then(() => {
        this.resource.remove(id)
          .takeUntil(this.destroyed$)
          .subscribe(() => this.state.go(`${this.resource.routeName}.list`, null, {reload: true}))
        ;
      })
    ;
  }

  public handleCustomDelete(id: string) {
    this.customDelete.emit({id: id});
  }
}
