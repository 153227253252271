import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { Component, Inject } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources';
import {
  MarketplacesCommissionResource
} from '@components/marketplaces-configure/commission/marketplaces-commission.resource';
import {SessionHelper} from '@helpers';
import {AbstractFiltersFieldsService} from '@components/generic/Form/filters/abstract-filters-fields.service';
import {MarketplacesCommissionFiltersService} from '@components/marketplaces-configure/commission/services/marketplaces-commission-filters.service';

@Component({
  selector: 'app-marketplaces-commission',
  template: require('./marketplaces-commission.component.html'),
  providers: [
    { provide: AbstractResource, useClass: MarketplacesCommissionResource },
    { provide: AbstractFiltersFieldsService, useClass: MarketplacesCommissionFiltersService }
  ],
})
export class MarketplacesCommissionComponent extends AbstractPageComponent {
  public currentLocales: string[] = SessionHelper.getCountry().locales;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, null, state);
  }
}
