import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { AuthService } from '@services/auth.service';
import { OrderManagerResource } from './order-manager.resource';
import { AbstractResource } from '@resources';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { OrderManagerFiltersFormService} from '@components/order-manager/services/order-manager-filters-form.service';
import { TabStripComponent } from '@progress/kendo-angular-layout';
import { IImportOptions } from '@interfaces/ImportOptions.interface';
import { SessionHelper } from '@helpers';

@Component({
  selector: 'app-order-manager-list',
  template: require('./order-manager-list.component.html'),
  providers: [
    { provide: AbstractResource, useClass: OrderManagerResource },
    { provide: AbstractFiltersFieldsService, useClass: OrderManagerFiltersFormService },
  ]
})

export class OrderManagerListComponent extends AbstractPageComponent implements OnInit {

  @ViewChild('tabstrip') public tabstrip: TabStripComponent;

  public importOptions: IImportOptions[] = [];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    public resource: AbstractResource,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit() {
    if (['william@walibuy.com', 'fdubois@walibuy.com', 'gpecro@walibuy.com'].includes(SessionHelper.getUser().email)) {
      this.importOptions.push({
        entryPoint: `/api/v2/import`,
        importButton: 'BUTTON.IMPORT.CSV',
        businessObject: 'order',
      });
    }
  }

  onKpiClickEvent() {
    this.tabstrip.selectTab(0);
  }
}
