import { IOrderManagerList } from './order-manager-list.interface';
import { SessionHelper } from '@helpers';
import { IMarketplace } from '@interfaces';
import { FULL_EUROPEEN_DATE_FORMAT } from '@constants';
import * as moment from 'moment';

export class OrderManagerListModel implements IOrderManagerList {

  id: string;
  orderId: string;
  date: string;
  marketplace: string;
  country: string;
  shippingAddress: any;
  billingAddress: any;
  items: any [];
  locale: string;
  references: any;
  totalPrice: string;
  uniqueKey: string;
  unpaired: boolean;
  detached: boolean;
  shippingDelay: boolean;
  marginPercentage: number;
  numberOfComments: number;
  delayReason: string;
  orderItemServices: any[];
  paymentType: string;
  hasPrivateData: boolean;

  constructor(order: any) {
    this.id = order.id;
    this.orderId = order.orderId;
    this.date = moment(order.date).format(FULL_EUROPEEN_DATE_FORMAT);
    this.marketplace = this.setMarketplaceName(order.marketplace);
    this.country = this.setHtmlImageCountry(order.locale);
    this.shippingAddress = order.shippingAddress;
    this.billingAddress = order.billingAddress;
    this.items = order.items;
    this.locale = order.locale;
    this.references = function (item: any): string {
      const references = order.items.find((i: any) => i.product.sku === item).product.superProduct.translations;
      return references && references[order.locale] ? references[order.locale].reference : '';
    };

    if (this.locale === 'en_GB') {
      this.totalPrice = `£ ${order.totalPrice.toFixed(2)}`;
    } else {
      this.totalPrice = `${order.totalPrice.toFixed(2)} €`;
    }

    this.uniqueKey = order.uniqueKey;
    this.unpaired = order.unpaired;
    this.detached = order.detached;
    this.shippingDelay = order.shippingDelay;
    this.marginPercentage = order.marginPercentage;
    this.numberOfComments = order.numberOfComments;
    this.delayReason = order.delayReason && order.delayReason.translations[this.locale]
      ? order.delayReason.translations[this.locale].label
      : null
    ;
    this.paymentType = order.paymentType;
    this.hasPrivateData = order.privateData;
  }

  private setHtmlImageCountry(locale: any): string {
    let html = locale;
    try {
      const countryCode = locale.split('_')[1];
      const src = `../images/flags/${countryCode.toLowerCase()}.png`;
      html = `<img src="${src}" alt="${countryCode}"/>`;
    } catch (error) {
      console.error(error);
    }
    return html;
  }

  private setMarketplaceName(marketplaceCode: any) {
    let marketplaceName = marketplaceCode;
    try {
      const marketplace = SessionHelper.getAllMarketplaces().find((item: IMarketplace) => item.code === marketplaceCode);

      if (marketplace && marketplace.commercialName) {
        marketplaceName = marketplace.commercialName;
      } else if (marketplace && marketplace.name) {
        marketplaceName = marketplace.name;
      }
    } catch (error) {
      console.error(error);
    }
    return marketplaceName;
  }
}
