import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { SessionHelper } from '@helpers/session.helper';
import { AbstractResource } from '@resources/abstract.resource';
import { SnackbarService } from '@components/snackbar';
import { IPaymentCarrierIcons } from '@components/payment-carrier-icons/models';
import { IDndColumn } from '@components/generic/dnd-list/dnd-list.component';

@Component({
  selector: 'app-list',
  template: require('./list.component.html')
})

export class ListComponent extends AbstractPageComponent implements OnInit {

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private snackbarService: SnackbarService,
    @Inject('DialogService') private dialog: any,
  ) {
    super($translate, authService, resource, state);
  }

  public list: IPaymentCarrierIcons[];
  public columns: IDndColumn[];

  ngOnInit(): void {
    this.columns = [{
      size: 10,
      title: this.translate('PAGE.PAYMENT_CARRIER_ICONS.LIST.COLUMN.IMAGE'),
      property: 'picto',
      type: 'img',
    }];
    this.fetch();
  }

  public fetch(): void {
    const BASE_URL: string = process.env.BASE_URL;

    this.resource.cGet({locale: SessionHelper.getLocale()})
      .takeUntil(this.destroyed$)
      .subscribe((response: IPaymentCarrierIcons[]) => {
        this.list = response;
        this.list.forEach(icon => icon.picto = `${BASE_URL}/${icon.picto}`);
      });
  }

  public updatePosition(event: any): void {
    this.resource.partialUpdate(event.id, {position: event.position})
      .takeUntil(this.destroyed$)
      .subscribe(() => {
        this.snackbarService.validate('ALERTS.DATA.UPDATE');
      });
  }

  public delete(event: any): void {
    this.dialog.confirm(this.translate('PAGE.PAYMENT_CARRIER_ICONS.LIST.CONFIRM.DELETE'))
      .then(() => {
        this.resource.remove(event.id)
          .takeUntil(this.destroyed$)
          .subscribe(() => {
            this.snackbarService.validate('ALERTS.DATA.UPDATE');
            this.actions.list.go('', {reload: true});
          });
      });
  }
}
