import { Injectable } from '@angular/core';
import { AbstractResource } from '@resources';
import { Resource } from '@decorators/Resource';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  entryPoint: '',
  translationKey: 'TAB',
  isHydra: true,
})
export class TabImagesResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }
}
