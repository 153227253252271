import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AuthService } from '@services';
import { AbstractFormComponent } from '@components/generic/Form/abstract-form.component';
import { ICategory, IFormViolation } from '@interfaces';
import { FormService } from '@services/form.service';
import { CountryHelper, SessionHelper } from '@helpers';
import { CategoryResource } from '@resources';

@Component({
  selector: 'app-seo-form-hreflang',
  template: require('./seo-form-hreflang.component.html'),
  providers: [FormService]
})
export class SeoFormHreflangComponent extends AbstractFormComponent implements OnInit {
  @Input() public form: FormGroup;
  @Input() public parentForm: FormGroup;
  @Input() public violations: IFormViolation[] = [];
  @Input() public index: any;

  public categoriesNames: any[] = [];
  public locales: any = [];

  @Output() public onDelete: EventEmitter<object> = new EventEmitter();

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private categoryResource: CategoryResource
  ) {
    super($translate, authService, null, state);
  }

  ngOnInit() {
    this.locales = CountryHelper.getAllLocalesForApplication();
    this.locales.splice(this.locales.indexOf('fr_FR'), 1);
    if (this.form.value.id !== null) {
      this.fetch();
    } else {
      this.form.valueChanges.subscribe(value => {
        if (value.locale) {
          this.fetch();
        }
      });
    }
  }

  public fetch() {
    this.categoryResource.getAllNames({ locale: this.form.get('locale').value }, { dontUseModel: true })
      .subscribe((response: ICategory[]) => {
        this.categoriesNames = response;
      });
  }

  public delete(): void {
    this.onDelete.emit(this.index);
  }

}
