import { SessionHelper } from './../../../helpers/session.helper';
import { ICarrierGroup } from './choice-carrier-groups-form.component';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CarrierService, WarehousesService } from '@components/export-logistics/services';
import { IWarehouse } from './choice-warehouses-form.component';

@Component({
  selector: 'app-choice-carrier-scaling-form',
  template: require('./choice-carrier-scaling-form.component.html'),
})
export class ChoiceCarrierScalingFormComponent extends AbstractComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public add: boolean = false;
  public warehousesChecked: IWarehouse[] = [];
  public _carrierGroupsChecked: ICarrierGroup[] = [];
  private checkedDays: boolean[] = [];

  get fromCarrierGroupsCheckedList(): ICarrierGroup[] {
    return this._carrierGroupsChecked;
  }

  get toCarrierGroupsCheckedList(): ICarrierGroup[] {
    const selectedFromCarrierGroup = this.form.get('fromCarrierGroup').value || null;
    return this._carrierGroupsChecked.filter((carrierGroup: ICarrierGroup) => {
      return selectedFromCarrierGroup ? selectedFromCarrierGroup.iri !== carrierGroup.iri : true;
    });
  }

  @Input() private scheduleTableValues: any;

  @Output() public onFormChanged: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    public carrierService: CarrierService,
    private warehousesService: WarehousesService
  ) {
    super($translate, authService, null, state);
  }

  ngOnInit(): void {
    this.warehousesChecked = this.warehousesService.getWarehousesChecked();
    this._carrierGroupsChecked = this.carrierService.getCarrierGroupsChecked();
    this.checkedDays = this.scheduleTableValues.map((hours: boolean[]) => hours.some((hour: boolean) => hour === true));

    this.buildForm();

    let warehouse,
      fromCarrierGroup,
      toCarrierGroup,
      add = true;
    const active = true,
      fromVolume = 0,
      toVolume = 0,
      maxCarriersDiffCost = 0,
      locale = SessionHelper.getLocale();
    if (!this.hasOneWarehouseSelected()) {
      add = false;
      this.form.disable();
    }
    if (this.hasOneWarehouseSelected()) {
      warehouse = this.warehousesChecked[0].iri;
    }
    if (this._carrierGroupsChecked.length === 2) {
      fromCarrierGroup = this._carrierGroupsChecked[0];
      toCarrierGroup = this._carrierGroupsChecked[1];
    }
    this.form.patchValue(
      Object.assign({ add, warehouse, fromCarrierGroup, toCarrierGroup, fromVolume, toVolume, maxCarriersDiffCost, locale, active })
    );
    this.add = add;
  }

  ngOnDestroy(): void {
    this.form = null;
    this.warehousesChecked = [];
    this._carrierGroupsChecked = [];
    this.checkedDays = [];
  }

  public buildForm(): void {
    this.form = this.formBuilder.group({
      add: [true],
      warehouse: [null, Validators.required],
      fromCarrierGroup: [null, Validators.required],
      fromVolume: [0, [Validators.required, Validators.min(0)]],
      toCarrierGroup: [null],
      toVolume: [0, [Validators.min(0)]],
      maxCarriersDiffCost: [0, [Validators.min(0), Validators.max(100)]],
      locale: [null],
      days: this.formBuilder.group({
        monday: [this.checkedDays[0]],
        tuesday: [this.checkedDays[1]],
        wednesday: [this.checkedDays[2]],
        thursday: [this.checkedDays[3]],
        friday: [this.checkedDays[4]],
        saturday: [this.checkedDays[5]],
        sunday: [this.checkedDays[6]],
      }),
      active: [true],
    });

    this.form.valueChanges.subscribe(() => {
      this.onFormChanged.emit({
        values: this.form.getRawValue(),
        valid: this.form.valid,
      });
    });

    this.form.get('fromCarrierGroup').valueChanges.subscribe(() => {
      const fromCarrierGroup = this.form.get('fromCarrierGroup').value || null;
      const toCarrierGroup = this.form.get('toCarrierGroup').value || null;
      if (fromCarrierGroup !== null && toCarrierGroup !== null && fromCarrierGroup.iri === toCarrierGroup.iri) {
        this.form.patchValue({ toCarrierGroup: null }, { onlySelf: true, emitEvent: true });
      }
    });

    this.disableInvalidDays();
    this.handleAutoScaleFields();
  }

  public hasOneWarehouseSelected(): boolean {
    return this.warehousesChecked.length === 1;
  }

  public switchAdd(add: boolean): void {
    this.add = add;
    add ? this.form.enable() : this.form.disable();
    this.form.get('add').enable();
    this.disableInvalidDays();
  }

  private disableInvalidDays(): void {
    const daysControlNames = Object.keys((<FormGroup>this.form.get('days')).controls);
    for (let i = 0; i < this.checkedDays.length; i++) {
      if (!this.checkedDays[i]) {
        this.form.get('days').get(daysControlNames[i]).disable();
      }
    }
  }

  public handleAutoScaleFields(): void {
    const toCarrier = this.form.get('toCarrierGroup').value;

    if (!toCarrier || !toCarrier.iri) {
      this.form.get('toVolume').setValue(0);
      this.form.get('maxCarriersDiffCost').setValue(0);
      this.form.get('toVolume').disable();
      this.form.get('maxCarriersDiffCost').disable();
    } else {
      this.form.get('toVolume').enable();
      this.form.get('maxCarriersDiffCost').enable();
    }
  }
}
