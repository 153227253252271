import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { Component, Inject } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { CarrierFamilyResource } from '@components/carrierFamily';
import { AbstractResource } from '@resources/abstract.resource';

@Component({
  selector: 'app-carrier-family-list',
  template: require('./carrier-family-list.component.html'),
  providers: [
    { provide: AbstractResource, useClass: CarrierFamilyResource },
  ]
})
export class CarrierFamilyListComponent extends AbstractPageComponent {

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }
}
