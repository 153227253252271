'use strict'

angular
  .module 'ammsFrontendApp'
  .controller 'OrderModalChangeAlertCtrl', [
    '$scope'
    '$uibModalInstance'
    'change'
    ($scope, $uibModalInstance, change) ->
      $scope.change = change

      $scope.ok = (reason) ->
        $uibModalInstance.close reason

      $scope.cancel = ->
        $uibModalInstance.dismiss 'cancel'
  ]
