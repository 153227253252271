import { AbstractResource } from '../../resources/abstract.resource';
import { Injectable } from '@angular/core';
import { WarehouseCarrierGroupAgenciesModel } from './models/warehouse-carrier-group-agencies.model';
import { Resource } from '@decorators/Resource';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  routeName: 'warehouses',
  entryPoint: '/v2/warehouse_carrier_group_agencies',
  translationKey: 'WAREHOUSE_CARRIER_AGENCIES',
  model: WarehouseCarrierGroupAgenciesModel,
  createAvailable: false,
  deleteAvailable: false,
  updateAvailable: false,
  isHydra: true
})
export class WarehouseCarrierGroupAgenciesResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }
}
