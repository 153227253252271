import { Component, Inject } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { OrderCollectionInterface } from '@components/order-collection/models';

@Component({
  selector: 'app-order-collection-result-list',
  template: require('./order-collection-result-list.component.html')
})
export class OrderCollectionResultListComponent extends AbstractComponent {

  private _items: OrderCollectionInterface[];

  set items(items: OrderCollectionInterface[]) {
    this._items = items;
  }

  get items(): OrderCollectionInterface[] {
    return this._items;
  }

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }

  goToEdit(orderId: any) {
    this.state.go(`order_manager.edit`, { id: orderId });
  }
}
