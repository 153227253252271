export default serviceRoutes;

/** @ngInject */
function serviceRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'walipush',
    sidebarSubNav: 'service',
    title: '',
    reloadChangeCountry: 'service.list',
  };

  $stateProvider
    .state('service', {
      url: '/services',
      parent: 'walipush',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.SERVICE',
        stateName: 'service.list'
      }
    })
    .state('service.list', {
      url: `?
        page&
        byPage&
      `,
      views: {
        'page@layout': {
          template: '<app-service-list></app-service-list>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.SERVICE.LIST.TITLE',
      })
    })
    .state('service.new', {
      url: '/new',
      breadcrumb: { label: 'BREADCRUMB.NEW', stateName: 'service.new' },
      views: {
        'page@layout': { template: '<app-service-form></app-service-form>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.SERVICE.NEW.TITLE',
      })
    })
    .state('service.edit', {
      url: '/:id',
      breadcrumb: { label: 'BREADCRUMB.EDIT', stateName: 'service.edit' },
      views: {
        'page@layout': { template: '<app-service-form></app-service-form>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.SERVICE.EDIT.TITLE',
      })
    })
  ;
}
