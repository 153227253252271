import { NgModule } from '@angular/core';
import { GenericFormModule, GenericModule } from '@components/generic';
import { CommonModule } from '@angular/common';
import { BannerFormComponent, BannerFormTranslationComponent } from '@components/banner/form';
import { BannerResource} from '@components/banner/banner.resource';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SwitchModule } from '@progress/kendo-angular-inputs';
import { EnhancedFieldsModule } from '@directives/enhanced-fields.module';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { DirectivesModule } from '@directives/directives.module';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { BannerListComponent } from '@components/banner/list/banner-list.component';
import { BannerResultListComponent } from '@components/banner/list/banner-result-list.component';

@NgModule({
  declarations: [
    BannerFormComponent,
    BannerFormTranslationComponent,
    BannerListComponent,
    BannerResultListComponent,
  ],
  entryComponents: [
    BannerFormComponent,
    BannerListComponent,
  ],
  imports: [
    CommonModule,
    GenericModule,
    FormsModule,
    ReactiveFormsModule,
    GenericFormModule,
    SwitchModule,
    EnhancedFieldsModule,
    TabsModule.forRoot(),
    DirectivesModule,
    TimepickerModule.forRoot()
  ],
  providers: [
    BannerResource,
  ]
})
export class BannerModule {}
