import { Component, Inject } from '@angular/core';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { SessionHelper } from '@helpers';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-website-homepage-slider',
  template: require('./website-homepage-slider.component.html'),
})

export class WebsiteHomepageSliderComponent extends AbstractPageComponent {
  public currentLocales: string[] = SessionHelper.getCountry().locales;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, null, state);
  }

  public hasSeveralTranslations(): boolean {
    return this.currentLocales.length > 1;
  }
}
