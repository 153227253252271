import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GenericFormModule } from '@components/generic/generic-form.module';
import { GenericModule } from '@components/generic/generic.module';
import { AttributeTableComponent } from './attribute-table/attribute-table.component';
import { AttributeTableRowComponent } from './attribute-table/attribute-table-row/attribute-table-row.component';
import { AttributeResource } from '@resources';
import { AttributeSetResource } from '@components/attribute-set/attribute-set.resource';
import { AttributeEditComponent } from './single/edit/attribute-edit.component';
import { AttributeFormComponent } from './single/form/attribute-form.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { AttributeNewComponent } from './single/new/attribute-new.component';
import { AttributeListComponent } from '@components/attribute/list/attribute-list.component';

@NgModule({
  declarations: [
    AttributeListComponent,
    AttributeTableComponent,
    AttributeTableRowComponent,
    AttributeEditComponent,
    AttributeFormComponent,
    AttributeNewComponent,
  ],
  entryComponents: [
    AttributeListComponent,
    AttributeTableComponent,
    AttributeEditComponent,
    AttributeNewComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    DropDownsModule,
    GenericFormModule,
    GenericModule,
  ],
  providers: [
    AttributeResource,
    AttributeSetResource
  ]
})
export class AttributeModule {}
