import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService, FormNotifierService } from '@services';
import { Subscription } from 'rxjs/Subscription';
import { DialogBoxService } from '@services/dialog-box.service';
import { DialogRef, DialogCloseResult } from '@progress/kendo-angular-dialog';
import { FORM_STREAMS } from '../../../../enums/form-notifier-streams.enum';

/**
 * Displays tabs for customer card.
 */
@Component({
  selector: 'app-customer-detail-tabs',
  template: require('./customer-detail-tabs.component.html'),
  styles: [require('./customer-detail-tabs.component.scss')]
})
export class CustomerDetailTabsComponent extends AbstractComponent implements OnInit, OnDestroy {
  public tabs: object[] = [];

  private translationDomain: string = 'PAGE.CUSTOMER_CARD.DETAILS';
  private formInEdition: boolean = false;
  private formNotifierSubscription: Subscription;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private formNotifier: FormNotifierService,
    private dialogBoxService: DialogBoxService,
  ) {
    super($translate, authService, null, state);

    this.tabs = [
      {
        label: this.translate(`${this.translationDomain}.ORDERS.LIST.LINK`),
        state: 'customers.detail.orders',
      },
    ];

    if (this.isGranted(['ROLE_WALISOFT_AGENT'])) {
      this.tabs.push(
        {
          label: this.translate(`${this.translationDomain}.ADDRESSES.LIST.LINK`),
          state: 'customers.detail.addresses',
        },
      );
    }

    if (this.isGranted(['ROLE_WALISOFT_AGENT'])) {
      this.tabs.push(
        {
          label: this.translate(`${this.translationDomain}.MAILS.LIST.LINK`),
          state: 'customers.detail.mails',
        },
      );
    }

    if (this.isGranted(['ROLE_WALISOFT_AGENT'])) {
      this.tabs.push(
        {
          label: this.translate(`${this.translationDomain}.DISCOUNT.LIST.LINK`),
          state: 'customers.detail.discounts',
        },
      );
    }

    if (this.isGranted(['ROLE_WALISOFT_AGENT'])) {
      this.tabs.push(
        {
          label: this.translate(`${this.translationDomain}.WALITICKETS`),
          state: 'customers.detail.walitickets',
        },
      );
    }
  }

  ngOnInit(): void {
    this.formNotifierSubscription = this.formNotifier.observable.subscribe((stream) => {
      if (FORM_STREAMS.formInEdition === stream) {
        this.formInEdition = true;
      }

      if (FORM_STREAMS.formIsDestroyed === stream) {
        this.formInEdition = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.formInEdition = false;
    this.formNotifierSubscription.unsubscribe();
  }

  public switchState(state: string): void {
    if (this.formInEdition) {
      const dialog: DialogRef = this.dialogBoxService.alert({content: this.translate('DIALOG.ALERT.FORM.IN_EDITION')});

      dialog.result.subscribe((result) => {
        if (result instanceof DialogCloseResult) {
          return;
        } else {
          if (this.translate('DIALOG.BUTTON.CANCEL') === result.text) {
            return;
          }

          this.state.go(state, {id: this.state.params.id});
        }
      });
    } else {
      this.state.go(state, {id: this.state.params.id});
    }
  }
}
