import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@services';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AbstractResource } from '@resources';
import { LitigationPackageResource } from '@resources/litigation-package.resource';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { LitigationListFiltersService } from '@components/litigation/list/services/litigation-list-filters.service';
import { LitigationStatusResource } from '@components/order-manager/resources/litigation-status.resource';
import { LitigationTypeResource } from '@components/order-manager/resources/litigation-type.resource';
import { SessionHelper } from '@helpers';
import { LOCALE_FR } from '@constants';

@Component({
  selector: 'app-litigation-list',
  template: require('./litigation-list.component.html'),
  providers: [
    { provide: AbstractResource, useClass: LitigationPackageResource },
    { provide: AbstractFiltersFieldsService, useClass: LitigationListFiltersService }
  ]
})
export class LitigationListComponent extends AbstractComponent implements OnInit {
  public litigationStatuses: any[] = [];
  public litigationTypes: any[] = [];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private litigationStatusResource: LitigationStatusResource,
    private litigationTypeResource: LitigationTypeResource
  ) {
    super($translate, authService, null, state);
  }

  public ngOnInit(): void {
    this.fetchLitigationStatuses();
    this.fetchLitigationTypes();
  }

  private fetchLitigationStatuses(): void {
    this.litigationStatusResource.cGet({}, { returnHydraMembers: true, blocking: false })
      .takeUntil(this.destroyed$)
      .subscribe((response: any[]) => {
        this.litigationStatuses = response.map((status: any) => {
          return {
            label: (status.translations[SessionHelper.getUILanguage()] || status.translations[LOCALE_FR]).label,
            code: status.code,
            id: status['@id'],
          };
        }).sort((a, b) => {
          if (a.label < b.label) { return -1; }
          if (a.label > b.label) { return 1; }
          return 0;
        });
      })
    ;
  }

  private fetchLitigationTypes(): void {
    this.litigationTypeResource.cGet({}, { returnHydraMembers: true, blocking: false })
      .takeUntil(this.destroyed$)
      .subscribe((response: any[]) => {
        this.litigationTypes = response.map((status: any) => {
          return {
            label: (status.translations[SessionHelper.getUILanguage()] || status.translations[LOCALE_FR]).label,
            id: status['@id'],
            code: status.code,
          };
        }).sort((a, b) => {
          if (a.label < b.label) { return -1; }
          if (a.label > b.label) { return 1; }
          return 0;
        });
      })
    ;
  }
}
