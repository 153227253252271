import { AbstractResource } from './abstract.resource';
import { Injectable } from '@angular/core';
import { Resource } from '../decorators/Resource';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  entryPoint: '/v2/order_supported_by',
  isHydra: true,
})
export class OrderSupportedByResource extends AbstractResource {
  constructor(http: HttpClient) {
    super(http);
  }
}
