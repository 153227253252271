import { Component, Inject, OnInit } from '@angular/core';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { AuthService } from '@services/auth.service';
import { WebsiteConfigurationResource } from '@resources/website-configuration.resource';
import { MarketplaceHelper } from '@helpers/MarketplaceHelper';

@Component({
  selector: 'app-payment-carrier-icons',
  template: require('./payment-carrier-icons.component.html')
})

export class PaymentCarrierIconsComponent extends AbstractPageComponent implements OnInit {

  public websiteConfiguration: any;
  public emptyWebsiteConfiguration: boolean = true;
  public isWebsiteFeature: boolean = false;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    private websiteConfigurationResource: WebsiteConfigurationResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, null, state);
  }

  ngOnInit(): void {
    this.isWebsiteFeature = MarketplaceHelper.hasWebsiteMarketplace();

    if (this.isWebsiteFeature) {
      this.getWebsiteConfiguration();
    }
  }

  public getWebsiteConfiguration(): void {
    this.websiteConfigurationResource.get(this.currentLocale)
      .takeUntil(this.destroyed$)
      .subscribe(
        response => {
          this.websiteConfiguration = response;
          this.emptyWebsiteConfiguration = false;
          },
        error => this.emptyWebsiteConfiguration = (404 === error.status)
      );
  }
}
