import { Component, Inject, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { SnackbarService } from '@components/snackbar/snackbar.service';
import { FormErrorHelper, SessionHelper } from '@helpers';
import { FormSubmitArgument } from '@interfaces';
import {CarrierGroupResource, CarrierResource} from '@resources';
import { ICarrierGroup } from '@components/carrier';
import { AuthService } from '@services';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';

@Component({
  selector: 'app-carrier-edit',
  template: require('./carrier-edit.component.html')
})
export class CarrierEditComponent extends AbstractPageComponent implements OnInit {

  public carrierGroup: ICarrierGroup;
  private carrierResource: CarrierResource;
  private carrierGroupResource: CarrierGroupResource;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: CarrierGroupResource,
    carrierResource: CarrierResource,
    carrierGroupResource: CarrierGroupResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private snackbar: SnackbarService,
  ) {
    super($translate, authService, resource, state);
    this.carrierResource = carrierResource;
    this.carrierGroupResource = carrierGroupResource;

    this.carrierGroupResource.get(this.state.params.id)
      .takeUntil(this.destroyed$)
      .subscribe((response: ICarrierGroup) => {
        this.pageTitle = this.translate('PAGE.CARRIER.EDIT.TITLE', { carrier: response.code });
      })
    ;
  }

  ngOnInit() {
    this.fetch();
  }

  private fetch(): void {
    const subscriber: Subscription = this.resource.get(this.state.params.id)
      .subscribe((carrierGroup: ICarrierGroup) => {
        subscriber.unsubscribe();
        this.carrierGroup = carrierGroup;
      });
  }

  public save({body, form, redirect}: FormSubmitArgument): void {
    const data: any = body;

    const subscriber: Subscription = this.carrierResource.updateConfigurations(this.carrierGroup.config.id.toString(), data)
      .subscribe((response: ICarrierGroup) => {
        subscriber.unsubscribe();

        this.snackbar.validate(this.translate('PAGE.CARRIER.EDIT.CONSTRAINTS.SAVED'));

        if (redirect) {
          this.actions.list.go();
        }
      }, (error: any) => {
        if (400 === error.code) {
          FormErrorHelper.fillFormControlWithErrors(form, error.errors);
        }
      }
    );
  }
}
