import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GenericFormModule } from '@components/generic/generic-form.module';
import { EnhancedFieldsModule } from '../../directives/enhanced-fields.module';
import { DndModule } from 'ng2-dnd';
import { GenericModule } from '@components/generic/generic.module';
import { ProductStatusComponent } from '@components/product/list/marketplaces-tab';
import { LogisticComponent } from '@components/product/list/logistic/logistic.component';
import {
  ProductStatusEditableComponent,
  ProductGeneralFormComponent,
  SparePartProductGeneralFormComponent,
  PurchaseServiceFormComponent,
  TabLinkedProductsComponent,
  LinkedProductsComponent,
  ProductCarrierFormComponent,
  ProductLogisticFormComponent,
  ExpeditionDeliveryFormComponent,
  ProductCarrierFamilyListComponent,
  PreviewCostsComponent,
  PackageManagementComponent,
  PackageManagementFormComponent,
  ProductContentFormComponent,
  ProductAttributesFormComponent,
  ProductContentTabComponent,
  ProductPreviewButtonComponent,
  ProductContentFormTranslationsComponent,
  ProductPicturesFormComponent,
  ProductProductFormComponent,
  ProductProductAvailabilityFormComponent,
  ProductMarketplaceFormsComponent,
  ProductMarketplaceGeneralFormComponent,
  ProductMarketplaceGeneralFormTranslationsComponent,
  ProductMarketplaceOffersFormComponent,
  ProductMarketplaceAttributesFormComponent,
  DuplicateProductComponent,
  DuplicateProductNewContentComponent,
  ProductWebsiteMarketplaceFormComponent,
  ProductWebsiteMarketplaceUploadTranslationsFormComponent,
  ProductWebsiteMarketplaceUploadFormComponent,
  ProductPageComponent,
  MasterProductsWarehousesListComponent,
  ProductVersionsFormComponent,
  ImportProductComponent,
  ImportProductFieldsComponent
} from '@components/product/form';
import { ProductResource } from '../../components/product/product.resource';
import { SwitchModule } from '@progress/kendo-angular-inputs';
import { BsDatepickerModule, TabsModule } from 'ngx-bootstrap';
import { DirectivesModule } from '@directives/directives.module';
import { PipesModule } from '../../pipes/pipes.module';
import { ProductSageFormComponent } from '@components/product/form/tab-sage';
import { ProductMarketplaceResource } from '@components/product/product-marketplace.resource';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { UploadModule } from '@progress/kendo-angular-upload';
import { ImportComponent } from '@components/generic/import-export/import.component';
import { ProductMarketplaceTabsComponent } from '@components/product/form/tabs-marketplace/product-marketplace-tabs.component';
import { ProductListComponent, ProductResultListComponent } from '@components/product/list';
import { TooltipModule } from 'ngx-tooltip';
import { ProductListBulkEditionComponent } from '@components/product/list/bulk-edition/product-list-bulk-edition.component';
import { MasterProductResource, MasterProductsWarehousesResource } from '@resources';
import { StickyHeaderDirective } from '@components/product/list/directive/stickyHeader.directive';
import { SynchronizeScrollDirective } from '@components/product/list/directive/synchronizeScroll.directive';
import { TabStripModule } from '@progress/kendo-angular-layout';
import { ExportModule } from '@components/export';
import { WarehousesResource } from '@components/warehouses/warehouses.resource';
import { AddressingResource } from '@resources/addressing.resource';
import { ProductLightPageComponent } from './light/product-light-page.component';
import { EavAttributeValueFormComponent } from './form/tab-purchase-service/eav-attribute-value/eav-attribute-value-form.component';

@NgModule({
  declarations: [
    ProductPageComponent,
    ProductStatusComponent,
    ProductStatusEditableComponent,
    ProductGeneralFormComponent,
    SparePartProductGeneralFormComponent,
    ProductCarrierFormComponent,
    ProductLogisticFormComponent,
    ProductContentTabComponent,
    ProductPreviewButtonComponent,
    ProductAttributesFormComponent,
    ProductContentFormComponent,
    ProductContentFormTranslationsComponent,
    PurchaseServiceFormComponent,
    TabLinkedProductsComponent,
    LinkedProductsComponent,
    LogisticComponent,
    ExpeditionDeliveryFormComponent,
    ProductCarrierFamilyListComponent,
    PreviewCostsComponent,
    PackageManagementComponent,
    PackageManagementFormComponent,
    ProductPicturesFormComponent,
    ProductProductFormComponent,
    ProductProductAvailabilityFormComponent,
    ProductSageFormComponent,
    ProductMarketplaceFormsComponent,
    ProductMarketplaceGeneralFormComponent,
    ProductMarketplaceGeneralFormTranslationsComponent,
    ProductMarketplaceOffersFormComponent,
    ProductMarketplaceAttributesFormComponent,
    ProductWebsiteMarketplaceFormComponent,
    ProductWebsiteMarketplaceUploadTranslationsFormComponent,
    ProductWebsiteMarketplaceUploadFormComponent,
    DuplicateProductComponent,
    DuplicateProductNewContentComponent,
    ImportProductComponent,
    ImportProductFieldsComponent,
    ProductMarketplaceTabsComponent,
    ProductListComponent,
    ProductResultListComponent,
    ProductListBulkEditionComponent,
    MasterProductsWarehousesListComponent,
    StickyHeaderDirective,
    SynchronizeScrollDirective,
    ProductLightPageComponent,
    ProductVersionsFormComponent,
    EavAttributeValueFormComponent
  ],
  entryComponents: [
    ProductPageComponent,
    ProductStatusComponent,
    ProductStatusEditableComponent,
    ProductGeneralFormComponent,
    SparePartProductGeneralFormComponent,
    ProductCarrierFormComponent,
    ProductLogisticFormComponent,
    ProductContentFormComponent,
    ProductContentTabComponent,
    ProductPreviewButtonComponent,
    ProductAttributesFormComponent,
    ProductContentFormTranslationsComponent,
    ProductSageFormComponent,
    PurchaseServiceFormComponent,
    TabLinkedProductsComponent,
    LinkedProductsComponent,
    LogisticComponent,
    ProductPicturesFormComponent,
    ProductProductFormComponent,
    ProductProductAvailabilityFormComponent,
    ProductMarketplaceFormsComponent,
    ProductMarketplaceGeneralFormComponent,
    ProductMarketplaceGeneralFormTranslationsComponent,
    ProductMarketplaceOffersFormComponent,
    ProductMarketplaceAttributesFormComponent,
    ProductWebsiteMarketplaceFormComponent,
    ProductWebsiteMarketplaceUploadTranslationsFormComponent,
    ProductWebsiteMarketplaceUploadFormComponent,
    DuplicateProductComponent,
    DuplicateProductNewContentComponent,
    ImportProductComponent,
    ImportProductFieldsComponent,
    ImportComponent,
    ProductListComponent,
    MasterProductsWarehousesListComponent,
    ProductLightPageComponent,
    ProductVersionsFormComponent,
    EavAttributeValueFormComponent
  ],
  imports: [
    CommonModule,
    DropDownsModule,
    FormsModule,
    GenericFormModule,
    EnhancedFieldsModule,
    ReactiveFormsModule,
    GenericModule,
    DndModule,
    SwitchModule,
    TabsModule,
    TooltipModule,
    DirectivesModule,
    PipesModule,
    DialogModule,
    UploadModule,
    BsDatepickerModule,
    TabStripModule,
    ExportModule
  ],
  exports: [
    ProductGeneralFormComponent,
    SparePartProductGeneralFormComponent,
    ProductProductFormComponent,
    ProductProductAvailabilityFormComponent,
    ProductContentTabComponent,
    ProductPreviewButtonComponent,
    PurchaseServiceFormComponent,
    ProductCarrierFormComponent,
    ProductLogisticFormComponent,
    TabLinkedProductsComponent,
    ProductPicturesFormComponent,
    ProductStatusEditableComponent,
    DuplicateProductComponent,
    DuplicateProductNewContentComponent,
    ImportProductComponent,
    ImportProductFieldsComponent,
    ProductWebsiteMarketplaceFormComponent,
    ProductMarketplaceFormsComponent,
    ProductMarketplaceTabsComponent,
    ProductAttributesFormComponent
  ],
  providers: [
    ProductResource,
    ProductMarketplaceResource,
    MasterProductsWarehousesResource,
    ProductPicturesFormComponent,
    MasterProductResource,
    WarehousesResource,
    AddressingResource,
  ]
})
export class ProductModule {}
