import { Component, Inject, Input, OnInit } from '@angular/core';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import { AuthService } from '@services';
import { AbstractFormComponent } from '@components/generic/Form/abstract-form.component';
import { IFormViolation } from '@interfaces';
import { AbstractResource } from '@resources/abstract.resource';
import { CommercialOperationsResource } from '@components/commercial-operations/resources';
import {INDEX_FOLLOW, NO_INDEX_FOLLOW, NO_INDEX_NO_FOLLOW} from '@components/categories';


@Component({
  selector: 'app-commercial-operations-form-seo',
  template: require('./commercial-operations-form-seo.component.html'),
  providers: [
    { provide: AbstractResource, useClass: CommercialOperationsResource },
  ],
})
export class CommercialOperationsFormSeoComponent extends AbstractFormComponent implements OnInit {
  @Input() public form: FormGroup;
  @Input() public parentForm: FormGroup;
  @Input() public violations: IFormViolation[] = [];
  @Input() public locale: string;
  @Input() public hideField: boolean;
  @Input() public cmsLinks: { name: string; id: string; };

  public seoMetaRobotOptions: any[] = [
    INDEX_FOLLOW,
    NO_INDEX_FOLLOW,
    NO_INDEX_NO_FOLLOW,
  ];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
  }
}
