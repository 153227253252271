import { ICatalog, ISuperProductList } from '@components/super-product/interfaces/super-product-list.interface';

export class SuperProductListModel implements ISuperProductList {
  id: string = '';
  skuParent: string = '';
  reference: string = '';
  catalog: string = '';
  // need for linking from super product list to catalog edition
  catalogId: string = '';
  productSkus: string[] = [];
  repairabilityIndex?: number;

  constructor(model: ISuperProductList) {
    this.id = model.id;
    this.skuParent = model.skuParent;
    this.reference = model.reference;
    this.catalog = (<ICatalog>model.catalog) ? (<ICatalog>model.catalog).label : '' ;
    this.catalogId = (<ICatalog>model.catalog) ? (<ICatalog>model.catalog).id : '' ;
    this.productSkus = model.productSkus ? model.productSkus : [] ;
    this.repairabilityIndex = model.repairabilityIndex ? model.repairabilityIndex : 0;
  }
}
