import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { CarrierGroupResource, OrderResource, OrderSupportedByResource } from '@resources';
import { ProductResource } from '@components/product/product.resource';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CountryHelper, MarketplaceHelper, SessionHelper } from '@helpers';
import { IMarketplace } from '@interfaces';
import { takeUntil } from 'rxjs/operators';
import { IHydraResource } from '@interfaces/hydra-resource.interface';
import { CarrierFamilyResource } from '@components/carrierFamily';
import { SnackbarService } from '@components/snackbar';
import * as moment from 'moment';
import { HydraHelper } from '@helpers/HydraHelper';
import { NumberHelper } from '@helpers/NumberHelper';
import { buildVintageDates } from '@helpers/VintageHelper';
import {CODE_FR, CODE_BE, CODE_GB, CODE_NL, CODE_ES, CODE_PT, CODE_IT, CODE_DE, CODE_LU, LOCALE_FR, CODE_PL} from '@constants';
import { debounce } from 'lodash';
import {IGrcReason} from '@components/order/interfaces/order-refund.interface';
import {GrcReasonResource} from '@components/order/resources/grc-reason-resource.service';
import {SparepartReplenishmentResource} from '@components/sav/resources/sparepart.replenishment.resource';

@Component({
    selector: 'app-sav-order-new',
    template: require('./sav-order-new.component.html'),
    styles: [require('./sav-order-new.component.scss')],
    providers: [
        { provide: AbstractResource, useClass: ProductResource },
    ],
})
export class SavOrderNewComponent extends AbstractPageComponent implements OnInit {
    constructor(
        @Inject('TranslationService') $translate: ng.translate.ITranslateService,
        authService: AuthService,
        resource: AbstractResource,
        private supportedByResource: OrderSupportedByResource,
        @Inject('StateService') state: ng.ui.IStateService,
        private formBuilder: FormBuilder,
        private carrierFamilyResource: CarrierFamilyResource,
        private orderResource: OrderResource,
        private snackbar: SnackbarService,
        private carrierGroupResource: CarrierGroupResource,
        private grcReasonResource: GrcReasonResource,
        private sparepartReplenishmentResource: SparepartReplenishmentResource
    ) {
        super($translate, authService, resource, state);
    }

    readonly ASSURANCE_CODE = 'assurance';
    readonly WALIBUY_CODE = 'walibuy';
    readonly CUSTOMER_CODE = 'customer';

    public createFromExistingOrder: boolean = false;
    public order: any;
    public products: any = {};
    private orderItems: { [index: string]: string } = {};

    public vintages: any[] = [];
    public vintagePossibilitiesDistinctSkus: any = [];
    public vintagePossibilitiesFilterBySku: any[];
    public newVintageList: any;

    public selectedSku: string;
    public selectedVintage: string;
    public availableVintageDates: any[];
    public counterItems: { sku: string, quantity: number }[] = [];

    public countries = SessionHelper.getApplicationActiveCountries().concat(SessionHelper.getApplicationActiveCountriesWithParent()).map(country => {
      return {
        ...country,
        name: country.translations[this.currentLocale].name
      };
    });

    public shippingAddressForm: FormGroup;
    public billingAddressForm: FormGroup;
    public billingAddressSameAsShipping: boolean = false;

    public spareParts: any[] = [];
    public warehouses: any[] = [];
    public initialSpareParts: any[] = [];
    public sparePartForms: any = {};
    public hasSelectedSpareParts: boolean = false;
    public marketplace: IMarketplace = MarketplaceHelper.getWebsiteMarketplace();
    public marketplaces: IMarketplace[] = [MarketplaceHelper.getWebsiteMarketplace()];
    public searchSparePartForm: FormGroup;

    public families: any[] = [];
    public familyForm: FormGroup;

    public orderForm: FormGroup;
    public supportedBy: any[] = [];
    public defaultSupportedBy: any;

    public carryingCosts: any[] = [];
    public carriers: any[] = [];

    public imagePathToShow?: string;

  public reasons: { label: string; id: string }[];
  public grcReasons:  { label: string; id: number }[];

    public previewCarryingCost = debounce(() => {
      const items: any[] = [];

      for (const sparePartSku of Object.keys(this.sparePartForms)) {
        const sparePartForm: FormGroup = this.sparePartForms[sparePartSku];

        if (sparePartForm.value.quantity > 0) {
          items.push({
            product: { sku: sparePartSku },
            quantity: sparePartForm.value.quantity,
            unitPrice: sparePartForm.value.currentPrice,
            carrierFamily: this.familyForm.value.family,
            sparePartParentSku: this.selectedSku,
          });
        }
      }

      const selectedCountry = this.countries.find(
        country => country.code === this.shippingAddressForm.controls['countryCode'].value
      );

      this.carrierGroupResource
        .getCostEstimation({
          country: HydraHelper.buildIri(selectedCountry ? selectedCountry.id : 1, 'countries'),
          items: items,
          clustering: true,
          postcodes: [this.shippingAddressForm.value.postcode]
        })
        .then((response: {results: any}) => {
          this.carryingCosts = response.results;
          this.carriers = Object.keys(response.results);

          let carryingCost = null;
          for (const carrier of this.carriers) {
            if (this.carryingCosts[carrier].cost > 1 && (null === carryingCost || this.carryingCosts[carrier].cost < carryingCost)) {
              carryingCost = this.carryingCosts[carrier].cost;
            }
          }

          this.orderForm.controls['shippingPrice'].setValue(carryingCost || 0);
          this.setTotal();
        })
      ;
    }, 750);

    ngOnInit() {
        if (this.state.params.id) {
            this.createFromExistingOrder = true;
            (<OrderResource>this.orderResource)
                .getSavOrder(this.state.params.id)
                .pipe(takeUntil(this.destroyed$))
                .subscribe((response: any) => {
                    this.order = response;
                    for (const item of this.order.items) {
                        this.orderItems[item.product.sku] = item['@id'];
                        this.products[item.product.sku] = item.product;
                    }

                    this.buildShippingAddressForm(this.order.shippingAddress);
                    this.buildBillingAddressForm(this.order.billingAddress);
                    this.fetchSupportedBy();
                    this.buildOrderForm();
                })
                ;
        } else {
          this.buildShippingAddressForm();
            this.buildBillingAddressForm();
            this.fetchSupportedBy();
            this.buildOrderForm();
        }

        if (this.marketplace.code === 'sitefr') {
          this.marketplaces.push(MarketplaceHelper.getMarketplaceByCode('showroom'));
        }

        this.availableVintageDates = buildVintageDates();

        this.buildSearchSparePartForm();
    }

    public onFilterSku(event: string) {
      if (event.length > 2) {
        this.resource.getVintagePossibilites(event).takeUntil(this.destroyed$)
          .subscribe((response: any) => {
            this.vintagePossibilitiesDistinctSkus = [];
            for (const iterator of Object.keys(response)) {
              this.vintagePossibilitiesDistinctSkus.push({
                'sku': iterator,
                'vintages': response[iterator].vintages
              });
            }
          });
      }
    }

    public onSelectSku(event: any, service?: boolean) {
        this.selectedSku = event.sku;

        if (this.createFromExistingOrder) {
            this.selectedVintage = moment(this.order.date).format('YYYY');
        } else {
            this.vintagePossibilitiesFilterBySku = [];
            this.newVintageList = event;
            if (event.vintages) {
              for (const iterator of event.vintages) {
                  this.vintagePossibilitiesFilterBySku.push(iterator);
              }
            }
        }

        if (this.selectedSku && this.selectedVintage && this.shippingAddressForm.controls['countryCode'].value) {
            this.fetchFamilies();
            this.fetchSpareParts(
              this.selectedSku, this.selectedVintage, this.shippingAddressForm.controls['countryCode'].value);
        }

        if (service) {
            this.orderForm.controls['supportedBy'].setValue(this.ASSURANCE_CODE);
            this.forceBillingAddress();
        }
    }

    public onSelectVintage(event: any) {
        this.selectedVintage = event;

        if (this.selectedSku && this.selectedVintage && this.shippingAddressForm.controls['countryCode'].value) {
            this.fetchFamilies();
            this.fetchSpareParts(this.selectedSku, this.selectedVintage, this.shippingAddressForm.controls['countryCode'].value);
        }
    }

    private fetchSupportedBy(): void {
        const that = this;
        (<OrderSupportedByResource>this.supportedByResource)
            .cGet(null, { returnHydraMembers: true })
            .pipe(takeUntil(this.destroyed$))
            .subscribe((response: any) => {
                this.supportedBy = response.map(function (supportedBy: any) {
                    return {
                        id: supportedBy.id,
                        code: supportedBy.code,
                        label: supportedBy.translations[that.currentLocale] ?
                            supportedBy.translations[that.currentLocale].label : supportedBy.translations['fr_FR'].label
                    };
                });
                this.defaultSupportedBy = this.supportedBy.slice(-1)[0];
            })
        ;
    }

    private fetchFamilies(): void {
        (<CarrierFamilyResource>this.carrierFamilyResource)
            .cGet(
                {
                  'country.code': this.shippingAddressForm.controls['countryCode'].value,
                  'carriers.carrierGroup.pickup': true, 'carriers.carrierGroup.active': true
                },
                { returnHydraMembers: true, dontUseModel: true }
            )
            .pipe(takeUntil(this.destroyed$))
            .subscribe((response: any) => {
                this.families = [];
                this.families = response.map(function (family: any) {
                    return {
                        id: family.id,
                        label: family.reference,
                    };
                });
                this.families.push({id: -1, label: ''});
                this.buildFamilyForm();
            })
        ;
    }

    private buildFamilyForm(): void {
        this.familyForm = this.formBuilder.group({
            family: null,
        });
    }

    private buildShippingAddressForm(address?: any): void {
        if (address) {
            this.shippingAddressForm = this.buildAddressForm(address);
        } else {
            this.shippingAddressForm = this.buildAddressForm({});
        }
    }

    private buildBillingAddressForm(address?: any): void {
        if (address) {
            this.billingAddressForm = this.buildAddressForm(address);
        } else {
            this.billingAddressForm = this.buildAddressForm({});
        }
    }

    private buildAddressForm(address: any): FormGroup {
        return this.formBuilder.group({
            firstName: new FormControl(address.firstName, Validators.required),
            lastName: new FormControl(address.lastName, Validators.required),
            company: new FormControl(address.company || ''),
            addressLine1: new FormControl(address.addressLine1, Validators.required),
            addressLine2: new FormControl(address.addressLine2 || ''),
            addressLine3: new FormControl(address.addressLine3 || ''),
            postcode: new FormControl(address.postcode, Validators.required),
            city: new FormControl(address.city, Validators.required),
            province: new FormControl(address.province || ''),
            countryCode: new FormControl(address.countryCode, Validators.required),
            phone1: new FormControl(address.phone1, Validators.required),
            phone2: new FormControl(address.phone2 || ''),
            email: new FormControl(address.email, [Validators.required, Validators.email]),
        });
    }

    public updateBillingAddress(): void {
        this.billingAddressSameAsShipping = !this.billingAddressSameAsShipping;

        this.setBillingAddress();
    }

    public setBillingAddress(): void {
      if (this.billingAddressSameAsShipping) {
        this.billingAddressForm = this.buildAddressForm(this.shippingAddressForm.value);
      }
    }

    private buildOrderForm() {
        this.orderForm = this.formBuilder.group({
            comment: null,
            supportedBy: new FormControl(this.defaultSupportedBy, Validators.required),
            zendeskTicketId: null,
            discount: new FormControl(0, [Validators.min(0), Validators.required]),
            shippingPrice: new FormControl(0, [Validators.min(0), Validators.required]),
            total: new FormControl(0,  [Validators.min(0), Validators.required]),
            marketplace: new FormControl(this.marketplace, Validators.required),
            reasons: new FormControl(this.reasons, Validators.required),
            grcReason: new FormControl(this.grcReasons, Validators.required),
        });

      this.buildFormReason();
    }

    public onChangeCountry() {
        if (this.selectedSku && this.selectedVintage && this.shippingAddressForm.controls['countryCode'].value) {
            this.fetchFamilies();
            this.fetchSpareParts(
              this.selectedSku,
              this.selectedVintage,
              this.shippingAddressForm.controls['countryCode'].value
            );
        }
    }

    public buildSparePartQuantityForms(spareParts: any): void {
        const defaultWarehouses: any = {
          [CODE_FR]: 'sav',
          [CODE_BE]: 'sav',
          [CODE_NL]: 'sav',
          [CODE_LU]: 'sav',
          [CODE_DE]: 'sav',
          [CODE_PL]: 'sav',
          [CODE_ES]: 'fercam',
          [CODE_PT]: 'fercam',
          [CODE_IT]: 'fercamit',
          [CODE_GB]: 'espack',
        };

        for (const sparePart of spareParts) {
            let arrivalQuantity = 0;

            for (const warehouseCode of Object.keys(sparePart.concatenatedArrivals)) {
              arrivalQuantity += sparePart.concatenatedArrivals[warehouseCode].totalQuantity;
            }

            const productMarketplace = sparePart.productMarketplaces[this.marketplace.code];
            const maxQuantity = sparePart.computedStock + arrivalQuantity;
            let warehouse = defaultWarehouses[this.shippingAddressForm.controls['countryCode'].value];

            if (sparePart.provisionalStockByWarehouse[warehouse] <= 0) {
              warehouse = null;

              for (const warehouseCode of Object.keys(sparePart.provisionalStockByWarehouse)) {
                if (sparePart.provisionalStockByWarehouse[warehouseCode] > 0) {
                  warehouse = warehouseCode;
                }
              }
            }

            this.sparePartForms[sparePart.sku] = this.formBuilder.group({
                quantity: new FormControl(0, [Validators.min(0), Validators.max(Math.max(maxQuantity, 0))]),
                currentPrice: productMarketplace ? productMarketplace.currentPrice : 0,
                warehouse: warehouse,
            });
        }
    }

    public fetchSpareParts(sku: string, vintage: string, countryCode: string) {
        countryCode = CountryHelper.getCountryParentByCountryCodeOrCountry(countryCode).code;
        (<ProductResource>this.resource)
            .getSpareParts({
                'sparePartParentSku': sku,
                'sparePartParentVintage': vintage,
                'country.code': countryCode,
                'pagination': false
            })
            .pipe(takeUntil(this.destroyed$))
            .subscribe((response: IHydraResource) => {
                this.spareParts = response['hydra:member'];
                this.initialSpareParts = this.spareParts;

                this.warehouses = [];
                for (const sparePart of this.spareParts) {
                  this.warehouses[sparePart.sku] = [];

                  for (const warehouseCode of Object.keys(sparePart.provisionalStockByWarehouse)) {
                    this.warehouses[sparePart.sku].push({
                      code: warehouseCode,
                      label: warehouseCode.toUpperCase() + ' : ' + sparePart.provisionalStockByWarehouse[warehouseCode]
                    });
                  }
                }

                this.buildSparePartQuantityForms(this.spareParts);
                this.buildOrderForm();

                if (this.spareParts.length === 0) {
                    this.snackbar.
                        warn(`${this.translate('PAGE.SAV.ORDER.CREATE.ERROR_NO_SPARE_PARTS')} (${sku} - ${vintage} - ${countryCode})`);
                }
              this.initializeCounterItems(this.spareParts.length);
            })
        ;
    }

    public setMax(value: number, sparePart: any) {
      this.sparePartForms[sparePart.sku].controls.quantity.setValue(Math.max(
        Math.min(
          value,
          this.getMaxProvisionalStockByWarehouse(sparePart.provisionalStockByWarehouse)
        ),
        0
      ));
    }

    public createOrder() {
        if (!this.shippingAddressForm.valid || !this.billingAddressForm.valid || !this.orderForm.valid) {
            this.snackbar.alert(this.translate('PAGE.SAV.ORDER.CREATE.ERROR_FORMS'));
            return;
        }

        const items: any[] = [];
        this.hasSelectedSpareParts = false;

        for (const sparePartSku of Object.keys(this.sparePartForms)) {
            const sparePartForm: FormGroup = this.sparePartForms[sparePartSku];

            if ( !sparePartForm.valid ) {
                this.snackbar.alert(this.translate('PAGE.SAV.ORDER.CREATE.ERROR_FORMS'));
                return;
            }

            if (sparePartForm.value.quantity > 0) {
                this.hasSelectedSpareParts = true;

                items.push({
                    product: { sku: sparePartSku },
                    quantity: sparePartForm.value.quantity,
                    unitPrice: sparePartForm.value.currentPrice,
                    carrierFamily: this.familyForm.value.family,
                    sparePartParentSku: this.selectedSku,
                    warehouse: sparePartForm.value.warehouse,
                });
            }
        }

        if (!this.hasSelectedSpareParts) {
            this.snackbar.alert(this.translate('PAGE.SAV.ORDER.CREATE.NO_SPARE_PART_SELECTED'));
            return;
        }

        const body = {
            locale: this.currentLocale,
            marketplace: this.orderForm.value.marketplace.code,
            paymentType: 'credit_card',
            shippingAddress: this.shippingAddressForm.value,
            billingAddress: this.billingAddressForm.value,
            items: items,
            supportedBy: this.orderForm.value.supportedBy.id,
            grcReason: this.orderForm.value.grcReason,
            zendeskTicketId: this.orderForm.value.zendeskTicketId,
            zendeskComment: this.orderForm.value.comment,
            shippingPrice: parseFloat(this.orderForm.value.shippingPrice),
            discount: parseFloat(this.orderForm.value.discount),
            customer: this.order && this.order.customer ? this.order.customer.id : null,
            referralOrderSav: this.order ? +this.order.id : null,
            referralOrderItemsIri: {
              [this.selectedSku]: this.orderItems.hasOwnProperty(this.selectedSku) ? this.orderItems[this.selectedSku] : null
            }
        };

        (<OrderResource>this.orderResource)
            .create(body)
            .pipe(takeUntil(this.destroyed$))
            .subscribe((response: any) => {
                if (this.getComment()) {
                    (<OrderResource>this.orderResource)
                        .createComment(response.id, { message: this.getComment() })
                        .pipe(takeUntil(this.destroyed$))
                        .subscribe(() => {
                            this.state.go('order_manager.edit', { id: response.id });
                        });
                } else {
                    this.state.go('order_manager.edit', { id: response.id });
                }
            })
        ;
    }

    private getComment() {
        let comment = this.orderForm.value.comment || '';

        if (this.orderForm.value.zendeskTicketId) {
            comment += ` Zendesk Ticket Id : ${this.orderForm.value.zendeskTicketId}`;
        }

        comment += ` Prise en charge : ${this.orderForm.value.supportedBy.label}`;

        return comment;
    }

    public getSubTotal() {
      let total = 0;

      for (const idSparePartForm of Object.keys(this.sparePartForms)) {
        const sparePartFormControls = this.sparePartForms[idSparePartForm].controls;

        total += +sparePartFormControls['quantity'].value * sparePartFormControls['currentPrice'].value;
      }

      return Math.round(total * 100) / 100;
    }

    public getTotal() {
      if (!this.isSupportedBy(this.CUSTOMER_CODE)) {
        return 0;
      }

      let total = this.getSubTotal();
      total += NumberHelper.parseFloat(this.orderForm.value.shippingPrice);
      total -= NumberHelper.parseFloat(this.orderForm.value.discount);

      return Math.round(total * 100) / 100;
    }

    public setTotal() {
        this.orderForm.controls['total'].setValue(this.getTotal());
    }

    public getDiscount() {
      if (!this.isSupportedBy(this.CUSTOMER_CODE)) {
        return NumberHelper.parseFloat(this.getSubTotal()) + NumberHelper.parseFloat(this.getShippingPrice());
      }

      return 0;
    }

    public getShippingPrice() {
      return this.orderForm.value.shippingPrice;
    }

    public setDiscount() {
      this.orderForm.controls['discount'].setValue(this.getDiscount());
    }

    public forceBillingAddress() {
        if (this.isSupportedBy(this.ASSURANCE_CODE)) {
            this.billingAddressSameAsShipping = false;
            this.billingAddressForm = this.buildAddressForm({
                addressLine1: '517 Avenue de la République',
                postcode: '59700',
                city: 'Marcq-en-Baroeul',
                countryCode: 'FR',
                firstName: 'FIDELIDADE à l\'attention d\'INNOSERVE',
                lastName: 'FIDELIDADE à l\'attention d\'INNOSERVE',
                company: 'FIDELIDADE à l\'attention d\'INNOSERVE',
                phone1: '0320150842',
                email: this.shippingAddressForm.value.email,
            });
        } else if (this.billingAddressSameAsShipping) {
          this.billingAddressForm = this.buildAddressForm(this.shippingAddressForm.value);
        }
    }

    public isSupportedBy(by: string): boolean {
      return (
        this.orderForm.controls['supportedBy'].value
        && this.orderForm.controls['supportedBy'].value.code === by
      );
    }

  private buildSearchSparePartForm(): void {
    this.searchSparePartForm = this.formBuilder.group({
      label: null,
    });
  }

  /*
   Function triggered when the value of the searchbar is changed.
   It filters the spare parts array according to the value entered.
   */
  public displaySpareParts(data: string) {
    this.spareParts = this.initialSpareParts;

    if (data) {
      this.spareParts = this.spareParts.filter(this.filterSpareParts(data, this.currentLocale));
    }
  }

  /*
  Callback function used to filter the spare parts array.
   */
  private filterSpareParts(label: string, currentLocale: string) {
    return function (element: any) {
      return typeof element.translations[currentLocale] !== 'undefined'
        && element.translations[currentLocale].shortTitle.toLowerCase().includes(label.toLowerCase());
    };
  }

  public showImage(path: string): void {
    this.imagePathToShow = path;
  }

  public hideImage(): void {
    this.imagePathToShow = null;
  }

  public getMaxProvisionalStockByWarehouse(provisionalStockByWarehouse: any): number {
    return Math.max(
      ...<number[]> Object.values(<number> provisionalStockByWarehouse)
    );
  }

  private buildFormReason(): void {

    this.grcReasonResource.cGet({'exists[parent]': false, 'active': 1, 'sav': 1}, { returnHydraMembers: true, blocking: false })
      .takeUntil(this.destroyed$)
      .subscribe((response: IGrcReason[]) => {

        this.reasons = response.map((reason: IGrcReason) => {

          return {
            label: (reason.translations[SessionHelper.getUILanguage()] || reason.translations[LOCALE_FR]).reason,
            id: reason['@id'],
          };
        }).sort((a, b) => {
          if (a.label < b.label) { return -1; }
          if (a.label > b.label) { return 1; }
          return 0;
        });

        this.orderForm.patchValue({ 'reason': null });
      })
    ;
  }

  public OnSelectReason(event: any) {
    this.grcReasonResource.cGet({'parent': event.id.split('/').pop(), 'active': 1, 'sav': 1}, { returnHydraMembers: true, blocking: true })
      .takeUntil(this.destroyed$)
      .subscribe((response: IGrcReason[]) => {

        this.grcReasons = response.map((reason: IGrcReason) => {
          return {
            label: (reason.translations[SessionHelper.getUILanguage()] || reason.translations[LOCALE_FR]).reason,
            id: reason.id,
          };
        }).sort((a, b) => {
          if (a.label < b.label) { return -1; }
          if (a.label > b.label) { return 1; }
          return 0;
        });

        this.grcReasons  = this.grcReasons.filter(elm => elm);

        if (this.grcReasons.length === 0) {
          this.orderForm.controls['grcReason'].clearValidators();
          this.orderForm.controls['grcReason'].updateValueAndValidity();
        } else {
          this.orderForm.patchValue({ 'grcReason': null });
          this.orderForm.controls['grcReason'].setValidators(Validators.required);
          this.orderForm.controls['grcReason'].updateValueAndValidity();
        }
      })
    ;
  }

  public saveNeededSparepart() {
    let neededSpareparts = this.counterItems;
    neededSpareparts = neededSpareparts.filter(item => item.quantity > 0);
    if (!neededSpareparts.length) {
      this.snackbar.alert(this.translate('PAGE.SAV.SPARE_PART.NO_SELECTED_QUANTITY'));
      return;
    }
    let message = this.translate('PAGE.SAV.SPARE_PART.SELECTED_NEEDED_SP') + ':';
    neededSpareparts.forEach(item => {
      message += `SKU: ${item.sku}, Quantity: ${item.quantity}\n`;
    });
    this.sparepartReplenishmentResource.create(neededSpareparts).takeUntil(this.destroyed$).subscribe((response: any) => {
      this.snackbar.inform(message);
      this.initializeCounterItems(this.spareParts.length, true);
    });
  }
  private initializeCounterItems(sparePartCount: number, reset: boolean = false) {
    if (reset) {
      this.counterItems = [];
    }
    for (let i: number = 0; i < sparePartCount; i++) {
      this.counterItems.push({sku: '', quantity: 0 });
    }
  }
  public increment(sku: string, index: number) {
    this.counterItems[index].quantity++;
    this.counterItems[index].sku = sku;
  }

  public decrement(sku: string, index: number) {
    if (this.counterItems[index].quantity === 0) {
      return;
    }
    this.counterItems[index].quantity--;
    this.counterItems[index].sku = sku;
  }
}
