import { Component, Inject, OnInit } from '@angular/core';
import { AbstractResource } from '@resources';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { AuthService } from '@services';
import { SnackbarService } from '@components/snackbar';
import { ContainerListResource } from '@components/stock/container/container-list.resource';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContainerModel } from '@components/stock/container/container.model';
import { ThirdPartyResource } from '@resources/third-party.resource';
import {SessionHelper} from '@helpers';
import {HydraHelper} from '@helpers/HydraHelper';

@Component({
  selector: 'app-container-new',
  template: require('./container-new.component.html'),
  providers: [
    { provide: AbstractResource, useClass: ContainerListResource },
  ],
})
export class ContainerNewComponent extends AbstractPageComponent implements OnInit {
  public form: FormGroup;
  public container: ContainerModel;
  public thirdParties: any[] = [];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    private thirdPartyResource: ThirdPartyResource,
    @Inject('StateService') state: ng.ui.IStateService,
    public formBuilder: FormBuilder,
    private snackbar: SnackbarService,
  ) {
    super($translate, authService, resource, state);
  }

  public ngOnInit(): void {
    this.buildForm();

    this.thirdPartyResource.cGet({pagination: false},  {isHydra: true,
      returnHydraMembers: true,
      dontUseModel: true,
      blocking: false })
      .takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        this.thirdParties = response.map((thirdParty: any): any => {
          return {
            label: `${thirdParty.label} (${thirdParty.code})`,
            code: thirdParty.code,
          };
        });
      });
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      reference: [null, Validators.required],
      thirdPartyCode: [null, Validators.required],
      weekNumber: [null, Validators.required],
      containerNumber: [null, Validators.required],
      date: [null],
      freightPrice: [null],
      arrivalPrice: [null],
      additionalPrice: [null],
      comment: [null, Validators.maxLength(69)]
    });
  }

  public submit(): void {
    const body: ContainerModel = this.form.value;
    body['commercialEntity'] = HydraHelper.buildIri(SessionHelper.getCountry().commercialEntity.id, 'commercial_entities');

    this.resource.create(body)
      .takeUntil(this.destroyed$)
      .subscribe((response: ContainerModel) => {
        this.snackbar.validate('SAVED');
         this.state.go('stocks.container.edit', { id: response.id });
      });
  }
}
