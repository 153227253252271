import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { AbstractResource } from '@resources/abstract.resource';
import { HttpClient } from '@angular/common/http';
import {BannerTypeModel} from '@components/banner/banner-type.model';

@Injectable()
@Resource({
  entryPoint: '/v2/banner_types',
  model: BannerTypeModel
})
export class BannerTypeRessource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }
}
