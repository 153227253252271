'use strict'

angular
  .module 'ammsFrontendApp'
  .directive 'filterSelect', [
    ->
      templateUrl: require('views/directives/filter-select.html')
      replace: true
      restrict: 'E'
      scope:
        addEmptyFirst: '='
        collection: '='
        multipleItems: '='
        search: '='
        values: '='
        idProperty: '@'
        valueProperty: '@'
        label: '@'
        placeholder: '@'
        orderBy: '@'
        changeAction: '&'
      controller: ($scope) ->
        $scope.config =
          valueField: $scope.idProperty
          labelField: $scope.valueProperty

        if $scope.placeholder?
          $scope.config.placeholder = $scope.placeholder

        if $scope.search? && true == $scope.search
          $scope.config.searchField = [$scope.valueProperty]

        if not $scope.multipleItems? || false == $scope.multipleItems
          $scope.config.maxItems = 1

        if $scope.orderBy?
          $scope.config.sortField = $scope.orderBy

        if $scope.addEmptyFirst?
          $scope.config.allowEmptyOption = $scope.addEmptyFirst

        if $scope.changeAction?
          $scope.config.onChange = ->
            $scope.changeAction()
  ]
