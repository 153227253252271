'use strict'

angular
  .module 'ammsFrontendApp'
  .filter 'carrier', [ ->
    (items, carrier) ->
      matchedItems = []

      if items? && 0 != _.size items
        for item in items
          if item.carrier is carrier
            matchedItems.push item

      matchedItems
  ]
