import { Component, Inject, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { SessionHelper } from '@helpers';
import { ISellingProduct } from '@models/ISellingProduct';
import { IProduct } from '@models';
import { FormControl, FormGroup } from '@angular/forms';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services';
import { ProductResource } from '../../../../components/product/product.resource';

@Component({
  selector: 'app-linked-products-form',
  template: require('./linked-products.component.html'),
  styles: [require('./linked-products.component.scss')]
})
export class LinkedProductsComponent extends AbstractComponent implements OnInit {

  public products: any[] = [];
  public newForm: FormGroup;

  @Input() public currentId: number;
  @Input() public linkedProducts: ISellingProduct[];
  @Input() public type: string;

  @Output() hasUpdatesEvent = new EventEmitter<boolean>();

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private productResource: ProductResource,
  ) {
    super($translate, authService, null, state);
  }

  ngOnInit(): void {
    // Init add form
    this.newForm = new FormGroup({
      linkedProduct: new FormControl(),
    });
  }

  public searchSku(event: any) {
    if (event.key === 'Enter') {
      this.handleAdd();
    }

    const path = event.path || (event.composedPath && event.composedPath());
    const search = path[0].value;

    if (search.length > 2 && event.key.match(/^\w$/)) {
      this.productResource.getByCountryCodeAndSku(
        SessionHelper.getCountry().code,
        undefined,
        { sku: search, dontUseModel: true }
      )
        .takeUntil(this.destroyed$)
        .subscribe((response: any): void => {
          const linkedProductSkus = this.linkedProducts.map((linkedProduct: ISellingProduct) => this.getSelling(linkedProduct).sku);

          this.products = response.filter((product: any) => (
            -1 === linkedProductSkus.indexOf(product.sku)) && product.id !== this.currentId
          );
        })
      ;
    }
  }

  /**
   * Drop action, reorganize linked products and reset positions
   */
  public dropped(dropZone: ISellingProduct, dropped: ISellingProduct): void {
    if (this.getSelling(dropZone).sku !== this.getSelling(dropped).sku) {
      const newIndex = this.linkedProducts.indexOf(dropZone);
      const oldIndex = this.linkedProducts.indexOf(dropped);
      if (newIndex >= this.linkedProducts.length) {
        let k = newIndex - this.linkedProducts.length + 1;
        while (k--) {
          this.linkedProducts.push(undefined);
        }
      }
      this.linkedProducts.splice(newIndex, 0, this.linkedProducts.splice(oldIndex, 1)[0]);
    }
  }

  /**
   * Adds a new linked product
   */
  public handleAdd(): void {
    const product = this.newForm.value;

    if (undefined !== product && null !== product.linkedProduct) {
      this.productResource.get(product.linkedProduct.id)
        .takeUntil(this.destroyed$)
        .subscribe((response: IProduct) => this.linkedProducts.push(this.mapProduct(response)))
      ;

      // resets form
      this.newForm.reset();
      this.products.splice(this.products.indexOf(product.linkedProduct), 1);
    }
  }

  /**
   * Removes a linked product
   */
  public handleDelete(linkedProduct: ISellingProduct): void {
    const sellingProduct: IProduct = this.getSelling(linkedProduct);
    this.products.push({id: sellingProduct.id, sku: sellingProduct.sku});
    this.products.sort((a: any, b: any) => a.sku > b.sku ? 1 : (a.sku < b.sku ? -1 : 0));
    this.linkedProducts.splice(this.linkedProducts.indexOf(linkedProduct), 1);
  }

  /**
   * Maps a product to a linked product sku
   */
  private mapProduct(product: any): any {
    const linkedProduct: any = {
      product: this.currentId,
      position: this.linkedProducts.length,
    };

    if (this.type === 'crossSelling') {
      linkedProduct.crossSelling = product;
    } else {
      linkedProduct.upSelling = product;
    }

    return linkedProduct;
  }

  private getSelling(linkedProduct: ISellingProduct): IProduct {
    if (linkedProduct.hasOwnProperty('crossSelling')) {
      return linkedProduct.crossSelling;
    }

    return linkedProduct.upSelling;
  }

  public updateCrossSellingProduct(event: any, crossSellingProduct: ISellingProduct) {
    crossSellingProduct.flagEquipment = event.target.checked;

    this.hasUpdatesEvent.emit(true);
  }
}
