import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { Component, Inject } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources';
import { MarketplacesConfigureResource } from './marketplaces-configure.resource';

@Component({
  selector: 'app-marketplaces-configure',
  template: require('./marketplaces-configure.component.html'),
  providers: [
    { provide: AbstractResource, useClass: MarketplacesConfigureResource },
  ],
})
export class MarketplacesConfigureComponent extends AbstractPageComponent {
  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, null, state);
  }
}
