import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';

export interface IBtnBarOptions {
  saveLabel?: string;
  deleteLabel?: string;
  enableCancelButton?: boolean;
  enableSaveAndListButton?: boolean;
  enableDeleteButton?: boolean;
  saveDisabled?: boolean;
}

@Component({
  selector: 'app-form-buttons-bar',
  template: require('./form-buttons-bar.component.html'),
  styles: [require('./form-buttons-bar.component.scss')]
})
export class FormButtonsBarComponent implements OnInit {

  @Input() public isAnchored: boolean;

  @Input() public saveLabel: string;
  @Input() public saveDisabled: boolean;
  @Output() public onSave: EventEmitter<any> = new EventEmitter();

  @Input() public cancelLabel: string;
  @Input() public cancelDisabled: boolean;
  @Input() public enableCancelButton: boolean = true;
  @Output() public onCancel: EventEmitter<any> = new EventEmitter();

  @Input() public saveAndCreateLabel: string;
  @Input() public saveAndCreateDisabled: boolean;
  @Output() public onSaveAndCreate: EventEmitter<any> = new EventEmitter();

  @Input() public saveAndListLabel: string;
  @Input() public saveAndListDisabled: boolean;
  @Input() public enableSaveAndListButton: boolean = true;
  @Output() public onSaveAndList: EventEmitter<any> = new EventEmitter();

  @Input() public editLabel: string;
  @Input() public editDisabled: boolean;
  @Output() public onEdit: EventEmitter<any> = new EventEmitter();

  @Input() public deleteLabel: string;
  @Input() public deleteDisabled: boolean;
  @Input() public enableDeleteButton: boolean = false;
  @Output() public onDelete: EventEmitter<any> = new EventEmitter();

  @Input() public selectAllLabel: string;
  @Input() public selectAllDisabled: boolean;
  @Output() public onSelectAll: EventEmitter<any> = new EventEmitter();

  @Input() public deSelectAllLabel: string;
  @Input() public deSelectAllDisabled: boolean;
  @Output() public onDeSelectAll: EventEmitter<any> = new EventEmitter();

  @Input() public refreshLabel: string;
  @Input() public refreshDisabled: boolean;
  @Output() public onRefresh: EventEmitter<any> = new EventEmitter();

  public enabledSaveButton: boolean = false;
  public enabledCancelButton: boolean = false;
  public enabledSaveAndCreateButton: boolean = false;
  public enabledSaveAndListButton: boolean = false;
  public enabledEditButton: boolean = false;
  public enabledDeleteButton: boolean = false;
  public enabledSelectAllButton: boolean = false;
  public enabledDeSelectAllButton: boolean = false;
  public enabledRefreshButton: boolean = false;

  constructor(@Inject('TranslationService') private $translate: ng.translate.ITranslateService) {}

  /**
   * @inheritdoc
   */
  ngOnInit() {
    this.enabledSaveButton = this.onSave.observers.length > 0 || undefined !== this.saveLabel;
    this.enabledCancelButton =  this.onCancel.observers.length > 0 && this.enableCancelButton;
    this.enabledSaveAndCreateButton = this.onSaveAndCreate.observers.length > 0;
    this.enabledSaveAndListButton = this.onSaveAndList.observers.length > 0 && this.enableSaveAndListButton;
    this.enabledEditButton = this.onEdit.observers.length > 0;
    this.enabledDeleteButton = this.onDelete.observers.length > 0 && this.enableDeleteButton;
    this.enabledSelectAllButton = this.onSelectAll.observers.length > 0;
    this.enabledDeSelectAllButton = this.onDeSelectAll.observers.length > 0;
    this.enabledRefreshButton = this.onRefresh.observers.length > 0;

    this.saveLabel = this.saveLabel ? this.translate(this.saveLabel) : this.translate('SAVE');
    this.cancelLabel = this.cancelLabel ? this.translate(this.cancelLabel) : this.translate('CANCEL');
    this.saveAndCreateLabel = this.saveAndCreateLabel ? this.translate(this.saveAndCreateLabel) : this.translate('SAVE_AND_CREATE');
    this.saveAndListLabel = this.saveAndListLabel ? this.translate(this.saveAndListLabel) : this.translate('SAVE_AND_LIST');
    this.editLabel = this.editLabel ? this.translate(this.editLabel) : this.translate('EDIT');
    this.deleteLabel = this.deleteLabel ? this.translate(this.deleteLabel) : this.translate('DELETE');
    this.selectAllLabel = this.selectAllLabel ? this.translate(this.selectAllLabel) : this.translate('SELECT_ALL');
    this.deSelectAllLabel = this.deSelectAllLabel ? this.translate(this.deSelectAllLabel) : this.translate('DESELECT_ALL');
    this.refreshLabel = this.refreshLabel ? this.translate(this.refreshLabel) : this.translate('REFRESH');
  }

  /**
   * Save action.
   */
  public save(): void {
    this.onSave.emit();
  }

  /**
   * Cancel action.
   */
  public cancel(): void {
    this.onCancel.emit();
  }

  /**
   * Save and create action.
   */
  public saveAndCreate(): void {
    this.onSaveAndCreate.emit();
  }

  /**
   * Save and list action.
   */
  public saveAndList(): void {
    this.onSaveAndList.emit();
  }

  /**
   * Edit action.
   */
  public edit(): void {
    this.onEdit.emit();
  }

  /**
   * Delete action.
   */
  public delete(): void {
    this.onDelete.emit();
  }

  /**
   * Select all action.
   */
  public selectAll(): void {
    this.onSelectAll.emit();
  }

  /**
   * Unselect all action.
   */
  public deSelectAll(): void {
    this.onDeSelectAll.emit();
  }

  public refresh(): void {
    this.onRefresh.emit();
  }

  public translate(translation: string): string {
    return this.$translate.instant(`BUTTON.${translation}`);
  }
}
