import { Injectable } from '@angular/core';
import { IMarketplace } from '@components/export-logistics/forms/choice-marketplaces-form.component';

@Injectable()
export class MarketplacesService {

  public marketplacesChecked: IMarketplace[] = [];

  public setMarketplacesChecked(marketplacesChecked: IMarketplace[]): void {
    this.marketplacesChecked = marketplacesChecked;
  }

  public getMarketplacesChecked(): IMarketplace[] {
    return this.marketplacesChecked;
  }
}
