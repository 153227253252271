import { AbstractResource } from '../resources/abstract.resource';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Resource } from '@decorators';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  entryPoint: '/wysiwygs',
})
export class WysiwygResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  public upload(file: any): Observable<object> {
    const data: FormData = new FormData();

    data.append('file', file, file.name);
    data.append('upload_file', '1');

    return this.uploadFile(data, {entryPoint: `${this.entryPoint}/upload`, blocking: false});
  }
}
