import { Component, Inject, Input, OnInit } from '@angular/core';
import { AbstractResource } from '@resources/abstract.resource';
import { ProductResource } from '@components/product/product.resource';
import { AbstractFormComponent } from '@components/generic/Form/abstract-form.component';
import { AuthService } from '@services/auth.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SnackbarService } from '@components/snackbar';
import { FormNotifierService } from '@services/form-notifier.service';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { IImage } from '@components/generic/List';
import { IFileInfo } from '@components/generic/Form/file-uploader/interfaces/file-info.interface';
import { takeUntil } from 'rxjs/operators';
import { switchMap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { IProductWebsiteMarketplaceImageList } from '@components/product/interfaces/product-marketplace-form.interface';
import { IMasterProduct } from '@components/product/interfaces/product-form.interface';

@Component({
  selector: 'app-product-website-marketplace-upload-translations-form',
  template: require('./product-website-marketplace-upload-translations-form.component.html'),
  providers: [{provide: AbstractResource, useClass: ProductResource}],
})
export class ProductWebsiteMarketplaceUploadTranslationsFormComponent extends AbstractFormComponent implements OnInit {

  @Input() public localeTab: string;
  @Input() public translationsFA: FormArray;
  @Input() public tabIndex: number = 0;
  @Input() public productMarketplaceImageList: IProductWebsiteMarketplaceImageList[];
  @Input() public masterProduct: IMasterProduct;

  @Input() public product: any;

  public fileRestrictions: FileRestrictions = { allowedExtensions: ['.jpg', '.jpeg', '.png', '.mp4', '.webm'] };
  public imgList: IFileInfo[];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private snackbar: SnackbarService,
    private formNotifier: FormNotifierService,
    @Inject('DialogService') private dialog: any,
    private formBuilder: FormBuilder,
  ) {
    super($translate, authService, resource, state);
  }

  public ngOnInit(): void {
    this.translationsFA.at(this.tabIndex).get('file').valueChanges
      .pipe(
        takeUntil(this.destroyed$),
        switchMap((event: {subscriber: BehaviorSubject<any>, type: string}) => {
          return event.type === 'select' ? event.subscriber : [];
        })
      )
      .subscribe((selectedFiles: any[]) => {
        this.imgList = selectedFiles.map((img: IFileInfo) => {
          if (!img.additionalContentForm) {
            img.additionalContentForm = this.formBuilder.group({
              name: ['', Validators.minLength(40)],
              titleAttribute: ['', Validators.minLength(40)],
              altAttribute: ['', Validators.minLength(40)],
              featured: [false],
              dimensionType: [false],
              backgroundType: [false],
              ambianceType: [false],
              isVideo: [false],
            });
          }
          return img;
        });
      });
  }

  public updatePosition(masterProductId: string, img: IImage): void {
    (<ProductResource>this.resource).partialUpdateWebsiteImage(
      +masterProductId,
      +img.id,
      { position: img.position },
      { dontUseModel: true }
    )
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.snackbar.validate('ALERTS.DATA.UPDATE');
      })
    ;
  }

  public delete(masterProductId: string, imageList: IImage[]): void {
    this.deleteImage(+masterProductId, imageList, 0, imageList.length);
  }

  private deleteImage(masterProductId: number, imageList: IImage[], index: number, imagesLength: number): void {
    (<ProductResource>this.resource).destroyWebsiteImage(masterProductId, +imageList[index].id, { dontUseModel: true })
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.snackbar.validate(this.translate('ALERTS.DATA.UPDATE'));

        imagesLength--;
        if (0 === imagesLength) {
          return;
        }

        index++;
        this.deleteImage(masterProductId, imageList, index, imagesLength);
      })
    ;
  }

  private saveImgForm(form: FormGroup, fileId: string, masterProductId: string): void {
    const body: any = {
      name: form.get('name').value,
      titleAttribute: form.get('titleAttribute').value,
      altAttribute: form.get('altAttribute').value,
      featured: form.get('featured').value,
      dimensionType: form.get('dimensionType').value,
      backgroundType: form.get('backgroundType').value,
      ambianceType: form.get('ambianceType').value,
      isVideo: form.get('isVideo').value,
      version: this.product.getPreviewVersion(),
    };

    (<ProductResource>this.resource).partialUpdateWebsiteImage(+masterProductId, +fileId, body, { dontUseModel: true })
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.snackbar.validate(this.translate('ALERTS.DATA.UPDATE'));

        if (!body.featured) {
          return;
        }

        this.productMarketplaceImageList = this.productMarketplaceImageList.map((list: IProductWebsiteMarketplaceImageList) => {
          list.imageList = list.imageList.map((img: IImage) => {
            const image = {...img, featured: img.id === fileId };
            image.additionalContentForm.get('featured').setValue(image.featured);
            return image;
          });

          return list;
        });
      });
  }
}
