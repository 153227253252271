import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GenericFormModule } from '@components/generic/generic-form.module';
import { GenericModule } from '@components/generic/generic.module';
import { AttributeFamilyResource } from '@resources';
import { AttributeSetResource } from '@components/attribute-set/attribute-set.resource';
import { AttributeFamilyEditComponent } from './single/edit/attribute-family-edit.component';
import { AttributeFamilyFormComponent } from './single/form/attribute-family-form.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';

@NgModule({
  declarations: [
    AttributeFamilyEditComponent,
    AttributeFamilyFormComponent,
  ],
  entryComponents: [
    AttributeFamilyEditComponent,
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    DropDownsModule,
    GenericFormModule,
    GenericModule,
  ],
  providers: [
    AttributeFamilyResource
  ]
})
export class AttributeFamilyModule {}
