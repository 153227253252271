import { AbstractComponent } from '@components/generic/abstract.component';
import { Component, Inject, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { ShipmentResource } from '@resources/shipment.resource';
import { ShipmentInterface } from '@components/order-manager/interfaces/shipment.interface';
import { WarehousesResource } from '@components';
import { IWarehouses } from '@components/warehouses/models';
import { CarrierResource } from '@resources';
import { EventEmitter } from '@angular/core';
import '@progress/kendo-angular-intl/locales/fr/numbers';

@Component({
    selector: 'app-order-manager-shipping-edit',
    template: require('./order-manager-shipping-edit.component.html'),
    styles: [require('./order-manager-shipping-edit.component.scss')],
    providers: [
        { provide: AbstractResource, useClass: ShipmentResource },
    ],
})
export class OrderManagerShippingEditComponent extends AbstractComponent implements OnInit {

    @Input() orderCountryCode: string;
    @Input() shipments: ShipmentInterface[];

    @Output() cancel = new EventEmitter();

    public showPackages: boolean[] = [];
    public warehouses: any[] = [];
    public carriers: {[key: string]: any[]} = {};

    public lastDragObject: any;
    public lastDragObjectParentIndex: any;
    public lastDragObjectItemIndex: any;

    private excludedCarriers: {[key: string]: {carriers: any[], skus: any[]}} = {};

    public displayCarryingCost: {[key: string]: boolean} = {};

  constructor(
        @Inject('TranslationService') $translate: ng.translate.ITranslateService,
        authService: AuthService,
        resource: AbstractResource,
        private warehouseResource: WarehousesResource,
        private carrierResource: CarrierResource,
        @Inject('StateService') state: ng.ui.IStateService
    ) {
        super($translate, authService, resource, state);
    }

    ngOnInit(): void {
        this.excludedCarriersPerShipment();
        this.fetchWarehouses();
        this.fetchCarriers();
        this.initDisplayCarrierCost();
    }

    onAddNewShipment() {
        this.shipments.push(
            {
                id: '',
                warehouse: null,
                carrier: null,
                items: [],
                carryingCost: null,
                carryingCostForced: false
            }
        );
    }

    fetchWarehouses() {
        this.warehouseResource.cGet(
            {
                'carriers.country.code[]': this.orderCountryCode,
                'active': true,
                'pagination': false,
            },
            {
                isHydra: true,
                returnHydraMembers: true,
                dontUseModel: true,
                blocking: false
            }
        )
            .takeUntil(this.destroyed$)
            .subscribe((response: any) => {
                this.warehouses = response.map((warehouse: IWarehouses): any => {
                    return {
                        '@id': warehouse['@id'],
                        code: warehouse.code,
                        name: warehouse.name,
                    };
                });
            });
    }

    fetchCarriers() {
        this.carrierResource.cGet({
            'country.code[]': this.orderCountryCode,
            'carrierGroup.active': true,
            'pagination': false,
            'allowedInManual': true,
        },
            {
                isHydra: true,
                returnHydraMembers: true,
                dontUseModel: true,
                blocking: false
            }).subscribe((response: any) => {
                for (const shipment of this.shipments) {
                    let excludedSkus: string[] = [];
                    this.carriers[shipment.id] = response.map((item: any) => {
                        let carrierWithFamily = item.carrierGroup.name;
                        if (item.carrierFamily != null) {
                            carrierWithFamily += ' (' + item.carrierFamily.reference + ')';
                        }
                        excludedSkus = [];
                        for (const exclu of item.excludedProducts) {
                            excludedSkus.push(exclu.sku);
                        }

                        return {
                            code: item.carrierGroup.code,
                            name: item.carrierGroup.name,
                            carrier: item,
                            carrierWithFamily: carrierWithFamily,
                            excluded: excludedSkus,
                        };
                    }).filter((carrier: any) => !(
                        this.excludedCarriers[shipment.id].carriers.includes(carrier.code)
                        && this.excludedCarriers[shipment.id].skus.some(sku => carrier.excluded.includes(sku))
                    ));
                }
            });
    }

    onCarrierChange(event: any, index: number) {
        const shipmentId = this.shipments[index].id;
        this.shipments[index].carrier = this.carriers[shipmentId].find(e => e.name === event).carrier;
        this.displayCarryingCost[shipmentId] = this.isCarrierAffretement(this.shipments[index]);
    }

    onWarehouseChange(event: any, index: number) {
        this.shipments[index].warehouse = this.warehouses.find(e => e.code === event);
    }

    onCarryingCostChange(event: any, index: number) {
      this.shipments[index].carryingCost = event;
    }

    onDeleteShipment(index: number) {
        this.shipments.splice(index, 1);
    }

    drop(ev: any, index: number) {
        this.shipments[this.lastDragObjectParentIndex].items.splice(this.lastDragObjectItemIndex, 1);
        this.shipments[index].items.push(this.lastDragObject);

        this.excludedCarriersPerShipment();
        this.fetchCarriers();
        ev.preventDefault();
    }

    drag(ev: any, item: any, index: number, itemIndex: number) {
        this.lastDragObjectParentIndex = index;
        this.lastDragObject = item;
        this.lastDragObjectItemIndex = itemIndex;
    }

    allowDrop(ev: any) {
        ev.preventDefault();
    }

    async onSubmit() {
        const error = [];
        const shipmentsToUpdate = this.getShipmentsToUpdate();
        const shipmentsToCreate = this.getShipmentsToCreate();

        for (const shipment of shipmentsToUpdate) {
            try {
                await this.resource.updateShipment(shipment.id, shipment);
            } catch (e) {
                error.push(e);
            }
        }

        for (const shipment of shipmentsToCreate) {
            try {
                await this.resource.createShipment(shipment);
            } catch (e) {
                error.push(e);
            }
        }

        if (error.length === 0) {
            this.cancel.emit();
        }
    }

    private getShipmentsToUpdate() {
        const shipmentsToUpdate: any[] = [];

        for (const shipment of this.shipments) {
            if (shipment['@id'] && 0 < shipment.items.length) {
                shipmentsToUpdate.push(shipment);
            }
        }

        // tslint:disable-next-line
        for (const i in shipmentsToUpdate) {
            shipmentsToUpdate[i] = this.prepareBody(shipmentsToUpdate[i]);
        }

        return shipmentsToUpdate;
    }

    private getShipmentsToCreate() {
        const shipmentsToCreate: any[] = [];

        for (const shipment of this.shipments) {
            if (!shipment['@id']) {
                shipmentsToCreate.push(shipment);
            }
        }

        // tslint:disable-next-line
        for (const i in shipmentsToCreate) {
            shipmentsToCreate[i] = this.prepareBody(shipmentsToCreate[i]);
        }

        return shipmentsToCreate;
    }

    private prepareBody(shipment: any) {
        const items = [];
        for (const item of shipment.items) {
            items.push(item['@id']);
        }

        return {
            id: shipment.id,
            items: items,
            warehouse: shipment.warehouse ? shipment.warehouse['@id'] : null,
            carrier: shipment.carrier ? shipment.carrier['@id'] : null,
            carryingCost: this.isCarrierAffretement(shipment) ? shipment.carryingCost : null,
            carryingCostForced: this.isCarrierAffretement(shipment),
        };
    }

    onCancel() {
        this.cancel.emit('');
    }

    excludedCarriersPerShipment() {
        for (const shipment of this.shipments) {
            let items: any[] = [];
            let skus: any[] = [];

            for (const item of shipment.items) {
                const excludeCarriersCodes = item.product.excludedCarrierCodes;
                items = [...items, ...excludeCarriersCodes];
                skus = [...skus, item.product.sku];
            }
            this.excludedCarriers[shipment.id] = {carriers: items, skus: skus};
        }
    }

    carriersLength() {
        return Object.keys(this.carriers).length;
    }

    initDisplayCarrierCost() {
      for (const shipment of this.shipments) {
        this.displayCarryingCost[shipment['id']] = this.isCarrierAffretement(shipment);
      }
    }

    isCarrierAffretement(shipment: ShipmentInterface): boolean {
      return 'affretement' === shipment.carrier.carrierGroup.code || 'affretement_take_back' === shipment.carrier.carrierGroup.code;
    }
}
