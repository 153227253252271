import { Component, Inject, OnInit } from '@angular/core';
import { AbstractResource, OneyOpcResource } from '@resources';
import { AuthService } from '@services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OneyOpcModel, SnackbarService } from '@components';
import { IOneyOpc } from '@components/oney-opc/interfaces/oney-opc.interface';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { EDITION_PAGE } from '@interfaces';
import { DATE_FULL_FORMAT, DATE_SHORT_FORMAT } from '@constants';
import moment = require('moment');
import { SessionHelper } from '@helpers';

@Component({
  selector: 'app-oney-opc-form',
  template: require('./oney-opc-form.component.html'),
  styles: [require('./oney-opc-form.component.scss')],
  providers: [
    { provide: AbstractResource, useClass: OneyOpcResource },
  ]
})
export class OneyOpcFormComponent extends AbstractPageComponent implements OnInit {

  public form: FormGroup;
  public types: string[] = [];

  protected readonly EDITION_PAGE = EDITION_PAGE;
  protected readonly SessionHelper = SessionHelper;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    public resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    public formBuilder: FormBuilder,
    private snackbar: SnackbarService,
    @Inject('DialogService') private dialog: any
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    this.fetchTypes();

    if (EDITION_PAGE === this.pageType) {
      this.fetch();

      return;
    }

    this.buildForm();
  }

  private buildForm(oneyOpc?: OneyOpcModel): void {
    this.form = this.formBuilder.group({
      code: [oneyOpc ? oneyOpc.code : null, Validators.required],
      locale: [oneyOpc ? oneyOpc.locale : null || SessionHelper.getLocale(), Validators.required],
      description: [oneyOpc ? oneyOpc.description : null],
      active: [oneyOpc ? oneyOpc.active : null],
      waitingPayment: [oneyOpc ? oneyOpc.waitingPayment : null],
      startDate: [oneyOpc ? oneyOpc.startDate : null],
      startTime: [oneyOpc ? oneyOpc.startTime : null],
      endDate: [oneyOpc ? oneyOpc.endDate : null],
      endTime: [oneyOpc ? oneyOpc.endTime : null],
      type: [oneyOpc ? oneyOpc.type : null],
      isFreeFees: [oneyOpc ? oneyOpc.freeFees : null],
    });
  }

  private fetch(): void {
    this.resource.get(this.state.params.id, { model: OneyOpcModel })
      .takeUntil(this.destroyed$)
      .subscribe((response: OneyOpcModel): void => {
        this.buildForm(response);
      });
  }

  public submit(event?: {redirect: boolean}): void {
    const body: IOneyOpc = this.prepareBody();

    if (EDITION_PAGE === this.pageType) {
      this.dialog.confirm(this.translate('PAGE.ONEY_OPC.CONFIRM.UPDATE'))
        .then((): void => {
          this.resource.update(this.state.params.id, body)
            .takeUntil(this.destroyed$)
            .subscribe((): void => {
              this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));

              if (event && event.redirect) {
                this.state.go(`${this.resource.routeName}.list`);

                return;
              }
            });
        });

      return;
    }

    this.dialog.confirm(this.translate('PAGE.ONEY_OPC.CONFIRM.CREATE'))
      .then((): void => {
        this.resource.create(body)
          .takeUntil(this.destroyed$)
          .subscribe((): void => {
            this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
            this.state.go(`${this.resource.routeName}.list`);

            return;
          });
      });

  }

  private prepareBody(): any {
    const form = this.form.value;

    let startDate = null;
    let endDate = null;

    if (form.startDate) {
      if (form.startTime) {
        startDate = moment(form.startDate, DATE_SHORT_FORMAT)
          .add(form.startTime.getHours(), 'hours')
          .add(form.startTime.getMinutes(), 'minutes')
          .add(0, 'seconds')
          .format(DATE_FULL_FORMAT);
      } else {
        startDate = moment(form.startDate, DATE_SHORT_FORMAT).startOf('day').format(DATE_FULL_FORMAT);
      }
    }

    if (form.endDate) {
      if (form.endTime) {
        endDate = moment(form.endDate, DATE_SHORT_FORMAT)
          .add(form.endTime.getHours(), 'hours')
          .add(form.endTime.getMinutes(), 'minutes')
          .add(59, 'seconds')
          .format(DATE_FULL_FORMAT);
      } else {
        endDate = moment(form.endDate, DATE_SHORT_FORMAT).startOf('day').format(DATE_FULL_FORMAT);
      }
    }

    return {
      code: form.code,
      locale: form.locale,
      description: form.description,
      active: form.active,
      waitingPayment: form.waitingPayment,
      startDate: startDate,
      endDate: endDate,
      type: form.type,
      isFreeFees: form.isFreeFees,
    };
  }

  public delete(): void {
    this.dialog.confirm(this.translate('PAGE.ONEY_OPC.CONFIRM.DELETE'))
      .then(() => {
        this.resource.remove(this.state.params.id)
          .takeUntil(this.destroyed$)
          .subscribe(() => {
            this.state.go(`${this.resource.routeName}.list`);
          });
      });
  }

  public fetchTypes(): void {
    this.resource.cGet(null, { entryPoint: '/v2/oney_opc_types', dontUseModel: true, returnHydraMembers: true })
      .takeUntil(this.destroyed$)
      .subscribe((response: string[]): void => {
        this.types = response;
      });
  }
}
