import {Component, Inject, Input, OnInit} from '@angular/core';
import {AbstractResource} from '@resources';
import {AbstractPageComponent} from '@components/generic/abstract-page.component';
import {AuthService, FormNotifierService} from '@services';
import {SnackbarService} from '@components/snackbar';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs/Observable';
import {catchError, takeUntil} from 'rxjs/operators';
import {of} from 'rxjs/observable/of';
import {forkJoin} from 'rxjs/observable/forkJoin';
import {EavAttributeValueResource} from '@components/eav-attribute/resources/eav-attribute-value.resource';
import {EavAttributeValueModel} from '@components/eav-attribute/models/eav-attribute-value.model';
import {EavAttributeResource} from '@components/eav-attribute/resources/eav-attribute.resource';
import {EavAttributeModel} from '@components/eav-attribute/models/eav-attribute.model';
import {EavAttributeOptionModel} from '@components/eav-attribute/models/eav-attribute-option.model';
import {EavType} from '@components/eav-attribute/models/eav-type.enum';
import {IProductForm} from '@components/product/interfaces/product-form.interface';
import * as moment from 'moment';
import {DATE_FULL_FORMAT, DATE_SHORT_FORMAT} from '@constants';

@Component({
  selector: 'app-eav-attribute-value-form',
  template: require('./eav-attribute-value-form.component.html'),
  providers: [
    { provide: AbstractResource, useClass: EavAttributeValueResource },
    EavAttributeResource
  ],
})
export class EavAttributeValueFormComponent extends AbstractPageComponent implements OnInit {
  public readonly EavType = EavType;
  public form: FormGroup;
  public attributes: EavAttributeModel[] = [];
  public attributeValues: { [key: string]: EavAttributeValueModel } = {};

  private attributeValuesPendingCreation: { [key: number]: any } = {};
  private attributeValuesPendingUpdate: { [key: number]: any } = {};
  private attributeValuesPendingDeletion: { [key: number]: null } = {};

  @Input() productModel: IProductForm;

  @Input() fields: string[] = [];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    public formBuilder: FormBuilder,
    public formNotifier: FormNotifierService,
    private snackbar: SnackbarService,
    private attributeResource: EavAttributeResource
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    const observables$: Observable<any>[] = [];

    let params = {};

    params = { pagination: false };

    if (this.fields.length > 0) {
      params = { pagination: false, code: this.fields };
    }

    observables$.push(this.attributeResource.cGet(
        params,
        { model: EavAttributeModel, returnHydraMembers: true }
    ).pipe(catchError((error) => of(error))));

    observables$.push(this.resource.cGet(
        { masterProduct: this.productModel.masterProduct.id, pagination: false },
        { model: EavAttributeValueModel, returnHydraMembers: true }
    ).pipe(catchError((error) => of(error))));

    forkJoin(observables$)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((results) => {
        for (const result of results) {
          for (const value of result) {
            if (value instanceof EavAttributeValueModel) {
              this.attributeValues[value.attribute['@id']] = value;
            } else if (value instanceof EavAttributeModel) {
              this.attributes.push(value);
            }
          }
        }

        this.buildForm();
      }
    );
  }

  public handleNumber(rawValue: string, attribute: EavAttributeModel): void {
    if ('' === rawValue) {
      this.form.get(attribute.code).patchValue(rawValue);
    } else if (attribute.type === EavType.INTEGER) {
      this.form.get(attribute.code).patchValue(parseInt(rawValue, 10) || rawValue);
    } else if (attribute.type === EavType.DECIMAL) {
      this.form.get(attribute.code).patchValue(parseFloat(rawValue) || rawValue);
    }
  }

  public submit(event?: any): void {
    this.prepareBodies();

    const observables$: Observable<any>[] = [];

    for (const [key, value] of Object.entries(this.attributeValuesPendingCreation)) {
      observables$.push(this.resource.createForMasterProduct(this.productModel.masterProduct.id, value).pipe(catchError((error) => of(error))));
    }

    for (const [key, value] of Object.entries(this.attributeValuesPendingUpdate)) {
      observables$.push(this.resource.update(String(key), value).pipe(catchError((error) => of(error))));
    }

    for (const [key, value] of Object.entries(this.attributeValuesPendingDeletion)) {
      observables$.push(this.resource.remove(String(key)).pipe(catchError((error) => of(error))));
    }

    forkJoin(observables$)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (responses) => {
          for (const response of responses) {
            if (response instanceof EavAttributeValueModel) {
              this.attributeValues[response.attribute['@id']] = response;
            }
          }
        },
        () => {
          this.snackbar.alert(this.translate('ALERTS.ERROR.API'));
        },
        () => {
          this.attributeValuesPendingCreation = {};
          this.attributeValuesPendingUpdate = {};
          this.attributeValuesPendingDeletion = {};

          this.buildForm(true);
        }
      )
    ;
  }

  private buildForm(patch: boolean = false): void {
    const inputs: { [key: string]: any } = {};

    for (const attribute of this.attributes) {
      const attributeValue = this.attributeValues[attribute['@id']] || null;
      const control = this.form ? this.form.get(attribute.code) : null;

      if (attributeValue) {
        if (attributeValue.value instanceof EavAttributeOptionModel) {
          inputs[attribute.code] = [attributeValue.value.option, []];
        } else if (moment.isMoment(attributeValue.value)) {
          inputs[attribute.code] = [attributeValue.value.format(DATE_SHORT_FORMAT), []];
        } else {
          inputs[attribute.code] = [attributeValue.value, []];
        }
      } else {
        inputs[attribute.code] = [null, []];
      }

      if (control && patch) {
        if (control.dirty) {
          continue;
        }

        control.patchValue(inputs[attribute.code][0]);
      }
    }

    if (patch) {
      return;
    }

    this.form = this.formBuilder.group(inputs);
  }

  private prepareBodies(): void {
    for (const field of Object.keys(this.form.controls)) {
      const attribute = this.attributes.find((attributeModel: EavAttributeModel) => attributeModel.code === field);
      const initialAttributeValue = this.attributeValues[attribute['@id']] || null;
      const control = this.form.get(field);

      if (initialAttributeValue instanceof EavAttributeValueModel) {
        const isMoment = moment.isMoment(initialAttributeValue.value);
        let initialAttributeValueRaw;

        if (initialAttributeValue.value instanceof EavAttributeOptionModel) {
          initialAttributeValueRaw = initialAttributeValue.value.option;
        } else if (isMoment) {
          // @ts-ignore
          initialAttributeValueRaw = initialAttributeValue.value.format(DATE_SHORT_FORMAT);
        } else {
          initialAttributeValueRaw = initialAttributeValue.value;
        }

        if (control.value !== initialAttributeValueRaw) {
          if (null !== control.value && '' !== control.value) {
            this.attributeValuesPendingUpdate[initialAttributeValue.id] = {
              value: isMoment ? moment(control.value, DATE_SHORT_FORMAT).startOf('day').format(DATE_FULL_FORMAT) : control.value
            };
          } else {
            this.attributeValuesPendingDeletion[initialAttributeValue.id] = null;

            delete this.attributeValues[attribute['@id']];
          }
        }
      } else {
        if (null !== control.value && '' !== control.value) {
          this.attributeValuesPendingCreation[attribute.id] = {
            attribute: attribute['@id'],
            value: attribute.type === EavType.DATETIME ? moment(control.value, DATE_SHORT_FORMAT).startOf('day').format(DATE_FULL_FORMAT) : control.value
          };
        }
      }
    }
  }
}
