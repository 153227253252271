import { IBanner, IBannerTranslation } from '@components/banner/banner.interface';
import { ColorHelper } from '@helpers/ColorHelper';
import * as moment from 'moment';
import { FULL_EUROPEEN_DATE_FORMAT } from '@constants/date';
import { SessionHelper } from '@helpers/session.helper';
import {IBannerType} from '@components/banner/banner-type.interface';

export const defaultFontColor = '#000000ff';
export const defaultBgColor = '#ffffffff';

export class BannerModel implements IBanner {
  id: string;
  active: boolean;
  bannerTypes: IBannerType[];
  timerDisplayed: boolean;
  fontColor: string;
  bgColor: string;
  startDate: string;
  endDate: string;
  startDateFormatted: string;
  endDateFormatted: string;
  translations?: {
    [locale: string]: IBannerTranslation;
  };
  content: string;

  constructor(model: IBanner) {
    this.id = model.id;
    this.active = model.active;
    this.timerDisplayed = model.timerDisplayed;
    this.fontColor = ColorHelper.convertRGBAToHex8(model.fontColor);
    this.bgColor = ColorHelper.convertRGBAToHex8(model.bgColor);
    this.startDate = model.startDate;
    this.bannerTypes = model.bannerTypes;
    this.endDate = model.endDate;
    this.startDateFormatted = model.startDate ? moment(model.startDate).format(FULL_EUROPEEN_DATE_FORMAT) : null;
    this.endDateFormatted = model.endDate ? moment(model.endDate).format(FULL_EUROPEEN_DATE_FORMAT) : null;
    this.translations = model.translations;
    this.content = model.translations && model.translations[SessionHelper.getLocale()] ?
      model.translations[SessionHelper.getLocale()].content :
      null
    ;
  }
}
