import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { CarrierCapacityResource } from '@resources/carrier-capacity.resource';
import { Moment } from 'moment';
import { AbstractResource } from '@resources';
import { SnackbarService } from '@components/snackbar';

@Component({
  selector: 'app-scale-week',
  template: require('./scale-week.component.html'),
  styles: [require('./week.component.scss')],
  providers: [
    { provide: AbstractResource, useClass: CarrierCapacityResource },
  ],
})
export class ScaleWeekComponent extends AbstractPageComponent {
  @Input() public scales: {[key: string]: any[]} = {};
  @Input() public days: Moment[] = [];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private carrierCapacityResource: CarrierCapacityResource,
    private snackbar: SnackbarService,
    @Inject('DialogService') private dialog: any,
  ) {
    super($translate, authService, null, state);
  }
}
