import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SparePartPageComponent } from './spare-part-page.component';
import { SparePartPageWarehouseAgentComponent } from './spare-part-warehouse-agent/spare-part-page-warehouse-agent.component';
import { GenericFormModule } from '@components/generic/generic-form.module';
import { GenericModule } from '@components/generic/generic.module';
import { SparePartListComponent } from '@components/spare-part/spare-part-list.component';
import { SparePartSearchComponent } from '@components/spare-part/spare-part-search.component';
import { ProductModule} from '@components/product';
import { ProductResource } from '@components/product/product.resource';
import { SparePartFiltersFormService } from './spare-part-filters-form.service';
import { ExportModule } from '@components/export/export.module';
import {DialogModule} from '@progress/kendo-angular-dialog';

@NgModule({
  declarations: [
    SparePartPageComponent,
    SparePartPageWarehouseAgentComponent,
    SparePartListComponent,
    SparePartSearchComponent,
  ],
  entryComponents: [
    SparePartPageComponent,
    SparePartPageWarehouseAgentComponent,
    SparePartListComponent,
    SparePartSearchComponent,
  ],
    imports: [
        CommonModule,
        GenericFormModule,
        GenericModule,
        ProductModule,
        ExportModule,
        DialogModule
    ],
  exports: [
    SparePartListComponent,
    SparePartSearchComponent,
  ],
  providers: [
    ProductResource,
    SparePartFiltersFormService
  ],
})

export class SparePartModule { }
