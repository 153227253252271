'use strict'

import { SessionHelper } from '@helpers'

angular
  .module 'ammsFrontendApp'
  .directive 'langSwitchingWarning', [
    '$dialog',
    '$state',
    '$translate',
    ($dialog, $state, $translate) ->
      restrict: 'A'
      link: ->
        currentLocale = SessionHelper.getLocale()
        angular.element(window).on('blur', ->
          currentLocale = SessionHelper.getLocale()
        )

        angular.element(window).on('focus', ->
          if (currentLocale != SessionHelper.getLocale())
            content = angular.element('<p/>')
              .addClass('popin-calltoaction')
              .append(
                angular.element('<a/>')
                  .attr('href', 'http://clients.alicesgarden.pro/videos/navigation-privee-walisoft.html')
                  .attr('target', '_blank')
                  .text($translate.instant('ALERTS.LANG_SWITCHING.CALL_TO_ACTION'))
              )

            dialog = $dialog.alert(
              $translate.instant('ALERTS.LANG_SWITCHING.MESSAGE') + content.get(0).outerHTML,
              $translate.instant('ALERTS.LANG_SWITCHING.TITLE')
            )

            redirect = ->
              SessionHelper.set('switchingCountry', 1)
              $state.reload()

            # first redirect is for click on button, second is for click outside of the modal
            dialog.then(redirect, redirect)
        )
  ]
