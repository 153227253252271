import { Component } from '@angular/core';
import { TranslatedTitleService } from './translated-title.service';

@Component({
  selector: 'app-page-title',
  template: ``
})

export class PageTitleComponent {
  constructor(translateTitle: TranslatedTitleService) {}
}
