import { OnDestroy } from '@angular/core';
import { Subject } from 'rxjs/Subject';

/**
 * Offers common features for services.
 */
export class AbstractService implements OnDestroy {

  protected destroyed$: Subject<boolean> = new Subject();

  protected constructor() {}

  /**
   * @inheritDoc
   */
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
