import {Component, Inject} from '@angular/core';
import {AuthService} from '@services';
import {AbstractResource} from '@resources';
import {AbstractPageComponent} from '@components/generic/abstract-page.component';
import { GrcResource } from '@components/grc/grc.resource';

@Component({
  selector: 'app-grc-dashboard',
  template: require('./grc-dashboard.component.html'),
  styles: [require('./grc-dashboard.component.scss')],
  providers: [
    { provide: AbstractResource, useClass: GrcResource },
  ],
})
export class GrcDashboardComponent extends AbstractPageComponent {

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }
}
