'use strict'

import { SessionHelper } from '@helpers/session.helper'

###
  @name OrderHelper

  @author Yann Prou <yann@les-tilleuls.coop>
###
angular
  .module('ammsFrontendApp')
  .service('OrderHelper', [
    '$dialog',
    '$translate',
    '$window',
    'LogisticResource',
    'OrderResource',
    'AuthService',
    (
      $dialog,
      $translate,
      $window,
      LogisticResource,
      OrderResource,
      AuthService
    ) ->
      setDateExpired: (key, timestamp) ->
        key = key + '_date_expired'
        SessionHelper.set(key, timestamp)

        return this

      isDateExpired: (key) ->
        key = key + '_date_expired'
        data = SessionHelper.get(key)

        if not data?
          return true

        if Date.now() >= ((1000 * 60 * 60) + data)
          return true

        return false

      ###
        The orderItem must be locked for the edition.
        Its status is higher than "handling".

        @param {String} status

        @returns {Boolean}
      ###
      isOrderItemLockedForEdition: (status) ->
        return ['exporting', 'in_transit', 'delivered', 'export_error'].includes(status)

      ###
        Check if the status field can be enabled.

        @param {Object}  orderItem
        @param {Boolean} awaitingData
        @param {Boolean} editMode

        @returns {Boolean}
      ###
      isFieldStatusEnabled: (orderItem = {}, awaitingData = false, editMode = false) ->
        isEnabled = false

        if AuthService.isGranted('ROLE_WALISOFT_AGENT')
          # Then adding a new item in an order
          if !editMode && orderItem.isNew
            return true

          # Then edition an item in an order
          if editMode && !orderItem.isNew?
            if orderItem.marketplaceSynced
              isEnabled = true

            if awaitingData && !AuthService.isGranted('ROLE_WALISOFT_AGENT')
              isEnabled = false

            if this.isOrderItemLockedForEdition(orderItem.status)
              isEnabled = false

            if (this.areOrderItemPackagesInDifferentStatus(orderItem))
              isEnabled = false

        return isEnabled

      areOrderItemPackagesInDifferentStatus: (orderItem) ->
        packagesStatus = orderItem.packages && orderItem.packages.map((pkg) -> pkg.status)

        return (new Set(packagesStatus)).size > 1

      ###
        Check if the warehouse field can be enabled.

        @param {Object}  orderItem
        @param {Boolean} editMode

        @returns {Boolean}
      ###
      isFieldWarehouseEnabled: (orderItem = {}, editMode = false) ->
        if AuthService.isGranted('ROLE_WALISOFT_AGENT')
          # Then adding a new item in an order
          if !editMode && orderItem.isNew
            return true

          # Then edition an item in an order
          if editMode && !orderItem.isNew? &&
            orderItem.canChange &&
            !this.isOrderItemLockedForEdition(orderItem.status)
              return true

        return false

      ###
        Check if the carrier field can be enabled.

        @param {Object}  orderItem
        @param {Boolean} awaitingData
        @param {Boolean} editMode

        @returns {Boolean}
      ###
      isFieldCarrierEnabled: (orderItem = {}, awaitingData = false, editMode = false) ->
        isEnabled = false

        if AuthService.isGranted('ROLE_WALISOFT_AGENT')
          # Then adding a new item in an order
          if !editMode && orderItem.isNew
            return true

          # Then edition an item in an order
          if editMode && !orderItem.isNew?
            if orderItem.canChange && orderItem.canChangeCarrier
              isEnabled = true

            if awaitingData && !AuthService.isGranted('ROLE_WALISOFT_AGENT')
              isEnabled = false

            if this.isOrderItemLockedForEdition(orderItem.status)
              isEnabled = false

        return isEnabled

      ###
        Check if order items contains the targeted status.

        @param {Object[]} orderItems
        @param {string}   status

        @returns {Boolean}
      ###
      checkStatusInOrderItems: (orderItems = [], status) ->
        return 0 != orderItems.filter((orderItem) -> status == orderItem.status).length

      ###
        Check if order items contains the targeted carriers.

        @param {Object[]} orderItems
        @param {Object[]} carriers

        @returns {Boolean}
      ###
      checkCarriersInOrderItems: (orderItems = [], carriers = []) ->
        return 0 != orderItems.filter((orderItem) -> carriers.includes(orderItem.carrier)).length

      ###
        Gets the total of a tax for a given order.

        @param {Object} order
        @param {string} taxName
      ###
      getTotalTax: (order, taxName) ->
        totalTax = 0
        for item in order.items
          if item.status isnt 'cancelled' && item.status isnt 'refused'
            quantity = if item.isNew || item.manuallyAdded then item.quantity else item.initialQuantity
            totalTax += item[taxName] * quantity

        return +totalTax.toFixed(2)

      doExport: (modexp, countryCode) ->
        modexp.countryCode = countryCode

        subscriber = OrderResource
          .filterStats(modexp)
          .subscribe (data) ->
            message = ''

            subscriber.unsubscribe()

            for warehouse, warehouseCarriers of data.stats
              if warehouse == ''
                warehouseName = $translate.instant 'PAGE.ORDER.LIST.TEXT.EXPORT.LOGISTIC.WITHOUT_WAREHOUSE'
              else
                warehouseName = warehouse

              message = "#{ message }<strong>#{ warehouseName.toUpperCase() }</strong>\n"

              for carrierName, warehouseData of warehouseCarriers
                for carrierData in warehouseData
                  message += $translate.instant 'PAGE.ORDER.LIST.TEXT.EXPORT.LOGISTIC.LINE',
                    carrier: carrierData.carrier
                    zone: carrierData.zone
                    numberOfOrders: carrierData.numberOfOrders
                    numberOfPackages: carrierData.numberOfPackages
                  message = "#{ message }\n"
              message = "#{ message }\n"

            $dialog.confirm(
              "#{ message }<br><br>#{ $translate.instant 'PAGE.ORDER.LIST.TEXT.EXPORT.LOGISTIC.CONFIRM' }"
            ).then ->
                subscriber = LogisticResource.create(modexp)
                  .subscribe( ->
                    subscriber.unsubscribe()
                    $window.alert $translate.instant('PAGE.ORDER.LIST.TEXT.EXPORT.LOGISTIC.GENERATE_FILE')
                  )
          , ->
            $window.alert $translate.instant 'API.DISCONNECTION'

  ])
