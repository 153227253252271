import {AbstractResource, IRequestOptions} from '../../resources/abstract.resource';
import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { MarketplaceHelper } from '@helpers';
import { WebsiteCategoryListModel } from '@components/website-category/models';
import { CategoriesModel } from '@components/categories/models';
import {Observable} from 'rxjs/Observable';

@Injectable()
@Resource({
  routeName: 'websiteCategories',
  entryPoint: '/v2/categories',
  isHydra: true,
  model: CategoriesModel,
  listModel: WebsiteCategoryListModel,
  cGetDefaultFilters: () => ({
    marketplace: MarketplaceHelper.getWebsiteMarketplace().code,
    'tabs.active': 1,
    'active': 1,
    'order[translations.name]': 'asc'
  }),
  translationKey: 'CATEGORIES',
  listColumns: [
    { field: 'id', translationKey: 'ID', type: 'id' },
    { field: 'name', translationKey: 'NAME', type: 'string' },
    { field: 'active', translationKey: 'ACTIVE', type: 'boolean' },
    { field: 'activeTab', translationKey: 'ACTIVE_TAB', type: 'boolean' },
  ],
})
export class WebsiteCategoryResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  cGet(params: any | null = null, options: IRequestOptions = {}, headers?: HttpHeaders): Observable<object> {
    const query: any = {
      ...params,
      marketplace: MarketplaceHelper.getWebsiteMarketplace().code,
      'order[translations.name]': 'asc',
      'active': params.active,
      'tabs.active': params.activeTab,
      'translations.name': params.name,
    };

    if (undefined !== query['activeTab']) {
      delete query['activeTab'];
    }

    if (undefined !== query.name) {
      delete query.name;
    }

    return super.cGet(query, options, headers);
  }
}
