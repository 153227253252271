import { Component, Inject } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AbstractResource } from '@resources/abstract.resource';
import { AuthService } from '@services/auth.service';

@Component({
    selector: 'app-order-manager-sage-export-result-list',
    template: require('./order-manager-sage-export-result-list.component.html'),
})
export class OrderManagerSageExportResultListComponent extends AbstractComponent {
    private _items: any[];

    set items(items: any[]) {
        this._items = items;
    }

    get items(): any[] {
        return this._items;
    }

    constructor(
        @Inject('TranslationService') $translate: ng.translate.ITranslateService,
        authService: AuthService,
        resource: AbstractResource,
        @Inject('StateService') state: ng.ui.IStateService) {
        super($translate, authService, resource, state);
    }
}
