import {
  IAttributeMarketplaceValue,
  IOffer,
  IProductMarketplaceForm,
  IProductMarketplaceTranslationForm
} from '@components/product/interfaces/product-marketplace-form.interface';
import * as moment from 'moment';
import { DATE_SHORT_FORMAT } from '@constants/date';

export class ProductMarketplaceForm implements IProductMarketplaceForm {
  '@id'?: string;
  id?: string;
  stock: number;
  shippingDate: null|string;
  basePrice: number;
  quantityForCut: number;
  status: string|object;
  shippingPrice: number;
  marketplace: string;
  offers: IOffer[];
  categoryMarketplace: { id: string; label: string } | null;
  attributeMarketplaceValues: IAttributeMarketplaceValue[];
  featuredForced: boolean;
  enabledMailInput: boolean;
  translations: {[locale: string]: IProductMarketplaceTranslationForm };

  constructor(model: IProductMarketplaceForm) {
    this['@id'] = model['@id'];
    this.id = model.id;
    this.stock = model.stock;
    this.shippingDate = model.shippingDate ? moment(model.shippingDate).startOf('day').format(DATE_SHORT_FORMAT) : null;
    this.basePrice = model.basePrice;
    this.quantityForCut = model.quantityForCut;
    this.status = model.status;
    this.shippingPrice = model.shippingPrice;
    this.marketplace = model.marketplace;
    this.offers = model.offers;
    this.categoryMarketplace = model.categoryMarketplace;
    this.attributeMarketplaceValues = model.attributeMarketplaceValues;
    this.featuredForced = model.featuredForced;
    this.enabledMailInput = model.enabledMailInput;
    this.translations = model.translations;
  }
}
