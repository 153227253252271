import { NgModule } from '@angular/core';
import { ReinsurancePointsListComponent, ReinsurancePointsResultListComponent } from '@components/footer/reinsurance-points/list';
import { FooterLinksListComponent, FooterLinksResultListComponent } from '@components/footer/footer-links/list';
import { CommonModule } from '@angular/common';
import { GenericFormModule, GenericModule } from '@components/generic';
import { ReinsurancePointsComponent } from '@components/footer/reinsurance-points/reinsurance-points.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../../pipes/pipes.module';
import {TabsModule} from 'ngx-bootstrap';

@NgModule({
  declarations: [
    FooterLinksListComponent,
    FooterLinksResultListComponent,
    ReinsurancePointsListComponent,
    ReinsurancePointsResultListComponent,
    ReinsurancePointsComponent,
  ],
  entryComponents: [
    FooterLinksListComponent,
    ReinsurancePointsListComponent,
    ReinsurancePointsComponent,
  ],
  imports: [
    CommonModule,
    GenericModule,
    GenericFormModule,
    ReactiveFormsModule,
    PipesModule,
    TabsModule,
  ]
})
export class FooterModule {}
