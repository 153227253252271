import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';

export const TYPE_ALERT: string = 'alert';
export const TYPE_INFO: string = 'info';
export const TYPE_VALID: string = 'valid';
export const TYPE_WARN: string = 'warn';

export interface ISnackbarEventMessage {
  type: string;
  message: string;
}

@Injectable()
export class SnackbarService {
  public static instance: SnackbarService;
  public messages: Subject<ISnackbarEventMessage> = new Subject();

  constructor () {
    if (!SnackbarService.instance) {
      SnackbarService.instance = this;
    }
  }

  /**
   * Emits an event that a snackbar item, for a specific type, should be displayed
   *
   * @param {string} type
   * @param {string} message
   *
   * @fires # MESSAGE_ADD
   */
  public emit(type: string, message: string) {
    this.messages.next({ type, message });
  }

  /**
   * Emits an alert request
   *
   * @param {string} message
   */
  public alert(message: string) {
    this.emit(TYPE_ALERT, message);
  }

  /**
   * Emits an inform request
   *
   * @param {string} message
   */
  public inform(message: string) {
    this.emit(TYPE_INFO, message);
  }

  /**
   * Emits an validate request
   *
   * @param {string} message
   */
  public validate(message: string) {
    this.emit(TYPE_VALID, message);
  }

  /**
   * Emits an warn request
   *
   * @param {string} message
   */
  public warn(message: string) {
    this.emit(TYPE_WARN, message);
  }
}
