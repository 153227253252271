import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { AbstractFormComponent } from '@components/generic/Form/abstract-form.component';
import { AuthService } from '@services/auth.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PictoResource } from '@resources/picto.resource';

@Component({
  selector: 'app-picto-selector',
  template: require('./picto-selector.component.html'),
  styles: [require('./picto-selector.component.scss')],
})
export class PictoSelectorComponent extends AbstractFormComponent implements OnInit {

  @Input() type: string;
  @Input() roles: string|string[] = [];
  @Input() withButton: boolean = true;
  @Input() imgHeight: string = '30';
  @Input() selectedPicto: string;

  @Output() onSubmit: EventEmitter<FormGroup> = new EventEmitter();
  @Output() onChange: EventEmitter<FormGroup> = new EventEmitter();

  public form: FormGroup;
  public pictoList: any[];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: PictoResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    const BASE_URL: string = process.env.BASE_URL;

    this.resource.cGet({type: this.type})
      .takeUntil(this.destroyed$)
      .subscribe((response: string[]) => {
        this.form = new FormGroup({
          picto: new FormControl(this.selectedPicto, Validators.required),
        });

        this.pictoList = response.map(relativeUrl => {
          const fullUrl = `${BASE_URL}/${relativeUrl}`;
          return {
            fullUrl,
            relativeUrl,
          };
        });
      });
  }

  public handleSubmit(): void {
    this.onSubmit.emit(this.form);
  }

  public handleChange(): void {
    this.onChange.emit(this.form);
  }
}
