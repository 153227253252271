'use strict'

import { SessionHelper } from '@helpers'

angular
  .module 'ammsFrontendApp'
  .filter 'marketplaceName', [
    ->
      (marketplaceCode) ->
        if not marketplaceCode?
          return ''

        marketplace = SessionHelper.getMarketplaces().find((item) -> marketplaceCode == item.code)

        if (undefined != marketplace)
          return marketplace.name

        ''
  ]
