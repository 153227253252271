import {AbstractFiltersFieldsService} from '@components/generic/Form/filters/abstract-filters-fields.service';
import {ComboSearchField, DateField, MultiSearchField, SkuSearchField, TextField} from '@components/generic/Form/dynamic/fields';
import {Inject, Injectable} from '@angular/core';
import {FormNotifierService} from '@services';
import {AbstractFormFieldBase} from '@components/generic/Form/dynamic/fields/abstract-form-field-base.class';
import * as moment from 'moment';
import {
  CODE_BE,
  CODE_DE,
  CODE_ES,
  CODE_FR,
  CODE_GB,
  CODE_IT,
  CODE_LU,
  CODE_NL,
  CODE_PT,
  CODE_PL,
  DATE_FULL_FORMAT
} from '@constants';
import {IDropdownData} from '@interfaces/IDropdownData';
import { SessionHelper } from '@helpers';
import { WarehousesResource } from '@components/warehouses';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class UnpairedFiltersFormService extends AbstractFiltersFieldsService {

  COUNTRIES = [
    { label: 'France', value: CODE_FR },
    { label: 'Belgique', value: CODE_BE },
    { label: 'Espagne', value: CODE_ES },
    { label: 'Belgique', value: CODE_BE },
    { label: 'Angleterre', value: CODE_GB },
    { label: 'Pays-Bas', value: CODE_NL },
    { label: 'Portugal', value: CODE_PT },
    { label: 'Italie', value: CODE_IT },
    { label: 'Allemagne', value: CODE_DE},
    { label: 'Luxembourg', value: CODE_LU},
    { label: 'Pologne', value: CODE_PL}
  ];

  private startDateField: TextField = new TextField({
    fieldName: 'dateStart',
    value: null,
    hidden: true,
  });

  private endDateField: TextField = new TextField({
    fieldName: 'dateEnd',
    value: null,
    hidden: true,
  });

  private countryCodeField: MultiSearchField = new MultiSearchField({
    fieldName: 'countries[]',
    label: 'PAGE.ORDER.LIST.FILTER.COUNTRY.LABEL',
    data: null,
    textField: 'label',
    valueField: 'value',
    value: null,
    defaultItem: { label: this.$translate.instant('PAGE.ORDER.LIST.FILTER.COUNTRY.DATA.0'), value: null },
    valuePrimitive: true,
    readonly: false,
  });

  public warehouseField: ComboSearchField = new ComboSearchField({
    fieldName: 'warehouses[]',
    label: 'PAGE.ORDER.LIST.FILTER.WAREHOUSES.LABEL',
    data: null,
    textField: 'label',
    valueField: 'value',
    valuePrimitive: true,
    value: null,
    order: 11,
    readonly: SessionHelper.useLinkedWarehousesOnly() && SessionHelper.getLinkedWarehouses().length === 1,
  });

  private static setDates(newValue: string, startField: TextField, endField: TextField): void {
    if (!newValue || null === newValue[0]) {
      return;
    }

    startField.value = moment(newValue[0]).startOf('day').format(DATE_FULL_FORMAT);
    endField.value = moment(newValue[1]).endOf('day').format(DATE_FULL_FORMAT);
  }

  constructor(
    @Inject('StateService') protected state: ng.ui.IStateService,
    @Inject('TranslationService') public $translate: ng.translate.ITranslateService,
    formNotifier: FormNotifierService,
    private warehouseResource: WarehousesResource
  ) {
    super(formNotifier, state);

    this.fetchWarehouses();
  }

  public getFields(): AbstractFormFieldBase<any>[] {
    const startDate = this.filters.get('date[before]');
    const endDate = this.filters.get('date[after]');

    if (startDate && endDate) {
      this.startDateField.value = startDate;
      this.endDateField.value = endDate;
    }

    const countriesList: IDropdownData[] = [...this.COUNTRIES];
    this.countryCodeField.value = 0 < this.filters.getAll('countries[]').length ? this.filters.getAll('countries[]') : undefined;
    this.countryCodeField.data = countriesList;

    return [
      new DateField({
        fieldName: 'date',
        label: 'PAGE.UNPAIRED.LIST.FILTERS.DATE',
        dateRange: true,
        value: startDate && endDate ? [new Date(startDate), new Date(endDate)] : undefined,
        valueChangedAction: this.setDateValue.bind(this),
        order: 1
      }),
      this.startDateField,
      this.endDateField,
      new SkuSearchField({
        fieldName: 'skus[]',
        label: 'PAGE.ORDER.LIST.FILTER.SKU.LABEL',
        productType: 'product',
        value: 0 < this.filters.getAll('skus[]').length ? this.filters.getAll('skus[]') : undefined,
        valueField: 'sku',
        valuePrimitive: true
      }),
      this.countryCodeField,
      this.warehouseField
    ];
  }

  private setDateValue(newValue: string): void {
    UnpairedFiltersFormService.setDates(newValue, this.startDateField, this.endDateField);
  }

  private getCountryCodesFromUser() {
    const countryCodes = [];
    for (const country of SessionHelper.get('CURRENT_USER_COUNTRIES')) {
      countryCodes.push(country.code);
    }
    return countryCodes;
  }

  private fetchWarehouses() {
    if (SessionHelper.useLinkedWarehousesOnly()) {
      const warehouseCodes = [];
      for (const warehouse of SessionHelper.getLinkedWarehouses()) {
        warehouseCodes.push({ label: warehouse.code, value: warehouse.code });
      }
      this.warehouseField.data = warehouseCodes;
    } else {
      const params: any = { 'carriers.country.code[]': this.getCountryCodesFromUser() };

      this.warehouseResource.cGet(params).pipe(
        takeUntil(this.destroyed$)
      ).subscribe(
        (response: any) => {
          const warehouses = response['hydra:member'];
          const warehouseCodes = [];
          for (const warehouse of warehouses) {
            warehouseCodes.push({ label: warehouse.code, value: warehouse.code });
          }
          this.warehouseField.data = warehouseCodes;
        });
    }
  }
}
