import { Component, Inject, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {FormBuilder, FormGroup, FormArray, Validators} from '@angular/forms';
import { SessionHelper, CountryHelper, AttributeHelper, AttributeValueHelper } from '@helpers';
import { AbstractComponent } from '@components/generic/abstract.component';
import { ATTRIBUTE_UNIT, CODE_FR, LOCALE_FR, WHITE_COLOR_HEX } from '@constants';
import { SnackbarService } from '@components/snackbar/snackbar.service';
import { AttributeValueResource, AttributeFamilyResource } from '@resources';
import { AuthService } from '@services';
import { IAttributeFamily } from '@components/attribute-family/models/attribute-family.interface';
import { FormService } from '@services/form.service';

@Component({
  selector: 'app-attribute-family-form',
  template: require('./attribute-family-form.component.html'),
  providers: [AttributeHelper, AttributeValueHelper, FormService]
})
export class AttributeFamilyFormComponent extends AbstractComponent implements OnInit {

  public originalAttributeFamily: any;
  public currentLocale: any;
  public locales: any;
  public applicationLocales: string[] = SessionHelper.get('APPLICATION_LOCALES');
  public availableLocalesForCurrentCountry: any;
  public attributeFamilyModel: any;
  public attributeUnitValues: any;
  public attributeForm: FormGroup;

  @Input() public attributeFamily: any;
  @Input() public isEdit: string;

  @Output() public onSaveAttributeAction: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    @Inject('DialogService') private dialog: any,
    private snackbar: SnackbarService,
    private attributeHelper: AttributeHelper,
    private attributeValueHelper: AttributeValueHelper,
    private attributeValueResource: AttributeValueResource,
    private attributeFamilyResource: AttributeFamilyResource,
    public fb: FormBuilder,
  ) {
    super($translate, authService, null, state);

    this.attributeForm = this.fb.group({
      id: [''],
      attributeValues: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    this.currentLocale = SessionHelper.getLocale();
    this.availableLocalesForCurrentCountry = this.getAvailableLocales();
    this.attributeUnitValues = ATTRIBUTE_UNIT;

    this.attributeFamilyModel = this.initAttributeFamily(this.applicationLocales, this.attributeFamily); // remove attribute model

    this.originalAttributeFamily = this.attributeFamily;
  }

  /**
   * Inits the given attributes.
   */
  private initAttributeFamily(locales: string[] = [], attributeFamily: IAttributeFamily): IAttributeFamily {
    this.attributeForm.patchValue({ id: attributeFamily.id });

    const formArray = this.attributeForm.get('attributeValues') as FormArray;

    if (attributeFamily.attributeValues) {
      // # for each attributeValue && for each locale, add locale to attributeValues[n].translations object
      attributeFamily.attributeValues.forEach((attributeValue: any, key: number) => {
        formArray.push(this.fb.group({
          id: [attributeValue.id],
          translations: this.fb.group([]),
          value: [attributeValue.value[0] ? attributeValue.value[0] : WHITE_COLOR_HEX, Validators.required],
        }));

        const group = formArray.at(key).get('translations') as FormGroup;

        locales.forEach((locale: any) => {
          let translation;
          if (attributeValue.translations && attributeValue.translations[locale]) {
            translation = attributeValue.translations[locale];
            attributeValue.translations[locale].locale = locale;
          } else {
            translation = {};
          }
          group.addControl(locale, this.fb.group({
            id: [ translation.id || ''],
            value: [{ value: translation.value || '', disabled: this.isDefaultLocale(locale) }],
            unit: [{ value: translation.unit, disabled: this.isDefaultLocale(locale) }],
            toTranslate: [undefined !== translation.toTranslate ? translation.toTranslate : true],
            locale: [locale]
          }));
        });
      });
    }

    return attributeFamily;
  }

  /**
   * Gets available locales
   */
  public getAvailableLocales(): string[] {
    const countries = [SessionHelper.getCountry()];

    if (CODE_FR !== SessionHelper.getCountry().code && this.isEdit) {
      countries.unshift(CountryHelper.getCountryByCountryCode(CODE_FR));

      return CountryHelper.getLocales(countries);
    } else {
      return CountryHelper.getLocales(countries);
    }
  }

  /**
   * Adds attribute value.
   */
  public addNewAttributeValue(locales: string[] = []): void {
    const formArray = this.attributeForm.get('attributeValues') as FormArray;
    formArray.push(this.fb.group({
      translations: this.fb.group([]),
      value: [WHITE_COLOR_HEX]
    }));
    locales.forEach((locale: any) => {
      const group = formArray.at(formArray.controls.length - 1).get('translations') as FormGroup;
      group.addControl(locale, this.fb.group({
        id: '',
        value: [{ value: '', disabled: this.isDefaultLocale(locale) }],
        unit: [{ value: undefined, disabled: this.isDefaultLocale(locale) }],
        toTranslate: [true],
        locale: [locale]
      }));
    });
  }

  /**
   * Checks if form is valid and save it if so.
   */
  public isValid(locales: string[] = [], redirect: string): void {
      const newAttributeValues = this.attributeHelper.getNewAttributeValues(this.attributeForm.getRawValue());
      newAttributeValues.map((attributeValue: any) => {
        attributeValue.attributeFamily = this.attributeFamily.id;
        attributeValue.translations[this.currentLocale].toTranslate = '' === attributeValue.translations[this.currentLocale].value;
        this.attributeValueHelper.copyTranslationsFromLocale(attributeValue, this.currentLocale);
      });

      const editedAttributeValues = this.attributeHelper
        .getEditedAttributeValues(this.attributeForm.getRawValue(), this.originalAttributeFamily);

      editedAttributeValues.map((attributeValue) => {
        attributeValue.attributeFamily = this.attributeFamily.id;
        const originalAttributeValue = this.originalAttributeFamily.attributeValues
          .find((item: any) => item.id === attributeValue.id);
        this.attributeValueHelper.updateToTranslate(attributeValue, originalAttributeValue);
      });

      this.onSaveAttributeAction.emit({
        redirect: redirect,
        attributeFamily: this.attributeForm.getRawValue(),
        newAttributeValues: newAttributeValues,
        editedAttributeValues: editedAttributeValues,
      });
  }

  /**
   * Deletes attribute value.
   */
  public deleteAttributeValue(attributeValueRowIndex: number): void {
    this.dialog.confirm(this.translate('PAGE.ATTRIBUTE_FAMILY.CONFIRM.VALUE.DELETE'))
      .then(() => {
        if (
          this.attributeFamilyModel.attributeValues[attributeValueRowIndex] &&
          this.attributeFamilyModel.attributeValues[attributeValueRowIndex].id
        ) {
          this.attributeValueResource.remove(this.attributeFamilyModel.attributeValues[attributeValueRowIndex].id)
            .subscribe(() => {
              this.attributeFamilyModel.attributeFamilies.forEach((attributeFamily: any) => {
                if (
                  attributeFamily.attributeValues[0] &&
                  attributeFamily.attributeValues[0].id === this.attributeFamilyModel.attributeValues[attributeValueRowIndex].id
                ) {
                  this.attributeFamilyResource.remove(attributeFamily.id).subscribe();
                }
              });
              this.attributeFamilyModel.attributeValues.splice(attributeValueRowIndex, 1);
            });
        }
        const formArray = this.attributeForm.get('attributeValues') as FormArray;
        formArray.removeAt(attributeValueRowIndex);
      });
  }

  /**
   * Disabled field for the locales at country FR.
   * If country FR is not current country.
   */
  public isDefaultLocale(locale: string): boolean {
    return LOCALE_FR === locale && CODE_FR !== SessionHelper.getCountry().code;
  }

  /**
   * Displays a popup to exit form.
   */
  public cancelAction(): void {
    this.dialog.confirm(this.translate('DIALOG.TEXT.DONT_SAVE'))
      .then(() => this.state.go('attribute.list'));
  }
}
