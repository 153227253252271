import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

export interface IPendingTask {
  name: string;
  blocking: boolean;
}

@Injectable()
export class LoaderService {
  public pendingTasks: BehaviorSubject<any[]> = new BehaviorSubject([]);

  /**
   * Adds a new task to pending tasks
   *
   * @param {string} name
   * @param {boolean} blocking - if false the main loader don't be fired.
   */
  public add(name: string, blocking: boolean = false): void {
    if (undefined === this.pendingTasks.value.find((item: IPendingTask) => name === item.name)) {
      this.pendingTasks.next([
        ...this.pendingTasks.value,
        {name, blocking},
      ]);
    }
  }

  /**
   * Remove a task from pending tasks
   *
   * @param {string} name
   */
  public remove(name: string): void {
    this.pendingTasks.next([
      ...this.pendingTasks.value.filter((item: IPendingTask) => name !== item.name)
    ]);
  }

  public clear(): void {
    this.pendingTasks.next([]);
  }
}
