import { Inject, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { LoaderService } from '@components/loader';
import { PendingInterceptor } from './';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { ISubscription } from 'rxjs/Subscription';
import { AuthService, FormNotifierService, IJwt } from '@services';
import { AuthResource } from '@components/login/resources/auth.resource';
import { SIGN_IN } from '@constants/route.constants';
import { ErrorService } from '../services/error.service';
import { SnackbarService } from '@components/snackbar';
import { CsvDataService} from '@services';
import * as moment from 'moment';

/**
 * Catch 401 error to send user to login
 */
@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    @Inject('TranslationService') private $translate: ng.translate.ITranslateService,
    @Inject('StateService') protected state: ng.ui.IStateService,
    private errorService: ErrorService,
    private loaderService: LoaderService,
    private authService: AuthService,
    private authResource: AuthResource,
    private formNotifier: FormNotifierService,
    private csvDataService: CsvDataService,
  ) {}

  /**
   * @inheritDoc
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestKey: string = PendingInterceptor.getRequestId(req);

    return next.handle(req)
      .catch((errorResponse: HttpErrorResponse): Observable<HttpEvent<any>> => {
        this.loaderService.remove(requestKey);

        // if 401 and user checks `remember me`, we try to refresh its token and reload the current state, reject return on login.
        if (401 === errorResponse.status) {
          const refreshToken = AuthService.getRefreshToken();
          const token = AuthService.getToken();

          if (!refreshToken) {
            this.state.go(SIGN_IN);
          }

          if (!!refreshToken && !!token) {

            req = req.clone({
              setHeaders: {
                'X-API-Token': token,
              }
            });

            const subscriber: ISubscription = this.authResource.refreshToken({'refresh_token': refreshToken})
              .subscribe((response: IJwt) => {
                AuthService.setTokenInStorage({
                  token: response.token,
                  refresh_token: response.refresh_token
                }, true);

                return this.state.go(this.state.current.name, { id: this.state.params.id }, { 'reload': true, 'notify': true });
              }, () => this.state.go(SIGN_IN), () => subscriber.unsubscribe());
          }
        }

        if (errorResponse.error && errorResponse.error.violations) {
          this.formNotifier.notifyFormHasErrors({apiErrors: errorResponse.error.violations});
        }

        let violations = ErrorService.getViolations(errorResponse);
        const throwViolations = !req.headers.has('X-Throw-Violations') || '0' !== req.headers.get('X-Throw-Violations');

        if (violations.length) {
          if (Array.isArray(violations)) {
            if (violations.length < 3) {
              if (throwViolations) {
                violations.forEach((error) => SnackbarService.instance.alert(error));
              }
            } else {
              this.csvDataService.exportToCsv(`errors_list_${moment().format()}.csv`, violations.join('\n'));
              SnackbarService.instance.alert(this.$translate.instant('ALERTS.ERROR.TOO_MANY_ERRORS'));
            }
          } else {
            if (throwViolations) {
              SnackbarService.instance.alert(violations);
            }
            violations = [violations];
          }

          this.formNotifier.notifyFormHasErrors(
            { apiErrors: ErrorService.shrinkErrorFromChildren(errorResponse.error.errors) }
          );

          return Observable.throw({status: errorResponse.status, violations: violations});
        }

        if (400 === errorResponse.error.code) {
          this.formNotifier.notifyFormHasErrors({
            apiErrors: ErrorService.shrinkErrorFromChildren(errorResponse.error.errors)
          });
        }

        SnackbarService.instance.alert(ErrorService.getGenericErrorMessage(errorResponse));

        const {code, message, errors} = errorResponse.error;

        return Observable.throw({
          code,
          message,
          errors: ErrorService.shrinkErrorFromChildren(errors)
        });
      });
  }
}
