import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { ReversePackageResource } from '@components/reverse/resources';
import { AbstractResource } from '@resources/abstract.resource';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { ReverseFiltersFormService } from '@components/reverse/services';
import { SkuSearchService } from '@services/sku-search.service';
import { SessionHelper } from '@helpers';
import { IExportOptions } from '@interfaces';
import moment = require('moment');

@Component({
  selector: 'app-reverse-list',
  template: require('./reverse-list.component.html'),
  providers: [
    { provide: AbstractResource, useClass: ReversePackageResource },
    { provide: AbstractFiltersFieldsService, useClass: ReverseFiltersFormService },
    SkuSearchService
  ]
})
export class ReverseListComponent extends AbstractPageComponent implements OnInit {
  warehouses: any[];

  public exportOption: IExportOptions[] = [
    {
      entryPoint: '/v2/export',
      responseType: 'text',
      type: 'text/csv',
      filename: `reverses-${moment().format('YYYYMMDDHHmmss')}.csv`,
      translationKey: 'BUTTON.EXPORT.CSV',
      filters: false,
      roles: ['ROLE_WALISOFT_AGENT'],
      postOptions: {
        exportCode: 'reverse_product_package',
        formatCode: 'reverseProductPackage',
        dryRun: false,
        deferred: true,
        split: false,
        serializationGroups: ['walisoft_reverse_list'],
        async: true,
        limit: null
      },
    },
    {
      entryPoint: `/exports/${SessionHelper.getCountry() && SessionHelper.getCountry().code}/reverse/product/complete`,
      responseType: 'text',
      type: 'text/plain',
      filename: 'export-sage.csv',
      translationKey: 'EXPORT.SAGE',
      filters: false
    }
  ];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: ReversePackageResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private renderer: Renderer2,
  ) {
    super($translate, authService, resource, state);
    if (SessionHelper.getCurrentUserRoles().some(role => role === 'ROLE_WALISOFT_AGENT')) {
      this.resource.setExportOptions();
    }
  }
  ngOnInit(): void {
    if (SessionHelper.get('MENU_IS_OPEN')) {
      this.renderer.addClass(document.body, 'minimized-sidebar');
      this.renderer.removeClass(document.body, 'expanded-sidebar');
      SessionHelper.set('MENU_IS_OPEN', false);
    }
  }

  public updateExportOptions(filters?: any) {
    const params = {
      reverseId: filters['reverseProduct.reverse.id'],
      orderId: filters['reverseProduct.reverse.order.id'],
      receptionDateStart: filters['startDate'],
      receptionDateEnd: filters['endDate'],
      'carriers[]': filters['carrierGroup.code[]'],
      'skus[]': filters['packageSku[]'],
      'receiveStatuses[]': filters['receiveStatus[]'],
      'reconditionedStatuses[]': filters['reconditionedStatus[]'],
      status: filters['reverseProduct.reverse.status'],
      'countryCode[]': filters['reverseProduct.reverse.order.shippingAddress.countryCode[]'],
      'warehouses[]': filters['reverseProduct.reverse.warehouse.code[]'],
      'taskTypes[]': filters['taskType[]'],
      'expected': filters['reverseProduct.reverse.expected'] !== undefined ? !!filters['reverseProduct.reverse.expected'] : true,
      'deal': false,
      'archived': filters['reverseProduct.reverse.archived'] !== undefined ? !!filters['reverseProduct.reverse.archived'] : undefined,
      'withoutLabel': filters['withoutLabel'] !== undefined && (filters['withoutLabel'] !== null && filters['withoutLabel'].toLowerCase() === 'true')
    };

    this.exportOption[0].postOptions.limit = this.resource.encodeParams(params).toString();

    let country = SessionHelper.getCountry() && SessionHelper.getCountry().code;

    if (
      undefined !== filters['reverseProduct.reverse.order.shippingAddress.countryCode[]']
      && filters['reverseProduct.reverse.order.shippingAddress.countryCode[]'].length > 0
    ) {
      country = filters['reverseProduct.reverse.order.shippingAddress.countryCode[]'][0];
    }

    this.exportOption[1].entryPoint = `/exports/${country}/reverse/product/complete`;
  }
}
