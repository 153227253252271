import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services/auth.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CustomerResource } from '@resources';
import { CustomerDetailAddressesResource } from '@components/customer/customer-detail-addresses.resource';
import { SnackbarService } from '@components/snackbar/snackbar.service';

@Component({
    selector: 'app-order-email',
    template: require('./order-email.component.html'),
    providers: [],
})
export class OrderEmailComponent extends AbstractComponent implements OnInit {

    public form: FormGroup;
    public errorMessage: string = '';
    public autocompleteEmail: string = '';
    @Output() private onUpdate: EventEmitter<any> = new EventEmitter();

  constructor(
        @Inject('TranslationService') $translate: ng.translate.ITranslateService,
        authService: AuthService,
        @Inject('StateService') state: ng.ui.IStateService,
        private formBuilder: FormBuilder,
        private customerResource: CustomerResource,
        private customerDetailAddressesResource: CustomerDetailAddressesResource,
        private snackbarService: SnackbarService
    ) {
        super($translate, authService, null, state);
    }

    ngOnInit(): void {
        this.buildForm();
    }

    buildForm() {
        this.form = this.formBuilder.group({
            email: [null],
        });

        this.form.controls.email.valueChanges.subscribe(
            (emailFormValue: string) => {
                if (emailFormValue.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
                  this.fetchCustomerAddresses(emailFormValue);
                } else {
                  this.clearSnackBar();
                }
            }
        );
    }
   snackBarClick() {
    if (this.autocompleteEmail.length > 0) {
      this.form.controls.email.setValue(this.autocompleteEmail);
      this.clearSnackBar();
    }
   }

   clearSnackBar() {
    this.autocompleteEmail = '';
    this.errorMessage = '';
   }

    warn(message: string) {
      this.errorMessage = message;
    }

  async fetchCustomerAddresses(username: string) {
    const response: any = await this.customerResource.cGet({ 'username': username, 'fuzzy': true }).toPromise();

    this.clearSnackBar();
    if (!response['hydra:member'] || !response['hydra:member'][0] || !response['hydra:member'][0]['id']) {
      this.onUpdate.emit({
        billingAddress: null,
        shippingAddress: null
      });
      this.warn(`${this.$translate.instant('PAGE.ORDER.NEW.BLOCK_ADDRESS.WARN.NO_MATCHES')} : ${username}`);
      return;
    }

    const perfectMatch = response['hydra:member'].filter((customerData: any) => customerData['perfectMatch']);
    const almostMatch = response['hydra:member'].filter((customerData: any) => !customerData['perfectMatch']);

    if (perfectMatch.length === 0) {
      if (almostMatch.length > 0) {
        const email = almostMatch[0]['username'];
        this.warn(`${this.$translate.instant('PAGE.ORDER.NEW.BLOCK_ADDRESS.WARN.SUGGEST')} <u>${email}</u> ?`);
        this.autocompleteEmail = email;
      }
      return;
    }

    const customerId = perfectMatch[0]['id'];

    const customer: any = await this.customerResource.getCustomerById(customerId).toPromise();
    const isCollaborator: boolean = !!customer[0].collaborator;

    const addresses: any = await this.customerDetailAddressesResource.getCustomerAddresses(customerId).toPromise();

    if (addresses.billingAddresses[0] !== undefined) {
      delete addresses.billingAddresses[0].address.id;
    }
    if (addresses.shippingAddresses[0] !== undefined) {
      delete addresses.shippingAddresses[0].address.id;
    }
    if (addresses.addresses[0] !== undefined) {
      delete addresses.addresses[0].id;
    }

    this.onUpdate.emit({
      billingAddress: addresses.billingAddresses.length > 0 ?
        addresses.billingAddresses[0].address : (
          addresses.addresses.length > 0 ? addresses.addresses[0] : null
        ),
      shippingAddress: addresses.shippingAddresses.length > 0 ?
        addresses.shippingAddresses[0].address : (
          addresses.addresses.length > 0 ? addresses.addresses[0] : null
        ),
      collaborator: isCollaborator
    });
  }
}
