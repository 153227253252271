import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SnackbarService } from '@components/snackbar';
import { ShippingPriceResource } from '@components/shipping-price/shipping-price.resource';
import { INPUT_NUMBER_PATTERN_DEC } from '@constants';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-service-form',
  template: require('./shipping-price-form.component.html'),
  providers: [
    { provide: AbstractResource, useClass: ShippingPriceResource },
  ],
})
export class ShippingPriceFormComponent extends AbstractPageComponent implements OnInit {

  public country: any;
  public relayPointShippingFee: any;
  public form: FormGroup;

  private static parseFloat(value: any): number {
    return +value.toString().replace(',', '.');
  }

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    private snackbar: SnackbarService,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    this.buildForm();
    this.fetch();
  }

  private fetch(): void {
    this.resource.get(this.state.params.id)
      .takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        this.country = response;
        this.fillForm();
      })
    ;
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      active: [null],
      countryExemptionLimit: [null, [Validators.pattern(INPUT_NUMBER_PATTERN_DEC)]],
      countryShippingPrice: [null, [Validators.pattern(INPUT_NUMBER_PATTERN_DEC)]],
      relayPointExemptionLimit: [null, [Validators.pattern(INPUT_NUMBER_PATTERN_DEC)]],
      relayPointShippingPrice: [null, [Validators.pattern(INPUT_NUMBER_PATTERN_DEC)]],
      postalCodeWithCarrierSurcharge: [null],
    });
  }

  private fillForm(): void {
    const shippingFee = this.country.shippingFee;
    const relayPointShippingFee = this.country.relayPointFamily ? this.country.relayPointFamily.shippingFee : {};

    this.form.get('active').setValue(shippingFee ? shippingFee.active : false);
    this.form.get('countryExemptionLimit').setValue(shippingFee ? shippingFee.exemptionLimit : null);
    this.form.get('countryShippingPrice').setValue(shippingFee ? shippingFee.shippingPrice : null);
    this.form.get('relayPointExemptionLimit').setValue(relayPointShippingFee ? relayPointShippingFee.exemptionLimit : null);
    this.form.get('relayPointShippingPrice').setValue(relayPointShippingFee ? relayPointShippingFee.shippingPrice : null);
    this.form.get('postalCodeWithCarrierSurcharge').setValue(this.country.postalCodeWithCarrierSurcharge ? this.country.postalCodeWithCarrierSurcharge : []);
  }

  public submit(event?: any): void {
    const observables: any[] = [];
    const form: any = this.form.value;

    let body: any = {
      shippingFee: {
        active: !!form.active,
        exemptionLimit: form.countryExemptionLimit ? ShippingPriceFormComponent.parseFloat(form.countryExemptionLimit) : 0.0,
        shippingPrice: form.countryShippingPrice ? ShippingPriceFormComponent.parseFloat(form.countryShippingPrice) : 0.0,
      },
      postalCodeWithCarrierSurcharge: form.postalCodeWithCarrierSurcharge,
    };

    if (this.country.shippingFee) {
      body.shippingFee.id = this.country.shippingFee['@id'];
    }

    observables.push(this.resource.update(this.state.params.id, body, { cleanParams: false }));

    if (form.relayPointExemptionLimit && form.relayPointShippingPrice) {
      body = {
        shippingFee: {
          active: !!form.active,
          exemptionLimit: form.relayPointExemptionLimit ? ShippingPriceFormComponent.parseFloat(form.relayPointExemptionLimit) : null,
          shippingPrice: form.relayPointShippingPrice ? ShippingPriceFormComponent.parseFloat(form.relayPointShippingPrice) : null,
        }
      };

      if (this.country.relayPointFamily) {
        if (this.country.relayPointFamily.shippingFee) {
          body.shippingFee.id = this.country.relayPointFamily.shippingFee['@id'];
        }

        observables.push(this.resource.update(null, body, {
          cleanParams: false,
          entryPoint: '/v2/carrier_families/' + this.country.relayPointFamily.id
        }));
      }
    }

    forkJoin(observables)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
        setTimeout((): void => {
          event && event.redirect
            ? this.state.go('shipping-prices.list')
            : this.state.go(this.state.current, this.state.params, { reload: true })
          ;
        }, 100);
      })
    ;
  }
}
