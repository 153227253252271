import { Component, Inject, Input } from '@angular/core';
import { SessionHelper } from '@helpers';
import { AbstractComponent } from '@components/generic/abstract.component';
import { isEmpty } from 'lodash';
import _ = require('lodash');
import { AuthService } from '@services';

@Component({
    selector: '[app-attribute-table-row]',
    template: require('./attribute-table-row.component.html')
})
export class AttributeTableRowComponent extends AbstractComponent {

  public objectKeys = Object.keys;

  @Input() public attribute: any;
  @Input() public attributesSets: any;
  @Input() public locales: any;
  @Input() public attributesSetsAttributes: any;
  @Input() public attributesSetsAttributeFilterables: any;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, null, state);

    this.attribute = this.warningAssignment(this.locales, this.attribute);
  }

  public warningAssignment(locales: any[] = [], attribute: any): any {
    locales.forEach((locale) => {
      if (!attribute.translations[locale]) {
        attribute.translations[locale] = { warning: locale };
      }

      if (attribute.translations[locale] && isEmpty(attribute.translations[locale].name)) {
        attribute.translations[locale].warning = locale;
      }
    });

    return attribute;
  }

  public isUsed(attribute: any, attributeSet: any): boolean {
    return !!_.find(this.attributesSetsAttributes, {
      id: `${attributeSet.id}`,
      attributeId: `${attribute.id}`
    });
  }

  public isFilterable(attribute: any, attributeSet: any): boolean {
    return !!_.find(this.attributesSetsAttributeFilterables, {
      id: `${attributeSet.id}`,
      attributeId: `${attribute.id}`,
      countryId: `${SessionHelper.getCountry().id}`,
      filterable: '1',
    });
  }
}
