import { ITab, ITabTranslation } from '@components/tab/models/tab.interface';

const defaultBackgroundColor = '#ffffffff';

export class TabModel implements ITab {
  [key: string]: any;
  public '@id': string;
  public id: string;
  public parent: any;
  public position: number;
  public startDate: any;
  public startTime: any;
  public endDate: any;
  public endTime: any;
  public active: boolean;
  public commercialOperation: string;
  public contentBlock: string;
  public category: string;
  public room: string;
  public fontColor: string|null;
  public backgroundColor: string|null;
  public translations: ITabTranslation[];
  public children: ITab[];
  public marketplace?: string;
  public obfuscated?: boolean;
  public hiddenMenu?: boolean;
  public customerTypes: any[];
  public type?: string;

  constructor(tab: ITab = {
    '@id': '',
    id: '',
    parent: null,
    position: 0,
    startDate: '',
    startTime: null,
    endDate: '',
    endTime: null,
    active: false,
    commercialOperation: '',
    contentBlock: '',
    category: '',
    fontColor: '',
    backgroundColor: '',
    translations: null,
    children: [],
    marketplace: '',
    obfuscated: false,
    hiddenMenu: false,
    customerTypes: [],
    type: ''
  }) {
    this['@id'] = tab['@id'];
    this.id = tab.id;
    this.parent = tab.parent;
    this.position = tab.position;
    this.startDate = tab.startDate;
    this.startTime = tab.startTime ? new Date(tab.startDate) : null;
    this.endDate = tab.endDate;
    this.endTime = tab.endTime ? new Date(tab.endDate) : null;
    this.active = tab.active;
    this.commercialOperation = tab.commercialOperation;
    this.contentBlock = tab.contentBlock;
    this.category = tab.category;
    this.room = tab.room;
    this.fontColor = tab.fontColor;
    this.backgroundColor = tab.backgroundColor || defaultBackgroundColor;
    this.translations = tab.translations;
    this.children = tab.children ? tab.children.map(child => new TabModel(child)) : [];
    this.marketplace = tab.marketplace;
    this.obfuscated = tab.obfuscated;
    this.hiddenMenu = tab.hiddenMenu;
    this.customerTypes = tab.customerTypes;
    this.type = tab.type;
  }
}
