import { Resource } from '@decorators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractResource } from '@resources';
import { MarketplaceHelper } from '@helpers';
import { Observable } from 'rxjs/Observable';

@Injectable()
@Resource({
  entryPoint: '/v2/wali_tickets',
  isHydra: true,
  routeName: 'walitickets',
})
export class GrcTicketResource extends AbstractResource {
  constructor(http: HttpClient) {
    super(http);
  }

  public cGet(params: any = {}, options: any = {}): Observable<object> {
    return super.cGet(params, options);
  }
}
