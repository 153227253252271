import { AttributeSetResource } from '@components/attribute-set/attribute-set.resource';

export default attributeSetRoutes;

/** @ngInject */
function attributeSetRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'walipush',
    sidebarSubNav: 'attributesSetList',
    title: '',
    resource: AttributeSetResource,
    reloadChangeCountry: 'attribute-sets.list',
  };

  $stateProvider
    .state('attribute-sets', {
      url: '/attribute-sets',
      parent: 'walisoft',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.ATTRIBUTE_SETS',
        stateName: 'attribute-sets.list'
      },
    })
    .state('attribute-sets.list', {
      url: '',
      views: {
        'page@layout': { template: '<app-attribute-set-list></app-attribute-set-list>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.ATTRIBUTE_SETS.LIST.TITLE',
      })
    })
    .state('attribute-sets.new', {
      url: '/new',
      breadcrumb: { label: 'BREADCRUMB.NEW' },
      views: {
        'page@layout': { template: '<app-attribute-set></app-attribute-set>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.ATTRIBUTE_SETS.NEW.TITLE',
      })
    })
    .state('attribute-sets.edit', {
      url: '/:id',
      breadcrumb: { label: 'BREADCRUMB.EDIT' },
      views: {
        'page@layout': { template: '<app-attribute-set></app-attribute-set>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.ATTRIBUTE_SETS.EDIT.TITLE',
      })
    })
  ;
}
