import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';

export class FormErrorHelper {
  public static readonly ERROR_KEY = 'API';

  /**
   * Fills the form controls with API errors
   *
   * @param {AbstractControl} group
   * @param {any} errors
   */
  static fillFormControlWithErrors(group: AbstractControl, errors: any = {}): void {
    Object.entries(errors).forEach(([key, value]: any[]) => {
      let field: AbstractControl;

      if (undefined !== (field = group.get(key))) {
        if ('translations' === key) {
          Object.entries(value).forEach(([locale, content]: any[]) => {
            FormErrorHelper.fillFormControlWithErrors(
              (field as FormArray).controls.find((item: FormGroup) => locale === item.get('locale').value),
              content
            );
          });
        } else if (field instanceof FormControl) {
          field.setErrors({
            [FormErrorHelper.ERROR_KEY]: value.errors,
          });
        } else if (Object.keys(value).length) {
          FormErrorHelper.fillFormControlWithErrors(field, value);
        }
      }
    });
  }

  /**
   * Removes recursively API Errors from all controls of a form
   *
   * @param {AbstractControl} group
   */
  static removeFormControlsAPIErrors(group: AbstractControl): void {
    // removes errors
    if (group.hasError(FormErrorHelper.ERROR_KEY)) {
      group.setErrors({[FormErrorHelper.ERROR_KEY]: null});
    }

    // continue with children
    if (group instanceof FormGroup || group instanceof FormArray) {
      Object.values(group.controls).forEach(FormErrorHelper.removeFormControlsAPIErrors);
    }
  }
}
