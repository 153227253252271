import { Injectable } from '@angular/core';
import { Resource } from '@decorators/Resource';
import { AbstractResource } from '../../resources/abstract.resource';
import { SuperProductCategoriesModel } from '@components/super-product-categories/models/super-product-categories.model';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';

@Injectable()
@Resource({
  entryPoint: '/v2/super_product_categories',
  model: SuperProductCategoriesModel,
  isHydra: true,
})

export class SuperProductCategoriesResource extends AbstractResource {

  public getCategories(id?: string, params?: any, options?: any): Observable<object> {
    return this.getMany(params, {
      ...options,
      entryPoint: this.entryPoint + '?superProduct=' + id,
    });
  }
  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  public getElastic(params?: any, options?: any): Observable<object> {
    return this.getMany(params, {
      ...options,
      entryPoint: this.entryPoint + '/elastic',
    });
  }

}
