import { IResource } from '@interfaces';

export const Resource = (parameters: IResource) => (target: any) => {
  Object.defineProperty(target.prototype, 'createAvailable', {value: true});
  Object.defineProperty(target.prototype, 'listAvailable', {value: true});
  Object.defineProperty(target.prototype, 'updateAvailable', {value: true});
  Object.defineProperty(target.prototype, 'deleteAvailable', {value: true});
  Object.defineProperty(target.prototype, 'archiveAvailable', {value: false});
  Object.defineProperty(target.prototype, 'customDeleteAvailable', {value: false});
  Object.defineProperty(target.prototype, 'downloadAvailable', {value: false});
  Object.defineProperty(target.prototype, 'buttonActionMultipleAvailable', {value: false});
  Object.defineProperty(target.prototype, 'exportOptions', {value: undefined});
  Object.defineProperty(target.prototype, 'importOptions', {value: undefined});
  Object.defineProperty(target.prototype, 'isHydra', {value: false});
  Object.defineProperty(target.prototype, 'keepFiltersInStorage', {value: false});
  Object.defineProperty(target.prototype, 'cGetDefaultFilters', {value: () => ({})});
  Object.defineProperty(target.prototype, 'getDefaultListColumns', {value: () => {}});
  Object.defineProperty(target.prototype, 'throwViolations', {value: true});

  Object.entries(parameters).forEach(([key, value]) => {
    Object.defineProperty(target.prototype, key, {value});
  });
};
