import { AbstractResource } from '../../../../resources/abstract.resource';
import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

@Injectable()
@Resource({
  entryPoint: '/v2/task_type_categories',
  isHydra: true,
})
export class TaskCategoryResource extends AbstractResource {

  constructor(http: HttpClient) {
    super(http);
  }

  getTaskTypes(taskTypeCategoryId: string): Observable<object> {
    return this.cGet({
      businessObject: 'reverse-product',
      active: true,
    }, {
      entryPoint: `/v2/task_type_categories/${taskTypeCategoryId}/task_types`,
      returnHydraMembers: true,
    });
  }
}
