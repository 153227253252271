import { Component, Inject, Input, OnInit } from '@angular/core';
import { IProductForm } from '@components/product/interfaces/product-form.interface';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { CityResource } from '@resources/city.resource';
import { SessionHelper } from '@helpers/session.helper';
import { CarrierGroupResource } from '@resources/carrier-group.resource';
import { AbstractComponent } from '@components/generic/abstract.component';
import { ProductNotifierService } from '@services/product-notifier.service';
import { PRODUCT_STREAMS } from '../../../../enums/product-notifier-streams.enum';

@Component({
  selector: 'app-preview-costs',
  template: require('./preview-costs.component.html'),
})
export class PreviewCostsComponent extends AbstractComponent implements OnInit {

  @Input() public model: IProductForm;

  public previewCosts: any = [];
  public objectKeys: (o: {}) => string[] = Object.keys;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    resource: AbstractResource,
    private cityResource: CityResource,
    private carrierGroupResource: CarrierGroupResource,
    private productNotifierService: ProductNotifierService,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    this.refreshPreviewCosts();
    this.productNotifierService.observable.subscribe((stream) => {
      if (PRODUCT_STREAMS.previewCostsUpdated === stream) {
        this.refreshPreviewCosts();
      }
    });
  }

  private refreshPreviewCosts(): void {
    if ('undefined' !== typeof this.model.packages && 0 < this.model.packages.length) {
      this.cityResource.cGet({country: SessionHelper.getCountry().id, default: 1})
        .takeUntil(this.destroyed$)
        .subscribe(async (response: any) => {
          const packages = this.model.packages.map((pkg: any) => `/api/v2/packages/${pkg.id}`);
          const postCodes = response.map((city: any) => city.postcode);

          if (0 < postCodes.length && 0 < packages.length) {
            const previewCostsPromise: any = await this.carrierGroupResource.getCostEstimation({
              packages: packages,
              postcodes: postCodes,
              country: `/api/v2/countries/${SessionHelper.getCountry().id}`});

            this.previewCosts = Object.values(previewCostsPromise.results);
          }
        }
      );
    }
  }
}
