import { NgModule } from '@angular/core';
import { CategoriesMarketplaceListComponent } from './list/categories-marketplace-list.component';
import { CategoriesMarketplaceResource } from '@components/categories-marketplace/categories-marketplace.resource';
import { GenericModule } from '@components/generic';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PaginationModule } from 'ngx-bootstrap';
import { ComboBoxModule } from '@progress/kendo-angular-dropdowns';
import { SwitchModule } from '@progress/kendo-angular-inputs';
import { CategoriesMarketplaceResultListComponent } from './list/categories-marketplace-result-list.component';
import { CategoriesMarketplaceFiltersFormService } from './service/categories-marketplace-filters-form.service';

@NgModule({
  declarations: [
    CategoriesMarketplaceListComponent,
    CategoriesMarketplaceResultListComponent,
  ],
  entryComponents: [
    CategoriesMarketplaceListComponent,
  ],
  providers: [
    CategoriesMarketplaceResource,
    CategoriesMarketplaceFiltersFormService,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    GenericModule,
    PaginationModule,
    ComboBoxModule,
    SwitchModule,
  ]
})

export class CategoriesMarketplaceModule {}
