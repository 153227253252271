import { Component, Inject, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { FormErrorHelper } from '@helpers';
import { ManufacturerResource } from '@resources/manufacturer.resource';
import { ILabelledContent, FormSubmitArgument } from '@interfaces';
import { SnackbarService } from '@components/snackbar/snackbar.service';
import { AuthService } from '@services';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';

@Component({
  selector: 'app-manufacturer-edit',
  template: require('./manufacturer-edit.component.html')
})
export class ManufacturerEditComponent extends AbstractPageComponent implements OnInit {

  public manufacturer: ILabelledContent;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: ManufacturerResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private snackbar: SnackbarService,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit() {
    this.fetch();
  }

  /**
   * Fetches API to get manufacturer info.
   */
  private fetch(): void {
    const subscriber: Subscription = this.resource.get(this.state.params.id)
      .subscribe((manufacturer: ILabelledContent) => {
        this.manufacturer = manufacturer;
      }, undefined, () => subscriber.unsubscribe());
  }

  /**
   * Handles the save form event.
   */
  public save({ body, form, redirect }: FormSubmitArgument): void {
    this.resource.partialUpdate(this.state.params.id, body)
      .takeUntil(this.destroyed$)
      .subscribe(() => {
        this.snackbar.validate(this.translate('PAGE.MANUFACTURER.EDIT.SAVED'));
        if (redirect) {
          this.actions.list.go();
        }
      }, (error: any) => {
        if (400 === error.code) {
          FormErrorHelper.fillFormControlWithErrors(form, error.errors);
        }
      })
    ;
  }
}
