import { AbstractResource } from './abstract.resource';
import { Injectable } from '@angular/core';
import { Resource } from '../decorators/Resource';
import { HttpClient } from '@angular/common/http';
import { ArrivalListModel } from '@components/sav/model/arrival-list.model';

@Injectable()
@Resource({
  entryPoint: '/v2/arrivals',
  isHydra: false,
  model: ArrivalListModel,
  listColumns: [
    { field: 'sku', translationKey: 'SKU', sortable: true },
    { field: 'label', translationKey: 'LABEL', sortable: true },
    { field: 'warehouse', translationKey: 'WAREHOUSE', sortable: true },
    { field: 'season', translationKey: 'SEASON', sortable: true },
    { field: 'date', type: 'date', translationKey: 'DATE', sortable: true },
    { field: 'quantity', translationKey: 'QUANTITY', sortable: true },
    { field: 'shippedQuantity', translationKey: 'SHIPPED_QUANTITY', sortable: true },
    { field: 'receivedQuantity', translationKey: 'RECEIVED_QUANTITY', sortable: true },
    { field: 'sageIdentifier', translationKey: 'SAGE_DL_NO', sortable: true },
    { field: 'comment', translationKey: 'COMMENT', sortable: true },
  ],
  translationKey: 'SAV.ARRIVAL',
  deleteAvailable: false,
  updateAvailable: false,
  createAvailable: false,
  updateRouteName: 'sav.arrival_edit'
})

export class ArrivalResource extends AbstractResource {
  constructor(http: HttpClient) {
    super(http);
  }
}
