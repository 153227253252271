import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { AbstractResource } from '../../../../resources/abstract.resource';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  entryPoint: '/v2/carrier_scale_scheduleds',
  isHydra: true,
})
export class CarrierScaleScheduleResource extends AbstractResource {
  constructor(http: HttpClient) {
    super(http);
  }
}
