import { Injectable } from '@angular/core';
import { AbstractResource } from '../../resources/abstract.resource';
import { CustomerModel } from './models/customer.model';
import { Observable } from 'rxjs/Observable';
import { Resource } from '@decorators/Resource';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  entryPoint: '/v2/customers',
  routeName: 'customers',
  editionState: 'customers.detail.orders',
  model: CustomerModel,
  translationKey: 'CUSTOMER',
  listColumns: [
    { field: 'id', translationKey: 'ID', type: 'id' },
    { field: 'fullName', translationKey: 'FULLNAME', type: 'string' },
    { field: 'username', translationKey: 'EMAIL', type: 'string' },
    { field: 'phones', translationKey: 'PHONES', type: 'string' },
    { field: 'postcodes', translationKey: 'POSTCODES', type: 'string' },
    { field: 'cities', translationKey: 'CITIES', type: 'string' },
    {
      field: 'numberOfOrder',
      translationKey: 'NUMBER_OF_ORDER',
      type: 'number',
      sortable: true,
      linkable: { state: 'order_manager.list', fromParams: 'id', toParams: 'customerIds[]' }
    },
    { field: 'totalPrice', translationKey: 'TOTAL_PRICE', type: 'number', sortable: true },
  ],
  createAvailable: false,
  deleteAvailable: false,
  updateAvailable: true,
  isHydra: true,
})
export class CustomerResource extends AbstractResource {

  constructor(http: HttpClient) {
    super(http);
  }

  public cGet(params: any = {}, options: any = {}): Observable<object> {
    if (params.sort_numberOfOrder) {
      params['order[numberOfOrder]'] = params.sort_numberOfOrder;
    }

    if (params.sort_totalPrice) {
      params['order[totalPrice]'] = params.sort_totalPrice;
    }

    return super.cGet(params, options);
  }

  public exists(usernames: string[] = []): Observable<object> {
    const params = {
      'username[]': usernames,
      pagination: false
    };
    const options = {
      dontUseModel: true,
      blocking: false,
      isHydra: false,
      returnHydraMembers: false,
      entryPoint: `${this.entryPoint}/exists`
    };

    return super.cGet(params, options);
  }
  public getCustomerById(id: number): Observable<object> {
    const params = {'id': id};
    const options = {
      dontUseModel: true,
      blocking: false,
      isHydra: true,
      returnHydraMembers: true,
      entryPoint: `${this.entryPoint}`
    };
    return super.cGet(params, options);
  }
  public getCollaborators(): Observable<object> {
    const options = {
      dontUseModel: true,
      blocking: false,
      isHydra: true,
      returnHydraMembers: true,
      entryPoint: `${this.entryPoint}/collaborators`
    };
    return super.cGet({}, options);
  }
}
