import {Component, Inject, OnInit} from '@angular/core';
import {AuthService} from '@services/auth.service';
import {SuperProductResource} from '@components/super-product/super-product.resource';
import {takeUntil} from 'rxjs/operators';
import {SessionHelper} from '@helpers';
import {Subject} from 'rxjs/Subject';
import {IFormValue} from '@components/super-product/interfaces/form-general.interfaces';
import {IButtonLinks, IButtonPost} from '@components/generic/buttons/button-links.interface';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
  IProductDeclination,
  ISuperProductForm,
  ISuperProductFormTranslations,
  ITranslations
} from '@components/super-product/interfaces/super-product-form.interface';
import {HydraHelper} from '@helpers/HydraHelper';
import { CREATION_PAGE } from '@interfaces/IPageComponent';
import {ICountry} from '@interfaces';
import {Observable} from 'rxjs/Observable';
import {FormNotifierService} from '@services';
import {SnackbarService} from '@components/snackbar';
import {forkJoin} from 'rxjs/observable/forkJoin';
import {AbstractResource} from '@resources';
import {AbstractPageComponent} from '@components/generic/abstract-page.component';

@Component({
  selector: 'app-super-product-content-website',
  template: require('./super-product-content-website.component.html'),
  providers: [
    { provide: AbstractResource, useClass: SuperProductResource },
  ]
})
export class SuperProductContentWebsiteComponent extends AbstractPageComponent implements OnInit {
  public superProduct: any;
  public form: FormGroup;
  public buttonLinks: IButtonLinks[];
  public buttonPosts: IButtonPost[] = [{
    params: 'event.duplicateNewContent',
    label: 'PAGE.SUPER_PRODUCT.MODAL_EDIT.DUPLICATE.TITLE',
  }];
  public currentCountry: ICountry;
  private openModalSubject: Subject<void> = new Subject<void>();
  public AllTranslations: any = [];

  private productDeclinationsMemory: IProductDeclination[];

  get translationsFA(): FormArray {
    return this.form.get('translations') as FormArray;
  }

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    protected resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    private snackbar: SnackbarService,
    private formNotifier: FormNotifierService,
  ) {
    super($translate, authService, resource, state);

    this.currentCountry = SessionHelper.getCountry();
  }

  ngOnInit(): void {
    this.buildForm();
    this.fillForm();
    this.resource.get(this.state.params.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((superProduct: any) => {
        this.superProduct = superProduct;
      })
    ;
  }
  private buildForm(): void {
    this.form = this.formBuilder.group({
      translations: this.formBuilder.array([]),
      repairabilityIndex: [],
    });

    this.buildTranslationsForm();
  }

  private buildTranslationsForm(translations?: { [locale: string]: ITranslations }): void {
    const formGroups = this.currentCountry.locales.map((locale) => {
      const translation = {
        marketplaceTitle: [translations && translations[locale] ? translations[locale].marketplaceTitle : ''],
        agTitle: [translations && translations[locale] ? translations[locale].agTitle : '', Validators.maxLength(80)],
        description: [translations && translations[locale] ? translations[locale].description : ''],
        locale,
        positivePoint1: [translations && translations[locale] ? translations[locale].positivePoint1 : ''],
        positivePoint2: [translations && translations[locale] ? translations[locale].positivePoint2 : ''],
        positivePoint3: [translations && translations[locale] ? translations[locale].positivePoint3 : ''],
        positivePoint4: [translations && translations[locale] ? translations[locale].positivePoint4 : ''],
        bulletPoint1: [translations && translations[locale] ? translations[locale].bulletPoint1 : ''],
        bulletPoint2: [translations && translations[locale] ? translations[locale].bulletPoint2 : ''],
        bulletPoint3: [translations && translations[locale] ? translations[locale].bulletPoint3 : ''],
        bulletPoint4: [translations && translations[locale] ? translations[locale].bulletPoint4 : ''],
        bulletPoint5: [translations && translations[locale] ? translations[locale].bulletPoint5 : ''],
        searchTerm1: [translations && translations[locale] ? translations[locale].searchTerm1 : ''],
        searchTerm2: [translations && translations[locale] ? translations[locale].searchTerm2 : ''],
        searchTerm3: [translations && translations[locale] ? translations[locale].searchTerm3 : ''],
        searchTerm4: [translations && translations[locale] ? translations[locale].searchTerm4 : ''],
        searchTerm5: [translations && translations[locale] ? translations[locale].searchTerm5 : ''],
        id: [translations && translations[locale] ? translations[locale].id : ''],
      };
      if (translations && translations[locale] && translations[locale].id) {
        translation.id = [translations[locale].id];
      }
      return this.formBuilder.group(translation);
    });

    const formArray = this.formBuilder.array(formGroups);

    this.form.setControl('translations', formArray);
  }

  public updateURI(dictionnary: any) {
    for (const key in dictionnary) {
      if (dictionnary[key].id) {
        dictionnary[key].id = HydraHelper.buildIri(dictionnary[key].id, 'super_product_translations');
      }
    }
  }

  private fillForm(): void {
    (<SuperProductResource>this.resource).getByLocale(this.state.params.id, this.currentLocale)
      .takeUntil(this.destroyed$)
      .subscribe((response: ISuperProductFormTranslations) => {
        this.AllTranslations = Object.assign({}, ...Object.keys(response.translations)
          .filter(key => !SessionHelper.getCountry().locales.includes(key)).map(key => ({ [key]: response.translations[key] })));

        this.buildTranslationsForm(response.translations);
        this.updateURI(this.AllTranslations);
        delete response.translations;

        this.form.patchValue(response);
      })
    ;
    (<SuperProductResource>this.resource).getByLocale(this.state.params.id, this.currentLocale)
      .takeUntil(this.destroyed$)
      .subscribe((response: ISuperProductForm) => {
        this.productDeclinationsMemory = response.productDeclinations;
        this.formatButtonLinks(this.productDeclinationsMemory);
      })
    ;
  }

  public submit(event?: any): void {
    const observable: Observable<object> | Observable<object[]> = this.getObservable();

    observable
      .takeUntil(this.destroyed$)
      .subscribe((response: ISuperProductFormTranslations | Array<object | ISuperProductFormTranslations>) => {
        this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));

        if (event && event.redirect) {
          this.state.go(`${this.resource.routeName}.list`);

          return;
        }

        this.formNotifier.notifyFormSubmitted();
        this.state.go(
          `${this.resource.routeName}.edit.blocks`,
          { id: !Array.isArray(response) ? (<ISuperProductFormTranslations>response).id : this.state.params.id, reload: true }
        )
        ;
      })
    ;
  }

  private getObservable(): Observable<object> | Observable<object[]> {
    const body: ISuperProductFormTranslations = this.prepareBody();

    if (CREATION_PAGE === this.pageType) {
      return this.resource.create(body);
    }

    const observables: Observable<object>[] = [];

    observables.push(this.resource.partialUpdate(this.state.params.id, body, {
      entryPoint: `/v2/super_products/${this.state.params.id}`
    }));

    return forkJoin(observables);
  }

  private prepareBody(): ISuperProductFormTranslations {
    const formValue: IFormValue = this.form.value;
    let translations: { [locale: string]: ITranslations } = {};

    this.translationsFA.controls.forEach((formGroup: FormGroup) => {
      const translationsFormValue: ITranslations = formGroup.value;

      translations[translationsFormValue.locale] = {
        marketplaceTitle: translationsFormValue.marketplaceTitle,
        agTitle: translationsFormValue.agTitle,
        description: translationsFormValue.description,
        locale: translationsFormValue.locale,
        positivePoint1: translationsFormValue.positivePoint1,
        positivePoint2: translationsFormValue.positivePoint2,
        positivePoint3: translationsFormValue.positivePoint3,
        positivePoint4: translationsFormValue.positivePoint4,
        bulletPoint1: translationsFormValue.bulletPoint1,
        bulletPoint2: translationsFormValue.bulletPoint2,
        bulletPoint3: translationsFormValue.bulletPoint3,
        bulletPoint4: translationsFormValue.bulletPoint4,
        bulletPoint5: translationsFormValue.bulletPoint5,
        searchTerm1: translationsFormValue.searchTerm1,
        searchTerm2: translationsFormValue.searchTerm2,
        searchTerm3: translationsFormValue.searchTerm3,
        searchTerm4: translationsFormValue.searchTerm4,
        searchTerm5: translationsFormValue.searchTerm5
      };
      if (translationsFormValue.id) {
        translations[translationsFormValue.locale].id = HydraHelper.buildIri(translationsFormValue.id, 'super_product_translations');
      }
    });

    const otherIris: any = {};

    Object.keys(this.AllTranslations).forEach((key: any) => {
      otherIris[key] = { id: this.AllTranslations[key].id };
    });

    translations = Object.assign({}, translations, otherIris);

    const body: ISuperProductFormTranslations = {
      translations,
      repairabilityIndex: null,
    };

    if (formValue.repairabilityIndex) {
      body['repairabilityIndex'] = parseFloat(String(formValue.repairabilityIndex));
    }

    return body;
  }

  public onPost(params: any) {
    if (params === 'event.duplicateNewContent') {
      this.openModalSubject.next();
    }
  }

  public hasSeveralTranslations(): boolean {
    return this.currentCountry.locales.length > 1;
  }

  public formatButtonLinks(products: IProductDeclination[] = []) {
    const children = products.map((product: IProductDeclination) => {
      return {
        url: `#!/products-new/products/${product.id}`,
        label: product.sku,
      };
    });

    this.buttonLinks = [{
      label: 'PAGE.SUPER_PRODUCT.EDIT.BUTTON_LINKS.LINKED_SKU',
      url: '#',
      children,
    }];
  }
}
