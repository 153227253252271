import { Inject, Injectable } from '@angular/core';
import { AbstractFormFieldBase } from '@components/generic/Form/dynamic/fields/abstract-form-field-base.class';
import {
  TextField,
  DateField,
  SkuSearchField,
  MultiSearchField,
  ComboSearchField
} from '@components/generic/Form/dynamic/fields';
import { DATE_FULL_FORMAT, getReceiveStatuses, getReconditionedStatuses } from '@constants';
import { CarrierGroupResource } from '@resources';
import { ICarrierGroup } from '@interfaces';
import { Subscription } from 'rxjs/Subscription';
import { REVERSE_STATUSES } from '@constants/reverseStatuses';
import { ITaskType } from '@components/generic/task-manager/interfaces';
import { TaskTypeResource } from '@components/generic/task-manager/resources';
import { FormNotifierService } from '@services';
import * as moment from 'moment';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { takeUntil } from 'rxjs/operators';
import { WarehousesResource } from '@components/warehouses/warehouses.resource';
import { SessionHelper } from '@helpers';
/**
 * Constructs all filters field for the reverse package list.
 */
@Injectable()
export class ReverseFiltersFormService extends AbstractFiltersFieldsService {

  public carrierField: MultiSearchField = new MultiSearchField({
    fieldName: 'carrierGroup.code[]',
    label: 'PAGE.REVERSE.LIST.FILTERS.CARRIERS',
    data: null,
    textField: 'code',
    valueField: 'code',
    value: null,
    order: 7
  });

  public taskTypeField: MultiSearchField = new MultiSearchField({
    fieldName: 'taskType[]',
    label: 'PAGE.REVERSE.LIST.FILTERS.TASK_TYPES',
    data: null,
    textField: 'name',
    valueField: 'id',
    value: null,
    order: 10
  });

  public warehouseField: ComboSearchField = new ComboSearchField({
    fieldName: 'reverseProduct.reverse.warehouse.code[]',
    label: 'PAGE.REVERSE.LIST.FILTERS.WAREHOUSES',
    data: null,
    textField: 'label',
    valueField: 'value',
    valuePrimitive: true,
    value: this.getWarehouseFieldDefaultValue(),
    order: 11,
    readonly: SessionHelper.useLinkedWarehousesOnly() && SessionHelper.getLinkedWarehouses().length === 1,
  });

  public taskStatusField: TextField = new TextField({
    fieldName: 'taskStatus',
    value: null,
    hidden: true
  });

  public taskAssignedToMeField: TextField = new TextField({
    fieldName: 'assignedToMe',
    value: null,
    hidden: true
  });

  public startDateField: TextField = new TextField({
    fieldName: 'startDate',
    value: null,
    hidden: true
  });

  public endDateField: TextField = new TextField({
    fieldName: 'endDate',
    value: null,
    hidden: true
  });

  public REVERSE_TASKS: any[] = [];
  public LABEL_VALUES: any[] = [];
  public ARCHIVED = {
    NO: false,
    YES: true,
  };

  constructor(
    @Inject('StateService') protected state: ng.ui.IStateService,
    @Inject('TranslationService') public $translate: ng.translate.ITranslateService,
    formNotifier: FormNotifierService,
    private carrierGroupResource: CarrierGroupResource,
    private taskTypeResource: TaskTypeResource,
    private warehouseResource: WarehousesResource
  ) {
    super(formNotifier, state);

    this.fetchCarriers();
    this.fetchTaskTypeList();
    this.fetchWarehouses();

    const MY_OPENED_TASKS = {
      fieldValue: { assignToMe: 'true' },
      label: this.$translate.instant('PAGE.REVERSE.LIST.FILTERS.TASKS.VALUES.OPEN_AND_MY_TASKS')
    };

    const ALL_OPENED_TASKS = {
      fieldValue: { taskStatus: 'open' },
      label: this.$translate.instant('PAGE.REVERSE.LIST.FILTERS.TASKS.VALUES.OPEN')
    };

    this.REVERSE_TASKS = [
      MY_OPENED_TASKS,
      ALL_OPENED_TASKS,
    ];

    const WITH_LABEL = {
      fieldValue: 'false',
      label: this.$translate.instant('PAGE.REVERSE.LIST.FILTERS.LABEL.VALUES.WITH_LABEL')
    };

    const WITHOUT_LABEL = {
      fieldValue: 'true',
      label: this.$translate.instant('PAGE.REVERSE.LIST.FILTERS.LABEL.VALUES.WITHOUT_LABEL')
    };

    this.LABEL_VALUES = [
      WITH_LABEL,
      WITHOUT_LABEL,
    ];
  }

  private archivingList: ({ label: string; value: boolean } | { label: string; value: boolean } | { label: string; value: null })[] = [
    {
      label: this.$translate.instant('PAGE.COMMERCIAL_OPERATIONS.LIST.FILTERS.ARCHIVING_STATUS.DATA.NON_ARCHIVED'),
      value: this.ARCHIVED.NO
    },
    {
      label: this.$translate.instant('PAGE.COMMERCIAL_OPERATIONS.LIST.FILTERS.ARCHIVING_STATUS.DATA.ARCHIVED'),
      value: this.ARCHIVED.YES
    },
  ];

  /**
   * Fetches carriers.
   */
  private fetchCarriers(): void {
    const subscriber: Subscription = this.carrierGroupResource.getMany(
      { 'handleReverses': 1, 'pagination': false }
    ).subscribe((carriers: ICarrierGroup[]) => {
      subscriber.unsubscribe();
      this.carrierField.data = this.sortCarriers(carriers);
    });
  }

  /**
   * Fetches task types.
   */
  private fetchTaskTypeList(): void {
    this.taskTypeResource.cGet({ active: true }, { returnHydraMembers: true })
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe((taskTypes: ITaskType[]) => this.taskTypeField.data = taskTypes)
      ;
  }

  private getCountryCodesFromUser() {
    const countryCodes = [];
    for (const country of SessionHelper.get('CURRENT_USER_COUNTRIES')) {
      countryCodes.push(country.code);
    }
    return countryCodes;
  }

  private fetchWarehouses() {
    if (SessionHelper.useLinkedWarehousesOnly()) {
      const warehouseCodes = [];
      for (const warehouse of SessionHelper.getLinkedWarehouses()) {
        warehouseCodes.push({ label: warehouse.code, value: warehouse.code });
      }
      this.warehouseField.data = warehouseCodes;
    } else {
      const params: any = { 'carriers.country.code[]': [] };
      for (const countryCode of this.getCountryCodesFromUser()) {
        params['carriers.country.code[]'].push(countryCode);
      }

      this.warehouseResource.cGet(params).pipe(
        takeUntil(this.destroyed$)
      ).subscribe(
        (response: any) => {
          const warehouses = response['hydra:member'];
          const warehouseCodes = [];
          for (const warehouse of warehouses) {
            warehouseCodes.push({ label: warehouse.code, value: warehouse.code });
          }
          this.warehouseField.data = warehouseCodes;
        });
    }
  }

  /**

   * Gets fields list.
   */
  public getFields(): AbstractFormFieldBase<any>[] {

    this.carrierField.value = this.filters.getAll('carrierGroup.code[]').length !== 0 ?
      this.filters.getAll('carrierGroup.code[]') :
      undefined
      ;

    this.taskTypeField.value = this.filters.getAll('taskType[]').length !== 0 ?
      this.filters.getAll('taskType[]') :
      undefined
      ;

    this.taskStatusField.value = this.filters.get('taskStatus') ? this.filters.get('taskStatus') : undefined;
    this.taskAssignedToMeField.value = this.filters.get('assignedToMe') ? this.filters.get('assignedToMe') : undefined;
    this.startDateField.value = this.filters.get('startDate') ? this.filters.get('startDate') : undefined;
    this.endDateField.value = this.filters.get('endDate') ? this.filters.get('endDate') : undefined;

    const fields: AbstractFormFieldBase<any>[] = [

      new TextField({
        label: 'PAGE.REVERSE.LIST.FILTERS.CASE_ID',
        fieldName: 'reverseProduct.reverse.id',
        value: this.filters.get('reverseProduct.reverse.id'),
        order: 1
      }),

      new DateField({
        label: 'PAGE.REVERSE.LIST.FILTERS.DATES',
        fieldName: 'date',
        dateRange: true,
        value: this.filters.get('startDate') && this.filters.get('endDate') ?
          [new Date(this.filters.get('startDate')), new Date(this.filters.get('endDate'))] :
          undefined,
        valueChangedAction: this.setDates.bind(this),
        order: 2
      }),

      this.startDateField,
      this.endDateField,

      new SkuSearchField({
        fieldName: 'packageSku[]',
        productType: 'product',
        label: 'PAGE.REVERSE.LIST.FILTERS.SKUS',
        value: this.filters.getAll('packageSku[]').length !== 0 ? this.filters.getAll('packageSku[]') : undefined,
        order: 3
      }),

      new MultiSearchField({
        fieldName: 'receiveStatus[]',
        label: 'PAGE.REVERSE.LIST.FILTERS.RECEIVE_STATUSES',
        data: getReceiveStatuses(),
        textField: 'label',
        valueField: 'id',
        value: this.filters.getAll('receiveStatus[]').length !== 0 ? this.filters.getAll('receiveStatus[]') : undefined,
        order: 4
      }),

      new MultiSearchField({
        fieldName: 'reconditionedStatus[]',
        label: 'PAGE.REVERSE.LIST.FILTERS.RECONDITIONED_STATUSES',
        data: getReconditionedStatuses(),
        textField: 'label',
        valueField: 'id',
        value: this.filters.getAll('reconditionedStatus[]').length !== 0 ?
          this.filters.getAll('reconditionedStatus[]') : undefined,
        order: 5
      }),

      new TextField({
        label: 'PAGE.REVERSE.LIST.FILTERS.ORDER_ID',
        fieldName: 'reverseProduct.reverse.order.id',
        value: this.filters.get('reverseProduct.reverse.order.id'),
        order: 6
      }),

      this.carrierField,

      new ComboSearchField({
        fieldName: 'reverseProduct.reverse.status',
        label: 'PAGE.REVERSE.LIST.FILTERS.STATUSES',
        data: REVERSE_STATUSES,
        valuePrimitive: true,
        value: this.filters.get('reverseProduct.reverse.status'),
        order: 8
      }),


      new ComboSearchField({
        fieldName: 'reverseProduct.reverse.archived',
        label: 'PAGE.REVERSE.LIST.FILTERS.ARCHIVED',
        data: this.archivingList,
        valuePrimitive: true,
        textField: 'label',
        valueField: 'value',
        value: this.filters.get('reverseProduct.reverse.archived'),
        order: 15
      }),

      new ComboSearchField({
        fieldName: 'expected',
        label: 'PAGE.REVERSE.LIST.FILTERS.EXPECTED',
        data: this.archivingList,
        valuePrimitive: true,
        textField: 'label',
        valueField: 'value',
        value: this.filters.get('expected'),
        order: 15
      }),


      new ComboSearchField({
        label: 'PAGE.REVERSE.LIST.FILTERS.TASKS.LABEL',
        fieldName: 'taskFilter',
        order: 9,
        data: this.REVERSE_TASKS,
        textField: 'label',
        valueField: 'fieldValue',
        value: this.filters.get('assignedToMe') ?
          this.REVERSE_TASKS[0] :
          this.filters.get('taskStatus') ? this.REVERSE_TASKS[1] : undefined,
        valueChangedAction: this.updateTasksHiddenFields.bind(this)
      }),

      this.taskStatusField,

      this.taskAssignedToMeField,

      this.taskTypeField,

      new ComboSearchField({
        fieldName: 'withoutLabel',
        label: 'PAGE.REVERSE.LIST.FILTERS.LABEL.LABEL',
        data: this.LABEL_VALUES,
        textField: 'label',
        valueField: 'fieldValue',
        valuePrimitive: true,
        value: this.filters.get('withoutLabel'),
        order: 10
      }),

      this.warehouseField,

      new MultiSearchField({
        fieldName: 'reverseProduct.reverse.order.shippingAddress.countryCode[]',
        label: 'PAGE.ORDER.LIST.FILTER.COUNTRY.LABEL',
        data: SessionHelper.getCountries(),
        textField: 'name',
        valueField: 'code',
        value: this.filters.getAll('reverseProduct.reverse.order.shippingAddress.countryCode[]').length !== 0 ?
          this.filters.getAll('reverseProduct.reverse.order.shippingAddress.countryCode[]') : undefined
      }),
    ];

    return fields.sort((a, b) => a.order - b.order);
  }

  /**
   * Updates myTasks + taskStatus fields when the taskFilter field value changes.
   */
  public updateTasksHiddenFields(newValues: any) {
    if (undefined === newValues) {
      this.taskStatusField.value = undefined;
      this.taskAssignedToMeField.value = undefined;
    }

    if (undefined !== newValues) {
      this.taskStatusField.value = newValues.fieldValue.taskStatus;
      this.taskAssignedToMeField.value = newValues.fieldValue.assignToMe;
    }
  }

  private setDates(newValue: string) {
    if (newValue) {
      this.startDateField.value = null !== newValue[0] ?
        moment(newValue[0]).startOf('day').format(DATE_FULL_FORMAT) :
        undefined
        ;

      this.endDateField.value = null !== newValue[0] ?
        moment(newValue[1]).endOf('day').format(DATE_FULL_FORMAT) :
        undefined
        ;

      return;
    }

    this.startDateField.value = null;
    this.endDateField.value = null;
  }

  private getWarehouseFieldDefaultValue(): string {
    if (this.filters.get('reverseProduct.reverse.warehouse.code[]')) {
      return this.filters.get('reverseProduct.reverse.warehouse.code[]');
    }
    if (SessionHelper.useLinkedWarehousesOnly() && SessionHelper.getLinkedWarehouses().length === 1) {
      return SessionHelper.getLinkedWarehouses()[0].code;
    }

    return null;
  }

  private sortCarriers(carriers: ICarrierGroup[]) {
    let topListCarriers: any[] = [];
    const othersCarriers = [];
    const topList = [
      'dpd',
      'geodis',
      'gls',
      'gls_be_pco',
      'schenker',
      'chronopost',
      'colissimo',
      'trusk_etage',
      'relaiscolis-confort',
      'relaiscolis-eco',
      'relaiscolis-pr',
      'relaiscolis-prmax',
      'relaiscolis-takeback-confort',
      'relaiscolis-takeback-eco',
    ];

    for (let i = 0; i < carriers.length; i++) {
      if (inArray(carriers[i].code, topList)) {
        topListCarriers[topList.indexOf(carriers[i].code)] = carriers[i];
      } else {
        othersCarriers.push(carriers[i]);
      }
    }
    topListCarriers = topListCarriers.filter(function() {return true; });
    othersCarriers.sort((a: ICarrierGroup, b: ICarrierGroup) => a.code.localeCompare(b.code));

    return topListCarriers.concat(othersCarriers);
  }
}

function inArray(needle: string, haystack: any) {
  const length = haystack.length;
  for (let i = 0; i < length; i++) {
    if (haystack[i] === needle) { return true; }
  }
  return false;
}
