import {
  IImportItem,
  IImportItemField,
  IImportItemFieldValidation
} from '@components/generic/import-v2/models/import-field.model';

export class ProductImportModel implements IProductImport {
  public status: string;
  public process: IProductImportProcess;
  public data: ProductImportProductItemModel[];
  public errors: IProductImportErrors;

  constructor(model: IProductImport) {
    this.status = model.status;
    this.process = model.process;
    this.data = model.data.map((element: IProductImportProductItem) => new ProductImportProductItemModel(element));
    this.errors = new ProductImportErrors(model.errors);
  }
}

export class ProductImportErrors implements IProductImportErrors {
  public globalErrors: {message: string}[];
  public lineErrors: IProductImportErrorLine[];

  constructor(model: IProductImportErrors) {
    this.globalErrors = model.globalErrors;
    this.lineErrors = model.lineErrors.sort((a, b) => a.line - b.line);
  }

  public hasError(): boolean {
    return 0 !== this.globalErrors.length
        || 0 !== this.lineErrors.length;
  }
}

export class ProductImportProductItemModel implements IProductImportProductItem {
  public sku: string;
  public statuses: IProductImportProductItemStatus;
  public valid: boolean;
  public validOnLoad: boolean;
  public fields: ProductImportProductItemFieldModel[];

  constructor(model: IProductImportProductItem) {
    this.sku = model.sku;
    this.statuses = model.statuses;
    this.valid = model.valid;
    this.validOnLoad = model.valid;
    this.fields = model.fields.map(element => new ProductImportProductItemFieldModel(element));
  }
}

export class ProductImportProductItemFieldModel implements IImportItemField {
  id: string;
  value: string;
  valueOnLoad: string;
  validation: IImportItemFieldValidation;

  constructor(model: IImportItemField) {
    this.id = model.id;
    this.value = model.value;
    this.valueOnLoad = model.value;
    this.validation = model.validation;
    this.validation.validOnLoad = model.validation.valid;
  }
}

interface IProductImportProcess {
  processId: string;
  processType: string;
  uploadedFiles: {
    accessiblePath: string,
    filename: string,
    extension: string
  }[];
}

interface IProductImport {
  status: string;
  process: IProductImportProcess;
  data: IProductImportProductItem[];
  errors: IProductImportErrors;
}

interface IProductImportProductItem extends IImportItem {
  sku: string;
  statuses: IProductImportProductItemStatus;
}

interface IProductImportProductItemStatus {
  current: string;
  between: string[];
  next: string;
}

interface IProductImportErrors {
  globalErrors: {message: string}[];
  lineErrors: IProductImportErrorLine[];
  hasError(): boolean;
}

interface IProductImportErrorLine {
  line: number;
  errors: {message: string}[];
}
