import { AbstractComponent } from '@components/generic/abstract.component';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { ProductResource } from '@components/product/product.resource';
import { IProductForm } from '@components/product/interfaces/product-form.interface';

@Component({
  selector: 'app-product-content-tab',
  template: require('./product-content-tab.component.html'),
  providers: [
    { provide: AbstractResource, useClass: ProductResource },
  ],
})
export class ProductContentTabComponent extends AbstractComponent {

  @Input() model: IProductForm;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    public resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }
}
