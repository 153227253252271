import { AbstractResource } from '../../../resources/abstract.resource';
import { Injectable } from '@angular/core';
import { Resource } from '@decorators/Resource';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';

@Injectable()
@Resource({
  routeName: 'commercial_operation_block_insets',
  entryPoint: '/v2/commercial_operation_block_insets',
  translationKey: 'COMMERCIAL_OPERATIONS_BLOCK_INSET',
  cGetDefaultFilters: () => ({
    page: 1,
    byPage: 30,
  }),
  isHydra: true,
  returnHydraMembers: true,
  updateAvailable: true
})
export class CommercialOperationBlockInsetResource extends AbstractResource {constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  public getCommercialOperationBlockInset(commercialOperationId: number, locale: string): Observable<any> {
    return this.cGet({'commercialOperation.id': commercialOperationId, 'locale': locale});
  }
}
