import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericModule } from '@components/generic/generic.module';

import { PageTitleComponent, TranslatedTitleService } from './';

@NgModule({
  declarations: [PageTitleComponent],
  entryComponents: [PageTitleComponent],
  imports: [CommonModule, GenericModule],
  exports: [PageTitleComponent],
})
export class PageTitleModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: PageTitleModule,
      providers: [TranslatedTitleService],
    };
  }
}
