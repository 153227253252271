import { AbstractComponent } from '@components/generic/abstract.component';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DATE_FULL_FORMAT, DATE_SHORT_FORMAT, INPUT_NUMBER_PATTERN_DEC } from '@constants';
import { CommercialOperationProductMarketplaceOffersResource } from '@components/commercial-operations/resources';
import * as moment from 'moment';
import { SnackbarService } from '@components/snackbar';
import { SessionHelper } from '@helpers';
import { IProductMarketplace } from '@components/product/interfaces/product-form.interface';
import { forkJoin } from 'rxjs/observable/forkJoin';
import {takeUntil} from 'rxjs/operators';
import {Observable} from 'rxjs/Observable';

@Component({
  selector: 'commercial-operation-product-form',
  template: require('./commercial-operation-product-form.component.html'),
  providers: [
    { provide: AbstractResource, useClass: CommercialOperationProductMarketplaceOffersResource },
  ]
})
export class CommercialOperationProductFormComponent extends AbstractComponent implements OnInit {
  @Input() public commercialOperation?: any;

  @Output() onClose: EventEmitter<any> = new EventEmitter();

  public form: FormGroup;
  public currentCountryCode: string = SessionHelper.getCountry().code;
  public productMarketplaceIds?: any[] = [];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    private snackbar: SnackbarService,
  ) {
    super($translate, authService, resource, state);
  }

  public ngOnInit(): void {
    this.buildForm();
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      skuParent: [null],
      sku: [null, [Validators.required]],
      basePrice: [null, [Validators.pattern(INPUT_NUMBER_PATTERN_DEC)]],
      offerPrice: [null, [Validators.pattern(INPUT_NUMBER_PATTERN_DEC)]],
      offerPriceVp: [null, [Validators.pattern(INPUT_NUMBER_PATTERN_DEC)]],
      startDate: [null],
      endDate: [null],
      rate: [null, [Validators.pattern(INPUT_NUMBER_PATTERN_DEC)]]
    });
  }

  public superProductChange($event: any): void {
    if (!Array.isArray($event)) {
      return;
    }

    const params: any = {
      marketplace: this.commercialOperation.marketplace,
      countryCode: this.currentCountryCode,
      'product.superProduct.id[]': $event.map((item) => item.id),
    };

    const options: any = {
      entryPoint: '/v2/product_marketplaces',
      isHydra: true,
      returnHydraMembers: true,
      dontUseModel: true,
    };

    this.productMarketplaceIds = null;

    this.resource.cGet(params, options)
      .takeUntil(this.destroyed$)
      .subscribe((results: IProductMarketplace[]): void => {
        const productMarketplaceIds: string[] = results.map((item: IProductMarketplace) => {
          return item.id;
        });

        this.form.get('sku').setValue(productMarketplaceIds);
        setTimeout((): void => { this.productMarketplaceIds = productMarketplaceIds; }, 100);
      })
    ;
  }

  public submit(): void {
    forkJoin(this.bulk())
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
        this.state.go(this.state.current, this.state.params, { reload: true });
      })
    ;
  }

  public bulk(): Observable<object>[] {
    const observables: Observable<object>[] = [];

    this.form.value.sku.forEach((item: any): void => {
      observables.push(this.observe(item.id));
    });

    return observables;
  }

  public observe(productMarketplaceId: number): Observable<object> {
    const body: any = {
      'commercialOperation': this.commercialOperation['@id'],
      'productMarketplace': `/api/v2/product_marketplaces/${productMarketplaceId}`
    };

    if (this.form.value.basePrice) {
      body['basePrice'] = this.form.value.basePrice;
    }

    if (this.form.value.offerPrice) {
      body['offerPrice'] = this.form.value.offerPrice;
    }

    if (this.form.value.offerPriceVp) {
      body['offerPriceVp'] = this.form.value.offerPriceVp;
    }

    if (this.form.value.startDate) {
      body['startDate'] = moment(this.form.value.startDate, DATE_SHORT_FORMAT).startOf('day').format(DATE_FULL_FORMAT);
    }

    if (this.form.value.endDate) {
      body['endDate'] = moment(this.form.value.endDate, DATE_SHORT_FORMAT).endOf('day').format(DATE_FULL_FORMAT);
    }

    if (this.form.value.rate) {
      body['rate'] = this.form.value.rate;
    }

    return this.resource.create(body, { cleanParams: false });
  }
}
