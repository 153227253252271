import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources';
import { OrderManagerResource } from '../order-manager.resource';
import { DashboardIndicatorResource } from './dashboard-indicator.resource';

@Component({
  selector: 'app-order-manager-dashboard',
  template: require('./order-manager-dashboard.component.html'),
  styles: [require('./order-manager-dashboard.component.scss')],
  providers: [
    { provide: AbstractResource, useClass: OrderManagerResource }
  ]
})

export class OrderManagerDashboardComponent extends AbstractPageComponent implements OnInit {

  @Output() onKpiClickEvent: EventEmitter<any> = new EventEmitter();

  dashboardIndicators: any = {};

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    public resource: AbstractResource,
    private dashboardIndicatorResource: DashboardIndicatorResource
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit() {
    this.fetch();
  }

  fetch() {
    this.dashboardIndicatorResource.cGet().subscribe(
      (response: any) => {
        this.dashboardIndicators = response['hydra:member'];
        for (const indicator of response['hydra:member']) {
          this.dashboardIndicators[indicator.id] = indicator.value;
        }
      });
  }

  onCardClick(indicator: string) {
    this.onKpiClickEvent.emit();
    this.state.go(`order_manager.list`, { 'indicatorFilters[]': indicator });
  }
}
