import { IHydraMember } from '@interfaces/hydra-resource.interface';

export class ArrivalModel {
  '@id': string;
  id: string;
  sku: string;
  date?: Date;
  quantity: number;
  maximumQuantity?: number;
  computedMaximumFreightUnitPrice?: number;
  maximumFreightUnitPrice?: number;
  freightPrice?: number;
  duty?: number;
  operatingPrice?: number;
  unloadingPrice?: number;
  isSageSynchronizationEnabled: boolean;
  unitPrice?: number;
  cmup?: number;
  totalExcludedTaxes?: number;

  constructor(arrival: IHydraMember) {
    this['@id'] = arrival['@id'];
    this.id = arrival.id;
    this.sku = arrival.masterProductsWarehouses ? arrival.masterProductsWarehouses.masterProduct.sku : null;
    this.date = arrival.date;
    this.quantity = arrival.quantity;
    this.maximumQuantity = arrival.maximumQuantity;
    this.computedMaximumFreightUnitPrice = arrival.computedMaximumFreightUnitPrice;
    this.maximumFreightUnitPrice = arrival.maximumFreightUnitPrice;
    this.freightPrice = arrival.freightPrice;
    this.duty = arrival.duty;
    this.operatingPrice = arrival.operatingPrice;
    this.unloadingPrice = arrival.unloadingPrice;
    this.isSageSynchronizationEnabled = arrival.sageSynchronizationEnabled;
    this.unitPrice = arrival.unitPrice;
    this.cmup = arrival.cmup;
    this.totalExcludedTaxes = arrival.totalExcludedTaxes;
  }
}
