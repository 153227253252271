import { Inject, Injectable } from '@angular/core';
import { FormNotifierService } from '@services/form-notifier.service';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { AbstractFormFieldBase } from '@components/generic/Form/dynamic/fields/abstract-form-field-base.class';
import { SkuSearchField, TextField } from '@components/generic/Form/dynamic/fields';

@Injectable()
export class GrcProductListService extends AbstractFiltersFieldsService {

  constructor(
    @Inject('StateService') protected state: ng.ui.IStateService,
    @Inject('TranslationService') public translate: ng.translate.ITranslateService,
    formNotifier: FormNotifierService,
  ) {
    super(formNotifier, state);
  }

  public getFields(): AbstractFormFieldBase<any>[] {
    return [
      new TextField({
        fieldName: 'reference',
        label: 'PAGE.PRODUCT.LIST.FILTER.REFERENCE.LABEL',
        value: this.filters.get('reference') ? this.filters.get('reference') : undefined,
        order: 9
      }),
      new SkuSearchField({
        fieldName: 'sku',
        label: 'PAGE.ORDER.LIST.FILTER.SKU.LABEL',
        productType: 'product',
        value: this.filters.get('sku') ? this.filters.get('sku') : undefined,
        valueField: 'sku',
        valuePrimitive: true
      }),
    ];
  }

  getComplementaryFields(): AbstractFormFieldBase<any>[] {
    return [];
  }
}
