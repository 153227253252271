import { Component, Inject, Input, OnInit } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services';
import { ITaskManagerComponent } from './task-manager.component.interface';
import { IBusinessObject, ITaskCategory, ITaskStatus, ITaskType, ITaskTypes } from './interfaces';
import { TaskCategoryResource } from './resources';
import { SessionHelper } from '@helpers/session.helper';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { Observable } from 'rxjs/Observable';

@Component({
  selector: 'app-task-manager',
  template: require('./task-manager.component.html'),
  styles: [require('./task-manager.component.scss')],
})
export class TaskManagerComponent extends AbstractComponent implements OnInit, ITaskManagerComponent {

  @Input() public businessObject: IBusinessObject;
  @Input() public businessObjectType: string = '';

  public taskCategories: ITaskCategory[];
  public taskTypes: ITaskTypes[];
  public taskStatuses: ITaskStatus;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private taskCategoryResource: TaskCategoryResource,
  ) {
    super($translate, authService, null, state);
  }

  ngOnInit(): void {
    this.getTaskCategories();
    this.taskStatuses = SessionHelper.getTaskStatuses();
  }

  private getTaskCategories(): void {
    this.taskCategoryResource.getMany(
      {
        businessObject: this.businessObjectType,
        businessObjectId: this.businessObject.id
      }, { dontUseModel: true }
    )
      .pipe(
        switchMap((response: ITaskCategory[]) => {
          this.taskCategories = response;

          const taskTypes$: Observable<object>[] = this.taskCategories.map((taskCategory: ITaskCategory) => {
            return this.taskCategoryResource.getTaskTypes(taskCategory.id)
              .pipe(
                map((taskTypes: ITaskType[]) => {
                  return { items: taskTypes, taskCategoryId: taskCategory.id };
                })
              );
          });

          return forkJoin(taskTypes$);
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe((response: ITaskTypes[]) => this.taskTypes = response)
    ;
  }

  /**
   * Gets the task types associated with the current task category.
   */
  public getTaskTypes(taskCategoryId: string): ITaskType[] {
    return this.taskTypes.find((item: ITaskTypes) => item.taskCategoryId === taskCategoryId).items;
  }
}
