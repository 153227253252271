'use strict'

angular
  .module 'ammsFrontendApp'
  .config [
    '$provide'
    'uibPaginationConfig'
    ($provide, uibPaginationConfig) ->
      uibPaginationConfig.maxSize = 5
      uibPaginationConfig.rotate = false

      uibPaginationConfig.directionLinks = true
      uibPaginationConfig.previousText = 'PAGINATION.PREVIOUS.TEXT'
      uibPaginationConfig.nextText = 'PAGINATION.NEXT.TEXT'

      uibPaginationConfig.boundaryLinks = true
      uibPaginationConfig.firstText = 'PAGINATION.FIRST.TEXT'
      uibPaginationConfig.lastText = 'PAGINATION.LAST.TEXT'

      $provide
        .decorator 'uibPaginationDirective', ($delegate) ->
          directive = $delegate[0]
          directive.templateUrl = require('views/ui-bootstrap/pagination/pagination.html')
          $delegate

      $provide
        .decorator 'uibTabsetDirective', ($delegate) ->
          directive = $delegate[0]
          directive.templateUrl = require('views/ui-bootstrap/tabs/tabset.html')
          $delegate
  ]
