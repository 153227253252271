import {Component, ComponentFactoryResolver, Inject, OnInit} from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { GenericResultListComponent } from '@components/generic/List';
import { UrlHelper} from '@helpers';
import { FormService } from '@services/form.service';
import { IWarehouses } from '@components/warehouses/models';
import { WarehousesResource } from '@components/warehouses';

@Component({
  selector: 'app-stock-shooting-result-list',
  template: require('./stock-shooting-result-list.component.html'),
  providers: [
    FormService
  ],
})

export class StockShootingResultListComponent extends GenericResultListComponent {
  public toWarehouses: any[] = [];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    @Inject('StateService') state: ng.ui.IStateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('DialogService') dialog: any,
    urlHelper: UrlHelper,
    componentFactoryResolver: ComponentFactoryResolver,
    private warehouseResource: WarehousesResource,
  ) {
    super($translate, state, authService, resource, dialog, urlHelper, componentFactoryResolver);
  }

  public editMovement(stockMovementId: number): void {
    this.state.go('stock-shooting.detail', { id: stockMovementId }, { reload: true });
  }

  public fetchWarehouse(): void {
    this.warehouseResource.getMany(
      { pagination: false },
      { dontUseModel: true }
    )
      .takeUntil(this.destroyed$)
      .subscribe((warehouses: IWarehouses[]) => {
        warehouses.forEach((warehouse: IWarehouses) => {
          if (warehouse.code === 'ecospace' || warehouse.code === 'alice_deals') {
            this.toWarehouses.push(warehouse);
          }
        });
      })
    ;
  }

  goToOrder(orderId: string): void {
    this.state.go('order_manager.edit', { id: orderId });
  }
}
