'use strict'

###
  @name draggableRow

  @description
  Enable drag'n drop for table row

  @param {Object} ng-model the model which store the order (if you're inside a ng-repeat, often, it's the table)

  @example
    <example>
      <tr draggable-row
          ng-model="myModel"></tr>
    </example>

###
angular
  .module('ammsFrontendApp')
  .directive('draggableRow', ['$compile', ($compile) ->
    return {
      restrict: 'A',
      priority: 1500, #compile first
      terminal: true,
      link: (scope, element, attrs) ->
        # remove itself to avoid infinite loop
        element.removeAttr('draggable-row')

        # catching directive without ngModel related
        if (!attrs.ngModel?)
          throw new Error('You have to defined a ng-model attribute to the row to handle re-ordering')

        # init amms styles
        element.addClass('draggable-row')
        # init drag'n drop plugin
        element.attr('data-drag', attrs.dataDrag || true)
        element.attr('data-drop', attrs.dataDrop || true)
        element.attr('jqyoui-droppable', attrs.jqyouiDroppable || '{index: {{$index}}}')
        element.attr(
          'jqyoui-draggable',
          attrs.jqyouiDraggable || "{index: {{$index}}, direction: 'direction', insertInline: true}"
        )
        element.attr('data-jqyoui-options', attrs.jqyouiOptions || "{revert: 'invalid'}")

        callback = $compile(element)(scope)

        return (scope) ->
          callback(scope)
    }
  ])
