import { Component, Inject, OnInit } from '@angular/core';
import { SnackbarService } from '@components/snackbar/snackbar.service';
import { FormErrorHelper, SessionHelper } from '@helpers';
import { WarrantyResource } from '@components/warranty/resources/warranty.resource';
import { AuthService } from '@services';
import { WarrantyModel } from '@components/warranty/models';
import { IWarranty } from '@components/warranty/models/warranty.interface';
import { FormSubmitArgument } from '@interfaces';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';

@Component({
  selector: 'app-warranty-edit',
  template: require('./warranty-edit.component.html')
})
export class WarrantyEditComponent extends AbstractPageComponent implements OnInit {

  public warranty: IWarranty;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: WarrantyResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private snackbar: SnackbarService,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit() {
    this.fetch();
  }

  /**
   * Fetches API to get warranty info.
   */
  private fetch(): void {
    this.resource.get(this.state.params.id, {model: WarrantyModel})
      .takeUntil(this.destroyed$)
      .subscribe((warranty: IWarranty) => {
        this.warranty = warranty;
      })
    ;
  }

  /**
   * Handles the save form event.
   */
  public save({body, form, redirect}: FormSubmitArgument): void {
    const data: any = body;

    for (const locale in data.translations) {
      if (data.translations.hasOwnProperty(locale) && data.translations[locale].hasOwnProperty('id') && data.translations[locale].hasOwnProperty('@id')) {
        data.translations[locale].id = data.translations[locale]['@id'];
      }
    }

    this.resource.update(this.state.params.id, data, {model: WarrantyModel})
      .takeUntil(this.destroyed$)
      .subscribe(() => {
        this.snackbar.validate(this.translate('PAGE.WARRANTY.EDIT.SAVED'));
        if (redirect) {
          this.actions.list.go();
        }
      }, (error: any) => {
        if (400 === error.code) {
          FormErrorHelper.fillFormControlWithErrors(form, error.errors);
        }
      })
    ;
  }
}
