import { Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {FormGroup, FormArray, Validators, FormBuilder} from '@angular/forms';
import { ReverseFormService } from './services';
import { ReverseResource } from './resources';
import { AbstractPageComponent } from '../generic/abstract-page.component';
import { ICarrierGroup } from '@interfaces';
import {
  getReceiveStatuses,
  getReconditionedStatuses,
  getSelectableReconditionedStatuses,
  getPreselectionStatusesMapping,
  RECEIVE_WAITING,
  RECEIVE_SAV_WAITING, LOCALE_FR,
} from '@constants';
import { SkuSearchComponent } from '../generic/Form';
import { DATE_SHORT_FORMAT } from '@constants';
import { AuthService, FormNotifierService } from '@services';
import { CarrierGroupResource } from '@resources';
import {SessionHelper} from '@helpers';
import {IGrcReason} from '@components/order/interfaces/order-refund.interface';
import {TransactionResource} from '@components/order/resources/transaction.resource';
import {GrcReasonResource} from '@components/order/resources/grc-reason-resource.service';


@Component({
  selector: 'app-reverse-case-general',
  template: require('./reverse-case-general.component.html'),
  styles: [require('./reverse-case-general.component.scss')],
  providers: [
    GrcReasonResource, TransactionResource
  ],
})
export class ReverseCaseGeneralComponent extends AbstractPageComponent implements OnInit, OnDestroy {
  @ViewChild(SkuSearchComponent) private skuSearchComponent: SkuSearchComponent;

  @Input() public readOnly: boolean = false;

  public form: FormGroup;
  public dateFormat: string = DATE_SHORT_FORMAT;
  public isAddSku: boolean = false;
  public listColumns: string[] = [
    'SKU',
    'PACKAGE',
    'REVERSE',
    'WITHOUT_LABEL',
    'RECEIVE_STATUS',
    'RECONDITIONNED_STATUS',
    'CARRIER',
    'RECEPTION_DATE',
  ];
  public receiveSavWaiting: string = RECEIVE_SAV_WAITING;
  public receiveWaiting: string = RECEIVE_WAITING;
  public reconditionedStatus: object[] = getReconditionedStatuses();
  public selectableReconditionedStatus: object[] = getSelectableReconditionedStatuses();
  public receiveStatus: object[] = getReceiveStatuses();
  public carrierGroups: ICarrierGroup[];


  get getReverseProducts(): FormArray {
    return <FormArray>this.reverseFormService.reverseForm.get('reverseProducts');
  }

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: ReverseResource,
    @Inject('StateService') state: ng.ui.IStateService,
    public reverseFormService: ReverseFormService,
    private carrierGroupResource: CarrierGroupResource,
    private formNotifier: FormNotifierService,
    private grcReasonResource: GrcReasonResource,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    this.fetchCarriers();
  }

  /**
   * Manages the visibility of the reconditionned status dropdown.
   */
  public isReconditionnedStatusShown(reverseProductPackage: FormGroup): boolean {
    let isShown = false;

    if (
      reverseProductPackage.get('expected').value &&
      reverseProductPackage.get('receiveStatus').value !== this.receiveWaiting &&
      reverseProductPackage.get('receiveStatus').value !== this.receiveSavWaiting
    ) {
      isShown = true;
    }

    return isShown;
  }

  /**
   * Fetches carriers.
   */
  public fetchCarriers(): void {
    this.carrierGroupResource.getMany(
      { 'handleReverses': 1, 'pagination': false },
      { dontUseModel: true }
    )
      .takeUntil(this.destroyed$)
      .subscribe((carrierGroups: ICarrierGroup[]) => {
        this.carrierGroups = carrierGroups.sort((a: ICarrierGroup, b: ICarrierGroup) => a.code.localeCompare(b.code));
        this.reverseFormService.carrierGroups = this.carrierGroups;
      })
    ;
  }

  public isExport(reverseProductPackage: FormGroup): boolean {
    return reverseProductPackage.get('reconditionedStatus').value === 'sage'
      && (reverseProductPackage.get('dateExportSage') !== undefined && reverseProductPackage.get('dateExportSage').value !== null);
  }

  /**
   * Returns all the packages of a product.
   */
  private getReverseProductsPackages(i: number): FormArray {
    const reverseProducts: FormArray = <FormArray>this.reverseFormService.reverseForm.get('reverseProducts');

    return reverseProducts.at(i).get('reverseProductPackages') as FormArray;
  }

  /**
   * Adds a sku with packages to the reverse case (delegates treatment to reverseFormservice).
   */
  private addSku(): void {
    this.reverseFormService.getReverseProductPackages(this.skuSearchComponent.currentValues);
    this.skuSearchComponent.currentValues = [];
  }

  /**
   * Changes the carrier of the packages of a product if the carrier of the package isn't set.
   */
  private updateCarrier(event: number): void {
    const reverseProducts: FormArray = <FormArray>this.reverseFormService.reverseForm.get('reverseProducts');

    for (const i in reverseProducts.value) {
      if (reverseProducts.value.hasOwnProperty(i)) {
        const formGroup =  reverseProducts.at(parseInt(i, 10)).get('reverseProductPackages') as FormArray;
        formGroup.controls.forEach((packageFG: FormGroup) => {
          if (!packageFG.value.carrierGroup) {
            packageFG.patchValue({ carrierGroup: event });
          }
        });
      }
    }
  }

  private getCarrierGroups(carrierId: number): ICarrierGroup[] {
    return this.carrierGroups.filter((carrierGroup: ICarrierGroup) => {
      return carrierGroup.active || carrierGroup.id === carrierId;
    });
  }

  /**
   * Changes the reconditionned Status and sets it to a mapped status if mapping exists.
   */
  private updateReceiveStatus(event: string, productIndex: number, packageIndex: number): void {
    if (undefined !== getPreselectionStatusesMapping()[event]) {
      this.getReverseProductsPackages(productIndex).at(packageIndex).patchValue({
        reconditionedStatus: getPreselectionStatusesMapping()[event]
      });
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.formNotifier.notifyFormIsDestroyed();
  }

  public OnSelectReason(event: any) {
    this.grcReasonResource.cGet({'parent': event, 'reverse': 1}, { returnHydraMembers: true, blocking: true })
      .takeUntil(this.destroyed$)
      .subscribe((response: IGrcReason[]) => {
        this.reverseFormService.grcReasons = response.map((reason: IGrcReason) => {
          return {
            label: (reason.translations[SessionHelper.getUILanguage()]  || reason.translations[LOCALE_FR]).reason,
            id: reason.id,
          };
        }).sort((a, b) => {
          if (a.label < b.label) { return -1; }
          if (a.label > b.label) { return 1; }
          return 0;
        });
        this.reverseFormService.grcReasons  = this.reverseFormService.grcReasons.filter(elm => elm);
      });
      this.reverseFormService.reverseForm.patchValue({ 'grcReason': null });
  }
}
