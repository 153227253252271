export default roomRoutes;

/** @ngInject */
function roomRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'walipush',
    sidebarSubNav: 'room',
    title: '',
    reloadChangeCountry: 'room.list',
  };

  $stateProvider
    .state('room', {
      url: '/rooms',
      parent: 'walipush',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.ROOMS',
        stateName: 'room.list'
      }
    })
    .state('room.list', {
      url: `?
        page
        &byPage
      `,
      views: {
        'page@layout': {
          template: '<app-room-list></app-room-list>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.ROOMS.LIST.TITLE',
        showCountries: false
      })
    })
    .state('room.new', {
      url: '/new',
      breadcrumb: { label: 'BREADCRUMB.NEW' },
      views: {
        'page@layout': {
          template: '<app-room-form></app-room-form>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.ROOMS.LIST.TITLE',
        showCountries: false,
        tabs: [
          {
            label: 'PAGE.CATEGORIES.TABS.GENERAL',
          },
          {
            label: 'PAGE.CATEGORIES.CONTENT_WEBSITE',
          },
        ],
      })
    })
    .state('room.edit', {
      url: '/:id',
      breadcrumb: { label: 'BREADCRUMB.EDIT' },
      views: {
        'page@layout': {
          template: '<app-room-form></app-room-form>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.ROOMS.LIST.TITLE',
        showCountries: false,
        tabs: [
          {
            label: 'PAGE.CATEGORIES.TABS.GENERAL',
            state: 'room.edit',
          },
          {
            label: 'PAGE.CATEGORIES.CONTENT_WEBSITE',
            state: 'room.edit.blocks',
          },
        ],
      })
    })
    .state('room.edit.blocks', {
      url: '/blocks',
      breadcrumb: { label: 'BREADCRUMB.EDIT' },
      views: {
        'page@layout': {
          template: '<app-room-block-list></app-room-block-list>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.ROOMS.LIST.TITLE',
        showCountries: false,
        tabs: [
          {
            label: 'PAGE.CATEGORIES.TABS.GENERAL',
            state: 'room.edit',
          },
          {
            label: 'PAGE.CATEGORIES.CONTENT_WEBSITE',
            state: 'room.edit.blocks',
          },
        ],
      })
    })
  ;
}
