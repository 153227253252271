import {
  AfterViewInit,
  Component,
  ElementRef, EventEmitter,
  Inject,
  Input, OnChanges,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { AuthService } from '@services/auth.service';
import { IActions } from '@components/generic/interfaces/actions.interface';
import { ACTIONS } from '@components/generic/abstract.component';
import { CREATION_PAGE, EDITION_PAGE, LIST_PAGE } from '@interfaces/IPageComponent';

/**
 * Generic wrapper for pages.
 * Title is set automatically.
 * It displays by default a title btn according to the type page if the pageType and pageActions are sets.
 * Template can always receive custom title buttons.
 * If you don't need to use title buttons so don't use pageActions, you can only wrap your page in <app-generic-page>
 *
 * @example:
 * 1 - Here a create btn will be displayed if the main resource granted.
 *  ```
 *  <app-generic-page [pageActions]="actions">
 *    <app-generic-list content></app-generic-list><!-- or dnd list or custom list or what you want -->
 *  </app-generic-page>
 *  ```
 *
 * 2 - Here we pass other btn and other content
 *  ```
 *  <app-generic-page>
 *    <ng-container title-buttons>
 *       <a class="btn btn-sm btn-primary" (click)="some.action()">
 *          <i class="fa fa-plus"></i> {{translate('BUTTON.SOME.ACTION')}}
 *       </a>
 *       <a class="btn btn-sm btn-primary" (click)="some.another.action()">
 *          <i class="fa fa-plus"></i> {{translate('BUTTON.SOME.ANOTHER.ACTION')}}
 *       </a>
 *    </ng-container>
 *    <div content>
 *      ... what you want
 *    </div>
 *  </app-generic-page>
 *  ```
 */
@Component({
  selector: 'app-generic-page',
  template: require('./generic-page.component.html'),
  styles: [require('./generic-page.component.scss')],
})
export class GenericPageComponent extends AbstractPageComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild('titleButtons') titleButtons: ElementRef;

  /**
   * TODO: remove this and use the resource in a generic way (we are able to access to it in the AbstractComponent)
   * Required if the template need to performs some actions like go to the create page, submit a form etc.
   */
  @Input() pageActions?: IActions = ACTIONS;
  @Input() customTitleIdentifier?: string;
  @Input() customPageTitle?: string;

  @Output() public onDelete: EventEmitter<any> = new EventEmitter();

  public replaceDefaultTitleButtons: boolean = false;
  public list = LIST_PAGE;
  public creation = CREATION_PAGE;
  public edition = EDITION_PAGE;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private renderer: Renderer2,
  ) {
    super($translate, authService, null, state);
  }

  ngOnInit(): void {
    this.pageTitle = this.customPageTitle || this.pageTitle;
    this.actions = this.pageActions;
  }

  ngOnChanges(): void {
    this.pageTitle = this.translate(this.state.current.data.title, {id: this.getCustomTitleIdentifier()});
  }

  ngAfterViewInit(): void {
    const elem = this.titleButtons.nativeElement;

    this.replaceDefaultTitleButtons = !!elem.children.length;

    // if the default buttons are displayed, we had this hack to hide the transclude container
    if (!this.replaceDefaultTitleButtons) {
      this.renderer.addClass(elem, 'hidden');
    }
  }

  public delete() {
    this.onDelete.emit();
  }

  private getCustomTitleIdentifier(): string {
    if (undefined !== this.customTitleIdentifier) {
      return this.customTitleIdentifier;
    }
    return this.state.params.id ? this.state.params.id : '';
  }

}
