import {Component, Inject, OnInit} from '@angular/core';
import {AuthService} from '@services/auth.service';
import {AbstractResource} from '@resources/abstract.resource';
import {AbstractComponent} from '@components/generic/abstract.component';
import {SessionHelper} from '@helpers';
import {delay, takeUntil} from 'rxjs/operators';
import {ExportLogisticsResource} from '@components/export-logistics/export-logistics.resource';
import {SnackbarService} from '@components';
import {OrderManagerResource} from '@components/order-manager/order-manager.resource';
import {CarrierResource} from '@resources';
import { ORDER_ITEM_STATUS_PREPARATION, ORDER_ITEM_STATUS_PROCESSING } from '@constants/item-status.constants';
import { Observable, SubscribableOrPromise } from 'rxjs/Observable';
import { merge } from 'rxjs/observable/merge';

@Component({
  selector: 'app-sav-orders-result-list',
  template: require('./sav-orders-result-list.component.html'),
  styles: [require('./sav-orders-result-list.component.scss')],
})
export class SavOrdersResultListComponent extends AbstractComponent implements OnInit {

  private _items: any[];
  public carriers: any[];
  public selectAll: boolean = false;
  public ORDER_ITEM_STATUS_PROCESSING: string = ORDER_ITEM_STATUS_PROCESSING;
  public ORDER_ITEM_STATUS_PREPARATION: string = ORDER_ITEM_STATUS_PREPARATION;
  public showQuantityInput: number;

  set items(items: any[]) {
    this._items = items;
  }

  get items(): any[] {
    return this._items;
  }

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    @Inject('DialogService') private dialog: any,
    private logisticExportResource: ExportLogisticsResource,
    private orderManagerResource: OrderManagerResource,
    private carrierResource: CarrierResource,
    private snackbar: SnackbarService,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    const carriers = SessionHelper.getCountries().map((country: any) => {
      return country.code;
    });
    this.carrierResource.cGet({
        'country.code': carriers,
        'active': true,
        'pagination': false,
        'carrierGroup.handleDetached': true
      },
      {
        isHydra: true,
        returnHydraMembers: true,
        dontUseModel: true,
        blocking: false
      }).subscribe((response: any) => {
      this.carriers = response.map((item: any) => {
        return {
          id: item['@id'],
          code: item.carrierGroup.code,
          name: item.carrierGroup.name,
          carrier: item,
          country: item.country.code
        };
      });
    });
  }

  toggleSelectAll() {
    this.items.forEach(order => {
      order.selected = this.selectAll;
      order.shipments.forEach((shipment: any) => {
        shipment.selected = this.selectAll;
      });
    });
  }

  getSelectedShipments(): string[] {
    const shipmentIds: string[] = [];

    for (const order of this.items) {
      for (const shipment of order.shipments) {
        if (shipment.selected) {
          shipmentIds.push(shipment['@id']);
        }
      }
    }

    return shipmentIds;
  }

  incrementPackages(shipment: string) {
    this.orderManagerResource.updateNumberPackage('increment', shipment).subscribe((response: any) => {
      this.reloadData();
    });
  }

  decrementPackages(shipment: string) {
    this.orderManagerResource.updateNumberPackage('decrement', shipment).subscribe((response: any) => {
      this.reloadData();
    });
  }

  rollbackToggle() {
    this.dialog.confirm(this.translate('PAGE.SAV.ORDERS_LIST.BATCH.CONFIRM_ROLLBACK'))
      .then(() => {
        this.orderManagerResource.bulkResetShipment(this.getSelectedShipments())
          .pipe(takeUntil(this.destroyed$))
          .subscribe((response: any) => {
            this.snackbar.validate(this.translate('ALERTS.DATA.UPDATE'));
            this.reloadData();
          })
        ;
      })
    ;
  }

  shipToggle() {
    this.dialog.confirm(this.translate('PAGE.SAV.ORDERS_LIST.BATCH.CONFIRM_SHIP'))
      .then(() => {
        this.orderManagerResource.bulkShipShipment(this.getSelectedShipments())
          .pipe(takeUntil(this.destroyed$))
          .subscribe(() => {
            this.snackbar.validate(this.translate('ALERTS.DATA.UPDATE'));
            this.reloadData();
          })
        ;
      })
    ;
  }

  slipToggle() {
    this.dialog.confirm(this.translate('PAGE.SAV.ORDERS_LIST.BATCH.CONFIRM_SLIP'))
      .then(() => {
        this.orderManagerResource.bulkSlipShipment(this.getSelectedShipments())
          .pipe(takeUntil(this.destroyed$))
          .subscribe((response: any) => {
            this.snackbar.validate(this.translate('ALERTS.DATA.UPDATE'));
          })
        ;
      })
    ;
  }

  setCarrier(carrierIri: string) {
    this.dialog.confirm(this.translate('PAGE.SAV.ORDERS_LIST.BATCH.CONFIRM_SET_CARRIER'))
      .then(() => {
        this.orderManagerResource.bulkShipmentCarrier(this.getSelectedShipments(), carrierIri)
          .pipe(takeUntil(this.destroyed$))
          .subscribe((response: any) => {
            this.snackbar.validate(this.translate('ALERTS.DATA.UPDATE'));
            this.reloadData();
          })
        ;
      })
    ;
  }

  export() {
    this.logisticExportResource.create({shipments: this.getSelectedShipments(), dryRun: false}, {entryPoint: '/v2/logistic/export/validate'})
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        if (!response.length) {
          this.doExport();

          return;
        }

        this.dialog.confirm(this.translate('PAGE.SAV.ORDERS_LIST.BATCH.CONFIRM_WARNINGS') + '\n\n' + response.join('\n')).then(() => {
          this.doExport();
        });
      })
    ;
  }

  doExport() {
    this.logisticExportResource.create({shipments: this.getSelectedShipments(), dryRun: false})
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        this.snackbar.validate(this.translate('ALERTS.EXPORT.SUCCESS'));
        this.state.go(this.state.current, this.state.params, {reload: true});
      })
    ;
  }

  getTranslation(translations: any) {
    if (translations[SessionHelper.getLocale()]) {
      return translations[SessionHelper.getLocale()].shortTitle;
    } else if (Object.keys(translations).length) {
      return translations[Object.keys(translations)[0]].shortTitle;
    } else {
      return '';
    }
  }

  getAddressing(addressings: any) {
    let finalAddressing = '';
    addressings.forEach((addressing: any) => {
      if (addressing.addressingType === 'picking'
        && (addressing.addressingLane !== null
        || addressing.addressingBay !== null
        || addressing.addressingLocation !== null
        || addressing.addressingLevel !== null)
      ) {
        finalAddressing = (addressing.addressingLane ? addressing.addressingLane : '') + ' ' + (addressing.addressingBay ? addressing.addressingBay : '') + ' '
          + (addressing.addressingLocation ? addressing.addressingLocation : '') + ' ' + (addressing.addressingLevel ? addressing.addressingLevel : '');
      } else {
        if (addressing.addressingType === 'picking') {
          return;
        }
        if (finalAddressing === '') {
          finalAddressing = (addressing.addressingLane ? addressing.addressingLane : '') + ' ' + (addressing.addressingBay ? addressing.addressingBay : '')
            + ' ' + (addressing.addressingLocation ? addressing.addressingLocation : '') + ' ' + (addressing.addressingLevel ? addressing.addressingLevel : '');
        }
      }
    });
    return finalAddressing;
  }

  goToEdit(orderId: any, e?: MouseEvent) {
    if (e && true === e.ctrlKey) {
      window.open(
        this.state.href(
          'order_manager.edit',
          {id: orderId},
          {absolute: true}
        ),
        '_blank'
      );
      return;
    }

    this.state.go('order_manager.edit', {id: orderId});
  }

  goToProductEdit(event: MouseEvent, productUri: string) {
    const id = productUri.split('/').pop();
    const state = 'spare-part.edit';

    if (event.ctrlKey) {
      window.open(this.state.href(state, {id}, {absolute: true}), '_blank');
      return;
    }
    this.state.go(state, {id});
  }

  reloadData() {
    window.location.reload();
  }

  public displayQuantityInput(orderId: number): void {
    this.showQuantityInput = orderId;

      document.getElementById('numberOfPackageClusteredForSav-' + orderId).focus();
  }

  public hideQuantityInput(): void {
      this.showQuantityInput = null;
  }

  async saveQuantity(shipments: any[], event: any, numberOfPackageClusteredForSav: number) {
    if (event.keyCode === 27) {
      this.hideQuantityInput();
      return;
    }

    if (event.keyCode !== 13) {
      return;
    }

    const observables: Observable<any>[] = [];
    shipments.forEach((shipment: any) => {
        for (let i = 0; i < Math.abs(numberOfPackageClusteredForSav - event.target.valueAsNumber); i++) {
          if (numberOfPackageClusteredForSav < event.target.valueAsNumber) {
            observables.push(this.orderManagerResource.updateNumberPackage('increment', shipment['@id']).pipe(delay(2000)));
          } else {
            observables.push(this.orderManagerResource.updateNumberPackage('decrement', shipment['@id']).pipe(delay(2000)));
          }
        }
    });

    this.showQuantityInput = null;

    merge(...observables)
    .pipe(takeUntil(this.destroyed$))
    .subscribe(
      (response: any) => {},
      err => {},
      () => this.state.go(this.state.current, this.state.params, { reload: true })
    );
  }
}


