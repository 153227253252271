import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { AbstractResource } from '../resources/abstract.resource';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  entryPoint: '/v2/commercial_entities',
  isHydra: true,
})
export class CommercialEntityResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }
}
