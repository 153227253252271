import { NgModule } from '@angular/core';
import { CategoriesResource } from '@components/categories/categories.resource';
import { CategoriesComponent } from '@components/categories/categories.component';
import { CommonModule } from '@angular/common';
import { GenericFormModule, GenericModule, TabManagementModule } from '@components/generic';
import { CategoriesFormComponent } from '@components/categories/forms/categories-form/categories-form.component';
import { BsDatepickerModule, TabsModule } from 'ngx-bootstrap';
import { CategoriesFormTranslationsComponent } from '@components/categories/forms/categories-form/categories-form-translations.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EnhancedFieldsModule } from '@directives/enhanced-fields.module';
import { ComboBoxModule } from '@progress/kendo-angular-dropdowns';
import { SeoFormComponent } from '@components/categories/forms/seo-form/seo-form.component';
import { SeoFormTranslationsComponent } from '@components/categories/forms/seo-form/seo-form-translations.component';
import { SeoFormHreflangComponent } from '@components/categories/forms/seo-form/seo-form-hreflang.component';
import { SwitchModule } from '@progress/kendo-angular-inputs';
import { SuperProductsFormComponent } from '@components/categories/forms/super-products-form/super-products-form.component';
import { DndModule } from 'ng2-dnd';
import { CategoriesListComponent } from '@components/categories/list';
import { CategoryRelationsResource } from './category-relations.resource';
import { CategoriesSatelliteFormComponent } from '@components/categories/forms/categories-satellite-form/categories-satellite-form.component';
import { CategoriesSatelliteListComponent } from '@components/categories/list/categories-satellite-list/categories-satellite-list.component';
import { CategoryBlocksFormComponent } from '@components/categories/forms/blocks-form/category-blocks-form.component';

@NgModule({
  entryComponents: [
    CategoriesComponent,
    CategoriesListComponent,
    SeoFormComponent,
    SuperProductsFormComponent,
    CategoriesSatelliteFormComponent,
    CategoriesSatelliteListComponent,
    CategoryBlocksFormComponent,
  ],
  declarations: [
    CategoriesComponent,
    CategoriesListComponent,
    CategoriesFormComponent,
    CategoriesFormTranslationsComponent,
    SeoFormComponent,
    SeoFormTranslationsComponent,
    SuperProductsFormComponent,
    SeoFormHreflangComponent,
    CategoriesSatelliteFormComponent,
    CategoriesSatelliteListComponent,
    CategoryBlocksFormComponent,
  ],
  providers: [
    CategoriesResource,
    CategoryRelationsResource
  ],
  exports: [
    CategoriesFormComponent
  ],
  imports: [
    CommonModule,
    GenericModule,
    GenericFormModule,
    FormsModule,
    ReactiveFormsModule,
    TabManagementModule,
    TabsModule,
    EnhancedFieldsModule,
    ComboBoxModule,
    SwitchModule,
    DndModule,
    BsDatepickerModule,
  ]
})

export class CategoriesModule {}
