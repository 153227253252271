import {Inject, Injectable} from '@angular/core';
import {FormNotifierService} from '@services/form-notifier.service';
import {ComboSearchField, SkuSearchField} from '@components/generic/Form/dynamic/fields';
import {AbstractFiltersFieldsService} from '@components/generic/Form/filters/abstract-filters-fields.service';
import {SuperProductResource} from '@components/super-product/super-product.resource';
import {SessionHelper} from '@helpers';

@Injectable()
export class SparePartFiltersFormService extends AbstractFiltersFieldsService {

  public superProductsField: ComboSearchField;
  private superProductResponse: any[];

  constructor(
    @Inject('StateService') protected state: ng.ui.IStateService,
    @Inject('TranslationService') public $translate: ng.translate.ITranslateService,
    private superProductResource: SuperProductResource,
    formNotifier: FormNotifierService,
  ) {
    super(formNotifier, state);

    this.superProductResource.getSkus({ locale: SessionHelper.getLocale() })
      .takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        this.superProductResponse = response;
      });

    this.superProductsField = new ComboSearchField({
      fieldName: 'idParent',
      label: 'PAGE.PRODUCT.LIST.FILTER.SUPER_PRODUCT.LABEL',
      data: null,
      textField: 'label',
      valueField: 'id',
      value: this.filters.get('skuParent') ? this.filters.get('skuParent') : undefined,
      valuePrimitive: true,
      order: 1,
      filterChange: this.filterChange.bind(this)
    });
  }

  private filterChange(sku: any) {
    this.superProductsField.data = this.superProductResponse.filter((object: any) => {
      return object.skuParent.startsWith(sku);
    });
  }

  public getFields(): any[] {
    return [
      this.superProductsField,
      new SkuSearchField({
        fieldName: 'sku',
        label: 'PAGE.ORDER.LIST.FILTER.SKU.LABEL',
        productType: 'product',
        value: this.filters.getAll('sku').length !== 0 ? this.filters.getAll('sku') : undefined,
        valuePrimitive: true,
        limit: 2
      })
    ];
  }
}
