import { Component, EventEmitter, Input, Output } from '@angular/core';

const ALPHA_CHANNEL_ENABLED: string = 'always';
const DEFAULT_COLOR_CODE: string = 'hex';

@Component({
  selector: 'app-color-picker',
  template: require('./color-picker.component.html'),
  styles: [require('./color-picker.component.scss')]
})
export class ColorPickerComponent {
  @Input()
  public colorCode?: string = DEFAULT_COLOR_CODE;

  @Input()
  public property: any;

  @Output()
  public setColorValue: EventEmitter<any> = new EventEmitter();

  public alphaChannel: string = ALPHA_CHANNEL_ENABLED;

  /**
   * Emits event to sets color to property
   */
  public setColor(e: string): void {
    this.setColorValue.emit(e);
  }
}
