import { Resource } from '@decorators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OrderManagerResource } from '../order-manager.resource';
import { OrderManagerListLightModel } from '../models/order-manager-list-light.model';

@Injectable()
@Resource({
  routeName: 'order_manager_light',
  entryPoint: '/v2/orders/external',
  isHydra: true,
  cGetDefaultFilters: () => ({
    page: 1,
    byPage: 30,
    order: 'DESC',
    orderType: 'all',
  }),
  translationKey: 'ORDER_MANAGER',
  deleteAvailable: false,
  updateAvailable: false,
  createAvailable: false,
  listModel: OrderManagerListLightModel,
})
export class OrderManagerLightResource extends OrderManagerResource {
  constructor(http: HttpClient) {
    super(http);
  }
}
