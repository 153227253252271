import { NgModule } from '@angular/core';
import { TabManagementComponent } from '@components/generic/tab-management/tab-management.component';
import { TabManagementTranslationsComponent } from '@components/generic/tab-management/tab-management-translations.component';
import { TabManagementUploadComponent } from '@components/generic/tab-management/tab-management-upload.component';
import { SwitchModule } from '@progress/kendo-angular-inputs';
import { UploadModule } from '@progress/kendo-angular-upload';
import { BsDatepickerModule, TabsModule, TimepickerModule } from 'ngx-bootstrap';
import { GenericModule } from './';
import { GenericFormModule } from './';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComboBoxModule, DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { EnhancedFieldsModule } from '@directives/enhanced-fields.module';
import { TabImagesResource } from '@components/tab/tab-images.resource';
import { EditorModule } from '@tinymce/tinymce-angular';
import { DirectivesModule } from '@directives/directives.module';
import { PipesModule } from '../../pipes/pipes.module';

@NgModule({
  declarations: [
    TabManagementComponent,
    TabManagementTranslationsComponent,
    TabManagementUploadComponent,
  ],
  imports: [
    SwitchModule,
    UploadModule,
    TabsModule,
    GenericModule,
    CommonModule,
    ReactiveFormsModule,
    GenericFormModule,
    ComboBoxModule,
    DropDownsModule,
    EnhancedFieldsModule,
    BsDatepickerModule,
    EditorModule,
    DirectivesModule,
    PipesModule,
    TimepickerModule,
    FormsModule,
  ],
  exports: [
    TabManagementComponent,
    TabManagementTranslationsComponent,
  ],
  providers: [
    TabImagesResource,
  ]
})
export class TabManagementModule {}
