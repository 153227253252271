import { Component, Inject } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { AbstractResource } from '@resources/abstract.resource';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { DiscountResource } from '@components/discount/resources/discount.resource';
import { DiscountFiltersFormService } from '@components/discount/list/services/discount-filters-form.service';

@Component({
  selector: 'app-discount-list',
  template: require('./discount-list.component.html'),
  providers: [
    { provide: AbstractResource, useClass: DiscountResource },
    { provide: AbstractFiltersFieldsService, useClass: DiscountFiltersFormService }
  ]
})
export class DiscountListComponent extends AbstractPageComponent {

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    @Inject('StateService') state: ng.ui.IStateService,
    authService: AuthService,
    resource: AbstractResource,
  ) {
    super($translate, authService, resource, state);
  }
}
