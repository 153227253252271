import { Component, Inject, Input } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { ProductResource } from '@components/product/product.resource';
import { IProductForm } from '@components/product/interfaces/product-form.interface';
import { AbstractComponent } from '@components/generic/abstract.component';

@Component({
  selector: 'app-product-carrier-form',
  template: require('./product-carrier-form.component.html'),
  providers: [
    { provide: AbstractResource, useClass: ProductResource },
  ]
})
export class ProductCarrierFormComponent extends AbstractComponent {

  @Input() model: IProductForm;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    resource: AbstractResource,
  ) {
    super($translate, authService, resource, state);
  }
}
