import { Injectable } from '@angular/core';

@Injectable()
export class UrlHelper {

  public getSplitUrl(): any {
    return window.location.href.split('?');
  }

  public getBaseUrl(): string {
    return this.getSplitUrl()[0];
  }

  public getParameters(): string {
    return this.getSplitUrl()[1];
  }
}
