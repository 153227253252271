import { Injectable } from '@angular/core';
import { Resource } from '@decorators/Resource';
import { AbstractResource } from '@resources/abstract.resource';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

@Injectable()
@Resource({
  entryPoint: '/v2/transactions',
  cGetDefaultFilters: () => ({
    operation: 'debit',
    archived: false
  })
})
export class TransactionResource extends AbstractResource {

  constructor(http: HttpClient) {
    super(http);
  }
}
