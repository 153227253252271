import { IAttributeFilterable } from '@components/attribute-set/models/attributes-set.interface';

export class AttributeFilterableModel implements IAttributeFilterable {
  public id: number;
  public attributeId: number;
  public countryId: number;
  public filterable: boolean;

  constructor(attributeFilterable: IAttributeFilterable) {
    this.id = attributeFilterable.id;
    this.attributeId = attributeFilterable.attributeId;
    this.countryId = attributeFilterable.countryId;
    this.filterable = attributeFilterable.filterable;
  }
}
