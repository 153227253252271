import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@services';
import { WarehouseCarrierGroupAgenciesResource } from './warehouse-carrier-group-agencies.resource';
import { SnackbarService } from '@components';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { IWarehouseCarrierGroupAgencies } from '@components/warehouses/models/warehouse-carrier-group-agencies.interface';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { Observable } from 'rxjs/Observable';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { WarehousesResource } from './warehouses.resource';
import { AbstractResource } from '@resources';

@Component({
  selector: 'app-warehouses-edit',
  template: require('./warehouses-edit.component.html'),
  styles: [require('./warehouses-edit.component.scss')],
  providers: [
    { provide: AbstractResource, useClass: WarehouseCarrierGroupAgenciesResource },
  ]
})

export class WarehousesEditComponent extends AbstractPageComponent implements OnInit {

  public warehouseCarrierGroupAgencies: IWarehouseCarrierGroupAgencies[] = [];
  public form: FormGroup;
  public generalForm: FormGroup;
  public warehouse: any;
  public boxForms: FormGroup[] = [];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    private warehousesResource: WarehousesResource,
    @Inject('StateService') state: ng.ui.IStateService,
    @Inject('DialogService') private dialog: any,
    private snackbar: SnackbarService,
    private formBuilder: FormBuilder,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    this.fetchWarehouse();
  }

  public fetchWarehouse() {
    this.warehousesResource.get(this.state.params.id).subscribe(response => {
      this.warehouse = response;

      this.pageTitle = this.translate('PAGE.WAREHOUSES.EDIT.TITLE', { id: this.warehouse.code });

      if (this.isGeneralFormMode()) {
        this.buildGeneralForm();

        for (const box of this.warehouse.boxes) {
          this.buildBoxForm(box);
        }

        this.buildBoxForm({});
      } else {
        this.fetch();
      }
    });
  }

  public buildGeneralForm() {
    this.generalForm = new FormGroup({
      acceptSparePart: new FormControl(this.warehouse.acceptSparePart),
      clustering: new FormControl(this.warehouse.clustering),
      capacityExportEnabled: new FormControl(this.warehouse.capacityExportEnabled),
    });
  }

  public fetch(): void {
    this.resource.getMany({ 'warehouse.code': this.warehouse.code })
      .takeUntil(this.destroyed$)
      .subscribe((result: any) => {
        this.warehouseCarrierGroupAgencies = result;

        this.form = new FormGroup({
          warehouseCarrierGroupAgencies: new FormArray([])
        });

        const formControls = this.form.get('warehouseCarrierGroupAgencies') as FormArray;
        this.warehouseCarrierGroupAgencies.forEach((warehouseCarrierGroupAgency: IWarehouseCarrierGroupAgencies) => {
          formControls.push(new FormGroup({
            warehouseId: new FormControl(warehouseCarrierGroupAgency.warehouse.id),
            agencyId: new FormControl(warehouseCarrierGroupAgency.carrierGroupAgency.id),
            directInjection: new FormControl(warehouseCarrierGroupAgency.directInjection)
          }));
        });
      });
  }

  public submit(): void {
    if (this.isGeneralFormMode()) {
      const generalFormData = this.generalForm.value;
      const body = {
        acceptSparePart: generalFormData.acceptSparePart,
        capacityExportEnabled: generalFormData.capacityExportEnabled,
      };
      this.warehousesResource.update(this.warehouse.id, body).subscribe(
        () => {
          this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
        }
      );
    } else {
      const formControls = this.form.get('warehouseCarrierGroupAgencies') as FormArray;
      const requests: Observable<any>[] = [];

      formControls.controls.forEach((control: FormGroup) => {
        if (control.touched) {
          requests.push(
            this.resource.update(
              `warehouse=${control.get('warehouseId').value};carrierGroupAgency=${control.get('agencyId').value}`,
              { directInjection: control.get('directInjection').value }
            )
          );
        }
      });

      if (requests.length === 0) {
        this.snackbar.warn(this.translate('ALERTS.NO_CHANGE.FORM'));
        return;
      }

      forkJoin(...requests)
        .subscribe(
          () => {
            this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
            this.actions.update.go();
          });
    }
  }

  public submitBox(boxForm: any): void {
    const body = {
      warehouse: this.warehouse['@id'],
      length: +boxForm.value.length,
      width: +boxForm.value.width,
      height: +boxForm.value.height,
      letter: !!boxForm.value.letter,
    };

    let observable;

    if (boxForm.value.id) {
      observable = this.warehousesResource.updateBox(boxForm.value.id, body);
    } else {
      observable = this.warehousesResource.createBox(body);
    }

    observable.subscribe(() => {
      this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
      this.state.go(this.state.current, this.state.params, { reload: true });
    });
  }

  public deleteBox(boxForm: any): void {
    this.warehousesResource.deleteBox(boxForm.value.id).subscribe(() => {
      this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
      this.state.go(this.state.current, this.state.params, { reload: true });
    });
  }

  public getDirectInjectionForm(agencyCode: string): FormGroup {
    const formArray: FormArray = this.form.get('warehouseCarrierGroupAgencies') as FormArray;
    return formArray.controls.find((group: FormGroup) => agencyCode === group.get('agencyId').value) as FormGroup;
  }

  public cancel() {
    this.dialog.confirm(this.translate('PAGE.WAREHOUSES.CONFIRM.BACK_TO_LIST'))
      .then(() => this.actions.list.go())
      ;
  }


  public boxFormHeader(boxForm: any) {
    // Gets an array `boxSizes` [length, width, height] with only numeric values or if null => 0
    const boxSizes: number[] = [boxForm.value.length, boxForm.value.width, boxForm.value.height].map((size: number) => size || 0);
    const boxType: string = boxForm.value.letter ? 'Letter' : 'Box';

    // Check if any of `dimension` values is greater than 0 then "<type> <length>x<width>x<height>" otherwise "<type>"
    return boxSizes.reduce((current: number, total: number) => current + total) > 0 ? boxType + ' ' + boxSizes.join('x') : boxType;
  }

  isGeneralFormMode() {
    return this.state.current.name === 'warehouses.edit';
  }

  private buildBoxForm(box?: any): void {
    this.boxForms.push(
      this.formBuilder.group({
        id: box.id,
        length: box.length,
        width: box.width,
        height: box.height,
        letter: box.letter,
      })
    );
  }
}
