import { Injectable, Inject } from '@angular/core';
import { AbstractFormFieldBase } from '@components/generic/Form/dynamic/fields/abstract-form-field-base.class';
import { TextField, MultiSearchField, DateField } from '@components/generic/Form/dynamic/fields';
import { CommercialEntityResource, MarketplaceResource } from '@resources';
import { FormNotifierService } from '@services';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { IMarketplace } from '@interfaces/marketplace.interface';
import { ICommercialEntity } from '@interfaces/commercial-entity.interface';
import { DATE_FULL_FORMAT } from '@constants/date';
import { Subscription } from 'rxjs/Subscription';
import { SessionHelper } from '@helpers';
import { DropDownListField } from '@components/generic/Form/dynamic/fields/select-field.class';
import { IDropdownData } from '@interfaces/IDropdownData';
import * as moment from 'moment';

@Injectable()
export class TransactionsFiltersFormService extends AbstractFiltersFieldsService {

  public readonly refundStatuses = [
    { label: this.$translate.instant('PAGE.REFUNDS.LIST.STATUSES.WAITING'), value: 'waiting' },
    { label: this.$translate.instant('PAGE.REFUNDS.LIST.STATUSES.WAITING_ELEMENTS'), value: 'elements_waiting' },
    { label: this.$translate.instant('PAGE.REFUNDS.LIST.STATUSES.WAITING_SAGE'), value: 'sage_waiting' },
    { label: this.$translate.instant('PAGE.REFUNDS.LIST.STATUSES.CLOSE'), value: 'close' },
    { label: this.$translate.instant('PAGE.REFUNDS.LIST.STATUSES.ERROR'), value: 'error' },
    { label: this.$translate.instant('PAGE.REFUNDS.LIST.STATUSES.TO_CHECK'), value: 'to_check' },
    { label: this.$translate.instant('PAGE.REFUNDS.LIST.STATUSES.REQUESTED'), value: 'requested' },
  ];

  public readonly providerStatuses = [
    { label: this.$translate.instant('PAGE.REFUNDS.LIST.TRANSACTION_PROVIDER_STATUSES.WAITING'), value: 'waiting' },
    { label: this.$translate.instant('PAGE.REFUNDS.LIST.TRANSACTION_PROVIDER_STATUSES.REFUSED'), value: 'refused' },
    { label: this.$translate.instant('PAGE.REFUNDS.LIST.TRANSACTION_PROVIDER_STATUSES.VALID'), value: 'valid' },
  ];

  private dateStartField: TextField = new TextField({
    fieldName: 'dateStart',
    value: null,
    hidden: true
  });
  private hipayPaymentProductField: DropDownListField = new DropDownListField({
    fieldName: 'hipayPaymentProduct',
    label: 'PAGE.ORDER.LIST.FILTER.HIPAY_PAYMENT_PRODUCT.LABEL',
    // See: https://github.com/hipay/hipay-fullservice-sdk-php/tree/2.9.2/lib/HiPay/Fullservice/PaymentConfigFiles
    data: [
      {
        value: '',
        label: '',
      },
      {
        value: 'american-express',
        label: 'American Express'
      },
      {
        value: 'bcmc',
        label: 'Bancontact / Mister Cash'
      },
      {
        value: 'cb',
        label: 'Carte Bancaire'
      },
      {
        value: 'maestro',
        label: 'Maestro'
      },
      {
        value: 'mastercard',
        label: 'MasterCard'
      },
      {
        value: 'multibanco',
        label: 'Multibanco'
      },
      {
        value: 'visa',
        label: 'Visa'
      }
    ],
    textField: 'label',
    valueField: 'value',
    value: this.filters.get('hipayPaymentProduct') ? this.filters.get('hipayPaymentProduct') : null,
    valuePrimitive: true,
    readonly: false,
    hidden: this.filters.get('paymentType') !== 'credit_card'
  });
  private paymentTypesOptions = [
    { label: '', value: '' },
    ...SessionHelper.getCountry().paymentTypes.map((key) => {
      return { label: this.$translate.instant(`DATA.PAYMENT_TYPES.${key.toUpperCase()}`), value: key };
    })
  ];
  private dateEndField: TextField = new TextField({
    fieldName: 'dateEnd',
    value: null,
    hidden: true
  });

  /** Defined here to keep a reference to data in order to change it dynamically*/
  public marketplacesField: MultiSearchField = new MultiSearchField({
    fieldName: 'order.marketplace[]',
    label: 'PAGE.REFUNDS.LIST.FILTERS.MP',
    data: null,
    textField: 'commercialName',
    valueField: 'code',
    value: null,
  });

  public statusesField: MultiSearchField = new MultiSearchField({
    fieldName: 'status[]',
    label: 'PAGE.REFUNDS.LIST.FILTERS.REFUND_STATUSES',
    data: this.refundStatuses,
    textField: 'label',
    valueField: 'value',
    value: null,
  });

  public providerStatusesField: MultiSearchField = new MultiSearchField({
    fieldName: 'providerStatus[]',
    label: 'PAGE.REFUNDS.LIST.FILTERS.TRANSACTION_PROVIDER_STATUSES',
    data: this.providerStatuses,
    textField: 'label',
    valueField: 'value',
    value: null,
  });

  private countryCodeField: MultiSearchField = new MultiSearchField({
    fieldName: 'order.billingAddress.countryCode[]',
    label: 'PAGE.ORDER.LIST.FILTER.COUNTRY.LABEL',
    data: SessionHelper.getCountries(),
    textField: 'name',
    valueField: 'code',
    value: null,
  });

  private commercialEntityField: DropDownListField = new DropDownListField({
    fieldName: 'transactionCommercialEntity',
    label: 'PAGE.ORDER.LIST.FILTER.COMMERCIAL_ENTITY.LABEL',
    data: [],
    textField: 'label',
    valueField: 'value',
    value: this.filters.get('transactionCommercialEntity') ?
      this.filters.get('transactionCommercialEntity') : SessionHelper.getCountry().commercialEntity.id.toString(),
    valuePrimitive: true,
  });

  private paymentField: DropDownListField = new DropDownListField({
    fieldName: 'paymentType',
    label: 'PAGE.REFUNDS.LIST.FILTERS.PAYMENT_TYPE',
    data: this.paymentTypesOptions,
    textField: 'label',
    valueField: 'value',
    value: this.filters.get('paymentType') ? this.filters.get('paymentType') : null,
    valuePrimitive: true,
    readonly: false,
    valueChangedAction: this.showHipayPaymentProduct.bind(this)
  });

  private static setDates(newValue: string, startField: TextField, endField: TextField): void {
    if (!newValue || null === newValue[0]) {
      return;
    }

    startField.value = moment(newValue[0]).startOf('day').format(DATE_FULL_FORMAT);
    endField.value = moment(newValue[1]).endOf('day').format(DATE_FULL_FORMAT);
  }
  constructor(
    private marketplaceResource: MarketplaceResource,
    private commercialEntityResource: CommercialEntityResource,
    @Inject('StateService') protected state: ng.ui.IStateService,
    @Inject('TranslationService') public $translate: ng.translate.ITranslateService,
    formNotifier: FormNotifierService
  ) {
    super(formNotifier, state);

    this.fetchMarketplaces();
    this.fetchCommercialEntities();
    if (this.filters.getAll('status[]').length !== 0) {
      this.updateStatuses(this.filters.getAll('status[]'));
    }
    if (this.filters.getAll('providerStatus[]').length !== 0) {
      this.updateProviderStatuses(this.filters.getAll('providerStatus[]'));
    }
  }

  public fetchMarketplaces(): void {
    const subscriber: Subscription = this.marketplaceResource.cGet()
      .subscribe((marketplaces: IMarketplace[]) => {
        subscriber.unsubscribe();

        this.marketplacesField.data.push(...marketplaces);

        if (this.filters.getAll('order.marketplace[]').length !== 0) {
          this.updateMarketplaces(this.filters.getAll('order.marketplace[]'));
        }
      })
    ;
  }

  public fetchCommercialEntities(): void {
    const subscriber: Subscription = this.commercialEntityResource.cGet(null, { returnHydraMembers: true })
      .subscribe((commercialEntities: ICommercialEntity[]) => {
        subscriber.unsubscribe();

        const formatted: IDropdownData[] = commercialEntities.map((commercialEntity: ICommercialEntity) => {
          return { label: commercialEntity.label, value: commercialEntity.id.toString() };
        });

        this.commercialEntityField.data = formatted;
      });
  }

  public updateMarketplaces(newValues: any[]) {
    if (0 < newValues.length) {
      this.marketplacesField.value = [];

      const itemsAvailable: any = [];
      this.marketplacesField.data.forEach((marketplace: IMarketplace) => {
        if (newValues.includes(marketplace.code)) {
          itemsAvailable.push(marketplace.code);
        }
      });

      this.marketplacesField.value.push(...itemsAvailable);
    }
  }

  public updateStatuses(newValues: any[]) {
    if (0 < newValues.length) {
      this.statusesField.value = [];

      const itemsAvailable: any = [];
      this.statusesField.data.forEach((status: { label: string, value: string }) => {
        if (newValues.includes(status.value)) {
          itemsAvailable.push(status.value);
        }
      });

      this.statusesField.value.push(...itemsAvailable);
    }
  }

  public updateProviderStatuses(newValues: any[]) {
    if (0 < newValues.length) {
      this.providerStatusesField.value = [];

      const itemsAvailable: any = [];
      this.providerStatusesField.data.forEach((providerStatus: { label: string, value: string }) => {
        if (newValues.includes(providerStatus.value)) {
          itemsAvailable.push(providerStatus.value);
        }
      });

      this.providerStatusesField.value.push(...itemsAvailable);
    }
  }

  public getFields(): AbstractFormFieldBase<any>[] {
    const dateStart = this.filters.get('dateStart');
    const dateEnd = this.filters.get('dateEnd');

    if (dateStart && dateEnd) {
      this.dateStartField.value = dateStart;
      this.dateEndField.value = dateEnd;
    }

    return [
      new TextField({
        fieldName: 'order.orderId',
        label: 'PAGE.REFUNDS.LIST.FILTERS.ORDER_NUMBER',
        value: this.filters.get('order.orderId') ? this.filters.get('order.orderId') : undefined
      }),

      new TextField({
        fieldName: 'order.id',
        label: 'PAGE.REFUNDS.LIST.FILTERS.ORDER_ID',
        value: this.filters.get('order.id') ? this.filters.get('order.id') : undefined
      }),

      new DateField({
        label: 'PAGE.REFUNDS.LIST.FILTERS.REFUND_CREATION_DATE',
        fieldName: 'date',
        dateRange: true,
        value: dateStart && dateEnd ? [new Date(dateStart), new Date(dateEnd)] : undefined,
        valueChangedAction: this.setDateValue.bind(this),
      }),

      this.dateStartField,
      this.dateEndField,
      this.paymentField,
      this.hipayPaymentProductField,
      this.marketplacesField,
      this.statusesField,
      this.providerStatusesField,
      this.countryCodeField,
      this.commercialEntityField,
    ];
  }

  private setDateValue(newValue: string): void {
    TransactionsFiltersFormService.setDates(newValue, this.dateStartField, this.dateEndField);
  }
  private showHipayPaymentProduct(value: string): void {
    const show = value === 'credit_card';
    this.hipayPaymentProductField.shouldBeDisplayed = this.paymentField.shouldBeDisplayed && show;
    this.hipayPaymentProductField.hidden = !show;
  }
}
