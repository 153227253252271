import { Inject, Injectable } from '@angular/core';
import { FormNotifierService } from '@services/form-notifier.service';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { AbstractFormFieldBase } from '@components/generic/Form/dynamic/fields/abstract-form-field-base.class';
import {
  DateField,
  MultiSearchField,
  SkuSearchField,
  TextField
} from '@components/generic/Form/dynamic/fields';
import * as moment from 'moment';
import { CODE_LU, DATE_FULL_FORMAT } from '@constants';
import { CODE_BE, CODE_ES, CODE_FR, CODE_GB, CODE_IT, CODE_MC, CODE_NL, CODE_PT, CODE_DE, CODE_PL } from '@constants';
import {SessionHelper, TranslationHelper} from '@helpers';
import { DropDownListField } from '@components/generic/Form/dynamic/fields/select-field.class';
import { DelayReasonResource } from '@components/order-manager/delay-reason/delay-reason.resource';

interface IDropdownData {
  label: string;
  value: string;
}

@Injectable()
export class DelayReasonOrderListService extends AbstractFiltersFieldsService {

  COUNTRIES = [
    { label: 'France', value: CODE_FR },
    { label: 'Belgique', value: CODE_BE },
    { label: 'Espagne', value: CODE_ES },
    { label: 'Belgique', value: CODE_BE },
    { label: 'Angleterre', value: CODE_GB },
    { label: 'Pays-Bas', value: CODE_NL },
    { label: 'Portugal', value: CODE_PT },
    { label: 'Italie', value: CODE_IT },
    { label: 'Allemagne', value: CODE_DE},
    { label: 'Luxembourg', value: CODE_LU},
    { label: 'Pologne', value: CODE_PL}
  ];

  private dateStartField: TextField = new TextField({
    fieldName: 'dateStart',
    value: null,
    hidden: true
  });

  private dateEndField: TextField = new TextField({
    fieldName: 'dateEnd',
    value: null,
    hidden: true
  });

  private etdStartField: TextField = new TextField({
    fieldName: 'etdStart',
    value: null,
    hidden: true
  });

  private etdEndField: TextField = new TextField({
    fieldName: 'etdEnd',
    value: null,
    hidden: true
  });

  private countryCodeField: MultiSearchField = new MultiSearchField({
    fieldName: 'countries[]',
    label: 'PAGE.ORDER.LIST.FILTER.COUNTRY.LABEL',
    data: null,
    textField: 'label',
    valueField: 'value',
    value: null,
    defaultItem: { label: this.translate.instant('PAGE.ORDER.LIST.FILTER.COUNTRY.DATA.0'), value: null },
    valuePrimitive: true,
    readonly: false,
  });

  private delayReasonField: DropDownListField = new DropDownListField({
    fieldName: 'delayReason',
    label: 'PAGE.ORDER_MANAGER.LIST.FILTER.DELAY_REASON',
    data: null,
    textField: 'label',
    valueField: 'value',
    value: this.filters.get('delayReason') ? this.filters.get('delayReason') : null,
    defaultItem: { label: '', value: null },
    valuePrimitive: true,
  });

  private static setDates(newValue: string, startField: TextField, endField: TextField): void {
    if (!newValue || null === newValue[0]) {
      return;
    }

    startField.value = moment(newValue[0]).startOf('day').format(DATE_FULL_FORMAT);
    endField.value = moment(newValue[1]).endOf('day').format(DATE_FULL_FORMAT);
  }

  constructor(
    @Inject('StateService') protected state: ng.ui.IStateService,
    @Inject('TranslationService') public translate: ng.translate.ITranslateService,
    formNotifier: FormNotifierService,
    private delayReasonResource: DelayReasonResource,
  ) {
    super(formNotifier, state);

    this.delayReasonResource.cGet(null, { isHydra: true, returnHydraMembers: true })
      .takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        for (const reason of response) {
          const translation = TranslationHelper.getTranslation(reason, SessionHelper.getLocale());
          this.delayReasonField.data.push({
            label: translation ? translation.label : reason.code,
            value: reason.code
          });
        }
      })
    ;
  }

  public getFields(): AbstractFormFieldBase<any>[] {
    const stockStatusOptions = [
      { label: this.translate.instant('PAGE.DELAY_REASON_ORDER_LIST.PRE_ORDER'), value: 'pre_order' },
    ];

    const dateStart = this.filters.get('dateStart');
    const dateEnd = this.filters.get('dateEnd');

    if (dateStart && dateEnd) {
      this.dateStartField.value = dateStart;
      this.dateEndField.value = dateEnd;
    }

    const etdStart = this.filters.get('etdStart');
    const etdEnd = this.filters.get('etdEnd');

    if (etdStart && etdEnd) {
      this.etdStartField.value = etdStart;
      this.etdEndField.value = etdEnd;
    }

    const countriesList: IDropdownData[] = [...this.COUNTRIES];
    this.countryCodeField.value = 0 < this.filters.getAll('countries[]').length ? this.filters.getAll('countries[]') : undefined;
    this.countryCodeField.data = countriesList;

    const indicatorStatusesOptions = [
      { label: this.translate.instant('PAGE.ORDER_MANAGER.LIST.FILTER.INDICATOR_STATUS.NONE'), value: 'none' },
      { label: this.translate.instant('PAGE.ORDER_MANAGER.LIST.FILTER.INDICATOR_STATUS.RED'), value: 'red' },
      { label: this.translate.instant('PAGE.ORDER_MANAGER.LIST.FILTER.INDICATOR_STATUS.ORANGE'), value: 'orange' },
      { label: this.translate.instant('PAGE.ORDER_MANAGER.LIST.FILTER.INDICATOR_STATUS.GREEN'), value: 'green' },
    ];

    const marketplacesOptions = [
      { label: this.translate.instant('PAGE.ORDER_MANAGER.LIST.FILTER.MARKETPLACE.WEBSITE'), value: 'website' },
      { label: this.translate.instant('PAGE.ORDER_MANAGER.LIST.FILTER.MARKETPLACE.MARKETPLACE'), value: 'marketplace' },
    ];

    return [
      new SkuSearchField({
        fieldName: 'skus[]',
        label: 'PAGE.ORDER.LIST.FILTER.SKU.LABEL',
        productType: 'product',
        value: 0 < this.filters.getAll('skus[]').length ? this.filters.getAll('skus[]') : undefined,
        valueField: 'sku',
        valuePrimitive: true
      }),
      new DateField({
        label: 'PAGE.ORDER.LIST.FILTER.ORDER_DATE.LABEL',
        fieldName: 'date',
        dateRange: true,
        value: dateStart && dateEnd ? [new Date(dateStart), new Date(dateEnd)] : undefined,
        valueChangedAction: this.setDateValue.bind(this),
      }),
      this.dateStartField,
      this.dateEndField,
      new DateField({
        label: 'PAGE.ORDER.LIST.FILTER.ETD.LABEL',
        fieldName: 'etd',
        dateRange: true,
        value: etdStart && etdEnd ? [new Date(etdStart), new Date(etdEnd)] : undefined,
        valueChangedAction: this.setEtdValue.bind(this),
      }),
      this.etdStartField,
      this.etdEndField,
      this.countryCodeField,
      new MultiSearchField({
        fieldName: 'orderManagerStatuses[]',
        label: 'PAGE.ORDER.LIST.FILTER.STATUSES.LABEL',
        data: SessionHelper.getOrderManagerStatuses().map((status) => {
          return {
            label: this.translate.instant(`DATA.ORDER_MANAGER_STATUS.${status.toUpperCase()}`),
            value: status,
          };
        }),
        textField: 'label',
        valueField: 'value',
        value: 0 < this.filters.getAll('orderManagerStatuses[]').length ? this.filters.getAll('orderManagerStatuses[]') : ['accepted'],
        valuePrimitive: true,
      }),
      new DropDownListField({
        fieldName: 'indicatorStatuses[deliverable]',
        label: 'PAGE.ORDER_MANAGER.LIST.FILTER.DELIVERABLE',
        data: indicatorStatusesOptions,
        textField: 'label',
        valueField: 'value',
        value: this.filters.get('indicatorStatuses[deliverable]') ? this.filters.get('indicatorStatuses[deliverable]') : null,
        defaultItem: { label: '', value: null },
        valuePrimitive: true,
      }),
      new DropDownListField({
        fieldName: 'marketplaceOrigin',
        label: 'PAGE.ORDER_MANAGER.LIST.FILTER.MARKETPLACE',
        data: marketplacesOptions,
        textField: 'label',
        valueField: 'value',
        value: this.filters.get('marketplaceOrigin') ? this.filters.get('marketplaceOrigin') : null,
        defaultItem: { label: '', value: null },
        valuePrimitive: true,
      }),
      this.delayReasonField
    ];
  }

  getComplementaryFields(): AbstractFormFieldBase<any>[] {
    return [];
  }

  private setDateValue(newValue: string): void {
    DelayReasonOrderListService.setDates(newValue, this.dateStartField, this.dateEndField);
  }

  private setEtdValue(newValue: string): void {
    DelayReasonOrderListService.setDates(newValue, this.etdStartField, this.etdEndField);
  }
}
