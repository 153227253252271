import { Injectable } from '@angular/core';
import { Resource } from '@decorators/Resource';
import { AbstractResource } from '../../resources/abstract.resource';
import { SessionHelper } from '@helpers/session.helper';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  routeName: 'categories-marketplace',
  entryPoint: '/categorymarketplaces',
  translationKey: 'CATEGORIES_MARKETPLACE',
  cGetDefaultFilters: () => ({
    'country[]': SessionHelper.getCountry().code,
    active: 1,
    deprecated: 0,
    leaf: 1,
    page: 1,
    byPage: 30,
  }),
  isHydra: false,
})
export class CategoriesMarketplaceResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }
}
