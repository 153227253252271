'use strict'

import { SessionHelper } from '@helpers'

angular
  .module 'ammsFrontendApp'
  .filter 'carrierName', [ ->
    (carrierCode) ->
      if not carrierCode?
        return ''

      carrierGroups = SessionHelper.getCarrierGroups()

      if carrierGroups? && 0 != _.size carrierGroups
        for carrierGroup in carrierGroups
          if carrierGroup.code is carrierCode
            return carrierGroup.name

      ''
  ]
