import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output, SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { AbstractResource } from '@resources/abstract.resource';
import { AuthService } from '@services/auth.service';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { IMarketplace as IProductMarketplace, IProduct, IProductMarketplaces } from '@components/product/interfaces';
import { IMarketplace } from '@interfaces/marketplace.interface';
import { ProductMarketplaceHelper } from '@helpers/ProductMarketplaceHelper';
import { IColumnSortedEvent } from '@components/generic/List/services/sort.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import moment = require('moment');
import { ProductResource } from '@components/product/product.resource';
import { takeUntil } from 'rxjs/operators';
import { DATE_FULL_FORMAT, DATE_SHORT_FORMAT } from '@constants/date';
import { ProductMarketplaceResource } from '@components/product/product-marketplace.resource';
import { SnackbarService } from '@components/snackbar';
import { INPUT_NUMBER_PATTERN_DEC, INPUT_NUMBER_PATTERN_NODEC } from '@constants/form';
import { SessionHelper } from '@helpers/session.helper';
import { ArrayHelper } from '@helpers';

@Component({
  selector: 'app-product-result-list',
  template: require('./product-result-list.component.html'),
  styles: [require('./product-result-list.component.scss')],
  encapsulation: ViewEncapsulation.None,
})
export class ProductResultListComponent extends AbstractPageComponent implements OnInit, OnChanges {

  @Input() private items: IProduct[];
  @Input() private marketplaces: IMarketplace[];

  @Input() private marketplacesNotToSelect: string[];

  @Output() public onSortList: EventEmitter<any> = new EventEmitter();
  @Output() public onSelectProduct: EventEmitter<any> = new EventEmitter();
  @Output() public onSubmitForm: EventEmitter<any> = new EventEmitter();

  private form: FormGroup;
  private untouchedItems: IProduct[];
  public checkAll: boolean = false;
  public bulkEditorHeight: number = 0;
  public seeMoreArrivals: boolean[] = [];
  public maxSeeMoreArrivals: number = 2;

  private margin: number = null;
  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    @Inject('DialogService') private dialog: any,
    private productMarketplaceResource: ProductMarketplaceResource,
    private snackbar: SnackbarService,
    private elementRef: ElementRef
  ) {
    super($translate, authService, resource, state);
  }

  public ngOnInit(): void {
    this.untouchedItems = [];
    this.items = this.items.map((item: IProduct) => {
      item.brotherProductMarketplaces = this.populateProduct(item);
      item.inEdition = false;
      this.untouchedItems.push(JSON.parse(JSON.stringify({...item})));
      return item;
    });
  }

  public arrivalsOnlyLast(arrivals: any[], warehouse: string|null = null) {
    const lastArrivals: any = [];

    for (const warehouseArrival in arrivals) {
      if (null !== warehouse && warehouseArrival !== warehouse) {
        continue;
      }

      if (0 === arrivals[warehouseArrival].length) {
        continue;
      }

      if (null === warehouse) {
        lastArrivals[warehouseArrival] = arrivals[warehouseArrival].filter((i: any) => i.last).sort((a: any, b: any) => {
          return new Date(a.date).getTime() - new Date(b.date).getTime();
        }) || [];

        if (0 === lastArrivals[warehouseArrival].length) {
          delete lastArrivals[warehouseArrival];
        }
      } else {
         return arrivals[warehouseArrival].filter((i: any) => i.last).sort((a: any, b: any) => {
           return new Date(a.date).getTime() - new Date(b.date).getTime();
         }) || [];
      }
    }

    return lastArrivals;
  }

  public hasArrival(arrivals: object): boolean {
    return Object.keys(arrivals).length >= 1;
  }

  public getArrivalsWarehouses(arrivals: any[]): any {
    return Object.keys(this.arrivalsOnlyLast(arrivals));
  }

  private populateProductMarketplaceData(product: IProduct, productMarketplaces: any): any {
    Object.keys(productMarketplaces).map((countryCode: string) => {
      const productMarketplace: IProductMarketplace = productMarketplaces[countryCode];

      this.populateProductMarketplaceTotal(productMarketplace);
      this.populateProductMarketplaceMargin(product, productMarketplace);
      this.populateProductMarketplaceShippinDate(productMarketplace);
    });

    return productMarketplaces;
  }

  private populateProductMarketplaceMargin(product: IProduct, productMarketplace: IProductMarketplace): number {
    const country = SessionHelper.getCountry();
    const divisor = 1 + +country.vat;
    const totalPriceDutyFree = +productMarketplace.affiliateTotal / divisor;

    let rate = 0;
    let fixedAmount = 0;

    if (product.superProduct && product.superProduct.catalog && product.superProduct.catalog.marketplaces[productMarketplace.marketplace]) {
      rate = product.superProduct.catalog.marketplaces[productMarketplace.marketplace].rate;
      fixedAmount = product.superProduct.catalog.marketplaces[productMarketplace.marketplace].fixedAmount;
    }

    let commission = 0;

    if ('debenhams' === productMarketplace.marketplace && product.superProduct.catalog.marketplaces[productMarketplace.marketplace]) {
        if (180 > totalPriceDutyFree) {
            rate = 24;
        }
    }

    if (0 < rate || 0 < fixedAmount) {
      commission = ((rate / 100) * totalPriceDutyFree) + fixedAmount;
    }

    if (divisor > 1) {
      productMarketplace.marginVolume = ((totalPriceDutyFree - (product.cmup + product.familyShippingCost + commission)) / totalPriceDutyFree) * 100 ;
    }

    return productMarketplace.marginVolume;
  }

  private populateProductMarketplaceTotal(productMarketplace: IProductMarketplace): void {
    if (productMarketplace.specialPrice) {
      productMarketplace.total = productMarketplace.affiliateTotal = +productMarketplace.specialPrice + +productMarketplace.shippingPrice;
    } else if (productMarketplace.currentOffer && productMarketplace.currentOffer.specialPrice) {
      productMarketplace.total = +productMarketplace.currentOffer.specialPrice + +productMarketplace.shippingPrice;
      productMarketplace.affiliateTotal = +productMarketplace.currentOffer.affiliateSpecialPrice + +productMarketplace.shippingPrice;
    } else {
      productMarketplace.total = +productMarketplace.basePrice + +productMarketplace.shippingPrice;
      productMarketplace.affiliateTotal = +productMarketplace.affiliateBasePrice + +productMarketplace.shippingPrice;
    }
  }

  private populateProductMarketplaceShippinDate(productMarketplace: IProductMarketplace) {
    productMarketplace.shippingDate = productMarketplace.shippingDate && moment(productMarketplace.shippingDate) >= moment()
      ? moment(productMarketplace.shippingDate).format(DATE_SHORT_FORMAT)
      : null;
  }

  private populateProduct(product: IProduct): IProductMarketplaces {
    return this.marketplaces.reduce((marketplaceList: {[code: string]: IMarketplace}, currentMarketplace: IMarketplace) => {
      if (product.brotherProductMarketplaces[currentMarketplace.code]) {
        marketplaceList[currentMarketplace.code] =
          this.populateProductMarketplaceData(product, product.brotherProductMarketplaces[currentMarketplace.code]);
      }

      return marketplaceList;
    }, {});
  }

  private isOffer(productMarketplace: IProductMarketplace): boolean {
    return !!productMarketplace && !!productMarketplace.currentOffer;
  }

  private showShippingPrice(productMarketplace: IProductMarketplace): boolean {
    if (!productMarketplace) {
      return;
    }

    const marketplace: IMarketplace = this.marketplaces.find((mkp: IMarketplace) => {
      return mkp.code === productMarketplace.marketplace;
    });

    if (!marketplace || !marketplace.parent) {
      return;
    }

    return marketplace.parent.code !== 'ebay' && marketplace.parent.code !== 'magento' && marketplace.parent.code !== 'website';
  }

  private hasStockAndShippingDate(product: IProduct, stock: number, isInPreorder: boolean): boolean {
    return ProductMarketplaceHelper.hasStockAndShippingDate(stock, isInPreorder);
  }

  private hasStocksWithoutShippingDate(product: IProduct, stock: number, isInPreorder: boolean): boolean {
    return !!product.sold && ProductMarketplaceHelper.hasStockWithoutShippingDate(stock, isInPreorder);
  }

  private isProductOutOfStockAndProductMarketplaceHasNoShippingDate(
    product: IProduct,
    stock: number,
    isInPreorder: boolean
  ): boolean {
    return !product.sold && ProductMarketplaceHelper.hasStockWithoutShippingDate(stock, isInPreorder);
  }

  private hasNoPMStock(active: boolean, stock: number): boolean {
    return active && !stock;
  }

  private goToEdit(id: number, $event: MouseEvent): void {
    const editionState = `${this.resource.routeName}.edit`;
    if (($event.button === 0 && $event.ctrlKey === true) || $event.button === 1 || $event.button === 4) {
      window.open(this.state.href(editionState, { id }, { absolute: true }), '_blank');
      return;
    } else if ($event.button === 0) {
      this.state.go(editionState, { id });
    }
  }

  private onSort(event: IColumnSortedEvent): void {
    this.onSortList.emit(event);
  }

  private onRowClick(id: number, event: any): void {
    const rowAlreadyInEdition: IProduct = this.items.find((item: IProduct) => item.inEdition);

    if (rowAlreadyInEdition || event.srcElement.type === 'button') {
      return;
    }

    const row: IProduct = this.items.find((item: IProduct) => item.id === id);
    row.inEdition = true;

    this.buildForm(id);
  }

  private buildForm(id: number): void {
    this.form = this.formBuilder.group({
      productMarketplaces: this.formBuilder.array([]),
    });

    const brotherProductMarketplaces: any = this.items.find((item: IProduct) => item.id === id).brotherProductMarketplaces;

    this.marketplaces.forEach((marketplace: IMarketplace) => {
      if (undefined === brotherProductMarketplaces[marketplace.code] || undefined === brotherProductMarketplaces[marketplace.code][marketplace.country.code]) {
        return;
      }

      const productMarketplace = brotherProductMarketplaces[marketplace.code][marketplace.country.code];

      (<FormArray>this.form.get('productMarketplaces'))
        .push(this.buildProductMarketplaceForm(productMarketplace, marketplace.code, id));
    });
  }

  private buildProductMarketplaceForm(productMarketplace: IProductMarketplace, code: string, id: number): FormGroup {
    return this.formBuilder.group({
      marketplace: [code],
      stock: [productMarketplace ? productMarketplace.stock : null, Validators.pattern(INPUT_NUMBER_PATTERN_NODEC)],
      basePrice: [productMarketplace ? productMarketplace.basePrice : null, Validators.pattern(INPUT_NUMBER_PATTERN_DEC)],
      shippingPrice: [productMarketplace ? productMarketplace.shippingPrice : null, Validators.pattern(INPUT_NUMBER_PATTERN_DEC)],
      shippingDate: [productMarketplace ? productMarketplace.shippingDate : null],
      specialPrice: [
        productMarketplace && productMarketplace.currentOffer
        ? productMarketplace.currentOffer.specialPrice
        : null, Validators.pattern(INPUT_NUMBER_PATTERN_DEC)
      ],
      product_id: productMarketplace.product ? productMarketplace.product.id : id,
      quantityForCut: [productMarketplace ? productMarketplace.quantityForCut : null, Validators.pattern(INPUT_NUMBER_PATTERN_NODEC)],
    });
  }

  private updatePrices(
    product: IProduct,
    productMarketplace: IProductMarketplace,
    marketplace: IMarketplace,
    basePrice: any,
    specialPrice: any
  ): void {
    productMarketplace.basePrice = basePrice;

    if (productMarketplace.currentOffer) {
      productMarketplace.currentOffer.specialPrice = specialPrice;
    }

    const productMarketplaceByCountry: any = {};
    productMarketplaceByCountry[marketplace.country.code] = productMarketplace;
    this.populateProductMarketplaceData(product, productMarketplaceByCountry);
    this.margin = this.populateProductMarketplaceMargin(product, productMarketplace);
  }

  public updateShippingPrice(product: IProduct, productMarketplace: IProductMarketplace, newShippingPrice: any) {
    productMarketplace.shippingPrice = parseFloat(newShippingPrice) || 0;
    this.populateProductMarketplaceTotal(productMarketplace);
  }

  public updateQuantityForCut(product: IProduct, productMarketplace: IProductMarketplace, newQuantityForCut: any) {
    productMarketplace.quantityForCut = parseInt(newQuantityForCut, 10) || 0;
  }

  private submit(id: number): void {
    if (this.margin < 0) {
      this.snackbar.warn(this.translate('ALERTS.NEGATIVE.MARGIN'));
    }

    (<ProductResource>this.resource).batchUpdateV2(this.prepareBody())
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
        this.items.find((item: IProduct) => item.id === id).inEdition = false;

        this.onSubmitForm.emit();
      });
  }

  private prepareBody(): any {
    const productIds = this.form.value.productMarketplaces.map((productMarketplace: any) => productMarketplace.product_id);
    const uniqueProductIds = ArrayHelper.unique(productIds);

    const body: any = {};
    uniqueProductIds.map((productId: number) => {
      const productMarketplaces = this.form.value.productMarketplaces.filter((productMarketplace: any) => productMarketplace.product_id === productId);
      const productMarketplacesBodyNew: any = {};

      productMarketplaces.map((productMarketplace: any) => {
        productMarketplacesBodyNew[productMarketplace.marketplace] = {
          stock: typeof productMarketplace.stock === 'string' ? parseInt(productMarketplace.stock, 10) : productMarketplace.stock,
          basePrice: typeof productMarketplace.basePrice === 'string' ?
            parseFloat(productMarketplace.basePrice.replace(',', '.')) : productMarketplace.basePrice,
          currentOffer: productMarketplace.specialPrice ? {
            specialPrice: typeof productMarketplace.specialPrice === 'string' ?
              parseFloat(productMarketplace.specialPrice.replace(',', '.')) : productMarketplace.specialPrice,
          } : null,
          shippingPrice: typeof productMarketplace.shippingPrice === 'string' ?
            parseFloat(productMarketplace.shippingPrice.replace(',', '.')) : productMarketplace.shippingPrice,
          shippingDate: productMarketplace.shippingDate ? moment(productMarketplace.shippingDate, DATE_SHORT_FORMAT).format(DATE_FULL_FORMAT) : null,
          quantityForCut: typeof productMarketplace.quantityForCut === 'string' ?
            parseInt(productMarketplace.quantityForCut, 10) : productMarketplace.quantityForCut,
        };

        const productMarketplaceWithOffers = this.getProductMarketplace(
          this.items.find((product: any) => product.id === productMarketplace.product_id),
          productMarketplace.marketplace,
          this.marketplaces.find((marketplace: any) => marketplace.code === productMarketplace.marketplace).country.code
        );

        if (this.isLockedOffer(productMarketplaceWithOffers)) {
          delete productMarketplacesBodyNew[productMarketplace.marketplace]['currentOffer'];
        }
      });

      body[productId] = {
        productMarketplaces: productMarketplacesBodyNew,
      };
    });

    return body;
  }

  private cancel(id: number): void {
    this.dialog.confirm(this.translate('DIALOG.TEXT.DONT_SAVE')).then(() => {
      this.form.reset();
      this.items.find((item: IProduct) => item.id === id).inEdition = false;
      this.items = [];
      this.untouchedItems.map((item: IProduct) => this.items.push(JSON.parse(JSON.stringify({...item}))));
      this.items = this.items.map((item: IProduct) => {
        item.brotherProductMarketplaces = this.populateProduct(item);

        return item;
      });
    });
  }

  private removeOffer(productMarketplace: IProductMarketplace, offerId: string, productId: number): void {
    this.dialog.confirm(
      this.translate('PAGE.PRODUCT.EDIT.TAB.MARKETPLACES.BOX_OFFER.CONFIRM.DELETE')
    )
      .then(() => {
        this.productMarketplaceResource.removeOffer(`${productMarketplace.id}`, offerId)
          .pipe(takeUntil(this.destroyed$))
          .subscribe(() => {
            this.snackbar.validate(this.translate('PAGE.PRODUCT.EDIT.TAB.MARKETPLACES.BOX_OFFER.ALERTS.DELETE'));
            delete productMarketplace.currentOffer;
            const formMarketplaceIndex = (<FormArray>this.form.get('productMarketplaces')).value ? (<FormArray>this.form.get('productMarketplaces')).value
              .map((value: IProductMarketplace) => value.marketplace)
              .indexOf(productMarketplace.marketplace) : -1;
            if (formMarketplaceIndex >= 0) {
              (<FormArray>this.form.get('productMarketplaces')).at(formMarketplaceIndex).get('specialPrice').setValue(null);
            }
          });
      });
  }

  private selectProduct(): void {
    this.onSelectProduct.emit(this.items);
    const test = this.items.find((product: IProduct) => !product.selected);
    if (test) {
      this.checkAll = false;
    }
  }

  private selectAllProducts(): void {
    this.items.forEach((marketplace: IProduct) => {
      this.checkAll
        ? marketplace.selected = true
        : marketplace.selected = false;
    });

    this.onSelectProduct.emit(this.items);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items.isFirstChange() || changes.items.previousValue === undefined) {
      return;
    }

    this.items = this.items.map((item: IProduct) => {
      item.brotherProductMarketplaces = this.populateProduct(item);
      item.inEdition = false;

      return item;
    });
  }

  public lockedQuantityFromWarehouse(item: any, warehouse: string): any[] {
    return item.masterProduct.masterProductsWarehouses.filter((i: any) => i.warehouse.code === warehouse && (
      i.lockedQuantityForCustoms > 0 ||
      i.lockedQuantityForDamageAtReception > 0 ||
      i.lockedQuantityForPreparation > 0 ||
      i.lockedQuantityForProductionDifference > 0 ||
      i.lockedQuantityForQuality > 0 ||
      i.lockedQuantityForSubsidiaries > 0 ||
      i.lockedQuantityBroken  > 0 ||
      i.lockedQuantityTemporary > 0 ||
      i.lockedQuantityForControl > 0
    ));
  }

  public totalLockedQuantityFromWarehouse(item: any, warehouse: string): number {
    let total = 0;
    item.masterProduct.masterProductsWarehouses.map((i: any) => {if (i.warehouse.code === warehouse) {
      total += i.lockedQuantityForCustoms;
      total += i.lockedQuantityForDamageAtReception;
      total += i.lockedQuantityForPreparation;
      total += i.lockedQuantityForProductionDifference;
      total += i.lockedQuantityForQuality;
      total += i.lockedQuantityForSubsidiaries;
      total += i.lockedQuantityBroken;
      total += i.lockedQuantityTemporary;
      total += i.lockedQuantityForControl;
    }});

    return total;
  }


  public uniqueStockWarehouse(item: IProduct): string[] {
    const uniqueWarehouse: string[] = [];

    item.stocks.forEach((stock: any) => {
      uniqueWarehouse.push(stock.warehouse);
    });

    return [...new Set(uniqueWarehouse)];
  }

  public getProductMarketplace(item: any, code: string, country: string): IProductMarketplace {
    if (!item) {
      return;
    }

    if (undefined === item.brotherProductMarketplaces[code] || undefined === item.brotherProductMarketplaces[code][country]) {
      return null;
    }

    return item.brotherProductMarketplaces[code][country];
  }

  public getCurrency(marketplace: IMarketplace): string {
    return undefined === marketplace.country ? this.currency : marketplace.country.currencyCode;
  }

  public getAffialiateCurrency(marketplace: IMarketplace): string {
    return undefined === marketplace.country
    || undefined === marketplace.country.commercialEntity
    || undefined === marketplace.country.commercialEntity.currencyCode
      ? this.currency : marketplace.country.commercialEntity.currencyCode;
  }

  public hasUnaffiliateCurrency(marketplaces: {[key: string]: IMarketplace}): boolean {
    const firstMarketplace = Object.values(marketplaces)[0];

    return this.getCurrency(firstMarketplace) !== this.getAffialiateCurrency(firstMarketplace);
  }

  public lockedOffer(item: any, code: string, country: string): boolean {
    return this.isLockedOffer(this.getProductMarketplace(item, code, country));
  }

  private isLockedOffer(productMarketplace: IProductMarketplace): boolean {
    return this.isOffer(productMarketplace) && (!!productMarketplace.currentOffer.dateBegin
      || !!productMarketplace.currentOffer.dateEnd
      || productMarketplace.currentOffer.hasCommercialOperation);
  }
}
