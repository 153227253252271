import { Component, ComponentFactoryResolver, Inject } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { GenericResultListComponent } from '@components/generic/List';
import { UrlHelper } from '@helpers';

@Component({
  selector: 'app-shipping-price-result-list',
  template: require('./shipping-price-result-list.component.html'),
})
export class ShippingPriceResultListComponent extends GenericResultListComponent {

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    @Inject('StateService') state: ng.ui.IStateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('DialogService') dialog: any,
    urlHelper: UrlHelper,
    componentFactoryResolver: ComponentFactoryResolver,
  ) {
    super($translate, state, authService, resource, dialog, urlHelper, componentFactoryResolver);
  }

  public goToEdit(id: any): void {
    this.state.go('shipping-prices.edit', { id: id }, { reload: true });
  }
}
