import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ErrorService {
  /**
   * Gets error messages from violations/exceptions
   */
  private static parseViolations(violations: any): string[] {
    if (!Array.isArray(violations) && typeof violations !== 'object') {
      return [];
    }

    return [...new Set(Object.keys(violations)
      .map((key: any) => {
        const path = violations[key].propertyPath || '';
        let message = violations[key].message || (typeof violations[key] === 'string' ? violations[key] : '');

        if (path) {
          message = `${path} : ${message}`;
        }

        return message.trim();
      })
      .filter((violation) => violation.length)
    )];
  }

  /**
   * Gets generic error message depending on response status code.
   */
  public static getGenericErrorMessage(error: {[keys: string]: any }): string {
    if (null === error || !error.status) {
      throw new TypeError('Error status code is undefined');
    }

    switch (error.status) {
      case 400:
      case 500:
        return 'ALERTS.ERROR.API';
      case 401:
        return 'ALERTS.UNAUTHORIZED';
      case 403:
        return 'ALERTS.ERROR.RIGHTS';
      case 404:
        return 'ALERTS.ERROR.NOT_FOUND';
      case -1:
        return 'ALERTS.ERROR.API_DOWN';
      default:
        return error.statusText;
    }
  }

  /**
   * Gets error messages according to the API response (FOS Rest + API Platform)
   */
  public static getViolations(response: HttpErrorResponse): string[] {
    const data: any = response.error;

    if (data.violations && data['@context'] && data['@context'].indexOf('/contexts/ConstraintViolationList') >= 0) {
      return this.parseViolations(data.violations);
    }

    if (data['hydra:description'] && data['@context'] && data['@context'].indexOf('/contexts/Error') >= 0) {
      return data['hydra:description'];
    }

    if (data.error && data.error.code && data.error.code >= 400 && data.error.code <= 599) {
      if (data.error.exception) {
        return this.parseViolations(data.error.exception);
      }

      if (data.error.message) {
        return [data.error.message];
      }
    }

    if (data.errors && data.errors.errors) {
      return this.parseViolations(data.errors.errors);
    }

    if (data.errors && data.errors.children) {
      return this.parseViolations(this.parseErrorsFromChildren(data.errors.children));
    }

    if (data.errors) {
      return this.parseViolations(data.errors);
    }

    if (data.code && data.message) {
      return [data.message];
    }

    if (data.detail) {
      return [data.detail];
    }

    return [];
  }

  /**
   * Recursively get children errors
   */
   private static parseErrorsFromChildren(node: any): string[] {
    if (null === node || 'object' !== typeof node) {
      return [];
    }

    const errors: any = [];

    Object.entries(node).forEach(([key, value]) => {
      switch (key) {
        case 'errors':
          errors.push(value);
          break;
        default:
          const deepErrors = this.parseErrorsFromChildren(value);

          if (deepErrors.length) {
            errors.push(deepErrors);
          }
          break;
      }
    });

    return errors.flat();
  }

  /**
   * Replaces recursively children nodes by their content
   *
   * @param node
   * @returns {any}
   */
  public static shrinkErrorFromChildren(node: any): any {
    if (null !== node) {
      if ('object' !== typeof node) {
        return node;
      }

      let newValue: any = {};

      Object.entries(node).forEach(([key, value]) => {
        switch (key) {
          case 'children': // takes children content instead of node
            newValue = Object.assign(newValue, ...this.shrinkErrorFromChildren(value));
            break;
          case 'errors': // takes errors array without transform
            newValue[key] = value;
            break;
          default: // parse node content recursively
            const nestedValue = this.shrinkErrorFromChildren(value);

            // removes empty object to avoid unnecessary complexity
            if ('object' !== typeof nestedValue || Object.keys(nestedValue).length) {
              newValue[key] = nestedValue;
            }
        }
      });

      return newValue;
    }

    return null;
  }
}
