export default websiteHomepageRoutes;

/** @ngInject */
function websiteHomepageRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'walipush',
    sidebarSubNav: 'website_homepage',
    title: 'PAGE.WEBSITE_HOMEPAGE.TITLE',
    reloadChangeCountry: 'websiteHomepage.slider-items.list',
    showCountries: true,
    tabs: [
      {
        label: 'PAGE.WEBSITE_HOMEPAGE.TAB.SLIDER_ITEMS',
        state: 'websiteHomepage.slider-items',
      },
      {
        label: 'PAGE.WEBSITE_HOMEPAGE.TAB.SLIDER',
        state: 'websiteHomepage.slider',
      },
      {
        label: 'PAGE.WEBSITE_HOMEPAGE.TAB.HIGHLIGHTS',
        state: 'websiteHomepage.highlights',
      },
      {
        label: 'PAGE.WEBSITE_HOMEPAGE.TAB.HIGHLIGHTS_ITEMS',
        state: 'websiteHomepage.highlights-items',
      },
      {
        label: 'PAGE.WEBSITE_HOMEPAGE.TAB.BARGAINS',
        state: 'websiteHomepage.bargains',
      },
      {
        label: 'PAGE.WEBSITE_HOMEPAGE.TAB.ADVICES',
        state: 'websiteHomepage.advices',
      },
      {
        label: 'PAGE.WEBSITE_HOMEPAGE.TAB.SEO',
        state: 'websiteHomepage.seo',
      }
    ],
  };

  $stateProvider
    .state('websiteHomepage', {
      url: '/website-homepage',
      parent: 'walipush',
      abstract: true,
      breadcrumb: {
        label: 'PAGE.WEBSITE_HOMEPAGE.TITLE',
        stateName: 'website_homepage'
      }
    })
    .state('websiteHomepage.highlights', {
      url: '/highlights',
      breadcrumb: {
        label: 'PAGE.WEBSITE_HOMEPAGE.BREADCRUMB.HIGHLIGHTS',
      },
      views: {
        'page@layout': {
          template: '<app-website-homepage-highlights></app-website-homepage-highlights>'
        }
      },
      data: commonStateData
    })
    .state('websiteHomepage.bargains', {
      url: '/bargains',
      breadcrumb: {
        label: 'PAGE.WEBSITE_HOMEPAGE.BREADCRUMB.BARGAINS',
      },
      views: {
        'page@layout': {
          template: '<app-website-homepage-bargains></app-website-homepage-bargains>'
        }
      },
      data: commonStateData
    })
    .state('websiteHomepage.slider', {
      url: '/slider',
      breadcrumb: {
        label: 'PAGE.WEBSITE_HOMEPAGE.BREADCRUMB.SLIDER',
      },
      views: {
        'page@layout': {
          template: '<app-website-homepage-slider></app-website-homepage-slider>'
        }
      },
      data: commonStateData
    })
    .state('websiteHomepage.slider-items', {
      url: '/slider-items',
      breadcrumb: {
        label: 'BREADCRUMB.SLIDER_ITEMS',
        stateName: 'websiteHomepage.slider-items.list'
      },
      views: {
        'page@layout': {
          template: '<app-website-homepage-slider-items-list></app-website-homepage-slider-items-list>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.SLIDER_ITEMS.LIST.TITLE',
      })
    })
    .state('websiteHomepage.slider-items.list', {
      url: '',
      views: {
        'page@layout': {
          template: '<app-website-homepage-slider-items-list></app-website-homepage-slider-items-list>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.SLIDER_ITEMS.LIST.TITLE',
      })
    })
    .state('websiteHomepage.slider-items.new', {
      url: '/new',
      breadcrumb: { label: 'BREADCRUMB.NEW' },
      views: {
        'page@layout': { template: '<app-website-homepage-slider-items></app-website-homepage-slider-items>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.SLIDER_ITEMS.NEW.TITLE',
      })
    })
    .state('websiteHomepage.slider-items.edit', {
      url: '/:id',
      breadcrumb: { label: 'BREADCRUMB.EDIT' },
      views: {
        'page@layout': { template: '<app-website-homepage-slider-items></app-website-homepage-slider-items>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.SLIDER_ITEMS.EDIT.TITLE',
      })
    })
    .state('websiteHomepage.highlights-items', {
      url: '/highlights-items',
      breadcrumb: {
        label: 'BREADCRUMB.HIGHLIGHTS',
        stateName: 'websiteHomepage.highlights-items.list'
      },
      views: {
        'page@layout': {
          template: '<app-website-homepage-highlights-items-list></app-website-homepage-highlights-items-list>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.SLIDER_ITEMS.LIST.TITLE',
      })
    })
    .state('websiteHomepage.highlights-items.list', {
      url: '',
      views: {
        'page@layout': {
          template: '<app-website-homepage-highlights-items-list></app-website-homepage-highlights-items-list>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.HIGHLIGHTS.LIST.TITLE',
      })
    })
    .state('websiteHomepage.highlights-items.new', {
      url: '/new',
      breadcrumb: { label: 'BREADCRUMB.NEW' },
      views: {
        'page@layout': { template: '<app-website-homepage-highlights-items></app-website-homepage-highlights-items>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.HIGHLIGHTS.NEW.TITLE',
      })
    })
    .state('websiteHomepage.highlights-items.edit', {
      url: '/:id',
      breadcrumb: { label: 'BREADCRUMB.EDIT' },
      views: {
        'page@layout': { template: '<app-website-homepage-highlights-items></app-website-homepage-highlights-items>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.HIGHLIGHTS.EDIT.TITLE',
      })
    })
    .state('websiteHomepage.advices', {
      url: '/advices',
      breadcrumb: {
        label: 'PAGE.WEBSITE_HOMEPAGE.BREADCRUMB.ADVICES',
      },
      views: {
        'page@layout': {
          template: '<app-website-homepage-advices></app-website-homepage-advices>'
        }
      },
      data: commonStateData
    })
    .state('websiteHomepage.seo', {
      url: '/seo',
      breadcrumb: {
        label: 'PAGE.WEBSITE_HOMEPAGE.BREADCRUMB.SEO',
      },
      views: {
        'page@layout': {
          template: '<app-website-homepage-seo></app-website-homepage-seo>'
        }
      },
      data: commonStateData
    });
}
