import { Component, Input, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UploadEvent } from '@progress/kendo-angular-upload';
import { SnackbarService } from '@components/snackbar/snackbar.service';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services';
import { Subscription } from 'rxjs/Subscription';
import { CommercialOperationsResource } from '@components/commercial-operations/resources/commercial-operations.resource';
import { SessionHelper } from '@helpers';

/**
 * This component manage the csv file upload.
 */
@Component({
  selector: 'app-form-csv',
  template: require('./form-csv.component.html'),
  styles: [require('./form-csv.component.scss')]
})
export class FormCsvComponent extends AbstractComponent {

  private form: FormGroup;

  @Input() private uploaderEndpoint: string;
  @Input() private marketplace: any;

  @ViewChild('kendoFileUploader') kendoFileUploaderRef: any;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    @Inject('DialogService') private dialogService: any,
    private formBuilder: FormBuilder,
    private snackbar: SnackbarService,
    public resource: CommercialOperationsResource,
  ) {
    super($translate, authService, null, state);

    this.createForm();
  }

  /**
   * Creates form.
   */
  private createForm(): void {
    this.form = this.formBuilder.group({
      'file': ['', Validators.required],
    });
  }

  /**
   * Fires by kendo-upload when one or more files are about to be uploaded.
   */
  private onSubmit(event: UploadEvent): void {
    event.data = {
      businessObject: 'commercial-operation',
      countryCode: SessionHelper.getCountry().code,
      locale: SessionHelper.getLocale(),
      businessObjectId: this.state.params.id
    };
  }

  private openUploadInput() {
    document.getElementsByName('file')[0].click();
  }

  private onSelect() {
    this.dialogService.confirm(
      this.translate(
        'PAGE.COMMERCIAL_OPERATIONS.PRODUCTS.ADD_PRODUCTS.MESSAGES.DIALOG_MARKETPLACE',
        {markeplace: this.marketplace.commercialName}
      )
    )
      .then(() => {
        this.kendoFileUploaderRef.uploadFiles();
      });
  }

  /**
   * Fires by kendo-upload when an `upload` operation has failed.
   * Launch a snackbar alert
   *
   * @param {event} event
   */
  private errorEventHandler(event: {[keys: string]: any}): void {
    event.response.violations[0].message
      ? this.snackbar.alert(event.response.violations[0].message)
      : this.snackbar.alert(event.response.violations[0]);
  }

  /**
   * Fires by kendo-upload when an `upload` operation is successfully completed.
   * Averts operator when file upload success.
   */
  private successEventHandler(): void {
    this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
  }

  public exportCsv() {
    const subscriber: Subscription = this.resource.exportFile({}, {
      entryPoint: `/v2/commercial_operations/${this.state.params.id}/export`,
      responseType: 'text/csv',
      type: 'text/csv'
    }).subscribe(() => {}, undefined, () => subscriber.unsubscribe());
  }
}
