import { AllHtmlEntities } from 'html-entities';

export class StringHelper {

  private static htmlEncoder: AllHtmlEntities = new AllHtmlEntities();

  /**
   * Replaces patterns in a given string.
   *
   * @param {string} stack
   * @param {any} needles
   * @param {string} options
   * @returns {string}
   */
  static replacePatterns(stack: string = '', needles: any = {}, options: string = 'g'): string {
    for (const key in needles) {
      if (needles.hasOwnProperty(key)) {
        stack = stack.replace(new RegExp(key, options), needles[key]);
      }
    }

    return stack;
  }

  /**
   * Switches from camel case to snake case for a given value.
   *
   * @param {string} value
   * @returns {string}
   */
  static camelCaseToSnakeCase(value: string): string {
    let text = '';

    if ('string' === typeof value) {
      text += value
        .replace(/(?:^|\.?)([A-Z])/g, (x, y) => `_${y.toLowerCase()}`)
        .replace(/^_/, '')
      ;
    }

    return text;
  }

  /**
   * Counts (encoded) chars for a given strings.
   *
   * @param {string|Array<string>} strings
   * @param {boolean} encoded
   * @returns {number}
   */
  static countChar(strings?: string|Array<string>, encoded: boolean = false): number {
    if (undefined === strings || null === strings) {
      return 0;
    }

    if ('string' === typeof strings) {
      strings = [strings];
    }

    let count = 0;

    for (const string of strings) {
      if (undefined !== string) {
        count += encoded ?
          StringHelper.nl2br(StringHelper.htmlEncode(string)).length :
          StringHelper.stripTags(StringHelper.htmlDecode(string)).length
        ;
      }
    }

    return count;
  }

  /**
   * Encodes to html chars.
   *
   * @param {string} text
   * @returns {string}
   */
  static htmlEncode(text: string): string {
    return StringHelper.htmlEncoder.encode(text);
  }

  /**
   * Decodes from html chars.
   *
   * @param {string} text
   * @returns {string}
   */
  static htmlDecode(text: string): string {
    return StringHelper.htmlEncoder.decode(text);
  }

  /**
   * Replaces carriage returns by BR tag.
   *
   * @param {string} text
   * @returns {string}
   */
  static nl2br(text: string): string {
    return text.replace(/[\n\r]+/g, '<br/>');
  }

  /**
   * Removes html tags.
   *
   * @param {string} text
   * @returns {string}
   */
  static stripTags(text: string): string {
    return text.replace(/<[\w\/=\" ]+>/g, '');
  }

  static snakeToCamel(str: string): string {
    return str.replace(/([-_]\w)/g, g => g[ 1 ].toUpperCase());
  }

  static snakeToPascal(str: string): string {
    const camelCase = StringHelper.snakeToCamel(str);
    const pascalCase = camelCase[0].toUpperCase() + camelCase.substr(1);

    return pascalCase;
  }

  static pluralize(str: string): string {
    const lastChar = str.slice(str.length - 1);

    if ('y' === lastChar) {
      return str.substring(0, str.length - 1) + 'ies';
    }

    return str + 's';
  }
}
