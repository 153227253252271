/**
 * @TODO: when ui-router will be removed and when the angular router will be in place,
 * put each of these constants in proper router definition
 */

export {
  DASHBOARD,
  DASHBOARD_REVENUES,
  DASHBOARD_SALES_COMPARISON,
  SIGN_IN,
  REVERSES_LIST,
};

const DASHBOARD: string = 'dashboard';
const DASHBOARD_REVENUES: string = 'dashboard.revenues';
const DASHBOARD_SALES_COMPARISON: string = 'dashboard.sales-comparison';
const SIGN_IN: string = 'anonymous.sign-in';
const REVERSES_LIST: string = 'reverses.list';
