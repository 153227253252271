import { Injectable, Inject } from '@angular/core';
import { AbstractFormFieldBase } from '@components/generic/Form/dynamic/fields/abstract-form-field-base.class';
import { ComboSearchField } from '@components/generic/Form/dynamic/fields';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { FormNotifierService } from '@services/form-notifier.service';
import { CatalogsResource } from '@components/catalogs';
import { SessionHelper } from '@helpers/session.helper';
import { Subscription } from 'rxjs/Subscription';
import { MarketplaceHelper } from '@helpers/MarketplaceHelper';
import { SuperProductResource } from '@components/super-product/super-product.resource';
import { CategoryResource } from '@resources/category.resource';
import { ISuperProductSku} from '@components/super-product/interfaces/super-product-sku.interface';

interface ICatalog {
  id: number;
  label: string;
}

interface ICategory {
  name: string;
  id: number;
  level: number;
  parentId: number;
}

/**
 * Constructs all filters field for the super product list.
 */
@Injectable()
export class SuperProductListFiltersService extends AbstractFiltersFieldsService {

  private catalogField = new ComboSearchField({
    fieldName: 'catalogId',
    label: 'PAGE.SUPER_PRODUCT.LIST.FILTER.CATALOG.LABEL',
    data: null,
    valuePrimitive: true,
    textField: 'label',
    valueField: 'id',
    value: undefined,
  });

  private idField = new ComboSearchField({
    fieldName: 'id',
    label: 'PAGE.SUPER_PRODUCT.LIST.FILTER.SUPER_PRODUCT_OR_REFERENCE.LABEL',
    data: null,
    valuePrimitive: true,
    textField: 'label',
    valueField: 'id',
    value: undefined,
  });

  private categoryIdField = new ComboSearchField({
    fieldName: 'categoryId',
    label: 'PAGE.SUPER_PRODUCT.LIST.FILTER.CATEGORY.LABEL',
    data: null,
    textField: 'name',
    valueField: 'id',
    valuePrimitive: true,
    value: undefined,
  });

  constructor(
    @Inject('StateService') protected state: ng.ui.IStateService,
    @Inject('TranslationService') public $translate: ng.translate.ITranslateService,
    formNotifier: FormNotifierService,
    private catalogResource: CatalogsResource,
    private superProductResource: SuperProductResource,
    private categoryResource: CategoryResource,
  ) {
    super(formNotifier, state);

    const locale = SessionHelper.getLocale();

    const catalogSubscriber: Subscription = this.catalogResource.cGet({ locale })
      .subscribe((response: ICatalog[]) => {
        catalogSubscriber.unsubscribe();

        this.catalogField.data = response.filter((catalog: ICatalog) => !!catalog.label);
        this.catalogField.value = this.filters.get('catalogId') ? +this.filters.get('catalogId') : undefined;
      })
    ;

    const superProductSubscriber = this.superProductResource.getSkus({ locale })
      .subscribe((response: ISuperProductSku[]) => {
        superProductSubscriber.unsubscribe();

        this.idField.data = response;
        this.idField.value = this.filters.get('id');
      })
    ;

    if (MarketplaceHelper.hasWebsiteMarketplace()) {
      const categorySubscriber: Subscription = this.categoryResource.getAllNames({ locale }, { dontUseModel: true })
        .subscribe((response: ICategory[]) => {
          categorySubscriber.unsubscribe();

          this.categoryIdField.data = response.filter((category: ICategory) => 1 === category.level)
            .reduce((sorted: any, category: ICategory) => {
              const children = response.filter((child: ICategory) => category.id === child.parentId)
                .map((item: ICategory) => Object.assign({}, item, { name: `|— ${item.name}` }))
              ;

              return sorted.concat(category, children);
            }, [])
          ;

          this.categoryIdField.value = this.filters.get('categoryId') ? +this.filters.get('categoryId') : undefined;
        });
    }
  }

  /**
   * @inheritDoc.
   */
  public getFields(): AbstractFormFieldBase<any>[] {
    const fields: AbstractFormFieldBase<any>[] = [
      this.catalogField,
      this.idField,
    ];

    if (MarketplaceHelper.hasWebsiteMarketplace()) {
      fields.push(this.categoryIdField);
    }

    return fields;
  }
}
