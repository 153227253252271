import {IRelation} from '@components/order-manager/relation/order-manager-relation.interface';
import {IOrder} from '@components/order-manager/models/order-manager-list-light.interface';
import * as moment from 'moment';
import {DATE_SHORT_FORMAT, FULL_EUROPEEN_DATE_FORMAT} from '@constants';

export class OrderManagerRelationModel implements IRelation {
  id: string;
  claimDate: string;
  litigation: boolean;
  origins: any[];
  type: string;
  order: any;
  orderItems: any[];
  carrier: string;
  marketplace?: any;
  reason: string;
  customer: any;
  agents: any[];
  status: string;
  updatedAt: string;
  problem_detail: string;
  comment: string;
  steps: any[];
  attachments: any[];
  advice: string;
  solution: string;
  satisfactionType: string;
  constructor(model: IRelation) {
    this.id = model.id;
    this.claimDate = model.claimDate;
    this.litigation = model.litigation;
    this.agents = model.agents ? model.agents : null;
    this.origins = model.origins ? model.origins : null;
    this.type = model.type;
    this.orderItems = model.orderItems ? model.orderItems.map((item: any) => {
      return {
      '@id': item['@id'],
      'sku': item['product']['sku'],
    }; }) : null;
    this.carrier = model.carrier ? model.carrier : null;
    this.order = model.order ? model.order.id : null;
    this.reason = model.reason ? model.reason : '';
    this.marketplace = model.marketplace ? model.marketplace : null;
    this.customer = model.customer ? model.customer.id : null;
    this.status = model.status ? model.status : 'opened';
    this.updatedAt = model.updatedAt ? model.updatedAt : null;
    this.problem_detail = model.problem_detail ? model.problem_detail : null;
    this.comment = model.comment ? model.comment : null;
    this.steps = model.steps ? model.steps : null;
    this.attachments = model.attachments ? model.attachments : null;
    this.advice = model.advice ? model.advice : null;
    this.solution = model.solution ? model.solution : null;
    this.satisfactionType = model.satisfactionType ? model.satisfactionType : null;
  }

}
