import { AbstractResource } from '../../../../resources/abstract.resource';
import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { TaskModel } from '@components/generic/task-manager/model/task.model';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  entryPoint: '/v2/tasks',
  isHydra: true,
  model: TaskModel
})
export class TaskResource extends AbstractResource {

  nullableProperties: string[] = ['assignFrom', 'assignTo'];

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }
}
