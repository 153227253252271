import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbComponent, BreadcrumbItemComponent } from '@components/breadcrumb';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { GenericFormModule } from '@components/generic';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    BreadcrumbComponent,
    BreadcrumbItemComponent,
  ],
  entryComponents: [
    BreadcrumbComponent,
    BreadcrumbItemComponent,
  ],
  imports: [
    CommonModule,
    DropDownListModule,
    GenericFormModule,
    FormsModule
  ],
  exports: [
    BreadcrumbComponent,
  ]
})

export class BreadcrumbModule {}
