import { Inject, Injectable } from '@angular/core';
import { FormNotifierService } from '@services/form-notifier.service';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { DateField, TextField } from '@components/generic/Form/dynamic/fields';
import * as moment from 'moment';
import { DATE_FULL_FORMAT } from '@constants';

@Injectable()
export class SparePartSearchFiltersService extends AbstractFiltersFieldsService {

  private dateStartField: TextField = new TextField({
    fieldName: 'dateStart',
    value: null,
    hidden: true
  });

  private dateEndField: TextField = new TextField({
    fieldName: 'dateEnd',
    value: null,
    hidden: true
  });

  private static setDates(newValue: string, startField: TextField, endField: TextField): void {
    if (!newValue || null === newValue[0]) {
      return;
    }

    startField.value = moment(newValue[0]).startOf('day').format(DATE_FULL_FORMAT);
    endField.value = moment(newValue[1]).endOf('day').format(DATE_FULL_FORMAT);
  }

  constructor(
    @Inject('StateService') protected state: ng.ui.IStateService,
    @Inject('TranslationService') public $translate: ng.translate.ITranslateService,
    formNotifier: FormNotifierService,
  ) {
    super(formNotifier, state);
  }

  public getFields(): any[] {
    const dateStart = this.filters.get('dateStart');
    const dateEnd = this.filters.get('dateEnd');

    if (dateStart && dateEnd) {
      this.dateStartField.value = dateStart;
      this.dateEndField.value = dateEnd;
    }

    return [
      new TextField({
        fieldName: 'sparePartId',
        label: 'PAGE.SPARE_PART.ID',
        value: this.filters.get('sparePartId') ? this.filters.get('sparePartId') : undefined,
      }),

      new DateField({
        label: 'PAGE.SPARE_PART.CREATED_AT',
        fieldName: 'date',
        dateRange: true,
        value: dateStart && dateEnd ? [new Date(dateStart), new Date(dateEnd)] : undefined,
        valueChangedAction: this.setDateValue.bind(this),
      }),

      this.dateStartField,
      this.dateEndField,
    ];
  }

  private setDateValue(newValue: string): void {
    SparePartSearchFiltersService.setDates(newValue, this.dateStartField, this.dateEndField);
  }
}
