import { Component, Inject } from '@angular/core';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { AuthService } from '@services/auth.service';
import { TrackingSalesResource } from '@components/tracking-sales/tracking-sales.resource';
import { AbstractResource } from '@resources';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { TrackingSalesFiltersFormService } from '@components/tracking-sales/services';

@Component({
  selector: 'app-tracking-sales-list',
  template: require('./tracking-sales-list.component.html'),
  providers: [
    { provide: AbstractResource, useClass: TrackingSalesResource },
    { provide: AbstractFiltersFieldsService, useClass: TrackingSalesFiltersFormService }
  ]
})

export class TrackingSalesListComponent extends AbstractPageComponent {
  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    public resource: AbstractResource,
  ) {
    super($translate, authService, resource, state);
  }
}
