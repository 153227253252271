import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { AbstractResource } from '../resources/abstract.resource';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  entryPoint: '/v2/role_hierarchies',
  isHydra: true
})
export class RoleHierarchyResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }
}
