import { Component, Inject, OnInit } from '@angular/core';
import { SnackbarService } from '@components/snackbar/snackbar.service';
import { FormErrorHelper } from '../../../../helpers/FormErrorHelper';
import { FormSubmitArgument } from '@interfaces';
import { WarrantyResource } from '@components/warranty/resources/warranty.resource';
import { AuthService } from '../../../../services/auth.service';
import { IWarranty } from '@components/warranty/models/warranty.interface';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';

@Component({
  selector: 'app-warranty-new',
  template: require('./warranty-new.component.html')
})
export class WarrantyNewComponent extends AbstractPageComponent implements OnInit {

  public warranty: IWarranty;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: WarrantyResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private snackbar: SnackbarService,
  ) {
    super($translate, authService, resource, state);
  }

  public ngOnInit() {
    this.warranty = {
      months: null,
      translations: {
        [this.currentLocale]: {
          label: '',
          locale: this.currentLocale,
        }
      }
    };
  }

  /**
   * Handles the save form event.
   */
  public save({ body, form, redirect }: FormSubmitArgument): void {
    this.resource.create(body)
      .takeUntil(this.destroyed$)
      .subscribe(() => {
        this.snackbar.validate(this.translate('PAGE.WARRANTY.NEW.SAVED'));
        if (redirect) {
          this.actions.list.go();
        }
      }, (error: any) => {
        if (400 === error.code) {
          FormErrorHelper.fillFormControlWithErrors(form, error.errors);
        }
      })
    ;
  }
}
