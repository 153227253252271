import { Component, Inject } from '@angular/core';
import { CategorySatelliteResource } from '@components/categories/categories-satellite.resource';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AbstractResource } from '@resources/abstract.resource';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-categories-satellite-list',
  template: require('./categories-satellite-list.component.html'),
  providers: [
    { provide: AbstractResource, useClass: CategorySatelliteResource },
  ],
})
export class CategoriesSatelliteListComponent extends AbstractComponent {
  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }
}
