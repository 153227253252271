import * as _ from 'lodash';

export class FormHelper {

  /**
   * Get errors for each field
   *
   * @static
   * @param {object} errors
   * @param {string} path
   * @returns {object}
   * @memberof FormHelper
   */
  public static getErrors(errors: object, path: string): object {
    // Get array with just the fields names of form
    if (null == errors) {
      errors = {};
    }
    if (null == path) {
      path = '';
    }

    const paths = path.match(/\w*/gi);
    const newPath = paths.filter((item) => {
      if ('' !== item) {
        return item;
      }
    }).reduce((last, current) => last + 'children.' + current + '.', '');

    errors = _.get(errors, newPath + 'errors');

    return errors || {};
  }

  public static convertModelToFormData(model: any, form: FormData = null, namespace = ''): FormData {
    const formData = form || new FormData();

    for (const propertyName in model) {
      if (!model.hasOwnProperty(propertyName) || !model[propertyName]) { continue; }
      const formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
      if (model[propertyName] instanceof Date) {
        formData.append(formKey, model[propertyName].toISOString());
      } else if (model[propertyName] instanceof Array) {
        model[propertyName].forEach((element: any, index: number) => {
          const tempFormKey = `${formKey}[${index}]`;
          this.convertModelToFormData(element, formData, tempFormKey);
        });
      } else if (typeof model[propertyName] === 'object' && !(model[propertyName] instanceof File)) {
        this.convertModelToFormData(model[propertyName], formData, formKey);
      } else {
        formData.append(formKey, model[propertyName].toString());
      }
    }
    return formData;
  }
}
