import { AbstractResource } from './abstract.resource';
import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  entryPoint: '/v2/logistic_waves',
  isHydra: true,
  routeName: 'logistic-wave',
  cGetDefaultFilters: () => ({
    'order[id]': 'desc',
  })
})

export class LogisticWaveResource extends AbstractResource {
  constructor(http: HttpClient) {
    super(http);
  }
}
