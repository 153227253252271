import { Component, Inject } from '@angular/core';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { AuthService } from '@services/auth.service';
import { UsersResource } from '@components/users';
import { SessionHelper } from '@helpers/session.helper';

@Component({
  selector: 'app-usurped',
  template: require('./usurped.component.html'),
})

export class UsurpedComponent extends AbstractPageComponent {

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private usersResource: UsersResource,
  ) {
    super($translate, authService, null, state);
  }

  public exitUsurpedMode(): void {
    SessionHelper.setCurrentUserDataInStorage(SessionHelper.getUser());
    SessionHelper.removeUsurpedUser();
    this.state.go(`${this.usersResource.routeName}.list`, {}, {reload: true});
  }
}
