import { AbstractFormFieldBase } from './abstract-form-field-base.class';

/**
 * TextField supports multiple HTML5 types such as text, email, and url via the type property.
 */
export class TextField extends AbstractFormFieldBase<string> {
  /**
   * Used in the template to render the text field.
   *
   * @type {string}
   */
  fieldType = 'text';

  /**
   * Type of the text field like email, password, number ...
   */
  type: string;

  constructor(options: {[key: string]: any} = {}) {
    super(options);

    this.type = <string>options['type'] || 'text';
  }
}
