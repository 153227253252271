import { Resource } from '@decorators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AbstractResource, IRequestOptions } from '@resources';
import { Observable } from 'rxjs/Observable';

@Injectable()
@Resource({
  entryPoint: '/v2/commitments?currentFiscalYear&commitmentOrderBy',
  isHydra: true,
  importOptions: [
    {
      entryPoint: `/api/v2/import`,
      importButton: 'BUTTON.IMPORT.CSV',
      businessObject: 'commitment',
    },
  ],
})
export class CommitmentResource extends AbstractResource {

  constructor(http: HttpClient) {
    super(http);
  }

  cGet(params: any | null = null, options: IRequestOptions = {}, headers?: HttpHeaders): Observable<object> {
    const query: any = {
      page: params.page,
      byPage: params.byPage,
      'order[country.id]': 'asc',
    };

    if (params['sku[]']) {
      query['masterProduct.products.sku[]'] = params['sku[]'];
    }

    if (params['country[]']) {
      query['country.code[]'] = params['country[]'];
    }

    return super.cGet(query, options, headers);
  }
}
