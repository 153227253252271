import { Injectable } from '@angular/core';
import { BsDatepickerConfig, BsLocaleService, frLocale } from 'ngx-bootstrap';

@Injectable()
export class DatepickerThemingService {
  constructor(
    private config: BsDatepickerConfig,
    private localeService: BsLocaleService
  ) {
    config.containerClass = 'theme-dark-blue';
    this.localeService.use(frLocale.abbr);
  }
}
