import {Component, Inject, OnInit} from '@angular/core';
import {AuthService} from '@services/auth.service';
import {AbstractResource} from '@resources/abstract.resource';
import {AbstractComponent} from '@components/generic/abstract.component';
import {BUSINESS_OBJECT_ORDER, INDICATOR_ESTIMATE_TYPE, BUSINESS_OBJECT_TRANSACTION, INDICATOR_TRANSACTION_TYPE} from '@constants';

@Component({
  selector: 'app-estimate-orders-result-list',
  template: require('./estimate-orders-result-list.component.html'),
})
export class EstimateOrdersResultListComponent extends AbstractComponent implements OnInit {

  public BUSINESS_OBJECT_ORDER: string = BUSINESS_OBJECT_ORDER;
  public BUSINESS_OBJECT_TRANSACTION: string = BUSINESS_OBJECT_TRANSACTION;
  public INDICATOR_ESTIMATE_TYPE: string = INDICATOR_ESTIMATE_TYPE;
  public INDICATOR_TRANSACTION_TYPE: string = INDICATOR_TRANSACTION_TYPE;

  private _items: any[];
  public carriers: any[];
  public selectAll: boolean = false;

  set items(items: any[]) {
    this._items = items;
  }

  get items(): any[] {
    return this._items;
  }

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
  }

  goToEdit(orderId: any, e?: MouseEvent) {
    if (e && true === e.ctrlKey) {
      window.open(
        this.state.href(
          'order_manager.edit',
          {id: orderId},
          {absolute: true}
        ),
        '_blank'
      );
      return;
    }

    this.state.go('order_manager.edit', {id: orderId});
  }

  public getTransactionId(order: any) {
    try {
      return order.transactions[0].split('/').pop();
    } catch (error) {
      console.error(error);
    }
  }
}
