import { AbstractComponent } from '@components/generic/abstract.component';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { ShipmentResource } from '@resources/shipment.resource';
import { ShipmentInterface } from '../../interfaces/shipment.interface';
import { takeUntil } from 'rxjs/operators';
import { OrderManagerResource } from '@components/order-manager/order-manager.resource';
import { OrderResource } from '@resources';
@Component({
  selector: 'app-order-manager-history',
  template: require('./order-manager-history.component.html'),
  styles: [require('./order-manager-history.component.scss')],
})
export class OrderManagerHistoryComponent extends AbstractComponent implements OnInit {

  @Input() public order: any;

  public shippingAddressId: any;
  public shipments: ShipmentInterface[];
  public paymentsRefunds: any;

  public billingAddresses: any[];
  public shippingAddresses: any[];
  public shippingInfos: any = {};
  public shipmentHistories: any = {};

  private static getAddressId(address: any) {
    try {
      return address['@id'].split('/').pop();
    } catch (error) {
      console.error(error);
    }
  }

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    private shipmentResource: ShipmentResource,
    private orderManagerResource: OrderManagerResource,
    private orderResource: OrderResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }

  public ngOnInit(): void {
    this.fetchShipments();
    this.fetchPaymentsRefunds();
    this.fetchAddressHistory();
    this.fetchArrivalHistory();
    this.fetchShipmentHistory();
    this.shippingAddressId = OrderManagerHistoryComponent.getAddressId(this.order.shippingAddress);
  }

  private fetchPaymentsRefunds(): void {
    this.orderManagerResource.getPaymentsRefunds(this.order.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        this.paymentsRefunds = response;
      })
      ;
  }

  private fetchShipments(): void {
    this.shipmentResource.cGet({ 'items.order.id': this.order.id }, { returnHydraMembers: true })
      .subscribe((response: ShipmentInterface[]) => {
        this.shipments = response;
      });
  }

  getLabel(shipmentId: string) {
    let label = ' ';

    const shipment = this.shipments.find((e: any) => e.id === shipmentId);

    if (shipment && shipment.warehouse) {
      label += shipment.warehouse.code;
    }

    if (shipment && shipment.carrier && shipment.carrier.carrierGroup) {
      label += '/';
      label += shipment.carrier.carrierGroup.name;
    }

    return label;
  }

  public getIdFromHydraId(transaction: any) {
    if (transaction && transaction['@id']) {
      return transaction['@id'].split('/').pop();
    }
  }

  public goToProductEdit(event: MouseEvent, productUri: string) {
    const id = productUri.split('/').pop();
    let state = 'product.edit';

    if (this.order.detached) {
      state = 'spare-part.edit';
    }

    if (event.ctrlKey) {
      window.open(this.state.href(state, { id }, { absolute: true }), '_blank');
      return;
    }
    this.state.go(state, { id });
  }

  private fetchAddressHistory() {
    this.orderManagerResource.getAddressHistory(this.getId(this.order.billingAddress)).subscribe((response: any) => {
      this.billingAddresses = response['hydra:member'];
    }
    );

    this.orderManagerResource.getAddressHistory(this.getId(this.order.shippingAddress)).subscribe((response: any) => {
      this.shippingAddresses = response['hydra:member'];
    }
    );
  }

  public fetchArrivalHistory(): void {
    for (const item of this.order.items) {
      this.shippingInfos[item.id] = {};

      if (item.arrival) {
        this.orderManagerResource.getArrivalHistory(item.arrival.id).pipe(takeUntil(this.destroyed$)).subscribe((response: any) => {
          this.shippingInfos[item.id].arrivalHistory = response['hydra:member'];
        });
      }

      this.orderManagerResource.getShippingInfos(item.id).pipe(takeUntil(this.destroyed$)).subscribe((response: any) => {
        this.shippingInfos[item.id].shippingInfos = response;
      });
    }
  }

  public fetchShipmentHistory(): void {
    for (const item of this.order.items) {
      this.orderManagerResource.getShipmentHistory(item.id).pipe(takeUntil(this.destroyed$)).subscribe((response: any) => {
        this.shipmentHistories[item.id] = response.shipmentHistory.map((history: string) => {
          return JSON.parse(history);
        });
      });
    }
  }

  public capitalize(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  public getSkus(items: any[]): string {
    return items.map((item: any) => item.product.sku).join(' - ');
  }

  private getId(item: any) {
    return item['@id'].split('/').pop();
  }
}
