import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericFormModule } from '@components/generic/generic-form.module';
import { GenericModule } from '@components/generic/generic.module';
import { ProductModule } from '@components/product';
import { OrderResource, OrderSupportedByResource } from '@resources';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { FormsModule } from '@angular/forms';
import { EstimateOrdersListComponent } from '@components/estimate/orders/estimate-orders-list.component';
import { EstimateOrdersResultListComponent } from './orders/estimate-orders-result-list.component';
import { BsDropdownModule } from 'ngx-bootstrap';
import { OrderManagerResource } from '@components/order-manager';
import {PipesModule} from '../../pipes/pipes.module';

@NgModule({
  declarations: [
    EstimateOrdersListComponent,
    EstimateOrdersResultListComponent,
  ],
  entryComponents: [
    EstimateOrdersListComponent,
    EstimateOrdersResultListComponent,
  ],
  imports: [
    CommonModule,
    GenericFormModule,
    GenericModule,
    ProductModule,
    DropDownListModule,
    FormsModule,
    BsDropdownModule,
    PipesModule
  ],
})

export class EstimateModule { }
