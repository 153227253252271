import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { FormNotifierService } from '@services/form-notifier.service';
import { FormArray, FormGroup } from '@angular/forms';
import { FORM_STREAMS } from '../../enums/form-notifier-streams.enum';
import { AbstractDirective } from '@directives/abstract.directive';
import { takeUntil } from 'rxjs/operators';

/**
 * After form submission, checks tabs where form groups contains form errors.
 */
@Directive({
  selector: '[appFormTabValidation]'
})
export class FormTabValidationDirective extends AbstractDirective {

  private tabs: NodeListOf<HTMLLIElement>;
  private translationsFA: FormArray;

  constructor(
    private formNotifier: FormNotifierService,
    private el: ElementRef,
    private renderer: Renderer2,
  ) {
    super();

    this.formNotifier.observable
      .pipe(takeUntil(this.destroyed$))
      .subscribe((stream: any) => {
        this.tabs = el.nativeElement.querySelectorAll('ul.nav li');

        if (stream) {
          if (stream.hasOwnProperty('form') && stream.hasOwnProperty('event') && stream.event === FORM_STREAMS.formUiHasErrors) {
            if (!stream.form.controls.translations) {
              return;
            }

            this.translationsFA = stream.form.controls.translations as FormArray;

            this.translationsFA.controls.forEach((formGroup: FormGroup) => {
              if (formGroup.invalid) {

                let i = 0;
                while (this.tabs.length > i) {
                  const tab: HTMLLIElement = this.tabs[i];

                  if (formGroup.controls.locale.value === (<HTMLElement>tab.querySelector('a span')).innerText) {
                    this.renderer.addClass(tab, 'tab-has-error');
                  }

                  i++;
                }
              }
            });
          }

          if (stream.hasOwnProperty('apiErrors')) {
            if (!stream['apiErrors']) {
              return;
            }

            stream['apiErrors'].forEach((error: any) => {
              let i = 0;
              while (this.tabs.length > i) {
                const tab: HTMLLIElement = this.tabs[i];
                const localeInError = error.propertyPath.search((<HTMLElement>tab.querySelector('a span')).innerText);

                if (-1 !== localeInError) {
                  this.renderer.addClass(tab, 'tab-has-error');
                }

                i++;
              }
            });
          }

          if (FORM_STREAMS.formInEdition === stream && this.translationsFA) {
            this.translationsFA.controls.forEach((formGroup: FormGroup) => {
              if (formGroup.valid) {

                let i = 0;
                while (this.tabs.length > i) {
                  const tab: HTMLLIElement = this.tabs[i];

                  if (
                    formGroup.controls.locale.value === (<HTMLElement>tab.querySelector('a span')).innerText &&
                    tab.classList.contains('tab-has-error')
                  ) {
                    this.renderer.removeClass(tab, 'tab-has-error');
                  }

                  i++;
                }
              }
            });
          } else if (FORM_STREAMS.formInEdition === stream && !this.translationsFA) {
            let i = 0;
            while (this.tabs.length > i) {
              const tab: HTMLLIElement = this.tabs[i];

              if (tab.classList.contains('tab-has-error')) {
                this.renderer.removeClass(tab, 'tab-has-error');
              }

              i++;
            }
          }
        }
      })
    ;
  }
}
