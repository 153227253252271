import { Component, Inject } from '@angular/core';
import { AbstractResource } from '@resources';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { AuthService } from '@services';
import { SupplierResource } from '@components/supplier/resources/supplier.resource';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { SupplierListFiltersService } from '@components/supplier/list/supplier-list-filters.service';

@Component({
  selector: 'supplier-list',
  template: require('./supplier-list.component.html'),
  providers: [
    { provide: AbstractResource, useClass: SupplierResource },
    { provide: AbstractFiltersFieldsService, useClass: SupplierListFiltersService },
  ],
})
export class SupplierListComponent extends AbstractPageComponent {
  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService
  ) {
    super($translate, authService, resource, state);
  }
}
