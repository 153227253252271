import { Component, Inject, Input, OnInit } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@services';
import { AbstractResource } from '@resources';
import { AbstractComponent } from '@components/generic/abstract.component';
import { OrderManagerItemResource } from '@components/order-manager/order-manager-item.resource';
import { INPUT_NUMBER_PATTERN_DEC } from '@constants';

@Component({
    selector: 'order-item-dialog',
    template: require('./order-item-dialog.component.html'),
    styles: [require('./order-item-dialog.component.scss')],

})
export class OrderItemDialogComponent extends AbstractComponent implements OnInit {

    @Input() orderId: string;
    @Input() marketplace: string;

    public form: FormGroup;
    public dryRunResponse: any;
    public body: any;

    constructor(
        @Inject('TranslationService') $translate: ng.translate.ITranslateService,
        authService: AuthService,
        @Inject('StateService') state: ng.ui.IStateService,
        private orderManagerItemResouce: OrderManagerItemResource,
        resource: AbstractResource,
        private dialog: DialogRef,
        private formBuilder: FormBuilder) {
        super($translate, authService, resource, state);
    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            sku: new FormControl('', Validators.required),
            quantity: new FormControl(1, Validators.required),
            discount: new FormControl(0, [Validators.required, Validators.pattern(INPUT_NUMBER_PATTERN_DEC)])
        });

        if (this.isMarketplaceHandledByShowroom()) {
          this.form.addControl('unitPrice', new FormControl(0, [Validators.required, Validators.pattern(INPUT_NUMBER_PATTERN_DEC)]));
        }

        this.form.valueChanges.subscribe(x => {
            this.dryRunResponse = undefined;
            this.computeDryRun();
        });
    }

    computeDryRun() {
        if (this.form.get('quantity').value && this.form.get('sku').value) {
            this.body = {
                quantity: +this.form.get('quantity').value,
                product: `/api/v2/products/${this.form.get('sku').value.id}`,
                order: `/api/v2/orders/${this.orderId}`,
                discount: parseFloat(this.form.get('discount').value.toString().replace(',', '.')),
            };

            if (this.form.get('unitPrice')) {
              this.body.unitPrice = parseFloat(this.form.get('unitPrice').value.toString().replace(',', '.'));
            }

            this.orderManagerItemResouce.create(this.body, { entryPoint: '/v2/order-manager/items?dryRun' }).subscribe(
                (response: any) => {
                    this.dryRunResponse = response;
                }
            );
        }
    }

    public onCancelAction(): void {
        this.dialog.close({ type: 'cancel' });
    }

    public onConfirmAction(): void {
        this.orderManagerItemResouce.create(this.body, { entryPoint: '/v2/order-manager/items' }).subscribe(
            () => {
                this.dialog.close({ type: 'submit' });
            }
        );
    }

    public isMarketplaceHandledByShowroom(): boolean {
      return ['b2b', 'showroom'].includes(this.marketplace);
    }
}
