import { IAddress } from '@models/address.interface';

export class AddressModel implements IAddress {
  public id: string;
  public phone1: string|null;
  public phone2: string|null;
  public email: string|null;
  public name: string|null;
  public gender: string|null;
  public firstName: string|null;
  public lastName: string|null;
  public company: string|null;
  public addressLine1: string;
  public addressLine2: string|null;
  public addressLine3: string|null;
  public postcode: string;
  public city: string;
  public province: string;
  public countryCode: string;
  public identificationNumber: string;
  public identificationNumberType: string;

  constructor(address: IAddress) {
    this.id = address.id;
    this.phone1 = address.phone1;
    this.phone2 = address.phone2;
    this.email = address.email;
    this.name = address.name;
    this.gender = address.gender;
    this.firstName = address.firstName;
    this.lastName = address.lastName;
    this.company = address.company;
    this.addressLine1 = address.addressLine1;
    this.addressLine2 = address.addressLine2;
    this.addressLine3 = address.addressLine3;
    this.postcode = address.postcode;
    this.city = address.city;
    this.province = address.province;
    this.countryCode = address.countryCode;
    this.identificationNumber = address.identificationNumber;
    this.identificationNumberType = address.identificationNumberType;
  }

  [keys: string]: any;
}
