import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericModule } from '@components/generic/generic.module';
import { AttributeSetResource } from '@components/attribute-set/attribute-set.resource';
import { AttributeSetComponent } from '@components/attribute-set/attribute-set.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GenericFormModule } from '@components/generic/generic-form.module';
import { ComboBoxModule, MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { DndModule } from 'ng2-dnd';
import { SwitchModule } from '@progress/kendo-angular-inputs';
import { AttributeAttributeSetResource } from '@components/attribute-set/attribute-attribute-set.resource';
import { AttributeSetListComponent } from '@components/attribute-set/list/attribute-set-list.component';
import { AttributeTypeWebsiteResource } from '@components/attribute-set/attribute-type-website.resource';

@NgModule({
  declarations: [
    AttributeSetComponent,
    AttributeSetListComponent,
  ],
  entryComponents: [
    AttributeSetComponent,
    AttributeSetListComponent,
  ],
  imports: [
    CommonModule,
    GenericModule,
    ReactiveFormsModule,
    GenericFormModule,
    MultiSelectModule,
    FormsModule,
    ComboBoxModule,
    DndModule,
    SwitchModule,
  ],
  providers: [
    AttributeSetResource,
    AttributeAttributeSetResource,
    AttributeTypeWebsiteResource
  ]
})
export class AttributeSetModule {}
