import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericModule } from '@components/generic';
import { SwitchModule } from '@progress/kendo-angular-inputs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ExportComponent } from '@components/export/export.component';
import { ExportResource } from '@resources/export.resource';
import { ExportSelectionComponent } from '@components/export/export-selection.component';

@NgModule({
  declarations: [
    ExportComponent,
    ExportSelectionComponent,
  ],
  entryComponents: [
    ExportComponent,
    ExportSelectionComponent,
  ],
  providers: [
    ExportResource,
    ExportSelectionComponent,
  ],
  exports: [
    ExportComponent,
    ExportSelectionComponent,
  ],
  imports: [
    CommonModule,
    GenericModule,
    SwitchModule,
    FormsModule,
    ReactiveFormsModule,
  ]
})

export class ExportModule {}
