import { Resource } from '@decorators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractResource } from '@resources';
import { Observable } from 'rxjs/Observable';
import { TrackingSalesModel } from '@components/tracking-sales/models';
import { SessionHelper } from '@helpers/session.helper';
import * as moment from 'moment';
import { DATE_SHORT_INTERNATIONAL_FORMAT } from '@constants/date';

@Injectable()
@Resource({
  entryPoint: '/v2/tracking_sales',
  isHydra: true,
  model: TrackingSalesModel,
  cGetDefaultFilters: () => ({
    page: 1,
    byPage: 200,
    'countries[]': [SessionHelper.getCountry().code],
    warehouse: SessionHelper.getWarehouses()[0].code,
    from: moment().subtract(1, 'days').format(DATE_SHORT_INTERNATIONAL_FORMAT),
    to: moment().format(DATE_SHORT_INTERNATIONAL_FORMAT),
  }),
  listColumns: [
    {
      field: 'formattedFlags',
      type: 'string[]',
      translationKey: 'FLAGS',
      displayRawHtml: true
    },
    { field: 'sku', translationKey: 'SKU', sortable: true },
    { field: 'sp', translationKey: 'SP', sortable: true, toolTip: 'PAGE.TRACKING_SALES.LIST.TABLE.TOOLTIPS.SP' },
    { field: 'ss', translationKey: 'SS', sortable: true, toolTip: 'PAGE.TRACKING_SALES.LIST.TABLE.TOOLTIPS.SS' },
    {
      field: 'formattedArrivals',
      type: 'string[]',
      translationKey: 'ARRIVALS',
      sortable: false,
      toolTip: 'PAGE.TRACKING_SALES.LIST.TABLE.TOOLTIPS.ARRIVALS',
      displayRawHtml: true
    },
    { field: 'rotation', translationKey: 'ROTATION', sortable: true, toolTip: 'PAGE.TRACKING_SALES.LIST.TABLE.TOOLTIPS.ROTATION' },
    { field: 'ets', type: 'date', translationKey: 'ETS', sortable: true, toolTip: 'PAGE.TRACKING_SALES.LIST.TABLE.TOOLTIPS.ETS' },
    { field: 'priceMin', translationKey: 'PRICE_MIN', sortable: true, toolTip: 'PAGE.TRACKING_SALES.LIST.TABLE.TOOLTIPS.PRICE_MIN' },
    { field: 'priceMax', translationKey: 'PRICE_MAX', sortable: true, toolTip: 'PAGE.TRACKING_SALES.LIST.TABLE.TOOLTIPS.PRICE_MAX' },
  ],
  translationKey: 'TRACKING_SALES',
  deleteAvailable: false,
  updateAvailable: false,
  createAvailable: false,
})

export class TrackingSalesResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  public cGet(params: any = {}, options: any = {}): Observable<object> {
    params['order[sku]'] = params.sort_sku;
    params['order[sp]'] = params.sort_sp;
    params['order[ss]'] = params.sort_ss;
    params['order[priceMin]'] = params.sort_priceMin;
    params['order[priceMax]'] = params.sort_priceMax;
    params['order[rotation]'] = params.sort_rotation;
    params['order[ets]'] = params.sort_ets;

    return super.cGet(params, options);
  }
}
