export default qualityExportRoutes;

/** @ngInject */
function qualityExportRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'walipush',
    sidebarSubNav: 'quality',
    title: '',
    reloadChangeCountry: 'quality.export',
  };

  $stateProvider
    .state('quality', {
      url: '/export/quality',
      parent: 'walipush',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.QUALITY_EXPORT',
        stateName: 'quality.export'
      }
    })
    .state('quality.export', {
      url: `?
        &startDate
        &endDate
      `,
      views: {
        'page@layout': {
          template: '<app-quality-export></app-quality-export>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.QUALITY_EXPORT.TITLE',
      })
    })
  ;
}
