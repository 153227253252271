import { AbstractResource } from '../../../resources/abstract.resource';
import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { HttpClient } from '@angular/common/http';

export interface ICredentials {
  username: string;
  password: string;
}

interface IRefreshToken {
  refresh_token: string;
}

@Injectable()
@Resource({
  entryPoint: '/user/login_check',
})
export class AuthResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  public refreshToken(refreshToken: IRefreshToken) {
    return this.getMany(refreshToken, {entryPoint: '/token/refresh'});
  }
}
