import { Injectable } from '@angular/core';
import {AbstractResource, IRequestOptions} from '@resources/abstract.resource';
import { Resource } from '@decorators/Resource';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {StringHelper} from '@helpers';

@Injectable()
@Resource({
  entryPoint: '/v2/warehouses/{id}/theoretical-cross-dock-capacities'
})
export class ExportLogisticsCrossDockResource extends AbstractResource {
  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  getLslCapacities(date: string, options: IRequestOptions = {}): Observable<object> {
    return this.cGet(
      { date: date },
      Object.assign({ entryPoint: StringHelper.replacePatterns(this.entryPoint, { '{id}': 25 }) }, options)
    );
  }
}
