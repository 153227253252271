import { Component, Inject, Input } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource, BlockResource } from '@resources';
import { SnackbarService } from '@components/snackbar/snackbar.service';
import { AbstractBlockOverrideFormComponent } from '@components/generic/blocks/abstract-block-override-form.component';
import { StringHelper } from '@helpers';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-block-group-form-table',
  template: require('./block-group-form-table.component.html'),
  styles: [require('./block.scss')],
  providers: [{ provide: AbstractResource, useClass: BlockResource }]
})
export class BlockGroupFormTableComponent extends AbstractBlockOverrideFormComponent {
  @Input() public data: {
    businessObject: string,
    blockTitleKey?: string,
    properties?: string[],
    columns?: string[],
    filters?: any,
    orders?: any,
    additionnalOptions?: any,
    isModule?: boolean,
    show?: boolean,
    maxItems?: number
  }[];
  @Input() public categoryModule: boolean = false;
  @Input() public blockListTitle: string;
  @Input() public locale: string;
  @Input() public allowOverride: boolean = false;
  @Input() public show: boolean = false;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    protected snackbar: SnackbarService,
    @Inject('DialogService') protected dialog: any,
  ) {
    super($translate, authService, resource, state, snackbar, dialog);
  }

  setOverrideField() {
    if (this.product) {
      this.overrideField = 'override' + StringHelper.snakeToPascal(this.data[0].businessObject.replace('product_', ''));
    } else if (this.category) {
      this.overrideField = 'override' + StringHelper.snakeToPascal(this.data[0].businessObject.replace('category_', ''));
    } else if (this.commercialOperation) {
      this.overrideField = 'override' + StringHelper.snakeToPascal(this.data[0].businessObject.replace('commercialOperation_', ''));
    }
  }

  fetch(event?: any) {
    if (undefined !== this.product) {
      this.fetchBlockConfig('/v2/products/' + this.product.id + '/product_block', event);
    } else if (undefined !== this.category) {
      this.fetchBlockConfig('/v2/categories/' + this.category.id + '/category_block', event);
    } else if (undefined !== this.commercialOperation) {
      this.fetchBlockConfig('/v2/commercial_operations/' + this.commercialOperation.id + '/commercial_operation_block', event);
    }
  }

  fetchBlockConfig(entryPoint: string, event?: any) {
    this.resource.cGet({}, { entryPoint: entryPoint, throwViolations: false })
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (response: any) => {
          this.config = response;

          if (event && event.reload) {
            this.reloadChildren();
          }
        },
        () => {
          this.config[this.overrideField] = false;
        }
      )
    ;
  }

  reloadChildren(): void {
    this.show = false;

    setTimeout(() => {
      this.show = true;
    }, 100);
  }
}
