import { Component, Inject } from '@angular/core';
import { AuthService } from '@services';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AbstractResource, LogisticWaveResource } from '@resources';

@Component({
  selector: 'app-logistic-wave-list',
  template: require('./logistic-wave-list.component.html'),
  providers: [
    { provide: AbstractResource, useClass: LogisticWaveResource }
  ]
})
export class LogisticWaveListComponent extends AbstractComponent {

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService
  ) {
    super($translate, authService, null, state);
  }
}
