import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services/auth.service';
import { FormBuilder, FormGroup } from '@angular/forms';


@Component({
    selector: 'app-order-comment',
    template: require('./order-comment.component.html'),
    providers: [],
})
export class OrderCommentComponent extends AbstractComponent implements OnInit {

    public form: FormGroup;

    @Output() private onUpdate: EventEmitter<any> = new EventEmitter();

    constructor(
        @Inject('TranslationService') $translate: ng.translate.ITranslateService,
        authService: AuthService,
        @Inject('StateService') state: ng.ui.IStateService,
        private formBuilder: FormBuilder,
    ) {
        super($translate, authService, null, state);
    }

    ngOnInit(): void {
        this.buildForm();
    }

    buildForm() {
        this.form = this.formBuilder.group({
            comment: [null],
        });

        this.form.controls.comment.valueChanges.subscribe(
            (commentFormValue: string) => {
                this.onUpdate.emit(commentFormValue);
            }
        );
    }

}
