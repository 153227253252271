import { Inject, Injectable } from '@angular/core';
import { FormNotifierService } from '@services/form-notifier.service';
import {MultiSearchField, SkuSearchField, TextField} from '@components/generic/Form/dynamic/fields';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { SuperProductResource } from '@components/super-product/super-product.resource';
import { SessionHelper } from '@helpers';

@Injectable()
export class ArrivalViewService extends AbstractFiltersFieldsService {
  constructor(
    @Inject('StateService') protected state: ng.ui.IStateService,
    @Inject('TranslationService') public $translate: ng.translate.ITranslateService,
    private superProductResource: SuperProductResource,
    formNotifier: FormNotifierService,
  ) {
    super(formNotifier, state);
  }

  public getFields(): any[] {
    return [
      new MultiSearchField({
        fieldName: 'warehouse',
        label: 'PAGE.ORDER.LIST.FILTER.WAREHOUSES.LABEL',
        data: SessionHelper.getAllWarehouses(),
        textField: 'name',
        valueField: 'code',
        value: 0 < this.filters.getAll('warehouse').length ? this.filters.getAll('warehouse') : ['lsl'],
        valuePrimitive: true,
      }),
      new MultiSearchField({
        fieldName: 'containers',
        data: [],
        label: 'Container',
        value: this.filters.getAll('container').length !== 0 ? this.filters.getAll('container') : undefined,
        order: 4,
        allowCustom: true
      }),
    ];
  }
}
