import { Component, Input } from '@angular/core';
import { FormArray } from '@angular/forms';

/**
 * Handle translations form.
 */
@Component({
  selector: 'app-discount-form-translations',
  template: require('./discount-form-translations.html'),
})
export class DiscountFormTranslationsComponent {

  @Input() translationsFA: FormArray;
  @Input() tabIndex: number = 0;
  @Input() isReadOnly: boolean = false;
}
