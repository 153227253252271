import {
  Component,
  EventEmitter, Inject,
  OnInit,
  Output,
} from '@angular/core';
import { UserResource } from '@resources';
import { IUser } from '@models/user.interface';
import {AbstractPageComponent} from '@components/generic/abstract-page.component';
import {AuthService} from '@services/auth.service';
import {IResource} from '@interfaces/IResource';

@Component({
  selector: 'app-user-search',
  template: require('./user-search.component.html')
})
export class UserSearchComponent extends AbstractPageComponent implements OnInit {

  public filteredUsers: IUser[];
  public currentUsers: IUser[];

  private users: IUser[];

  @Output() public onChange: EventEmitter<any> = new EventEmitter();

  public constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: UserResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }

  /**
   * @inheritDoc
   */
  ngOnInit() {
    this.resource.getMany()
      .takeUntil(this.destroyed$)
      .subscribe((results: IUser[]) => {
        this.users = results;
        this.filteredUsers = results;
      })
    ;
  }

  private doFiltering(value: string): IUser[] {
    if (!this.users.length || !value) {
      return [];
    }

    return this.users.filter((user: IUser) => user.username.toLowerCase().includes(value.toLowerCase()));
  }

  public filter(value: string): void {
    this.filteredUsers = this.doFiltering(value);
  }

  public change(users: IUser[]): void {
    this.onChange.emit(users);
  }
}
