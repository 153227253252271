import { Component, Inject, OnInit } from '@angular/core';
import { SnackbarService } from '@components/snackbar/snackbar.service';
import { AttributeValueHelper, SessionHelper } from '@helpers';
import { Subscription } from 'rxjs/Subscription';
import { AttributeResource, AttributeFamilyResource, AttributeValueResource } from '@resources';
import { cloneDeep } from 'lodash';
import { Observable } from 'rxjs/Observable';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { AuthService } from '@services';
import { IAttribute } from '@components/attribute/models/attribute.interface';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';

@Component({
  selector: 'app-attribute-edit',
  template: require('./attribute-edit.component.html'),
  providers: [AttributeValueHelper]
})
export class AttributeEditComponent extends AbstractPageComponent implements OnInit {

  public attributeID: string;
  public actionName: string = 'edit';
  public attribute: any;
  public applicationLocales: string[] = SessionHelper.get('APPLICATION_LOCALES');

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AttributeResource,
    @Inject('StateService') state: ng.ui.IStateService,
    @Inject('DialogService') private dialog: any,
    private snackbar: SnackbarService,
    private attributeFamilyResource: AttributeFamilyResource,
    private attributeValueResource: AttributeValueResource,
    private attributeValueHelper: AttributeValueHelper,
  ) {
    super($translate, authService, resource, state);

    this.attributeID = this.state.params.id;
  }

  ngOnInit() {
    // # Get attribute by id
    const subscriber: Subscription = this.resource.get(this.attributeID)
      .subscribe(
        (attributeResponse) => {
          this.attribute = attributeResponse;
        },
        null,
        () => subscriber.unsubscribe()
      );
  }

  /**
   * Saves attribute, new attribute values, edited attribute families and create/update asociated attribute values.
   */
  public saveAttributeAction({ redirect, attribute, newAttributeFamilies = [], editedAttributeFamilies = [] }: any): void {
    this.dialog.confirm(this.translate('PAGE.ATTRIBUTE.CONFIRM.UPDATE'))
      .then(() => {
        const formErrors = {};
        const pendingRequest: Observable<object>[] = [];

        this.updateAttributeValuesAndFamilies(editedAttributeFamilies, pendingRequest);
        this.createAttributeValuesAndFamilies(newAttributeFamilies, formErrors);

        const sentAttribute = cloneDeep(attribute);
        delete sentAttribute.attributeFamilies;
        delete sentAttribute.id;

        this.updateAttribute(pendingRequest, sentAttribute, redirect, formErrors);
      });
  }

  private updateAttribute(pendingRequest: Observable<object>[], sentAttribute: IAttribute, redirect: string, formErrors: {}): void {
    pendingRequest.push(this.resource.partialUpdate(this.attributeID, sentAttribute));
    const subscriber: Subscription = Observable.forkJoin(...pendingRequest).pipe(catchError(error => of(error))).subscribe((res) => {
      this.snackbar.validate(this.translate('ALERTS.DATA.UPDATE'));
      if ('single' === redirect) {
        return this.actions.update.go({ id: this.attributeID }, { 'reload': true, 'notify': true });
      }
      return this.actions.list.go();
    }, (errorData) => {
      if (errorData.status === 400) {
        formErrors = errorData.data.errors;
      }
    }, () => subscriber.unsubscribe());
  }

  private createAttributeValuesAndFamilies(newAttributeFamilies: any, formErrors: any): void {
    newAttributeFamilies.forEach((newAttributeFamily: any) => {
      newAttributeFamily.attribute = `/api/v2/attributes/${this.attribute.id}`;
      newAttributeFamily.value = [].concat(newAttributeFamily.value);
      const newAttributeValue: any = {
        attributeFamily: null,
        translations: {},
      };
      this.applicationLocales.forEach((locale) => {
        newAttributeValue.translations[locale] = {
          value: newAttributeFamily.translations[locale].value,
          unit: newAttributeFamily.translations[locale].unit,
          locale: locale
        };
      });

      const attributeFamilysubscriber: Subscription = this.attributeFamilyResource.create(newAttributeFamily)
        .subscribe((res: any) => {
            newAttributeValue.attributeFamily = res['@id'].substring(res['@id'].lastIndexOf('/') + 1, res['@id'].length);
            this.attributeValueHelper.cleanObject(newAttributeValue);
            this.attributeValueResource.create(newAttributeValue).subscribe();
        }, (errorData: any) => {
          if (errorData.status === 400) {
            formErrors = errorData.data.errors;
          }
        }, () => attributeFamilysubscriber.unsubscribe());
    });
  }

  private updateAttributeValuesAndFamilies(
    editedAttributeFamilies: any[],
    pendingRequest: Observable<object>[]
  ): void {
    editedAttributeFamilies.forEach((editedAttributeFamily: any, i: number) => {
      const editedAttributeValue: any = {
        id: null,
        translations: {}
      };
      let currentAttributeFamily: any, valueToUpdate: any;

      this.attribute.attributeFamilies.forEach((attributeFamily: any) => {
        if (attributeFamily.id === editedAttributeFamily.id) {
          currentAttributeFamily = attributeFamily;
          valueToUpdate = attributeFamily.attributeValues[0];
        }
      });

      this.applicationLocales.forEach((locale) => {
        if (editedAttributeFamily.translations[locale] && editedAttributeFamily.translations[locale].id) {
          editedAttributeFamily.translations[locale].id =
            `/api/v2/attribute_family_translations/${editedAttributeFamily.translations[locale].id}`;
        } else {
          delete editedAttributeFamily.translations[locale].id;
        }
      });

      editedAttributeFamily.value = [].concat(editedAttributeFamily.value);

      if (undefined !== currentAttributeFamily) {
        pendingRequest.push(this.attributeFamilyResource.update(currentAttributeFamily.id, editedAttributeFamily));
      }

      if (this.attribute.familyActive) {
        return;
      }

      if (currentAttributeFamily && valueToUpdate) {
        currentAttributeFamily.attributeValues.forEach((attributeValue: any) => {
          if (attributeValue.id === valueToUpdate.id) {
            editedAttributeValue.id = attributeValue.id;

            this.applicationLocales.forEach((locale) => {
              editedAttributeValue.translations[locale] = {
                value: editedAttributeFamily.translations[locale].value,
                locale: locale,
                toTranslate: editedAttributeFamily.translations[locale].toTranslate,
                unit: editedAttributeFamily.translations[locale].unit
              };
            });
          }
        });
      }

      this.attributeValueHelper.cleanObject(editedAttributeValue);
      editedAttributeValue.value = [].concat(editedAttributeValue.value);

      if (valueToUpdate) {
        pendingRequest.push(this.attributeValueResource.partialUpdate(valueToUpdate.id, editedAttributeValue));
      }
    });
  }

  public deleteAction() {
    this.dialog.confirm(this.translate('PAGE.ATTRIBUTE.CONFIRM.DELETE'))
      .then(() => {
        const subscriber: Subscription = this.resource.remove(this.attribute.id)
          .subscribe(
            () => this.actions.list.go(),
            null,
            () => subscriber.unsubscribe()
          );
      });
  }
}
