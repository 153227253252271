import { AbstractSearchFieldClass } from './abstract-search-field.class';

/**
 * Select search field.
 * (Actually using kendo-combobox)
 */
export class ComboSearchField extends AbstractSearchFieldClass {
  /**
   * Used in the template to render the kendo-multiselect component.
   */
  public fieldType: string = 'comboSearch';
  public readonly: boolean;
  public allowCustom: boolean;

  /**
   * Fires when the user tape in the field.
   */
  public filterChange: (newValue: any) => any;

  constructor(options: any = {}) {
    super(options);

    this.readonly = <boolean>options.readonly || false;
    this.allowCustom = <boolean>options.allowCustom || false;
    this.filterChange = options.filterChange || false;
  }
}
