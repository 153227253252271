import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericModule } from '@components/generic/generic.module';

import { LoaderComponent, LoaderService } from './';

@NgModule({
  declarations: [LoaderComponent],
  entryComponents: [LoaderComponent],
  imports: [CommonModule, GenericModule],
  exports: [LoaderComponent],
})
export class LoaderModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: LoaderModule,
      providers: [LoaderService],
    };
  }
}
