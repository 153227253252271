import { Component, ElementRef, Inject, OnInit, Renderer2 } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services/auth.service';
import { TasksStatsResource } from '@components/generic/task-manager/resources/tasks-stats.resource';
import { ITasksStats } from '@components/generic/task-manager/interfaces/tasks-stats.interface';
import { TaskCategoryResource } from '@components/generic/task-manager/resources';
import { ITaskCategory } from '@components/generic/task-manager/interfaces';
import { SessionHelper } from '@helpers/session.helper';
import { BusinessObjectPageListService } from '@components/header-tasks-stats/services/business-object-page-list.service';
import { BUSINESS_OBJECT_REVERSE_PRODUCT } from '@constants/business-object.constants';

@Component({
  selector: 'app-header-tasks-stats',
  template: require('./header-tasks-stats.component.html'),
  styles: [require('./header-tasks-stats.component.scss')],
  providers: [TasksStatsResource],
})

export class HeaderTasksStatsComponent extends AbstractComponent implements OnInit {

  public stats: ITasksStats;
  public taskTypeCategories: ITaskCategory[] = [];
  public uiLanguage: string = '';
  public ttcPending: boolean = false;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private tasksStatsResource: TasksStatsResource,
    private taskTypeCategoriesResource: TaskCategoryResource,
    private elem: ElementRef,
    private renderer: Renderer2,
  ) {
    super($translate, authService, null, state);
  }

  ngOnInit(): void {
    this.fetchTasksStats();
    this.uiLanguage = SessionHelper.getUILanguage();
  }

  /**
   * - refresh task stats
   * - get task type categories when user click on the dropdown to avoid loss of performance
   */
  public getTaskTypeCategories(): void {
    this.fetchTasksStats();
    this.ttcPending = 0 === this.taskTypeCategories.length;

    this.taskTypeCategoriesResource.getMany(undefined, { blocking: false, dontUseModel: true, isHydra: true })
      .takeUntil(this.destroyed$)
      .subscribe((response: ITaskCategory[]) => {
        this.taskTypeCategories = response;
        this.ttcPending = false;
      })
    ;
  }

  /**
   * Goes to the business object list according to the business object type and params.
   */
  public goToBusinessObjectList(businessObject: string, params: { taskStatus: 'open', myTasks?: true, 'taskType[]': string }) {
    this.state.go(BusinessObjectPageListService.getStateName(businessObject), params, { inherit: false });
  }

  private fetchTasksStats(): void {
    this.tasksStatsResource.getMany({ businessObject: BUSINESS_OBJECT_REVERSE_PRODUCT }, { blocking: false })
      .takeUntil(this.destroyed$)
      .subscribe((response: ITasksStats) => {
        this.stats = response;

        if (0 === response.tasksOpened && 0 === response.tasksAffectedToUser) {
          this.renderer.setStyle(this.elem.nativeElement, 'display', 'none');
        }
      })
    ;
  }
}
