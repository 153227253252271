import { Inject, Injectable } from '@angular/core';
import { FormNotifierService } from '@services/form-notifier.service';
import { MultiSearchField } from '@components/generic/Form/dynamic/fields';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';

@Injectable()
export class ContainerListFiltersService extends AbstractFiltersFieldsService {
  constructor(
    @Inject('StateService') protected state: ng.ui.IStateService,
    @Inject('TranslationService') public $translate: ng.translate.ITranslateService,
    formNotifier: FormNotifierService,
  ) {
    super(formNotifier, state);
  }

  public getFields(): any[] {
    return [
      new MultiSearchField({
        fieldName: 'reference[]',
        label: 'PAGE.CONTAINER_VIEW.LIST.FILTER.REFERENCE',
        data: [],
        value: this.filters.getAll('reference[]').length !== 0 ? this.filters.getAll('reference[]') : undefined,
        allowCustom: true
      }),
      new MultiSearchField({
        fieldName: 'containerNumber[]',
        label: 'PAGE.CONTAINER_VIEW.LIST.FILTER.CONTAINER_NUMBER',
        data: [],
        value: this.filters.getAll('containerNumber[]').length !== 0 ? this.filters.getAll('containerNumber[]') : undefined,
        allowCustom: true
      }),
      new MultiSearchField({
        fieldName: 'sageIdentifier[]',
        label: 'PAGE.CONTAINER_VIEW.LIST.FILTER.SAGE_IDENTIFIER',
        data: [],
        value: this.filters.getAll('sageIdentifier[]').length !== 0 ? this.filters.getAll('sageIdentifier[]') : undefined,
        allowCustom: true
      })
    ];
  }
}
