import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountCharPipe } from './count-char.pipe';
import { DumpPipe } from './dump.pipe';
import { GroupByPipe } from './groupby.pipe';

@NgModule({
  declarations: [
    CountCharPipe,
    DumpPipe,
    GroupByPipe,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    CountCharPipe,
    DumpPipe,
    GroupByPipe,
  ]
})
export class PipesModule {}
