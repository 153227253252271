export default estimateRoutes;

/** @ngInject */
function estimateRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'walipush',
    sidebarSubNav: 'sav',
    title: '',
    reloadChangeCountry: 'spare-part.list',
  };

  $stateProvider
    .state('estimate', {
      url: '/estimate',
      parent: 'walisoft',
      abstract: true,

    })
    .state('estimate.orders_list', {
      url: `/orders?
      page
      &byPage
      &search
      &orderId[]
      &countries[]
      &warehouses[]
      &carriers[]
      &orderManagerStatuses[]`,
      views: {
        'page@layout': {
          template: '<app-estimate-orders-list></app-estimate-orders-list>'
        }
      },
      breadcrumb: {
        label: 'BREADCRUMB.ESTIMATE_ORDERS',
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.ESTIMATE.ORDERS_LIST.TITLE',
        showCountries: false
      })
    })
  ;
}
