import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { EstimateOrdersResource } from './estimate-orders.resource';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { EstimateOrdersFiltersFormService } from './estimate-orders-filters-form.service';

@Component({
  selector: 'app-estimate-orders-list',
  template: require('./estimate-orders-list.component.html'),
  providers: [
    { provide: AbstractResource, useClass: EstimateOrdersResource },
    { provide: AbstractFiltersFieldsService, useClass: EstimateOrdersFiltersFormService },
  ],
})
export class EstimateOrdersListComponent extends AbstractPageComponent {

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }
}
