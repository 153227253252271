import { ICustomer, IDiscountList, IType } from '../interfaces/discount-list.interface';

export class DiscountListModel implements IDiscountList {
  '@id'?: string;
  id: string;
  country: any;
  active: boolean;
  customers: string;
  dateBegin?: string;
  dateEnd?: string;
  name: string;
  numberOfUsage: number;
  type: IType;
  archived: boolean;
  readOnly?: boolean;

  constructor(model: IDiscountList) {
    this.id = model.id;
    this.country = model.country;
    this.active = model.active;
    this.customers = (<ICustomer[]>model.customers).filter((customer: ICustomer) => !!customer.username)
      .map((customer: ICustomer) => customer.username)
      .join(', ')
    ;
    this.dateBegin = model.dateBegin;
    this.dateEnd = model.dateEnd;
    this.name = model.name;
    this.numberOfUsage = model.numberOfUsage;
    this.archived = model.archived;
    this.type = {
      percentage: model.type.percentage,
      translations: model.type.translations,
      amount: model.type.amount,
      gifts: model.type.gifts ? model.type.gifts.map((gift: any) => gift.sku).join(', ') : '',
      typeText: `PAGE.DISCOUNT.FILTER.TYPE.DATA.${model.type.type.toUpperCase()}`,
      type: model.type.type,
      remainingAmount: model.type.remainingAmount
    };
    this.readOnly = model.readOnly;
  }
}
