import { IFooterLinks } from '@components/footer/footer-links/models/footer-links.interface';

export class FooterLinksListModel {
  id?: string;
  parent: string;
  locale: string;
  obfuscated: boolean;
  position: number;
  title: string;
  link: string;

  constructor(footerLinks: IFooterLinks) {
    this.id = footerLinks.id;
    this.parent = footerLinks.parent;
    this.locale = footerLinks.locale;
    this.obfuscated = footerLinks.obfuscated;
    this.position = footerLinks.position;
    this.title = footerLinks.title;
    this.link = footerLinks.link;
  }
}
