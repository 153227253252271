import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { AbstractResource } from '@resources/abstract.resource';
import { DiscountResource } from '@components/discount/resources/discount.resource';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services/auth.service';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { DiscountFormGeneralModel } from '@components/discount/models/discount-form-general.model';
import { takeUntil } from 'rxjs/operators';
import { IDiscountFormGeneralModel, IFormBody } from '@components/discount/interfaces/discount-form.interface';
import { SnackbarService } from '@components/snackbar';
import { FormNotifierService } from '@services/form-notifier.service';
import {DiscountRestrictionsFormComponent} from '@components/discount';

@Component({
  selector: 'app-discount-limits-form',
  template: require('./discount-limits-form.component.html'),
  providers: [{ provide: AbstractResource, useClass: DiscountResource }]
})
export class DiscountLimitsFormComponent extends AbstractComponent implements OnInit {

  @Input() public commonFields: { [keys: string]: AbstractControl };

  @Output() public validNameField: EventEmitter<any> = new EventEmitter();
  @Output() public setReadOnly: EventEmitter<any> = new EventEmitter();
  @Input() onCustomer: DiscountRestrictionsFormComponent;

  public isReadOnly: boolean = false;

  private model: IDiscountFormGeneralModel;
  private form: FormGroup;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    private snackbar: SnackbarService,
    private formNotifier: FormNotifierService,
    @Inject('DialogService') private dialog: any,
  ) {
    super($translate, authService, resource, state);
  }

  /**
   * @inheritDoc
   */
  ngOnInit(): void {
    this.resource.get(this.state.params.id, {formModel: DiscountFormGeneralModel})
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe((response: IDiscountFormGeneralModel) => {
        this.model = response;
        this.isReadOnly = this.model.readOnly;
        this.setReadOnly.emit(this.model.readOnly);
        this.commonFields.name.patchValue(this.model.name);
        this.commonFields.description.patchValue(this.model.description);
        this.commonFields.zendeskTicketId.patchValue(this.model.zendeskTicketId);
        this.buildForm();
      });
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      maximumUsage: [this.model ? this.model.maximumUsage : null],
      maximumUsagePerCustomer: [this.model ? this.model.maximumUsagePerCustomer : null],
    });
  }

  public submit(event?: any): void {
    let message = this.translate('PAGE.DISCOUNT.FORM.CONFIRM_SAVE');
    if (this.model.customers.length < 1 && (isNaN(this.form.value.maximumUsage) || null === this.form.value.maximumUsage)
      && (isNaN(this.form.value.maximumUsagePerCustomer) || null === this.form.value.maximumUsagePerCustomer)) {
      message = `${this.translate('PAGE.DISCOUNT.FORM.ALERT_SAVE')}\n${message}`;
    }
    this.dialog.confirm(message)
      .then(() => {
        const body: IFormBody = this.prepareBody();

        if (!body.name) {
          this.validNameField.emit(false);

          return;
        }

        this.validNameField.emit(true);

        (<DiscountResource>this.resource).updateDiscount(this.state.params.id, body)
          .pipe(
            takeUntil(this.destroyed$)
          )
          .subscribe((response: any) => {
            this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
            this.formNotifier.notifyFormSubmitted();

            if (event && event.redirect) {
              this.state.go(`${this.resource.routeName}.list`);

              return;
            }

            this.state.go(`${this.resource.routeName}.edit.limits`, { id: response.id });
          })
        ;
      })
    ;
  }

  private prepareBody(): any {
    const formValue: IDiscountFormGeneralModel = this.form.value;

    return {
      name: this.commonFields.name.value,
      description: this.commonFields.description.value,
      zendeskTicketId: this.commonFields.zendeskTicketId.value,
      maximumUsage: formValue.maximumUsage,
      maximumUsagePerCustomer: formValue.maximumUsagePerCustomer,
    };
  }
}
