import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { IFormNotifier } from '@services/IFormNotifier';
import { FORM_STREAMS } from '../enums/form-notifier-streams.enum';

/**
 * Manage form events.
 */
export class FormNotifierService implements IFormNotifier {
  private subject: Subject<any> = new Subject<any>();

  /**
   * Observable streams.
   */
  public observable: Observable<any> = this.subject.asObservable();

  public notifyFormHasErrors(reject: any): void {
    this.subject.next(reject);
  }

  public notifyFormSubmitted(): void {
    this.subject.next(FORM_STREAMS.dataSubmitted);
  }

  public notifyFormInEdition(): void {
    this.subject.next(FORM_STREAMS.formInEdition);
  }

  public notifyFormResetting(): void {
    this.subject.next(FORM_STREAMS.formResetting);
  }

  public notifyFormIsDestroyed(): void {
    this.subject.next(FORM_STREAMS.formIsDestroyed);
  }

  public notifyEditableFormEnabled(id: string): void {
    this.subject.next({event: FORM_STREAMS.editableFormEnabled, id});
  }
}
