/** @ngInject */
export default function LitigationRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'walisoft',
    sidebarSubNav: 'litigation',
    title: '',
    reloadChangeCountry: 'litigation.list'
  };

  $stateProvider
    .state('litigation', {
      url: '/litigations',
      parent: 'walisoft',
      abstract: true,
      breadcrumb: {
        label: 'SIDEBAR.LABEL.LITIGATIONS',
        stateName: 'litigation.list'
      },
      data: Object.assign(commonStateData, {
        showCountries: false
      })
    })
    .state('litigation.list', {
      url: `?
        page
        &byPage
        &search
        &createdAtStart
        &createdAtEnd
        &shippedAtStart
        &shippedAtEnd
        &paidAtStart
        &paidAtEnd
        &sku[]
        &carrier[]
        &status[]
        &type[]
        &country[]
        &paymentAmount
        &archived
      `,
      views: {
        'page@layout': {
          template: '<app-litigation-list></app-litigation-list>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.LITIGATION.LIST.TITLE'
      }),
    });
}
