import { Component, Inject, Input } from '@angular/core';
import { AbstractComponent } from '../../../abstract.component';
import { AuthService } from '@services';
import { ShowDetailsInterface } from '@components/generic/List/show-details';

@Component({
  selector: 'app-table',
  template: require('./table.component.html'),
})
export class TableComponent extends AbstractComponent implements ShowDetailsInterface {
  @Input() data: any;
  public objectKeys: (o: {}) => string[] = Object.keys;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, null, state);
  }
}
