import { CatalogsResource } from '@components/catalogs/catalogs.resource';

export default catalogsRoutes;

/** @ngInject */
function catalogsRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'walipush',
    sidebarSubNav: 'catalogs',
    title: '',
    reloadChangeCountry: 'catalogs.list',
  };

  $stateProvider
    .state('catalogs', {
      url: '/catalogs',
      parent: 'walisoft',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.CATALOGS',
        stateName: 'catalogs.list'
      }
    })
    .state('catalogs.list', {
      url: '',
      views: {
        'page@layout': {
          template: '<app-catalogs-list></app-catalogs-list>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.CATALOGS.LIST.TITLE',
      })
    })
    .state('catalogs.new', {
      url: '/new',
      breadcrumb: { label: 'BREADCRUMB.NEW' },
      views: {
        'page@layout': { template: '<app-catalogs></app-catalogs>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.CATALOGS.NEW.TITLE',
      })
    })
    .state('catalogs.edit', {
      url: '/:id',
      breadcrumb: { label: 'BREADCRUMB.EDIT' },
      views: {
        'page@layout': { template: '<app-catalogs></app-catalogs>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.CATALOGS.EDIT.TITLE',
      })
    })
  ;
}
