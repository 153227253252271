import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabsComponent } from '@components/generic/tabs/tabs.component';
import { CardComponent } from '@components/generic/Card';
import { EditableComponent } from '@components/generic/Form';
import {
  FilterListComponent,
  GenericListComponent,
  GenericResultListComponent,
  GenericRowComponent,
  GenericImageListComponent,
  GenericGridComponent
} from '@components/generic/List';
import { GridComponent } from '@components/generic/grid/grid.component';
import { DynamicFormComponent } from '@components/generic/Form/dynamic/dynamic-form.component';
import { DynamicFormFieldComponent } from '@components/generic/Form/dynamic/dynamic-form-field.component';
import { SliderModule, SwitchModule } from '@progress/kendo-angular-inputs';
import { SortableColumnComponent } from '@components/generic/List/sortable-column/sortable-column.component';
import { SortableTableDirective } from '@components/generic/List/directives/sortable-table.directive';
import { GenericFormModule } from '@components/generic/generic-form.module';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { SortService } from '@components/generic/List/services/sort.service';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { EnhancedFieldsModule } from '@directives/enhanced-fields.module';
import { ButtonActionMultipleComponent } from '@components/generic/buttons/button-action-multiple.component';
import { ButtonCopyComponent } from '@components/generic/buttons/button-copy.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ShowDetailsModule } from '@components/generic/List/show-details';
import { ShowDetailsDirective } from '@components/generic/List/show-details';
import { UploadModule } from '@progress/kendo-angular-upload';
import { ImportExportComponent } from '@components/generic/import-export/import-export.component';
import { ImportV2FieldsComponent } from '@components/generic/import-v2/import-v2-fields.component';
import { SageSynchronizationIndicatorComponent } from '@components/generic/sage-synchronization-indicator/sage-synchronization-indicator.component';
import { DndDirective } from '@components/generic/List/directives/dnd.directive';
import { DndListComponent } from '@components/generic/dnd-list/dnd-list.component';
import { GenericPageComponent } from '@components/generic/page/generic-page.component';
import { HydraPaginationComponent } from '@components/generic/List';
import { PictoSelectorComponent } from '@components/generic/picto-selector/picto-selector.component';
import { DndModule } from 'ng2-dnd';
import { SlidingSidebarComponent } from '@components/generic/sliding-sidebar';
import { IndicatorComponent, IndicatorHistoryComponent } from '@components/generic/indicator';
import { TooltipModule } from 'ngx-tooltip';
import { PipesModule } from '../../pipes/pipes.module';
import { DndListChildrenComponent } from '@components/generic/dnd-list/dnd-list-children.component';
import {IsDateBetweenSwitchComponent} from '@components/generic/dnd-list/date-between-switch';
import { CustomSnackBarComponent } from '@components/custom-snack-bar/custom-snack-bar.component';

/**
 * @reminder for developers
 */
@NgModule({
    /**
     * The module's declarations array tells Angular which components belong to that module.
     * As you create more components, add them to declarations.
     */
    declarations: [
        CardComponent,
        TabsComponent,
        EditableComponent,
        GenericPageComponent,
        GenericListComponent,
        GenericResultListComponent,
        GenericRowComponent,
        GridComponent,
        FilterListComponent,
        DynamicFormComponent,
        DynamicFormFieldComponent,
        SortableColumnComponent,
        SortableTableDirective,
        ButtonActionMultipleComponent,
        ButtonCopyComponent,
        ShowDetailsDirective,
        ImportExportComponent,
        SageSynchronizationIndicatorComponent,
        DndDirective,
        DndListComponent,
        DndListChildrenComponent,
        HydraPaginationComponent,
        PictoSelectorComponent,
        GenericImageListComponent,
        GenericGridComponent,
        SlidingSidebarComponent,
        IndicatorComponent,
        IndicatorHistoryComponent,
        IsDateBetweenSwitchComponent,
        CustomSnackBarComponent,
        ImportV2FieldsComponent,
    ],

  /**
   * An entry component is any component that Angular loads imperatively,
   * (which means you’re not referencing it in the template), by type.
   * You specify an entry component by bootstrapping it in an NgModule, or including it in a routing definition.
   */
  entryComponents: [
    /**
     * Component is declared in the router:
     *     @example:
     *         views: {
     *             'aside@layout': { template: '<app-tabs></app-tabs>' },
     */
    TabsComponent,
    EditableComponent,
    GenericResultListComponent,
    GenericRowComponent,
    GridComponent,
    ButtonActionMultipleComponent,
    SlidingSidebarComponent,
  ],

  /**
   * The module's imports array appears exclusively in the @NgModule metadata object.
   * It tells Angular about other NgModules that this particular module needs to function properly.
   */
  imports: [
    CommonModule,
    DialogsModule,
    FormsModule,
    DropDownsModule,
    GenericFormModule,
    ReactiveFormsModule,
    SliderModule,
    BsDatepickerModule.forRoot(),
    PaginationModule.forRoot(),
    LayoutModule,
    EnhancedFieldsModule,
    BsDropdownModule.forRoot(),
    ShowDetailsModule,
    UploadModule,
    DndModule,
    SwitchModule,
    TooltipModule,
    PipesModule,
  ],

  /**
   * Export declarable classes that components in other NgModules are able to reference in their templates.
   * These are your public classes.
   */
  exports: [
    CardComponent,
    TabsComponent,
    EditableComponent,
    GenericPageComponent,
    GenericListComponent,
    GenericResultListComponent,
    GenericRowComponent,
    GridComponent,
    FilterListComponent,
    DynamicFormComponent,
    DynamicFormFieldComponent,
    SortableColumnComponent,
    SortableTableDirective,
    ButtonActionMultipleComponent,
    ButtonCopyComponent,
    ImportExportComponent,
    SageSynchronizationIndicatorComponent,
    DndDirective,
    DndListComponent,
    HydraPaginationComponent,
    PictoSelectorComponent,
    GenericImageListComponent,
    GenericGridComponent,
    SlidingSidebarComponent,
    IndicatorComponent,
    IndicatorHistoryComponent,
    CustomSnackBarComponent,
    ImportV2FieldsComponent,
  ],

  /**
   * The providers array is where you list the services the app needs.
   * When you list services here, they are available app-wide.
   */
  providers: [
    SortService,
  ]
})
export class GenericModule {}
