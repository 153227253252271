import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { TabStripComponent } from '@progress/kendo-angular-layout';
import { IImportOptions } from '@interfaces/ImportOptions.interface';
import { OrderManagerLightFiltersFormService } from '../services/order-manager-light-filters-form.service';
import { OrderManagerLightResource } from '../resources/order-manager-light.resource';

@Component({
  selector: 'app-order-manager-list-light',
  template: require('./order-manager-list-light.component.html'),
  providers: [
    { provide: AbstractResource, useClass: OrderManagerLightResource },
    { provide: AbstractFiltersFieldsService, useClass: OrderManagerLightFiltersFormService },
  ]
})
export class OrderManagerListLightComponent extends AbstractPageComponent {

  @ViewChild('tabstrip') public tabstrip: TabStripComponent;

  public importOptions: IImportOptions[] = [];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    public resource: AbstractResource,
  ) {
    super($translate, authService, resource, state);
  }
}
