import * as moment from 'moment';
import { ICustomerDetailOrder } from '@components/customer/interfaces';
import { DATE_SHORT_FORMAT } from '@constants';
import { IOrderItem } from '@interfaces/IOrderItem';

export class CustomerDetailOrderModel implements ICustomerDetailOrder {
  public id: number;
  public orderId: string;
  public date: string;
  public status: string[];
  public amount: number|string;
  public marketplace: string;

  constructor(order: ICustomerDetailOrder) {
    this.id = order.id;
    this.orderId = order.orderId || null;
    this.date = moment(order.date).format(DATE_SHORT_FORMAT);
    this.status = 0 < order.items.length ? this.getStatuses(order.items) : null;
    this.amount = 0 < order.items.length ? this.getOrderAmount(order.items).toFixed(2) : 0;
    this.marketplace = order.marketplace;
  }

  private getStatuses(orderItems: IOrderItem[] = []): string[] {
    return orderItems.map((item) => {
      return item.status.toUpperCase();
    });
  }

  private getOrderAmount(orderItems: IOrderItem[] = []): number {
    const amounts = orderItems.map((item) => {
      return item.totalPrice;
    });

    return amounts.reduce((a, b) => a + b, 0);
  }
}
