import { AbstractResource } from '../resources/abstract.resource';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { Resource } from '@decorators/Resource';

@Injectable()
@Resource({
  entryPoint: '/v2/product_marketplace_statuses',
  isHydra: true,
  createAvailable: false,
  updateAvailable: false,
  deleteAvailable: false,
})
export class ProductMarketplaceStatusResource extends AbstractResource {

  constructor(
    http: HttpClient,
    @Inject('TranslationService') private $translate: ng.translate.ITranslateService,
  ) {
    super(http);
  }

  public getManyWithLabel(params?: object, options: any = {}): Observable<object> {
    return this.getMany(params, options).map((productMarketplaceStatusResponse: any): any => {
      return productMarketplaceStatusResponse['hydra:member'].map((statusCode: string): object => {
        return {
          value: statusCode,
          text: this.$translate.instant(`PAGE.PRODUCT.EDIT.TAB.MARKETPLACES.STATUS.${statusCode.toUpperCase()}`),
        };
      });
    });
  }
}
