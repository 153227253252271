import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  Inject,
  Input,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { AuthService } from '@services';
import { BASE_URL, BASE_URL_API } from '@constants/config.constants';

interface IFile {
  uploader?: FileUploader;
  path?: string;
}

enum Type {
  IMAGE = 'image',
  FILE = 'file',
}

const mimeTypes: any = {
  images: ['image/jpeg', 'image/png', 'image/gif'],
};

@Component({
  selector: 'app-upload',
  template: require('./upload.component.html'),
  styles: [require('./upload.component.scss')],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => UploadComponent), multi: true },
  ]
})
export class UploadComponent implements ControlValueAccessor, AfterViewInit, AfterViewChecked {
  @Input()
  public type: Type = Type.FILE;

  @Input()
  public multiple: boolean = false;

  @ViewChild('placeholder')
  private placeholderView: ElementRef;

  @ViewChild('uploader')
  private uploaderView: ElementRef;

  private onChange: (key: any) => any;
  public value: IFile = {};

  constructor(@Inject('TranslationService') public $translate: ng.translate.ITranslateService) {}

  /**
   * @inheritDoc
   */
  ngAfterViewInit() {
    this.value.uploader = new FileUploader({
      allowedMimeType: this.type && this.isImage() ? mimeTypes.images : undefined,
      authToken: `Bearer ${AuthService.getToken()}`,
      removeAfterUpload: true,
      url: BASE_URL_API,
    });
    this.value.uploader.onAfterAddingFile = (file: FileItem) => {
      file.withCredentials = false;
      this.uploaderView.nativeElement.value = '';
    };
    this.change();
  }

  /**
   * @inheritDoc
   */
  ngAfterViewChecked() {
    // so sad to remove this attribute instead of using angular dynamic values but plugin checks if the attribute is
    // set but don't check the value
    if (!this.multiple && this.uploaderView) {
      this.uploaderView.nativeElement.removeAttribute('multiple');
    }
  }

  /**
   * Translates a specific key.
   */
  public translate(translation: string): string {
    return this.$translate.instant(`BUTTON.UPLOAD.${translation}`);
  }

  /**
   * @inheritDoc
   */
  public writeValue(value: any): void {
    this.value = value;
  }

  /**
   * @inheritDoc
   */
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * @inheritDoc
   */
  public registerOnTouched(fn: any): void { }

  /**
   * @inheritDoc
   */
  public setDisabledState?(isDisabled: boolean): void { }

  /**
   * Checks if the current upload is an image upload form
   *
   * @returns {boolean}
   */
  public isImage(): boolean {
    return Type.IMAGE === this.type;
  }

  /**
   * Gets trigger label
   *
   * @returns {string}
   */
  public getTriggerLabel(): string {
    const type: string = this.isImage() ? 'IMAGE' : 'FILE';
    const multiple: string = this.multiple ? 'MULTIPLE' : 'SINGLE';

    return this.translate(`${type}.${multiple}`);
  }

  /**
   * Triggers file upload selection
   */
  public askForSelection(): void {
    this.placeholderView.nativeElement.blur();
    this.uploaderView.nativeElement.click();
  }

  /**
   * Gets file names which are waiting for upload
   *
   * @returns {string}
   */
  public getFileNames(): string {
    if (0 === this.value.uploader.queue.length) {
      return '';
    }

    return this.value.uploader.queue.map(({ file }: FileItem) => file.name)
      .join(' | ');
  }

  /**
   * Emits uploader's change
   */
  public change(): void {
    this.onChange(this.value);
  }

  /**
   * Removes image path if the load fails
   */
  public handleImageLoadError(): void {
    delete this.value.path;
  }

  /**
   * Get full path of a file (with API prefix)
   *
   * @param {string} path
   * @returns {string}
   */
  public getFullPath(path: string): string {
    if (path) {
      return `${BASE_URL}/${path}`;
    }

    return null;
  }

  /**
   * removes the file
   *
   * @memberof UploadComponent
   */
  public remove(): void {
    if (this.value.uploader.queue.length) {
      this.value.uploader.queue[this.value.uploader.queue.length - 1].remove();
    }

    this.value.path = null;

    this.change();
  }
}
