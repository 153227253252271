import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { HttpClient } from '@angular/common/http';
import { AbstractResource } from '@resources';
import { EavAttributeValueModel } from '@components/eav-attribute/models/eav-attribute-value.model';
import { Observable } from 'rxjs/Observable';

@Injectable()
@Resource({
  routeName: 'eav-attribute-value',
  entryPoint: '/v2/eav_attribute_values',
  model: EavAttributeValueModel,
  isHydra: true
})
export class EavAttributeValueResource extends AbstractResource {
  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  createForMasterProduct(masterProductId: number, body: object, options: any = {}): Observable<object> {
    return this.update(String(masterProductId), body, { entryPoint: `/v2/master_products/${masterProductId}/eav_attribute_values`, ...options });
  }

  getBySkuAndAttributeCodes(sku: string, attributeCodes: string[]): Observable<object> {
    return this.cGet({ sku: sku, attributeCode: attributeCodes}, { returnHydraMembers: true } );
  }
}
