import { IWarehouses } from '@components/warehouses/models';
import { SessionHelper } from '@helpers';
import { ICurrentCarrier } from '@interfaces';

export class CarrierScaleScheduledModel {
  public '@id': string;
  public id: string;
  public warehouse: IWarehouses;
  public fromCarrierGroup: ICurrentCarrier;
  public toCarrierGroup: ICurrentCarrier;
  public fromVolume: number;
  public toVolume: number;
  public maxCarriersDiffCost: number;
  public days: string[];
  public active: boolean;

  constructor(model: any) {
    this['@id'] = model['@id'];
    this.id = model.id;
    this.warehouse = this.getWarehouse(model.warehouse);
    this.fromCarrierGroup = this.getCarrierGroup(model.fromCarrierGroup);
    this.toCarrierGroup = this.getCarrierGroup(model.toCarrierGroup);
    this.fromVolume = model.fromVolume;
    this.toVolume = model.toVolume;
    this.maxCarriersDiffCost = model.maxCarriersDiffCost;
    this.days = model.days;
    this.active = model.active || false;
  }

  private getWarehouse(findWarehouses: string): IWarehouses {
    return SessionHelper.getAllWarehouses().filter((warehouse: IWarehouses) => findWarehouses === warehouse['@id'])[0];
  }

  private getCarrierGroup(findCarrierGroups: string): ICurrentCarrier {
    return SessionHelper.getAllCarrierGroups().filter(
      (carrierGroup: any) => findCarrierGroups === carrierGroup['@id']
    )[0];
  }
}
