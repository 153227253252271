import { SessionHelper } from '@helpers';
import { ICategory } from '@interfaces';

export const DEFAULT_COLOR: string = '#ffffff00';

export class CategoryModel implements ICategory {
  public children: any[] = [];
  public backgroundColor?: string;
  public translations: {};

  constructor(properties: any) {
    const locale: string = SessionHelper.getLocale();

    Object.keys(properties).forEach((key) => {
      Object.defineProperty(
        this,
        key,
        {value: properties[key], writable: true, enumerable: true}
      );
    });

    if (undefined !== properties.parent) {
      Object.defineProperty(
        this,
        'parent',
        {value: properties.parent.id, writable: true, enumerable: true}
      );
    }

    if (properties.translations && properties.translations[locale]) {
      Object.defineProperty(
        this,
        'translation',
        {value: properties.translations[locale], writable: true, enumerable: true}
      );
    }

    // transform children as CategoryModel
    if (this.children.length) {
      this.children = this.children.map((child: any) => new CategoryModel(child));
    }

    this.backgroundColor = !properties.backgroundColor ? DEFAULT_COLOR : properties.backgroundColor;
  }
}
