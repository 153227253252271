import {Component, Inject, Input, ViewEncapsulation} from '@angular/core';
import {AbstractComponent} from '@components/generic/abstract.component';
import {AbstractResource} from '@resources/abstract.resource';
import {AuthService} from '@services/auth.service';
import {OrderManagerRelationRessource} from '@components/order-manager/relation/order-manager-relation.ressource';
import {Observable} from 'rxjs/Observable';
import {IRelation} from '@components/order-manager/relation/order-manager-relation.interface';
import moment = require('moment');
import {takeUntil} from 'rxjs/operators';
import {OrderManagerRelationModel} from '@components/order-manager/relation/order-manager-relation-model';
import {forkJoin} from 'rxjs/observable/forkJoin';
import {generateSummaryForTicket} from './SummaryHelper';

@Component({
  selector: 'app-order-manager-relation',
  template: require('./order-manager-relation-component.html'),
  styles: [require('./order-manager-relation-component.scss')],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {provide: AbstractResource, useClass: OrderManagerRelationRessource}
  ]
})

export class OrderManagerRelationComponent extends AbstractComponent {
  @Input() public order: any;
  public closed = 'closed';
  public opened = 'opened';
  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    public resource: AbstractResource,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService
  ) {
    super($translate, authService, resource, state);
  }

  public addTicket(): void {
    this.state.go('order_manager.relation_new', { id: this.order.id }, { reload: true });
  }

  getOpenedColumn(): any[] {
    return [
      'PAGE.RELATION.TABLE.ID',
      'PAGE.RELATION.TABLE.DATE',
      'PAGE.RELATION.TABLE.ORDER',
      'PAGE.RELATION.TABLE.ORIGIN',
      'PAGE.RELATION.TABLE.LITIGATION',
      'PAGE.RELATION.TABLE.DESCRIPTION',
      'PAGE.RELATION.TABLE.STATUS',
      'PAGE.RELATION.TABLE.WHOM',
    ];
  }

  getClosedColumn(): any[] {
    return [
    'PAGE.RELATION.TABLE.ID',
    'PAGE.RELATION.TABLE.DATE',
    'PAGE.RELATION.TABLE.ORDER',
    'PAGE.RELATION.TABLE.ORIGIN',
    'PAGE.RELATION.TABLE.LITIGATION',
    'PAGE.RELATION.TABLE.DESCRIPTION',
    'PAGE.RELATION.TABLE.STATUS',
    'PAGE.RELATION.TABLE.WHOM',
    'PAGE.RELATION.TABLE.CLOSED_BY',
    'PAGE.RELATION.TABLE.SATISFACTION'
    ];
  }
}
