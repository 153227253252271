import { Injectable, Inject } from '@angular/core';
import { AbstractFormFieldBase } from '@components/generic/Form/dynamic/fields/abstract-form-field-base.class';
import {TextField, DateField, ComboSearchField, MultiSearchField} from '@components/generic/Form/dynamic/fields';
import * as moment from 'moment';
import {DATE_FULL_FORMAT, SIZE_MAX} from '@constants';
import { FormNotifierService } from '@services';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import {CountryHelper, SessionHelper} from '@helpers';
import {ICustomerDetail} from '@components/customer/interfaces';
import {DropDownListField} from '@components/generic/Form/dynamic/fields/select-field.class';

@Injectable()
export class SalesLogExportFiltersFormService extends AbstractFiltersFieldsService {

  public customer: ICustomerDetail;

  public startDateField: TextField = new TextField({
    fieldName: 'startDate',
    value: null,
    type: 'hidden',
    order: 4
  });

  public endDateField: TextField = new TextField({
    fieldName: 'endDate',
    value: null,
    type: 'hidden',
    order: 5,
  });

  public readonly formExportType = [
    { label: this.$translate.instant('PAGE.SALES_LOG.LIST.FORM_ID.SALESLOG'), value: 'salesLog' },
    { label: this.$translate.instant('PAGE.SALES_LOG.LIST.FORM_ID.STOCKMOVEMENT'), value: 'stockMovement' },
  ];


  constructor(
    @Inject('StateService') protected state: ng.ui.IStateService,
    @Inject('TranslationService') public $translate: ng.translate.ITranslateService,
    formNotifier: FormNotifierService
  ) {
    super(formNotifier, state);
  }

  public getFields(): AbstractFormFieldBase<any>[] {

    const fields: AbstractFormFieldBase<any>[] = [
      new ComboSearchField({
        fieldName: 'formId',
        label: 'PAGE.SALES_LOG.LIST.TITLE',
        data: this.formExportType,
        textField: 'label',
        valueField: 'value',
        value: null,
        order: 1,
        valuePrimitive: true
      }),
      new DateField({
        fieldName: 'date',
        label: 'PAGE.COMMERCIAL_OPERATIONS.LIST.FILTERS.DATE',
        dateRange: true,
        value: undefined,
        valueChangedAction: this.setDates.bind(this),
        order: 2
      }),
      new DropDownListField({
        fieldName: 'transactionCommercialEntity',
        label: 'PAGE.COMMERCIAL_OPERATIONS.LIST.FILTERS.COUNTRIES',
        data: SessionHelper.getCountries(),
        textField: 'name',
        valueField: 'code',
        value: undefined ,
        order: 3,
        valuePrimitive: true,
      }),
      this.startDateField,
      this.endDateField,
    ];

    return fields.sort((a, b) => a.order - b.order);
  }

  private setDates(newValue: string) {
    if (newValue) {
      this.startDateField.value = null !== newValue[0] ?
        moment(newValue[0]).startOf('day').format(DATE_FULL_FORMAT) :
        undefined
      ;

      this.endDateField.value = null !== newValue[0] ?
        moment(newValue[1]).endOf('day').format(DATE_FULL_FORMAT) :
        undefined
      ;

      return;
    }

    this.startDateField.value = null;
    this.endDateField.value = null;
  }
}
