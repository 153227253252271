import { AbstractResource } from './abstract.resource';
import { Injectable } from '@angular/core';
import { Resource } from '../decorators/Resource';
import { HttpClient } from '@angular/common/http';
import { CarrierScaleListModel } from '@components/order-manager/models/carrier-scale-list.model';

@Injectable()
@Resource({
  entryPoint: '/v2/carrier_scales',
  listModel: CarrierScaleListModel,
  isHydra: true,
  createAvailable: true,
  createRouteName: 'order_manager.scaling_form',
  deleteAvailable: false,
  updateAvailable: false,
  translationKey: 'CARRIER_SCALE',
  listColumns: [
    { field: 'warehouse', translationKey: 'WAREHOUSE' },
    { field: 'fromCarrierGroup', translationKey: 'FROM_CARRIER' },
    { field: 'toCarrierGroup', translationKey: 'TO_CARRIER' },
    { field: 'volume', translationKey: 'VOLUME' },
    { field: 'scaledVolume', translationKey: 'SCALED_VOLUME' },
    { field: 'startDate', type: 'date', translationKey: 'START_DATE' },
    { field: 'endDate', type: 'date', translationKey: 'END_DATE' },
    { field: 'fileUrl', type: 'download', translationKey: 'FILE' },
    { field: 'automatic', type: 'string', translationKey: 'AUTOMATIC' },
  ],
})
export class CarrierScaleResource extends AbstractResource {
  constructor(http: HttpClient) {
    super(http);
  }
}
