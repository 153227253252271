import {Component, Inject, OnInit} from '@angular/core';
import {AuthService} from '@services/auth.service';
import {AbstractResource} from '@resources/abstract.resource';
import {IProductForm} from '@components/product/interfaces/product-form.interface';
import {ProductResource} from '@components/product/product.resource';
import {AbstractPageComponent} from '@components/generic/abstract-page.component';
import {IButtonLinks} from '@components/generic/buttons/button-links.interface';
import {IMarketplace} from '@interfaces';
import {MarketplaceHelper, SessionHelper} from '@helpers';
import {AddressingModel} from '@models/addressing.model';
import {AddressingResource} from '@resources/addressing.resource';
import {ITabs} from '@components/generic/tabs/tabs.interface';

@Component({
    selector: 'app-spare-part-page',
    template: require('./spare-part-page.component.html'),
    providers: [
        {provide: AbstractResource, useClass: ProductResource},
    ],
})
export class SparePartPageComponent extends AbstractPageComponent implements OnInit {

    public model: IProductForm;
    public buttonLinks: IButtonLinks[];
    public marketplaces: IMarketplace[];
    public pageTitle: string = '';
    public addressings: AddressingModel[] = [];

    constructor(
        @Inject('TranslationService') $translate: ng.translate.ITranslateService,
        authService: AuthService,
        resource: AbstractResource,
        @Inject('StateService') state: ng.ui.IStateService,
        private addressingResource: AddressingResource
    ) {
        super($translate, authService, resource, state);
    }

    ngOnInit() {
        this.marketplaces = [MarketplaceHelper.getWebsiteMarketplace()];

        if (!this.state.params.id && this.state.current.name !== 'spare-part.new') {
            return this.state.go('spare-part.list', {}, {reload: true});
        }

        if (SessionHelper.useLinkedWarehousesOnly()) {
            return this.state.go('spare-part.warehouse-agent', {id: this.state.params.id}, {reload: true});
        }

        if (!this.state.params.id) {
            return;
        }

        this.resource.get(this.state.params.id)
            .takeUntil(this.destroyed$)
            .subscribe((response: IProductForm) => {
                this.model = response;
                this.pageTitle = this.translate('PAGE.PRODUCT.EDIT.TITLE', {sku: response.sku});
                this.buttonLinks = this.formatButtonLinks();
            })
        ;

        this.addressingResource.getMany({
            byProduct: this.state.params.id
        }, {
            isHydra: true,
        }).subscribe((response: AddressingModel[]) => {
            this.addressings = response;
        });
    }

    private formatButtonLinks(): IButtonLinks[] {
        return [
            {
                url: `#!/super-products/${this.model.superProduct.id}`,
                label: this.translate('PAGE.PRODUCT.EDIT.BUTTON_LINKS', {reference: this.model.superProductRef})
            },
            {
                url: `#!/sav/stocks/new
?sparePartId=${this.model.id}
&masterProductId=${this.model.masterProduct.id}
&label=${encodeURIComponent(this.model.superProductRef)}
`,
                label: this.translate('PAGE.SPARE_PART.PAGE.CREATE_STOCK')
            }
        ];
    }
}
