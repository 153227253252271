import { AbstractResource } from '../../resources/abstract.resource';
import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  routeName: 'queues',
  entryPoint: '/rabbitmq/queues',
  translationKey: 'RABBITMQ'
})
export class RabbitMqResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }
}
