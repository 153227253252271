import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { Component, Inject } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AttributeSetResource } from '../attribute-set.resource';
import { AbstractResource } from '@resources/abstract.resource';

@Component({
  selector: 'app-attribute-set-list',
  template: require('./attribute-set-list.component.html'),
  styles: [require('./attribute-set-list.component.scss')],
  providers: [
    { provide: AbstractResource, useClass: AttributeSetResource },
  ]
})
export class AttributeSetListComponent extends AbstractPageComponent {

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }
}
