import { NgModule } from '@angular/core';
import { HeaderTasksStatsComponent } from '@components/header-tasks-stats/header-tasks-stats.component';
import { PopoverModule } from 'ngx-bootstrap';
import { CommonModule } from '@angular/common';
import { NumberToInternationalPrefix } from '../../pipes/number-to-international-prefix.pipe';

@NgModule({
  declarations: [
    HeaderTasksStatsComponent,
    NumberToInternationalPrefix
  ],
  entryComponents: [
    HeaderTasksStatsComponent,
  ],
  imports: [
    PopoverModule,
    CommonModule,
  ],
  exports: [
    HeaderTasksStatsComponent,
  ]
})

export class HeaderTasksStatsModule {}
