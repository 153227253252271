import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { AbstractFormComponent } from '@components/generic/Form/abstract-form.component';
import { EDITION_PAGE } from '@interfaces/IPageComponent';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ISliderItems, ISliderItemsFormValue } from '@components/website-homepage/website-homepage-slider-items/models';
import { SnackbarService } from '@components/snackbar';
import { FormNotifierService } from '@services/form-notifier.service';
import { BASE_URL, DATE_FULL_FORMAT, DATE_SHORT_FORMAT } from '@constants';
import * as moment from 'moment';
import { SkuSearchService } from '@services/sku-search.service';
import {WebsiteHomepageSliderItemsResource} from '@components/website-homepage/website-homepage-slider-items/website-homepage-slider-items.resource';

@Component({
  selector: 'app-website-homepage-slider-items',
  template: require('./website-homepage-slider-items.component.html'),
  providers: [
    { provide: AbstractResource, useClass: WebsiteHomepageSliderItemsResource },
    SkuSearchService
  ],
})
export class WebsiteHomepageSliderItemsComponent extends AbstractFormComponent implements OnInit {
  public form: FormGroup;
  public sliderItem: ISliderItems;
  public EDITION_PAGE = EDITION_PAGE;
  public BASE_URL = BASE_URL;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    private snackbar: SnackbarService,
    private formNotifier: FormNotifierService,
    @Inject('DialogService') private dialogService: any,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    this.buildForm();

    if (EDITION_PAGE === this.pageType) {
      this.fillForm();
    }
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      file: ['', (EDITION_PAGE !== this.pageType) ? Validators.required : null],
      sku: [''],
      link: [''],
      dateStart: [''],
      startTime: [''],
      dateEnd: [''],
      endTime: [''],
      title: [''],
      titleColor: [''],
      description: [''],
      highDescriptionColor: [''],
      lowDescription: [''],
      lowDescriptionColor: [''],
      mediaQuery: ['', Validators.required],
    });
  }

  private fillForm(): void {
    this.resource.get(this.state.params.id)
      .takeUntil(this.destroyed$)
      .subscribe((response: ISliderItems) => {
        this.sliderItem = response;
        this.form.patchValue(response);
      })
    ;
  }

  private submit(event: {redirect: boolean}): void {
    if (EDITION_PAGE === this.pageType) {
      this.update(event);

      return;
    }

    this.create(event);
  }

  private create(event?: {redirect: boolean}): void {
    this.dialogService.confirm(this.translate('PAGE.SLIDER_ITEMS.CONFIRM.SAVE'))
      .then(() => {
        this.resource.uploadFile(this.prepareFormDataBody())
          .takeUntil(this.destroyed$)
          .subscribe((response: ISliderItems) => {
            this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
            this.formNotifier.notifyFormSubmitted();
            this.form.reset();
            event && event.redirect ?
              this.state.go(`${this.resource.routeName}.list`) :
              this.state.go(`${this.resource.routeName}.edit`, {id: response.id});
          });
      });
  }

  private update(event?: {redirect: boolean}): void {
    this.dialogService.confirm(this.translate('PAGE.SLIDER_ITEMS.CONFIRM.UPDATE'))
      .then(() => {
        this.resource.uploadFile(this.prepareFormDataBody(), { entryPoint: `${this.resource.entryPoint}?id=${this.state.params.id}` })
          .takeUntil(this.destroyed$)
          .subscribe(() => {
            this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
            this.formNotifier.notifyFormSubmitted();
            event && event.redirect ?
              this.state.go(`${this.resource.routeName}.list`) :
              this.state.go(`${this.resource.routeName}.edit`, {id: this.state.params.id}, {reload: true});
          });
      });
  }

  private prepareFormDataBody(): FormData {
    const formValue: ISliderItemsFormValue = this.form.value;
    let file: File;

    if (undefined !== (<HTMLInputElement & EventTarget>formValue.file.target) &&
      (<HTMLInputElement & EventTarget>formValue.file.target).files.length > 0) {
      file = (<HTMLInputElement & EventTarget>formValue.file.target).files[0];
    }

    const formData = new FormData();

    if (file) {
      formData.append('file', file);
    }

    if (formValue.sku) {
      formData.append('productMarketplace', formValue.sku.id);
    }

    formValue.link ? formData.append('link', formValue.link) : formData.append('link', '');

    if (formValue.title) {
      formData.append('title', formValue.title);
    }
    if (formValue.titleColor) {
      formData.append('titleColor', formValue.titleColor);
    }

    if (formValue.dateStart) {
      if (formValue.startTime) {
        formData.append('dateStart', moment(formValue.dateStart, DATE_SHORT_FORMAT)
          .add(formValue.startTime.getHours(), 'hours')
          .add(formValue.startTime.getMinutes(), 'minutes')
          .add(formValue.startTime.getSeconds(), 'seconds')
          .format(DATE_FULL_FORMAT));
      } else {
        formData.append('dateStart', moment(formValue.dateStart, DATE_SHORT_FORMAT).startOf('day').format(DATE_FULL_FORMAT));
      }
    }

    if (formValue.dateEnd) {
      if (formValue.endTime) {
        formData.append('dateEnd', moment(formValue.dateEnd, DATE_SHORT_FORMAT)
          .add(formValue.endTime.getHours(), 'hours')
          .add(formValue.endTime.getMinutes(), 'minutes')
          .add(formValue.endTime.getSeconds(), 'seconds')
          .format(DATE_FULL_FORMAT));
      } else {
        formData.append('dateEnd', moment(formValue.dateEnd, DATE_SHORT_FORMAT).endOf('day').format(DATE_FULL_FORMAT));
      }
    }

    if (formValue.description) {
      formData.append('description', formValue.description);
    }
    if (formValue.highDescriptionColor) {
      formData.append('highDescriptionColor', formValue.highDescriptionColor);
    }
    if (formValue.lowDescription) {
      formData.append('lowDescription', formValue.lowDescription);
    }
    if (formValue.lowDescriptionColor) {
      formData.append('lowDescriptionColor', formValue.lowDescriptionColor);
    }
    if (formValue.mediaQuery) {
      formData.append('mediaQuery', formValue.mediaQuery);
    }

    formData.append('locale', this.currentLocale);

    return formData;
  }

  private prepareBody(): ISliderItems {
    return {
      locale: this.currentLocale,
      link: this.form.get('link').value,
      dateStart: this.form.get('dateStart').value && this.form.get('startTime').value ?
        moment(this.form.get('dateStart').value, DATE_SHORT_FORMAT)
          .add(this.form.get('startTime').value.getHours(), 'hours')
          .add(this.form.get('startTime').value.getMinutes(), 'minutes')
          .add(this.form.get('startTime').value.getSeconds(), 'seconds')
          .format(DATE_FULL_FORMAT) : this.form.get('dateStart').value ?
          moment(this.form.get('dateStart').value, DATE_SHORT_FORMAT)
            .startOf('day')
            .format(DATE_FULL_FORMAT) : null,
      dateEnd: this.form.get('dateEnd').value && this.form.get('endTime').value ?
        moment(this.form.get('dateEnd').value, DATE_SHORT_FORMAT)
          .add(this.form.get('endTime').value.getHours(), 'hours')
          .add(this.form.get('endTime').value.getMinutes(), 'minutes')
          .add(this.form.get('endTime').value.getSeconds(), 'seconds')
          .format(DATE_FULL_FORMAT) : this.form.get('dateEnd').value ?
          moment(this.form.get('dateEnd').value, DATE_SHORT_FORMAT)
            .startOf('day')
            .format(DATE_FULL_FORMAT) : null,
      title: this.form.get('title').value ? this.form.get('title').value : null,
      titleColor: this.form.get('titleColor').value,
      description: this.form.get('description').value,
      highDescriptionColor: this.form.get('highDescriptionColor').value,
      lowDescription: this.form.get('lowDescription').value,
      lowDescriptionColor: this.form.get('lowDescriptionColor').value,
      productMarketplace: this.form.get('sku').value ? this.form.get('sku').value.id : null,
      mediaQuery: this.form.get('mediaQuery').value
    };
  }
}
