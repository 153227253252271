import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@services';
import { AbstractResource, CarrierGroupResource } from '@resources';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { Observable } from 'rxjs/Observable';
import {BASE_URL_API, DATE_SHORT_INTERNATIONAL_FORMAT} from '@constants';
import { SessionHelper } from '@helpers';
import {Subscription} from 'rxjs/Subscription';
import * as moment from 'moment';
import { FormBuilder } from '@angular/forms';
import { SnackbarService } from '@components/snackbar';

@Component({
  selector: 'app-carrier-blacklist-postal-codes',
  template: require('./carrier-blacklist-postal-codes.component.html'),
  styles: [require('./carrier-blacklist-postal-codes.component.scss')],
  providers: [
    { provide: AbstractResource, useClass: CarrierGroupResource },
  ]
})
export class CarrierBlacklistPostalCodesComponent extends AbstractPageComponent implements OnInit {

  /**
  * Needed by kendo-upload to set the FormData property name.
 */
  public readonly uploadFieldName = 'file';
  public export$: Observable<object>;
  public importEntryPoint: string;
  public importAllowedExtensions: string[] = ['.csv'];
  public carrierId: string;
  public invalidPostalCodes: any;
  public validPostalCodes: any;
  public importValidPostcodesOptions: any[];
  public exportValidPostcodesOptions: any[];
  public importInvalidPostcodesOptions: any[];
  public exportInvalidPostcodesOptions: any[];
  addPostCodeBlacklist = this.formBuilder.group({
    postCodeBlacklist: ''
  });
  addPostCodeWhitelist = this.formBuilder.group({
    postCodeWhitelist: ''
  });

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    public carrierGroupResource: CarrierGroupResource,
    private formBuilder: FormBuilder,
    private snackbar: SnackbarService,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    this.carrierId = this.state.params.id;
    this.validPostalCodes = [];
    this.invalidPostalCodes = [];
    this.carrierGroupResource.getMany(
      {
        locale: this.currentLocale,
        countryCode: SessionHelper.getCountry() && SessionHelper.getCountry().code,
      },
      {isHydra: false, dontUseModel: true, entryPoint: `/v2/carrier_groups/${this.carrierId}/invalid_postcodes`}
    )
       .subscribe((data) => {
       this.invalidPostalCodes = data;
     });

    this.carrierGroupResource.getMany(
      {
        locale: this.currentLocale,
        countryCode: SessionHelper.getCountry() && SessionHelper.getCountry().code,
      },
      {isHydra: false, dontUseModel: true, entryPoint: `/v2/carrier_groups/${this.carrierId}/valid_postcodes`}
    )
      .subscribe((data) => {
        this.validPostalCodes = data;
      });
    this.importEntryPoint = `${BASE_URL_API}/v2/import`;

    this.importValidPostcodesOptions = [
      {
        entryPoint: '/api/v2/import',
        importButton: 'PAGE.CARRIER.WHITELIST.IMPORT_LABEL',
        businessObject: 'carrier_valid_postcodes',
        businessObjectId: this.carrierId
      },
    ];

    this.exportValidPostcodesOptions = [
      {
        translationKey: 'PAGE.CARRIER.WHITELIST.EXPORT',
        entryPoint: '/v2/export',
        responseType: 'text/csv',
        type: 'text/csv',
        filename: `export-whitelisted-postcodes-${moment().format(DATE_SHORT_INTERNATIONAL_FORMAT)}.csv`,
        filters: false,
        roles: ['ROLE_WALISOFT_AGENT'],
        name: 'carrier_valid_postcodes',
        postOptions: {
          exportCode: 'carrier_valid_postcodes',
          formatCode: 'csv',
          dryRun: false,
          deferred: false,
          split: false,
          serializationGroups: [],
          limit: this.state.params.id + '|' + SessionHelper.getCountry().code,
          async: false
        }
      }
    ];

    this.importInvalidPostcodesOptions = [
      {
        entryPoint: '/api/v2/import',
        importButton: 'PAGE.CARRIER.BLACKLIST.IMPORT_LABEL',
        businessObject: 'carrier_invalid_postcodes',
        businessObjectId: this.carrierId
      },
    ];

    this.exportInvalidPostcodesOptions = [
      {
        translationKey: 'PAGE.CARRIER.BLACKLIST.EXPORT',
        entryPoint: '/v2/export',
        responseType: 'text/csv',
        type: 'text/csv',
        filename: `export-blacklisted-postcodes-${moment().format(DATE_SHORT_INTERNATIONAL_FORMAT)}.csv`,
        filters: false,
        roles: ['ROLE_WALISOFT_AGENT'],
        name: 'carrier_invalid_postcodes',
        postOptions: {
          exportCode: 'carrier_invalid_postcodes',
          formatCode: 'csv',
          dryRun: false,
          deferred: false,
          split: false,
          serializationGroups: [],
          limit: this.state.params.id + '|' + SessionHelper.getCountry().code,
          async: false
        }
      }
    ];
  }
  public exportWhitelistCsv() {
    // tslint:disable-next-line:max-line-length
    const subscriber: Subscription = this.resource.exportFile({}, this.exportValidPostcodesOptions[0]).subscribe(() => {}, undefined, () => subscriber.unsubscribe());
  }

  public exportBlacklistCsv() {
    // tslint:disable-next-line:max-line-length
    const subscriber: Subscription = this.resource.exportFile({}, this.exportInvalidPostcodesOptions[0]).subscribe(() => {}, undefined, () => subscriber.unsubscribe());
  }

  onSubmitBlacklist(): void {
    if (this.addPostCodeBlacklist.value.postCodeBlacklist === '') {
      this.snackbar.alert(this.translate('PAGE.CARRIER.BLACKLIST.EMPTY_VALUE'));
      return;
    }
    if (false === /^[a-z0-9]+$/i.test(this.addPostCodeBlacklist.value.postCodeBlacklist)) {
      this.snackbar.alert(this.translate('PAGE.CARRIER.BLACKLIST.MULTIPLE_VALUES'));
      return;
    }
    this.invalidPostalCodes.push(this.addPostCodeBlacklist.value.postCodeBlacklist);
    this.carrierGroupResource.addPostCode(SessionHelper.getCountry().code, this.carrierId, 'invalid_postcodes', this.invalidPostalCodes).subscribe((data) => {
    this.snackbar.validate(this.translate('PAGE.CARRIER.BLACKLIST.SAVED_VALUE'));
    });
    this.addPostCodeBlacklist.reset();
  }

  onSubmitWhitelist(): void {
    if (this.addPostCodeWhitelist.value.postCodeWhitelist === '') {
      this.snackbar.alert(this.translate('PAGE.CARRIER.BLACKLIST.EMPTY_VALUE'));
      return;
    }
    if (false === /^[a-z0-9]+$/i.test(this.addPostCodeWhitelist.value.postCodeWhitelist)) {
      this.snackbar.alert(this.translate('PAGE.CARRIER.BLACKLIST.MULTIPLE_VALUES'));
      return;
    }
    this.validPostalCodes.push(this.addPostCodeWhitelist.value.postCodeWhitelist);
    this.carrierGroupResource.addPostCode(SessionHelper.getCountry().code, this.carrierId, 'valid_postcodes', this.validPostalCodes).subscribe((data) => {
      this.snackbar.validate(this.translate('PAGE.CARRIER.BLACKLIST.SAVED_VALUE'));
    });
    this.addPostCodeWhitelist.reset();
  }
}
