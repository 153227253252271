import { Injectable } from '@angular/core';
import { AbstractResource } from '../../../resources/abstract.resource';
import 'rxjs/add/operator/toPromise';
import { SessionHelper } from '../../../helpers/session.helper';
import { CarrierFamilyModel } from '@components/carrierFamily/models/carrier-family.model';
import { Resource } from '@decorators/Resource';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  entryPoint: '/v2/carrier_families',
  model: CarrierFamilyModel,
  cGetDefaultFilters: () => ({'country.code': SessionHelper.getCountry() && SessionHelper.getCountry().code, 'active': 1}),
  translationKey: 'CARRIER.CARRIER_FAMILIES',
  deleteAvailable: false,
  updateAvailable: true,
  isHydra: true,
  routeName: 'carrierFamilies',
  buttonActionMultipleAvailable: false,
})
export class CarrierFamilyResource extends AbstractResource {

  protected nullableProperties: string[] = ['description', 'title', 'shippingPrice'];

  constructor(http: HttpClient) {
    super(http);
  }
}
