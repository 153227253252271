import { IWarranties, IWarranty } from '@components/warranty/models/warranty.interface';
import { SessionHelper } from '@helpers';

export class WarrantyListModel implements IWarranties {
    id: number;
    label: string;
    months: number;

    constructor(warranty: IWarranty) {
        this.id = warranty.id;
        this.label = warranty.translations ? WarrantyListModel.getLabel(warranty.translations) : '';
        this.months = warranty.months;
    }

    private static getLabel(translations: any): string {
        const locale: string = SessionHelper.getLocale();

        return translations[locale] ? translations[locale].label : '';
    }
}
