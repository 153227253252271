import { Injectable } from '@angular/core';
import { Resource } from '@decorators/Resource';
import { AbstractResource } from '@resources/abstract.resource';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';

@Injectable()
@Resource({
  entryPoint: '/v2/litigation_statuses',
  isHydra: true,
})
export class LitigationStatusResource extends AbstractResource {

  constructor(http: HttpClient) {
    super(http);
  }
}
