import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericFormModule, GenericModule } from '@components/generic';
import { PipesModule } from '../../pipes/pipes.module';
import { EavAttributeListComponent } from '@components/eav-attribute/list/eav-attribute-list.component';
import { EavAttributeResource } from '@components/eav-attribute/resources/eav-attribute.resource';
import { EavAttributeFormComponent } from '@components/eav-attribute/form/eav-attribute-form.component';
import { DialogModule, WindowModule } from '@progress/kendo-angular-dialog';
import { SharedModule } from '@progress/kendo-angular-dropdowns';
import { EavAttributeOptionResource } from '@components/eav-attribute/resources/eav-attribute-option.resource';

@NgModule({
  declarations: [
    EavAttributeFormComponent,
    EavAttributeListComponent
  ],
  entryComponents: [
    EavAttributeFormComponent,
    EavAttributeListComponent
  ],
  providers: [
    EavAttributeResource,
    EavAttributeOptionResource
  ],
  imports: [
    CommonModule,
    GenericModule,
    DialogModule,
    SharedModule,
    WindowModule,
    GenericFormModule,
    PipesModule
  ]
})
export class EavAttributeModule {}
