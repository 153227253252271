import { Component, Inject, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AuthService } from '@services';
import { AbstractFormComponent } from '@components/generic/Form/abstract-form.component';
import { IFormViolation } from '@interfaces';
import { ICategories } from '@components/categories/models';

@Component({
  selector: 'app-seo-form-translations',
  template: require('./seo-form-translations.component.html'),
})
export class SeoFormTranslationsComponent extends AbstractFormComponent {
  @Input() public form: FormGroup;
  @Input() public parentForm: FormGroup;
  @Input() public violations: IFormViolation[] = [];
  @Input() public locale: string;
  @Input() public model: ICategories;
  @Input() public inCreation: boolean;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, null, state);
  }
}
