import {Component, Inject, Input, ViewEncapsulation} from '@angular/core';
import { AbstractFormComponent } from '@components/generic/Form/abstract-form.component';
import { FormGroup } from '@angular/forms';
import { IFormViolation, ISku } from '@interfaces';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { AuthService } from '@services';
import { SnackbarService } from '@components/snackbar';
import { FILE_EXTENSIONS } from '@constants';
import { TabImagesResource } from '@components/tab/tab-images.resource';
import { IFileInfo } from '@components/generic/Form/file-uploader/interfaces/file-info.interface';
import { ITabTranslation } from '@components/tab/models';

@Component({
  selector: 'app-tab-management-upload',
  template: require('./tab-management-upload.component.html'),
  styles: [require('./tab-management-upload.component.scss')],
  encapsulation: ViewEncapsulation.None
})
export class TabManagementUploadComponent extends AbstractFormComponent {

  public fileRestrictions: FileRestrictions;
  public violations: IFormViolation[] = [];

  @Input() public form: FormGroup;
  @Input() public translations: ITabTranslation;
  @Input() public simple: boolean;
  @Input() public field: string;
  @Input() public translationKey: string;
  @Input() public withDates: boolean = true;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private snackbar: SnackbarService,
    resource: TabImagesResource,
  ) {
    super($translate, authService, resource, state);

    this.fileRestrictions = { allowedExtensions: FILE_EXTENSIONS };
  }

  public updateFileControlValue(e: IFileInfo[]): void {
    this.form.markAsDirty();
    this.form.get(this.field).get('file').patchValue(e);
  }

  public updateSkuControlValue(e: ISku): void {
    this.form.markAsDirty();
    this.form.get(this.field).get('productMarketplace').patchValue(undefined === e ? null : e);
  }

  public deleteTabThumbnail(): void {
    this.translations[this.field].imageUrl = '';
    this.form.markAsDirty();
    this.form.get(this.field).get('file').patchValue({
      file: null,
      src: null,
      locale: null,
      additionalContentForm: null
    });
  }
}
