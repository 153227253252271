import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericFormModule } from '@components/generic/generic-form.module';
import { GenericModule } from '@components/generic/generic.module';
import { ProductModule } from '@components/product';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { FormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap';
import { CommitmentListComponent } from '@components/commitment/commitment-list.component';
import { CommitmentResultListComponent } from '@components/commitment/commitment-result-list.component';
import { CommitmentResource } from '@components/commitment/commitment.resource';

@NgModule({
  declarations: [
    CommitmentListComponent,
    CommitmentResultListComponent,
  ],
  entryComponents: [
    CommitmentListComponent,
  ],
  imports: [
    CommonModule,
    GenericFormModule,
    GenericModule,
    ProductModule,
    DropDownListModule,
    FormsModule,
    BsDropdownModule,
  ],
  exports: [
    CommitmentListComponent,
    CommitmentResultListComponent,
  ],
  providers: [
    CommitmentResource,
  ],
})

export class CommitmentModule { }
