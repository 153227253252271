import { AbstractComponent } from '@components/generic/abstract.component';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { OrderResource } from '@resources';
import { takeUntil } from 'rxjs/operators';
import { IHydraMember } from '@interfaces/hydra-resource.interface';

@Component({
  selector: 'app-order-manager-associated',
  template: require('./order-manager-associated.component.html'),
})
export class OrderManagerAssociatedComponent extends AbstractComponent implements OnInit {

  @Input() public order: any;

  private associated: any = {sav: [], forwarding: [], classic: []};

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService
  ) {
    super($translate, authService, resource, state);
  }

  public ngOnInit(): void {
    this.fetchAssociated();
  }

  public fetchAssociated(): void {
    (<OrderResource>this.resource).getAssociated(this.state.params.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: IHydraMember[]) => {
        response.forEach((order: any) => {
          if (order.supportedBy) {
            this.associated.sav.push(order);
          } else if ('sav' === order.orderType) {
            this.associated.forwarding.push(order);
          } else {
            this.associated.classic.push(order);
          }
        });
      })
    ;
  }
}
