import { Injectable, Inject } from '@angular/core';
import { AbstractFormFieldBase } from '@components/generic/Form/dynamic/fields/abstract-form-field-base.class';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { FormNotifierService } from '@services/form-notifier.service';

@Injectable()
export class GlossaryListFiltersService extends AbstractFiltersFieldsService {
  constructor(
    @Inject('StateService') protected state: ng.ui.IStateService,
    @Inject('TranslationService') public $translate: ng.translate.ITranslateService,
    formNotifier: FormNotifierService,
  ) {
    super(formNotifier, state);
  }

  public getFields(): AbstractFormFieldBase<any>[] {
   return [];
  }
}
