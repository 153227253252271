/** @ngInject */
export default function LogisticWaveRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'walisoft',
    sidebarSubNav: 'logistic-wave',
    title: '',
    reloadChangeCountry: 'logistic-wave.list'
  };

  $stateProvider
    .state('logistic-wave', {
      url: '/logistic-wave',
      parent: 'walisoft',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.LOGISTIC_WAVE',
        stateName: 'logistic-wave.list'
      },
      data: Object.assign(commonStateData, {
        showCountries: false
      })
    })
    .state('logistic-wave.list', {
      url: `?
        page
        &byPage
      `,
      views: {
        'page@layout': {
          template: '<app-logistic-wave-list></app-logistic-wave-list>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.LOGISTIC_WAVE.LIST.TITLE'
      }),
    });
}
