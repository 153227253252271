import { AfterViewInit, Directive, ElementRef, HostListener, Inject, Input, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { StringHelper } from '@helpers';

@Directive({selector: 'input[inputCounter], textarea[inputCounter]'})
export class InputCounterDirective implements OnInit, AfterViewInit {
  @Input()
  public limit: number;

  @Input()
  public displayLabel: boolean = false;

  @Input()
  public displayLimit: boolean = false;

  private counter: any;
  private maxPart: string = '';
  private label: string;

  constructor(
    private element: ElementRef,
    @Inject(DOCUMENT) private document: any,
    @Inject('TranslationService') private $translate: ng.translate.ITranslateService,
  ) {}

  /**
   * @inheritDoc
   */
  ngOnInit() {
    this.label = this.displayLabel ? this.$translate.instant('BUTTON.CHARACTERS') : '';
    this.maxPart = this.displayLimit && undefined !== this.limit ? ' / ' + this.limit : '';

    const element = this.element.nativeElement;
    this.counter = this.document.createElement('span');
    this.counter.classList.add('input-group-addon');
    this.counter.innerHTML = 0;
    element.after(this.counter);
  }

  ngAfterViewInit(): void {
    this.changeValue();
  }

  /**
   * Changes counter value and trigger counter limit checks
   */
  changeValue(): void {
    const value = this.element.nativeElement.value;
    const length: number = StringHelper.countChar(value);
    this.counter.innerHTML = this.label + length + this.maxPart;
    this.checkLimit(length);
  }

  checkLimit(length: number): void {
    if (undefined !== this.limit) {
      if (length > this.limit) {  // if limits is reached we alert the user by changing background
        this.counter.classList.add('input-group-addon-danger');
      } else {
        this.counter.classList.remove('input-group-addon-danger');

        if (length > this.limit * .85) {  // if limits is almost reached we warn the user by changing background
          this.counter.classList.add('input-group-addon-warn');
        } else {
          this.counter.classList.remove('input-group-addon-warn');
        }
      }
    }
  }

  @HostListener('keyup') onKeyUp() {
    this.changeValue();
  }
}
