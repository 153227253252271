export {
  AdditionalPropertyFieldType,
  AdditionalPropertyFieldTypes,
  IAdditionalPropertyTranslation,
  IAdditionalPropertyValue,
  IRestrictedValue,
  ITaskAdditionalProperty,
};

/**
 * Additional properties are related to a task type.
 * They represent fields like text or single-choice that user can be attach to a task.
 */
interface ITaskAdditionalProperty {
  '@id': string;
  id: string;

  /**
   * The type of the field.
   */
  type: AdditionalPropertyFieldType;

  /**
   * Default value of the field.
   * Can be array of value, string null ...
   */
  default?: any;

  /**
   * A list of restricted value (imposed values)
   */
  restrictedValues: IRestrictedValue[];

  /**
   * The translations for the label of the additional property.
   */
  translations: {
    [keys: string]: IAdditionalPropertyTranslation
  };

  /**
   * Value of the additional property. Can be a restricted value or free text or other like array of value.
   */
  value: any;
}

interface IRestrictedValue {
  /**
   * The value to send to API.
   */
  valueCode: string;

  /**
   * The translations for the value of the restricted value.
   */
  translations: {
    [keys: string]: {
      value: string;
    }
  };
}

interface IAdditionalPropertyTranslation {
  label: string;
}

type AdditionalPropertyFieldType = 'choice-single'|'text';

enum AdditionalPropertyFieldTypes {
  SELECT = 'choice-single',
  TEXT = 'text',
}

interface IAdditionalPropertyValue {
  value: any;
}
