import { IHydraMember } from '@interfaces/hydra-resource.interface';
import { SupplierModel } from '@components/supplier/models/supplier.model';
import { ProformaInvoiceItemModel } from '@components/proforma-invoice/models/proforma-invoice-item.model';

export class ProformaInvoiceModel {
  '@id': string;
  id: number;
  commercialEntityId: number;
  warehouseCode: string;
  supplier: SupplierModel;
  proformaInvoiceItems: ProformaInvoiceItemModel[];
  identifier: string;
  date: Date;
  currency: string;
  incoterms: string;
  cargoReadyDate?: Date;
  sparePartNote?: string;
  note?: string;
  createdAt?: Date;
  estimatedTimeOfDelivery?: Date;
  cubicMeter: number;
  containerNumberRequired: number;
  price: number;

  constructor(object: IHydraMember) {
    this['@id'] = object['@id'];
    this.id = object.id;
    this.proformaInvoiceItems = object.proformaInvoiceItems.map((proformaInvoiceItem: IHydraMember) => new ProformaInvoiceItemModel(proformaInvoiceItem));
    this.identifier = object.identifier;
    this.date = object.date ? new Date(object.date) : new Date();
    this.currency = object.currency;
    this.incoterms = object.incoterms;
    this.cargoReadyDate = object.cargoReadyDate ? new Date(object.cargoReadyDate) : null;
    this.sparePartNote = object.sparePartNote;
    this.note = object.note;
    this.createdAt = object.createdAt ? new Date(object.createdAt) : null;
    this.estimatedTimeOfDelivery = object.estimatedTimeOfDelivery ? new Date(object.estimatedTimeOfDelivery) : null;
    this.cubicMeter = object.cubicMeter;
    this.containerNumberRequired = object.containerNumberRequired;
    this.price = object.price;

    if (typeof object.warehouse === 'object' && object.warehouse !== null) {
      this.warehouseCode = object.warehouse.code;
    }

    if (typeof object.supplier === 'object' && object.supplier !== null) {
      this.supplier = new SupplierModel(object.supplier);
    }

    if (object.commercialEntity) {
      this.commercialEntityId = Number(object.commercialEntity.split('/').pop());
    }
  }
}
