import { AbstractSearchFieldClass } from './abstract-search-field.class';

/**
 * Generic multi search field, used to render multi select component.
 * (Actually using kendo-multiselect)
 */
export class MultiSearchField extends AbstractSearchFieldClass {
  /**
   * Used in the template to render the kendo-multiselect component.
   *
   * @type {string}
   */
  fieldType = 'multiSearch';

  /**
   * Allow search on custom terms
   */
  public allowCustom: boolean;

  constructor(options: any = {}) {
    super(options);
    this.allowCustom = options.allowCustom || false;
  }
}
