import { ICategoriesMarketplace } from '@components/categories-marketplace/models/categories-marketplace.interface';
import { SessionHelper } from '@helpers/session.helper';

export class CategoriesMarketplaceModel implements ICategoriesMarketplace {
  active: boolean;
  code: string;
  deprecated: string;
  id: string;
  label: string;
  leaf: string;
  marketplace: string;
  parent: ICategoriesMarketplace;
  relatedProducts: number;

  constructor(categoriesMarketplace: ICategoriesMarketplace) {
    this.active = categoriesMarketplace.active;
    this.code = categoriesMarketplace.code;
    this.deprecated = categoriesMarketplace.deprecated
      ? 'PAGE.CATEGORIES_MARKETPLACE.BOOLEANS.YES'
      : 'PAGE.CATEGORIES_MARKETPLACE.BOOLEANS.NO';
    this.id = categoriesMarketplace.id;
    this.label = categoriesMarketplace.label;
    this.leaf = categoriesMarketplace.leaf
      ? 'PAGE.CATEGORIES_MARKETPLACE.BOOLEANS.YES'
      : 'PAGE.CATEGORIES_MARKETPLACE.BOOLEANS.NO';
    this.marketplace = this.getMarketplaceName(categoriesMarketplace.marketplace);
    this.parent = categoriesMarketplace.parent;
    this.relatedProducts = categoriesMarketplace.relatedProducts;
  }

  public getMarketplaceName(code: string) {
    return SessionHelper.getMarketplaces().find(marketplace => marketplace.code === code).name;
  }
}
