import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services/auth.service';
import { Inject } from '@angular/core';

const STATUS_NEW: string = 'new';
const STATUS_PENDING_CONTENT: string = 'pending_content';
const STATUS_PENDING_TRANSLATION_CHECK: string = 'pending_translation_check';
const STATUS_CONTENT_VALID: string = 'content_valid';
const STATUS_READY_FOR_SALE: string = 'ready_for_sale';
const STATUS_END_OF_LIFE: string = 'end_of_life';

const PRODUCT_STATUSES: Array<string> = [
  STATUS_NEW,
  STATUS_PENDING_CONTENT,
  STATUS_PENDING_TRANSLATION_CHECK,
  STATUS_CONTENT_VALID,
  STATUS_READY_FOR_SALE,
  STATUS_END_OF_LIFE,
];

const PRODUCT_STATUSES_BACKGROUND = new Map([
  [STATUS_NEW, 'bg-red'],
  [STATUS_PENDING_CONTENT, 'bg-yellow-saffron'],
  [STATUS_PENDING_TRANSLATION_CHECK, 'bg-yellow-gold'],
  [STATUS_CONTENT_VALID, 'bg-green-jungle'],
  [STATUS_READY_FOR_SALE, 'bg-blue-madison'],
  [STATUS_END_OF_LIFE, 'bg-black'],
]);

export abstract class AbstractProductStatus extends AbstractComponent {
  protected readonly statuses: Array<{[keys: string]: string}> = [
    {label: this.translate('PAGE.PRODUCT.STATUSES.STATUS_NEW'), apiValue: STATUS_NEW},
    {label: this.translate('PAGE.PRODUCT.STATUSES.STATUS_PENDING_CONTENT'), apiValue: STATUS_PENDING_CONTENT},
    {label: this.translate('PAGE.PRODUCT.STATUSES.STATUS_PENDING_TRANSLATION_CHECK'), apiValue: STATUS_PENDING_TRANSLATION_CHECK},
    {label: this.translate('PAGE.PRODUCT.STATUSES.STATUS_CONTENT_VALID'), apiValue: STATUS_CONTENT_VALID},
    {label: this.translate('PAGE.PRODUCT.STATUSES.STATUS_READY_FOR_SALE'), apiValue: STATUS_READY_FOR_SALE},
    {label: this.translate('PAGE.PRODUCT.STATUSES.STATUS_END_OF_LIFE'), apiValue: STATUS_END_OF_LIFE},
  ];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, null, state);
  }

  /**
   * Gets the product status background style.tatus
   * @returns {string} - The css class
   */
  protected getProductStatusBackground(status: string): string {
    if (PRODUCT_STATUSES.includes(status)) {
      return PRODUCT_STATUSES_BACKGROUND.get(status);
    }
  }

  /**
   * Gets the product status.
   */
  protected getProductStatusLabel(status: string): string {
    if (PRODUCT_STATUSES.includes(status)) {
      return this.translate('PAGE.PRODUCT.STATUSES.STATUS_' + status.toUpperCase());
    }
  }

  protected isReadOnlyStatus(status: string): boolean {
    return [STATUS_PENDING_TRANSLATION_CHECK].includes(status);
  }
}
