import { TaskTypeResource } from '@components/generic/task-manager/resources';

export default taskTypeRoutes;

/** @ngInject */
function taskTypeRoutes($stateProvider: any) {
  const commonStateData: {[key: string]: any} = {
    sidebarNav: 'parameters',
    sidebarSubNav: 'task',
    title: '',
    reloadChangeCountry: 'task-manager.list',
  };

  $stateProvider
    .state('task-manager', {
      parent: 'walisoft',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.TASK_MANAGER',
        stateName: 'task-manager.list'
      }
    })
    .state('task-manager.list', {
      url: '/task-manager',
      views: {
        'page@layout': {
          template: '<app-task-type-list></app-task-type-list>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.TASK_MANAGER.TASK_TYPE.LIST.TITLE',
        resource: TaskTypeResource
      })
    })
    .state('task-manager.edit', {
      url: '/task-manager/:id/edit',
      breadcrumb: {
        label: 'BREADCRUMB.EDIT'
      },
      views: {
        'page@layout': {
          template: '<app-task-type-edit></app-task-type-edit>'
        }
      },
      data: Object.assign(commonStateData, {
        reloadChangeCountry: 'task-manager.edit',
        reloadChangeCountrySearchParam: 'id',
        title: 'PAGE.TASK_MANAGER.TASK_TYPE.EDIT.TITLE'
      })
    })
  ;
}
