const TYPE_SELECT: string = 'Select';
const TYPE_TEXT: string = 'Text';
const TYPE_DATE: string = 'Date';
const TYPE_WYSIWYG: string = 'Wysiwyg';

export class ProductMarketplaceAttributeFieldHelper {

  /**
   * Checks if a field is a select box
   *
   * @static
   * @param {string} type
   * @returns {boolean}
   * @memberof ProductMarketplaceAttributeFieldHelper
   */
  public static isSelect(type: string): boolean {
    return TYPE_SELECT === type;
  }

  /**
   * Checks if a field is a input text
   *
   * @static
   * @param {string} type
   * @returns {boolean}
   * @memberof ProductMarketplaceAttributeFieldHelper
   */
  public static isText(type: string): boolean {
    return TYPE_TEXT === type;
  }

  /**
   * Checks if a field is a input date
   *
   * @static
   * @param {string} type
   * @returns {boolean}
   * @memberof ProductMarketplaceAttributeFieldHelper
   */
  public static isDate(type: string): boolean {
    return TYPE_DATE === type;
  }

  /*
    Checks if a field is a wysiwyg editor
     @param {string} type
     @returns {boolean}
  */
  /**
   * Checks if a field is a wysiwyg editor
   *
   * @static
   * @param {string} type
   * @returns {boolean}
   * @memberof ProductMarketplaceAttributeFieldHelper
   */
  public static isWysiwyg(type: string): boolean {
    return TYPE_WYSIWYG === type;
  }
}
