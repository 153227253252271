import { Injectable } from '@angular/core';
import { IWarehouse } from '@components/export-logistics/forms/choice-warehouses-form.component';

/**
 * Keep a reference of warehouses checked needed by the ExportLogisticsComponent.
 */
@Injectable()
export class WarehousesService {

  public warehousesChecked: IWarehouse[] = [];

  public setWarehousesChecked(warehousesChecked: IWarehouse[]): void {
    this.warehousesChecked = warehousesChecked;
  }

  public getWarehousesChecked(): IWarehouse[] {
    return this.warehousesChecked;
  }
}
