import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MarketplacesService } from '@components/export-logistics/services';

export interface IMarketplace {
  iri: string;
  code: string;
  name: string;
  checked: boolean;
}

@Component({
  selector: 'app-choice-marketplaces-form',
  template: require('./choice-marketplaces-form.component.html'),
})
export class ChoiceMarketplacesFormComponent extends AbstractComponent {

  public form: FormGroup;
  private _marketplaces: IMarketplace[];

  @Input() set marketplaces(marketplaces: IMarketplace[]) {
    this._marketplaces = marketplaces;

    this.setControls();
    this.subscribeToValueChange();
    this.marketplacesService.setMarketplacesChecked(marketplaces.filter((marketplace: IMarketplace) => marketplace.checked));
  }

  @Output() public onFieldSelected: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private fb: FormBuilder,
    public marketplacesService: MarketplacesService,
  ) {
    super($translate, authService, null, state);

    this.form = this.fb.group({
      marketplaces: this.fb.array([]),
    });
  }

  get marketplaces(): IMarketplace[] { return this._marketplaces; }
  get marketplacesFA(): FormArray { return this.form.get('marketplaces') as FormArray; }

  private setControls(): void {
    const fgs: FormGroup[] = this.marketplaces.map(
      (marketplace: IMarketplace): FormGroup => this.fb.group({ checked: marketplace.checked })
    );
    const fa: FormArray = this.fb.array(fgs);

    this.form.setControl('marketplaces', fa);
  }

  private subscribeToValueChange(): void {
    const controls = (<FormArray>this.form.get('marketplaces')).controls;

    controls.forEach((control, index) => {
      control.get('checked').valueChanges.forEach((value: boolean) => {
        this.marketplaces[index].checked = value;
        this.marketplacesService.setMarketplacesChecked(this.marketplaces.filter((marketplace: IMarketplace) => marketplace.checked));
        this.emitFieldSelected();
      });
    });
  }

  public hasSomeChecked(): boolean {
    return (<FormArray>this.form.get('marketplaces')).controls.some(control => control.get('checked').value === true);
  }

  public toggleCheck(): void {
    const controls = (<FormArray>this.form.get('marketplaces')).controls;
    const checkedValue = !this.hasSomeChecked();

    controls.forEach((control, index) => {
      this.marketplaces[index].checked = checkedValue;
      control.get('checked').setValue(checkedValue);
    });

    this.marketplacesService.setMarketplacesChecked(this.marketplaces.filter((marketplace: IMarketplace) => marketplace.checked));
    this.emitFieldSelected();
  }

  private emitFieldSelected(): void {
    this.onFieldSelected.emit();
  }
}
