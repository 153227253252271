import { Component, Inject, Input, OnInit } from '@angular/core';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { AuthService } from '@services';
import { CommercialOperationsResource } from '@components/commercial-operations/resources';
import { CommercialOperationsModel } from '@components/commercial-operations/models';
import {
  ICommercialOperations,
  ICommercialOperationsTranslation
} from '@components/commercial-operations/models/commercial-operations.interface';
import { SessionHelper } from '@helpers';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-commercial-operations-seo',
  template: require('./commercial-operations-seo.component.html'),
})

export class CommercialOperationsSeoComponent extends AbstractPageComponent implements OnInit {

  public locales: string[] = [];
  public model: any;
  public seoTranslationsForms: any = {};

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: CommercialOperationsResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder
  ) {
    super($translate, authService, resource, state);
  }

  public createTranslationForm(translation: ICommercialOperationsTranslation): FormGroup {
    return this.formBuilder.group({
      titleSeo: translation.titleSeo,
      metaDescriptionSeo: translation.metaDescriptionSeo,
      seoMetaRobot: translation.seoMetaRobot,
      longDescription: translation.longDescription,
      shortDescription: translation.shortDescription,
    });
  }

  ngOnInit(): void {
    this.resource.get(this.state.params.id, { model: CommercialOperationsModel })
      .takeUntil(this.destroyed$)
      .subscribe((response: ICommercialOperations) => {
        this.model = response;
        response.translations.forEach((translation: ICommercialOperationsTranslation) => {
          this.seoTranslationsForms[translation.locale] = this.createTranslationForm(translation);
          this.locales.push(translation.locale);
        });
      });
  }

  public hasSeveralTranslation(): boolean {
    return this.model && this.model.translations.length > 1;
  }

  public getLocales() {
    return this.locales;
  }

  onSubmitForm() {
    const bodyPutSeo: any = { translations: {} };

    for (const key of Object.keys(this.seoTranslationsForms)) {
      const idTranslation = this.model.translations.find((e: { locale: string; }) => {
        return e.locale === key;
      }).id;

      bodyPutSeo.translations[key] = {
        'id': idTranslation,
        'locale': key,
        'titleSeo': this.seoTranslationsForms[key].get('titleSeo').value,
        'metaDescriptionSeo': this.seoTranslationsForms[key].get('metaDescriptionSeo').value,
        'longDescription': this.seoTranslationsForms[key].get('longDescription').value,
        'shortDescription': this.seoTranslationsForms[key].get('shortDescription').value,
        'seoMetaRobot': this.seoTranslationsForms[key].get('seoMetaRobot').value,
      };
    }

    this.resource.putSeo(this.state.params.id, bodyPutSeo).takeUntil(this.destroyed$)
      .subscribe();
  }
}
