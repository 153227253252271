import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { HttpClient } from '@angular/common/http';
import { AbstractResource } from '@resources';

@Injectable()
@Resource({
  entryPoint: '/v2/arrival_view',
  isHydra: true,
  exportHeaders: { Accept: 'text/csv' },
})
export class ArrivalViewResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }
}
