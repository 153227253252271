import { Component, Inject, OnInit } from '@angular/core';
import { AbstractResource, CommercialEntityResource } from '@resources';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { AuthService } from '@services';
import { SnackbarService } from '@components/snackbar';
import { CREATION_PAGE, EDITION_PAGE, ICommercialEntity } from '@interfaces';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SupplierResource } from '@components/supplier/resources/supplier.resource';
import { SupplierModel } from '@components/supplier/models/supplier.model';
import { Subscription } from 'rxjs/Subscription';
import { CountryTypeEnum } from '@components/supplier/models/country-type.enum';

@Component({
  selector: 'supplier-form',
  template: require('./supplier-form.component.html'),
  providers: [
    { provide: AbstractResource, useClass: SupplierResource },
    CommercialEntityResource
  ],
})
export class SupplierFormComponent extends AbstractPageComponent implements OnInit {
  public form: FormGroup;
  public commercialEntities: ICommercialEntity[] = [];
  public countries: any[] = [];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    public formBuilder: FormBuilder,
    private snackbar: SnackbarService,
    @Inject('DialogService') private dialog: any,
    private commercialEntityResource: CommercialEntityResource
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    const subscriber: Subscription = this.commercialEntityResource.cGet({ 'pagination': false }, { returnHydraMembers: true })
      .takeUntil(this.destroyed$)
      .subscribe((commercialEntities: ICommercialEntity[]) => {
        subscriber.unsubscribe();

        this.commercialEntities = commercialEntities;

        for (const [key, value] of Object.entries(CountryTypeEnum)) {
          this.countries.push({
            label: value,
            value: key
          });
        }

        if (EDITION_PAGE === this.pageType) {
          this.fetch();
        } else if (CREATION_PAGE === this.pageType) {
          this.buildForm();
        }
      })
    ;
  }

  public submit(): void {
    this.upsert();
  }

  public delete(): void {
    this.dialog.confirm(this.translate('PAGE.SUPPLIER.DELETE.CONFIRM'))
      .then(() => {
        const subscriber: Subscription = this.resource.remove(this.state.params.id)
          .subscribe(
            () => this.actions.list.go(),
            null,
            () => subscriber.unsubscribe()
          );
      });
  }

  private fetch(): void {
    this.resource.get(this.state.params.id)
      .takeUntil(this.destroyed$)
      .subscribe((response: SupplierModel): void => {
        this.buildForm(response);
      })
    ;
  }

  private buildForm(supplier?: SupplierModel): void {
    const commercialEntities: any = {};

    for (const commercialEntity of this.commercialEntities) {
      const supplierCommercialEntity = supplier ? supplier.supplierCommercialEntities.find(function (supplierCommercialEntityModel) {
        return supplierCommercialEntityModel.commercialEntity === commercialEntity['@id'];
      }) : null;

      commercialEntities[commercialEntity.id] = this.formBuilder.group({
        iri: [commercialEntity['@id']],
        sageIdentifier: [supplierCommercialEntity ? supplierCommercialEntity.sageIdentifier : null]
      });
    }

    this.form = this.formBuilder.group({
      label: [supplier ? supplier.label : null, [Validators.required]],
      address: this.formBuilder.group({
        firstName: [supplier ? supplier.address.firstName : null, [Validators.required]],
        lastName: [supplier ? supplier.address.lastName : null, [Validators.required]],
        countryCode: [supplier ? supplier.address.countryCode : null, [Validators.required]],
        address: [supplier ? supplier.address.address : null, [Validators.required]],
        additionalAddress: [supplier ? supplier.address.additionalAddress : null],
        city: [supplier ? supplier.address.city : null, [Validators.required]],
        postcode: [supplier ? supplier.address.postcode : null, [Validators.required]],
        province: [supplier ? supplier.address.province : null],
        email: [supplier ? supplier.address.email : null, [Validators.required]],
        phoneNumber: [supplier ? supplier.address.phoneNumber : null, [Validators.required]],
      }),
      supplierCommercialEntities: commercialEntities
    });
  }

  private prepareBody(): any {
    const supplierCommercialEntities: any = [];

    for (const [id, form] of Object.entries(this.form.value.supplierCommercialEntities)) {
      // @ts-ignore
      const sageIdentifier = form.value.sageIdentifier;

      if (sageIdentifier) {
        supplierCommercialEntities.push({
          // @ts-ignore
          commercialEntity: form.value.iri,
          sageIdentifier: sageIdentifier
        });
      }
    }

    return {
      label: this.form.value.label,
      address: {
        firstName: this.form.value.address.firstName,
        lastName: this.form.value.address.lastName,
        countryCode: this.form.value.address.countryCode,
        address: this.form.value.address.address,
        additionalAddress: this.form.value.address.additionalAddress,
        city: this.form.value.address.city,
        postcode: this.form.value.address.postcode,
        province: this.form.value.address.province,
        email: this.form.value.address.email,
        phoneNumber: this.form.value.address.phoneNumber,
      },
      supplierCommercialEntities: supplierCommercialEntities
    };
  }

  private upsert(): void {
    if (EDITION_PAGE === this.pageType) {
      const id = this.state.params.id;

      this.resource.update(id, this.prepareBody())
        .takeUntil(this.destroyed$)
        .subscribe((supplier: SupplierModel): void => {
          this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));

          this.actions.list.go();
        })
      ;
    } else if (CREATION_PAGE === this.pageType) {
      this.resource.create(this.prepareBody())
        .takeUntil(this.destroyed$)
        .subscribe((supplier: SupplierModel): void => {
          this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));

          const id = supplier.id;

          this.state.go(`proforma_invoice.new`, { supplierId: id }, { reload: true });
        })
      ;
    }
  }
}
