import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SessionHelper } from '@helpers';

export const TOKEN_NAME = 'token';
export const REFRESH_TOKEN = 'refresh_token';

export interface IJwt {
  token: string;
  refresh_token: string;
}

/**
 * Handles:
 *     - authentication communication with api,
 *     - authorization: checks if user is granted to access certain element/parts of application
 */
@Injectable()
export class AuthService {

  constructor(public jwtHelper: JwtHelperService) {}

  /**
   * Put token in localstorage. If user has checks the `remember me` checkbox we put the refresh_token to localstorage.
   */
  public static setTokenInStorage(jwt: IJwt, rememberUser: boolean): void {
    localStorage.setItem(TOKEN_NAME, jwt.token);

    if (rememberUser) {
      localStorage.setItem(REFRESH_TOKEN, jwt.refresh_token);
    }
  }

  public static getToken(): string {
    return localStorage.getItem(TOKEN_NAME);
  }

  public static getRefreshToken(): string {
    return localStorage.getItem(REFRESH_TOKEN);
  }

  public getDecodedToken(): {} {
    return this.jwtHelper.decodeToken(AuthService.getToken());
  }

  public isGranted(roles: string|string[]): boolean {
    if (!Array.isArray(roles)) {
      roles = [roles];
    }

    roles = [...roles].filter((role?: string) => undefined !== role);

    if (roles.length === 0) {
      return true;
    }


    return roles.some(role => SessionHelper.getCurrentUserRoles().includes(role));
  }
}
