import { Injectable } from '@angular/core';
import { AbstractResource } from '../../resources/abstract.resource';
import { Observable } from 'rxjs/Observable';
import { Resource } from '@decorators/Resource';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  entryPoint: '',
})
export class DashboardResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  public getRevenuesByCountry(countryCode: string): Observable<object> {
    return this.getMany({}, {
      entryPoint: `/orders/revenues/${countryCode}`,
      isHydra: false,
    });
  }

  public getSalesComparison(): Observable<object> {
    return this.getMany({}, {
      entryPoint: '/v2/sales_revenue_comparison_for_dashboards',
      dontUseModel: true,
      isHydra: true,
    });
  }
}
