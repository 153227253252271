import { Component, ComponentFactoryResolver, Inject } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { GenericResultListComponent } from '@components/generic/List';
import { UrlHelper } from '@helpers';
import { SnackbarService } from '@components/snackbar';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { FormService } from '@services/form.service';

@Component({
  selector: 'app-stock-movement-result-list',
  template: require('./stock-movement-result-list.component.html'),
  providers: [
    FormService
  ],
})
export class StockMovementResultListComponent extends GenericResultListComponent {

  public transferFormData: any = null;
  public transferForm: FormGroup = new FormGroup({
    receivedQuantity: new FormControl(null, Validators.required)
  });

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    @Inject('StateService') state: ng.ui.IStateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('DialogService') dialog: any,
    urlHelper: UrlHelper,
    componentFactoryResolver: ComponentFactoryResolver,
    private snackbar: SnackbarService,
  ) {
    super($translate, state, authService, resource, dialog, urlHelper, componentFactoryResolver);
  }

  openTransferModal(id: number, receivedQuantity: number): void {
    this.transferFormData = { id: id, receivedQuantity: receivedQuantity };
    this.transferForm.get('receivedQuantity').setValue(receivedQuantity);
  }

  closeTransferModal(): void {
    this.transferFormData = null;
    this.transferForm.get('receivedQuantity').setValue(null);
  }

  transfer(id: number): void {
    const observable: Observable<object> = this.resource.update(
      null,
      { received: true, transferred: true, receivedQuantity: this.transferForm.value.receivedQuantity },
      { entryPoint: '/v2/stock_movement_lines/' + id }
    );

    observable
      .takeUntil(this.destroyed$)
      .subscribe((): void => {
        this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
        setTimeout((): void => {
          this.state.go(this.state.current, this.state.params, {reload: true});
        }, 100);
      })
    ;
  }

  goToOrder(orderId: string): void {
    this.state.go('order_manager.edit', { id: orderId });
  }
}
