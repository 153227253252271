import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {AuthService} from '@services/auth.service';
import {AbstractResource} from '@resources/abstract.resource';
import {AbstractPageComponent} from '@components/generic/abstract-page.component';
import {ProductResource} from '@components/product/product.resource';
import {buildVintageDates} from '@helpers/VintageHelper';

@Component({
  selector: 'app-sav-spare-part-search',
  template: require('./sav-spare-part-search.component.html'),
  styles: [require('./sav-spare-part-search.component.scss')],
  providers: [
    {provide: AbstractResource, useClass: ProductResource},
  ],
})
export class SavSparePartSearchComponent extends AbstractPageComponent implements OnInit {

  @Input() public readonlyValue: string;

  @Output() public onChange: EventEmitter<any> = new EventEmitter();

  public vintages: any[] = [];
  public vintagePossibilitiesDistinctSkus: any = [];
  public spareParts: any[] = [];
  public availableVintageDates: any[];


  public selectedSku: any = null;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    const sparePartId = this.state.params['sparePartId'];
    const masterProductId = this.state.params['masterProductId'];
    const label = this.state.params['label'];
    this.availableVintageDates = buildVintageDates();
  }

  public onFilterSku(event: string) {
    this.resource.getVintagePossibilites(event).takeUntil(this.destroyed$)
      .subscribe((response: any) => {
          this.vintagePossibilitiesDistinctSkus = [];

          for (const iterator of Object.keys(response)) {
            this.vintagePossibilitiesDistinctSkus.push({
              'sku': response[iterator].sku,
              'id': response[iterator].id,
            });
          }
        }
      )
    ;
  }

  public onSelectSku(event: any) {
    this.selectedSku = event;
  }

  public onSelectVintage(event: any) {
    this.resource.getSparePartsByVintage(this.selectedSku.id, event)
      .takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        this.spareParts = response.vintagesByDate;
      });
  }

  public onSelectSparePart(event: any): void {
    this.onChange.emit(event);
  }
}
