import { IReverse, IReverseProduct } from '@components/reverse';
import * as moment from 'moment';
import { DATE_SHORT_INTERNATIONAL_FORMAT } from '@constants';
import {IGrcReason} from '@components/order/interfaces/order-refund.interface';

export class Reverse implements IReverse {
  public id: number;
  public date: string;
  public status: string;
  public lastname: string;
  public firstname: string;
  public reverseReason: IGrcReason;
  public grcReason: IGrcReason|string;
  public zendDeskId: string;
  public comment: string;
  public reverseProducts: IReverseProduct[];
  public order: {[keys: string]: any} | string;
  public orderId: string;
  public warehouse: any;
  public archived: boolean;

  constructor(
    reverse?: IReverse,
    reverseProducts?: IReverseProduct[]
  ) {
    if (reverse) {
      this.id = reverse.id;
      this.date = moment(reverse.date).format(DATE_SHORT_INTERNATIONAL_FORMAT);
      this.status = reverse.status;
      this.lastname = reverse.lastname || '';
      this.firstname = reverse.firstname || '';
      // @ts-ignore
      this.reverseReason = reverse.grcReason ? reverse.grcReason.parent : reverse.grcReason;
      this.grcReason =  reverse.grcReason;
      this.archived = reverse.archived;
      this.zendDeskId = reverse.zendDeskId;
      this.comment = reverse.comment;
      this.reverseProducts = reverseProducts;
      if (null !== reverse.order && typeof reverse.order === 'object') {
        this.order = reverse.order.id;
        this.orderId = reverse.order.orderId;
      } else {
        this.order = reverse.order;
      }
      this.warehouse = reverse.warehouse;
    }
  }
}
