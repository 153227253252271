import {Component, ComponentFactoryResolver, Inject, Input} from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AbstractResource } from '@resources/abstract.resource';
import { AuthService } from '@services/auth.service';
import { BASE_URL_INVOICE_ORDER } from '@constants/config.constants';
import { OrderResource } from '@resources';
import { IHydraMember } from '@interfaces/hydra-resource.interface';
import { takeUntil } from 'rxjs/operators';
import { CommentDialogComponent } from './components/comment-dialog.component';
import { DialogService } from '@progress/kendo-angular-dialog';
import { OrderManagerListModel} from '@components/order-manager/models';
import {SessionHelper, UrlHelper} from '@helpers';
import { ICountry } from '@interfaces';
import {GenericResultListComponent} from '@components/generic/List';

@Component({
    selector: 'app-order-manager-result-list',
    template: require('./order-manager-result-list.component.html'),
    styles: [require('./order-manager-result-list.component.scss')],
})
export class OrderManagerResultListComponent extends GenericResultListComponent {

    public BASE_URL_INVOICE_ORDER = BASE_URL_INVOICE_ORDER;

    @Input() public forcedItems?: any[] = null;
    comments: any;

    private _items: any[];

    set items(items: any[]) {
        this._items = items;
    }

    get items(): any[] {
        return null !== this.forcedItems ? this.forcedItems : this._items;
    }

    constructor(
        @Inject('TranslationService') $translate: ng.translate.ITranslateService,
        authService: AuthService,
        resource: AbstractResource,
        private orderResource: OrderResource,
        private dialogService: DialogService,
        protected urlHelper: UrlHelper,
        protected componentFactoryResolver: ComponentFactoryResolver,
        @Inject('StateService') state: ng.ui.IStateService
    ) {
        super($translate, state, authService, resource, dialogService, urlHelper, componentFactoryResolver);
    }

    getDistinctServices(order: OrderManagerListModel): string[] {
      const services: string[] = [];

      order.items.forEach((item: any) => {
        item.orderItemServices.forEach((service: any)  => {
          if (!services.includes(service.service.type)) {
            services.push(service.service.type);
          }
        });
      });

      return services;
    }

    hasInsurance(order: OrderManagerListModel) {
      return this.getDistinctServices(order).includes('insurance');
    }

    getId(item: any) {
        return item['@id'].split('/').pop();
    }

    goToEdit(orderId: any, e?: MouseEvent) {
        if (e && true === e.ctrlKey) {
            window.open(
                this.state.href(
                    'order_manager.edit',
                    { id: orderId },
                    { absolute: true }
                ),
                '_blank'
            );
            return;
        }

        this.state.go('order_manager.edit', { id: orderId });
    }

    isOrderTypeSav(order: any): boolean {
        return order.detached;
    }

    openDialogWhitComments(orderId: any) {
        (<OrderResource>this.orderResource).getComments(orderId)
            .pipe(takeUntil(this.destroyed$))
            .subscribe((response: IHydraMember[]) => {
                this.comments = response;
                this.openCommentDialog();
            });
    }

    public getTranslation(product: any, locale: string): string {
        const countriesParent = SessionHelper.getApplicationActiveCountriesWithParent().filter((country: ICountry) =>
            country.locales.includes(locale) && country.parent
        );

        if (countriesParent[0] && countriesParent[0].parent && countriesParent[0].parent.locales[0]) {
            locale = countriesParent[0].parent.locales[0];
        }

        if (product && product.translations && product.translations[locale] && product.translations[locale].shortTitle) {
            return product.translations[locale].shortTitle;
        }

        return '';
    }

    public openCommentDialog() {
        const dialogRef = this.dialogService.open({
            content: CommentDialogComponent
        });

        const commentComponentInstance = dialogRef.content.instance;
        commentComponentInstance.comments = this.comments;

        dialogRef.result.subscribe((r: any) => {
            if (r.type === 'submit') {
            }
        });
    }

    public goToProductEdit(event: MouseEvent, productUri: string, order: any) {
        const id = productUri.split('/').pop();
        let state = 'product.edit';

        if (order.detached) {
            state = 'spare-part.edit';
        }

        if (event.ctrlKey) {
            window.open(this.state.href(state, { id }, { absolute: true }), '_blank');
            return;
        }
        this.state.go(state, { id });
    }

    isOrderTypeUnpaired(order: any): boolean {
        return order.unpaired;
     }
}
