import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericFormModule, GenericModule } from '@components/generic';
import { PipesModule } from '../../pipes/pipes.module';
import { DialogModule, WindowModule } from '@progress/kendo-angular-dialog';
import { SharedModule } from '@progress/kendo-angular-dropdowns';
import {GlossaryFormComponent} from '@components/glossary/form/glossary-form.component';
import {GlossaryListComponent} from '@components/glossary/list/glossary-list.component';
import {GlossaryResource} from '@components/glossary/glossary.resource';

@NgModule({
  declarations: [
    GlossaryFormComponent,
    GlossaryListComponent
  ],
  entryComponents: [
    GlossaryFormComponent,
    GlossaryListComponent
  ],
  providers: [
    GlossaryResource,
  ],
  imports: [
    CommonModule,
    GenericModule,
    DialogModule,
    SharedModule,
    WindowModule,
    GenericFormModule,
    PipesModule
  ]
})
export class GlossaryModule {}
