import { NgModule } from '@angular/core';
import { RedirectsComponent } from '@components/redirects/redirects.component';
import { RedirectsResource } from '@components/redirects/redirects.resource';
import { CommonModule } from '@angular/common';
import { GenericFormModule, GenericModule } from '@components';
import { ReactiveFormsModule } from '@angular/forms';
import { ComboBoxModule } from '@progress/kendo-angular-dropdowns';
import { RedirectsFiltersFieldsService } from '@components/redirects/services/redirects-filters-fields.service';
import { RedirectListComponent } from '@components/redirects/list/redirect-list.component';
import { FormNotifierService } from '@services/form-notifier.service';

@NgModule({
  declarations: [
    RedirectListComponent,
    RedirectsComponent
  ],
  entryComponents: [
    RedirectListComponent,
    RedirectsComponent
  ],
  imports: [
    CommonModule,
    GenericModule,
    GenericFormModule,
    ReactiveFormsModule,
    ComboBoxModule,
  ],
  providers: [
    RedirectsResource,
    RedirectsFiltersFieldsService,
    FormNotifierService,
  ]
})
export class RedirectsModule {}
