import { AbstractResource } from './abstract.resource';
import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { IExportOptions } from '@interfaces';
import moment = require('moment');

const exportOptions: IExportOptions[] = [
  {
    entryPoint: '/v2/litigations_export',
    responseType: 'text',
    type: 'text/plain',
    filename: 'export.csv',
    translationKey: 'BUTTON.EXPORT.CSV',
    filters: true
  },
  {
    entryPoint: '/v2/litigations/invoices',
    responseType: 'blob',
    type: 'application/zip',
    filename: `carriers-invoices-${moment().format('YYYYMMDD')}.zip`,
    translationKey: 'BUTTON.EXPORT.CARRIERS.INVOICES',
    filters: true
  }
];

@Injectable()
@Resource({
  entryPoint: '/v2/litigations',
  isHydra: true,
  routeName: 'litigation',
  exportOptions: exportOptions,
})

export class LitigationPackageResource extends AbstractResource {
  constructor(http: HttpClient) {
    super(http);
  }

  public cGet(params: any = {}, options: any = {}): Observable<object> {
    if (params.createdAt) {
        delete params.createdAt;
    }

    if (params.updatedAt) {
      delete params.updatedAt;
    }

    if (params.paidAt) {
      delete params.paidAt;
    }

    return super.cGet(params, options);
  }

  public exportFile(params?: any, options: any = {}, headers?: any): Observable<any> {
    if (params.createdAt) {
      delete params.createdAt;
    }

    if (params.updatedAt) {
      delete params.updatedAt;
    }

    if (params.paidAt) {
      delete params.paidAt;
    }

    return super.exportFile(params, options, headers);
  }
}
