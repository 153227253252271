import { ICarrierGroupAgency } from './carrier-group-agency.interface';
import { ICarrierGroupAgencyModel } from './carrier-group-agency.model.interface';

export class CarrierGroupAgencyModel implements ICarrierGroupAgencyModel {
  warehouseCode: string[];
  agencyCode: string;
  countryCode: string;
  deliverable: string;

  constructor(carrierGroupAgency: ICarrierGroupAgency) {
    this.warehouseCode = carrierGroupAgency.warehouseCarrierGroupAgencies.map((warehouseCarrierGroupAgency) => {
      return warehouseCarrierGroupAgency.warehouse.code;
    });

    this.agencyCode = carrierGroupAgency.agencyCode;
    this.countryCode = carrierGroupAgency.countryCode;
    this.deliverable = carrierGroupAgency.deliverable.toString();
  }
}
