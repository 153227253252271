import { Component, Inject, OnInit } from '@angular/core';
import { AbstractResource } from '@resources';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { AuthService } from '@services';
import { SnackbarService } from '@components/snackbar';
import { CREATION_PAGE, EDITION_PAGE } from '@interfaces';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import {catchError, takeUntil} from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { EavAttributeResource } from '@components/eav-attribute/resources/eav-attribute.resource';
import { EavAttributeModel } from '@components/eav-attribute/models/eav-attribute.model';
import { EavAttributeOptionModel } from '@components/eav-attribute/models/eav-attribute-option.model';
import { EavType } from '@components/eav-attribute/models/eav-type.enum';
import { EavAttributeOptionResource } from '@components/eav-attribute/resources/eav-attribute-option.resource';

@Component({
  selector: 'app-eav-attribute-form',
  template: require('./eav-attribute-form.component.html'),
  providers: [
    { provide: AbstractResource, useClass: EavAttributeResource },
    EavAttributeOptionResource
  ],
})
export class EavAttributeFormComponent extends AbstractPageComponent implements OnInit {
  public form: FormGroup;
  public attribute: EavAttributeModel;
  public readonly EavType = EavType;
  public eavTypes: any[] = [];

  private attributeOptionsPendingDeletion: EavAttributeOptionModel[] = [];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    public formBuilder: FormBuilder,
    private snackbar: SnackbarService,
    private attributeOptionResource: EavAttributeOptionResource
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    for (const [key, value] of Object.entries(EavType)) {
      this.eavTypes.push({
        label: key,
        value: value
      });
    }

    if (EDITION_PAGE === this.pageType) {
      this.fetch();
    } else if (CREATION_PAGE === this.pageType) {
      this.buildForm();
    }
  }

  public submit(event?: any): void {
    this.upsert();
  }

  public addAttributeOption(): void {
    for (const [index, attributeOption] of this.attribute.attributeOptions.entries()) {
      this.attribute.attributeOptions[index].option = this.form.value.attributeOptions[attributeOption.id].value.option;
    }

    this.attribute.attributeOptions.push(new EavAttributeOptionModel({
      '@id': null,
      '@type': null,
      'id': Math.random().toString(36).substr(2, 5)
    }));

    // Refresh form
    this.buildForm(this.attribute);
  }

  public removeAttributeOption(id: number|string): void {
    let i = this.attribute.attributeOptions.length;

    while (i--) {
      const attributeOption = this.attribute.attributeOptions[i];

      if (id === attributeOption.id) {
        this.attribute.attributeOptions.splice(i, 1);

        // Only already persisted attribute option can be deleted though API
        if (attributeOption['@id']) {
          this.attributeOptionsPendingDeletion.push(attributeOption);
        }
      }
    }

    // Refresh form
    this.buildForm(this.attribute);
  }

  private fetch(): void {
    this.resource.get(this.state.params.id)
      .takeUntil(this.destroyed$)
      .subscribe((response: EavAttributeModel): void => {
        this.buildForm(response);
        this.attribute = response;
      })
    ;
  }

  private buildForm(attribute?: EavAttributeModel): void {
    const attributeOptions: any = {};

    for (const attributeOption of attribute ? attribute.attributeOptions : []) {
      attributeOptions[attributeOption.id] = this.formBuilder.group({
        option: [attributeOption.option, [Validators.required]],
      });
    }

    this.form = this.formBuilder.group({
      type: [attribute ? attribute.type : null, [Validators.required]],
      code: [attribute ? attribute.code : null, [Validators.required]],
      label: [attribute ? attribute.label : null, [Validators.required]],
      regex: [attribute ? attribute.regex : null],
      attributeOptions: attributeOptions
    });
  }

  private prepareBody(): any {
    return {
      type: this.form.value.type,
      code: this.form.value.code,
      label: this.form.value.label,
      regex: this.form.value.regex || null,
    };
  }

  private prepareAttributeOptionBody(form: FormGroup): any {
    return {
      attribute: this.attribute['@id'],
      option: form.value.option
    };
  }

  private upsert(): void {
    const observables$: Observable<any>[] = [];

    for (const attributeOption of this.attributeOptionsPendingDeletion) {
      observables$.push(this.attributeOptionResource.remove(String(attributeOption.id)).pipe(catchError((error) => of(error))));
    }

    for (const [id, attributeOption] of Object.entries(this.form.value.attributeOptions)) {
      // check if NaN... JavaScript yeah!
      if (+id) {
        observables$.push(
          this.attributeOptionResource.update(id, this.prepareAttributeOptionBody(attributeOption as any))
            .pipe(catchError((error) => of(error)))
        );
      } else {
        observables$.push(
          this.attributeOptionResource.create(this.prepareAttributeOptionBody(attributeOption as any))
            .pipe(catchError((error) => of(error)))
        );
      }
    }

    if (this.attribute && this.attribute.id) {
      this.resource.update(this.attribute.id, this.prepareBody())
        .takeUntil(this.destroyed$)
        .subscribe((attribute: EavAttributeModel): void => {
          this.attribute = attribute;

          forkJoin(observables$)
            .pipe(takeUntil(this.destroyed$))
            .subscribe((results) => {
              // refresh data
              this.fetch();

              this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
            })
          ;
        })
      ;
    } else {
      this.resource.create(this.prepareBody())
        .takeUntil(this.destroyed$)
        .subscribe((attribute: EavAttributeModel): void => {
          const id = attribute.id;

          this.state.go(`${this.resource.routeName}.edit`, { id }, { reload: true });
        })
      ;
    }
  }
}
