import { Injectable } from '@angular/core';
import { AbstractResource } from '../resources/abstract.resource';
import 'rxjs/add/operator/toPromise';
import { Resource } from '../decorators/Resource';
import {CarrierConfigurationTypeModel} from '@components/carrier/models/carrier-configuration-type.model';

@Injectable()
@Resource({
  entryPoint: '/v2/carrier_configuration_types',
  routeName: 'carrierConfigurationType',
  model: CarrierConfigurationTypeModel,
  translationKey: 'CARRIER.CARRIER_CONFIGURATION_TYPE',
  listColumns: [
    { field: 'id', translationKey: 'ID', type: 'id' },
    { field: 'code', translationKey: 'CODE' }
  ],
  createAvailable: false,
  deleteAvailable: false,
  goToEditField: 'id',
  isHydra: true
})
export class CarrierConfigurationTypeResource extends AbstractResource {

}
