import { IReinsurancePoints } from '@components/footer/reinsurance-points';
import { BASE_URL } from '@constants/config.constants';

export class ReinsurancePointsListModel {
  id?: string;
  firstLine: string;
  secondLine: string;
  file: File;
  imageWebPath: string;
  position: number;
  locale: string;

  constructor(reinsurancePoint: IReinsurancePoints) {
    this.id = reinsurancePoint.id;
    this.firstLine = reinsurancePoint.firstLine;
    this.secondLine = reinsurancePoint.secondLine;
    this.file = reinsurancePoint.file;
    this.imageWebPath = reinsurancePoint.imageWebPath;
    this.position = reinsurancePoint.position;
    this.locale = reinsurancePoint.locale;
  }
}
