import {Component, EventEmitter, Inject, Input, Output, TemplateRef} from '@angular/core';
import { AuthService } from '@services/auth.service';
import { FileInfo, FileRestrictions, SelectEvent } from '@progress/kendo-angular-upload';
import { AbstractComponent } from '@components/generic/abstract.component';
import { IFileInfo } from '@components/generic/Form/file-uploader/interfaces/file-info.interface';
import { FormNotifierService } from '@services/form-notifier.service';
import { FORM_STREAMS } from '../../../../enums/form-notifier-streams.enum';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

/**
 * This component handle upload of image files.
 *
 * An additional form can be attached to each selected file.
 * This formGroup is set to additionalContentForm field of file object
 * the form template can be passed via the attribute additionalContentForm.
 * It comes from parent via ng-template and injected into the template,
 * It must have an associated file to refer to it.
 *
 * @example of template form
 *
 * <ng-template #additionalContentForm let-associatedForm>
 *  <app-form [form]="associatedForm" [dontUseButtonBar]="true">
 *    <app-form-control [control]="associatedForm.get('title')"
 *                     [fieldOptions]="{
 *                             label: 'label',
 *                             controlName: 'title'
 *                           }">
 *    </app-form-control>
 *  </app-form>
 * </ng-template>
 */
@Component({
  selector: 'app-file-uploader',
  template: require('./file-uploader.component.html'),
  styles: [require('./file-uploader.component.scss')],
})
export class FileUploaderComponent extends AbstractComponent {
  private selectedFilesSubscriber$: BehaviorSubject<IFileInfo[]> = new BehaviorSubject([]);

  @Input() public fileRestrictions: FileRestrictions;
  @Input() public multipleFiles: boolean = false;
  @Input() public additionalContentForm: TemplateRef<any>;
  @Input() public withoutSubscriber = false;
  @Input() public fileName = 'UPLOADER.SELECT_OR_DROP';

  @Output() public select: EventEmitter<any> = new EventEmitter();
  @Output() public remove: EventEmitter<any> = new EventEmitter();
  @Input() selectedFiles: any[] = [];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private formNotifier: FormNotifierService,
  ) {
    super($translate, authService, null, state);
    this.formNotifier.observable
      .takeUntil(this.destroyed$)
      .subscribe((stream: any) => {
        if (stream === FORM_STREAMS.dataSubmitted) {
          if (this.fileRestrictions) {
            this.selectedFiles = this.selectedFiles.filter((file: IFileInfo) => {
              return !this.fileRestrictions.allowedExtensions.includes(file.extension);
            });
          }

          this.select.emit(this.selectedFiles);
        }
      })
    ;
  }

  public selectFiles(event: SelectEvent): void {
    if (!this.multipleFiles) {
      this.selectedFiles = [];
    }

    this.handleFile(event.files, 0);
  }

  private handleFile(files: FileInfo[], index: number): void {
    if (index === files.length) {
      this.select.emit(!this.withoutSubscriber ? {subscriber: this.selectedFilesSubscriber$, type: 'select'} : this.selectedFiles);
      return;
    }

    const file = files[index];

    if (this.fileRestrictions && !this.fileRestrictions.allowedExtensions.includes(file.extension)) {
      index++;
      file.state = 0;

      this.selectedFiles.push(file);
      this.selectedFilesSubscriber$.next((this.selectedFiles));
      this.handleFile(files, index);

      return;
    }

    if (file.rawFile) {
      const reader = new FileReader();

      reader.onloadend = () => {
        index++;

        this.selectedFiles.push({...file, src: <string>reader.result});
        this.selectedFilesSubscriber$.next(this.selectedFiles);
        this.handleFile(files, index);
      };

      reader.readAsDataURL(file.rawFile);
    }
  }

  public removeFile(uid: string): void {
    this.selectedFiles = this.selectedFiles.filter((file: IFileInfo) => uid !== file.uid);
    this.remove.emit(this.selectedFiles);
  }

  public isPdf(filename: string): boolean {
    return new RegExp(/.*\.pdf/).test(filename);
  }

  public isVideo(filename: string): boolean {
    return new RegExp(/.*\.(mp4|webm)/).test(filename);
  }
}
