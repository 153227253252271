import { CREATION_PAGE, EDITION_PAGE, IPageComponent, IResource, LIST_PAGE, PageType } from '@interfaces';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services';
import { Inject } from '@angular/core';

export abstract class AbstractPageComponent extends AbstractComponent implements IPageComponent {

  public pageTitle: string = '';
  public readonly pageType: PageType;
  public readonly editionPage = EDITION_PAGE;

  protected constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: IResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);

    this.pageTitle = this.translate(this.state.current.data.title, this.state.params.id ? {id: this.state.params.id} : {});
    this.pageType = this.state.current.name.includes(LIST_PAGE) ?
      LIST_PAGE : this.state.params.id ?
        EDITION_PAGE : CREATION_PAGE
    ;
  }
}
