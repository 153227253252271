import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { IUsers } from '@components/users/models';
import { SessionHelper } from '@helpers/session.helper';
import { LoaderService } from '@components/loader';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { AuthService } from '@services/auth.service';
import { SIGN_IN } from '@constants/route.constants';
import { IRootScopeService } from 'angular';

@Component({
  selector: 'app-header',
  template: require('./header.component.html'),
  styles: [require('./header.component.scss')],
  providers: [LoaderService],
})

export class HeaderComponent extends AbstractPageComponent implements OnInit {

  public loggedUser: string;
  public isUserUsurped: IUsers;
  public showCountries: boolean;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    @Inject('RootscopeService') rootScope: IRootScopeService,
    private loaderService: LoaderService,
    private renderer: Renderer2,
  ) {
    super($translate, authService, null, state);
    // Used on refresh and url navigation
    this.enableCountrySelector();
    // Used for normal navigation (with menu)
    rootScope.$on('$stateChangeSuccess', () => this.enableCountrySelector());
  }

  ngOnInit(): void {
    this.loggedUser = SessionHelper.getUser().username;
    this.isUserUsurped = SessionHelper.getUsurpedUser();
    this.toggleMenu(this.isMenuOpen());
  }

  public toggleMenu(open: boolean): void {
    SessionHelper.set('MENU_IS_OPEN', open);

    if (open) {
      this.renderer.addClass(document.body, 'expanded-sidebar');
      this.renderer.removeClass(document.body, 'minimized-sidebar');
      return;
    }

    this.renderer.addClass(document.body, 'minimized-sidebar');
    this.renderer.removeClass(document.body, 'expanded-sidebar');
  }

  public isMenuOpen(): boolean {
    return SessionHelper.get('MENU_IS_OPEN');
  }

  public logoutAction(): void {
    this.loaderService.add('logout');
    this.loaderService.remove('logout');
    this.state.go(SIGN_IN);
  }

  public enableCountrySelector(): void {
    this.showCountries = true;

    if (this.state.current.data.showCountries !== undefined) {
      this.showCountries = this.state.current.data.showCountries;
    }
  }

  public isUserAvaibleToShowSupportAndTaskStats(): boolean {
    return SessionHelper.getCurrentUserRoles().some( role => role === 'ROLE_WALISOFT_AGENT');
  }
}
