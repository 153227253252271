import { AbstractResource, IRequestOptions } from '../resources/abstract.resource';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Resource } from '@decorators';
import { CategoryModel } from '@models';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  routeName: 'category',
  entryPoint: '/categories',
  model: CategoryModel,
  translationKey: 'CATEGORY',
})
export class CategoryResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  /**
   * Gets all category name.
   */
  public getAllNames(params?: object, options: any = {}): Observable<object> {
    return this.getMany(
      params,
      {
        ...options,
        entryPoint: this.entryPoint + '/name'
      }
    );
  }

  public getAltSlugsFromId(id: string, options: IRequestOptions = {}): Observable<object> {
    options.entryPoint = `${this.entryPoint}/${id}/alternative/slugs`;
    return this.get(id, options);
  }
}
