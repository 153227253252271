'use strict';

// country codes
export const CODE_FR: string = 'FR';
export const CODE_BE: string = 'BE';
export const CODE_GB: string = 'GB';
export const CODE_NL: string = 'NL';
export const CODE_ES: string = 'ES';
export const CODE_PT: string = 'PT';
export const CODE_IT: string = 'IT';
export const CODE_MC: string = 'MC';
export const CODE_DE: string = 'DE';
export const CODE_LU: string = 'LU';
export const CODE_AT: string = 'AT';
export const CODE_PL: string = 'PL';

// locales
export const LOCALE_FR: string = 'fr_FR';
export const LOCALES_BE: string[] = ['fr_BE', 'nl_BE'];
export const LOCALE_GB: string = 'en_GB';
export const LOCALE_NL: string = 'nl_NL';
export const LOCALE_ES: string = 'es_ES';
export const LOCALE_PT: string = 'pt_PT';
export const LOCALE_IT: string = 'it_IT';
export const LOCALE_DE: string = 'de_DE';
export const LOCALES_LU: string[] = ['fr_LU', 'lb_LU', 'de_LU'];
export const LOCALE_PL: string = 'pl_PL';

export const LOCALES: string[] = [
  LOCALE_FR,
  ...LOCALES_BE,
  LOCALE_GB,
  LOCALE_NL,
  LOCALE_ES,
  LOCALE_PT,
  LOCALE_IT,
  LOCALE_DE,
  ...LOCALES_LU,
  LOCALE_PL
];

export const COUNTRIES: string[] = [
  CODE_BE,
  CODE_DE,
  CODE_ES,
  CODE_FR,
  CODE_GB,
  CODE_IT,
  CODE_NL,
  CODE_PL,
  CODE_PT
];
