import {Component, ElementRef, Inject, Input, OnInit} from '@angular/core';
import { FormArray } from '@angular/forms';
import { MP_NAMES } from '@constants/marketplace';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services/auth.service';

/**
 * Handle translations form.
 */
@Component({
  selector: 'app-super-product-form-translations',
  template: require('./super-product-form-translations.html'),
  styles: [require('./super-product-form-translations.scss')],
})
export class SuperProductFormTranslationsComponent extends AbstractComponent implements OnInit {

  public readonly mpNames = MP_NAMES;
  public messageSuccess = false;

  @Input() translationsFA: FormArray;
  @Input() tabIndex: number = 0;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private el: ElementRef
  ) {
    super($translate, authService, null, state);
  }

  ngOnInit() {
    setTimeout(() => {
      this.messageSuccess = true;
    }, 150);
  }

  public getDescriptions(): string[] {
    const htmlAttributes = this.el.nativeElement.querySelector(`.attributes-list-${this.tabIndex}`) ?
      this.el.nativeElement.querySelector(`.attributes-list-${this.tabIndex}`)
        .innerHTML
        .replace(/(\<!--[\s\S]*?-->)/g, '') // remove angular comments
        .replace(/<\s*([a-z][a-z0-9]*)\s.*?>/gi, '<$1>') // attributes
        .replace(/[\n\r]+/g, '') // line ending
        .replace(/\s{2,}/g, '') : // more than one space
      ''
      ;

    return [
      this.translationsFA.at(this.tabIndex).get('description').value,
      htmlAttributes
    ];
  }
}
