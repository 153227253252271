import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { Component, Inject } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { WarrantyResource } from '@components/warranty/resources/warranty.resource';
import { AbstractResource } from '@resources/abstract.resource';

@Component({
  selector: 'app-warranty-list',
  template: require('./warranty-list.component.html'),
  styles: [require('./warranty-list.component.scss')],
  providers: [
    { provide: AbstractResource, useClass: WarrantyResource },
  ],
})
export class WarrantyListComponent extends AbstractPageComponent {

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }
}
