import { Component, Inject, OnInit } from '@angular/core';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { AuthService } from '@services/auth.service';
import { TaskTypeResource } from '@components/generic/task-manager/resources';
import { TaskTypeModel } from '@components/generic/task-manager/model';
import { HydraHelper } from '@helpers/HydraHelper';
import { SnackbarService } from '@components/snackbar';
import { IUser } from '@models/user.interface';

@Component({
  selector: 'app-task-type-edit',
  template: require('./task-type-edit.component.html')
})
export class TaskTypeEditComponent extends AbstractPageComponent implements OnInit {
  public taskType: TaskTypeModel;

  constructor(
      authService: AuthService,
      @Inject('TranslationService') $translate: ng.translate.ITranslateService,
      @Inject('StateService') state: ng.ui.IStateService,
      @Inject('DialogService') private dialog: any,
      public resource: TaskTypeResource,
      private snackbar: SnackbarService,
  ) {
    super($translate, authService, resource, state);
  }

  private static indexOf(users: IUser[], user: IUser): number {
    for (const key in users) {
      if (user.username === users[key].username) {
        return parseInt(key, 10);
      }
    }

    return -1;
  }

  /**
   * @inheritDoc
   */
  ngOnInit() {
    this.fetch();
  }

  private fetch(): void {
    this.resource.get(this.state.params.id)
      .takeUntil(this.destroyed$)
      .subscribe((taskType: TaskTypeModel) => this.taskType = taskType)
    ;
  }

  public delete(collection: IUser[], users: IUser[]): void {
    if (!users.length) {
      return;
    }

    this.dialog.confirm(this.translate('PAGE.TASK_MANAGER.TASK_TYPE.EDIT.CONFIRM.DELETE'))
      .then(() => {
        users.forEach((user) => {
          const indexOf = TaskTypeEditComponent.indexOf(collection, user);

          if (indexOf >= 0) {
            collection.splice(indexOf, 1);
          }
        });

        this.put();
      }
    );
  }

  public add(collection: IUser[], users: IUser[]): void {
    if (!users.length) {
        return;
    }

    users.forEach((user) => {
        const indexOf = TaskTypeEditComponent.indexOf(collection, user);

        if (-1 === indexOf) {
          collection.push(user);
        }
    });

    this.put();
  }

  private put(): void {
    const body = {
      from: HydraHelper.getIris(this.taskType.from, 'users'),
      to: HydraHelper.getIris(this.taskType.to, 'users')
    };

    this.resource.update(this.taskType.id, body)
      .takeUntil(this.destroyed$)
      .subscribe(
        () => {
          this.snackbar.validate(this.translate('ALERTS.DATA.UPDATE'));
          this.state.go(`${this.resource.routeName}.edit`, {id: this.taskType.id}, {reload: true});
        },
        () => this.snackbar.alert(this.translate('ALERTS.DATA.FAIL'))
      )
    ;
  }
}
