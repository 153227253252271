export const LIST_PAGE = 'list';
export const CREATION_PAGE = 'creation';
export const EDITION_PAGE = 'edition';

export type PageType = typeof LIST_PAGE | typeof CREATION_PAGE | typeof EDITION_PAGE;

export interface IPageComponent {
  pageTitle: string;
  pageType: PageType;
}
