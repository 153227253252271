import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { IProductNotifier } from '@services/product-notifier.interface';
import { PRODUCT_STREAMS } from '../enums/product-notifier-streams.enum';
import {IProductForm} from '@components/product/interfaces/product-form.interface';

export class ProductNotifierService implements IProductNotifier {
  private subject: Subject<any> = new Subject<any>();

  /**
   * Observable streams.
   */
  public observable: Observable<any> = this.subject.asObservable();

  public notifyPreviewCostsUpdated(): void {
    this.subject.next(PRODUCT_STREAMS.previewCostsUpdated);
  }
}
