'use strict'

###
  @name FormSelectorCmp
###
angular
  .module('ammsFrontendApp')
  .controller('FormSelector', [
    '$scope',
    '$translate'
    ($scope, $translate) ->

      this.crmId = ''

      this.translate = (key, domain = 'DIALOG') ->
        $translate.instant(domain + '.' + key)

      this.close = (crmId) ->
        $scope.$close({ $value: crmId })

      return this
])
