import { IMasterProductsWarehouses } from '@models/master-products-warehouses.interface';
import {IAddressing} from '@models/addressing.interface';

export class AddressingModel implements IAddressing {
  id: number;
  masterProductsWarehouse: IMasterProductsWarehouses;
  addressingBay: string;
  addressingLane: string;
  addressingLocation: string;
  addressingLevel: string;
  addressingType: string;
  addressing: string;
  quantity: number;
  updatedBy?: string;
  createdAt?: string;

  constructor(addressing: IAddressing) {
    this.id = addressing.id;
    this.masterProductsWarehouse = addressing.masterProductsWarehouse;
    this.addressingBay = addressing.addressingBay;
    this.addressingLane = addressing.addressingLane;
    this.addressingLocation = addressing.addressingLocation;
    this.addressingLevel = addressing.addressingLevel;
    this.addressingType = addressing.addressingType;
    this.quantity = addressing.quantity;
    this.updatedBy = addressing.updatedBy;
    this.createdAt = addressing.createdAt;
    this.addressing = [
      this.addressingType,
      this.addressingBay,
      this.addressingLane,
      this.addressingLocation,
      this.addressingLevel
    ].join(' - ');
  }

  public toString(): string {
    return [
      this.addressingBay,
      this.addressingLane,
      this.addressingLocation,
      this.addressingLevel
    ].filter((item: string) => !!item).join('-');
  }
}
