import {
  ICarrierGroupAgency,
  IWarehouseCarrierGroupAgencies
} from '@components/warehouses/models/warehouse-carrier-group-agencies.interface';
import { IWarehouses } from '@components/warehouses/models/warehouses.interface';

export class WarehouseCarrierGroupAgenciesModel implements IWarehouseCarrierGroupAgencies {
  public '@id': string;
  public carrierGroupAgency: ICarrierGroupAgency;
  public warehouse: IWarehouses;
  public directInjection: boolean;

  constructor(warehouseCarrierGroupAgencies: IWarehouseCarrierGroupAgencies) {
    this['@id'] = warehouseCarrierGroupAgencies['@id'];
    this.carrierGroupAgency = warehouseCarrierGroupAgencies.carrierGroupAgency;
    this.warehouse = warehouseCarrierGroupAgencies.warehouse;
    this.directInjection = warehouseCarrierGroupAgencies.directInjection;
  }
}
