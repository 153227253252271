import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '../../../resources/abstract.resource';
import { DiscountResource } from '../resources/discount.resource';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EDITION_PAGE, ICountry } from '@interfaces';
import { SessionHelper } from '@helpers';
import { IDiscountFormGeneralModel } from '@components/discount/interfaces/discount-form.interface';
import { takeUntil } from 'rxjs/operators';
import { CountryService } from '@components/export-logistics/services';

@Component({
  selector: 'app-discount-form',
  template: require('./discount-form.component.html'),
  providers: [
    { provide: AbstractResource, useClass: DiscountResource },
  ]
})
export class DiscountFormComponent extends AbstractPageComponent implements OnInit {

  public formTab: string;
  public form: FormGroup;
  public invalidField: boolean = false;
  public isReadOnly: boolean = false;
  public model: IDiscountFormGeneralModel;

  private currentCountry: ICountry = SessionHelper.getCountry();

  get translationsFA(): FormArray {
    return this.form.get('ErrorMessagesTranslations') as FormArray;
  }

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    @Inject('CountryService') private countryService: any,
  ) {
    super($translate, authService, resource, state);

    this.formTab = this.state.current.data.formTab;
  }

  ngOnInit() {
    if (EDITION_PAGE === this.pageType) {
      const id = this.state.params.id;

      this.resource.get(id, { entryPoint: `/v2/discounts/${id}`})
        .pipe(
          takeUntil(this.destroyed$)
        )
        .subscribe((response: IDiscountFormGeneralModel) => {
          this.model = response;
          if (this.model.country && this.model.country.code !== SessionHelper.getCountry().code) {
            this.countryService.changeCountry(this.model.country.code);
          }

          this.buildForm();
        })
      ;

      return;
    }

    this.buildForm();
  }

  public validNameField(e: boolean) {
    this.invalidField = !e;
  }

  public setReadOnly(e: boolean) {
    this.isReadOnly = e;
  }
  private buildForm(): void {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      description: [''],
      zendeskTicketId: [''],
      createdByCrm360: [this.model ? this.model.createdByCrm360 : false],
    });
    this.buildTranslationsForm();
  }

  public hasSeveralTranslations(): boolean {
    return this.currentCountry.locales.length > 1;
  }

  private buildTranslationsForm(): void {
    // build several form group for each country locales
    const formGroups = this.currentCountry.locales.map((locale: string) => {
      let errorMessage = '';

      if (this.model) {
        errorMessage = this.model.translations[locale] ? this.model.translations[locale].errorMessage : '';
      }

      return this.formBuilder.group({
        errorMessage: [errorMessage],
        locale
      });
    });

    // pass the form groups to a form array
    const formArray = this.formBuilder.array(formGroups);

    // replace existing control with a new one, we pass it the form array that is a form group collection
    this.form.setControl('ErrorMessagesTranslations', formArray);
  }
}
