import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appSynchronizeScroll]'
})
export class SynchronizeScrollDirective {
  @HostListener('scroll', ['$event'])
  public elementScrolled($event: Event) {
    this.el.nativeElement.querySelector('thead').scrollLeft = this.el.nativeElement.scrollLeft;

    this.el.nativeElement.querySelector('thead').style.marginLeft = '';

      if (this.el.nativeElement.querySelector('thead').classList.contains('stickyTabHeader')) {
        this.el.nativeElement.querySelector('thead').style.marginLeft = -this.el.nativeElement.scrollLeft + 'px';
      }
  }

  constructor(private el: ElementRef) {}
}
