import {IBannerType} from '@components/banner/banner-type.interface';


export class BannerTypeModel {
  '@id': string;

  constructor(model: IBannerType) {
    this['@id'] = model['@id'];
  }
}
