import { Component, Inject, Input } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { IProductForm } from '@components/product/interfaces/product-form.interface';
import { ProductResource } from '@components/product/product.resource';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { MasterProductResource } from '@resources/master-product.resource';
import { IButtonLinks, IButtonPost } from '@components/generic/buttons/button-links.interface';
import { IMarketplace } from '@interfaces';
import { MarketplaceHelper, SessionHelper} from '@helpers';
import { SnackbarService } from '@components/snackbar';
import { ProductMarketplaceResource } from '@components/product/product-marketplace.resource';
import { FormNotifierService } from '@services';

@Component({
  selector: 'app-product-page',
  template: require('./product-page.component.html'),
  providers: [
    { provide: AbstractResource, useClass: ProductResource },
  ],
})
export class ProductPageComponent extends AbstractPageComponent {

  public model: IProductForm;
  public buttonLinks: IButtonLinks[];
  public buttonPost: IButtonPost[];
  public marketplaces: IMarketplace[];
  public formattedVersions: any[] = [];
  public selectedVersion: string;
  @Input() public currentLocale: string = SessionHelper.getLocale();

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    private masterProductResource: MasterProductResource,
    private productMarketplaceResource: ProductMarketplaceResource,
    private snackbar: SnackbarService,
    @Inject('StateService') state: ng.ui.IStateService,
    private formNotifier: FormNotifierService,
    @Inject('DialogService') private dialog: any,
  ) {
    super($translate, authService, resource, state);

    this.resource.get(this.state.params.id)
      .takeUntil(this.destroyed$)
      .subscribe((response: IProductForm) => {
        this.model = response;
        this.pageTitle = this.translate('PAGE.PRODUCT.EDIT.TITLE', { sku: response.sku });
        this.buttonLinks = this.formatButtonLinks();
        this.buttonPost = this.formatButtonsPost();
        this.formattedVersions = this.getFormattedVersions();
        this.selectedVersion = this.model.getPreviewVersion();
      })
    ;

    this.marketplaces = MarketplaceHelper.getDisplayedOnProductPageMarketplaces();
  }

  private formatButtonLinks(): IButtonLinks[] {
    return [{
      url: `#!/super-products/${this.model.superProduct.id}`,
      label: this.translate('PAGE.PRODUCT.EDIT.BUTTON_LINKS', { reference: this.model.superProductRef })
    }];
  }

  private formatButtonsPost(): IButtonPost[] {
    const buttons = [];

    if (undefined !== this.state.params.marketplaceCode) {
      buttons.push({
        params: {id: this.model.productMarketplaces[this.state.params.marketplaceCode].id, businessObject: null, type: 'purge'},
        label: this.translate('PAGE.PRODUCT.EDIT.BUTTON_PURGE')
      });
    }

    return buttons;
  }

  public putSyncCrossAndUpSeling(event: any) {
    const id = this.state.params.id;
    this.resource.update(id, { syncCrossAndUpSellingFromFr: event.target.checked }, { entryPoint: `/v2/products/${id}` })
      .takeUntil(this.destroyed$)
      .subscribe(() => {
        this.snackbar.validate(this.translate('ALERTS.FORM.IN_PROGRESS'));
        this.formNotifier.notifyFormSubmitted();
      })
    ;
  }

  public mutualize(event: any) {
    const translationKey: string = event.target.checked ? 'MUTUALIZE' : 'DEMUTUALIZE';

    this.dialog.confirm(this.translate('PAGE.PRODUCT.EDIT.' + translationKey))
      .then(() => {
        const body: any = {
          sku: this.model.sku,
          country: this.model.country.code,
          warehouses: event.target.checked ? ['lsl'] : []
        };

        this.resource.create(body, { entryPoint: '/v2/products/mutualize' })
          .takeUntil(this.destroyed$)
          .subscribe(() => {
            this.snackbar.validate(this.translate('SAVED'));
            setTimeout(() => {
              this.state.go(this.state.current, this.state.params, { reload: true });
            }, 300);
          }, () => {
            event.target.checked = !event.target.checked;
          })
        ;
      })
      .catch(() => {
        event.target.checked = !event.target.checked;
      })
    ;
  }

  public onPost(event: any) {
    switch (event.type) {
      case 'purge':
        this.productMarketplaceResource.purge(event.id).subscribe(
          response => {
            this.snackbar.validate(this.translate('PAGE.PRODUCT.EDIT.SAVE_PURGE'));
          }
        );
        break;
      default:
        this.snackbar.warn('This operation is not defined.');
        break;
    }
  }

  public createVersion(): void {
    this.dialog.confirm(this.translate('PAGE.PRODUCT.EDIT.SAVE_VERSION')).then(() => {
      (<ProductResource>this.resource).createVersion(this.state.params.id).takeUntil(this.destroyed$).subscribe(() => {
        this.snackbar.validate(this.translate('SAVED'));
        this.state.go(this.state.current, this.state.params, { reload: true });
      });
    });
  }

  public previewVersion(e: any): void {
    if (e === this.model.getPreviewVersion()) {
      return;
    }

    SessionHelper.setProductVersion(this.model.sku, e);
    this.state.go(this.state.current, this.state.params, { reload: true });
  }

  public getFormattedVersions(): any[] {
    return this.model.masterProduct.versions.map((version: string) => {
        let text = 'Version ' + version;

        if (version === this.model.version) {
          text += ' (' + this.translate('BUTTON.AFFECTED_VERSION') + ')';
        }

        return { value: version, text: text };
    });
  }
}
