'use strict'

import * as moment from 'moment'

angular
  .module 'ammsFrontendApp'
  .factory '$interfaceLanguage', [
    '$rootScope'
    '$translate'
    '$translateLocalStorage'
    'tmhDynamicLocale'
    ($rootScope, $translate, $translateLocalStorage, tmhDynamicLocale) ->
      new class InterfaceLanguage
        constructor: ->
          @locale = $translateLocalStorage.get($translate.storageKey())

        setLocale: (@locale) ->
          $translateLocalStorage.set($translate.storageKey(), @locale)
          $translate.use(@locale)

          return this

        getLocale: ->
          @locale

        $rootScope.$on '$translateChangeSuccess', (event, data) ->
          shortLocale = data.language.split('_').shift()
          locale = data.language.replace('_', '-').toLowerCase()
          # sets "lang" attribute to html
          document.documentElement.setAttribute('lang', shortLocale)
          # Change moment locale for daterangepicker
          moment.locale(shortLocale)
          # asking angular-dynamic-locale to load and apply proper AngularJS $locale setting
          tmhDynamicLocale.set(locale)
  ]
