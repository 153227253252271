import { Component, Inject, Input, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { AbstractFormFieldBase } from '@components/generic/Form/dynamic/fields/abstract-form-field-base.class';
import { IExport } from '@components/export/export.interface';
import { ExportResource } from '@resources/export.resource';
import { ExportModel } from '@components/export/export.model';
import { takeUntil } from 'rxjs/operators';
import {SnackbarService} from '@components/snackbar';

@Component({
  selector: 'app-export',
  template: require('./export.component.html')
})
export class ExportComponent extends AbstractPageComponent implements OnInit {

  public model: IExport;
  public fields: AbstractFormFieldBase<any>[] = [];

  @Input() public exportCode: string;
  @Input() public formatCode: string;
  @Input() public serializationGroups: string[];
  @Input() public dryRun: boolean = false;
  @Input() public batchSize: Number = null;
  @Input() public deferred: boolean = false;
  @Input() public split: boolean = false;
  @Input() public async: boolean = true;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    @Inject('StateService') state: ng.ui.IStateService,
    authService: AuthService,
    resource: ExportResource,
    private filtersFieldsService: AbstractFiltersFieldsService,
    private snackbar: SnackbarService,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    this.model = new ExportModel({
      exportCode: this.exportCode,
      formatCode: this.formatCode,
      dryRun: this.dryRun,
      batchSize: this.batchSize,
      deferred: this.deferred,
      split: this.split,
      async: this.async,
      limitFields: [],
      serializationGroups: this.serializationGroups,
    });

    this.fields = this.filtersFieldsService.getFields();
  }

  public submit(event: object): void {
    this.model.limitFields = JSON.stringify(event);
    this.resource.create(this.model)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.snackbar.validate(this.translate('ALERTS.EXPORT.SUCCESS')))
    ;
  }
}
