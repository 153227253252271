import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericFormModule, GenericModule, TabManagementModule } from '@components/generic';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EnhancedFieldsModule } from '@directives/enhanced-fields.module';
import { ComboBoxModule } from '@progress/kendo-angular-dropdowns';
import { SwitchModule } from '@progress/kendo-angular-inputs';
import { DndModule } from 'ng2-dnd';
import { WebsiteCategoryListComponent, WebsiteCategoryResultListComponent } from '@components/website-category/list';
import { WebsiteCategoryFormComponent } from '@components/website-category/form';
import { WebsiteCategoryResource } from '@components/website-category/website-category.resource';
import { CategoriesModule } from '@components/categories';
import { CategoriesSatelliteListComponent } from '@components/categories/list/categories-satellite-list/categories-satellite-list.component';
import { WebsiteCategoryFiltersFormService } from '@components/website-category/service/website-category-filters-form.service';

@NgModule({
  entryComponents: [
    WebsiteCategoryListComponent,
    WebsiteCategoryResultListComponent,
    WebsiteCategoryFormComponent,
    CategoriesSatelliteListComponent
  ],
  declarations: [
    WebsiteCategoryListComponent,
    WebsiteCategoryResultListComponent,
    WebsiteCategoryFormComponent,
  ],
  providers: [
    WebsiteCategoryResource,
    WebsiteCategoryFiltersFormService,
  ],
  imports: [
    CommonModule,
    GenericModule,
    GenericFormModule,
    FormsModule,
    ReactiveFormsModule,
    EnhancedFieldsModule,
    ComboBoxModule,
    SwitchModule,
    DndModule,
    BsDatepickerModule,
    CategoriesModule,
  ]
})

export class WebsiteCategoryModule {}
