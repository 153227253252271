import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { HttpClient } from '@angular/common/http';
import { AbstractResource } from '@resources';
import { SupplierModel } from '@components/supplier/models/supplier.model';
import { SupplierListModel } from '@components/supplier/models/supplier-list.model';

@Injectable()
@Resource({
  routeName: 'supplier',
  entryPoint: '/v2/suppliers',
  translationKey: 'SUPPLIER',
  cGetDefaultFilters: () => ({
    page: 1,
    byPage: 30
  }),
  listColumns: [
    { field: 'label', translationKey: 'LABEL', type: 'string' },
    { field: 'country', translationKey: 'COUNTRY', type: 'string' },
    { field: 'contactName', translationKey: 'CONTACT_NAME', type: 'string' },
    { field: 'address', translationKey: 'ADDRESS', type: 'string' },
    { field: 'phoneNumber', translationKey: 'PHONE_NUMBER', type: 'string' },
    { field: 'email', translationKey: 'EMAIL', type: 'string' },
  ],
  listModel: SupplierListModel,
  formModel: SupplierModel,
  createAvailable: true,
  deleteAvailable: true,
  customActionAvailable: true,
  customAction: [{
    routeName: 'proforma_invoice.new',
    params: {
      supplierId: 'id',
    },
    icon: 'fa-plus',
    color: 'btn-primary',
  }],
  isHydra: true
})
export class SupplierResource extends AbstractResource {
  constructor(
    http: HttpClient,
  ) {
    super(http);
  }
}
