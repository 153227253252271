import { AbstractComponent } from '@components/generic/abstract.component';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '../../../../resources/abstract.resource';
import {
  IProductForm,
  IProductMarketplace,
  IAttributeValue as IBindedAttributeValue,
  IFormProductAttributesBody,
  IMasterProduct,
} from '@components/product/interfaces/product-form.interface';
import { MarketplaceHelper } from '@helpers/MarketplaceHelper';
import { AttributeResource } from '../../../../resources/attribute.resource';
import { IAttribute, IAttributeValue } from '@components/attribute/models/attribute.interface';
import { LOCALE_FR } from '@constants/country';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { AttributeModel } from '@components/attribute/models/attribute.model';
import { SnackbarService } from '@components/snackbar';
import { FormNotifierService } from '@services/form-notifier.service';
import { SessionHelper } from '@helpers';
import { MasterProductResource } from '@resources';
import { HydraHelper } from '@helpers/HydraHelper';
import { SuperProductResource } from '@components/super-product/super-product.resource';
import { IResource } from '@interfaces';

@Component({
  selector: 'app-product-attributes-form',
  template: require('./product-attributes-form.component.html'),
  styles: [require('./product-attributes-form.component.scss')],
})
export class ProductAttributesFormComponent extends AbstractComponent implements OnInit {

  public websiteAttributes: IAttribute[] = [];
  public form: FormGroup;

  private resourceId: string;
  private resourceContext: IResource;

  public attributesSetListUrl: string;

  @Input() attributeValues: IBindedAttributeValue[];
  @Input() masterProductId?: string;
  @Input() superProductId: string;

  get attributesFA(): FormArray {
    return this.form.get('attributes') as FormArray;
  }

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private attributeResource: AttributeResource,
    private formBuilder: FormBuilder,
    private masterProductResource: MasterProductResource,
    private superProductResource: SuperProductResource,
    private snackbar: SnackbarService,
    private formNotifier: FormNotifierService,
    @Inject('DialogService') private dialog: any,
  ) {
    super($translate, authService, resource, state);
  }

  /**
   * @inheritDoc
   */
  ngOnInit(): void {
    this.attributesSetListUrl = this.state.href('attribute-sets.list', {}, { absolute: true });
    this.buildForm();
  }

  public isAttributeFromParent(attribute: any, index: number): any {
    const isAttribute = this.attributesFA.at(index).get([attribute.id]).value.parent;
    if (isAttribute === undefined) {
      return '';
    }
    return this.translate('PAGE.ATTRIBUTE.FORM.PARENT.' + String(isAttribute).toUpperCase());
  }

  private buildForm(): void {
    if (!this.masterProductId && !this.superProductId) {
      return;
    }

    this.resourceId = this.masterProductId ? this.masterProductId : this.superProductId;
    this.resourceContext = this.masterProductId ? this.masterProductResource : this.superProductResource;

    this.form = this.formBuilder.group({
      attributes: this.formBuilder.array([]),
    });

    this.attributeResource.cGet({
      superProduct: this.superProductId,
      active: 1,
      countryCode: SessionHelper.getCountry().code
    }, { model: AttributeModel })
      .takeUntil(this.destroyed$)
      .subscribe((response: IAttribute[]) => {
        const formGroups: FormGroup[] = [];
        this.websiteAttributes = response;

        this.websiteAttributes.forEach((attribute: IAttribute, loopIndex: number) => {
          // build several form group for each attribute
          formGroups.push(this.formBuilder.group({
              [attribute.id]: [{ label: '', id: undefined, translations: undefined }],
            })
          );

          // prepare a blank option for the select field
          attribute.attributeValues.unshift({ id: undefined, label: '', translations: undefined });

          // format the select options text + bind attribute value from productMarketplace attributeValue
          let index = 0;
          attribute.attributeValues.forEach((attributeValues: any[]) => {
            if (attributeValues.length) {
              attributeValues.forEach((attributeValue: IAttributeValue) => {
                attribute.attributeValues[index] = {
                  label: attributeValue.translations ? this.getAttributeValueTextOption(attributeValue) : '',
                  ...attributeValue,
                };

                if (undefined !== this.attributeValues) {
                  this.attributeValues.forEach((bindedValue: IBindedAttributeValue) => {
                    if (+bindedValue.id === +attributeValue.id) {
                      formGroups[loopIndex].patchValue({[attribute.id]: bindedValue});
                    }
                  });
                }
                index++;
              });
            }
          });
        });

        // pass the form groups to a form array
        const formArray = this.formBuilder.array(formGroups);

        // replace existing control with a new one, we pass it the form array that is a form group collection
        this.form.setControl('attributes', formArray);
      })
    ;
  }

  /**
   * Format the option text for the attribute value select field.
   */
  private getAttributeValueTextOption(value: IAttributeValue): string {
    const attributeValue = value.translations[this.currentLocale].value;
    const attributeUnit = value.translations[this.currentLocale].unit ? value.translations[this.currentLocale].unit : '';
    const frenchTranslations =
      value.translations[LOCALE_FR] && value.translations[LOCALE_FR].value !== value.translations[this.currentLocale].value ?
        `(${value.translations[LOCALE_FR].value})` :
        ''
    ;
    const toTranslate = value.translations[this.currentLocale].toTranslate ?
      this.translate('PAGE.PRODUCT.EDIT.TAB.CONTENTS.BOX_ATTRIBUTES.TO_TRANSLATE') :
      ''
    ;

    return `${attributeValue} ${attributeUnit} ${frenchTranslations} ${toTranslate}`;
  }

  public submit(event: any): void {
    this.dialog.confirm(this.translate('PAGE.PRODUCT.CONFIRM.UPDATE'))
      .then(() => {
        const body: IFormProductAttributesBody = this.prepareBody();

        this.resourceContext.putAttributeValues(this.resourceId, body)
          .takeUntil(this.destroyed$)
          .subscribe(() => {
            this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));

            if (event && event.redirect) {
              if (this.state.current.name === 'spare-part.edit.content') {
                this.state.go('spare-part.list');
              } else {
                this.state.go(`${this.resource.routeName}.list`);
              }

              return;
            }

            this.formNotifier.notifyFormSubmitted();

            if (this.state.current.name === 'spare-part.edit.content') {
              this.state.go('spare-part.edit.content',  { id: this.state.params.id });
            } else if (this.masterProductId) {
              this.state.go(`${this.resource.routeName}.edit.content`,  { id: this.state.params.id });
            } else {
              this.state.go(`${this.resource.routeName}.edit`,  { id: this.state.params.id });
            }
          })
        ;
      })
    ;
  }

  private prepareBody(): IFormProductAttributesBody {
    const formValue: any = this.form.value.attributes;
    const body: IFormProductAttributesBody = { attributeValues: [] };

    formValue
      .filter((value: any) => {
        return value[Object.keys(value)[0]].id !== undefined && undefined === this.attributeValues.find(function(attr) {
          return attr.id === value[Object.keys(value)[0]].id && attr.parent;
        });
      })
      .map((value: any) => {
        return {
          [Object.keys(value)[0]]: value[Object.keys(value)[0]].id,
        };
      })
      .forEach((value: any) => {
        body.attributeValues.push(HydraHelper.buildIri(value[Object.keys(value)[0]], 'attribute_values'));
      })
    ;

    return body;
  }
}
