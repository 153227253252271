import { AbstractResource, IRequestOptions } from '@resources/abstract.resource';
import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  entryPoint: '/productmarketplaces'
})
export class ProductMarketplaceResource extends AbstractResource {

  nullableProperties: string[] = ['dateBegin', 'dateEnd', 'longTitle', 'shortTitle', 'shippingDate'];

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  public removeOffer(productId: string, offerId: string): Observable<Object> {
    return this.remove(null, { entryPoint: `${this.entryPoint}/${productId}/offers/${offerId}` });
  }

  public getHighestOffer(productId: string): Observable<Object> {
    return this.get(null, { entryPoint: `${this.entryPoint}/${productId}/offer/highest`, blocking: false });
  }

  public createOffer(productId: string, body: object): Observable<Object> {
    return this.create(body, { entryPoint: `${this.entryPoint}/${productId}/offers` });
  }

  public partialUpdateOffer(productId: string, offerId: string, body: object): Observable<Object> {
    return this.partialUpdate(null, body, { entryPoint: `${this.entryPoint}/${productId}/offers/${offerId}` });
  }

  public destroyOffer(productId: string, offerId: string): Observable<Object> {
    return this.remove(null, { entryPoint: `${this.entryPoint}/${productId}/offers/${offerId}` });
  }

  public getProductMarketplace(productId: string, marketplaceCode: string, options?: IRequestOptions): Observable<Object> {
    return this.get(null, {
      entryPoint: `/v2/products/${productId}/product_marketplaces/${marketplaceCode}`,
      ...options
    });
  }

  public cGetV2(params: any, options?: IRequestOptions): Observable<Object> {
    return this.cGet(params, {
      entryPoint: '/v2/product_marketplaces',
      ...options
    });
  }

  public purge(productId: string): Observable<Object> {
    return this.http.post(this.getPath(`/v2/product_marketplaces/${productId}/purge`), null);
  }
}
