import { Component, Inject, OnInit } from '@angular/core';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { AuthService} from '@services/auth.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SnackbarService } from '@components/snackbar';
import { SessionHelper } from '@helpers/session.helper';
import { EDITION_PAGE } from '@interfaces/IPageComponent';
import { FormNotifierService } from '@services/form-notifier.service';
import { DATE_FULL_FORMAT, DATE_SHORT_FORMAT } from '@constants/date';
import * as moment from 'moment';
import {
  MarketplacesCommissionResource
} from '@components/marketplaces-configure/commission/marketplaces-commission.resource';
import {ICommission, IMarketplace} from '@components/marketplaces-configure/commission/commission.interface';
import {AbstractResource, OrderResource} from '@resources';
import {CatalogsResource} from '@components/catalogs';
import {Observable} from 'rxjs/Observable';
import {HydraHelper} from '@helpers/HydraHelper';
import {MarketplacesConfigureResource} from '@components/marketplaces-configure/marketplaces-configure.resource';

@Component({
  selector: 'app-marketplaces-commission-form',
  template: require('./marketplaces-commission-form.component.html'),
  providers: [
    { provide: AbstractResource, useClass: MarketplacesCommissionResource },
    MarketplacesConfigureResource
  ],
})
export class MarketplacesCommissionFormComponent extends AbstractPageComponent implements OnInit {

  public form: FormGroup;
  public currentLocales: string[] = SessionHelper.getCountry().locales;
  public model: ICommission;
  public startTime: Date;
  public endTime: Date;
  public marketplaces: any[] = [];
  public catalogs: any[] = [];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    private snackbar: SnackbarService,
    @Inject('DialogService') private dialog: any,
    private formNotifier: FormNotifierService,
    private catalogResource: CatalogsResource,
    public marketplaceResource: MarketplacesConfigureResource
  ) {
    super($translate, authService, resource, state);
  }

  /**
   * @inheritDoc
   */
  ngOnInit(): void {
    if (EDITION_PAGE === this.pageType) {
      this.resource.get(this.state.params.id)
        .takeUntil(this.destroyed$)
        .subscribe((response: any) => {
          this.model = response;
          this.startTime = this.model.startDate ? new Date(this.model.startDate) : null;
          this.endTime = this.model.endDate ? new Date(this.model.endDate) : null;

          this.buildForm(true);
          this.fillForm();
        })
      ;

      return;
    }

    this.buildForm(false);
    this.fillForm();
  }

  private buildForm(isEdition: boolean): void {
    this.form = this.formBuilder.group({
      startDate: [this.model && this.model.startDate ? moment(this.model.startDate).format(DATE_SHORT_FORMAT) : null],
      endDate: [this.model && this.model.endDate ? moment(this.model.endDate).format(DATE_SHORT_FORMAT) : null],
      catalogTranslation: [this.model ? this.model.catalog.translations[this.currentLocale] : null],
      catalog: [this.model ? this.model.catalog : null],
      marketplace: [this.model ? this.model.marketplace : null],
      fixedAmount: [this.model ? this.model.fixedAmount : null],
      rate: [this.model ? this.model.rate : null],
    });
  }

  private fillForm(): void {
    this.marketplaceResource.cGet({ 'order[commercialName]': 'asc', country: SessionHelper.getCountry().code }).subscribe((response: any) => {
      response['hydra:member'].forEach((marketplace: IMarketplace) => {
        this.marketplaces.push({
          id: marketplace.id,
          code: marketplace.code,
          commercialName: marketplace.commercialName,
        });
      });
    });

    this.catalogResource.getMany({ locale: this.currentLocale, active: true }, { dontUseModel: true, blocking: false }).subscribe((catalogResponse: any) => {
      catalogResponse.forEach((response: any) => {
        this.catalogs.push({
          id: response.id,
          label: response.label,
        });
      });
    });
  }

  public submit(): void {
    this.dialog.confirm(
      this.translate(EDITION_PAGE === this.pageType ? 'PAGE.BANNER.FORM.CONFIRM.UPDATE' : 'PAGE.BANNER.FORM.CONFIRM.SAVE')
    )
      .then(() => {
        const body: any = this.prepareBody();
        const observable$ = EDITION_PAGE === this.pageType ?
          this.resource.update(this.state.params.id, body) :
          this.resource.create(body)
        ;

        observable$
          .takeUntil(this.destroyed$)
          .subscribe(() => {
            this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));

            this.formNotifier.notifyFormSubmitted();
            this.state.go(`marketplaces.commission`);
          })
        ;
      })
    ;
  }

  private prepareBody(): any {
    const formValue: any = this.form.value;
    let startDate = null;
    let endDate = null;

    if (formValue.startDate) {
      if (this.startTime) {
        startDate = moment(formValue.startDate, DATE_SHORT_FORMAT)
          .add(this.startTime.getHours(), 'hours')
          .add(this.startTime.getMinutes(), 'minutes')
          .add(this.startTime.getSeconds(), 'seconds')
          .format(DATE_FULL_FORMAT)
        ;
      } else {
        startDate = moment(formValue.startDate, DATE_SHORT_FORMAT).startOf('day').format(DATE_FULL_FORMAT);
      }
    }

    if (formValue.endDate) {
      if (this.endTime) {
        endDate = moment(formValue.endDate, DATE_SHORT_FORMAT)
          .add(this.endTime.getHours(), 'hours')
          .add(this.endTime.getMinutes(), 'minutes')
          .add(this.endTime.getSeconds(), 'seconds')
          .format(DATE_FULL_FORMAT)
        ;
      } else {
        endDate = moment(formValue.endDate, DATE_SHORT_FORMAT).endOf('day').format(DATE_FULL_FORMAT);
      }
    }

    const catalogId = formValue.catalogTranslation && formValue.catalogTranslation.id
      ? formValue.catalogTranslation.id : formValue.catalog['@id'].split('/').pop();

    return {
      startDate,
      endDate,
      catalog: HydraHelper.buildIri(catalogId, 'catalogs'),
      marketplace: HydraHelper.buildIri(formValue.marketplace.id, 'marketplaces'),
      marketplaceCode: formValue.marketplace.code,
      fixedAmount: formValue.fixedAmount,
      rate: formValue.rate,
    };
  }
}
