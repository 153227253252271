import { Component, Inject, Input } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { IProductForm } from '@components/product/interfaces/product-form.interface';
import { ProductResource } from '@components/product/product.resource';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { MasterProductResource } from '@resources/master-product.resource';
import { IButtonLinks, IButtonPost } from '@components/generic/buttons/button-links.interface';
import { IMarketplace } from '@interfaces';
import { MarketplaceHelper, SessionHelper } from '@helpers';
import { SnackbarService } from '@components/snackbar';
import { ProductMarketplaceResource } from '@components/product/product-marketplace.resource';
import { FormNotifierService } from '@services';
import { IProduct, IProductLight } from '../interfaces/product-light.interface';
import { ProductLightModel } from '../models/product-light.model';

@Component({
  selector: 'app-product-light-page',
  template: require('./product-light-page.component.html'),
  styles: [require('./product-light-page.component.scss')],
  providers: [
    { provide: AbstractResource, useClass: ProductResource },
  ],
})
export class ProductLightPageComponent extends AbstractPageComponent {
  public model: IProductLight;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);

    this.resource.getForExternal(this.state.params.id)
      .takeUntil(this.destroyed$)
      .subscribe((response: IProductLight) => {
        this.model = response;
      });
  }
}
