export default proformaInvoiceRoutes;

/** @ngInject */
function proformaInvoiceRoutes($stateProvider: any): void {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'walipush',
    sidebarSubNav: 'proformaInvoiceList',
    title: '',
  };

  $stateProvider
    .state('proforma_invoice', {
      url: '/proforma-invoices',
      parent: 'walisoft',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.PROFORMA_INVOICE',
          stateName: 'proforma_invoice.list'
      },
    })
    .state('proforma_invoice.list', {
      url: '?page&byPage&commercialEntity&supplier.label&startDate&endDate&sku[]',
      views: {
        'page@layout': {
          template: '<app-proforma-invoice-list></app-proforma-invoice-list>'
        },
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.PROFORMA_INVOICE.LIST.TITLE'
      })
    })
    .state('proforma_invoice.new', {
      url: '/new',
      breadcrumb: { label: 'BREADCRUMB.NEW' },
      params: {
        supplierId: null,
      },
      views: {
        'page@layout': {
          template: '<app-proforma-invoice-form></app-proforma-invoice-form>'
        },
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.PROFORMA_INVOICE.NEW.TITLE',
      })
    })
    .state('proforma_invoice.edit', {
      url: '/:id',
      breadcrumb: { label: 'BREADCRUMB.EDIT' },
      views: {
        'page@layout': {
          template: '<app-proforma-invoice-form></app-proforma-invoice-form>'
        },
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.PROFORMA_INVOICE.EDIT.TITLE',
      })
    })
  ;
}
