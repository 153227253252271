import { Component, Inject, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { SnackbarService } from '@components/snackbar/snackbar.service';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import { zip } from 'rxjs/observable/zip';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services';

@Component({
  selector: 'app-uploaded-pictures',
  template: require('./uploaded-pictures.component.html'),
  styles: [require('./uploaded-pictures.component.scss')]
})
export class UploadedPicturesComponent extends AbstractComponent implements OnInit {
  /**
   * Fires when a picture is changed.
   */
  @Output() public userPicturesChange = new EventEmitter();

  /**
   * The array of pictures to show. If @var fetchResource is defined this property is filled by @var fetchResource getMany property.
   */
  @Input() public userPictures: Array<any> = [];

  /**
   * The resource to create/update/delete.
   */
  @Input() resource: any;

  /**
   * The resource to fetch the pictures. If not defined then @var userPictures is used to fill pictures.
   */
  @Input() public fetchResource: any;

  /**
   * If defined overrides the @var fetchResource entryPoint.
   */
  @Input() public fetchEntryPoint: string;

  /**
   * Enables drag and drop.
   */
  @Input() public dragEnabled: boolean;

  /**
   * Enables selection, deselection and bulk delete.
   */
  @Input() public selection: boolean;

  /**
   * Enables delete buttons on images.
   */
  @Input() public delete: boolean;

  /**
   * the property path of the url to get picture from.
   */
  @Input() public pictureUrl: string;

  constructor(
    private snackbar: SnackbarService,
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    @Inject('DialogService') private dialog: any,
  ) {
    super($translate, authService, null, state);
  }

  ngOnInit(): void {
    if (this.fetchResource) {
      this.fetchPictures();
    }
  }

  /**
   * Fetches pictures to show.
   */
  private fetchPictures(): void {
    let fetch$: Observable<any>;
    if (this.fetchEntryPoint) {
      fetch$ = this.fetchResource.getMany(
        null,
        { entryPoint: this.fetchEntryPoint }
      );
    } else {
      fetch$ = this.fetchResource.getMany();
    }
    const subscriber: Subscription = fetch$.subscribe(
      (res: any) => {
        this.userPictures = res['hydra:member'].sort((a: any, b: any) => a.position - b.position);
        this.userPicturesChange.emit(this.userPictures);
      },
      () => { this.snackbar.alert(this.translate('UPLOADED_PICTURES.CANT_SHOW_PICTURES')); },
      () => subscriber.unsubscribe()
    );
  }

  /**
   * Fires when an image has been dropped and when an image is deleted.
   */
  public updatePosition(img: any, i: number): void {
    const picturesToUpdate$: Observable<object>[] = [];

    this.userPictures = this.userPictures.map((userImage: any, k: number) => {
      userImage.position = k;
      if (k >= i) {
        picturesToUpdate$.push(this.resource.partialUpdate(userImage['id'], {id: userImage.id, position: userImage.position}));
      }
      return userImage;
    });
    if (picturesToUpdate$.length) {
      const subscriber: Subscription = zip(...picturesToUpdate$).subscribe(
        () => { this.snackbar.validate(this.translate('UPLOADED_PICTURES.PICTURE_UPDATED')); },
        () => {
          this.snackbar.alert(this.translate('UPLOADED_PICTURES.CANT_UPDATE_PICTURES'));
          this.fetchPictures();
        },
        () => subscriber.unsubscribe()
      );
    }
    this.userPicturesChange.emit(this.userPictures);
  }

  /**
   * Tests if there are pictures to delete.
   */
  public hasPicturesToDelete(): boolean {
    return !!this.userPictures.filter((userImage: any) => userImage.selected).length;
  }

  /**
   * Fires when supress buton is clicked.
   */
  public deleteImg(img: any) {
    let picturesToRemove = this.userPictures.filter((userImage: any) => userImage.selected);
    const picturesToRemove$: Observable<object>[] = [];

    if (!picturesToRemove.length && img !== 'multiple') {
      picturesToRemove = [img];
    }

    const smallerIndexToUpdate = picturesToRemove.sort((a, b) => a.position - b.position)[0].position;
    const confirmText = `${this.translate('UPLOADED_PICTURES.CONFIRM.REMOVE')} :
                         ${picturesToRemove.length} images`;

    this.dialog.confirm(confirmText).then(() => {
      picturesToRemove.forEach((picture) => {
        picturesToRemove$.push(this.resource.remove(picture['id']));
      });

      const subscriber: Subscription = zip(...picturesToRemove$).subscribe(
        (val: any) => {
          this.snackbar.validate(this.translate('UPLOADED_PICTURES.PICTURE_REMOVED'));

          picturesToRemove.forEach((picture: any) => {
            this.userPictures.forEach((userImage: any, i: number) => {
              if (picture.id === userImage.id) {
                this.userPictures.splice(i, 1);
              }
            });
          });

          this.updatePosition(this.userPictures[smallerIndexToUpdate], smallerIndexToUpdate);
        },
        (err: any) => {
          this.snackbar.alert(this.translate('UPLOADED_PICTURES.CANT_REMOVE_PICTURES'));
          this.fetchPictures();
        },
        () => subscriber.unsubscribe()
      );
    });
  }

  /**
   * Selects all images.
   */
  public selectAllImg(): void {
    this.userPictures.map((userPicture: any) => {
      userPicture.selected = true;
      return userPicture;
    });
  }

  /**
   * Deselects all images.
   */
  public deSelectAllImg(): void {
    this.userPictures.map((userPicture: any) => {
      userPicture.selected = false;
      return userPicture;
    });
  }
}
