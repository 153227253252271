import { AbstractResource } from './abstract.resource';
import { Injectable } from '@angular/core';
import { SessionHelper } from '../helpers/session.helper';
import { Resource } from '@decorators/Resource';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  routeName: 'manufacturer',
  entryPoint: '/manufacturers',
  translationKey: 'MANUFACTURER',
  cGetDefaultFilters: () => ({ locale: SessionHelper.getLocale() }),
  listColumns: [
    { field: 'id', translationKey: 'ID', type: 'id' },
    { field: 'label', translationKey: 'LABEL' },
  ],
  deleteAvailable: false,
})
export class ManufacturerResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }
}
