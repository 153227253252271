import { Component, Inject, OnInit } from '@angular/core';
import { AbstractFormComponent } from '@components/generic/Form/abstract-form.component';
import { AuthService } from '@services/auth.service';
import { SnackbarService } from '@components/snackbar';
import {CatalogsModel, ICatalogs, ICatalogsTranslations} from '@components/catalogs/models';
import { CatalogsResource } from '@components/catalogs/catalogs.resource';
import { FormControl, FormGroup } from '@angular/forms';
import { IFormViolation } from '@interfaces/IFormViolation';
import {
  ICommercialOperationsTranslation
} from '@components/commercial-operations/models/commercial-operations.interface';

@Component({
  selector: 'app-catalogs',
  template: require('./catalogs.component.html'),
  styles: [require('./catalogs.component.scss')]
})
export class CatalogsComponent extends AbstractFormComponent implements OnInit {

  public inCreation: boolean;
  public model: CatalogsModel;
  public form: FormGroup;
  public violations: IFormViolation[] = [];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: CatalogsResource,
    @Inject('StateService') state: ng.ui.IStateService,
    @Inject('DialogService') private dialog: any,
    private snackbar: SnackbarService,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    this.inCreation = !this.state.params.id;
    this.inCreation
    ? this.createForm()
    : this.fetch();
  }

  private createForm(): void {
    this.form = new FormGroup({
      label: new FormControl(this.model ? this.model.label : ''),
      active: new FormControl(this.model ? this.model.active : false)
    });
  }

  private fetch(): void {
    this.resource.get(this.state.params.id)
      .takeUntil(this.destroyed$)
      .subscribe(
        (response: ICatalogs) => {
          this.model = response;
          this.createForm();
        }
      )
    ;
  }

  private cancel(): void {
    this.dialog.confirm(this.translate('PAGE.CATALOGS.CONFIRM.BACK_TO_LIST'))
      .then(() => this.actions.list.go());
  }

  private submit(returnToList: boolean): void {
    while (this.violations.length) { this.violations.pop(); }

    if (!this.form.dirty || !this.form.valid) {
      let errorMessage = 'ALERTS.NO_CHANGE.FORM';
      if (!this.form.valid) {
        errorMessage = 'ALERTS.ERROR.FORM';
      }

      this.snackbar.warn(this.translate(errorMessage));
      return;
    }

    const body = this.prepareQuery(this.form);
    !this.inCreation
      ? this.update(body, returnToList)
      : this.save(body, returnToList);
  }

  private save(body: ICatalogs, returnToList: boolean): void {
    this.resource.create(body)
      .takeUntil(this.destroyed$)
      .subscribe(
        (response: ICatalogs) => {
          this.snackbar.validate(this.translate('ALERT.FORM.SAVED'));
          returnToList
            ? this.actions.list.go()
            : this.actions.update.go({ id: response.id }, { reload: true })
          ;
        },
        (reject: any) => this.violations = this.formatViolations(reject)
      )
    ;
  }

  private update(body: ICatalogs, returnToList: boolean): void {
    this.dialog.confirm(this.translate('PAGE.CATALOGS.CONFIRM.SAVE'))
      .then(() => {
        this.resource.partialUpdate(this.state.params.id, body)
          .takeUntil(this.destroyed$)
          .subscribe(
            (response: ICatalogs) => {
              this.snackbar.validate(this.translate('ALERT.FORM.SAVED'));
              returnToList
                ? this.actions.list.go()
                : this.actions.update.go({ id: response.id }, { reload: true })
              ;
            },
            (reject: any) => this.violations = this.formatViolations(reject),
          )
        ;
      }
    );
  }

  private prepareQuery(catalogForm: FormGroup): ICatalogs {
    const label = catalogForm.get('label').value;
    const active = catalogForm.get('active').value;

    if (this.model && this.model.translations) {
      // Merge actual translations with the new translation
      Object.keys(this.model.translations).forEach((translation: any) => {
        if (this.model.translations && this.model.translations[translation]) {
          this.model.translations[translation].id = this.model.translations[translation]['@id'];
        }

        if (translation === this.currentLocale) {
          this.model.translations[this.currentLocale].label = label;
          this.model.translations[this.currentLocale].locale = this.currentLocale;
        }

        this.model.active = active;
      });

      if (!this.model.translations[this.currentLocale]) {
        this.model.translations[this.currentLocale] = {
          label: label,
          locale: this.currentLocale
        };
      }

      return {
        translations: this.model.translations,
        active: active,
      };
    }

    return {
      translations: {
        [this.currentLocale]: {
          label,
          locale: this.currentLocale,
        }
      },
      active,
    };

  }

  private formatViolations(reject: any): IFormViolation[] {
    if (!reject.hasOwnProperty('violations')) {
      return;
    }

    return reject.violations.map((violation: IFormViolation): IFormViolation => {
      return {
        propertyPath: violation.propertyPath,
        message: violation.message
      };
    });
  }
}
