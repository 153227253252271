import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { AbstractComponent } from '@components/generic/abstract.component';
import { IBanner } from '@components/banner';
import { BannerResource } from '@components/banner/banner.resource';
import { SnackbarService } from '@components/snackbar';

@Component({
  selector: 'app-banner-result-list',
  template: require('./banner-result-list.component.html'),
  styles: [require('./banner-result-list.component.scss')],
})
export class BannerResultListComponent extends AbstractComponent {

  private _items: IBanner[];

  @Output() onUpdate: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    @Inject('DialogService') private dialog: any,
    private bannerResource: BannerResource,
  ) {
    super($translate, authService, resource, state);
  }

  set items(items: IBanner[]) {
    this._items = items;
    this.checkAndUpdateBannerStatus();
  }

  get items(): IBanner[] {
    return this._items;
  }

  private checkAndUpdateBannerStatus() {
    const currentDate = new Date();

    this._items.forEach((banner) => {
      if (banner.endDate && new Date(banner.endDate) < currentDate && banner.active) {
        this.updateBannerStatus(banner.id, false);
      }
    });
  }

  private updateBannerStatus(id: string, active: boolean) {
    this.bannerResource.update(id, { active })
      .takeUntil(this.destroyed$)
      .subscribe(() => {
        this.onUpdate.emit({ active, id });
      });
  }

  public update(event: any, id: string) {
    this.onUpdate.emit({ active: event, id });
  }

  public goToEdit(id: string): void {
    this.actions.update.go({ id });
  }

  formatBannerTypes(bannerTypes: any[]): string[] {
    return bannerTypes.map(bannerType => 'PAGE.BANNER.FORM.LABEL.' + bannerType.code.toUpperCase());
  }

  public delete(id: string): void {
    this.dialog.confirm(this.translate('PAGE.BANNER.DELETE.CONFIRM'))
      .then(() => {
        this.bannerResource.remove(id)
          .takeUntil(this.destroyed$)
          .subscribe(() => this.state.go('banners.list', null, { reload: true }));
      });
  }
}
