import { AbstractComponent } from '@components/generic/abstract.component';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { takeUntil } from 'rxjs/operators';
import { OrderManagerResource } from '../../order-manager.resource';
import { SnackbarService } from '@components/snackbar';
import { BASE_URL_INVOICE_ORDER, BUSINESS_OBJECT_TRANSACTION, INDICATOR_TRANSACTION_TYPE } from '@constants';
import { OrderResource } from '@resources';
import {SessionHelper} from '@helpers';
import {ICountry} from '@interfaces';

@Component({
  selector: 'app-order-manager-payment-form',
  template: require('./order-manager-payment-form.component.html'),
  styles: [require('./order-manager-payment-form.component.scss')],
  providers: [
    {provide: AbstractResource, useClass: OrderManagerResource},
  ],
})
export class OrderManagerPaymentFormComponent extends AbstractComponent implements OnInit {

  @Input() order: any;
  @Input() orderEntityCommercialCurrency: any;
  paymentsRefunds: any;
  public BASE_URL_INVOICE_ORDER = BASE_URL_INVOICE_ORDER;
  public openRefund: boolean = false;

  public BUSINESS_OBJECT_TRANSACTION: string = BUSINESS_OBJECT_TRANSACTION;
  public INDICATOR_TRANSACTION_TYPE: string = INDICATOR_TRANSACTION_TYPE;

  public transactionIndicator: any;
  public opened = false;

  public refundIdToEdit: any;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    @Inject('DialogService') private dialog: any,
    private snackbar: SnackbarService
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit() {
    this.fetchPaymentsRefunds();
  }

  private fetchPaymentsRefunds(): void {
    this.resource.getPaymentsRefunds(this.order.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        this.paymentsRefunds = response;

        if (this.state.params.refund) {
          this.toggleRefund();
        }
      })
    ;
  }

  public toggleRefund(): void {
    this.openRefund = !this.openRefund;

    if (!this.openRefund) {
      this.refundIdToEdit = undefined;
    }
  }

  public getTransactionId(transaction: any) {
    try {
      return transaction['@id'].split('/').pop();
    } catch (error) {
      console.error(error);
    }
  }

  public goToProductEdit(event: MouseEvent, productUri: string) {
    const id = productUri.split('/').pop();
    let state = 'product.edit';

    if (this.order.detached) {
        state = 'spare-part.edit';
    }

    if (event.ctrlKey) {
        window.open(this.state.href(state, { id }, { absolute: true }), '_blank');
        return;
    }
    this.state.go(state, { id });
  }

  public setTransactionIndicator($eventIndicator: any) {
    this.transactionIndicator = $eventIndicator;
  }

  public openDateDialog() {
    this.opened = true;
  }

  setPaymentLink() {
    const body: any = { 'order': this.order['@id'] };
    this.dialog.confirm(this.translate('ALERTS.CONFIRM.BULK_SEND_PAYMENT_LINK')).then(() => {
      (<OrderResource>this.resource).create(body, { entryPoint: '/v2/order-manager/manual-send-payment-link' })
            .pipe(takeUntil(this.destroyed$))
            .subscribe(
              () => {
                this.snackbar.validate(this.translate('PAGE.ORDER_MANAGER.PAYMENT.SEND_PAYMENT_LINK'));
                this.state.go(this.state.current, this.state.params, { reload: true });
            }
      );
    });
  }
}
