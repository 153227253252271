'use strict'

###
  @name formErrorMessage

  @deprecated
###
angular
  .module 'ammsFrontendApp'
  .directive 'formErrorMessage', [ ->
    replace: true
    restrict: 'E'
    scope:
      object: '='
    templateUrl: require('views/directives/form-error-message.html')
  ]
