import { Component, Inject, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AuthService } from '@services';
import { AbstractFormComponent } from '@components/generic/Form/abstract-form.component';
import { IFormViolation } from '@interfaces';
import { ITabTranslation } from '@components/tab/models';

@Component({
  selector: 'app-tab-management-translations',
  template: require('./tab-management-translations.component.html'),
  styles: [require('./tab-management-translations.component.scss')]
})
export class TabManagementTranslationsComponent extends AbstractFormComponent {

  public rteOptions: any;

  @Input() public form: FormGroup;
  @Input() public violations: IFormViolation[] = [];
  @Input() public translations: ITabTranslation;
  @Input() public isParent: boolean;
  @Input() public displayPublicLink: boolean = false;
  @Input() public type: boolean;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, null, state);

    this.rteOptions = {
      base_url: '/vendor/tinymce',
      menubar: false,
      toolbar: 'bold italic',
      block_formats: '',
      height: 150,
      content_css: false,
    };
  }
}
