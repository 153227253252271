import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { SessionHelper } from '@helpers';
import { AuthService, FormNotifierService } from '@services';
import { ICountry, IFormViolation } from '@interfaces';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { COUNTRY_ENTRYPOINT } from '@resources/country.resource';
import { CountryService } from '@components/export-logistics/services';
import { FORM_STREAMS } from '../../../enums/form-notifier-streams.enum';

const COUNTRIES_PARAM = 'countries';

export interface IUserCountry {
  iri: string;
  code: string;
  name: string;
  checked: boolean;
}

@Component({
  selector: 'app-choice-countries-form',
  template: require('./choice-countries-form.component.html'),
})
export class ChoiceCountriesFormComponent extends AbstractComponent implements OnInit {

  public countries: IUserCountry[] = [];
  public form: FormGroup;
  public violationMessage: string = '';

  @Output() public onFieldSelected: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private fb: FormBuilder,
    private formNotifier: FormNotifierService,
    public countryService: CountryService
  ) {
    super($translate, authService, null, state);
  }

  get countriesFA(): FormArray {
    return this.form.get('countries') as FormArray;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      countries: this.fb.array([]),
    });

    this.setCountries();
    this.setControls();
    this.subscribeToValueChange();
    this.countryService.setCountriesChecked(this.countries.filter((country: IUserCountry) => country.checked));
    this.onFieldSelected.emit([SessionHelper.getCountry().code]);

    this.formNotifier.observable
      .takeUntil(this.destroyed$)
      .subscribe((stream: any) => {
        if (stream.reject && stream.reject.violations && Array.isArray(stream.reject.violations)) {
          const violation = stream.reject.violations
            .find((item: IFormViolation) => item.propertyPath === COUNTRIES_PARAM)
          ;

          this.violationMessage = violation ? violation.message : '';
        }

        if (stream === FORM_STREAMS.dataSubmitted) {
          this.violationMessage = '';
        }
      })
    ;
  }

  /**
   * Sets the countries according to the user countries,
   * The `checked` property is set to true only for the user country.
   */
  private setCountries(): void {
    this.countries = SessionHelper.getCountries()
      .map((country: ICountry): IUserCountry => {
        return {
          iri: `api${COUNTRY_ENTRYPOINT}/${country.id}`,
          code: country.code,
          name: country.translations[SessionHelper.getUILanguage()].name,
          checked: SessionHelper.getCountry().id === country.id,
        };
      });
  }

  /**
   * Sets the form controls.
   */
  private setControls(): void {
    const fgs: FormGroup[] = this.countries.map(
      (country: IUserCountry): FormGroup => this.fb.group({checked: country.checked})
    );
    const fa: FormArray = this.fb.array(fgs);

    this.form.setControl('countries', fa);
  }

  /**
   * When user checks a field, we have to avert the parent component and give it the countries checked and codes.
   */
  private subscribeToValueChange(): void {
    const controls = (<FormArray>this.form.get('countries')).controls;

    controls.forEach((control, index) => {
      control.get('checked').valueChanges.forEach((value: boolean) => {
        this.violationMessage = '';
        this.countries[index].checked = value;

        const countriesChecked: IUserCountry[] = this.countries.filter((country: IUserCountry) => country.checked);
        const countriesCodes: string[] = countriesChecked.map((country: IUserCountry) => country.code);

        this.countryService.setCountriesChecked(countriesChecked);
        this.onFieldSelected.emit(countriesCodes);
      });
    });
  }
}
