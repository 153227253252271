const BREADCRUMBS_SEPARATOR: string = ' > ';

export class CategoryMarketplaceHelper {
  /**
   * Gets breadcrumbs for a category.
   *
   * @param {object} category
   * @returns {string}
   */
  static getBreadcrumbsForCategory(category: any): string {
    const breadcrumbs: string[] = [];

    function getBreadcrumbs(categoryItem: any): any {
      breadcrumbs.unshift(categoryItem.label);

      if (categoryItem.hasOwnProperty('parent')) {
        return getBreadcrumbs(categoryItem.parent);
      }
    }

    getBreadcrumbs(category);

    return breadcrumbs.join(BREADCRUMBS_SEPARATOR);
  }
}
