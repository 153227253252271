'use strict'

import { LOCALE_FR } from '@constants'

angular
  .module 'ammsFrontendApp'
  .config [
    '$translateProvider'
    'tmhDynamicLocaleProvider'
    ($translateProvider, tmhDynamicLocaleProvider) ->
      # Register a loader for the static files
      # So, the module will search missing translation tables under the specified urls
      # Those urls are [prefix][langKey][suffix]
      $translateProvider.useStaticFilesLoader
        prefix: 'i18n/json/locale-'
        suffix: '.json'
      # Tell the module what language to use by default
      $translateProvider.preferredLanguage(LOCALE_FR)
      # Tell the module to store the language in the local storage
      $translateProvider.useLocalStorage()
      # Enable escaping of HTML
      $translateProvider.useSanitizeValueStrategy null
      # Tell the module what language to use by default
      tmhDynamicLocaleProvider.defaultLocale 'fr-fr'
      tmhDynamicLocaleProvider.localeLocationPattern 'i18n/angular-i18n/angular-locale_{{locale}}.js'
  ]
