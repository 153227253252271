import { NgModule } from '@angular/core';
import { ColorPickerModule } from 'ngx-color-picker';
import { CommonModule } from '@angular/common';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { FileSelectDirective } from 'ng2-file-upload';
import {
  ColorPickerComponent,
  FormButtonsBarComponent,
  FormComponent,
  FormControlComponent,
  FormRowComponent,
  FileUploaderComponent,
  SkuSearchComponent,
  UploadComponent,
  UploadedPicturesComponent,
} from './Form';
import { DndModule } from 'ng2-dnd';
import { TooltipModule } from 'ngx-tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditorModule } from '@tinymce/tinymce-angular';
import { BsDatepickerModule, TabsModule, TimepickerModule } from 'ngx-bootstrap';
import { UploadModule } from '@progress/kendo-angular-upload';
import { SwitchModule } from '@progress/kendo-angular-inputs';
import { ImportComponent } from '@components/generic/import-export/import.component';
import { PipesModule } from '../../pipes/pipes.module';
import { BlockListFormComponent } from '@components/generic/blocks/block-list-form.component';
import { BlockListFormTableComponent } from '@components/generic/blocks/block-list-form-table.component';
import { BlockGroupFormComponent } from '@components/generic/blocks/block-group-form.component';
import { BlockGroupFormTableComponent } from '@components/generic/blocks/block-group-form-table.component';
import { BlockFormComponent } from '@components/generic/blocks/block-form.component';
import { BlockFormControlComponent } from '@components/generic/blocks/block-form-control.component';
import { BlockListTableComponent } from '@components/generic/blocks/block-list-table.component';
import { BlockTableFormComponent } from '@components/generic/blocks/block-table-form.component';
import { ProductBlockListComponent } from '@components/generic/product-block/product-block-list.component';
import { ProductBlockResultListComponent } from '@components/generic/product-block/product-block-result-list.component';
import { CategoryBlockListComponent } from '@components/generic/category-block/category-block-list.component';
import { CategoryBlockUniverseResultListComponent } from '@components/generic/category-block/category-block-universe-result-list.component';
import { CategoryBlockShelfResultListComponent } from '@components/generic/category-block/category-block-shelf-result-list.component';
import { EnhancedFieldsModule } from '@directives/enhanced-fields.module';

@NgModule({
  declarations: [
    ColorPickerComponent,
    FileSelectDirective,
    FormButtonsBarComponent,
    FormRowComponent,
    SkuSearchComponent,
    UploadComponent,
    UploadedPicturesComponent,
    FormControlComponent,
    FormComponent,
    FileUploaderComponent,
    ImportComponent,
    ProductBlockListComponent,
    ProductBlockResultListComponent,
    BlockListFormComponent,
    BlockListFormTableComponent,
    BlockGroupFormComponent,
    BlockGroupFormTableComponent,
    BlockFormComponent,
    BlockFormControlComponent,
    BlockListTableComponent,
    BlockTableFormComponent,
    CategoryBlockListComponent,
    CategoryBlockUniverseResultListComponent,
    CategoryBlockShelfResultListComponent,
  ],
  entryComponents: [
    ColorPickerComponent,
    FormButtonsBarComponent,
    FormRowComponent,
    SkuSearchComponent,
    UploadComponent,
    UploadedPicturesComponent,
    ImportComponent,
    ProductBlockListComponent,
    ProductBlockResultListComponent,
    BlockListFormComponent,
    BlockListFormTableComponent,
    BlockGroupFormComponent,
    BlockGroupFormTableComponent,
    BlockFormComponent,
    BlockFormControlComponent,
    BlockListTableComponent,
    BlockTableFormComponent,
    CategoryBlockListComponent,
    CategoryBlockUniverseResultListComponent,
    CategoryBlockShelfResultListComponent,
  ],
  imports: [
    ColorPickerModule,
    CommonModule,
    DropDownsModule,
    DndModule.forRoot(),
    TooltipModule,
    ReactiveFormsModule,
    EditorModule,
    BsDatepickerModule.forRoot(),
    UploadModule,
    FormsModule,
    SwitchModule,
    PipesModule,
    TabsModule,
    EnhancedFieldsModule,
    TimepickerModule.forRoot()
  ],
  exports: [
    ColorPickerComponent,
    FileSelectDirective,
    FormButtonsBarComponent,
    FormRowComponent,
    SkuSearchComponent,
    UploadComponent,
    UploadedPicturesComponent,
    FormControlComponent,
    FormComponent,
    FileUploaderComponent,
    ImportComponent,
    ProductBlockListComponent,
    ProductBlockResultListComponent,
    BlockListFormComponent,
    BlockListFormTableComponent,
    BlockGroupFormComponent,
    BlockGroupFormTableComponent,
    BlockFormComponent,
    BlockFormControlComponent,
    BlockListTableComponent,
    BlockTableFormComponent,
    CategoryBlockListComponent,
    CategoryBlockUniverseResultListComponent,
    CategoryBlockShelfResultListComponent,
  ],
})
export class GenericFormModule {}
