import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommercialOperationsComponent } from './commercial-operations.component';
import { CommercialOperationsResource } from '@components/commercial-operations/resources/commercial-operations.resource';
import { GenericFormModule } from '@components/generic/generic-form.module';
import { GenericModule } from '@components/generic/generic.module';
import { FormService } from './form/form.service';
import { CommercialOperationFormComponent } from '@components/commercial-operations/form/commercial-operation-form.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { SliderModule } from '@progress/kendo-angular-inputs';
import { UploadModule } from '@progress/kendo-angular-upload';
import { FormTabTranslationComponent } from './form/form-tab-translation.component';
import { EnhancedFieldsModule } from '@directives/enhanced-fields.module';
import { TabsModule, TimepickerModule } from 'ngx-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import {
  CommercialOperationsProductsComponent
} from '@components/commercial-operations/commercial-operations-products.component';
import { FormCsvComponent } from '@components/commercial-operations/form/form-csv.component';
import {
  CommercialOperationsFiltersFormService
} from '@components/commercial-operations/services/commercial-operations-filters-form.service';
import { CommercialOperationsListComponent } from '@components/commercial-operations/list/commercial-operations-list.component';
import {CommercialOperationsSeoComponent} from '@components/commercial-operations/commercial-operations-seo.component';
import {CommercialOperationsFormSeoComponent} from '@components/commercial-operations/form/commercial-operations-form-seo.component';
import {CommercialOperationsBlockListComponent} from '@components/commercial-operations/commercial-operations-block-list.component';
import {CommercialOperationsBlockResultListComponent} from '@components/commercial-operations/commercial-operations-block-result-list.component';
import {
  CommercialOperationProductFormComponent
} from '@components/commercial-operations/form/tab-product/commercial-operation-product-form.component';
import { PipesModule } from '../../pipes/pipes.module';
import {FormFlagComponent} from '@components/commercial-operations/form/form-flag.component';

@NgModule({
  declarations: [
    CommercialOperationsListComponent,
    CommercialOperationsComponent,
    CommercialOperationsProductsComponent,
    CommercialOperationFormComponent,
    CommercialOperationProductFormComponent,
    CommercialOperationsFormSeoComponent,
    CommercialOperationsSeoComponent,
    CommercialOperationsBlockListComponent,
    CommercialOperationsBlockResultListComponent,
    FormTabTranslationComponent,
    FormCsvComponent,
    FormFlagComponent,
  ],
  entryComponents: [
    CommercialOperationsListComponent,
    CommercialOperationsComponent,
    CommercialOperationsProductsComponent,
    CommercialOperationsSeoComponent,
    CommercialOperationProductFormComponent,
    CommercialOperationsFormSeoComponent,
    CommercialOperationsBlockListComponent,
    CommercialOperationsBlockResultListComponent,
  ],
  imports: [
    BsDatepickerModule.forRoot(),
    ReactiveFormsModule,
    SliderModule,
    FormsModule,
    DropDownsModule,
    UploadModule,
    CommonModule,
    GenericFormModule,
    GenericModule,
    EnhancedFieldsModule,
    TabsModule.forRoot(),
    BsDropdownModule.forRoot(),
    TimepickerModule,
    PipesModule,
  ],
  exports: [
    CommercialOperationFormComponent
  ],
  providers: [
    CommercialOperationsResource,
    FormService,
    CommercialOperationsFiltersFormService
  ]
})
export class CommercialOperationsModule {}
