import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DiscountListComponent, DiscountResultListComponent } from '@components/discount/list';
import { DiscountResource } from '@components/discount/resources/discount.resource';
import { GenericFormModule, GenericModule } from '@components/generic';
import { DiscountFiltersFormService } from '@components/discount/list/services/discount-filters-form.service';
import { SwitchModule } from '@progress/kendo-angular-inputs';
import { FormsModule } from '@angular/forms';
import { DiscountFormComponent, DiscountGeneralFormComponent, DiscountLimitsFormComponent } from '@components/discount/form';
import { TabsModule } from 'ngx-bootstrap';
import { DiscountFormTranslationsComponent } from '@components/discount/form/general/discount-form-translations.component';
import { DirectivesModule } from '@directives/directives.module';
import { DiscountRestrictionsFormComponent } from '@components/discount/form/restrictions/discount-restrictions-form.component';
import { TooltipModule } from 'ngx-tooltip';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { DiscountFormRejectionTranslationComponent } from '@components/discount/form/discount-form-rejection-translation.component';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DiscountProductsFormComponent } from './form/products/discount-products-form.component';
import { DiscountCreditResource } from '@components/discount/resources/discount-credit.resource';
import { DiscountTranslationResource } from '@components/discount/resources/discount-translation.resource';
import { DiscountCartResource } from '@components/discount/resources/discount-cart.resource';
import { DiscountGiftResource } from '@components/discount/resources/discount-gift.resource';
import { DiscountProductResource } from '@components/discount/resources/discount-product.resource';

@NgModule({
  declarations: [
    DiscountListComponent,
    DiscountResultListComponent,
    DiscountFormComponent,
    DiscountGeneralFormComponent,
    DiscountFormTranslationsComponent,
    DiscountRestrictionsFormComponent,
    DiscountLimitsFormComponent,
    DiscountFormRejectionTranslationComponent,
    DiscountProductsFormComponent
  ],
  entryComponents: [
    DiscountListComponent,
    DiscountFormComponent,
  ],
  providers: [
    DiscountResource,
    DiscountCreditResource,
    DiscountGiftResource,
    DiscountProductResource,
    DiscountCartResource,
    DiscountTranslationResource,
    DiscountFiltersFormService,
  ],
  imports: [
    CommonModule,
    GenericModule,
    SwitchModule,
    FormsModule,
    GenericFormModule,
    TabsModule,
    DirectivesModule,
    TooltipModule,
    TimepickerModule.forRoot(),
    DialogsModule
  ]
})

export class DiscountModule {}
