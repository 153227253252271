import { Injectable } from '@angular/core';
import { ICarrierGroup, ICarriers, ICountry, ICurrentCarrier, IMarketplace, IMarketplacesByCountry } from '@interfaces';
import { IUser } from '../models/user.interface';
import { CountryHelper } from './';
import { Observable } from 'rxjs/Observable';
import { StatusResource } from '../resources/status.resource';
import { CarrierGroupResource } from '../resources/carrier-group.resource';
import { MarketplaceResource } from '../resources/marketplace.resource';
import { OrderResource } from '../resources/order.resource';
import { IWarehouses } from '../components/warehouses/models/warehouses.interface';
import { WarehousesResource } from '../components/warehouses/warehouses.resource';
import { TaskStatusResource } from '../components/generic/task-manager/resources/task-status.resource';
import { ITaskStatus } from '../components/generic/task-manager/interfaces/task-status.interface';
import { CountryResource } from '../resources/country.resource';
import { OrderManagerStatusResource } from '../resources/order-manager-status.resource';

const CURRENT_USER: string = 'CURRENT_USER';
const CURRENT_USER_ROLES: string = 'CURRENT_USER_ROLES';
const CURRENT_USER_COUNTRIES: string = 'CURRENT_USER_COUNTRIES';
const CURRENT_USER_COUNTRY: string = 'CURRENT_USER_COUNTRY';
const CURRENT_USER_LOCALE: string = 'CURRENT_USER_LOCALE';
const CURRENT_USER_CURRENCY: string = 'CURRENT_USER_CURRENCY';
const USURPED_USER: string = 'USURPED_USER';

const ALL_MARKETPLACES: string = 'ALL_MARKETPLACES';
const ALL_MARKETPLACES_BY_COUNTRY: string = 'ALL_MARKETPLACES_BY_COUNTRY';
const CURRENT_MARKETPLACES: string = 'CURRENT_MARKETPLACES';
const FILTERED_MARKETPLACES: string = 'FILTERED_MARKETPLACES';
const ALL_CARRIERS: string = 'ALL_CARRIERS';
const CURRENT_CARRIERS: string = 'CURRENT_CARRIERS';
const ALL_WAREHOUSES: string = 'ALL_WAREHOUSES';
const USER_WAREHOUSES: string = 'USER_WAREHOUSES';
const CURRENT_WAREHOUSES: string = 'CURRENT_WAREHOUSES';
const ORDER_MANAGER_STATUSES: string = 'ORDER_MANAGER_STATUSES';
const ORDER_STATUSES: string = 'ORDER_STATUSES';
const ORDER_TYPES: string = 'ORDER_TYPES';
const TASK_STATUSES: string = 'TASK_STATUSES';
const APPLICATION_LOCALES: string = 'APPLICATION_LOCALES';
const APPLICATION_ACTIVE_COUNTRIES: string = 'APPLICATION_ACTIVE_COUNTRIES';
const APPLICATION_ACTIVE_COUNTRIES_WITH_PARENT: string = 'APPLICATION_ACTIVE_COUNTRIES_WITH_PARENT';
const USER_LINKED_WAREHOUSES: string = 'USER_LINKED_WAREHOUSES';
const CURRENT_VERSIONS: string = 'CURRENT_VERSIONS';

const UI_LANGUAGE: string = 'UI_LANGUAGE';

/**
 * Handle the data required for the application in a user session.
 */
@Injectable()
export class SessionHelper {
  constructor(
    private marketplaceResource: MarketplaceResource,
    private carrierGroupResource: CarrierGroupResource,
    private warehousesResource: WarehousesResource,
    private statusResource: StatusResource,
    private orderResource: OrderResource,
    private taskStatusResource: TaskStatusResource,
    private countryResource: CountryResource,
    private orderManagerStatusResource: OrderManagerStatusResource
  ) { }

  /**
   * Gets stored value for current session.
   */
  public static get(key: string): any {
    return JSON.parse(localStorage.getItem(key));
  }

  /**
   * Stores a value for current session.
   */
  public static set(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  /**
   * Removes data from current session.
   */
  public static remove(key: string): void {
    localStorage.removeItem(key);
  }

  public static clearStorage(): void {
    sessionStorage.clear();
    localStorage.clear();
  }

  public static setCurrentUser(user: IUser): void {
    SessionHelper.set(CURRENT_USER, user);
  }

  public static getUser(): IUser {
    return SessionHelper.get(CURRENT_USER);
  }

  public static setCurrentUserDataInStorage(user: IUser): void {
    const defaultUserCountry = user.countries[0];

    SessionHelper.setCurrentUserLocale(defaultUserCountry.locales[0]);
    SessionHelper.setCurrentUserRoles(user.selectableRoles);
    SessionHelper.setCurrentUserCountries(user.countries);
    SessionHelper.setCurrentUserCountry(defaultUserCountry);
    SessionHelper.setCurrentUserCurrency(defaultUserCountry.currencyCode);
  }

  public static setProductVersion(product: string, version: string): void {
    const country = SessionHelper.getCountry().code;
    const versions = SessionHelper.get(CURRENT_VERSIONS) || {};

    if (!versions[country]) {
      versions[country] = {};
    }

    versions[country][product] = version;

    SessionHelper.set(CURRENT_VERSIONS, versions);
  }

  public static getProductVersion(product: string): string {
    const country = SessionHelper.getCountry().code;
    const versions = SessionHelper.get(CURRENT_VERSIONS) || [];

    if (!versions[country]) {
      return null;
    }

    return versions[country][product];
  }

  public static setCurrentUserRoles(currentRoles: string[]): void {
    SessionHelper.set(CURRENT_USER_ROLES, currentRoles);
  }

  public static getCurrentUserRoles(): string[] {
    return SessionHelper.get(CURRENT_USER_ROLES) ? SessionHelper.get(CURRENT_USER_ROLES) : [];
  }

  public static setCurrentUserCountries(countries: ICountry[]): void {
    SessionHelper.set(CURRENT_USER_COUNTRIES, countries.map(CountryHelper.decodeCountry));
  }

  public static isCurrentUserWarehouseAgent(): boolean {
    return (SessionHelper.getCurrentUserRoles().length === 1 &&
      SessionHelper.getCurrentUserRoles().every(role => role === 'ROLE_WALISOFT_WAREHOUSE_AGENT'))
      || (SessionHelper.getCurrentUserRoles().length === 2 &&
        SessionHelper.getCurrentUserRoles().some(role => role === 'ROLE_WALISOFT_WAREHOUSE_AGENT') &&
        SessionHelper.getCurrentUserRoles().some(role => role === 'ROLE_EXTERNAL_REVERSE')
      );
  }

  public static isCurrentUserReverseExternal(): boolean {
    return SessionHelper.getCurrentUserRoles().length === 1 && SessionHelper.getCurrentUserRoles().every(role => role === 'ROLE_EXTERNAL_REVERSE');
  }


  public static useLinkedWarehousesOnly(): boolean {
    return SessionHelper.isCurrentUserWarehouseAgent();
  }

  /**
   * Gets the countries linked to the current user.
   */
  public static getCountries(): ICountry[] {
    return SessionHelper.get(CURRENT_USER_COUNTRIES);
  }

  public static setCurrentUserCountry(country: ICountry): void {
    SessionHelper.set(CURRENT_USER_COUNTRY, CountryHelper.decodeCountry(country));
  }

  /**
   * Gets the country of the current user.
   */
  public static getCountry(): ICountry {
    return SessionHelper.get(CURRENT_USER_COUNTRY);
  }

  /**
   * Get marketplace by code
   */
  public static getCountryByMarketplaceCode(code: string): ICountry|null {
    const filteredMarketplace = SessionHelper.getCountries().filter((country: ICountry) => (
      country.marketplaces.includes(code)
    ));

    return filteredMarketplace.length > 0 ? filteredMarketplace[0] : null;
  }

  public static getAllApplicationActiveCountries(): ICountry[] | null {
    return SessionHelper.get(APPLICATION_ACTIVE_COUNTRIES).concat(this.getApplicationActiveCountriesWithParent());
  }

  /**
   * Gets all countries active.
   */
  public static getApplicationActiveCountries(): ICountry[] | null {
    return SessionHelper.get(APPLICATION_ACTIVE_COUNTRIES);
  }

  /**
 * Gets all countries active with parent.
 */
  public static getApplicationActiveCountriesWithParent(): ICountry[] | null {
    return SessionHelper.get(APPLICATION_ACTIVE_COUNTRIES_WITH_PARENT);
  }

  public static setCurrentUserLocale(locale: string): void {
    SessionHelper.set(CURRENT_USER_LOCALE, locale);
  }

  /**
   * Gets the locale of the current user.
   */
  public static getLocale(): string {
    return SessionHelper.get(CURRENT_USER_LOCALE);
  }

  /**
   * Sets the language chosen by the user.
   */
  public static setUILanguage(locale: string): void {
    return SessionHelper.set(UI_LANGUAGE, locale);
  }

  /**
   * Gets the language chosen by the user.
   */
  public static getUILanguage(): string {
    return SessionHelper.get(UI_LANGUAGE);
  }

  public static setCurrentUserCurrency(currentCurrency: string): void {
    SessionHelper.set(CURRENT_USER_CURRENCY, currentCurrency);
  }

  /**
   * Gets the currency linked to the the current user country.
   */
  public static getCurrency(): string {
    return SessionHelper.get(CURRENT_USER_CURRENCY);
  }

  public static setUsurpedUser(user: IUser): void {
    SessionHelper.set(USURPED_USER, user);
  }

  public static getUsurpedUser(): IUser {
    return SessionHelper.get(USURPED_USER);
  }

  /**
   * Override the current user data in session,
   * when the usurped mode will finish,
   * we will call `setCurrentUserDataInStorage()` with the current user that is keep in memory.
   */
  public static setUsurpedUserDataInStorage(user: IUser): void {
    SessionHelper.setCurrentUserDataInStorage(user);
  }

  public static removeUsurpedUser(): void {
    SessionHelper.remove(USURPED_USER);
  }

  /**
   * Gets all marketplaces.
   */
  public static getAllMarketplaces(): IMarketplace[] {
    return SessionHelper.get(ALL_MARKETPLACES);
  }

  /**
   * Gets marketplaces linked to the current user country.
   */
  public static getMarketplaces(): IMarketplace[] {
    const country = this.getCountry();
    return SessionHelper.get(CURRENT_MARKETPLACES).map((marketplace: IMarketplace) => {
      marketplace.country = country;

      return marketplace;
    });
  }

  public static getCurrentWebsiteMarketplace(): IMarketplace {
    return SessionHelper.get('CURRENT_MARKETPLACES').find((marketplace: IMarketplace) => {
        return marketplace.code.startsWith('site');
    });
  }
  /**
   * Gets all carriers.
   */
  public static getAllCarrierGroups(): ICurrentCarrier[] {
    return SessionHelper.get(ALL_CARRIERS);
  }

  /**
   * Gets carriers linked to the current user country, filtered by `handleProduct`.
   */
  public static getCarrierGroups(): ICurrentCarrier[] {
    return SessionHelper.get(CURRENT_CARRIERS);
  }

  /**
   * Gets all warehouses.
   */
  public static getAllWarehouses(): IWarehouses[] {
    const warehouses: IWarehouses[] = SessionHelper.get(ALL_WAREHOUSES);

    warehouses.sort((a: IWarehouses, b: IWarehouses): number =>  {
      return a.name.localeCompare(b.name);
    });

    return warehouses;
  }

  public static getWarehouse(code: string): IWarehouses {
    return SessionHelper.getAllWarehouses().find((item: IWarehouses): boolean => {
      return item.code === code;
    });
  }

  public static getUserWarehouses(): IWarehouses[] {
    return SessionHelper.get(USER_WAREHOUSES);
  }

  /**
   * Gets warehouses linked to the current user country.
   */
  public static getWarehouses(): ICurrentCarrier[] {
    return SessionHelper.get(CURRENT_WAREHOUSES);
  }

  /**
   * Gets an array of all order manager statuses.
   */
  public static getOrderManagerStatuses(): string[] {
    return SessionHelper.get(ORDER_MANAGER_STATUSES);
  }

  /**
   * Gets an array of all order statuses.
   */
  public static getOrderStatuses(): string[] {
    return SessionHelper.get(ORDER_STATUSES);
  }

  /**
   * Gets an array of all order types.
   */
  public static getOrderTypes(): string[] {
    return SessionHelper.get(ORDER_TYPES);
  }

  /**
   * Gets an array of all task statuses.
   */
  public static getTaskStatuses(): ITaskStatus {
    return SessionHelper.get(TASK_STATUSES);
  }

  /**
   * Sets the filters from a list page in storage.
   */
  public static setFiltersForPage(filters: any, page: string): void {
    Object.entries(filters).forEach(([filter, value]: any) => {
      if ('' === value || null === value) {
        filters[filter] = undefined;
      }
    });

    SessionHelper.set(this.getStorageKeyForPageWithFilters(page), filters);
  }

  public static getFiltersForPage(page: string): any {
    return SessionHelper.get(this.getStorageKeyForPageWithFilters(page));
  }

  public static removeFiltersForPage(page: string): void {
    SessionHelper.remove(this.getStorageKeyForPageWithFilters(page));
  }

  public static getStorageKeyForPageWithFilters(page: string): string {
    return `preference-${page}`;
  }

  static getLinkedWarehouses() {
    return SessionHelper.get(USER_LINKED_WAREHOUSES);
  }

  public static transformCarrierGroup(carrierGroups: ICarrierGroup[], countryCode: string) {
    return carrierGroups.map((carrierGroup: ICarrierGroup): ICurrentCarrier => {
      const carrierForCurrentCountry: ICarriers = carrierGroup.carriers.filter((carrier: ICarriers) => {
        return carrier.country.code === countryCode;
      }).shift();
      return {
        code: carrierGroup.code,
        name: carrierGroup.name,
        assignable: undefined !== carrierForCurrentCountry ? carrierForCurrentCountry.assignable : undefined,
        active: carrierGroup.active,
        handleReverses: carrierGroup.handleReverses
      };
    });
  }

  /**
   * Set the marketplaces filtered by the user on products list page.
   */
  public static setFilteredMarketplaces(marketplaces: IMarketplace[]): void {
    SessionHelper.set(FILTERED_MARKETPLACES, marketplaces);
  }

  /**
   * Get the marketplaces filtered by the user on products list page.
   */
  public static getFilteredMarketplaces(): IMarketplacesByCountry {
    return SessionHelper.get(FILTERED_MARKETPLACES);
  }

  public static getCountFilteredMarketplaces(): number {
    const filtered = this.getFilteredMarketplaces();

    if (null === filtered) {
      return 0;
    }

    let count: number = 0;

    Object.keys(filtered).map((countryCode: string) => {
      const marketplaces = filtered[countryCode];
      count += marketplaces.length;
    });

    return count;
  }

  public static setMarketplacesByCountry(marketplaces: IMarketplacesByCountry): void {
    SessionHelper.set(ALL_MARKETPLACES_BY_COUNTRY, marketplaces);
  }

  public static getMarketplacesByCountry(): IMarketplacesByCountry {
    return SessionHelper.get(ALL_MARKETPLACES_BY_COUNTRY);
  }

  /**
   * Sets required data for application,
   * data depends from current user data but are not dependents from user itself.
   */
  public callRequiredDataForApplication(): Observable<object> {
    const currentCountryCode = SessionHelper.getCountry().code;

    const observables: Observable<object>[] = [
      this.marketplaceResource.filterByCountryCode(currentCountryCode),
      this.carrierGroupResource.getForProducts(),
      this.warehousesResource.getMany({ 'carriers.country.code': currentCountryCode }),
      this.statusResource.getMany(),
      this.orderResource.getOrderTypes(),
      this.taskStatusResource.getMany(undefined, { dontUseModel: true }),
      this.countryResource.getMany({ active: true, 'exists[parent]': false }, { dontUseModel: true }),
      this.countryResource.getMany({ active: true, 'exists[parent]': true }, { dontUseModel: true }),
      this.marketplaceResource.cGet(),
      this.carrierGroupResource.cGet({}, { returnHydraMembers: true }),
      this.warehousesResource.cGet({ active: true }, { returnHydraMembers: true }),
      this.orderManagerStatusResource.cGet({}, { returnHydraMembers: true }),
      this.warehousesResource.cGet({}, { entryPoint: '/v2/warehouses/linked', returnHydraMembers: true })
    ];

    return Observable.zip(...observables);
  }

  public setRequiredDataInStorage(requiredData: [
    IMarketplace[],
    ICarrierGroup[],
    IWarehouses[],
    string[],
    string[],
    ITaskStatus[],
    ICountry[],
    ICountry[],
    IMarketplace[],
    ICarrierGroup[],
    IWarehouses[],
    string[],
    IWarehouses[]
  ]): void {
    const currentCountryCode = SessionHelper.getCountry().code;

    SessionHelper.set(CURRENT_MARKETPLACES, <IMarketplace[]>requiredData[0]);

    SessionHelper.set(CURRENT_CARRIERS, SessionHelper.transformCarrierGroup(<ICarrierGroup[]>requiredData[1], currentCountryCode));

    SessionHelper.remove('arrivals');
    SessionHelper.remove('arrivals_date_expired');
    SessionHelper.set(CURRENT_WAREHOUSES, <IWarehouses[]>requiredData[2]);
    SessionHelper.set(ORDER_STATUSES, <string[]>requiredData[3]);
    SessionHelper.set(ORDER_TYPES, <string[]>requiredData[4]);
    SessionHelper.set(TASK_STATUSES, <ITaskStatus>requiredData[5].pop());
    SessionHelper.set(APPLICATION_ACTIVE_COUNTRIES, <ICountry[]>requiredData[6]);
    SessionHelper.set(APPLICATION_ACTIVE_COUNTRIES_WITH_PARENT, <ICountry[]>requiredData[7]);

    const locales: string[] = [];

    requiredData[6].forEach((country: ICountry) => {
      locales.push(...country.locales);
    });

    SessionHelper.set(ALL_MARKETPLACES, <IMarketplace[]>requiredData[8]);
    SessionHelper.set(ALL_CARRIERS, <ICarrierGroup[]>requiredData[9]);
    SessionHelper.set(ALL_WAREHOUSES, <IWarehouses[]>requiredData[10]);
    SessionHelper.set(ORDER_MANAGER_STATUSES, <string[]>requiredData[11]);

    SessionHelper.set(APPLICATION_LOCALES, locales);
    SessionHelper.set(USER_LINKED_WAREHOUSES, <IWarehouses[]>requiredData[12]);
  }
}
