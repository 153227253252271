import { Component, Inject } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { RabbitMqResource } from '@components/rabbit/rabbit-mq.resource';
import 'rxjs/add/operator/map';
import { AuthService } from '@services';

@Component({
  selector: 'app-rabbit-mq',
  template: require('./rabbit-mq.component.html')
})
export class RabbitMqComponent extends AbstractComponent {
  public errors: any;
  public queues: any;
  public isPulsing: boolean = false;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    protected resource: RabbitMqResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, null, state);
  }

  /**
   * Gets queues and messages from rabbitmq instance when the popover is open.
   */
  public getQueues(): void {
    this.isPulsing = true;

    this.resource.getMany(
      { columns: 'name,messages', sort: 'messages', sort_reverse: true }, { blocking: false }
    ).subscribe(
      (response: any) => this.queues = response,
      (reject: any) => this.errors = reject,
      () => this.isPulsing = false)
    ;
  }
}
