import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { AbstractResource } from '@resources/abstract.resource';
import { DiscountResource } from '@components/discount/resources/discount.resource';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services/auth.service';
import { AbstractControl } from '@angular/forms';
import { DiscountFormGeneralModel } from '@components/discount/models/discount-form-general.model';
import { takeUntil } from 'rxjs/operators';
import { ICustomer, IDiscountFormGeneralModel, IProduct } from '@components/discount/interfaces/discount-form.interface';
import _ = require('lodash');
import { Subscription } from 'rxjs/Subscription';
import { DATE_SHORT_INTERNATIONAL_FORMAT } from '@constants';
import * as moment from 'moment';
import { IExportOptions } from '@interfaces';
import { Resource } from '@decorators';

@Component({
  selector: 'app-discount-products-form',
  template: require('./discount-products-form.component.html'),
  providers: [{ provide: AbstractResource, useClass: DiscountResource }]
})
export class DiscountProductsFormComponent extends AbstractComponent implements OnInit {

  @Input() public commonFields: { [keys: string]: AbstractControl };
  @Input() public readOnly: boolean;

  @Output() public validNameField: EventEmitter<any> = new EventEmitter();
  @Output() public setReadOnly: EventEmitter<any> = new EventEmitter();

  public isReadOnly = false;

  private model: IDiscountFormGeneralModel;
  public modalOpened: boolean = false;
  public customerUsernames: string[];
  public customerExist: ICustomer[] = [];
  public customerNotExistUsernames: string[] = [];
  public customerToAdd: any = {};
  public modalIncludedProductsOpened: boolean = false;
  public includedProductsSku: string[];
  public includedProductsExist: IProduct[] = [];
  public includedProductsNotExistSkus: string[] = [];
  public includedProductsToAdd: any = {};
  public importOptions: any[];
  public exportOptions: any[];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    @Inject('DialogService') private dialog: any,
  ) {
    super($translate, authService, resource, state);
  }

  /**
   * @inheritDoc
   */
  ngOnInit(): void {
    this.setImportOptions();
    this.exportOptions = [
      {
        translationKey: 'PAGE.DISCOUNT.FORM.LABEL.EXPORT',
        entryPoint: '/v2/export',
        responseType: 'text/csv',
        type: 'text/csv',
        filename: `export-include-exclude-discount-${moment().format(DATE_SHORT_INTERNATIONAL_FORMAT)}.csv`,
        filters: false,
        roles: ['ROLE_WALISOFT_AGENT'],
        name: 'discount_include_exclude_products',
        postOptions: {
          exportCode: 'discount_include_exclude_products',
          formatCode: 'csv',
          dryRun: false,
          deferred: false,
          split: false,
          serializationGroups: [],
          limit: this.state.params.id,
          async: false
        }
      }
    ];

    this.resource.get(this.state.params.id, { formModel: DiscountFormGeneralModel })
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe((response: IDiscountFormGeneralModel) => {
        this.model = response;
        this.isReadOnly = this.model.readOnly;
        this.setReadOnly.emit(this.isReadOnly);
        this.commonFields.name.patchValue(this.model.name);
        this.commonFields.description.patchValue(this.model.description);
        this.commonFields.zendeskTicketId.patchValue(this.model.zendeskTicketId);
      });
  }

  public exportCsv() {
    const subscriber: Subscription = this.resource.exportFile({}, this.exportOptions[0]).subscribe(() => {}, undefined, () => subscriber.unsubscribe());
  }

  private setImportOptions() {
    this.importOptions = [
      {
        entryPoint: '/api/v2/import',
        importButton: 'PAGE.DISCOUNT.FORM.LABEL.IMPORT',
        businessObject: 'discount',
        businessObjectId: this.state.params.id
      },
    ];
  }
}
