import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { AbstractResource } from '../resources/abstract.resource';
import { HttpClient } from '@angular/common/http';
import { AttributeFamilyModel } from '@components/attribute-family/models/attribute-family.model';

@Injectable()
@Resource({
  routeName: 'attribute-family',
  entryPoint: '/v2/attribute_families',
  isHydra: true,
  model: AttributeFamilyModel
})
export class AttributeFamilyResource extends AbstractResource {

  protected nullableProperties: string[] = ['unit'];

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }
}
