import {Component, Inject, Input, OnInit} from '@angular/core';
import { AbstractFormComponent } from '@components/generic/Form/abstract-form.component';
import { AbstractResource } from '@resources/abstract.resource';
import { ProductResource } from '@components/product/product.resource';
import { AuthService } from '@services/auth.service';
import {
  ProductImportProductItemFieldModel,
  ProductImportProductItemModel
} from '@components/product/models/product-import.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-import-product-fields',
  template: require('./import-product-fields.component.html'),
  providers: [
    { provide: AbstractResource, useClass: ProductResource },
  ],
})
export class ImportProductFieldsComponent extends AbstractFormComponent {

  @Input()
  public product: ProductImportProductItemModel;
  public form: FormGroup;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
  ) {
    super($translate, authService, resource, state);
  }
}
