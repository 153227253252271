import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { DndModule } from 'ng2-dnd';
import { GenericModule } from '@components/generic/generic.module';
import { GenericFormModule } from '@components/generic/generic-form.module';
import { NotationListComponent, NotationRowComponent } from './List';

@NgModule({
  declarations: [NotationListComponent, NotationRowComponent],
  entryComponents: [NotationListComponent],
  imports: [CommonModule, DndModule.forRoot(), GenericFormModule, ReactiveFormsModule, GenericModule],
  exports: [NotationListComponent],
})
export class NotationModule {}
