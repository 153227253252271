import { Resource } from '@decorators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractResource } from '@resources';
import { Observable } from 'rxjs/Observable';

@Injectable()
@Resource({
  entryPoint: '/v2/sav/orders?isSav=1&warehouses[]=sav&stockStatuses[]=in_stock',
    isHydra: true,
    deleteAvailable: false,
    updateAvailable: true,
    createAvailable: false,
})
export class SavOrdersResource extends AbstractResource {

    constructor(http: HttpClient) {
        super(http);
    }

    public cGet(params: any = {}, options: any = {}): Observable<object> {
      delete params.isSparePart;
      delete params.date;

      if (params.sparePartId) {
        params['skus[]'] = 'SPAREPART_' + params.sparePartId;
        delete params.sparePartId;
      }

      return super.cGet(params, options);
    }
}
