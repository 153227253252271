import { Component, Inject, OnInit } from '@angular/core';
import { SessionHelper } from '@helpers';
import { AuthService } from '@services';
import { DashboardResource } from '@components/dashboard/dashboard.resource';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';

@Component({
  selector: 'app-sales-comparison',
  template: require('./sales-comparison.component.html'),
  styles: [require('./sales-comparison.component.scss')],
})
export class SalesComparisonComponent extends AbstractPageComponent implements OnInit {

  public salesComparison: any = [];
  public salesComparisonRef: any = {};
  public objectKeys = Object.keys;
  public formatNumber = SessionHelper.getUILanguage();

  /**
   * The calendar landmark title to differentiate block table.
   */
  get landmarkTitle(): string {
    if ('day' === this.landmark) {
      return this.translate('CALENDAR_LANDMARK.DAY').toUpperCase();
    }

    if ('week' === this.landmark) {
      return this.translate('CALENDAR_LANDMARK.WEEK').toUpperCase();
    }

    if ('year' === this.landmark) {
      return this.translate('CALENDAR_LANDMARK.YEAR').toUpperCase();
    }

    return '';
  }

  private landmark: string = '';
  private amountRef: any = {};

  public constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: DashboardResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }

  public ngOnInit(): void {
    this.resource.getSalesComparison()
      .takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        this.salesComparison = response;

        const countryRef = Object.keys(this.salesComparison[0])[0];

        this.salesComparisonRef = this.salesComparison[0][countryRef];
      })
    ;
  }

  /**
   * Is calendar landmark should be displayed?
   */
  public displayLandmarkTitle(property: string): boolean {
    if (this.landmark && -1 !== property.indexOf(this.landmark, 0)) {
      return;
    }

    if (-1 !== property.indexOf('day', 0)) {
      this.landmark = 'day';
    }

    if (-1 !== property.indexOf('week', 0)) {
      this.landmark = 'week';
    }

    if (-1 !== property.indexOf('year', 0)) {
      this.landmark = 'year';
    }

    return true;
  }

  /**
   * Is date end should be displayed?
   */
  public displayTimeScale(property: string): boolean {
    return -1 === property.indexOf('day', 0);
  }

  /**
   * Is the comparison percentage should be displayed?
   */
  public getComparisonPercentage(property: string, amount: number, countryCode: string): string|void {
    if (-1 !== property.indexOf('Current')) {
      this.amountRef[countryCode] = +amount;

      return;
    }

    // (amountRef - precedentAmount) / amountRef * 100
    const percentage =
      0 === this.amountRef[countryCode] ? -100 : (this.amountRef[countryCode] - +amount) / this.amountRef[countryCode] * 100
    ;

    const percentageFormated = Number.isInteger(percentage) ? `${percentage}%` : `${percentage.toFixed(2)}%`;

    return `<span class="${this.getPercentageStyle(percentage)}">${percentageFormated}</span>`;
  }

  /**
   * Gets the style of percentage comparison.
   *
   * green if positive,
   * red if negative
   */
  private getPercentageStyle(percentage: number): string {
    return -1 === Math.sign(percentage) ? 'negative' : 'positive';
  }

  /**
   * Gets the translation of the moment comparison in time.
   */
  public getMomentInTimeName(name: string): string {
    switch (name) {
      case 'dayCurrent':
        return this.translate('CALENDAR_LANDMARK.CURRENT_DAY');
      case 'dayOneWeekAgo':
      case 'weekOneWeekAgo':
        return this.translate('CALENDAR_LANDMARK.ONE_WEEK_AGO');
      case 'dayOneYearAgo':
      case 'weekOneYearAgo':
      case 'yearOneYearAgo':
        return this.translate('CALENDAR_LANDMARK.ONE_YEAR_AGO');
      case 'weekCurrent':
        return this.translate('CALENDAR_LANDMARK.CURRENT_WEEK');
      case 'weekOneYearAndOneWeekAgo':
        return `${this.translate('CALENDAR_LANDMARK.ONE_YEAR_AGO')} ${this.translate('CALENDAR_LANDMARK.ONE_WEEK_AGO')}`;
      case 'dayTwoYearsAgo':
      case 'weekTwoYearsAgo':
      case 'yearTwoYearsAgo':
        return this.translate('CALENDAR_LANDMARK.TWO_YEAR_AGO');
      case 'yearCurrent':
        return this.translate('CALENDAR_LANDMARK.CURRENT_YEAR');
      default:
        return name;
    }
  }
}
