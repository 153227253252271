'use strict'

import { CODE_FR, CODE_BE, CODE_GB, CODE_NL, CODE_ES, CODE_PT, CODE_IT, CODE_MC, CODE_DE, CODE_AT, CODE_LU, CODE_PL } from '@constants'
import { LogisticHelper, SessionHelper, HydraHelper } from '@helpers'

angular
  .module 'ammsFrontendApp'
  .controller 'OrderNewCtrl', [
    '$window',
    '$scope',
    '$state',
    '$translate',
    '$dialog',
    'InscpxeeResource',
    'OrderResource',
    'OrderItemStatusService',
    'ProductResource',
    'OrderHelper',
    'AuthService',
    'OrderDuplicateService',
    'CarrierGroupResource',
    '$filter',
    'PatternsResource'
    (
      $window,
      $scope,
      $state,
      $translate,
      $dialog,
      InscpxeeResource,
      OrderResource,
      OrderItemStatusService,
      ProductResource,
      OrderHelper,
      AuthService,
      OrderDuplicateService,
      CarrierGroupResource,
      $filter,
      PatternsResource
    ) ->
      $scope.isGranted = (roles) ->
        return AuthService.isGranted(roles)

      $scope.currentCountry = SessionHelper.getCountry()
      $scope.currencyCode = SessionHelper.getCurrency()
      $scope.paymentTypes = $scope.currentCountry.paymentTypes
      $scope.orderTypes = SessionHelper.getOrderTypes()
      $scope.marketplaces = SessionHelper.getMarketplaces()
      $scope.warehouses = LogisticHelper.getAssignableWarehouses(SessionHelper.getWarehouses())
      $scope.carrierGroups = LogisticHelper.getAssignableCarriers(SessionHelper.getCarrierGroups())
      $scope.statuses = SessionHelper.getOrderStatuses()
      $scope.order = {}
      $scope.items = []
      $scope.order.shippingAddress = {}
      $scope.order.billingAddress = {}
      $scope.order.locale = {}
      $scope.shippingIsSameAsBilling = false
      $scope.patterns = []
      $scope.causeRexpes = []
      $scope.causeRexpe = 0
      $scope.countries =
        'FR':
          0:
            'code': null
            'name': ''
          1:
            'code': CODE_FR
            'name': 'France'
          2:
            'code': CODE_BE
            'name': 'Belgique'
          3:
            'code': CODE_MC
            'name': 'Monaco'
        'ES':
          0:
            'code': null
            'name': ''
          1:
            'code': CODE_ES
            'name': 'Spain'
        'BE':
          0:
            'code': null
            'name': ''
          1:
            'code': CODE_BE
            'name': 'Belgique'
        'GB':
          0:
            'code': null
            'name': ''
          1:
            'code': CODE_GB
            'name': 'Great Britain'
        'NL':
          0:
            'code': null
            'name': ''
          1:
            'code': CODE_NL
            'name': 'Pays-Bas'
        'PT':
          0:
            'code': null
            'name': ''
          1:
            'code': CODE_PT
            'name': 'Portugal'
        'IT':
          0:
            'code': null
            'name': ''
          1:
            'code': CODE_IT
            'name': 'Italie'
        'DE':
          0:
            'code': null
            'name': ''
          1:
            'code': CODE_DE
            'name': 'Allemagne'
          2:
            'code': CODE_AT
            'name': 'Autriche'
        'PL':
          0:
            'code': null
            'name': ''
          1:
            'code': CODE_PL
            'name': 'Pologne'
        'LU':
          0:
            'code': null
            'name': ''
          1:
            'code': CODE_LU
            'name': 'Luxembourg'

      if $state.params.marketplace
        $scope.order.marketplace = $state.params.marketplace
      if $state.params.orderType
        $scope.order.orderType = $state.params.orderType
      if $scope.currentCountry.defaultLocale
        $scope.order.locale = $scope.currentCountry.defaultLocale

      $scope.$watch 'order.marketplace', (newValue, oldValue) ->
        if newValue isnt oldValue
          delete $scope.order.paymentType

      $scope.$watch 'order.shippingAddress.countryCode', (newValue, oldValue) ->
        if $scope.order.marketplace is "showroom"
          if newValue and newValue isnt oldValue
            CarrierGroupResource.getFromCountry(newValue).subscribe((response) ->
              $scope.carrierGroups = LogisticHelper.getAssignableCarriers(SessionHelper.transformCarrierGroup(response, newValue))
              if $scope.carrierGroups.length is 0 then $scope.carrierGroups = LogisticHelper.getAssignableCarriers(SessionHelper.getCarrierGroups()))
        else
          $scope.carrierGroups = LogisticHelper.getAssignableCarriers(SessionHelper.getCarrierGroups())

      # Check if shipping address is same as billing address
      isShippingIsSameAsBilling = (shippingIsSameAsBilling) ->
        if shippingIsSameAsBilling
          $scope.order.shippingAddress = $scope.order.billingAddress
        else
          $scope.order.shippingAddress = angular.copy $scope.order.shippingAddress

      $scope.checkShippingIsSameAsBilling = (shippingIsSameAsBilling) ->
        isShippingIsSameAsBilling shippingIsSameAsBilling



      # Updates cities list
      $scope.updateShippingAddressCityList = (items, shippingAddress) ->
        if (0 != items.length)
          searchRC = OrderHelper.checkCarriersInOrderItems(items, ['relaiscolis-eco', 'relaiscolis-confort'])

          if (!!shippingAddress.postcode && 3 <= shippingAddress.postcode.length && searchRC)
            $scope.shippingAddressCityList = []
            subscriber = InscpxeeResource.getMany({
                countryCode: shippingAddress.countryCode
                postcode: shippingAddress.postcode
              }).subscribe (inscpxeeResponse) ->
                $scope.shippingAddressCityList = _.uniq(inscpxeeResponse.map((item) -> return item.city.name))

                subscriber.unsubscribe()

      $scope.cancel = ->
        $dialog
          .confirm $translate.instant 'DIALOG.TEXT.DONT_SAVE'
          .then ->
            $state.go 'order_manager.list'

      $scope.canAddAddress = (order, marketplaceRootCode) ->
        return (
          order.marketplace &&
          (
            ('magento' != marketplaceRootCode && 'website' != marketplaceRootCode) ||
            (('magento' == marketplaceRootCode || 'website' == marketplaceRootCode) && order.paymentType)
          ) &&
          order.orderType
        )

      $scope.showPaymentLink = (order) ->
        marketplaceRootCode = $filter('marketplaceParentCode') order.marketplace
        return (['website', 'showroom'].includes(marketplaceRootCode)  || order.marketplace == 'b2b') && order.paymentType == 'credit_card'

      $scope.canAddItem = ->
        mandatoryFields = [
          'firstName',
          'lastName',
          'addressLine1',
          'postcode',
          'city',
          'countryCode',
          'email',
          'phone1'
        ]
        billingAddress = $scope.order.billingAddress
        shippingAddress = $scope.order.shippingAddress

        for field in mandatoryFields
          if !billingAddress || !billingAddress[field]
            return false

          if !shippingAddress || !shippingAddress[field]
            return false

        if !$scope.order.marketplace
          return false

        true

      $scope.canPostItem = (item) ->
        if ('mondialrelay-pr' == item.carrier && (!item.relayId? || '' == item.relayId))
          return false

        return (
          item.status? &&
          item.carrier? &&
          item.warehouse? &&
          item.product?.id? &&
          item.quantity? &&
          item.quantity > 0
        )

      $scope.addItem = ->
        $scope.items.push
          isNew: true
          canChange: true
          canChangeCarrier: true
          canBeCancelled: true
          product:
            sku: ''
            weight: 0
            m3: 0
            ecotax: 0
            deee: 0
            cmup: 0
          quantity: 1
          unitPrice: 0
          ecotax: 0
          deee: 0
          manuallyAdded: true
          shippingPrice: 0

      $scope.reloadProduct = (item, key) ->
        if angular.isObject item.product.sku
          ProductResource.get(item.product.sku.id)
            .subscribe( (resultProduct) ->
              item.product = resultProduct
              item.product.id = '/api/v2/products/' + item.product.id
              order = $scope.order
              order.items = [item]
              OrderResource.dryRun(order)
                .subscribe((order) ->
                  dryRunItem = order.items[0]

                  item.carrier = dryRunItem.carrier
                  item.warehouse = dryRunItem.warehouse
                  item.status = dryRunItem.status
                  item.unitPrice = dryRunItem.unitPrice
                  item.product.deee = dryRunItem.product.deee
                  item.product.cmup = dryRunItem.product.cmup
                  item.product.ecotax = dryRunItem.product.ecotax
                  item.product.weight = dryRunItem.product.weight
                  item.product.childrenStocks = dryRunItem.product.childrenStocks
                  item.product.stocks = dryRunItem.product.stocks
                )
          , ->
            $window.alert $translate.instant 'API.DISCONNECTION')

      # update field pattern & causeRexpe
      PatternsResource.getAllPatterns()
        .subscribe( (resultPattern) ->
          $scope.patterns = resultPattern
          $scope.updatePatterns = (pattern) ->
            $scope.patterns.forEach((element) ->
              if (parseInt(pattern) == element.id)
                $scope.causeRexpes = []
                for _i in element.causeRexpe
                  $scope.causeRexpes.push(_i)
          )
          $scope.updateCauseRexpe = (causeRexpe) ->
            $scope.causeRexpe = causeRexpe
        )

      $scope.removeItem = (itemIndex) ->
        $scope.items.splice itemIndex, 1

      $scope.getStockPackageQuantity = (packageStocks, warehouse) ->
        return ((packageStocks.filter (stock) -> stock.type == 'SP' && stock.warehouse.code == warehouse).at 0)?.quantity || 0

      $scope.getLineTotalPrice = (item) ->
        Math.round((item.unitPrice * item.quantity) * 100) / 100

      $scope.createOrder = (redirect) ->
        $dialog
          .confirm $translate.instant 'PAGE.ORDER.NEW.BLOCK_PRODUCTS.TEXT.CREATE.CONFIRM'
          .then ->
            sentOrder = angular.copy $scope.order
            sentOrder.discount = 0
            sentOrder.items = angular.copy $scope.items
            sentOrder.shippingPrice = 0
            sentOrder.referralOrderSav = if $scope.order.orderType == 'sav' then $scope.order.orderParentId else null
            delete sentOrder.orderParentId

            if !$scope.order.paymentType
              sentOrder.paymentType = 'marketplace'

            sentOrder.items.forEach( (orderItem) ->
              sentOrder.shippingPrice += orderItem.shippingPrice

              orderItem.packagesNumber = []
              for _package in orderItem.product.packages
                if _package.checked == true && (($scope.getStockPackageQuantity _package.stocks, orderItem.warehouse) > 0 \
                || !['lsl', 'logs', 'bureauneauf'].includes(orderItem.warehousese))
                  orderItem.packagesNumber.push _package.number

              if orderItem.unitPrice > 0
                if orderItem.product.ecotax
                  orderItem.ecotax = orderItem.product.ecotax
                  orderItem.unitPrice -= orderItem.ecotax

                if orderItem.product.deee
                  orderItem.deee = orderItem.product.deee
                  orderItem.unitPrice -= orderItem.deee
            )

            sentOrder.causeRexpe = angular.copy $scope.causeRexpe

            subscriber = OrderResource
              .create(sentOrder)
              .subscribe (resultOrder) ->
                subscriber.unsubscribe()

                OrderResource.createComment(resultOrder.id, $scope.modelComment)
                  .subscribe (postedComment) ->
                    subscriber.unsubscribe()
                    Snackbar.validate $translate.instant 'ALERTS.COMMENT.SAVE'
                    $scope.modelComment.message = ''
                    $scope.order.comments.push postedComment

                if typeof resultOrder is 'string'
                  $window.alert resultOrder
                else
                  $window.alert $translate.instant 'ALERTS.DATA.UPDATE'
                  if redirect is 'list'
                    $state.go 'order_manager.list'
                  else if redirect is 'edit'
                    $state.go 'order_manager.edit', { id: resultOrder.id }
              , ->
                $window.alert $translate.instant 'API.DISCONNECTION'

      $scope.addComment = (orderId) ->
        subscriber = OrderResource.createComment(orderId, $scope.modelComment)
          .subscribe (postedComment) ->
            subscriber.unsubscribe()
            Snackbar.validate $translate.instant 'ALERTS.COMMENT.SAVE'

            $scope.modelComment.message = ''
            $scope.order.comments.push postedComment

      $scope.isValidItems = ->
        isValid = true

        if $scope.items.length == 0
          isValid = false

        for item in $scope.items
          if !$scope.canPostItem item
            isValid = false

        isValid

      $scope.searchSku = (sku) ->
        if (sku.length >= 2)
          if ($scope.order.unpaired)
            return ProductResource.filterByCountryCodeAndSku(
              $scope.currentCountry.code,
              { sku, isPack: 0 },
              { blocking: false, dontUseModel: true }
            )

          return ProductResource.filterByCountryCodeAndSku(
            $scope.currentCountry.code,
            { sku },
            { blocking: false, dontUseModel: true }
          )

      $scope.getAvailableStatuses = ->
        return ['waiting_payment', 'processing', 'delivered']

      $scope.checkCarriersInOrderItems = (orderItems) ->
        return OrderHelper.checkCarriersInOrderItems(orderItems, ['mondialrelay-pr'])

      $scope.shouldDisplayPaymentType = (marketplace) ->
        parentMarketplace = $filter('marketplaceParentCode') marketplace
        return ['magento', 'website', 'showroom'].includes(parentMarketplace) || marketplace == 'b2b'

      initFromDuplicate = ->
        if ( !$state.params.marketplace && !$state.params.orderType )
          $scope.order.billingAddress = OrderDuplicateService.getBillingAddress()
          $scope.order.shippingAddress = OrderDuplicateService.getShippingAddress()
          $scope.order.marketplace = OrderDuplicateService.getMarketplace()
          $scope.order.paymentType =  OrderDuplicateService.getPaymentType()
          $scope.order.orderParentId =  OrderDuplicateService.getOrderId()
          OrderDuplicateService.resetAllProperties()

      # Initialization
      readArrivals()
      initFromDuplicate()
      isShippingIsSameAsBilling $scope.shippingIsSameAsBilling
  ]
