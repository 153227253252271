import { Inject, Injectable } from '@angular/core';
import { FormNotifierService } from '@services/form-notifier.service';
import {ComboSearchField, TextField} from '@components/generic/Form/dynamic/fields';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';

@Injectable()
export class CMSListFiltersService extends AbstractFiltersFieldsService {

  private booleanChoices: {value: number; label: string}[] = [];

  constructor(
    @Inject('StateService') protected state: ng.ui.IStateService,
    @Inject('TranslationService') public $translate: ng.translate.ITranslateService,
    formNotifier: FormNotifierService,
  ) {
    super(formNotifier, state);
  }

  public getFields(): any[] {

    this.booleanChoices = [
      {value: 1, label: this.$translate.instant('PAGE.CMS.BOOLEANS.YES')},
      {value: 0, label: this.$translate.instant('PAGE.CMS.BOOLEANS.NO')},
      {value: null, label: this.$translate.instant('PAGE.CMS.BOOLEANS.ALL')},
    ];

    return [
      new TextField({
        fieldName: 'translations.name',
        label: 'PAGE.CMS.LIST.FILTER.NAME',
        value: this.filters.get('name') ? this.filters.get('name') : undefined,
      }),

      new ComboSearchField({
        fieldName: 'translations.active',
        label: 'PAGE.CMS.LIST.FILTER.ACTIVE',
        data: this.booleanChoices,
        textField: 'label',
        valueField: 'value',
        value: this.filters.get('active') ? +this.filters.get('active') : undefined,
        valuePrimitive: true,
        order: 2,
      }),
    ];
  }
}
