import { Component, EventEmitter, Output } from '@angular/core';
import { IButtonPost } from '@components/generic/buttons/button-links.interface';
import { GenericResultListComponent } from '@components/generic/List';
import { IAddressingList } from '../models/addressing.interface';

@Component({
  selector: 'app-addressing-result-list',
  template: require('./addressing-result-list.component.html'),
  styles: [require('../../generic/List/generic-result-list.component.scss')],
})
export class AddressingResultListComponent extends GenericResultListComponent {
  @Output() public onPrintBarcode: EventEmitter<any> = new EventEmitter();

  public buttonsPost: IButtonPost[] = [
    {
      params: { printBarcode: true },
      label: this.translate('PAGE.ADDRESSING.LIST.ACTIONS.PRINT_BARCODES')
    }
  ];

  public onPost(event: any) {
    const toggledItems = this.items.filter((item: any) => item.toggled);

    if (event && event.printBarcode && event.printBarcode === true) {
      const barcodeToPrint = toggledItems
        .map((item: IAddressingList) => item.barcodeImage || null)
        .filter((barcodeImage: string | null) => !!barcodeImage)
        ;

      this.onPrintBarcode.emit(barcodeToPrint);
    }
  }
}
