import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { SnackbarService } from '../../../snackbar/snackbar.service';
import { AbstractResource } from '../../../../resources/abstract.resource';
import { SessionHelper } from '@helpers/session.helper';
import { FormNotifierService } from '@services/form-notifier.service';
import { ICountry } from '@interfaces/ICountry';
import { ProductResource } from '../../../../components/product/product.resource';
import { DiscountResource } from '@components/discount/resources/discount.resource';
import { DISCOUNT_TYPES } from '@components/discount/list/services/discount-filters-form.service';
import { AbstractComponent } from '@components/generic/abstract.component';
import { MarketplaceHelper } from '@helpers/MarketplaceHelper';
import {
  IDiscountFormGeneralModel, IDiscountRejectionTranslations,
  IDiscountTranslations,
  IDiscountType, IDiscountTypeCredit,
  IDiscountTypeGift,
  IFormBody,
  IFormValue,
  ITranslationsFormValue, ITranslationsRejectionFormValue
} from '@components/discount/interfaces/discount-form.interface';
import * as moment from 'moment';
import { DATE_FULL_FORMAT, DATE_SHORT_FORMAT } from '@constants';
import { takeUntil } from 'rxjs/operators';
import { CustomerResource } from '@components/customer';
import { ICustomer } from '@components/customer/interfaces';
import { CREATION_PAGE, EDITION_PAGE } from '@interfaces';
import { HydraHelper } from '@helpers/HydraHelper';
import {
  DiscountCartResource,
  DiscountCreditResource,
  DiscountGiftResource,
  DiscountProductResource,
  DiscountTranslationResource
} from '@components/discount/resources';

@Component({
  selector: 'app-discount-general-form',
  template: require('./discount-general-form.component.html'),
  styles: [require('./discount-general-form.component.scss')],
  providers: [
    { provide: AbstractResource, useClass: DiscountResource },
  ]
})
export class DiscountGeneralFormComponent extends AbstractComponent implements OnInit, OnDestroy {

  public form: FormGroup;
  public readonly discountTypesList = [
    { label: this.translate('PAGE.DISCOUNT.FILTER.TYPE.DATA.PRODUCT'), value: DISCOUNT_TYPES.product },
    { label: this.translate('PAGE.DISCOUNT.FILTER.TYPE.DATA.CART'), value: DISCOUNT_TYPES.cart },
    { label: this.translate('PAGE.DISCOUNT.FILTER.TYPE.DATA.GIFT'), value: DISCOUNT_TYPES.gift },
    { label: this.translate('PAGE.DISCOUNT.FILTER.TYPE.DATA.CREDIT'), value: DISCOUNT_TYPES.credit },
  ];
  public readonly discountTypes = DISCOUNT_TYPES;
  public products$: Observable<Object>;
  public customers: any = [];
  public readonly currency = SessionHelper.getCurrency().toLowerCase();
  public isReadOnly = false;
  public startTime: Date;
  public endTime: Date;
  protected discountCredit: any = null;
  protected discountGift: any = null;
  protected discountProduct: any = null;
  protected discountCart: any = null;
  protected currentCountry: ICountry = SessionHelper.getCountry();

  get translationsFA(): FormArray {
    return this.form.get('translations') as FormArray;
  }

  @Input() private commonFields: { [keys: string]: AbstractControl };
  @Input() private readonly pageType: string;
  @Input() public readOnly: boolean;
  @Input() public model: IDiscountFormGeneralModel;

  @Output() public validNameField: EventEmitter<any> = new EventEmitter();
  @Output() public setReadOnly: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    private productResource: ProductResource,
    private snackbar: SnackbarService,
    private formNotifier: FormNotifierService,
    @Inject('DialogService') private dialog: any,
    private customerResource: CustomerResource,
    private discountCreditResource: DiscountCreditResource,
    private discountGiftResource: DiscountGiftResource,
    private discountProductResource: DiscountProductResource,
    private discountCartResource: DiscountCartResource,
    private discountTranslationResource: DiscountTranslationResource
  ) {
    super($translate, authService, resource, state);
  }

  /**
   * @inheritDoc
   */
  ngOnInit(): void {
    this.products$ = this.productResource.getByCountryCodeAndSku(
      SessionHelper.getCountry().code,
      undefined,
      { 'marketplaces[]': MarketplaceHelper.getWebsiteMarketplace().code },
      { dontUseModel: true, blocking: false },
    );

    if (EDITION_PAGE === this.pageType) {
      this.isReadOnly = this.model.readOnly;
      this.setReadOnly.emit(this.isReadOnly);
      this.commonFields.name.patchValue(this.model.name);
      this.commonFields.description.patchValue(this.model.description);
      this.commonFields.zendeskTicketId.patchValue(this.model.zendeskTicketId);
      this.startTime = this.model.dateBegin ? new Date(this.model.dateBegin) : null;
      this.endTime = this.model.dateEnd ? new Date(this.model.dateEnd) : null;
    } else {
      this.startTime = (new Date());
      this.startTime.setHours(0);
      this.startTime.setMinutes(0);
      this.startTime.setSeconds(0);
      this.endTime = (new Date());
      this.endTime.setHours(23);
      this.endTime.setMinutes(59);
      this.endTime.setSeconds(59);
    }
    this.buildForm();
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      id: [this.model ? this.model.type.id : null],
      type: [this.model ? this.model.type.type : '', Validators.required],
      amount: [this.model ? this.model.type.amount : null],
      percentage: [this.model ? this.model.type.percentage : null],
      freeShipping: [this.model ? this.model.type.freeShipping : null],
      active: [this.model ? this.model.active : false],
      highlight: [this.model ? this.model.highlight : false],
      dateBegin: [this.model && this.model.dateBegin ? moment(this.model.dateBegin).format(DATE_SHORT_FORMAT) : null],
      dateEnd: [this.model && this.model.dateEnd ? moment(this.model.dateEnd).format(DATE_SHORT_FORMAT) : null],
      gifts: [this.getGifts()],
      numberOfGiftPerCart: [this.getNumberOfGiftPerCart()],
      customers: [this.model ? this.model.customers : null],
      order: [this.getCreditOrder()],
    });
    if (this.state.params.type && this.state.params.type === 'credit') {
      this.form.get('type').patchValue(DISCOUNT_TYPES.credit);
    }
    if (this.form.controls.type.value !== 'credit') {
      this.buildTranslationsForm();
    }

    this.handleFormFields();
  }

  private getGifts(): string[] {
    if (this.model && this.model.type.gifts) {
      return this.model.type.gifts.map((gift) => gift.id);
    }

    return null;
  }

  private getNumberOfGiftPerCart(): string {
    if (this.model && this.model.type.numberOfGiftPerCart) {
      return '' + this.model.type.numberOfGiftPerCart;
    }

    return '1';
  }

  private getCreditOrder(): string {
    if (this.model && 'credit' === this.model.type.type && this.model.type.order) {
      return this.model.type.order.id;
    }

    return '';
  }

  private buildTranslationsForm(): void {
    // build several form group for each country locales
    const formGroups = this.currentCountry.locales.map((locale: string) => {
      let translation: any = null;
      let code: string = '';
      let id: string = null;

      if (this.model) {
        translation = this.model.type.translations[locale];
        code = translation ? translation.code : '';
        id = translation && translation.id ? translation.id : null;
      }

      return this.formBuilder.group({
        id: id,
        code: [code, Validators.required],
        locale
      });
    });

    // pass the form groups to a form array
    const formArray = this.formBuilder.array(formGroups);

    // replace existing control with a new one, we pass it the form array that is a form group collection
    this.form.setControl('translations', formArray);
  }

  /**
   * Adds, remove some fields according to the discount type.
   */
  private handleFormFields(): void {
    const giftFields = ['gifts', 'numberOfGiftPerCart'];

    this.form.get('type').valueChanges.forEach((value: string) => {
      // if discount is gift add gifts fields
      if (value === DISCOUNT_TYPES.gift) {
        this.form.addControl(giftFields[0], this.formBuilder.control('', Validators.required));
        this.form.addControl(giftFields[1], this.formBuilder.control('', Validators.required));

        if (this.form.get('amount') && this.form.get('percentage')) {
          this.form.removeControl('amount');
          this.form.removeControl('percentage');
        }
      }

      // if discount is not a gift remove gifts fields
      if (value !== DISCOUNT_TYPES.gift) {
        if (this.form.get(giftFields[0]) && this.form.get(giftFields[1])) {
          giftFields.forEach((field: string) => this.form.removeControl(field));
        }

        if (!this.form.get('amount')) {
          this.form.addControl('amount', this.formBuilder.control(0, Validators.required));
        }

        if (!this.form.get('percentage') && value !== DISCOUNT_TYPES.credit) {
          this.form.addControl('percentage', this.formBuilder.control(0, Validators.required));
        }
      }

      // if discount is credit remove code field (for the moment only code is in translations)
      if (value === DISCOUNT_TYPES.credit) {
        if (this.form.get('translations')) {
          this.form.removeControl('translations');
        }

        if (this.form.get('percentage')) {
          this.form.removeControl('percentage');
        }

        if (this.form.get('freeShipping')) {
          this.form.removeControl('freeShipping');
        }

        if (!this.form.get('customers')) {
          this.form.addControl('customers', this.formBuilder.control([]));
        }

        if (!this.form.get('combinable')) {
          this.form.addControl('combinable', this.formBuilder.control(true));
        }

        if (!this.form.get('order')) {
          this.form.addControl('order', this.formBuilder.control(null));
        }
      }

      // if discount is not credit add the translations fields with code
      if (value !== DISCOUNT_TYPES.credit && !this.form.get('translations') && !this.form.get('freeShipping')) {
        this.form.addControl('translations', this.formBuilder.control([]));
        this.buildTranslationsForm();

        this.form.addControl('freeShipping', this.formBuilder.control(null));

        if (this.form.get('customers')) {
          this.form.removeControl('customers');
        }

        if (this.form.get('combinable')) {
          this.form.get('combinable').setValue(false);
        }

        if (this.form.get('order')) {
          this.form.removeControl('order');
        }
      }
    });
  }

  public submit(event?: any): void {
    let message = this.translate('PAGE.DISCOUNT.FORM.CONFIRM_SAVE');
    if (this.model === undefined || this.model.customers.length < 1 && null === this.model.maximumUsage && null === this.model.maximumUsagePerCustomer) {
      message = `${this.translate('PAGE.DISCOUNT.FORM.ALERT_SAVE')}\n${message}`;
    }
    this.dialog.confirm(message)
      .then(async () => {
        const body: IFormBody = await this.prepareBody();
        const observable$ = EDITION_PAGE === this.pageType ?
          (<DiscountResource>this.resource).updateDiscount(this.state.params.id, body) :
          (<DiscountResource>this.resource).createDiscount(body)
        ;

        if (!body.name) {
          this.validNameField.emit(false);

          return;
        }

        this.validNameField.emit(true);

        observable$
          .pipe(
            takeUntil(this.destroyed$)
          )
          .subscribe((response: any) => {
            CREATION_PAGE === this.pageType ?
              this.createTranslations(response['@id']) :
              this.currentCountry.locales.map((locale: string) => {
                if (undefined !== response.translations[locale]) {
                  this.updateTranslations(response.translations[locale]['@id'].split('/').pop(), locale);
                } else {
                  this.createTranslations(response['@id']);
                }
              });

            this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
            this.formNotifier.notifyFormSubmitted();

            if (event && event.redirect) {
              this.state.go(`${this.resource.routeName}.list`);

              return;
            }

            this.state.go(`${this.resource.routeName}.edit`, {id: response['@id'].split('/').pop()});
          })
        ;
      })
    ;
  }

  private createDiscountTypeCredit(formValue: IFormValue): Promise<void> {
    return this.discountCreditResource.createDiscountCredit({
      amount: formValue.amount,
      order: this.prepareOrder(formValue)
    })
      .toPromise()
      .then((response: any) => {
        this.discountCredit = response['@id'];
      });
  }

  private updateDiscountTypeCredit(creditId: string, formValue: IFormValue): Promise<void> {
    return this.discountCreditResource.updateDiscountCredit(creditId, {
      amount: formValue.amount,
      order: this.prepareOrder(formValue)
    })
      .toPromise()
      .then((response: any) => {
        this.discountCredit = response['@id'];
      });
  }

  private prepareOrder(formValue: IFormValue): string {
    if (formValue.order) {
      return HydraHelper.buildIri(formValue.order, 'orders');
    }
  }

  private createDiscountTypeGift(formValue: IFormValue): Promise<void> {
    const gifts = formValue.gifts.map((gift) => gift ? HydraHelper.buildIri(gift, 'products') : '');

    return this.discountGiftResource.createDiscountGift({
      gifts: gifts,
      numberOfGiftPerCart: +formValue.numberOfGiftPerCart,
      freeShipping: formValue.freeShipping,
      translations: this.getFormTranslations(),
    }).toPromise()
      .then((response: any) => {
        this.discountGift = response['@id'];
      });
  }

  private updateDiscountTypeGift(giftId: string, formValue: IFormValue): Promise<void> {
    const gifts = formValue.gifts.map((gift) => gift ? HydraHelper.buildIri(gift, 'products') : '');

    return this.discountGiftResource.updateDiscountGift(giftId, {
      gifts: gifts,
      numberOfGiftPerCart: +formValue.numberOfGiftPerCart,
      freeShipping: formValue.freeShipping,
      translations: this.getFormTranslations('discount_gift_translations'),
    })
      .toPromise()
      .then((response: any) => {
        this.discountGift = response['@id'];
      });
  }

  private createDiscountTypeProduct(formValue: IFormValue): Promise<void> {
    return this.discountProductResource.createDiscountProduct({
      percentage: formValue.percentage,
      amount: formValue.amount,
      freeShipping: formValue.freeShipping,
      translations: this.getFormTranslations(),
    }).toPromise()
      .then((response: any) => {
        this.discountProduct = response['@id'];
      });
  }

  private updateDiscountTypeProduct(productId: string, formValue: IFormValue): Promise<void> {
    return this.discountProductResource.updateDiscountProduct(productId, {
      percentage: formValue.percentage,
      amount: formValue.amount,
      freeShipping: formValue.freeShipping,
      translations: this.getFormTranslations('discount_product_translations'),
    })
      .toPromise()
      .then((response: any) => {
        this.discountProduct = response['@id'];
      });
  }

  private createDiscountTypeCart(formValue: IFormValue): Promise<void> {
    return this.discountCartResource.createDiscountCart({
      percentage: formValue.percentage,
      amount: formValue.amount,
      freeShipping: formValue.freeShipping,
      translations: this.getFormTranslations(),
    }).toPromise()
      .then((response: any) => {
        this.discountCart = response['@id'];
      });
  }

  private updateDiscountTypeCart(cartId: string, formValue: IFormValue): Promise<void> {
    return this.discountCartResource.updateDiscountCart(cartId, {
      percentage: formValue.percentage,
      amount: formValue.amount,
      freeShipping: formValue.freeShipping,
      translations: this.getFormTranslations('discount_cart_translations'),
    }).toPromise()
      .then((response: any) => {
        this.discountCart = response['@id'];
      });
  }

  private createTranslations(discountIri: string): void {
    this.commonFields.ErrorMessagesTranslations.value.forEach((translation: ITranslationsRejectionFormValue) => {
      this.discountTranslationResource.createDiscountTranslation({
        translatable: discountIri,
        locale: translation.locale,
        errorMessage: translation.errorMessage
      }).toPromise()
        .then(() => {
        });
    });
  }

  private updateTranslations(translationId: string, locale: string): void {
    this.commonFields.ErrorMessagesTranslations.value.forEach((translation: ITranslationsRejectionFormValue) => {
      if (translation.locale === locale) {
        this.discountTranslationResource.updateDiscountTranslation(translationId, {
          locale: translation.locale,
          errorMessage: translation.errorMessage
        }).toPromise()
          .then(() => {
          })
        ;
      }
    });
  }

  // private prepareBody(): IFormBody {
    private async prepareBody(): Promise<{
    country: string;
    discountProduct: IDiscountType;
    discountCredit: IDiscountTypeCredit;
    active: boolean;
    description: any;
    zendeskTicketId: any;
    discountGift: IDiscountTypeGift;
    dateEnd: string;
    dateBegin: string;
    highlight: boolean;
    translations: IDiscountRejectionTranslations;
    name: any;
    discountCart: IDiscountType;
    customers: string[];
    combinable: boolean;
    order?: string;
  }> {
    const formValue: IFormValue = this.form.value;
    let dateBegin;
    let dateEnd;

    if (formValue.dateBegin) {
      if (this.startTime) {
        dateBegin = moment(formValue.dateBegin, DATE_SHORT_FORMAT)
          .add(this.startTime.getHours(), 'hours')
          .add(this.startTime.getMinutes(), 'minutes')
          .add(this.startTime.getSeconds(), 'seconds')
          .format(DATE_FULL_FORMAT)
        ;
      } else {
        dateBegin = moment(formValue.dateBegin, DATE_SHORT_FORMAT).startOf('day').format(DATE_FULL_FORMAT);
      }
    }

    if (formValue.dateEnd) {
      if (this.endTime) {
        dateEnd = moment(formValue.dateEnd, DATE_SHORT_FORMAT)
          .add(this.endTime.getHours(), 'hours')
          .add(this.endTime.getMinutes(), 'minutes')
          .add(this.endTime.getSeconds(), 'seconds')
          .format(DATE_FULL_FORMAT)
        ;
      } else {
        dateEnd = moment(formValue.dateEnd, DATE_SHORT_FORMAT).endOf('day').format(DATE_FULL_FORMAT);
      }
    }

    switch (formValue.type) {
      case 'credit':
        CREATION_PAGE === this.pageType ? await this.createDiscountTypeCredit(formValue) : await this.updateDiscountTypeCredit(formValue.id, formValue);
        break;
      case 'gift':
        CREATION_PAGE === this.pageType ? await this.createDiscountTypeGift(formValue) : await this.updateDiscountTypeGift(formValue.id, formValue);
        break;
      case 'product':
        CREATION_PAGE === this.pageType ? await this.createDiscountTypeProduct(formValue) : await this.updateDiscountTypeProduct(formValue.id, formValue);
        break;
      case 'cart':
        CREATION_PAGE === this.pageType ? await this.createDiscountTypeCart(formValue) : await this.updateDiscountTypeCart(formValue.id, formValue);
        break;
    }

    return {
      active: formValue.active,
      dateBegin: dateBegin,
      dateEnd: dateEnd,
      name: this.commonFields.name.value,
      description: this.commonFields.description.value,
      zendeskTicketId: this.commonFields.zendeskTicketId.value,
      discountProduct: formValue.type === 'product' ? this.discountProduct : null,
      discountCart: formValue.type === 'cart' ? this.discountCart : null,
      discountGift: formValue.type === 'gift' ? this.discountGift : null,
      discountCredit: formValue.type === 'credit' ? this.discountCredit : null,
      country: HydraHelper.buildIri(SessionHelper.getCountry().id, 'countries'),
      customers: formValue.type === 'credit' ? this.prepareCustomers(formValue) : null,
      translations: this.getErrorMessageFormTranslations(),
      highlight: formValue.highlight,
      combinable: formValue.combinable,
    };
  }

  private prepareCustomers(formValue: IFormValue): string[] {
    if (formValue.customers) {
      return formValue.customers.map((customer: { id: string; label: string }): string => customer.id ? HydraHelper.buildIri(customer.id, 'customers') : '');
    }
  }

  private getFormTranslations(discountTranslation: string = null): IDiscountTranslations {
    const translations: IDiscountTranslations = {};

    this.translationsFA.controls.forEach((formGroup: FormGroup) => {
      const translationsFormValue: ITranslationsFormValue = formGroup.value;

      if (translationsFormValue.id) {
        translations[translationsFormValue.locale] = {
          'id': HydraHelper.buildIri(translationsFormValue.id, discountTranslation),
          code: translationsFormValue.code,
          locale: translationsFormValue.locale,
        };
      } else {
        translations[translationsFormValue.locale] = {
          code: translationsFormValue.code,
          locale: translationsFormValue.locale,
        };
      }
    });

    return translations;
  }

  private getErrorMessageFormTranslations(): IDiscountRejectionTranslations {
    const translations: IDiscountRejectionTranslations = {};

    this.commonFields.ErrorMessagesTranslations.value.forEach((translation: ITranslationsRejectionFormValue) => {
      translations[translation.locale] = translation;
    });

    return translations;
  }

  public hasSeveralTranslations(): boolean {
    return this.currentCountry.locales.length > 1;
  }

  public getCustomers(e: string): void {
    if (e.length > 2) {
      this.customerResource.cGet({ username: e }, { dontUseModel: true, blocking: false, returnHydraMembers: true })
        .pipe(
          takeUntil(this.destroyed$)
        )
        .subscribe((response: ICustomer[]) => {
          this.customers = response.map((customer: ICustomer) => {
            return { id: customer.id, label: customer.username };
          });
        });
    }
  }
}
