import { Subject } from 'rxjs/Subject';

export interface IColumnSortedEvent {
    sortColumn: string;
    sortDirection: string;
}

export class SortService {
    private columnSortedSource = new Subject<IColumnSortedEvent>();
    public columnSorted$ = this.columnSortedSource.asObservable();

    columnSorted(event: IColumnSortedEvent) {
      this.columnSortedSource.next(event);
    }
}
