import { Component, Inject, OnInit } from '@angular/core';
import { AbstractResource } from '@resources/abstract.resource';
import { CMSResource } from '@resources/cms.resource';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { AuthService } from '@services/auth.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ICountry } from '@interfaces/ICountry';
import { SessionHelper } from '@helpers/session.helper';
import {takeUntil} from 'rxjs/operators';
import {ICMS, ICMSSave, ICMSTranslations} from '@components/cms/models/cms.interface';
import { EDITION_PAGE } from '@interfaces/IPageComponent';
import { SnackbarService } from '@components/snackbar';
import { FormNotifierService } from '@services/form-notifier.service';
import {Observable} from 'rxjs/Observable';
import {forkJoin} from 'rxjs/observable/forkJoin';

@Component({
  selector: 'app-cms-form',
  template: require('./cms-form.component.html'),
  providers: [{provide: AbstractResource, useClass: CMSResource}],
})
export class CMSFormComponent extends AbstractPageComponent implements OnInit {

  public form: FormGroup;
  public currentCountry: ICountry;
  public cms: ICMS;
  public EDITION_PAGE = EDITION_PAGE;

  get translationsFA(): FormArray {
    return this.form.get('translations') as FormArray;
  }

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    private snackbar: SnackbarService,
    private formNotifier: FormNotifierService,
    @Inject('DialogService') private dialog: any,
  ) {
    super($translate, authService, resource, state);

    this.currentCountry = SessionHelper.getCountry();
  }

  public ngOnInit(): void {
    this.buildForm();

    if (EDITION_PAGE === this.pageType) {
      this.fetch();

      return;
    }

    this.buildTranslationsForm();
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      translations: this.formBuilder.array([]),
    });
  }

  private fetch(): void {
    (<CMSResource>this.resource).get(this.state.params.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: ICMS) => {
        this.cms = response;
        this.buildTranslationsForm();
      })
    ;
  }

  private buildTranslationsForm(): void {
    const formGroups = this.currentCountry.locales.map((locale: string) => {

      return this.formBuilder.group({
        slug: [this.hasExistingTranslations(locale) ? this.cms.translations[locale].slug : '', Validators.required],
        name: [this.hasExistingTranslations(locale) ? this.cms.translations[locale].name : '', Validators.required],
        active: [this.hasExistingTranslations(locale) ? this.cms.translations[locale].active : false],
        content: [this.hasExistingTranslations(locale) ? this.cms.translations[locale].content : '', Validators.required],
        seoMetaRobot: [this.hasExistingTranslations(locale) ? this.cms.translations[locale].seoMetaRobot : '', Validators.required],
        titleSeo: [this.hasExistingTranslations(locale) ? this.cms.translations[locale].titleSeo : ''],
        metaDescriptionSeo: [this.hasExistingTranslations(locale) ? this.cms.translations[locale].metaDescriptionSeo : ''],
        canonical: [this.hasExistingTranslations(locale) ? this.cms.translations[locale].canonical : ''],
        breadcrumb: [this.hasExistingTranslations(locale) ? this.cms.translations[locale].breadcrumb : []],
        locale,
      });
    });

    const formArray = this.formBuilder.array(formGroups);

    this.form.setControl('translations', formArray);
  }

  private hasExistingTranslations(locale: string): boolean {
    return this.cms && this.cms.translations && !!this.cms.translations[locale];
  }

  public hasSeveralTranslations(): boolean {
    return this.currentCountry.locales.length > 1;
  }

  public submit(event: {redirect: boolean}): void {
    if (EDITION_PAGE === this.pageType) {
      this.update(event);

      return;
    }
    this.create(event);
  }

  private create(event: {redirect: boolean}): void {
    this.dialog.confirm(this.translate('PAGE.CMS.CONFIRM.CREATE'))
      .then(() => {
        const body: ICMS = this.prepareBody();

        this.resource.create(body)
          .pipe(takeUntil(this.destroyed$))
          .subscribe((response: ICMS) => {
            this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));

            if (event && event.redirect) {
              this.state.go(`${this.resource.routeName}.list`);

              return;
            }

            this.formNotifier.notifyFormSubmitted();
            this.state.go(`${this.resource.routeName}.edit`, { id: response.id }, { reload: true });
          })
        ;
      })
    ;
  }

  private update(event: {redirect: boolean}): void {
    this.dialog.confirm(this.translate('PAGE.CMS.CONFIRM.UPDATE'))
      .then(() => {
        const id: string = this.state.params.id;
        const body: ICMSSave = this.prepareTranslationsBody();

        const observables: Observable<any>[] = [];

        for (const translationId in body) {
          if (body.hasOwnProperty(translationId)) {
            observables.push(
              this.resource.partialUpdate(translationId, body[translationId], {
                entryPoint: '/v2/content_block_translations/' + translationId
              })
            );
          }
        }

        forkJoin(observables)
          .pipe(takeUntil(this.destroyed$))
          .subscribe(() => {
            this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));

            if (event && event.redirect) {
              this.state.go(`${this.resource.routeName}.list`);

              return;
            }

            this.formNotifier.notifyFormSubmitted();
            this.state.go(`${this.resource.routeName}.edit`, { id }, { reload: true });
          });
      })
    ;
  }

  public prepareBody(): ICMS {
      const translations: { [locale: string]: ICMSTranslations } = {};

      this.translationsFA.controls.forEach((formGroup: FormGroup) => {
        translations[formGroup.value.locale] = formGroup.value;
      });

      return { translations };
  }

  private prepareTranslationsBody(): ICMSSave {
    const response: ICMSSave = {};

    this.translationsFA.controls.forEach((formGroup: FormGroup) => {
      const translation: ICMSTranslations = formGroup.value;
      const locale: string = translation.locale;
      const id: string = this.cms.translations[locale].id;

      response[id] = translation;
    });

    return response;
  }
}
