export default websiteCategoryRoutes;

/** @ngInject */
function websiteCategoryRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'walipush',
    sidebarSubNav: 'website_category',
    title: '',
    reloadChangeCountry: 'website_category.new',
  };

  const tabs = [
    {
      label: 'PAGE.CATEGORIES.TABS.GENERAL',
      state: 'websiteCategories.edit',
    },
    {
      label: 'PAGE.CATEGORIES.TABS.EMERCH',
      state: 'websiteCategories.edit.superProducts',
    },
    {
      label: 'PAGE.CATEGORIES.TABS.SEO',
      state: 'websiteCategories.edit.seo',
    },
    {
      label: 'PAGE.CATEGORIES.TABS.CATEGORIES_SATELLITE',
      state: 'websiteCategories.edit.categoriesSatellite.list',
    },
    {
      label: 'PAGE.CATEGORIES.CONTENT_WEBSITE',
      state: 'websiteCategories.edit.blocks',
    },
  ];

  $stateProvider
    .state('websiteCategories', {
      url: '/website-categories',
      parent: 'walisoft',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.CATEGORY',
        stateName: 'websiteCategories.list'
      }
    })
    .state('websiteCategories.list', {
      url: `?
        active
        &activeTab
      `,
      views: {
        'page@layout': {
          template: '<app-website-category-list></app-website-category-list>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.CATEGORIES.LIST.TITLE',
      })
    })
    .state('websiteCategories.new', {
      url: '/new',
      breadcrumb: {
        label: 'BREADCRUMB.NEW'
      },
      views: {
        'page@layout': {
          template: '<app-website-category-form></app-website-category-form>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.CATEGORIES.NEW.TITLE',
        tabs: tabs,
      })
    })
    .state('websiteCategories.edit', {
      url: '/{id}',
      breadcrumb: {
        label: 'BREADCRUMB.NEW'
      },
      views: {
        'page@layout': {
          template: '<app-website-category-form></app-website-category-form>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.CATEGORIES.EDIT.TITLE',
        tabs: tabs,
      })
    })
    .state('websiteCategories.edit.superProducts', {
      url: '/super-products',
      breadcrumb: { label: 'BREADCRUMB.SUPER_PRODUCT' },
      views: {
        'page@layout': { template: '<app-super-products-form></app-super-products-form>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.CATEGORIES.EDIT.TITLE',
        tabs: tabs,
      })
    })
    .state('websiteCategories.edit.seo', {
      url: '/seo',
      breadcrumb: { label: 'BREADCRUMB.SEO' },
      views: {
        'page@layout': { template: '<app-seo-form></app-seo-form>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.CATEGORIES.EDIT.TITLE',
        tabs: tabs,
      })
    })
    .state('websiteCategories.edit.blocks', {
      url: '/blocks',
      breadcrumb: { label: 'BREADCRUMB.EDIT' },
      views: {
        'page@layout': { template: '<app-category-blocks-form></app-category-blocks-form>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.CATEGORIES.CONTENT_WEBSITE',
        tabs: tabs,
        reloadChangeCountry: 'websiteCategories.edit.blocks',
      })
    })
    .state('websiteCategories.edit.categoriesSatellite', {
      url: '/categories-satellite',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.CATEGORIES_SATELLITE',
        stateName: 'websiteCategories.edit.categoriesSatellite.list'
      }
    })
    .state('websiteCategories.edit.categoriesSatellite.list', {
      url: '/list',
      breadcrumb: { label: 'BREADCRUMB.LIST' },
      views: {
        'page@layout': { template: '<app-categories-satellite-list></app-categories-satellite-list>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.CATEGORIES_SATELLITE.LIST.TITLE',
        tabs: tabs,
        reloadChangeCountry: 'websiteCategories.edit.categoriesSatellite.list',
      })
    })
    .state('websiteCategories.edit.categoriesSatellite.new', {
      url: '/new',
      breadcrumb: { label: 'BREADCRUMB.NEW' },
      views: {
        'page@layout': { template: '<app-categories-satellite-form></app-categories-satellite-form>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.CATEGORIES_SATELLITE.NEW.TITLE',
        tabs: tabs,
        reloadChangeCountry: 'websiteCategories.edit.categoriesSatellite.list',
      })
    })
    .state('websiteCategories.edit.categoriesSatellite.edit', {
      url: '/{idSatellite}',
      breadcrumb: { label: 'BREADCRUMB.EDIT' },
      views: {
        'page@layout': { template: '<app-categories-satellite-form></app-categories-satellite-form>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.CATEGORIES_SATELLITE.EDIT.TITLE',
        tabs: tabs,
        reloadChangeCountry: 'websiteCategories.edit.categoriesSatellite.list',
      })
    })
  ;
}
