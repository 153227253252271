import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { GenericFormModule } from '@components/generic/generic-form.module';
import { GenericModule } from '@components/generic/generic.module';
import { CarrierFamilyResource } from '@components/carrierFamily';
import { CarrierFamilyFormComponent } from '@components/carrierFamily/single/form/carrier-family-form.component';
import { CarrierFamilyEditComponent } from '@components/carrierFamily/single/edit/carrier-family-edit.component';
import { CarrierFamilyNewComponent } from '@components/carrierFamily/single/new/carrier-family-new.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker/bs-datepicker.module';
import { DndModule } from 'ng2-dnd';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns/dist/es/dropdowns.module';
import { EnhancedFieldsModule } from '@directives/enhanced-fields.module';
import { SliderModule } from '@progress/kendo-angular-inputs/dist/es/slider.module';
import { SwitchModule } from '@progress/kendo-angular-inputs/dist/es/switch.module';
import { TabsModule } from 'ngx-bootstrap/tabs/tabs.module';
import { CarrierResource } from '@resources';
import { CarrierFamilyShippingPriceComponent } from '@components/carrierFamily/shipping-price';
import { CarrierFamilyListComponent, CarrierFamilyResultListComponent } from '@components/carrierFamily/list';

@NgModule({
  declarations: [
    CarrierFamilyListComponent,
    CarrierFamilyFormComponent,
    CarrierFamilyNewComponent,
    CarrierFamilyEditComponent,
    CarrierFamilyShippingPriceComponent,
    CarrierFamilyResultListComponent,
  ],
  entryComponents: [
    CarrierFamilyListComponent,
    CarrierFamilyFormComponent,
    CarrierFamilyNewComponent,
    CarrierFamilyEditComponent,
    CarrierFamilyShippingPriceComponent,
  ],
  exports: [
    CarrierFamilyFormComponent,
    CarrierFamilyNewComponent,
    CarrierFamilyEditComponent,
  ],
  imports: [
    CommonModule,
    BsDatepickerModule.forRoot(),
    DndModule.forRoot(),
    DropDownsModule,
    EnhancedFieldsModule,
    GenericFormModule,
    ReactiveFormsModule,
    SliderModule,
    SwitchModule,
    TabsModule.forRoot(),
    GenericModule,
    FormsModule,
  ],
  providers: [
    CarrierResource,
    CarrierFamilyResource
  ],
})
export class CarrierFamilyModule {}
