import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericFormModule, GenericModule } from '@components/generic';
import { PipesModule } from '../../pipes/pipes.module';
import { ProformaInvoiceListComponent } from '@components/proforma-invoice/list/proforma-invoice-list.component';
import { ProformaInvoiceFormComponent } from '@components/proforma-invoice/form/proforma-invoice-form.component';
import { ProformaInvoiceResource } from '@components/proforma-invoice/resources/proforma-invoice.resource';
import { DialogModule, WindowModule } from '@progress/kendo-angular-dialog';
import { SharedModule } from '@progress/kendo-angular-dropdowns';

@NgModule({
  declarations: [
    ProformaInvoiceListComponent,
    ProformaInvoiceFormComponent
  ],
  entryComponents: [
    ProformaInvoiceListComponent,
    ProformaInvoiceFormComponent
  ],
  providers: [
    ProformaInvoiceResource
  ],
  imports: [
    CommonModule,
    GenericModule,
    DialogModule,
    SharedModule,
    WindowModule,
    GenericFormModule,
    PipesModule
  ]
})
export class ProformaInvoiceModule {}
