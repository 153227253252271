import { CountryHelper, SessionHelper } from '@helpers';

export class ArrivalListModel {
  id: number;
  productId: number;
  sku: string;
  label: string;
  warehouse: string;
  season?: string;
  date: string;
  quantity: number;
  shippedQuantity: number;
  receivedQuantity: number;
  sageIdentifier?: string;
  comment?: string;
  vintageDate?: string;
  reason?: string;
  updatedBy?: string;

  constructor(arrival: any) {
    const translations = arrival.masterProductsWarehouses.masterProduct.products[0].translations;
    let translation = translations[SessionHelper.getLocale()] || null;

    if (null === translation) {
      const locales = CountryHelper.getLocales(SessionHelper.getCountries());

      for (const key in locales) {
        if (translations[locales[key]]) {
          translation = translations[locales[key]];
          break;
        }
      }
    }

    this.id = arrival.id;
    this.productId = this.sku = arrival.masterProductsWarehouses.masterProduct.products[0].id;
    this.sku = arrival.masterProductsWarehouses.masterProduct.products[0].sku;
    this.label = translation ? translation.shortTitle : null;
    this.warehouse = arrival.masterProductsWarehouses.warehouse.name;
    this.season = arrival.season;
    this.date = arrival.date;
    this.quantity = arrival.quantity;
    this.shippedQuantity = arrival.shippedQuantity;
    this.receivedQuantity = arrival.receivedQuantity;
    this.sageIdentifier = arrival.sageIdentifier;
    this.comment = arrival.comment;
    this.vintageDate = `${arrival.sparePartVintageMinYear}-${arrival.sparePartVintageMaxYear}`;
    let reason = null;
    if (arrival.reason) {
      if (arrival.reason.translations[SessionHelper.getLocale()]) {
        reason = arrival.reason.translations[SessionHelper.getLocale()].reason;
      } else if (arrival.reason.translations[SessionHelper.getUILanguage()]) {
        reason = arrival.reason.translations[SessionHelper.getUILanguage()].reason;
      }
    }
    this.reason = reason;
    this.updatedBy = arrival.updatedBy ? arrival.updatedBy.username : null;
  }
}
