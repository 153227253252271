import { Component, Inject, Input, OnInit } from '@angular/core';
import { AbstractResource } from '@resources/abstract.resource';
import { AuthService } from '@services/auth.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SnackbarService } from '@components/snackbar';
import { FormNotifierService } from '@services/form-notifier.service';
import { AbstractComponent } from '@components/generic/abstract.component';
import { MasterProductResource } from '@resources';
import { IProductForm } from '@components/product/interfaces/product-form.interface';
import { CountryHelper, SessionHelper } from '@helpers';

@Component({
  selector: 'app-product-product-availability-form',
  template: require('./product-product-availability-form.component.html'),
  providers: [
    { provide: AbstractResource, useClass: MasterProductResource },
  ],
})
export class ProductProductAvailabilityFormComponent extends AbstractComponent implements OnInit {
  public availabilities: any[] = [];
  public form: FormGroup;

  @Input() model: IProductForm;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    @Inject('DialogService') private dialog: any,
    private snackbar: SnackbarService,
    private formNotifier: FormNotifierService,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    this.buildForm();
    this.fetch();
  }

  private buildForm() {
    const countries = SessionHelper.getApplicationActiveCountries().concat(SessionHelper.getApplicationActiveCountriesWithParent());
    countries.forEach((country) => {
      country = CountryHelper.decodeCountry(country);
      this.availabilities.push({code: country.code, label: country.name, isChecked: false});
    });

    const controls: any = {};
    this.availabilities.forEach((availability) => {
      controls[availability.code] = availability.isChecked;
    });

    this.form = this.formBuilder.group(controls);
  }

  private fillForm() {
    const controls: any = {};
    this.availabilities.forEach((availability) => {
      controls[availability.code] = availability.isChecked;
    });
    this.form.patchValue(controls);
  }

  private fetch(): void {
    this.resource.get(null, { entryPoint: `/v2/master_products/${this.model.masterProduct.id}`, dontUseModel: true })
      .takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        const availabilities = response['availabilities'] || null;

        if (null === availabilities) {
          this.availabilities.map((availability) => availability.isChecked = true);
        } else if (Array.isArray(availabilities)) {
          this.availabilities.map((availability) => availability.isChecked = availabilities.includes(availability.code));
        }

        this.fillForm();
      })
    ;
  }

  public hasSomeChecked(): boolean {
    return this.availabilities.some(availability => availability.isChecked);
  }

  public toggleCheck(): void {
    const checkedValue = !this.hasSomeChecked();
    const controls: any = {};
    this.availabilities.forEach((availability) => {
      availability.isChecked = checkedValue;
      controls[availability.code] = checkedValue;
    });
    this.form.patchValue(controls);
  }

  public submit(event?: any): void {
    const body: any = {'availabilities': []};

    for (const [countryCode, isChecked] of Object.entries(this.form.value)) {
      if (isChecked) {
        body['availabilities'].push(countryCode);
      }
    }

    const id: string = this.model.masterProduct.id;

    this.resource.update(id, body, { entryPoint: `/v2/master_products/${id}` })
      .takeUntil(this.destroyed$)
      .subscribe(() => {
        this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));

        if (event && event.redirect) {
          this.state.go('product_new.list');

          return;
        }

        this.formNotifier.notifyFormSubmitted();

        if (this.state.current.name === 'spare-part.edit.product') {
          this.state.go('spare-part.edit.product', { id: this.model.id });
        } else {
          this.state.go('product.edit', { id: this.model.id });
        }
      }
    );
  }
}
