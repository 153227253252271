import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { FormGroup } from '@angular/forms';
import { AbstractComponent } from '@components/generic/abstract.component';

export interface IGrid {
  productsUnlisted?: boolean;
  id: string;
  thumbnailWebPath?: string;
  thumbnailPath?: string;
  watermarkedWebPath?: string;
  baseWebPath?: string;
  originalPath?: string;
  position?: number;
  selected?: boolean;
  fileName?: string;
  name?: string;
  titleAttribute?: string;
  featured?: boolean;
  dimensionType?: boolean;
  backgroundType?: boolean;
  ambianceType?: boolean;
  altAttribute?: string;
  additionalContentForm?: FormGroup;
}

export interface Columns {
  [key: string]: number | string;
}

/**
 *
 * This component is used to show a list of something by 4 columns.
 * this list can be ordered with the dragEnabled attribute.
 * Delete action can be activated with delete attribute.
 *
 * An additional form can be attached to each file.
 * the form template can be passed via the attribute additionalContentForm.
 * It comes from parent via ng-template and injected into the view,
 * It must have an associated file to refer to it.
 * The dialog need a title with modalTitle attribute.
 *
 * @example of template form
 *
 * <ng-template #additionalContentForm let-associatedForm>
 *  <app-form [form]="associatedForm" [dontUseButtonBar]="true">
 *    <app-form-control [control]="associatedForm.get('title')"
 *                     [fieldOptions]="{
 *                             label: 'label',
 *                             controlName: 'title'
 *                           }">
 *    </app-form-control>
 **  </app-form>
 * </ng-template>
*/
@Component({
  selector: 'app-generic-grid',
  template: require('./generic-grid.component.html'),
  styles: [require('./generic-grid.component.scss')],
})
export class GenericGridComponent extends AbstractComponent implements OnChanges {

  @Input() public dragList: IGrid[];
  @Input() public columns: Columns[];
  @Input() public enableSelection: boolean = false;
  @Input() public enableDeletion: boolean = false;
  @Input() public dragEnabled: boolean;
  @Input() public additionalContentForm: TemplateRef<any>;
  @Input() public modalTitle: string = '';
  @Input() public confirmRemove = 'UPLOADED_PICTURES.CONFIRM.REMOVE';
  @Input() public confirmRemoveMultiple = 'UPLOADED_PICTURES.CONFIRM.REMOVE';
  @Input() public noData = 'DATA.UPLOADED_PICTURES.NO_DATA';

  @Output() public onUpdatePosition?: EventEmitter<IGrid> = new EventEmitter();
  @Output() public onDelete: EventEmitter<any> = new EventEmitter();

  @Input() public placeholder: boolean = false;
  public open: boolean = false;
  public fileOnEdition: IGrid;
  public pageSize: number = 48;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    @Inject('DialogService') private dialog: any,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dragList) {
      this.generateFakeData();
    }
  }

  public hasItemsToDelete(): boolean {
    return !!this.dragList.filter((item: IGrid) => item.id !== 'placeholder' && item.selected).length;
  }

  public selectAllItems(active: boolean): void {
    this.dragList.filter((item: IGrid) => item.id !== 'placeholder').map((item: any) => {
      item.selected = active;

      return item;
    });
  }

  public updatePosition(img: IGrid): void {
    this.dragList = this.dragList.filter((item) => {
      return item.id !== 'placeholder';
    }).map((item: IGrid, index: number) => {
      item.position = index;
      return item;
    });
    this.generateFakeData();
    this.onUpdatePosition.emit(img);
  }

  public editItem(item: any): void {
      item.inEdition = true;
      this.fileOnEdition = item;
      this.open = true;
  }

  public close(): void {
    this.open = false;
  }

  public deleteItem(items?: IGrid): void {
    let itemsToRemove = this.dragList.filter((item: IGrid) => item.id !== 'placeholder' && item.selected);

    if (itemsToRemove.length === 0 && items) {
      itemsToRemove = [items];
    }

    const confirmText = `
    ${itemsToRemove.length > 1 ?
      this.translate(this.confirmRemoveMultiple) :
      this.translate(this.confirmRemove)} (${itemsToRemove.length})\
    `;

    this.dialog.confirm(confirmText).then(() => {
      itemsToRemove.forEach((removeItem: IGrid) => {
        this.dragList.forEach((item: IGrid, i: number) => {
          if (removeItem.id === item.id) {
            this.dragList.splice(i, 1);
          }
        });
      });
      this.generateFakeData();

      this.onDelete.emit(itemsToRemove);
    });
  }

  preventDrag($event: DragEvent) {
    $event.preventDefault();
    $event.stopPropagation();
  }

  getNextPage(i: number) {
    return Math.floor(i / this.pageSize) + 2;
  }

  generateFakeData() {
    if (!this.placeholder) {
      return;
    }
    this.dragList = this.dragList.filter((item) => item.id !== 'placeholder');
    const length = this.dragList.length;
    for (let i = 3; i < length; i += 46) {
      this.addFakeDataToDragList(i);
    }
    for (let i = 12; i < length; i += 47) {
      this.addFakeDataToDragList(i);
    }
    for (let i = 13; i < length; i += 47) {
      this.addFakeDataToDragList(i);
    }
  }
  addFakeDataToDragList(id: number): void {
    this.dragList.splice(id, 0, {
      id: 'placeholder'
    });
  }
}
