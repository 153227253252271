export const ATTRIBUTE_UNIT = {
  'fr_FR': [
    { name: 'm³' },
    { name: 'm²' },
    { name: 'm' },
    { name: 'cm' },
    { name: 'mm' },
    { name: 'pied' },
    { name: 'g/m²' },
    { name: 'g' },
    { name: 'kg' },
    { name: 'L' },
    { name: 'L/h' },
    { name: 'h' },
    { name: 'min' },
    { name: 'an' },
    { name: 'ans' },
    { name: 'mois' },
    { name: '°C' },
    { name: 'dB' },
    { name: 'W' },
    { name: 'g/h' },
    { name: 'lm' },
    { name: 'kw' },
    { name: 'V' },
    { name: '"' },
    { name: '' },
  ],
  'fr_LU': [
    { name: 'm³' },
    { name: 'm²' },
    { name: 'm' },
    { name: 'cm' },
    { name: 'mm' },
    { name: 'pied' },
    { name: 'g/m²' },
    { name: 'g' },
    { name: 'kg' },
    { name: 'L' },
    { name: 'L/h' },
    { name: 'h' },
    { name: 'min' },
    { name: 'an' },
    { name: 'ans' },
    { name: 'mois' },
    { name: '°C' },
    { name: 'dB' },
    { name: 'W' },
    { name: 'g/h' },
    { name: 'lm' },
    { name: 'kw' },
    { name: 'V' },
    { name: '"' },
    { name: '' },
  ],
  'en_GB': [
    { name: 'm³' },
    { name: 'm²' },
    { name: 'm' },
    { name: 'cm' },
    { name: 'mm' },
    { name: 'ft' },
    { name: 'g/m²' },
    { name: 'g' },
    { name: 'kg' },
    { name: 'L' },
    { name: 'L/h' },
    { name: 'h' },
    { name: 'min' },
    { name: 'year' },
    { name: 'years' },
    { name: 'months' },
    { name: '°C' },
    { name: 'dB' },
    { name: 'W' },
    { name: 'g/h' },
    { name: 'lm' },
    { name: 'kw' },
    { name: 'V' },
    { name: '"' },
    { name: '' },
  ],
  'lb_LU': [
    { name: 'm³' },
    { name: 'm²' },
    { name: 'm' },
    { name: 'cm' },
    { name: 'mm' },
    { name: 'ft' },
    { name: 'g/m²' },
    { name: 'g' },
    { name: 'kg' },
    { name: 'L' },
    { name: 'L/h' },
    { name: 'h' },
    { name: 'min' },
    { name: 'year' },
    { name: 'years' },
    { name: 'months' },
    { name: '°C' },
    { name: 'dB' },
    { name: 'W' },
    { name: 'g/h' },
    { name: 'lm' },
    { name: 'kw' },
    { name: 'V' },
    { name: '"' },
    { name: '' },
  ],
  'es_ES': [
    { name: 'm³' },
    { name: 'm²' },
    { name: 'm' },
    { name: 'cm' },
    { name: 'mm' },
    { name: 'pie' },
    { name: 'g/m²' },
    { name: 'g' },
    { name: 'kg' },
    { name: 'L' },
    { name: 'L/h' },
    { name: 'h' },
    { name: 'min' },
    { name: 'año' },
    { name: 'años' },
    { name: 'meses' },
    { name: '°C' },
    { name: 'dB' },
    { name: 'W' },
    { name: 'g/h' },
    { name: 'lm' },
    { name: 'kw' },
    { name: 'V' },
    { name: '"' },
    { name: '' },
  ],
  'fr_BE': [
    { name: 'm³' },
    { name: 'm²' },
    { name: 'm' },
    { name: 'cm' },
    { name: 'mm' },
    { name: 'pied' },
    { name: 'g/m²' },
    { name: 'g' },
    { name: 'kg' },
    { name: 'L' },
    { name: 'L/h' },
    { name: 'h' },
    { name: 'min' },
    { name: 'an' },
    { name: 'ans' },
    { name: 'mois' },
    { name: '°C' },
    { name: 'dB' },
    { name: 'W' },
    { name: 'g/h' },
    { name: 'lm' },
    { name: 'kw' },
    { name: 'V' },
    { name: '"' },
    { name: '' },
  ],
  'nl_BE': [
    { name: 'm³' },
    { name: 'm²' },
    { name: 'm' },
    { name: 'cm' },
    { name: 'mm' },
    { name: 'voet' },
    { name: 'g/m²' },
    { name: 'g' },
    { name: 'kg' },
    { name: 'L' },
    { name: 'L/h' },
    { name: 'h' },
    { name: 'min' },
    { name: 'jaar' },
    { name: 'jaren' },
    { name: 'maanden' },
    { name: '°C' },
    { name: 'dB' },
    { name: 'W' },
    { name: 'g/h' },
    { name: 'lm' },
    { name: 'kw' },
    { name: 'V' },
    { name: '"' },
    { name: '' },
  ],
  'nl_NL': [
    { name: 'm³' },
    { name: 'm²' },
    { name: 'm' },
    { name: 'cm' },
    { name: 'mm' },
    { name: 'voet' },
    { name: 'g/m²' },
    { name: 'g' },
    { name: 'kg' },
    { name: 'L' },
    { name: 'L/h' },
    { name: 'h' },
    { name: 'min' },
    { name: 'jaar' },
    { name: 'jaren' },
    { name: 'maanden' },
    { name: '°C' },
    { name: 'dB' },
    { name: 'W' },
    { name: 'g/h' },
    { name: 'lm' },
    { name: 'kw' },
    { name: 'V' },
    { name: '"' },
    { name: '' },
  ],
  'pt_PT': [
    { name: 'm³' },
    { name: 'm²' },
    { name: 'm' },
    { name: 'cm' },
    { name: 'mm' },
    { name: 'pie' },
    { name: 'g/m²' },
    { name: 'g' },
    { name: 'kg' },
    { name: 'L' },
    { name: 'L/h' },
    { name: 'h' },
    { name: 'min' },
    { name: 'ano' },
    { name: 'anos' },
    { name: 'meses' },
    { name: '°C' },
    { name: 'dB' },
    { name: 'W' },
    { name: 'g/h' },
    { name: 'lm' },
    { name: 'kw' },
    { name: 'V' },
    { name: '' },
  ],
  'it_IT': [
    { name: 'm³' },
    { name: 'm²' },
    { name: 'm' },
    { name: 'cm' },
    { name: 'mm' },
    { name: 'pie' },
    { name: 'g/m²' },
    { name: 'g' },
    { name: 'kg' },
    { name: 'L' },
    { name: 'L/h' },
    { name: 'h' },
    { name: 'min' },
    { name: 'anno' },
    { name: 'anni' },
    { name: 'mesi' },
    { name: 'mese' },
    { name: '°C' },
    { name: 'dB' },
    { name: 'W' },
    { name: 'g/h' },
    { name: 'lm' },
    { name: 'kw' },
    { name: 'V' },
    { name: '' },
  ],
  'de_DE': [
    { name: 'm3' },
    { name: 'm²' },
    { name: 'm' },
    { name: 'cm'},
    { name: 'mm' },
    { name: 'Fuß' },
    { name: 'g/m²' },
    { name: 'g' },
    { name: 'kg' },
    { name: 'L' },
    { name: 'L/h' },
    { name: 'h' },
    { name: 'min' },
    { name: 'Jahr' },
    { name: 'Jahre' },
    { name: 'Monate' },
    { name: '°C' },
    { name: 'dB' },
    { name: 'W' },
    { name: 'lm' },
    { name: 'kw' },
    { name: 'V' },
    { name: '"' },
    { name: '' },
  ],
  'pl_PL': [
    { name: 'm³' },
    { name: 'm²' },
    { name: 'm' },
    { name: 'cm' },
    { name: 'mm' },
    { name: 'stopa' },
    { name: 'g/m²' },
    { name: 'g' },
    { name: 'kg' },
    { name: 'L' },
    { name: 'L/h' },
    { name: 'h' },
    { name: 'min' },
    { name: 'rok' },
    { name: 'lata' },
    { name: 'lat' },
    { name: 'miesiące' },
    { name: 'miesięcy' },
    { name: '°C' },
    { name: 'dB' },
    { name: 'W' },
    { name: 'g/h' },
    { name: 'lm' },
    { name: 'kw' },
    { name: 'V' },
    { name: '"' },
    { name: '' },
  ]
};
