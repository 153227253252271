import { IService } from '@components/service/model/service.interface';
import { IServiceTranslation } from '@components/service/model/service-translation.interface';
import { IProductMarketplace } from '@components';
import { IMarketplace } from '@interfaces';
import { ICMS } from '@components/cms/models/cms.interface';

export class ServiceModel implements IService {
  id?: string;
  type: string;
  sku: string;
  amount: number;
  cost: number;
  productMinPrice?: number;
  productMaxPrice?: number;
  marketplace: IMarketplace;
  contentBlock: ICMS;
  productMarketplacesExcluded: IProductMarketplace[];
  productMarketplacesIncluded: IProductMarketplace[];
  translations: {
    [locale: string]: IServiceTranslation;
  };

  constructor(model?: IService) {
    if (!model) {
      return;
    }

    this.id = model.id;
    this.type = model.type;
    this.sku = model.sku;
    this.amount = model.amount;
    this.cost = model.cost;
    this.productMinPrice = model.productMinPrice;
    this.productMaxPrice = model.productMaxPrice;
    this.marketplace = model.marketplace;
    this.contentBlock = model.contentBlock;
    this.productMarketplacesExcluded = model.productMarketplacesExcluded;
    this.productMarketplacesIncluded = model.productMarketplacesIncluded;
    this.translations = model.translations;
  }
}
