import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2
} from '@angular/core';

const DND_IMG = '../../../images/drag_and_drop.png';

@Directive({
  selector: '[dragAndDropDirective]'
})
export class DndDirective implements OnInit {

  @Input() transferredData: any;
  @Input() effectAllowed: string = 'move';
  @Output() onDragStart: EventEmitter<any> = new EventEmitter();
  @Output() onDrop: EventEmitter<any> = new EventEmitter();

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.renderer.setAttribute(this.el.nativeElement, 'draggable', 'true');
  }

  @HostListener('dragover', ['$event']) onDragOverListener(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.renderer.setStyle(this.el.nativeElement, 'backgroundColor', '#e6e6e6');
    this.renderer.setStyle(this.el.nativeElement, 'opacity', '0.5');
  }

  @HostListener('dragleave', ['$event']) public onDragLeaveListener(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.removeStyle();
  }

  @HostListener('dragend', ['$event']) public onDragEndListener(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.removeStyle();
  }

  @HostListener('drop', ['$event']) public onDropListener(event: any) {
    const droppedData = JSON.parse(event.dataTransfer.getData('data'));
    this.removeStyle();
    this.onDrop.emit(droppedData);
  }

  @HostListener('dragstart', ['$event']) public onDragStartListener(event: any) {
    this.effectAllowed ? event.dataTransfer.effectAllowed = this.effectAllowed : event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.setData('data', JSON.stringify(this.transferredData));
    const img = new Image();
    img.src = DND_IMG;
    event.dataTransfer.setDragImage(img, 10, 10);
    this.onDragStart.emit(event);
  }

  private removeStyle() {
    this.renderer.removeStyle(this.el.nativeElement, 'backgroundColor');
    this.renderer.removeStyle(this.el.nativeElement, 'opacity');
  }
}
