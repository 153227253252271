import {Component, Inject, ViewEncapsulation} from '@angular/core';
import { AbstractResource } from '@resources';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { AuthService } from '@services';
import { ProformaInvoiceResource } from '@components/proforma-invoice/resources/proforma-invoice.resource';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { ProformaInvoiceListFiltersService } from '@components/proforma-invoice/list/proforma-invoice-list-filters.service';

@Component({
  selector: 'proforma-invoice-list',
  template: require('./proforma-invoice-list.component.html'),
  encapsulation: ViewEncapsulation.None,
  styles: [require('./proforma-invoice-list.component.scss')],
  providers: [
    { provide: AbstractResource, useClass: ProformaInvoiceResource },
    { provide: AbstractFiltersFieldsService, useClass: ProformaInvoiceListFiltersService },
  ],
})
export class ProformaInvoiceListComponent extends AbstractPageComponent {
  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService
  ) {
    super($translate, authService, resource, state);
  }

  protected handleCustomActions($event: any): void {
    (this as any)[$event.function]($event.params);
  }

  public exportProformaInvoice(row: any): void {
    this.resource.downloadDocument(row.id).takeUntil(this.destroyed$)
      .subscribe(
        (response: Blob | null) => {
          const fileURL = URL.createObjectURL(response);
          window.open(fileURL);
        }
      )
    ;
  }
}
