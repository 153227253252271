import { Injectable } from '@angular/core';
import { IAttributeValue } from '@components/attribute/models/attribute.interface';
import { IAttributeFamily } from '@components/attribute-family/models/attribute-family.interface';

@Injectable()
export class AttributeValueHelper {
  /**
   * Clean attribute value.
   *
   * @param {IAttributeValue} attributeValue
   * @param {number}          attributeId
   *
   * @returns {IAttributeValue}
   */
  public cleanObject(attributeValue: IAttributeValue): IAttributeValue {
    delete attributeValue.id;
    delete attributeValue.attribute;

    return attributeValue;
  }

  /**
   * Updates toTranslate bool on updated translation.
   *
   * @param {IAttributeValue|IAttributeFamily} attributeValueFamily
   * @param {IAttributeValue|IAttributeFamily} originalAttributeValueFamily
   *
   * @returns {IAttributeValue|IAttributeFamily}
   */
  public updateToTranslate(
    attributeValueFamily: IAttributeValue|IAttributeFamily,
    originalAttributeValueFamily: IAttributeValue|IAttributeFamily
  ): IAttributeValue|IAttributeFamily {
    for (const locale in attributeValueFamily.translations) {
      if (attributeValueFamily.translations.hasOwnProperty(locale)) {
        const attributeValueTranslation = attributeValueFamily.translations[locale];
        const originalValueTranslation = originalAttributeValueFamily.translations[locale];

        if (originalValueTranslation !== undefined && attributeValueTranslation.value !== originalValueTranslation.value) {
            attributeValueTranslation.toTranslate = false;
        }

        if (!attributeValueTranslation.value) {
            attributeValueTranslation.toTranslate = true;
        }
      }
    }

    return attributeValueFamily;
  }

  /**
   * Fill missing translations
   *
   * @param {IAttributeValue} attributeValueFamily
   * @param {string}          fromLocale
   *
   * @returns {IAttributeValue|IAttributeFamily}
   */
  public copyTranslationsFromLocale(
    attributeValueFamily: IAttributeValue|IAttributeFamily,
    fromLocale: string
  ): IAttributeValue|IAttributeFamily {
    const fromTranslation = attributeValueFamily.translations[fromLocale];
    const fromValue = fromTranslation.value;
    const fromUnit = fromTranslation.unit;

    for (const locale in attributeValueFamily.translations) {
      if (attributeValueFamily.translations.hasOwnProperty(locale)) {
        const toTranslation = attributeValueFamily.translations[locale];

        if ('' === toTranslation.value) {
          toTranslation.value = fromValue;
          toTranslation.unit = fromUnit;
          toTranslation.toTranslate = true;
        }
      }
    }

    return attributeValueFamily;
  }
}
