import { Resource } from '@decorators/Resource';
import { Injectable } from '@angular/core';
import { AbstractResource } from '@resources/abstract.resource';
import { HttpClient } from '@angular/common/http';
import {AddressingModel} from '@models/addressing.model';

@Injectable()
@Resource({
  entryPoint: '/v2/addressings',
  translationKey: 'ADDRESSING',
  model: AddressingModel,
  isHydra: true,
})
export class AddressingResource extends AbstractResource {
  protected nullableProperties: string[] = ['addressingBay', 'addressingLane', 'addressingLevel', 'addressingLocation'];

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  public putAddressing(addressingId: string, body?: object, options: any = {}) {
    options.entryPoint = `/v2/addressings/${addressingId}`;
    return this.update(addressingId, body, options);
  }

  public deleteAddressing(addressingId: string, options: any = {}) {
    return this.remove(null, {
      ...options,
      entryPoint: `${this.entryPoint}/${addressingId}`,
    });
  }
  public postAddressing(body?: object, options: any = {}) {
    options.entryPoint = `${this.entryPoint}`;
    return this.create(body, options);
  }
}
