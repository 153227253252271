import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@services';
import { AbstractResource, CarrierGroupResource } from '@resources';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { Observable } from 'rxjs/Observable';
import { BASE_URL_API } from '@constants';
import { SessionHelper } from '@helpers';

@Component({
  selector: 'app-carrier-blacklist',
  template: require('./carrier-blacklist.component.html'),
  styles: [require('./carrier-blacklist.component.scss')],
  providers: [
    { provide: AbstractResource, useClass: CarrierGroupResource },
  ]
})
export class CarrierBlacklistComponent extends AbstractPageComponent implements OnInit {

  /**
  * Needed by kendo-upload to set the FormData property name.
 */
  public readonly uploadFieldName = 'file';
  public export$: Observable<object>;
  public importEntryPoint: string;
  public importAllowedExtensions: string[] = ['.csv'];
  public carrierId: string;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    public carrierGroupResource: CarrierGroupResource,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    this.carrierId = this.state.params.id;
    this.export$ = this.carrierGroupResource.exportBlacklist(this.carrierId, 'carrier_exclude_product');
    this.importEntryPoint = `${BASE_URL_API}/v2/import`;
  }

  /**
 * Prepare query before sending import.
 */
  public prepareQuery(): any {
    return {
      countryCode: SessionHelper.getCountry().code,
      locale: SessionHelper.getLocale(),
      businessObject: 'carrier_exclude_product',
      businessObjectId: this.carrierId
    };
  }
}
