import { CustomerDetailOrdersResource } from '../customer/customer-detail-orders.resource';

export default customerRoutes;

/** @ngInject */
function customerRoutes($stateProvider: any) {
  const commonStateData: {[key: string]: any} = {
    sidebarNav: 'customers',
    title: '',
    reloadChangeCountry: 'customers.list'
  };

  $stateProvider
    .state('customers', {
      parent: 'walisoft',
      url: '/customers',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.CUSTOMER',
        stateName: 'customers.list',
      }
    })
    .state('customers.list', {
      url: `?
        page&
        byPage&
        fullName&
        username&
        phones&
        id&
        orderNumber&
        postcodes&
        countries[]&
        marketplaces[]&
        sort_numberOfOrder&
        sort_totalPrice&
        collaborators
      `,
      views: {
        'page@layout': {
          template: '<app-customer-list></app-customer-list>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.CUSTOMER.LIST.TITLE',
      })
    })
    .state('customers.detail', {
      url: '/:id',
      breadcrumb: {
        label: 'BREADCRUMB.CUSTOMER_CARD'
      },
      views: {
        'page@layout': {
          template: '<app-customer-detail></app-customer-detail>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.CUSTOMER_CARD.DETAILS.TITLE',
      })
    })
    .state('customers.detail.orders', {
      url: '/orders',
      views: {
        'additional-page-content@layout': {
          template: '<app-customer-detail-orders></app-customer-detail-orders>'
        }
      },
      data: Object.assign(commonStateData, {
        resource: CustomerDetailOrdersResource
      })
    })
    .state('customers.detail.addresses', {
      url: '/addresses',
      views: {
        'additional-page-content@layout': {
          template: '<app-customer-detail-addresses></app-customer-detail-addresses>'
        }
      },
    })
    .state('customers.detail.mails', {
      url: '/mails',
      views: {
        'additional-page-content@layout': {
          template: '<app-customer-detail-mails></app-customer-detail-mails>'
        }
      },
    })
    .state('customers.detail.discounts', {
      url: '/discounts',
      views: {
        'additional-page-content@layout': {
          template: '<app-customer-detail-discount></app-customer-detail-discount>'
        }
      },
    })
    .state('customers.detail.walitickets', {
      url: '/walitickets',
      views: {
        'additional-page-content@layout': {
          template: '<app-customer-detail-walitickets></app-customer-detail-walitickets>'
        }
      },
      data: Object.assign(commonStateData, {
        resource: CustomerDetailOrdersResource
      })
    })
  ;
}
