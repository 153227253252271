import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { AuthService } from '../../../../services/auth.service';
import { AbstractResource } from '../../../../resources/abstract.resource';
import { ProductResource } from '../../../../components/product/product.resource';
import {
  IAttributeValue,
  IContentFormBody,
  IContentFormValue,
  IProductForm,
} from '@components/product/interfaces/product-form.interface';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SuperProductResource } from '../../../../components/super-product/super-product.resource';
import { WarrantyResource } from '../../../../components/warranty/resources/warranty.resource';
import { SessionHelper } from '../../../../helpers/session.helper';
import { ManufacturerResource } from '../../../../resources/manufacturer.resource';
import { SnackbarService } from '../../../../components/snackbar/snackbar.service';
import { FormNotifierService } from '@services/form-notifier.service';
import { ICountry } from '@interfaces/ICountry';
import { MarketplaceHelper } from '@helpers/MarketplaceHelper';
import { StringHelper } from '@helpers/StringHelper';

/**
 * Handle ProductContentForm.
 */
@Component({
  selector: 'app-product-content-form',
  template: require('./product-content-form.component.html'),
  providers: [
    { provide: AbstractResource, useClass: ProductResource },
  ],
})
export class ProductContentFormComponent extends AbstractPageComponent implements OnInit {

  public currentCountry: ICountry;
  public form: FormGroup;
  public attributeValues: IAttributeValue[] = [];
  public packagesInfo: string = '';
  public isFrance: boolean;

  @Input() model: IProductForm;

  get translationsFA(): FormArray {
    return this.form.get('translations') as FormArray;
  }

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    public resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    private superProductResource: SuperProductResource,
    private warrantyResource: WarrantyResource,
    private manufacturerResource: ManufacturerResource,
    private snackbar: SnackbarService,
    private formNotifier: FormNotifierService,
    @Inject('DialogService') private dialog: any,
  ) {
    super($translate, authService, resource, state);

    this.currentCountry = SessionHelper.getCountry();
  }

  /**
   * @inheritDoc
   */
  ngOnInit(): void {
    if (MarketplaceHelper.getWebsiteMarketplace()) {
      this.attributeValues = this.model.masterProduct.attributeValues;
    }

    this.packagesInfo = this.model.packagesInfo ? StringHelper.nl2br(this.model.packagesInfo.map((value, index) => {
      return `<b>${this.translate('PAGE.PRODUCT.EDIT.TAB.CONTENTS.PACKAGE')} ${index + 1}/${this.model.packagesInfo.length}: </b>${value}`;
    })
      .join('')) : ''
    ;

    this.buildForm();
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      translations: this.formBuilder.array([])
    });

    this.buildTranslationsForm();
  }

  private buildTranslationsForm(): void {
    // build several form group for each country locales
    const formGroups = this.currentCountry.locales.map((locale: string) => {
      const groups = this.formBuilder.group({
        longTitle: [this.hasExistingTranslations(locale) ? this.model.translations[locale].longTitle : '', Validators.maxLength(200)],
        shortTitle: [this.hasExistingTranslations(locale) ? this.model.translations[locale].shortTitle : '', Validators.maxLength(130)],
        titleGoogleShopping: [
          this.hasExistingTranslations(locale) ? this.model.translations[locale].titleGoogleShopping : '', Validators.maxLength(180)
        ],
        metaDescriptionSeo: [
          this.hasExistingTranslations(locale) ? this.model.translations[locale].metaDescriptionSeo : '', Validators.maxLength(1000)]
        ,
        descriptionGoogleShopping: [
          this.hasExistingTranslations(locale) ? this.model.translations[locale].descriptionGoogleShopping : '', Validators.maxLength(5000)
        ],
        descriptionMarketplace: [
          this.hasExistingTranslations(locale) ? this.model.translations[locale].descriptionMarketplace : '',
        ],
        complementaryDescriptionForMarketplace: [
          this.hasExistingTranslations(locale) ? this.model.translations[locale].complementaryDescriptionForMarketplace : '',
        ],
        detailedDescription: [
          this.hasExistingTranslations(locale) ? this.model.translations[locale].detailedDescription : '',
        ],
        detailedDescriptionOverrideContent: [
          this.hasExistingTranslations(locale) ? this.model.translations[locale].detailedDescriptionOverrideContent : false,
        ],
        slug: [
          this.hasExistingTranslations(locale) ? this.model.translations[locale].slug : '',
        ],
        autoGeneratedShortTitle: [ this.hasExistingTranslations(locale) ? this.model.translations[locale].autoGeneratedShortTitle : false ],
        autoGeneratedLongTitle: [this.hasExistingTranslations(locale) ? this.model.translations[locale].autoGeneratedLongTitle : false ],
        autoGeneratedMetaDescription: [this.hasExistingTranslations(locale) ? this.model.translations[locale].autoGeneratedMetaDescription : false],
        locale,
      });

      for (let i: number = 1; i < 5; i++) {
          groups.addControl(
              'positivePoint' + i,
              this.formBuilder.control(this.hasExistingTranslations(locale) ? this.model.translations[locale]['positivePoint' + i] : ''));
          groups.addControl(
            'positivePoint' + i + 'OverrideContent',
            this.formBuilder.control(this.hasExistingTranslations(locale) ?
              this.model.translations[locale]['positivePoint' + i + 'OverrideContent']
              : false
            )
          );
      }

      for (let i: number = 1; i < 6; i++) {
        groups.addControl(
          'bulletPoint' + i,
          this.formBuilder.control(this.hasExistingTranslations(locale) ? this.model.translations[locale]['bulletPoint' + i] : ''));

        groups.addControl(
          'searchTerm' + i,
          this.formBuilder.control(this.hasExistingTranslations(locale) ? this.model.translations[locale]['searchTerm' + i] : ''));

        groups.addControl(
          'bulletPoint' + i + 'OverrideContent',
          this.formBuilder.control(this.hasExistingTranslations(locale) ?
            this.model.translations[locale]['bulletPoint' + i + 'OverrideContent']
            : false
          )
        );

        groups.addControl(
          'searchTerm' + i + 'OverrideContent',
          this.formBuilder.control(this.hasExistingTranslations(locale) ?
            this.model.translations[locale]['searchTerm' + i + 'OverrideContent']
            : false
          )
        );
      }

      return groups;
    });

    // pass the form groups to a form array
    const formArray = this.formBuilder.array(formGroups);

    // replace existing control with a new one, we pass it the form array that is a form group collection
    this.form.setControl('translations', formArray);
  }

  /**
   * Checks if the model translations exists and are sets for a given locale.
   */
  private hasExistingTranslations(locale: string): boolean {
    return this.model.translations && !!this.model.translations[locale];
  }

  /**
   * Checks if the current country has several translations.
   */
  public hasSeveralTranslations(): boolean {
    return this.currentCountry.locales.length > 1;
  }

  public submit(event?: any): void {
    this.dialog.confirm(this.translate('PAGE.PRODUCT.CONFIRM.UPDATE'))
      .then(() => {
        const body: IContentFormBody = this.prepareBody();
        const id: string = this.state.params.id;

        this.resource.update(id, body, { entryPoint: `/v2/products/${id}` })
          .takeUntil(this.destroyed$)
          .subscribe((response: IProductForm) => {
            this.model.translations = response.translations;
            this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));

            if (event && event.redirect) {

              if (this.state.current.name === 'spare-part.edit.content') {
                this.state.go('spare-part.list');
              } else {
                this.state.go(`${this.resource.routeName}.list`);
              }

              return;
            }

            this.formNotifier.notifyFormSubmitted();

            if (this.state.current.name === 'spare-part.edit.content') {
              this.state.go('spare-part.edit.content',  { id });
            } else {
              this.state.go(`${this.resource.routeName}.edit.content`, { id });
            }
          })
        ;
      })
    ;
  }

  private prepareBody(): IContentFormBody {
    const translations: { [locale: string]: IContentFormValue } = {};

    this.translationsFA.controls.forEach((formGroup: FormGroup) => {
      const translationsFormValue: IContentFormValue = formGroup.value;

      if (this.model.translations[translationsFormValue.locale]) {
        translations[translationsFormValue.locale] = {
          id: `/api/v2/product_translations/${this.model.translations[translationsFormValue.locale].id}`,
          ...translationsFormValue
        };
      } else {
        translations[translationsFormValue.locale] = translationsFormValue;
      }
    });

    return { translations };
  }
}
