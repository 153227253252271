import { Component, Inject, OnInit } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AbstractResource } from '@resources/abstract.resource';
import { AuthService } from '@services/auth.service';
import { BASE_URL_INVOICE_ORDER } from '@constants/config.constants';
import { OrderResource } from '@resources';
import { IHydraMember } from '@interfaces/hydra-resource.interface';
import { takeUntil } from 'rxjs/operators';
import { CommentDialogComponent } from '../components/comment-dialog.component';
import { DialogService } from '@progress/kendo-angular-dialog';
import { DelayReasonResource } from '@components/order-manager/delay-reason/delay-reason.resource';
import { HydraHelper } from '@helpers/HydraHelper';
import { SnackbarService } from '@components/snackbar';
import { ExportResource } from '@resources/export.resource';
import { CsvDataService } from '@services';

@Component({
    selector: 'app-delay-reason-order-result-list',
    template: require('./delay-reason-order-result-list.component.html'),
    styles: [require('./delay-reason-order-result-list.component.scss')],
})
export class DelayReasonOrderResultListComponent extends AbstractComponent implements OnInit {

    public BASE_URL_INVOICE_ORDER = BASE_URL_INVOICE_ORDER;

    public comments: any;
    public orders: any[];
    public openConfirmBulk: boolean = false;
    public delayReasons: any[] = [];
    public delayReason: string;
    public delayComment: string;
    public selectAll: any = {};
    public selectedOrders: any = {};
    public selectedOrderIris: string[];
    public selectedOrderIds: string[];

    private _items: any[];

    set items(items: any[]) {
        this._items = items;
    }

    get items(): any[] {
        return this._items;
    }

    constructor(
        @Inject('TranslationService') $translate: ng.translate.ITranslateService,
        authService: AuthService,
        resource: AbstractResource,
        private orderResource: OrderResource,
        private dialogService: DialogService,
        @Inject('StateService') state: ng.ui.IStateService,
        @Inject('DialogService') private dialog: any,
        private delayReasonResource: DelayReasonResource,
        private exportResource: ExportResource,
        private snackbar: SnackbarService,
        private csvDataService: CsvDataService,
    ) {
        super($translate, authService, resource, state);
    }

    ngOnInit() {
      this.delayReasonResource.cGet({pagination: false}, {
          isHydra: true,
          returnHydraMembers: true,
          dontUseModel: true,
          blocking: false
        }).subscribe((response: any) => {
          this.delayReasons = response.map((item: any) => {
            return {
              id: item['@id'],
              label: item.translations[this.currentLocale] ? item.translations[this.currentLocale].label : item['@id'],
            };
          });
      });
    }

    getId(item: any) {
        return item['@id'].split('/').pop();
    }

    goToEdit(orderId: any, e?: MouseEvent) {
        if (e && true === e.ctrlKey) {
            window.open(
                this.state.href(
                    'order_manager.edit',
                    { id: orderId },
                    { absolute: true }
                ),
                '_blank'
            );
            return;
        }

        this.state.go('order_manager.edit', { id: orderId });
    }

    isOrderTypeSav(order: any): boolean {
        return order.detached;
    }

    openDialogWhitComments(orderId: any) {
        (<OrderResource>this.orderResource).getComments(orderId)
            .pipe(takeUntil(this.destroyed$))
            .subscribe((response: IHydraMember[]) => {
                this.comments = response;
                this.openCommentDialog();
            });
    }

    public openCommentDialog() {
        const dialogRef = this.dialogService.open({
            content: CommentDialogComponent
        });

        const commentComponentInstance = dialogRef.content.instance;
        commentComponentInstance.comments = this.comments;

        dialogRef.result.subscribe((r: any) => {
            if (r.type === 'submit') {
            }
        });
    }

    public goToProductEdit(event: MouseEvent, productUri: string, order: any) {
        const id = productUri.split('/').pop();
        let state = 'product.edit';

        if (order.detached) {
            state = 'spare-part.edit';
        }

        if (event.ctrlKey) {
            window.open(this.state.href(state, { id }, { absolute: true }), '_blank');
            return;
        }
        this.state.go(state, { id });
    }

    public isOrderTypeUnpaired(order: any): boolean {
        return order.unpaired;
    }

    public toggleSelectAll() {
      this.selectAll[this.state.params.page || 1] = !this.selectAll[this.state.params.page || 1];
      this.items.forEach(order => {
        if (!this.selectAll[this.state.params.page || 1]) {
          delete this.selectedOrders[order.id];
        } else {
          this.selectedOrders[order.id] = order;
        }
      });

      this.setSelectedOrderIris();
      this.setSelectedOrderIds();
    }

    public setSelectedOrderIris(): void {
      const orders: string[] = [];

      for (const orderId of Object.keys(this.selectedOrders)) {
        orders.push(HydraHelper.buildIri(orderId, 'orders'));
      }

      this.selectedOrderIris = orders;
    }

  public setSelectedOrderIds(): void {
    const orders: string[] = [];

    for (const orderId of Object.keys(this.selectedOrders)) {
      orders.push(orderId);
    }

    this.selectedOrderIds = orders;
  }

    public bulk(delayReasonIri?: string): void {
      this.openConfirmBulk = true;
      this.delayReason = delayReasonIri;
    }

    public confirmBulk(): void {
      this.delayReasonResource.bulk(this.selectedOrderIris, this.delayReason, this.delayComment)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response: any) => {
          this.snackbar.validate(this.translate('ALERTS.DATA.UPDATE'));
          this.state.go(this.state.current, this.state.params, { reload: true });
          this.resetSelection();
          this.setSelectedOrderIris();
          this.setSelectedOrderIds();
        })
      ;
    }

    public export(): void {
      this.exportResource.exportSelection('order', this.selectedOrderIds, 'delayed_order_export')
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response: any) => {
          this.csvDataService.exportToCsv('delayed-orders.csv', response);
          this.snackbar.validate(this.translate('ALERTS.DATA.UPDATE'));
          this.resetSelection();
          this.setSelectedOrderIris();
          this.setSelectedOrderIds();
        })
      ;
    }

    public handleSelection(order: any): void {
      if (this.selectedOrders[order.id]) {
        delete this.selectedOrders[order.id];
      } else {
        this.selectedOrders[order.id] = order;
      }

      this.setSelectedOrderIris();
      this.setSelectedOrderIds();
    }

    public resetSelection(): void {
      this.selectedOrders = {};
    }
}
