import { AbstractFormFieldBase } from './abstract-form-field-base.class';

/**
 * Used for select components.
 * (Actually using kendo-angular-dropdowns)
 */
export abstract class AbstractSearchFieldClass extends AbstractFormFieldBase<any> {
  /**
   * Data necessary for the field.
   */
  public data: any[];

  /**
   * Used to display text in the multi select.
   */
  public textField: string;

  /**
   * Used as field value.
   */
  public valueField: string;

  /**
   * Allows search field to have a clear button or not.
   */
  public clearButton: boolean;

  public valuePrimitive: boolean;

  protected constructor(options: any) {
    super(options);

    this.data = options.data || [];
    this.textField = options.textField || '';
    this.valueField = options.valueField || '';
    this.clearButton = undefined !== options.clearButton ? options.clearButton : true;
    this.valuePrimitive = options.valuePrimitive || false;
  }
}
