import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { CategoryResource } from '@resources/category.resource';
import { CategoriesFormComponent } from '@components/categories/forms/categories-form/categories-form.component';
import { IFormViolation } from '@interfaces/IFormViolation';
import { SnackbarService } from '@components/snackbar';
import { DATE_FULL_FORMAT, DATE_SHORT_FORMAT } from '@constants/date';
import * as moment from 'moment';
import { CategoriesModel, ICategories, ICategoriesTranslation } from '@components/categories/models';
import { MarketplaceHelper } from '@helpers/MarketplaceHelper';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { ICategory } from '@interfaces';
import { WebsiteCategoryResource } from '@components/website-category';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-website-category-form',
  template: require('./website-category-form.component.html'),
})

export class WebsiteCategoryFormComponent extends AbstractPageComponent implements OnInit {
  @ViewChild(CategoriesFormComponent) categoriesFormComponent: CategoriesFormComponent;

  public inCreation: boolean;
  public model: ICategories;
  public violations: IFormViolation[]|any = [];
  public parentCategories: any = [];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: WebsiteCategoryResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private categoryResource: CategoryResource,
    @Inject('DialogService') private dialog: any,
    private snackbar: SnackbarService,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    this.inCreation  = !this.state.params.id;

    this.categoryResource.getAllNames({level: 1, locale: this.currentLocale}, { dontUseModel: true })
      .subscribe((response: ICategory[]) => {

        this.parentCategories = response;
      })
    ;

    if (!this.inCreation) {
      this.resource.get(this.state.params.id)
        .takeUntil(this.destroyed$)
        .subscribe((response: ICategories) => {
            this.model = response;
        })
      ;

      return;
    }

    this.model = new CategoriesModel();
  }

  public delete(id: string) {
    this.dialog.confirm(this.translate('PAGE.CATEGORIES.CONFIRM.DELETE'))
      .then(() => {
        this.resource.remove(id)
          .takeUntil(this.destroyed$)
          .subscribe(() => this.actions.list.go({}, {reload: true}));
      });
  }

  public cancel() {
    this.dialog.confirm(this.translate('PAGE.CATEGORIES.CONFIRM.BACK_TO_LIST'))
      .then(() => this.state.go(`${this.resource.routeName}.list`));
  }

  public submit(returnToList: boolean) {
    while (this.violations.length) {
      this.violations.pop();
    }

    this.save(this.prepareCategoriesBody(this.categoriesFormComponent.form), returnToList);
  }

  public save(categoriesBody: ICategories, returnToList: boolean) {
    const observable$ = this.inCreation ?
      this.resource.create(categoriesBody) :
      this.resource.update(this.state.params.id, categoriesBody, {cleanParams: 'false'})
    ;

    observable$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: ICategory) => {
        returnToList
          ? this.state.go(`${this.resource.routeName}.list`)
          : this.state.go(`${this.resource.routeName}.edit`, { id: response.id }, { reload: true })
        ;
      });
  }

  public prepareCategoriesBody(categoriesForm: any) {
    let categoryParentId = null;

    if (categoriesForm.value.parent) {
      categoryParentId = categoriesForm.value.parent.id
        ? categoriesForm.value.parent.id
        : categoriesForm.value.parent
      ;

      categoryParentId = `/api/v2/categories/${categoryParentId}`;
    }

    return {
      name: categoriesForm.value.name,
      active: categoriesForm.value.active,
      parent: categoryParentId,
      translations: this.getPreparedTranslationsCategories(categoriesForm.value.translations),
      hreflangs: categoriesForm.value.hreflangs,
      marketplace: MarketplaceHelper.getAlicesGardenMarketplace().code,
      defaultSorting: categoriesForm.value.defaultSorting,
      seoMetaRobot: this.model.seoMetaRobot ? this.model.seoMetaRobot : 'index, follow',
      startDate: categoriesForm.value.startDate ? moment(categoriesForm.value.startDate, DATE_SHORT_FORMAT).startOf('day').format(DATE_FULL_FORMAT) : null,
      endDate: categoriesForm.value.endDate ? moment(categoriesForm.value.endDate, DATE_SHORT_FORMAT).endOf('day').format(DATE_FULL_FORMAT) : null,
      position: categoriesForm.value.position,
    };
  }

  private getPreparedTranslationsCategories(translations: ICategoriesTranslation): ICategoriesTranslation[] {
    return translations.reduce(
      (
        current: ICategoriesTranslation,
        {name, lengowName, slug, locale, id, shortDescription, longDescription, contentBlock, contentBlockTitle}: ICategoriesTranslation
      ) => {
        current[locale] = {
          name,
          lengowName,
          slug,
          locale,
          id,
          shortDescription,
          longDescription,
          contentBlock,
          contentBlockTitle,
        };
        return current;
      },
      {}
    );
  }
}
