import { Component, Inject, Input } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource, BlockResource } from '@resources';
import { StringHelper } from '@helpers';
import { SnackbarService } from '@components/snackbar/snackbar.service';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { takeUntil } from 'rxjs/operators';
import { AbstractBlockOverrideFormComponent } from '@components/generic/blocks/abstract-block-override-form.component';

@Component({
  selector: 'app-block-group-form',
  template: require('./block-group-form.component.html'),
  styles: [require('./block.scss')],
  providers: [{ provide: AbstractResource, useClass: BlockResource }]
})
export class BlockGroupFormComponent extends AbstractBlockOverrideFormComponent {
  @Input() public data: { businessObject: string, blockTitleKey?: string, properties?: string[], filters?: any, additionnalOptions?: any }[];
  @Input() public categoryModule: boolean = false;
  @Input() public blockListTitle: string;
  @Input() public locale: string;
  @Input() public allowOverride: boolean = false;

  public blocks: any[] = [];
  public show: boolean = false;
  public shows: any = {};
  public edits: any = {};
  public ownerIndex: number = 0;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    protected snackbar: SnackbarService,
    @Inject('DialogService') protected dialog: any,
  ) {
    super($translate, authService, resource, state, snackbar, dialog);
  }

  setOverrideField() {
    if (this.product) {
      this.overrideField = 'override' + StringHelper.snakeToPascal(this.data[this.ownerIndex].businessObject.replace('product_', ''));
    } else if (this.category) {
      this.overrideField = 'override' + StringHelper.snakeToPascal(this.data[this.ownerIndex].businessObject.replace('category_', ''));
    }
  }

  fetch(event?: any) {
    if (undefined !== this.product) {
      if (this.allowOverride) {
        this.fetchBlockConfig('/v2/products/' + this.product.id + '/product_block');
      } else {
        this.fetchBlocks({ 'product.id': this.product.id });
      }
    } else if (undefined !== this.category) {
      if (this.allowOverride) {
        this.fetchBlockConfig('/v2/categories/' + this.category.id + '/category_block');
      } else {
        this.fetchBlocks({'category.id': this.category.id});
      }
    } else if (undefined !== this.superProduct) {
      this.fetchBlocks({ 'superProduct.id': this.superProduct.id });
    } if (undefined !== this.commercialOperation) {
      this.fetchBlocks({ 'commercialOperation.id': this.commercialOperation.id });
    } if (undefined !== this.room) {
      this.fetchBlocks({ 'room.id': this.room.id });
    } else if (this.categoryModule) {
      this.fetchBlocks({ 'exists[category]': false });
    }
  }

  fetchBlocks(filters: any) {
    filters.locale = this.locale;

    const ownerFilters = {...filters, ...this.data[this.ownerIndex].filters};
    const ownerOptions = { entryPoint: '/v2/' + StringHelper.pluralize(this.data[this.ownerIndex].businessObject), isHydra: true, returnHydraMembers: true };

    this.blocks = [];

    this.resource.cGet(ownerFilters, ownerOptions).pipe(takeUntil(this.destroyed$)).subscribe((ownerResponse: any[]) => {
      this.blocks[0] = ownerResponse[0] ? ownerResponse[0] : {};

      if (this.blocks[0].id) {
        this.fetchChildren(filters);
      } else {
        this.initChildren();
      }
    });
  }

  fetchChildren(filters: any) {
    const observables$: any[] = [];

    for (const index of Object.keys(this.data)) {
      const i = +index;
      const item = this.data[i];

      if (i !== this.ownerIndex) {
        const itemFilters = {...filters, ...item.filters, ...{'owner.id': this.blocks[0].id}};
        const itemOptions = {
          entryPoint: '/v2/' + StringHelper.pluralize(item.businessObject),
          isHydra: true,
          returnHydraMembers: true
        };

        observables$.push(this.resource.cGet(itemFilters, itemOptions));
      }
    }

    forkJoin(observables$).pipe(takeUntil(this.destroyed$)).subscribe((responses: any[]) => {
      for (const response of responses) {
        this.blocks.push(response[0] ? response[0] : {});
      }
    });
  }

  initChildren() {
    for (const index of Object.keys(this.data)) {
      const i = +index;

      if (i !== this.ownerIndex) {
        this.blocks.push({});
      }
    }
  }
}
