import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericFormModule, GenericModule } from '@components/generic';
import { ReactiveFormsModule } from '@angular/forms';
import { UploadModule } from '@progress/kendo-angular-upload';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { TabsModule } from 'ngx-bootstrap';
import { DirectivesModule } from '@directives/directives.module';
import { PipesModule } from '../../pipes/pipes.module';
import { ServiceListComponent } from '@components/service/list/service-list.component';
import { ServiceResultListComponent } from '@components/service/list/service-result-list.component';
import { ServiceResource } from '@components/service/service.resource';
import { ServiceFormComponent } from '@components/service/form';
import { ServiceFormTranslationsComponent } from '@components/service/form/service-form-translations.component';

@NgModule({
  declarations: [
    ServiceListComponent,
    ServiceResultListComponent,
    ServiceFormComponent,
    ServiceFormTranslationsComponent,
  ],
  entryComponents: [
    ServiceListComponent,
    ServiceResultListComponent,
    ServiceFormComponent,
    ServiceFormTranslationsComponent,
  ],
  imports: [
    CommonModule,
    GenericModule,
    ReactiveFormsModule,
    GenericFormModule,
    UploadModule,
    DialogsModule,
    TabsModule,
    DirectivesModule,
    PipesModule
  ],
  providers: [
    ServiceResource,
  ]
})
export class ServiceModule {}
