import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@services';
import { AbstractComponent } from '@components/generic/abstract.component';
import { DiscountResource } from '@components/discount/resources/discount.resource';

@Component({
  selector: 'app-customer-detail-discount',
  template: require('./customer-detail-discount.component.html'),
})
export class CustomerDetailDiscountComponent extends AbstractComponent implements OnInit {
  protected discountsCredit: any = [];
  protected discountsProductCart: any = [];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    @Inject('DialogService') private dialogService: any,
    protected resource: DiscountResource,
  ) {
    super($translate, authService, null, state);
  }

  ngOnInit(): void {
    this.fetch();
  }

  private fetch(): void {
    this.resource.getCreditDiscount(this.state.params.id)
      .takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        this.discountsCredit = response;
      });

    this.resource.getProductCartDiscount(this.state.params.id)
      .takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        this.discountsProductCart = response;
      });
  }
}
