import { AbstractComponent } from '@components/generic/abstract.component';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { ProductResource } from '@components/product/product.resource';
import { getWebsiteHostFromEnv } from '@constants';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-product-preview-button',
  template: require('./product-preview-button.component.html'),
  providers: [
    { provide: AbstractResource, useClass: ProductResource },
  ],
})
export class ProductPreviewButtonComponent extends AbstractComponent {

  private slug: string;

  @Input() translationFA: FormGroup;
  @Input() contentKey: string;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    public resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }

  public getLink(): string {
    const websiteBaseUrl = getWebsiteHostFromEnv(),
      websiteUrl = new URL(websiteBaseUrl);
    websiteUrl.pathname = '/product/' + this.getSlug();
    websiteUrl.searchParams.set('_preview', Date.now().toString(36));

    return websiteUrl.toString();
  }

  public getContent(): string {
    return this.translationFA.get(this.contentKey).value;
  }

  private getSlug(): string {
    if (!this.slug && this.translationFA && this.translationFA.get('slug') && this.translationFA.get('slug').value) {
      this.slug = this.translationFA.get('slug').value;
    }

    return this.slug;
  }

  public submit($event: any) {
    $event.srcElement.form.submit();
  }
}
