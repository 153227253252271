import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericFormModule } from '@components/generic/generic-form.module';
import { GenericModule } from '@components/generic/generic.module';
import { TrackingSalesListComponent } from '@components/tracking-sales/tracking-sales-list.component';
import { TrackingSalesResource } from '@components/tracking-sales/tracking-sales.resource';

@NgModule({
  declarations: [
    TrackingSalesListComponent,
  ],
  entryComponents: [
    TrackingSalesListComponent,
  ],
  imports: [
    CommonModule,
    GenericFormModule,
    GenericModule,
  ],
  exports: [
    TrackingSalesListComponent,
  ],
  providers: [
    TrackingSalesResource,
  ],
})

export class TrackingSalesModule { }
