import { Component, Inject, Input, OnInit } from '@angular/core';
import { FileRestrictions, UploadEvent } from '@progress/kendo-angular-upload';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services';
import { Observable } from 'rxjs/Observable';
import { SnackbarService } from '@components/snackbar/snackbar.service';
import { FormNotifierService } from '@services';

/**
 * This component manage the import/export file upload. It's generic and can be used everywhere.
 * The component emit `dataSubmitted` stream event when the import has succeeded.
 */
@Component({
  selector: 'app-import-export',
  template: require('./import-export.component.html'),
  styles: [require('./import-export.component.scss')],
})
export class ImportExportComponent extends AbstractComponent implements OnInit {

  /**
   * Observable that is called by parent component and return a file.
   */
  @Input() public export$?: Observable<void>;
  /**
   * Required by kendo-upload to make the request.
   */
  @Input() public importEntryPoint?: string;
  /**
   * The extensions allowed by the endpoint.
   */
  @Input() public allowedExtensions?: string[];
  /**
   * As the component is generic, it's the role to its parent to prepare the query before sending data.
   */
  @Input() public prepareQuery?: () => any;
  /**
   * kendo-upload require a property name for the file field to send with the FormData, so the parent must give it.
   */
  @Input() public uploadFieldName?: string;
  /**
   * A boolean to enabled/disabled import operation.
   */
  @Input() public disabledImport?: boolean;

  public fileRestrictions: FileRestrictions;
  public errors: {status: number, violations: any};

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private snackbar: SnackbarService,
    private formNotifier: FormNotifierService
  ) {
    super($translate, authService, null, state);
  }

  ngOnInit(): void {
    this.fileRestrictions = { allowedExtensions: this.allowedExtensions };
  }

  /**
   * Gets export file from api to front.
   */
  public getExportFile(): void {
    this.export$.takeUntil(this.destroyed$).subscribe();
  }

  /**
   * The kendo-upload component is hidden, we trigger it by clicking on the `import` btn.
   * Firstly the `onSubmit` method is called to attach data to the form.
   * Lastly the request is sending.
   */
  public importFile(): void {
    this.errors = null;
    document.getElementsByName(this.uploadFieldName)[0].click();
  }

  /**
   * Fires by kendo-upload when one or more files are about to be uploaded.
   * Attach data with the upload (internally use FormData) before submitting request.
   */
  private onSubmit(event: UploadEvent): void {
    event.data = this.prepareQuery();
  }

  /**
   * Fires by kendo-upload when an `upload` operation has failed.
   */
  private errorEventHandler(reject: any): void {
    this.errors = reject.response;
  }

  /**
   * Fires by kendo-upload when an `upload` operation is successfully completed.
   * Emit an event when the file has been uploaded.
   * Averts operator when file upload success.
   */
  private successEventHandler(): void {
    this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
    this.formNotifier.notifyFormSubmitted();
  }
}
