import { SessionHelper } from '@helpers';

/**
 * This file exports all status needed for reverses,
 * copied from amms-api/src/Dunglas/OrderBundle/Enumeration/ReverseProductPackageStatus.php
 * There is a task to create an endpoint.
 */
export const RECEIVE_WAITING = 'waiting';
export const RECEIVE_SAV_WAITING = 'sav_waiting';
export const RECEIVE_PROCESSING = 'processing';
export const RECEIVE_NEW = 'new';
export const RECEIVE_SECOND_HAND = 'second_hand';
export const RECEIVE_BROKEN = 'broken';
export const RECEIVE_SAV = 'sav';

export const RECONDITIONED_PROCESSING = 'processing';
export const RECONDITIONED_NEW = 'new';
export const RECONDITIONED_SECOND_HAND = 'second_hand';
export const RECONDITIONED_BROKEN = 'broken';
export const RECONDITIONED_RESEND_NEW = 'resend_new';
export const RECONDITIONED_SAGE = 'sage';
export const RECONDITIONED_SAV = 'sav';

export function getReceiveStatuses(): object[] {
  if (SessionHelper.getUILanguage() === 'en_GB') {
    return [
      { id: this.RECEIVE_WAITING, label: 'Awaiting return' },
      { id: this.RECEIVE_SAV_WAITING, label: 'Awaiting verification' },
      { id: this.RECEIVE_PROCESSING, label: 'Verification in progress' },
      { id: this.RECEIVE_NEW, label: 'Back to stock' },
      { id: this.RECEIVE_SECOND_HAND, label: 'Secondhand' },
      { id: this.RECEIVE_BROKEN, label: 'Disposal' },
      { id: this.RECEIVE_SAV, label: 'Spare parts' },
    ];
  }
  return [
    { id: this.RECEIVE_WAITING, label: 'En attente' },
    { id: this.RECEIVE_SAV_WAITING, label: 'SAV – En attente' },
    { id: this.RECEIVE_PROCESSING, label: 'En cours d’analyse' },
    { id: this.RECEIVE_NEW, label: 'Neuf' },
    { id: this.RECEIVE_SECOND_HAND, label: 'SM' },
    { id: this.RECEIVE_BROKEN, label: 'Epave' },
    { id: this.RECEIVE_SAV, label: 'Pièce détaché' },
  ];
}

export function getSelectableReconditionedStatuses(): object[] {
  if (SessionHelper.getUILanguage() === 'en_GB') {
    return [
      { id: this.RECONDITIONED_PROCESSING, label: 'In progress' },
      { id: this.RECONDITIONED_NEW, label: 'Back to stock' },
      { id: this.RECONDITIONED_SECOND_HAND, label: 'Secondhand' },
      { id: this.RECONDITIONED_BROKEN, label: 'Disposal' },
      { id: this.RECONDITIONED_RESEND_NEW, label: 'Reshipped' },
      { id: this.RECONDITIONED_SAV, label: 'Spare parts' },
    ];
  }
  return [
    { id: this.RECONDITIONED_PROCESSING, label: 'En cours' },
    { id: this.RECONDITIONED_NEW, label: 'Neuf' },
    { id: this.RECONDITIONED_SECOND_HAND, label: 'SM' },
    { id: this.RECONDITIONED_BROKEN, label: 'Epave' },
    { id: this.RECONDITIONED_RESEND_NEW, label: 'Réexpédié neuf' },
    { id: this.RECONDITIONED_SAV, label: 'Pièce détaché' },
  ];
}

export function getReconditionedStatuses(): object[] {
  return this.getSelectableReconditionedStatuses().concat([
    { id: this.RECONDITIONED_SAGE, label: 'Sage' }
  ]);
}

export function getPreselectionStatusesMapping(): { [key: string]: string } {
  return {
    [this.RECEIVE_NEW]: this.RECONDITIONED_NEW,
    [this.RECEIVE_SECOND_HAND]: this.RECONDITIONED_SECOND_HAND,
    [this.RECEIVE_BROKEN]: this.RECONDITIONED_BROKEN,
    [this.RECEIVE_SAV]: this.RECONDITIONED_PROCESSING,
  };
}
