import { Component, Inject } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services';
import { BASE_URL_API } from '@constants';
import { SessionHelper } from '@helpers';

@Component({
  selector: 'app-by-import-form',
  template: require('./by-import-form.component.html')
})
export class ByImportFormComponent extends AbstractComponent {

  public importEntryPoint: string = `${BASE_URL_API}/v2/carriers/preview_cost/import`;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, null, state);
  }

  public upload(): void {
   (document.querySelector('div#by-import-file').firstElementChild as HTMLElement).click();
  }

  public import(event: any): void {
    event.data = { country: SessionHelper.getCountry().id };
  }

  public error(event: any): void {
    console.log('error', event);
  }

  public success(event: any): void {
    const blob: Blob = new Blob([event.response.body.csv], {type: 'text/csv;charset=utf-8;'});
    const link: HTMLAnchorElement = document.createElement('a');

    if (link.download !== undefined) {
      const url: string = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', 'calculator.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  public complete(event: any): void {
  }
}
