import { Component, Inject, OnInit } from '@angular/core';
import { AbstractFormComponent } from '@components/generic/Form/abstract-form.component';
import { AbstractResource } from '@resources/abstract.resource';
import { ProductResource } from '@components/product/product.resource';
import { AuthService } from '@services/auth.service';
import {ProductImportModel, ProductImportProductItemModel} from '@components/product/models/product-import.model';
import {SnackbarService} from '@components/snackbar';
import {Observable} from 'rxjs/Observable';

@Component({
  selector: 'app-import-product',
  template: require('./import-product.component.html'),
  providers: [
    { provide: AbstractResource, useClass: ProductResource },
  ],
})
export class ImportProductComponent extends AbstractFormComponent implements OnInit {

  public importModel: ProductImportModel;
  public status: string = null;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private snackbar: SnackbarService,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {

    let observable: Observable<any>;

    switch (this.state.current.name) {
      case 'product.import':
        observable = this.resource.retrieveProductImport(this.state.params.id);
        break;
      case 'product.pack_import':
        observable = this.resource.retrieveProductPackImport(this.state.params.id);
        break;
    }

    observable
      .takeUntil(this.destroyed$)
      .subscribe(
        (response: ProductImportModel) => {
          this.importModel = response;
          this.status = response.status;
        },
        (error: any) => {
          this.status = 'error';
        }
      )
    ;
  }

  validateImport() {
    if (this.isImportModelValid()) {
      this.resource
        .submitImportValidation(this.importModel)
        .takeUntil(this.destroyed$)
        .subscribe(
          (response: ProductImportModel) => {
            this.status = response.status;

            if ('waiting_import' === response.status) {
              this.snackbar.inform(this.translate('PAGE.PRODUCT.IMPORT.SNACKBAR.WAITING_IMPORT'));
            } else {
              this.importModel = response;
              this.snackbar.warn(this.translate('PAGE.PRODUCT.IMPORT.SNACKBAR.NEED_REVALIDATION'));
              this.state.reload();
            }
          },
          (error: any) => {
            this.status = 'error';
          }
        );
    } else {
      this.snackbar.warn(this.translate('PAGE.PRODUCT.IMPORT.SNACKBAR.INVALID_FORM'));
    }
  }

  isImportModelValid(): boolean {
    let response = true;

    this.importModel.data.forEach((product: ProductImportProductItemModel) => {
      if (!product.valid) {
        response = false;
      }
    });

    return response;
  }

  cancelImport() {
    this.state.go('product_new.list', {}, {reload: true});
  }

  reloadPage(): void {
    this.ngOnInit();
  }
}
