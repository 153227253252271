import { AuthService } from '@services';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { SageExportResource } from '@resources/sage-export.resource';
import {SageResource} from '@resources/sage.resource';
import {SnackbarService} from '@components/snackbar';
import {DialogBoxService} from '@services/dialog-box.service';
import {SessionHelper} from '@helpers';
import {DialogCloseResult, DialogRef, DialogResult} from '@progress/kendo-angular-dialog';
import {Component, Inject} from '@angular/core';
import {AbstractResource} from '@resources';

@Component({
  selector: 'app-order-manager-sage-export-list',
  template: require('./order-manager-sage-export-list.component.html'),
  providers: [
    { provide: AbstractResource, useClass: SageExportResource },
  ],
})
export class OrderManagerSageExportListComponent extends AbstractPageComponent {
  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private sageResource: SageResource,
    private snackbar: SnackbarService,
    private dialogBoxService: DialogBoxService,
  ) {
    super($translate, authService, resource, state);
  }

  isIntrastatAvailable(): boolean {
    return 'FR_BE_NL_DE' !== SessionHelper.getCountry().commercialEntity.code;
  }

  onIntrastatExport(): void {
    const dialog: DialogRef = this.dialogBoxService.alert({
      title: this.translate('DIALOG.TITLE.CONFIRM'),
      content: this.translate('PAGE.ORDER.LIST.TEXT.EXPORT.INTRASTAT.CONFIRM')
    });

    dialog.result.subscribe((result: DialogResult) => {
      if (result instanceof DialogCloseResult || this.translate('DIALOG.BUTTON.CANCEL') === result.text) {
        return;
      }

      this.sageResource.createIntrastatExport(SessionHelper.getCountry().code)
        .takeUntil(this.destroyed$)
        .subscribe(() => {
          this.snackbar.validate(this.translate('PAGE.ORDER.LIST.TEXT.EXPORT.INTRASTAT.GENERATE_FILE'));
        });
    });
  }
}
