import { Component, Inject, OnInit } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services/auth.service';
import { SessionHelper } from '@helpers';

@Component({
  selector: 'app-room-block-list',
  template: require('./room-block-list.component.html'),
})
export class RoomBlockListComponent extends AbstractComponent implements OnInit {
  public room: any;
  public currentLocales: string[] = SessionHelper.getCountry().locales;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, null, state);
  }

  ngOnInit() {
    this.room = { id: this.state.params.id };
  }

  public hasSeveralTranslations(): boolean {
    return this.currentLocales.length > 1;
  }
}
