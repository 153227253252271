import { Component, ComponentFactoryResolver, Inject, Input } from '@angular/core';
import { GenericResultListComponent } from '@components/generic/List';
import { BASE_URL_INVOICE_ORDER } from '@constants/config.constants';
import { AuthService } from '@services';
import { UrlHelper } from '@helpers';
import { AbstractResource } from '@resources';
import { SnackbarService } from '@components/snackbar';
import { LitigationResource } from '@components/order-manager/resources/litigation.resource';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { DATE_FULL_FORMAT, DATE_SHORT_FORMAT, INPUT_NUMBER_PATTERN_DEC } from '@constants';
import * as moment from 'moment';
import { forkJoin } from 'rxjs/observable/forkJoin';

@Component({
  selector: 'app-litigation-result-list',
  template: require('./litigation-result-list.component.html'),
  providers: [
    LitigationResource,
  ]
})
export class LitigationResultListComponent extends GenericResultListComponent {
  public BASE_URL_INVOICE_ORDER = BASE_URL_INVOICE_ORDER;
  private _items: any[];
  public openBulkPayment: boolean = false;
  protected selectedItems: any = {};
  protected selectedLitigations: any[] = [];
  protected selectedCarrier: string;
  public form: FormGroup;
  public bulkRest: number = 0;
  public bulkCredit: number = 0;
  public selectAlls: any = {};

  @Input() public litigationStatuses: any[] = [];
  @Input() public litigationTypes: any[] = [];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    @Inject('StateService') state: ng.ui.IStateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('DialogService') dialog: any,
    urlHelper: UrlHelper,
    componentFactoryResolver: ComponentFactoryResolver,
    private snackbar: SnackbarService,
    private litigationResource: LitigationResource,
    private formBuilder: FormBuilder,
  ) {
    super($translate, state, authService, resource, dialog, urlHelper, componentFactoryResolver);
  }

  set items(items: any[]) {
    this._items = items;
  }

  get items(): any[] {
    return this._items;
  }

  public toggleSelectAll() {
    this.selectAlls[this.getPage()] = !this.selectAlls[this.getPage()];

    this._items.forEach(item => {
      if (!this.selectAlls[this.getPage()]) {
        delete this.selectedItems[item.id];
        this.removeSelectedLitigation(item);
      } else {
        this.selectedItems[item.id] = true;
        this.selectedLitigations.push(item);
      }
    });
  }

  public getPage(): number {
    return this.state.params.page || 1;
  }

  public getLitigationStatus(id: any): any {
    const status = this.litigationStatuses.find((item) => {
      return item.id === id;
    });

    return status || {};
  }

  public getLitigationType(id: any): string {
    const type = this.litigationTypes.find((item) => {
      return item.id === id;
    });

    return type ? type.label : null;
  }

  public goToEdit(id: any): void {
    this.state.go('order_manager.litigation', { id: id }, { reload: true });
  }

  public getDownloads(litigation: any): any[] {
    if (undefined === litigation.invoice) {
      return [];
    }

    const downloads = [
      {
        label: 'invoice' === litigation.invoice ? this.translate('BUTTON.DOWNLOAD_INVOICE') : this.translate('BUTTON.DOWNLOAD_PRE_INVOICE'),
        litigation: litigation,
        type : 'invoice',
      },
      {
        label: this.translate('BUTTON.DOWNLOAD_ALL'),
        litigation: litigation,
        all: true,
        type : 'invoice',
      },
    ];

    if (litigation.credit > 0) {
      downloads.push(
        {
          label:  this.translate('BUTTON.DOWNLOAD_CREDIT'),
          litigation: litigation,
          type: 'credit'
        },
      );
    }

    return downloads;
  }

  public download($event: any): void {
    if (undefined !== $event.all && $event.all) {
      this.downloadAll(+($event.litigation.id));
      return;
    }
    const type = $event.type && $event.type === 'credit' ? 'credit' : 'invoice';

    this.downloadFile(+($event.litigation.id), type);
  }

  protected downloadFile(id: number, type: string): void {
    this.litigationResource.downloadInvoice(id, type)
      .takeUntil(this.destroyed$)
      .subscribe(
        (response: Blob | null) => {
          if (null === response) {
            this.snackbar.warn(this.translate('ALERTS.NO_INVOICE_TO_DOWNLOAD'));

            return;
          }

          const fileURL = URL.createObjectURL(response);
          window.open(fileURL);
        });
  }

  protected downloadAll(id: number): void {
   this.litigationResource.downloadAllInvoices(id)
      .takeUntil(this.destroyed$)
      .subscribe(
        (response: Blob | null) => {
          const fileURL = URL.createObjectURL(response);
          window.open(fileURL);
        },
      );
  }

  public onSelectedItemsChange(isChecked: boolean, litigationId: number): void {
    const litigation: any = this.getLitigation(litigationId);

    if (undefined === litigation) {
      this.selectedItems[litigationId] = false;
      return;
    }

    if (isChecked) {
      this.selectedLitigations.push(litigation);
    } else {
      this.removeSelectedLitigation(litigation);
    }

    this.selectedItems[litigationId] = isChecked;
  }

  protected unselectLitigation(litigation: any): void {
    this.selectedItems[litigation.id] = false;
    this.removeSelectedLitigation(litigation);
    this.computeBulkRest();
    this.computeBulkCredit();
  }

  protected bulkPayment(): void {
    const relaisColisCarriers = [
      'relaiscolis-takeback-eco',
      'relaiscolis-takeback-confort',
      'relaiscolis-prmax',
      'relaiscolis-pr',
      'relaiscolis-eco',
      'relaiscolis-confort',
    ];

    if (!this.selectedLitigations.length) {
      this.snackbar.warn(this.translate('PAGE.LITIGATION.LIST.BULK_PAYMENT_ERROR_COUNT'));
      return;
    }

    const uniqueCarrierCodes: any[] = [...new Set(
      this.selectedLitigations.map((item: any) => item.carrierGroup.code)
    )];

    const onlySameCarriers = uniqueCarrierCodes.every((carrierGroupCode: string) => relaisColisCarriers.includes(carrierGroupCode));

    if (uniqueCarrierCodes.length > 1 && !onlySameCarriers) {
      this.snackbar.warn(this.translate('PAGE.LITIGATION.LIST.BULK_PAYMENT_ERROR_CARRIER'));
      return;
    }

    const billedLitigations: any[] = this.selectedLitigations.filter((item: any) => {
      return 'billed' === this.getLitigationStatus(item.litigationStatus).code;
    });

    if (billedLitigations.length) {
      this.snackbar.warn(this.translate('PAGE.LITIGATION.LIST.BULK_PAYMENT_ERROR_STATUS'));
      return;
    }

    const selectedAmount = this.getSelectedAmount();

    if (!selectedAmount) {
      this.snackbar.warn(this.translate('PAGE.LITIGATION.LIST.BULK_PAYMENT_ERROR_AMOUNT'));
      return;
    }

    this.selectedCarrier = uniqueCarrierCodes[0];
    this.buildForm();
    this.computeBulkRest();
    this.computeBulkCredit();
    this.openBulkPayment = true;
  }

  protected closeBulkPayment(): void {
    this.openBulkPayment = false;
  }

  private getLitigation(id: number): any {
    return this._items.find((item: any) => item.id === id);
  }

  private removeSelectedLitigation(litigation: any): void {
    const index: number = this.selectedLitigations.indexOf(litigation);

    if (index > -1) {
      this.selectedLitigations.splice(index, 1);
    }
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      carrier: [this.selectedCarrier],
      paymentTotal: [null, [Validators.required, Validators.pattern(INPUT_NUMBER_PATTERN_DEC)]],
      paymentDate: [null, [Validators.required]],
    });
  }

  public computeBulkRest(): void {
    const result: number = this.getSelectedAmount() - this.getSelectedCredit() ;
    this.bulkRest = +(this.form.value.paymentTotal - result).toFixed(2);
  }

  public computeBulkCredit(): void {
    this.bulkCredit = +(this.getSelectedCredit());
  }

  private getSelectedAmount(): number {
    return this.selectedLitigations.map((item: any) => item.litigationTotal).reduce((a, b) => a + b, 0).toFixed(2);
  }

  private getSelectedCredit(): number {
    return this.selectedLitigations.map((item: any) => item.credit).reduce((a, b) => a + b, 0).toFixed(2);
  }

  public createPayment(): void {
    const form = this.form.value;
    const paymentDate = moment(form.paymentDate, DATE_SHORT_FORMAT);

    if (paymentDate.isAfter(moment(), 'day')) {
      this.snackbar.warn(this.translate('PAGE.LITIGATION.LIST.BULK_PAYMENT_ERROR_DATE'));
      return;
    }

    if (0 !== this.bulkRest) {
      this.snackbar.warn(this.translate('PAGE.LITIGATION.LIST.BULK_PAYMENT_ERROR_TOTAL'));
      return;
    }

    const litigationIris: string[] = this.selectedLitigations.map((item: any) => item['@id']);

    if (!litigationIris.length) {
      this.snackbar.warn(this.translate('PAGE.LITIGATION.LIST.BULK_PAYMENT_ERROR_COUNT'));
      return;
    }

    this.dialog.confirm(this.translate('PAGE.LITIGATION.LIST.BULK_PAYMENT_CONFIRM'))
      .then(() => {
        this.litigationResource.createPayment(form.paymentTotal, paymentDate.format(DATE_FULL_FORMAT), litigationIris)
          .takeUntil(this.destroyed$)
          .subscribe(() => {
            this.snackbar.validate('SAVED');
            this.state.go(this.state.current, this.state.params, { reload: true });
          })
        ;
      })
    ;
  }

  public archive(id: string): void {
    this.litigationResource.partialUpdate(id, { archived: true })
      .takeUntil(this.destroyed$)
      .subscribe(() => {
        this.snackbar.validate('SAVED');
        this.state.go(this.state.current, this.state.params, { reload: true });
      })
    ;
  }

  protected bulkArchive(): void {
    if (!this.selectedLitigations.length) {
      return;
    }

    const observables: any[] = [];

    this.selectedLitigations.forEach((item: any) => {
      observables.push( this.litigationResource.partialUpdate(item.id, { archived: true }));
    });

    forkJoin(observables)
      .subscribe(
        () => {
          this.snackbar.validate('SAVED');
          this.state.go(this.state.current, this.state.params, { reload: true });
        },
        () => this.snackbar.alert(this.translate('ALERTS.DATA.FAIL'))
      )
    ;
  }
}
