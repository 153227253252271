export default warehousesRoutes;

/** @ngInject */
function warehousesRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'walisoft',
    sidebarSubNav: 'warehouses',
    title: '',
    reloadChangeCountry: 'warehouses.list',
  };

  $stateProvider
    .state('warehouses', {
      url: '/warehouses',
      parent: 'walisoft',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.WAREHOUSES',
        stateName: 'warehouses.list'
      },
      data: Object.assign(commonStateData, {
        showCountries: false
      })
    })
    .state('warehouses.list', {
      url: '',
      views: {
        'page@layout': {
          template: '<app-warehouse-list></app-warehouse-list>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.WAREHOUSES.LIST.TITLE',
      })
    })
    .state('warehouses.edit', {
      url: '/:id',
      breadcrumb: { label: 'BREADCRUMB.EDIT' },
      views: {
        'page@layout': { template: '<app-warehouses-edit></app-warehouses-edit>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.WAREHOUSES.EDIT.TITLE',
        tabs: [
          {
            label: 'PAGE.WAREHOUSES.TABS.GENERAL',
            state: 'warehouses.edit',
          },
          {
            label: 'PAGE.WAREHOUSES.TABS.DIRECT_INJECTION',
            state: 'warehouses.edit.injection',
          }
        ],
      })
    })
    .state('warehouses.edit.injection', {
      url: '/injections',
      breadcrumb: { label: 'BREADCRUMB.EDIT' },
      views: {
        'page@layout': { template: '<app-warehouses-edit></app-warehouses-edit>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.WAREHOUSES.EDIT.TITLE',
        tabs: [
          {
            label: 'PAGE.WAREHOUSES.TABS.GENERAL',
            state: 'warehouses.edit',
          },
          {
            label: 'PAGE.WAREHOUSES.TABS.DIRECT_INJECTION',
            state: 'warehouses.edit.injection',
          }
        ],
      })
    })
  ;
}
