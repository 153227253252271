import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button-copy',
  template: require('./button-copy.component.html'),
  styles: [require('./button-copy.component.scss')]
})
export class ButtonCopyComponent {
  @Input() private element: HTMLInputElement;

  public copyText(): void {
    this.element.select();
    document.execCommand('copy');
  }
}
