import { Resource } from '@decorators/Resource';
import { Injectable } from '@angular/core';
import { AbstractResource } from '@resources/abstract.resource';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  entryPoint: '/v2/carrier_capacities',
  isHydra: true,
})
export class CarrierCapacityResource extends AbstractResource {
  protected nullableProperties: string[] = ['date', 'dayOfWeek'];

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }
}
