export default salesLogExportRoutes;

/** @ngInject */
function salesLogExportRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'walipush',
    sidebarSubNav: 'saleslog',
    title: '',
    reloadChangeCountry: 'saleslog.export',
  };

  $stateProvider
    .state('saleslog', {
      url: '/export/saleslog',
      parent: 'walipush',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.SALESLOG',
        stateName: 'saleslog.export'
      }
    })
    .state('saleslog.export', {
      url: `?
        &startDate
        &endDate
      `,
      views: {
        'page@layout': {
          template: '<app-sales-log-export></app-sales-log-export>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.SALES_LOG.TITLE',
      })
    })
  ;
}
