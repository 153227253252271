import { Component, Inject, Input, OnInit } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services/auth.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-block-form-control',
  template: require('./block-form-control.component.html'),
  styles: [require('./block.scss')],
})
export class BlockFormControlComponent extends AbstractComponent implements OnInit {
  @Input() public control: FormControl;
  @Input() public type: string;
  @Input() public name: string;
  @Input() public readonly: boolean;
  @Input() public hideLabel: boolean = false;
  @Input() public businessObject?: string;
  @Input() public rteOptions?: object;
  @Input() public additionnalOptions: any;
  @Input() public data: any[];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, null, state);
  }
  ngOnInit(): void {
    if (!this.rteOptions || typeof this.rteOptions !== 'object' && (<Object>this.rteOptions).constructor !== Object) {
      this.rteOptions = {
        plugins: [''],
        toolbar: 'bold',
        block_formats: '',
        height: 300
      };
    } else {
      this.rteOptions = Object.assign({
        plugins: [''],
        toolbar: 'bold',
        block_formats: '',
        height: 300
      }, this.rteOptions);
    }
    if (this.type !== 'rte' && this.rteOptions) {
      this.rteOptions = null;
    }

    this.initAdditionnalOptions();
  }

  getId(): string {
    return this.name + '-' + Math.floor(Math.random() * 100);
  }

  initAdditionnalOptions(): void {
    if (!this.additionnalOptions) {
      this.additionnalOptions = {
        max: 0,
        image_size: this.getAdditionnalInformationTranslation()
      };

      return;
    }

    this.additionnalOptions = {
      max: this.additionnalOptions.max && this.additionnalOptions.max[this.name] ? this.additionnalOptions.max[this.name] : 0,
      image_size: this.additionnalOptions.image_size ? this.additionnalOptions.image_size : this.getAdditionnalInformationTranslation()
        ,
    };
  }

  getAdditionnalInformationTranslation(): string {
    return this.keyExists('PAGE.CONTENT_WEBSITE.ADDITIONAL_INFORMATION.'
      + (this.businessObject !== undefined ? this.businessObject.toUpperCase() : '') + '.' + this.name.toUpperCase())
      ? this.translate('PAGE.CONTENT_WEBSITE.ADDITIONAL_INFORMATION.'
      + this.businessObject.toUpperCase() + '.' + this.name.toUpperCase()) : '';
  }
}
