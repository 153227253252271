'use strict'

###
  @name FormDisplayGlobalErrorsCmp

  @description Component formDisplayGlobalErrors for form.
  Display global errors if the API returned the errors.

  @param {object} errors

  @example
    <div form-display-global-errors
         errors="form.formErrors"></div>
###
angular
  .module('ammsFrontendApp')
  .directive('formDisplayGlobalErrors', [
    ->
      replace: true
      restrict: 'A'
      templateUrl: require('views/default/form/display-global-errors.html')
      controller: 'FormDisplayGlobalErrorsCtrl'
      controllerAs: 'formError'
      bindToController:
        errors: '='
      scope: true
      link: ((scope, elem, attrs, formError) ->
        formError.listErrors = []

        scope.$watchCollection('formError.errors', (newValue) ->
          if ('undefined' != typeof newValue && 'undefined' != typeof newValue.errors && Array.isArray(newValue.errors))
            formError.listErrors = newValue.errors
        )
      )
  ])
  .controller('FormDisplayGlobalErrorsCtrl', [ ->
    this.$onInit = =>
      if ('undefined' != typeof this.errors && Array.isArray(this.errors))
        this.listErrors = this.errors

    return this
  ])
