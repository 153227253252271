import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from '@components/dashboard/dashboard.component';
import { GenericModule } from '@components/generic';
import { SalesComparisonComponent } from '@components/dashboard/sales-comparison/sales-comparison.component';
import { DashboardResource } from '@components/dashboard/dashboard.resource';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { LayoutModule } from '@progress/kendo-angular-layout';

@NgModule({
  declarations: [
    DashboardComponent,
    SalesComparisonComponent,
  ],
  entryComponents: [
    DashboardComponent,
    SalesComparisonComponent,
  ],
  imports: [
    CommonModule,
    GenericModule,
    DialogsModule,
    LayoutModule
  ],
  providers: [DashboardResource],
})
export class DashboardModule {}
