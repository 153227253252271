'use strict'

angular
  .module 'ammsFrontendApp'
  .filter 'nl2br',
    ($sce) ->
      (sourceText) ->
        if !sourceText? || 'string' isnt typeof sourceText
          return sourceText

        $sce.trustAsHtml sourceText.replace(/(\n|\r\n|\r)/g, '<br>')
