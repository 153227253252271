import { AbstractFormFieldBase } from './abstract-form-field-base.class';

/**
 * Generic text-area field, used to render text-area component.
 * (Actually using kendo-text-area)
 */
export class TextAreaField extends AbstractFormFieldBase<string> {
  /**
   * Used in the template to render the kendo-text-area component.
   *
   * @type {string}
   */
  fieldType = 'textArea';

  constructor(options: any = {}) {
    super(options);
  }
}
