import { Component, Inject } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { CategoriesMarketplaceFiltersFormService } from '../service/categories-marketplace-filters-form.service';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { SnackbarService } from '@components/snackbar';
import { AbstractResource } from '@resources/abstract.resource';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { CategoriesMarketplaceResource } from '@components/categories-marketplace/categories-marketplace.resource';

@Component({
  selector: 'app-categories-marketplace-list',
  template: require('./categories-marketplace-list.component.html'),
  styles: [require('./categories-marketplace-list.component.scss')],
  providers: [
    { provide: AbstractResource, useClass: CategoriesMarketplaceResource },
    { provide: AbstractFiltersFieldsService, useClass: CategoriesMarketplaceFiltersFormService }
  ]
})
export class CategoriesMarketplaceListComponent extends AbstractPageComponent {

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    @Inject('StateService') state: ng.ui.IStateService,
    authService: AuthService,
    resource: AbstractResource,
    private snackbar: SnackbarService,
  ) {
    super($translate, authService, resource, state);
  }

  public updateCategory({ active, id }: { active: boolean; id: string; }) {
    this.resource.partialUpdate(id, { active })
      .takeUntil(this.destroyed$)
      .subscribe(() => this.snackbar.validate(this.translate('ALERTS.DATA.UPDATE')))
    ;
  }
}
