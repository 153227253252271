import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { ArrivalResource } from '@resources/arrival.resource';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { IButtonLinks } from '@components/generic/buttons/button-links.interface';
import { IHydraResource } from '@interfaces/hydra-resource.interface';
import { ArrivalListModel } from '../model/arrival-list.model';
import { SnackbarService } from '@components';
import { SavArrivalFiltersFormService } from './sav-arrival-filters-form.service';
import * as moment from 'moment';
import { DATE_SHORT_INTERNATIONAL_FORMAT } from '@constants/date';
import { SessionHelper } from '@helpers';

@Component({
  selector: 'app-sav-arrival-list',
  template: require('./sav-arrival-list.component.html'),
  styles: [require('./sav-arrival-list.component.scss')],
  providers: [
    { provide: AbstractResource, useClass: ArrivalResource },
    { provide: AbstractFiltersFieldsService, useClass: SavArrivalFiltersFormService }
  ],
})
export class SavArrivalListComponent extends AbstractPageComponent implements OnInit {

  public links: IButtonLinks[] = [];
  public items: any[];
  public totalItems: number;
  public businessObject: string = 'spare-part';
  public currentState: string;
  public importOptions: any[];
  public paginationFilters: any;
  public currentFilters: any;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    private snackbar: SnackbarService,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit() {
    this.currentState = (<any>this.state.$current).parent.self.name;
    this.importOptions = [
      {
        entryPoint: '/api/v2/import',
        importButton: 'PAGE.SAV.ARRIVAL.LIST.IMPORT',
        businessObject: 'sav-arrival'
      },
    ];
  }

  public fetch(): void {
    const _filters = {
      isSparePart: true,
      ...this.paginationFilters,
      ...this.currentFilters,
    };

    if (this.currentFilters) {
      if (this.currentFilters.date) {
        _filters['date[before]'] = moment(this.currentFilters.date[1]).format(DATE_SHORT_INTERNATIONAL_FORMAT);
        _filters['date[after]'] = moment(this.currentFilters.date[0]).format(DATE_SHORT_INTERNATIONAL_FORMAT);
      }
      if (this.currentFilters.reasons) {
        _filters['reason.id[]'] = this.currentFilters.reasons;
      }
      if (this.currentFilters.sparePartId) {
        _filters['masterProductsWarehouses.masterProduct.products.sku'] = 'SPAREPART_' + this.currentFilters.sparePartId;
      }
    }

    this.resource.getMany(_filters)
      .takeUntil(this.destroyed$)
      .subscribe((response: IHydraResource) => {
        this.items = response['hydra:member'].map((item: any) => new ArrivalListModel(item));
        this.totalItems = response['hydra:totalItems'];
      }, (error) => { },
        () => {
          SessionHelper.setFiltersForPage(_filters, this.currentState);
          this.state.go(this.state.current, this.state.params, { location: true, notify: false });
        })
      ;
  }

  public update(property: string, $event: any, id: string) {
    const body: any = {};
    if ($event.type === 'number') {
      body[property] = +$event.value;
    } else {
      body[property] = $event.value;
    }

    this.resource.update(id, body).takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        this.snackbar.validate(`${id} ${this.translate('PAGE.SAV.ARRIVAL.LIST.SAVED')}`);
      }, error => {
        this.fetch();
      });
  }

  public paginate(event: { page: number, byPage: number }): void {
    this.paginationFilters = event;
    this.fetch();
  }

  public onFilterChange(e: any) {
    this.currentFilters = e;
    this.fetch();
  }
}
