import { Component, Inject, Input, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { WarehousesResource } from '@components/warehouses';
import { IWarehouses } from '@components/warehouses/models';
import { ArrivalResource } from '@resources/arrival.resource';
import { HydraHelper } from '@helpers/HydraHelper';
import { SnackbarService } from '@components/snackbar';
import * as moment from 'moment';
import { DATE_FULL_FORMAT, DATE_SHORT_FORMAT } from '@constants';
import { ArrivalReasonResource } from '@resources/arrival-reason.resource';
import { AddressingResource } from '@resources/addressing.resource';
import { MasterProductsWarehousesResource } from '@resources';

@Component({
  selector: 'app-sav-arrival-form',
  template: require('./sav-arrival-form.component.html'),
  providers: [
    { provide: AbstractResource, useClass: ArrivalResource },
  ],
})
export class SavArrivalFormComponent extends AbstractPageComponent implements OnInit {

  @Input() public stockMovement: boolean = false;

  public arrival: any;
  public sparePart: any = null;
  public masterProductId: any;
  public warehouseId: any;
  public form: FormGroup;
  public warehouses: any[] = [];
  public reasons: any[] = [];
  public editable: boolean = true;
  public addressings: any[] = [];
  public selectedWarehouse: any = null;

  public anchorBtnBarOptions = {
    enableCancelButton: true,
    enableSaveAndListButton: false,
    enableDeleteButton: false
  };

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    private snackbar: SnackbarService,
    private warehouseResource: WarehousesResource,
    private arrivalReasonResource: ArrivalReasonResource,
    private masterProductsWarehousesResource: MasterProductsWarehousesResource,
    private addressingResource: AddressingResource,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit() {
    this.fetch();
    this.buildForm();
  }

  private fetch(): void {
    if (this.state.params.id) {
      this.resource.get(this.state.params.id, { dontUseModel: true })
        .takeUntil(this.destroyed$)
        .subscribe((response: any) => {
          this.arrival = response;
          this.editable = null === this.arrival.receivedQuantity;
          this.buildForm();
        })
      ;
    }

    const options: any = {
      isHydra: true,
      returnHydraMembers: true,
      dontUseModel: true,
      blocking: false
    };

    this.warehouseResource.cGet(
      {
        'acceptSparePart' : true,
        'active': true,
        'pagination': false,
      },
      options
    )
      .takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        this.warehouses = response.map((warehouse: IWarehouses): any => {
          return {
            iri: warehouse['@id'],
            code: warehouse.code,
            name: warehouse.name,
          };
        });
      })
    ;

    this.arrivalReasonResource.cGet({pagination: false}, options)
      .takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        this.reasons = response.map((reason: any): any => {
          return {
            iri: reason['@id'],
            reason: (reason.translations[this.currentLocale] || reason.translations['fr_FR']).reason,
          };
        });
      })
    ;
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      season: this.arrival ? this.arrival.season : null,
      warehouse: this.arrival ? this.arrival.masterProductsWarehouses.warehouse['@id'] : null,
      quantity: this.arrival ? this.arrival.quantity : null,
      shippedQuantity: this.arrival ? this.arrival.shippedQuantity : null,
      receivedQuantity: this.arrival ? this.arrival.receivedQuantity : null,
      date: this.arrival ? moment(this.arrival.date).format(DATE_SHORT_FORMAT) : null,
      sageIdentifier: this.arrival ? this.arrival.sageIdentifier : null,
      comment: this.arrival ? this.arrival.comment : null,
      reason: this.arrival ? this.arrival.reason['@id'] : null,
      addressingBay: this.arrival ? this.arrival.addressing.addressingBay : null,
      addressingLane: this.arrival ? this.arrival.addressing.addressingLane : null,
      addressingLocation: this.arrival ? this.arrival.addressing.addressingLocation : null,
      addressingLevel: this.arrival ? this.arrival.addressing.addressingLevel : null,
      addressingType: this.arrival ? this.arrival.addressing.addressingType : null,
      addressing: this.arrival ? this.arrival.addressing : null,
    });
  }

  public onSelectWarehouse(event: any): void {
    this.selectedWarehouse = event;
    this.addressing(null, event.iri.split('/').pop());
  }

  public onSparePartSelection(event: any): void {
    this.sparePart = event;
    this.addressing(event.masterProduct, null);
  }

  public addressing(masterProduct: number, warehouse: number): void {
    if (masterProduct !== null) {
      this.masterProductId = masterProduct;
    }
    if (warehouse !== null) {
      this.warehouseId = warehouse;
    }
    if (this.masterProductId !== null && this.warehouseId !== null) {
      this.masterProductsWarehousesResource.cGet({'masterProduct' : this.masterProductId, 'warehouse' : this.warehouseId }, {})
        .takeUntil(this.destroyed$)
        .subscribe((response: any) => {
          this.addressingResource.cGet({'masterProductsWarehouse' : response['hydra:member'][0].id}, {})
            .takeUntil(this.destroyed$)
            .subscribe((addressings: any) => {
              addressings['hydra:member'].forEach((addressing: any) => {
                addressing.addressing = addressing.addressingBay + ' ' + addressing.addressingLane + ' ' + addressing.addressingLocation + ' ' +
                  addressing.addressingLevel + ' ' + addressing.addressingType;
                return addressing;
              });
              this.addressings = addressings['hydra:member'];
            })
          ;
        })
      ;
    }
  }
  public submit(event?: any): void {
    const form = this.form.value;
    const date = this.stockMovement ? new Date() : form.date;

    if (!this.arrival) {
      const body: any = {
        masterProduct: HydraHelper.buildIri(this.sparePart.masterProduct, 'master_products'),
        warehouse: form.warehouse,
        quantity: parseInt(form.quantity, 10),
        season: form.season,
        date: moment(date, DATE_SHORT_FORMAT).startOf('day').format(DATE_FULL_FORMAT),
        sageIdentifier: form.sageIdentifier,
        comment: form.comment,
        reason: form.reason,
        addressingBay: form.addressing ? form.addressing.addressingBay : null,
        addressingLane: form.addressing ? form.addressing.addressingLane : null,
        addressingLocation: form.addressing ? form.addressing.addressingLocation : null,
        addressingLevel: form.addressing ? form.addressing.addressingLevel : null,
        addressingType: form.addressing ? form.addressing.addressingType : null,
        addressing: form.addressing,
      };

      if (this.stockMovement) {
        body.shippedQuantity = body.quantity;
        body.receivedQuantity = body.quantity;
      }

      this.resource.create(body, { entryPoint: '/v2/arrival_stock_movement' })
        .takeUntil(this.destroyed$)
        .subscribe((response: any) => {
          this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
          this.state.go('sav.arrival_list');
        })
      ;
    } else {
      const body: any = {
        quantity: parseInt(form.quantity, 10),
        shippedQuantity: parseInt(form.shippedQuantity, 10),
        receivedQuantity: parseInt(form.receivedQuantity, 10),
        season: form.season,
        date: moment(date, DATE_SHORT_FORMAT).startOf('day').format(DATE_FULL_FORMAT),
        comment: form.comment,
      };

      this.resource.update(this.arrival.id, body)
        .takeUntil(this.destroyed$)
        .subscribe((response: any) => {
          this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
          this.state.go('sav.arrival_list');
        })
      ;
    }
  }
}
