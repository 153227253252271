import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractFormComponent } from '@components/generic/Form/abstract-form.component';
import { AbstractResource } from '@resources/abstract.resource';
import { AuthService } from '@services/auth.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SnackbarService } from '@components/snackbar';
import { FormNotifierService } from '@services/form-notifier.service';
import { ICountry } from '@interfaces/ICountry';
import { SessionHelper } from '@helpers/session.helper';
import { SuperProductResource } from '../super-product.resource';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';

interface ISelectableCountry {
  id: number;
  code: string;
  label: string;
}

@Component({
  selector: 'app-duplicate-super-product',
  template: require('./duplicate-super-product.component.html'),
  providers: [
    { provide: AbstractResource, useClass: SuperProductResource },
  ],
})
export class DuplicateSuperProductComponent extends AbstractFormComponent implements OnInit, OnDestroy {

  public form: FormGroup;
  public countries: ISelectableCountry[];
  public currentCountry: ICountry;
  public open: boolean = false;
  private eventsSubscription: Subscription;

  @Input() openModalEvent: Observable<void>;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    private snackbar: SnackbarService,
    private formNotifier: FormNotifierService,
    @Inject('DialogService') private dialog: any,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    this.currentCountry = SessionHelper.getCountry();
    this.countries = SessionHelper.getCountries().map((country: ICountry) => {
      return {
        id: country.id,
        code: country.code,
        label: country.translations[this.currentLocale].name,
      };
    });

    if (this.openModalEvent) {
      this.eventsSubscription = this.openModalEvent.subscribe(() => {
        this.openModal();
      });
    }
  }

  ngOnDestroy(): void {
    if (this.openModalEvent) {
      this.eventsSubscription.unsubscribe();
    }
  }

  public createForm(): void {
    this.form = this.formBuilder.group({
      country: [{
        label: this.currentCountry.translations[this.currentLocale].name,
        id: this.currentCountry.id,
        code: this.currentCountry.code,
      }],
      sku: [''],
      deeplTranslation: [false]
    });
  }

  public closeModal(): void {
    this.open = false;
  }

  public openModal(): void {
    this.open = true;
    this.createForm();
  }

  public duplicateSuperProduct(): void {
    this.resource.duplicate(
      +this.form.get('sku').value.id,
      +this.state.params.id,
      +this.form.get('country').value.id,
      +this.currentCountry.id,
      !!this.form.get('deeplTranslation').value,
      { dontUseModel: true }
    ).takeUntil(this.destroyed$).subscribe(() => {
      this.closeModal();
      this.snackbar.validate(this.translate('ALERTS.DATA.UPDATE'));
    });

    this.resource.duplicateNewContent(
      +this.form.get('sku').value.id,
      +this.state.params.id,
      +this.form.get('country').value.id,
      +this.currentCountry.id,
      !!this.form.get('deeplTranslation').value,
      { dontUseModel: true }
    ).takeUntil(this.destroyed$).subscribe(() => {
      this.closeModal();
      this.snackbar.validate(this.translate('ALERTS.DATA.UPDATE'));
    });
    this.state.reload();
  }

  public resetSku(): void {
    this.form.get('sku').reset();
  }
}
