import { SessionHelper } from '@helpers';
import { isString } from 'angular';
import { IPackage, IProduct, IProductLight } from '../interfaces/product-light.interface';


export class ProductLightModel implements IProductLight {
    public reference: string;
    public sku: string;
    public weight: null;
    public width: number;
    public height: number;
    public length: number;
    public numberOfPieces: number;
    public packages: IPackage[];
    public image: string;

    constructor(model: IProduct) {
        this.reference = this.getReferenceName(model);
        this.sku = model.sku;
        this.weight = model.masterProduct.weight;
        this.width = model.masterProduct.width;
        this.height = model.masterProduct.height;
        this.length = model.masterProduct.length;
        this.numberOfPieces = model.masterProduct.numberOfPieces;
        this.packages = model.packages;
        this.image = this.getImage(model);
    }

    private getReferenceName(model: IProduct): string {
        const currentLocale = SessionHelper.getUILanguage();

        if (model.superProduct
            && model.superProduct.translations
            && model.superProduct.translations[currentLocale]
            && model.superProduct.translations[currentLocale].reference
        ) {
            return model.superProduct.translations[currentLocale].reference || '';
        }

        return '';
    }

    private getImage(model: IProduct): string {
        if (model.imagesOrdered
            && Array.isArray(model.imagesOrdered)
            && model.imagesOrdered.length > 0
        ) {
            const image = model.imagesOrdered
                .sort((a, b) => {
                    const weightA = (+!!a.isFeatured * 8) + (+!!a.isBackground * 4) + (+!!a.isAmbiance * 2) + (+!!a.isDimension * -16);
                    const weightB = (+!!b.isFeatured * 8) + (+!!b.isBackground * 4) + (+!!b.isAmbiance * 2) + (+!!b.isDimension * -16);

                    return weightB - weightA;
                })
                .find((imageOrdered) => isString(imageOrdered.image) && imageOrdered.image.trim() !== '').image;

            return image || '';
        }

        return '';
    }
}
