import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@services';
import { CommercialOperationsResource } from './resources/commercial-operations.resource';
import { CommercialOperationsModel } from './models/commercial-operations.model';
import { ICommercialOperations } from './models/commercial-operations.interface';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';

@Component({
  selector: 'app-commercial-operations',
  template: require('./commercial-operations.component.html'),
  styles: [require('./commercial-operations.component.scss')]
})

export class CommercialOperationsComponent extends AbstractPageComponent implements OnInit {

  public model: ICommercialOperations;
  public inCreation: boolean;
  public webSiteUrls: any;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: CommercialOperationsResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    this.inCreation = !this.state.params.id;

    if (this.inCreation) {
      this.model = new CommercialOperationsModel();

      return;
    }

    this.resource.get(this.state.params.id, {model: CommercialOperationsModel})
      .takeUntil(this.destroyed$)
      .subscribe((response: ICommercialOperations) => {
        this.model = response;

        if (this.model.websiteUrls) {
          this.webSiteUrls = Object.keys(this.model.websiteUrls).map((locale: string) => {
            return { url: this.model.websiteUrls[locale], locale: locale };
          });
        }
      })
    ;
  }
}
