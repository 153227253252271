'use strict'

angular
  .module 'ammsFrontendApp'
  .directive 'formSelect', [
    ->
      controller = ($scope) ->
        $scope.config =
          valueField: $scope.idProperty
          labelField: $scope.valueProperty
          load: (query, callback) ->
            pendingLoad = $scope.load({ query: query })
            if undefined != pendingLoad
              pendingLoad.then((response) -> callback(response))

        if $scope.search? && true == $scope.search
          $scope.config['searchField'] = [$scope.valueProperty]

        if not $scope.multipleItems? || false == $scope.multipleItems
          $scope.config['maxItems'] = 1

        if not $scope.multipleItems? || false == $scope.multipleItems
          $scope.config['maxItems'] = 1

      templateUrl: require('views/directives/form-select.html')
      restrict: 'E'
      scope:
        collection: '='
        multipleItems: '='
        search: '='
        values: '='
        idProperty: '@'
        valueProperty: '@'
        help: '@'
        errors: '='
        field: '@'
        load: '&'
      controller: controller
      link: (scope, elem, attrs) ->
        # if autocompletion is based on API request, we have to set default values by hand (for edit view for ex)
        if ('undefined' != typeof attrs.load && 'undefined' != typeof attrs.values && 'undefined' != typeof scope.values)
          select = elem.find('selectize').get(0).selectize

          if (Array.isArray(scope.values))
            scope.values.forEach((item) ->
              select.addOption(item)
              select.addItem(item[scope.idProperty])
            )
  ]
