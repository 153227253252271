import { AbstractResource } from '../../../resources/abstract.resource';
import { Injectable } from '@angular/core';
import { Resource } from '@decorators/Resource';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  routeName: 'commercial-operation-product-marketplace-offers',
  entryPoint: '/v2/commercial_operation_product_marketplace_offers',
  translationKey: 'COMMERCIAL_OPERATION_PRODUCT_MARKETPLACE_OFFERS',
  buttonActionMultipleAvailable: false,
  deleteAvailable: true,
  archiveAvailable: false,
  isHydra: true,
})
export class CommercialOperationProductMarketplaceOffersResource extends AbstractResource {
  constructor(
    http: HttpClient,
  ) {
    super(http);
  }
}
