export default productRoutes;

/** @ngInject */
function productRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'walipush',
    sidebarSubNav: 'product',
    title: 'PAGE.PRODUCT.EDIT.TITLE',
    reloadChangeCountrySearchParam: 'id',
    reloadChangeCountry: 'product.edit',
    tabs: [
      {
        label: 'PAGE.PRODUCT.EDIT.TAB.GENERAL.TAB_LABEL',
        state: 'product.edit',
      },
      {
        label: 'PAGE.PRODUCT.EDIT.TAB.PURCHASE_SERVICE.TAB_LABEL',
        state: 'product.edit.purchase-service'
      },
      {
        label: 'PAGE.PRODUCT.EDIT.TAB.SAGE.TAB_LABEL',
        state: 'product.edit.sage'
      },
      {
        label: 'PAGE.PRODUCT.EDIT.TAB.CARRIERS.TAB_LABEL',
        state: 'product.edit.carriers'
      },
      {
        label: 'PAGE.PRODUCT.EDIT.TAB.CONTENTS.TAB_LABEL',
        state: 'product.edit.content'
      },
      {
        label: 'PAGE.PRODUCT.EDIT.TAB.PICTURES.TAB_LABEL',
        state: 'product.edit.pictures-mp'
      },
      {
        label: 'PAGE.PRODUCT.EDIT.TAB.LINKED_PRODUCTS.TAB_LABEL',
        state: 'product.edit.cross-up-sells'
      },
      {
        label: 'PAGE.PRODUCT.EDIT.TAB.VERSIONS.TAB_LABEL',
        state: 'product.edit.versions'
      }
    ],
  };

  $stateProvider
    .state('product_light', {
      url: '/product-light',
      parent: 'walipush',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.PRODUCT',
        stateName: 'product_new.list'
      }
    })
    .state('product_light.view', {
      url: '/:id',
      parent: 'product_light',
      breadcrumb: {
        label: 'SIDEBAR.LABEL.PRODUCT_INFOS'
      },
      views: {
        'page@layout': { template: '<app-product-light-page></app-product-light-page>' }
      },
      data: commonStateData,
    })
    .state('product_new', {
      url: '/products-new',
      parent: 'walipush',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.PRODUCT',
        stateName: 'product_new.list'
      },
    })
    .state('product_new.list', {
      url: `?
        page&
        byPage&
        orderBy&
        direction&
        catalog&
        superProduct&
        sku[]&
        marketplaces[]&
        stockMin&
        stockMax&
        status&
        hidden&
        reference&
        hasLogisitc&
        packageType&
        stockStatus&
        startArrivalDate&
        endArrivalDate&
        currentTab&
        mutualized&
        ean
      `,
      views: {
        'page@layout': {
          template: '<app-product-list></app-product-list>'
        }
      },
      data: Object.assign(commonStateData, {
        reloadChangeCountry: 'product_new.list',
        title: 'PAGE.PRODUCT.LIST.TITLE',
      })
    })
    .state('product.edit', {
      url: '/products/:id',
      parent: 'product_new.list',
      breadcrumb: { label: 'BREADCRUMB.EDIT', stateName: 'product.edit' },
      views: {
        'page@layout': { template: '<app-product-page></app-product-page>' }
      },
      data: commonStateData,
    })
    .state('product.edit.purchase-service', {
      url: '/purchase-service',
      breadcrumb: { label: 'BREADCRUMB.EDIT', stateName: 'product.edit.purchase-service' },
      views: {
        'page@layout': { template: '<app-product-page></app-product-page>' }
      },
      data: commonStateData,
    })
    .state('product.edit.sage', {
      url: '/sage',
      breadcrumb: { label: 'BREADCRUMB.EDIT', stateName: 'product.edit.sage' },
      views: {
        'page@layout': { template: '<app-product-page></app-product-page>' }
      },
      data: commonStateData,
    })
    .state('product.edit.carriers', {
      url: '/carriers',
      breadcrumb: { label: 'BREADCRUMB.EDIT', stateName: 'product.edit.carriers' },
      views: {
        'page@layout': { template: '<app-product-page></app-product-page>' }
      },
      data: commonStateData,
    })
    .state('product.edit.content', {
      url: '/content',
      breadcrumb: { label: 'BREADCRUMB.EDIT', stateName: 'product.edit.content' },
      views: {
        'page@layout': { template: '<app-product-page></app-product-page>' }
      },
      data: commonStateData,
    })
    .state('product.edit.pictures-mp', {
      url: '/pictures-mp',
      breadcrumb: { label: 'BREADCRUMB.EDIT', stateName: 'product.edit.pictures-mp' },
      views: {
        'page@layout': { template: '<app-product-page></app-product-page>' }
      },
      data: commonStateData,
    })
    .state('product.edit.cross-up-sells', {
      url: '/cross-up-sells',
      breadcrumb: { label: 'BREADCRUMB.EDIT', stateName: 'product.edit.cross-up-sells' },
      views: {
        'page@layout': { template: '<app-product-page></app-product-page>' }
      },
      data: commonStateData,
    })
    .state('product.edit.versions', {
      url: '/versions',
      breadcrumb: { label: 'Versions', stateName: 'product.edit.versions' }, // TODO : trad,
      views: {
        'page@layout': { template: '<app-product-page></app-product-page>' }
      },
      data: commonStateData,
    })
    .state('product.edit.marketplace', {
      url: '/:marketplaceCode',
      breadcrumb: { label: 'BREADCRUMB.SUPER_PRODUCT.PRODUCT_MARKETPLACE_GROUPING' },
      views: {
        'page@layout': { template: '<app-product-page></app-product-page>' }
      },
      data: commonStateData,
    })
    .state('product.import', {
      url: '/import/:id',
      parent: 'product_new.list',
      breadcrumb: { label: 'BREADCRUMB.IMPORT', stateName: 'product.import' },
      views: {
        'page@layout': { template: '<app-import-product></app-import-product>' }
      },
      data: commonStateData,
    })
    .state('product.pack_import', {
      url: '/pack/import/:id',
      parent: 'product_new.list',
      breadcrumb: { label: 'BREADCRUMB.IMPORT', stateName: 'product.import' },
      views: {
        'page@layout': { template: '<app-import-product></app-import-product>' }
      },
      data: commonStateData,
    })
    ;
}
