import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AbstractComponent } from '@components/generic/abstract.component';
import { COL_LONG, COL_MAX, COL_MEDIUM, COL_SHORT, SIZE_LONG, SIZE_MAX, SIZE_MEDIUM, SIZE_SHORT } from '@constants';
import { IFormFieldBase } from '@components/generic/Form/dynamic/interfaces';
import { IFormViolation } from '@interfaces';
import { AuthService, FormNotifierService } from '@services';
import { Subscription } from 'rxjs/Subscription';

/**
 * DynamicFormFieldComponent,
 * responsible for rendering the details of each individual field based on values in the data-bound field object.
 */
@Component({
  selector: 'app-dynamic-form-field',
  template: require('./dynamic-form-field.component.html'),
  styles: [require('./dynamic-form-field.component.scss')],
})
export class DynamicFormFieldComponent extends AbstractComponent implements OnInit, OnDestroy {
  @Input()
  field: IFormFieldBase<any>;

  @Input()
  form: FormGroup;

  @Input()
  public formResetting: boolean;

  public violations: IFormViolation[] = [];
  private subscription: Subscription;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private formNotifier: FormNotifierService,
  ) {
    super($translate, authService, null, state);
  }

  ngOnInit(): void {
    this.subscription = this.formNotifier.observable.subscribe((stream) => {
      if (stream.hasOwnProperty('reject')) {
        this.violations = stream.reject.violations
          .filter((violation: IFormViolation) => violation.propertyPath.split('.').pop() === this.field.fieldName);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Gets form group size style css classes.
   *
   * @param {string} size
   *
   * @returns {string}
   */
  private getFormGroupSizeStyle(size: string): string {
    switch (size) {
      case SIZE_SHORT:
        return COL_SHORT;
      case SIZE_MEDIUM:
        return COL_MEDIUM;
      case SIZE_LONG:
        return COL_LONG;
      case SIZE_MAX:
        return COL_MAX;
    }
  }

  /**
   * Fires when a new value is selected in a @class ComboSearchField or in a @class MultiSearchField.
   * Does nothing if no callback defined.
   */
  public valueChange(newValue: any): void {
    if (this.field.valueChangedAction) {
      this.field.valueChangedAction(newValue);
    }
  }

  public clearViolations(): void {
    if (this.violations.length) {
      this.violations = [];
    }
  }
}
