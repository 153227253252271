'use strict'

import { DATE_SHORT_FORMAT, DATE_SHORT_INTERNATIONAL_FORMAT } from '@constants'
import * as moment from 'moment'

angular
  .module 'ammsFrontendApp'
  .directive 'daterangepicker', [
    '$timeout',
    '$translate',
    ($timeout, $translate) ->
      replace: true
      restrict: 'E'
      templateUrl: require('views/directives/daterangepicker.html')
      scope: {
        startDate: '=',
        endDate: '=',
        id: '@'
      }
      link: (scope, element) ->
        picker = element.find('.picker')
        pickerOptions = {
          showWeekNumbers: 1,
          locale: {
            format: DATE_SHORT_FORMAT,
            separator: ' - ',
            applyLabel: "<i class=\"fa fa-check\"></i>#{ $translate.instant('BUTTON.APPLY') }",
            cancelLabel: "<i class=\"fa fa-undo\"></i>#{ $translate.instant('BUTTON.RESET') }",
            firstDay: 1,
          }
        }

        picker.daterangepicker(pickerOptions, (start, end) ->
          $timeout( ->
            scope.startDate = start.format(DATE_SHORT_INTERNATIONAL_FORMAT)
            scope.endDate = end.format(DATE_SHORT_INTERNATIONAL_FORMAT)
          )
        )

        picker.on('cancel.daterangepicker', ->
          picker.val('')
          $timeout( ->
            scope.startDate = null
            scope.endDate = null
          )
          scope.$apply()
        )

        refreshValue = ->
          if (scope.startDate && scope.endDate)
            picker.val(
              moment(scope.startDate).format(pickerOptions.locale.format) + \
              pickerOptions.locale.separator + \
              moment(scope.endDate).format(pickerOptions.locale.format)
            )
          else
            picker.val('')

        scope.$watch('startDate', refreshValue)
        scope.$watch('endDate', refreshValue)
        refreshValue()
  ]
