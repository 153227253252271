import { DASHBOARD, DASHBOARD_REVENUES, DASHBOARD_SALES_COMPARISON } from '@constants/route.constants';
import { DashboardResource } from '@components/dashboard/dashboard.resource';

export default dashboardRoutes;

/** @ngInject */
function dashboardRoutes($stateProvider: any) {
  const commonStateData: {[key: string]: any} = {
    sidebarNav: 'dashboard',
    title: '',
    reloadChangeCountry: DASHBOARD_REVENUES,
    resource: DashboardResource,
  };

  $stateProvider
    .state(DASHBOARD, {
      url: '/',
      parent: 'walisoft',
      abstract: true,
    })
    .state(DASHBOARD_REVENUES, {
      url: '',
      breadcrumb: {
        label: 'BREADCRUMB.DASHBOARD',
        stateName: DASHBOARD_REVENUES
      },
      views: {
        'page@layout': { template: '<app-dashboard>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.DASHBOARD.TITLE',
        tabs: [
          {
            label: 'PAGE.DASHBOARD.TABS.REVENUES',
            state: DASHBOARD_REVENUES,
          },
          {
            label: 'PAGE.DASHBOARD.TABS.SALES_COMPARISON',
            state: DASHBOARD_SALES_COMPARISON,
          },
        ],
      }),
    })
    .state(DASHBOARD_SALES_COMPARISON, {
      url: 'sales-comparison',
      breadcrumb: {
        label: 'BREADCRUMB.SALES_COMPARISON',
        stateName: DASHBOARD_SALES_COMPARISON
      },
      views: {
        'page@layout': { template: '<app-sales-comparison></app-sales-comparison>' }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.DASHBOARD.SALES_COMPARISON.TITLE',
        tabs: [
          {
            label: 'PAGE.DASHBOARD.TABS.REVENUES',
            state: DASHBOARD_REVENUES,
          },
          {
            label: 'PAGE.DASHBOARD.TABS.SALES_COMPARISON',
            state: DASHBOARD_SALES_COMPARISON,
          },
        ],
      }),
    })
  ;
}
