export default attributeFamilyRoutes;

/** @ngInject */
function attributeFamilyRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {
    title: '',
    reloadChangeCountry: 'attribute.list',
  };

  $stateProvider
    .state('attribute-family', {
      parent: 'walisoft',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.ATTRIBUTE.LIST',
        stateName: 'attribute.list'
      }
    })
    .state('attribute-family.edit', {
      url: '/attribute-families/:id/edit',
      breadcrumb: {
        label: 'BREADCRUMB.EDIT'
      },
      views: {
        'page@layout': {
          template: '<app-attribute-family-edit></app-attribute-family-edit>'
        }
      },
      data: Object.assign(commonStateData, {
        reloadChangeCountry: 'attribute-family.edit',
        reloadChangeCountrySearchParam: 'id',
        title: 'PAGE.ATTRIBUTE_FAMILY.EDIT.TITLE',
      })
    })
    ;
}
