import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AbstractResource, IRequestOptions } from '@resources';
import {ProformaInvoiceItemModel} from '@components/proforma-invoice/models/proforma-invoice-item.model';

@Injectable()
@Resource({
  routeName: 'proforma_invoice_items',
  entryPoint: '/v2/proforma_invoice_items',
  translationKey: 'PROFORMA_INVOICE_ITEMS',
  listModel: ProformaInvoiceItemModel,
  formModel: ProformaInvoiceItemModel,
  isHydra: true,
})
export class ProformaInvoiceItemResource extends AbstractResource {
  constructor(
    http: HttpClient,
  ) {
    super(http);
  }
}
