import { Component, Inject, OnInit } from '@angular/core';
import { AbstractResource } from '@resources/abstract.resource';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { OrderResource, OrderSupportedByResource } from '@resources';
import { AuthService } from '@services';
import { FormBuilder, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-sav-order-detail',
  template: require('./sav-order-detail.component.html'),
  styles: [require('./sav-order-detail.component.scss')],
  providers: [
    { provide: AbstractResource, useClass: OrderResource },
  ],
})
export class SavOrderDetailComponent extends AbstractPageComponent implements OnInit {

  public order: any;
  public form: FormGroup;
  public supportedBy: any[] = [];
  public fromOrder: any;
  public defaultSupportedBy: any;
  public totalReadOnly: boolean = false;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    private supportedByResource: OrderSupportedByResource
  ) {
    super($translate, authService, resource, state);

  }

  ngOnInit() {
    this.fetch(this.state.params.id);
    this.fetchSupportedBy();
    this.fetchFromOrder(this.state.params.fromOrderId);
  }

  private fetch(orderId: string): void {
    (<OrderResource>this.resource)
      .getSavOrder(orderId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        this.order = response;
        this.form = this.buildForm();
      })
    ;
  }

  private fetchFromOrder(fromOrderId: string) {
    (<OrderResource>this.resource)
      .getSavOrder(fromOrderId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        this.fromOrder = response;
      });
  }

  private fetchSupportedBy(): void {
    const that = this;
    (<OrderSupportedByResource>this.supportedByResource)
      .cGet(null, { returnHydraMembers: true })
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        this.supportedBy = response.map(function (supportedBy: any) {
          return {
            id: supportedBy['@id'],
            code: supportedBy.code,
            label: supportedBy.translations[that.currentLocale] ? supportedBy.translations[that.currentLocale].label : supportedBy['@id'],
          };
        });

        this.defaultSupportedBy = this.supportedBy.slice(-1)[0];
      })
    ;
  }

  private buildForm(): FormGroup {
    return this.formBuilder.group({
      comment: null,
      supportedBy: this.defaultSupportedBy,
      zendeskTicketId: null,
      total: this.order.forcedTotalPrice ? this.order.forcedTotalPrice : this.order.totalPrice + this.order.carryingCost,
    });
  }

  public updateTotalPrice(event: any): void {
    const CUSTOMER = 'customer';

    if (CUSTOMER === event.code) {
      this.totalReadOnly = false;
      this.form.get('total').setValue(this.order.totalPrice + this.order.carryingCost);
    } else {
      this.totalReadOnly = true;
      this.form.get('total').setValue(0);
    }
  }

  public putOrder(): void {
    const body: any = {
      supportedBy: this.form.value.supportedBy.id,
      zendeskTicketId: this.form.value.zendeskTicketId,
      forcedTotalPrice: this.form.value.total ? parseFloat(this.form.value.total) : null,
    };

    if (this.form.value.comment) {
      body.comments = [{
          message: this.form.value.comment
      }];
    }

    (<OrderResource>this.resource)
      .putSavOrder(this.state.params.id, body)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.state.go('order_manager.edit', { id: this.order.id });
      })
    ;
  }
}
