import { Component, Inject, Input, OnInit } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@services';
import { AbstractResource } from '@resources';
import { AbstractComponent } from '@components/generic/abstract.component';
import { OrderManagerItemResource } from '@components/order-manager/order-manager-item.resource';
import { LOCALE_GB } from '@constants';

@Component({
    selector: 'order-item-update-dialog',
    template: require('./order-item-update-dialog.component.html'),
    styles: [require('./order-item-dialog.component.scss')],

})
export class OrderItemUpdateDialogComponent extends AbstractComponent implements OnInit {

    @Input() orderId: string;
    @Input() marketplace: string;
    @Input() quantity: string;
    @Input() discount: string;
    @Input() unitPrice: string;
    @Input() orderItemId: string;

    public form: FormGroup;
    public dryRunResponse: any;
    public body: any;
    public orderLocale: any;
    public LOCALE_GB = LOCALE_GB;

    constructor(
        @Inject('TranslationService') $translate: ng.translate.ITranslateService,
        authService: AuthService,
        @Inject('StateService') state: ng.ui.IStateService,
        private orderManagerItemResouce: OrderManagerItemResource,
        resource: AbstractResource,
        private dialog: DialogRef,
        private formBuilder: FormBuilder) {
        super($translate, authService, resource, state);
    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            quantity: new FormControl(this.quantity || 1 , Validators.required),
            discount: new FormControl(this.discount || 0),
            orderItemId: new FormControl(this.orderItemId),
            unitPrice: new FormControl(this.unitPrice )
        });

        this.form.valueChanges.subscribe(x => {
            this.dryRunResponse = undefined;
            this.computeDryRun();
        });
    }

    computeDryRun() {
        if (this.form.get('quantity').value) {
            this.body = {
                quantity: +this.form.get('quantity').value,
                discount: parseFloat(this.form.get('discount').value.toString().replace(',', '.')),
            };

            if (this.form.get('unitPrice')) {
              this.body.unitPrice = parseFloat(this.form.get('unitPrice').value.toString().replace(',', '.'));
            }
        }
    }

    public onCancelAction(): void {
        this.dialog.close({ type: 'cancel' });
    }

  public onConfirmSaveAction(id: any): void {
    this.orderManagerItemResouce.update(id, this.body
    , { entryPoint: `/v2/order-manager/items-estimate/${id}` }).subscribe(
      () => {
        this.dialog.close({ type: 'submit' });
      }
    );
  }
}
