import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { HttpClient } from '@angular/common/http';
import { AbstractResource } from '@resources';
import { EavAttributeModel } from '@components/eav-attribute/models/eav-attribute.model';

@Injectable()
@Resource({
  routeName: 'eav-attribute',
  entryPoint: '/v2/eav_attributes',
  translationKey: 'EAV_ATTRIBUTE',
  cGetDefaultFilters: () => ({
    page: 1,
    byPage: 30
  }),
  listColumns: [
    { field: 'id', translationKey: 'ID', type: 'id' },
    { field: 'label', translationKey: 'LABEL', type: 'string', sortable: true },
    { field: 'type', translationKey: 'TYPE', type: 'string', sortable: true },
  ],
  listModel: EavAttributeModel,
  formModel: EavAttributeModel,
  createAvailable: true,
  deleteAvailable: false,
  isHydra: true,
  importOptions: [
    {
      entryPoint: '/api/v2/import',
      importButton: 'PAGE.EAV_ATTRIBUTE.LIST.FILTER.IMPORT.ATTRIBUTE_VALUE',
      businessObject: 'eav-attribute-value'
    },
  ]
})
export class EavAttributeResource extends AbstractResource {
  constructor(
    http: HttpClient,
  ) {
    super(http);
  }
}
