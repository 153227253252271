import * as _ from 'lodash';
import { IPackage, IProduct, IProductTranslation } from '@models';

export interface IProductFilters {
  byPage?: string;
  catalog?: any;
  currentTab?: string;
  direction?: any;
  hasEan?: any;
  hasLogistic?: any;
  packageType?: string;
  hidden?: string;
  marketplaces?: Array<any>;
  orderBy?: any;
  page?: string;
  reference?: any;
  sku?: string;
  stockMax?: any;
  stockMin?: any;
  superProduct?: any;
}

export class ProductHelper {
  /**
   * Removes filters used for interface (pagination, ...)
   *
   * @param {IProductFilters} filters
   * @returns {Array<string>}
   */
  static extractUIFilters(filters: IProductFilters = {}): Array<string> {
    return Object.keys(filters)
      .filter((filter) => {
          return ![
            'byPage',
            'currentTab',
            'direction',
            'orderBy'
          ].includes(filter);
        }
      );
  }

  /**
   * Checks if some filters are used or not.
   *
   * @param {IProductFilters} filters
   * @returns {boolean}
   */
  static hasNonEmptyFilters(filters: IProductFilters = {}): boolean {
    return !!ProductHelper.extractUIFilters(filters)
      .filter((filter: keyof IProductFilters) => {
        return undefined !== filters[filter] &&
          !(
            (_.isNumber(filters[filter]) && 0 === +filters[filter]) ||
            (_.isString(filters[filter]) && 0 === +filters[filter]) ||
            (_.isObject(filters[filter]) && _.isEmpty(filters[filter]))
          );
      }).length;
  }

  /**
   * Removes invalid fields before send to api.
   *
   * @param {IProduct} product
   * @returns {IProduct}
   */
  static purgeInvalidProperties(product: IProduct): IProduct {
    const productToPurge: IProduct = { ...product };

    const fieldsToDelete = [
      'allowedCarriers',
      'cmup',
      'country',
      'ecotax',
      'ean',
      'deee',
      'id',
      'm3',
      'marketplaceImages',
      'numberOfPackages',
      'packageTotalM3',
      'packageTotalWeight',
      'packages',
      'packagesInfo',
      'productMarketplaces',
      'quantityInStock',
      'sageName',
      'shippingCost',
      'sku',
      'masterProduct',
      'crossSellingProducts',
      'originalCrossSellingProducts',
      'upSellingProducts',
      'originalUpSellingProducts',
      'translations',
      'manufacturer',
      'notifyReviewers',
      'superProduct',
      'hidden',
    ];

    fieldsToDelete.forEach((field: keyof IProduct) => delete productToPurge[field]);

    return productToPurge;
  }

  /**
   * Formats packages.
   *
   * @param {IPackage} pkg
   * @returns {object} The package formatted
   */
  static packageFormatted(pkg: IPackage): IPackage {
    const productPackage: IPackage = { ...pkg };

    Object.keys(productPackage).forEach((property: string) => {
      if (
        ['height', 'length', 'weight', 'width'].includes('' + property) &&
        'undefined' !== typeof productPackage[property] &&
        null !== productPackage[property]
      ) {
        productPackage[property] = parseFloat(productPackage[property].toFixed(2));
      }
    });

    return productPackage;
  }
}
