import { AbstractResource } from '../resources/abstract.resource';
import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  entryPoint: '/sage/export'
})
export class SageResource extends AbstractResource {

  constructor(http: HttpClient) {
    super(http);
  }
  public createIntrastatExport(countryCode: string): Observable<Object> {
    return this.exportFile(
      {},
      {
        type: 'application/csv',
        entryPoint: `/v2/export`,
        filename: 'export_intrastat_' + countryCode + '.csv',
        postOptions: {
          async: false,
          deferred: false,
          exportCode: 'intrastat_export',
          formatCode: 'csv',
          limit: countryCode,
          dryRun: false,
          serializationGroups: [],
          split: false
        }
      }
    );
  }
}
