import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LitigationListComponent } from './list/litigation-list.component';
import {GenericFormModule, GenericModule} from '@components/generic';
import { LitigationResultListComponent } from './list/litigation-result-list.component';
import { LitigationStatusResource } from '@components/order-manager/resources/litigation-status.resource';
import { LitigationTypeResource } from '@components/order-manager/resources/litigation-type.resource';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {BsDatepickerModule, BsDropdownModule} from 'ngx-bootstrap';

@NgModule({
  declarations: [LitigationListComponent, LitigationResultListComponent],
  entryComponents: [LitigationListComponent, LitigationResultListComponent],
  providers: [LitigationStatusResource, LitigationTypeResource],
  imports: [
    CommonModule,
    GenericModule,
    ReactiveFormsModule,
    BsDropdownModule,
    FormsModule,
    GenericFormModule,
    BsDatepickerModule
  ]
})
export class LitigationModule { }
