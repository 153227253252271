import { Component, Inject, Input, OnInit } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AbstractResource } from '@resources/abstract.resource';
import { AuthService } from '@services/auth.service';
import { CategoriesMarketplaceResource } from '@components/categories-marketplace';
import { IMarketplace } from '@interfaces/marketplace.interface';
import { SessionHelper } from '@helpers/session.helper';
import { CategoryTreeHelper } from '@helpers/CategoryTreeHelper';
import {
  IAttributeMarketplace,
  IAttributeMarketplaceValue,
  ICategoryMarketplace,
  ICategoryMarketplaceFlatten,
  IProductMarketplaceAttributeFormBody,
  IProductMarketplaceAttributeFormValue,
  IProductMarketplaceForm
} from '@components/product/interfaces/product-marketplace-form.interface';
import { AttributeMarketplaceResource } from '@resources/attribute-marketplace.resource';
import { SnackbarService } from '@components/snackbar';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProductMarketplaceAttributeFieldHelper } from '@helpers/ProductMarketplaceAttributeFieldHelper';
import { FormNotifierService } from '@services/form-notifier.service';

@Component({
  selector: 'app-product-marketplace-attributes-form',
  template: require('./product-marketplace-attributes-form.component.html'),
})
/**
 * @deprecated
 * @todo Remove
 * @todo Remove product-marketplace-attributes-form.component.html
 * @todo Remove app-product-marketplace-attributes-form selector in product-marketplace-forms.component.html
 */
export class ProductMarketplaceAttributesFormComponent extends AbstractComponent implements OnInit {

  public categories: ICategoryMarketplaceFlatten[];
  public categoriesData: ICategoryMarketplaceFlatten[];
  public attributes: IAttributeMarketplace[] = [];
  public extraAttributes: IAttributeMarketplace[] = [];
  public form: FormGroup;

  @Input() private marketplace: IMarketplace;
  @Input() private productMarketplace: IProductMarketplaceForm;
  @Input() sparePartContext: boolean = false;

  get attributesFA(): FormArray {
    return this.form.get('attributeMarketplaceValues') as FormArray;
  }

  get extraAttributesFA(): FormArray {
    return this.form.get('extraAttributeMarketplaceValues') as FormArray;
  }

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    resource: AbstractResource,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private categoriesMarketplaceResource: CategoriesMarketplaceResource,
    private attributeMarketplaceResource: AttributeMarketplaceResource,
    private snackbar: SnackbarService,
    private formBuilder: FormBuilder,
    @Inject('DialogService') private dialog: any,
    private formNotifier: FormNotifierService,
  ) {
    super($translate, authService, resource, state);
  }

  /**
   * @inheritDoc
   */
  ngOnInit(): void {
    this.buildForm();

    this.categoriesMarketplaceResource.cGet({
      marketplace: this.marketplace.code,
      country: SessionHelper.getCountry().code,
      active: 1
    }, { dontUseModel: true })
      .takeUntil(this.destroyed$)
      .subscribe((response: { page: number, total: number, categories: ICategoryMarketplace[] }) => {
        this.categories = CategoryTreeHelper.getFlattenTree(response.categories)
          .map((category: ICategoryMarketplace) => {
            return {
              id: category.id,
              label: category.label,
              leaf: category.leaf,
            };
          })
        ;
        this.categoriesData = this.categories.slice();

        if (this.productMarketplace.categoryMarketplace && this.productMarketplace.categoryMarketplace.id) {
          this.refreshAttributes(this.productMarketplace.categoryMarketplace.id);
        }
      })
    ;

    this.attributeMarketplaceResource.cGet({
      marketplace: this.marketplace.code,
      extraAttribute: 1,
    })
      .takeUntil(this.destroyed$)
      .subscribe((response: IAttributeMarketplace[]) => {
        this.extraAttributes = this.decodeAttributesResponse(response);
        this.buildFormArray(this.extraAttributes, true);
      })
    ;
  }

  /**
   * Refresh attributes list regarding the selected category.
   */
  private refreshAttributes(categoryMarketplaceId: any): void {
    const category: ICategoryMarketplaceFlatten = this.categories.find(
      (item: ICategoryMarketplaceFlatten) => +categoryMarketplaceId === +item.id
    );

    if (!category) {
      return;
    }

    if (!category.leaf) {
      this.snackbar.warn(this.translate('PAGE.PRODUCT.EDIT.TAB.MARKETPLACES.MESSAGES.WRONG_CATEGORY'));

      return;
    }

    // gets new attributes
    this.attributeMarketplaceResource.cGet({
        category: categoryMarketplaceId,
        marketplace: this.marketplace.code,
        extraAttribute: 0,
      }, { dontUseModel: true })
      .takeUntil(this.destroyed$)
      .subscribe((response: IAttributeMarketplace[]) => {
        this.attributes = this.decodeAttributesResponse(response);
        this.buildFormArray(this.attributes);
      })
    ;
  }

  /**
   * Decodes response to keep only mandatory properties well formatted
   */
  private decodeAttributesResponse(attributesMarketplaces: IAttributeMarketplace[]): IAttributeMarketplace[] {
    return attributesMarketplaces.map((attribute: IAttributeMarketplace) => {
      return {
        code: attribute.code,
        deprecated: attribute.deprecated,
        id: attribute.id,
        label: attribute.label,
        mandatory: attribute.mandatory,
        values: attribute.values,
        valueType: attribute.valueType,
      };
    });
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      categoryMarketplace: [
        this.productMarketplace.categoryMarketplace ? this.productMarketplace.categoryMarketplace : { id: '', label: ''}
      ],
      attributeMarketplaceValues: this.formBuilder.array([]),
      extraAttributeMarketplaceValues: this.formBuilder.array([]),
    });
  }

  private buildFormArray(attributes: IAttributeMarketplace[], isExtraAttributes?: boolean): void {
    const formGroups: FormGroup[] = [];

    attributes.forEach((attribute: IAttributeMarketplace, loopIndex: number) => {
      const attributeBindToPM = this.productMarketplace.attributeMarketplaceValues.find(
        (attributeValue: IAttributeMarketplaceValue) => attribute.id === attributeValue.attributeMarketplace.id)
      ;

      // build several form group for each attribute
      formGroups.push(this.formBuilder.group({
        [attribute.id]: ['', attribute.mandatory ? Validators.required : null],
      }));

      // have to format attribute values type for select field
      if (ProductMarketplaceAttributeFieldHelper.isSelect(attribute.valueType)) {
        attribute.values = Object.entries(attribute.values).map((value: any[]) => {
          return {
            label: value[1],
            value: value[0]
          };
        });

        // add empty value for attribute values if type select
        attribute.values.unshift({ label: '', value: ''});
      }

      if (attributeBindToPM) {
        formGroups[loopIndex].patchValue({[attribute.id]: attributeBindToPM.value});
      }
    });

    // pass the form groups to a form array
    const formArray = this.formBuilder.array(formGroups);

    // replace existing control with a new one, we pass it the form array that is a form group collection
    this.form.setControl(isExtraAttributes ? 'extraAttributeMarketplaceValues' : 'attributeMarketplaceValues', formArray);
  }

  public submit(event: any): void {
    this.dialog.confirm(this.translate('PAGE.PRODUCT.CONFIRM.UPDATE'))
      .then(() => {
        const body: IProductMarketplaceAttributeFormBody = this.prepareBody();

        this.resource.update(undefined, body, { entryPoint: `/v2/product_marketplaces/${this.productMarketplace.id}` })
          .takeUntil(this.destroyed$)
          .subscribe(() => {
            this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));

            if (event && event.redirect) {
              this.state.go(this.sparePartContext ? 'spare-part.list' : 'product_new.list');

              return;
            }

            this.formNotifier.notifyFormSubmitted();
            this.state.go(
              this.sparePartContext ? 'spare-part.edit.marketplace' : 'product.edit.marketplace',
              { id: this.state.params.id, marketplaceCode: this.marketplace.code }
              );
          })
        ;
      })
    ;
  }

  private prepareBody(): IProductMarketplaceAttributeFormBody {
    const formValue: IProductMarketplaceAttributeFormValue = this.form.value;

    const attributeMarketplaceValues: { attributeMarketplace: string, value: string }[] = [];
    ['attributeMarketplaceValues', 'extraAttributeMarketplaceValues'].forEach((item: string) => {
      formValue[item].forEach((attributeMarketplaceValue: any) => {
        Object.keys(attributeMarketplaceValue).forEach((attributeMarketplaceId: string) => {
          if (attributeMarketplaceValue[attributeMarketplaceId]) {
            attributeMarketplaceValues.push({
              attributeMarketplace: `/api/v2/attribute_marketplaces/${attributeMarketplaceId}`,
              value: attributeMarketplaceValue[attributeMarketplaceId],
            });
          }
        });
      });
    });

    return {
      categoryMarketplace: `/api/v2/category_marketplaces/${formValue.categoryMarketplace.id}`,
      attributeMarketplaceValues,
    };
  }

  /**
   * Filter category in the dropdown category field.
   */
  public handleCategoriesFilter(event: string): void {
    this.categoriesData = this.categories.filter((item: ICategoryMarketplaceFlatten) => {
      return item.label.toLowerCase().indexOf(event.toLowerCase()) !== -1;
    });
  }
}
