import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup , Validators } from '@angular/forms';
import { ICountry, ISku } from '@interfaces';
import { AbstractComponent } from '@components/generic/abstract.component';
import { SessionHelper } from '@helpers';
import { AuthService } from '@services';
import { CarrierGroupResource } from '@resources';
import { DialogBoxService } from '@services/dialog-box.service';
import { IPackage, IProduct } from '@models';
import { ProductResource } from '../../../../components/product/product.resource';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { FormService } from '@services/form.service';

export interface IByExistingSkuData {
  products: number[];
  packages: string[];
  postcodes: string[];
}

/**
 * Selects a sku in an autocomplete field.
 * User can attach postal codes to form.
 *
 * Emits an event when submitting the form to CarrierCalculatorComponent.
 */
@Component({
  selector: 'app-by-existing-sku-form',
  template: require('./by-existing-sku-form.component.html'),
  providers: [FormService]
})
export class ByExistingSkuFormComponent extends AbstractComponent {

  public byExistingSkuForm: FormGroup;
  public skuErrors: string;
  public currentCountry: ICountry = SessionHelper.getCountry();

  @Output() public onSubmit: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private fb: FormBuilder,
    private carrierGroupResource: CarrierGroupResource,
    private productResource: ProductResource,
    private dialogBoxService: DialogBoxService,
  ) {
    super($translate, authService, null, state);

    this.createByExistingSkuForm();
  }

  async compute() {
    this.skuErrors = '';
    const observables = [];
    for (const control of (<FormArray>this.byExistingSkuForm.get('skus')).controls) {
      observables.push(this.productResource.get('' + control.get('sku').value.id, { dontUseModel: true }));
    }
    const packages: string[] = [];
    const products: number[] = [];

    forkJoin(observables).subscribe(
      (results: IProduct[]) => {
        (<FormArray>this.byExistingSkuForm.get('skus')).controls.forEach((control, index) => {
          const quantity = control.get('quantity').value;
          let packagesSku: string[] = [];
          packagesSku.push(...results[index].packages.map((pkg: IPackage) => {
            return `/api/v2/packages/${pkg.id}`;
          }));

          packagesSku = packagesSku.reduce((res, current) => {
            return res.concat(Array(quantity).fill(current));
          }, []);
          packages.push(...packagesSku);
          products.push(control.get('sku').value);
        });
        this.getEstimations({products: products, packages, postcodes: this.byExistingSkuForm.get('postcodes').value});
      },
      () => {
        this.skuErrors = this.translate('ALERTS.WRONG_SKU');
      }
    );
  }

  private createByExistingSkuForm(): void {
    this.byExistingSkuForm = this.fb.group({
      skus: new FormArray([]),
      postcodes: [[]],
    });

    this.addSku();
  }

  private getEstimations({products, packages, postcodes}: IByExistingSkuData): void {
    this.onSubmit.emit({
      products,
      packages,
      postcodes
    });
  }

  public addSku(): void {
    (<FormArray> this.byExistingSkuForm.get('skus')).push(new FormGroup({
      sku: new FormControl(null, Validators.required),
      quantity: new FormControl(null, Validators.required),
    }));
  }

  public removeSku(index: number): void {
    (<FormArray>this.byExistingSkuForm.get('skus')).removeAt(index);
    this.byExistingSkuForm.get('skus').markAsDirty();
  }

}
