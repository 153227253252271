import { AbstractResource } from '../../../resources/abstract.resource';
import { Injectable } from '@angular/core';
import { FormService } from '@components/commercial-operations/form/form.service';
import { CommercialOperationsModelList } from '@components/commercial-operations/models/commercial-operations-list.model';
import { Observable } from 'rxjs/Observable';
import { Resource } from '@decorators/Resource';
import { HttpClient } from '@angular/common/http';
import { BASE_URL_API } from '@constants/config.constants';
import { CommercialOperationsModel } from '@components/commercial-operations/models';

@Injectable()
@Resource({
  routeName: 'commercial-operations',
  entryPoint: '/v2/commercial_operations',
  translationKey: 'COMMERCIAL_OPERATIONS',
  cGetDefaultFilters: () => ({
    page: 1,
    byPage: 30,
  }),
  model: CommercialOperationsModelList,
  formService: FormService,
  listColumns: [
    { field: 'id', translationKey: 'ID', type: 'id' },
    { field: 'title', translationKey: 'TITLE', type: 'string', sortable: true, sortableName: 'translations.title' },
    { field: 'countryName', translationKey: 'COUNTRY', type: 'string', sortable: true, sortableName: 'country' },
    { field: 'marketplace', translationKey: 'MARKETPLACE', type: 'string', sortable: true },
    { field: 'type', translationKey: 'TYPE', type: 'string', sortable: true },
    { field: 'startDate', translationKey: 'START_DATE', type: 'string', sortable: true },
    { field: 'endDate', translationKey: 'END_DATE', type: 'string', sortable: true },
    { field: 'productMarketplaceCount', translationKey: 'NUMBER_OF_PRODUCTS', type: 'number', sortable: true },
  ],
  buttonActionMultipleAvailable: true,
  deleteAvailable: true,
  archiveAvailable: true,
  isHydra: true
})
export class CommercialOperationsResource extends AbstractResource {

  protected nullableProperties: string[] = ['defaultDirection', 'startDate', 'endDate'];

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  public getFileEndpoint(id: string): string {
    return `${BASE_URL_API}/v2/import`;
  }

  public cGet(filters: any = {}, options: any = {}): Observable<object> {
    const params = {...filters};

    params['dateRange[begin]'] = params.startDate;
    params['dateRange[end]'] = params.endDate;
    params['order[id]'] = params.sort_id;
    params['order[marketplace]'] = params.sort_marketplace;
    params['order[translations.title]'] = params['sort_translations.title'];
    params['order[archived]'] = params['archived'];
    params['order[country]'] = params.sort_country;
    params['order[type]'] = params.sort_type;
    params['order[productMarketplaceCount]'] = params.sort_productMarketplaceCount;
    params['order[startDate]'] = params.sort_startDate;
    params['order[endDate]'] = params.sort_endDate;
    params['status'] = params.status;

    delete params.startDate;
    delete params.endDate;
    delete params.date;

    return super.cGet(params, options);
  }

  public putSeo(commercialOperationId: string, body: any, params?: any): Observable<any> {
    return this.update('', body, { entryPoint: `/v2/commercial_operations/${commercialOperationId}/seo` });
  }

  public getProducts(commercialOperationId: string, filters: any = {}): Observable<any> {
    return this.cGet({ pagination: false, 'order[position]': 'ASC', ...filters }, {
      entryPoint: `/v2/commercial_operations/${commercialOperationId}/commercial_operation_product_marketplace_offers`,
      dontUseModel: true,
      returnHydraMembers: true
    });
  }

  public getProductsElastic(filters: any = {}): Observable<any> {
    return this.cGet({ pagination: false, 'order[position]': 'ASC', ...filters }, {
      entryPoint: `/v2/commercial_operation_marketplace_offer/elastic`,
      dontUseModel: true,
      returnHydraMembers: true
    });
  }

  public updateProductPosition(id: string, body: object, options: any = {}): Observable<object> {
    return this.update(id, body, {
      entryPoint: `/v2/commercial_operation_product_marketplace_offers/${id}`,
      dontUseModel: true
    });
  }
  public deleteProducts(id: string): Observable<object> {
    return this.remove(id, {
      entryPoint: `/v2/commercial_operation_product_marketplace_offers/${id}`,
      dontUseModel: true
    });
  }

  public getAssociatedGoodDealsCommercialOperations(commercialOperationId: string): Observable<any> {
    return this.cGet({ pagination: false, associated_good_deals: commercialOperationId }, {
      entryPoint: '/v2/commercial_operations',
      model: CommercialOperationsModel,
      returnHydraMembers: true
    });
  }

  public getAvailableGoodDealsCommercialOperations(commercialOperationId: string): Observable<any> {
    return this.cGet({ pagination: false, available_good_deals: commercialOperationId }, {
      entryPoint: '/v2/commercial_operations',
      model: CommercialOperationsModel,
      returnHydraMembers: true
    });
  }
}
