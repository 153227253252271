import { Component, Inject, OnInit, ViewChild, OnChanges, Input } from '@angular/core';
import { ColorHelper, SessionHelper } from '@helpers';
import { AuthService } from '@services';
import { TabManagementComponent } from '@components/generic/tab-management';
import { TabAdditionalFormComponent } from '@components/tab/tab-additional-form/tab-additional-form.component';
import { ITab, ITabTranslation, TabModel } from '@components/tab/models';
import { TabResource } from '@components/tab';
import * as moment from 'moment';
import { DATE_FULL_FORMAT, DATE_SHORT_FORMAT } from '@constants';
import { FormGroup } from '@angular/forms';
import { IFormViolation } from '@interfaces';
import { SnackbarService } from '@components/snackbar';
import { TabImagesResource } from '@components/tab/tab-images.resource';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { Observable } from 'rxjs/Observable';
import { TabFileUploadService } from '@components/tab/services/tab-file-upload.service';

@Component({
  selector: 'app-tab',
  template: require('./tab.component.html'),
  styles: [require('./tab.component.scss')],
  providers: [TabFileUploadService],
})
export class TabComponent extends AbstractPageComponent implements OnInit {

  public model: TabModel;
  public inCreation: boolean;
  public violations: IFormViolation[]|any = [];

  @ViewChild(TabManagementComponent) tabManagementComponent: TabManagementComponent;
  @ViewChild(TabAdditionalFormComponent) tabAdditionalFormComponent: TabAdditionalFormComponent;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: TabResource,
    @Inject('StateService') state: ng.ui.IStateService,
    @Inject('DialogService') private dialog: any,
    private tabImagesResource: TabImagesResource,
    private snackbar: SnackbarService,
    private tabFileUploadService: TabFileUploadService,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    this.inCreation = !this.state.params.id;

    if (!this.inCreation) {
      this.resource.get(this.state.params.id, { model: TabModel })
        .takeUntil(this.destroyed$)
        .subscribe((response: TabModel) => this.model = response)
      ;

      return;
    }
    this.model = new TabModel();
  }

  private isDirty(): boolean {
    return this.tabManagementComponent.form.dirty || this.tabAdditionalFormComponent.form.dirty;
  }

  private isValid(): boolean {
    return this.tabManagementComponent.form.valid && this.tabAdditionalFormComponent.form.valid;
  }

  public submit(returnToList: boolean) {
    const dirty = this.isDirty();
    const valid = this.isValid();

    while (this.violations.length) { this.violations.pop(); }

    if (!dirty || !valid) {
      let errorMessage = 'ALERTS.NO_CHANGE.FORM';

      if (!valid) {
        errorMessage = 'ALERTS.ERROR.FORM';
      }

      this.snackbar.warn(this.translate(errorMessage));
      return;
    }

    const body = this.prepareQuery(this.tabManagementComponent.form, this.tabAdditionalFormComponent.form);
    this.save(body, returnToList);
  }

  private formatViolations(reject: any): IFormViolation[] {
    if (!reject.hasOwnProperty('violations')) {
      return;
    }

    return reject.violations.map((violation: IFormViolation): IFormViolation => {
      return {
        propertyPath: violation.propertyPath,
        message: violation.message
      };
    });
  }

  public save(body: any, returnToList: boolean) {
    const data: any = body;
    SessionHelper.get('APPLICATION_LOCALES').forEach((locale: string) => {
      if (data.translations[locale] && data.translations[locale].id) {
        data.translations[locale].id = `/api/v2/tab_translations/${data.translations[locale].id}`;
      }
    });

    data.startDate = moment(data.startDate, DATE_FULL_FORMAT).isValid()
      ? moment(data.startDate, DATE_FULL_FORMAT).seconds(0).format(DATE_FULL_FORMAT)
      : null;
    data.endDate = moment(data.endDate, DATE_FULL_FORMAT).isValid()
      ? moment(data.endDate, DATE_FULL_FORMAT).seconds(59).format(DATE_FULL_FORMAT)
      : null;

    const observable$ = this.inCreation ?
      this.resource.create(data) :
      this.resource.update(this.state.params.id, data, { model: TabModel })
    ;

    observable$
      .takeUntil(this.destroyed$)
      .subscribe(
        (response: any) => {
          const translations: ITabTranslation[] = this.tabManagementComponent.form.value.translations;
          const formDatas$: Observable<object>[] = this.tabFileUploadService.getFormData$(translations, response);

          if (0 < formDatas$.length) {
            Observable.onErrorResumeNext(formDatas$)
              .takeUntil(this.destroyed$)
              .subscribe(
                () => this.snackbar.validate('UPLOADER.FILE.UPLOADED'),
                undefined,
                () => {
                  returnToList
                    ? this.actions.list.go()
                    : this.actions.update.go({ id: response['@id'].split('/').pop() }, { reload: true })
                  ;
                })
            ;

            return;
          }

          returnToList
            ? this.actions.list.go()
            : this.actions.update.go({ id: response['@id'].split('/').pop() }, { reload: true })
          ;
        },
        (reject: any) => {
          this.violations = this.formatViolations(reject);
        }
      )
    ;
  }

  public prepareQuery(managementForm: FormGroup, additionalForm: FormGroup): ITab {
    return {
      parent: managementForm.value.parent && managementForm.value.parent.id ? managementForm.value.parent.id : null,
      position: managementForm.value.position,
      marketplace: SessionHelper.getMarketplaces().find((item) => item.code.includes('site')).code,
      startDate: managementForm.value.startDate && managementForm.value.startTime ?
      moment(managementForm.value.startDate, DATE_SHORT_FORMAT).startOf('day')
        .add(managementForm.value.startTime.getHours(), 'hours')
        .add(managementForm.value.startTime.getMinutes(), 'minutes')
        .add(managementForm.value.startTime.getSeconds(), 'seconds')
        .format(DATE_FULL_FORMAT) :
      managementForm.value.startDate ?
        moment(managementForm.value.startDate, DATE_FULL_FORMAT).startOf('day').format(DATE_FULL_FORMAT) :
        null,
    endDate: managementForm.value.endDate && managementForm.value.endTime ?
      moment(managementForm.value.endDate, DATE_SHORT_FORMAT).startOf('day')
        .add(managementForm.value.endTime.getHours(), 'hours')
        .add(managementForm.value.endTime.getMinutes(), 'minutes')
        .add(managementForm.value.endTime.getSeconds(), 'seconds')
        .format(DATE_FULL_FORMAT) :
      managementForm.value.endDate ?
        moment(managementForm.value.endDate, DATE_FULL_FORMAT).endOf('day').format(DATE_FULL_FORMAT) :
        null,
      active: managementForm.value.active,
      obfuscated: managementForm.value.obfuscated,
      hiddenMenu: managementForm.value.hiddenMenu,
      commercialOperation: additionalForm.value.commercialOperation ? additionalForm.value.commercialOperation : null,
      contentBlock: additionalForm.value.contentBlock ? additionalForm.value.contentBlock : null,
      category: additionalForm.value.category ? additionalForm.value.category : null,
      room: additionalForm.value.room ? additionalForm.value.room : null,
      fontColor: managementForm.value.fontColor ? ColorHelper.convertHex8ToRgba(managementForm.value.fontColor) : '',
      backgroundColor: managementForm.value.backgroundColor
        ? ColorHelper.convertHex8ToRgba(managementForm.value.backgroundColor)
        : '',
      translations: this.getPreparedTranslations(managementForm.value.translations),
      customerTypes: managementForm.value.customerTypes || [],
      type: this.getType(managementForm.value.type),
    };
  }

  private getPreparedTranslations(translations: ITabTranslation): ITabTranslation[] {
    return translations.reduce(
      (current: ITabTranslation, {name, locale, id, publicLink, url}: ITabTranslation) => {
        current[locale] = {
          name,
          locale,
          id,
          publicLink,
          url
        };
        return current;
      },
      {}
    );
  }

  public getType(type: string): string {
    switch (type) {
      case this.translate('PAGE.TAB.FORM.CATEGORY'):
        return 'universe';
      case this.translate('PAGE.TAB.FORM.COMMERCIAL_OPERATIONS'):
        return 'commercial-operation';
      case this.translate('PAGE.TAB.FORM.ROOM'):
        return 'room';
      case this.translate('PAGE.TAB.FORM.CMS'):
          return 'cms';
      case this.translate('PAGE.TAB.FORM.SPECIFIC_URL'):
        return 'url';
      case this.translate('PAGE.TAB.FORM.COLLAPSE'):
      default:
        return 'collapse';
    }
  }

  /**
   * Cancels form and redirect to list.
   */
  public cancel() {
    this.dialog.confirm(this.translate('PAGE.TAB.CONFIRM.BACK_TO_LIST'))
      .then(() => this.actions.list.go())
    ;
  }
}
