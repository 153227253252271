'use strict'

###
  @name copyButton

  @description
  Display copy button
  Use directive only matches element tag.

  @param {string} id
  @param {object} value

  @example
    <example>
      <span id="id"
            copy-button
            value="value"></span>
    </example>

  @author Yann Prou <yann@les-tilleuls.coop>
###
angular
  .module('ammsFrontendApp')
  .directive('copyButton', [
    ->
      restrict: 'A'
      scope:
        id: '@'
        value: '='
      link: (scope, elem) ->
        scope.$watch('value', (newValue) ->
          if (newValue? && !elem.next().hasClass('btn-copy'))
            icon = angular.element('<i></i>')
              .addClass('fa fa-clipboard')

            button = angular.element('<button></button>')
              .attr('data-clipboard-target', '#' + scope.id)
              .addClass('btn btn-default btn-xs btn-right btn-copy')
              .append(icon)

            clipboard = new Clipboard(button[0])
            elem.parent()
              .append(button)
        )
  ])
