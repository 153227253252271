import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { CarrierCapacityResource } from '@resources/carrier-capacity.resource';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { Moment } from 'moment';
import { SessionHelper } from '@helpers';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IWarehouses } from '@components/warehouses/models';
import { AbstractResource } from '@resources';
import { CarrierScaleResource } from '@resources/carrier-scale.resource';

@Component({
  selector: 'app-carrier-capacity',
  template: require('./carrier-capacity.component.html'),
  providers: [
    { provide: AbstractResource, useClass: CarrierCapacityResource },
  ],
})
export class CarrierCapacityComponent extends AbstractPageComponent implements OnInit {
  public learnMore: boolean = false;
  public year: number = +moment().format('YYYY');
  public week: number = +moment().format('W');
  public warehouse: any;
  public warehouses: any[] = SessionHelper.getAllWarehouses();
  public warehouseForm: FormGroup;
  public days: Moment[] = [];
  public dayOfWeekCapacities: {[key: string]: any[]};
  public dateCapacities: {[key: string]: any[]};
  public capacities: {[key: string]: any[]};
  public scales: {[key: string]: any[]};

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    private carrierCapacityResource: CarrierCapacityResource,
    private carrierScaleResource: CarrierScaleResource,
  ) {
    super($translate, authService, null, state);
  }

  ngOnInit(): void {
    this.setDays();
    this.buildWarehouseForm();
    this.setWarehouse('lsl');
  }

  public warehouseChange(): void {
    this.warehouse = this.warehouses.find((item: IWarehouses): boolean => {
      return item['@id'] === this.warehouseForm.value.warehouse;
    });

    this.fetchCapacities();
    this.fetchScales();
  }

  public buildWarehouseForm(): void {
    this.warehouseForm = this.formBuilder.group({
      warehouse: [null],
    });
  }

  public setWarehouse(code: string): void {
    this.warehouseForm.get('warehouse').setValue(this.warehouses.find((warehouse: IWarehouses): boolean => {
      return warehouse.code === code;
    })['@id']);

    this.warehouseChange();
  }

  public setDays(): void {
    const firstDay: Moment = moment().day('Monday').year(this.year).week(this.week);
    this.days = [firstDay];

    for (let i: number = 1; i < 5; ++i) {
      this.days.push(firstDay.clone().add(i, 'days'));
    }
  }

  public fetchCapacities(): void {
    if (!this.warehouse) {
      return;
    }

    this.dayOfWeekCapacities = null;
    this.dateCapacities = null;
    this.capacities = null;

    const body: any = {
      'warehouseCarriers.warehouse.code': this.warehouse.code,
      year: this.year,
      week: this.week
    };

    this.carrierCapacityResource.cGet(body, { isHydra: true, returnHydraMembers: true })
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any[]): void => {
        const capacities: any = [];
        const dayOfWeekCapacities: any = [];
        const dateCapacities: any = [];

        const capacitiesWithDate: any[] = response.filter((item): boolean => {
          return null !== item.date;
        });

        const capacitiesWithDay: any[] = response.filter((item): boolean => {
          return null !== item.day;
        });

        this.days.forEach((day: Moment): void => {
          dayOfWeekCapacities[day.format('YYYY-MM-DD')] = capacitiesWithDay.filter((item: any): boolean => {
            return item.dayOfWeek === +day.isoWeekday() - 1;
          });

          dateCapacities[day.format('YYYY-MM-DD')] = capacitiesWithDate.filter((item: any): boolean => {
            return moment(item.date).format('YYYY-MM-DD') === day.format('YYYY-MM-DD');
          });

          capacities[day.format('YYYY-MM-DD')] = [...dateCapacities[day.format('YYYY-MM-DD')]];

          dayOfWeekCapacities[day.format('YYYY-MM-DD')].forEach((dayOfWeekCapacity: any): void => {
            const similarCapacity: any = dateCapacities[day.format('YYYY-MM-DD')].find((dateCapacity: any): boolean => {
              return this.compare(dayOfWeekCapacity, dateCapacity);
            });

            if (!similarCapacity) {
              capacities[day.format('YYYY-MM-DD')].push(dayOfWeekCapacity);
            }
          });
        });

        this.capacities = capacities;
        this.dayOfWeekCapacities = dayOfWeekCapacities;
        this.dateCapacities = dateCapacities;
      })
    ;
  }

  private compare(dayOfWeekCapacity: any, dateCapacity: any): boolean {
    if (dayOfWeekCapacity.dayOfWeek !== +moment(dateCapacity.date).isoWeekday() - 1) {
      return false;
    }

    const dayOfWeekWarehouseCarriers: string[] = dayOfWeekCapacity.warehouseCarriers.map((wc: any): string => {
      return wc['@id'];
    });

    dayOfWeekWarehouseCarriers.sort((a: string, b: string) => {
      return a.localeCompare(b);
    });

    const dateWarehouseCarriers: string[] = dateCapacity.warehouseCarriers.map((wc: any): string => {
      return wc['@id'];
    });

    dateWarehouseCarriers.sort((a: string, b: string) => {
      return a.localeCompare(b);
    });

    return JSON.stringify(dayOfWeekWarehouseCarriers) === JSON.stringify(dateWarehouseCarriers);
  }

  public fetchScales(): void {
    if (!this.warehouse) {
      return;
    }

    const body: any = {
      'warehouse.code': this.warehouse.code,
      'scheduledAt[year]': this.year,
      'scheduledAt[week]': this.week
    };

    this.carrierScaleResource.cGet(body, { isHydra: true, returnHydraMembers: true })
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any[]): void => {
        const scales: {[key: string]: any[]} = {};

        this.days.forEach((day: Moment): void => {
          scales[day.format('YYYY-MM-DD')] = response.filter((item: any): boolean => {
            return moment(item.scheduledAt).format('YYYY-MM-DD') === day.format('YYYY-MM-DD');
          });
        });

        this.scales = scales;
      })
    ;
  }

  public goToCreation(): void {
    this.state.go('carrier.capacity.new');
  }

  public previousWeek(): void {
    --this.week;

    if (this.week < 1) {
      this.week = 53;
      --this.year;
    }

    this.setDays();
    this.fetchCapacities();
    this.fetchScales();
  }

  public nextWeek(): void {
    ++this.week;

    if (this.week > 53) {
      this.week = 1;
      ++this.year;
    }

    this.setDays();
    this.fetchCapacities();
    this.fetchScales();
  }
}
