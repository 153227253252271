import { AbstractResource } from '../../../resources/abstract.resource';
import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { Observable } from 'rxjs/Observable';
import { IReverseProduct } from '@components/reverse';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  entryPoint: '/v2/reverse_products',
})
export class ReverseProductResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  public updateReverseProductWorkshop(reverseProductId: string, body: object): Observable<IReverseProduct> {
    return this.update(
      reverseProductId,
      body,
      { entryPoint: `${this.entryPoint}/${reverseProductId}/workshop` }) as Observable<IReverseProduct>;
  }
}
