import { AbstractResource } from '../resources/abstract.resource';
import { Injectable } from '@angular/core';
import { Resource } from '@decorators/Resource';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  routeName: 'website_configuration',
  entryPoint: '/v2/website_configurations',
  translationKey: 'NOTATIONS',
  deleteAvailable: false,
})
export class WebsiteConfigurationResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }
}
