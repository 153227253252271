import { AbstractResource } from '@resources';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Resource } from '@decorators';

@Injectable()
@Resource({
  entryPoint: '/v2/attribute_type_websites',
  isHydra: true,
})
export class AttributeTypeWebsiteResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }
}
