import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OneyOpcListComponent } from '@components/oney-opc/list/oney-opc-list.component';
import { GenericFormModule, GenericModule } from '@components/generic';
import { OneyOpcFormComponent } from '@components/oney-opc/form/oney-opc-form.component';
import { TimepickerModule } from 'ngx-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    OneyOpcFormComponent,
    OneyOpcListComponent
  ],
  entryComponents: [
    OneyOpcFormComponent,
    OneyOpcListComponent
  ],
  imports: [
    CommonModule,
    GenericModule,
    GenericFormModule,
    TimepickerModule,
    ReactiveFormsModule
  ],
  providers: []
})
export class OneyOpcModule {}
