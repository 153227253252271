import { Component, EventEmitter, Inject, Input, Output, OnChanges } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services';
import { IButtonPost, IButtonLinks } from '@components/generic/buttons/button-links.interface';

@Component({
  selector: 'app-button-action-multiple',
  template: require('./button-action-multiple.component.html'),
})
export class ButtonActionMultipleComponent extends AbstractComponent implements OnChanges {

  @Input() public links: any;
  @Input() public downloads: any;
  @Input() public goLinkLabel: string;
  @Input() public deleteLabel: string;
  @Input() public deleteAvailable = false;
  @Input() public downloadLabel: string;
  @Input() public label: string;
  @Input() public usurpAvailable: boolean;
  @Input() public targetBlank = true;
  @Input() public archiveAvailable = false;
  @Input() public archivedValue: boolean;
  @Input() public postAvailable: any;
  @Input() public actionType: 'CREATE'|'OTHER' = 'OTHER';

  @Output() public delete: EventEmitter<any> = new EventEmitter();
  @Output() public download: EventEmitter<any> = new EventEmitter();
  @Output() public usurp: EventEmitter<any> = new EventEmitter();
  @Output() public archive: EventEmitter<any> = new EventEmitter();
  @Output() public post: EventEmitter<any> = new EventEmitter();

  public actionList: any[] = [];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, null, state);
  }

  /* TODO Find a way to use ngOnInit instead of ngOnChanges
  * Actually there is a problem only in SuperProductComponent page
  * SubLinks aren't ready when the onInit is fired, put ngIf on template doesn't work
  * Need more time to investigate
  */
  ngOnChanges(): void {
    if (this.links) {
      this.actionList = this.links.map((link: IButtonLinks) => {
        return {
          translationParams: link.translationParams,
          action: link.url,
          label: link.label ? link.label : (this.goLinkLabel ? this.goLinkLabel : 'BUTTON.GO_LINK'),
          type: 'link',
          children: link.children ? this.formatChildren(link.children) : null,
        };
      });
    }

    if (this.post && this.postAvailable) {
      this.actionList.push(
        ...this.postAvailable.map((post: IButtonPost) => {
          return {
            action: () => this.onPost(post.params),
            label: post.label,
            type: 'action',
          };
        })
      );
    }

    if (this.deleteAvailable) {
      this.actionList.push({
        locale: '',
        action: () => this.onDelete(),
        label: this.deleteLabel ? this.deleteLabel : 'BUTTON.DELETE',
        type: 'action',
      });
    }

    if (this.archiveAvailable) {
      this.actionList.push({
        locale: '',
        action: () => this.triggerArchive(),
        label: this.archivedValue ? 'BUTTON.UNARCHIVE' : 'BUTTON.ARCHIVE',
        type: 'action',
      });
    }

    if (this.downloads) {
      const downloads = this.downloads.map((download: any) => {
        return {
          action: () => this.triggerDownload(download),
          label: download.label ? download.label : 'BUTTON.DOWNLOAD',
          type: 'action',
        };
      });

      this.actionList.length > 0
        ? this.actionList = [...this.actionList, ...downloads]
        : this.actionList = downloads;
    }

    if (this.usurpAvailable) {
      this.actionList.push({
        locale: '',
        action: () => this.onUsurp(),
        label: 'BUTTON.USURP',
        type: 'action',
      });
    }
  }

  public onDelete() {
    this.delete.emit();
  }

  public onPost(params: any) {
    this.post.emit(params);
  }

  public triggerArchive() {
    this.archive.emit();
  }

  public triggerDownload(event: any) {
    this.download.emit(event);
  }

  public onUsurp() {
    this.usurp.emit();
  }

  public formatChildren(children: IButtonLinks[] = []): IButtonLinks[] {
    return children.map((childLink: IButtonLinks) => {
      return {
        translationParams: childLink.translationParams,
        action: childLink.url,
        label: childLink.label ? childLink.label : (this.goLinkLabel ? this.goLinkLabel : 'BUTTON.GO_LINK'),
        type: 'link',
      };
    });
  }
}
