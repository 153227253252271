import { AbstractResource } from './abstract.resource';
import { Injectable } from '@angular/core';
import { Resource } from '../decorators/Resource';
import { Observable } from 'rxjs/Observable';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { SessionHelper } from '@helpers';
import { IImportOptions } from '@interfaces/ImportOptions.interface';
import { OrderManagerListModel} from '@components/order-manager/models';
import {BASE_URL_API} from '@constants';

const importOptions: IImportOptions[] = [
  {
    entryPoint: `/api/v2/import`,
    importButton: 'BUTTON.IMPORT.CSV',
    businessObject: 'order',
  },
];

@Injectable()
@Resource({
  routeName: 'order_new',
  entryPoint: '/orders',
  importOptions,
  cGetDefaultFilters: () => ({
    page: 1,
    byPage: 15,
    order: 'DESC',
    orderType: 'all',
    'countries[]': SessionHelper.getCountry().code,
  }),
})
export class OrderResource extends AbstractResource {

  protected nullableProperties: string[] = ['company', 'addressLine2'];

  constructor(http: HttpClient) {
    super(http);
  }

  public getComments(orderId: string): Observable<Object> {
    return this.cGet({}, {entryPoint: `/v2/orders/${orderId}/comments`, returnHydraMembers: true, isHydra: true, dontUseModel: true});
  }


  public createComment(orderId: string, body: object): Observable<Object> {
    return this.create(body, {entryPoint: `${this.entryPoint}/${orderId}/comments`});
  }

  public getHistory(orderId: string): Observable<Object> {
    return this.getMany({}, {entryPoint: `${this.entryPoint}/${orderId}/history`});
  }

  public getAssociated(orderId: string): Observable<Object> {
    return this.cGet({}, {entryPoint: `/v2/associated/orders?order=${orderId}`, returnHydraMembers: true, isHydra: true, dontUseModel: true});
  }

  public getOrderTypes(): Observable<Object> {
    return this.getMany({}, {entryPoint: `${this.entryPoint}/types`});
  }

  public sendPaymentMail(orderId: string): Observable<Object> {
    return this.create({'order': `/api/v2/orders/${orderId}`}, {entryPoint: '/v2/payment/mail'});
  }

  public dryRun(order: object): Observable<Object> {
    return this.create(order, {entryPoint: `/v2/orders?dryRun`});
  }

  public getOrderManagerOrder(orderId: string): Observable<Object> {
    return this.get(null, {entryPoint: `/v2/order-manager/orders/${orderId}`});
  }

  public getSavOrder(orderId: string): Observable<Object> {
    return this.get(null, {entryPoint: `/v2/sav/orders/${orderId}`});
  }

  public putSavOrder(orderId: string, body: object): Observable<Object> {
    return this.partialUpdate(null, body, {entryPoint: `/v2/sav/orders/${orderId}`});
  }

  public createLitigation(orderId: string, body: object): Observable<Object> {
    return this.create(body, {entryPoint: `${this.entryPoint}/${orderId}/litigation`});
  }

  public downloadEstimate(orderId: number): Observable<Object> {
    const url = this.getPath(`/v2/estimates/${orderId}/invoices`);
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.get(url, {
      headers: headers,
      responseType: 'blob'
    });
  }
}
