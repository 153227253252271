'use strict'

###
  @name $dialog

  @description
  Service dialog.

  @author Yann Prou <yann@les-tilleuls.coop>
###
angular
  .module('ammsFrontendApp')
  .factory('$dialog', [
    '$uibModal'
    ($uibModal) ->
      getModal = (message, type, title) ->
        return $uibModal.open(
          templateUrl: require('views/dialogs/dialog.html')
          controller: 'DialogCtrl'
          controllerAs: 'dialog'
          resolve:
            title: ->
              return title
            message: ->
              return message
            type: ->
              return type
        ).result

      this.confirm = (message, title) ->
        return getModal(message, 'confirm', title)

      this.alert = (message, title) ->
        return getModal(message, 'alert', title)

      this.success = (message, title) ->
        return getModal(message, 'success', title)

      ###
        @param {string|object} errors

        @returns {boolean}
      ###
      this.hasCancelConfirmBox = (errors) ->
        return (errors instanceof String || ['cancel click', 'backdrop click'].includes(errors))

      return this
  ])
  .controller('DialogCtrl', [
    '$uibModalInstance'
    'message'
    'type'
    'title'
    (
      $uibModalInstance,
      message,
      type,
      title
    ) ->
      this.getTitle = ->
        return title

      this.getMessage = ->
        return message

      this.getType = ->
        return type

      this.cancelAction = ->
        return $uibModalInstance.dismiss('cancel click')

      this.validateAction = ->
        return $uibModalInstance.close()

      return this
  ])
