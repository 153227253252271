'use strict'

import { BASE_URL } from '../../constants';

###
  @name PictoSelectorCtrl

  @example
    <example>
      <div
        picto-selector
        model="form.model.image"
        type="reinsurance"></div>
    </example>

  @example
    <example>
      <div
        picto-selector
        model="form.model.image"
        small
        type="reinsurance"></div>
    </example>
###
angular
  .module('ammsFrontendApp')
  .directive('pictoSelector', [ ->
    replace: true
    restrict: 'A'
    templateUrl: require('views/default/picto-selector.html')
    controller: 'PictoSelectorCmp'
    controllerAs: 'selector'
    bindToController:
      model: '=',
      small: '@',
      type: '@'
    scope: true
  ])
  .controller('PictoSelectorCmp', [
    'PictoResource',
    (PictoResource) ->
      this.pictos = []
      this.useSmallUI = 'undefined' != typeof this.small

      this.$onInit = =>
        pictoDirectory = 'picto/' + this.type + '/'

        ###
          Gets pictos.
        ###
        subscriber = PictoResource.getMany({ type: this.type })
          .subscribe((pictos) =>
            subscriber.unsubscribe()

            this.pictos = pictos.sort()
          )

      ###
        @param {string} picto

        @return {string}
      ###
      this.getFullUrl = (picto) ->
        return BASE_URL + '/' + picto
      return this
  ])
