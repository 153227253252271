import { Inject, Injectable } from '@angular/core';
import { FormNotifierService } from '@services/form-notifier.service';
import { MultiSearchField, SkuSearchField } from '@components/generic/Form/dynamic/fields';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { SuperProductResource } from '@components/super-product/super-product.resource';
import { DropDownListField } from '@components/generic/Form/dynamic/fields/select-field.class';
import { SessionHelper } from '@helpers';

@Injectable()
export class StockViewService extends AbstractFiltersFieldsService {
  constructor(
    @Inject('StateService') protected state: ng.ui.IStateService,
    @Inject('TranslationService') public $translate: ng.translate.ITranslateService,
    private superProductResource: SuperProductResource,
    formNotifier: FormNotifierService,
  ) {
    super(formNotifier, state);
  }

  public getFields(): any[] {
    const stockTypeOptions = [
      { label: 'SA', value: 'SA' },
      { label: 'SP', value: 'SP' },
    ];

    return [
      new DropDownListField({
        fieldName: 'stockType',
        label: 'PAGE.STOCK_VIEW.TYPE',
        data: stockTypeOptions,
        textField: 'label',
        valueField: 'value',
        value: this.filters.get('stockType') ? this.filters.get('stockType') : 'SA',
        defaultItem: { label: '', value: null },
        valuePrimitive: true,
      }),
      new MultiSearchField({
        fieldName: 'warehouse',
        label: 'PAGE.ORDER.LIST.FILTER.WAREHOUSES.LABEL',
        data: SessionHelper.getAllWarehouses(),
        textField: 'name',
        valueField: 'code',
        value: 0 < this.filters.getAll('warehouse').length ? this.filters.getAll('warehouse') : ['lsl'],
        valuePrimitive: true,
      }),
      new SkuSearchField({
        fieldName: 'stockSku',
        label: 'PAGE.ORDER.LIST.FILTER.SKU.LABEL',
        productType: 'product',
        value: this.filters.getAll('stockSku').length !== 0 ? this.filters.getAll('stockSku') : undefined,
        valuePrimitive: true,
        limit: 2,
        withSpareParts: true,
      })
    ];
  }
}
