import { Component, Inject } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { AbstractComponent } from '@components/generic/abstract.component';
import { WEBSITE_URLS } from '@constants';

@Component({
  selector: 'app-grc-product-result-list',
  template: require('./grc-product-result-list.component.html'),
  styles: [require('./grc-product-detail.component.scss')],
})
export class GrcProductResultListComponent extends AbstractComponent {

  private _items: any[];

  set items(items: any[]) {
    this._items = items;
  }

  get items(): any[] {
    return this._items;
  }

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }

  public goToEdit(event: any, productMarketplaceId: string): void {
    if (event.ctrlKey) {
      window.open(this.state.href('grc.detail', { id: productMarketplaceId } , { absolute: true }), '_blank');
    } else {
      this.state.go('grc.detail', { id: productMarketplaceId });
    }
  }

  public getWebsiteHost(): string {
    return WEBSITE_URLS[this.currentLocale];
  }
}
