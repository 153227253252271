import { Component, Inject } from '@angular/core';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { AuthService } from '@services/auth.service';
import { AbstractResource, RoomResource } from '@resources';
import { ICarrierFamily } from '@components/carrierFamily';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-room-result-list',
  template: require('./room-result-list.component.html'),
  providers: [
    { provide: AbstractResource, useClass: RoomResource },
  ]
})

export class RoomResultListComponent extends AbstractPageComponent {
  private _items: ICarrierFamily[];

  set items(items: ICarrierFamily[]) {
    this._items = items;
  }

  get items(): ICarrierFamily[] {
    return this._items;
  }

  public columns = [
    { 'size': 1, property: 'id', title: 'ID', type: 'id' },
    { 'size': 9, property: 'name', title: 'PAGE.ROOMS.LIST.NAME', type: 'customColumnContent' },
  ];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    public resource: AbstractResource,
  ) {
    super($translate, authService, resource, state);
  }

  public updatePosition(event: { id: string, position: number }): void {
    this.resource.partialUpdate(event.id, { position: event.position })
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.state.go(this.state.current, this.state.params, { reload: true });
      })
    ;
  }
}
