import { AbstractSearchFieldClass } from '@components/generic/Form/dynamic/fields/abstract-search-field.class';

/**
 * Dropdown list field, use to render a dropdown list in dynamic form template.
 * (Actually using kendo-dropdownlist)
 */
export class DropDownListField extends AbstractSearchFieldClass {
  /**
   * Used in the template to render the kendo-dropdownlist component.
   */
  public fieldType: string;

  /**
   * Sets a default item to the select field.
   */
  public defaultItem: any;

  constructor(options: any) {
    super(options);

    this.defaultItem = options.defaultItem;
    this.fieldType = options.fieldType || 'dropDownList';
  }
}
