import { Injectable } from '@angular/core';
import { IUserCountry } from '@components/export-logistics/forms';

/**
 * Keep a reference of countries checked needed by the ExportLogisticsComponent.
 */
@Injectable()
export class CountryService {

  public countriesChecked: IUserCountry[] = [];

  public setCountriesChecked(countriesChecked: IUserCountry[]): void {
    this.countriesChecked = countriesChecked;
  }

  public getCountryChecked(): IUserCountry[] {
    return this.countriesChecked;
  }
}
