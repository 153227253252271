import {IUnpaired} from '@components/unpaired/models/unpaired.interface';

export class UnpairedModel implements IUnpaired {
  public sku: string;
  public package_number: number;
  public total: number;
  public warehouse: string;

  constructor(unpaired: IUnpaired) {
    this.sku = unpaired.sku;
    this.package_number = unpaired.package_number;
    this.total = unpaired.total;
    this.warehouse = unpaired.warehouse;
  }
}
