import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { AbstractResource } from '@resources';
import { HttpClient } from '@angular/common/http';
import { StockImportListModel } from '@components/stock/stock-import/stock-import-list.model';

@Injectable()
@Resource({
  entryPoint: '/v2/stock_imports',
  isHydra: true,
  deleteAvailable: false,
  updateAvailable: false,
  listModel: StockImportListModel,
  translationKey: 'STOCK_IMPORT',
  listColumns: [
    { field: 'id', translationKey: 'ID', type: 'id' },
    { field: 'warehouse', translationKey: 'WAREHOUSE', type: 'string' },
    { field: 'startDate', translationKey: 'START_DATE', type: 'string' },
    { field: 'endDate', translationKey: 'END_DATE', type: 'string' },
  ],
})
export class StockImportResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }
}
