import { IAttributesSetAttribute } from './attributes-set.interface';

export class AttributesSetAttributeModel implements IAttributesSetAttribute {
  public id: number;
  public attributeId: number;

  constructor(attributesSetAttribute: IAttributesSetAttribute) {
    this.id = attributesSetAttribute.id;
    this.attributeId = attributesSetAttribute.attributeId;
  }
}
