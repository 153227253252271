import {Component, Inject, Input, OnInit} from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { AbstractResource } from '@resources/abstract.resource';
import { FooterLinksResource } from '@components/footer/footer-links';
import { IFooterLinks } from '@components/footer/footer-links/models';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SnackbarService} from '@components/snackbar';
import {HydraHelper} from '@helpers/HydraHelper';
import {FormNotifierService} from '@services';

@Component({
  selector: 'app-footer-links-result-list',
  template: require('./footer-links-result-list.component.html'),
  providers: [
    { provide: AbstractResource, useClass: FooterLinksResource },
  ],
})

export class FooterLinksResultListComponent extends AbstractPageComponent implements OnInit {
  @Input() public locale: string;

  public showBlock = false;
  public id: number;
  public parentId: number;
  public isEdit = false;
  public form: FormGroup;
  public model: IFooterLinks;
  public parents: any[] = [];
  public childrens: { [parentId: number]: any[] } = {};

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private snackbar: SnackbarService,
    private formBuilder: FormBuilder,
    @Inject('DialogService') private dialogService: any,
    private formNotifier: FormNotifierService,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    this.buildForm();
    this.fetchData();
  }

  public buildForm(): void {
    this.form = this.formBuilder.group({
      title: [null],
      link: [null],
      obfuscated: [true],
      position: [null, Validators.min(0)],
      parent: [null]
    });
  }

  public fetchData(): void {
    this.resource.cGet({
      'locale': this.locale
    })
      .takeUntil(this.destroyed$)
      .subscribe((response: any): void => {
        const data = response['hydra:member'];
        this.parents = data
          .filter((item: any) => item.parent === null)
          .sort((a: any, b: any) => a.position - b.position);

        this.parents.forEach((parent: any) => {
          const parentId = parent.id;
          this.childrens[parentId] = data
            .filter((item: any) => item.parent === `/api/v2/footers/${parentId}`)
            .sort((a: any, b: any) => a.position - b.position);
        });
      });
  }

  public getParent(): any[] {
    return this.parents || [];
  }

  public getChildren(parentId: number): any[] {
    return this.childrens[parentId] || [];
  }

  public addParent(): void {
    if (this.parents && this.parents.length >= 5) {
      this.snackbar.alert(this.translate('PAGE.FOOTER.ALERTS.PARENT.MAXIMUM'));

      return;
    }

    this.buildForm();
    this.showBlock = true;
  }

  public addChildren(parentId: number): void {
    this.parentId = parentId;

    if (this.childrens[this.parentId] && this.childrens[this.parentId].length >= 8) {
      this.snackbar.alert(this.translate('PAGE.FOOTER.ALERTS.CHILD.MAXIMUM'));

      return;
    }

    this.buildForm();
    this.showBlock = true;
  }

  public edit(id: number): void {
    this.id = id;
    this.fillForm();
    this.prepareBody();
    this.isEdit = true;
    this.showBlock = true;
  }

  public remove(id: number): void {
    this.id = id;
    this.delete();
  }

  public submit(): void {
    if (this.isEdit) {
      this.update();

      return;
    }

    this.create();
  }

  private prepareBody(): IFooterLinks {
    return {
      parent: this.parentId ? HydraHelper.buildIri(this.parentId, 'footers') : null,
      locale: this.locale,
      obfuscated: this.form ? this.form.get('obfuscated').value : null,
      position: this.form ? this.form.get('position').value : null,
      title: this.form ? this.form.get('title').value : null,
      link: this.form ? this.form.get('link').value : null,
    };
  }

  private fillForm(): void {
    this.resource.get(this.id)
      .subscribe((response: IFooterLinks) => {
        this.model = response;
        this.form.patchValue(response);
      });
  }

  private update(): void {
    this.dialogService.confirm(this.translate('PAGE.FOOTER.CONFIRM.UPDATE'))
      .then(() => {
        this.resource.update(this.id, this.prepareBody())
          .takeUntil(this.destroyed$)
          .subscribe(() => {
            this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
            this.formNotifier.notifyFormSubmitted();
            this.state.go(this.state.current, this.state.params, { reload: true });
          });
      });
  }

  private delete(): void {
    this.dialogService.confirm(this.translate('PAGE.FOOTER.CONFIRM.DELETE'))
      .then(() => {
        this.resource.remove(this.id)
          .takeUntil(this.destroyed$)
          .subscribe(() => {
            this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
            this.formNotifier.notifyFormSubmitted();
            this.state.go(this.state.current, this.state.params, { reload: true });
          });
      });
  }

  public create(): void {
    this.dialogService.confirm(this.translate('PAGE.FOOTER.CONFIRM.ADD'))
      .then(() => {
      this.resource.create(this.prepareBody())
        .takeUntil(this.destroyed$)
        .subscribe((response: any) => {
          this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
          this.state.go(this.state.current, this.state.params, { reload: true });
        });
    });
  }
}
