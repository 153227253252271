import { AbstractResource } from '../../resources/abstract.resource';
import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  entryPoint: '/v2/category_relations',
})
export class CategoryRelationsResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }
}
