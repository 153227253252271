export default warrantyRoutes;

/** @ngInject */
function warrantyRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'parameters',
    sidebarSubNav: 'warranty',
    reloadChangeCountry: 'warranty.list',
  };

  $stateProvider
    .state('warranty', {
      parent: 'walisoft',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.WARRANTY',
        stateName: 'warranty.list'
      }
    })
    .state('warranty.list', {
      url: '/warranties',
      breadcrumb: {
        label: 'BREADCRUMB.WARRANTY'
      },
      views: {
        'page@layout': {
          template: '<app-warranty-list></app-warranty-list>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.WARRANTY.LIST.TITLE',
      })
    })
    .state('warranty.new', {
      url: '/warranties/new',
      breadcrumb: {
        label: 'BREADCRUMB.NEW'
      },
      views: {
        'page@layout': {
          template: '<app-warranty-new></app-warranty-new>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.WARRANTY.NEW.TITLE',
      })
    })
    .state('warranty.edit', {
      url: '/warranties/:id/edit',
      breadcrumb: {
        label: 'BREADCRUMB.EDIT'
      },
      views: {
        'page@layout': {
          template: '<app-warranty-edit></app-warranty-edit>'
        }
      },
      data: Object.assign(commonStateData, {
        reloadChangeCountry: 'warranty.edit',
        reloadChangeCountrySearchParam: 'id',
        title: 'PAGE.WARRANTY.EDIT.TITLE',
      })
    })
  ;
}
