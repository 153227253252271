'use strict'

import { SessionHelper } from '@helpers';

###
  @name Price

  @description Display currency symbol by current currency

  @param {String} amount
  @param {Object} fractionSize
  @param {Object} sup

  @example
    <example>
      <price></price>
    </example>
###
angular
  .module('ammsFrontendApp')
  .directive('price', [
    '$filter'
    (
      $filter,
    ) ->
      restrict: 'E'
      replace: true
      template: '<span></span>'
      scope:
        amount: '@'
        fractionSize: '='
        sup: '='
      link: (scope, element) ->
        currencyCode = SessionHelper.getCurrency()

        if (scope.amount?)
          scope.$watch('amount', (newValue) ->
            if (newValue)
              amount = scope.amount
              fractionSize = if (scope.fractionSize?) then scope.fractionSize else 2
              sup = if (scope.sup?) then scope.sup else true

              if (amount? && !_.isNumber(amount))
                amount = parseFloat(amount.replace(' ', ''))

              result = $filter('isoCurrency')(amount, currencyCode, fractionSize)
              result = result.replace(/(.+)(\$|£)/g, '$2$1')

              if (sup)
                element.html(result.replace(/(\$|€|£)/g, '<sup>$&<\/sup>'))
              else
                element.html(result)
          )
        else
          angular.element(element)
            .text($filter('isoCurrency')(0, currencyCode).replace(/(.+)(\$|€|£)/, '$2'))
            .addClass('input-group-addon')
  ])
