import { Component, EventEmitter, Inject, Input, Output, TemplateRef } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { FormGroup } from '@angular/forms';
import { AbstractComponent } from '@components/generic/abstract.component';

export interface IImage {
  id: string;
  thumbnailWebPath?: string;
  thumbnailPath?: string;
  watermarkedWebPath?: string;
  webPath?: string;
  baseWebPath?: string;
  originalPath?: string;
  position?: number;
  selected?: boolean;
  fileName?: string;
  name?: string;
  titleAttribute?: string;
  featured?: boolean;
  dimensionType?: boolean;
  backgroundType?: boolean;
  ambianceType?: boolean;
  isVideo?: boolean;
  altAttribute?: string;
  additionalContentForm?: FormGroup;
}

/**
 *
 * TODO: see to rename this file by GenericFilesListComponent
 * This component is used to show a list of uploaded files.
 * this list can be ordered with the dragEnabled attribute.
 * Delete action can be activated with delete attribute.
 *
 * An additional form can be attached to each file.
 * the form template can be passed via the attribute additionalContentForm.
 * It comes from parent via ng-template and injected into the view,
 * It must have an associated file to refer to it.
 * The dialog need a title with modalTitle attribute.
 *
 * @example of template form
 *
 * <ng-template #additionalContentForm let-associatedForm>
 *  <app-form [form]="associatedForm" [dontUseButtonBar]="true">
 *    <app-form-control [control]="associatedForm.get('title')"
 *                     [fieldOptions]="{
 *                             label: 'label',
 *                             controlName: 'title'
 *                           }">
 *    </app-form-control>
 **  </app-form>
 * </ng-template>
*/
@Component({
  selector: 'app-generic-image-list',
  template: require('./generic-image-list.component.html'),
  styles: [require('./generic-image-list.component.scss')],
})
export class GenericImageListComponent extends AbstractComponent {

  @Input() public imageList: IImage[];
  @Input() public enableImageSelection: boolean = false;
  @Input() public enableImageDeletion: boolean = false;
  @Input() public dragEnabled: boolean;
  @Input() public additionalContentForm: TemplateRef<any>;
  @Input() public modalTitle: string = '';
  @Input() public showFilename: boolean = false;
  @Input() public hideLabel: boolean = false;

  @Output() public onUpdatePosition?: EventEmitter<IImage> = new EventEmitter();
  @Output() public onDelete: EventEmitter<any> = new EventEmitter();

  public open: boolean = false;
  public fileOnEdition: IImage;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    @Inject('DialogService') private dialog: any,
  ) {
    super($translate, authService, resource, state);
  }

  public hasImagesToDelete(): boolean {
    return !!this.imageList.filter((userImage: any) => userImage.selected).length;
  }

  public selectAllImg(active: boolean): void {
    this.imageList.map((img: any) => {
      img.selected = active;

      return img;
    });
  }

  public updatePosition(img: IImage): void {
    this.imageList = this.imageList.map((image: IImage, index: number) => {
      image.position = index;

      return image;
    });

    this.onUpdatePosition.emit(img);
  }

  public editImg(img: any): void {
      img.inEdition = true;
      this.fileOnEdition = img;
      this.open = true;
  }

  public close(): void {
    this.open = false;
  }

  public deleteImg(image?: IImage): void {
    let imagesToRemove = this.imageList.filter((img: IImage) => img.selected);

    if (imagesToRemove.length === 0 && image) {
      imagesToRemove = [image];
    }

    const confirmText = `\
      ${this.translate('UPLOADED_PICTURES.CONFIRM.REMOVE')} ${imagesToRemove.length}\
      ${this.translate('UPLOADER.FILE')}${1 < imagesToRemove.length ? this.translate('UPLOADER.FILES') : ''}\
    `;

    this.dialog.confirm(confirmText).then(() => {
      imagesToRemove.forEach((removeImg: IImage) => {
        this.imageList.forEach((img: IImage, i: number) => {
          if (removeImg.id === img.id) {
            this.imageList.splice(i, 1);
          }
        });
      });

      this.onDelete.emit(imagesToRemove);
    });
  }

  public isPdf(file: IImage): boolean {
    if (file) {
      return file.fileName ? file.fileName.split('.').pop().toLowerCase() === 'pdf' : false;
    }
  }

  public isVideo(file: IImage): boolean {
    if (file) {
      return file.webPath ? new RegExp(/.*\.(mp4|webm)/).test(file.webPath) : false;
    }

    return false;
  }
}
