import { Component, Inject, OnInit } from '@angular/core';
import { AbstractResource } from '@resources/abstract.resource';
import { CarrierFamilyResource, ICarrierFamily } from '@components/carrierFamily';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@services/auth.service';
import { SnackbarService } from '@components/snackbar';
import { FormNotifierService } from '@services/form-notifier.service';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { INPUT_NUMBER_PATTERN_DEC } from '@constants/form';
import { NumberHelper } from '@helpers/NumberHelper';

@Component({
  selector: 'app-carrier-family-shipping-price',
  template: require('./carrier-family-shipping-price.component.html'),
  styles: [require('./carrier-family-shipping-price.component.scss')],
  providers: [
    { provide: AbstractResource, useClass: CarrierFamilyResource },
  ],
})
export class CarrierFamilyShippingPriceComponent extends AbstractPageComponent implements OnInit {
  public form: FormGroup;
  public isActive: boolean = false;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    private snackbar: SnackbarService,
    private formNotifier: FormNotifierService,
    @Inject('DialogService') private dialog: any,
  ) {
    super($translate, authService, resource, state);
  }

  public ngOnInit(): void {
    this.buildForm();
    this.fillForm();
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      shippingPrice: [null, Validators.pattern(INPUT_NUMBER_PATTERN_DEC)],
    });
  }

  private fillForm(): void {
    this.resource.get(this.state.params.id)
      .takeUntil(this.destroyed$)
      .subscribe((response: ICarrierFamily) => {
        this.form.patchValue(response);

        Object.keys(this.form.controls).forEach((key) => {
          if (null !== this.form.get(key).value) {
            this.isActive = true;
          }
        });
      })
    ;
  }

  public submit(event?: any): void {
    this.dialog.confirm(this.translate('PAGE.CARRIER.CARRIER_FAMILIES.CONFIRM.UPDATE'))
      .then(() => {
        const id: string = this.state.params.id;
        this.resource.update(id, this.prepareBody(this.form))
          .takeUntil(this.destroyed$)
          .subscribe(() => {
            this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));

            if (event && event.redirect) {
              this.state.go(`${this.resource.routeName}.list`);

              return;
            }

            this.formNotifier.notifyFormSubmitted();
            this.state.go(`${this.resource.routeName}.edit.shippingPrice`, {id}, {reload: true});
          })
        ;
      })
    ;
  }

  private prepareBody(form: FormGroup): { shippingPrice: number } {
    return this.isActive
      ? { shippingPrice: NumberHelper.parseFloat(this.form.get('shippingPrice').value) }
      : { shippingPrice: null };
  }

  private updateFieldsStatus(event: any): void {
    event
      ? this.form.setValue({shippingPrice: 0})
      : Object.keys(this.form.controls).forEach(key => {
        this.form.setValue({[key] : null});
      });
  }
}
