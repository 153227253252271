import { Component, Inject, Input, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AbstractResource } from '@resources/abstract.resource';
import { AbstractFormComponent } from '@components/generic/Form/abstract-form.component';
import { IFormExpedition, IProductForm } from '@components/product/interfaces/product-form.interface';
import { ICarrierGroup } from '@components/carrier';
import { LogisticHelper } from '@helpers/LogisticHelper';
import { SessionHelper } from '@helpers/session.helper';
import { Observable } from 'rxjs/Observable';
import { FormNotifierService } from '@services/form-notifier.service';
import { SnackbarService } from '@components/snackbar';

@Component({
  selector: 'app-expedition-delivery-form',
  template: require('./expedition-delivery-form.component.html'),
})
export class ExpeditionDeliveryFormComponent extends AbstractFormComponent implements OnInit {

  public form: FormGroup;
  public carriers$: Observable<object>;
  @Input() public model: IProductForm;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    resource: AbstractResource,
    private formBuilder: FormBuilder,
    private formNotifier: FormNotifierService,
    private snackbar: SnackbarService,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    this.buildForm();
    this.fillForm();
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      numberOfPackages: [''],
      m3: [''],
      packageTotalWeight: [''],
      carriers: [null],
      packed: false,
      clustering: false,
      excludedCarrierCodes: [null],
    });
  }

  private fillForm(): void {
    const carriers: any[] = [];
    this.model.carriers.forEach(carrier => {
      // If the carrier is blacklisted, it is not eligible
      if (!this.model.excludedCarrierCodes.includes(carrier)) {
        carriers.push({name : carrier, code: carrier});
      }
    });
    this.model.carriers = carriers;

    const excludedCarrierCodes: any[] = [];
    this.model.excludedCarrierCodes.forEach(excludedCarrierCode => {
      excludedCarrierCodes.push({name: excludedCarrierCode, code: excludedCarrierCode});
    });
    this.model.excludedCarrierCodes = excludedCarrierCodes;

    this.form.patchValue(this.model);
  }

  private submit(event?: any): void {
    const id = this.state.params.id;
    const body = this.prepareBody();

    this.resource.update(id, body, { entryPoint: `/v2/products/${id}` })
      .takeUntil(this.destroyed$)
      .subscribe(() => {
        this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));

        if (event && event.redirect) {
          if (this.state.current.name === 'spare-part.edit.carriers') {
            this.state.go('spare-part.list');
          } else {
            this.state.go(`${this.resource.routeName}.list`);
          }

          return;
        }

        this.formNotifier.notifyFormSubmitted();

        if (this.state.current.name === 'spare-part.edit.carriers') {
          this.state.go('spare-part.edit.carriers',  { id });
        } else {
          this.state.go(`${this.resource.routeName}.edit.carriers`, { id });
        }
      })
    ;
  }

  private prepareBody(): IFormExpedition {
    return {
      carriers: this.form.get('carriers').value,
      primaryCarrierFamily: this.model.primaryCarrierFamily,
      secondaryCarrierFamilies: this.model.secondaryCarrierFamilies,
      packed: this.form.get('packed').value,
      clustering: this.form.get('clustering').value
    };
  }
}
