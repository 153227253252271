import {
  IMarketplaceImage,
  IProductForm,
  IProductMarketplace,
  IProductTranslation,
  ISuperProduct,
  ISuperProductSelectedValue,
  IPackage,
  IMasterProduct,
  ICountry,
} from '@components/product/interfaces/product-form.interface';
import { SessionHelper } from '@helpers/session.helper';
import { ISellingProduct } from '@models/ISellingProduct';
import {IManufacturer} from '@components/super-product/interfaces/form-general.interfaces';

export class ProductFormModel implements IProductForm {
  id: string;
  sku: string;
  ean: string;
  status: string;
  superProduct: ISuperProduct|ISuperProductSelectedValue = {
    id: undefined,
    label: '',
  };
  superProductRef: string = '';
  warrantyOverrideContent: boolean;
  warranty?: {
    id: string;
  };
  manufacturerOverrideContent: boolean;
  manufacturer?: {
    id: string;
  };
  manufacturers: IManufacturer[];
  afterSalesService?: string;
  notifyReviewers: boolean;
  notifyReviewerOverrideContent: boolean;
  hidden: boolean;
  carriers?: string[];
  cmup?: number;
  deee?: number;
  deeeHT?: number;
  ecotax?: number;
  ecotaxHT?: number;
  provisionalStock: number;
  numberOfPackages?: number;
  m3?: number;
  packageTotalWeight?: number;
  packages?: IPackage[];

  version: string;
  masterProduct?: IMasterProduct;
  primaryCarrierFamily: string;
  quantityInStock?: number;
  sageName?: string;
  secondaryCarrierFamilies: string[];
  shippingCost?: number;
  allowedCarriers?: string[];
  isPack?: boolean;
  productMarketplaces?: {
    [marketplaceCode: string]: IProductMarketplace
  };
  packagesInfo: string[];
  translations: {
    [keys: string]: IProductTranslation;
  };
  internalNotes: string;
  internalNotesOverrideContent: boolean;
  crossSellingProducts?: ISellingProduct[];
  upSellingProducts?: ISellingProduct[];
  originalCrossSellingProducts: ISellingProduct[];
  originalUpSellingProducts: ISellingProduct[];
  packed: boolean;
  clustering: boolean;
  excludedCarrierCodes?: string[];
  stockByWarehouse?: any[];
  syncCrossAndUpSellingFromFr: boolean;
  mutualized: boolean;
  country?: ICountry;
  barcodeImage?: string;
  returnOneForOne: boolean;

  constructor(model: IProductForm) {
    this.id = model.id;
    this.sku = model.sku;
    this.ean = model.ean;
    this.status = model.status;
    if (model.superProduct) {
      this.superProduct = {
        id: model.superProduct.id,
        label: (<ISuperProduct>model.superProduct).translations &&
          (<ISuperProduct>model.superProduct).translations[SessionHelper.getLocale()] ?
            `${(<ISuperProduct>model.superProduct).skuParent}` +
            `(${(<ISuperProduct>model.superProduct).translations[SessionHelper.getLocale()].reference})` :
            '',
        skuParent: (<ISuperProduct>model.superProduct).skuParent
      };

      this.superProductRef = (<ISuperProduct>model.superProduct).translations &&
        (<ISuperProduct>model.superProduct).translations[SessionHelper.getLocale()] ?
          (<ISuperProduct>model.superProduct).translations[SessionHelper.getLocale()].reference :
          ''
      ;
    }
    this.warranty = model.warranty;
    this.manufacturer = model.manufacturer;
    this.manufacturers = model.manufacturers;
    this.afterSalesService = model.afterSalesService || null;
    this.notifyReviewers = model.notifyReviewers;
    this.hidden = model.hidden;
    this.carriers = model.carriers;
    this.cmup = model.cmup;
    this.deee = model.deee;
    this.deeeHT = model.deeeHT;
    this.ecotax = model.ecotax;
    this.ecotaxHT = model.ecotaxHT;
    this.m3 = model.m3;
    this.numberOfPackages = model.numberOfPackages;
    this.packageTotalWeight = model.packageTotalWeight;
    this.packages = model.packages;
    this.masterProduct = model.masterProduct;
    this.primaryCarrierFamily = model.primaryCarrierFamily;
    this.sageName = model.sageName;
    this.secondaryCarrierFamilies = model.secondaryCarrierFamilies;
    this.shippingCost = model.shippingCost;
    this.allowedCarriers = model.allowedCarriers;
    this.productMarketplaces = model.productMarketplaces;
    this.quantityInStock = model.quantityInStock;
    this.packagesInfo = model.packagesInfo;
    this.translations = model.translations;
    this.isPack = model.isPack;
    this.internalNotes = model.internalNotes;
    this.crossSellingProducts = model.crossSellingProducts;
    this.upSellingProducts = model.upSellingProducts;
    this.originalCrossSellingProducts = model.crossSellingProducts ? model.crossSellingProducts.slice(0) : [];
    this.originalUpSellingProducts = model.upSellingProducts ? model.upSellingProducts.slice(0) : [];
    this.packed = model.packed;
    this.clustering = model.clustering;
    this.excludedCarrierCodes = model.excludedCarrierCodes;
    this.warrantyOverrideContent = model.warrantyOverrideContent;
    this.manufacturerOverrideContent = model.manufacturerOverrideContent;
    this.notifyReviewerOverrideContent = model.notifyReviewerOverrideContent;
    this.internalNotesOverrideContent = model.internalNotesOverrideContent;
    this.stockByWarehouse = model.stockByWarehouse;
    this.syncCrossAndUpSellingFromFr = model.syncCrossAndUpSellingFromFr;
    this.mutualized = model.mutualized;
    this.barcodeImage = model.barcodeImage || null;
    this.version = model.version;
    this.country = model.country;
    this.returnOneForOne = model.returnOneForOne;
    this.provisionalStock = model.provisionalStock;
  }

  public getPreviewVersion(): string {
    const version = SessionHelper.getProductVersion(this.sku);

    return version ? version : this.version;
  }

  public getVersionPackages(): any[] {
    if (this.isPack) {
      return this.masterProduct.packages;
    }

    return this.masterProduct.packages.filter((item: any) => {
      return item.version === this.getPreviewVersion();
    });
  }

}
