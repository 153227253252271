import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericFormModule } from '@components/generic/generic-form.module';
import { GenericModule } from '@components/generic/generic.module';
import { ProductModule } from '@components/product';
import { OrderResource, OrderSupportedByResource } from '@resources';
import { SavOrderDetailComponent } from '@components/sav/order/sav-order-detail.component';
import { SavArrivalFormComponent } from '@components/sav/arrival/sav-arrival-form.component';
import { SavSparePartSearchComponent } from '@components/sav/spare-part/sav-spare-part-search.component';
import {ComboBoxModule, DropDownListModule} from '@progress/kendo-angular-dropdowns';
import { ArrivalResource } from '@resources/arrival.resource';
import { SavArrivalListComponent } from '@components/sav/arrival/sav-arrival-list.component';
import { InlineEditComponent } from './inline-edit/inline-edit.component';
import { FormsModule } from '@angular/forms';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { SavArrivalFiltersFormService } from './arrival/sav-arrival-filters-form.service';
import { ArrivalReasonResource } from '@resources/arrival-reason.resource';
import { SavOrdersListComponent } from '@components/sav/orders/sav-orders-list.component';
import { SavOrdersResultListComponent } from './orders/sav-orders-result-list.component';
import { ExportLogisticsResource } from '@components/export-logistics/export-logistics.resource';
import { SavOrderNewComponent } from './order/sav-order-new.component';
import { BsDropdownModule } from 'ngx-bootstrap';
import { OrderManagerResource } from '@components/order-manager';
import {SparepartReplenishmentResource} from '@components/sav/resources';

@NgModule({
  declarations: [
    SavOrderDetailComponent,
    SavArrivalFormComponent,
    SavSparePartSearchComponent,
    SavArrivalListComponent,
    InlineEditComponent,
    SavOrdersListComponent,
    SavOrdersResultListComponent,
    SavOrderNewComponent,
    InlineEditComponent
  ],
  entryComponents: [
    SavOrderDetailComponent,
    SavArrivalFormComponent,
    SavArrivalListComponent,
    InlineEditComponent,
    SavOrdersListComponent,
    SavOrdersResultListComponent,
    SavOrderNewComponent,
    InlineEditComponent
  ],
    imports: [
        CommonModule,
        GenericFormModule,
        GenericModule,
        ProductModule,
        DropDownListModule,
        FormsModule,
        BsDropdownModule,
        ComboBoxModule
    ],
  exports: [
    SavOrderNewComponent
  ],
  providers: [
    OrderResource,
    OrderSupportedByResource,
    ArrivalResource,
    ArrivalReasonResource,
    OrderManagerResource,
    ExportLogisticsResource,
    { provide: AbstractFiltersFieldsService, useClass: SavArrivalFiltersFormService },
    SparepartReplenishmentResource,
  ],
})

export class SavModule { }
