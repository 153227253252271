import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { HttpClient } from '@angular/common/http';
import { AbstractResource } from '@resources';
import { ContainerModel } from '@components/stock/container/container.model';
import {Observable} from 'rxjs/Observable';

@Injectable()
@Resource({
  routeName: 'stocks.container',
  entryPoint: '/v2/containers',
  translationKey: 'CONTAINER_VIEW',
  cGetDefaultFilters: () => ({
    page: 1,
    byPage: 30,
    'order[id]': 'DESC'
  }),
  listColumns: [
    { field: 'id', translationKey: 'ID', type: 'id' },
    { field: 'reference', translationKey: 'REFERENCE', type: 'string', sortable: true },
    { field: 'containerNumber', translationKey: 'CONTAINER_NUMBER', type: 'string', sortable: true },
    { field: 'sageIdentifier', translationKey: 'SAGE_IDENTIFIER', type: 'string', sortable: true },
  ],
  listModel: ContainerModel,
  formModel: ContainerModel,
  createAvailable: true,
  deleteAvailable: true,
  isHydra: true,
})
export class ContainerListResource extends AbstractResource {
  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  public addArrivalToContainer(containerId: number, body: object): Observable<Object> {
    return this.update(
      null,
      body,
      {entryPoint: `/v2/containers/${containerId}/arrivals`}
    );
  }
}
