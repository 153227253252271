import { Injectable, Inject } from '@angular/core';
import { AbstractFormFieldBase } from '@components/generic/Form/dynamic/fields/abstract-form-field-base.class';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { LitigationStatusResource } from '@components/order-manager/resources/litigation-status.resource';
import { LitigationTypeResource } from '@components/order-manager/resources/litigation-type.resource';
import { FormNotifierService } from '@services/form-notifier.service';
import { CODE_BE, CODE_ES, CODE_FR, CODE_GB, CODE_IT, CODE_NL, CODE_PT, CODE_DE, CODE_AT, CODE_LU, CODE_PL } from '@constants';
import * as moment from 'moment';
import { DATE_FULL_FORMAT, LOCALE_FR } from '@constants';
import { SessionHelper } from '@helpers';
import { DropDownListField } from '@components/generic/Form/dynamic/fields/select-field.class';
import {
  DateField,
  MultiSearchField,
  SkuSearchField,
  TextField
} from '@components/generic/Form/dynamic/fields';

interface IDropdownData {
  label: string;
  value: string;
}

@Injectable()
export class LitigationListFiltersService extends AbstractFiltersFieldsService {
  COUNTRIES = [
    { label: 'France', value: CODE_FR },
    { label: 'Belgique', value: CODE_BE },
    { label: 'Espagne', value: CODE_ES },
    { label: 'Belgique', value: CODE_BE },
    { label: 'Angleterre', value: CODE_GB },
    { label: 'Pays-Bas', value: CODE_NL },
    { label: 'Portugal', value: CODE_PT },
    { label: 'Italie', value: CODE_IT },
    { label: 'Allemagne', value: CODE_DE },
    { label: 'Luxembourg', value: CODE_LU },
    { label: 'Autriche', value: CODE_AT },
    { label: 'Pologne', value: CODE_PL },
  ];

  private countryCodeField: MultiSearchField = new MultiSearchField({
    fieldName: 'country[]',
    label: 'PAGE.ORDER.LIST.FILTER.COUNTRY.LABEL',
    data: null,
    textField: 'label',
    valueField: 'value',
    value: null,
    defaultItem: { label: this.$translate.instant('PAGE.ORDER.LIST.FILTER.COUNTRY.DATA.0'), value: null },
    valuePrimitive: true,
    readonly: false,
  });

  private createdAtStartField: TextField = new TextField({
    fieldName: 'createdAtStart',
    value: null,
    hidden: true
  });

  private createdAtEndField: TextField = new TextField({
    fieldName: 'createdAtEnd',
    value: null,
    hidden: true
  });

  private shippedAtStartField: TextField = new TextField({
    fieldName: 'shippedAtStart',
    value: null,
    hidden: true
  });

  private shippedAtEndField: TextField = new TextField({
    fieldName: 'shippedAtEnd',
    value: null,
    hidden: true
  });

  private paidAtStartField: TextField = new TextField({
    fieldName: 'paidAtStart',
    value: null,
    hidden: true
  });

  private paidAtEndField: TextField = new TextField({
    fieldName: 'paidAtEnd',
    value: null,
    hidden: true
  });

  private statusField = new MultiSearchField({
    fieldName: 'status[]',
    label: 'PAGE.LITIGATION.LIST.TABLE.HEAD.STATUS',
    textField: 'label',
    valueField: 'code',
    value: 0 < this.filters.getAll('status[]').length ? this.filters.getAll('status[]') : undefined,
    valuePrimitive: true,
  });

  private typeField = new MultiSearchField({
    fieldName: 'type[]',
    label: 'PAGE.LITIGATION.LIST.TABLE.HEAD.TYPE',
    textField: 'label',
    valueField: 'code',
    value: 0 < this.filters.getAll('type[]').length ? this.filters.getAll('type[]') : undefined,
    valuePrimitive: true,
  });

  private archivingList: IDropdownData[] = [
    {
      label: this.$translate.instant('PAGE.COMMERCIAL_OPERATIONS.LIST.FILTERS.ARCHIVING_STATUS.DATA.NON_ARCHIVED'),
      value: '0'
    },
    {
      label: this.$translate.instant('PAGE.COMMERCIAL_OPERATIONS.LIST.FILTERS.ARCHIVING_STATUS.DATA.ARCHIVED'),
      value: '1'
    },
  ];

  private creditList: IDropdownData[] = [
    {
      label: this.$translate.instant('PAGE.COMMERCIAL_OPERATIONS.LIST.FILTERS.CREDIT_STATUS.DATA.WITHOUT_CREDIT'),
      value: '0'
    },
    {
      label: this.$translate.instant('PAGE.COMMERCIAL_OPERATIONS.LIST.FILTERS.CREDIT_STATUS.DATA.WITH_CREDIT'),
      value: '1'
    },
  ];

  private static setDates(newValue: string, startField: TextField, endField: TextField): void {
    if (!newValue || null === newValue[0]) {
      return;
    }

    startField.value = moment(newValue[0]).startOf('day').format(DATE_FULL_FORMAT);
    endField.value = moment(newValue[1]).endOf('day').format(DATE_FULL_FORMAT);
  }

  constructor(
    @Inject('StateService') protected state: ng.ui.IStateService,
    @Inject('TranslationService') public $translate: ng.translate.ITranslateService,
    formNotifier: FormNotifierService,
    private litigationStatusResource: LitigationStatusResource,
    private litigationTypeResource: LitigationTypeResource,
  ) {
    super(formNotifier, state);
  }

  private fetchLitigationStatuses(): void {
    this.litigationStatusResource.cGet({}, { returnHydraMembers: true, blocking: false })
      .takeUntil(this.destroyed$)
      .subscribe((response: any[]) => {
        this.statusField.data = response.map((status: any) => {
          return {
            label: (status.translations[SessionHelper.getUILanguage()] || status.translations[LOCALE_FR]).label,
            code: status['code'],
            id: status['@id'],
          };
        }).sort((a, b) => {
          if (a.label < b.label) { return -1; }
          if (a.label > b.label) { return 1; }
          return 0;
        });
      })
    ;
  }

  private fetchLitigationTypes(): void {
    this.litigationTypeResource.cGet({}, { returnHydraMembers: true, blocking: false })
      .takeUntil(this.destroyed$)
      .subscribe((response: any[]) => {
        this.typeField.data = response.map((status: any) => {
          return {
            label: (status.translations[SessionHelper.getUILanguage()] || status.translations[LOCALE_FR]).label,
            code: status['code'],
            id: status['@id'],
          };
        }).sort((a, b) => {
          if (a.label < b.label) { return -1; }
          if (a.label > b.label) { return 1; }
          return 0;
        });
      })
    ;
  }

  /**
   * @inheritDoc.
   */
  public getFields(): AbstractFormFieldBase<any>[] {
    return [
      new TextField({
        fieldName: 'search',
        label: 'PAGE.ORDER_MANAGER.LIST.FILTER.SEARCH',
        value: this.filters.get('search') ? this.filters.get('search') : undefined,
      }),
    ];
  }

  /**
   * @inheritDoc.
   */
  public getComplementaryFields(): AbstractFormFieldBase<any>[] {
    this.fetchLitigationStatuses();
    this.fetchLitigationTypes();

    this.countryCodeField.data = [...this.COUNTRIES];
    this.countryCodeField.value = 0 < this.filters.getAll('country[]').length
      ? this.filters.getAll('country[]')
      : undefined
    ;

    const createdAtStart = this.filters.get('createdAtStart');
    const createdAtEnd = this.filters.get('createdAtEnd');

    if (createdAtStart && createdAtEnd) {
      this.createdAtStartField.value = createdAtStart;
      this.createdAtEndField.value = createdAtEnd;
    }

    const shippedAtStart = this.filters.get('shippedAtStart');
    const shippedAtEnd = this.filters.get('shippedAtEnd');

    if (shippedAtStart && shippedAtEnd) {
      this.shippedAtStartField.value = shippedAtStart;
      this.shippedAtEndField.value = shippedAtEnd;
    }

    const paidAtStart = this.filters.get('paidAtStart');
    const paidAtEnd = this.filters.get('paidAtEnd');

    if (paidAtStart && paidAtEnd) {
      this.paidAtStartField.value = paidAtStart;
      this.paidAtEndField.value = paidAtEnd;
    }

    return [
      new DateField({
        label: 'PAGE.LITIGATION.LIST.FILTER.CREATED_DATE',
        fieldName: 'createdAt',
        dateRange: true,
        value: createdAtStart && createdAtEnd ? [new Date(createdAtStart), new Date(createdAtEnd)] : undefined,
        valueChangedAction: this.setCreatedAtValue.bind(this),
      }),
      this.createdAtStartField,
      this.createdAtEndField,
      new DateField({
        label: 'PAGE.LITIGATION.LIST.FILTER.SHIPPED_DATE',
        fieldName: 'shipped',
        dateRange: true,
        value: shippedAtStart && shippedAtEnd ? [new Date(shippedAtStart), new Date(shippedAtEnd)] : undefined,
        valueChangedAction: this.setShippedAtValue.bind(this),
      }),
      this.shippedAtStartField,
      this.shippedAtEndField,
      new DateField({
        label: 'PAGE.LITIGATION.LIST.FILTER.PAID_DATE',
        fieldName: 'paidAt',
        dateRange: true,
        value: paidAtStart && paidAtEnd ? [new Date(paidAtStart), new Date(paidAtEnd)] : undefined,
        valueChangedAction: this.setPaidAtValue.bind(this),
      }),
      this.paidAtStartField,
      this.paidAtEndField,
      new TextField({
        fieldName: 'paymentAmount',
        label: 'PAGE.LITIGATION.LIST.TABLE.HEAD.PAYMENT_AMOUNT',
        value: this.filters.get('paymentAmount') ? this.filters.get('paymentAmount') : undefined,
      }),
      new SkuSearchField({
        fieldName: 'sku[]',
        label: 'PAGE.ORDER.LIST.FILTER.SKU.LABEL',
        productType: 'product',
        value: 0 < this.filters.getAll('sku[]').length ? this.filters.getAll('sku[]') : undefined,
        valueField: 'sku',
        valuePrimitive: true
      }),
      this.countryCodeField,
      this.statusField,
      this.typeField,
      new MultiSearchField({
        fieldName: 'carrier[]',
        label: 'PAGE.ORDER.LIST.FILTER.CARRIERS.LABEL',
        data: SessionHelper.getAllCarrierGroups().sort((a, b) => {
          if (a.name < b.name) { return -1; }
          if (a.name > b.name) { return 1; }
          return 0;
        }),
        textField: 'name',
        valueField: 'code',
        value: 0 < this.filters.getAll('carrier[]').length
        ? this.filters.getAll('carrier[]') : undefined,
        valuePrimitive: true,
      }),
      new DropDownListField({
        fieldName: 'archived',
        label: 'PAGE.DISCOUNT.FILTER.ARCHIVING_STATUS.LABEL',
        data: this.archivingList,
        textField: 'label',
        valueField: 'value',
        value: this.filters.get('archived') ? this.filters.get('archived') : undefined,
        defaultItem: { label: this.$translate.instant('PAGE.DISCOUNT.FILTER.ARCHIVING_STATUS.DATA.ALL'), value: null },
        valuePrimitive: true,
      }),
      new DropDownListField({
        fieldName: 'credit',
        label: 'PAGE.COMMERCIAL_OPERATIONS.LIST.FILTERS.CREDIT_STATUS.LABEL',
        data: this.creditList,
        textField: 'label',
        valueField: 'value',
        value: this.filters.get('credit') ? this.filters.get('credit') : undefined,
        defaultItem: { label: this.$translate.instant('PAGE.COMMERCIAL_OPERATIONS.LIST.FILTERS.CREDIT_STATUS.DATA.ALL'), value: null },
        valuePrimitive: true,
      }),
    ];
  }

  private setCreatedAtValue(newValue: string): void {
    LitigationListFiltersService.setDates(newValue, this.createdAtStartField, this.createdAtEndField);
  }

  private setShippedAtValue(newValue: string): void {
    LitigationListFiltersService.setDates(newValue, this.shippedAtStartField, this.shippedAtEndField);
  }

  private setPaidAtValue(newValue: string): void {
    LitigationListFiltersService.setDates(newValue, this.paidAtStartField, this.paidAtEndField);
  }
}
