import { Component, Inject, Input, OnInit } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { takeUntil } from 'rxjs/operators';
import { MasterProductsWarehousesResource } from '@resources';
import { IMasterProductsWarehouses } from '@models';

@Component({
  selector: 'app-master-products-warehouses-list',
  template: require('./master-products-warehouses-list.component.html'),
  providers: [
    { provide: AbstractResource, useClass: MasterProductsWarehousesResource },
  ],
})
export class MasterProductsWarehousesListComponent extends AbstractComponent implements OnInit {

  @Input() product: string;

  public items: IMasterProductsWarehouses[] = [];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    this.resource.cGet({'product_id': this.product, 'cmup[gt]' : 0}, { returnHydraMembers: true, blocking: false })
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: IMasterProductsWarehouses[]) => {
        this.items = response;
      })
    ;
  }
}
