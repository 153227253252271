import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {IFileInfo} from '@components/generic/Form/file-uploader/interfaces/file-info.interface';
import {DATE_FULL_FORMAT, DATE_SHORT_FORMAT} from '@constants';
import moment = require('moment');

export class BlockHelper {
  static fields: any = {
    title: [
      {
        name: 'title',
        type: 'text',
      }
    ],
    mobileTitle: [
      {
        name: 'mobileTitle',
        type: 'text',
      }
    ],
    titleSeo: [
      {
        name: 'titleSeo',
        type: 'text',
      }
    ],
    subtitle: [
      {
        name: 'subtitle',
        type: 'text',
      }
    ],
    bigTitle: [
      {
        name: 'bigTitle',
        type: 'text',
      }
    ],
    description: [
      {
        name: 'description',
        type: 'rte',
      }
    ],
    metaDescriptionSeo: [
      {
        name: 'metaDescriptionSeo',
        type: 'rte',
      }
    ],
    link: [
      {
        name: 'link',
        type: 'text',
      }
    ],
    video: [
      {
        name: 'videoPath',
        type: 'text',
      }
    ],
    image: [
      {
        name: 'imageWebPath',
        type: 'readonly-image',
      },
      {
        name: 'file',
        type: 'file',
      },
    ],
    alt: [
      {
        name: 'alt',
        type: 'text',
      }
    ],
    tag: [
      {
        name: 'tag',
        type: 'text',
      }
    ],
    keyvalue: [
      {
        name: 'key',
        type: 'text',
      },
      {
        name: 'value',
        type: 'text',
      },
    ],
    boldText: [
      {
        name: 'boldText',
        type: 'text',
      }
    ],
    buttonText: [
      {
        name: 'buttonText',
        type: 'text',
      }
    ],
    borderColor: [
      {
        name: 'borderColor',
        type: 'color',
      }
    ],
    color: [
      {
        name: 'color',
        type: 'color',
      }
    ],
    position: [
      {
        name: 'position',
        type: 'number',
      }
    ],
    backgroundColor: [
      {
        name: 'backgroundColor',
        type: 'color',
      }
    ],
    buttonBackgroundColor: [
      {
        name: 'buttonBackgroundColor',
        type: 'color',
      }
    ],
    buttonColor: [
      {
        name: 'buttonColor',
        type: 'color',
      }
    ],
    active: [
      {
        name: 'active',
        type: 'checkbox',
      }
    ],
    introduction: [
      {
        name: 'introduction',
        type: 'checkbox',
      }
    ],
    bold: [
      {
        name: 'bold',
        type: 'checkbox',
      }
    ],
    dynamic: [
      {
        name: 'dynamic',
        type: 'checkbox',
      }
    ],
    startDate: [
      {
        name: 'startDate',
        type: 'single-date',
      },
      {
        name: 'startDatetime',
        type: 'single-time',
        real_name: 'startDate'
      }
    ],
    endDate: [
      {
        name: 'endDate',
        type: 'single-date',
      },
      {
        name: 'endDatetime',
        type: 'single-time',
        real_name: 'endDate'
      }
    ],
    direction: [
      {
        name: 'direction',
        type: 'select',
        data: ['left', 'middle', 'right']
      }
    ],
    mediaQuery: [
      {
        name: 'mediaQuery',
        type: 'select',
        data: ['mobile', 'desktop']
      }
    ],
    obfuscated: [
      {
        name: 'obfuscated',
        type: 'checkbox',
      }
    ],
  };

  static getForm(formBuilder: FormBuilder, properties: any[], data?: any): FormGroup {
    const controls: any = {};

    for (const property of properties) {
      for (const field of BlockHelper.fields[property]) {
        const fieldName = field.real_name ? field.real_name : field.name;

        if ('single-date' === field.type && data[field.name]) {
          controls[field.name] = data[field.name] ? moment(new Date(data[field.name])).format(DATE_SHORT_FORMAT) : null;

          continue;
        }

        if ('single-time' === field.type && data[fieldName]) {
          controls[field.name] = data[fieldName] ? new Date(data[fieldName]) : null;

          continue;
        }

        controls[field.name] = data ? data[field.name] : null;
      }
    }

    return formBuilder.group(controls);
  }

  static getControls(properties: any[]): any[] {
    const controls: any = [];

    for (const property of properties) {
      for (const field of BlockHelper.fields[property]) {
        controls.push({
          name: field.name,
          type: field.type,
          real_name: field.real_name,
          data: field.data,
        });
      }
    }

    return controls;
  }

  static getFormData(
    form: FormGroup,
    properties: string[],
    locale: string,
    businessObject: string,
    data?: any,
    product?: any,
    superProduct?: any,
    category?: any,
    commercialOperation?: any,
    room?: any,
    ownerBusinessObject?: string,
    ownerData?: any,
    additionalFormData?: any,
    isModule: boolean = false,
    filters: any = {}
  ): FormData {
    const formData: FormData = new FormData();

    if (locale) {
      formData.append('locale', locale);
    }

    formData.append('businessObject', businessObject);

    if (undefined !== data && undefined !== data.id) {
      formData.append('businessObjectId', data.id);
    }

    if (!!product && undefined !== product.id) {
      formData.append('product', product.id);
    }

    if (!!superProduct && undefined !== superProduct.id) {
      formData.append('superProduct', superProduct.id);
    }

    if (!!category && undefined !== category.id) {
      formData.append('category', category.id);
    }

    if (!!commercialOperation && undefined !== commercialOperation.id) {
      formData.append('commercialOperation', commercialOperation.id);
    }

    if (!!room && undefined !== room.id) {
      formData.append('room', room.id);
    }

    if (undefined !== ownerBusinessObject) {
      formData.append('ownerBusinessObject', ownerBusinessObject);
    }

    if (!!ownerData && undefined !== ownerData.id) {
      formData.append('owner', ownerData.id);
    }

    for (const property of properties) {
      for (const field of this.fields[property]) {
        if ((field.name !== 'file' && field.type !== 'single-date' && field.type !== 'single-time') && form
          && (field.type === 'checkbox' || (field.type !== 'checkbox' && (form.value[field.name] || '' === form.value[field.name])))
        ) {
          formData.append(field.name, field.type === 'checkbox' ? +form.value[field.name] : form.value[field.name]);
        }

        if (field.type === 'single-date' && form) {
          let fieldTimeName = this.fields[property].find((elem: any) => (elem.real_name && elem.real_name === field.name));
          fieldTimeName = fieldTimeName ? fieldTimeName.name : field.name;

          if (field.name === 'startDate') {
            formData.append(field.name,
              form.value[field.name] && form.value[fieldTimeName] ?
                moment(form.value[field.name], DATE_SHORT_FORMAT)
                  .startOf('day')
                  .add(form.value[fieldTimeName].getHours(), 'hours')
                  .add(form.value[fieldTimeName].getMinutes(), 'minutes')
                  .format(DATE_FULL_FORMAT) : form.value[field.name] ? moment(form.value[field.name], DATE_SHORT_FORMAT)
                  .startOf('day')
                  .format(DATE_FULL_FORMAT) : ''
            );
          }
          if (field.name === 'endDate') {
            formData.append(field.name,
              form.value[field.name] && form.value[fieldTimeName] ?
                moment(form.value[field.name], DATE_SHORT_FORMAT)
                  .startOf('day')
                  .add(form.value[fieldTimeName].getHours(), 'hours')
                  .add(form.value[fieldTimeName].getMinutes(), 'minutes')
                  .seconds(59)
                  .format(DATE_FULL_FORMAT) : form.value[field.name] ? moment(form.value[field.name], DATE_SHORT_FORMAT)
                  .endOf('day')
                  .format(DATE_FULL_FORMAT) : ''
            );
          }
        }
      }
    }

    for (const key of Object.keys(filters)) {
      formData.append(key, filters[key]);
    }

    if (form && form.value.file && form.value.file.subscriber) {
      form.value.file.subscriber.subscribe((fileList: IFileInfo[]) => {
        formData.append('file', <File>fileList[0].rawFile, fileList[0].name);
      });
    }

    if (!!additionalFormData) {
      for (const key of Object.keys(additionalFormData)) {
        formData.append(key, additionalFormData[key]);
      }
    }

    formData.append('isModule', (+isModule).toString());

    return formData;
  }

  static isFormEmpty(form: FormGroup, properties: any): boolean {
    for (const property of properties) {
      for (const field of this.fields[property]) {
        if (form.value[field.name]) {
          return false;
        }
      }
    }

    return true;
  }

  static getFilters(config: any, overrideField: string, product?: any, category?: any) {
    const filters: any = {};

    if (product) {
      if (config[overrideField]) {
        filters['product.id'] = product.id;
      } else {
        filters['superProduct.id'] = product.superProduct.id;
      }
    } else if (category) {
      if (config[overrideField]) {
        filters['category.id'] = category.id;
      } else {
        filters['exists[category]'] = false;
      }
    }

    return filters;
  }
}
