'use strict'

import { DATE_FULL_FORMAT, HEADER_HEIGHT } from '@constants'
import { MarketplaceHelper, SessionHelper } from '@helpers'
import * as moment from 'moment'

###
  @name ProductBulkEditorCmp
###
angular
  .module('ammsFrontendApp')
  .factory('ProductBulkEditorService', [
    '$rootScope'
    ($rootScope) ->
      this.REFRESH_BULK_EDITOR = 'bulk-refresh'
      this.REFRESHED_BULK_EDITOR = 'bulk-refreshed'
      this.floatTheadOptions = {
        position: 'absolute',
        top: HEADER_HEIGHT
      }

      ###
        Fires an event about product selection/deselection
      ###
      this.refreshBulkEditor = => $rootScope.$broadcast(this.REFRESH_BULK_EDITOR)

      ###
        Fires an event about bulk editor refresh finished

        @param {array} selection - selected sku
      ###
      this.refreshedBulkEditor = (selection) =>
        $rootScope.$broadcast(this.REFRESHED_BULK_EDITOR, { selection })

      return this
  ])
  .directive('productBulkEditor', [
    'ProductBulkEditorService',
    'ProductRowECommerceService',
    (ProductBulkEditorService, ProductRowECommerceService) ->
      replace: true
      restrict: 'A'
      templateUrl: require('views/products/list/bulk-editor.html')
      controller: 'ProductBulkEditorCtrl'
      controllerAs: 'bulk'
      bindToController:
        products: '='
        afterSave: '&'
      scope: true
      link: (scope, element, attrs, bulk) ->
        scope.$on(ProductBulkEditorService.REFRESH_BULK_EDITOR, bulk.refreshSelection)
        scope.$on(ProductRowECommerceService.INLINE_PRODUCTION_EDITION_START, bulk.cancel)
  ])
  .controller('ProductBulkEditorCtrl', [
    '$dialog',
    '$translate',
    '$window',
    'ProductBulkEditorService',
    'ProductResource',
    'ProductMarketplaceStatusResource',
    (
      $dialog,
      $translate,
      $window,
      ProductBulkEditorService,
      ProductResource,
      ProductMarketplaceStatusResource
    ) ->
      marketplacesNotToSelect = ['BP', 'GO', 'BETA', 'SHOW', 'YOUKADO']
      this.checkAll = false
      this.marketplaces = MarketplaceHelper.getDisplayedOnProductPageMarketplaces()
        .filter((marketplace) ->
          return !['moncornerbrico', 'moncornerdeco', 'moncornerjardin'].includes(marketplace.code)
        )
        .map((marketplace) -> return {
          code: marketplace.code,
          name: marketplace.name,
          parent: marketplace.parent?.code
        })
      this.model = {}
      this.selectedProducts = []
      this.availableProductMarketplaceStatuses = []

      this.$onInit = =>
        subscriber = ProductMarketplaceStatusResource.getManyWithLabel()
          .subscribe((productMarketplaceStatusResponse) =>
              this.availableProductMarketplaceStatuses = productMarketplaceStatusResponse
            , null
            , -> subscriber.unsubscribe()
          )

      ###
        Refresh selected products
      ###
      this.refreshSelection = =>
        this.selectedProducts = this.products.filter((product) -> return product.selected)
          .map((product) -> product.sku)
        ProductBulkEditorService.refreshedBulkEditor(this.selectedProducts)

      ###
        Checks if the module has to be displayed or not

        @returns {boolean}
      ###
      this.shouldDisplay = =>
        ProductBulkEditorService.floatTheadOptions.top = HEADER_HEIGHT + (angular.element('.bulk-editor--fixed').eq(0).height() || 0)
        return !!this.products && !!this.products.length && !!this.selectedProducts && !!this.selectedProducts.length

      this.shouldFix = ->
        return angular.element(document).scrollTop() > (angular.element('.bulk-editor').offset().top - HEADER_HEIGHT)

      ###
        Removes a product from selection

        @param {string} sku
      ###
      this.deselect = (sku) =>
        this.products.forEach((product) ->
          if (sku == product.sku)
            delete product.selected
        )
        this.refreshSelection()

      ###
        Selects/deselects all marketplaces
      ###
      this.toggleAllMarketplace = =>
        this.marketplaces.forEach((marketplace) =>
          if (this.checkAll && !marketplacesNotToSelect.includes(marketplace.name))
            marketplace.selected = true
          else
            delete marketplace.selected
        )

      ###
        Checks if all marketplaces are selected or not
      ###
      this.checkAllMarketplaceStatus = =>
        this.checkAll = this.marketplaces.length == this.marketplaces.filter((marketplace) ->
            return marketplace.selected
          ).length

      ###
        Cancel the edition
      ###
      this.cancel = =>
        this.model = {}
        this.products.forEach((product) ->
          delete product.selected
        )
        this.refreshSelection()

      ###
        Save all products
      ###
      this.save = =>
        selectedMarketplace = this.marketplaces.filter((marketplace) -> return marketplace.selected)
          .map((marketplace) -> marketplace.code)

        if (0 == selectedMarketplace.length)
          $dialog.alert($translate.instant('PAGE.PRODUCT.LIST.TAB.ECOMMERCE.BULK_EDITOR.ALERTS'))
        else
          request = {}
          this.products.filter((product) => this.selectedProducts.includes(product.sku))
            .forEach((product) ->
              selectedMarketplace.forEach((marketplace) ->
                prepareRequestItem(request, product.id, marketplace)
              )
            )

          # launch request if needed
          if (Object.keys(request).length)
            ProductResource.batchUpdate(request)
              .then(this.afterSave)

      ###
        Exports selected products
      ###
      this.export = =>
        request = {
          productIds: this.products.filter((product) => this.selectedProducts.includes(product.sku))
            .map((product) -> product.id),
          locale: SessionHelper.getLocale(),
        }

        ProductResource.export(request, { responseType: 'blob', dontUseModel: true })
          .then((response) ->
            reader = new FileReader()
            reader.readAsDataURL(response)

            reader.addEventListener('loadend', ->
              opener = $window.document.createElement('a')
              opener.href = reader.result

              opener.download = 'products_export_' + moment().format(DATE_FULL_FORMAT) + '.csv'

              document.body.appendChild(opener)
              opener.click()

              document.body.removeChild(opener)
            )
        )

      ###
        Prepare a productMarketplace item for patch

        @param {object} target
        @param {string} productId
        @param {string} marketplace
      ###
      prepareRequestItem = (model, productId, marketplace) =>
        if ('undefined' == typeof model[productId])
          model[productId] = { productMarketplaces: {} }

        # copy model && convert shippingDate to remove Timezone
        shippingDate = this.model.shippingDate
        if (shippingDate)
          shippingDate = { shippingDate: moment(shippingDate).format(DATE_FULL_FORMAT) }
        else if (this.model.dateReset)
          shippingDate = { shippingDate: null }
        else
          shippingDate = {}

        model[productId].productMarketplaces[marketplace] = Object.assign(
          {},
          this.model,
          shippingDate
        )

        delete model[productId].productMarketplaces[marketplace].dateReset

      return this
  ])
