import { NgModule } from '@angular/core';
import { OrderCollectionListComponent } from '@components/order-collection/list/order-collection-list.component';
import { OrderCollectionResource } from '@components/order-collection/order-collection.resource';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GenericModule } from '@components/generic';
import { PaginationModule } from 'ngx-bootstrap';
import { ComboBoxModule } from '@progress/kendo-angular-dropdowns';
import { OrderCollectionResultListComponent } from '@components/order-collection/list';
import { OrderCollectionFiltersFormService } from '@components/order-collection/services/order-collection-filters-form.service';

@NgModule({
  entryComponents: [
    OrderCollectionListComponent,
  ],
  declarations: [
    OrderCollectionListComponent,
    OrderCollectionResultListComponent
  ],
  providers: [
    OrderCollectionResource,
    OrderCollectionFiltersFormService,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    GenericModule,
    PaginationModule,
    ComboBoxModule,
  ],
})

export class OrderCollection {}
