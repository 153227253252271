import { ICategories, ICategoriesTranslation, IHreflang, IRelations } from '@components/categories/models/categories.interface';
import { ISuperProductsCategories } from '@components/super-product-categories/models/super-product-categories.interface';
import { CODE_FR } from '@constants';
import { SessionHelper } from '@helpers';

export class CategoriesModel implements ICategories {
  '@id': string;
  id: string;
  active: boolean;
  level?: number;
  parent?: ICategories|string;
  translations: ICategoriesTranslation[];
  hreflangs?: IHreflang[];
  relations?: IRelations[];
  seoMetaRobot: string;
  defaultSorting: string;
  marketplace: string;
  superProductCategories?: ISuperProductsCategories[];
  featuredProduct?: string;
  startDate: string;
  endDate: string;
  position: number;

  constructor(categories: ICategories = {
    '@id': '',
    id: '',
    active: false,
    level: null,
    parent: null,
    translations: null,
    hreflangs: null,
    relations: null,
    seoMetaRobot: '',
    defaultSorting: '',
    marketplace: '',
    superProductCategories: null,
    featuredProduct: '',
    startDate: '',
    endDate: '',
    position: null,
  }) {
    if ('' === categories.defaultSorting) {
      categories.defaultSorting = 'default_merchandising';
    }
    this['@id'] = categories['@id'];
    this.id = categories.id;
    this.active = categories.active;
    this.level = categories.level;
    this.parent = categories.parent;
    this.seoMetaRobot = categories.seoMetaRobot;
    this.defaultSorting = categories.defaultSorting;
    this.marketplace = categories.marketplace;
    this.translations = categories.translations ? this.formatTranslations(categories.translations) : [];
    this.hreflangs = categories.hreflangs;
    this.relations = categories.relations;
    this.superProductCategories = categories.superProductCategories;
    this.featuredProduct = categories.featuredProduct;
    this.startDate = categories.startDate;
    this.endDate = categories.endDate;
    this.position = categories.position;
  }

  private formatTranslations(translations: any): ICategoriesTranslation[] {
    return Object.keys(translations).map((locale: any) => {
      return {
        locale: translations[locale].locale,
        id: translations[locale]['@id'],
        slug: translations[locale].slug,
        longDescription: translations[locale].longDescription,
        shortDescription: translations[locale].shortDescription,
        name: translations[locale].name,
        lengowName: translations[locale].lengowName,
        metaDescriptionSeo: translations[locale].metaDescriptionSeo,
        titleSeo: translations[locale].titleSeo,
        contentBlock: translations[locale].contentBlock,
        contentBlockTitle: translations[locale].contentBlockTitle,
      };
    });
  }
}
