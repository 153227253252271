import { AbstractFormFieldBase } from './abstract-form-field-base.class';
import { DATE_SHORT_INTERNATIONAL_FORMAT } from '@constants';

/**
 * Date field, used to render input field with date picker plugin.
 */
export class DateField extends AbstractFormFieldBase<string> {
  /**
   * Used in the template to render the date field.
   *
   * @type {string}
   */
  fieldType = 'date';

  /**
   * When use with a date picker plugin, should be equal to text.
   */
  type: string;

  /**
   * Generally using with short international format (YYYY-MM-DD).
   */
  dateFormat: string;

  /**
   * Choose between date range picker or simple picker.
   */
  dateRange: boolean;

  minDate: string|Date;

  constructor(options: {[key: string]: any} = {}) {
    super(options);

    this.type = <string>options.type || 'text';
    this.dateFormat = <string>options.dateFormat || DATE_SHORT_INTERNATIONAL_FORMAT;
    this.dateRange = false !== <boolean>options.dateRange;
    this.minDate = <string|Date>options.minDate;
  }
}
