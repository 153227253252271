import {Component, EventEmitter, Inject, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import {
  AbstractResource,
} from '@resources';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { SnackbarService } from '@components/snackbar';
import { FormNotifierService } from '@services';
import {
  IProductForm
} from '@components/product/interfaces/product-form.interface';
import { ProductResource } from '@components/product/product.resource';
import { INPUT_NUMBER_PATTERN_DEC } from '@constants/form';
import { NumberHelper } from '@helpers/NumberHelper';
import {IFileInfo} from '@components/generic/Form/file-uploader/interfaces/file-info.interface';
import {takeUntil} from 'rxjs/operators';
import {IImage} from '@components/generic/List';
import {CeStandardResource} from '@components/product/form/tab-purchase-service/ce-standard.resource';
import {FileRestrictions} from '@progress/kendo-angular-upload';

@Component({
  selector: 'app-purchase-service-form',
  template: require('./purchase-service-form.component.html'),
  providers: [
    { provide: AbstractResource, useClass: ProductResource },
    CeStandardResource
  ],
  styles: [require('./purchase-service-form.component.scss')],
  encapsulation: ViewEncapsulation.None
})
export class PurchaseServiceFormComponent  extends AbstractPageComponent implements OnInit {

  public form: FormGroup;
  public formCeStandardTypeImg: FormGroup;
  public formCeStandardTypePdf: FormGroup;
  public isSparePart: boolean = false;
  public userPictures: Array<any> = [];
  public ceStandardTypeImage: Array<any> = [];
  public ceStandardTypeDoc: Array<any> = [];
  public fileImageRestrictions: FileRestrictions = { allowedExtensions: ['.jpg', '.jpeg', '.png', '.JPG', '.JPEG', '.PNG'] };
  public fileDocRestrictions: FileRestrictions = { allowedExtensions: ['.pdf', '.PDF'] };
  public formCeStandard: FormGroup;
  public isSwitchOn: boolean = false;
  public isReturnOneForOneClicked: boolean = false;

  @Output() public onDelete: EventEmitter<object> = new EventEmitter();

  @Input() model: IProductForm;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    private snackbar: SnackbarService,
    private formNotifier: FormNotifierService,
    private ceStandardResource: CeStandardResource,

    @Inject('DialogService') private dialog: any,
  ) {
    super($translate, authService, resource, state);

    this.formCeStandardTypeImg = this.formBuilder.group({
      superproductId: [this.state.params.id],
      file: [this.userPictures]
    });

    this.formCeStandardTypePdf = this.formBuilder.group({
      superproductId: [this.state.params.id],
      file: [this.userPictures]
    });

  }

  /**
   * @inheritDoc
   */
  ngOnInit(): void {
    if (this.state.current.name === 'spare-part.edit.purchase-service') {
      this.isSparePart = true;
    }
    this.formCeStandard = this.formBuilder.group({
      active: [false],
    });
    this.formCeStandard.get('active').valueChanges.subscribe(value => {
      this.isSwitchOn = value;
    });
    this.buildForm();
    this.fillForm();

    this.resource.cGet({ product: this.state.params.id },
      { entryPoint: '/v2/ce_standards', returnHydraMembers: true,  isHydra: true, dontUseModel: true })
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
          for (const item of response) {
            this.pushImageToCeStandard(item);
          }

        this.formCeStandard.get('active').setValue(this.ceStandardTypeImage.length > 0 || this.ceStandardTypeDoc.length > 0);
      })
    ;
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      cmup: ['', Validators.required],
      internalNotes: [''],
      internalNotesOverrideContent: [''],
      deee: ['', Validators.pattern(INPUT_NUMBER_PATTERN_DEC)],
      deeeHT: [''],
      ecotax: ['', Validators.pattern(INPUT_NUMBER_PATTERN_DEC)],
      ecotaxHT: [''],
      returnOneForOne: [null]
    });
  }

  public onReturnOneForOneChanged(event: any): void {
    this.isReturnOneForOneClicked = true;
  }

  private fillForm(): void {
    this.form.patchValue(this.model);
  }

  public submit(event?: any): void {
    this.dialog.confirm(this.translate('PAGE.PRODUCT.CONFIRM.UPDATE'))
      .then(() => {
        const file = this.formCeStandardTypeImg.value.file;
        const file2 = this.formCeStandardTypePdf.value.file;
        const errors: any = [];

        this.checkFile(file, 'UPLOADER.INVALID_EXTENSION', errors);
        this.checkFile(file2, 'UPLOADER.INVALID_EXTENSION_PDF', errors);

        if (errors.length > 0) {
          errors.forEach((error: string) => {
            this.snackbar.warn(this.translate(error));
          });

          return;
        }

        this.saveFile(file, file.name, 'image');
        this.saveFile(file2, file2.name , 'document');
        const id: string = this.state.params.id;

        const parameters: any = {
          internalNotes: this.form.value.internalNotes,
          internalNotesOverrideContent: this.form.value.internalNotesOverrideContent,
          ecotax: NumberHelper.parseFloat(this.form.value.ecotax),
          deee: NumberHelper.parseFloat(this.form.value.deee)
        };

        if (this.isReturnOneForOneClicked) {
          parameters.returnOneForOne = this.form.value.returnOneForOne;
        }

        this.resource.update(id, parameters, { entryPoint: `/v2/products/${id}` })
          .takeUntil(this.destroyed$)
          .subscribe(() => {
            this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));

            if (event && event.redirect) {
              if (this.state.current.name === 'spare-part.edit.content') {
                this.state.go('spare-part.list');
              } else {
                this.state.go(`${this.resource.routeName}.list`);
              }
              return;
            }

            this.formNotifier.notifyFormSubmitted();

            if (this.state.current.name === 'spare-part.edit.purchase-service') {
              this.state.go('spare-part.edit.purchase-service',  { id });
            } else {
              this.state.go(`${this.resource.routeName}.edit.purchase-service`, { id });
            }
          })
        ;
      })
    ;
  }

  public saveFile(file: any, filename: string, type: string) {
    let files: IFileInfo[] = [];

    if (file.subscriber) {
      file.subscriber.subscribe((fileList: IFileInfo[]) => {
        files = fileList;
      });

      const rawFile = files[0].rawFile;
      const formData: FormData = new FormData();

      formData.append('file', rawFile, filename);

      this.resource.uploadFile(formData, {
        entryPoint: '/v2/ce_standards/' + this.state.params.id + '?typeOfFile=' + type
      })
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response: any) => {
          this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
        })
      ;
    }

  }
  public delete(images: any[]): void {
    for (const image of images) {
      this.ceStandardResource.remove(image.id).pipe(takeUntil(this.destroyed$)).subscribe();
    }
  }

  public pushImageToCeStandard(response: any) {
    const image: IImage = {
      id: response.id,
      fileName: response.fileName,
      originalPath: response.originalPath,
    };
    'image' === response.typeOfFile ? this.ceStandardTypeImage.push(image) : this.ceStandardTypeDoc.push(image);
  }

  public checkFile(file: any, errorMessage: any, errors: any) {
    const fileRestrictions = errorMessage === 'UPLOADER.INVALID_EXTENSION' ?  this.fileImageRestrictions : this.fileDocRestrictions;

    if (file.subscriber) {
      file.subscriber.subscribe((fileList: any[]) => {
        fileList.map((oneFile: any) => {
          if (!fileRestrictions.allowedExtensions.includes(oneFile.extension)) {
            errors.push(errorMessage);
          }
        });
      });
    }
  }
}
