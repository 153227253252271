import { IUsers } from '@components/users/models/users.interface';
import { ICountry } from '@interfaces/ICountry';
import {IWarehouses} from '@components/warehouses/models';

export class UsersModel implements IUsers {
  id?: string;
  allRoles?: string[];
  selectableRoles?: string[];
  countries: ICountry[];
  email: string;
  locale: string;
  roles: string[];
  warehouses: IWarehouses[];
  username?: string;
  plainPassword?: string;
  [key: string]: any;

  constructor(users: IUsers = {
    id: '',
    allRoles: [],
    selectableRoles: [],
    countries: [],
    warehouses: [],
    email: '',
    locale: '',
    roles: [],
    username: '',
    plainPassword: '',
  }) {
    this.id = users.id;
    this.allRoles = users.allRoles;
    this.selectableRoles = users.selectableRoles;
    this.countries = users.countries;
    this.email = users.email;
    this.locale = users.locale;
    this.roles = users.roles;
    this.warehouses = users.warehouses;
    this.username = users.username;
    this.plainPassword = users.plainPassword;
  }
}
