import { BANNER_TYPE, THUMBNAIL_TYPE, TAB_TYPE, COMMERCIAL_OPERATION_BANNER_TYPE } from '@constants';

export class ImageTypeHelper {
    /**
     * Checks if passed param is the same as BANNER_TYPE constant
     *
     * @static
     * @param {string} type
     * @returns {boolean}
     * @memberof ImageTypeHelper
     */
    static isBannerType(type: string): boolean {
        return BANNER_TYPE === type;
    }

    /**
     * Checks if passed param is the same as THUMBNAIL_TYPE constant
     *
     * @static
     * @param {string} type
     * @returns {boolean}
     * @memberof ImageTypeHelper
     */
    static isThumbnailType(type: string): boolean {
        return THUMBNAIL_TYPE === type;
    }

    /**
     * Checks if passed param is the same as TAB_TYPE constant
     *
     * @static
     * @param {string} type
     * @returns {boolean}
     * @memberof ImageTypeHelper
     */
    static isTabType(type: string): boolean {
        return TAB_TYPE === type;
    }

    /**
     * Checks if passed param is the same as COMMERCIAL_OPERATION_BANNER_TYPE constant
     *
     * @static
     * @param {string} type
     * @returns {boolean}
     * @memberof ImageTypeHelper
     */
    static isCmlOperationBannerType(type: string): boolean {
        return COMMERCIAL_OPERATION_BANNER_TYPE === type;
    }
}
