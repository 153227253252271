import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '@services';
import { RABBIT_MQ_PW, RABBIT_MQ_USER } from '@constants/config.constants';

/**
 * Append Bearer to header if user is authenticated.
 */
@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {

  /**
   * @inheritDoc
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = AuthService.getToken();

    if (token && !req.url.includes('/api/queues')) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        }
      });
    } else if (req.url.includes('/api/queues')) {
      req = req.clone({
        setHeaders: {
          Authorization: `Basic ${window.btoa(RABBIT_MQ_USER + ':' + RABBIT_MQ_PW)}`,
        }
      });
    }

    return next.handle(req);
  }
}
