import { IHighlights, IHighlightsProductMarketplace } from '@components/website-homepage';
import { DATE_SHORT_FORMAT } from '@constants/date';
import * as moment from 'moment';

export class HighlightsModel implements IHighlights {
  id: string;
  description: string;
  highDescriptionColor: string;
  link: string;
  locale: string;
  lowDescription: string;
  lowDescriptionColor: string;
  position: number;
  productMarketplace: IHighlightsProductMarketplace;
  skuLabel: string;
  title: string;
  titleColor: string;
  webPath: string;
  sku: string;
  dateStart: string;
  startTime?: any;
  dateEnd: string;
  endTime?: any;
  alt?: any;

  constructor(highlights: IHighlights) {
    this.id = highlights.id ;
    this.description = highlights.description ? highlights.description : '';
    this.highDescriptionColor = highlights.highDescriptionColor ? highlights.highDescriptionColor : '#000000';
    this.link = highlights.link ? highlights.link : '';
    this.locale = highlights.locale;
    this.lowDescription = highlights.lowDescription ? highlights.lowDescription : '';
    this.lowDescriptionColor = highlights.lowDescriptionColor ? highlights.lowDescriptionColor : '#000000';
    this.position = highlights.position;
    this.productMarketplace = highlights.productMarketplace;
    this.skuLabel = highlights.productMarketplace ? highlights.productMarketplace.product.sku : null;
    this.sku = highlights.productMarketplace ? highlights.productMarketplace.id : '';
    this.title = highlights.title ? highlights.title : '';
    this.alt = highlights.alt ? highlights.alt : '';
    this.titleColor = highlights.titleColor ? highlights.titleColor : '#000000';
    this.webPath = highlights.webPath;
    this.dateStart = highlights.dateStart ? moment(highlights.dateStart).startOf('day').format(DATE_SHORT_FORMAT) : null;
    this.startTime = highlights.dateStart ? new Date(highlights.dateStart) : null;
    this.dateEnd = highlights.dateEnd ? moment(highlights.dateEnd).endOf('day').format(DATE_SHORT_FORMAT) : null;
    this.endTime = highlights.dateEnd ? new Date(highlights.dateEnd) : null;
  }
}
