import { IExport } from './export.interface';

export class ExportModel implements IExport {
  public exportCode: string;
  public formatCode: string;
  public dryRun: boolean;
  public batchSize: Number;
  public deferred: boolean;
  public split: boolean;
  public async: boolean;
  public limitFields: any;
  public serializationGroups: string[];

  constructor(object: IExport) {
    this.exportCode = object.exportCode;
    this.formatCode = object.formatCode;
    this.dryRun = object.dryRun;
    this.batchSize = object.batchSize;
    this.deferred = object.deferred;
    this.split = object.split;
    this.async = object.async;
    this.limitFields = object.limitFields;
    this.serializationGroups = object.serializationGroups;
  }
}
