import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericModule } from '@components/generic/generic.module';
import { RabbitMqComponent } from './';
import { RabbitMqResource } from './rabbit-mq.resource';
import { PopoverModule } from 'ngx-bootstrap';


@NgModule({
  declarations: [
    RabbitMqComponent,
  ],
  entryComponents: [
    RabbitMqComponent,
  ],
  imports: [
    CommonModule,
    PopoverModule.forRoot(),
    GenericModule,
  ],
  providers: [
    RabbitMqResource,
  ],
  exports: [
    RabbitMqComponent,
  ]
})
export class RabbitMqModule {}
