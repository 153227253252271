import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'custom-snack-bar',
  template: require('./custom-snack-bar.component.html'),
  styles: [require('./custom-snack-bar.component.scss')],
})
export class CustomSnackBarComponent {

  @Input() public errorMessage: string = '';
  @Input() public class: string = '';
  @Output() public onClick: EventEmitter<any> = new EventEmitter();

}
