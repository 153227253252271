import { Component, Inject, Input, AfterViewInit, ViewChild, TemplateRef, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { IColumn } from '@components/generic/grid/interfaces/column.interface';
import { AbstractComponent } from '@components/generic/abstract.component';
import { ILogisticRow, IStock, IProduct } from '@components/product/interfaces';
import { cloneDeep } from 'lodash';
import { AuthService } from '../../../../services/auth.service';
import { SessionHelper } from '@helpers';
import { HttpParams } from '@angular/common/http';
import { HttpEncoderService } from '@services';

const DISCRIMINATOR_PRODUCT = 'product';
const DISCRIMINATOR_PACKAGE = 'package';

@Component({
  selector: 'app-logistic',
  template: require('./logistic.component.html'),
  styles: [require('./logistic.component.scss')]
})
export class LogisticComponent extends AbstractComponent implements AfterViewInit, OnChanges {

  @ViewChild('skuTemplate') public skuTemplate: TemplateRef<any>;
  @ViewChild('packagesTemplate') public packagesTemplate: TemplateRef<any>;
  @ViewChild('saTemplate') public saTemplate: TemplateRef<any>;
  @ViewChild('seTemplate') public seTemplate: TemplateRef<any>;

  /**
   * Holds the informations about the products
   */
  @Input() public list: any;

  /**
   * Defines the columns of the table
   */
  public columns: IColumn[];
  public products: (IProduct | ILogisticRow)[];

  private static getDirectionFilter(params: URLSearchParams, param: string): string {
    const defaultDirection = 'none';

    if (params.get('direction') && params.get('orderBy')) {
      return params.get('orderBy') === param ? params.get('direction') : defaultDirection;
    }

    return defaultDirection;
  }

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, null, state);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.list.currentValue) {
      this.products = cloneDeep(changes.list.currentValue.products ? changes.list.currentValue.products : changes.list.currentValue);
      // calculate the gap between sage and warehouse stock for SE
      this.products.forEach((product: any) => {
        const details: any = {};
        let warehouseStockDiff: number = 0;
        this.createDetailsStocks('product', product, details);
        Object.values(details).forEach((detail: ILogisticRow) => {
          if (detail.warehouseStockDiff) {
            warehouseStockDiff += detail.warehouseStockDiff.warehouse - detail.warehouseStockDiff.sage;
          }
        });
        if (warehouseStockDiff) {
          product.warehouseStock += ` (${warehouseStockDiff})`;
        }

        let quantityInStockDiff: number = 0;
        this.createDetailsStocks('product', product, details);
        Object.values(details).forEach((detail: ILogisticRow) => {
          if (detail.quantityInStockDiff) {
            quantityInStockDiff += detail.quantityInStockDiff.warehouse - detail.quantityInStockDiff.sage;
          }
        });
        if (quantityInStockDiff) {
          product.quantityInStock += ` (${quantityInStockDiff})`;
        }
      });
    }
  }

  /**
   * Initiates the table.
   */
  ngAfterViewInit(): void {
    const filters = !!this.encodeParams(this.state.params).toString() ? this.state.params : false;
    const filtersInStorage = SessionHelper.getFiltersForPage((<any>this.state.$current).parent.self.name);
    const paramsFrom = filters ?
      filters :
      filtersInStorage ?
        filtersInStorage :
        {}
    ;
    const params = new URLSearchParams(paramsFrom ? this.encodeParams(paramsFrom).toString() : null);

    this.columns = [
      {
        name: this.translate('PAGE.PRODUCT.LIST.TAB.LOGISTIC.TABLE.HEAD.SKU'),
        field: 'sku',
        sortable: true,
        direction: LogisticComponent.getDirectionFilter(params, 'sku'),
        template: this.skuTemplate
      },
      {
        name: this.translate('PAGE.PRODUCT.LIST.TAB.LOGISTIC.TABLE.HEAD.PACKAGE'),
        field: 'package',
        template: this.packagesTemplate
      },
      {
        name: this.translate('PAGE.PRODUCT.LIST.TAB.LOGISTIC.TABLE.HEAD.WEIGHT'),
        field: 'weight'
      },
      {
        name: this.translate('PAGE.PRODUCT.LIST.TAB.LOGISTIC.TABLE.HEAD.CUBAGE'),
        field: 'm3',
      },
      {
        name: this.translate('PAGE.PRODUCT.LIST.TAB.LOGISTIC.TABLE.HEAD.STOCK'),
        field: 'stock',
        nestedHeaders: [
          {
            name: this.translate('PAGE.PRODUCT.LIST.TAB.LOGISTIC.TABLE.HEAD.SE'),
            field: 'warehouseStock',
            sortable: true,
            direction: LogisticComponent.getDirectionFilter(params, 'warehouseStock'),
            template: this.seTemplate,
          },
          {
            name: this.translate('PAGE.PRODUCT.LIST.TAB.LOGISTIC.TABLE.HEAD.SA'),
            field: 'prepared',
            template: this.saTemplate,
            sortable: true,
            direction: LogisticComponent.getDirectionFilter(params, 'prepared'),
          },
          {
            name: this.translate('PAGE.PRODUCT.LIST.TAB.LOGISTIC.TABLE.HEAD.SP'),
            field: 'sold',
            sortable: true,
            direction: LogisticComponent.getDirectionFilter(params, 'sold'),
          },
          {
            name: this.translate('PAGE.PRODUCT.LIST.TAB.LOGISTIC.TABLE.HEAD.SS'),
            field: 'quantityInStock',
            sortable: true,
            direction: LogisticComponent.getDirectionFilter(params, 'quantityInStock'),
          }
        ]
      },
    ];
  }

  /**
   * Changes the product edition state.
   */
  public goToProduct(productId: number): void {
    this.state.go('product.edit', { id: productId });
  }

  /**
   * Manages the opening and closing stock details of the logistic table.
   * Delegates row creation
   */
  public expandRow(row: IProduct, index: number): void {
    if (this.products[index].expanded) {
      // remove expanded product stock details
      this.products.splice(index + 1, this.products[index].expanded);
      this.products[index].expanded = 0;
    } else {
      // add expanded product stock details
      let sortedDetails: ILogisticRow[];
      const details: { [keys: string]: ILogisticRow } = {};

      this.createDetailsStocks('product', row, details);
      this.createDetailsStocks('package', row, details);

      sortedDetails = this.sortByWarehouse(Object.values(details));

      this.products.splice(index + 1, 0, ...sortedDetails);
      this.products[index].expanded = sortedDetails.length;
    }
  }

  /**
   * Creates the details rows
   * Delegates stocks information filling.
   */
  public createDetailsStocks(stockType: string, row: IProduct, details: { [keys: string]: ILogisticRow }): void {
    if (stockType === 'product') {
      const productStocks: IStock[] = row.stocks.filter((stock: IStock) => (stock.discriminator === DISCRIMINATOR_PRODUCT));
      productStocks.forEach((stock: IStock) => {
        const detail: ILogisticRow = details[stock.warehouse] || {
          sku: stock.warehouse,
          package: null,
          weight: row.weight,
          m3: row.m3,
          details: true,
          discriminator: stock.discriminator,
          warehouseStock: null,
          prepared: null,
          sold: null,
          quantityInStock: null,
        };
        this.fillStockDetails(detail, stock);
        details[stock.warehouse] = detail;
      });
    } else if (stockType === 'package') {
      if (row.packages.length > 1) {
        const packagesStocks: IStock[] = row.stocks.filter((stock: IStock) => (stock.discriminator === DISCRIMINATOR_PACKAGE));
        packagesStocks.forEach((stock: IStock) => {
          const detail: ILogisticRow = details[`${stock.warehouse}_${stock.sku}_${DISCRIMINATOR_PACKAGE}_${stock.number}`] || {
            sku: stock.warehouse,
            package: `${stock.sku} ${stock.number}/${stock.total}`,
            weight: row.weight,
            m3: row.m3,
            details: true,
            discriminator: stock.discriminator,
            warehouseStock: null,
            sold: null,
            prepared: null,
            quantityInStock: null,
          };
          this.fillStockDetails(detail, stock);
          details[`${stock.warehouse}_${stock.sku}_${DISCRIMINATOR_PACKAGE}_${stock.number}`] = detail;
        });
      }
    }
  }

  /**
   * Fills the stock details
   */
  public fillStockDetails(detail: ILogisticRow, stock: IStock) {
    switch (stock.type) {
      case 'SS':
        if (
          detail.discriminator === DISCRIMINATOR_PRODUCT
          && detail.quantityInStock !== null
          && detail.quantityInStock !== stock.quantity
        ) {
          if (stock.origin === 'warehouse') {
            detail.quantityInStockDiff = {
              sage: stock.quantity,
              warehouse: detail.quantityInStock
            };
          } else {
            detail.quantityInStockDiff = {
              sage: detail.quantityInStock,
              warehouse: stock.quantity
            };
          }
        } else {
          detail.quantityInStock = stock.quantity;
        }
        break;
      case 'SE':
        if (
          detail.discriminator === DISCRIMINATOR_PRODUCT
          && detail.warehouseStock !== null
          && detail.warehouseStock !== stock.quantity
        ) {
          if (stock.origin === 'sage') {
            detail.warehouseStockDiff = {
              sage: stock.quantity,
              warehouse: detail.warehouseStock
            };
          } else {
            detail.warehouseStockDiff = {
              sage: detail.warehouseStock,
              warehouse: stock.quantity
            };
          }
        } else {
          detail.warehouseStock = stock.quantity;
        }
        break;
      case 'SA':
        detail.prepared = stock.quantity;
        break;
      case 'SP':
        detail.sold = stock.quantity;
        break;
      default:
        break;
    }
  }

  /**
   * Sorts the details rows by warehouse in order to keep product stock lines close to packages stock lines.
   */
  public sortByWarehouse(details: ILogisticRow[]): ILogisticRow[] {
    return details.sort(function (a: ILogisticRow, b: ILogisticRow) {
      const textA = a.sku.toUpperCase();
      const textB = b.sku.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
  }

  /**
   * Encode the filter object into query params.
   */
  private encodeParams(params: object): HttpParams {
    if (params) {
      const encodedParams = new URLSearchParams();

      Object.entries(params)
        .filter((item: any[]) => undefined !== item[1])
        .forEach((item: any[]) => {
          if (Array.isArray(item[1])) {
            for (const param of item[1]) {
              encodedParams.append(item[0], param);
            }
          } else {
            encodedParams.append(item[0], item[1]);
          }
        })
      ;

      return new HttpParams({ fromString: encodedParams.toString(), encoder: new HttpEncoderService() });
    }

    return null;
  }
}
