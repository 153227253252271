import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { GenericFormModule } from '@components/generic/generic-form.module';
import { GenericModule } from '@components/generic/generic.module';
import { OrderManagerListComponent } from './order-manager-list.component';
import { OrderManagerResource } from './order-manager.resource';
import { OrderManagerFormComponent } from '@components/order-manager/form';
import { OrderResource } from '@resources';
import { BsDatepickerModule, BsDropdownModule, TabsModule } from 'ngx-bootstrap';
import { OrderManagerGeneralFormComponent } from './form/tab-general/order-manager-general-form.component';
import { OrderManagerShippingFormComponent } from '@components/order-manager/form/tab-shipping/order-manager-shipping-form.component';
import { OrderManagerSynchronizationFormComponent } from './form/tab-synchronization/order-manager-synchronization-form.component';
import { OrderManagerCommentFormComponent } from '@components/order-manager/form/tab-comment/order-manager-comment-form.component';
import { OrderManagerAssociatedComponent } from '@components/order-manager/form/tab-associated/order-manager-associated.component';
import { OrderManagerPaymentFormComponent } from './form/tab-payment/order-manager-payment-form.component';
import { OrderManagerHistoryComponent } from '@components/order-manager/form/tab-history/order-manager-history.component';
import { OrderManagerLitigationComponent } from '@components/order-manager/form/tab-litigation/order-manager-litigation.component';
import { OrderManagerLitigationFormComponent } from '@components/order-manager/form/tab-litigation/order-manager-litigation-form.component';
import { ShipmentResource } from '@resources/shipment.resource';
import { OrderModule } from '@components/order';
import { TabStripModule } from '@progress/kendo-angular-layout';
import { OrderManagerDashboardComponent } from './dashboard/order-manager-dashboard.component';
import { DashboardIndicatorResource } from './dashboard/dashboard-indicator.resource';
import { OrderManagerScalingFormComponent } from '@components/order-manager/scaling/order-manager-scaling-form.component';
import { CarrierScaleResource } from '@resources/carrier-scale.resource';
import { OrderManagerScalingListComponent } from '@components/order-manager/scaling/order-manager-scaling-list.component';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { AddressDialogComponent } from './form/tab-general/address-dialog/address-dialog.component';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrderManagerAddressResource } from './order-manager-address.resource';
import { OrderItemDialogComponent } from './form/tab-general/order-item-dialog/order-item-dialog.component';
import { OrderItemUpdateDialogComponent } from './form/tab-general/order-item-dialog/order-item-update-dialog.component';
import { OrderManagerItemResource } from './order-manager-item.resource';
import { OrderManagerShippingEditComponent } from './form/tab-shipping/shipping-edit/order-manager-shipping-edit.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { OrderManagerResultListComponent } from './order-manager-result-list.component';
import { CommentDialogComponent } from './components/comment-dialog.component';
import { OrderManagerSageExportListComponent } from './sage-export/order-manager-sage-export-list.component';
import { OrderManagerSageExportResultListComponent } from './sage-export/order-manager-sage-export-result-list.component';
import { SageExportResource } from '@resources/sage-export.resource';
import { TooltipModule } from 'ngx-tooltip';
import { DelayReasonOrderListComponent } from './delay-reason/delay-reason-order-list.component';
import { DelayReasonOrderResultListComponent } from './delay-reason/delay-reason-order-result-list.component';
import { DelayReasonResource } from '@components/order-manager/delay-reason/delay-reason.resource';
import { ExportModule } from '@components/export';
import { OrderManagerListLightComponent, OrderManagerResultListLightComponent } from './light';
import { OrderManagerLightResource } from './resources/order-manager-light.resource';
import { OrderManagerReverseComponent } from '@components/order-manager/form/tab-reverse/order-manager-reverse.component';
import { ReverseModule } from '@components/reverse';
import { OrderManagerRelationComponent, OrderManagerRelationFormComponent } from '@components/order-manager/relation';
import {PipesModule} from '../../pipes/pipes.module';
import {CustomerModule} from '@components/customer';
import {SageResource} from '@resources/sage.resource';

@NgModule({
  declarations: [
    OrderManagerListComponent,
    OrderManagerFormComponent,
    OrderManagerGeneralFormComponent,
    OrderManagerShippingFormComponent,
    OrderManagerSynchronizationFormComponent,
    OrderManagerCommentFormComponent,
    OrderManagerAssociatedComponent,
    OrderManagerPaymentFormComponent,
    OrderManagerDashboardComponent,
    OrderManagerHistoryComponent,
    OrderManagerLitigationComponent,
    OrderManagerLitigationFormComponent,
    OrderManagerScalingFormComponent,
    OrderManagerScalingListComponent,
    AddressDialogComponent,
    OrderItemDialogComponent,
    OrderItemUpdateDialogComponent,
    OrderManagerShippingEditComponent,
    OrderManagerResultListComponent,
    CommentDialogComponent,
    OrderManagerSageExportListComponent,
    OrderManagerSageExportResultListComponent,
    DelayReasonOrderListComponent,
    DelayReasonOrderResultListComponent,
    OrderManagerListLightComponent,
    OrderManagerResultListLightComponent,
    OrderManagerReverseComponent,
    OrderManagerRelationComponent,
    OrderManagerRelationFormComponent
  ],
  entryComponents: [
    OrderManagerListComponent,
    OrderManagerFormComponent,
    OrderManagerGeneralFormComponent,
    OrderManagerShippingFormComponent,
    OrderManagerSynchronizationFormComponent,
    OrderManagerCommentFormComponent,
    OrderManagerAssociatedComponent,
    OrderManagerPaymentFormComponent,
    OrderManagerDashboardComponent,
    OrderManagerHistoryComponent,
    OrderManagerLitigationComponent,
    OrderManagerLitigationFormComponent,
    OrderManagerScalingFormComponent,
    OrderManagerScalingListComponent,
    AddressDialogComponent,
    OrderItemDialogComponent,
    OrderItemUpdateDialogComponent,
    OrderManagerShippingEditComponent,
    OrderManagerResultListComponent,
    CommentDialogComponent,
    OrderManagerSageExportListComponent,
    OrderManagerSageExportResultListComponent,
    DelayReasonOrderListComponent,
    DelayReasonOrderResultListComponent,
    OrderManagerListLightComponent,
    OrderManagerResultListLightComponent,
    OrderManagerReverseComponent,
    OrderManagerRelationComponent,
    OrderManagerRelationFormComponent
  ],
  imports: [
    CommonModule,
    GenericFormModule,
    GenericModule,
    TabsModule,
    OrderModule,
    TabStripModule,
    DialogsModule,
    InputsModule,
    FormsModule,
    ReactiveFormsModule,
    DropDownsModule,
    TooltipModule,
    BsDropdownModule,
    ExportModule,
    ReverseModule,
    BsDatepickerModule,
    PipesModule,
    CustomerModule,
  ],
  providers: [
    OrderManagerResource,
    OrderResource,
    ShipmentResource,
    DashboardIndicatorResource,
    CarrierScaleResource,
    OrderManagerAddressResource,
    OrderManagerItemResource,
    SageExportResource,
    DelayReasonResource,
    CurrencyPipe,
    OrderManagerLightResource,
    SageResource
  ],
})

export class OrderManagerModule { }
