import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { SavOrdersResource } from './sav-orders.resource';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { SavOrdersFiltersFormService } from './sav-orders-filters-form.service';

@Component({
  selector: 'app-sav-orders-list',
  template: require('./sav-orders-list.component.html'),
  styles: [require('./sav-orders-list.component.scss')],
  providers: [
    { provide: AbstractResource, useClass: SavOrdersResource },
    { provide: AbstractFiltersFieldsService, useClass: SavOrdersFiltersFormService },
  ],
})
export class SavOrdersListComponent extends AbstractPageComponent implements OnInit {

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
  }
}
