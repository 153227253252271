import { Component, Inject } from '@angular/core';
import { AbstractResource } from '@resources/abstract.resource';
import { CMSResource } from '@components/cms/cms.resource';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { AuthService } from '@services/auth.service';
import { ICMS } from '@components/cms/models/cms.interface';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import {CMSListFiltersService} from '@components/cms/list/cms-list-filters.service';

@Component({
  selector: 'app-cms-list',
  template: require('./cms-list.component.html'),
  providers: [
    { provide: AbstractResource, useClass: CMSResource },
    { provide: AbstractFiltersFieldsService, useClass: CMSListFiltersService }
  ],
})
export class CMSListComponent extends AbstractPageComponent {

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }

  private delete(item: ICMS) {
    (<CMSResource>this.resource).remove(
      item.translations[this.currentLocale].id,
      { entryPoint: '/v2/content_block_translations/' + item.translations[this.currentLocale].id }
      ).subscribe(
        () => this.state.go(`${(<CMSResource>this.resource).routeName}.list`, null, {reload: true, notify: true})
      );
  }
}
