import { Injectable, Inject } from '@angular/core';
import { AbstractFormFieldBase } from '@components/generic/Form/dynamic/fields/abstract-form-field-base.class';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { FormNotifierService } from '@services/form-notifier.service';
import * as moment from 'moment';
import { DATE_FULL_FORMAT } from '@constants';
import { DropDownListField } from '@components/generic/Form/dynamic/fields/select-field.class';
import {
  DateField,
  MultiSearchField,
  TextField
} from '@components/generic/Form/dynamic/fields';
import { UsersResource } from '@components/users/users.resource';

interface IDropdownData {
  label: string;
  value: string;
}

@Injectable()
export class GrcTicketsFiltersService extends AbstractFiltersFieldsService {

  constructor(
    @Inject('StateService') protected state: ng.ui.IStateService,
    @Inject('TranslationService') public $translate: ng.translate.ITranslateService,
    formNotifier: FormNotifierService,
    private userResource: UsersResource,
  ) {
    super(formNotifier, state);
  }
  private typeList: IDropdownData[] = [
    {
      label: this.$translate.instant('PAGE.RELATION.FIELDS.THEME.SAV'),
      value: 'sav'
    },
    {
      label: this.$translate.instant('PAGE.RELATION.FIELDS.THEME.TRACKING'),
      value: 'tracking'
    },
    {
      label: this.$translate.instant('PAGE.RELATION.FIELDS.THEME.BUSINESS'),
      value: 'business'
    }
  ];

  private statusList: IDropdownData[] = [
    {
      label: this.$translate.instant('PAGE.RELATION.STATUS.OPENED'),
      value: 'opened'
    },
    {
      label: this.$translate.instant('PAGE.RELATION.STATUS.CLOSED'),
      value: 'closed'
    }
  ];

  private createdAtStartField: TextField = new TextField({
    fieldName: 'createdAtStart',
    value: null,
    hidden: true
  });

  private createdAtEndField: TextField = new TextField({
    fieldName: 'createdAtEnd',
    value: null,
    hidden: true
  });

  private userField: DropDownListField = new DropDownListField({
    fieldName: 'agent',
    label: 'PAGE.GRC.REPORTING.FILTER.ADVISOR',
    textField: 'label',
    valueField: 'value',
    value: this.filters.get('agent') ? this.filters.get('agent') : undefined,
    valuePrimitive: true,
  });

  private static setDates(newValue: string, startField: TextField, endField: TextField): void {
    if (!newValue || null === newValue[0]) {
      return;
    }

    startField.value = moment(newValue[0]).startOf('day').format(DATE_FULL_FORMAT);
    endField.value = moment(newValue[1]).endOf('day').format(DATE_FULL_FORMAT);
  }

  private fetchUser(): void {
    this.userResource.cGet()
      .takeUntil(this.destroyed$)
      .subscribe((response: any[]) => {
        this.userField.data = response.map((user: any) => {
          return {
            label: user.username,
            value: user.username
          };
        }).sort((a, b) => {
          if (a.label < b.label) { return -1; }
          if (a.label > b.label) { return 1; }
          return 0;
        });
      })
    ;
  }
  /**
   * @inheritDoc.
   */
  public getFields(): AbstractFormFieldBase<any>[] {
    this.fetchUser();
    const createdAtStart = this.filters.get('createdAtStart');
    const createdAtEnd = this.filters.get('createdAtEnd');

    if (createdAtStart && createdAtEnd) {
      this.createdAtStartField.value = createdAtStart;
      this.createdAtEndField.value = createdAtEnd;
    }

    return [
      new DateField({
        label: 'PAGE.GRC.REPORTING.FILTER.DATE',
        fieldName: 'createdAt',
        dateRange: true,
        value: createdAtStart && createdAtEnd ? [new Date(createdAtStart), new Date(createdAtEnd)] : undefined,
        valueChangedAction: this.setCreatedAtValue.bind(this),
      }),
      this.createdAtStartField,
      this.createdAtEndField,
      new MultiSearchField({
        fieldName: 'type[]',
        label: 'PAGE.GRC.REPORTING.FILTER.REASON',
        data: this.typeList,
        textField: 'label',
        valueField: 'value',
        value: 0 < this.filters.getAll('type[]').length ? this.filters.getAll('type[]') : undefined,
        valuePrimitive: true,
      }),
      this.userField,
      new DropDownListField({
        fieldName: 'status',
        label: 'PAGE.GRC.REPORTING.FILTER.STATUT',
        data: this.statusList,
        textField: 'label',
        valueField: 'value',
        value: this.filters.get('status') ? this.filters.get('status') : undefined,
        valuePrimitive: true,
      })
    ];
  }

  private setCreatedAtValue(newValue: string): void {
    GrcTicketsFiltersService.setDates(newValue, this.createdAtStartField, this.createdAtEndField);
  }
}
