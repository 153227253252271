import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { EnhancedFieldsModule } from '@directives/enhanced-fields.module';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { RoomListComponent } from '@components/room/room-list.component';
import { RoomResultListComponent } from '@components/room/room-result-list.component';
import { RoomBlockListComponent } from '@components/room/room-block-list.component';
import { RoomBlockResultListComponent } from '@components/room/room-block-result-list.component';
import { RoomFormComponent } from '@components/room/room-form.component';
import { RoomResource } from '@resources';
import { RoomFormTranslationComponent } from '@components/room/room-form-translation.component';
import { TabsModule } from 'ngx-bootstrap';
import { GenericFormModule, GenericModule } from '@components/generic';

@NgModule({
  entryComponents: [
    RoomListComponent,
    RoomResultListComponent,
    RoomFormComponent,
    RoomFormTranslationComponent,
    RoomBlockListComponent,
    RoomBlockResultListComponent,
  ],
  imports: [
    GenericModule,
    GenericFormModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    EnhancedFieldsModule,
    DropDownsModule,
    TabsModule.forRoot(),
  ],
  providers: [
    RoomResource,
  ],
  declarations: [
    RoomListComponent,
    RoomResultListComponent,
    RoomFormComponent,
    RoomFormTranslationComponent,
    RoomBlockListComponent,
    RoomBlockResultListComponent,
  ],
})
export class RoomModule {}
