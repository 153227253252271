import { Component, Inject } from '@angular/core';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { WebsiteHomepageHighlightsItemsResource } from './website-homepage-highlights-items.resource';

@Component({
  selector: 'website-homepage-highlights-items-list',
  template: require('./website-homepage-highlights-items-list.component.html'),
  providers: [
    { provide: AbstractResource, useClass: WebsiteHomepageHighlightsItemsResource },
  ],
})
export class WebsiteHomepageHighlightsItemsListComponent extends AbstractPageComponent {
  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }

  public add(): void {
    this.state.go('websiteHomepage.highlights-items.new');
  }
}
