'use strict'

import { SIGN_IN } from '@constants/route.constants'
import { AuthService } from '@services'

angular
  .module 'ammsFrontendApp'
  .run [
    '$rootScope'
    '$state'
    ($rootScope, $state) ->

      ##
      # If a user is a perfect anonymous, if he navigate from url, we prevent the state other than login state to be loaded
      ##
      $rootScope.$on '$stateChangeStart', (event, toState) ->
        if !AuthService.getToken() && toState.data?.secure
          event.preventDefault()

          $state.go SIGN_IN
  ]
