import { AbstractResource } from '../../resources/abstract.resource';
import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { CategoriesModel } from '@components/categories/models/categories.model';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  routeName: 'categories',
  entryPoint: '/v2/categories',
  model: CategoriesModel,
  translationKey: 'CATEGORIES',
})
export class CategoriesResource extends AbstractResource {
  protected nullableProperties: string[] = [
    'contentBlockTitle',
    'contentBlock'
  ];

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }
}
