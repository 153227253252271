import * as moment from 'moment';

export class DateHelper {
  /**
   * Is the date valid?
   *
   * @static
   * @param {*} date
   * @returns {boolean}
   * @memberof DateHelper
   */
  public static isValid(date?: any): boolean {
    return !!date && date instanceof Date;
  }

  /**
   * Formats the date string with moment.js with a date format.
   *
   * @static
   * @param {any} date
   * @param {string} dateFormat
   * @returns {string}
   * @memberof DateHelper
   */
  public static format(date: any, dateFormat: string): string {
    if ('undefined' === typeof date) {
      throw new TypeError('\'date\' parameter is required');
    }

    if (!dateFormat) {
      throw new TypeError('\'dateFormat\' parameter is required');
    }

    if (date) {
      return moment(date, dateFormat).format();
    }

    return '';
  }

  /**
   * Binds date field to single date picker.
   *
   * @static
   * @param {*} dateInputTag - input field tag
   * @param {*} sliderItem
   * @param {string} dateField - sliderItem date property
   * @param {string} dateFormat
   * @returns
   * @memberof DateHelper
   */
  public static bindToSingleDatePicker(dateInputTag: any, sliderItem: any, dateField: string, dateFormat: string) {
    dateInputTag.daterangepicker({
      singleDatePicker: true,
      showDropdowns: true,
      locale: { format: dateFormat },
      autoUpdateInput: false
    }, (chosen_date: any) => {
      dateInputTag.val(chosen_date.format(dateFormat));
      return sliderItem[dateField] = dateInputTag.val();
    });

    // Because clicking on current date as no effect, we have to do this:
    return dateInputTag.on('show.daterangepicker', function () {
      const currentDate = $('.today');
      currentDate.on('click', function () {
        dateInputTag.val(moment(new Date()).format(dateFormat));
        return sliderItem[dateField] = dateInputTag.val();
      });

      return $('.daterangepicker.show-calendar').addClass('single-date-picker');
    });
  }

}
