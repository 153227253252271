import { NgModule } from '@angular/core';
import { UsersResource } from '@components/users/users.resource';
import { UsersComponent } from '@components/users/users.component';
import { UsersListComponent } from '@components/users/list';
import { CommonModule } from '@angular/common';
import { GenericFormModule, GenericModule } from '@components/generic';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EnhancedFieldsModule } from '@directives/enhanced-fields.module';
import { ComboBoxModule, MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { UsersResultListComponent } from '@components/users/list/users-result-list.component';

@NgModule({
  entryComponents: [
    UsersComponent,
    UsersListComponent,
  ],
  declarations: [
    UsersComponent,
    UsersListComponent,
    UsersResultListComponent,
  ],
  providers: [
    UsersResource,
  ],
  imports: [
    CommonModule,
    GenericModule,
    GenericFormModule,
    ReactiveFormsModule,
    FormsModule,
    EnhancedFieldsModule,
    ComboBoxModule,
    MultiSelectModule,
  ]
})

export class UsersModule {}
