import { ICarrierGroup } from '@components/carrier/interfaces/carrier-group.interface';
import { SessionHelper } from '@helpers';
import { IHydraMember } from '@interfaces/hydra-resource.interface';
import { ICarrier } from '@components/carrier/interfaces/carrier.interface';
import { ICarrierTranslation } from '@components/carrier/interfaces/carrier-group-translation.interface';

export class CarrierGroupModel implements ICarrierGroup {
  public '@id': string;
  public id: number;
  public code: string;
  public name: string;
  public overhead: number;
  public overheadPercentage: number;
  public config: ICarrier;
  public dynamicAgency: boolean;
  public translations: {[keys: string]: ICarrierTranslation};
  public active: boolean;
  public handleReverses: boolean;

  constructor(carrierGroup: IHydraMember) {
    const countryCode: string = SessionHelper.getCountry().code;

    this['@id'] = carrierGroup['@id'];
    this.id = carrierGroup.id;
    this.code = carrierGroup.code;
    this.name = carrierGroup.name;

    carrierGroup.carriers.forEach((carrier: ICarrier) => {
      if (countryCode === carrier.country.code && undefined === this.config) {
        this.config = carrier;
      }
      if (countryCode === carrier.country.code && undefined !== this.config && undefined !== this.config.configurations) {
        const mapObj = new Map(
          this.config.configurations.concat(carrier.configurations).map((obj: any) => {
            delete obj.id;
            return [JSON.stringify(obj), obj];
          })
        );
        this.config.configurations =  Array.from(mapObj.values());
      }
    });

    if (this.config) {
      this.overhead = this.config.overhead;
      this.overheadPercentage = this.config.overheadPercentage;
    }

    this.dynamicAgency = carrierGroup.dynamicAgency;
    this.translations = carrierGroup.translations;
    this.active = carrierGroup.active;
    this.handleReverses = carrierGroup.handleReverses;
  }
}
