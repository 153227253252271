import { Directive, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

import { SortService, IColumnSortedEvent } from '../services/sort.service';

@Directive({
  selector: '[sortableTable]'
})
export class SortableTableDirective implements OnInit, OnDestroy {

  private columnSortedSubscription: Subscription;

  @Output() public sorted: EventEmitter<any> = new EventEmitter();

  constructor(private sortService: SortService) { }

  ngOnInit() {
    this.columnSortedSubscription = this.sortService.columnSorted$.subscribe((event: IColumnSortedEvent) => {
      this.sorted.emit(event);
    });
  }

  ngOnDestroy() {
    this.columnSortedSubscription.unsubscribe();
  }
}
