import { Component, Inject, Input } from '@angular/core';
import { AbstractFormComponent } from '@components/generic/Form/abstract-form.component';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { SnackbarService } from '@components/snackbar';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-management',
  template: require('./management.component.html')
})

export class ManagementComponent extends AbstractFormComponent {
  @Input() type: string;
  @Input() websiteConfiguration: any;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private snackbarService: SnackbarService,
    @Inject('DialogService') private dialog: any,
  ) {
    super($translate, authService, resource, state);
  }

  public addIcon(form: FormGroup): void {
    this.dialog.confirm(this.translate('PAGE.PAYMENT_CARRIER_ICONS.LIST.CONFIRM.CREATE'))
      .then(() => {
        const body = {
          picto: form.get('picto').value,
          position: this.websiteConfiguration[`${this.type}Items`].length,
          websiteConfiguration: this.websiteConfiguration.id,
        };

        this.resource.create(body)
          .takeUntil(this.destroyed$)
          .subscribe(() => {
            this.snackbarService.validate('ALERT.DATA.UPDATE');
            this.actions.list.go('', {reload: true});
          });
      });
  }
}
