import * as _ from 'lodash';
import { ITreeCategory } from '@interfaces';

export class CategoryTreeHelper {
  /**
   * Gets a tree of categories and flatten it.
   *
   * @param {any[]} categories
   * @returns {any[]}
   */
  static getFlattenTree(categories: any[] = []): any[] {
    return CategoryTreeHelper.flatten(CategoryTreeHelper.getTree(categories));
  }

  /**
   * Gets tree of categories
   *
   * @param {ITreeCategory[]} categories
   * @returns {ITreeCategory[]}
   */
  static getTree(categories: ITreeCategory[]): ITreeCategory[] {
    return categories.filter((category: ITreeCategory) => undefined === category.parent)
      .map((category: ITreeCategory) => CategoryTreeHelper.getNodeAndChildren(category, categories));
  }

  /**
   * Transforms a flatten tree of categories to a hierarchical one
   *
   * @param node
   * @param {ITreeCategory[]} categories
   * @returns {ITreeCategory}
   */
  static getNodeAndChildren(node: any, categories: ITreeCategory[]): ITreeCategory {
    return {
      ...node,
      children: categories.filter((category: ITreeCategory) => undefined !== category.parent && node.id === category.parent.id)
        .map((category: ITreeCategory) => CategoryTreeHelper.getNodeAndChildren(category, categories))
    };
  }

  /**
   * Flattens a list of categories and their children
   *
   * @param {any[]} categories
   * @param {number} level
   * @param {any} parent
   * @returns {ITreeCategory[]}
   */
  static flatten(categories: any[], level: number = 0, parent: any = null): ITreeCategory[] {
    let nodes: ITreeCategory[] = [];

    if (categories) {
      categories.forEach((category: ITreeCategory) => {
        nodes.push({
          ...category,
          label: _.padStart('', level, ' ') + category.label,
          level: level,
          parent
        });

        if (undefined !== category.children && category.children.length > 0) {
          nodes = nodes.concat(CategoryTreeHelper.flatten(category.children, level + 1, category.id));
        }
      });
    }

    return nodes;
  }
}
