import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericFormModule, GenericModule } from '@components/generic';
import { PipesModule } from '../../pipes/pipes.module';
import { SupplierListComponent } from '@components/supplier/list/supplier-list.component';
import { SupplierFormComponent } from '@components/supplier/form/supplier-form.component';
import { SupplierResource } from '@components/supplier/resources/supplier.resource';
import { DialogModule, WindowModule } from '@progress/kendo-angular-dialog';
import { SharedModule } from '@progress/kendo-angular-dropdowns';

@NgModule({
  declarations: [
    SupplierListComponent,
    SupplierFormComponent
  ],
  entryComponents: [
    SupplierListComponent,
    SupplierFormComponent
  ],
  providers: [
    SupplierResource
  ],
  imports: [
    CommonModule,
    GenericModule,
    DialogModule,
    SharedModule,
    WindowModule,
    GenericFormModule,
    PipesModule
  ]
})
export class SupplierModule {}
