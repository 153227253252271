import { Injectable } from '@angular/core';

@Injectable()
export class OrderItemStatusService {

  /**
   * Gets available order item statuses.
   */
  public getAvailableStatuses(orderItem: any): string[] {
    return [...orderItem.availableTransitions, orderItem.status ? orderItem.status : orderItem.previous.currentStatus]
      .filter((item: string, index: number, array: any[]) => array.indexOf(item) === index);
  }
}
