import { Component, Inject } from '@angular/core';
import { AbstractResource } from '@resources/abstract.resource';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { ProductResource } from '@components/product/product.resource';
import { AuthService } from '@services';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { SparePartSearchFiltersService } from '@components/spare-part/spare-part-search-filters.service';
import { SessionHelper } from '@helpers';
import { BASE_URL, DATE_SHORT_INTERNATIONAL_FORMAT } from '@constants';
import { IHydraResource } from '@interfaces/hydra-resource.interface';
import { IExportOptions } from '@interfaces';
import moment = require('moment');

@Component({
  selector: 'app-spare-part-search',
  template: require('./spare-part-search.component.html'),
  styles: [require('./spare-part-search.component.scss')],
  providers: [
    { provide: AbstractResource, useClass: ProductResource },
    { provide: AbstractFiltersFieldsService, useClass: SparePartSearchFiltersService }
  ]
})
export class SparePartSearchComponent extends AbstractPageComponent {
  public spareParts: any[] = [];
  public totalItems: number;
  public currentFilters: any;
  public paginationFilters: any;

  public exportOptions: IExportOptions[] = [
    {
      entryPoint: '/v2/export',
      responseType: 'text',
      type: 'text/csv',
      filename: `sav-by-id-csv-excel-${moment().format('YYYYMMDDHHmmss')}.csv`,
      translationKey: 'PAGE.SPARE_PART.LIST.EXPORT_PRODUCTS',
      filters: false,
      roles: ['ROLE_WALISOFT_AGENT'],
      postOptions: {
        exportCode: 'spare_part_by_id',
        formatCode: 'csv_excel',
        dryRun: false,
        deferred: true,
        split: false,
        serializationGroups: [],
        limit: SessionHelper.getCountry() ? SessionHelper.getCountry().locales[0] : null,
        async: true
      }
    }
  ];

  public imports: object[] = [
    {
      entryPoint: BASE_URL + '/api/v2/import',
      importButton: 'PAGE.SPARE_PART.LIST.IMPORT_PRODUCTS',
      businessObject: 'spare-part',
    },
    {
      entryPoint: BASE_URL + '/api/v2/import',
      importButton: 'PAGE.SPARE_PART.LIST.IMPORT_STOCK',
      businessObject: 'spare-part-stock',
    }
  ];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }

  public onFilterChange(event: any) {
    this.currentFilters = event;
    this.fetch();
  }

  public paginate(event: { page: number, byPage: number }): void {
    this.paginationFilters = event;
    this.fetch();
  }

  public fetch() {
    let body: any = {
      'country.code': SessionHelper.getCountry().code,
      'masterProduct.detached': true,
    };

    if (this.currentFilters.sparePartId) {
      body['sku'] = 'SPAREPART_' + this.currentFilters.sparePartId;
    }

    if (this.currentFilters.dateStart) {
      body['createdAt[after]'] = this.currentFilters.dateStart;
    }

    if (this.currentFilters.dateEnd) {
      body['createdAt[before]'] = this.currentFilters.dateEnd;
    }

    body = {
      ...body,
      ...this.paginationFilters,
    };

    body.limit = body.byPage || null;

    this.resource.getProducts(body)
      .takeUntil(this.destroyed$)
      .subscribe((response: IHydraResource) => {
        this.spareParts = response['hydra:member'];
        this.totalItems = response['hydra:totalItems'];
      })
      ;
  }

  public goToEdit(sparePartId: string): void {
    window.open(this.state.href('spare-part.edit', { id: sparePartId }, { absolute: true }), '_blank');
  }

  getFirstImage(sparepart: any): null|string {
    if (sparepart.masterProduct.marketplaceImages && sparepart.masterProduct.marketplaceImages[0] && sparepart.masterProduct.marketplaceImages[0].webPath) {
      return sparepart.masterProduct.marketplaceImages[0].webPath;
    }
    return null;
  }
}
