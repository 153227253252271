import { Injectable, Inject } from '@angular/core';
import { AbstractFormFieldBase } from '@components/generic/Form/dynamic/fields/abstract-form-field-base.class';
import { ComboSearchField, MultiSearchField, SkuSearchField, TextField } from '@components/generic/Form/dynamic/fields';
import { FormNotifierService } from '@services';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { SessionHelper } from '@helpers/session.helper';
import { ICountry } from '@interfaces/ICountry';
import * as moment from 'moment';
import { DATE_SHORT_INTERNATIONAL_FORMAT } from '@constants/date';
import { WarehousesResource } from '@components/warehouses';

interface IWarehouse {
  id: string;
  countries: {code: string}[];
  countriesCodes: string[];
  name: string;
  code: string;
  assignable: boolean;
  active: boolean;
}

@Injectable()
export class TrackingSalesFiltersFormService extends AbstractFiltersFieldsService {

  public warehouses: any[] = SessionHelper.getWarehouses();
  public countries: ICountry[];
  private booleanChoices: {value: number; label: string}[] = [];

  public warehousesField: ComboSearchField = new ComboSearchField({
    fieldName: 'warehouse',
    label: 'PAGE.TRACKING_SALES.LIST.FILTERS.WAREHOUSE',
    data: [],
    textField: 'name',
    valueField: 'code',
    value: this.filters.get('warehouse') ? this.filters.get('warehouse') : undefined,
    valuePrimitive: true,
    order: 2,
    clearButton: false,
  });

  public countriesField: MultiSearchField = new MultiSearchField({
    fieldName: 'countries[]',
    label: 'PAGE.TRACKING_SALES.LIST.FILTERS.COUNTRIES',
    data: [],
    textField: 'name',
    valueField: 'code',
    value: [],
    valueChangedAction: this.updateWarehouses.bind(this),
    order: 1
  });

  constructor(
    @Inject('StateService') protected state: ng.ui.IStateService,
    formNotifier: FormNotifierService,
    @Inject('TranslationService') private $translate: ng.translate.ITranslateService,
    private warehousesResource: WarehousesResource,
  ) {
    super(formNotifier, state);

    let availableCountriesCodes: string[] = [];
    this.warehousesResource.cGet(null, {returnHydraMembers: true}).subscribe((response: IWarehouse[]) => {
      this.warehouses = response;

      this.warehouses.forEach((warehouse: IWarehouse) => {
        availableCountriesCodes.push(...warehouse.countriesCodes);
      });

      availableCountriesCodes = [...new Set(availableCountriesCodes)];

      this.countries = SessionHelper.getCountries().filter((country: ICountry) => {
        return availableCountriesCodes.includes(country.code);
      });
      this.countriesField.data = this.countries;
      this.countriesField.value = this.filters.getAll('countries[]').length !== 0
        ? this.filters.getAll('countries[]')
        : [this.countries[0].code];

      if (this.filters.getAll('countries[]').length !== 0) {
        this.getWarehouses((this.filters.getAll('countries[]')));
        return;
      }
      this.getWarehouses(this.countries.map((country) => country.code));
    });
  }

  public updateWarehouses(newValues: string[]): void {
    this.warehousesField.data = [];
    this.getWarehouses(newValues);
  }

  public getWarehouses(countryCodes: string[]): void {
    let currentWarehouses: IWarehouse[] = [];
    countryCodes.forEach((code: string) => {
      currentWarehouses.push(...this.warehouses.filter((warehouse: IWarehouse) => warehouse.countriesCodes.includes(code)));
    });

    currentWarehouses = [...new Set(currentWarehouses)];
    this.warehousesField.data = currentWarehouses;

    if (currentWarehouses.length !== 0) {
      const isCurrentWarehouseFieldExist: IWarehouse = currentWarehouses.find(
        (warehouse: IWarehouse) => warehouse.code === this.warehousesField.value
      );
      this.warehousesField.value = isCurrentWarehouseFieldExist ? this.warehousesField.value : currentWarehouses[0].code;

      return;
    }

    this.warehousesField.value = null;
  }

  public getFields(): AbstractFormFieldBase<any>[] {
    this.booleanChoices = [
      {value: 1, label: this.$translate.instant('PAGE.TRACKING_SALES.LIST.BOOLEANS.YES')},
      {value: 0, label: this.$translate.instant('PAGE.TRACKING_SALES.LIST.BOOLEANS.NO')},
      {value: null, label: this.$translate.instant('PAGE.TRACKING_SALES.LIST.BOOLEANS.ALL')},
    ];

    const fields = [
      this.countriesField,
      this.warehousesField,

      new ComboSearchField({
        fieldName: 'pricingAlert',
        label: 'PAGE.TRACKING_SALES.LIST.FILTERS.PRICING_ALERT.LABEL',
        data: this.booleanChoices,
        textField: 'label',
        valueField: 'value',
        value: this.filters.get('pricingAlert') ? +this.filters.get('pricingAlert') : undefined,
        valuePrimitive: true,
        order: 3,
      }),

      new TextField({
        fieldName: 'from',
        value: moment().subtract(1, 'days').format(DATE_SHORT_INTERNATIONAL_FORMAT),
        order: 4,
        hidden: true,
      }),

      new TextField({
        fieldName: 'to',
        value: moment().format(DATE_SHORT_INTERNATIONAL_FORMAT),
        order: 5,
        hidden: true,
      }),

      new SkuSearchField({
        fieldName: 'sku',
        label: 'PAGE.PRODUCT.LIST.FILTER.SKU.LABEL',
        productType: 'product',
        value: this.filters.get('sku') ? this.filters.get('sku') : undefined,
        order: 5
      }),
    ];

    return fields.sort((a, b) => a.order - b.order);
  }
}
