import { Inject, Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'boolean'})

export class BooleanPipe implements PipeTransform {

  constructor(
    @Inject('TranslationService') protected $translate: ng.translate.ITranslateService
  ) {}

  transform(value: any): string {
    return value ? this.$translate.instant('BOOLEANS.YES') : this.$translate.instant('BOOLEANS.NO');
  }
}
