import { Injectable } from '@angular/core';
import { AbstractResource } from '../resources/abstract.resource';
import 'rxjs/add/operator/toPromise';
import { Observable } from 'rxjs/Observable';
import { CarrierGroupModel } from '../components/carrier/models/carrier-group.model';
import { SessionHelper } from '../helpers/session.helper';
import { Resource } from '../decorators/Resource';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  entryPoint: '/v2/carrier_groups',
  routeName: 'carrier',
  model: CarrierGroupModel,
  cGetDefaultFilters: () => ({
    'carriers.country.code': SessionHelper.getCountry() && SessionHelper.getCountry().code
  }),
  translationKey: 'CARRIER.CARRIER',
  listColumns: [
    { field: 'id', translationKey: 'ID', type: 'id' },
    { field: 'code', translationKey: 'CODE' },
    { field: 'countryCode', translationKey: 'CODE', type: 'flag' },
    { field: 'overhead', translationKey: 'OVERHEAD' },
    { field: 'overheadPercentage', translationKey: 'OVERHEAD_PERCENTAGE' },
  ],
  createAvailable: false,
  deleteAvailable: false,
  goToEditField: 'id',
  isHydra: true
})
export class CarrierGroupResource extends AbstractResource {

  private readonly v1Entrypoint: string = '/carriers';

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  /**
   * Get estimation cost
   *
   * @param {Object} params
   * @param {Object} options
   * @returns {Promise<Object>}
   */
  public getCostEstimation(params?: object, options?: object): Promise<Object> {
    return this.create(params, {
      ...options,
      entryPoint: '/v2/carriers/preview_cost',
    })
      .toPromise();
  }

  /**
   * Filter by Country code.
   */
  public getForProducts(): Observable<Object> {
    const countryCode = SessionHelper.getCountry() && SessionHelper.getCountry().code;
    return this.getMany({ 'carriers.country.code': countryCode, 'handleProducts': 1 }, { isHydra: true, dontUseModel: true });
  }

  public getFromCountry(countryCode: string): Observable<Object> {
    return this.getMany({ 'carriers.country.code': countryCode, 'handleProducts': 1 }, { isHydra: true, dontUseModel: true });
  }


  /**
   * returns the family reference costs for the product with @param productIri Iri
   *
   * @param {string} productIri - the IRI of the product
   * @returns {Observable<Object>}
   * @memberof CarrierResource
   */
  public getFamilyCosts(productIri: string): Observable<Object> {
    return this.create({ product: productIri }, { entryPoint: '/v2/carriers/preview_family_costs' });
  }


  public exportBlacklist(carrierGroupId: string, exportCode: string): Observable<Object> {
    const countryCode = SessionHelper.getCountry() && SessionHelper.getCountry().code;

    return this.create({
      'deferred': true,
      'dryRun': false,
      'exportCode': exportCode,
      'formatCode': 'carrier_exclude_product' === exportCode ? 'csv_excel' : 'csv',
      'limit': carrierGroupId + '|' + countryCode,
      'split': false,
      'async': true,
      'serializationGroups': {}
    }, {
      entryPoint: `/v2/export`
    });
  }

  public addPostCode(countryCode: string, carrierGroupId: string, Type: string, postCodes: any[]) {
    const body = {'countryCode': countryCode, 'carrierGroupId': carrierGroupId, 'type': Type, 'postCodes': postCodes};
    return this.http.post(this.getPath(`/v2/carrier_groups/blacklist_postal_codes/add_post_code`), JSON.stringify(body), {
      headers: {'Content-Type': 'application/json'},
    });
  }
}
