import {Component, Inject, Input, OnInit} from '@angular/core';
import { AbstractFormComponent } from '@components/generic/Form/abstract-form.component';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IReinsurancePoints } from '@components/footer/reinsurance-points/models';
import { EDITION_PAGE } from '@interfaces/IPageComponent';
import { ReinsurancePointsResource } from '@components/footer/reinsurance-points/reinsurance-points.resource';
import { SessionHelper } from '@helpers/session.helper';
import {IFileInfo} from '@components/generic/Form/file-uploader/interfaces/file-info.interface';

@Component({
  selector: 'app-reinsurance-points',
  template: require('./reinsurance-points.component.html'),
  providers: [
    {provide: AbstractResource, useClass: ReinsurancePointsResource}
  ],
})
export class ReinsurancePointsComponent extends AbstractFormComponent implements OnInit {
  public form: FormGroup;
  public model: IReinsurancePoints;
  protected readonly SessionHelper = SessionHelper;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    public resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
  ) {
    super($translate, authService, resource, state);
  }

  public ngOnInit(): void {
    this.buildForm();

    if (EDITION_PAGE === this.pageType) {
      this.fillForm();
    }
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      firstLine: ['', [Validators.required]],
      secondLine: [''],
      position: [null, [Validators.required, Validators.min(0)]],
      locale: ['', [Validators.required]],
      file: [null],
    });
  }

  private fillForm(): void {
    this.resource.get(this.state.params.id)
      .subscribe((response: IReinsurancePoints) => {
        this.model = response;
        this.form.patchValue(response);
      });
  }

  protected submit(event: {redirect: boolean}): void {
    const formData: FormData = new FormData();


    if (this.state.params.id) {
      formData.append('reinsurance', this.state.params.id);
    }

    if (this.form && this.form.value.file && this.form.value.file.subscriber) {
      this.form.value.file.subscriber.subscribe((fileList: IFileInfo[]) => {
        formData.append('file', <File>fileList[0].rawFile, fileList[0].name);
      });
    }

    formData.append('firstLine', this.form.value.firstLine);
    formData.append('secondLine', this.form.value.secondLine);
    formData.append('position', this.form.value.position);
    formData.append('locale', this.form.value.locale);

    this.resource.uploadFile(formData, { entryPoint: '/v2/reinsurance_items' })
      .takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        this.state.go(`${this.resource.routeName}.list`, { id: response.id }, { reload: true });
      })
    ;
  }
}
