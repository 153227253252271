import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { CarrierCapacityResource } from '@resources/carrier-capacity.resource';
import { Moment } from 'moment';
import { AbstractResource } from '@resources';
import { SnackbarService } from '@components/snackbar';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-carrier-capacity-docked-volume',
  template: require('./carrier-capacity-docked-volume.component.html'),
  providers: [
    { provide: AbstractResource, useClass: CarrierCapacityResource },
  ],
})
export class CarrierCapacityDockedVolumeComponent extends AbstractPageComponent {
  @Input() public volumes: any = [];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService
  ) {
    super($translate, authService, null, state);
  }
}
