import { NgModule } from '@angular/core';
import { PaymentCarrierIconsComponent } from '@components/payment-carrier-icons/payment-carrier-icons.component';
import { ListComponent } from '@components/payment-carrier-icons/generic/list/list.component';
import { PaymentIconsResource } from '@components/payment-carrier-icons/payment-icons/payment-icons.resource';
import { ManagementComponent } from '@components/payment-carrier-icons/generic/management';
import { GenericModule } from '@components/generic';
import { CarrierIconsResource } from '@components/payment-carrier-icons/carrier-icons/carrier-icons.resource';
import { PaymentIconsComponent } from '@components/payment-carrier-icons/payment-icons/payment-icons.component';
import { CarrierIconsComponent } from '@components/payment-carrier-icons/carrier-icons/carrier-icons.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    PaymentCarrierIconsComponent,
    ListComponent,
    ManagementComponent,
    PaymentIconsComponent,
    CarrierIconsComponent,
  ],
  entryComponents: [
    PaymentCarrierIconsComponent,
  ],
  providers: [
    PaymentIconsResource,
    CarrierIconsResource,
  ],
  imports: [
    CommonModule,
    GenericModule,
  ],
})
export class PaymentCarrierIconsModule {}
