import { Injectable } from '@angular/core';
import { AbstractResource } from '@resources';
import { Resource } from '@decorators/Resource';
import { HttpClient } from '@angular/common/http';
import { SessionHelper } from '@helpers';
import { Observable } from 'rxjs/Observable';

@Injectable()
@Resource({
  routeName: 'order-collection',
  entryPoint: '/v2/order-manager/click-and-collect/orders',
  translationKey: 'ORDER_COLLECTION',
  isHydra: true,
  updateAvailable: true,
  cGetDefaultFilters: () => ({
    'indicatorFilters[]': 'clickAndCollect',
    'transactionCommercialEntity': SessionHelper.getCountry().commercialEntity.id,
  }),
})
export class OrderCollectionResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  public cGet(params: any = {}, options: any = {}): Observable<object> {
    delete params.date;

    return super.cGet(params, options);
  }
}
