import { Component, Inject, Input, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IProductForm } from '@components/product/interfaces/product-form.interface';
import { ProductResource } from '@components/product/product.resource';
import { AbstractComponent } from '@components/generic/abstract.component';

@Component({
  selector: 'app-product-sage-form',
  template: require('./product-sage-form.component.html'),
  providers: [
    { provide: AbstractResource, useClass: ProductResource },
  ],
})
export class ProductSageFormComponent extends AbstractComponent implements OnInit {

  public form: FormGroup;

  @Input() model: IProductForm;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    this.createForm();
    this.fillForm();
  }

  public createForm(): void {
    this.form = this.formBuilder.group({
      sageName: [''],
      quantityInStock: [0],
      shippingCost: [0],
      cmup: [0],
    });
  }

  public fillForm(): void {
    this.form.patchValue(this.model);
  }
}
