import {Component, Inject, Output, EventEmitter, AfterViewInit, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserGuideResource} from '@components/super-product/form/user-guide/user-guide.resource';
import {IColumn} from '@components/generic/grid/interfaces/column.interface';
import {AuthService} from '@services';
import {SuperProductResource} from '@components/super-product/super-product.resource';
import {AbstractPageComponent} from '@components/generic/abstract-page.component';
import {AbstractResource} from '@resources';
import {IFileInfo} from '@components/generic/Form/file-uploader/interfaces/file-info.interface';
import {switchMap, takeUntil} from 'rxjs/operators';
import {IImage} from '@components/generic/List';
import {SnackbarService} from '@components/snackbar';
import {buildVintageDates} from '@helpers/VintageHelper';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';

@Component({
  selector: 'app-user-guide',
  template: require('./user-guide.component.html'),
  styles: [require('./user-guide.component.scss')],
  providers: [
    {provide: AbstractResource, useClass: UserGuideResource},
  ]
})
export class UserGuideComponent extends AbstractPageComponent implements AfterViewInit, OnInit {

  public userPictures: Array<any> = [];
  public form: FormGroup;
  public selectedFilesHeaders: IColumn[];
  public userGuidesTypeNotice: any[] = [];
  public userGuidesTypeIndex: any[] = [];
  public availableVintageDates: any[];
  public availableType: { value: string; label: string }[] = [];

  @Output() public onDelete: EventEmitter<object> = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    public superProductResource: SuperProductResource,
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: UserGuideResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private snackbar: SnackbarService,
  ) {
    super($translate, authService, resource, state);

    this.form = this.fb.group({
      superproductId: [this.state.params.id, [Validators.required, Validators.minLength(4)]],
      file: [this.userPictures, [Validators.required]]
    });

    this.availableType = [
      {value: 'notice', label: this.$translate.instant('PAGE.SUPER_PRODUCT.TAB.USER_GUIDE.CHOICE_NOTICE')},
      {value: 'index', label: this.$translate.instant('PAGE.SUPER_PRODUCT.TAB.USER_GUIDE.CHOICE_INDEX')},
    ];

  }

  ngOnInit() {
    this.buildVintageDates();

    this.form.get('file').valueChanges
      .pipe(
        takeUntil(this.destroyed$),
        switchMap((event: { subscriber: BehaviorSubject<any>, type: string }) => {
          return event.type === 'select' ? event.subscriber : [];
        })
      )
      .subscribe((selectedFiles: any[]) => {
        for (const img of selectedFiles) {
          if (!img.additionalContentForm) {
            img.additionalContentForm = this.fb.group({
              vintage: null,
              typeOfFile: null,
            });
          }
        }
      });

    this.resource.cGet({superProduct: this.state.params.id}, {returnHydraMembers: true})
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        for (const item of response) {
          this.pushImageToUserGuides(item);
        }
      })
    ;
  }

  ngAfterViewInit(): void {
    this.selectedFilesHeaders = [
      {
        name: this.translate('UPLOADER.NAME'),
        field: 'name',
      },
    ];
  }

  public submit(event: any): void {
    let files: IFileInfo[] = [];
    const file = this.form.value.file;

    if (file.subscriber) {
      file.subscriber.subscribe((fileList: IFileInfo[]) => {
        files = fileList;
      });

      const rawFile = files[0].rawFile;
      const formData: FormData = new FormData();
      const vintage = files[0].additionalContentForm.get('vintage').value;
      const year = vintage ? vintage : new Date().getFullYear();

      formData.append('file', rawFile, rawFile.name);

      this.resource.uploadFile(formData, {
        entryPoint: '/v2/user_guides/' + this.state.params.id + '?vintage=' + year + '&typeOfFile=' + files[0].additionalContentForm.get('typeOfFile').value
      })
        .pipe(takeUntil(this.destroyed$))
        .subscribe((response: any) => {
          this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
          this.pushImageToUserGuides(response);
        })
      ;
    }
  }

  public pushImageToUserGuides(response: any) {
    const image: IImage = {
      id: response.id,
      fileName: 'Millesime: ' + response.vintage + ' - ' + response.fileName,
      originalPath: response.originalPath,
      thumbnailPath: '/images/upload-image.png',
      additionalContentForm: this.fb.group({
        vintage: response.vintage,
        typeOfFile: response.typeOfFile,
      }),
    };

    'notice' === response.typeOfFile ? this.userGuidesTypeNotice.push(image) : this.userGuidesTypeIndex.push(image);
  }

  public putVintage(additionalForm: FormGroup, id: string): void {
    this.resource.partialUpdate(id, additionalForm.value).pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.state.go(this.state.current, this.state.params, {reload: true});
      });
  }

  public delete(images: any[]): void {
    for (const image of images) {
      this.resource.remove(image.id).pipe(takeUntil(this.destroyed$)).subscribe();
    }
  }

  private buildVintageDates(): void {
    this.availableVintageDates = buildVintageDates().map(x => x.toString());
  }
}
