import { Component, Input } from '@angular/core';
import { CarrierIconsResource } from '@components/payment-carrier-icons/carrier-icons';
import { AbstractResource } from '@resources/abstract.resource';

@Component({
  selector: 'app-carrier-icons',
  template: require('./carrier-icons.component.html'),
  providers: [
    { provide: AbstractResource, useClass: CarrierIconsResource }
  ],
})
export class CarrierIconsComponent {
  @Input() websiteConfiguration: any;
}
