import { Component, Inject, Input, OnInit } from '@angular/core';
import { AbstractFormComponent } from '@components/generic/Form/abstract-form.component';
import { IPackage, IProductForm } from '@components/product/interfaces/product-form.interface';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormNotifierService } from '@services/form-notifier.service';
import { SnackbarService } from '@components/snackbar';
import { PackageResource } from '@resources/package.resource';
import { ProductNotifierService } from '@services/product-notifier.service';
import { INPUT_NUMBER_PATTERN_DEC, INPUT_NUMBER_PATTERN_NODEC } from '@constants/form';
import { NumberHelper } from '@helpers/NumberHelper';
import { HydraHelper } from '@helpers/HydraHelper';
import { SessionHelper } from '@helpers';

@Component({
  selector: 'app-package-management-form',
  template: require('./package-management-form.component.html'),
})
export class PackageManagementFormComponent extends AbstractFormComponent implements OnInit {

  @Input() public package: IPackage;
  @Input() public model: IProductForm;

  public form: FormGroup;

  public packages: any[] = [];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    resource: AbstractResource,
    private formBuilder: FormBuilder,
    private formNotifier: FormNotifierService,
    private snackbar: SnackbarService,
    private packageResource: PackageResource,
    private productNotifierService: ProductNotifierService,
    @Inject('DialogService') private dialogService: any,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit(): void {
    this.buildForm();
    this.fillForm();
    this.fetchAllPackages();
    this.updateCubage('length');
    this.updateCubage('width');
    this.updateCubage('height');
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      weight: [[0], Validators.pattern(INPUT_NUMBER_PATTERN_DEC)],
      length: [[0], Validators.pattern(INPUT_NUMBER_PATTERN_DEC)],
      width: [[0], Validators.pattern(INPUT_NUMBER_PATTERN_DEC)],
      height: [[0], Validators.pattern(INPUT_NUMBER_PATTERN_DEC)],
      m3: [0],
      number: [[0], Validators.pattern(INPUT_NUMBER_PATTERN_NODEC)],
      comment: [''],
      substitutionPackages: [],
    });
  }

  private fillForm(): void {
    if (this.package) {
      this.form.patchValue(this.package);
      this.form.value.substitutionPackages = this.packages.filter((item: any) => this.package.substitutionPackages.includes(item.id));
    }
  }

  private create(): void {
    this.dialogService.confirm(this.translate('PAGE.PRODUCT.EDIT.TAB.CARRIERS.BOX_PACKAGE.CONFIRM.ADD'))
      .then(() => {
        this.packageResource.create(this.prepareBody(this.form, true))
          .takeUntil(this.destroyed$)
          .subscribe((response: IPackage) => {
            this.model.packages.push(response);
            this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
            this.formNotifier.notifyFormSubmitted();
            this.productNotifierService.notifyPreviewCostsUpdated();
            this.state.go(`${this.resource.routeName}.edit.carriers`, { id: this.model.id }, { reload: true });
          });
      });
  }

  private update(packageId: string): void {
    this.dialogService.confirm(this.translate('PAGE.PRODUCT.EDIT.TAB.CARRIERS.BOX_PACKAGE.CONFIRM.UPDATE'))
      .then(() => {
        this.packageResource.update(packageId, this.prepareBody(this.form, false))
          .takeUntil(this.destroyed$)
          .subscribe(() => {
            this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
            this.formNotifier.notifyFormSubmitted();
            this.productNotifierService.notifyPreviewCostsUpdated();
          });
      });
  }

  private delete(packageId: string): void {
    this.dialogService.confirm(this.translate('PAGE.PRODUCT.EDIT.TAB.CARRIERS.BOX_PACKAGE.CONFIRM.DELETE'))
      .then(() => {
        this.packageResource.remove(packageId)
          .takeUntil(this.destroyed$)
          .subscribe(() => {
            this.snackbar.validate(this.translate('PAGE.PRODUCT.EDIT.TAB.CARRIERS.BOX_PACKAGE.ALERTS.DELETE'));
            this.formNotifier.notifyFormSubmitted();
            this.productNotifierService.notifyPreviewCostsUpdated();
            this.model.packages = this.model.packages.filter((pkg) => packageId !== pkg.id);
            this.state.go(`${this.resource.routeName}.edit.carriers`, { id: this.model.id }, { reload: true });
          });
      });
  }

  private updateCubage(field: string): void {
    this.calculateCubage(this.form.get('width').value, this.form.get('length').value, this.form.get('height').value);

    this.form.get(field).valueChanges.subscribe(() => {
       this.calculateCubage(
         NumberHelper.parseFloat(this.form.get('width').value),
         NumberHelper.parseFloat(this.form.get('length').value),
         NumberHelper.parseFloat(this.form.get('height').value)
       );
    });
  }

  private calculateCubage(length: number, width: number, height: number): void {
    this.form.get('m3').setValue(((width * length * height) / 1000000));
  }

  private prepareBody(form: FormGroup, isCreation: boolean): IPackage {
    const body: IPackage = {
      weight: NumberHelper.parseFloat(form.value.weight),
      length: NumberHelper.parseFloat(form.value.length),
      width: NumberHelper.parseFloat(form.value.width),
      height: NumberHelper.parseFloat(form.value.height),
      number: NumberHelper.parseFloat(form.value.number),
      comment: form.value.comment,
      substitutionPackages: form.value.substitutionPackages ? form.value.substitutionPackages.map((item: any) => HydraHelper.buildIri(item, 'packages')) : null,
    };

    if (isCreation) {
      body.masterProduct = '/api/v2/master_products/' + this.model.masterProduct.id;
      body.version = this.model.getPreviewVersion();
    }

    return body;
  }

  private fetchAllPackages(): void {
    this.packageResource.cGet({ countryCode: SessionHelper.getCountry().code }, { entryPoint: `/v2/packages/skus` })
      .takeUntil(this.destroyed$)
      .subscribe((response: any) => {
        this.packages = response['hydra:member'].map((item: any) => {
          return { id: item.id, label: item.sku + '/' + item.number };
        });
        this.fillForm();
      })
    ;
  }
}
