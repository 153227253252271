import { Component, Input, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService, FormNotifierService } from '@services';
import { Subscription } from 'rxjs/Subscription';
import { DialogBoxService } from '@services/dialog-box.service';
import { DialogRef, DialogCloseResult } from '@progress/kendo-angular-dialog';
import { FORM_STREAMS } from '../../../enums/form-notifier-streams.enum';
import { ITabs } from '@components/generic/tabs/tabs.interface';

/**
 * Displays tabs for a template by allows to switch between different part of this template.
 *  - When switching, checks if a form is in edition to avert user that he can loose these data.
 *  - Each tabs correspond to a ui-router state that allows to keep reference of the tab view.
 */
@Component({
  selector: 'app-tabs',
  template: require('./tabs.component.html'),
  styles: [require('./tabs.component.scss')]
})
export class TabsComponent extends AbstractComponent implements OnInit, OnDestroy {

  /**
   * To share data between tabs.
   */
  @Input() public params: object;

  public tabs: ITabs[] = [];
  public btnAreGrey: boolean = false;

  private formInEdition: boolean = false;
  private formNotifierSubscription: Subscription;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private formNotifier: FormNotifierService,
    private dialogBoxService: DialogBoxService,
  ) {
    super($translate, authService, null, state);
  }

  ngOnInit(): void {
    if (undefined === this.state.current.data.tabs) {
      throw new Error('Tabs are not set');
    }

    this.tabs = this.state.current.data.tabs;

    if (this.state.current.data.btnAreGrey) {
      this.btnAreGrey = true;
    }

    this.formNotifierSubscription = this.formNotifier.observable.subscribe((stream) => {
      if (FORM_STREAMS.formInEdition === stream) {
        this.formInEdition = true;
      }

      if (FORM_STREAMS.formIsDestroyed === stream || FORM_STREAMS.dataSubmitted === stream) {
        this.formInEdition = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.formInEdition = false;
    this.formNotifierSubscription.unsubscribe();
  }

  public switchState(event: Event, tab: ITabs): void {
    if (undefined === tab.state || this.state.current.name === tab.state) {
      event.preventDefault();
      return;
    }

    if (!this.formInEdition) {
      this.state.go(tab.state, this.params);
    }

    const dialog: DialogRef = this.dialogBoxService.alert({ content: this.translate('DIALOG.ALERT.FORM.IN_EDITION') });
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult || this.translate('DIALOG.BUTTON.CANCEL') === result.text) {
        return;
      }
      this.state.go(tab.state, this.params);
    });
  }

  public getObjectKeys(obj: any): Array<string> {
    return Object.keys(obj);
  }

  public getObjectLabel(color: string): string {
    return this.translate(this.getObjectKeys(color).toString()).toUpperCase();
  }

  public getTabLabel(tab: ITabs): string {
    return tab.label.toString().replace(/\s*\(.*\)$/, '');
  }

  public hasMatchingLabel(tab: ITabs): boolean {
    // @ts-ignore
    return this.params && this.params.colors ? this.params.colors.some(color => this.getObjectLabel(color) === this.getTabLabel(tab)) : false;
  }
}
