import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { IProductForm } from '@components/product/interfaces/product-form.interface';
import { ProductResource } from '@components/product/product.resource';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { MasterProductResource } from '@resources';
import { SessionHelper } from '@helpers';
import { IWarehouse } from '@components/export-logistics/forms';
import {IAddressing} from '@models/addressing.interface';
import {NumberHelper} from '@helpers/NumberHelper';

@Component({
    selector: 'app-spare-part-page-warehouse-agent',
    template: require('./spare-part-page-warehouse-agent.component.html'),
    providers: [
        { provide: AbstractResource, useClass: ProductResource },
    ],
})
export class SparePartPageWarehouseAgentComponent extends AbstractPageComponent implements OnInit {

    public model: IProductForm;
    public savAddressing: any;
    public savAddressingStorage: any[] = [];
    public vintages: any[] = [];
    public stockByWarehouse: any;
    public pageTitle: string = '';

    constructor(
        @Inject('TranslationService') $translate: ng.translate.ITranslateService,
        authService: AuthService,
        resource: AbstractResource,
        @Inject('StateService') state: ng.ui.IStateService,
        private masterProductResource: MasterProductResource,
    ) {
        super($translate, authService, resource, state);
    }

    ngOnInit() {
        if (!this.state.params.id) {
            this.state.go('spare-part.list', {}, {reload: true});
        }
        if (!SessionHelper.useLinkedWarehousesOnly()) {
            this.state.go('spare-part.edit', {id: this.state.params.id}, {reload: true});
        }

        this.resource.get(this.state.params.id)
            .takeUntil(this.destroyed$)
            .subscribe((response: IProductForm) => {
                this.model = response;
                this.pageTitle = response.sku;
                this.stockByWarehouse = SessionHelper.getLinkedWarehouses().map((warehouse: IWarehouse) => warehouse.code);
                this.getAddressing();
                this.getVintages();
            });
    }

    private getAddressing() {
        this.masterProductResource.getAddressing(this.model.masterProduct.id).subscribe((response: any) => {
            const sav = response.masterProductsWarehouses
                .filter((masterProductWarehouse: { warehouse: { code: string; }; }) => masterProductWarehouse.warehouse.code === 'sav');
            if (sav[0]) {
              sav[0].addressings.forEach((addressing: IAddressing, index: number) => {
                if (addressing.addressingType === 'picking') {
                  this.savAddressing = {
                    addressingBay: addressing.addressingBay,
                    addressingLane: addressing.addressingLane,
                    addressingLocation: addressing.addressingLocation,
                    addressingLevel: addressing.addressingLevel,
                    addressingQuantity: addressing.quantity
                  };
                } else {
                  this.savAddressingStorage[index - 1] = {
                    addressingBay: addressing.addressingBay,
                    addressingLane: addressing.addressingLane,
                    addressingLocation: addressing.addressingLocation,
                    addressingLevel: addressing.addressingLevel,
                    addressingQuantity: addressing.quantity
                  };
                }
              });
            }
        });
    }

    private getVintages() {
        this.resource.getVintagesFromMasterProductId(this.model.masterProduct.id).takeUntil(this.destroyed$)
            .subscribe((response: any) => {
                for (const vintage of response.vintages) {
                    this.vintages.push(vintage);
                }

                this.vintages.sort(function (a, b) {
                    return a.vintage - b.vintage;
                });

                this.vintages.sort(function (a, b) {
                    if (a.sku < b.sku) {
                        return -1;
                    }
                    if (a.sku > b.sku) {
                        return 1;
                    }
                    return 0;
                });
            });
    }

    public calculateCubage(length: number, width: number, height: number): number {
        return (width * length * height) / 1000000;
    }
}
