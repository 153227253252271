import { AbstractComponent } from '@components/generic/abstract.component';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { OrderItemResource, OrderResource } from '@resources';
import {
  BUSINESS_OBJECT_ORDER,
  BUSINESS_OBJECT_ORDER_ITEM,
  INDICATOR_CRM360_TYPE,
  INDICATOR_SAGE_TYPE,
  INDICATOR_ORDER_ITEM_STATUS_SYNC_TYPE
} from '@constants';
import { IOrderManagerItems } from '@components/order-manager/models/order-manager-items.interface';
import { IndicatorResource } from '@resources/indicator.resource';
import { HydraHelper } from '@helpers/HydraHelper';
import { SnackbarService } from '@components';

@Component({
  selector: 'app-order-manager-synchronization-form',
  template: require('./order-manager-synchronization-form.component.html'),
  styles: [require('./order-manager-synchronization-form.component.scss')],
  providers: [
    { provide: AbstractResource, useClass: OrderResource },
  ],
})
export class OrderManagerSynchronizationFormComponent extends AbstractComponent implements OnInit {

  @Input() order: any;

  public fetchedOrder: IOrderManagerItems;

  public BUSINESS_OBJECT_ORDER: string = BUSINESS_OBJECT_ORDER;
  public BUSINESS_OBJECT_ORDER_ITEM: string = BUSINESS_OBJECT_ORDER_ITEM;
  public INDICATOR_CRM360_TYPE: string = INDICATOR_CRM360_TYPE;
  public INDICATOR_SAGE_TYPE: string = INDICATOR_SAGE_TYPE;
  public INDICATOR_ORDER_ITEM_STATUS_SYNC: string = INDICATOR_ORDER_ITEM_STATUS_SYNC_TYPE;
  public hideButtons = false;


  public listOfOrderItemHistory: { orderItemId: number; orderItemSku: string; history: any[]; status: any[]; }[] = [];


  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    private orderItemResource: OrderItemResource,
    private indicatorResource: IndicatorResource,
    @Inject('StateService') state: ng.ui.IStateService,
    protected snackbar: SnackbarService,
  ) {
    super($translate, authService, resource, state);
  }

  ngOnInit() {
    this.fetchOrderItems();
  }

  private fetchOrderItems() {
    this.orderItemResource.getOrderItem(this.order.id).subscribe((response: IOrderManagerItems) => {
      this.fetchedOrder = response;
      this.extractAllDistinctStatusFromHistoryOfIndicatorOrderItemStatusSync();
    }
    );
  }

  private extractAllDistinctStatusFromHistoryOfIndicatorOrderItemStatusSync() {
    for (const orderItem of this.fetchedOrder.items) {

      const orderItemDistinctStatus: any[] = [];
      const historic: any[] = [];

      this.indicatorResource.getHistory(orderItem.id, this.BUSINESS_OBJECT_ORDER_ITEM, this.INDICATOR_ORDER_ITEM_STATUS_SYNC)
      .subscribe(
        (response: any) => {
          for (const history of response.historic) {
            if (!orderItemDistinctStatus.some(status => status === history.extraData.status)) {
              orderItemDistinctStatus.push(history.extraData.status);
            }
            historic.push(history);
          }
          this.listOfOrderItemHistory.push(
            {
              orderItemId: orderItem.id,
              orderItemSku: orderItem.product.sku,
              history: historic,
              status: orderItemDistinctStatus
            });
        }
      );
    }
  }

  getIndicators(status: string, orderItemId: number) {
    const orderItemHistory = this.listOfOrderItemHistory.find(e => e.orderItemId === orderItemId);
    return orderItemHistory.history.filter(e => e.extraData.status === status);
  }

  public createInvoice(): void {
    this.resource
      .create(
        { order: HydraHelper.buildIri(this.order.id, 'orders') },
        { entryPoint: '/v2/sage/invoice/create', isHydra: true }
      )
      .takeUntil(this.destroyed$)
      .subscribe(() => {
        this.snackbar.validate('SAVED');
      })
    ;
  }

  public createReturnInvoice(isInter: boolean): void {
      this.hideButtons = true;
      this.resource
        .create(
          {
            order: HydraHelper.buildIri(this.order.id, 'orders'),
            isInternational: isInter,
          },
          { entryPoint: '/v2/sage/return-sale-invoice', isHydra: true }
        )
      .takeUntil(this.destroyed$)
      .subscribe(() => {
        this.snackbar.validate('SAVE in just a few minutes');
      })
    ;
  }
}
