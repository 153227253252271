import { Injectable } from '@angular/core';
import { AbstractResource } from '../../resources/abstract.resource';
import { Resource } from '@decorators/Resource';
import { TabListModel } from '@components/tab/models';
import { SessionHelper } from '@helpers/session.helper';
import { HttpClient } from '@angular/common/http';
import { BASE_URL_API } from '@constants';
import { Observable } from 'rxjs/Observable';

@Injectable()
@Resource({
  entryPoint: '/v2/tabs',
  routeName: 'tabs',
  editionState: 'tabs.edit',
  translationKey: 'TAB',
  isHydra: true,
  createAvailable: 'ROLE_WALISOFT_AGENT',
  deleteAvailable: true,
  model: TabListModel,
  cGetDefaultFilters: () => ({
    'parent[exists]': false,
    'translations.locale': SessionHelper.getLocale(),
    'order[position]': 'asc',
    'order[children.position]': 'asc',
    'pagination': false,
  }),
  buttonActionMultipleAvailable: true,
})
export class TabResource extends AbstractResource {

  protected nullableProperties: string[] = ['parent', 'startDate', 'endDate', 'category', 'contentBlock', 'commercialOperation', 'publicLink'];

  public getTabsLight(params: any = {}, options: any = {}): Observable<object> {
    options.entryPoint = '/v2/tabs/light';

    return super.cGet(params, options);
  }

  constructor(http: HttpClient) {
    super(http);
  }
}
