import { Component, Inject, Input, OnInit } from '@angular/core';
import { AbstractResource } from '@resources/abstract.resource';
import { ProductResource } from '@components/product/product.resource';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import { AuthService } from '@services/auth.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SnackbarService } from '@components/snackbar';
import { FormNotifierService } from '@services/form-notifier.service';
import { IFileInfo } from '@components/generic/Form/file-uploader/interfaces/file-info.interface';
import { IMarketplaceImage, IProductForm } from '@components/product/interfaces/product-form.interface';
import { IImage } from '@components/generic/List';
import { AbstractComponent } from '@components/generic/abstract.component';
import {SessionHelper} from '@helpers';

@Component({
  selector: 'app-product-pictures-form',
  template: require('./product-pictures-form.component.html'),
  providers: [
    { provide: AbstractResource, useClass: ProductResource },
  ],
})
export class ProductPicturesFormComponent extends AbstractComponent implements OnInit {

  public currentLocales: string[] = SessionHelper.getCountry().locales;
  public fileRestrictions: FileRestrictions = { allowedExtensions: ['.jpg', '.jpeg', '.png'] };
  public form: FormGroup;
  public marketplaceImageList: IImage[];

  @Input() model: IProductForm;

  private readonly productId: string;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    @Inject('DialogService') private dialog: any,
    private snackbar: SnackbarService,
    private formNotifier: FormNotifierService,
  ) {
    super($translate, authService, resource, state);

    this.productId = this.state.params.id;
  }

  /**
   * @inheritDoc
   */
  ngOnInit(): void {
    this.buildForm();
    this.fetch();
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      file: [null]
    });
  }

  private fetch(): void {
    const images = this.model.masterProduct.marketplaceImages;

    this.marketplaceImageList = images.filter((image: any) => image.version === this.model.getPreviewVersion()).map((img: IMarketplaceImage) => {
      return {
        id: img.id,
        thumbnailWebPath: img.thumbnailWebPath,
        watermarkedWebPath: img.watermarkedWebPath,
        position: img.position,
        selected: false,
      };
    });

    this.form.patchValue(this.model);
  }

  public submit(event?: any): void {
    const files = this.form.value.file.filter((f: IFileInfo) => this.fileRestrictions.allowedExtensions.includes(f.extension));
    const fileLength = files.length;

    if (0 === fileLength) {
      this.snackbar.warn(this.translate('UPLOADER.NO_FILES_TO_UPLOAD'));

      return;
    }

    this.dialog.confirm(this.translate('PAGE.PRODUCT.CONFIRM.UPDATE')).then(() => {
      this.create(files, 0, fileLength, event);
    });
  }

  private create(files: IFileInfo[], index: number, fileLength: number, event?: any): void {
    (<ProductResource>this.resource).createPMImage(this.model.masterProduct.id, <File>files[index].rawFile, this.model.getPreviewVersion())
      .takeUntil(this.destroyed$)
      .subscribe((response: IImage) => {
        this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));

        this.marketplaceImageList = [...this.marketplaceImageList, {
          id: response.id,
          thumbnailWebPath: response.thumbnailWebPath,
          watermarkedWebPath: response.watermarkedWebPath,
          position: response.position,
          selected: false,
        }];

        fileLength--;
        if (0 === fileLength) {
          this.formNotifier.notifyFormSubmitted();

          if (event && event.redirect) {
            this.state.go(`${this.resource.routeName}.list`);

            return;
          }

          return;
        }

        index++;
        this.create(files, index, fileLength, event);
      })
    ;
  }

  public updatePosition(img: IImage): void {
    (<ProductResource>this.resource).updateImage(
      this.model.masterProduct.id,
      img.id,
      { position: img.position },
      { dontUseModel: true }
    )
      .takeUntil(this.destroyed$)
      .subscribe(() => {
        this.snackbar.validate('ALERTS.DATA.UPDATE');
      })
    ;
  }

  public delete(imageList: IImage[]): void {
    this.deleteImage(imageList, 0, imageList.length);
  }

  private deleteImage(imageList: IImage[], index: number, imagesLength: number): void {
    (<ProductResource>this.resource).destroyImage(this.model.masterProduct.id, imageList[index].id, { dontUseModel: true })
      .takeUntil(this.destroyed$)
      .subscribe(() => {
        this.snackbar.validate(this.translate('ALERTS.DATA.UPDATE'));

        imagesLength--;
        if (0 === imagesLength) {
          return;
        }

        index++;
        this.deleteImage(imageList, index, imagesLength);
      })
    ;
  }
}
