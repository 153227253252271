import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AuthService } from '@services';
import { AbstractFormComponent } from '@components/generic/Form/abstract-form.component';
import { IFormViolation } from '@interfaces';
import { AbstractResource } from '@resources/abstract.resource';

@Component({
  selector: 'app-form-tab-translation',
  template: require('./form-tab-translation.component.html'),
  styles: [require('./form-tab-translation.component.scss')],
})
export class FormTabTranslationComponent extends AbstractFormComponent {

  public banner: string;

  @Input() public form: FormGroup;
  @Input() public parentForm: FormGroup;
  @Input() public violations: IFormViolation[] = [];
  @Input() public locale: string;
  @Input() public hideField: boolean;
  @Input() public cmsLinks: { name: string; id: string; };

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }
}
