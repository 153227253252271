import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { Component, Inject } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import { QualityExportFiltersFormService } from '@components/quality-export/quality-export-filters-form.service';

@Component({
  selector: 'app-quality-export',
  template: require('./quality-export.component.html'),
  providers: [
    { provide: AbstractFiltersFieldsService, useClass: QualityExportFiltersFormService },
  ]
})
export class QualityExportComponent extends AbstractPageComponent {

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, null, state);
  }
}
