import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import { AbstractResource } from '@resources/abstract.resource';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services/auth.service';
import { OrderResource } from '@resources/order.resource';
import { FormBuilder, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { SnackbarService } from '@components/snackbar';
import { FormNotifierService } from '@services/form-notifier.service';
import { OrderDuplicateService } from '@services';
import { ICarrierGroup } from '@interfaces/carrier-group.interface';
import { LogisticHelper, SessionHelper } from '@helpers';
import { CarrierGroupResource } from '@resources';
import { CODE_ES, CODE_FR, CODE_IT } from '@constants';
import { ICountry } from '@interfaces';
import { HydraHelper } from '@helpers/HydraHelper';
import {IStock} from '@components/product/interfaces';
import {IPackage} from '@models';

@Component({
  selector: 'app-order-creation',
  template: require('./order-creation.component.html'),
  providers: [
    { provide: AbstractResource, useClass: OrderResource },
  ],
})
export class OrderCreationComponent extends AbstractComponent implements OnInit {

  @ViewChild('detailsComponent') public detailsComponent: any;
  @ViewChild('productListComponent') public productListComponent: any;
  @ViewChild('orderResumeComponent') public orderResumeComponent: any;

  public showAdditionalContent: boolean = false;
  public marketplace: string = null;
  public tmpOrder: any = {};
  public orderFromDryRun: any;
  public form: FormGroup;
  public currentCountry: ICountry = SessionHelper.getCountry();

  public billingAddress: any;
  public shippingAddress: any;
  public items: any;
  public privateData: boolean = false;
  public collaborator: boolean;

  public carrierGroup: ICarrierGroup[] = LogisticHelper.getAssignableCarriers(SessionHelper.getCarrierGroups());

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    @Inject('DialogService') private dialog: any,
    private snackbar: SnackbarService,
    private formNotifier: FormNotifierService,
    private formBuilder: FormBuilder,
    private orderDuplicateService: OrderDuplicateService,
    private carrierGroupResource: CarrierGroupResource,
  ) {
    super($translate, authService, resource, state);
  }

  public ngOnInit(): void {
    this.fetchFromOrderDuplicateService();
    this.buildForm();
  }

  public fetchFromOrderDuplicateService(): void {
    if (this.state.params.marketplace === 'showroom') {
      return;
    }
    if (this.orderDuplicateService && Object.keys(this.orderDuplicateService).length !== 0) {
      this.state.params.marketplace = this.orderDuplicateService.getMarketplace();
      this.state.params.orderType = 'order';

      this.state.params.paymentType = this.orderDuplicateService.getPaymentType();

      this.state.params.orderId = this.orderDuplicateService.getOrderId();

      this.billingAddress = this.orderDuplicateService.getBillingAddress();
      this.tmpOrder.billingAddress = this.billingAddress;

      this.shippingAddress = this.orderDuplicateService.getShippingAddress();
      this.tmpOrder.shippingAddress = this.shippingAddress;

      this.items = this.orderDuplicateService.getItems();
    }
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({});
  }

  public updateAdditionalContentVisibility(showAdditionalContent: boolean): void {

    let orderType = this.detailsComponent.form.get('orderType').value;

    if ('estimate' === orderType) {
       orderType = 'order';
    }

    if (null !== orderType) {
      this.showAdditionalContent = showAdditionalContent;
    }

    if (null !== this.detailsComponent.form.get('marketplace').value) {
      this.marketplace = this.detailsComponent.form.get('marketplace').value.code;
    }

    this.tmpOrder = Object.assign(this.tmpOrder, {
      locale: this.currentLocale,
      marketplace: this.detailsComponent.form.get('marketplace').value ? this.detailsComponent.form.get('marketplace').value.code : null,
      paymentType: this.detailsComponent.form.get('paymentType') ? this.detailsComponent.form.get('paymentType').value : 'marketplace',
      orderType: orderType,
      orderParentId: orderType === 'sav' ? this.detailsComponent.form.get('orderParentId').value : null
    });
    this.updateCarrierGroups(this.tmpOrder.marketplace, (this.shippingAddress ? this.shippingAddress.countryCode : null));

    if (undefined !== this.tmpOrder.items) {
      for (let i = 0; i < this.tmpOrder.items.length; i++) {
        this.productListComponent.dryRun(this.tmpOrder, i);
      }
    }
  }

  private isUnpaired(order: any): boolean {
    return order.items.filter((item: any) => item.unpaired ).length > 0;
  }

  public saveNewOrder(event: any): void {
    const order = this.productListComponent.prepareNewOrderBody();

    this.checkOrder(order);

    if (this.isUnpaired(order)) {
      (<OrderResource>this.resource).create({ marketplace: order.marketplace, items: order.items }, { entryPoint: '/v2/order-manager/unpaired/substitution' })
      .subscribe((response: any) => {
        if (Object.keys(response.substitutions).length) {
          let msg = '';

          Object.keys(response.substitutions).forEach((key: any) => {
            msg += '<br/>' + this.translate('PAGE.ORDER.NEW.BLOCK_PRODUCTS.TEXT.PROPOSAL_SUBSTITUTION', {
              oldPackage: key,
              substitutionPackage: response.substitutions[key]
            });
          });

          this.dialog.alert(this.translate('PAGE.ORDER.NEW.BLOCK_PRODUCTS.TEXT.VALIDATE_SUBSTITUTIONS') + msg).then(() => {
            this.doSaveNewOrder(event, order);
          });
        } else {
          this.doSaveNewOrder(event, order);
        }
      });
    } else {
      this.doSaveNewOrder(event, order);
    }
  }

  public doSaveNewOrder(event: any, order: any): void {
    this.dialog.confirm(this.translate('PAGE.ORDER.NEW.BLOCK_PRODUCTS.TEXT.CREATE.CONFIRM'))
      .then(() => {
        const body: any = this.prepareBody(order.items);

        (<OrderResource>this.resource).create(body, { entryPoint: '/v2/order-manager/manual-order-creation' })
          .pipe(takeUntil(this.destroyed$))
          .subscribe(
            (response: any) => {
              this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));

              if (event && event.redirect) {
                this.state.go(`order_manager.list`);
                return;
              }

              const orderId = response['id'];

              if (this.tmpOrder.comment && this.tmpOrder.comment.message) {
                (<OrderResource>this.resource).createComment(response['id'], this.tmpOrder.comment).subscribe(
                  () => {
                    this.redirectOnOrderManager(orderId);
                  }
                );
              } else {
                this.redirectOnOrderManager(orderId);
              }
            },
            (reject: any) => {
              Object.keys(reject.errors).forEach((formName: string) => {
                Object.keys(reject.errors[formName]).forEach((formControlName: string) => {
                  reject.errors[formName][formControlName].errors.forEach((errorMessage: string) => {
                    this.snackbar.alert(`${formName}: ${formControlName}: ${errorMessage}`);
                  });
                });
              });
            }
          );
      });
  }

  private prepareBody(items: any = []): any {
    const detailsForm: FormGroup = (<FormGroup>this.form.get('details'));
    let estimate = false;
    let orderType = detailsForm.get('orderType').value;

    if ('estimate' === detailsForm.get('orderType').value) {
      estimate = true;
      orderType = 'order';
    }

    if ('order' === orderType) {
      items.forEach((item: any, i: number) => {
        items[i].unitPrice = item.unitPrice - item.product.ecotax - item.product.deee;
        items[i].ecotax = item.product.ecotax;
        items[i].deee = item.product.deee;
      });
    }

    return {
      shippingAddress: this.shippingAddress,
      billingAddress: this.billingAddress,
      locale: this.currentLocale,
      marketplace: detailsForm.get('marketplace').value.code,
      orderType: orderType,
      referralOrderSav: orderType === 'sav' ? HydraHelper.buildIri(detailsForm.get('orderParentId').value, 'orders') : null,
      items: {...items},
      paymentType: detailsForm.get('paymentType') ? detailsForm.get('paymentType').value : 'marketplace',
      sendPaymentLink: detailsForm.get('paymentLink') && detailsForm.get('paymentLink').value ? true : false,
      manuallyAdded: true,
      grcReason: detailsForm.get('grcReason').value,
      estimate: estimate,
      zendeskTicketId: detailsForm.get('zendeskTicketId').value,
      privateData: !!(+this.privateData),
      discount: this.tmpOrder.discount,
      collaborator: !!(+this.collaborator)
    };
  }

  public onUpdateEmailCustomer($event: any) {
    this.billingAddress = $event.billingAddress;
    this.shippingAddress = $event.shippingAddress;
    this.collaborator = $event.collaborator;
    this.tmpOrder.billingAddress = this.billingAddress;
    this.tmpOrder.shippingAddress = this.shippingAddress;
  }

  public onUpdateComment($event: any) {
    if ($event) {
      this.tmpOrder.comment = { message: $event };
    }
  }

  public onAddressesUpdate($event: any) {
    this.billingAddress = $event.billingAddress;
    this.shippingAddress = $event.shippingAddress;
    this.tmpOrder.billingAddress = this.billingAddress;
    this.tmpOrder.shippingAddress = this.shippingAddress;
    this.privateData = $event.privateData;
    this.collaborator = $event.collaborator;
    this.updateCarrierGroups(this.tmpOrder.marketplace, (this.shippingAddress ? this.shippingAddress.countryCode : null));
  }

  public onUpdateProductList($event: any) {
    this.orderFromDryRun = $event;
    this.orderResumeComponent.computeOrderResume();
    this.updateCarrierGroups(this.tmpOrder.marketplace, (this.shippingAddress ? this.shippingAddress.countryCode : null));
  }

  public getStockQuantityForPackage(item: any, packageNumber: number): number {
    const productPackage = item.product.packages.find((element: IPackage) => element.number === packageNumber);

    if (!productPackage) {
      return 0;
    }

    const productPackageStock = productPackage.stocks && productPackage.stocks
      .filter((stock: any) => stock.type === 'SP' && stock.warehouse.code === item.warehouse)
      .at(0)
    ;

    return productPackageStock && productPackageStock.quantity || 0;
  }


  private redirectOnOrderManager(orderId: string) {
    this.formNotifier.notifyFormSubmitted();
    this.state.go(`order_manager.edit`, { id: orderId });
  }

  private updateCarrierGroups(marketplace?: string, countryCode?: string): void {
    if (marketplace === 'showroom' && countryCode) {
      this.carrierGroupResource.getFromCountry(countryCode).subscribe((response: ICarrierGroup[]) => {
        this.carrierGroup = LogisticHelper.getAssignableCarriers(SessionHelper.transformCarrierGroup(response, countryCode));
        if (this.carrierGroup.length === 0) {
          this.carrierGroup = LogisticHelper.getAssignableCarriers(SessionHelper.getCarrierGroups());
        }
      });
    } else {
      this.carrierGroup = LogisticHelper.getAssignableCarriers(SessionHelper.getCarrierGroups());
    }
  }

  private checkOrder(order: any) {
    if (!order.items || order.items.length < 1) {
      this.snackbar.warn(this.translate('PAGE.ORDER.NEW.BLOCK_PRODUCTS.TEXT.NO_PRODUCTS'));
      throw new Error(this.translate('PAGE.ORDER.NEW.BLOCK_PRODUCTS.TEXT.NO_PRODUCTS'));
    }

    let subTotal = 0;
    let discountTotal = 0;
    let shippingPriceTotal = 0;

    let secondHandItems = 0;
    let unpairedItems = 0;

    order.items.forEach((item: any) => {
      subTotal += +item.totalPrice;
      discountTotal += +item.discount;
      discountTotal += +item.fidelity;
      shippingPriceTotal += +item.shippingPrice;

      if (item.product.sku === 'DEALS' && !item.secondHand) {
        this.snackbar.warn(this.translate('PAGE.ORDER_MANAGER.FORM.ORDER_ITEM.ERRORS.SECOND_HAND_DEALS'));
        throw new Error(this.translate('PAGE.ORDER_MANAGER.FORM.ORDER_ITEM.ERRORS.SECOND_HAND_DEALS'));
      }

      if (item.secondHand) {
        secondHandItems += 1;

        if ((CODE_FR === this.currentCountry.code && 'alice_deals' !== item.warehouse) ||
            (CODE_ES === this.currentCountry.code && 'fercam' !== item.warehouse) ||
            (CODE_IT === this.currentCountry.code && 'fercamit' !== item.warehouse)) {
          this.snackbar.warn(this.translate('PAGE.ORDER_MANAGER.FORM.ORDER_ITEM.ERRORS.SECOND_HAND_WAREHOUSE'));
          throw new Error(this.translate('PAGE.ORDER_MANAGER.FORM.ORDER_ITEM.ERRORS.SECOND_HAND_WAREHOUSE'));
        }

        if (item.product.sku === 'DEALS' && (!item.sageDescription || item.sageDescription.trim() === '')) {
          this.snackbar.warn(this.translate('PAGE.ORDER_MANAGER.FORM.ORDER_ITEM.ERRORS.SECOND_HAND_DEALS_SAGE_DESCRIPTION'));
          throw new Error(this.translate('PAGE.ORDER_MANAGER.FORM.ORDER_ITEM.ERRORS.SECOND_HAND_DEALS_SAGE_DESCRIPTION'));
        }

        if (item.product.sku !== 'DEALS' && item.sageDescription && item.sageDescription.trim()) {
          this.snackbar.warn(this.translate('PAGE.ORDER_MANAGER.FORM.ORDER_ITEM.ERRORS.SECOND_HAND_NOT_DEALS_SAGE_DESCRIPTION'));
          throw new Error(this.translate('PAGE.ORDER_MANAGER.FORM.ORDER_ITEM.ERRORS.SECOND_HAND_NOT_DEALS_SAGE_DESCRIPTION'));
        }
      }

      if (item.unpaired) {
        unpairedItems += 1;
        if (item.packagesNumber.length === 0) {
          this.snackbar.warn(this.translate('PAGE.ORDER_MANAGER.FORM.ORDER_ITEM.ERRORS.UNPAIRED_NO_PACKAGE'));
          throw new Error(this.translate('PAGE.ORDER_MANAGER.FORM.ORDER_ITEM.ERRORS.UNPAIRED_NO_PACKAGE'));
        }
      }
    });

    if (secondHandItems && secondHandItems !== order.items.length) {
      this.snackbar.warn(this.translate('PAGE.ORDER_MANAGER.FORM.ORDER_ITEM.ERRORS.SECOND_HAND_ALL'));
      throw new Error(this.translate('PAGE.ORDER_MANAGER.FORM.ORDER_ITEM.ERRORS.SECOND_HAND_ALL'));
    }

    if (unpairedItems && unpairedItems !== order.items.length) {
      this.snackbar.warn(this.translate('PAGE.ORDER_MANAGER.FORM.ORDER_ITEM.ERRORS.UNPAIRED_ALL'));
      throw new Error(this.translate('PAGE.ORDER_MANAGER.FORM.ORDER_ITEM.ERRORS.UNPAIRED_ALL'));
    }

    discountTotal += order.discount;
    const total = subTotal + shippingPriceTotal - discountTotal;

    if (total < 0) {
      this.snackbar.warn('Prix total négatif');
      throw new Error('Prix total négatif');
    }
  }
}
