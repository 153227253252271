import { Component, Inject, Input } from '@angular/core';
import { AbstractFormComponent } from '@components/generic/Form/abstract-form.component';
import { IProductForm } from '@components/product/interfaces/product-form.interface';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';

@Component({
  selector: 'app-package-management',
  template: require('./package-management.component.html'),
})
export class PackageManagementComponent extends AbstractFormComponent {

  @Input() public model: IProductForm;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    resource: AbstractResource,
  ) {
    super($translate, authService, resource, state);
  }
}
