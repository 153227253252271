import {Component, Inject, Input, OnInit} from '@angular/core';
import { FormArray } from '@angular/forms';
import { MP_NAMES } from '@constants/marketplace';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'app-super-product-form-translations-reference',
  template: require('./super-product-form-translations-reference.html'),
})
export class SuperProductFormTranslationsReferenceComponent extends AbstractComponent implements OnInit {

  public messageSuccess = false;

  @Input() translationsFA: FormArray;
  @Input() tabIndex: number = 0;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, null, state);
  }

  ngOnInit() {
    setTimeout(() => {
      this.messageSuccess = true;
    }, 150);
  }
}
