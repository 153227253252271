import { CarrierScaleScheduledModel } from './carrier-scale-scheduled.model';
import { IWarehouses } from '@components/warehouses/models';
import { IWarehouseCarrierGroupAgencies } from '@components/warehouses/models/warehouse-carrier-group-agencies.interface';
import { SessionHelper } from '@helpers';
import { ICountry, ICurrentCarrier, IMarketplace } from '@interfaces';
import { UserModel } from '@models';
import moment = require('moment');

export class LogisticsScheduledModel {
  public '@id': string;
  public id: string;
  public countries: ICountry[];
  public marketplaces: IMarketplace[];
  public carrierGroups: ICurrentCarrier[];
  public warehouses: IWarehouses[];
  public warehouseCarrierGroupAgencies: IWarehouseCarrierGroupAgencies[];
  public schedule: moment.Moment[];
  public active: boolean;
  public carrierScaleScheduled?: CarrierScaleScheduledModel;
  public createdAt: Date;
  public createdBy: UserModel;

  constructor(model: any, allWarehouseCarrierGroupAgencies: IWarehouseCarrierGroupAgencies[] = null) {
    this['@id'] = model['@id'];
    this.id = model.id;
    this.countries = this.getCountries(model.params.countries);
    this.marketplaces = this.getMarketplaces(model.params.marketplaces);
    this.warehouses = this.getWarehouses(model.params.warehouses);
    this.carrierGroups = this.getCarrierGroups(model.params.carrierGroups);
    this.warehouseCarrierGroupAgencies = this.getWarehouseCarrierGroupAgencies(
      model.params.warehouseCarrierGroupAgencies.map((element: any) => element.carrierGroupAgencyId),
      allWarehouseCarrierGroupAgencies
    );
    this.schedule = this.getSchedule(model.schedule);
    this.active = model.active;
    if (model.carrierScaleScheduled) {
      this.carrierScaleScheduled = new CarrierScaleScheduledModel(model.carrierScaleScheduled);
    }
    this.createdAt = new Date(model.createdAt);
    this.createdBy = new UserModel(model.createdBy);
  }

  private getCountries(countries: string[]): ICountry[] {
    return SessionHelper.getCountries().filter((country: ICountry) => countries.indexOf(country.code) !== -1);
  }

  private getMarketplaces(marketplaces: string[]): IMarketplace[] {
    return SessionHelper.getAllMarketplaces().filter(
      (marketplace: IMarketplace) => marketplaces.indexOf(marketplace.code) !== -1
    );
  }

  private getWarehouses(warehouses: string[]): IWarehouses[] {
    return SessionHelper.getAllWarehouses().filter(
      (warehouse: IWarehouses) => warehouses.indexOf(warehouse.code) !== -1
    );
  }

  private getCarrierGroups(carrierGroups: string[]): ICurrentCarrier[] {
    return SessionHelper.getAllCarrierGroups().filter(
      (carrierGroup: ICurrentCarrier) => carrierGroups.indexOf(carrierGroup.code) !== -1
    );
  }

  private getWarehouseCarrierGroupAgencies(
    warehouseCarrierGroupAgencies: any,
    allWarehouseCarrierGroupAgencies: IWarehouseCarrierGroupAgencies[]
  ): IWarehouseCarrierGroupAgencies[] {
    return allWarehouseCarrierGroupAgencies.filter((warehouseCarrierGroupAgency: IWarehouseCarrierGroupAgencies) => {
      return warehouseCarrierGroupAgencies.indexOf(warehouseCarrierGroupAgency.carrierGroupAgency.id) !== -1;
    });
  }

  private getSchedule(schedule: string[]): moment.Moment[] {
    const currentMoment = moment(),
      days = [, 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'],
      sortedSchedule: moment.Moment[] = [];
    for (let i = 0; i < schedule.length; i++) {
      let day: string, hour: string;
      [day, hour] = schedule[i].split('_');
      const isoWeekday = days.indexOf(day);
      const scheduleMoment = moment()
        .isoWeekday(isoWeekday)
        .hour(+hour)
        .startOf('hour');
      if (scheduleMoment.isBefore(currentMoment)) {
        scheduleMoment.add(7, 'days');
      }
      sortedSchedule.push(scheduleMoment);
    }
    sortedSchedule.sort((a: moment.Moment, b: moment.Moment) => {
      return a.isAfter(b) ? 1 : -1;
    });

    return sortedSchedule;
  }
}
