import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { AbstractResource } from '../resources/abstract.resource';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

@Injectable()
@Resource({
  entryPoint: '/v2/master_products'
})
export class MasterProductResource extends AbstractResource {
  protected nullableProperties: string[] = ['width', 'height', 'length', 'numberOfPieces'];

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  public computeStock(masterProductId: string, warehouse: string, businessObject: string, body?: object, options?: object): Observable<Object> {
    return this.create(body, {
      ...options,
      entryPoint: `${this.entryPoint}/${masterProductId}/compute/${warehouse}/${businessObject}`,
    });
  }

  public getAddressing(masterProductId: string, params: any = {}, options: any = {}): Observable<Object> {
    options.entryPoint = `${this.entryPoint}/${masterProductId}/addressing`;
    return this.cGet(params, options);
  }
  public putAttributeValues(masterProductId: string, body?: object, options: any = {}) {
    options.entryPoint = `${this.entryPoint}/${masterProductId}/attribute_values`;
    return this.update(masterProductId, body, options);
  }
}
