export default supplierRoutes;

/** @ngInject */
function supplierRoutes($stateProvider: any): void {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'walipush',
    sidebarSubNav: 'supplierList',
    title: '',
  };

  $stateProvider
    .state('supplier', {
      url: '/suppliers',
      parent: 'walisoft',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.SUPPLIER',
          stateName: 'supplier.list'
      },
    })
    .state('supplier.list', {
      url: '?page&byPage&address.countryCode&label&supplierCommercialEntities.sageIdentifier&supplierCommercialEntities.commercialEntity',
      views: {
        'page@layout': {
          template: '<app-supplier-list></app-supplier-list>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.SUPPLIER.LIST.TITLE'
      })
    })
    .state('supplier.new', {
      url: '/new',
      breadcrumb: { label: 'BREADCRUMB.NEW' },
      views: {
        'page@layout': {
          template: '<app-supplier-form></app-supplier-form>'
        },
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.SUPPLIER.NEW.TITLE',
      })
    })
    .state('supplier.edit', {
      url: '/:id',
      breadcrumb: { label: 'BREADCRUMB.EDIT' },
      views: {
        'page@layout': {
          template: '<app-supplier-form></app-supplier-form>'
        },
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.SUPPLIER.EDIT.TITLE',
      })
    })
  ;
}
