'use strict'

angular
  .module 'ammsFrontendApp'
  .factory 'ProductChecker', [
    ->
      class ProductChecker
        constructor: (@product, @marketplaces, @vat) ->
          return this

        populateProductMarketplaceData: (marketplace, productMarketplace) ->
          if productMarketplace.specialPrice?
            productMarketplace.total = productMarketplace.specialPrice + productMarketplace.shippingPrice
          else if productMarketplace.currentOffer?.specialPrice?
            productMarketplace.total = productMarketplace.currentOffer.specialPrice + productMarketplace.shippingPrice
          else
            productMarketplace.total = productMarketplace.basePrice + productMarketplace.shippingPrice

          divisor = 1 + @vat
          totalPriceDutyFree = (productMarketplace.total / divisor)

          rate = 0
          fixedAmount = 0
          if @product.superProduct and @product.superProduct.catalog and @product.superProduct.catalog.marketplaces[productMarketplace.marketplace]?
            rate = @product.superProduct.catalog.marketplaces[productMarketplace.marketplace].rate
            fixedAmount = @product.superProduct.catalog.marketplaces[productMarketplace.marketplace].fixedAmount

          commission = 0
          if 0 < rate or 0 < fixedAmount
            commission = ((rate / 100) * totalPriceDutyFree) + fixedAmount

          if divisor > 1
            productMarketplace.marginVolume = ((totalPriceDutyFree - (@product.cmup + @product.familyShippingCost + commission)) / totalPriceDutyFree) * 100

          productMarketplace

        populate: ->
          for marketplace in @marketplaces
            if @product.productMarketplaces[marketplace.code]
              @populateProductMarketplaceData marketplace, @product.productMarketplaces[marketplace.code]
  ]
