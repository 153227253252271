import { PaymentIconsResource } from '@components/payment-carrier-icons/payment-icons/payment-icons.resource';

export default paymentCarrierIconsRoutes;

/** @ngInject */
function paymentCarrierIconsRoutes($stateProvider: any) {
  const commonStateData: { [key: string]: any } = {
    sidebarNav: 'website',
    sidebarSubNav: 'paymentCarrierIcons',
    title: '',
    reloadChangeCountry: 'paymentCarrierIcons.list',
  };

  $stateProvider
    .state('paymentCarrierIcons', {
      url: '/payment-carrier-icons',
      parent: 'website',
      abstract: true,
      breadcrumb: {
        label: 'BREADCRUMB.PAYMENT_CARRIER_ICONS',
        stateName: 'paymentCarrierIcons.list'
      }
    })
    .state('paymentCarrierIcons.list', {
      url: `?
        page&
        limit
      `,
      views: {
        'page@layout': {
          template: '<app-payment-carrier-icons></app-payment-carrier-icons>'
        }
      },
      data: Object.assign(commonStateData, {
        title: 'PAGE.PAYMENT_CARRIER_ICONS.LIST.TITLE',
        resource: PaymentIconsResource,
      })
    })
  ;
}
