import { IHydraMember } from '@interfaces/hydra-resource.interface';
import {SupplierAddressModel} from '@components/supplier/models/supplier-address.model';
import {SupplierModel} from '@components/supplier/models/supplier.model';
import {CountryTypeEnum} from '@components/supplier/models/country-type.enum';
import {SupplierCommercialEntityModel} from '@components/supplier/models/supplier-commercial-entity.model';

export class SupplierListModel {
  '@id': string;
  id: number;
  label: string;
  country: string;
  contactName: string;
  address: string;
  phoneNumber: string;
  email: string;
  supplierCommercialEntities: SupplierCommercialEntityModel[];

  constructor(object: IHydraMember) {
    const supplier = new SupplierModel(object);

    this['@id'] = supplier['@id'];
    this.id = supplier.id;
    this.label = supplier.label;
    this.country = this.formatCountry(supplier.address.countryCode);
    this.contactName = `${supplier.address.firstName} ${supplier.address.lastName}`;
    this.address = this.formatAddress(supplier.address);
    this.phoneNumber = supplier.address.phoneNumber;
    this.email = supplier.address.email;
    this.supplierCommercialEntities = supplier.supplierCommercialEntities;
  }

  private formatCountry(countryCode: string) {
    for (const [key, value] of Object.entries(CountryTypeEnum)) {
      if (countryCode.toLowerCase() === key.toLowerCase()) {
        return value;
      }
    }

    return countryCode;
  }

  private formatAddress(address: SupplierAddressModel) {
    let formattedAddress = '';

    if (address.address) {
      formattedAddress += formattedAddress.length ? ` - ${address.address}` : address.address;
    }

    if (address.additionalAddress) {
      formattedAddress += formattedAddress.length ? ` - ${address.additionalAddress}` : address.additionalAddress;
    }

    if (address.postcode) {
      formattedAddress += formattedAddress.length ? ` - ${address.postcode}` : address.postcode;
    }

    if (address.city) {
      formattedAddress += formattedAddress.length ? ` - ${address.city}` : address.city;
    }

    if (address.province) {
      formattedAddress += formattedAddress.length ? ` - ${address.province}` : address.province;
    }

    return formattedAddress;
  }
}
