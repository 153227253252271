import { Injectable } from '@angular/core';
import { AbstractResource } from '../../resources/abstract.resource';
import { SessionHelper } from '../../helpers/session.helper';
import { CatalogsModel } from '@components/catalogs/models/catalogs.model';
import { Resource } from '@decorators/Resource';
import { HttpClient } from '@angular/common/http';

@Injectable()
@Resource({
  routeName: 'catalogs',
  entryPoint: '/v2/catalogs',
  translationKey: 'CATALOGS',
  model: CatalogsModel,
  cGetDefaultFilters: () => ({ locale: SessionHelper.getLocale() }),
  listColumns: [
    { field: 'id', translationKey: 'ID', type: 'id' },
    { field: 'label', translationKey: 'LABEL', type: 'string' },
    { field: 'activeLabel', translationKey: 'ACTIVE', type: 'string' },
  ],
  createAvailable: true,
  updateAvailable: true,
  deleteAvailable: false,
  isHydra: true,
})
export class CatalogsResource extends AbstractResource {

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }
}
