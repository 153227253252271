import { ICountry } from '@interfaces';
import { LOCALE_FR } from '@constants';
import { SessionHelper } from '@helpers';

export class CountryHelper {
  /**
   * Add default values for several properties of a country.
   */
  public static decodeCountry(country: ICountry): ICountry {
    const currentLocale: string = SessionHelper.getLocale();

    return {
      code: country.code,
      currencyCode: country.currencyCode,
      defaultLocale: country.defaultLocale || country.locales[0],
      id: country.id,
      locales: country.locales,
      name: country.translations[currentLocale] ? country.translations[currentLocale].name : '',
      paymentTypes: country.paymentTypes,
      translations: country.translations,
      vat: country.vat,
      commercialEntity: country.commercialEntity,
      marketplaces: country.marketplaces,
    };
  }

  /**
   * Extract all locales in a list of countries
   *
   * @param {ICountry[]} countries
   * @returns {string[]}
   */
  static getLocales(countries: ICountry[]): string[] {
    if (countries) {
      return countries.map((item: ICountry) => item.locales)
        .reduce(((last: string[], current: string[]) => [...last, ...current]), []);
    }

    return [];
  }

  /**
   * Extract country code from a local code
   *
   * @param {string} locale
   * @returns {string}
   */
  static getCountryCodeFromLocale(locale: string): string {
    if (!locale) {
      return '';
    }

    return locale.toLowerCase().substr(3);
  }

  /**
   * Extract all locales available in a list of countries for the current lang note: the french local is available
   * by default for all countries
   *
   * @param {ICountry[]} countries
   * @param {string} currentLocale
   * @returns {string[]}
   */
  static getLocalesByLang(countries: ICountry[], currentLocale: string): string[] {
    if (undefined === currentLocale) {
      return [];
    }

    const countryCodeOfCurrentLocale = CountryHelper.getCountryCodeFromLocale(currentLocale);

    const locales = CountryHelper.getLocales(countries)
      .filter((locale: string) => {
        return CountryHelper.getCountryCodeFromLocale(locale) === countryCodeOfCurrentLocale;
      });

    locales.push(LOCALE_FR);

    return locales;
  }

  /**
   * Checks if the targeted country is the current one
   *
   * @param {string} countryCode
   * @returns {boolean}
   */
  static isCurrentCountry(countryCode: string): boolean {
    const country = SessionHelper.getCountry();

    return null !== country && countryCode === country.code;
  }

  static getAllLocalesForApplication(): string[] {
    return CountryHelper.getLocales(SessionHelper.getCountries());
  }

  static getCountryByCountryCode(countryCode: string): ICountry {
    return SessionHelper.getCountries().find((country) => country.code === countryCode);
  }

  static getCountryChilrenByCountryCode(countryCode: string): ICountry[] {
    return SessionHelper.getApplicationActiveCountriesWithParent().filter((country: ICountry) => country.parent && country.parent.code === countryCode);
  }

  static getCountryParentByCountryCodeOrCountry(countryCode: string): ICountry {
    const countries = SessionHelper.getApplicationActiveCountriesWithParent().filter((country: ICountry) => country.code === countryCode);

    if (countries[0]) {
      return countries[0].parent;
    }

    return this.getCountryByCountryCode(countryCode);
  }

  static getDefaultLocaleByCountryCode(countryCode: string): string {
    const country: ICountry = SessionHelper
        .getCountries()
        .find(countryToFind => countryCode === countryToFind.code);

    return country ? country.defaultLocale : null;
  }
}
