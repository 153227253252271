import { Injectable } from '@angular/core';
import { Resource } from '@decorators';
import { HttpClient } from '@angular/common/http';
import { AbstractResource } from '@resources';
import {SessionHelper} from '@helpers';
import {IListColumn} from '@interfaces';

@Injectable()
@Resource({
  routeName: 'glossary',
  entryPoint: '/v2/glossaries',
  translationKey: 'GLOSSARY',
  cGetDefaultFilters: () => ({
    'toLang': SessionHelper.getCountry() && SessionHelper.getCountry().code
  }),
  getDefaultListColumns: (): IListColumn[] => {
    return [
      { field: 'id', translationKey: 'ID', type: 'id' },
      { field: 'fromText', translationKey: 'FR', type: 'string', sortable: true },
      { field: 'toText', translationKey: SessionHelper.getCountry() && SessionHelper.getCountry().code, type: 'string', sortable: true },
    ];
  },
  createAvailable: true,
  deleteAvailable: true,
  updateAvailable: false,
  isHydra: true,
  importOptions: [
    {
      entryPoint: '/api/v2/import',
      importButton: 'BUTTON.IMPORT.GLOSSARY',
      businessObject: 'glossary'
    },
  ]
})
export class GlossaryResource extends AbstractResource {
  constructor(
    http: HttpClient,
  ) {
    super(http);
  }
}
