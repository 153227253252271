import { AbstractComponent } from '@components/generic/abstract.component';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractResource } from '@resources/abstract.resource';
import { IMarketplace } from '@interfaces/marketplace.interface';
import { IProductMarketplaceForm } from '@components/product/interfaces/product-marketplace-form.interface';
import { ProductMarketplaceResource } from '@components/product/product-marketplace.resource';
import { ProductMarketplaceForm } from '@components/product/models/product-marketplace-form.model';
import { MarketplaceHelper } from '@helpers/MarketplaceHelper';

@Component({
  selector: 'app-product-marketplace-forms',
  template: require('./product-marketplace-forms.component.html'),
  providers: [
    { provide: AbstractResource, useClass: ProductMarketplaceResource },
  ]
})
export class ProductMarketplaceFormsComponent extends AbstractComponent implements OnInit {

  public productMarketplace: IProductMarketplaceForm;

  @Input() marketplace: IMarketplace;
  @Input() sparePartContext: boolean = false;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }

  /**
   * @inheritDoc
   */
  ngOnInit(): void {
    (<ProductMarketplaceResource>this.resource).getProductMarketplace(
      this.state.params.id,
      this.marketplace.code,
      { formModel: ProductMarketplaceForm }
    )
      .takeUntil(this.destroyed$)
      .subscribe((response: IProductMarketplaceForm) => {
        this.productMarketplace = response;
      }
      , (reject: any) => {
        if (404 === reject.status) {
          this.resource.create({
            stock: 0,
            shippingPrice: 0,
            basePrice: 0,
            status: 'inactive',
            product: `/api/v2/products/${this.state.params.id}`,
            marketplace: this.marketplace.code,
            offers: [],
            categoryMarketplace: null,
            attributeMarketplaceValues: [],
          }, { entryPoint: '/v2/product_marketplaces'}
          )
            .takeUntil(this.destroyed$)
            .subscribe((response: IProductMarketplaceForm) => this.productMarketplace = response)
          ;
        }
      })
    ;
  }

  public isAttributeEditableMarketplace(): boolean {
    return MarketplaceHelper.isAttributeEditableMarketplace(this.marketplace);
  }
}
