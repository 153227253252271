import { Injectable } from '@angular/core';
import { CustomerDetailModel } from '@components/customer/models/customer-detail.model';
import { Resource } from '@decorators';
import { AbstractResource } from '../../resources/abstract.resource';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

@Injectable()
@Resource({
  entryPoint: '/v2/customers',
  model: CustomerDetailModel,
  translationKey: 'CUSTOMER_CARD',
})
export class CustomerDetailResource extends AbstractResource {

  protected nullableProperties: string[] = ['gender'];

  constructor(
    http: HttpClient,
  ) {
    super(http);
  }

  public reset(customerId: string): Observable<Object> {
    return this.create({customerId: customerId}, {entryPoint: '/v2/customer_resets', isHydra: true});
  }
}
