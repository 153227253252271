import { Component, Inject, Input } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { IColumn } from './interfaces/column.interface';
import { IColumnSortedEvent } from '../List/services/sort.service';
import { AuthService } from '@services';

@Component({
  selector: 'app-grid',
  template: require('./grid.component.html'),
})
export class GridComponent extends AbstractComponent {

  /**
   * Defines the columns of the grid.
   * If a template is defined then the cell will render according to the passed template.
   * Column and row are exposed.
   * Else the field is rendered.
   * if nestedHeaders (IColumn[]) are passed to a column
   * then headers will be nested and columns rendered.
   * Supports only one level of nested headers.
   * If a template is defined then the cell will render according to the passed template.
   * Column and row are exposed.
   * Else the field is rendered.
   * Adds sortable columns if sortable is defined to true (works with state params and reloads state)
   *
   * @todo When needed add possibility to add a header template (for multiselection for example)
   */
  @Input() public columns: IColumn[];

  /**
   * Defines the rows of the grid.
   */
  @Input() public rows: { [key: string]: any }[];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, null, state);
  }
  public tableHasNestedHeaders(): boolean {
    return !!(this.columns && this.columns.filter((column: IColumn) => !!column.nestedHeaders).length);
  }

  /**
   * Sorts the items.
   */
  public onSorted(event: IColumnSortedEvent): void {
    const params = Object.assign({}, this.state.params);
    params.orderBy = event.sortColumn;
    params.direction = event.sortDirection;
    if (params.direction === 'none') {
      params.orderBy = undefined;
      params.direction = undefined;
    }
    this.state.go('.', params, {reload: true});
  }
}
