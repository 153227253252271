import { Component, Inject, Input, OnInit} from '@angular/core';
import { AuthService } from '@services/auth.service';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { AbstractResource } from '@resources/abstract.resource';
import { IDndColumn } from '@components/generic/dnd-list/dnd-list.component';
import { ReinsurancePointsResource } from '@components/footer/reinsurance-points';

@Component({
  selector: 'app-reinsurance-points-result-list',
  template: require('./reinsurance-points-result-list.component.html'),
  providers: [
    { provide: AbstractResource, useClass: ReinsurancePointsResource },
  ],
})

export class ReinsurancePointsResultListComponent extends AbstractPageComponent implements OnInit {
  @Input() public locale: string;

  public reinsurance: any;
  public columns: IDndColumn[];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, resource, state);
  }


  public fetchData(): void {
    this.resource.cGet({
      'locale': this.locale
    })
      .takeUntil(this.destroyed$)
      .subscribe((response: any): void => {
        this.reinsurance = response['hydra:member'];
      });
  }

  public ngOnInit(): void {
    this.fetchData();
    this.columns = [{
      size: 4,
      title: this.translate('PAGE.REINSURANCE_POINTS.LIST.TABLE.COLUMN.IMAGE'),
      property: 'imageWebPath',
      type: 'img',
    },
    {
      size: 3,
      title: this.translate('PAGE.REINSURANCE_POINTS.LIST.TABLE.COLUMN.FIRST_LINE'),
      property: 'firstLine',
    },
    {
      size: 3,
      title: this.translate('PAGE.REINSURANCE_POINTS.LIST.TABLE.COLUMN.SECOND_LINE'),
      property: 'secondLine',
    }];
  }
}
