import { NgModule } from '@angular/core';
import { RefundsListComponent } from '@components/transactions/refunds/list';
import { GenericModule } from '@components/generic';
import { CommonModule } from '@angular/common';
import { RefundsResultListComponent } from './list/refunds-result-list.component';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { BsDropdownModule } from 'ngx-bootstrap';

@NgModule({
  declarations: [
    RefundsListComponent,
    RefundsResultListComponent,
  ],
  entryComponents: [
    RefundsListComponent,
  ],
  imports: [
    CommonModule,
    GenericModule,
    DropDownListModule,
    BsDropdownModule,
  ]
})
export class RefundsModule {}
