import { Component, Inject, OnInit, Input } from '@angular/core';
import { FormGroup, Validators, FormArray, FormBuilder, FormControl } from '@angular/forms';
import { ColorHelper, SessionHelper } from '@helpers';
import { AuthService, FormNotifierService } from '@services';
import { ICountry, IFormViolation, IResource } from '@interfaces';
import { ITab, ITabList, TabListModel } from '@components/tab/models';
import { AbstractFormComponent } from '@components/generic/Form/abstract-form.component';
import { FileInfo } from '@progress/kendo-angular-upload';
import { IProductMarketplace } from '@components/product/interfaces/product-form.interface';
import { SkuSearchService } from '@services/sku-search.service';
import { DATE_FULL_FORMAT, DATE_SHORT_FORMAT } from '@constants';
import * as moment from 'moment';
import { CustomerTypeResource } from '@resources';

/**
 * Create a form for tabs personalisation and styling
 * it's linked to tab component and later to categories component
 */

@Component({
  selector: 'app-tab-management',
  template: require('./tab-management.component.html'),
  styles: [require('./tab-management.component.scss')],
  providers: [SkuSearchService],
})
export class TabManagementComponent extends AbstractFormComponent implements OnInit {

  @Input() public model: ITab;
  @Input() public inCreation: boolean;
  @Input() public violations: IFormViolation[]|any = [];
  @Input() public resource: IResource;
  @Input() public formType: string;

  public form: FormGroup;
  public currentLocales: string[] = SessionHelper.getCountry().locales;
  public tabTypeList: string[] = [
    this.$translate.instant('PAGE.TAB.FORM.SPECIFIC_URL'),
    this.$translate.instant('PAGE.TAB.FORM.CMS'),
    this.$translate.instant('PAGE.TAB.FORM.COMMERCIAL_OPERATIONS'),
    this.$translate.instant('PAGE.TAB.FORM.ROOM'),
    this.$translate.instant('PAGE.TAB.FORM.COLLAPSE'),
    this.$translate.instant('PAGE.TAB.FORM.CATEGORY'),
  ];
  public parentTabs: any[] = [];
  public customerTypes: any[] = [];
  public isParent: boolean = true;
  public type: string;
  public isUrl: boolean = false;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    public formNotifier: FormNotifierService,
    private fb: FormBuilder,
    private customerTypeResource: CustomerTypeResource,
  ) {
    super($translate, authService, null, state);
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      translations: this.fb.array([]),
      type: [this.getType(this.model), Validators.required],
      parent: [],
      position: [+this.model.position || 0, [Validators.required, Validators.min(0)]],
      startDate: new FormControl((this.model.startDate && this.model.startTime) ?
        moment(this.model.startDate, DATE_SHORT_FORMAT).startOf('day')
          .add(this.model.startTime.getHours(), 'hours')
          .add(this.model.startTime.getMinutes(), 'minutes')
          .add(this.model.startTime.getSeconds(), 'seconds')
          .format(DATE_FULL_FORMAT) :
        this.model.startDate ?
          moment(this.model.startDate, DATE_FULL_FORMAT).startOf('day').format(DATE_SHORT_FORMAT) :
          null
      ),
      startTime: new FormControl(this.model.startDate ? new Date(this.model.startDate) : null),
      endDate: new FormControl((this.model.endDate && this.model.endTime) ?
        moment(this.model.endDate, DATE_SHORT_FORMAT).startOf('day')
          .add(this.model.endTime.getHours(), 'hours')
          .add(this.model.endTime.getMinutes(), 'minutes')
          .add(this.model.endTime.getSeconds(), 'seconds')
          .format(DATE_FULL_FORMAT) :
        this.model.endDate ?
          moment(this.model.endDate, DATE_FULL_FORMAT).endOf('day').format(DATE_SHORT_FORMAT) :
          null
      ),
      endTime: new FormControl(this.model.endDate ? new Date(this.model.endDate) : null),
      active: [this.model.active],
      obfuscated: [this.model.obfuscated],
      hiddenMenu: [this.model.hiddenMenu],
      customerTypes: [this.model.customerTypes],
      publicLink: [this.model.publicLink],
      fontColor: [ColorHelper.convertRGBAToHex8(this.model.fontColor)],
      backgroundColor: [ColorHelper.convertRGBAToHex8(this.model.backgroundColor)],
    });

    this.isParent = !this.model.parent;
    this.type = this.model.type;

    this.getParentTabs();
    this.getCustomerTypes();

    this.form.statusChanges.subscribe(() => {
      if (this.form.dirty) {
        this.formNotifier.notifyFormInEdition();
      }
    });

    if (!this.inCreation) {
      Object.keys(this.model.translations).forEach((locale: any) => {
        (<FormArray>this.form.controls['translations']).push(this.createTranslationForm(this.model.translations[locale]));
      });

      return;
    }

    this.prepareTranslationsForForm(SessionHelper.getCountry());
  }

  public changeType(event: any): void {
    this.type = this.getTypeFromString(event);
    const trans = this.form.get('translations') as FormArray;
    if (this.type === 'url') {
      Object.keys(this.model.translations).forEach((locale: any) => {
        trans.controls.find((group: FormGroup) => locale === group.get('locale').value).get('url').setValidators([Validators.required]);
      });
      return;
    }

    Object.keys(this.model.translations).forEach((locale: any) => {
      trans.controls.find((group: FormGroup) => locale === group.get('locale').value).get('url').setValidators([]);
    });
  }

  public hasSeveralTranslation(): boolean {
    return this.currentLocales.length > 1;
  }

  public setColorValue(color: Event, name: string): void {
    this.form.get(name).setValue(color);
    this.form.markAsDirty();
  }

  public getParentTabs(): void {
    this.resource.getTabsLight(
      {
        'translations.locale': SessionHelper.getCountry().defaultLocale,
        'pagination': false
      },
      {
        model: TabListModel,
        returnHydraMembers: true,
      }
    )
      .takeUntil(this.destroyed$)
      .subscribe((response: ITabList[]) => {
        response.map((item: ITabList) => {
          this.parentTabs.push(this.formatTabsList(item));
        });

        this.parentTabs = this.parentTabs.filter((item: ITabList) => {
          return !this.model.id || !item.id.includes(this.model.id);
        });

        this.parentTabs.unshift({ name: this.translate('PAGE.TAB.FORM.NO_PARENTS'), id: null });

        this.model.parent
          ? this.form.get('parent').setValue(this.parentTabs.find(tab => tab.id === this.model.parent['@id']))
          : this.form.get('parent').setValue(this.parentTabs[0]);
      })
    ;
  }

  public getCustomerTypes(): void {
    this.customerTypeResource.cGet({}, { isHydra: true, returnHydraMembers: true })
    .takeUntil(this.destroyed$).subscribe((response: any[]) => {
      this.customerTypes = response;
    });
  }

  private prepareTranslationsForForm(country: ICountry): void {
    const arrayControls = this.form.get('translations') as FormArray;

    while (arrayControls.length !== 0) {
      arrayControls.removeAt(0);
    }

    country.locales.forEach((locale: string) => {

      const translation: {
        name: string;
        locale: string;
        url: string;
        image: {
          file: FileInfo;
          startDate: string;
          startTime: string;
          endDate: string;
          endTime: string;
        };
        leftBanner: {
          file: FileInfo;
          startDate: string;
          startTime: string;
          endDate: string;
          endTime: string;
          associatedLink: string;
          title: string;
          description: string;
          alt: string;
          productMarketplace: string;
        };
        middleBanner: {
          file: FileInfo;
          startDate: string;
          startTime: string;
          endDate: string;
          endTime: string;
          associatedLink: string;
          title: string;
          description: string;
          alt: string;
          productMarketplace: string;
        };
        rightBanner: {
          file: FileInfo;
          startDate: string;
          startTime: string;
          endDate: string;
          endTime: string;
          associatedLink: string;
          title: string;
          description: string;
          alt: string;
          productMarketplace: string;
        };
        icon: {
          file: FileInfo;
        };
        publicLink?: string;
      } = {
        name: '',
        url: '',
        locale,
        image: {
          file: null,
          startDate: null,
          startTime: null,
          endDate: null,
          endTime: null,
        },
        leftBanner: {
          file: null,
          startDate: null,
          startTime: null,
          endDate: null,
          endTime: null,
          associatedLink: '',
          title: '',
          description: '',
          alt: '',
          productMarketplace: '',
        },
        middleBanner: {
          file: null,
          startDate: null,
          startTime: null,
          endDate: null,
          endTime: null,
          associatedLink: '',
          title: '',
          description: '',
          alt: '',
          productMarketplace: '',
        },
        rightBanner: {
          file: null,
          startDate: null,
          startTime: null,
          endDate: null,
          endTime: null,
          associatedLink: '',
          title: '',
          description: '',
          alt: '',
          productMarketplace: '',
        },
        icon: {
          file: null,
        },
        publicLink: null,
      };

      arrayControls.push(this.createTranslationForm(translation));
    });
  }

  public createTranslationForm(translation: any): FormGroup {
    return this.fb.group({
      id: [translation.id],
      name: [translation.name, Validators.required],
      url: [translation.url],
      locale: [translation.locale],
      image: this.fb.group({
        file: [],
        startDate: [(translation.image && translation.image.startDate && translation.image.startTime) ?
          moment(translation.image.startDate).startOf('day')
            .add(translation.image.startTime.getHours(), 'hours')
            .add(translation.image.startTime.getMinutes(), 'minutes')
            .add(translation.image.startTime.getSeconds(), 'seconds')
            .format(DATE_FULL_FORMAT) :
          (translation.image && translation.image.startDate) ?
            moment(translation.image.startDate, DATE_FULL_FORMAT).startOf('day').format(DATE_SHORT_FORMAT) :
            null],
        startTime: [translation.image && translation.image.startDate ? new Date(translation.image.startDate) : null],
        endDate: [(translation.image && translation.image.endDate && translation.image.endTime) ?
          moment(translation.image.endDate).startOf('day')
            .add(translation.image.endTime.getHours(), 'hours')
            .add(translation.image.endTime.getMinutes(), 'minutes')
            .add(translation.image.endTime.getSeconds(), 'seconds')
            .format(DATE_FULL_FORMAT) :
          (translation.image && translation.image.endDate) ?
            moment(translation.image.endDate, DATE_FULL_FORMAT).endOf('day').format(DATE_SHORT_FORMAT) :
            null],
        endTime: [translation.image && translation.image.endDate ? new Date(translation.image.endDate) : null],
      }),
      leftBanner: this.fb.group({
        file: [],
        startDate: [(translation.leftBanner && translation.leftBanner.startDate && translation.leftBanner.startTime) ?
          moment(translation.leftBanner.startDate).startOf('day')
            .add(translation.leftBanner.startTime.getHours(), 'hours')
            .add(translation.leftBanner.startTime.getMinutes(), 'minutes')
            .add(translation.leftBanner.startTime.getSeconds(), 'seconds')
            .format(DATE_FULL_FORMAT) :
          (translation.leftBanner && translation.leftBanner.startDate) ?
            moment(translation.leftBanner.startDate, DATE_FULL_FORMAT).startOf('day').format(DATE_SHORT_FORMAT) :
            null],
        startTime: [translation.leftBanner && translation.leftBanner.startDate ? new Date(translation.leftBanner.startDate) : null],
        endDate: [(translation.leftBanner && translation.leftBanner.endDate && translation.leftBanner.endTime) ?
          moment(translation.leftBanner.endDate).startOf('day')
            .add(translation.leftBanner.endTime.getHours(), 'hours')
            .add(translation.leftBanner.endTime.getMinutes(), 'minutes')
            .add(translation.leftBanner.endTime.getSeconds(), 'seconds')
            .format(DATE_FULL_FORMAT) :
          (translation.leftBanner && translation.leftBanner.endDate) ?
            moment(translation.leftBanner.endDate, DATE_FULL_FORMAT).endOf('day').format(DATE_SHORT_FORMAT) :
            null],
        endTime: [translation.leftBanner && translation.leftBanner.endDate ? new Date(translation.leftBanner.endDate) : null],
        associatedLink: [translation.leftBanner ? translation.leftBanner.associatedLink : ''],
        title: [translation.leftBanner ? translation.leftBanner.title : ''],
        description: [translation.leftBanner ? translation.leftBanner.description : ''],
        alt: [translation.leftBanner ? translation.leftBanner.alt : ''],
        productMarketplace: [translation.leftBanner ? this.getProductMarketplaceId(translation.leftBanner.productMarketplace) : ''],
      }),
      middleBanner: this.fb.group({
        file: [],
        startDate: [(translation.middleBanner && translation.middleBanner.startDate && translation.middleBanner.startTime) ?
          moment(translation.middleBanner.startDate).startOf('day')
            .add(translation.middleBanner.startTime.getHours(), 'hours')
            .add(translation.middleBanner.startTime.getMinutes(), 'minutes')
            .add(translation.middleBanner.startTime.getSeconds(), 'seconds')
            .format(DATE_FULL_FORMAT) :
          (translation.middleBanner && translation.middleBanner.startDate) ?
            moment(translation.middleBanner.startDate, DATE_FULL_FORMAT).startOf('day').format(DATE_SHORT_FORMAT) :
            null],
        startTime: [translation.middleBanner && translation.middleBanner.startDate ? new Date(translation.middleBanner.startDate) : null],
        endDate: [(translation.middleBanner && translation.middleBanner.endDate && translation.middleBanner.endTime) ?
          moment(translation.middleBanner.endDate).startOf('day')
            .add(translation.middleBanner.endTime.getHours(), 'hours')
            .add(translation.middleBanner.endTime.getMinutes(), 'minutes')
            .add(translation.middleBanner.endTime.getSeconds(), 'seconds')
            .format(DATE_FULL_FORMAT) :
          (translation.middleBanner && translation.middleBanner.endDate) ?
            moment(translation.middleBanner.endDate, DATE_FULL_FORMAT).endOf('day').format(DATE_SHORT_FORMAT) :
            null],
        endTime: [translation.middleBanner && translation.middleBanner.endDate ? new Date(translation.middleBanner.endDate) : null],
        associatedLink: [translation.middleBanner ? translation.middleBanner.associatedLink : ''],
        title: [translation.middleBanner ? translation.middleBanner.title : ''],
        description: [translation.middleBanner ? translation.middleBanner.description : ''],
        alt: [translation.middleBanner ? translation.middleBanner.alt : ''],
        productMarketplace: [translation.middleBanner ? this.getProductMarketplaceId(translation.middleBanner.productMarketplace) : ''],
      }),
      rightBanner: this.fb.group({
        file: [],
        startDate: [(translation.rightBanner && translation.rightBanner.startDate && translation.rightBanner.startTime) ?
          moment(translation.rightBanner.startDate).startOf('day')
            .add(translation.rightBanner.startTime.getHours(), 'hours')
            .add(translation.rightBanner.startTime.getMinutes(), 'minutes')
            .add(translation.rightBanner.startTime.getSeconds(), 'seconds')
            .format(DATE_FULL_FORMAT) :
          (translation.rightBanner && translation.rightBanner.startDate) ?
            moment(translation.rightBanner.startDate, DATE_FULL_FORMAT).startOf('day').format(DATE_SHORT_FORMAT) :
            null],
        startTime: [translation.rightBanner && translation.rightBanner.startDate ? new Date(translation.rightBanner.startDate) : null],
        endDate: [(translation.rightBanner && translation.rightBanner.endDate && translation.rightBanner.endTime) ?
          moment(translation.rightBanner.endDate).startOf('day')
            .add(translation.rightBanner.endTime.getHours(), 'hours')
            .add(translation.rightBanner.endTime.getMinutes(), 'minutes')
            .add(translation.rightBanner.endTime.getSeconds(), 'seconds')
            .format(DATE_FULL_FORMAT) :
          (translation.rightBanner && translation.rightBanner.endDate) ?
            moment(translation.rightBanner.endDate, DATE_FULL_FORMAT).endOf('day').format(DATE_SHORT_FORMAT) :
            null],
        endTime: [translation.rightBanner && translation.rightBanner.endDate ? new Date(translation.rightBanner.endDate) : null],
        associatedLink: [translation.rightBanner ? translation.rightBanner.associatedLink : ''],
        title: [translation.rightBanner ? translation.rightBanner.title : ''],
        description: [translation.rightBanner ? translation.rightBanner.description : ''],
        alt: [translation.rightBanner ? translation.rightBanner.alt : ''],
        productMarketplace: [translation.rightBanner ? this.getProductMarketplaceId(translation.rightBanner.productMarketplace) : ''],
      }),
      icon: this.fb.group({
        file: [],
      }),
      publicLink: [translation.publicLink],
    });
  }

  private getProductMarketplaceId(productMarketplace: IProductMarketplace|null): string {
    return productMarketplace ? productMarketplace.id : '';
  }

  public getTranslation(key?: string): FormGroup {
    const translations: FormArray = this.form.get('translations') as FormArray;

    if (this.hasSeveralTranslation() && undefined !== key) {
      return translations.controls.find((group: FormGroup) => key === group.get('locale').value) as FormGroup;
    }

    return translations.controls[0] as FormGroup;
  }

  public formatTabsList(tab: ITabList): any {
    return { name: tab.name, id: tab['@id'] };
  }

  public showParentFields(event: any) {
    this.isParent = (!event || !event.id);
  }

  public getType(tab: ITab): string {
    switch (tab.type) {
      case 'universe':
        return this.translate('PAGE.TAB.FORM.CATEGORY');
      case 'commercial-operation':
        return this.translate('PAGE.TAB.FORM.COMMERCIAL_OPERATIONS');
      case 'room':
        return this.translate('PAGE.TAB.FORM.ROOM');
        case 'cms':
          return this.translate('PAGE.TAB.FORM.CMS');
      case 'url':
        return this.translate('PAGE.TAB.FORM.SPECIFIC_URL');
      case 'collapse':
      default:
        return this.translate('PAGE.TAB.FORM.COLLAPSE');
    }
  }

  public getTypeFromString(type: string): string {
    switch (type) {
      case this.translate('PAGE.TAB.FORM.CATEGORY'):
        return 'universe';
      case this.translate('PAGE.TAB.FORM.COMMERCIAL_OPERATIONS'):
        return 'commercial-operation';
      case this.translate('PAGE.TAB.FORM.ROOM'):
        return 'room';
      case this.translate('PAGE.TAB.FORM.CMS'):
        return 'cms';
      case this.translate('PAGE.TAB.FORM.SPECIFIC_URL'):
        return 'url';
      case this.translate('PAGE.TAB.FORM.COLLAPSE'):
      default:
        return 'collapse';
    }
  }

  public displayCustomerTypeField(): boolean {
    return this.model.commercialOperation !== '' || this.form.get('type').value === this.translate('PAGE.TAB.FORM.COMMERCIAL_OPERATIONS');
  }

  public displayPublicLinkField(): boolean {
    return this.displayCustomerTypeField() && (this.model.customerTypes.length > 0 || this.form.get('customerTypes').value.length > 0);
  }
}
