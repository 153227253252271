import { Component, Inject } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { SessionHelper } from '@helpers';
import { AuthService } from '@services';
import { LOCALE_FR, LOCALE_GB } from '@constants';
import * as moment from 'moment';

interface ILocaleApplication {
  language: string;
  locale: string;
}

/**
 * TODO: it's just a fix to handle interface language, waiting for a complete refactoring
 */
@Component({
  selector: 'app-language-interface',
  template: require('./language-interface.component.html')
})
export class LanguageInterfaceComponent extends AbstractComponent {
  public applicationLanguages: ILocaleApplication[];
  public uiLanguage: ILocaleApplication;

  public constructor (
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, null, state);

    this.initDropdownLanguages();

    if (SessionHelper.getUILanguage()) {
      this.uiLanguage = this.applicationLanguages.find((language) => language.locale === SessionHelper.getUILanguage());

      return;
    }

    this.uiLanguage = this.applicationLanguages[0];
    SessionHelper.setUILanguage(this.uiLanguage.locale);
  }

  public selectionChange(locale: string): void {
    const shortLocale = locale.split('_').shift();

    SessionHelper.setUILanguage(locale);
    document.documentElement.setAttribute('lang', shortLocale);
    moment.locale(shortLocale);
    this.$translate.use(locale);
  }

  private initDropdownLanguages(): void {
    this.applicationLanguages = [
      {
        language: this.translate('APPLICATION.LANGUAGE.FRENCH'),
        locale: LOCALE_FR,
      },
      {
        language: this.translate('APPLICATION.LANGUAGE.ENGLISH'),
        locale: LOCALE_GB,
      }
    ];
  }
}
