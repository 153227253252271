'use strict'

angular
  .module 'ammsFrontendApp'
  .filter 'htmlSafe', [
    '$sce'
    ($sce) ->
      (htmlCode) ->
        $sce.trustAsHtml htmlCode
  ]
