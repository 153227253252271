import { Injectable } from '@angular/core';

@Injectable()
export class FieldUtilityService {

  /**
   * Trigger actions on a textarea:
   * `shift + enter` work like facebook textarea,
   * `enter` trigger the enterAction like submitting the field
   * `escape` trigger the escapeAction like resetting the field.
   */
  public triggerTextAreaActions(event: KeyboardEvent, fieldValue: string, enterAction: () => any, escapeAction: () => any): void {
    switch (event.key) {
      case 'Enter':
        if (fieldValue && !event.shiftKey) {
          event.preventDefault();
          enterAction();
        }
        break;
      case 'Escape':
        if (fieldValue) {
          escapeAction();
        }
        break;
      default:
        return;
    }
  }
}
