import { ICategories } from '.';
import { CategoriesListComponent, CategoriesSatelliteListComponent } from '../list';
import { ICategorySatellite, ICategorySatelliteTranslation } from './category-satellite.interface';

export class CategorySatelliteModel implements ICategorySatellite {
  public '@id': string;
  public id: number;
  public referenceCategory: ICategories | string;
  public active: boolean;
  public translations: { [key: string]: ICategorySatelliteTranslation };
  public seoMetaRobot: string;
  public attributeFamilies: string[];
  public minPrice?: number;
  public maxPrice?: number;

  constructor(categorySatellite: ICategorySatellite = {
    '@id': '',
    id: 0,
    referenceCategory: null,
    translations: null,
    attributeFamilies: [],
    minPrice: null,
    maxPrice: null
  }) {
    this['@id'] = categorySatellite['@id'];
    this.id = categorySatellite.id;
    this.referenceCategory = categorySatellite.referenceCategory;
    this.translations = this.transformTranslations(categorySatellite.translations);
    this.attributeFamilies = categorySatellite.attributeFamilies;
    this.minPrice = categorySatellite.minPrice;
    this.maxPrice = categorySatellite.maxPrice;
  }

  private transformTranslations(translations: { [key: string]: ICategorySatelliteTranslation }) {
    for (const locale in translations) {
      if (Object.prototype.hasOwnProperty.call(translations, locale)) {
        translations[locale] = new CategorySatelliteTranslationModel(translations[locale]);
      }
    }

    return translations;
  }
}

export class CategorySatelliteTranslationModel implements ICategorySatelliteTranslation {
  public '@id'?: string;
  public id?: number|string;
  public name?: string;
  public shortDescription: string;
  public longDescription: string;
  public metaDescriptionSeo?: string;
  public titleSeo?: string;
  public locale?: string;
  public active: boolean;
  public seoMetaRobot: string;

  constructor(categorySatelliteTranslation: ICategorySatelliteTranslation = {
    '@id': '',
    name: '',
    shortDescription: '',
    longDescription: '',
    metaDescriptionSeo: '',
    titleSeo: '',
    locale: '',
    active: false,
    seoMetaRobot: '',
  }) {
    this['@id'] = categorySatelliteTranslation['@id'];
    this.name = categorySatelliteTranslation.name;
    this.shortDescription = categorySatelliteTranslation.shortDescription;
    this.longDescription = categorySatelliteTranslation.longDescription;
    this.metaDescriptionSeo = categorySatelliteTranslation.metaDescriptionSeo;
    this.titleSeo = categorySatelliteTranslation.titleSeo;
    this.locale = categorySatelliteTranslation.locale;
    this.active = categorySatelliteTranslation.active;
    this.seoMetaRobot = categorySatelliteTranslation.seoMetaRobot;
  }
}
