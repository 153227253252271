import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { AbstractComponent } from '@components/generic/abstract.component';
import { AuthService } from '@services';

@Component({
  selector: 'app-dnd-list-children',
  template: require('./dnd-list-children.component.html'),
})
export class DndListChildrenComponent extends AbstractComponent {
  @Input() public children: object[];
  @Input() public cols: any;
  @Input() public index?: number = 0;

  @Output() public onDragStart: EventEmitter<any> = new EventEmitter();
  @Output() public onDragEnter: EventEmitter<any> = new EventEmitter();
  @Output() public onDragEnd: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, null, state);
  }
}
