import { IPaymentCarrierIcons } from './payment-carrier-icons.interface';

export class PaymentCarrierIconsModel implements IPaymentCarrierIcons {
  id: string;
  picto: string;
  position: number;

  constructor(paymentCarrierIcon: IPaymentCarrierIcons) {
    this.id = paymentCarrierIcon.id;
    this.picto = paymentCarrierIcon.picto;
    this.position = paymentCarrierIcon.position;
  }
}
