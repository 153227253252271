import { Component, Inject } from '@angular/core';
import { AuthService} from '@services/auth.service';
import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { AbstractResource } from '@resources/abstract.resource';
import { ExportLogisticsHistoryResource } from '@components/export-logistics/export-logistics-history/export-logistics-history.resource';
import {AbstractFiltersFieldsService} from '@components/generic/Form/filters/abstract-filters-fields.service';
import {
  ExportLogisticsHistoryFiltersFormService
} from '@components/export-logistics/services/export-logistics-history-filters-form.service';
@Component({
  selector: 'app-export-logistics-history',
  template: require('./export-logistics-history.component.html'),
  providers: [
    { provide: AbstractResource, useClass: ExportLogisticsHistoryResource },
    { provide: AbstractFiltersFieldsService, useClass: ExportLogisticsHistoryFiltersFormService },
  ]
})
export class ExportLogisticsHistoryComponent extends AbstractPageComponent {
  public readonly roles: string[] = ['ROLE_WALISOFT_AGENT', 'ROLE_WALISOFT_AGENT'];

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
  ) {
    super($translate, authService, null, state);
  }
}
