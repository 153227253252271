import { Inject, Injectable } from '@angular/core';
import { FormNotifierService } from '@services/form-notifier.service';
import { DateField, SkuSearchField, TextField } from '@components/generic/Form/dynamic/fields';
import { AbstractFiltersFieldsService } from '@components/generic/Form/filters/abstract-filters-fields.service';
import * as moment from 'moment';
import { DATE_FULL_FORMAT } from '@constants';
import { DropDownListField } from '@components/generic/Form/dynamic/fields/select-field.class';

@Injectable()
export class StockRegularizationPackageListFiltersService extends AbstractFiltersFieldsService {
  private startDateField: TextField = new TextField({
    fieldName: 'startDate',
    value: null,
    hidden: true
  });

  private endDateField: TextField = new TextField({
    fieldName: 'endDate',
    value: null,
    hidden: true
  });

  constructor(
    @Inject('StateService') protected state: ng.ui.IStateService,
    @Inject('TranslationService') public $translate: ng.translate.ITranslateService,
    formNotifier: FormNotifierService,
  ) {
    super(formNotifier, state);
  }

  public getFields(): any[] {
    const startDate = this.filters.get('startDate');
    const endDate = this.filters.get('endDate');

    if (startDate && endDate) {
      this.startDateField.value = startDate;
      this.endDateField.value = endDate;
    }

    return [
      new SkuSearchField({
        fieldName: 'sku',
        label: 'PAGE.STOCK_REGULARIZATION_PACKAGE_VIEW.LIST.FILTER.SKU',
        productType: 'product',
        value: this.filters.getAll('sku').length !== 0 ? this.filters.getAll('sku') : undefined,
        valuePrimitive: true,
        limit: 1,
        withSpareParts: false,
      }),
      new DateField({
        fieldName: 'date',
        label: 'PAGE.STOCK_REGULARIZATION_PACKAGE_VIEW.LIST.FILTER.DATE',
        dateRange: true,
        value: startDate && endDate ? [new Date(startDate), new Date(endDate)] : undefined,
        valueChangedAction: this.setDates.bind(this),
      }),
      new DropDownListField({
        fieldName: 'origin',
        label: 'PAGE.STOCK_REGULARIZATION_PACKAGE_VIEW.LIST.FILTER.ORIGIN',
        data: [
          { label: this.$translate.instant('PAGE.STOCK_REGULARIZATION_PACKAGE_VIEW.LIST.FILTER.ORIGIN.LOGISTICS'), value: 'logistics' },
          { label: this.$translate.instant('PAGE.STOCK_REGULARIZATION_PACKAGE_VIEW.LIST.FILTER.ORIGIN.IMPORT'), value: 'import' },
        ],
        textField: 'label',
        valueField: 'value',
        value: this.filters.get('origin') ? this.filters.get('origin') : null,
        defaultItem: { label: '', value: null },
        valuePrimitive: true,
      }),
      this.startDateField,
      this.endDateField,
    ];
  }

  private setDates(newValue: string): void {
    if (newValue && null !== newValue[0]) {
      this.startDateField.value = moment(newValue[0]).startOf('day').format(DATE_FULL_FORMAT);
      this.endDateField.value = moment(newValue[1]).endOf('day').format(DATE_FULL_FORMAT);
    }
  }
}
